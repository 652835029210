import {
  EnhancedButton,
  EnhancedCol,
  EnhancedRow,
  EnhancedCard,
  EnhancedIcon,
  EnhancedTooltip,
  EnhancedInput,
} from 'components/shared/antd';
import React from 'react';
import moment from 'moment';
import {
  ACTIONS,
  CREATED_AT,
  DATE_FORMAT,
  LABEL_SIZE_XS,
  VALUE_SIZE_XS,
  ROW_GUTTER_SIZE,
} from 'configs/employeeProfileConstants';
import Permissions from 'constants/AccessPermissions';
import { hasPermission } from 'utils/AccessUtils';
import { REQUIRED, FILE_NAME } from 'configs/validationConstants';
import { noData, shouldSort } from 'utils/FieldGenerateUtils';
import { createErrorMessage } from 'utils/validationUtils';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';

class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.documentRef = React.createRef();
    this.documentURL = null;
    this.isDocumentFetching = false;
    this.state = {
      sortedInfo: {
        columnKey: 'createdAt',
        field: 'createdAt',
        order: 'descend',
      },
      fileName: '',
      uploadFile: null,
      showUploadForm: false,
      errors: {
        fileName: '',
        uploadFile: '',
      },
    };

    this.columns = [
      {
        key: 'name',
        label: 'Document Name',
        filterConfig: {
          type: 'search',
          key: 'name',
        },
      },
      { key: 'createdAt', label: 'Date' },
      { key: ACTIONS, label: 'Actions' },
    ];
  }

  componentDidMount() {
    const employeeId = 'me';
    this.props.getEmployeeDocuments(employeeId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.employeeDocumentUrl && this.isDocumentFetching) {
      this.isDocumentFetching = false;
      this.documentRef.current.href = nextProps.employeeDocumentUrl;
      this.documentRef.current.click();
    }

    if (this.props.documents !== nextProps.documents) {
      if (this.state.showUploadForm) {
        this.handleCancel();
      }
    }
  }

  getDocumentUrl = (document) => {
    const employeeId = 'me';
    this.documentURL = null;
    this.isDocumentFetching = true;
    this.props.getEmployeeDocumentURL(employeeId, document.id);
  };

  getColumnsInfo = () => {
    let { sortedInfo } = this.state;

    sortedInfo = sortedInfo || {};
    const columnsToRender = [];

    this.columns.forEach((col) => {
      const columnObj = {
        title: col.label,
        dataIndex: col.key,
        key: col.key,
        render: (_, data) => this.renderField(data, data[col.key], col.key),
        width: 'auto',
        sorter: shouldSort(col.key) ? (a, b) => this.sortField(a, b, col.key) : false,
        sortOrder: sortedInfo.columnKey === col.key && sortedInfo.order,
      };

      if (col.filterConfig) {
        columnObj.filterConfig = col.filterConfig;
      }

      columnsToRender.push(columnObj);
    });

    return columnsToRender;
  };

  getTable = (documents) => {
    const { isLoading } = this.props;
    const columns = this.getColumnsInfo();

    return (
      <EnhancedClientSideTable
        loading={isLoading}
        columns={columns}
        data={documents}
        onChange={this.handleChange}
        scroll={{ x: 400 }}
      />
    );
  };

  handleFileUpload = (evt) => {
    const file = evt.target.files[0];
    const reader = new FileReader();
    reader.onload = this.hanldeFileLoad;
    reader.fileName = file.name;
    reader.readAsDataURL(file);
  };

  handleFileName = (e) => {
    this.setState({ fileName: e.target.value });
    if (this.state.errors.uploadFile) {
      this.validateFields();
    }
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({ sortedInfo: sorter });
  };

  sortField = (a, b, key = null) => {
    switch (key) {
      case CREATED_AT:
        return new Date(a[key]) - new Date(b[key]);
      case ACTIONS:
        return null;
      default:
        return a[key].localeCompare(b[key]);
    }
  };

  handleCancel = () => {
    this.setState({
      showUploadForm: false,
      uploadFile: null,
      fileName: '',
    });
  };

  handleEdit = () => {
    this.setState({
      showUploadForm: true,
    });
  };

  hanldeFileLoad = (evt) => {
    const base64 = evt.target.result;
    const { fileName } = evt.target;
    const updatedState = {
      uploadFile: base64,
    };

    const splitedFileName = fileName.split('.')[0];
    updatedState.fileName = splitedFileName;
    this.setState({ ...updatedState });
    if (this.state.errors.uploadFile) {
      this.validateFields();
    }
  };

  validateFields = () => {
    const documentError = createErrorMessage({
      value: this.state.uploadFile,
      validations: [REQUIRED],
    });
    const fileNameError = createErrorMessage({
      value: this.state.fileName,
      validations: [REQUIRED, FILE_NAME],
    });

    this.setState({
      errors: {
        fileName: fileNameError,
        uploadFile: documentError,
      },
    });
  };

  handleSave = () => {
    const { uploadFile, fileName, errors } = this.state;
    this.validateFields();
    if (uploadFile && fileName && !errors.fileName && !errors.uploadFile) {
      const employeeId = 'me';
      this.props.uploadEmployeeDocument(this.state.uploadFile, this.state.fileName, employeeId);
    }
  };

  handleDelete = (document) => {
    const employeeId = 'me';
    this.props.deleteEmployeeDocument(employeeId, document.id);
  };

  renderCreateDocumentForm = () => {
    const { errors } = this.state;
    return (
      <EnhancedCard className="mb-10 card-shadow upload-document" hoverable title="Upload Document">
        <EnhancedRow gutter={ROW_GUTTER_SIZE}>
          <EnhancedCol xs={LABEL_SIZE_XS} md={4}>
            <b className="label">File Name</b>
          </EnhancedCol>
          <EnhancedCol xs={VALUE_SIZE_XS} md={10}>
            <EnhancedInput
              id="document-upload"
              value={this.state.fileName}
              onChange={this.handleFileName}
            />
            {errors.fileName ? errors.fileName : null}
          </EnhancedCol>
          <EnhancedCol xs={VALUE_SIZE_XS} md={8}>
            <input
              type="file"
              id="element"
              accept="application/pdf"
              onChange={this.handleFileUpload}
            />
            {errors.uploadFile ? errors.uploadFile : null}
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow gutter={ROW_GUTTER_SIZE}>
          <EnhancedCol xs={24} md={24}>
            <div className="actions">
              <EnhancedButton className="btn-actions" onClick={this.handleSave}>
                Save
              </EnhancedButton>
              <EnhancedButton className="btn-actions" onClick={this.handleCancel}>
                Cancel
              </EnhancedButton>
            </div>
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedCard>
    );
  };

  renderField = (data, fieldValue, key = null) => {
    const { userPermissions } = this.props;
    const canDelete = hasPermission(userPermissions, [
      Permissions.Employee.Profile.Documents.Delete,
    ]);
    switch (key) {
      case ACTIONS:
        return (
          <div className="actions-wrapper">
            <EnhancedTooltip placement="top" title="Download">
              <EnhancedIcon
                className="table-download-link"
                type="download"
                onClick={() => this.getDocumentUrl(data)}
              />
            </EnhancedTooltip>
            {canDelete && (
              <EnhancedTooltip placement="top" title="Delete">
                <EnhancedIcon
                  className="table-download-link"
                  type="delete"
                  onClick={() => this.handleDelete(data)}
                />
              </EnhancedTooltip>
            )}
            {/* <a target="_blank" href={fieldValue}>
              Download
            </a> */}
          </div>
        );
      case CREATED_AT:
        return (
          <div className="table-field-value">
            {fieldValue ? moment(fieldValue).format(DATE_FORMAT) : 'N/A'}
          </div>
        );
      default:
        return <div className="table-field-value">{fieldValue}</div>;
    }
  };

  render() {
    const { isLoading } = this.props;
    const documents = this.props.documents || [];
    const canCreate = false;
    return (
      <React.Fragment>
        {!isLoading && canCreate && (
          <EnhancedRow gutter={ROW_GUTTER_SIZE}>
            <EnhancedCol xs={24} md={24} className="upload-document-btn">
              <EnhancedButton className="actions" onClick={this.handleEdit}>
                Upload
              </EnhancedButton>
            </EnhancedCol>
          </EnhancedRow>
        )}
        {this.state.showUploadForm && this.renderCreateDocumentForm()}
        <EnhancedCard className="mt-20 documents-table" loading={isLoading} bordered={false}>
          <a
            target="_blank"
            href={this.documentURL}
            ref={this.documentRef}
            style={{ display: 'none' }}
          >
            link
          </a>
          {documents.length ? this.getTable(documents) : noData()}
        </EnhancedCard>
      </React.Fragment>
    );
  }
}

export default Documents;
