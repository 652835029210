import { EnhancedCol, EnhancedRow, EnhancedLayout } from 'components/shared/antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';

const { Header } = EnhancedLayout;

class ProjectReviews extends Component {
  componentWillMount() {
    this.props.getProjectReviewsCycleAction();
  }

  getExportFileHeaders = () => {
    return [
      {
        key: 'name',
        label: 'Cycle Name',
      },
      {
        key: 'projectCount',
        label: 'No. of Projects',
      },
      {
        key: 'status',
        label: 'Cycle Status',
      },
    ];
  };

  getColumnsInfo = () => {
    const columns = [
      {
        title: 'Cycle',
        dataIndex: 'name',
        key: 'name',
        render: this.renderCycleName,
        filterConfig: {
          type: 'search',
          key: 'name',
        },
      },
      {
        title: 'No. of Projects',
        dataIndex: 'projectCount',
        key: 'projectCount',
      },
      {
        title: 'Cycle Status',
        dataIndex: 'status',
        key: 'status',
        render: this.renderStatusName,
      },
    ];
    return columns;
  };

  renderStatusName = (data) => {
    let checkActive = null;
    if (data === 'Actived') {
      checkActive = <p> Active</p>;
    } else {
      checkActive = <p> Complete</p>;
    }
    return checkActive;
  };

  renderCycleName = (name, data) => (
    <Link to={`project-reviews/${data.id}`}>
      <b style={{ color: '#5A5A5A' }}>{name}</b>
    </Link>
  );

  render() {
    const { isLoading } = this.props;
    return (
      <div>
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol lg={24}>
              <h1 className="heading-pro">Project Reviews</h1>
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        <div className="project-table">
          <EnhancedClientSideTable
            loading={isLoading}
            data={this.props.projectReviews}
            columns={this.getColumnsInfo()}
            exportFileConfig={{
              fileName: 'Project Reporting - Cycles',
              fileHeaders: this.getExportFileHeaders(),
              showExportButton: true,
            }}
          />
        </div>
      </div>
    );
  }
}

export default ProjectReviews;
