import {
  GET_PROJECT_DETAILS_INIT,
  GET_PROJECT_DETAILS_SUCCESS,
  GET_PROJECT_DETAILS_FAILURE,
  ADD_NEW_RESOURCE_PROJECT_INIT,
  ADD_NEW_RESOURCE_PROJECT_SUCCESS,
  ADD_NEW_RESOURCE_PROJECT_FAILURE,
  DELETE_EMPLOYEE_FROM_PROJECT,
  UPDATE_EMPLOYEE_IN_PROJECT,
} from 'constants/ProjectEmployeesTypes';

import moment from 'moment';
import { dateFormat } from 'configs/constants';
import { isArray } from 'utils/arrayUtils';

const initialState = {
  projectDetails: [],
  isLoading: false,
  overAllocation: [],
};

export default function projectEmployeesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROJECT_DETAILS_INIT: {
      return {
        ...state,
        projectDetails: [],
        isLoading: true,
      };
    }
    case GET_PROJECT_DETAILS_SUCCESS: {
      return {
        ...state,
        projectDetails: action.data,
        isLoading: false,
      };
    }
    case GET_PROJECT_DETAILS_FAILURE: {
      return {
        ...state,
        projectDetails: [],
        isLoading: false,
      };
    }
    case ADD_NEW_RESOURCE_PROJECT_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADD_NEW_RESOURCE_PROJECT_SUCCESS: {
      const proj = {};
      proj.name = action.employeeName;
      proj.employeeProjectId = action.employeeProjectId.employeeProjectId;
      proj.allocation = action.data.allocation;
      proj.billed = action.data.billed;
      proj.type = action.data.type;
      proj.startDate = action.data.startDate ? action.data.startDate : undefined;
      proj.endDate = action.data.endDate ? action.data.endDate : undefined;
      proj.extendedEndDate = action.data.extendedEndDate ? action.data.extendedEndDate : undefined;
      proj.probability = action.data.probability;
      proj.employeeId = action.data.assigneeId;
      proj.comment = action.data.comment;
      proj.projectId = action.data.projectId;
      proj.existing = true;
      proj.department = action.department;
      proj.competency = action.competency;
      proj.location = action.location;
      proj.taleoId = action.taleoId;
      if (isArray(action.roles)) {
        proj.role = action.roles.join(', ');
      } else {
        proj.role = action.roles;
      }
      return {
        ...state,
        isLoading: false,
        projectDetails: [...state.projectDetails, proj],
        overAllocation: action.employeeProjectId.overAllocation,
      };
    }
    case ADD_NEW_RESOURCE_PROJECT_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case DELETE_EMPLOYEE_FROM_PROJECT: {
      const projDetail = state.projectDetails.filter(
        (proj) => proj.employeeProjectId === action.employeeProjectId,
      )[0];
      if (projDetail) {
        projDetail.existing = false;
        projDetail.endDate = moment().format(dateFormat.DDMMMYYYYwithSpace);
      }
      return {
        ...state,
        projectDetails: [...state.projectDetails],
      };
    }
    case UPDATE_EMPLOYEE_IN_PROJECT: {
      const { employeeProjectInfo, currentInformation } = action;
      let info = currentInformation;

      if (employeeProjectInfo) {
        info = employeeProjectInfo;
        info.role = employeeProjectInfo.roles ? employeeProjectInfo.roles : currentInformation.role;
        info.existing =
          currentInformation.existing !== undefined
            ? currentInformation.existing
            : employeeProjectInfo.existing;
      }

      const proj = state.projectDetails.filter(
        (value) => value.employeeProjectId === info.employeeProjectId,
      )[0];

      if (proj) {
        proj.allocation = info.allocation;
        proj.billed = info.billed;
        proj.startDate = moment(info.startDate).format(dateFormat.DDMMMYYYYwithSpace);
        proj.endDate = info.endDate
          ? moment(info.endDate).format(dateFormat.DDMMMYYYYwithSpace)
          : '';
        proj.extendedEndDate = info.extendedEndDate
          ? moment(info.extendedEndDate).format(dateFormat.DDMMMYYYYwithSpace)
          : '';
        proj.probability = info.probability;
        proj.type = info.type;
        proj.name = proj.name;
        proj.status = info.status;
        proj.existing = info.existing;
        if (isArray(info.role)) {
          proj.role = info.role.join(', ');
        } else {
          proj.role = info.role;
        }
      }

      return {
        ...state,
        projectDetails: [...state.projectDetails],
      };
    }
    default:
      return {
        ...state,
      };
  }
}
