import { EnhancedRow, EnhancedCol, EnhancedCard, EnhancedForm } from 'components/shared/antd';
import React, { useEffect, useState } from 'react';
import {
  GRADES,
  SUB_GRADES,
  ROW_GUTTER_SIZE,
  FULL_GRID_SIZE,
  TEXTAREA,
  SELECT,
  INPUT,
  RADIO,
} from 'configs/employeeProfileConstants';
import { createFields } from 'utils/FieldGenerateUtils';
import { getValueForKeyinJSON } from 'utils/arrayUtils';

const PromotionStep = (props) => {
  const { data, updateHandler, designations, isEditable } = props;
  const [promotion, setPromotion] = useState({});
  const [grads, setGrades] = useState([]);
  const isAllowClear = true;

  const setGradesValues = () => {
    const gradesArr = [];
    GRADES.forEach((grade) => {
      SUB_GRADES.forEach((subGrade) => {
        if (grade.key !== 'n/a' && subGrade.key !== 'n/a') {
          const gradeObj = {
            key: `${grade.key} - ${subGrade.key}`,
            value: `${grade.value} - ${subGrade.value}`,
          };
          gradesArr.push(gradeObj);
        }
      });
    });
    setGrades(gradesArr);
  };

  useEffect(() => {
    setGradesValues();
    setPromotion(data);
  }, [data]);

  const handleSave = () => {};

  const handleChange = (value, key, error) => {
    if (!error) {
      const clonePromotion = { ...promotion };
      let valueToSet = value;

      if (value === undefined) {
        // implemented for case when selected value is removed from dropdowns
        valueToSet = null;
      }
      clonePromotion[key] = valueToSet;
      updateHandler('promotionInfo', clonePromotion);
      setPromotion(clonePromotion);
    }
  };

  return (
    <div>
      <EnhancedForm onSubmit={handleSave}>
        <EnhancedCard>
          <React.Fragment>
            <h2>Promotion & Designation</h2>
            <EnhancedRow gutter={ROW_GUTTER_SIZE}>
              <EnhancedCol span={FULL_GRID_SIZE}>
                <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
                  loading={false}
                  bordered={false}
                  className="card-pd-0"
                >
                  <div>
                    {createFields(
                      [
                        {
                          key: `isPromotion`,
                          label: 'Is Promotion?',
                          value: promotion.isPromotion,
                          options: [
                            { key: true, value: 'Yes' },
                            { key: false, value: 'No' },
                          ],
                          type: RADIO,
                          labelSize: 4,
                          valueSize: 6,
                        },
                      ],
                      1,
                      isEditable,
                      [],
                      handleChange,
                    )}
                    {createFields(
                      [
                        {
                          key: `strengths`,
                          label: `Strengths`,
                          value: promotion.strengths,
                          type: TEXTAREA,
                          noCapitalize: true,
                          labelSize: 8,
                          valueSize: 12,
                          className: 'break-spaces',
                        },
                        {
                          key: `areasOfImprovement`,
                          label: `Areas Of Improvements`,
                          value: promotion.areasOfImprovement,
                          type: TEXTAREA,
                          noCapitalize: true,
                          labelSize: 8,
                          valueSize: 12,
                          className: 'break-spaces',
                        },
                        {
                          key: `newGrade`,
                          label: 'New Grade',
                          value: promotion.newGrade,
                          options: grads,
                          type: SELECT,
                          isAllowClear,
                          labelSize: 8,
                          valueSize: 12,
                        },
                        {
                          key: `designationAfterPromotion`,
                          label: 'New Designation',
                          value: getValueForKeyinJSON(
                            designations,
                            promotion.designationAfterPromotion,
                            'id',
                            'name',
                          ),
                          options: designations,
                          type: SELECT,
                          isAllowClear,
                          labelSize: 8,
                          valueSize: 12,
                        },
                      ],
                      2,
                      isEditable,
                      [],
                      handleChange,
                    )}
                    {createFields(
                      [
                        {
                          key: `promotionJustification`,
                          label: `Promotion Justification`,
                          value: promotion.promotionJustification,
                          type: TEXTAREA,
                          noCapitalize: true,
                          labelSize: 4,
                          valueSize: 18,
                          className: 'break-spaces',
                        },
                      ],
                      1,
                      isEditable,
                      [],
                      handleChange,
                    )}
                    {createFields(
                      [
                        {
                          key: `otherComments`,
                          label: `Others Comments`,
                          value: promotion.otherComments,
                          type: TEXTAREA,
                          noCapitalize: true,
                          labelSize: 4,
                          valueSize: 18,
                          className: 'break-spaces',
                        },
                      ],
                      1,
                      isEditable,
                      [],
                      handleChange,
                    )}
                  </div>
                </EnhancedCard>
              </EnhancedCol>
            </EnhancedRow>
          </React.Fragment>
        </EnhancedCard>
        <EnhancedCard>
          <React.Fragment>
            <h2>Redesignation</h2>
            <EnhancedRow gutter={ROW_GUTTER_SIZE}>
              <EnhancedCol span={FULL_GRID_SIZE}>
                <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
                  loading={false}
                  bordered={false}
                  className="card-pd-0"
                >
                  <div>
                    {createFields(
                      [
                        {
                          key: `isRedesignation`,
                          label: 'Is Redesignation?',
                          value: promotion.isRedesignation,
                          options: [
                            { key: true, value: 'yes' },
                            { key: false, value: 'no' },
                          ],
                          type: RADIO,
                          labelSize: 8,
                          valueSize: 12,
                        },
                        {
                          key: `designationAfterRedesignation`,
                          label: 'New Designation',
                          value: getValueForKeyinJSON(
                            designations,
                            promotion.designationAfterRedesignation,
                            'id',
                            'name',
                          ),
                          options: designations,
                          type: SELECT,
                          isAllowClear,
                          labelSize: 8,
                          valueSize: 12,
                        },
                      ],
                      2,
                      isEditable,
                      [],
                      handleChange,
                    )}
                  </div>
                </EnhancedCard>
              </EnhancedCol>
            </EnhancedRow>
          </React.Fragment>
        </EnhancedCard>
        <EnhancedCard>
          <React.Fragment>
            <h2>Salary</h2>
            <EnhancedRow gutter={ROW_GUTTER_SIZE}>
              <EnhancedCol span={FULL_GRID_SIZE}>
                <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
                  loading={false}
                  bordered={false}
                  className="card-pd-0"
                >
                  <div>
                    {createFields(
                      [
                        {
                          key: `recommendedSalary`,
                          label: 'Salary',
                          value: promotion.recommendedSalary,
                          type: INPUT,
                          labelSize: 4,
                          valueSize: 20,
                          className: 'break-spaces',
                        },
                      ],
                      1,
                      isEditable,
                      [],
                      handleChange,
                    )}
                  </div>
                </EnhancedCard>
              </EnhancedCol>
            </EnhancedRow>
          </React.Fragment>
        </EnhancedCard>
      </EnhancedForm>
    </div>
  );
};
export default PromotionStep;
