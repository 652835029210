import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import {
  getEmployeesInit,
  getEmployeesSuccess,
  getEmployeesFailure,
  getEmployeesSkillsInit,
  getEmployeesSkillsSuccess,
  getEmployeesSkillsFailure,
  updateEmployeesSkillsInit,
  markEmployeeInactiveInit,
  updateEmployeesSkillsSuccess,
  updateEmployeesSkillsFailure,
  deleteEmployeeFailure,
  getEmployeeDepartmentsInit,
  getEmployeeDepartmentsSuccess,
  getEmployeeDepartmentsFailure,
  getEmployeeDivisionsInit,
  getEmployeeDivisionsSuccess,
  getEmployeeDivisionsFailure,
  getEmployeeBusinessUnitsInit,
  getEmployeeBusinessUnitsSuccess,
  getEmployeeBusinessUnitsFailure,
  getEmployeeCompetenciesInit,
  getEmployeeCompetenciesSuccess,
  getEmployeeCompetenciesFailure,
  getEmployeesProjectInit,
  getEmployeesProjectSuccess,
  getEmployeesProjectFailure,
  deleteEmployeesProjectInit,
  deleteEmployeesProjectSuccess,
  deleteEmployeesProjectFailure,
  updateEmployeesProjectInit,
  updateEmployeesProjectSuccess,
  updateEmployeesProjectFailure,
  getEmployeesProjectRolesInit,
  getEmployeesProjectRolesSuccess,
  getEmployeesProjectRolesFailure,
  addEmployeeBirthdaySuccess,
  deleteEmployeeSuccess,
  addSkillInit,
  addSkillSuccess,
  addSkillFailure,
  editSkillSuccess,
  deleteSkillSuccess,
  updateEmployeeWish,
  addDepartmentInit,
  addDepartmentSuccess,
  addDepartmentFailure,
  editDepartmentSuccess,
  deleteDepartmentSuccess,
  getAllEmployeesInit,
  getAllEmployeesSuccess,
  getAllEmployeesFailure,
  addDivisionInit,
  addDivisionSuccess,
  addDivisionFailure,
  editDivisionSuccess,
  deleteDivisionSuccess,
  addBusinessUnitInit,
  addBusinessUnitSuccess,
  addBusinessUnitFailure,
  editBusinessUnitSuccess,
  deleteBusinessUnitSuccess,
  addCompetencyInit,
  addCompetencySuccess,
  addCompetencyFailure,
  editCompetencySuccess,
  deleteCompetencySuccess,
  getAllReporteesSuccess,
  updateEmployeesBulkInfoInit,
  updateEmployeesBulkInfoSuccess,
  updateEmployeesBulkInfoFailure,
} from 'store/actions/EmployeeActions';
import { getResponseError } from 'utils/sourceUtils';

import {
  getResourcesInit,
  getResourcesSuccess,
  getResourcesFailure,
  getResourceDetailInit,
  getResourceDetailSuccess,
  getResourceDetailFailure,
} from 'store/actions/ResourceActions';

import {
  deleteEmployeeFromProject,
  getProjectDetailsActions,
} from 'store/actions/ProjectEmployeesActions';
import { getProjectActivityLogsAction } from 'store/actions/ProjectActivityLogActions';
import { showLoader, hideLoader } from 'store/actions/AppLoaderActions';
import {
  employeesUrls,
  skillsUrls,
  employeesProjectUrls,
  employeesProjectRolesUrls,
  rcaUrls,
  departmentUrls,
  employeesBirthdayUrls,
  divisionUrls,
  businessUnitUrls,
  competencyUrls,
  reporteesUrls,
  uploadIncrementsBulkUrl,
} from 'configs/constants';
import {
  normalizeGetEmployeesResponse,
  normalizeGetAllEmployessResponse,
  normalizeGetEmployeesSkillsResponse,
  normalizeGetEmployeesProjectResponse,
  normalizeGetEmployeesBirthdayResponse,
  convertResignationToUTC,
  convertResignationLocal,
  convertLeavingToUTC,
  convertLeavingLocal,
  normalizeUserAttr,
} from 'normalizers/employeeNormalizers';
import { extractDateFromDatetime } from 'utils/DateUtils';

import {
  getRcaProjectEmployeesInit,
  getRcaProjectEmployeesSuccess,
  getRcaProjectEmployeesFailure,
} from 'store/actions/RcaProjectEmployeesActions';
import { userAttrUrls } from '../configs/constants';
import { generateEmployeeFamilyInfoReport } from '../utils/employeeUtils';

const cache = {};

export const getAllEmployees = () => (dispatch) => {
  const url = employeesUrls.GET_ALL;

  dispatch(getAllEmployeesInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        // parse response through normalizer
        const employeesNormalized = normalizeGetAllEmployessResponse(response.data.data);
        // call success or failure action
        dispatch(getAllEmployeesSuccess(employeesNormalized));
      } else {
        dispatch(getAllEmployeesFailure());
      }
    })
    .catch(() => {
      dispatch(getAllEmployeesFailure());
    });
};

export const getEmployeesFamilyInfo = (status) => (dispatch) => {
  const url = employeesUrls.GET_FAMILY_INFO.replace('{status}', status);
  dispatch(showLoader());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        // call success or failure action

        generateEmployeeFamilyInfoReport(response.data.data);
        dispatch(hideLoader());
      } else {
        dispatch(hideLoader());
      }
    })
    .catch(() => {
      dispatch(hideLoader());
    });
};

export const getEmployees =
  (area = '') =>
  (dispatch) => {
    const url = employeesUrls.GET;

    // if (cache[area]) {
    //   dispatch(getEmployeesSuccess(cache[area]));
    //   return;
    // }

    const options = {
      params: { area },
    };
    dispatch(getEmployeesInit());
    axios
      .get(url, options)
      .then((response) => {
        if (response.data.success) {
          // parse response through normalizer
          const employeesNormalized = normalizeGetEmployeesResponse(response.data.data);
          // call success or failure action
          cache[area] = employeesNormalized;
          dispatch(getEmployeesSuccess(employeesNormalized));
        } else {
          dispatch(getEmployeesFailure());
        }
      })
      .catch(() => {
        dispatch(getEmployeesFailure());
      });
  };

export const getSkills = () => (dispatch) => {
  const url = skillsUrls.GET;
  dispatch(getEmployeesSkillsInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        // parse response through normalizer
        const skillsNormalized = normalizeGetEmployeesSkillsResponse(response.data.data);
        // call success or failure action
        cache.skills = skillsNormalized;
        dispatch(getEmployeesSkillsSuccess(skillsNormalized));
      } else {
        dispatch(getEmployeesSkillsFailure());
      }
    })
    .catch(() => {
      dispatch(getEmployeesSkillsFailure());
    });
};

export const postSkill = (request) => (dispatch) => {
  const url = skillsUrls.POST;
  dispatch(addSkillInit());
  axios
    .post(url, request)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Created Successfully',
        });
        dispatch(addSkillSuccess(response.data));
      } else {
        EnhancedNotification.error({
          message: 'Error in creating a new skill',
        });
        dispatch(addSkillFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in creating a new skill.',
      });
      dispatch(addSkillFailure());
    });
};

export const editSkill = (request) => (dispatch) => {
  const url = skillsUrls.PUT;
  dispatch(addSkillInit());
  axios
    .put(url, request)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Updated Successfully',
        });
        dispatch(editSkillSuccess(request));
      } else {
        EnhancedNotification.error({
          message: 'Error in editing the skill',
        });
        dispatch(addSkillFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in editing the skill.',
      });
      dispatch(addSkillFailure());
    });
};

export const deleteSkill = (request) => (dispatch) => {
  const url = skillsUrls.DELETE;
  dispatch(addSkillInit());
  axios
    .delete(url, { data: request })
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Deleted Successfully',
        });
        dispatch(deleteSkillSuccess(request.id));
      } else {
        EnhancedNotification.error({
          message: 'Cannot delete because the skill is possessed by some employees',
        });
        dispatch(addSkillFailure());
      }
    })
    .catch((e) => {
      EnhancedNotification.error({
        message: e.response.data.message,
      });
      dispatch(addSkillFailure());
    });
};

export const getDepartments = () => (dispatch) => {
  const url = departmentUrls.GET;
  dispatch(getEmployeeDepartmentsInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        cache.departments = response.data.data;
        dispatch(getEmployeeDepartmentsSuccess(response.data.data));
      } else {
        dispatch(getEmployeeDepartmentsFailure());
      }
    })
    .catch(() => {
      dispatch(getEmployeeDepartmentsFailure());
    });
};

export const postDepartment = (request) => (dispatch) => {
  const url = departmentUrls.POST;
  dispatch(addDepartmentInit());
  axios
    .post(url, request)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Created Successfully',
        });
        dispatch(addDepartmentSuccess(response.data));
      } else {
        EnhancedNotification.error({
          message: 'Error in creating a new competency',
        });
        dispatch(addDepartmentFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Entered competency already exists. Cannot add it again',
      });
      dispatch(addDepartmentFailure());
    });
};

export const editDepartment = (request) => (dispatch) => {
  const url = departmentUrls.PUT.replace('{departmentId}', request.id);
  dispatch(addDepartmentInit());
  axios
    .put(url, request)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Updated Successfully',
        });
        dispatch(editDepartmentSuccess(request));
      } else {
        EnhancedNotification.error({
          message: 'Error in editing the competency',
        });
        dispatch(addDepartmentFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Entered competency already exists. Cannot add it again',
      });
      dispatch(addDepartmentFailure());
    });
};

export const deleteDepartment = (request) => (dispatch) => {
  const url = departmentUrls.DELETE.replace('{departmentId}', request.id);
  dispatch(addDepartmentInit());
  axios
    .delete(url, { data: request })
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Deleted Successfully',
        });
        dispatch(deleteDepartmentSuccess(request.id));
      } else {
        EnhancedNotification.error({
          message: 'Cannot delete because the competency is associated with employees',
        });
        dispatch(addDepartmentFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Cannot delete because the competency is associated with employees',
      });
      dispatch(addDepartmentFailure());
    });
};

export const getBusinessUnits = () => (dispatch) => {
  const url = businessUnitUrls.GET;
  dispatch(getEmployeeBusinessUnitsInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        cache.businessUnit = response.data.data;
        dispatch(getEmployeeBusinessUnitsSuccess(response.data.data));
      } else {
        dispatch(getEmployeeBusinessUnitsFailure());
      }
    })
    .catch(() => {
      dispatch(getEmployeeBusinessUnitsFailure());
    });
};

export const postBusinessUnit = (request) => (dispatch) => {
  const url = businessUnitUrls.POST;
  dispatch(addBusinessUnitInit());
  axios
    .post(url, request)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Created Successfully',
        });
        dispatch(addBusinessUnitSuccess(response.data));
      } else {
        EnhancedNotification.error({
          message: 'Error in creating a new Business Unit',
        });
        dispatch(addBusinessUnitFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Entered Business Unit already exists. Cannot add it again',
      });
      dispatch(addBusinessUnitFailure());
    });
};

export const editBusinessUnit = (request) => (dispatch) => {
  const url = businessUnitUrls.PUT.replace('{businessUnitId}', request.id);
  dispatch(addBusinessUnitInit());
  axios
    .put(url, request)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Updated Successfully',
        });
        dispatch(editBusinessUnitSuccess(request));
      } else {
        EnhancedNotification.error({
          message: 'Error in editing the Business Unit',
        });
        dispatch(addBusinessUnitFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Entered Business Unit already exists. Cannot add it again',
      });
      dispatch(addBusinessUnitFailure());
    });
};

export const deleteBusinessUnit = (request) => (dispatch) => {
  const url = businessUnitUrls.DELETE.replace('{businessUnitId}', request.id);
  dispatch(addBusinessUnitInit());
  axios
    .delete(url, { data: request })
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Deleted Successfully',
        });
        dispatch(deleteBusinessUnitSuccess(request.id));
      } else {
        EnhancedNotification.error({
          message: 'Cannot delete because the Business Unit is associated with employees',
        });
        dispatch(addBusinessUnitFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Cannot delete because the Business Unit is associated with employees',
      });
      dispatch(addBusinessUnitFailure());
    });
};

export const getDivisions = () => (dispatch) => {
  const url = divisionUrls.GET;
  dispatch(getEmployeeDivisionsInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        cache.divisions = response.data.data;
        dispatch(getEmployeeDivisionsSuccess(response.data.data));
      } else {
        dispatch(getEmployeeDivisionsFailure());
      }
    })
    .catch(() => {
      dispatch(getEmployeeDivisionsFailure());
    });
};

export const postDivision = (request) => (dispatch) => {
  const url = divisionUrls.POST;
  dispatch(addDivisionInit());
  axios
    .post(url, request)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Created Successfully',
        });
        dispatch(addDivisionSuccess(response.data));
      } else {
        EnhancedNotification.error({
          message: 'Error in creating a new division',
        });
        dispatch(addDivisionFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Entered division already exists. Cannot add it again',
      });
      dispatch(addDivisionFailure());
    });
};

export const editDivision = (request) => (dispatch) => {
  const url = divisionUrls.PUT.replace('{divisionId}', request.id);
  dispatch(addDivisionInit());
  axios
    .put(url, request)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Updated Successfully',
        });
        dispatch(editDivisionSuccess(request));
      } else {
        EnhancedNotification.error({
          message: 'Error in editing the division',
        });
        dispatch(addDivisionFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Entered division already exists. Cannot add it again',
      });
      dispatch(addDivisionFailure());
    });
};

export const deleteDivision = (request) => (dispatch) => {
  const url = divisionUrls.DELETE.replace('{divisionId}', request.id);
  dispatch(addDivisionInit());
  axios
    .delete(url, { data: request })
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Deleted Successfully',
        });
        dispatch(deleteDivisionSuccess(request.id));
      } else {
        EnhancedNotification.error({
          message: 'Cannot delete because the division is associated with employees',
        });
        dispatch(addDivisionFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Cannot delete because the division is associated with employees',
      });
      dispatch(addDivisionFailure());
    });
};

export const getCompetencies = () => (dispatch) => {
  const url = competencyUrls.GET;
  dispatch(getEmployeeCompetenciesInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        cache.competencies = response.data.data;
        dispatch(getEmployeeCompetenciesSuccess(response.data.data));
      } else {
        dispatch(getEmployeeCompetenciesFailure());
      }
    })
    .catch(() => {
      dispatch(getEmployeeCompetenciesFailure());
    });
};

export const postCompetency = (request) => (dispatch) => {
  const url = competencyUrls.POST;
  dispatch(addCompetencyInit());
  axios
    .post(url, request)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Created Successfully',
        });
        dispatch(addCompetencySuccess(response.data));
      } else {
        EnhancedNotification.error({
          message: 'Error in creating a new division',
        });
        dispatch(addCompetencyFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Entered competency already exists. Cannot add it again',
      });
      dispatch(addCompetencyFailure());
    });
};

export const editCompetency = (request) => (dispatch) => {
  const url = competencyUrls.PUT.replace('{competencyId}', request.id);
  dispatch(addCompetencyInit());
  axios
    .put(url, request)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Updated Successfully',
        });
        dispatch(editCompetencySuccess(request));
      } else {
        EnhancedNotification.error({
          message: 'Error in editing the division',
        });
        dispatch(addCompetencyFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Entered competency already exists. Cannot add it again',
      });
      dispatch(addCompetencyFailure());
    });
};

export const deleteCompetency = (request) => (dispatch) => {
  const url = competencyUrls.DELETE.replace('{competencyId}', request.id);
  dispatch(addCompetencyInit());
  axios
    .delete(url, { data: request })
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Deleted Successfully',
        });
        dispatch(deleteCompetencySuccess(request.id));
      } else {
        EnhancedNotification.error({
          message: 'Cannot delete because the division is associated with employees',
        });
        dispatch(addCompetencyFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Cannot delete because the division is associated with employees',
      });
      dispatch(addCompetencyFailure());
    });
};

export const updateEmployeeSkills = (employee) => (dispatch) => {
  const url = employeesUrls.PUT;
  dispatch(updateEmployeesSkillsInit());
  const {
    resignationDate = null,
    leavingDate = null,
    graduationDate = null,
    careerStartDate = null,
  } = employee;
  const newResignationDate = convertResignationToUTC(resignationDate);
  const newLeavingDate = convertLeavingToUTC(leavingDate);
  const newGraduationDate = extractDateFromDatetime(graduationDate);
  const newCareerStartDate = extractDateFromDatetime(careerStartDate);
  const payload = {
    ...employee,
    resignationDate: newResignationDate,
    graduationDate: newGraduationDate,
    leavingDate: newLeavingDate,
    careerStartDate: newCareerStartDate,
  };
  axios
    .put(url, payload)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({ message: 'Employee updated successfully!' });
        const { employee_skills } = response.data.data;
        const skillsNormalized = normalizeGetEmployeesSkillsResponse(employee_skills);
        response.data.data.employee_skills = skillsNormalized;
        response.data.data.resignationDate = convertResignationLocal(
          response.data.data.resignationDate,
        );
        response.data.data.leavingDate = convertLeavingLocal(response.data.data.leavingDate);
        response.data.data.totalExperience = response.data.data.experience.totalInWords;
        dispatch(updateEmployeesSkillsSuccess(response.data));
      } else {
        dispatch(updateEmployeesSkillsFailure(response.data));
      }
    })
    .catch((e) => {
      dispatch(updateEmployeesSkillsFailure(e));
      EnhancedNotification.error({
        message: e.response.data.message || 'Error in editing the employee details.',
      });
    });
};

export const markEmployeeInactive = (employee) => (dispatch) => {
  const url = employeesUrls.DELETE.replace('{employeeId}', employee.userId);
  dispatch(markEmployeeInactiveInit());
  axios
    .delete(url)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({ message: 'Employee deleted successfully!' });
        dispatch(deleteEmployeeSuccess(employee.userId));
      } else {
        dispatch(deleteEmployeeFailure(response.data));
      }
    })
    .catch((e) => {
      EnhancedNotification.error({
        message: e.response.data.message || 'Updating Resource failed.',
      });
      dispatch(deleteEmployeeFailure(e));
    });
};

export const getEmployeesProject = (userId) => (dispatch) => {
  const url = employeesProjectUrls.GET.replace('{employeeId}', userId);

  dispatch(getEmployeesProjectInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        // parse response through normalizer
        const employeesProjectNormalized = normalizeGetEmployeesProjectResponse(response.data.data);
        // call success or failure action
        // cache[area] = employeesProjectNormalized;
        dispatch(getEmployeesProjectSuccess(employeesProjectNormalized));
      } else {
        dispatch(getEmployeesProjectFailure());
      }
    })
    .catch(() => {
      dispatch(getEmployeesProjectFailure());
    });
};

export const deallocateEmployee =
  (
    employeeProjectId,
    employeeId,
    projectId = null,
    comment = null,
    endDate = null,
    deleteEntry = false,
  ) =>
  (dispatch) => {
    let url = employeesProjectUrls.DEALLOCATE;
    if (deleteEntry) url = employeesProjectUrls.DELETE;
    // on employee de-allocate also set extendedEndDate
    const data = { comment, endDate, extendedEndDate: endDate };
    const payload = { data };

    dispatch(deleteEmployeesProjectInit());
    axios
      .delete(
        url.replace('{employeeProjectId}', employeeProjectId).replace('{employeeId}', employeeId),
        payload,
      )
      .then((response) => {
        if (response.data.success) {
          // call success or failure action
          const operationName = deleteEntry ? 'removed' : 'deallocated';
          EnhancedNotification.success({ message: `Resource ${operationName} successfully.` });
          dispatch(deleteEmployeesProjectSuccess({ employeeProjectId, endDate, deleteEntry }));
          dispatch(deleteEmployeeFromProject(employeeProjectId));
          dispatch(getProjectDetailsActions(projectId));
          dispatch(getProjectActivityLogsAction(projectId, 1));
        } else {
          const operationName = deleteEntry ? 'Removing' : 'Deallocating';
          EnhancedNotification.error({ message: `${operationName} resource failed.` });
          dispatch(deleteEmployeesProjectFailure());
        }
      })
      .catch(() => {
        const operationName = deleteEntry ? 'Removing' : 'Deallocating';
        EnhancedNotification.error({ message: `${operationName} resource failed.` });
        dispatch(deleteEmployeesProjectFailure());
      });
  };

export const updateEmployeesProject = (employeeProjectInfo, currentInformation) => (dispatch) => {
  const url = employeesProjectUrls.PUT;
  dispatch(updateEmployeesProjectInit());
  axios
    .put(url, currentInformation)
    .then((response) => {
      if (response.data.success) {
        // call success or failure action
        if (currentInformation.existing === undefined) {
          EnhancedNotification.success({ message: 'Resource Updated Successfully.' });
        }
        dispatch(updateEmployeesProjectSuccess(employeeProjectInfo, currentInformation));
        dispatch(getProjectActivityLogsAction(currentInformation.projectId, 1));
        dispatch(getProjectDetailsActions(currentInformation.projectId));
      } else {
        EnhancedNotification.error({
          message: response.data.message || 'Updating Resource failed.',
        });
        dispatch(updateEmployeesProjectFailure(employeeProjectInfo, currentInformation));
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: error.response.data.message || 'Updating Resource failed.',
      });
      dispatch(updateEmployeesProjectFailure());
    });
};

export const getEmployeesProjectRoles = () => (dispatch) => {
  const url = employeesProjectRolesUrls.GET;

  dispatch(getEmployeesProjectRolesInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        dispatch(getEmployeesProjectRolesSuccess(response.data.data));
      } else {
        dispatch(getEmployeesProjectRolesFailure());
      }
    })
    .catch(() => {
      dispatch(getEmployeesProjectRolesFailure());
    });
};

export const getResourcesWithAllocation = (startDate, endDate) => (dispatch) => {
  const dateFilter = `?startDate=${startDate}&endDate=${endDate}`;

  const url = rcaUrls.EMPLOYEES.SUMMARY.concat(dateFilter);

  dispatch(getResourcesInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        dispatch(getResourcesSuccess(response.data.data));
      } else {
        dispatch(getResourcesFailure());
      }
    })
    .catch(() => {
      dispatch(getResourcesFailure());
    });
};

export const getResourcesDetailWithAllocation =
  (userId, employeeId, startDate, endDate) => (dispatch) => {
    const dateFilter = `?startDate=${startDate}&endDate=${endDate}`;
    const url = rcaUrls.EMPLOYEES.DETAILS.replace('{employeeId}', employeeId).concat(dateFilter);
    dispatch(showLoader());
    dispatch(getResourceDetailInit());
    axios
      .get(url)
      .then((response) => {
        dispatch(hideLoader());
        if (response.data.success) {
          dispatch(getResourceDetailSuccess(response.data.data));
        } else {
          dispatch(getResourceDetailFailure());
        }
      })
      .catch(() => {
        dispatch(hideLoader());
        dispatch(getResourceDetailFailure());
      });
  };

export const getAllocationGroupedProjectResources =
  (userId, projectId, startDate, endDate) => (dispatch) => {
    const dateFilter = `?startDate=${startDate}&endDate=${endDate}`;

    const url = rcaUrls.PROJECTS.DETAILS.replace('{projectId}', projectId).concat(dateFilter);
    dispatch(showLoader());

    dispatch(getRcaProjectEmployeesInit());
    axios
      .get(url)
      .then((response) => {
        dispatch(hideLoader());
        if (response.data.success) {
          dispatch(getRcaProjectEmployeesSuccess(response.data.data));
        } else {
          dispatch(getRcaProjectEmployeesFailure());
        }
      })
      .catch(() => {
        dispatch(hideLoader());
        dispatch(getRcaProjectEmployeesFailure());
      });
  };

export const wishEmployee = (id, data) => (dispatch) => {
  const url = employeesBirthdayUrls.PUT.replace('{employeeId}', id);
  axios.put(url, data).then((response) => {
    if (response.data.success) {
      dispatch(updateEmployeeWish(id));
    } else {
      // handle error
      dispatch(getEmployeesFailure());
      // console.log('wishEmployee--ERROR', response);
    }
  });
};

export const getEmployeesBirthday = () => (dispatch) => {
  const url = employeesBirthdayUrls.GET;

  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        // parse response through normalizer
        const employeesNormalized = normalizeGetEmployeesBirthdayResponse(response.data.data);
        // call success or failure action
        dispatch(addEmployeeBirthdaySuccess(employeesNormalized));
      } else {
        dispatch(getEmployeesFailure());
      }
    })
    .catch(() => {
      dispatch(getEmployeesFailure());
    });
};

export const getUserAttributes = (employeeId, module = '') =>
  new Promise((resolve, reject) => {
    let url = userAttrUrls.GET.replace('{employeeId}', employeeId);
    if (module) {
      url += `?module=${module}`;
    }
    axios
      .get(url)
      .then((response) => {
        if (response.data.success) {
          const userAttrNormalizer = normalizeUserAttr(response.data.data);
          resolve(userAttrNormalizer);
        } else {
          EnhancedNotification.error({
            message: getResponseError(response.message),
          });
          reject(response.message);
        }
      })
      .catch((err) => {
        EnhancedNotification.error({
          message: getResponseError(err),
        });
        reject(err);
      });
  });

export const getAllReportees = () => (dispatch) => {
  const url = reporteesUrls.GET_ALL_REPORTEES;
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        dispatch(getAllReporteesSuccess(response.data.data));
      } else {
        dispatch(getEmployeesFailure());
      }
    })
    .catch(() => {
      dispatch(getEmployeesFailure());
    });
};

export const updateEmployeesBulkInfo = (payload) => (dispatch) => {
  dispatch(updateEmployeesBulkInfoInit());
  dispatch(showLoader());
  const url = uploadIncrementsBulkUrl.PUT;
  axios
    .put(url, payload)
    .then((response) => {
      dispatch(hideLoader());
      if (response.status === 200) {
        EnhancedNotification.success({ message: 'Bulk updated successfully' });
        if (payload) {
          dispatch(updateEmployeesBulkInfoSuccess(payload));
        } else {
          dispatch(updateEmployeesBulkInfoInit());
        }
      } else {
        EnhancedNotification.error({ message: response.data.data.message });
        dispatch(updateEmployeesBulkInfoFailure());
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      EnhancedNotification.error({ message: getResponseError(error) });
      dispatch(updateEmployeesBulkInfoFailure());
    });
};
