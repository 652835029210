/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import { getResponseError } from 'utils/sourceUtils';
import { normalizeTeamLeaveBalance } from 'normalizers/leaveDashboardNormalizers';
import { leaveDashboardUrls } from 'configs/constants';
import { leaveTypeCategories } from '../../configs/leaveConstants';

export const getTeamLeaveBalance = (attributeId, parameters) =>
  new Promise((resolve, reject) => {
    const url = leaveDashboardUrls.GET.replace('{attributeId}', attributeId);
    const params = { ...parameters, leaveTypeCategory: leaveTypeCategories.PRIMARY };
    axios
      .get(url, {
        params,
      })
      .then((response) => {
        if (response && response.data.success) {
          const {
            data: { content, pageInfo },
          } = response.data;
          const leaveData = normalizeTeamLeaveBalance(content);
          resolve({ leaveData, pageInfo });
        } else {
          EnhancedNotification.error({
            message: getResponseError(response.message),
          });
          reject(response.message);
        }
      })
      .catch((err) => {
        EnhancedNotification.error({
          message: getResponseError(err),
        });
        reject(err);
      });
  });
