import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';

const EnhancedInput = (props) => {
  const {
    children,
    className,
    style,
    placeholder,
    // value,
    onChange,
    id,
    name,
    allowClear,
    bordered,
    disabled,
    ...rest
  } = props;

  return (
    <Input
      style={style}
      className={className}
      placeholder={placeholder}
      // value={value}
      onChange={onChange}
      id={id}
      name={name}
      allowClear={allowClear}
      bordered={bordered}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Input>
  );
};

EnhancedInput.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  placeholder: PropTypes.string,
  // value: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  allowClear: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  bordered: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.bool,
};

EnhancedInput.defaultProps = {
  className: '',
  style: {},
  placeholder: '',
  // value: '',
  name: '',
  onChange: () => {},
  id: '',
  allowClear: false,
  bordered: true,
  disabled: false,
};

EnhancedInput.TextArea = Input.TextArea;
EnhancedInput.Search = Input.Search;
EnhancedInput.Password = Input.Password;

export default EnhancedInput;
