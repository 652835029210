import {
  MY_LEAVES_GET,
  MY_LEAVES_GET_SUCCESS,
  MY_LEAVES_GET_FAIL,
  GET_LEAVES_DETAILS_INIT,
  GET_LEAVES_DETAILS_SUCCESS,
  GET_LEAVES_DETAILS_FAIL,
  REQUEST_LEAVE,
  REQUEST_LEAVE_SUCCESS,
  REQUEST_LEAVE_FAIL,
  REQUEST_EDIT_LEAVE,
  REQUEST_EDIT_LEAVE_SUCCESS,
  REQUEST_EDIT_LEAVE_FAIL,
  CANCEL_APPROVED_LEAVE_INIT,
  CANCEL_APPROVED_LEAVE_SUCCESS,
  CANCEL_APPROVED_LEAVE_FAIL,
  GET_TOTAL_LEAVES_COUNT_INIT,
  GET_TOTAL_LEAVES_COUNT_SUCCESS,
  GET_TOTAL_LEAVES_COUNT_FAILURE,
} from 'constants/MyLeavesTypes';

const initialState = {
  leaveRequests: [],
  error: '',
  loader: false,
  requestLoader: false,
  postSuccess: false,
  quota: {
    currentBalance: {},
    quotaLoader: false,
  },
  pageInfo: {},
  cancelled: false,
  workingDays: 0,
};

const myLeaves = (state = initialState, action) => {
  switch (action.type) {
    case MY_LEAVES_GET:
      return {
        ...state,
        loader: true,
      };
    case MY_LEAVES_GET_SUCCESS: {
      const {
        data: { leaveRequests, pageInfo },
      } = action;
      return {
        ...state,
        loader: false,
        leaveRequests,
        pageInfo,
      };
    }
    case MY_LEAVES_GET_FAIL:
      return {
        ...state,
        loader: false,
      };
    case GET_LEAVES_DETAILS_INIT:
      return {
        ...state,
        quota: {
          currentBalance: {},
          quotaLoader: true,
        },
      };
    case GET_LEAVES_DETAILS_SUCCESS: {
      const {
        data: { currentBalance },
      } = action;
      return {
        ...state,
        quota: {
          currentBalance,
          quotaLoader: false,
        },
      };
    }
    case GET_LEAVES_DETAILS_FAIL:
      return {
        ...state,
        quota: {
          ...state.quota.currentBalance,
          quotaLoader: false,
        },
      };
    case REQUEST_LEAVE:
    case REQUEST_EDIT_LEAVE:
      return {
        ...state,
        requestLoader: true,
        postSuccess: false,
      };
    case REQUEST_LEAVE_SUCCESS:
    case REQUEST_EDIT_LEAVE_SUCCESS:
      return {
        ...state,
        loader: false,
        requestLoader: false,
        postSuccess: true,
      };
    case REQUEST_LEAVE_FAIL:
    case REQUEST_EDIT_LEAVE_FAIL:
      return {
        ...state,
        loader: false,
        requestLoader: false,
        postSuccess: false,
      };
    case CANCEL_APPROVED_LEAVE_INIT:
      return {
        ...state,
        loader: true,
        cancelled: false,
      };
    case CANCEL_APPROVED_LEAVE_SUCCESS: {
      return {
        ...state,
        loader: false,
        cancelled: true,
      };
    }
    case CANCEL_APPROVED_LEAVE_FAIL:
      return {
        ...state,
        loader: false,
        cancelled: false,
      };
    case GET_TOTAL_LEAVES_COUNT_INIT:
      return {
        ...state,
        loader: true,
        workingDays: 0,
      };
    case GET_TOTAL_LEAVES_COUNT_SUCCESS: {
      const {
        data: { workingDays },
      } = action;
      return {
        ...state,
        loader: false,
        workingDays,
      };
    }
    case GET_TOTAL_LEAVES_COUNT_FAILURE:
      return {
        ...state,
        loader: false,
      };
    default:
      return state;
  }
};
export default myLeaves;
