import { EnhancedRow, EnhancedCol, EnhancedDivider, EnhancedList } from 'components/shared/antd';
import React, { Fragment } from 'react';
// import moment from 'moment';
import { List } from 'antd';
import htmlToText from 'html-to-text';
import './EventsPlaceholder.css';
import SVGProvider from '../../utils/SVGProvider';
import EventsModal from '../../modals/EventsModal';
/* eslint-disable no-param-reassign */
const { Item } = List;
const { Meta } = Item;

class EventsPlaceholder extends React.Component {
  state = {
    showEventsModal: false,
  };

  href = '#';

  toggleModal = (eventID) => {
    this.setState({
      showEventsModal: true,
      eventDataID: eventID,
    });
  };

  render() {
    const { title, data, icon } = this.props;
    return (
      <Fragment>
        <EnhancedRow className="list-header">
          <EnhancedCol lg={{ span: 14 }} xs={{ span: 15 }}>
            <div className="list-title">{title}</div>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow className="list-container homepageEvents">
          <EnhancedList
            className="list"
            dataSource={data}
            locale={{ emptyText: 'No Upcoming Events' }}
            renderItem={(item, listIndex) => (
              <div className="list-element" key={listIndex}>
                <Item>
                  <Meta
                    avatar={<SVGProvider date={item.date} month={item.month} today={item.today} />}
                    /* eslint-disable */
                    title={
                      <p
                        className="list-element-title pointer-enabled"
                        href={this.href}
                        onClick={() => this.toggleModal(item.id)}
                      >
                        {item.title}
                      </p>
                    }
                    /* eslint-enabled */
                    description={
                      <p className="list-element-description margin-top set-font-size">
                        {item.description.length > 35
                          ? `${htmlToText.fromString(item.description.substr(0, 45))}...`
                          : htmlToText.fromString(item.description)}
                      </p>
                    }
                  />
                  {icon}
                </Item>
                {listIndex < data.length - 1 && (
                  <EnhancedDivider className="remove-divider-margin" />
                )}
              </div>
            )}
          />
        </EnhancedRow>
        <EventsModal
          data={data}
          eventDataID={this.state.eventDataID}
          visible={this.state.showEventsModal}
        />
      </Fragment>
    );
  }
}

export default EventsPlaceholder;
