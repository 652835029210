import React from 'react';
// import Permissions from 'constants/AccessPermissions';
// import AuthorizedTabs from 'components/shared/AuthorizedTabs';
import EmployeeInfo from 'components/employeeProfile/employeeInfo';
import Tracking from 'components/employeeProfile/tracking';
import EmployeeProfileHeader from 'components/employeeProfile/header';
import Compensation from 'components/employeeProfile/compensation';
import GoalsReviews from 'components/employeeProfile/goalsReviews';
import TalentManagement from 'components/employeeProfile/talentManagment';
import EmploymentHistory from 'components/employeeProfile/employmentHistory';
// import EducationHistory from 'components/employeeProfile/educationHistory';
import Documents from 'components/employeeProfile/documents';
import CommentsIncidents from 'components/employeeProfile/commentsIncidents';
import HistoryLog from 'components/employeeProfile/historyLog';
import { isEmptyObject } from 'utils/objectUtils';
import { BREADCRUMBS } from 'configs/constants';
import { ERROR_403 } from 'configs/errorConstants';
import Permissions from 'constants/AccessPermissions';
import { hasPermission } from 'utils/AccessUtils';
import { GOAL_STATUS } from 'configs/employeeProfileConstants';
import { EnhancedCard, EnhancedSpin, EnhancedTabs } from 'components/shared/antd';

const { TabPane } = EnhancedTabs;

const tabs = [
  {
    key: 'employee-info',
    title: 'Employee Info',
    component: EmployeeInfo,
    propVar: 'employeeInfo',
  },
  {
    key: 'tracking',
    title: 'Tracking',
    component: Tracking,
    propVar: 'trackingInfo',
  },
  {
    key: 'compensation',
    title: 'Bonus',
    component: Compensation,
    propVar: 'compensation',
  },
  {
    key: 'goals-and-reviews',
    title: 'Goals and Reviews',
    component: GoalsReviews,
    propVar: 'goals',
  },
  {
    key: 'talent-managment',
    title: 'Talent Management',
    component: TalentManagement,
    propVar: 'talentManagement',
  },
  {
    key: 'employment-history',
    title: 'Employment History',
    component: EmploymentHistory,
    propVar: 'employmentHistory',
  },
  {
    key: 'documents',
    title: 'Documents',
    component: Documents,
    propVar: 'documents',
  },
  {
    key: 'commentsIncidents',
    title: 'Comments and Incidents',
    component: CommentsIncidents,
    propVar: 'comments',
  },
  {
    key: 'historyLog',
    title: 'History Log',
    component: HistoryLog,
    propVar: 'auditLogs',
  },
];

class EmployeeProfile extends React.Component {
  componentWillMount() {
    const employeeId = this.props.match.params.id;
    this.props.getEmployeeProfileHeader(employeeId);
    this.props.getEmployeeTrackingInfo(employeeId);
    this.props.getEmployeeInfo(employeeId);
    this.props.getEmployeeTalent(employeeId);
    this.props.getEmployeeCompensation(employeeId);
    this.props.getEmployeeComment(employeeId);
    this.props.getEmployeeDocuments(employeeId);
    this.props.getEmployeeEmploymentHistory(employeeId);
    this.props.getEmployeeHistoryLogs(employeeId);
    this.props.getGoal({
      empId: employeeId,
      status: `${GOAL_STATUS.PENDING_APPROVAL},${GOAL_STATUS.APPROVED}`,
      pageNumber: 0,
      pageSize: 10,
    });

    if (hasPermission(this.props.user.permissions, [Permissions.Employee.All.List])) {
      this.props.getAllEmployees();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.profileHeader !== nextProps.profileHeader) {
      this.updateBreadCrumbs(nextProps.profileHeader, nextProps.match.params.id);
    }
  }

  updateBreadCrumbs = (data, id) => {
    if (!isEmptyObject(data) && id) {
      this.props.updateBreadCrumb(BREADCRUMBS.EMPLOYEE, id, data.fullName);
    }
  };

  isLoading = () => {
    const isLoading = tabs.reduce((acc, tab) => {
      const { [tab.propVar]: data } = this.props;
      return data.loading || acc;
    }, false);
    return isLoading;
  };

  render() {
    const { user } = this.props;
    const employeeId = this.props.match.params.id;
    const profileHeader =
      (!isEmptyObject(this.props.profileHeader) && {
        ...this.props.profileHeader,
      }) ||
      {};

    return (
      <div className="emp-profile">
        <EnhancedSpin spinning={this.isLoading()}>
          <EnhancedCard loading={false} bordered={false}>
            <EmployeeProfileHeader
              profileHeader={profileHeader}
              updateEmployeePicture={this.props.updateEmployeePicture}
              employeeId={employeeId}
              userPermissions={user.permissions}
            />
            <EnhancedTabs>
              {tabs.map((tab) => {
                const Component = tab.component;
                const { [tab.propVar]: data } = this.props;

                if (data.error && data.error.code === ERROR_403) {
                  return null;
                }
                return (
                  <TabPane key={tab.key} tab={tab.title}>
                    <Component {...this.props} userPermissions={user.permissions} />
                  </TabPane>
                );
              })}
            </EnhancedTabs>

            {/* <AuthorizedTabs
            {...this.props}
            tabs={[
              {
                key: 'employee-info',
                title: 'Employee Info',
                component: EmployeeInfo,
                requiredPermissions: [
                  Permissions.Employee.Profile.Info.View,
                ],
              },
              {
                key: 'tracking',
                title: 'Tracking',
                component: Tracking,
                requiredPermissions: [
                  Permissions.Employee.Profile.JobInfo.Tracking.View,
                ],
              },
              {
                key: 'compensation',
                title: 'Compensation',
                component: Compensation,
                requiredPermissions: [
                  Permissions.Employee.Profile.Compensation.View,
                ],
              },
              {
                key: 'goals-and-reviews',
                title: 'Goals and Reviews',
                component: GoalsReviews,
                requiredPermissions: [Permissions.Employee.Profile.View],
              },
              {
                key: 'talent-managment',
                title: 'Talent Management',
                component: TalentManagement,
                requiredPermissions: [
                  Permissions.Employee.Profile.TalentManagement.View,
                  Permissions.Employee.Profile.TalentManagement.CareerPlanning
                    .View,
                  Permissions.Employee.Profile.TalentManagement.Management.View,
                ],
              },
              {
                key: 'employment-history',
                title: 'Employment History',
                component: EmploymentHistory,
                requiredPermissions: [
                  Permissions.Employee.Profile.EmploymentHistory.View,
                ],
              },
              {
                key: 'documents',
                title: 'Documents',
                component: Documents,
                requiredPermissions: [
                  Permissions.Employee.Profile.Documents.View,
                ],
              },
              {
                key: 'commentsIncidents',
                title: 'Comments and Incidents',
                component: CommentsIncidents,
                requiredPermissions: [
                  Permissions.Employee.Profile.Comments.View,
                ],
              },
              {
                key: 'historyLog',
                title: 'History Log',
                component: HistoryLog,
                requiredPermissions: [
                  Permissions.Employee.Profile.HistoryLog.View,
                ],
              },
            ]}
            userPermissions={user.permissions}
          /> */}
          </EnhancedCard>
        </EnhancedSpin>
      </div>
    );
  }
}

export default EmployeeProfile;
