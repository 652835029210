import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LeaveDashboard from 'components/leaves/LeaveDashboard';
import { getTeamLeavesBalanceAction } from 'store/actions/LeaveActions';
import { getUserAttributesAction } from 'store/actions/EmployeeActions';

const mapStateToProps = ({ auth, teamLeaveBalanceReducer, employeeReducer }) => ({
  userId: auth.user && auth.user.id,
  isManager: auth.user && auth.user.isManager,
  teamLeaveBalance: teamLeaveBalanceReducer.teamLeaveBalance,
  pageInfo: teamLeaveBalanceReducer.pageInfo,
  isLoading: teamLeaveBalanceReducer.isLoading,
  userAttributes: employeeReducer.userAttributes,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTeamLeavesBalanceAction,
      getUserAttributesAction,
    },
    dispatch,
  );
}
const LeaveDashboardContainer = connect(mapStateToProps, mapDispatchToProps)(LeaveDashboard);

export default LeaveDashboardContainer;
