import React from 'react';
import { Modal, Button } from 'antd';
import PropTypes from 'prop-types';

const EnhancedModal = (props) => {
  const {
    children,
    className,
    style,
    title,
    visible,
    userId,
    onCancel,
    closable,
    onOk,
    width,
    height,
    bodyStyle,
    okText,
    destroyOnClose,
    afterClose,
    maskClosable,
    okButtonProps,
    'data-testid': testId,
    mask,
    footer,
  } = props;

  const defaultFooter = (
    <div>
      <Button key="back" onClick={onCancel}>
        Cancel
      </Button>
      <Button key="submit" type="primary" onClick={onOk} disabled={okButtonProps.disabled}>
        {okText}
      </Button>
    </div>
  );

  return (
    <Modal
      className={className}
      style={style}
      title={title}
      visible={visible}
      userId={userId}
      onCancel={onCancel}
      closable={closable}
      onOk={onOk}
      width={width}
      height={height}
      bodyStyle={bodyStyle}
      okText={okText}
      destroyOnClose={destroyOnClose}
      afterClose={afterClose}
      maskClosable={maskClosable}
      okButtonProps={okButtonProps}
      data-testid={testId}
      mask={mask}
      footer={footer !== undefined ? footer : defaultFooter}
    >
      {children}
    </Modal>
  );
};

EnhancedModal.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  bodyStyle: PropTypes.shape({}),
  afterClose: PropTypes.func,
  visible: PropTypes.bool.isRequired, // changed to open
  title: PropTypes.string.isRequired,
  userId: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  closable: PropTypes.bool, // changed to maskClosable
  onOk: PropTypes.func,
  destroyOnClose: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  okText: PropTypes.string,
  mask: PropTypes.bool,
  okButtonProps: PropTypes.Object,
};

EnhancedModal.defaultProps = {
  className: '',
  style: {},
  userId: '',
  closable: true,
  onOk: () => {},
  width: 520,
  height: '',
  bodyStyle: {},
  okText: 'OK',
  destroyOnClose: false,
  afterClose: () => {},
  mask: true,
  okButtonProps: {},
};

EnhancedModal.info = Modal.info;
EnhancedModal.success = Modal.success;
EnhancedModal.error = Modal.error;
EnhancedModal.warning = Modal.warning;
EnhancedModal.confirm = Modal.confirm;

export default EnhancedModal;
