/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import {
  getGoalCategoryGoalInit,
  getGoalCategoryGoalSuccess,
  getGoalCategoryGoalFailure,
  getGoalCategoryGoalDetailsInit,
  getGoalCategoryGoalDetailsSuccess,
  getGoalCategoryGoalDetailsFailure,
  addGoalCategoryGoalInit,
  addGoalCategoryGoalSuccess,
  addGoalCategoryGoalFailure,
  updateGoalCategoryGoalInit,
  updateGoalCategoryGoalSuccess,
  updateGoalCategoryGoalFailure,
  deleteGoalCategoryGoalInit,
  deleteGoalCategoryGoalSuccess,
  deleteGoalCategoryGoalFailure,
  getGoalCategoryLogInit,
  getGoalCategoryLogSuccess,
  getGoalCategoryLogFailure,
  updateGoalCategoryDetailAssignGoalInit,
  updateGoalCategoryDetailAssignGoalSuccess,
  updateGoalCategoryDetailAssignGoalFailure,
} from 'store/actions/admin/PredefinedGoalCategoriesDetailActions';
import { adminPredefinedGoalCategoryGoalsUrls } from 'configs/constants';
import { showLoader, hideLoader } from 'store/actions/AppLoaderActions';

export const getGoalCategoryGoal = (request) => (dispatch) => {
  const url = adminPredefinedGoalCategoryGoalsUrls.GET.replace('{categoryId}', request.categoryId);
  dispatch(getGoalCategoryGoalInit());
  dispatch(showLoader());
  axios
    .get(url, { params: request })
    .then((response) => {
      dispatch(hideLoader());
      if (response.status === 200) {
        dispatch(getGoalCategoryGoalSuccess(response.data.data));
      } else {
        dispatch(getGoalCategoryGoalFailure());
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      dispatch(getGoalCategoryGoalFailure(error));
    });
};

export const getGoalCategoryGoalDetails = (request) => (dispatch) => {
  const url = adminPredefinedGoalCategoryGoalsUrls.GET_DETAILS.replace(
    '{userId}',
    request.userId,
  ).replace('{id}', request.id);
  dispatch(getGoalCategoryGoalDetailsInit());
  dispatch(showLoader());
  axios
    .get(url)
    .then((response) => {
      dispatch(hideLoader());
      if (response.status === 200) {
        //   const resourceNormalized = normalizeResourceAvailability(response.data.data, date);
        dispatch(getGoalCategoryGoalDetailsSuccess(response.data.data));
      } else {
        dispatch(getGoalCategoryGoalDetailsFailure());
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      dispatch(getGoalCategoryGoalDetailsFailure(error));
    });
};

export const addGoalCategoryGoal = (request) => (dispatch) => {
  const { payload, parameters } = request;
  const url = adminPredefinedGoalCategoryGoalsUrls.CREATE.replace(
    '{categoryId}',
    parameters.categoryId,
  );

  dispatch(addGoalCategoryGoalInit());
  dispatch(showLoader());
  axios
    .post(url, { goals: payload })
    .then((response) => {
      dispatch(hideLoader());
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Created Successfully',
        });
        dispatch(addGoalCategoryGoalSuccess(response.data));
        dispatch(getGoalCategoryGoal(parameters));
      } else {
        EnhancedNotification.error({
          message: 'Error in creating a new goal',
        });
        dispatch(addGoalCategoryGoalFailure());
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in creating a new goal.',
      });
      dispatch(addGoalCategoryGoalFailure());
    });
};

export const updateGoalCategoryGoal = (request) => (dispatch) => {
  const { categoryId, payload, params } = request;
  const url = adminPredefinedGoalCategoryGoalsUrls.UPDATE.replace('{categoryId}', categoryId);

  dispatch(updateGoalCategoryGoalInit());
  dispatch(showLoader());
  axios
    .put(url, payload)
    .then((response) => {
      dispatch(hideLoader());
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Updated Successfully',
        });
        dispatch(updateGoalCategoryGoalSuccess(response.data));
        dispatch(getGoalCategoryGoal(params));
      } else {
        EnhancedNotification.error({
          message: 'Error in updating a new goal',
        });
        dispatch(updateGoalCategoryGoalFailure());
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in updating a new goal.',
      });
      dispatch(updateGoalCategoryGoalFailure());
    });
};

export const removeGoalCategoryGoal = (request) => (dispatch) => {
  const { goalId, params } = request;
  const url = adminPredefinedGoalCategoryGoalsUrls.DELETE.replace(
    '{categoryId}',
    params.categoryId,
  ).replace('{goalId}', goalId);

  dispatch(deleteGoalCategoryGoalInit());
  dispatch(showLoader());
  axios
    .delete(url)
    .then((response) => {
      dispatch(hideLoader());
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Deleted Successfully',
        });

        dispatch(deleteGoalCategoryGoalSuccess(response.data));
        dispatch(getGoalCategoryGoal(params));
      } else {
        EnhancedNotification.error({
          message: 'Error in deleting goal.',
        });
        dispatch(deleteGoalCategoryGoalFailure());
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      EnhancedNotification.error({ message: error.response.message || 'Error in deleting goal.' });
      dispatch(deleteGoalCategoryGoalFailure(error));
    });
};

export const getGoalCategoryLogsGoal = (request) => (dispatch) => {
  const url = adminPredefinedGoalCategoryGoalsUrls.GOAL_CATEGORY_LOG.replace(
    '{categoryId}',
    request.categoryId,
  );

  dispatch(getGoalCategoryLogInit());
  dispatch(showLoader());
  axios
    .get(url, { params: request.params })
    .then((response) => {
      dispatch(hideLoader());
      if (response.status === 200) {
        dispatch(getGoalCategoryLogSuccess(response.data.data));
      } else {
        dispatch(getGoalCategoryLogFailure());
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      dispatch(getGoalCategoryLogFailure(error));
    });
};

export const assignGoalCategory = (request) => (dispatch) => {
  const url = adminPredefinedGoalCategoryGoalsUrls.UPDATE_ASSIGN_GOAL.replace(
    '{categoryId}',
    request.categoryId,
  );

  dispatch(updateGoalCategoryDetailAssignGoalInit());
  dispatch(showLoader());
  axios
    .post(url, request.body)
    .then((response) => {
      dispatch(hideLoader());
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Updated Successfully',
        });
        const logsPayload = {
          categoryId: request.categoryId,
          params: {
            page: 0,
            size: 10,
          },
        };
        dispatch(getGoalCategoryLogsGoal(logsPayload));
        dispatch(updateGoalCategoryDetailAssignGoalSuccess(response.data));
      } else {
        EnhancedNotification.error({
          message: 'Error in updating a new goal',
        });
        dispatch(updateGoalCategoryDetailAssignGoalFailure());
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in updating a assing employee goal date.',
      });
      dispatch(updateGoalCategoryDetailAssignGoalFailure());
    });
};
