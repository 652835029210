/* eslint-disable no-param-reassign */
import * as authTypes from 'constants/AuthTypes';
import { gaSet } from 'sources/AnalyticsSource';

const initialState = {
  user: null,
};
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case authTypes.LOGIN_SUCCESS: {
      const user = action.data;
      state = Object.assign({}, state, { user });
      gaSet({ userId: user.id });
      return state;
    }
    case authTypes.LOGOUT: {
      state = Object.assign({}, initialState);
      gaSet({ userId: null });
      return state;
    }
    default:
      return state;
  }
}
