import { createSlice } from '@reduxjs/toolkit';
import { EnhancedNotification } from 'components/shared/antd';
import { showLoader, hideLoader } from 'store/actions/AppLoaderActions';
import {
  normalizeEvaluationReport,
  normalizePerformanceCycleEmployees,
} from 'normalizers/performanceManagementNormalizer';
import {
  getEvaluationReport,
  getGoalsReport,
  getEvaluationReportForLM,
  getGoalsReportForLM,
  getReporteesGoalsForLM,
  getReporteesEvaluationsForLM,
} from 'sources/PMDashboard';
import { generateReporteeGoalsReport } from 'utils/goalUtils';
import { generatePerformanceCycleEmployeesReport } from '../../utils/performanceManagementUtils';

const pmDashboardSlice = createSlice({
  name: 'pmDashboard',
  initialState: {
    evaluationReport: [],
    goalsReport: [],
    reporteesGoals: [],
    reporteesEvaluations: {},
  },
  reducers: {
    getEvaluationReportInit(state) {
      return { ...state, evaluationReport: [] };
    },
    getEvaluationReportSuccess(state, action) {
      return { ...state, evaluationReport: action.payload };
    },
    getEvaluationReportFailure(state) {
      return { ...state, evaluationReport: [] };
    },
    getGoalsReportInit(state) {
      return { ...state, goalsReport: [] };
    },
    getGoalsReportSuccess(state, action) {
      return { ...state, goalsReport: action.payload };
    },
    getGoalsReportFailure(state) {
      return { ...state, goalsReport: [] };
    },
    getReporteesGoalsInit(state) {
      return { ...state, reporteesGoals: [] };
    },
    getReporteesGoalsSuccess(state, action) {
      return { ...state, reporteesGoals: action.payload };
    },
    getReporteesGoalsFailure(state) {
      return { ...state, reporteesGoals: [] };
    },
    getReporteesEvaluationsInit(state) {
      return { ...state, reporteesEvaluations: {} };
    },
    getReporteesEvaluationsSuccess(state, action) {
      return { ...state, reporteesEvaluations: action.payload };
    },
    getReporteesEvaluationsFailure(state) {
      return { ...state, reporteesEvaluations: {} };
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = pmDashboardSlice;

export const getEvaluationReportAction = (id) => async (dispatch) => {
  dispatch(showLoader());
  try {
    dispatch(actions.getEvaluationReportInit());
    const response = await getEvaluationReport(id);
    dispatch(actions.getEvaluationReportSuccess(normalizeEvaluationReport(response.data.data)));
    dispatch(hideLoader());
  } catch (error) {
    EnhancedNotification.error({ message: error.response.data.message });
    dispatch(actions.getEvaluationReportFailure());
    dispatch(hideLoader());
  }
};

export const getGoalsReportAction = () => async (dispatch) => {
  dispatch(showLoader());
  try {
    dispatch(actions.getGoalsReportInit());
    const response = await getGoalsReport();
    dispatch(actions.getGoalsReportSuccess(response.data.data));
    dispatch(hideLoader());
  } catch (error) {
    EnhancedNotification.error({ message: error.response.data.message });
    dispatch(actions.getGoalsReportFailure());
    dispatch(hideLoader());
  }
};

export const getEvaluationReportForLMAction = (id) => async (dispatch) => {
  dispatch(showLoader());
  try {
    dispatch(actions.getEvaluationReportInit());
    const response = await getEvaluationReportForLM(id);
    dispatch(actions.getEvaluationReportSuccess(normalizeEvaluationReport(response.data.data)));
    dispatch(hideLoader());
  } catch (error) {
    EnhancedNotification.error({ message: error.response.data.message });
    dispatch(actions.getEvaluationReportFailure());
    dispatch(hideLoader());
  }
};

export const getGoalsReportForLMAction = () => async (dispatch) => {
  dispatch(showLoader());
  try {
    dispatch(actions.getGoalsReportInit());
    const response = await getGoalsReportForLM();
    dispatch(actions.getGoalsReportSuccess(response.data.data));
    dispatch(hideLoader());
  } catch (error) {
    EnhancedNotification.error({ message: error.response.data.message });
    dispatch(actions.getGoalsReportFailure());
    dispatch(hideLoader());
  }
};

export const getReporteesGoalsForLMAction = (params) => async (dispatch) => {
  dispatch(showLoader());
  try {
    dispatch(actions.getReporteesGoalsInit());
    const response = await getReporteesGoalsForLM(params);
    dispatch(actions.getReporteesGoalsSuccess(response.data.data));
    dispatch(hideLoader());
  } catch (error) {
    EnhancedNotification.error({ message: error.response.data.message });
    dispatch(actions.getReporteesGoalsFailure());
    dispatch(hideLoader());
  }
};

export const exportReporteesGoalsForLMAction = (params) => async (dispatch) => {
  dispatch(showLoader());
  try {
    const response = await getReporteesGoalsForLM(params);
    generateReporteeGoalsReport(response.data.data.rows, 'Goals Report');
    dispatch(hideLoader());
  } catch (error) {
    EnhancedNotification.error({ message: error.response.data.message });
    dispatch(hideLoader());
  }
};

export const getReporteesEvaluationsForLMAction = (params) => async (dispatch) => {
  dispatch(showLoader());
  try {
    dispatch(actions.getReporteesEvaluationsInit());
    const response = await getReporteesEvaluationsForLM(params);
    dispatch(actions.getReporteesEvaluationsSuccess(response.data.data));
    dispatch(hideLoader());
  } catch (error) {
    EnhancedNotification.error({ message: error.response.data.message });
    dispatch(actions.getReporteesEvaluationsFailure());
    dispatch(hideLoader());
  }
};

export const exportReporteesEvaluationsForLMAction = (params) => async (dispatch) => {
  dispatch(showLoader());
  try {
    const response = await getReporteesEvaluationsForLM(params);
    const normalized = normalizePerformanceCycleEmployees(response.data.data.rows);
    generatePerformanceCycleEmployeesReport(normalized, '', 'Evaluations Report');
    dispatch(hideLoader());
  } catch (error) {
    EnhancedNotification.error({ message: error.response.data.message });
    dispatch(hideLoader());
  }
};

// Export the reducer, either as a default or named export
export default reducer;
