// main routes
import { FEEDBACK_REDIRECT } from './constants';

export const ROOT = '/';
export const LOGIN = `${ROOT}login`;
export const FAQS = `${ROOT}faqs`;
export const LOGWORK = `${ROOT}logwork`;
export const LOGOUT = `${ROOT}logout`;
export const POLICY = `${ROOT}policy`;
export const ADMIN = `${ROOT}admin`;
export const CRMC = `${ROOT}crmc`;
export const PC = `${ROOT}pc`;
export const HR = `${ROOT}hr`;
export const RP = `${ROOT}resourcePlanner`;

export const FEEDBACK_REDIRECT_URL = `${FEEDBACK_REDIRECT}/feedbacks`;

export const MY_LEAVE_REQUEST = `${ROOT}my-leave-requests`;
export const TEAM_LEAVE_REQUEST = `${ROOT}team-leave-requests`;
export const LEAVE_DASHBOARD = `${ROOT}leave-dashboard`;
export const LEAVE_CALENDAR = `${ROOT}leave-calendar`;
export const PROFILE = `${ROOT}profile`;
export const LM_DASHBAORD = `${ROOT}lm-dashboard`;
export const ADMIN_DASHBAORD = `${ROOT}pm-admin-dashboard`;

// admin sub routes
export const ADMIN_REPORT = `${ADMIN}/report`;
export const ADMIN_MISSING_ALLOCATION = `${ADMIN}/missing-allocations`;

// crmc sub routes
export const CRMC_DAHBOARD = `${CRMC}/dashboard`;
export const CRMC_EMPLOYEES_CURRENT = `${CRMC}/employees/current`;
export const CRMC_EMPLOYEES_INACTIVE = `${CRMC}/employees/inactive`;
export const CRMC_EMPLOYEES_BILLABLE = `${CRMC}/employees/billable`;
export const CRMC_EMPLOYEES_NON_BILLABLE = `${CRMC}/employees/non-billable`;
export const CRMC_PROJECTS_CURRENT = `${CRMC}/projects/current`;
export const CRMC_PROJECTS_COMPLETED = `${CRMC}/projects/completed`;

// employees sub routes
export const EMPLOYEES_PROFILE = `/:id`;
export const REPORTEE_PROFILE = `/reportee/:id`;
export const EMPLOYEES_ALL = `/all`;
export const EMPLOYEES_CREATE = `/create`;

// policy sub routes
export const POLICY_OFFICIAL_DRESS_CODE = `${POLICY}/official-dress-code`;
export const POLICY_EMPLOYEE_SAFETY = `${POLICY}/employee-safety`;
export const POLICY_CONDUCT_WORK = `${POLICY}/conduct-work`;
export const POLICY_TUITION_ASSISTANCE = `${POLICY}/tuition-assistance`;
export const POLICY_CAREEM_PROCESS_FLOW = `${POLICY}/careem-process-flow`;
export const POLICY_MEDICAL = `${POLICY}/medical`;
export const POLICY_FEMALE_TRANSPORT_ASSISTANCE = `${POLICY}/female-transport-assistance`;
export const POLICY_CHILDREN_EDUCATION = `${POLICY}/children-education`;
export const POLICY_LEAVES = `${POLICY}/leaves`;
export const POLICY_EMPLOYEE_REFERRAL = `${POLICY}/employee-referral`;
export const POLICY_MEAL_ALLOWANCE = `${POLICY}/meal-allowance`;
export const POLICY_EXTRA_WORK_DAY = `${POLICY}/extra-work-day`;
export const POLICY_TRAVEL = `${POLICY}/travel`;
export const POLICY_GRIEVANCE = `${POLICY}/grievance`;
export const POLICY_ENGAGEMENT_COMMITTEE = `${POLICY}/engagement-committee`;
export const POLICY_RECREATION_AND_ENGAGEMENT = `${POLICY}/recreation-and-engagement`;
export const POLICY_EMPLOYMENT_AT_VENTUREDIVE = `${POLICY}/employment-at-veturedive`;
export const POLICY_LAPTOP = `${POLICY}/laptop`;
export const POLICY_TRAINING_CERTIFICATION_APPROVAL_FORM = `${POLICY}/training-certification-approval-form`;

// hr sub routes
export const HR_LEAVES_DASHBOARD = `${HR}/leaves-dashboard`;
export const ROLES = `${ROOT}roles`;
export const SKILLS = `${ROOT}skills`;
export const EMPLOYMENT_TYPES = `${ROOT}employment-types`;
export const DESIGNATIONS = `${ROOT}designations`;
export const LOCATIONS = `${ROOT}locations`;
export const DEPARTMENTS = `${ROOT}departments`;
export const BUSINESS_UNIT = `${ROOT}business-unit`;
export const DIVISION = `${ROOT}division`;
export const COMPETENCIES = `${ROOT}competencies`;
export const PROJECT_ROLES = `${ROOT}project-roles`;

// performance cycle routes
export const PERFORMANCE_CYCLE = `${PC}/performance-cycles`;

// employee rating routes
export const EMPLOYEE_RATINGS = `${ROOT}employee-ratings`;
export const PERFORMANCE_CYCLE_CRMC = `${PC}/performance-cycles-crmc`;
export const PROJECT_REVIEWS = `${PC}/project-reviews`;

// employee directory routes
export const EMPLOYEE_SEARCH = `${ROOT}employee-directory`;
export const EMPLOYEE_ORG_TREE = `${ROOT}employee-organization-tree`;

// expense claim routes
export const EXPENSE_CLAIM_REQUEST = `${ROOT}expense-claim/request`;
export const EXPENSE_CLAIM_LIST = `${ROOT}expense-claim/list`;
export const EXPENSE_CLAIM_FAQ = `${ROOT}expense-claim/faqs`;

// Resource routes
export const RESOURCES = `${ROOT}resources`;

// finance routes
export const FINANCE_BILLING = `${ROOT}billing`;

export const RCA_PROJECTS = `${ROOT}rca/billing-projects`;

export const CRMC_REVIEW_RATING = `${ROOT}employee-ratings`;

export const HOME_PAGE = `${ROOT}homepage`;

// resource planner
export const RESOURCE_PLANNER_DEAL_SUMMARY = `${RP}/dealSummary`;
export const RESOURCE_PLANNER_REPORT_RESOURCE_DEFICIT = `${RP}/reports/resource-deficit`;
export const TRANSIENT_RESOURCE = `${RP}/transientResource`;
export const RESOURCE_PLANNER_REPORT_PLANNING_SUMMARY = `${RP}/reports/planning-summary`;
export const RESOURCE_PLANNER_REPORT_BENCH_SUMMARY = `${RP}/reports/bench-summary`;
// interview tracking

export const INTERVIEW_TRACKING = `${ROOT}interview-tracking`;
export const INTERVIEW_QUESTIONS = `${ROOT}interview-questions`;
export const INTERVIEW_QUESTIONS_FREQUENCY = `${ROOT}interview-questions-frequency`;

// performance-management
export const PERFORMANCE_MANAGEMENT = `${ROOT}performance-management`;

// Goals
export const ALL_GOALS = `${ROOT}goals`;

export const PREDEFINED_GOALS = `${ROOT}predefined-goals`;
export const PREDEFINED_GOALS_CATEGORIES = `${ROOT}goals-categories`;

// LM Dashboard
export const LM_DASHBOARD_PERFORMANCE_MANAGEMENT = `${ROOT}lm-dashboard/performance-management`;

export const DEALS = `${ROOT}deals`;
