import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedModal,
  EnhancedDatePicker,
  EnhancedForm,
} from 'components/shared/antd';
import React, { useState } from 'react';
import RequiredField from 'components/RequiredField';
import { DATE_FORMAT } from 'configs/employeeProfileConstants';
import moment from 'moment';

const AssignGoalFormModal = (props) => {
  const {
    handleOk,
    isLoading,
    categoryId,
    handleCancel,
    isModalVisible,
    assignGoalCategory,
    selectedEmployeeIds,
  } = props;

  const [dueDate, setDueDate] = useState({
    dueDate: moment().format(DATE_FORMAT),
  });

  const onDueDateChange = (date) => {
    setDueDate(moment(date).format(DATE_FORMAT));
  };

  const updateAssignEmployeeGoal = () => {
    const payload = {
      categoryId,
      body: {
        employeeIds: selectedEmployeeIds,
        dueDate,
      },
    };

    assignGoalCategory(payload);
    handleOk();
  };

  const closeModalHandler = () => {
    handleCancel();
  };

  return (
    <EnhancedModal
      title="Assign Goal Category"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={closeModalHandler}
      className="add-goals-modal"
      width="800px"
      footer={[
        <EnhancedButton key="submit" type="primary" onClick={updateAssignEmployeeGoal}>
          Submit
        </EnhancedButton>,
      ]}
    >
      <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
        loading={isLoading}
        bordered={false}
        className="details-card-wrapper"
        style={{
          marginTop: 10,
        }}
      >
        <EnhancedRow>
          <EnhancedForm layout="inline" className="goals-form w-full">
            <EnhancedCol lg={4}>
              <RequiredField> Due Date </RequiredField>
            </EnhancedCol>
            <EnhancedCol lg={20}>
              <EnhancedDatePicker
                name="dueDate"
                dropdownClassName="dueDate-goal-field"
                style={{ width: '100%' }}
                onChange={onDueDateChange}
                value={moment(dueDate)}
                format={DATE_FORMAT}
                disabledDate={(current) => {
                  const customDate = moment().format(DATE_FORMAT);
                  return current && current < moment(customDate, DATE_FORMAT);
                }}
                allowClear={false}
              />
            </EnhancedCol>
          </EnhancedForm>
        </EnhancedRow>
      </EnhancedCard>
    </EnhancedModal>
  );
};

export default AssignGoalFormModal;
