import axios from 'axios';
import {
  getDesignationInit,
  getDesignationSuccess,
  getDesignationFailure,
  createDesignationInit,
  createDesignationSuccess,
  createDesignationFailure,
  updateDesignationInit,
  updateDesignationSuccess,
  updateDesignationFailure,
  deleteDesignationInit,
  deleteDesignationSuccess,
  deleteDesignationFailure,
} from 'store/actions/DesignationActions';

import { designationUrls } from 'configs/constants';
import { EnhancedNotification } from 'components/shared/antd';

const cache = {};

export const getAllDesignations = () => (dispatch) => {
  const url = designationUrls.GET;
  dispatch(getDesignationInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        cache.designations = response.data.data;
        dispatch(getDesignationSuccess(response.data.data));
      } else {
        dispatch(getDesignationFailure());
      }
    })
    .catch(() => {
      dispatch(getDesignationFailure());
    });
};

export const createNewDesignation = (request) => (dispatch) => {
  const url = designationUrls.POST;
  dispatch(createDesignationInit());
  axios
    .post(url, request)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Created Successfully',
        });
        dispatch(createDesignationSuccess(response.data.data));
      } else {
        EnhancedNotification.error({
          message: 'Error in creating a new designation',
        });
        dispatch(createDesignationFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Entered designation already exists. Cannot add it again',
      });
      dispatch(createDesignationFailure());
    });
};

export const updateDesignation = (request) => (dispatch) => {
  const url = designationUrls.PUT.replace('{designationId}', request.id);
  dispatch(updateDesignationInit());
  axios
    .put(url, request)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Updated Successfully',
        });
        dispatch(updateDesignationSuccess(request));
      } else {
        EnhancedNotification.error({
          message: 'Error in editing the designation',
        });
        dispatch(updateDesignationFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Entered designation already exists. Cannot add it again',
      });
      dispatch(updateDesignationFailure());
    });
};

export const deleteDesignation = (request) => (dispatch) => {
  const url = designationUrls.DELETE.replace('{designationId}', request.id);
  dispatch(deleteDesignationInit());
  axios
    .delete(url, { data: request })
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Deleted Successfully',
        });
        dispatch(deleteDesignationSuccess(request.id));
      } else {
        EnhancedNotification.error({
          message: 'Cannot delete because the designation is associated with employees',
        });
        dispatch(deleteDesignationFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Cannot delete because the designation is associated with employees',
      });
      dispatch(deleteDesignationFailure());
    });
};
