import { GET_LATEST_HOME_NEWS } from 'constants/NewsTypes';
import {
  GET_LATEST_VIDEO,
  GET_YOUTUBE_VIDEOS,
  GET_EVENTS,
  GET_API_HOMEPAGE_FAILURE,
} from 'constants/HomePageTypes';

const initialState = {
  news: [],
  video: [],
  youtubeLinks: [],
  events: [],
  isDataLoaded: false,
  loader: false,
};
export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LATEST_HOME_NEWS: {
      return Object.assign({}, state, {
        news: action.data,
      });
    }
    case GET_LATEST_VIDEO: {
      return Object.assign({}, state, {
        video: action.data,
      });
    }
    case GET_YOUTUBE_VIDEOS: {
      return Object.assign({}, state, {
        youtubeLinks: action.data,
        isDataLoaded: true,
      });
    }
    case GET_EVENTS: {
      return Object.assign({}, state, {
        events: action.data,
      });
    }
    case GET_API_HOMEPAGE_FAILURE: {
      return {
        ...state,
        loader: false,
      };
    }
    default:
      return state;
  }
}
