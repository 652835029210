import React, { useState, useEffect } from 'react';
import {
  EnhancedSelect,
  EnhancedDatePicker,
  EnhancedModal,
  EnhancedInput,
  EnhancedRadio,
  EnhancedNotification,
} from 'components/shared/antd';
import { connect } from 'react-redux';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import { getProjectsAction } from 'store/actions/ProjectActions';
import { INTERVIEW_RESULT } from './constants';
import {
  createInterview as createInterviewAction,
  getReporteesTracks,
  updateInterview as updateInterviewAction,
} from '../../sources/interviewSources';
import { getAllReportees } from '../../sources/EmployeeSource';

const initialState = {
  resource: '',
  client: '',
  track: '',
  result: INTERVIEW_RESULT.ON_HOLD,
  clientComments: '',
  lmLeadComments: '',
  scheduleDate: null,
};

function AddEditInterview(props) {
  const {
    visible,
    isEdit,
    data,
    onClose,
    resources,
    clientList,
    getResources,
    trackList,
    createInterview,
    updateInterview,
  } = props;
  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    getResources();
  }, []);

  useEffect(() => {
    if (isEdit && !isEmpty(data)) {
      setFormState({
        resource: data.employeeId,
        client: data.projectId,
        track: data.trackId,
        result: data.result,
        clientComments: data.clientComments,
        lmLeadComments: data.lmComments,
        scheduleDate: moment(data.scheduledAt),
      });
    }
  }, [isEdit, data]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSelect = (value, name) => {
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const handleClose = () => {
    setFormState(initialState);
    onClose();
  };

  const handleOk = () => {
    let error = '';
    if (!formState.resource) {
      error = 'Resource field is required';
    } else if (!formState.client) {
      error = 'Client field is required';
    } else if (!formState.track) {
      error = 'Track field is required';
    } else if (!formState.scheduleDate) {
      error = 'Schedule date is required';
    }
    if (error) {
      EnhancedNotification.error({ message: error });
    } else {
      const payload = {
        employeeId: formState.resource,
        projectId: formState.client,
        trackId: formState.track,
        result: formState.result,
        clientComments: formState.clientComments,
        lmComments: formState.lmLeadComments,
        scheduledAt: formState.scheduleDate.toDate().toISOString(),
      };
      if (isEdit) {
        updateInterview(data.id, payload).then(() => handleClose());
        return;
      }
      createInterview(payload).then(() => handleClose());
    }
  };

  return (
    <EnhancedModal
      title="Add Interview"
      visible={visible || false}
      onOk={handleOk}
      onCancel={handleClose}
      okText="Save"
      destroyOnClose
    >
      <div className="field">
        <div className="label">Resource</div>
        <EnhancedSelect
          showSearch
          defaultActiveFirstOption={false}
          showArrow={false}
          style={{ width: '100%' }}
          value={formState.resource}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(value) => onSelect(value, 'resource')}
        >
          {resources.map((res) => (
            <EnhancedSelect.Option value={res.id} key={res.id}>
              {`${res.fullName} - ${res.department.name} - ${res.oracleId}`}
            </EnhancedSelect.Option>
          ))}
        </EnhancedSelect>
      </div>
      <div className="field">
        <div className="label">Client</div>
        <EnhancedSelect
          showSearch
          defaultActiveFirstOption={false}
          showArrow={false}
          style={{ width: '100%' }}
          value={formState.client}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(value) => onSelect(value, 'client')}
        >
          {clientList.map((cl) => (
            <EnhancedSelect.Option value={cl.id} key={cl.id}>
              {cl.name}
            </EnhancedSelect.Option>
          ))}
        </EnhancedSelect>
      </div>
      <div className="field">
        <div className="label">Track</div>
        <EnhancedSelect
          showSearch
          defaultActiveFirstOption={false}
          showArrow={false}
          style={{ width: '100%' }}
          value={formState.track}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(value) => onSelect(value, 'track')}
        >
          {trackList.map((tr) => (
            <EnhancedSelect.Option key={tr.id} value={tr.id}>
              {tr.name}
            </EnhancedSelect.Option>
          ))}
        </EnhancedSelect>
      </div>
      <div className="field">
        <div className="label">Result</div>
        <EnhancedRadio.Group value={formState.result} onChange={onChange} name="result">
          <EnhancedRadio value={INTERVIEW_RESULT.ON_HOLD} style={{ color: 'grey' }}>
            On Hold
          </EnhancedRadio>
          <EnhancedRadio value={INTERVIEW_RESULT.ACCEPTED} style={{ color: 'green' }}>
            Pass
          </EnhancedRadio>
          <EnhancedRadio value={INTERVIEW_RESULT.REJECTED} style={{ color: 'red' }}>
            Fail
          </EnhancedRadio>
        </EnhancedRadio.Group>
      </div>
      <div className="field">
        <div className="label">Interview Date</div>
        <EnhancedDatePicker
          value={formState.scheduleDate}
          style={{ width: '100%' }}
          onChange={(date) => onSelect(date, 'scheduleDate')}
        />
      </div>
      <div className="field">
        <div className="label">Client Comments</div>
        <EnhancedInput.TextArea
          rows={2}
          name="clientComments"
          value={formState.clientComments}
          onChange={onChange}
        />
      </div>
      <div className="field">
        <div className="label">LM/Lead Comments</div>
        <EnhancedInput.TextArea
          rows={2}
          name="lmLeadComments"
          value={formState.lmLeadComments}
          onChange={onChange}
        />
      </div>
    </EnhancedModal>
  );
}

const mapStateToProps = (state) => ({
  resources: state.employeeReducer.reportees.filter((v) => v.isActive),
  clientList: state.projectReducer.projects.filter((v) => v.status),
  trackList: state.interviewReducer.trackList,
});

const mapDispatchToProps = (dispatch) => ({
  getProjects: () => dispatch(getProjectsAction()),
  getTracks: () => dispatch(getReporteesTracks()),
  createInterview: (payload) => dispatch(createInterviewAction(payload)),
  updateInterview: (id, payload) => dispatch(updateInterviewAction(id, payload)),
  getResources: () => dispatch(getAllReportees()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditInterview);
