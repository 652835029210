import {
  GET_REPORT_INIT,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAILURE,
  GET_REPORT_WITH_FORMAT_INIT,
  GET_REPORT_WITH_FORMAT_FAILURE,
  GET_REPORT_WITH_FORMAT_SUCCESS,
} from 'constants/ReportTypes';

const initialState = {
  isLoading: false,
  report: null,
  summary: null,
  employees: [],
  managers: [],
  departments: [],
  csvReport: null,
  isCSVLoading: false,
};
export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REPORT_INIT: {
      return Object.assign({}, initialState, {
        isLoading: true,
      });
    }
    case GET_REPORT_SUCCESS: {
      return Object.assign({}, state, {
        report: action.data.report,
        summary: action.data.summary,
        employees: action.data.employees,
        managers: action.data.managers,
        departments: action.data.departments,
        isLoading: false,
      });
    }
    case GET_REPORT_FAILURE: {
      return Object.assign({}, initialState);
    }
    case GET_REPORT_WITH_FORMAT_INIT: {
      return Object.assign({}, state, {
        csvReport: null,
        isCSVLoading: true,
      });
    }
    case GET_REPORT_WITH_FORMAT_SUCCESS: {
      return Object.assign({}, state, {
        csvReport: action.data,
        isCSVLoading: false,
      });
    }
    case GET_REPORT_WITH_FORMAT_FAILURE: {
      return Object.assign({}, state, {
        csvReport: null,
        isCSVLoading: false,
      });
    }
    default:
      return state;
  }
}
