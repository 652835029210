export const notSetVariable = ['Not Set', 'not_set'];
export const NOT_AVAILABLE = 'N/A';
export const genders = {
  MALE: 'male',
  FEMALE: 'female',
};
export const emotionUpdated = 'emotionUpdated';
export const invalidDate = 'Invalid date';
export const completed = 'completed';
export const current = 'current';

export const FUTURE_MONTH_MESSAGE =
  'The data given below is displaying the projected utilisation based on the current allocations. Actual utilisation may vary.';
