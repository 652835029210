// Employee Profile error messages
export const EMPLOYEE_NOT_FOUND_MESSAGE = 'Error in fetching Employee Info';
export const EMPLOYEE_INFO_UPDATE_FAILED_MESSAGE = 'Error in updating Employee Info';
export const EMPLOYEE_EMERGENCY_CONTACT_DELETE_FAILED_MESSAGE =
  'Error in deleting Employee Emergency Contact';
export const EMPLOYEE_EMERGENCY_CONTACT_CREATE_FAILED_MESSAGE =
  'Error in creating Employee Emergency Contact';
export const EMPLOYEE_FAMILY_INFO_CREATE_FAILED_MESSAGE = 'Error in creating Employee Family Info';
export const EMPLOYEE_FAMILY_INFO_UPDATE_FAILED_MESSAGE = 'Error in updating Employee Family Info';
export const EMPLOYEE_FAMILY_INFO_DELETE_FAILED_MESSAGE = 'Error in deleting Employee Family Info';
export const EMPLOYEE_DOCUMENT_CREATE_FAILED_MESSAGE = 'Error in uploading Employee Document.';
export const EMPLOYEE_TRACKING_INFO_UPDATE_FAILED_MESSAGE =
  'Error in Updating Employee Tracking Info';
export const EMPLOYEE_DOCUMENT_DELETE_FAILED_MESSAGE = 'Error in deleting Employee Document.';
export const EMPLOYEE_PHOTO_UPDATE_FAILED_MESSAGE = 'Error in updating Employee Picture.';
export const EMPLOYEE_COMMENT_CREATE_FAILED_MESSAGE = 'Error in creating new Comment.';
export const EMPLOYEE_COMMENT_DELETE_FAILED_MESSAGE = 'Error in deleting Comment.';
export const EMPLOYEE_EDUCATION_HISTORY_CREATE_FAILED_MESSAGE =
  'Error in creating Employee Education History';
export const EMPLOYEE_EDUCATION_HISTORY_UPDATE_FAILED_MESSAGE =
  'Error in updating Employee Education History';
export const EMPLOYEE_EDUCATION_HISTORY_DELETE_FAILED_MESSAGE =
  'Error in deleting Employee Education History';
export const EMPLOYEE_COMMENT_ATTACHMENT_GET_FAILED_MESSAGE =
  'Error in fetching Comment Attachment.';
export const EMPLOYEE_EMPLOYMENT_UPDATE_FAILED_MESSAGE = 'Error in updating Employee Employment';
export const EMPLOYEE_EMPLOYMENT_CREATE_FAILED_MESSAGE = 'Error in creating Employee Employment';
export const EMPLOYEE_EMPLOYMENT_DELETE_FAILED_MESSAGE = 'Error in deleting Employee Employment';
export const EMPLOYEE_JOB_INFO_UPDATE_FAILED_MESSAGE = 'Error in updating Employee Job Info';
export const EMPLOYEE_JOB_INFO_DELETE_FAILED_MESSAGE = 'Error in deleting Employee Job Info';

// Miscellaneous error messages
export const MISCELLANEOUS_GET_FAILURE_MESSAGE = 'Error in fetching Miscellaneous data';
export const JOB_DESCRIPTION_DOCUMENT_GET_FAILED_MESSAGE =
  'Error in fetching Job Description Document';

// Employee Talent Management messages
export const EMPLOYEE_TALENT_UPDATE_FAILED_MESSAGE = 'Error in updating Employee Talent';
export const EMPLOYEE_CAREER_PLANNING_UPDATE_FAILED_MESSAGE =
  'Error in updating Employee Career Planning';
export const EMPLOYEE_CERTIFICATE_UPDATE_FAILED_MESSAGE = 'Error in updating Employee Certificate';
export const EMPLOYEE_CERTIFICATE_CREATE_FAILED_MESSAGE = 'Error in creating Employee Certificate';
export const EMPLOYEE_AUDIT_LOGS_GET_FAILED_MESSAGE = 'Error in fetching Employee Audit logs';
export const EMPLOYEE_CERTIFICATE_DELETE_FAILED_MESSAGE = 'Error in deleting Employee Certificate';

// Generic success messages
export const CREATE_SUCCESS_MESSAGE = 'Created Successfully';
export const UPDATE_SUCCESS_MESSAGE = 'Updated Successfully';
export const DELETE_SUCCESS_MESSAGE = 'Deleted Successfully';

// Field validation error messages
export const REQUIRED_ERROR_MESSAGE = 'This field is required';
export const NUMBERS_ONLY_ERROR_MESSAGE = 'Please use numbers only';
export const EMAIL_ERROR_MESSAGE = 'Please enter a valid email address';
export const FILE_NAME_ERROR_MESSAGE = 'Please enter a valid file name';
export const POSITIVE_NUMBERS_ONLY_ERROR_MESSAGE = 'Please enter a positive number';

// Prompt messages
export const DELETE_CONFIRM_PROMPT_MESSAGE = 'Are you sure you want to delete ?';
export const DELETE_CONFIRM_PROMPT_WARNING_MESSAGE = 'Once deleted, it cannot be undone';
export const DE_ACTIVATE_EMPLOYEE_CONFIRM_PROMPT_MESSAGE =
  'Are you sure you want to deactivate this employee?';
export const COMPENSATION_HISTORY_CONFIRMATION_MESSAGE =
  'Compensation history will not be updated. Are you sure you want to update ?';
export const DELETE_EMPLOYMENT_CONFIRM_PROMPT_MESSAGE =
  'Are you sure you want to delete this employment?';
export const GOAL_DELETE_WARNING_MESSAGE_TITLE = `Are you sure you want to delete this goal?`;
export const GOAL_DELETE_WARNING_MESSAGE_DETAILS = `It will also be deleted from the goal category if it's a part of it.`;
