import { connect } from 'react-redux';
import LeavesDashboard from 'components/hr/LeavesDashboard';
import {
  getCompanyWideDashboardAction,
  getLeavesAdjustmentsLogsAction,
  exportLeavesLogAction,
} from 'store/actions/hr/LeavesDashboardActions';
import {
  updateTeamLeaveAction,
  getTeamLeavesBalanceAction,
  updateTeamLeaveAsHRAction,
  getLeavesStatuses,
  exportLeaveQuotasAction,
  exportAllLeavesAction,
  getLeaveTypesAction,
  adjustLeaveQuotaAction,
} from 'store/actions/LeaveActions';

import {
  getUserAttributesAction,
  getEmployeeDepartmentsAction,
} from 'store/actions/EmployeeActions';

const mapStateToProps = ({
  auth,
  teamLeaveBalanceReducer,
  leavesStatusesReducer,
  employeeReducer,
  teamLeavesReducer,
  leaveTypesReducer,
}) => ({
  userId: auth.user && auth.user.id,
  userName: auth.user && auth.user.fullName,
  joiningDate: auth.user && auth.user.joiningDate,
  isManager: auth.user && auth.user.isManager,
  getLeaveQuotas: teamLeaveBalanceReducer.teamLeaveBalance,
  isLoading: teamLeaveBalanceReducer.isLoading,
  teamLeaveBalance: teamLeaveBalanceReducer.teamLeaveBalance,
  pageInfo: teamLeaveBalanceReducer.pageInfo,
  userAttributes: employeeReducer.userAttributes,
  companyWideDashboard: teamLeaveBalanceReducer.companyWideDashboard,
  adjustLeaveQuotaLogs: teamLeaveBalanceReducer.adjustLeaveQuotaLogs,
  adjustLeaveQuotaLogsPageInfo: teamLeaveBalanceReducer.adjustLeaveQuotaLogsPageInfo,
  leaveQuota: teamLeaveBalanceReducer.adjustLeaveQuota,
  leavesStatuses: leavesStatusesReducer.leavesStatuses,
  updateSuccess: teamLeavesReducer.updateSuccess,
  leaveTypes: leaveTypesReducer.leaveTypes,
  employees: employeeReducer.employees,
  departments: employeeReducer.departments,
});

const mapDispatchToProps = (dispatch) => ({
  getTeamLeavesBalance: (attributeId, params) =>
    dispatch(getTeamLeavesBalanceAction(attributeId, params)),
  exportLeaveQuotas: (attributeId, params) =>
    dispatch(exportLeaveQuotasAction(attributeId, params)),
  exportAllLeaves: (params) => dispatch(exportAllLeavesAction(params)),
  getCompanyWideDashboard: (params) => dispatch(getCompanyWideDashboardAction(params)),
  getLeavesAdjustmentsLogs: (params) => dispatch(getLeavesAdjustmentsLogsAction(params)),
  getUserAttributesAction: (attributeId) => dispatch(getUserAttributesAction(attributeId)),
  updateTeamLeaveAsHR: (leaveId, data) => dispatch(updateTeamLeaveAsHRAction(leaveId, data)),
  updateTeamLeave: (data) => dispatch(updateTeamLeaveAction(data)),
  getLeavesStatuses: () => dispatch(getLeavesStatuses()),
  getLeaveTypes: () => dispatch(getLeaveTypesAction()),
  adjustLeaveQuota: (data) => dispatch(adjustLeaveQuotaAction(data)),
  getEmployeeDepartmentsAction: () => dispatch(getEmployeeDepartmentsAction()),
  exportLeavesLog: (params) => dispatch(exportLeavesLogAction(params)),
});
const LeavesDashboardContainer = connect(mapStateToProps, mapDispatchToProps)(LeavesDashboard);

export default LeavesDashboardContainer;
