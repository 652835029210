import React from 'react';
import { DatePicker } from 'antd';
import PropTypes from 'prop-types';

const EnhancedDatePicker = (props) => {
  const {
    children,
    className,
    style,
    name,
    dropdownClassName,
    onChange,
    // value,
    format,
    disabledDate,
    allowClear,
    disabled,
    required,
    // defaultPickerValue,
    id,
    attr,
    // placeholder,
    ...rest
  } = props;

  return (
    <DatePicker
      style={style}
      className={className}
      name={name}
      dropdownClassName={dropdownClassName}
      onChange={onChange}
      // value={value}
      format={format}
      disabledDate={disabledDate}
      allowClear={allowClear}
      disabled={disabled}
      required={required}
      // defaultPickerValue={defaultPickerValue}
      id={id}
      attr={attr}
      // placeholder={placeholder}
      {...rest} // other remaining properties
    >
      {children}
    </DatePicker>
  );
};

EnhancedDatePicker.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
};

EnhancedDatePicker.defaultProps = {
  className: '',
  style: {},
  allowClear: true,
  disabled: false,
};

EnhancedDatePicker.RangePicker = DatePicker.RangePicker;
EnhancedDatePicker.MonthPicker = DatePicker.MonthPicker;

export default EnhancedDatePicker;
