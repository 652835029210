import { connect } from 'react-redux';
import Competency from 'components/competencies/competencies';
import {
  getEmployeeCompetenciesAction,
  addCompetencyAction,
  editCompetencyAction,
  deleteCompetencyAction,
} from 'store/actions/EmployeeActions';

function mapStateToProps(state) {
  const { employeeReducer, auth } = state;
  const { competencies, isLoading } = employeeReducer;
  return {
    competencies,
    isLoading,
    userId: auth.user && auth.user.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEmployeeCompetenciesAction: () => dispatch(getEmployeeCompetenciesAction()),
    addCompetency: (request) => dispatch(addCompetencyAction(request)),
    editCompetency: (request) => dispatch(editCompetencyAction(request)),
    deleteCompetency: (request) => dispatch(deleteCompetencyAction(request)),
  };
}

const CompetencyContainer = connect(mapStateToProps, mapDispatchToProps)(Competency);

export default CompetencyContainer;
