import { EnhancedRow, EnhancedCard, EnhancedCol } from 'components/shared/antd';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';
import React from 'react';
import moment from 'moment';
import Authorize from '../Authorize';
import AccessPermissions from '../../constants/AccessPermissions';
import { DD_MMM_YYYY_HH_MM_SS } from '../../configs/employeeProfileConstants';

const LeaveDashboardModal = (props) => {
  const {
    params,
    isLoading,
    handlePageChange,
    adjustLeaveQuotaLogs,
    generateLeaveQuotaReport,
    adjustLeaveQuotaLogsPageInfo,
    onUpdateParams,
  } = props;

  const { page } = params;

  const renderUserName = (user) => {
    return user ? user.fullName : 'N/A';
  };

  const leavesAdjustmentLogsColumn = [
    {
      title: 'Taleo ID',
      dataIndex: 'oracleId',
      key: 'oracleId',
      filterConfig: {
        type: 'search',
        key: 'oracleId',
      },
      render: (value, record) =>
        record && record.employeeDetail && record.employeeDetail.oracleId
          ? record.employeeDetail.oracleId
          : 'N/A',
    },
    {
      title: 'Employee Name',
      dataIndex: 'employeeDetail',
      key: 'employeeDetail',
      filterConfig: {
        type: 'search',
        key: 'employeeName',
      },
      render: (value) => renderUserName(value),
    },
    {
      title: 'Adjusted By',
      dataIndex: 'adjustedByUser',
      key: 'adjustedByUser',
      render: (value) => renderUserName(value),
      filterConfig: {
        type: 'search',
        key: 'adjustedByUser',
      },
    },
    {
      title: 'Adjusted Count',
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    },
    {
      title: 'Count Before Adjustment',
      dataIndex: 'quotaBeforeAdjustment',
      key: 'quotaBeforeAdjustment',
      render: (value, record) =>
        record && record.quotaBeforeAdjustment ? record.quotaBeforeAdjustment : 'N/A',
    },
    {
      title: 'Count After Adjustment',
      dataIndex: 'quotaAfterAdjustment',
      key: 'quotaAfterAdjustment',
      render: (value, record) =>
        record && record.quotaAfterAdjustment ? record.quotaAfterAdjustment : 'N/A',
    },
    {
      title: 'Adjusted Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value, record) => moment(record.createdAt).format(`${DD_MMM_YYYY_HH_MM_SS}`),
      sorter: true,
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      render: (value, record) => (record && record.comment ? record.comment : 'N/A'),
    },
  ];

  return (
    <Authorize requiredPermissions={[AccessPermissions.Leave.AdjustmentLogs.List]}>
      <EnhancedCard style={{ marginTop: '20px' }}>
        <EnhancedRow gutter={3} style={{ backgroundColor: '#fff', marginTop: '30px' }}>
          <EnhancedCol span={24}>
            <EnhancedServerSideTable
              data={adjustLeaveQuotaLogs}
              loading={isLoading}
              columns={leavesAdjustmentLogsColumn}
              onChange={handlePageChange}
              paginationInfo={{
                totalItems: adjustLeaveQuotaLogsPageInfo.totalItems,
                pageSize: adjustLeaveQuotaLogsPageInfo.size,
                pageNumber: page + 1,
              }}
              updateParams={(updatedParams) => {
                onUpdateParams(updatedParams);
              }}
              scroll={{ x: 1400 }}
              exportFileConfig={{
                showExportButton: true,
                handler: generateLeaveQuotaReport,
              }}
            />
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedCard>
    </Authorize>
  );
};

export default LeaveDashboardModal;
