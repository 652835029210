import React from 'react';
import { InputNumber } from 'antd';
import PropTypes from 'prop-types';

const EnhancedInputNumber = (props) => {
  const {
    // children,
    className,
    style,
    placeholder,
    // value,
    onChange,
    min,
    max,
    id,
    onPressEnter,
    name,
    bordered,
    disabled,
    readOnly,
    step,
    ...rest
  } = props;

  return (
    <InputNumber
      style={style}
      className={className}
      placeholder={placeholder}
      // value={value}
      onChange={onChange}
      min={min}
      max={max}
      onPressEnter={onPressEnter}
      id={id}
      name={name}
      bordered={bordered}
      disabled={disabled}
      step={step}
      readOnly={readOnly}
      {...rest}
    />
  );
};

EnhancedInputNumber.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  placeholder: PropTypes.string,
  // value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  bordered: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.bool,
  onPressEnter: PropTypes.func,
  readOnly: PropTypes.bool,
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

EnhancedInputNumber.defaultProps = {
  className: '',
  style: {},
  placeholder: '',
  // value: '',
  name: '',
  onChange: () => {},
  id: '',
  bordered: true,
  disabled: false,
  onPressEnter: () => {},
  readOnly: false,
  max: Number.MAX_SAFE_INTEGER,
  min: Number.MIN_SAFE_INTEGER,
  step: 1,
};

export default EnhancedInputNumber;
