import {
  GET_MISCELLANEOUS_INIT,
  GET_MISCELLANEOUS_SUCCESS,
  GET_MISCELLANEOUS_FAILURE,
  GET_DESIGNATIONS_SUCCESS,
  GET_DESIGNATIONS_FAILURE,
  GET_JOB_DESCRIPTION_DOCUMENT_URL_INIT,
  GET_JOB_DESCRIPTION_DOCUMENT_URL_SUCCESS,
  GET_JOB_DESCRIPTION_DOCUMENT_URL_FAILURE,
} from 'constants/MiscellaneousTypes';

import {
  getMiscellaneousData,
  getDesignationsData,
  getJobDescriptionDocumentURL,
} from 'sources/MiscellaneousSource';

export const getMiscellaneousDataInit = () => ({
  type: GET_MISCELLANEOUS_INIT,
});

export const getMiscellaneousDataSuccess = (data) => ({
  type: GET_MISCELLANEOUS_SUCCESS,
  data,
});

export const getMiscellaneousDataFailure = () => ({
  type: GET_MISCELLANEOUS_FAILURE,
});

export const getDesignationsDataSuccess = (data) => ({
  type: GET_DESIGNATIONS_SUCCESS,
  data,
});

export const getDesignationsDataFailure = () => ({
  type: GET_DESIGNATIONS_FAILURE,
});

export const getJobDescriptionDocumentURLInit = () => ({
  type: GET_JOB_DESCRIPTION_DOCUMENT_URL_INIT,
});

export const getJobDescriptionDocumentURLSuccess = (url) => ({
  type: GET_JOB_DESCRIPTION_DOCUMENT_URL_SUCCESS,
  url,
});

export const getJobDescriptionDocumentURLFailure = () => ({
  type: GET_JOB_DESCRIPTION_DOCUMENT_URL_FAILURE,
});

export const getJobDescriptionDocumentURLAction = (documentId) =>
  getJobDescriptionDocumentURL(documentId);

export const getMiscellaneousDataAction = () => getMiscellaneousData();
export const getDesignationsDataAction = () => getDesignationsData();
