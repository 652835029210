import React from 'react';
import { Popover } from 'antd';
import PropTypes from 'prop-types';

const EnhancedPopover = (props) => {
  const { children, className, style, title, content } = props;
  return (
    <Popover className={className} style={style} title={title} content={content}>
      {children}
    </Popover>
  );
};

EnhancedPopover.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};

EnhancedPopover.defaultProps = {
  className: '',
  style: {},
};

export default EnhancedPopover;
