import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import { projectRoleUrls } from 'configs/constants';

import {
  projectRolesInit,
  projectRolesFailure,
  getProjectRolesSuccess,
  addProjectRoleSuccess,
  editProjectRoleSuccess,
  deleteProjectRoleSuccess,
} from '../store/actions/ProjectRolesActions';

export const getProjectRoles = () => (dispatch) => {
  const url = projectRoleUrls.GET;
  dispatch(projectRolesInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        dispatch(getProjectRolesSuccess(response.data.data));
      } else {
        dispatch(projectRolesFailure());
      }
    })
    .catch(() => {
      dispatch(projectRolesFailure());
    });
};

export const addProjectRole = (request) => (dispatch) => {
  const url = projectRoleUrls.POST;
  dispatch(projectRolesInit());
  axios
    .post(url, request)
    .then((response) => {
      EnhancedNotification.success({
        message: 'Created successfully',
      });
      dispatch(addProjectRoleSuccess(response.data.data));
    })
    .catch((error) => {
      try {
        EnhancedNotification.error({
          message: error.response.data.message,
        });
      } catch (err) {
        EnhancedNotification.error({
          message: 'Unable to create project role',
        });
      }
      dispatch(projectRolesFailure());
    });
};

export const editProjectRole = (request) => (dispatch) => {
  const url = projectRoleUrls.PUT.replace('{id}', request.id);
  dispatch(projectRolesInit());
  axios
    .put(url, request)
    .then(() => {
      EnhancedNotification.success({
        message: 'Updated successfully',
      });
      dispatch(editProjectRoleSuccess(request));
    })
    .catch((error) => {
      try {
        EnhancedNotification.error({
          message: error.response.data.message,
        });
      } catch (err) {
        EnhancedNotification.error({
          message: 'Unable to update project role',
        });
      }
      dispatch(projectRolesFailure());
    });
};

export const deleteProjectRole = (projectRoleId) => (dispatch) => {
  const url = projectRoleUrls.DELETE.replace('{id}', projectRoleId);
  dispatch(projectRolesInit());
  axios
    .delete(url)
    .then(() => {
      EnhancedNotification.success({
        message: 'Deleted successfully',
      });
      dispatch(deleteProjectRoleSuccess(projectRoleId));
    })
    .catch((error) => {
      try {
        EnhancedNotification.error({
          message: error.response.data.message,
        });
      } catch (err) {
        EnhancedNotification.error({
          message: 'Unable to delete project role',
        });
      }
      dispatch(projectRolesFailure());
    });
};
