import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedIcon,
  EnhancedModal,
  EnhancedSelect,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';
import { Link } from 'react-router-dom';

import AddFormModal from './AddFormModal';

const { confirm } = EnhancedModal;
const { Option } = EnhancedSelect;

const Listing = (props) => {
  const {
    // userRole,
    updateGoalCategory,
    getGoalCategory,
    addGoalCategory,
    removeGoalCategory,
    getGoalCategoryDetails,
    list,
    isLoading,
    details,
  } = props;

  const [isGoalFormModalVisible, setIsGoalFormModalVisible] = useState(false);
  const [params, setParams] = useState({
    page: 0,
    size: 10,
  });

  const [isEdit, setIsEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);

  useEffect(() => {
    getGoalCategory(params);
  }, [params]);

  const setInitialState = () => {
    setIsAdd(false);
    setIsEdit(false);
  };

  const showGoalFormModal = (isEditValue) => {
    if (isEditValue) setIsEdit(isEditValue);
    else setIsAdd(true);

    setIsGoalFormModalVisible(true);
  };

  const handleGoalFormOk = () => {
    setIsGoalFormModalVisible(false);
    setInitialState();
  };

  const handleGoalFormCancel = () => {
    setIsGoalFormModalVisible(false);
    setInitialState();
  };

  const handleGoalCategoryDelete = ({ id, userId }) => {
    confirm({
      title: 'Are you sure you want to delete this goal Category?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        let pageNumber = params.page;
        const dataCount = list.count;
        const pageLastRecord = (dataCount - 1) % 10;

        if (pageLastRecord === 0 && pageNumber > 0) {
          pageNumber = params.page - 1;
          setParams({
            ...params,
            page: pageNumber,
          });
        }

        removeGoalCategory({
          id,
          userId,
          params: {
            ...params,
            page: pageNumber,
          },
        });
      },
    });
  };

  const changeActionHandler = (key, id, _record) => {
    setInitialState();
    if (key === '1') {
      setIsEdit(true);
      showGoalFormModal(true);
      getGoalCategoryDetails({ id, userId: _record.userId });
    } else if (key === '2') {
      handleGoalCategoryDelete({ id, userId: _record.userId });
    }
  };

  const optionMenu = [
    {
      value: '1',
      label: 'Edit',
    },
    {
      value: '2',
      label: 'Delete',
    },
  ];

  const setActionsHandler = (id, _record) => {
    return (
      <EnhancedSelect
        style={{
          width: 120,
        }}
        className="action-selection-dropdown"
        placeholder="Options"
        value="Options"
        onChange={(key) => changeActionHandler(key, id, _record)}
      >
        {optionMenu.map((item) => (
          <Option value={item.value}>{item.label}</Option>
        ))}
      </EnhancedSelect>
    );
  };

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      page: pagination.current - 1,
      size: pagination.pageSize,
    });
  };

  const renderTitle = (name, data) => (
    <Link
      to={{
        pathname: `goals-categories/${data.id}`,
        categoryId: data.id,
      }}
    >
      <b style={{ color: '#5A5A5A' }}>{name}</b>
    </Link>
  );

  const getColumnsInfo = () => {
    const columns = [
      {
        title: 'Category Title',
        dataIndex: 'title',
        key: 'title',
        filterConfig: {
          type: 'search',
          key: 'title',
        },
        render: renderTitle,
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        render: (id, _record) => setActionsHandler(id, _record),
      },
    ];
    return columns;
  };

  return (
    <div>
      <EnhancedCard>
        <React.Fragment>
          <EnhancedRow type="flex" justify="space-between" align="middle" className="mb-20">
            <EnhancedCol>
              <h1 className="mb-0">Goals Categories</h1>
            </EnhancedCol>
            <EnhancedCol>
              <EnhancedButton className="btn-edit" onClick={() => showGoalFormModal(false)}>
                <EnhancedIcon type="plus-circle" />
                <span>Add New Category</span>
              </EnhancedButton>
            </EnhancedCol>
          </EnhancedRow>
          <EnhancedServerSideTable
            rowKey={(record) => record.id}
            loading={isLoading}
            columns={getColumnsInfo()}
            data={list.rows}
            onChange={handleTableChange}
            className="goal-table-container"
            paginationInfo={{
              totalItems: list.count,
              pageSize: params.size,
              pageNumber: params.page + 1,
            }}
            scroll={{ x: 'max-content' }}
            updateParams={(updatedParams) => {
              setParams({
                ...params,
                ...updatedParams,
                page: 0, // move to page no 0 in case filters are applied
              });
            }}
          />

          <AddFormModal
            isModalVisible={isGoalFormModalVisible}
            handleOk={handleGoalFormOk}
            handleCancel={handleGoalFormCancel}
            addGoalCategoryActions={addGoalCategory}
            updateGoalCategoryActions={updateGoalCategory}
            params={params}
            isEdit={isEdit}
            isAdd={isAdd}
            editableHandler={setIsEdit}
            isLoading={isLoading}
            details={details}
          />
        </React.Fragment>
      </EnhancedCard>
    </div>
  );
};

export default Listing;
