import { EnhancedNotification } from 'components/shared/antd';
import {
  addPMCycle,
  editPMCycle,
  deletePMCycle,
  getPMCycle,
  getPMEmpCycle,
  getPMCycleDetail,
  getEmployeesExport,
  sendPMCycleInitiationEmail,
  addEmployees,
  deleteEmployee,
  closePMCycle,
  showPreviousPMCycle,
  getRatingOptions,
} from 'sources/PerformanceMangementSource';

import {
  ADD_PMCYCLE_INIT,
  ADD_PMCYCLE_SUCCESS,
  ADD_PMCYCLE_FAILURE,
  EDIT_PMCYCLE_INIT,
  EDIT_PMCYCLE_SUCCESS,
  EDIT_PMCYCLE_FAILURE,
  DELETE_PMCYCLE_INIT,
  DELETE_PMCYCLE_SUCCESS,
  DELETE_PMCYCLE_FAILURE,
  GET_PMCYCLE_INIT,
  GET_PMCYCLE_SUCCESS,
  GET_PMCYCLE_FAILURE,
  GET_PREVIOUS_PMCYCLE_INIT,
  GET_PREVIOUS_PMCYCLE_SUCCESS,
  GET_PREVIOUS_PMCYCLE_FAILURE,
  GET_PM_EMP_CYCLE_INIT,
  GET_PM_EMP_CYCLE_SUCCESS,
  GET_PM_EMP_CYCLE_FAILURE,
  ADD_EMPLOYEES_PM_CYCLE_INIT,
  ADD_EMPLOYEES_PM_CYCLE_SUCCESS,
  ADD_EMPLOYEES_PM_CYCLE_FAILURE,
  DELETE_EMPLOYEE_PM_CYCLE_INIT,
  DELETE_EMPLOYEE_PM_CYCLE_SUCCESS,
  DELETE_EMPLOYEE_PM_CYCLE_FAILURE,
  GET_PMCYCLE_DETAIL_INIT,
  GET_PMCYCLE_DETAIL_SUCCESS,
  GET_PMCYCLE_DETAIL_FAILURE,
  GET_RATING_OPTIONS_INIT,
  GET_RATING_OPTIONS_SUCCESS,
  GET_RATING_OPTIONS_FAILURE,
} from 'constants/PerformanceManagementTypes';

import { showLoader, hideLoader } from 'store/actions/AppLoaderActions';

import { generatePerformanceCycleEmployeesReport } from '../../utils/performanceManagementUtils';

export const addPMCycleInit = () => ({
  type: ADD_PMCYCLE_INIT,
});

export const addPMCycleSuccess = (data) => ({
  type: ADD_PMCYCLE_SUCCESS,
  data,
});

export const addPMCyclelFailure = () => ({
  type: ADD_PMCYCLE_FAILURE,
});

export const editPMCycleInit = () => ({
  type: EDIT_PMCYCLE_INIT,
});

export const editPMCycleSuccess = (data) => ({
  type: EDIT_PMCYCLE_SUCCESS,
  data,
});

export const editPMCyclelFailure = () => ({
  type: EDIT_PMCYCLE_FAILURE,
});

export const deletePMCycleInit = () => ({
  type: DELETE_PMCYCLE_INIT,
});

export const deletePMCycleSuccess = (data) => ({
  type: DELETE_PMCYCLE_SUCCESS,
  data,
});

export const deletePMCyclelFailure = () => ({
  type: DELETE_PMCYCLE_FAILURE,
});

export const getPMCycleInit = () => ({
  type: GET_PMCYCLE_INIT,
});

export const getPMCycleSuccess = (data) => ({
  type: GET_PMCYCLE_SUCCESS,
  data,
});

export const getPMCycleFailure = (data) => ({
  type: GET_PMCYCLE_FAILURE,
  data,
});

export const getPreviousPMCycleInit = () => ({
  type: GET_PREVIOUS_PMCYCLE_INIT,
});

export const getPreviousPMCycleSuccess = (data) => ({
  type: GET_PREVIOUS_PMCYCLE_SUCCESS,
  data,
});

export const getPreviousPMCycleFailure = (data) => ({
  type: GET_PREVIOUS_PMCYCLE_FAILURE,
  data,
});

export const getPMCycleDetailInit = () => ({
  type: GET_PMCYCLE_DETAIL_INIT,
});

export const getPMCycleDetailSuccess = (data) => ({
  type: GET_PMCYCLE_DETAIL_SUCCESS,
  data,
});

export const getPMCycleDetailFailure = (data) => ({
  type: GET_PMCYCLE_DETAIL_FAILURE,
  data,
});

export const getPMEmpCycleInit = () => ({
  type: GET_PM_EMP_CYCLE_INIT,
});

export const getPMEmpCycleSuccess = (data) => ({
  type: GET_PM_EMP_CYCLE_SUCCESS,
  data,
});

export const getPMEmpCycleFailure = (data) => ({
  type: GET_PM_EMP_CYCLE_FAILURE,
  data,
});

export const addEmployeesInit = () => ({
  type: ADD_EMPLOYEES_PM_CYCLE_INIT,
});

export const addEmployeesSuccess = () => ({
  type: ADD_EMPLOYEES_PM_CYCLE_SUCCESS,
});

export const addEmployeesFailure = () => ({
  type: ADD_EMPLOYEES_PM_CYCLE_FAILURE,
});
export const deleteEmployeeInit = () => ({
  type: DELETE_EMPLOYEE_PM_CYCLE_INIT,
});

export const deleteEmployeeSuccess = () => ({
  type: DELETE_EMPLOYEE_PM_CYCLE_SUCCESS,
});

export const deleteEmployeeFailure = () => ({
  type: DELETE_EMPLOYEE_PM_CYCLE_FAILURE,
});

export const getRatingOptionsInit = () => ({
  type: GET_RATING_OPTIONS_INIT,
});

export const getRatingOptionsSuccess = (data) => ({
  type: GET_RATING_OPTIONS_SUCCESS,
  data,
});

export const getRatingOptionsFailure = () => ({
  type: GET_RATING_OPTIONS_FAILURE,
});

export const deleteEmployeeAction = (requestObject) => (dispatch) => {
  dispatch(deleteEmployee(requestObject));
};

export const addEmployeesAction = (requestObject, handleHideModal) => (dispatch) => {
  dispatch(addEmployees(requestObject, handleHideModal));
};

export const addPMCycleActions = (requestObject) => (dispatch) => {
  dispatch(addPMCycle(requestObject));
};

export const editPMCycleActions = (requestObject) => (dispatch) => {
  dispatch(editPMCycle(requestObject));
};

export const showPreviousPMCycleActions = (requestObject) => (dispatch) => {
  dispatch(showPreviousPMCycle(requestObject));
};

export const deletePMCycleActions = (requestObject) => (dispatch) => {
  dispatch(deletePMCycle(requestObject));
};

export const getPMCycleActions = (requestObject) => (dispatch) => {
  dispatch(getPMCycle(requestObject));
};

export const getPMCycleDetailAction = (id) => (dispatch) => {
  dispatch(getPMCycleDetail(id));
};

export const getPMEmpCycleActions = (requestObject) => (dispatch) => {
  dispatch(getPMEmpCycle(requestObject));
};

export const exportAllEmpPerformanceCycleAction = (params) => (dispatch) => {
  dispatch(showLoader());
  return getEmployeesExport(params)
    .then((response) => {
      generatePerformanceCycleEmployeesReport(response, params.pmCycleTitle);
      dispatch(hideLoader());
    })
    .catch(() => {
      dispatch(hideLoader());
      EnhancedNotification.error({ message: 'Unable to download all Performance Cycle list.' });
    });
};

export const sendInitiationEmailAction = (params) => (dispatch) => {
  dispatch(showLoader());
  return sendPMCycleInitiationEmail(params)
    .then(() => {
      EnhancedNotification.success({
        message: 'Performance Cycle Initiation email has been triggered.',
      });
      dispatch(hideLoader());
    })
    .catch(() => {
      dispatch(hideLoader());
      EnhancedNotification.error({ message: 'Unable to send email.' });
    });
};

export const closePMCycleActions = (requestObject) => (dispatch) => {
  dispatch(closePMCycle(requestObject));
};

export const getRatingOptionsActions = () => (dispatch) => {
  dispatch(getRatingOptions());
};
