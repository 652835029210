/* eslint-disable */
import React, { Component } from 'react';

class SVGProvider extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const data = this.props;

    return (
      <div>
        <svg width="43px" height="44px">
          <title>ic_date</title>
          <desc>Created with Sketch.</desc>
          <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="ListItem-Event" transform="translate(0.000000, -3.000000)">
              <g id="ic_date" transform="translate(1.000000, 4.000000)">
                <g
                  id="Group"
                  transform="translate(0.000000, 30.000000)"
                  fill={data.today ? '#2c82be' : '#99c34f'}
                >
                  <g id="Rectangle_green">
                    <rect x="0" y="0" width="41" height="11" />
                  </g>
                </g>
                <text
                  id="APR"
                  fontFamily="OpenSans-SemiBold, Open Sans"
                  fontSize="10"
                  fontWeight="500"
                  letterSpacing="-0.4166667"
                  fill="#FFFFFF"
                >
                  <tspan x="12.0473633" y="39">
                    {data.month}
                  </tspan>
                </text>
                <text
                  id="06"
                  fontFamily="OpenSans-SemiBold, Open Sans"
                  fontSize="18"
                  fontWeight="500"
                  letterSpacing="-0.7500001"
                  fill={data.today ? '#2c82be' : '#99c34f'}
                >
                  <tspan x="10.975586" y="21">
                    {data.date}
                  </tspan>
                </text>
                <rect
                  id="Rectangle"
                  stroke="#BFBFBF"
                  strokeWidth="1.5"
                  x="-0.25"
                  y="0"
                  width="41.5"
                  height="41.5"
                  rx="1"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

export default SVGProvider;
