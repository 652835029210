import { EnhancedIcon, EnhancedMenu } from 'components/shared/antd';
import React from 'react';
import resourceIcon from 'images/resource_ic.svg';
import {
  ROOT,
  FAQS,
  LOGOUT,
  ADMIN_REPORT,
  ADMIN_MISSING_ALLOCATION,
  MY_LEAVE_REQUEST,
  LEAVE_DASHBOARD,
  LEAVE_CALENDAR,
  TEAM_LEAVE_REQUEST,
  PROFILE,
  CRMC_DAHBOARD,
  CRMC_EMPLOYEES_CURRENT,
  CRMC_EMPLOYEES_INACTIVE,
  CRMC_EMPLOYEES_BILLABLE,
  CRMC_EMPLOYEES_NON_BILLABLE,
  CRMC_PROJECTS_CURRENT,
  CRMC_PROJECTS_COMPLETED,
  EMPLOYEES_ALL,
  HR_LEAVES_DASHBOARD,
  ROLES,
  SKILLS,
  EMPLOYMENT_TYPES,
  DEPARTMENTS,
  RESOURCES,
  COMPETENCIES,
  BUSINESS_UNIT,
  DIVISION,
  DESIGNATIONS,
  LOCATIONS,
  PROJECT_ROLES,
  PERFORMANCE_CYCLE,
  EMPLOYEE_RATINGS,
  EMPLOYEE_SEARCH,
  EMPLOYEE_ORG_TREE,
  PERFORMANCE_CYCLE_CRMC,
  PROJECT_REVIEWS,
  FINANCE_BILLING,
  EXPENSE_CLAIM_REQUEST,
  EXPENSE_CLAIM_LIST,
  EXPENSE_CLAIM_FAQ,
  RCA_PROJECTS,
  FEEDBACK_REDIRECT_URL,
  LOGWORK,
  RESOURCE_PLANNER_DEAL_SUMMARY,
  RESOURCE_PLANNER_REPORT_RESOURCE_DEFICIT,
  RESOURCE_PLANNER_REPORT_PLANNING_SUMMARY,
  RESOURCE_PLANNER_REPORT_BENCH_SUMMARY,
  TRANSIENT_RESOURCE,
  ALL_GOALS,
  PREDEFINED_GOALS,
  PREDEFINED_GOALS_CATEGORIES,
  INTERVIEW_TRACKING,
  INTERVIEW_QUESTIONS,
  INTERVIEW_QUESTIONS_FREQUENCY,
  PERFORMANCE_MANAGEMENT,
  LM_DASHBAORD,
  ADMIN_DASHBAORD,
  LM_DASHBOARD_PERFORMANCE_MANAGEMENT,
  DEALS,
} from 'configs/routesConstants';
import { FEEDBACK_REDIRECT } from 'configs/constants';
import Cookies from 'js-cookie';
import { setCookie, getJWTtoken } from '../utils/storageUtils';
import Permissions from '../constants/AccessPermissions';
import { EMPLOYEES } from '../configs/employeeProfileConstants';

import AuthorizedSubMenu from './AuthorizedSubMenu';
import AuthorizedMenuItem from './AuthorizedMenuItem';

const DEFAULT_SELECTION = 'homepage';
const { SubMenu } = EnhancedMenu;

class AppMenu extends React.Component {
  constructor(props) {
    super(props);
    const { pathname } = props.history.location;
    let route = DEFAULT_SELECTION;
    if (pathname !== ROOT) {
      const pathArray = pathname.split(ROOT);
      route = pathArray[pathArray.length - 1];
    }
    this.state = {
      selectedKey: route,
    };
  }

  onSelect = ({ key }) => {
    this.setState({ selectedKey: key });
    switch (key) {
      case 'logout': {
        this.pushToRoute(LOGOUT);
        break;
      }
      case 'logwork': {
        this.pushToRoute(LOGWORK);
        break;
      }
      case 'report': {
        this.pushToRoute(ADMIN_REPORT);
        break;
      }
      case 'missing-allocations': {
        this.pushToRoute(ADMIN_MISSING_ALLOCATION);
        break;
      }
      case 'faqs': {
        this.pushToRoute(FAQS);
        break;
      }
      case 'my-leave-requests': {
        this.pushToRoute(MY_LEAVE_REQUEST);
        break;
      }
      case 'team-leave-requests': {
        this.pushToRoute(TEAM_LEAVE_REQUEST);
        break;
      }
      case 'leave-dashboard': {
        this.pushToRoute(LEAVE_DASHBOARD);
        break;
      }
      case 'lm-dashboard': {
        this.pushToRoute(LM_DASHBAORD);
        break;
      }
      case 'pm-admin-dashboard': {
        this.pushToRoute(ADMIN_DASHBAORD);
        break;
      }
      case 'leave-calendar': {
        this.pushToRoute(LEAVE_CALENDAR);
        break;
      }
      case 'profile': {
        this.pushToRoute(PROFILE);
        break;
      }
      case 'dashboard': {
        this.pushToRoute(CRMC_DAHBOARD);
        break;
      }
      case 'current-employees': {
        this.pushToRoute(CRMC_EMPLOYEES_CURRENT);
        break;
      }
      case 'all-employees': {
        this.pushToRoute(`${ROOT}${EMPLOYEES}${EMPLOYEES_ALL}`);
        break;
      }
      case 'inactive-employees': {
        this.pushToRoute(CRMC_EMPLOYEES_INACTIVE);
        break;
      }
      case 'billable': {
        this.pushToRoute(CRMC_EMPLOYEES_BILLABLE);
        break;
      }
      case 'non-billable': {
        this.pushToRoute(CRMC_EMPLOYEES_NON_BILLABLE);
        break;
      }
      case 'current-projects': {
        this.pushToRoute(CRMC_PROJECTS_CURRENT);
        break;
      }
      case 'completed-projects': {
        this.pushToRoute(CRMC_PROJECTS_COMPLETED);
        break;
      }
      case 'leaves-dashboard': {
        this.pushToRoute(HR_LEAVES_DASHBOARD);
        break;
      }
      case 'roles': {
        this.pushToRoute(ROLES);
        break;
      }
      case 'skills': {
        this.pushToRoute(SKILLS);
        break;
      }
      case 'employment-types': {
        this.pushToRoute(EMPLOYMENT_TYPES);
        break;
      }
      case 'departments': {
        this.pushToRoute(DEPARTMENTS);
        break;
      }
      case 'designations': {
        this.pushToRoute(DESIGNATIONS);
        break;
      }
      case 'locations': {
        this.pushToRoute(LOCATIONS);
        break;
      }
      case 'competencies': {
        this.pushToRoute(COMPETENCIES);
        break;
      }
      case 'business-unit': {
        this.pushToRoute(BUSINESS_UNIT);
        break;
      }
      case 'division': {
        this.pushToRoute(DIVISION);
        break;
      }
      case 'project-roles': {
        this.pushToRoute(PROJECT_ROLES);
        break;
      }
      case 'performance-cycles': {
        this.pushToRoute(PERFORMANCE_CYCLE);
        break;
      }
      case 'employee-ratings': {
        this.pushToRoute(EMPLOYEE_RATINGS);
        break;
      }
      case 'employee-directory': {
        this.pushToRoute(EMPLOYEE_SEARCH);
        break;
      }
      case 'employee-organization-tree': {
        this.pushToRoute(EMPLOYEE_ORG_TREE);
        break;
      }
      case 'performance-cycles-crmc': {
        this.pushToRoute(PERFORMANCE_CYCLE_CRMC);
        break;
      }
      case 'project-reviews': {
        this.pushToRoute(PROJECT_REVIEWS);
        break;
      }
      case 'billing': {
        this.pushToRoute(FINANCE_BILLING);
        break;
      }
      case 'claim-request': {
        this.pushToRoute(EXPENSE_CLAIM_REQUEST);
        break;
      }
      case 'view-expense-claim': {
        this.pushToRoute(EXPENSE_CLAIM_LIST);
        break;
      }
      case 'claim-faqs': {
        this.pushToRoute(EXPENSE_CLAIM_FAQ);
        break;
      }
      case 'rca/projects': {
        this.pushToRoute(RCA_PROJECTS);
        break;
      }
      case 'resources': {
        this.pushToRoute(RESOURCES);
        break;
      }
      case 'homepage': {
        this.pushToRoute(ROOT);
        break;
      }
      case 'dealSummary': {
        this.pushToRoute(RESOURCE_PLANNER_DEAL_SUMMARY);
        break;
      }
      case 'transientResource': {
        this.pushToRoute(TRANSIENT_RESOURCE);
        break;
      }
      case 'resourceDeficit': {
        this.pushToRoute(RESOURCE_PLANNER_REPORT_RESOURCE_DEFICIT);
        break;
      }
      case 'planningSummary': {
        this.pushToRoute(RESOURCE_PLANNER_REPORT_PLANNING_SUMMARY);
        break;
      }
      case 'benchSummary': {
        window
          .open(`${window.location.origin}${RESOURCE_PLANNER_REPORT_BENCH_SUMMARY}`, '_blank')
          .focus();
        break;
      }
      case 'goals': {
        this.pushToRoute(ALL_GOALS);
        break;
      }
      case 'predefined-goals': {
        this.pushToRoute(PREDEFINED_GOALS);
        break;
      }
      case 'predefined-goals-categories': {
        this.pushToRoute(PREDEFINED_GOALS_CATEGORIES);
        break;
      }
      case 'interview-tracking': {
        this.pushToRoute(INTERVIEW_TRACKING);
        break;
      }
      case 'interview-questions': {
        this.pushToRoute(INTERVIEW_QUESTIONS);
        break;
      }
      case 'interview-questions-frequency': {
        this.pushToRoute(INTERVIEW_QUESTIONS_FREQUENCY);
        break;
      }
      case 'performance-management': {
        this.pushToRoute(PERFORMANCE_MANAGEMENT);
        break;
      }
      case 'lm-performance-management-dashboard': {
        this.pushToRoute(LM_DASHBOARD_PERFORMANCE_MANAGEMENT);
        break;
      }
      case 'deals': {
        this.pushToRoute(DEALS);
        break;
      }
      default:
    }
  };
  setCokieesOnClick = () => {
    if (!Cookies.get('tokenid')) {
      setCookie(getJWTtoken());
    }
  };
  pushToRoute = (route) => {
    if (this.props.history.location.pathname !== route) {
      this.props.history.push(route);
    }
  };
  render() {
    const {
      // isAdmin,
      isManager,
      // isLogger,
      isCRMC1,
      isCRMC2,
      internal,
      isPM,
      logout,
      isFunctionalHead,
      isRater,
      isLeadership,
      isDM,
      isRCA,
    } = this.props;
    const { selectedKey } = this.state;
    return (
      <EnhancedMenu
        theme="dark"
        className="side-app-menu"
        mode="inline"
        defaultSelectedKeys={[DEFAULT_SELECTION]}
        selectedKeys={[selectedKey]}
        onClick={this.onSelect}
      >
        <EnhancedMenu.Item key="homepage">
          {/* <img src={resourceIcon} className="resource-icon" alt="" /> */}
          <EnhancedIcon type="home" className="resource-icon" />
          <span className="resource-text">Home</span>
        </EnhancedMenu.Item>
        {/* <EnhancedMenu.Item key="profile">
          <Icon type="user" />
          <span>My Profile</span>
        </EnhancedMenu.Item> */}
        <AuthorizedSubMenu
          key="resource-planner"
          requiredPermissions={[
            Permissions.ResourcePlanner.TransientResource.View,
            Permissions.ResourcePlanner.Deal.Summary.View,
            Permissions.ResourcePlanner.Reports.Summary.Resources.View,
            Permissions.ResourcePlanner.Reports.Summary.ResourcesStatusWise.View,
            Permissions.ResourcePlanner.Reports.Summary.UnPlannedResources.View,
            Permissions.ResourcePlanner.Reports.Bench.Resources.View,
            Permissions.ResourcePlanner.Reports.ResourceDeficit.View,
          ]}
          title={
            <span>
              <img src={resourceIcon} className="resource-icon" alt="" />
              <span className="resource-text">Resource Planner</span>
            </span>
          }
        >
          <AuthorizedSubMenu
            key="transientResource"
            requiredPermissions={[Permissions.ResourcePlanner.TransientResource.View]}
            title={
              <span>
                <img src={resourceIcon} className="resource-icon" alt="" />
                <span className="resource-text">Resources</span>
              </span>
            }
          >
            <AuthorizedMenuItem
              key="transientResource"
              requiredPermissions={[Permissions.ResourcePlanner.TransientResource.View]}
            >
              <span>Transient Resource</span>
            </AuthorizedMenuItem>
          </AuthorizedSubMenu>
          <AuthorizedSubMenu
            key="rp-deals"
            requiredPermissions={[Permissions.ResourcePlanner.Deal.Summary.List]}
            title={
              <span>
                <img src={resourceIcon} className="resource-icon" alt="" />
                <span className="resource-text">Deals</span>
              </span>
            }
          >
            <AuthorizedMenuItem
              key="deals"
              requiredPermissions={[Permissions.ResourcePlanner.Deal.Summary.List]}
            >
              <span>Deals Summary</span>
            </AuthorizedMenuItem>
          </AuthorizedSubMenu>
          <AuthorizedSubMenu
            key="rp-reports"
            requiredPermissions={[
              Permissions.ResourcePlanner.Reports.ResourceDeficit.View,
              Permissions.ResourcePlanner.Reports.Summary.Resources.View,
              Permissions.ResourcePlanner.Reports.Bench.Resources.View,
            ]}
            title={
              <span>
                <img src={resourceIcon} className="resource-icon" alt="" />
                <span className="resource-text">Reports</span>
              </span>
            }
          >
            <AuthorizedMenuItem
              key="resourceDeficit"
              requiredPermissions={[Permissions.ResourcePlanner.Reports.ResourceDeficit.View]}
            >
              <span>Resource Deficit</span>
            </AuthorizedMenuItem>
            <AuthorizedMenuItem
              key="planningSummary"
              requiredPermissions={[Permissions.ResourcePlanner.Reports.Summary.Resources.View]}
            >
              <span>Planning Summary</span>
            </AuthorizedMenuItem>
            <AuthorizedMenuItem
              key="benchSummary"
              requiredPermissions={[Permissions.ResourcePlanner.Reports.Bench.Resources.View]}
            >
              <span>Bench Summary</span>
            </AuthorizedMenuItem>
          </AuthorizedSubMenu>
        </AuthorizedSubMenu>
        {/* <SubMenu
          key="resource-allocation"
          title={
            <span>
              <img src={resourceIcon} className="resource-icon" alt="" />
              <span className="resource-text">Resource Allocation</span>
            </span>
          }
        >
          {isLogger ? (
            <EnhancedMenu.Item key="logwork">
              <span>Log Work</span>
            </EnhancedMenu.Item>
          ) : (
            ''
          )}
          {isAdmin ? (
            <EnhancedMenu.Item key="report">
              <span>Reports</span>
            </EnhancedMenu.Item>
          ) : (
            ''
          )}
          {isAdmin ? (
            <EnhancedMenu.Item key="missing-allocations">
              <span>Missing Allocations</span>
            </EnhancedMenu.Item>
          ) : (
            ''
          )}
          <EnhancedMenu.Item key="faqs">
            <span>FAQs</span>
          </EnhancedMenu.Item>
        </SubMenu> */}
        <AuthorizedSubMenu
          key="projects"
          title={
            <span>
              <EnhancedIcon type="appstore-o" />
              <span className="resource-text">Projects</span>
            </span>
          }
          requiredPermissions={[Permissions.Project.List, Permissions.Project.Current.List]}
        >
          <AuthorizedMenuItem
            key="current-projects"
            requiredPermissions={[Permissions.Project.List, Permissions.Project.Current.List]}
          >
            <span>Current</span>
          </AuthorizedMenuItem>
          <AuthorizedMenuItem
            key="completed-projects"
            requiredPermissions={[Permissions.Project.Completed.List]}
          >
            <span>Completed</span>
          </AuthorizedMenuItem>
        </AuthorizedSubMenu>
        {(isRater || isCRMC1 || isFunctionalHead || isManager || isLeadership || isDM) && (
          <SubMenu
            key="performance-cycles"
            title={
              <span>
                <EnhancedIcon type="appstore-o" />
                <span className="resource-text">Performance Cycle</span>
              </span>
            }
          >
            {isRater && (
              <EnhancedMenu.Item key="performance-cycles">
                <span>View Cycles</span>
              </EnhancedMenu.Item>
            )}
            {isCRMC1 && (
              <EnhancedMenu.Item key="performance-cycles-crmc">
                <span>CRMC Panel</span>
              </EnhancedMenu.Item>
            )}
            {(isFunctionalHead || isManager || isCRMC1) && (
              <EnhancedMenu.Item key="employee-ratings">
                {/* <Icon type="area-chart" /> */}
                <span className="resource-text">Individual Reporting</span>
              </EnhancedMenu.Item>
            )}
            {(isCRMC1 || isLeadership || isDM || isFunctionalHead) && (
              <EnhancedMenu.Item key="project-reviews">
                <span>Project Reporting</span>
              </EnhancedMenu.Item>
            )}
          </SubMenu>
        )}
        <SubMenu
          key="leaves-management"
          title={
            <span>
              <EnhancedIcon type="calendar" />
              <span className="resource-text">Leaves Management</span>
            </span>
          }
        >
          <EnhancedMenu.Item key="my-leave-requests">
            <span>My Requests</span>
          </EnhancedMenu.Item>
          {(isManager || isPM || isCRMC2 || isDM) && (
            <EnhancedMenu.Item key="team-leave-requests">
              <span>Team Requests</span>
            </EnhancedMenu.Item>
          )}
          {isManager && (
            <EnhancedMenu.Item key="leave-dashboard">
              <span>Leave Dashboard</span>
            </EnhancedMenu.Item>
          )}
          {isManager && (
            <EnhancedMenu.Item key="leave-calendar">
              <span>Leave Calendar</span>
            </EnhancedMenu.Item>
          )}
          {internal && (
            <EnhancedMenu.Item key="leaves-dashboard">
              <span>Company-wide Dashboard</span>
            </EnhancedMenu.Item>
          )}
        </SubMenu>
        {/* <EnhancedMenu.Item key="lm-dashboard">
          <Icon type="layout" />
          <span>LM Dashboard</span>
        </EnhancedMenu.Item> */}
        {/* <AuthorizedSubMenu
          key="my-vendians"
          title={
            <span>
              <EnhancedIcon type="layout" />
              <span className="resource-text">My VenDians</span>
            </span>
          }
          requiredPermissions={[Permissions.Dashboard.View]}
        >
          <AuthorizedMenuItem key="dashboard" requiredPermissions={[Permissions.Dashboard.View]}>
            <span>Dashboard</span>
          </AuthorizedMenuItem>
        </AuthorizedSubMenu> */}
        <AuthorizedSubMenu
          key="employees"
          title={
            <span>
              <EnhancedIcon type="team" />
              <span className="resource-text">Employees</span>
            </span>
          }
          requiredPermissions={[
            Permissions.Employee.List,
            Permissions.Employee.Billable.List,
            Permissions.Employee.All.List,
          ]}
        >
          <AuthorizedMenuItem
            key="all-employees"
            requiredPermissions={[Permissions.Employee.All.List]}
          >
            <span>All</span>
          </AuthorizedMenuItem>
          <AuthorizedMenuItem
            key="current-employees"
            requiredPermissions={[Permissions.Employee.List]}
          >
            <span>Current</span>
          </AuthorizedMenuItem>
          <AuthorizedMenuItem
            key="inactive-employees"
            requiredPermissions={[Permissions.Employee.List]}
          >
            <span>Inactive</span>
          </AuthorizedMenuItem>
          <AuthorizedMenuItem
            key="billable"
            requiredPermissions={[Permissions.Employee.Billable.List]}
          >
            <span>Billable Resources</span>
          </AuthorizedMenuItem>
          <AuthorizedMenuItem key="non-billable" requiredPermissions={[Permissions.Employee.List]}>
            <span>Non-Billable Resources</span>
          </AuthorizedMenuItem>
        </AuthorizedSubMenu>
        <AuthorizedSubMenu
          key="configuration"
          title={
            <span>
              <EnhancedIcon type="appstore-o" />
              <span className="resource-text">Configuration</span>
            </span>
          }
          requiredPermissions={[
            Permissions.Configuration.Roles.List,
            Permissions.Configuration.Skills.List,
            Permissions.Configuration.EmploymentTypes.List,
            Permissions.Configuration.Department.List,
            Permissions.Configuration.Designation.List,
            Permissions.Configuration.Location.List,
            Permissions.Configuration.Competency.List,
            Permissions.Configuration.BusinessUnit.List,
            Permissions.Configuration.Division.List,
            Permissions.Configuration.ProjectRoles.List,
          ]}
        >
          <AuthorizedMenuItem
            key="roles"
            requiredPermissions={[Permissions.Configuration.Roles.List]}
          >
            <span>Roles</span>
          </AuthorizedMenuItem>
          <AuthorizedMenuItem
            key="skills"
            requiredPermissions={[Permissions.Configuration.Skills.List]}
          >
            <span>Skills</span>
          </AuthorizedMenuItem>
          <AuthorizedMenuItem
            key="employment-types"
            requiredPermissions={[Permissions.Configuration.EmploymentTypes.List]}
          >
            <span>Employment Types</span>
          </AuthorizedMenuItem>
          <AuthorizedMenuItem
            key="departments"
            requiredPermissions={[Permissions.Configuration.Department.List]}
          >
            <span>Departments</span>
          </AuthorizedMenuItem>
          <AuthorizedMenuItem
            key="designations"
            requiredPermissions={[Permissions.Configuration.Designation.List]}
          >
            <span>Designations</span>
          </AuthorizedMenuItem>
          <AuthorizedMenuItem
            key="locations"
            requiredPermissions={[Permissions.Configuration.Location.List]}
          >
            <span>Locations</span>
          </AuthorizedMenuItem>
          <AuthorizedMenuItem
            key="competencies"
            requiredPermissions={[Permissions.Configuration.Competency.List]}
          >
            <span>Competencies</span>
          </AuthorizedMenuItem>
          <AuthorizedMenuItem
            key="business-unit"
            requiredPermissions={[Permissions.Configuration.BusinessUnit.List]}
          >
            <span>Business Unit</span>
          </AuthorizedMenuItem>
          <AuthorizedMenuItem
            key="division"
            requiredPermissions={[Permissions.Configuration.Division.List]}
          >
            <span>Division</span>
          </AuthorizedMenuItem>
          <AuthorizedMenuItem
            key="project-roles"
            requiredPermissions={[Permissions.Configuration.ProjectRoles.List]}
          >
            <span>Project Roles</span>
          </AuthorizedMenuItem>
        </AuthorizedSubMenu>
        {(isCRMC1 || isRCA) && (
          <SubMenu
            title={
              <span>
                <EnhancedIcon type="team" />
                <span className="resource-text">Reports</span>
              </span>
            }
          >
            <EnhancedMenu.Item key="billing">
              <span>Resource Utilization</span>
            </EnhancedMenu.Item>
          </SubMenu>
        )}
        {
          <SubMenu
            title={
              <span>
                <EnhancedIcon type="wallet" />
                <span className="resource-text">Expense Claim</span>
              </span>
            }
          >
            <EnhancedMenu.Item key="claim-request">
              <span>New Claim</span>
            </EnhancedMenu.Item>
            <EnhancedMenu.Item key="view-expense-claim">
              <span>View Claim</span>
            </EnhancedMenu.Item>
            <EnhancedMenu.Item key="claim-faqs">
              <span>FAQs</span>
            </EnhancedMenu.Item>
          </SubMenu>
        }
        {FEEDBACK_REDIRECT && (
          <EnhancedMenu.Item onClick={() => this.setCokieesOnClick()}>
            <a href={FEEDBACK_REDIRECT_URL} target="_blank" rel="noopener noreferrer">
              <EnhancedIcon type="team" />
              <span className="resource-text">VenD 360</span>
            </a>
          </EnhancedMenu.Item>
        )}
        <EnhancedMenu.Item>
          <a href="https://venturedive.engagedly.com/" target="_blank" rel="noopener noreferrer">
            <EnhancedIcon type="team" />
            <span className="resource-text">Engagedly</span>
          </a>
        </EnhancedMenu.Item>
        {logout && (
          <EnhancedMenu.Item key="logout">
            <EnhancedIcon type="logout" />
            <span>Logout</span>
          </EnhancedMenu.Item>
        )}
        <EnhancedMenu.Item key="employee-directory">
          <EnhancedIcon type="team" />
          <span>Employee Directory</span>
        </EnhancedMenu.Item>
        {/* <EnhancedMenu.Item key="resources">
          <Icon type="team" />
          <span>Resources</span>
        </EnhancedMenu.Item> */}
        <AuthorizedMenuItem
          key="employee-organization-tree"
          requiredPermissions={[Permissions.Employee.OrgChart.View, Permissions.Reportee.Allowed]}
        >
          <EnhancedIcon type="team" />
          <span className="org-tree-menu-item">Organization Chart</span>
        </AuthorizedMenuItem>
        <AuthorizedSubMenu
          key="admin-panel"
          title={
            <span>
              <EnhancedIcon type="wallet" />
              <span className="resource-text">Admin Panel</span>
            </span>
          }
          requiredPermissions={[
            Permissions.Employee.Profile.Goal.View,
            Permissions.PerformanceManagement.PerformanceCycle.View,
            Permissions.PerformanceManagement.Reports.Goals,
            Permissions.Goals.PredefinedGoals.List,
            Permissions.Goals.GoalCategories.List,
          ]}
        >
          <AuthorizedMenuItem
            key="goals"
            requiredPermissions={[Permissions.Employee.Profile.Goal.View]}
          >
            <EnhancedIcon type="team" />
            <span>All Goals</span>
          </AuthorizedMenuItem>

          <AuthorizedMenuItem
            key="predefined-goals"
            requiredPermissions={[Permissions.Goals.PredefinedGoals.List]}
          >
            <EnhancedIcon type="team" />
            <span>Predefined Goals</span>
          </AuthorizedMenuItem>

          <AuthorizedMenuItem
            key="predefined-goals-categories"
            requiredPermissions={[Permissions.Goals.GoalCategories.List]}
          >
            <EnhancedIcon type="team" />
            <span>Goal Categories</span>
          </AuthorizedMenuItem>

          <AuthorizedMenuItem
            key="performance-management"
            requiredPermissions={[Permissions.PerformanceManagement.PerformanceCycle.View]}
          >
            <EnhancedIcon type="team" />
            <span>Performance Management</span>
          </AuthorizedMenuItem>
          <AuthorizedMenuItem
            key="pm-admin-dashboard"
            requiredPermissions={[Permissions.PerformanceManagement.Reports.Goals]}
          >
            <EnhancedIcon type="team" />
            <span>PM Dashboard</span>
          </AuthorizedMenuItem>
        </AuthorizedSubMenu>
        {isManager && (
          <SubMenu
            key="lm-dashboard"
            title={
              <span>
                <EnhancedIcon type="calendar" />
                <span className="resource-text">LM Dashboards</span>
              </span>
            }
          >
            {isManager && (
              <EnhancedMenu.Item key="lm-performance-management-dashboard">
                <span>Performance Management</span>
              </EnhancedMenu.Item>
            )}
          </SubMenu>
        )}
        {/* <SubMenu
          title={
            <span>
              <Icon type="wallet" />
              <span className="resource-text">Interview Tracker</span>
            </span>
            }
        >
          <EnhancedMenu.Item key="interview-tracking">
            <Icon type="team" />
            <span>Interviews</span>
          </EnhancedMenu.Item>
          <EnhancedMenu.Item key="interview-questions">
            <Icon type="team" />
            <span>Questions</span>
          </EnhancedMenu.Item>
          <EnhancedMenu.Item key="interview-questions-frequency">
            <Icon type="team" />
            <span>Questions Frequency</span>
          </EnhancedMenu.Item>
        </SubMenu> */}
      </EnhancedMenu>
    );
  }
}

export default AppMenu;
