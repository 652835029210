import moment from 'moment';
import groupBy from 'lodash/groupBy';
import { DATE_FORMAT } from 'configs/employeeProfileConstants';
import { roundOffRating } from 'utils/conversionUtils';

export const performanceEvaluationNormalizer = (response) => {
  const normalizedResponse = {
    trainingInfo: response.trainingInfo,
    goals: response.goals,
    competencies: response.competencies,
    employeeInfo: {
      ...response.employeeInfo,
      joiningDate: response.employeeInfo.joiningDate
        ? moment(`${response.employeeInfo.joiningDate}`).format(DATE_FORMAT)
        : '',
    },
    overallRating: response.overallRating,
    overallComment: response.overallComment,
    isFreezed: response.isFreezed,
    pcRatingId: response.pcRatingId,
    promotionInfo: response.promotionInfo,
    status: response.status,
    raterId: response.raterId,
    approvers: response.approvers,
    selfEvaluation: response.selfEvaluation,
  };

  return normalizedResponse;
};

export const normalizePerformanceCycleEmployee = (data) => {
  const employee = data;
  return {
    ...employee,
    employeeId: employee.taleoId,
    reviewManager: employee.managerName,
  };
};

export const normalizePerformanceCycleEmployees = (data) => {
  return data.map((lq) => normalizePerformanceCycleEmployee(lq));
};

export const competenciesByCategory = (competencies) => {
  const categoryToCompetencies = groupBy(competencies, (competency) => {
    return competency.category;
  });
  return categoryToCompetencies;
};

export const normalizeEvaluationReport = (evaluationReport) => {
  return evaluationReport.map((row) => {
    const { self, manager } = row;
    return {
      ...row,
      manager: {
        ...manager,
        rating: roundOffRating(manager.rating),
      },
      self: {
        ...self,
        rating: roundOffRating(self.rating),
      },
    };
  });
};
