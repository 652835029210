import { EnhancedButton } from 'components/shared/antd';
import React from 'react';

import PropTypes from 'prop-types';
import editIcon from 'images/edit-icon.svg';

const editButton = (props) => {
  const { clickHandler, buttonText } = props;
  return (
    <EnhancedButton onClick={clickHandler} className="btn-edit">
      <img src={editIcon} alt="edit icon" />
      <span>{buttonText}</span>
    </EnhancedButton>
  );
};

editButton.PropTypes = {
  clickHandler: PropTypes.func,
  buttonText: PropTypes.string,
};

export default editButton;
