import {
  GET_DEFAULTERS_INIT,
  GET_DEFAULTERS_SUCCESS,
  GET_DEFAULTERS_FAILURE,
} from 'constants/DefaulterTypes';
import { getDefaulters } from 'sources/DefaulterSource';

export const getDefaultersInit = () => ({
  type: GET_DEFAULTERS_INIT,
  errorText: '',
  successText: '',
});

export const getDefaultersSuccess = (data) => ({
  type: GET_DEFAULTERS_SUCCESS,
  data,
});

export const getDefaultersFailure = (message) => ({
  type: GET_DEFAULTERS_FAILURE,
  errorText: message,
});

export const getDefaultersAction = (startDate, endDate) => getDefaulters(startDate, endDate);
