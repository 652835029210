import { connect } from 'react-redux';
import Division from 'components/division/division';
import {
  getEmployeeDivisionsAction,
  addDivisionAction,
  editDivisionAction,
  deleteDivisionAction,
} from 'store/actions/EmployeeActions';

function mapStateToProps(state) {
  const { employeeReducer, auth } = state;
  const { divisions, isLoading } = employeeReducer;
  return {
    divisions,
    isLoading,
    userId: auth.user && auth.user.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEmployeeDivisionsAction: () => dispatch(getEmployeeDivisionsAction()),
    addDivision: (request) => dispatch(addDivisionAction(request)),
    editDivision: (request) => dispatch(editDivisionAction(request)),
    deleteDivision: (request) => dispatch(deleteDivisionAction(request)),
  };
}

const DivisionContainer = connect(mapStateToProps, mapDispatchToProps)(Division);

export default DivisionContainer;
