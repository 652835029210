import PropTypes from 'prop-types';

import React from 'react';

const asteriskText = (props) => {
  return (
    <React.Fragment>
      {props.asterisk} <span className="color-red">*</span>
    </React.Fragment>
  );
};

asteriskText.propTypes = {
  asterisk: PropTypes.string.isRequired,
};

export default asteriskText;
