import {
  EnhancedButton,
  EnhancedModal,
  EnhancedAutoComplete,
  EnhancedInput,
} from 'components/shared/antd';
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import {
  createInterviewQuestion,
  updateInterviewQuestion,
  createInterviewQuestionByFile,
} from '../../sources/interviewSources';
import UploadSheet from '../UploadSheet';
import './question-details.css';

const caselessFilter = (inputValue, option) => option.props.slug.indexOf(inputValue) !== -1;

function QuestionDetails(props) {
  const {
    visible,
    onClose,
    previousData,
    create,
    update,
    projectList,
    trackList,
    createQuestionByFile,
  } = props;

  const [text, setText] = useState('');
  const [answer, setAnswer] = useState('');
  const [track, setTrack] = useState('');
  const [project, setProject] = useState('');
  const [file, setFile] = useState([]);

  const clearAll = () => {
    setText('');
    setAnswer('');
    setTrack('');
    setProject('');
  };

  useEffect(() => {
    if (previousData) {
      setText(previousData.text);
      setAnswer(previousData.answer);
      setTrack(previousData.trackId.toString());
      setProject(previousData.projectId.toString());
    } else {
      clearAll();
    }
  }, [previousData]);

  const setDropdownValue = (value, setter) => {
    setter(value);
  };

  const handleClose = () => {
    clearAll();
    setFile([]);
    onClose();
  };

  const handleOk = () => {
    if (!isEmpty(file)) {
      const formData = new FormData();
      formData.append('projectId', project);
      formData.append('trackId', track);
      formData.append('questions', file[0]);
      createQuestionByFile(formData).then(() => setFile([]));
      handleClose();
      return;
    }

    const payload = {
      text,
      answer,
      projectId: project,
      trackId: track,
    };
    if (previousData) {
      const { id } = previousData;
      update(id, payload);
    } else {
      create(payload);
    }
    handleClose();
  };

  const submitEnabled = () => {
    if (isEmpty(file)) {
      return isEmpty(text) || isEmpty(project) || isEmpty(track);
    }
    return isEmpty(project) || isEmpty(track);
  };

  return (
    <EnhancedModal
      title={previousData ? 'Edit Question' : 'Create New Question'}
      visible={visible || false}
      onCancel={handleClose}
      footer={null}
    >
      <div className="field">
        <div className="label">Project</div>
        <EnhancedAutoComplete
          style={{ width: '100%' }}
          dataSource={projectList}
          filterOption={caselessFilter}
          value={project}
          onChange={(v) => setDropdownValue(v, setProject)}
        >
          {projectList.map((proj) => (
            <EnhancedAutoComplete.Option
              slug={proj.name.toLowerCase()}
              key={proj.id}
              value={`${proj.id}`}
            >
              {proj.name}
            </EnhancedAutoComplete.Option>
          ))}
        </EnhancedAutoComplete>
      </div>
      <div className="field">
        <div className="label">Track</div>
        <EnhancedAutoComplete
          style={{ width: '100%' }}
          dataSource={trackList}
          filterOption={caselessFilter}
          value={track}
          onChange={(v) => setDropdownValue(v, setTrack)}
        >
          {trackList.map((tr) => (
            <EnhancedAutoComplete.Option
              slug={tr.name.toLowerCase()}
              key={tr.id}
              value={`${tr.id}`}
            >
              {tr.name}
            </EnhancedAutoComplete.Option>
          ))}
        </EnhancedAutoComplete>
      </div>
      <div className="field">
        <div className="label">Question</div>
        <EnhancedInput.TextArea
          rows={2}
          style={{ width: '100%' }}
          value={text}
          onChange={(e) => setText(e.target.value)}
          disabled={!isEmpty(file)}
        />
      </div>
      <div className="field">
        <div className="label">Answer</div>
        <EnhancedInput.TextArea
          rows={3}
          style={{ width: '100%' }}
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          disabled={!isEmpty(file)}
        />
      </div>
      <div className="modal-footer">
        {!previousData && (
          <UploadSheet
            disabled={!isEmpty(text.trim()) || !isEmpty(answer.trim())}
            files={file}
            maxCount={1}
            onChange={(_file) => setFile(_file)}
          />
        )}
        <EnhancedButton type="secondary" onClick={onClose}>
          {previousData ? 'Discard' : 'Cancel'}
        </EnhancedButton>
        <EnhancedButton type="primary" onClick={handleOk} disabled={submitEnabled()}>
          {!isEmpty(file) ? 'Start Uploading' : previousData ? 'Save Changes' : 'Save'}
        </EnhancedButton>
      </div>
    </EnhancedModal>
  );
}

const mapDispatch = (dispatch) => ({
  create: (payload) => dispatch(createInterviewQuestion(payload)),
  update: (id, payload) => dispatch(updateInterviewQuestion(id, payload)),
  createQuestionByFile: (payload) => dispatch(createInterviewQuestionByFile(payload)),
});

const mapState = (state) => ({
  projectList: state.projectReducer.projects.filter((v) => v.status),
  trackList: state.interviewReducer.trackList,
});

export default connect(mapState, mapDispatch)(QuestionDetails);
