export const GET_PROJECT_REVIEWS_CYCLE_INIT = 'GET_PROJECT_REVIEWS_CYCLE_INIT';
export const GET_PROJECT_REVIEWS_CYCLE_SUCCESS = 'GET_PROJECT_REVIEWS_CYCLE_SUCCESS';
export const GET_PROJECT_REVIEWS_CYCLE_FAILURE = 'GET_PROJECT_REVIEWS_CYCLE_FAILURE';

export const GET_PROJECT_REVIEWS_BY_CYCLE_ID_INIT = 'GET_PROJECT_REVIEWS_BY_CYCLE_ID_INIT';
export const GET_PROJECT_REVIEWS_BY_CYCLE_ID_SUCCESS = 'GET_PROJECT_REVIEWS_BY_CYCLE_ID_SUCCESS';
export const GET_PROJECT_REVIEWS_BY_CYCLE_ID_FAILURE = 'GET_PROJECT_REVIEWS_BY_CYCLE_ID_FAILURE';

export const GET_PROJECT_REVIEWS_PROJECT_DATA_INIT = 'GET_PROJECT_REVIEWS_PROJECT_DATA_INIT';
export const GET_PROJECT_REVIEWS_PROJECT_DATA_SUCCESS = 'GET_PROJECT_REVIEWS_PROJECT_DATA_SUCCESS';
export const GET_PROJECT_REVIEWS_PROJECT_DATA_FAILURE = 'GET_PROJECT_REVIEWS_PROJECT_DATA_FAILURE';

export const POST_PROJECT_REVIEWS_RATING_DATA_INIT = 'POST_PROJECT_REVIEWS_RATING_DATA_INIT';
export const POST_PROJECT_REVIEWS_RATING_DATA_SUCCESS = 'POST_PROJECT_REVIEWS_RATING_DATA_SUCCESS';
export const POST_PROJECT_REVIEWS_RATING_DATA_FAILURE = 'POST_PROJECT_REVIEWS_RATING_DATA_FAILURE';
export const CLEAR_PROJECT_REVIEWS_RATING_DATA = 'CLEAR_PROJECT_REVIEWS_RATING_DATA';
