import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LeaveCalendar from 'components/leaves/LeaveCalendar';
import { getTeamLeavesBalanceAction, getLeaveCalendar } from 'store/actions/LeaveActions';

const mapStateToProps = ({ auth, teamLeavesReducer }) => ({
  userId: auth.user && auth.user.id,
  isManager: auth.user && auth.user.isManager,
  leaveCalendar: teamLeavesReducer.leaveCalendar,
  loader: teamLeavesReducer.loader,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getTeamLeavesBalanceAction, getLeaveCalendar }, dispatch);
}
const LeaveDashboardContainer = connect(mapStateToProps, mapDispatchToProps)(LeaveCalendar);

export default LeaveDashboardContainer;
