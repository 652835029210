/* eslint-disable no-prototype-builtins */
import React, { Component } from 'react';
import moment from 'moment';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import {
  EnhancedCol,
  EnhancedRow,
  EnhancedButton,
  EnhancedIcon,
  EnhancedAlert,
  EnhancedLayout,
  EnhancedModal,
  EnhancedSelect,
  EnhancedDatePicker,
  EnhancedInput,
  EnhancedForm,
} from 'components/shared/antd';
import { Link } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { transformProjectData } from 'utils/arrayUtils';
import { dateFormat } from 'configs/constants';
import { invalidDate, NOT_AVAILABLE, completed } from 'configs/additionalConstants';
import 'components/project/Project.css';
import { CRMC_PROJECTS_CURRENT } from 'configs/routesConstants';
import { parse } from 'utils/stringUtils';
import RequiredField from '../RequiredField';
import './ProjectEmployees.css';
import PROJECT_ROUTES from '../../constants/UrlConstants';
import Authorize from '../Authorize';
import Permissions from '../../constants/AccessPermissions';
import { hasPermission } from '../../utils/AccessUtils';
import ProjectActivationModal from './ProjectActivateModal';

const { Header } = EnhancedLayout;
const { Option } = EnhancedSelect;

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: null,
      project: {
        name: '',
        startDate: '',
        endDate: '',
        projectManagerId: undefined,
        deliveryManagerId: undefined,
        architectId: undefined,
        employeeId: props.user.id,
        leadershipRoles: [],
        businessUnitId: undefined,
      },
      showAddNewForm: false,
      modalOpen: false,
      errorText: '',
      readOnly: this.props.readOnly,
      showActivationModal: false,
      projectData: {},
      projects: [],
    };
    this.props.getProjectRoles();
  }

  componentWillMount() {
    const { user, match } = this.props;
    if (match.params.status === completed) {
      const isAuthorized = hasPermission(user.permissions, [Permissions.Project.Completed.List]);
      if (!isAuthorized) this.props.history.push('/crmc/projects');
    }
  }

  componentDidMount() {
    this.props.getProjectsAction();
    this.props.getBusinessUnits();
  }

  componentWillReceiveProps(nextProps) {
    const { projects, match } = this.props;

    if (nextProps.projects !== projects || nextProps.match.params.status !== match.params.status) {
      this.setState({
        projects: this.filterProjectsAsPerStatus(nextProps.projects, nextProps.match.params.status),
      });
    }

    if (!nextProps.isAdding && this.props.isAdding && this.state.modalOpen) {
      this.handleCancel();
    }
  }

  onChange = (field, value) => {
    const project = { ...this.state.project };
    project[field] = value;
    this.setState({
      project,
    });
  };

  onStartDateChange = (value, option) => {
    this.onChange('startDate', option);
  };

  onEndDateChange = (value, option) => {
    this.onChange('endDate', option);
  };

  onClose = () => {
    this.setState({
      errorText: '',
      project: {
        ...this.state.project,
        name: '',
      },
    });
  };

  getLeadershipRoleString = (project, roleId) => {
    const items = project.leadershipRoles
      .filter((lr) => lr.projectRoleId === roleId)
      .map((lr) => lr.employee.fullName);
    return items.length ? items.join(', ') : NOT_AVAILABLE;
  };

  getColumnsInfo = () => {
    let { sortedInfo } = this.state;
    let { projectRoles } = this.props;
    sortedInfo = sortedInfo || {};
    projectRoles = projectRoles.filter((pr) => pr.isLeadership === true);

    const dynamicColumns = projectRoles.map((pr) => ({
      title: pr.role,
      dataIndex: pr.role,
      key: pr.role,
      // defaultSortOrder: 'ascend',
      filterConfig: {
        type: 'search',
        key: pr.role,
        customFunc: this.filterDynamicRoles,
      },
      sortOrder: sortedInfo.columnKey === pr.role && sortedInfo.order,
      // filterIcon: <Icon type="search" />,
      // filterDropdown: this.dynamicRoleSearchDropDown(pr),
      sorter: (a, b) => this.dynamicRoleSorter(a, b, pr),
      render: (text, record) => (
        <div>
          {record.leadershipRoles.filter((lr) => lr.projectRoleId === pr.id).length > 0
            ? record.leadershipRoles
                .filter((lr) => lr.projectRoleId === pr.id)
                .map((lr) => <p style={{ marginBlockEnd: '0px' }}>{lr.employee.fullName}</p>)
            : NOT_AVAILABLE}
        </div>
      ),
      width: 250,
    }));

    const columns = [
      {
        title: 'Project Name',
        dataIndex: 'name',
        key: 'name',
        defaultSortOrder: 'ascend',
        filterConfig: {
          type: 'search',
          key: 'name',
        },
        render: this.renderProjectName,
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        width: 200,
        fixed: 'left',
      },
      {
        title: 'Project Manager',
        dataIndex: 'manager',
        key: 'manager',
        // defaultSortOrder: 'ascend',
        filterConfig: {
          type: 'search',
          key: 'manager.fullName',
        },
        sortOrder: sortedInfo.columnKey === 'manager' && sortedInfo.order,
        sorter: (a, b) =>
          a.manager && b.manager && a.manager.firstName.localeCompare(b.manager.firstName),
        render: (text, record) => (
          <div>{record.manager ? record.manager.fullName : NOT_AVAILABLE}</div>
        ),
        width: 200,
      },
      {
        title: 'Delivery Manager',
        dataIndex: 'deliveryManager',
        key: 'deliveryManager',
        // defaultSortOrder: 'ascend',
        filterConfig: {
          type: 'search',
          key: 'deliveryManager.fullName',
        },
        sortOrder: sortedInfo.columnKey === 'deliveryManager' && sortedInfo.order,
        sorter: (a, b) =>
          a.deliveryManager &&
          b.deliveryManager &&
          a.deliveryManager.firstName.localeCompare(b.deliveryManager.firstName),
        render: (text, record) => (
          <div>{record.deliveryManager ? record.deliveryManager.fullName : NOT_AVAILABLE}</div>
        ),
        width: 200,
      },
      {
        title: 'Business Unit',
        dataIndex: 'businessUnit',
        key: 'businessUnit',
        // defaultSortOrder: 'ascend',
        filterConfig: {
          type: 'enhanced_filter',
          key: 'businessUnit.name',
          optionsConfig: {
            values: this.props.businessUnits,
          },
        },
        sortOrder: sortedInfo.columnKey === 'businessUnit' && sortedInfo.order,
        sorter: (a, b) =>
          a.businessUnit &&
          b.businessUnit &&
          a.businessUnit.name.localeCompare(b.businessUnit.name),
        render: (text, record) => (
          <div>{record.businessUnit ? `${record.businessUnit.name}` : NOT_AVAILABLE}</div>
        ),
        width: 200,
      },
      {
        title: 'Architect',
        dataIndex: 'architect',
        key: 'architect',
        // defaultSortOrder: 'ascend',
        filterConfig: {
          type: 'search',
          key: 'architect.fullName',
        },
        sortOrder: sortedInfo.columnKey === 'architect' && sortedInfo.order,
        sorter: (a, b) =>
          a.architect && b.architect && a.architect.firstName.localeCompare(b.architect.firstName),
        render: (text, record) => (
          <div>{record.architect ? record.architect.fullName : NOT_AVAILABLE}</div>
        ),
        width: 200,
      },
      ...dynamicColumns,
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        render: (startDate) => startDate || NOT_AVAILABLE,
        fixed: 'right',
        width: 150,
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
        key: 'endDate',
        render: (endDate) => (endDate.toString() === invalidDate ? NOT_AVAILABLE : endDate),
        width: 150,
        fixed: 'right',
      },
    ];
    if (this.props.match.params.status === 'completed') {
      columns.push({
        title: 'Actions',
        render: this.actions,
        fixed: 'right',
        width: 100,
      });
    }
    return columns;
  };
  getProjectRoleValue = (projectRole) => {
    const { project } = this.state;
    const ids = project.leadershipRoles
      .filter((lr) => lr.projectRoleId === projectRole.id)
      .map((lr) => lr.employeeId);
    return ids.length > 0 ? ids : undefined;
  };

  getFileHeaders = () => {
    const headers = [
      {
        label: 'Name',
        key: 'name',
      },
      {
        label: 'Business Unit',
        key: 'businessUnit.name',
      },
      {
        label: 'Start Date',
        key: 'startDate',
      },
      {
        label: 'End Date',
        key: 'endDate',
      },
      {
        label: 'Project Manager',
        key: 'manager.fullName',
      },
      {
        label: 'Delivery Manager',
        key: 'deliveryManager.fullName',
      },
      {
        label: 'Project Architect',
        key: 'architect.fullName',
      },
    ];

    this.props.projectRoles
      .filter((projectRole) => projectRole.isLeadership)
      .forEach((projectRole) => {
        headers.push({
          label: projectRole.role,
          key: 'leadershipRoles',
          renderer: this.projectRolesRendererForExport,
        });
      });

    return headers;
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  };

  filterProjectsAsPerStatus = (projects, paramStatus) => {
    let status = true;
    if (paramStatus === completed) status = false;
    let filteredProjects = projects.filter((pro) => pro.status === status);

    // excluding some pre-defined projects
    const excludedProjects = ['admin', 'holiday', 'leaves', 'hr', 'services/management'];

    filteredProjects = filteredProjects.filter(
      (project) =>
        !excludedProjects.includes(project.name ? project.name.toLowerCase().replace(' ', '') : ''),
    );

    return filteredProjects;
  };

  filterDynamicRoles = (item, filterValue, columnKey) => {
    return (
      item.leadershipRoles.filter(
        (lr) =>
          lr.projectRole.role === columnKey &&
          lr.employee.fullName.toLowerCase().includes(filterValue),
      ).length > 0
    );
  };

  openActivationModal = (projectData) => {
    this.setState({
      showActivationModal: true,
      projectData,
    });
  };
  closeActivationModal = (activated) => {
    this.props.resetProjectActivation();
    this.props.getProjectsAction();
    this.setState({
      showActivationModal: false,
    });
    if (activated) {
      this.props.history.push(
        `${CRMC_PROJECTS_CURRENT}/${this.state.projectData.id}?status=current`,
      );
    }
  };
  showTotal = (total) => `Total ${total} items`;

  showAddNew = () => {
    this.setState({
      showAddNewForm: !this.state.showAddNewForm,
    });
  };
  actions = (projectData) => (
    <div>
      <Authorize requiredPermissions={[Permissions.Project.Allocation.Update]}>
        <EnhancedIcon
          type="edit"
          role="presentation"
          style={{ cursor: 'pointer' }}
          theme="twoTone"
          twoToneColor="#52c41a"
          onClick={() => this.openActivationModal(projectData)}
        />
      </Authorize>
    </div>
  );
  showModal = () => {
    this.setState({
      modalOpen: true,
    });
  };

  handleCancel = () => {
    this.setState({
      modalOpen: false,
      project: {
        name: '',
        startDate: '',
        endDate: '',
        projectManagerId: '',
        deliveryManagerId: '',
        architectId: '',
        leadershipRoles: [],
      },
      errorText: '',
    });
  };

  dynamicRoleSearchDropDown = (projectRole) => {
    const { activeProjectRole, searchProjectRoleText } = this.state;
    return (
      <EnhancedRow className="custom-filter-dropdown">
        <EnhancedCol span={19}>
          <EnhancedInput
            value={
              activeProjectRole && activeProjectRole.id === projectRole.id
                ? searchProjectRoleText
                : ''
            }
            placeholder="Search"
            onChange={(event) => this.onSearchProjectRole(event, projectRole)}
          />
        </EnhancedCol>
        <EnhancedCol offset={1} span={4}>
          <EnhancedButton type="primary" shape="circle" icon="search" />
        </EnhancedCol>
      </EnhancedRow>
    );
  };

  dynamicRoleSorter = (a, b, projectRole) => {
    const rolesA = this.getLeadershipRoleString(a, projectRole.id);
    const rolesB = this.getLeadershipRoleString(b, projectRole.id);
    return rolesA.localeCompare(rolesB);
  };

  handleProjectRoleChange = (employeeIds, projectRole) => {
    let { project } = this.state;
    const leadershipRoles = [
      ...project.leadershipRoles.filter((lr) => lr.projectRoleId !== projectRole.id),
    ];
    leadershipRoles.push(
      ...employeeIds.map((employeeId) => ({
        employeeId,
        projectRoleId: projectRole.id,
      })),
    );
    project = {
      ...project,
      leadershipRoles,
    };
    this.setState({
      project,
    });
  };

  handleNameChange = (value) => {
    const project = { ...this.state.project };
    project.name = value.target.value;
    this.setState({
      project,
    });
  };
  handleManagerChange = (manager) => {
    this.onChange('projectManagerId', manager);
  };
  handleBussinessUnitChange = (businessUnit) => {
    this.onChange('businessUnitId', businessUnit);
  };
  handleDeliveryManagerChange = (manager) => {
    this.onChange('deliveryManagerId', manager);
  };

  handleArchitectChange = (manager) => {
    this.onChange('architectId', manager);
  };

  disabledStartDate = (startValue) => {
    const endValue = this.state.project.endDate ? moment.utc(this.state.project.endDate) : Date();
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = (endValue) => {
    const startValue = moment.utc(this.state.project.startDate).add(1, 'days');
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  handleSubmit = (value) => {
    value.preventDefault();
    const { name } = this.state.project;
    const { projects } = this.props;
    const similarProject = projects.filter(
      (project) => project.name.toLowerCase() === name.toLowerCase(),
    )[0];

    if (similarProject) {
      this.setState({
        errorText: 'Project with the same name already exists.',
      });
    } else {
      this.props.addProject(this.state.project);
      this.handleCancel();
    }
  };

  shouldButtonDisable = () => {
    const { project } = this.state;
    if (project.name && project.startDate && project.businessUnitId) {
      return false;
    }
    return true;
  };

  projectRolesRendererForExport = (value, key, label) => {
    const item = value
      .filter((lr) => lr.projectRole.role === label)
      .map((lr) => lr.employee.fullName)
      .join(' | ');

    return item;
  };

  handleExport = (filteredProjects) => {
    const leadershipRoles = this.props.projectRoles.filter((pr) => pr.isLeadership);
    fileDownload(transformProjectData(filteredProjects, leadershipRoles), 'Current Projects.csv');
  };

  renderModal = () => {
    const { isAdding } = this.props;
    const { modalOpen } = this.state;
    return (
      <EnhancedModal
        title="New Project"
        visible={modalOpen}
        userId={this.props.user.id}
        onCancel={this.handleCancel}
        width={650}
        footer={[
          <EnhancedButton key="back" onClick={this.handleCancel}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            key="add"
            type="primary"
            loading={isAdding}
            disabled={this.shouldButtonDisable()}
            onClick={this.handleSubmit}
          >
            Save
          </EnhancedButton>,
        ]}
        style={{
          backgroundColor: 'white',
          maxHeight: '90%',
          padding: '0',
        }}
        bodyStyle={{ padding: '0', maxHeight: '90%' }}
      >
        {this.renderFormRow()}
      </EnhancedModal>
    );
  };

  renderProjectName = (name, data) => {
    const { status } = this.props.match.params;
    const link = parse(PROJECT_ROUTES.PROJECTS_DETAIL_ROUTE, { status, id: data.id });
    return (
      <Link to={`${link}?status=${status}`}>
        <b style={{ color: '#5A5A5A' }}>{name}</b>
      </Link>
    );
  };

  renderDynamicFormRows = () => {
    const { employees } = this.props;
    let { projectRoles } = this.props;
    projectRoles = projectRoles.filter((pr) => pr.isLeadership === true);
    return projectRoles.map((projectRole) => (
      <EnhancedCol span={24} className="form-col" key={projectRole.id}>
        <p>{projectRole.role}</p>
        <EnhancedSelect
          showSearch
          allowClear
          mode="multiple"
          optionFilterProp="children"
          value={this.getProjectRoleValue(projectRole)}
          style={{ width: '100%' }}
          placeholder="Please Select"
          onChange={(value) => this.handleProjectRoleChange(value, projectRole)}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {employees && employees.length
            ? employees.map((employee) =>
                employee.isActive ? (
                  <Option key={employee.id} value={employee.id}>
                    {employee.name}
                  </Option>
                ) : (
                  ''
                ),
              )
            : null}
        </EnhancedSelect>
      </EnhancedCol>
    ));
  };

  renderFormRow = () => {
    const { employees, businessUnits } = this.props;
    const { errorText } = this.state;
    return (
      <EnhancedRow
        style={{ padding: '24px', height: '500px', overflowY: 'scroll' }}
        type="flex"
        justify="center"
      >
        {errorText && (
          <EnhancedAlert
            style={{ width: '100%' }}
            message={errorText}
            type="error"
            closable
            onClose={this.onClose}
          />
        )}
        <EnhancedForm layout="inline">
          <EnhancedCol span={24} className="form-col">
            <p>
              <RequiredField>Project Name </RequiredField>
            </p>
            <EnhancedInput
              placeholder="Project Name"
              id="projectName"
              onChange={this.handleNameChange}
              value={this.state.project.name}
            />
          </EnhancedCol>
          <EnhancedCol span={24} className="form-col">
            <p>Project Manager</p>
            <EnhancedSelect
              showSearch
              allowClear
              optionFilterProp="children"
              value={this.state.project.projectManagerId}
              style={{ width: '100%' }}
              placeholder="Please Select"
              onChange={this.handleManagerChange}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {employees && employees.length
                ? employees.map((employee) =>
                    employee.isActive ? (
                      <Option key={employee.id} value={employee.id}>
                        {employee.name}
                      </Option>
                    ) : (
                      ''
                    ),
                  )
                : null}
            </EnhancedSelect>
          </EnhancedCol>
          <EnhancedCol span={24} className="form-col">
            <p>
              <RequiredField>Business Unit</RequiredField>
            </p>
            <EnhancedSelect
              showSearch
              allowClear
              optionFilterProp="children"
              value={this.state.project.businessUnitId}
              style={{ width: '100%' }}
              placeholder="Please Select"
              onChange={this.handleBussinessUnitChange}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {businessUnits && businessUnits.length
                ? businessUnits.map((unit) =>
                    unit.name ? (
                      <Option key={unit.id} value={unit.id}>
                        {`${unit.name}`}
                      </Option>
                    ) : (
                      ''
                    ),
                  )
                : null}
            </EnhancedSelect>
          </EnhancedCol>
          <EnhancedCol span={24} className="form-col">
            <p>Delivery Manager</p>
            <EnhancedSelect
              showSearch
              allowClear
              optionFilterProp="children"
              value={this.state.project.deliveryManagerId}
              style={{ width: '100%' }}
              placeholder="Please Select"
              onChange={this.handleDeliveryManagerChange}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {employees && employees.length
                ? employees.map((employee) =>
                    employee.isActive ? (
                      <Option key={employee.id} value={employee.id}>
                        {employee.name}
                      </Option>
                    ) : (
                      ''
                    ),
                  )
                : null}
            </EnhancedSelect>
          </EnhancedCol>
          <EnhancedCol span={24} className="form-col">
            <p>Architect</p>
            <EnhancedSelect
              showSearch
              allowClear
              optionFilterProp="children"
              value={this.state.project.architectId}
              style={{ width: '100%' }}
              placeholder="Please Select"
              onChange={this.handleArchitectChange}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {employees && employees.length
                ? employees.map((employee) =>
                    employee.isActive ? (
                      <Option key={employee.id} value={employee.id}>
                        {employee.name}
                      </Option>
                    ) : (
                      ''
                    ),
                  )
                : null}
            </EnhancedSelect>
          </EnhancedCol>
          {this.renderDynamicFormRows()}
          <EnhancedCol span={24} className="form-col">
            <p>
              <RequiredField>Start Date</RequiredField>
            </p>
            <EnhancedDatePicker
              style={{ width: '100%' }}
              onChange={this.onStartDateChange}
              placeholder="Enter Start Date"
              format={dateFormat.DDMMMYYYYwithSpace}
              allowClear
              disabledDate={this.disabledStartDate}
            />
          </EnhancedCol>
          <EnhancedCol span={24} className="form-col">
            <p>End Date</p>
            <EnhancedDatePicker
              style={{ width: '100%' }}
              onChange={this.onEndDateChange}
              placeholder="Enter End Date"
              format={dateFormat.DDMMMYYYYwithSpace}
              allowClear
              disabledDate={this.disabledEndDate}
            />
          </EnhancedCol>
        </EnhancedForm>
      </EnhancedRow>
    );
  };

  render() {
    const { isLoading, projectRoles, user } = this.props;
    const { projects } = this.state;

    let status = true;
    const currentStatus = this.props.match.params.status;
    if (currentStatus === completed) status = false;

    const scrollX = 1100 + projectRoles.filter((pr) => pr.isLeadership).length * 250;

    return (
      <div>
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol lg={12}>
              <h1 className="heading-pro">Projects</h1>
            </EnhancedCol>
            <EnhancedCol lg={12} style={{ textAlign: 'right' }}>
              {status && !this.state.readOnly ? (
                <Authorize requiredPermissions={[Permissions.Project.Create]}>
                  <EnhancedButton type="primary" onClick={this.showModal}>
                    {this.state.showAddNewForm ? 'Close' : 'Add New Projects'}
                  </EnhancedButton>
                </Authorize>
              ) : (
                ''
              )}
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        {this.state.modalOpen ? this.renderModal() : ''}
        <div className="project-table">
          <EnhancedClientSideTable
            loading={isLoading}
            columns={this.getColumnsInfo()}
            onChange={this.handleTableChange}
            data={projects}
            scroll={{ x: scrollX }}
            exportFileConfig={{
              fileName: `${currentStatus} Projects`,
              fileHeaders: this.getFileHeaders(),
              showExportButton: hasPermission(user.permissions, [Permissions.Project.Export]),
            }}
          />
        </div>
        <ProjectActivationModal
          visible={this.state.showActivationModal}
          projectData={this.state.projectData}
          closeActivationModal={this.closeActivationModal}
          activateProjectAction={this.props.activateProject}
          user={this.props.user}
          isActivated={this.props.isActivated}
          history={this.props.history}
        />
      </div>
    );
  }
}

export default Project;
