import {
  GET_RESOURCES_INIT,
  GET_RESOURCES_SUCCESS,
  GET_RESOURCES_FAILURE,
  GET_RESOURCE_DETAIL_INIT,
  GET_RESOURCE_DETAIL_SUCCESS,
  GET_RESOURCE_DETAIL_FAILURE,
} from 'constants/ResourceTypes';

const initialState = {
  resources: [],
  resourceData: {},
  isLoading: false,
};

export default function resourceReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RESOURCES_INIT:
      return {
        ...state,
        isLoading: true,
        resources: [],
      };
    case GET_RESOURCES_SUCCESS:
      return {
        ...state,
        resources: action.data,
        isLoading: false,
      };
    case GET_RESOURCES_FAILURE:
      return {
        ...state,
        isLoading: false,
        resources: action.data,
      };
    case GET_RESOURCE_DETAIL_INIT:
      return {
        ...state,
        isLoading: true,
        resourceData: {},
      };
    case GET_RESOURCE_DETAIL_SUCCESS:
      return {
        ...state,
        resourceData: action.data,
        isLoading: false,
      };
    case GET_RESOURCE_DETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        resourceData: action.data,
      };
    default:
      return state;
  }
}
