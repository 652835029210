import { connect } from 'react-redux';
import EmploymentTypes from 'components/employmentTypes/EmploymentTypes';

import {
  getEmploymentTypesAction,
  addEmploymentTypeAction,
  editEmploymentTypeAction,
  deleteEmploymentTypeAction,
} from 'store/actions/EmploymentTypesActions';

function mapStateToProps(state) {
  const { employmentTypesReducer, auth } = state;
  const { employmentTypes, isLoading } = employmentTypesReducer;
  return {
    employmentTypes,
    isLoading,
    userId: auth.user && auth.user.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEmploymentTypesAction: () => dispatch(getEmploymentTypesAction()),
    addEmploymentType: (request) => dispatch(addEmploymentTypeAction(request)),
    editEmploymentType: (request) => dispatch(editEmploymentTypeAction(request)),
    deleteEmploymentType: (request) => dispatch(deleteEmploymentTypeAction(request)),
  };
}

const EmploymentTypesContainer = connect(mapStateToProps, mapDispatchToProps)(EmploymentTypes);
export default EmploymentTypesContainer;
