import { getLeaveTypes } from 'sources/LeavesSource';
import {
  GET_LEAVE_TYPES_INIT,
  GET_LEAVE_TYPES_SUCCESS,
  GET_LEAVE_TYPES_FAILURE,
} from 'constants/LeaveTypes';

export const getLeaveTypesInit = () => ({
  type: GET_LEAVE_TYPES_INIT,
});

export const getLeaveTypesSuccess = (data) => ({
  type: GET_LEAVE_TYPES_SUCCESS,
  data,
});

export const getLeaveTypesFailure = (data) => ({
  type: GET_LEAVE_TYPES_FAILURE,
  data,
});

export const getLeaveTypesAction = () => getLeaveTypes();
