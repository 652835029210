import moment from 'moment';
import { flattenDeep } from 'lodash';
import { skillLevel } from 'configs/constants';
import { DATE_FORMAT, COMMENT_TIME_FORMAT, MONTH_FORMAT } from 'configs/employeeProfileConstants';
import { capitalizeString } from 'utils/stringUtils';
import { isEmptyObject } from 'utils/objectUtils';
import {
  // getFirstMobileNumber,
  filterSkills,
  // formatTenure,
  // getFuelAllowance,
} from 'utils/employeeUtils';
import { prepareOptionsFromJSON, prepareOptionsFromArray } from 'utils/arrayUtils';

const normalizeEmployeeBasicInfo = (info) => {
  return {
    id: info.employeeId,
    email: info.email || '',
    name:
      info.firstName || info.lastName
        ? `${info.firstName}${info.middleName ? ` ${info.middleName}` : ''} ${info.lastName}`
        : '',
    firstName: info.firstName || '',
    middleName: info.middleName || '',
    lastName: info.lastName || '',
    dateOfBirth: info.dateOfBirth ? moment(`${info.dateOfBirth}`).format(DATE_FORMAT) : '',
    oracleId: info.oracleId ? info.oracleId : 'N/A',
    phoneNumber: info.phoneNumber || '',
    mobileNumber: info.mobileNumber || '',
    // mobileNumber: getFirstMobileNumber(info.contacts) || [],
    employmentStatusId:
      info.employmentStatus && !isEmptyObject(info.employmentStatus)
        ? info.employmentStatus.id
        : '',
    genderId: info.genderId || '',
    maritalStatus: info.maritalStatus || '',
    religion: info.religion || '',
    cnic: info.cnic || '',
    cnicExpiry: info.cnicExpiry ? moment(`${info.cnicExpiry}`).format(DATE_FORMAT) : '',
    presentAddress: info.presentAddress || '',
    presentCity: info.presentCity || '',
    presentProvince: info.presentProvince || '',
    presentCountry: info.presentCountry || '',
    presentPostalCode: info.presentPostalCode || '',
    permanentAddress: info.permanentAddress || '',
    permanentCity: info.permanentCity || '',
    permanentProvince: info.permanentProvince || '',
    permanentCountry: info.permanentCountry || '',
    permanentPostalCode: info.permanentPostalCode || '',
    bloodGroup: info.bloodGroup || '',
    passportNo: info.passportNo || '',
    passportExpiry: info.passportExpiry ? moment(`${info.passportExpiry}`).format(DATE_FORMAT) : '',
    taxReturnFiler: info.taxReturnFiler || '',
    placeOfBirth: info.placeOfBirth || '',
    imageUrl: info.imageUrl || '',
    managerId: info.managerId || '',
    businessUnitId: info.businessUnitId || '',
    divisionId: info.divisionId || '',
    competencyId: info.competencyId || '',
    departmentId: info.departmentId || '',
    employmentTypeId: info.employmentTypeId || '',
    externalExpYears: info.externalExpYears || '',
    externalExpMonths: info.externalExpMonths || '',
    experience: info.experience || '',
    joiningDate: info.joiningDate ? moment(`${info.joiningDate}`).format(DATE_FORMAT) : '',
    grade: info.grade || '',
    subGrade: info.subGrade || '',
    location: info.location || '',
    grossSalary: info.grossSalary || '',
    inflationSupport: info.inflationSupport || '',
    fuelAllowance: info.fuelAllowance || '',
    designationId: info.designationId || null,
    designationName: info.designation ? info.designation.name : '',
    departmentName: info.department ? info.department.name : '',
    divisionName: info.division ? info.division.name : '',
    employmentStatus: info.employmentStatus ? info.employmentStatus : {},
    managerName: info.managerName,
    opdEligibility: info.opdEligibility || false,
    ipdEligibility: info.ipdEligibility || false,
    ipdEligibilityOptions: info.ipdEligibilityOptions || '',
    transportAssistanceEligibility: info.transportAssistanceEligibility || false,
    fatherName: info.fatherName || '',
    personalEmail: info.personalEmail || '',
  };
};

const normalizeEmployeeFamilyInfo = (familyInfo) =>
  familyInfo.map((fm) => ({
    id: fm.id || '',
    fullName: fm.fullName || '',
    relationship: fm.relationship || '',
    gender: fm.gender || '',
    email: fm.email || '',
    dateOfBirth: fm.dob ? moment(`${fm.dob}`).format(DATE_FORMAT) : '',
    contactNo: fm.contactNo || '',
    cnic: fm.cnic || '',
    registeredInMedical: fm.registeredInMedical || false,
    medicalPolicyNo: fm.medicalPolicyNo || '',
  }));

export const normalizeEmergencyContactResponse = (contact) => ({
  id: contact.id || '',
  fullname: contact.fullName || '',
  relationship: contact.relationship || '',
  contactNo: contact.contactNo || '',
});

const normalizeEmployeeEmergencyContacts = (emergencyContacts) =>
  emergencyContacts.map(normalizeEmergencyContactResponse);

export function normalizeGetEmployeeInfoResponse(res) {
  const employee = res || {};
  return {
    info:
      employee.info && !isEmptyObject(employee.info)
        ? normalizeEmployeeBasicInfo(employee.info)
        : {},
    familyInfo: normalizeEmployeeFamilyInfo(employee.familyInfo || []),
    emergencyContacts: normalizeEmployeeEmergencyContacts(employee.emergencyContacts || []),
  };
}

export const normalizeGetEmployeeProfileHeaderResponse = (res) => {
  const profileHeader = res || {};
  return {
    firstName: profileHeader.firstName || '',
    lastName: profileHeader.lastName || '',
    fullName: profileHeader.fullName || '',
    imageUrl: profileHeader.imageUrl || '',
  };
};

export const normalizeEmployeeJobInfoHistory = (job) => ({
  ...job,
  grade: job.grade || '',
  subGrade: job.subGrade || '',
  designationId: job.designationId || null,
  departmentId: job.departmentId || null,
  managerId: job.managerId || null,
  startDate: job.startDate ? moment(job.startDate).format(DATE_FORMAT) : '',
  endDate: job.endDate ? moment(job.endDate).format(DATE_FORMAT) : '',
  divisionId: job.divisionId || null,
  grossSalary: job.grossSalary || null,
  fuelAllowance: job.fuelAllowance || null,
  managerName: job.managerName,
});

export const normalizeEmployeeJobInfoTracking = (tracking) => ({
  joiningDate: moment(tracking.joiningDate).format(DATE_FORMAT) || '',
  createdAt: moment(tracking.createdAt).format(DATE_FORMAT) || '',
  resignationDate: tracking.resignationDate
    ? moment(tracking.resignationDate).format(DATE_FORMAT)
    : '',
  leavingDate: tracking.leavingDate ? moment(tracking.leavingDate).format(DATE_FORMAT) : '',
  probationPeriodDuration: tracking.probationPeriodDuration,
  probationPeriodEnd:
    moment(
      moment(tracking.joiningDate).add(tracking.probationPeriodDuration, 'd').format(DATE_FORMAT),
    ).format(DATE_FORMAT) || '',
  noticePeriodDuration: tracking.noticePeriodDuration || '',
  noticePeriodServed: tracking.noticePeriodServed,
  resignationType: tracking.resignationType || '',
  primaryReasonForLeaving: tracking.primaryReasonForLeaving || '',
  secondaryReasonForLeaving: tracking.secondaryReasonForLeaving || '',
  lastTrackingUpdate: tracking.lastTrackingUpdate
    ? moment(tracking.lastTrackingUpdate).format(DATE_FORMAT)
    : '',
  isActive: tracking.isActive,
});

export function normalizeGetEmployeeJobInfoResponse(res) {
  const jobInfo = res || {};
  return jobInfo && !isEmptyObject(jobInfo)
    ? jobInfo.map((job) => normalizeEmployeeJobInfoHistory(job))
    : [];
}

const normalizeEmployeeCompensationCurrent = (compensation) => ({
  id: compensation.id || '',
  amount: compensation.amount || '',
  date: compensation.date ? moment(`${compensation.date}`).format(DATE_FORMAT) : '',
  reason: compensation.reason || '',
  type: compensation.type || '',
  commitmentUntil: compensation.commitmentUntil
    ? moment(`${compensation.commitmentUntil}`).format(DATE_FORMAT)
    : '',
  comments: compensation.comments || '',
});

export const normalizeCompensationPayFrequencies = (payFrequencies) => {
  return payFrequencies.map((fm, index) => ({
    key: fm.id || index,
    value: capitalizeString(fm.value || fm || ''),
  }));
};
export const normalizeCompensationJobsInfoOnly = (jobsInfo) => {
  return jobsInfo.map((fm, index) => ({
    key: fm.id || index,
    value: fm.title || '',
    isActive: fm.isActive || false,
  }));
};

export const normalizeCompensationReasons = (reasons) => {
  return reasons
    .sort((a, b) => a.id - b.id)
    .map((fm, index) => ({
      key: fm.id || index,
      value: capitalizeString(fm.name || ''),
    }));
};

const normalizeEmployeeCompensationHistory = (listing) =>
  listing.map((compensation) => ({
    id: compensation.id || '',
    amount: compensation.amount || '',
    date: compensation.date ? moment(`${compensation.date}`).format(DATE_FORMAT) : '',
    reason: compensation.reason || '',
    type: compensation.type || '',
    commitmentUntil: compensation.commitmentUntil
      ? moment(`${compensation.commitmentUntil}`).format(DATE_FORMAT)
      : '',
    comments: compensation.comments || '',
    // startDate: compensation.startDate
    //   ? moment(`${compensation.startDate}`).format(DATE_FORMAT)
    //   : 'N/A',
    // endDate: compensation.endDate
    //   ? moment(`${compensation.endDate}`).format(DATE_FORMAT)
    //   : 'N/A',
  }));

export const normalizeCreateEmployeeCompensationResponse = (res) => {
  const compensation = res || {};
  return normalizeEmployeeCompensationCurrent(compensation);
};

export function normalizeGetEmployeeCompensationResponse(res) {
  const compensation = res || [];
  return normalizeEmployeeCompensationHistory(compensation);
  // {
  //   current: normalizeEmployeeCompensationCurrent(compensation.current || {}),
  //   history: normalizeEmployeeCompensationHistory(compensation.listing || {}),
  // };
}

export const normalizeEmployeeTalentCareerPlanning = (careerPlanning, skills) => ({
  areasOfExpertise: filterSkills(skills || [], skillLevel.EXPERT),
  careerAmbitions: careerPlanning.careerAmbitions || '',
  criticalExperience: careerPlanning.criticalExperience || false,
  criticalPosition: careerPlanning.criticalPosition || false,
  highPotential: careerPlanning.highPotential || false,
  leadershipQualities: careerPlanning.leadershipQualities || '',
  promotionReadiness: careerPlanning.promotionReadiness || '',
  successorFor: careerPlanning.successorFor || '',
  isEdit: false,
  isLoading: false,
});

export const normalizeEmployeeTalentCertificates = (certificates) => {
  const certificatesArr = [];
  certificates.map((certificate) => {
    certificatesArr.push({
      id: certificate.id || '',
      name: certificate.name || '',
      certificateNo: certificate.certificateNo || null,
      certificateCompetency: certificate.certificateCompetency || null,
      date: certificate.date ? moment(`${certificate.date}`).format(MONTH_FORMAT) : '',
      validTill: certificate.validTill
        ? moment(`${certificate.validTill}`).format(MONTH_FORMAT)
        : '',
      issuingBody: certificate.issuingBody || '',
      isEdit: false,
      isLoading: false,
    });

    return true;
  });

  return certificatesArr;
};

export const normalizeGetEmployeeTalentResponse = (res) => {
  const talent = res || {};
  return {
    skills: filterSkills(talent.skills || [], skillLevel.BEGINNER),
    primarySkills: filterSkills(talent.skills || [], skillLevel.EXPERT),
    secondarySkills: filterSkills(talent.skills || [], skillLevel.BEGINNER),
    competency: talent.competency || '',
    resourceType: talent.resourceType,
    careerPlanning:
      talent.careerPlanning && !isEmptyObject(talent.careerPlanning)
        ? normalizeEmployeeTalentCareerPlanning(talent.careerPlanning || {}, talent.skills || [])
        : {},
    certificates: normalizeEmployeeTalentCertificates(talent.certificates || []),
    isEdit: false,
    isLoading: false,
  };
};

export const normalizeEmployeeEmployment = (data) => ({
  id: data.id,
  positionTitle: data.positionTitle || '',
  startDate: data.startDate ? moment(`${data.startDate}`).format(MONTH_FORMAT) : '',
  endDate: data.endDate ? moment(`${data.endDate}`).format(MONTH_FORMAT) : '',
  companyName: data.companyName || '',
  city: data.city || '',
  country: data.country || '',
  grossSalary: data.grossSalary || null,
  reasonForLeaving: data.reasonForLeaving || '',
});

export const normalizeGetEmployeeEmploymentHistoryResponse = (res) => {
  const employmentHistory = res || [];
  return employmentHistory.map((history) => normalizeEmployeeEmployment(history));
};

export const normalizeGetEmployeeEducationHistoryResponse = (res) => {
  const educationHistory = res || [];
  return educationHistory.map((history) => ({
    id: history.id || '',
    dateFrom: history.dateFrom ? moment(`${history.dateFrom}`).format(MONTH_FORMAT) : '',
    dateTo: history.dateTo ? moment(`${history.dateTo}`).format(MONTH_FORMAT) : '',
    major: history.major || '',
    degree: history.degree || '',
    qualificationTypeId:
      (history.qualificationType && history.qualificationType.id) ||
      history.qualificationTypeId ||
      null,
    institute: history.institute || '',
    score: history.value || history.score || '',
    city: history.city || '',
    province: history.province || '',
    country: history.country || '',
    isEdit: false,
    isLoading: false,
  }));
};

export const normalizeGetEmployeeDocumentsResponse = (res) => {
  const documents = res || [];
  return documents.map((document) => ({
    id: document.id || '',
    name: document.name || '',
    url: document.url || '',
    type: document.type,
    createdAt: document.createdAt,
  }));
};

export const normalizeEmployeeCommentResponse = (res) => {
  const comments = res || [];
  return comments.map((comment) => ({
    id: comment.id || '',
    authorId: (comment.commenter && comment.commenter.id) || null,
    author: (comment.commenter && comment.commenter.fullName) || '',
    dated: comment.dated ? moment(comment.dated).format(COMMENT_TIME_FORMAT) : '',
    title: comment.title || '',
    value: comment.content || '',
    document: comment.document || null,
    documentId: comment.documentId || null,
  }));
};

export const normalizeGetBenefitsResponse = (benefits) => {
  return benefits.map((fm, index) => ({
    id: fm.id || index,
    name: fm.name || '',
  }));
};

export const normalizeGetPayFrequenciesResponse = (res) => {
  const payFrequencies = res || [];
  return prepareOptionsFromArray(payFrequencies);
};
export const normalizeGetjoResponse = (res) => {
  const payFrequencies = res || [];
  return prepareOptionsFromArray(payFrequencies);
};
export const normalizeGetMaritalStatusesResponse = (res) => {
  const maritalStatuses = (res && res.map((item) => item.status)) || [];
  return prepareOptionsFromArray(maritalStatuses);
};

export const normalizeGetEmploymentStatusesResponse = (res) => {
  const employmentStatuses = res || [];
  return prepareOptionsFromJSON(employmentStatuses);
};

export const normalizeGetBloodGroupsResponse = (res) => {
  const bloodGroups = (res && res.map((item) => item.name)) || [];
  return prepareOptionsFromArray(bloodGroups);
};

export const normalizeGetReligionsResponse = (res) => {
  const religions = (res && res.map((item) => item.name)) || [];
  return prepareOptionsFromArray(religions);
};

export const normalizeGetQualificationsResponse = (res) => {
  const qualifications = res || [];
  return prepareOptionsFromJSON(qualifications);
};

export const normalizeGetEmployeeAuditLogsResponse = (res) => {
  if (typeof res === 'object') {
    return flattenDeep(Object.values(res));
  }
  return [];
};
