/* eslint-disable no-nested-ternary */
import moment from 'moment';

export function removeItemAtIndex(array, index) {
  if (index > -1) {
    array.splice(index, 1);
  }
  return array;
}
export function isArray(obj) {
  return obj.constructor === Array;
}
export function editItemAtIndex(array, data, index) {
  array[index] = data; // eslint-disable-line no-param-reassign
  return array;
}

export function updateObjAgainstKey(arr, key, valueObj) {
  if (valueObj[key]) {
    let index;
    const objExists = arr.some((obj, objIndex) => {
      if (obj[key] === valueObj[key]) {
        index = objIndex;
        return true;
      }
      return false;
    });
    if (objExists) {
      removeItemAtIndex(arr, index);
    }
  }
  arr.push(valueObj);
}

export function getObjectByKey(arr, key, valueName) {
  let answer = null;
  arr.some((element) => {
    if (element[valueName] === key) {
      answer = element;
      return answer;
    }
    return false;
  });
  return answer;
}

export function getProjectRow(project, projectRoles) {
  const row = [];
  row.push(project.name);
  row.push(project.businessUnit ? project.businessUnit.name : 'N/A');
  row.push(project.startDate.toString() === 'Invalid date' ? '' : project.startDate);
  row.push(project.endDate.toString() === 'Invalid date' ? '' : project.endDate);
  if (project.manager) {
    row.push(project.manager.fullName);
  } else {
    row.push('');
  }
  if (project.deliveryManager) {
    row.push(project.deliveryManager.fullName);
  } else {
    row.push('');
  }
  if (project.architect) {
    row.push(project.architect.fullName);
  } else {
    row.push('');
  }
  projectRoles.forEach((projectRole) => {
    const item = project.leadershipRoles
      .filter((lr) => lr.projectRoleId === projectRole.id)
      .map((lr) => lr.employee.fullName)
      .join(' | ');
    row.push(item);
  });
  return row;
}

export function transformProjectData(projects, projectRoles) {
  const columns = [
    'Name',
    'Business Unit',
    'Start Date',
    'End Date',
    'Project Manager',
    'Delivery Manager',
    'Project Architect',
  ];
  projectRoles.forEach((projectRole) => {
    columns.push(projectRole.role);
  });
  const rows = projects.map((project) => getProjectRow(project, projectRoles));
  let data = `${columns.join(', ')} \n`;
  rows.forEach((row) => {
    data += `${row.join(', ')} \n`;
  });
  return data;
}

export function transformTableData(antColumn, antData) {
  const allowed = antColumn.map((value) => value.key);
  const excludeKeys = ['managerName', 'allocation', 'startDate', 'endDate'];
  const multiRows = [];
  let transformed = antData
    .map((data) => {
      const wrappingData = data;
      if (
        'project' in wrappingData &&
        Array.isArray(wrappingData.project) &&
        wrappingData.project.length > 1
      ) {
        for (let i = 1; i < wrappingData.project.length; i += 1) {
          const row = {
            project: wrappingData.project[i],
            allocated: wrappingData.allocated[i],
            releasingOn: wrappingData.releasingOn[i],
            type: wrappingData.type[i],
            available: wrappingData.available,
            competency: wrappingData.competency,
            designation: wrappingData.designation,
            name: wrappingData.name,
            location: wrappingData.location,
            skills: wrappingData.skills,
          };
          delete wrappingData.project[i];
          delete wrappingData.allocated[i];
          delete wrappingData.releasingOn[i];
          delete wrappingData.type[i];
          multiRows.push(row);
        }
        /* eslint prefer-destructuring: ["error", {"object": true, "array": false}] */
        wrappingData.project = wrappingData.project[0];
        wrappingData.allocated = wrappingData.allocated[0];
        wrappingData.releasingOn = wrappingData.releasingOn[0];
        wrappingData.type = wrappingData.type[0];
      }
      return wrappingData;
    })
    .map((Data) =>
      Object.keys(Data)
        .filter((key) => allowed.includes(key) || excludeKeys.includes(key))
        .reduce((obj, key) => {
          const filteredObj = obj;
          filteredObj[key] = Data[key];
          return filteredObj;
        }, {}),
    )
    .map((data) => {
      const wrappingData = data;
      if ('endDate' in wrappingData) {
        wrappingData.endDate =
          wrappingData.endDate.toString() === 'Invalid date' ? '' : wrappingData.endDate;
      }
      return wrappingData;
    })
    .map((data) => {
      const wrappingData = data;

      if ('manager' in wrappingData) {
        wrappingData.manager = `${data.manager.firstName} ${data.manager.lastName}`;
      }

      if ('deliveryManager' in wrappingData && wrappingData.deliveryManager !== null) {
        wrappingData.deliveryManager = `${data.deliveryManager.firstName} ${data.deliveryManager.lastName}`;
      }

      if ('architect' in wrappingData && wrappingData.architect !== null) {
        wrappingData.architect = `${data.architect.firstName} ${data.architect.lastName}`;
      }

      return wrappingData;
    })
    .map((data) => {
      const wrappingData = data;

      if ('skills' in wrappingData) {
        wrappingData.skills = wrappingData.skills.map((a) =>
          'skill' in a && a.skill ? a.skill.name : 'name' in a ? a.name : '',
        );
      }
      return wrappingData;
    })
    .map((data) => {
      const wrappingData = data;

      if ('releasingOn' in wrappingData) {
        if (typeof wrappingData.releasingOn === 'string') {
          wrappingData.releasingOn = moment(wrappingData.releasingOn).format('YYYY-MM-DD');
        } else {
          wrappingData.releasingOn =
            wrappingData.releasingOn[0] !== ''
              ? moment(wrappingData.releasingOn[0]).format('YYYY-MM-DD')
              : '';
        }
      }
      return wrappingData;
    })
    .map((data) => {
      const wrappingData = data;
      if ('available' in wrappingData) {
        if ('fte' in wrappingData.available) {
          delete wrappingData.available.fte;
        }
        wrappingData.available = wrappingData.available.billed;
      }
      return wrappingData;
    });
  if (multiRows.length) {
    transformed = [...transformed, ...transformTableData(antColumn, multiRows)];
  }

  return transformed.sort((a, b) => {
    const nameA = 'name' in a ? a.name.toUpperCase() : '';
    const nameB = 'name' in b ? b.name.toUpperCase() : '';
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
}

export function selectRandom(array) {
  return array[Math.floor(Math.random() * array.length)];
}

export function prepareOptionsFromArray(array = []) {
  const options = [];
  for (let index = 0; index < array.length; index += 1) {
    options.push({ key: array[index], value: array[index] });
  }
  return options;
}

export function prepareOptionsFromArrayWithIndexKey(array = []) {
  const options = [];
  for (let index = 0; index < array.length; index += 1) {
    options.push({ key: index, value: array[index] });
  }
  return options;
}

// Expects key value pair in object only
export function prepareOptionsFromJSON(JSON = []) {
  const options = [];
  for (let index = 0; index < JSON.length; index += 1) {
    const currentObject = JSON[index];
    const keys = Object.keys(currentObject);
    if (keys.length >= 2) {
      options.push({
        key: currentObject[keys[0]],
        value: currentObject[keys[1]],
      });
    }
  }
  return options;
}

export function prepareOptionsFromJSONDestructure(JSON = []) {
  const options = [];
  for (let index = 0; index < JSON.length; index += 1) {
    const currentObject = JSON[index];
    const keys = Object.keys(currentObject);
    if (keys.length >= 2) {
      const option = {
        key: currentObject[keys[0]],
        value: currentObject[keys[1]],
      };

      if (keys.length > 2) {
        for (let propertyIndex = 2; propertyIndex < keys.length; propertyIndex += 1) {
          const property = keys[propertyIndex];
          option[property] = currentObject[property];
        }
      }
      options.push(option);
    }
  }
  return options;
}
/**
 * Utility to get value for a specific key for AntD Select options
 * @param {object[]} JSON array of AntD Select options [{key:number,value:string}]
 * @param {number} value value to match
 * @param {string} [keyName='key'] key name to match value against
 * @param {string} [valueName='value'] key name of value to return on match
 */
export function getValueForKeyinJSON(JSON = [], value, keyName = 'key', valueName = 'value') {
  for (let index = 0; index < JSON.length; index += 1) {
    const currentObject = JSON[index];
    if (currentObject[keyName] === value) {
      return currentObject[valueName];
    }
  }
  return undefined;
}

export function createArrayForSelectWithId(
  array = [],
  excludeIds = [],
  firstKey = 'name',
  secondKey = '',
) {
  let result = [];
  if (excludeIds && excludeIds.length) {
    result = array.filter((arrayItem) => !excludeIds.includes(arrayItem.id));
  }
  return result.map((arrayItem) => ({
    key: arrayItem.id,
    value: secondKey ? `${arrayItem[firstKey]} ${arrayItem[secondKey]}` : `${arrayItem[firstKey]}`,
  }));
}
