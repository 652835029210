export const XLS_FILENAME = 'Interview Questions';
export const ACTION_TOOLTIP =
  'Questions asscociated to interviews can only be edited or deleted in Interviews screen';
export const XLS_META = [
  {
    key: 'text',
    label: 'Question',
  },
  {
    key: 'answer',
    label: 'Answer',
  },
  {
    key: 'project.name',
    label: 'Client',
  },
  {
    key: 'track.name',
    label: 'Track',
  },
];
