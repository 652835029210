import { GET_HISTORY_INIT, GET_HISTORY_SUCCESS, GET_HISTORY_FAILURE } from 'constants/HistoryTypes';

import { CREATE_WORKLOG_SUCCESS } from 'constants/WorkLogTypes';

import { updateObjAgainstKey } from 'utils/arrayUtils';

const initialState = {
  isLoading: false,
  logs: [],
  mostEngagingProjects: [],
};
export default function historyReducer(state = initialState, action) {
  switch (action.type) {
    case GET_HISTORY_INIT: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }
    case CREATE_WORKLOG_SUCCESS: {
      const logs = state.logs.slice();
      const { data } = action.data;
      updateObjAgainstKey(logs, 'id', data);
      return Object.assign({}, state, {
        logs,
      });
    }
    case GET_HISTORY_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        logs: action.data.normalizedLogs,
        mostEngagingProjects: action.data.mostEngagingProjects,
      });
    }
    case GET_HISTORY_FAILURE: {
      return Object.assign({}, state, {
        isLoading: false,
        logs: [],
        mostEngagingProjects: [],
      });
    }
    default:
      return state;
  }
}
