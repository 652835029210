import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { ROOT } from 'configs/routesConstants';
import PerformanceManagementContainer from 'containers/lineManagerDashboard/PerformanceManagement';

class LMDashboardRoutes extends React.PureComponent {
  render() {
    const { match } = this.props;
    return (
      <React.Fragment>
        <Switch>
          <Route
            exact
            path={`${match.path}/performance-management`}
            component={PerformanceManagementContainer}
          />

          <Redirect to={ROOT} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default LMDashboardRoutes;
