import { connect } from 'react-redux';
import { getDashboardActions } from 'store/actions/DashboardActions';
import Dashboard from 'components/dashboard/Dashboard';

function mapStateToProps(state) {
  const { auth, employeeReducer, dashboardReducer } = state;
  return {
    user: auth.user,
    employees: employeeReducer.employees,
    dashboard: dashboardReducer.dashboard,
    isLoading: employeeReducer.isLoading,
    isDashboardLoading: dashboardReducer.isDashboardLoading,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getDashboardActions: () => dispatch(getDashboardActions()),
});

const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default DashboardContainer;
