import * as _ from 'underscore';
import routeConfigs from 'configs/routeConfigs';

/**
 * Utility function to check whether a user has access to a component or resource
 * @param {string[]} userPermissions Permissions of current user
 * @param {string[]} requiredPermissions Permissions required for component or resource
 */
export const hasPermission = (userPermissions, requiredPermissions) =>
  _.intersection(userPermissions, requiredPermissions).length > 0;

/**
 * Returns a list of permissions for provided path
 * @param {Object} props
 */
export const getRoutePermissions = (path) => {
  const matchedPath = routeConfigs[path];
  if (matchedPath && matchedPath.permissions) {
    return routeConfigs[path].permissions;
  }
  return [];
};
