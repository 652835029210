import {
  GET_PRIMARY_TEAM_LEAVES_INIT,
  GET_PRIMARY_TEAM_LEAVES_SUCCESS,
  GET_PRIMARY_TEAM_LEAVES_FAILURE,
  GET_SECONDARY_TEAM_LEAVES_INIT,
  GET_SECONDARY_TEAM_LEAVES_SUCCESS,
  GET_SECONDARY_TEAM_LEAVES_FAILURE,
  GET_BENCH_LEAVES_INIT,
  GET_BENCH_LEAVES_SUCCESS,
  GET_BENCH_LEAVES_FAILURE,
  UPDATE_TEAM_LEAVE_INIT,
  UPDATE_TEAM_LEAVE_SUCCESS,
  UPDATE_TEAM_LEAVE_FAILURE,
  UPDATE_TEAM_LEAVE_COMPLETE,
  GET_LEAVE_CALENDAR_INIT,
  GET_LEAVE_CALENDAR_SUCCESS,
  GET_LEAVE_CALENDAR_FAILURE,
} from 'constants/TeamLeavesTypes';

const initialState = {
  teamLeaves: [],
  indirect: [],
  loader: false,
  updateSuccess: false,
  primary: {
    leaves: [],
    pageInfo: {},
    primaryLoader: false,
  },
  secondary: {
    leaves: [],
    pageInfo: {},
    secondaryLoader: false,
  },
  leaveCalendar: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PRIMARY_TEAM_LEAVES_INIT: {
      return {
        ...state,
        primary: {
          leaves: [],
          pageInfo: {},
          primaryLoader: true,
        },
        updateSuccess: false,
      };
    }
    case GET_PRIMARY_TEAM_LEAVES_SUCCESS: {
      const { leaves, pageInfo } = action.data;
      return {
        ...state,
        primary: {
          leaves,
          pageInfo,
          primaryLoader: false,
        },
      };
    }
    case GET_PRIMARY_TEAM_LEAVES_FAILURE: {
      const { primary } = state;
      return {
        ...state,
        primary: {
          leaves: primary.leaves,
          pageInfo: primary.pageInfo,
          primaryLoader: false,
        },
      };
    }
    case GET_SECONDARY_TEAM_LEAVES_INIT: {
      return {
        ...state,
        secondary: {
          leaves: [],
          pageInfo: {},
          primaryLoader: true,
        },
        updateSuccess: false,
      };
    }
    case GET_SECONDARY_TEAM_LEAVES_SUCCESS: {
      const { leaves, pageInfo } = action.data;
      return {
        ...state,
        secondary: {
          leaves,
          pageInfo,
          primaryLoader: false,
        },
      };
    }
    case GET_SECONDARY_TEAM_LEAVES_FAILURE: {
      const { secondary } = state;
      return {
        ...state,
        secondary: {
          leaves: secondary.leaves,
          pageInfo: secondary.pageInfo,
          primaryLoader: false,
        },
      };
    }
    case GET_BENCH_LEAVES_INIT: {
      return {
        ...state,
        teamLeavesBench: [],
        loader: true,
      };
    }
    case GET_BENCH_LEAVES_SUCCESS: {
      return {
        ...state,
        teamLeavesBench: action.data.teamLeavesBench,
        loader: false,
      };
    }
    case GET_BENCH_LEAVES_FAILURE: {
      return {
        ...state,
        loader: false,
      };
    }

    case GET_LEAVE_CALENDAR_INIT: {
      return {
        ...state,
        leaveCalendar: [],
        loader: true,
      };
    }
    case GET_LEAVE_CALENDAR_SUCCESS: {
      return {
        ...state,
        leaveCalendar: action.data,
        loader: false,
      };
    }
    case GET_LEAVE_CALENDAR_FAILURE: {
      return {
        ...state,
        loader: false,
      };
    }
    case UPDATE_TEAM_LEAVE_INIT: {
      return {
        ...state,
        loader: true,
        updateSuccess: false,
      };
    }
    case UPDATE_TEAM_LEAVE_SUCCESS: {
      return {
        ...state,
        loader: false,
        updateSuccess: true,
      };
    }
    case UPDATE_TEAM_LEAVE_FAILURE: {
      return {
        ...state,
        loader: false,
        updateSuccess: false,
      };
    }
    case UPDATE_TEAM_LEAVE_COMPLETE: {
      return {
        ...state,
        updateSuccess: false,
      };
    }
    default:
      return state;
  }
}
