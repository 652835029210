import React from 'react';
import EmployeeGoals from 'components/MyProfile/goalsReviews/employeeGoals';
import PerformanceReview from 'components/MyProfile/goalsReviews/performanceReview';
import { EnhancedCard, EnhancedTabs } from 'components/shared/antd';

const { TabPane } = EnhancedTabs;
const GoalsReviews = (props) => {
  const { history } = props;

  return (
    <React.Fragment>
      <EnhancedCard
        className="mt-20"
        style={{
          color: 'rgba(0,0,0,0.55)',
        }}
      >
        <EnhancedTabs defaultActiveKey="1" centered type="card">
          <TabPane tab="Employee Goals" key="1">
            <EmployeeGoals history={history} variant="my-profile" />
          </TabPane>
          <TabPane tab="Performance Review" key="2">
            <PerformanceReview {...props} />
          </TabPane>
        </EnhancedTabs>
      </EnhancedCard>
    </React.Fragment>
  );
};

export default GoalsReviews;
