import {
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedRow,
  EnhancedModal,
  EnhancedDatePicker,
  EnhancedForm,
} from 'components/shared/antd';
import React, { useState } from 'react';
import moment from 'moment';
import { dateFormat } from 'configs/constants';
import RequiredField from 'components/RequiredField';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';

const { MonthPicker } = EnhancedDatePicker;

const ShowPreviousCycle = (props) => {
  const {
    isModalVisible,
    handleOk,
    handleCancel,
    showPreviousPMCycleActions,
    params,
    previousPMCycle,
  } = props;
  const [pmcField, setPMCField] = useState({
    startMonth: null,
    endMonth: null,
  });
  const { startMonth } = pmcField;

  const showPrevoiusPMC = () => {
    showPreviousPMCycleActions({ payload: pmcField, parameters: params });
  };

  const onStartMonthChange = (date) => {
    setPMCField({
      startMonth: date ? date.format(dateFormat.abbreviatedMonthAndYear) : null,
      endMonth: null,
    });
  };

  const onEndMonthChange = (date) => {
    setPMCField({
      ...pmcField,
      endMonth: date ? date.format(dateFormat.abbreviatedMonthAndYear) : null,
    });
  };

  const closeModalHandler = () => {
    handleCancel();
  };

  const getDateMonthIndexAndYear = (formattedDate) => {
    const splitDate = formattedDate.split('-');
    return {
      monthIndex: +moment().month(splitDate[0]).format('M'),
      year: +splitDate[1],
    };
  };

  const disabledDate = (date) => {
    if (!startMonth || !date) return false;

    const currentDateAndYear = date.format(dateFormat.abbreviatedMonthAndYear);

    const { monthIndex: startMonthIndex, year: startMonthYear } =
      getDateMonthIndexAndYear(startMonth);
    const { monthIndex: currentMonthIndex, year: currentMonthYear } =
      getDateMonthIndexAndYear(currentDateAndYear);

    return (
      currentMonthYear < startMonthYear ||
      (currentMonthIndex < startMonthIndex && currentMonthYear === startMonthYear)
    );
  };

  const getColumns = () => {
    return [
      {
        title: 'Competency Name',
        dataIndex: 'name',
      },
      {
        title: 'Average Rating',
        dataIndex: 'rating',
      },
    ];
  };

  return (
    <EnhancedModal
      title="Average monthly performance rating"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={closeModalHandler}
      className="add-goals-modal"
      width="1041px"
    >
      <EnhancedCard
        bordered={false}
        className="details-card-wrapper"
        style={{
          marginTop: 10,
        }}
      >
        <EnhancedRow className="mb-24">
          <EnhancedForm layout="inline" className="goals-form w-full">
            <EnhancedCol lg={3} md={3}>
              <RequiredField> Start Month </RequiredField>
            </EnhancedCol>
            <EnhancedCol lg={5} md={5}>
              <MonthPicker
                id="mo"
                attr="mo"
                onChange={onStartMonthChange}
                placeholder="Select start month"
              />
            </EnhancedCol>
            <EnhancedCol lg={2} md={2} />
            <EnhancedCol lg={3} md={3}>
              <RequiredField> End Month </RequiredField>
            </EnhancedCol>
            <EnhancedCol lg={5} md={5}>
              <MonthPicker
                id="me"
                attr="me"
                onChange={onEndMonthChange}
                placeholder="Select End month"
                disabledDate={disabledDate}
                key={startMonth}
                disabled={!startMonth}
              />
            </EnhancedCol>
            <EnhancedCol lg={2} md={2} />
            <EnhancedCol lg={4} md={4}>
              <EnhancedButton
                disabled={!pmcField.startMonth || !pmcField.endMonth}
                onClick={showPrevoiusPMC}
              >
                {'Fetch Data'}
              </EnhancedButton>
            </EnhancedCol>
          </EnhancedForm>
        </EnhancedRow>
        <EnhancedClientSideTable
          columns={getColumns()}
          data={previousPMCycle}
          showResetFiltersButton={false}
        />
      </EnhancedCard>
    </EnhancedModal>
  );
};
export default ShowPreviousCycle;
