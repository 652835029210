import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { hasPermission } from 'utils/AccessUtils';
import Permissions from 'constants/AccessPermissions';

class Authorize extends React.Component {
  isAuthorized = () => {
    let authorized = hasPermission(this.props.userPermissions, this.props.requiredPermissions);
    if (!authorized && this.props.requiredPermissions.includes(Permissions.Reportee.Allowed)) {
      authorized = this.props.isManager;
    }
    return authorized;
  };
  handleUnauthorizedAccess = () => {
    if (this.props.redirect) {
      return <Redirect to={this.props.redirect} />;
    }
    return null;
  };
  render() {
    if (!this.isAuthorized()) {
      return this.handleUnauthorizedAccess();
    }
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

function mapStateToProps(state) {
  const { auth } = state;
  return {
    userPermissions: auth.user ? auth.user.permissions : [],
    isManager: auth.user ? auth.user.isManager : false,
  };
}

export default connect(mapStateToProps, null)(Authorize);
