import { connect } from 'react-redux';

import EmployeeDetails from 'components/employees/EmployeeDetails';
import {
  getEmployeesSkillsActions,
  updateEmployeeSkillsActions,
  getEmployeesProjectActions,
  getEmployeeDepartmentsAction,
  getEmployeeBusinessUnitAction,
  getEmployeeDivisionsAction,
  getEmployeeCompetenciesAction,
} from 'store/actions/EmployeeActions';
import updateBreadCrumb from 'store/actions/BreadCrumbActions';
import { getAllDesignationActions } from 'store/actions/DesignationActions';
import { getAllLocationActions } from 'store/actions/LocationActions';

function mapStateToProps(state, ownProps) {
  const { employeeReducer, auth, designationsReducer, locationsReducer } = state;
  const { match } = ownProps;
  const {
    skills,
    skillLoading,
    isLoading,
    isProjectLoading,
    employees,
    departments,
    businessUnits,
    divisions,
    competencies,
  } = employeeReducer;
  const employee = employees.filter((emp) => emp.id === parseInt(match.params.id, 10))[0] || {};
  return {
    userId: auth.user && auth.user.id,
    readOnly: auth.user && auth.user.isReadOnly,
    userEmail: auth.user && auth.user.email,
    employee,
    employees,
    defaultSkills: skills,
    isLoading,
    designations: designationsReducer.designations,
    locations: locationsReducer.locations,
    skillLoading,
    isProjectLoading,
    departments,
    businessUnits,
    divisions,
    competencies,
    permissions: auth.user.permissions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEmployeesSkillsAction: () => dispatch(getEmployeesSkillsActions()),
    getEmployeeDepartments: () => dispatch(getEmployeeDepartmentsAction()),
    getAllDesignations: () => dispatch(getAllDesignationActions()),
    getAllLocations: () => dispatch(getAllLocationActions()),
    getEmployeeBusinessUnits: () => dispatch(getEmployeeBusinessUnitAction()),
    getEmployeeDivisionsAction: () => dispatch(getEmployeeDivisionsAction()),
    getEmployeeCompetenciesAction: () => dispatch(getEmployeeCompetenciesAction()),
    updateEmployeeSkillsActions: (employee, skills) =>
      dispatch(updateEmployeeSkillsActions(employee, skills)),
    getEmployeesProjectActions: (userId) => dispatch(getEmployeesProjectActions(userId)),
    updateBreadCrumb: (key, oldVal, newVal) => dispatch(updateBreadCrumb(key, oldVal, newVal)),
  };
}

const EmployeeDetailContainer = connect(mapStateToProps, mapDispatchToProps)(EmployeeDetails);

export default EmployeeDetailContainer;
