import React from 'react';
// import efficiencyIcon from 'images/ic_efficiency.svg';
// import ownershipIcon from 'images/ic_ownership.svg';
// import ethicsIcon from 'images/ic_ethics.svg';
import qualityIcon from 'images/ic_quality.svg';
// import skillIcon from 'images/ic_skill.svg';
import { EnhancedCol, EnhancedRow, EnhancedSpin } from 'components/shared/antd';

const EmployeeRatingCards = (props) => {
  const { employeeRatingSummary } = props;
  const { loading, data } = employeeRatingSummary;

  const getCard = (cardInfo) => (
    <EnhancedCol lg={6} style={{ marginBottom: '16px', marginTop: '16px' }}>
      <div className="cardBox">
        <p className="cardHeading">{cardInfo.name}</p>
        <img
          src={qualityIcon}
          alt="quality icon"
          style={{ marginBottom: '8px', marginRight: '8px' }}
        />
        <span className="cardValue">{cardInfo.rating}</span>
      </div>
    </EnhancedCol>
  );

  return (
    <EnhancedCol lg={24}>
      {loading ? (
        <EnhancedRow gutter={14}>
          <div className="box">
            <div>
              <EnhancedSpin tip="loading" />
            </div>
          </div>
        </EnhancedRow>
      ) : (
        <EnhancedRow
          style={{ display: 'flex', flexDirection: 'row', flexFlow: 'wrap' }}
          gutter={14}
        >
          {data.map((item) => getCard(item))}
        </EnhancedRow>
      )}
    </EnhancedCol>
  );
};

export default EmployeeRatingCards;
