import { EnhancedModal } from '../../components/shared/antd';

const { confirm } = EnhancedModal;

export default (
  title,
  onClickOkay,
  content = '',
  okText = 'Yes',
  okType = 'danger',
  cancelText = 'No',
) =>
  confirm({
    title,
    content,
    okText,
    okType,
    cancelText,
    onOk() {
      onClickOkay();
    },
    onCancel() {},
  });
