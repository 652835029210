import React from 'react';
import moment from 'moment';
import { dateFormat } from 'configs/constants';
import { notSetVariable } from 'configs/additionalConstants';
import { EnhancedIcon, EnhancedDatePicker, EnhancedForm } from 'components/shared/antd';

const FormItem = EnhancedForm.Item;

class EmployeeEditableDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
      key: props.value,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    if (value && this.state.key !== this.props.value) {
      this.setState({ key: value });
    }
  }

  onSubmit = () => {
    const { updateInput, inputName } = this.props;
    const { key } = this.state;
    updateInput(inputName, key);
    this.setState({ editable: false });
  };

  onCancelUpdate = () => {
    this.setState({ editable: false });
  };
  onEdit = () => {
    this.setState({ editable: true, key: moment().format(dateFormat.YYYYMMDDwithoutOffSet) });
  };
  handleChange = (key) => {
    let date = null;
    if (key) {
      date = moment(key).format(dateFormat.YYYYMMDDwithoutOffSet);
    }

    if ((!date && this.props.allowNull) || !!date) {
      this.setState({ key: date });
      if (this.props.updateInput) this.props.updateInput(this.props.inputName, date);
    }
  };

  disabledStartDate = (current) => {
    let shouldDisable = false;
    if (this.props.disableDate) {
      const { disableBefore, disableAfter } = this.props;
      if (disableAfter) {
        shouldDisable = current && current > moment(disableAfter).endOf('day');
      } else if (disableBefore) {
        shouldDisable = current && current < moment(disableBefore).startOf('day');
      }
    }
    return shouldDisable;
  };

  validate = (value) => {
    if (!value || notSetVariable.includes(value)) {
      return false;
    }
    return true;
  };
  validateLabelValue = (value, field) => {
    if (!value || notSetVariable.includes(value)) {
      return <span className="field-details">{field}</span>;
    }
    return this.renderFromattedDate(value);
  };

  renderFromattedDate = (value) => {
    const { displayFormat } = this.props;
    const formatType = displayFormat || dateFormat.DDMMMYYYYwithSpace;
    return moment(value).format(`${formatType}`);
  };

  render() {
    const { editable } = this.state;
    const { editingAllowed, values, text, field, value, isDisabled } = this.props;
    let label = values.filter((v) => v.key === this.state.key)[0];
    if (!label && text) label = { value: text };
    if (!editingAllowed) {
      return (
        <div className="editable-cell">
          {this.validate(label) ? (
            this.validateLabelValue(label.value, field)
          ) : (
            <span className="field-details">Not Set</span>
          )}
        </div>
      );
    }
    return (
      <div
        className="editable-cell"
        style={{
          cursor: 'pointer',
          ...this.props.style,
        }}
      >
        {editable ? (
          <div className="wrapper">
            <FormItem style={{ margin: 0 }}>
              <EnhancedDatePicker
                style={{ width: '100%' }}
                onChange={this.handleChange}
                allowClear
                value={value && !isDisabled ? moment(value) : null}
                format={value ? dateFormat.DDMMMYYYYwithSpace : false}
                disabledDate={this.disabledStartDate}
                disabled={isDisabled}
              />
            </FormItem>
            <EnhancedIcon
              type="check"
              className="input-icon check"
              style={{
                marginLeft: '0.5em',
                marginTop: '0.4rem',
                cursor: 'pointer',
                ...this.props.iconStyle,
              }}
              onClick={this.onSubmit}
              title="Click to save"
            />
            <EnhancedIcon
              type="close"
              className="input-icon close"
              style={{
                marginLeft: '0.5em',
                marginTop: '0.4rem',
                cursor: 'pointer',
                ...this.props.iconStyle,
              }}
              onClick={this.onCancelUpdate}
              title="Click to close"
            />
          </div>
        ) : (
          <div className="inline" role="presentation" onClick={this.onEdit} onKeyDown={this.onEdit}>
            {this.validate(label) ? (
              `${this.renderFromattedDate(label.value)}`
            ) : (
              <span className="field-details">Not Set</span>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default EmployeeEditableDate;
