import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import { getProjectActivityLogsAction } from 'store/actions/ProjectActivityLogActions';

import {
  getProjectsInit,
  getProjectsSuccess,
  getProjectsFailure,
  createProjectInit,
  createProjectSuccess,
  createProjectFailure,
  editProjectDetailsInit,
  editProjectDetailsSuccess,
  editProjectDetailsFailure,
  activateProjectInit,
  activateProjectSuccess,
  activateProjectFailure,
} from 'store/actions/ProjectActions';

import {
  getRcaProjectsInit,
  getRcaProjectsSuccess,
  getRcaProjectsFailure,
} from 'store/actions/RcaProjectsActions';

import { gaEvent } from 'sources/AnalyticsSource';
import { categories, events } from 'configs/gaConstants';

import { normalizeProjects, normalizeProjectsForWorkLog } from 'normalizers/projectNormalizers';

import { projectsUrls, rcaUrls, workLogProjectListUrls } from 'configs/constants';
import moment from 'moment';

export const getProjects = () => (dispatch) => {
  dispatch(getProjectsInit());
  axios
    .get(projectsUrls.GET)
    .then((response) => {
      if (response.data.success) {
        // parse response through normalizer
        const projectsNormalized = normalizeProjects(response.data.data);
        // call success or failure action
        dispatch(getProjectsSuccess(projectsNormalized));
      } else {
        dispatch(getProjectsFailure(response.data.code));
      }
    })
    .catch((error) => {
      dispatch(getProjectsFailure(error));
    });
};

export const getWorkLogProjects = () => {
  const url = workLogProjectListUrls.GET;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        if (response.data.success) {
          const projectsNormalized = normalizeProjectsForWorkLog(response.data.data);
          dispatch(getProjectsSuccess(projectsNormalized));
        } else {
          dispatch(getProjectsFailure(response.data.code));
        }
      })
      .catch((error) => {
        dispatch(getProjectsFailure(error));
      });
  };
};

export const addProject = (req) => {
  Object.keys(req).forEach((key) => {
    if (req[key] === '' && key !== 'name') {
      delete req[key];
    }
  });
  const url = projectsUrls.CREATE;
  return (dispatch) => {
    gaEvent(categories.PROJECTS, events.ADD_PROJECT);
    dispatch(createProjectInit());
    req.startDate = moment.utc(req.startDate).startOf('day');
    req.endDate = req.endDate ? moment.utc(req.endDate).startOf('day') : null;
    axios
      .post(url, req)
      .then((response) => {
        if (response.data.success) {
          const addedProject = normalizeProjects(response.data.data)[0];
          EnhancedNotification.success({ message: 'Project Added Successfully.' });
          dispatch(createProjectSuccess(addedProject));
          dispatch(getProjects());
        } else {
          EnhancedNotification.error({ message: 'Adding Project failed.' });
          dispatch(createProjectFailure(response.data.code));
        }
      })
      .catch((error) => {
        EnhancedNotification.error({ message: 'Adding Project failed.' });
        dispatch(createProjectFailure(error));
      });
  };
};

export const editProjectDetails = (projectId, ProjectData, employeeId) => (dispatch) => {
  const url = projectsUrls.PUT.replace('{projectId}', projectId);
  const projectData = ProjectData;
  projectData.startDate = moment.utc(ProjectData.startDate).startOf('day');
  projectData.endDate = ProjectData.endDate ? moment.utc(ProjectData.endDate).startOf('day') : null;
  dispatch(editProjectDetailsInit());
  axios
    .put(url, projectData)
    .then((response) => {
      if (response.data.success) {
        const dataProject = response.data.data;
        const messageToShow =
          Object.prototype.hasOwnProperty.call(projectData, 'isActive') &&
          projectData.isActive === false
            ? 'Project Marked Completed Successfully.'
            : 'Project Details Edited Successfully.';
        EnhancedNotification.success({ message: messageToShow });
        dispatch(getProjectActivityLogsAction(projectId, 1));
        dispatch(editProjectDetailsSuccess(dataProject));
        dispatch(getProjects(employeeId));
      } else {
        EnhancedNotification.error({ message: 'Editing Details failed.' });
        dispatch(editProjectDetailsFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({ message: 'Editing Details failed.' });
      dispatch(editProjectDetailsFailure());
    });
};

export const getAllocationGroupedProjects = (startDate, endDate) => (dispatch) => {
  const dateFilter = `?startDate=${startDate}&endDate=${endDate}`;

  const url = rcaUrls.PROJECTS.SUMMARY.concat(dateFilter);

  dispatch(getRcaProjectsInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        dispatch(getRcaProjectsSuccess(response.data.data));
      } else {
        dispatch(getRcaProjectsFailure());
      }
    })
    .catch(() => {
      dispatch(getRcaProjectsFailure());
    });
};

export const activateProject = (data) => {
  const url = projectsUrls.ACTIVATE_PROJECT.replace('{{projectId}}', data.projectId);
  return (dispatch) => {
    dispatch(activateProjectInit());
    axios
      .put(url, data)
      .then((response) => {
        dispatch(activateProjectSuccess(response));
        EnhancedNotification.success({ message: 'Project activated successfully' });
      })
      .catch((error) => {
        EnhancedNotification.error({ message: 'Project activation failed' });
        dispatch(activateProjectFailure(error));
      });
  };
};
