import { EnhancedRow, EnhancedCol, EnhancedButton, EnhancedCard } from 'components/shared/antd';
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import { getProjectsAction } from 'store/actions/ProjectActions';
import { getQuestionList, getReporteesTracks } from '../../sources/interviewSources';
import { XLS_FILENAME, XLS_META } from './constants';
import generateColumns from './columns';
import DeleteQuestion from './DeleteQuestion';
import QuestionDetails from './QuestionDetails';

const InterviewQuestions = (props) => {
  const { questionList, projectList, trackList, loading, getQuestions, getProjects, getTracks } =
    props;
  const [data, setData] = useState([]);
  const [modal, setModal] = useState({});

  useEffect(() => {
    getQuestions();
    getProjects();
    getTracks();
  }, []);

  useEffect(() => {
    setData(questionList);
  }, [questionList]);

  useEffect(() => {
    const newData = [...questionList];
    setData(newData);
  }, [questionList]);

  const handleCreate = () => setModal({ type: 'create' });

  const handleCloseModal = () => setModal({});

  const handleDelete = (event) => {
    const id = Number(event.currentTarget.dataset.id);
    setModal({
      type: 'delete',
      recordId: id,
    });
  };

  const handleEdit = (event) => {
    const id = Number(event.currentTarget.dataset.id);
    setModal({
      type: 'edit',
      data: questionList.find((item) => item.id === id),
      recordId: id,
    });
  };

  const columns = generateColumns({ projectList, trackList }, { handleEdit, handleDelete });

  return (
    <div>
      <EnhancedCard>
        <EnhancedRow>
          <EnhancedCol sm={12}>
            <h2 className="heading-emp">Interview Questions</h2>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow type="flex" justify="end" gutter={[16, 16]}>
          <EnhancedCol>
            <EnhancedButton type="primary" onClick={handleCreate}>
              Add New Question
            </EnhancedButton>
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedCard>
      <EnhancedCard>
        <EnhancedClientSideTable
          rowKey={(record) => record.id}
          columns={columns}
          data={data}
          loading={loading}
          scroll={{ x: 1300 }}
          exportFileConfig={{
            fileName: XLS_FILENAME,
            fileHeaders: XLS_META,
            showExportButton: true,
          }}
        />
      </EnhancedCard>
      <DeleteQuestion
        visible={modal.type === 'delete'}
        recordId={modal.recordId}
        onClose={handleCloseModal}
      />
      <QuestionDetails
        visible={['create', 'edit'].includes(modal.type)}
        previousData={modal.data}
        onClose={handleCloseModal}
      />
    </div>
  );
};

const mapState = (state) => ({
  loading: state.interviewReducer.loadingQuestions,
  questionList: state.interviewReducer.questionList,
  projectList: state.projectReducer.projects.filter((v) => v.status),
  trackList: state.interviewReducer.trackList,
});

const mapDispatch = (dispatch) => ({
  getQuestions: () => dispatch(getQuestionList()),
  getProjects: () => dispatch(getProjectsAction()),
  getTracks: () => dispatch(getReporteesTracks()),
});

export default connect(mapState, mapDispatch)(InterviewQuestions);
