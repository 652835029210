import {
  EnhancedCol,
  EnhancedRow,
  EnhancedDivider,
  EnhancedModal,
  EnhancedRate,
} from 'components/shared/antd';
import React from 'react';
import './pc.css';
import NoFeedback from './shared/NoFeedback';
import { starRatingDescription } from '../../configs/constants';
import AttributeTitle from './shared/AttributeTitle';

class RatingModalLM extends React.Component {
  componentWillUnmount() {
    const { resetRatingDetails } = this.props;
    resetRatingDetails();
  }

  getAttributeRatingField = (ratingValue) => (
    <React.Fragment>
      <EnhancedRate tooltips={starRatingDescription} value={ratingValue} disabled />
      {ratingValue ? (
        <div className="ant-rate-text">{starRatingDescription[ratingValue - 1]}</div>
      ) : (
        ''
      )}
    </React.Fragment>
  );

  getAttributeCommentField = (commentValue) => (
    <p className="vd-ratings-comment">
      <b>Comment:</b> {commentValue}
    </p>
  );

  getRatingTitle = (ratingObj) => {
    const { raterName, raterRole } = ratingObj;
    return <strong>{`${raterRole} - ${raterName}`}</strong>;
  };

  handleModalCancel = () => {
    this.props.onModalClose(false);
  };

  render() {
    const {
      resourceName,
      ratingDetails: { data },
    } = this.props;

    return (
      <EnhancedModal
        title={resourceName}
        onCancel={this.handleModalCancel}
        okText="Save"
        visible
        afterClose={this.handleOnClose}
        footer={null}
        width="1000px"
        height="400px"
        style={{
          backgroundColor: 'white',
          overflowY: 'scroll',
          maxHeight: '80%',
          padding: '0',
        }}
        className="modal-custom"
        bodyStyle={{ padding: '24px' }}
      >
        <EnhancedRow gutter={24}>
          {data.ratings &&
            data.ratings.map((item) => (
              <EnhancedCol span={12} key={item.ratingId}>
                {this.getRatingTitle(item)}

                {item.freeze ? (
                  item.ratingAttributes.map((ratingAttribute) => (
                    <div key={ratingAttribute.ratingAttributeId}>
                      <EnhancedDivider className="vd-divider" />

                      <AttributeTitle
                        title={ratingAttribute.attributeName}
                        description={ratingAttribute.attributeDescription}
                      />

                      {this.getAttributeRatingField(ratingAttribute.rating)}

                      {this.getAttributeCommentField(ratingAttribute.comment)}
                    </div>
                  ))
                ) : (
                  <div>
                    <EnhancedDivider className="vd-divider" />

                    <NoFeedback />
                  </div>
                )}
              </EnhancedCol>
            ))}
        </EnhancedRow>
      </EnhancedModal>
    );
  }
}
export default RatingModalLM;
