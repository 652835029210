import {
  EnhancedCol,
  EnhancedButton,
  EnhancedRow,
  EnhancedDrawer,
  EnhancedSelect,
  EnhancedInput,
  EnhancedRadio,
  EnhancedForm,
  EnhancedDatePicker,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import RequiredField from 'components/RequiredField';
import moment from 'moment';

const { TextArea } = EnhancedInput;

const resourceTypes = {
  EXISTING: 'existing',
  NEW: 'new',
};

const SuggestResourceForm = (props) => {
  const {
    onClose,
    isDrawerVisible,
    dealRequirementObj,
    suggestResource,
    getDealRequirementMatchingResources,
    dealRequirementMatchingResources,
    dealRequirementParams,
    dealDetail,
  } = props;

  const [formData, setFormData] = useState({
    resourceType: resourceTypes.EXISTING,
    resourceId: '',
    suggestedDate: null,
    comments: '',
  });

  useEffect(() => {
    if (isDrawerVisible) {
      getDealRequirementMatchingResources(dealRequirementObj.id);
    }
  }, [isDrawerVisible]);

  const addSuggestResource = () => {
    suggestResource({ ...formData }, dealRequirementObj.id, dealDetail.id, dealRequirementParams);
    setFormData({
      resourceType: resourceTypes.EXISTING,
      resourceId: '',
      suggestedDate: null,
      comments: '',
    });
  };

  const closeDrawerHandler = () => {
    onClose();
    setFormData({
      resourceType: resourceTypes.EXISTING,
      resourceId: '',
      comments: '',
      suggestedDate: null,
    });
  };

  const handleResourceTypeChange = (e) => {
    setFormData({
      ...formData,
      resourceType: e.target.value,
      resourceId: '',
    });
  };

  const handleResourceChange = (value) => {
    setFormData({
      ...formData,
      resourceId: value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      suggestedDate: date ? moment(date).startOf('day') : moment().startOf('day'),
    });
  };

  const handleCommentChange = (e) => {
    setFormData({
      ...formData,
      comments: e.target.value,
    });
  };

  return (
    <EnhancedDrawer
      visible={isDrawerVisible}
      title="Add Suggest Resource"
      width={720}
      onClose={closeDrawerHandler}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <EnhancedForm layout="vertical" hideRequiredMark>
        <EnhancedRow gutter={16}>
          <EnhancedCol span={24}>
            <EnhancedForm.Item>
              <EnhancedCol span={24}>
                <RequiredField>
                  <span className="color-black">Resource Type</span>
                </RequiredField>
              </EnhancedCol>
              <EnhancedRadio.Group
                name="resourceType"
                onChange={handleResourceTypeChange}
                className="mt-8"
                value={formData.resourceType}
              >
                <EnhancedRadio value={resourceTypes.EXISTING} checked>
                  Existing
                </EnhancedRadio>
                <EnhancedRadio value={resourceTypes.NEW}>New</EnhancedRadio>
              </EnhancedRadio.Group>
            </EnhancedForm.Item>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow gutter={16}>
          {formData.resourceType === resourceTypes.EXISTING && (
            <EnhancedCol span={12}>
              <EnhancedForm.Item>
                <RequiredField>
                  <span className="color-black">Existing Employee</span>
                </RequiredField>
                <EnhancedSelect
                  className="mt-8"
                  placeholder="Please select the existing employee"
                  name="resourceId"
                  onSelect={handleResourceChange}
                  value={formData.resourceId}
                  showSearch
                  optionFilterProp="children"
                >
                  {dealRequirementMatchingResources.employees &&
                    dealRequirementMatchingResources.employees.map((item) => (
                      <EnhancedSelect.Option value={item.id} key={item.id}>
                        {item.name}
                      </EnhancedSelect.Option>
                    ))}
                </EnhancedSelect>
              </EnhancedForm.Item>
            </EnhancedCol>
          )}
          {formData.resourceType === resourceTypes.NEW && (
            <EnhancedCol span={12}>
              <EnhancedForm.Item>
                <RequiredField>
                  <span className="color-black">Transient Resource</span>
                </RequiredField>
                <EnhancedSelect
                  className="mt-8"
                  placeholder="Please choose the new employee"
                  name="resourceId"
                  onSelect={handleResourceChange}
                  value={formData.resourceId}
                  showSearch
                  optionFilterProp="children"
                >
                  {dealRequirementMatchingResources.transientResources &&
                    dealRequirementMatchingResources.transientResources.map((item) => (
                      <EnhancedSelect.Option value={item.id} key={item.id}>
                        {item.name}
                      </EnhancedSelect.Option>
                    ))}
                </EnhancedSelect>
              </EnhancedForm.Item>
            </EnhancedCol>
          )}
          <EnhancedCol span={12}>
            <EnhancedForm.Item>
              <RequiredField>
                <span className="color-black">Suggested Date</span>
              </RequiredField>
              <EnhancedDatePicker
                className="mt-8"
                placeholder="Select suggested date"
                onChange={handleDateChange}
                value={formData.suggestedDate}
                style={{ width: '100%' }}
              />
            </EnhancedForm.Item>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow gutter={16}>
          <EnhancedCol span={24}>
            <EnhancedForm.Item>
              <RequiredField>
                <span className="color-black">Comments</span>
              </RequiredField>
              <TextArea
                name="comments"
                onChange={handleCommentChange}
                placeholder="Please enter your comments"
                className="mt-8"
                rows={5}
                value={formData.comments}
              />
            </EnhancedForm.Item>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow type="flex" justify="end" align="middle">
          <EnhancedCol>
            <EnhancedButton
              style={{ width: 120 }}
              key="submit"
              type="primary"
              onClick={addSuggestResource}
              disabled={
                !formData.resourceType ||
                !formData.resourceId ||
                !formData.suggestedDate ||
                !formData.comments
              }
            >
              Submit
            </EnhancedButton>
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedForm>
    </EnhancedDrawer>
  );
};

export default SuggestResourceForm;
