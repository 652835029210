import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedIcon,
  EnhancedModal,
  EnhancedSelect,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import PROJECT_ROUTES from 'constants/UrlConstants';
import { hasPermission } from 'utils/AccessUtils';
import { ATTRIBUTES } from 'configs/leaveConstants';
import AccessPermissions from 'constants/AccessPermissions';
import evaluationStatus from 'configs/performanceManagementConstants';
import Authorize from 'components/Authorize';
import { BREADCRUMBS, goalStatusToClassMapping } from 'configs/constants';
import ConfirmModal from '../../shared/performanceEvaluationForm/ConfirmModal';
import AddButton from '../../shared/employeeProfile/AddButton';
import statusConfirmContentMap from '../../shared/performanceEvaluationForm/statusConfirmContentMap';
import ManagerRatingStatusLabel from '../../shared/performanceEvaluationForm/ManagerRatingStatusLabel';
import { roundOffRating } from '../../../utils/conversionUtils';
import EnhancedServerSideTable from '../../../components/shared/EnhancedServerSideTable';

const { confirm } = EnhancedModal;
const { Option } = EnhancedSelect;

const PMCycleEmp = (props) => {
  const {
    list,
    history,
    employees,
    isLoading,
    addEmployees,
    pmCycleDetail,
    getPMEmpCycle,
    deleteEmployee,
    getAllEmployees,
    updateBreadCrumb,
    getPMCycleDetail,
    exportAllEmpPerformanceCycle,
    sendInitiationEmail,
    reopenPEF,
    approvePEFStatus,
    markPEFAsFinal,
    getEmployeeDivisions,
    getEmployeeDepartments,
    getEmployeeCompetencies,
    getEmployeeBusinessUnits,
    userPermissions,
  } = props;

  const { id } = props.match.params;
  const [params, setParams] = useState({
    id,
    pageNumber: 0,
    pageSize: 10,
  });
  const [currentEmployees, setCurrentEmployees] = useState([]);
  const [selectedEmployeeRows, setSelectedEmployeeRows] = useState([]);
  const [selectedEmployeeRowKeys, setSelectedEmployeeRowKeys] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmModalStatus, setConfirmModalStatus] = useState(null);
  const [approvalEmployee, setApprovalEmployee] = useState(null);

  useEffect(() => {
    getAllEmployees();
    getPMEmpCycle(params);
    getPMCycleDetail(id);

    getEmployeeDivisions();
    getEmployeeDepartments();
    getEmployeeCompetencies();
    getEmployeeBusinessUnits();
  }, []);

  const getCurrentEmployees = () => {
    const currentEmployeesData = employees.filter((emp) => emp.isActive === true);
    setCurrentEmployees(currentEmployeesData);
  };

  useEffect(() => {
    getCurrentEmployees();
  }, [employees]);

  const getSelectOptions = () => {
    const optionsArr = [];
    currentEmployees.forEach((employee) => {
      optionsArr.push(
        <Option key={employee.id}>{`${employee.name} - ${employee.taleoId}`}</Option>,
      );
    });
    setOptions(optionsArr);
  };

  useEffect(() => {
    getSelectOptions();
  }, [currentEmployees]);

  useEffect(() => {
    getPMEmpCycle(params);
  }, [params]);

  useEffect(() => {
    if (pmCycleDetail) {
      updateBreadCrumb(BREADCRUMBS.PERFORMANCE_MANAGEMENT, id, pmCycleDetail.title);
    }
  }, [pmCycleDetail]);

  const handleDeleteEmployee = (record) => {
    /* VENDIANS-3483: If last page has only one employee and it is deleted
    then FE wont get next records as it is sending the last page number
    */
    // if we are on page other than 1st one and we only have one employee on that page
    if (list.rows.length === 1 && params.pageNumber > 0) {
      params.pageNumber -= 1;
    }
    const payload = {
      id,
      employeeId: record,
      parameters: params,
    };
    confirm({
      title: 'Are you sure you want to remove this employee from Performance Cycle?',
      content: '',
      okText: 'Yes',
      okType: 'success',
      cancelText: 'No',
      onOk() {
        deleteEmployee(payload);
      },
      onCancel() {},
    });
  };

  const handleReopenReview = (employeeId) => {
    const payload = {
      employeeId,
      performanceCycleId: id,
      params,
    };
    confirm({
      title: 'Are you sure you want to reopen evaluation form for this employee?',
      content: '',
      okText: 'Yes',
      okType: 'success',
      cancelText: 'No',
      onOk() {
        reopenPEF(payload);
      },
      onCancel() {},
    });
  };

  const handleMarkAsFinal = (employeeId) => {
    const payload = {
      employeeId,
      performanceCycleId: id,
      params,
    };
    confirm({
      title: 'Are you sure you want to finalize evaluation form for this employee?',
      content: '',
      okText: 'Yes',
      okType: 'success',
      cancelText: 'No',
      onOk() {
        markPEFAsFinal(payload);
      },
      onCancel() {},
    });
  };

  const viewProfileHandler = (key) => {
    const urlProfile = `${PROJECT_ROUTES.CRMC_EMPLOYEES_ROUTE}`;
    history.push(`${urlProfile}/${key}`);
  };

  const changeActionHandler = (key, empId, record) => {
    if (key === 'deleteEmployee') {
      handleDeleteEmployee(empId);
    } else if (key === 'employeeProfile') {
      viewProfileHandler(empId);
    } else if (key === 'reopenReview') {
      handleReopenReview(empId);
    } else if (key === 'approve') {
      setConfirmModalStatus(evaluationStatus.APPROVED);
      setApprovalEmployee(record);
    } else if (key === 'reject') {
      setConfirmModalStatus(evaluationStatus.REJECTED);
      setApprovalEmployee(record);
    } else if (key === 'markAsFinal') {
      handleMarkAsFinal(empId);
    } else {
      history.push(`/performance-management/${id}/${empId}?type=${key}`);
    }
  };

  const canAddNewEmployee = () => !pmCycleDetail.freeze;

  const canMarkAsFinal = (record) =>
    pmCycleDetail.freeze && record.manager.status.replace(/\s/g, '') !== evaluationStatus.FINAL;

  const canApprove = (record) =>
    record.manager.status.replace(/\s/g, '') === evaluationStatus.PENDING_APPROVAL;

  // const canReopenEvaluation = () => !pmCycleDetail.freeze;

  const optionMenu = [
    {
      value: 'reopenReview',
      label: 'Reopen evaluation',
      isAllowed: hasPermission(userPermissions, [
        AccessPermissions.PerformanceManagement.PerformanceCycle.Evaluation.Reopen,
      ]),
      // canCallback: canReopenEvaluation,
    },
    {
      value: 'employeeProfile',
      label: 'View Profile',
      isAllowed: true,
    },
    {
      value: 'deleteEmployee',
      label: 'Delete Employee',
      canCallback: canAddNewEmployee,
      isAllowed: hasPermission(userPermissions, [
        AccessPermissions.PerformanceManagement.PerformanceCycle.Employee.Remove,
      ]),
    },
    {
      value: 'self',
      label: 'Self View',
      isAllowed: hasPermission(userPermissions, [
        AccessPermissions.PerformanceManagement.PerformanceCycle.Evaluation.View,
      ]),
    },
    {
      value: 'manager',
      label: 'Manager View',
      isAllowed: hasPermission(userPermissions, [
        AccessPermissions.PerformanceManagement.PerformanceCycle.Evaluation.View,
      ]),
    },
    {
      value: 'reject',
      label: `Reject`,
      canCallback: canApprove,
      isAllowed: hasPermission(userPermissions, [
        AccessPermissions.PerformanceManagement.PerformanceCycle.Evaluation.Approve,
      ]),
    },
    {
      value: 'approve',
      label: `Approve`,
      canCallback: canApprove,
      isAllowed: hasPermission(userPermissions, [
        AccessPermissions.PerformanceManagement.PerformanceCycle.Evaluation.Approve,
      ]),
    },
    {
      value: 'markAsFinal',
      label: 'Mark as final',
      canCallback: canMarkAsFinal,
      isAllowed: hasPermission(userPermissions, [
        AccessPermissions.PerformanceManagement.PerformanceCycle.Evaluation.markAsFinal,
      ]),
    },
  ];

  const setActionsHandler = (eId, record) => {
    const actions = (
      <EnhancedSelect
        style={{
          width: 160,
        }}
        className="action-selection-dropdown"
        placeholder="Options"
        value="Options"
        onChange={(key) => changeActionHandler(key, eId, record)}
      >
        {optionMenu.map(
          (item) =>
            (!item.canCallback || item.canCallback(record)) &&
            item.isAllowed && <Option value={item.value}>{item.label}</Option>,
        )}
      </EnhancedSelect>
    );
    return actions;
  };

  const getColumnsInfo = () => {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'taleoId',
        key: 'oracleId',
        filterConfig: {
          type: 'search',
          key: 'oracleId',
        },
      },
      {
        title: 'Employee',
        dataIndex: 'name',
        key: 'name',
        filterConfig: {
          type: 'search',
          key: 'name',
        },
        width: 150,
      },
      {
        title: 'Review Manager',
        dataIndex: 'managerName',
        filterConfig: {
          type: 'search',
          key: 'managerName',
        },
        width: 150,
      },
      {
        title: 'Manager Rating',
        dataIndex: ['manager', 'rating'],
        render: (rating) => roundOffRating(rating),
      },
      {
        title: 'Manager Rating Status',
        dataIndex: 'manager',
        render: ({ status, comment }) => (
          <ManagerRatingStatusLabel status={status} comment={comment} />
        ),
      },
      {
        title: 'Self Rating',
        dataIndex: ['self', 'rating'],
        render: (rating) => roundOffRating(rating),
      },
      {
        title: 'Self Rating Status',
        dataIndex: ['self', 'status'],
        render: (status) => (
          <span className={`status-box ${goalStatusToClassMapping[status]}`}>{status}</span>
        ),
      },
      {
        title: 'Approver Name (LM+1)',
        dataIndex: ['lineManagerApprover', 'name'],
        key: 'approverName',
        filterConfig: {
          type: 'search',
          key: 'approverName',
        },
        width: 150,
      },
      {
        title: 'Department',
        dataIndex: 'department',
        width: 180,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'departmentIds',
          optionsConfig: {
            values: props.departments,
            valueVar: 'id',
            labelVar: 'name',
          },
        },
      },
      {
        title: 'Competency',
        dataIndex: 'competency',
        width: 180,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'competencyIds',
          optionsConfig: {
            values: props.competencies,
            valueVar: 'id',
            labelVar: 'name',
          },
        },
      },
      {
        title: 'Business Unit',
        dataIndex: 'businessUnit',
        filterConfig: {
          type: 'enhanced_filter',
          key: 'businessUnitIds',
          optionsConfig: {
            values: props.businessUnits,
            valueVar: 'id',
            labelVar: 'name',
          },
        },
      },
      {
        title: 'Division',
        dataIndex: 'division',
        filterConfig: {
          type: 'enhanced_filter',
          key: 'divisionIds',
          optionsConfig: {
            values: props.divisions,
            valueVar: 'id',
            labelVar: 'name',
          },
        },
        width: 180,
      },
      {
        title: 'Actions',
        dataIndex: 'employeeId',
        render: (eId, _record) => setActionsHandler(eId, _record),
      },
    ];

    return columns;
  };

  const onChangeSelection = (selectedRowKeys, selectedRows) => {
    let newSelectedRows = [...selectedEmployeeRows];
    newSelectedRows = newSelectedRows.filter((row) =>
      selectedRowKeys.some((key) => key === row.manager.pcRatingId),
    );
    selectedRows.forEach((row) => {
      const alreadyExists = newSelectedRows.some(
        (previousRecord) => previousRecord.manager.pcRatingId === row.manager.pcRatingId,
      );
      if (!alreadyExists) {
        newSelectedRows.push(row);
      }
    });

    setSelectedEmployeeRowKeys(selectedRowKeys);
    setSelectedEmployeeRows(newSelectedRows);
  };

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      pageNumber: pagination.current - 1,
      pageSize: pagination.pageSize,
    });
  };

  const generateEmployeesReport = () => {
    const updatedParams = {
      page: 0,
      size: 1000000,
      id: params.id,
      oracleId: params.oracleId,
      employeeName: params.name,
      approverName: params.approverName,
      managerName: params.managerName,
      departmentIds: params.departmentIds,
      competencyIds: params.competencyIds,
      businessUnitIds: params.businessUnitIds,
      divisionIds: params.divisionIds,
      pmCycleTitle: pmCycleDetail.title,
    };
    exportAllEmpPerformanceCycle(updatedParams);
  };

  const emailHandler = () => {
    confirm({
      title: 'Are you sure you want to send the initiation email?',
      content: '',
      okText: 'Yes',
      okType: 'success',
      cancelText: 'No',
      onOk() {
        const request = {
          performanceCycleId: params.id,
        };
        sendInitiationEmail(request);
      },
      onCancel() {},
    });
  };

  const handleAddNewEmployee = () => {
    setIsModalOpen(true);
  };

  const handleHideModal = () => {
    setSelectedEmployees([]);
    setIsModalOpen(false);
  };

  const modalTitle = statusConfirmContentMap[confirmModalStatus];

  const handleConfirmModalOk = ({ comment }) => {
    const employeeIds = [];
    const ratingIds = [];

    if (approvalEmployee) {
      employeeIds.push(approvalEmployee.employeeId);
      ratingIds.push(approvalEmployee.manager.pcRatingId);
    } else {
      selectedEmployeeRows.forEach((emp) => {
        employeeIds.push(emp.employeeId);
        ratingIds.push(emp.manager.pcRatingId);
      });
    }

    const request = {
      payload: {
        employeeIds,
        ratingIds,
        status: confirmModalStatus,
        as: ATTRIBUTES.HR,
        comment,
      },
      refetchWithData: params,
    };

    approvePEFStatus(request);
    setConfirmModalStatus(null);

    if (approvalEmployee) {
      setSelectedEmployeeRowKeys((rowKeys) =>
        rowKeys.filter((key) => key !== approvalEmployee.manager.pcRatingId),
      );
      setSelectedEmployeeRows((empRow) =>
        empRow.filter((row) => row.manager.pcRatingId !== approvalEmployee.manager.pcRatingId),
      );
      setApprovalEmployee(null);
    } else {
      setSelectedEmployeeRowKeys([]);
      setSelectedEmployeeRows([]);
    }
  };

  const handleConfirmModalCancel = () => {
    if (approvalEmployee) setApprovalEmployee(null);
    setConfirmModalStatus(null);
  };

  const handleSubmit = () => {
    const payload = {
      id,
      employeesIds: selectedEmployees,
      parameters: params,
    };

    addEmployees(payload, handleHideModal);
  };

  const handleChange = (value) => {
    setSelectedEmployees(value);
  };

  return (
    <div>
      <EnhancedCard>
        <React.Fragment>
          <div className="add-btn-wrapper">
            {!pmCycleDetail.freeze && (
              <EnhancedButton
                className="btn-edit"
                style={{
                  marginRight: 10,
                }}
                onClick={emailHandler}
              >
                <EnhancedIcon type="check-circle" />
                <span>Send Initiation Email</span>
              </EnhancedButton>
            )}
            {selectedEmployeeRowKeys.length > 0 && (
              <React.Fragment>
                <EnhancedButton
                  className="btn-edit"
                  style={{ marginRight: 10 }}
                  onClick={() => setConfirmModalStatus(evaluationStatus.APPROVED)}
                >
                  Approve ({selectedEmployeeRowKeys.length})
                </EnhancedButton>
                <EnhancedButton
                  className="btn-edit"
                  style={{ marginRight: 10 }}
                  onClick={() => setConfirmModalStatus(evaluationStatus.REJECTED)}
                >
                  Reject ({selectedEmployeeRowKeys.length})
                </EnhancedButton>
              </React.Fragment>
            )}
            {!pmCycleDetail.freeze && (
              <Authorize
                requiredPermissions={[
                  AccessPermissions.PerformanceManagement.PerformanceCycle.Employee.Assign,
                ]}
              >
                <AddButton buttonText="Add New Employee" clickHandler={handleAddNewEmployee} />
              </Authorize>
            )}
          </div>
          <br />
          <ConfirmModal
            status={confirmModalStatus}
            title={modalTitle}
            onOk={handleConfirmModalOk}
            onCancel={handleConfirmModalCancel}
          />

          <EnhancedServerSideTable
            rowKey={(record) => record.manager.pcRatingId}
            loading={isLoading}
            columns={getColumnsInfo()}
            data={list.rows}
            onChange={handleTableChange}
            className="goal-table-container"
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selectedEmployeeRowKeys,
              onChange: onChangeSelection,
              getCheckboxProps: (record) => ({
                disabled: !canApprove(record),
              }),
            }}
            paginationInfo={{
              totalItems: list.count,
              pageSize: params.pageSize,
              pageNumber: params.pageNumber + 1,
            }}
            scroll={{ x: 'max-content' }}
            updateParams={(updatedParams) => {
              setParams({
                ...params,
                ...updatedParams,
                pageNumber: 0, // move to page no 0 in case filters are applied
              });
            }}
            exportFileConfig={{
              showExportButton: true,
              handler: generateEmployeesReport,
            }}
          />
          <EnhancedModal
            title="Add New Employees"
            visible={isModalOpen}
            onCancel={handleHideModal}
            width={850}
            bodyStyle={{ height: 400 }}
            footer={[
              <EnhancedButton key="back" onClick={handleHideModal}>
                Cancel
              </EnhancedButton>,
              <EnhancedButton
                disabled={selectedEmployees.length === 0}
                key="add"
                type="primary"
                onClick={handleSubmit}
              >
                Add
              </EnhancedButton>,
            ]}
          >
            <div>
              <EnhancedRow>
                <EnhancedCol span={8}>Add Employees:</EnhancedCol>
                <EnhancedCol span={12}>
                  <EnhancedSelect
                    mode="multiple"
                    showSearch
                    optionFilterProp="children"
                    style={{ width: '100%' }}
                    placeholder="Select Employees"
                    value={selectedEmployees}
                    // defaultValue={[]}
                    onChange={handleChange}
                  >
                    {options}
                  </EnhancedSelect>
                </EnhancedCol>
              </EnhancedRow>
            </div>
          </EnhancedModal>
        </React.Fragment>
      </EnhancedCard>
    </div>
  );
};
export default PMCycleEmp;
