import React from 'react';
import { List } from 'antd';
import PropTypes from 'prop-types';

const EnhancedList = (props) => {
  const {
    style,
    children,
    grid,
    dataSource,
    renderItem,
    className,
    itemLayout,
    locale,
    size,
    header,
    split,
  } = props;

  return (
    <List
      style={style}
      grid={grid}
      dataSource={dataSource}
      renderItem={renderItem}
      className={className}
      itemLayout={itemLayout}
      locale={locale}
      size={size}
      header={header}
      split={split}
    >
      {children}
    </List>
  );
};

EnhancedList.propTypes = {
  style: PropTypes.shape({}),
  // grid: PropTypes.Object,
  renderItem: PropTypes.func.isRequired,
  className: PropTypes.string,
  itemLayout: PropTypes.string,
  locale: PropTypes.Object,
  size: PropTypes.string.isRequired,
  header: PropTypes.node.isRequired,
  split: PropTypes.bool,
};

EnhancedList.defaultProps = {
  style: {},
  split: true,
  itemLayout: 'horizontal',
  className: '',
  // grid: {},
  locale: {},
};

export default EnhancedList;
