import { EnhancedCard, EnhancedTabs } from 'components/shared/antd';
import React from 'react';
import {
  INFO as stateName,
  EMPLOYEE_INFO as propsName,
  DROP_DOWNS,
  FAMILY_INFO,
  EMERGENCY_CONTACT,
  EDUCATION_HISTORY,
  EMPLOYEE_CERTIFICATES,
} from 'configs/employeeProfileConstants';
import FamilyInfo from 'components/MyProfile/employeeInfo/familyInfo';
import EmergencyContact from 'components/MyProfile/employeeInfo/emergencyContact';
import EducationInfo from 'components/MyProfile/employeeInfo/educationInfo';
import CertificatesLicenses from 'components/MyProfile/talentManagment/certificatesLicenses';
import PersonalInfo from './personalInfo';
import BasicInfo from './basicInfo';

const { TabPane } = EnhancedTabs;

class EmployeeInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      [stateName]: {},
      // [`${stateName}Errors`]: {}, // TODO: This many not be needed
      [DROP_DOWNS]: {},
    };
  }

  componentDidMount() {
    // const employeeId = this.props.match.params.id;
    const employeeId = 'me';
    this.props.getEmployeeInfo(employeeId);
    this.props.getDropdownOptions();
    this.props.getEmployeeBusinessUnits();
    // this.props.getEmployeeDivisionsAction();
    this.props.getEmployeeCompetenciesAction();
    this.props.getEmployeeDepartments();
    this.props.getMiscellaneousData();
    this.props.getEmployeeEducationHistory(employeeId);
    this.props.getEmployeeCertificates(employeeId);
    this.props.getAllLocations();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps[propsName][stateName] !== this.props[stateName]) {
      this.initializeValues(nextProps[propsName]);
      // TODO: This may not be needed -> Generates error state for each value in state
      // this.initializeErrors(nextProps[propsName]);
    }
  }
  getValueForSelect = (items, type) => {
    if (this.props[items] && this.props[items].length > 0) {
      return this.props[items].map((item) => ({
        value: type ? `${item.firstName} ${item.lastName}` : item.name,
        key: item.id,
      }));
    }
    return [];
  };

  initializeValues = (props) => this.setState({ ...props });
  initializeDropDowns = (props) => this.setState({ dropDowns: { ...props } });

  // TODO: This may not be needed -> Generates error state for each value in state
  // initializeErrors = (props) => {
  //   const errorObject = generateErrorObject(props[stateName]);
  //   this.setState({ [`${stateName}Errors`]: errorObject });
  // };

  render() {
    const { isLoading, designations } = this.props;
    const { userPermissions, cardLoading } = this.props;
    // const errors = this.state[`${stateName}Errors`]; // TODO: This may not be needed
    return (
      <EnhancedCard className="mt-20" loading={isLoading} bordered={false}>
        <EnhancedTabs type="card" centered defaultActiveKey="1">
          <TabPane tab="Basic Info" key="1">
            <BasicInfo
              employeeId="me"
              userPermissions={userPermissions}
              cardLoading={cardLoading}
              employees={this.getValueForSelect('employees', 'employeesList')}
              businessUnits={this.getValueForSelect('businessUnits')}
              divisions={this.getValueForSelect('empDivisions')}
              competencies={this.getValueForSelect('competencies')}
              departments={this.getValueForSelect('departments')}
              designations={designations}
              locations={this.getValueForSelect('locations')}
              employmentTypes={this.props.employmentTypes}
              info={this.state[stateName]}
              dropDowns={this.props[DROP_DOWNS]}
              updateEmployeeInfo={this.props.updateEmployeeInfo}
            />
          </TabPane>
          <TabPane tab="Personal Info" key="2">
            <PersonalInfo
              employeeId="me"
              userPermissions={userPermissions}
              cardLoading={cardLoading}
              info={this.state[stateName]}
              dropDowns={this.props[DROP_DOWNS]}
              updateEmployeeInfo={this.props.updateEmployeeInfo}
            />
            <EmergencyContact
              cardLoading={cardLoading}
              userPermissions={userPermissions}
              employeeId="me"
              emergencyContacts={this.props[propsName][EMERGENCY_CONTACT]}
              createEmployeeEmergencyContact={this.props.createEmployeeEmergencyContact}
              updateEmployeeEmergencyContact={this.props.updateEmployeeEmergencyContact}
              deleteEmployeeEmergencyContact={this.props.deleteEmployeeEmergencyContact}
            />
          </TabPane>
          <TabPane tab="Family Info" key="3">
            <FamilyInfo
              userPermissions={userPermissions}
              familyInfo={this.props[propsName][FAMILY_INFO]}
              updateEmployeeFamilyInfo={this.props.updateEmployeeFamilyInfo}
              createEmployeeFamilyInfo={this.props.createEmployeeFamilyInfo}
              deleteEmployeeFamilyInfo={this.props.deleteEmployeeFamilyInfo}
              employeeId="me"
              cardLoading={cardLoading}
            />
          </TabPane>
          <TabPane tab="Education History" key="4">
            <EducationInfo
              userPermissions={userPermissions}
              educationHistory={this.props[EDUCATION_HISTORY]}
              dropDowns={this.props[DROP_DOWNS]}
              updateEmployeeEducationHistory={this.props.updateEmployeeEducationHistory}
              createEmployeeEducationHistory={this.props.createEmployeeEducationHistory}
              deleteEmployeeEducationHistory={this.props.deleteEmployeeEducationHistory}
              employeeId="me"
              isLoading={this.props.isLoading}
            />
          </TabPane>
          <TabPane tab="Certificates & Trainings" key="5">
            <CertificatesLicenses
              userPermissions={userPermissions}
              employeeId="me"
              isLoading={this.props.isLoading}
              certificates={this.props[EMPLOYEE_CERTIFICATES]}
              competencies={this.getValueForSelect('competencies')}
              createEmployeeCertificates={this.props.createEmployeeCertificates}
              updateEmployeeCertificates={this.props.updateEmployeeCertificates}
              deleteEmployeeCertificates={this.props.deleteEmployeeCertificates}
            />
          </TabPane>
        </EnhancedTabs>
      </EnhancedCard>
    );
  }
}

export default EmployeeInfo;
