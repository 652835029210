/* eslint-disable */
import {
  EnhancedButton,
  EnhancedRow,
  EnhancedCol,
  EnhancedLayout,
  EnhancedModal,
  EnhancedDatePicker,
  EnhancedInputNumber,
  EnhancedCheckbox,
  EnhancedForm,
} from 'components/shared/antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { default as ReactSelect, components } from 'react-select';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';

import RequiredField from '../RequiredField';

const { confirm } = EnhancedModal;
const { Header } = EnhancedLayout;
const { MonthPicker } = EnhancedDatePicker;

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} readOnly /> <label>{props.value}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => {
  return (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
};

class PerformanceCycleCRMC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mo: '',
      criteria: 1,
      projects: [],
      showAddNewForm: false,
      modalOpen: false,
      /*     searchProjectText: '',
      searchManagerText: '',
      errorText: '', */
      readOnly: this.props.readOnly,
      dueDate: null,
    };
  }

  componentWillMount() {
    this.props.getPerformanceCRMCCyclesAction(this.props.userId);
    this.props.getProjectsAction();
  }

  completeCycleConfirmation = (data) => {
    const employeeId = this.props.userId;
    const performanceCycleId = data.id;
    confirm({
      title: `Are you sure you want to mark cycle complete?`,
      content: 'Once Mark as complete it cannot be undone',
      onOk: () => {
        this.props.markPerformanceCycleCompleteAction({ performanceCycleId, employeeId });
      },
      onCancel() {},
    });
  };

  renderStatusName = (data) => {
    if (data === 'Actived') {
      return <p> Active</p>;
    } else {
      return <p> Complete</p>;
    }
  };

  renderCompleteCycle = (data) => {
    let completeButton = (
      <EnhancedCheckbox
        checked={true}
        onChange={() => this.completeCycleConfirmation(data)}
        disabled={true}
      ></EnhancedCheckbox>
    );
    if (data.status === 'Actived') {
      completeButton = (
        <EnhancedCheckbox
          checked={false}
          onChange={() => this.completeCycleConfirmation(data)}
        ></EnhancedCheckbox>
      );
    }
    return completeButton;
  };

  getColumnsInfo = () => {
    const columns = [
      {
        title: 'Cycle',
        dataIndex: 'name',
        key: 'name',
        width: '12%',
        render: this.renderCycleName,
        filterConfig: {
          type: 'search',
          key: 'name',
        },
      },
      {
        title: 'No. of Projects',
        dataIndex: 'projectCount',
        key: 'projectCount',
        width: '10%',
      },
      {
        title: 'Cycle Status',
        dataIndex: 'status',
        key: 'status',
        width: '10%',
        render: this.renderStatusName,
      },
    ];
    return columns;
  };

  showTotal = (total) => `Total ${total} items`;

  showAddNew = () => {
    this.setState({
      showAddNewForm: !this.state.showAddNewForm,
    });
  };

  showModal = () => {
    this.setState({
      modalOpen: true,
    });
  };

  handleCancel = () => {
    this.setState({
      modalOpen: false,
      criteria: 1,
      mo: null /*
      errorText: '', */,
    });
  };

  handleCriteriaChange = (e) => {
    this.setState({
      criteria: e,
    });
  };

  getMonth = (e) => {
    if (e) {
      this.setState({
        mo: [e.month() + 1, e.year()],
      });
    } else {
      this.setState({
        mo: null,
      });
    }
  };

  onFreezeDateChange = (date, dateString) => {
    this.setState({
      dueDate: dateString,
    });
  };

  onInitiationDateChange = (date, dateString) => {
    this.setState({
      initiationDate: dateString,
    });
  };

  getProjects = (e) => {
    this.setState({
      projects: e,
    });
  };

  handleSubmit = (value) => {
    value.preventDefault();
    const { mo, criteria, dueDate, initiationDate } = this.state;
    let { projects } = this.state;
    projects = projects.map((proj) => proj.id);
    const employeeId = this.props.userId;
    this.props.createPerformanceCycleAction({
      mo,
      criteria,
      projects,
      employeeId,
      dueDate,
      initiationDate,
    });
    this.handleCancel();
  };

  renderCycleName = (name, data) => (
    <Link to={`performance-cycles-crmc/${data.id}`}>
      <b style={{ color: '#5A5A5A' }}>{name}</b>
    </Link>
  );

  shouldSaveButtonDisable = () => {
    const { mo, criteria, dueDate, initiationDate } = this.state;
    if (mo && criteria && dueDate && initiationDate) {
      return false;
    }
    return true;
  };

  disableDueDate = (date) => date < moment(this.state.initiationDate);

  renderModal = () => {
    const { isAdding } = this.props;
    const { modalOpen } = this.state;

    return (
      <EnhancedModal
        title="New Performance Cycle"
        visible={modalOpen}
        userId={this.props.userId}
        onCancel={this.handleCancel}
        width={650}
        footer={[
          <EnhancedButton key="back" onClick={this.handleCancel}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            key="add"
            type="primary"
            loading={isAdding}
            onClick={this.handleSubmit}
            disabled={this.shouldSaveButtonDisable()}
          >
            Save
          </EnhancedButton>,
        ]}
      >
        {this.renderFormRow()}
      </EnhancedModal>
    );
  };
  renderFormRow = () => {
    const projectOptions = [];
    this.props.projects.map((project) => {
      projectOptions.push({ id: project.id, value: project.name });
    });
    return (
      <EnhancedRow type="flex" justify="center">
        <EnhancedForm layout="inline">
          <EnhancedCol span={12} className="form-col">
            <p>
              <RequiredField>Cycle Month</RequiredField>
            </p>
            <MonthPicker id="mo" attr="mo" onChange={this.getMonth} placeholder="Select month" />
          </EnhancedCol>
          <EnhancedCol span={12} className="form-col">
            <p>
              <RequiredField>Criteria(days)</RequiredField>
            </p>
            <EnhancedInputNumber
              placeholder="Eligibility criteria in days"
              // id="criteria"
              // attr="criteria"
              min={1}
              onChange={this.handleCriteriaChange}
              value={this.state.criteria}
              required
            />
          </EnhancedCol>
          <EnhancedCol span={12} className="form-col">
            <p>
              <RequiredField> Initiation Date</RequiredField>
            </p>
            <EnhancedDatePicker
              id="initiationDate"
              attr="initiationDate"
              onChange={this.onInitiationDateChange}
              placeholder="Select Date"
            />
          </EnhancedCol>
          <EnhancedCol span={12} className="form-col">
            <p>
              <RequiredField>Due Date</RequiredField>
            </p>
            <EnhancedDatePicker
              id="dueDate"
              attr="dueDate"
              disabled={this.state.initiationDate ? false : true}
              onChange={this.onFreezeDateChange}
              disabledDate={this.disableDueDate}
              placeholder="Select Date"
            />
          </EnhancedCol>
          <EnhancedCol span={24} className="form-col">
            <p>Projects To Exclude</p>
            <ReactSelect
              closeMenuOnSelect={false}
              isMulti
              components={{ Option, MultiValue }}
              options={projectOptions}
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
              onChange={this.getProjects}
              placeholder="Select Projects"
              controlShouldRenderValue={false}
            />
          </EnhancedCol>
        </EnhancedForm>
      </EnhancedRow>
    );
  };

  getExportFileHeaders = () => {
    return [
      {
        key: 'name',
        label: 'Cycle Name',
      },
      {
        key: 'projectCount',
        label: 'No. of Projects',
      },
      {
        key: 'status',
        label: 'Cycle Status',
      },
    ];
  };

  render() {
    const { isLoading } = this.props;
    return (
      <div>
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol lg={12}>
              <h1 className="heading-pro">Performance Cycles</h1>
            </EnhancedCol>
            <EnhancedCol lg={12} style={{ textAlign: 'right' }}>
              <EnhancedButton type="primary" onClick={this.showModal}>
                Add new Cycle
              </EnhancedButton>
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        {this.state.modalOpen ? this.renderModal() : ''}
        <div className="project-table">
          <EnhancedClientSideTable
            loading={isLoading}
            data={this.props.performanceCycle}
            columns={this.getColumnsInfo()}
            exportFileConfig={{
              fileName: 'Performance Cycles',
              fileHeaders: this.getExportFileHeaders(),
              showExportButton: true,
            }}
          />
        </div>
      </div>
    );
  }
}

export default PerformanceCycleCRMC;
