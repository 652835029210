import React from 'react';
import { EnhancedSpin } from 'components/shared/antd';

const EmployeeDirectorySpinner = () => (
  <div className="box employee-directory-spinner">
    <EnhancedSpin tip="loading" />
  </div>
);

export default EmployeeDirectorySpinner;
