import { connect } from 'react-redux';
import { getPublicHolidaysAction } from 'store/actions/LeaveActions';
import {
  getEmployeesActions,
  getEmployeesProjectRolesActions,
  getEmployeesBirthdayActions,
} from 'store/actions/EmployeeActions';
import App from 'components/App';

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getEmployeesActions: (area) => dispatch(getEmployeesActions(area)),
  getPublicHolidays: () => dispatch(getPublicHolidaysAction()),
  getEmployeesProjectRolesActions: () => dispatch(getEmployeesProjectRolesActions()),
  getEmployeesBirthday: () => dispatch(getEmployeesBirthdayActions()),
});

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
