import { connect } from 'react-redux';
import updateBreadCrumb from 'store/actions/BreadCrumbActions';
import {
  getPEFDataActions,
  submitPEFDataActions,
  approvePEFAction,
} from '../store/actions/PerformanceEvaluationFormActions';
import PerformanceEvaluationForm from '../components/performanceEvaluationForm';
import {
  getPMCycleDetailAction,
  showPreviousPMCycleActions,
  getRatingOptionsActions,
} from '../store/actions/PerformanceMangementActions';
import { getAllDesignationActions } from '../store/actions/DesignationActions';

const mapStateToProps = (state) => {
  const { pefData, isLoading, isRedirect } = state.performanceEvaluationFormReducer;
  const { performanceManagementReducer, designationsReducer, employeeReducer } = state;
  return {
    pefData,
    isLoading,
    isRedirect,
    pmCycleDetail: performanceManagementReducer.pmCycleDetail,
    allEmployees: employeeReducer.employees,
    designations: designationsReducer.designations,
    previousPMCycle: performanceManagementReducer.previousCycle,
    ratingOptions: performanceManagementReducer.ratingOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllDesignations: () => dispatch(getAllDesignationActions()),
  getPEFDataActions: (params) => dispatch(getPEFDataActions(params)),
  submitPEFDataActions: (params) => dispatch(submitPEFDataActions(params)),
  getPMCycleDetail: (id) => dispatch(getPMCycleDetailAction(id)),
  updateBreadCrumb: (key, oldVal, newVal) => dispatch(updateBreadCrumb(key, oldVal, newVal)),
  approvePEF: (params) => dispatch(approvePEFAction(params)),
  showPreviousPMCycleActions: (params) => dispatch(showPreviousPMCycleActions(params)),
  getRatingOptions: () => dispatch(getRatingOptionsActions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceEvaluationForm);
