import { notification } from 'antd';

const EnhancedNotification = {
  success: (props) => {
    notification.success(props);
  },
  error: (props) => {
    notification.error(props);
  },
  info: (props) => {
    notification.info(props);
  },
  warning: (props) => {
    notification.warning(props);
  },
  warn: (props) => {
    notification.warn(props);
  },
  open: (props) => {
    notification.open(props);
  },
  close: (key) => {
    notification.close(key);
  },
  config: (options) => {
    notification.config(options);
  },
  destroy: () => {
    notification.destroy();
  },
};

export default EnhancedNotification;
