import {
  EnhancedButton,
  EnhancedCol,
  EnhancedRow,
  EnhancedLayout,
  EnhancedModal,
} from 'components/shared/antd';
import React, { Component } from 'react';
import LeavesSummaryCard from 'components/leaves/LeavesSummaryCard';
import OtherLeavesCard from 'components/leaves/OtherLeavesCard';
import LeavesHistoryList from 'components/leaves/LeavesHistoryList';
import LeaveTypesContainer from 'containers/LeaveTypesContainer';
import { leaveStatus, leaveTypes } from 'configs/leaveConstants';
import { leaveMode } from 'components/leaves/ApplyLeaveModal';
import { gaEvent } from 'sources/AnalyticsSource';
import { categories, events } from 'configs/gaConstants';
import { paginationOptions } from '../../utils/getPagination';

const { Header } = EnhancedLayout;
const Confirm = EnhancedModal.confirm;

class MyLeaves extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isEdit: false,
      modalLeaveMode: leaveMode.ALL,
      leaveType: '',
    };
  }
  componentDidMount() {
    this.props.getLeavesDetails(this.props.user.id);
    this.props.getLeavesStatuses();
  }
  componentWillReceiveProps(nextProps) {
    const { leavesStatuses: nextleavesStatuses, myLeaves: nextMyLeaves } = nextProps;
    const { leavesStatuses: prevleavesStatuses, myLeaves: prevMyLeaves } = this.props;

    if (
      nextleavesStatuses.length &&
      (nextleavesStatuses !== prevleavesStatuses ||
        nextMyLeaves.cancelled !== prevMyLeaves.cancelled ||
        nextMyLeaves.postSuccess !== prevMyLeaves.postSuccess)
    ) {
      const { id } = nextleavesStatuses.find(
        (status) => status.systemKey.toLowerCase() === leaveStatus.PENDING,
      );
      const { page, size } = paginationOptions;
      const params = {
        page,
        size,
        leaveStatus: id,
      };
      this.props.getMyLeaves(this.props.user.id, params);
      this.setState({ filteredText: id, visible: false });
    }

    if (nextMyLeaves.postSuccess !== prevMyLeaves.postSuccess) {
      this.props.getLeavesDetails(this.props.user.id);
    }
  }
  onCancelLeave = ({ id, leaveStatusId }) => {
    gaEvent({ category: categories.MY_LEAVES, action: events.CANCEL });
    const {
      leavesStatuses,
      user: { id: employeeId },
    } = this.props;
    const nextStatus = leavesStatuses.find(
      (status) => status.systemKey.toLowerCase() === leaveStatus.CANCELLED,
    );

    const payload = {
      id,
      nextStatus: nextStatus.id,
      currentStatus: leaveStatusId,
      employeeId,
    };

    Confirm({
      title: 'Confirmation',
      content: 'Are you sure you want to cancel your leave ?',
      onOk: () => {
        this.props.cancelApprovedLeave(payload);
      },
    });
  };

  setStatus = (filteredText) => this.setState({ filteredText });

  showCreateLeaveModal = (leaveType = '') => {
    gaEvent({ category: categories.MY_LEAVES, action: events.APPLY_LEAVE_COMMON });
    this.setState({
      visible: true,
      isEdit: false,
      modalLeaveMode: leaveMode.COMMON,
      leaveType,
    });
  };
  showCreateOtherLeaveModal = () => {
    gaEvent({ category: categories.MY_LEAVES, action: events.APPLY_LEAVE_OTHER });
    this.setState({ visible: true, isEdit: false, modalLeaveMode: leaveMode.OTHER });
  };
  showCreateAllLeaveModal = () => {
    gaEvent({ category: categories.MY_LEAVES, action: events.APPLY_LEAVE_ALL });
    this.setState({ visible: true, isEdit: false, modalLeaveMode: leaveMode.ALL });
  };
  showEditLeaveModal = (leaveRequest) => {
    gaEvent({ category: categories.MY_LEAVES, action: events.EDIT });
    this.setState({
      visible: true,
      isEdit: true,
      selectedLeave: leaveRequest,
    });
  };
  hideModal = () => {
    gaEvent({ category: categories.MY_LEAVES, action: events.CANCEL });
    this.setState({
      visible: false,
      selectedLeave: null,
      isEdit: false,
      leaveType: '',
    });
  };

  renderPendingOtherCount = (pendingCounts) => {
    const regularLeaves = [leaveTypes.ANNUAL, leaveTypes.SICK, leaveTypes.CASUAL];
    const pendingOther = Object.keys(pendingCounts).filter(
      (leave) => !regularLeaves.includes(leave),
    );
    let sum = 0;
    pendingOther.map((element) => {
      sum += pendingCounts[element];
      return sum;
    });
    return sum;
  };

  render() {
    const { myLeaves } = this.props;
    const {
      quota: { currentBalance, quotaLoader },
      loader,
    } = myLeaves;

    const { leaveType, visible, isEdit, modalLeaveMode, selectedLeave, filteredText } = this.state;

    const { annualAvailable } = currentBalance;

    return (
      <div>
        <Header className="leaves-header">
          <EnhancedRow style={{ backgroundColor: '#fff', marginBottom: '3px' }}>
            <EnhancedCol lg={12} md={10} sm={24} xs={24}>
              <h1>My Leave Requests</h1>
            </EnhancedCol>
            <EnhancedCol offset={9} lg={3} md={5} sm={24} xs={24} className="button-leaves-col">
              <EnhancedButton
                type="primary"
                size="large"
                onClick={this.showCreateAllLeaveModal}
                className="button-apply-leave"
              >
                Apply Leave
              </EnhancedButton>
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        {
          <LeaveTypesContainer
            visible={visible}
            onHideModal={this.hideModal}
            selectedLeave={selectedLeave}
            leaveType={leaveType}
            isEdit={isEdit}
            leaveMode={modalLeaveMode}
            annualTotal={currentBalance.annualTotal}
            annualAvailable={annualAvailable}
          />
        }
        <EnhancedRow gutter={14}>
          <EnhancedCol lg={6} md={12} sm={24} xs={24} className="sick-leaves">
            <LeavesSummaryCard
              heading="Sick Leaves"
              icon="medicine-box"
              totalDays={currentBalance.sickTotal}
              availableDays={currentBalance.sickAvailable}
              pendingRequests={currentBalance.sickPending}
              onClick={() => {
                this.showCreateLeaveModal(leaveTypes.SICK);
              }}
              loading={quotaLoader}
              showPending
            />
          </EnhancedCol>
          <EnhancedCol lg={6} md={12} sm={24} xs={24}>
            <LeavesSummaryCard
              heading="Casual Leaves"
              icon="laptop"
              totalDays={currentBalance.casualTotal}
              availableDays={currentBalance.casualAvailable}
              pendingRequests={currentBalance.casualPending}
              onClick={() => {
                this.showCreateLeaveModal(leaveTypes.CASUAL);
              }}
              loading={quotaLoader}
              showPending
            />
          </EnhancedCol>
          <EnhancedCol lg={6} md={12} sm={24} xs={24}>
            <LeavesSummaryCard
              heading="Annual Leaves"
              icon="table"
              availableDays={annualAvailable}
              pendingRequests={currentBalance.annualPending}
              onClick={() => {
                this.showCreateLeaveModal(leaveTypes.ANNUAL);
              }}
              loading={quotaLoader}
              showPending
            />
          </EnhancedCol>
          <EnhancedCol lg={6} md={12} sm={24} xs={24}>
            <OtherLeavesCard
              heading="Other Leaves"
              onClick={this.showCreateOtherLeaveModal}
              loading={quotaLoader}
              pendingRequests={currentBalance.otherPending}
              gender={this.props.user.gender}
            />
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow>
          <EnhancedCol span={24}>
            <div className="my-leaves-content">
              {filteredText && (
                <LeavesHistoryList
                  leavesStatuses={this.props.leavesStatuses}
                  onEditClicked={this.showEditLeaveModal}
                  onCancelClicked={this.onCancelLeave}
                  leaveRequests={myLeaves.leaveRequests}
                  pageInfo={myLeaves.pageInfo}
                  loading={loader}
                  workingHolidays={this.props.workingHolidays}
                  getMyLeaves={this.props.getMyLeaves}
                  user={this.props.user}
                  filteredText={filteredText}
                  setStatus={this.setStatus}
                />
              )}
            </div>
          </EnhancedCol>
        </EnhancedRow>
      </div>
    );
  }
}

export default MyLeaves;
