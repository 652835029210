/* eslint-disable */

import Axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import { getResponseError } from 'utils/sourceUtils';
import { interviewUrls } from '../configs/constants';
import {
  createInterviewFail,
  createInterviewInit,
  createInterviewSuccess,
  createQuestionFail,
  createQuestionInit,
  createQuestionSuccess,
  deleteInterviewInit,
  getInterviewsFail,
  getInterviewsInit,
  getInterviewsSuccess,
  getQuestionsFail,
  getQuestionsInit,
  getQuestionsSuccess,
  getReporteesTracksFail,
  getReporteesTracksInit,
  getReporteesTracksSuccess,
  updateInterviewFail,
  updateInterviewInit,
  updateInterviewSuccess,
  deleteQuestionInit,
  deleteQuestionSuccess,
  deleteQuestionFail,
  updateQuestionInit,
  updateQuestionSuccess,
  updateQuestionFail,
  deleteInterviewSuccess,
  getQuestionsFrequencyPerTrackFail,
  getQuestionsFrequencyPerTrackInit,
  getQuestionsFrequencyPerTrackSuccess,
  getQuestionsFrequencyPerClientAndTrackFail,
  getQuestionsFrequencyPerClientAndTrackInit,
  getQuestionsFrequencyPerClientAndTrackSuccess,
} from '../store/actions/interviewActions';
import { normalizeInterviewsList } from 'normalizers/interviewNormalizers';

export const getInterviewsList = () => (dispatch) => {
  dispatch(getInterviewsInit());
  const url = interviewUrls.IINTERVIEWS;
  Axios.get(url)
    .then((response) => {
      if (response.status === 200) {
        const { data } = response.data;
        const normalizedData = normalizeInterviewsList(data);
        // data.forEach((item) => {
        //   // eslint-disable-next-line no-param-reassign
        //   item.resourceName = item.employee.fullName;
        //   // eslint-disable-next-line no-param-reassign
        //   item.clientName = item.project.name;
        //   // eslint-disable-next-line no-param-reassign
        //   item.trackName = item.track.name;
        // });
        dispatch(getInterviewsSuccess(normalizedData));
      } else {
        EnhancedNotification.error({ message: response.data.data.message });
        dispatch(getInterviewsFail());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({ message: getResponseError(error) });
      dispatch(getInterviewsFail());
    });
};

export const createInterview = (payload) => async (dispatch) => {
  try {
    dispatch(createInterviewInit());
    const url = interviewUrls.IINTERVIEWS;
    const response = await Axios.post(url, payload);
    if (response.status !== 200) throw new Error(response);
    dispatch(createInterviewSuccess(response.data.data));
    dispatch(getInterviewsList());
    EnhancedNotification.success({ message: 'Interview created successfully.' });
  } catch (error) {
    EnhancedNotification.error({ message: getResponseError(error) });
    throw error;
  }
};

export const updateInterview = (id, payload) => async (dispatch) => {
  try {
    dispatch(updateInterviewInit());
    const url = interviewUrls.IINTERVIEWS;
    const response = await Axios.put(url, { id, ...payload });
    if (response.status !== 200) throw new Error(response);
    dispatch(updateInterviewSuccess(response.data.data));
    EnhancedNotification.success({ message: 'Interview updated successfully.' });
    dispatch(getInterviewsList());
  } catch (error) {
    EnhancedNotification.error({ message: getResponseError(error) });
    dispatch(updateInterviewFail());
    throw error;
  }

  // dispatch(updateInterviewInit());
  // const url = interviewUrls.IINTERVIEWS;
  // Axios.put(url, { id, ...payload })
  //   .then((response) => {
  //     if (response.status === 200) {
  //       dispatch(updateInterviewSuccess(response.data.data));
  //       EnhancedNotification.success({ message: 'Interview updated successfully.' });
  //     } else {
  //       EnhancedNotification.error({ message: response.data.data.message });
  //       dispatch(updateInterviewFail());
  //     }
  //   })
  //   .catch((error) => {
  //     EnhancedNotification.error({ message: getResponseError(error) });
  //     dispatch(updateInterviewFail());
  //   });
};

export const deleteInterview = (id) => (dispatch) => {
  const url = interviewUrls.IINTERVIEWS;
  Axios.delete(url, { data: { id } })
    .then((response) => {
      if (response.status === 200) {
        dispatch(deleteInterviewSuccess(id));
        EnhancedNotification.success({ message: 'Interview deleted successfully.' });
      } else {
        EnhancedNotification.error({ message: response.data.data.message });
      }
    })
    .catch((error) => {
      EnhancedNotification.error({ message: getResponseError(error) });
    });
};

export const getReporteesTracks = () => (dispatch) => {
  dispatch(getReporteesTracksInit());
  const url = interviewUrls.TRACKS;
  Axios.get(url, { params: { onlyReporteesTracks: true } })
    .then((response) => {
      if (response.status === 200) {
        dispatch(getReporteesTracksSuccess(response.data.data));
      } else {
        EnhancedNotification.error({ message: response.data.data.message });
        dispatch(getReporteesTracksFail());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({ message: getResponseError(error) });
      dispatch(getReporteesTracksFail());
    });
};

export const getQuestionList = (interviewId) => (dispatch) => {
  dispatch(getQuestionsInit());
  let url = interviewUrls.QUESTIONS;
  if (interviewId) {
    url = `${url}?interviewId=${interviewId}`;
  }
  Axios.get(url)
    .then((response) => {
      if (response.status === 200) {
        dispatch(getQuestionsSuccess(response.data.data));
      } else {
        EnhancedNotification.error({ message: response.data.data.message });
        dispatch(getQuestionsFail());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({ message: getResponseError(error) });
      dispatch(getQuestionsFail());
    });
};

export const createInterviewQuestion = (payload) => (dispatch) => {
  dispatch(createQuestionInit());
  const url = `${interviewUrls.QUESTIONS}`;
  Axios.post(url, payload)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({ message: 'Question created successfully' });
        if (payload.interviewId) {
          dispatch(createQuestionSuccess(response.data.data));
        } else {
          dispatch(getQuestionList());
        }
      } else {
        EnhancedNotification.error({ message: response.data.data.message });
        dispatch(createQuestionFail());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({ message: getResponseError(error) });
      dispatch(createQuestionFail());
    });
};

export const createInterviewQuestionByFile = (payload) => async (dispatch) => {
  try {
    const interviewId = payload.get('interviewId');
    dispatch(createQuestionInit());
    const url = `${interviewUrls.UPLOAD_QUESTIONS_BY_FILE}`;
    const response = await Axios.post(url, payload);
    if (response.status !== 200) throw new Error(response);
    dispatch(getQuestionList(interviewId));
  } catch (error) {
    EnhancedNotification.error({ message: getResponseError(error) });
    dispatch(createQuestionFail());
    throw error;
  }
};

export const updateInterviewQuestion = (id, payload) => (dispatch) => {
  dispatch(updateQuestionInit());
  const url = `${interviewUrls.QUESTIONS}`;
  Axios.put(url, { id, ...payload })
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({ message: 'Question updated successfully' });
        if (payload.interviewId) {
          dispatch(updateQuestionSuccess({ id, ...payload }));
        } else {
          dispatch(getQuestionList());
        }
      } else {
        EnhancedNotification.error({ message: response.data.data.message });
        dispatch(updateQuestionFail());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({ message: getResponseError(error) });
      dispatch(updateQuestionFail());
    });
};

export const deleteInterviewQuestion = (id) => (dispatch) => {
  dispatch(deleteQuestionInit(id));
  const url = `${interviewUrls.QUESTIONS}`;
  Axios.delete(url, { data: { id } })
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({ message: 'Question deleted successfully' });
        dispatch(deleteQuestionSuccess(response.data.data));
      } else {
        EnhancedNotification.error({ message: response.data.data.message });
        dispatch(deleteQuestionFail());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({ message: getResponseError(error) });
      dispatch(deleteQuestionFail());
    });
};

// QUESTIONS FREQUENCY

export const getQuestionsFrequencyPerTrack = (interviewId) => (dispatch) => {
  dispatch(getQuestionsFrequencyPerTrackInit());
  let url = interviewUrls.QUESTIONSFREQUENCYPERTRACK;
  if (interviewId) {
    url = `${url}`;
  }
  Axios.get(url)
    .then((response) => {
      // console.log('response => ', response);
      if (response.status === 200) {
        dispatch(getQuestionsFrequencyPerTrackSuccess(response.data.data));
      } else {
        EnhancedNotification.error({ message: response.data.data.message });
        dispatch(getQuestionsFrequencyPerTrackFail());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({ message: getResponseError(error) });
      dispatch(getQuestionsFrequencyPerTrackFail());
    });
};

export const getQuestionsFrequencyPerClientAndTrack = (interviewId) => (dispatch) => {
  dispatch(getQuestionsFrequencyPerClientAndTrackInit());
  let url = interviewUrls.QUESTIONSFREQUENCYPERCLIENTANDTRACK;
  if (interviewId) {
    url = `${url}`;
  }
  Axios.get(url)
    .then((response) => {
      if (response.status === 200) {
        dispatch(getQuestionsFrequencyPerClientAndTrackSuccess(response.data.data));
      } else {
        EnhancedNotification.error({ message: response.data.data.message });
        dispatch(getQuestionsFrequencyPerClientAndTrackFail());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({ message: getResponseError(error) });
      dispatch(getQuestionsFrequencyPerClientAndTrackFail());
    });
};
