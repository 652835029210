import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import throttle from 'lodash/throttle';
import rootReducer from 'store/reducers/rootReducer';
import { saveState, loadState } from 'utils/storageUtils';
import { environments } from 'configs/constants';

const middleware = [thunk];
if (process.env.NODE_ENV !== environments.PRODUCTION) {
  middleware.push(createLogger());
}

if (localStorage.getItem('reduxState')) {
  localStorage.removeItem('reduxState');
}

const persistedState = loadState();

const store = createStore(rootReducer, persistedState, applyMiddleware(...middleware));

store.subscribe(
  throttle(() => {
    saveState({
      auth: store.getState().auth,
    });
  }, 3000),
);

export default store;
