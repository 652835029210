import { EnhancedIcon, EnhancedSelect, EnhancedForm } from 'components/shared/antd';
import React from 'react';
import { notSetVariable } from 'configs/additionalConstants';

const { Option } = EnhancedSelect;
const FormItem = EnhancedForm.Item;

class EmployeeEditable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
      key: props.value,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    if (value && this.state.key !== this.props.value) {
      this.setState({ key: value });
    }
  }

  onSubmit = () => {
    const { updateInput, inputName } = this.props;
    const { key } = this.state;
    updateInput(inputName, key);
    this.setState({ editable: false });
  };

  onCancelUpdate = () => {
    this.setState({ editable: false });
  };
  onEdit = () => {
    this.setState({ editable: true });
  };
  handleChange = (key) => {
    this.setState({ key });
    if (this.props.updateInput) this.props.updateInput(this.props.inputName, key);
  };
  validate = (value) => {
    if (!value || notSetVariable.includes(value)) {
      return false;
    }
    return true;
  };
  validateLabelValue = (value) => {
    if (!value || notSetVariable.includes(value)) {
      return <span className="field-details">Not Set</span>;
    }
    return value;
  };
  render() {
    const { editable } = this.state;
    const { editingAllowed, values, text, field } = this.props;
    let label = values.filter((v) => v.key === this.state.key)[0];
    if (!label && text) label = { value: text };
    if (!editingAllowed) {
      return (
        <div className="editable-cell">
          {this.validate(label) ? (
            this.validateLabelValue(label.value, field)
          ) : (
            <span className="field-details">Not Set</span>
          )}
        </div>
      );
    }
    return (
      <div
        className="editable-cell"
        style={{
          cursor: 'pointer',
          ...this.props.style,
        }}
      >
        {editable ? (
          <div className="wrapper">
            <FormItem style={{ margin: 0 }}>
              <EnhancedSelect
                value={this.state.key}
                // showAction={["focus"]}
                showSearch
                placeholder="Select"
                style={{ minWidth: 150 }}
                optionFilterProp="children"
                onChange={this.handleChange}
              >
                {values && values.length
                  ? values.map((v) => (
                      <Option key={v.key} value={v.key}>
                        {`${v.value}`}
                      </Option>
                    ))
                  : null}
              </EnhancedSelect>
            </FormItem>
            <EnhancedIcon
              type="check"
              className="input-icon check"
              style={{
                marginLeft: '0.5em',
                marginTop: '0.4rem',
                cursor: 'pointer',
                ...this.props.iconStyle,
              }}
              onClick={this.onSubmit}
              title="Click to save"
            />
            <EnhancedIcon
              type="close"
              className="input-icon close"
              style={{
                marginLeft: '0.5em',
                marginTop: '0.4rem',
                cursor: 'pointer',
                ...this.props.iconStyle,
              }}
              onClick={this.onCancelUpdate}
              title="Click to close"
            />
          </div>
        ) : (
          <div className="inline" role="presentation" onClick={this.onEdit} onKeyDown={this.onEdit}>
            {this.validate(label) ? label.value : <span className="field-details">Not Set</span>}
          </div>
        )}
      </div>
    );
  }
}

export default EmployeeEditable;
