import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedTooltip,
  EnhancedSelect,
  EnhancedDatePicker,
  EnhancedInputNumber,
  EnhancedNotification,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { dateFormat } from 'configs/constants';
import { roundOffRating } from 'utils/conversionUtils';
import RequiredField from 'components/RequiredField';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import DealsListDrawer from './DealsListDrawer';

const ResourceDeficitReport = (props) => {
  const { Option } = EnhancedSelect;
  const {
    dealNames,
    getDealName,
    getEmployeeBusinessUnitAction,
    businessUnits,
    getResourceDeficitReport,
    resourceDeficit,
    getDealsSkillWise,
    dealsSkillWiseRows,
    dealsSkillWisePageInfo,
  } = props;

  const [formState, setFormState] = useState({
    selectedDate: moment().add(2, 'M'),
    probability: 41,
    reserveResource: 12,
    selectedDeals: [],
    selectedBusinessUnits: [],
  });

  const [tableState, setTableState] = useState({
    pageNumber: 0,
    pageSize: 10,
    dynamicColumns: [],
    xlsxFileHeaders: [],
  });

  const [filteredRecords, setFilteredRecords] = useState([]);

  const [drawerState, setDrawerState] = useState({
    showDrawer: false,
    selectedSkill: '',
    skills: [],
  });

  const [columnTotals, setColumnTotals] = useState({});

  const handleProbabilityChange = (value) => {
    setFormState((prevState) => ({ ...prevState, probability: value }));
  };

  const handleReserveChange = (value) => {
    setFormState((prevState) => ({ ...prevState, reserveResource: value }));
  };

  const handleDateChange = (date) => {
    setFormState((prevState) => ({ ...prevState, selectedDate: date }));
  };

  const handleDealSelect = (value) => {
    setFormState((prevState) => ({ ...prevState, selectedDeals: value }));
  };

  const handleBusinessUnitSelect = (value) => {
    setFormState((prevState) => ({ ...prevState, selectedBusinessUnits: value }));
  };

  const calculateTotals = (data) => {
    const totals = {};

    if (data[0] !== undefined) {
      Object.keys(data[0]).forEach((key) => {
        if (key !== 'skills') {
          data.forEach((row) => {
            if (!totals[key]) {
              totals[key] = 0;
            }
            totals[key] += parseFloat(row[key]);
          });
        }
      });
    }

    return totals;
  };

  const handleSearch = () => {
    if (
      formState.probability === '' ||
      formState.reserveResource === '' ||
      formState.selectedDate === '' ||
      formState.selectedDeals === []
    ) {
      EnhancedNotification.error({
        message: 'Please fill all the required fields',
      });
      return;
    }

    getResourceDeficitReport({
      probability: formState.probability,
      dealIDs: formState.selectedDeals,
      endDate: formState.selectedDate,
      reserve: formState.reserveResource,
      businessUnits: formState.selectedBusinessUnits,
    });
  };

  const handleTableChange = (pagination) => {
    setTableState((prevState) => ({
      ...prevState,
      pageNumber: pagination.current - 1,
      pageSize: pagination.pageSize,
    }));
  };

  const openDrawer = (selectedSkill) => {
    setDrawerState((prevState) => ({
      ...prevState,
      showDrawer: true,
      selectedSkill,
    }));
  };

  const hideDrawer = () => {
    setDrawerState((prevState) => ({
      ...prevState,
      showDrawer: false,
    }));
  };

  const tableColumns = [
    {
      title: 'Skills',
      dataIndex: 'skills',
      key: 'skills',
      filterConfig: {
        type: 'enhanced_filter',
        key: 'skills',
        optionsConfig: {
          values: resourceDeficit,
          valueVar: 'skills',
          labelVar: 'skills',
        },
      },
      ellipses: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <EnhancedTooltip title={text}>
            <b
              className="pointer-enabled ellipses"
              aria-hidden="true"
              onClick={() => openDrawer(record.skills)}
              style={{ width: 300 }}
            >
              {text}
            </b>
          </EnhancedTooltip>
        );
      },
      width: 250,
    },
    {
      title: (
        <React.Fragment>
          <div>Resource Required</div>
          <b>Total: {roundOffRating(columnTotals.resourceRequired)}</b>
        </React.Fragment>
      ),
      dataIndex: 'resourceRequired',
      key: 'resourceRequired',
      sorter: (a, b) => a.resourceRequired - b.resourceRequired,
      width: 200,
    },
  ];

  const fileHeaders = [
    {
      label: 'Skills',
      key: 'skills',
    },
    {
      label: `Resource Required, Total: ${roundOffRating(columnTotals.resourceRequired)}`,
      key: 'resourceRequired',
    },
  ];

  const addDynamicColumnsToXLSX = () => {
    const xlsxHeader = [];

    if (resourceDeficit[0] !== undefined) {
      Object.keys(resourceDeficit[0]).forEach((key) => {
        if (key !== 'skills' && key !== 'resourceRequired') {
          xlsxHeader.push({
            label: `${key}, Total: ${roundOffRating(columnTotals[key])}`,
            key,
          });
        }
      });
    }

    return xlsxHeader;
  };

  const addDynamicColumnsToTable = () => {
    const columns = [];

    if (resourceDeficit[0] !== undefined) {
      Object.keys(resourceDeficit[0]).forEach((key) => {
        if (key !== 'skills' && key !== 'resourceRequired') {
          columns.push({
            title: (
              <React.Fragment>
                <div>{key}</div>
                <b>Total: {roundOffRating(columnTotals[key])}</b>
              </React.Fragment>
            ),
            dataIndex: key,
            key,
            width: 150,
            render: (text) => {
              return {
                props: {
                  style: { background: parseFloat(text) < 0.0 && 'rgb(245, 34, 45, 0.1)' },
                },
                children: <div>{text}</div>,
                sorter: (a, b) => a.text - b.text,
              };
            },
            sorter: (a, b) => a[key] - b[key],
          });
        }
      });
    }

    return columns;
  };

  useEffect(() => {
    const newXLSXHeader = addDynamicColumnsToXLSX();
    const newTableColumns = addDynamicColumnsToTable();

    setTableState((prevState) => ({
      ...prevState,
      xlsxFileHeaders: [...fileHeaders, ...newXLSXHeader],
      dynamicColumns: [...tableColumns, ...newTableColumns],
    }));
  }, [columnTotals]);

  useEffect(() => {
    const totals = calculateTotals(filteredRecords);
    setColumnTotals(totals);
  }, [filteredRecords]);

  useEffect(() => {
    getDealName();
  }, []);

  useEffect(() => {
    if (dealNames.length > 0) {
      getEmployeeBusinessUnitAction();
      const deals = dealNames.map((deal) => deal.id);
      setFormState((prevState) => ({ ...prevState, selectedDeals: deals }));

      // getResourceDeficitReport({
      //   probability: formState.probability,
      //   dealIDs: deals,
      //   endDate: formState.selectedDate,
      //   reserve: formState.reserveResource,
      // });
    }
  }, [dealNames]);

  useEffect(() => {
    if (businessUnits.length > 0) {
      const selectedBusinessUnits = businessUnits.map((businessUnit) => businessUnit.name);
      setFormState((prevState) => ({ ...prevState, selectedBusinessUnits }));

      getResourceDeficitReport({
        probability: formState.probability,
        dealIDs: formState.selectedDeals,
        endDate: formState.selectedDate,
        reserve: formState.reserveResource,
        businessUnits: selectedBusinessUnits,
      });
    }
  }, [businessUnits]);

  useEffect(() => {
    const totals = calculateTotals(resourceDeficit);
    setColumnTotals(totals);
  }, [resourceDeficit]);

  return (
    <React.Fragment>
      <EnhancedCard style={{ marginBottom: '10px' }}>
        <EnhancedRow>
          <EnhancedCol span={24}>
            <h1 className="heading-emp" style={{ marginBottom: 0 }}>
              Resource Deficit
            </h1>
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedCard>
      <EnhancedCard style={{ marginBottom: '10px' }}>
        <EnhancedRow type="flex" align="middle" justify="space-between" gutter={48}>
          <EnhancedCol>
            <EnhancedRow type="flex" align="middle">
              <EnhancedCol className="mr-10">
                <RequiredField>Probability:</RequiredField>
              </EnhancedCol>
              <EnhancedCol>
                <EnhancedInputNumber
                  min={0}
                  max={100}
                  style={{ width: 100 }}
                  value={formState.probability}
                  onChange={handleProbabilityChange}
                />
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCol>
          <EnhancedCol>
            <EnhancedRow type="flex" align="middle">
              <EnhancedCol className="mr-10">
                <RequiredField>Reserve Resource (%):</RequiredField>
              </EnhancedCol>
              <EnhancedCol>
                <EnhancedInputNumber
                  min={0}
                  max={100}
                  style={{ width: 100 }}
                  value={formState.reserveResource}
                  onChange={handleReserveChange}
                />
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCol>
          <EnhancedCol>
            <EnhancedRow type="flex" align="middle">
              <EnhancedCol className="mr-10">
                <RequiredField>End Date:</RequiredField>
              </EnhancedCol>
              <EnhancedCol>
                <EnhancedDatePicker
                  onChange={handleDateChange}
                  defaultValue={moment().add(2, 'M')}
                  disabledDate={(current) => current.isBefore(moment())}
                  format={dateFormat.YYYYMMDDwithHyphen}
                  placeholder="Select End Date"
                  value={formState.selectedDate}
                />
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCol>

          <EnhancedCol style={{ flexGrow: 1 }}>
            <EnhancedRow type="flex" align="middle" style={{ width: '100%' }}>
              <EnhancedCol className="mr-10">
                <RequiredField>Business Units:</RequiredField>
              </EnhancedCol>
              <EnhancedCol style={{ flexGrow: 1 }}>
                <EnhancedSelect
                  allowClear
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Select Business Unit"
                  onChange={handleBusinessUnitSelect}
                  maxTagCount={2}
                  maxTagTextLength={15}
                  showArrow
                  value={formState.selectedBusinessUnits}
                  optionFilterProp="children"
                  showSearch
                  autoClearSearchValue={false}
                >
                  {businessUnits.map((businessUnit) => (
                    <Option key={businessUnit.id} value={businessUnit.name}>
                      {businessUnit.name}
                    </Option>
                  ))}
                </EnhancedSelect>
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow type="flex" align="middle" justify="end" style={{ marginTop: '20px' }}>
          <EnhancedCol style={{ flexGrow: 1 }}>
            <EnhancedRow type="flex" align="middle" style={{ width: '100%' }}>
              <EnhancedCol className="mr-10">
                <RequiredField>Deals:</RequiredField>
              </EnhancedCol>
              <EnhancedCol style={{ flexGrow: 1 }}>
                <EnhancedSelect
                  allowClear
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Select Deals"
                  onChange={handleDealSelect}
                  maxTagCount={5}
                  maxTagTextLength={15}
                  showArrow
                  value={formState.selectedDeals}
                  optionFilterProp="children"
                  showSearch
                  autoClearSearchValue={false}
                >
                  {dealNames.map((deal) => (
                    <Option key={deal.id} value={deal.id}>
                      {deal.name}
                    </Option>
                  ))}
                </EnhancedSelect>
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCol>
          <EnhancedCol span={4} style={{ textAlign: 'end' }}>
            <EnhancedButton icon="search" type="primary" className="ml-10" onClick={handleSearch}>
              Search
            </EnhancedButton>
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedCard>
      <EnhancedCard>
        <EnhancedClientSideTable
          rowKey={(record) => record.skills}
          data={resourceDeficit}
          columns={tableState.dynamicColumns}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
          setFilteredRecords={(records) => {
            setFilteredRecords(records);
          }}
          exportFileConfig={{
            fileName: `Resource_Deficit_Report_Ending_${
              formState.selectedDate
                ? formState.selectedDate.toJSON().slice(0, 10).replace(/-/g, '/')
                : ''
            }`,
            fileHeaders: tableState.xlsxFileHeaders,
            showExportButton: true,
          }}
        />
      </EnhancedCard>
      <DealsListDrawer
        isDrawerVisible={drawerState.showDrawer}
        onClose={hideDrawer}
        selectedSkill={drawerState.selectedSkill}
        formData={formState}
        getDealsSkillWise={getDealsSkillWise}
        dealsSkillWiseRows={dealsSkillWiseRows}
        dealsSkillWisePageInfo={dealsSkillWisePageInfo}
      />
    </React.Fragment>
  );
};

export default ResourceDeficitReport;
