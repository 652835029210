import React from 'react';
import { Route } from 'react-router-dom';
import { ROOT } from 'configs/routesConstants';
import { getRoutePermissions } from 'utils/AccessUtils';
import Authorize from './Authorize';

const AuthorizedRoute = (props) => {
  return (
    <Authorize
      redirect={props.redirect || ROOT}
      requiredPermissions={getRoutePermissions(props.path)}
    >
      <Route {...props} />
    </Authorize>
  );
};

// class AuthorizedRoute extends React.Component {
//   render() {
//     return (
//       <Authorize
//         redirect={this.props.redirect || ROOT}
//         requiredPermissions={getRoutePermissions(this.props.path)}
//       >
//         <Route {...this.props} />
//       </Authorize>
//     );
//   }
// }
export default AuthorizedRoute;
