import { connect } from 'react-redux';
import EmployeeOrgTree from 'components/employeeDirectory/EmployeeOrgTree';
import { getEmployeOrgTreeAction } from 'store/actions/EmployeeOrgTreeActions';
import { getEmployeeSearchAction } from 'store/actions/EmployeeSearchActions';

function mapStateToProps(state) {
  const { employeeOrgTreeReducer, employeeSearchReducer, auth } = state;
  return {
    userId: auth.user && auth.user.id,
    employeeDirectory: employeeSearchReducer.employeeDirectory,
    employeeOrgTree: employeeOrgTreeReducer.employeeOrgTree,
    isLoading: employeeOrgTreeReducer.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEmployeOrgTreeAction: (id) => dispatch(getEmployeOrgTreeAction(id)),
    getEmployeeSearchAction: (params) => dispatch(getEmployeeSearchAction(params)),
  };
}

const EmployeeOrgTreeContainer = connect(mapStateToProps, mapDispatchToProps)(EmployeeOrgTree);

export default EmployeeOrgTreeContainer;
