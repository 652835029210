import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import {
  getPerformanceCyclesInit,
  getPerformanceCyclesSuccess,
  getPerformanceCyclesFailure,
  getProjectByCycleIdInit,
  getProjectByCycleIdSuccess,
  getProjectByCycleIdFailure,
  getProjectByCycleIdCRMCInit,
  getProjectByCycleIdCRMCSuccess,
  getProjectByCycleIdCRMCFailure,
  getProjectRatingInit,
  getProjectRatingSuccess,
  getProjectRatingFailure,
  addProjectRatingInit,
  addProjectRatingFailure,
  markPerformanceCycleCompleteInit,
  markPerformanceCycleCompleteFailure,
  updatePerformanceCycleInit,
  updatePerformanceCycleSuccess,
  updatePerformanceCycleFailure,
  getCRMCPanelProjectDataInit,
  getCRMCPanelProjectDataSuccess,
  getCRMCPanelProjectDataFailure,
  getPerformanceCRMCCyclesInit,
  getPerformanceCRMCCyclesSuccess,
  getPerformanceCRMCCyclesFailure,
  getRatingDetailsInit,
  getRatingDetailsSuccess,
  getRatingDetailsFailure,
  getProjectScoreInit,
  getProjectScoreSuccess,
  getProjectScoreFailure,
} from 'store/actions/PerformanceCycleActions';
import { showLoader, hideLoader } from 'store/actions/AppLoaderActions';
import {
  normalizePerformanceCycles,
  normalizeProject,
  normalizeProjectRating,
  normalizeProjectSummary,
  normalizeRatingDetails,
} from 'normalizers/performanceCycleNormalizer';

import { performanceCycleUrls, employeeRatingUrls } from 'configs/constants';
import DownloadExcelFile from '../utils/ExcelFilesUtils';

export const downloadPerformanceRatings = (cycleInfo) => (dispatch) => {
  const cycleFilter = `?cycleId=${cycleInfo.id}&origin=crmc_panel`;
  const url = employeeRatingUrls.DOWNLOAD_ALL_RATINGS.concat(cycleFilter);

  dispatch(showLoader());
  axios
    .get(url)
    .then((response) => {
      dispatch(hideLoader());
      if (response.data.success && response.data.data) {
        const headers = [
          { key: 'resourceName', label: 'Resource Name' },
          { key: 'department', label: 'Department' },
          { key: 'competency', label: 'Competency' },
          { key: 'lineManager', label: 'Line Manager' },
          { key: 'performance_Month', label: 'Performance_Month' },
          { key: 'performance_Year', label: 'Performance_Year' },
          { key: 'project', label: 'Project' },
          { key: 'projectManager', label: 'Project Manager' },
          { key: 'projectArchitect', label: 'Project Architect' },
        ];
        DownloadExcelFile(
          [
            {
              arrayOfObjects: response.data.data.RatingsByPMAndArch,
              headerList: headers,
              name: 'Ratings By PM & ARCH',
            },
            {
              arrayOfObjects: response.data.data.RatingsByResource,
              headerList: headers,
              name: 'Ratings By Resource',
            },
          ],
          `Ratings - ${cycleInfo.cycleName}`,
        );
      }
    })
    .catch(() => {
      dispatch(hideLoader());
      EnhancedNotification.error({ message: 'Unable to download ratings.' });
    });
};

export const getPerformanceCycles = () => {
  const url = performanceCycleUrls.GET;
  return (dispatch) => {
    dispatch(getPerformanceCyclesInit());
    axios
      .get(url)
      .then((response) => {
        if (response.data.success) {
          // parse response through normalizer
          const PerformanceCycles = normalizePerformanceCycles(response.data.data);
          // call success or failure action
          dispatch(getPerformanceCyclesSuccess(PerformanceCycles));
        } else {
          dispatch(getPerformanceCyclesFailure(response.data.code));
        }
      })
      .catch((error) => {
        dispatch(getPerformanceCyclesFailure(error));
      });
  };
};

export const getCRMCPanelProjectData = (projectCycleId, projectId) => {
  const url = performanceCycleUrls.GET_CRMC_PANEL_PROJECT_DATA.replace(
    '{projectCycleId}',
    projectCycleId,
  ).replace('{projectId}', projectId);
  return (dispatch) => {
    dispatch(getCRMCPanelProjectDataInit());
    axios
      .get(url)
      .then((response) => {
        if (response.data.success) {
          // parse response through normalizer
          const PerformanceCycles = normalizeProjectRating(response.data.data);
          // call success or failure action
          dispatch(getCRMCPanelProjectDataSuccess(PerformanceCycles));
        } else {
          dispatch(getCRMCPanelProjectDataFailure(response.data.code));
        }
      })
      .catch((error) => {
        dispatch(getCRMCPanelProjectDataFailure(error));
      });
  };
};

export const getPerformanceCRMCCycles = () => {
  const url = performanceCycleUrls.GET_CRMC_CYCLE;
  return (dispatch) => {
    dispatch(getPerformanceCRMCCyclesInit());
    axios
      .get(url)
      .then((response) => {
        if (response.data.success) {
          // parse response through normalizer
          const PerformanceCycles = normalizePerformanceCycles(response.data.data);
          // call success or failure action
          dispatch(getPerformanceCRMCCyclesSuccess(PerformanceCycles));
        } else {
          dispatch(getPerformanceCRMCCyclesFailure(response.data.code));
        }
      })
      .catch((error) => {
        dispatch(getPerformanceCRMCCyclesFailure(error));
      });
  };
};

export const getProjectByCycleId = (cycleId) => {
  const url = performanceCycleUrls.GET_PROJECT_BY_CYCLE_ID.replace('{cycleId}', cycleId);

  return (dispatch) => {
    dispatch(getProjectByCycleIdInit());
    axios
      .get(url)
      .then((response) => {
        if (response.data.success) {
          // parse response through normalizer
          const PCProjects = normalizeProject(response.data.data);
          // call success or failure action
          dispatch(getProjectByCycleIdSuccess(PCProjects));
        } else {
          dispatch(getProjectByCycleIdFailure(response.data.code));
        }
      })
      .catch((error) => {
        dispatch(getProjectByCycleIdFailure(error));
      });
  };
};

export const getProjectByCycleIdForCRMC = (cycleId) => {
  const url = performanceCycleUrls.GET_PROJECT_BY_CYCLE_ID_CRMC.replace('{cycleId}', cycleId);

  return (dispatch) => {
    dispatch(getProjectByCycleIdCRMCInit());
    axios
      .get(url)
      .then((response) => {
        if (response.data.success) {
          // parse response through normalizer
          const PCProjects = normalizeProject(response.data.data);
          // call success or failure action
          dispatch(getProjectByCycleIdCRMCSuccess(PCProjects));
        } else {
          dispatch(getProjectByCycleIdCRMCFailure(response.data.code));
        }
      })
      .catch((error) => {
        dispatch(getProjectByCycleIdCRMCFailure(error));
      });
  };
};

export const getProjectRating = (projectCycleId, projectId) => {
  const url = performanceCycleUrls.GET_PROJECT_RATING.replace('{projectId}', projectId).replace(
    '{projectCycleId}',
    projectCycleId,
  );
  return (dispatch) => {
    dispatch(getProjectRatingInit());
    axios
      .get(url)
      .then((response) => {
        if (response.data.success) {
          // parse response through normalizer
          const ProjectRating = normalizeProjectSummary(response.data.data);
          // call success or failure action
          dispatch(getProjectRatingSuccess(ProjectRating));
        } else {
          dispatch(getProjectRatingFailure(response.data.code));
        }
      })
      .catch((error) => {
        dispatch(getProjectRatingFailure(error));
      });
  };
};

export const addProjectRating = (obj) => (dispatch) => {
  const url = performanceCycleUrls.PUT.replace('{employeeId}', obj.employeeId);
  const payload = {
    performanceCycleId: obj.cycleId,
    ratings: obj.ratings,
  };
  dispatch(showLoader());
  dispatch(addProjectRatingInit());
  axios
    .put(url, payload)
    .then((response) => {
      dispatch(hideLoader());
      if (response.data.success) {
        EnhancedNotification.success({ message: 'Rating Marked Successfully.' });

        if (obj.projectId) {
          dispatch(getProjectRating(obj.cycleId, obj.projectId));
        }
      } else {
        EnhancedNotification.error({ message: 'Editing Details failed.' });
        dispatch(addProjectRatingFailure());
      }
    })
    .catch(() => {
      dispatch(hideLoader());
      EnhancedNotification.error({ message: 'Editing Details failed.' });
      dispatch(addProjectRatingFailure());
    });
};

export const addPerformanceCycle = (obj) => (dispatch) => {
  const url = performanceCycleUrls.POST;
  const ratingData = obj;
  dispatch(showLoader());
  dispatch(addProjectRatingInit());
  axios
    .post(url, ratingData)
    .then((response) => {
      dispatch(hideLoader());
      if (response.data.success) {
        EnhancedNotification.success({ message: 'Performance Cycle Added Successfully.' });
        dispatch(getPerformanceCRMCCycles());
      } else {
        EnhancedNotification.error({ message: response.data.message });
        dispatch(addProjectRatingFailure());
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      EnhancedNotification.error({ message: error.response.data.message });
      dispatch(addProjectRatingFailure());
    });
};

export const deleteProjectRating = (obj) => (dispatch) => {
  const url = performanceCycleUrls.DELETE;
  const { ignoreProjectIdForNextCall } = obj;
  const payload = {
    employeeId: obj.employeeId,
    performanceCycleId: obj.performanceCycleId,
    projectId: obj.projectId,
    resourceId: obj.resourceId,
  };
  axios
    .put(url, payload)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({ message: 'Project Rating Deleted Successfully.' });
        let { projectId } = payload;
        if (ignoreProjectIdForNextCall) {
          projectId = 0;
        }
        dispatch(getCRMCPanelProjectData(payload.performanceCycleId, projectId));
      } else {
        EnhancedNotification.error({ message: 'Can not remove project rating.' });
        dispatch(addProjectRatingFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({ message: 'Can not remove project rating.' });
      dispatch(addProjectRatingFailure());
    });
};

export const deleteProjectCycle = (obj) => (dispatch) => {
  const url = performanceCycleUrls.DELETE_PROJECT_CYCLE;
  axios
    .put(url, obj)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({ message: 'Project Cycle Deleted Successfully.' });
        dispatch(getProjectByCycleIdForCRMC(obj.performanceCycleId));
      } else {
        EnhancedNotification.error({ message: 'Can not remove project cycle.' });
        dispatch(addProjectRatingFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({ message: 'Can not remove project cycle.' });
      dispatch(addProjectRatingFailure());
    });
};

export const addEmployeeProjectRating = (obj) => (dispatch) => {
  const url = performanceCycleUrls.POST_PROJECT_RATING;
  const ratingData = obj;
  dispatch(addProjectRatingInit());
  axios
    .post(url, ratingData)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({ message: 'Employee Added Successfully.' });
        dispatch(getCRMCPanelProjectData(obj.performanceCycleId, obj.projectId));
      } else {
        EnhancedNotification.error({ message: 'Can not add employee.' });
        dispatch(addProjectRatingFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({ message: 'Can not add employee.' });
      dispatch(addProjectRatingFailure());
    });
};

export const markPerformanceCycleComplete = (obj) => (dispatch) => {
  const url = performanceCycleUrls.COMPLETE.replace('{performanceCycleId}', obj.performanceCycleId);
  const ratingData = obj;
  dispatch(markPerformanceCycleCompleteInit());
  axios
    .put(url, ratingData)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: 'Performance Cycle Marked Complete Successfully.',
        });
        dispatch(getProjectByCycleIdForCRMC(obj.performanceCycleId));
      } else {
        EnhancedNotification.error({ message: 'Can not mark performance cycle complete.' });
        dispatch(markPerformanceCycleCompleteFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({ message: 'Can not mark performance cycle complete.' });
      dispatch(markPerformanceCycleCompleteFailure());
    });
};

export const extendProjectCycleDueDates = (data) => (dispatch) => {
  const url = performanceCycleUrls.EXTEND_PROJECT_CYCLE_DUE_DATES;
  axios
    .post(url, data)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: 'Rating due dates extended for selected projects.',
        });
        dispatch(getProjectByCycleIdForCRMC(data.performanceCycleId));
      } else {
        EnhancedNotification.error({ message: 'Unable to extend due date.' });
      }
    })
    .catch(() => {
      EnhancedNotification.error({ message: 'Unable to extend due date.' });
    });
};

export const updatePerformanceCycle = (performanceCycle) => (dispatch) => {
  const url = performanceCycleUrls.UPDATE_PERFORMANCE_CYCLE;
  dispatch(updatePerformanceCycleInit());
  axios
    .put(url, performanceCycle)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({ message: 'Performance Cycle update Successfully.' });
        dispatch(updatePerformanceCycleSuccess());
        const cycleId = performanceCycle.performanceCycleId;
        dispatch(getProjectByCycleIdForCRMC(cycleId));
      } else {
        EnhancedNotification.error({ message: response.data.message });
        dispatch(updatePerformanceCycleFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({ message: error.response.data.message });
      dispatch(updatePerformanceCycleFailure());
    });
};

export const getRatingDetailsCRMC = (payload) => (dispatch) => {
  const url = performanceCycleUrls.GET_RATING_DETAILS_CRMC.replace(
    '{performanceCycleId}',
    payload.performanceCycleId,
  )
    .replace('{projectId}', payload.projectId)
    .replace('{resourceId}', payload.resourceId);

  dispatch(showLoader());
  dispatch(getRatingDetailsInit());
  axios
    .get(url)
    .then((response) => {
      dispatch(hideLoader());
      if (response.data.success) {
        const ratingDetails = normalizeRatingDetails(response.data.data);
        dispatch(getRatingDetailsSuccess(ratingDetails));
      } else {
        EnhancedNotification.error({ message: response.data.message });
        dispatch(getRatingDetailsFailure());
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      EnhancedNotification.error({ message: error.response.data.message });
      dispatch(getRatingDetailsFailure());
    });
};

export const getRatingDetails = (payload) => {
  const url = `${performanceCycleUrls.GET_RATING_DETAILS}`;
  return (dispatch) => {
    dispatch(showLoader());
    dispatch(getRatingDetailsInit());
    axios
      .post(url, payload)
      .then((response) => {
        dispatch(hideLoader());
        if (response.data.success) {
          // parse response through normalizer
          const ratingDetails = normalizeRatingDetails(response.data.data);
          dispatch(getRatingDetailsSuccess(ratingDetails));
        } else {
          EnhancedNotification.error({ message: response.data.message });
          dispatch(getRatingDetailsFailure(response.data.code));
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        EnhancedNotification.error({ message: error.response.data.message });
        dispatch(getRatingDetailsFailure(error));
      });
  };
};

export const getProjectScore = (performanceCycleId, projectId) => {
  const url = performanceCycleUrls.GET_PROJECT_SCORE.replace(
    '{performanceCycleId}',
    performanceCycleId,
  ).replace('{projectId}', projectId);

  return (dispatch) => {
    dispatch(getProjectScoreInit());
    axios
      .get(url)
      .then((response) => {
        if (response.data.success) {
          dispatch(getProjectScoreSuccess(response.data.data));
        } else {
          EnhancedNotification.error({ message: response.data.message });
          dispatch(getProjectScoreFailure(response.data.code));
        }
      })
      .catch((error) => {
        EnhancedNotification.error({ message: error.response.data.message });
        dispatch(getProjectScoreFailure(error));
      });
  };
};
