import { projectDetailsUrls, AddNewResourceProjectUrls } from 'configs/constants';
import { EnhancedNotification } from 'components/shared/antd';
import axios from 'axios';
import {
  getProjectDetailsInit,
  getProjectDetailsSuccess,
  getProjectDetailsFailure,
  addNewResourceInit,
  addNewResourceSuccess,
  addNewResourceFailure,
} from 'store/actions/ProjectEmployeesActions';

import { getProjectActivityLogsAction } from 'store/actions/ProjectActivityLogActions';

import normalizeGetProjectResponse from 'normalizers/projectEmployeesNormalizers';

export const getProjectDetails = (projectId) => (dispatch) => {
  const url = projectDetailsUrls.GET;

  dispatch(getProjectDetailsInit());
  axios
    .get(url.replace('{projectId}', projectId))
    .then((response) => {
      if (response.data.success) {
        const projectEmployeesNormalized = normalizeGetProjectResponse(response.data.data);
        dispatch(getProjectDetailsSuccess(projectEmployeesNormalized));
      } else {
        dispatch(getProjectDetailsFailure());
      }
    })
    .catch(() => {
      dispatch(getProjectDetailsFailure());
    });
};

export const addNewResource =
  (request, roles, employeeName, department, competency, location, taleoId) => (dispatch) => {
    dispatch(addNewResourceInit());
    axios
      .post(AddNewResourceProjectUrls.POST, request)
      .then((response) => {
        if (response.data.success) {
          const employeeProjectId = response.data.data;
          EnhancedNotification.success({ message: 'New Resource Added Successfully.' });
          dispatch(
            addNewResourceSuccess(
              employeeProjectId,
              request,
              roles,
              employeeName,
              department,
              competency,
              location,
              taleoId,
            ),
          );
          dispatch(getProjectActivityLogsAction(request.projectId, 1));
        } else {
          EnhancedNotification.error({
            message: response.data.message || 'Adding New Resource failed.',
          });
          dispatch(addNewResourceFailure());
        }
      })
      .catch((error) => {
        EnhancedNotification.error({
          message: error.response.data.message || 'Adding New Resource failed.',
        });
        dispatch(addNewResourceFailure());
      });
  };
