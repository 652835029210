/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { addEmployeeInit, addEmployeeFailure } from 'store/actions/AddEmployeeActions';
import { showLoader, hideLoader } from 'store/actions/AppLoaderActions';
import { addEmployeeSuccessful } from 'store/actions/EmployeeActions';
import { normalizeAddEmployeesResponse } from 'normalizers/employeeNormalizers';
import { EnhancedNotification } from 'components/shared/antd';
import { employeesUrls } from 'configs/constants';

export const addEmployee = (request) => (dispatch) => {
  dispatch(addEmployeeInit());
  dispatch(showLoader());
  axios
    .post(employeesUrls.POST, { ...request })
    .then((response) => {
      dispatch(hideLoader());
      if (response.data.success) {
        EnhancedNotification.success({ message: 'Employee Added Successfully.' });
        const normalizedResponse = normalizeAddEmployeesResponse(response.data.data);
        dispatch(addEmployeeSuccessful(normalizedResponse));
      } else {
        EnhancedNotification.error({ message: response.data.message });
        dispatch(addEmployeeFailure(response.data.message));
      }
    })
    .catch((err) => {
      dispatch(hideLoader());
      EnhancedNotification.error({
        message: err.response ? err.response.data.message : 'Something went wrong!',
      });
      dispatch(addEmployeeFailure(err.response.data.message));
    });
};
