import { connect } from 'react-redux';
import {
  getAllEmployeesActions,
  employeesBulkInfoActions,
  getEmployeeDepartmentsAction,
  getEmployeeDivisionsAction,
  getEmployeesFamilyInfoActions,
} from 'store/actions/EmployeeActions';
import { getAllDesignationActions } from 'store/actions/DesignationActions';
import { getAllLocationActions } from 'store/actions/LocationActions';
import AllEmployees from 'components/employees/AllEmployees';
import { getEmploymentStatusesAction } from 'store/actions/EmployeeProfileActions';

function mapStateToProps(state) {
  const { employeeReducer, designationsReducer, locationsReducer, auth, employeeProfileReducer } =
    state;
  return {
    employees: employeeReducer.allEmployees,
    designations: designationsReducer.designations,
    locations: locationsReducer.locations,
    isLoading: employeeReducer.isLoading,
    user: auth.user,
    departments: employeeReducer.departments,
    divisions: employeeReducer.divisions,
    dropDowns: employeeProfileReducer.dropDowns,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAllEmployeesActions: () => dispatch(getAllEmployeesActions()),
    getEmployeesFamilyInfoActions: (status) => dispatch(getEmployeesFamilyInfoActions(status)),
    getAllDesignations: () => dispatch(getAllDesignationActions()),
    getAllLocations: () => dispatch(getAllLocationActions()),
    employeesBulkInfo: (data) => dispatch(employeesBulkInfoActions(data)),
    getEmployeeDepartmentsAction: () => dispatch(getEmployeeDepartmentsAction()),
    getEmployeeDivisionsAction: () => dispatch(getEmployeeDivisionsAction()),
    getEmploymentStatuses: () => dispatch(getEmploymentStatusesAction()),
  };
}
const AllEmployeesContainer = connect(mapStateToProps, mapDispatchToProps)(AllEmployees);

export default AllEmployeesContainer;
