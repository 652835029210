import {
  GET_PERFORMANCE_CYCLES_INIT,
  GET_PERFORMANCE_CYCLES_SUCCESS,
  GET_PERFORMANCE_CYCLES_FAILURE,
  GET_PROJECT_BY_CYCLE_ID_INIT,
  GET_PROJECT_BY_CYCLE_ID_SUCCESS,
  GET_PROJECT_BY_CYCLE_ID_FAILURE,
  GET_PROJECT_BY_CYCLE_ID_CRMC_INIT,
  GET_PROJECT_BY_CYCLE_ID_CRMC_SUCCESS,
  GET_PROJECT_BY_CYCLE_ID_CRMC_FAILURE,
  GET_PROJECT_RATING_INIT,
  GET_PROJECT_RATING_SUCCESS,
  GET_PROJECT_RATING_FAILURE,
  GET_CRMC_PANEL_PROJECT_DATA_INIT,
  GET_CRMC_PANEL_PROJECT_DATA_SUCCESS,
  GET_CRMC_PANEL_PROJECT_DATA_FAILURE,
  GET_PERFORMANCE_CRMC_CYCLES_INIT,
  GET_PERFORMANCE_CRMC_CYCLES_SUCCESS,
  GET_PERFORMANCE_CRMC_CYCLES_FAILURE,
  GET_RATING_DETAILS_INIT,
  GET_RATING_DETAILS_SUCCESS,
  GET_RATING_DETAILS_FAILURE,
  RESET_RATING_DETAILS,
  GET_PROJECT_SCORE_INIT,
  GET_PROJECT_SCORE_SUCCESS,
  GET_PROJECT_SCORE_FAILURE,
  /* CREATE_PERFORMANCE_CYCLE_INIT,
  CREATE_PERFORMANCE_CYCLE_SUCCESS,
  CREATE_PERFORMANCE_CYCLE_FAILURE,
  EDIT_PERFORMANCE_CYCLE_DETAILS_INIT,
  EDIT_PERFORMANCE_CYCLE_DETAILS_SUCCESS,
  EDIT_PERFORMANCE_CYCLE_DETAILS_FAILURE, */
} from 'constants/PerformanceCycleTypes';

const initialState = {
  performanceCycle: [],
  projectCycleData: {},
  projectRating: {},
  performanceCycleCRMC: [],
  isLoading: false,
  isAdding: false,
  ratingDetails: {
    loading: false,
    data: {},
    error: {},
  },
  projectScore: {
    loading: false,
    data: {},
    error: {},
  },
};
export default function performanceCycleReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PERFORMANCE_CYCLES_INIT: {
      return Object.assign({}, state, {
        performanceCycle: [],
        isLoading: true,
      });
    }
    case GET_PERFORMANCE_CYCLES_SUCCESS: {
      return Object.assign({}, state, {
        performanceCycle: action.data,
        isLoading: false,
      });
    }
    case GET_PERFORMANCE_CYCLES_FAILURE: {
      return Object.assign({}, state, {
        performanceCycle: [],
        isLoading: false,
      });
    }
    case GET_CRMC_PANEL_PROJECT_DATA_INIT: {
      return Object.assign({}, state, {
        projectRating: {},
        isLoading: true,
      });
    }
    case GET_CRMC_PANEL_PROJECT_DATA_SUCCESS: {
      return Object.assign({}, state, {
        projectRating: action.data,
        isLoading: false,
      });
    }
    case GET_CRMC_PANEL_PROJECT_DATA_FAILURE: {
      return Object.assign({}, state, {
        projectRating: {},
        isLoading: false,
      });
    }
    case GET_PERFORMANCE_CRMC_CYCLES_INIT: {
      return Object.assign({}, state, {
        performanceCycle: [],
        isLoading: true,
      });
    }
    case GET_PERFORMANCE_CRMC_CYCLES_SUCCESS: {
      return Object.assign({}, state, {
        performanceCycle: action.data,
        isLoading: false,
      });
    }
    case GET_PERFORMANCE_CRMC_CYCLES_FAILURE: {
      return Object.assign({}, state, {
        performanceCycle: [],
        isLoading: false,
      });
    }
    case GET_PROJECT_BY_CYCLE_ID_INIT: {
      return Object.assign({}, state, {
        projectCycleData: {},
        isLoading: true,
      });
    }
    case GET_PROJECT_BY_CYCLE_ID_SUCCESS: {
      return Object.assign({}, state, {
        projectCycleData: action.data,
        isLoading: false,
      });
    }
    case GET_PROJECT_BY_CYCLE_ID_FAILURE: {
      return Object.assign({}, state, {
        projectCycleData: {},
        isLoading: false,
      });
    }
    case GET_PROJECT_BY_CYCLE_ID_CRMC_INIT: {
      return Object.assign({}, state, {
        projectCycleData: {},
        isLoading: true,
      });
    }
    case GET_PROJECT_BY_CYCLE_ID_CRMC_SUCCESS: {
      return Object.assign({}, state, {
        projectCycleData: action.data,
        isLoading: false,
      });
    }
    case GET_PROJECT_BY_CYCLE_ID_CRMC_FAILURE: {
      return Object.assign({}, state, {
        projectCycleData: {},
        isLoading: false,
      });
    }
    case GET_PROJECT_RATING_INIT: {
      return Object.assign({}, state, {
        projectRating: {},
        isLoading: true,
      });
    }
    case GET_PROJECT_RATING_SUCCESS: {
      return Object.assign({}, state, {
        projectRating: action.data,
        isLoading: false,
      });
    }
    case GET_PROJECT_RATING_FAILURE: {
      return Object.assign({}, state, {
        projectRating: {},
        isLoading: false,
      });
    }
    case GET_RATING_DETAILS_INIT: {
      return Object.assign({}, state, {
        ratingDetails: {
          ...state.ratingDetails,
          loading: true,
          data: {},
        },
      });
    }
    case GET_RATING_DETAILS_SUCCESS: {
      return Object.assign({}, state, {
        ratingDetails: {
          data: action.data,
          loading: false,
          error: {},
        },
      });
    }
    case GET_RATING_DETAILS_FAILURE: {
      return Object.assign({}, state, {
        ratingDetails: {
          data: {},
          loading: false,
          error: action.data,
        },
      });
    }
    case RESET_RATING_DETAILS: {
      return Object.assign({}, state, {
        ratingDetails: {
          loading: false,
          data: {},
          error: {},
        },
      });
    }
    case GET_PROJECT_SCORE_INIT: {
      return Object.assign({}, state, {
        projectScore: {
          ...state.projectScore,
          loading: true,
          data: {},
        },
      });
    }
    case GET_PROJECT_SCORE_SUCCESS: {
      return Object.assign({}, state, {
        projectScore: {
          data: action.data,
          loading: false,
          error: {},
        },
      });
    }
    case GET_PROJECT_SCORE_FAILURE: {
      return Object.assign({}, state, {
        projectScore: {
          data: {},
          loading: false,
          error: action.data,
        },
      });
    }
    /*  case CREATE_PERFORMANCE_CYCLE_INIT: {
      return Object.assign({}, state, {
        isAdding: true,
      });
    }
    case CREATE_PERFORMANCE_CYCLE_SUCCESS: {
      const { projects } = state;
      projects.push(action.data);
      return {
        ...state,
        projects,
        isAdding: false,
      };
    }
    case CREATE_PERFORMANCE_CYCLE_FAILURE: {
      return Object.assign({}, state, {
        isAdding: false,
      });
    } */
    default:
      return state;
  }
}
