export const GET_EMPLOYEES_PROJECT_INIT = 'GET_EMPLOYEES_PROJECT_INIT';
export const GET_EMPLOYEES_PROJECT_SUCCESS = 'GET_EMPLOYEES_PROJECT_SUCCESS';
export const GET_EMPLOYEES_PROJECT_FAILURE = 'GET_EMPLOYEES_PROJECT_FAILURE';

export const DELETE_EMPLOYEES_PROJECT_INIT = 'DELETE_EMPLOYEES_PROJECT_INIT';
export const DELETE_EMPLOYEES_PROJECT_SUCCESS = 'DELETE_EMPLOYEES_PROJECT_SUCCESS';
export const DELETE_EMPLOYEES_PROJECT_FAILURE = 'DELETE_EMPLOYEES_PROJECT_FAILURE';

export const ADD_EMPLOYEES_PROJECT_INIT = 'ADD_EMPLOYEES_PROJECT_INIT';
export const ADD_EMPLOYEES_PROJECT_SUCCESS = 'ADD_EMPLOYEES_PROJECT_SUCCESS';
export const ADD_EMPLOYEES_PROJECT_FAILURE = 'ADD_EMPLOYEES_PROJECT_FAILURE';

export const UPDATE_EMPLOYEES_PROJECT_INIT = 'UPDATE_EMPLOYEES_PROJECT_INIT';
export const UPDATE_EMPLOYEES_PROJECT_SUCCESS = 'UPDATE_EMPLOYEES_PROJECT_SUCCESS';
export const UPDATE_EMPLOYEES_PROJECT_FAILURE = 'UPDATE_EMPLOYEES_PROJECT_FAILURE';

export const GET_EMPLOYEES_PROJECT_ROLES_INIT = 'GET_EMPLOYEES_PROJECT_ROLES_INIT';
export const GET_EMPLOYEES_PROJECT_ROLES_SUCCESS = 'GET_EMPLOYEES_PROJECT_ROLES_SUCCESS';
export const GET_EMPLOYEES_PROJECT_ROLES_FAILURE = 'GET_EMPLOYEES_PROJECT_ROLES_INIT';
