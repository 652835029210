/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import { getResponseError } from 'utils/sourceUtils';
import { normalizeAllLeaves } from 'normalizers/hr/leavesDashboardNormalizers';
import { HRleavesDashboardUrls } from 'configs/constants';

export const getCompanyWideDashboard = (params) =>
  new Promise((resolve, reject) => {
    const url = HRleavesDashboardUrls.GET;
    axios
      .get(url, {
        params,
      })
      .then((response) => {
        if (response && response.data.success) {
          const {
            data: { content, pageInfo },
          } = response.data;
          const companyWideDashboard = normalizeAllLeaves(content);
          resolve({ companyWideDashboard, pageInfo });
        } else {
          EnhancedNotification.error({
            message: getResponseError(response.message),
          });
          reject(response.message);
        }
      })
      .catch((err) => {
        EnhancedNotification.error({
          message: getResponseError(err),
        });
        reject(err);
      });
  });

export const getLeavesAdjustmentsLogs = (params) =>
  new Promise((resolve, reject) => {
    const url = HRleavesDashboardUrls.GET_LOGS;
    axios
      .get(url, {
        params,
      })
      .then((response) => {
        if (response && response.data.success) {
          const {
            data: { rows, pageInfo },
          } = response.data;
          resolve({ rows, pageInfo });
        } else {
          EnhancedNotification.error({
            message: getResponseError(response.message),
          });
          reject(response.message);
        }
      })
      .catch((err) => {
        EnhancedNotification.error({
          message: getResponseError(err),
        });
        reject(err);
      });
  });

export const adjustLeaveQuota = (body) =>
  new Promise((resolve, reject) => {
    const url = HRleavesDashboardUrls.POST;
    axios
      .post(url, body)
      .then((response) => {
        if (response && response.data.success) {
          const { data } = response.data;
          EnhancedNotification.success({
            message: 'Leave adjustment updated successfully',
          });
          resolve(data);
        } else {
          EnhancedNotification.error({
            message: getResponseError(response.message),
          });
          reject(response.message);
        }
      })
      .catch((err) => {
        EnhancedNotification.error({
          message: getResponseError(err),
        });
        reject(err);
      });
  });
