export default {
  Project: {
    List: 'project.list',
    Create: 'project.create',
    Update: 'project.update',
    Delete: 'project.delete',
    Export: 'project.export',
    Current: {
      List: 'project.current.list',
    },
    Allocation: {
      List: 'project.allocation.list',
      Create: 'project.allocation.create',
      Update: 'project.allocation.update',
      Delete: 'project.allocation.delete',
    },
    ActivityLogs: {
      List: 'project.activity_logs.list',
    },
    Completed: {
      List: 'project.completed.list',
    },
  },
  Employee: {
    Create: 'employee.create',
    View: 'employee.view',
    List: 'employee.list',
    Delete: 'employee.delete',
    Billable: {
      List: 'employee.billable.list',
    },
    Update: 'employee.update',
    OrgChart: {
      View: 'employee.orgchart.view',
    },
    All: {
      List: 'employee.all.list',
      Export: 'employees.all.export',
    },
    Profile: {
      View: 'employee.profile.view',
      Info: {
        View: 'employee.profile.info.view',
        Update: 'employee.profile.info.update',
        BulkUpdate: 'employee.profile.info.bulk_update',
        ProfilePicture: {
          Update: 'employee.profile.info.profile_picture.update',
        },
        Contact: {
          Update: 'employee.profile.info.contact.update',
        },
        Address: {
          Update: 'employee.profile.info.address.update',
        },
        BloodGroup: {
          Update: 'employee.profile.info.blood_group.update',
        },
        TaxReturnFiler: {
          Update: 'employee.profile.info.tax_return_filer.update',
        },
        Passport: {
          Update: 'employee.profile.info.passport.update',
        },
        PlaceOfBirth: {
          Update: 'employee.profile.info.place_of_birth.update',
        },
        Family: {
          View: 'employee.profile.info.family.view',
          Create: 'employee.profile.info.family.create',
          Update: 'employee.profile.info.family.update',
          Delete: 'employee.profile.info.family.delete',
          Export: 'employee.profile.info.family.export',
        },
        EmergencyContact: {
          View: 'employee.profile.info.emergency_contact.view',
          Create: 'employee.profile.info.emergency_contact.create',
          Update: 'employee.profile.info.emergency_contact.update',
          Delete: 'employee.profile.info.emergency_contact.delete',
        },
      },
      JobInfo: {
        View: 'employee.profile.job_info.view',
        Create: 'employee.profile.job_info.create',
        Update: 'employee.profile.job_info.update',
        Delete: 'employee.profile.job_info.delete',
        Tracking: {
          View: 'employee.profile.job_info.tracking.view',
          Update: 'employee.profile.job_info.tracking.update',
        },
        History: {
          View: 'employee.profile.job_info.history.view',
        },
      },
      Compensation: {
        View: 'employee.profile.compensation.view',
        Create: 'employee.profile.compensation.create',
        Update: 'employee.profile.compensation.update',
        Delete: 'employee.profile.compensation.delete',
      },
      TalentManagement: {
        View: 'employee.profile.talent_management.view',
        Update: 'employee.profile.talent_management.update',
        CareerPlanning: {
          View: 'employee.profile.talent_management.career_planning.view',
          AreasOfExpertise: {
            Update: 'employee.profile.talent_management.career_planning.areas_of_expertise.update',
          },
          CareerAmbitions: {
            Update: 'employee.profile.talent_management.career_planning.career_ambitions.update',
          },
        },
        Management: {
          View: 'employee.profile.talent_management.management.view',
          Update: 'employee.profile.talent_management.management.update',
        },
        Certificates: {
          View: 'employee.profile.talent_management.certificates.view',
          Create: 'employee.profile.talent_management.certificates.create',
          Update: 'employee.profile.talent_management.certificates.update',
          Delete: 'employee.profile.talent_management.certificates.delete',
        },
      },
      EmploymentHistory: {
        View: 'employee.profile.employment_history.view',
        Create: 'employee.profile.employment_history.create',
        Update: 'employee.profile.employment_history.update',
        Delete: 'employee.profile.employment_history.delete',
      },
      EducationHistory: {
        View: 'employee.profile.education_history.view',
        Create: 'employee.profile.education_history.create',
        Update: 'employee.profile.education_history.update',
        Delete: 'employee.profile.education_history.delete',
      },
      Documents: {
        View: 'employee.profile.documents.view',
        Create: 'employee.profile.documents.create',
        Delete: 'employee.profile.documents.delete',
        Update: 'employee.profile.documents.update',
      },
      Comments: {
        View: 'employee.profile.comments.view',
        Create: 'employee.profile.comments.create',
        Delete: 'employee.profile.comments.delete',
      },
      HistoryLog: {
        View: 'employee.profile.history_log.view',
      },
      Goal: {
        View: 'employee.profile.goal.list',
        Update: 'employee.profile.goal.update',
        Delete: 'employee.profile.goal.delete',
      },
    },
  },
  Reportee: {
    Allowed: 'reportee.allowed',
    Profile: {
      Info: {
        View: 'reportee.profile.info.view',
        Family: {
          View: 'reportee.profile.info.family.view',
        },
        EmergencyContact: {
          View: 'reportee.profile.info.emergency_contact.view',
        },
      },
      JobInfo: {
        View: 'reportee.profile.job_info.view',
        Tracking: {
          View: 'reportee.profile.job_info.tracking.view',
        },
        History: {
          View: 'reportee.profile.job_info.history.view',
        },
      },
      Compensation: {
        View: 'reportee.profile.compensation.view',
      },
      TalentManagement: {
        View: 'reportee.profile.talent_management.view',
        CareerPlanning: {
          View: 'reportee.profile.talent_management.career_planning.view',
        },
        Management: {
          View: 'reportee.profile.talent_management.management.view',
        },
        Certificates: {
          View: 'reportee.profile.talent_management.certificates.view',
        },
      },
      EmploymentHistory: {
        View: 'reportee.profile.employment_history.view',
      },
      EducationHistory: {
        View: 'reportee.profile.education_history.view',
      },
      Documents: {
        View: 'reportee.profile.documents.view',
      },
      Comments: {
        View: 'reportee.profile.comments.view',
      },
      HistoryLog: {
        View: 'reportee.profile.history_log.view',
      },
    },
  },
  Dashboard: {
    View: 'dashboard.view',
  },
  Leave: {
    AdjustmentLogs: {
      List: 'leave.adjustment_logs.list',
    },
  },
  Goals: {
    PredefinedGoals: {
      List: 'goals.predefined_goals.list',
      View: 'goals.predefined_goals.view',
      Update: 'goals.predefined_goals.update',
      Create: 'goals.predefined_goals.create',
      Delete: 'goals.predefined_goals.delete',
    },
    GoalCategories: {
      List: 'goals.goal_categories.list',
      Update: 'goals.goal_categories.update',
      Create: 'goals.goal_categories.create',
      Delete: 'goals.goal_categories.delete',
      View: 'goals.goal_categories.View',
      Goals: {
        List: 'goals.goal_categories.goals.list',
        Update: 'goals.goal_categories.goals.update',
        Create: 'goals.goal_categories.goals.create',
        Delete: 'goals.goal_categories.goals.delete',
      },
    },
  },
  Configuration: {
    Department: {
      List: 'configuration.department.list',
      Create: 'configuration.department.create',
      Update: 'configuration.department.update',
      Delete: 'configuration.department.delete',
    },
    Designation: {
      List: 'configuration.designation.list',
      Create: 'configuration.designation.create',
      Update: 'configuration.designation.update',
      Delete: 'configuration.designation.delete',
    },
    Location: {
      List: 'configuration.location.list',
      Create: 'configuration.location.create',
      Update: 'configuration.location.update',
      Delete: 'configuration.location.delete',
    },
    Competency: {
      List: 'configuration.competency.list',
      Create: 'configuration.competency.create',
      Update: 'configuration.competency.update',
      Delete: 'configuration.competency.delete',
    },
    BusinessUnit: {
      List: 'configuration.business_unit.list',
      Create: 'configuration.business_unit.create',
      Update: 'configuration.business_unit.update',
      Delete: 'configuration.business_unit.delete',
    },
    Division: {
      List: 'configuration.division.list',
      Create: 'configuration.division.create',
      Update: 'configuration.division.update',
      Delete: 'configuration.division.delete',
    },
    ProjectRoles: {
      List: 'configuration.project_roles.list',
      Create: 'configuration.project_roles.create',
      Update: 'configuration.project_roles.update',
      Delete: 'configuration.project_roles.delete',
    },
    Skills: {
      List: 'configuration.skills.list',
      Create: 'configuration.skills.create',
      Update: 'configuration.skills.update',
      Delete: 'configuration.skills.delete',
    },
    EmploymentTypes: {
      List: 'configuration.employment_types.list',
      Create: 'configuration.employment_types.create',
      Update: 'configuration.employment_types.update',
      Delete: 'configuration.employment_types.delete',
    },
    Roles: {
      List: 'configuration.roles.list',
      AssignUser: 'configuration.roles.assign_user',
      RemoveUser: 'configuration.roles.remove_user',
    },
  },
  PerformanceManagement: {
    PerformanceCycle: {
      View: 'performance_management.performance_cycle.list',
      Create: 'performance_management.performance_cycle.create',
      Update: 'performance_management.performance_cycle.update',
      Delete: 'performance_management.performance_cycle.delete',
      Close: 'performance_management.performance_cycle.close',
      Employee: {
        View: 'performance_management.performance_cycle.employee.list',
        Assign: 'performance_management.performance_cycle.employee.assign',
        Remove: 'performance_management.performance_cycle.employee.remove',
      },
      Evaluation: {
        View: 'performance_management.performance_cycle.evaluation.view',
        SubmitForApproval:
          'performance_management.performance_cycle.evaluation.submit_for_approval',
        Approve: 'performance_management.performance_cycle.evaluation.approve',
        Reopen: 'performance_management.performance_cycle.evaluation.reopen',
        markAsFinal: 'performance_management.performance_cycle.evaluation.mark_as_final',
      },
    },
    Reports: {
      Goals: 'performance_management.reports.goals',
      Evaluation: 'performance_management.reports.evaluation',
    },
  },
  ResourcePlanner: {
    TransientResource: {
      View: 'resource_planner.transient_resource.view',
      Create: 'resource_planner.transient_resource.create',
      Update: 'resource_planner.transient_resource.update',
      Delete: 'resource_planner.transient_resource.delete',
    },
    Reports: {
      ResourceDeficit: {
        View: 'resource_planner.reports.resource_deficit.view',
      },
      Summary: {
        Resources: {
          View: 'resource_planner.reports.summary.resources.view',
        },
        UnPlannedResources: {
          View: 'resource_planner.reports.summary.unplanned_resources.view',
        },
        ResourcesStatusWise: {
          View: 'resource_planner.reports.summary.resources_status_wise.view',
        },
      },
      Bench: {
        Resources: {
          View: 'resource_planner.reports.bench.resources.view',
        },
      },
    },
    Sync: 'resource_planner.sync',
    Plan: 'resource_planner.plan',
    Clients: 'resource_planner.clients',
    DealsName: 'resource_planner.deals_name',
    DealsOwners: 'resource_planner.deals_owners',
    DealResourceStatus: 'resource_planner.deal_resource_status',
    Deal: {
      Summary: {
        View: 'resource_planner.deal.summary.view',
        List: 'resource_planner.deal.summary.list',
      },
      View: 'resource_planner.deal.view',
      Requirements: {
        List: 'resource_planner.deal.requirements.list',
        Create: 'resource_planner.deal.requirements.create',
        Update: 'resource_planner.deal.requirements.update',
        Delete: 'resource_planner.deal.requirements.delete',
        Suggestions: {
          Create: 'resource_planner.deal.requirements.suggestions.create',
          List: 'resource_planner.deal.requirements.suggestions.list',
          Update: 'resource_planner.deal.requirements.suggestions.update',
        },
      },
    },
  },
};
