import {
  EnhancedIcon,
  EnhancedAvatar,
  EnhancedMenu,
  EnhancedDropdown,
  EnhancedBadge,
} from 'components/shared/antd';
import React from 'react';
import { connect } from 'react-redux';
import * as Colors from 'constants/ColorTypes';
import DefaultUserImage from 'images/user-default.png';
import { leaveStatus } from 'configs/leaveConstants';
import { TEAM_LEAVE_REQUEST } from 'configs/routesConstants';

class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allowedKeys: ['logout', 'profile'],
    };
  }

  onClick = ({ key }) => {
    if (this.state.allowedKeys.includes(key)) this.props.history.push(`/${key}`);
  };

  render() {
    const { user, collapsed, teamLeaves } = this.props;
    const username = user && user.fullName;
    const userImage = user && user.userImage;
    const pendingCount = teamLeaves.filter((leave) => leave.status === leaveStatus.PENDING).length;
    const menu = (
      <EnhancedMenu onClick={this.onClick}>
        <EnhancedMenu.Item key="profile">
          <EnhancedIcon type="user" />
          <span style={{ paddingLeft: 20 }}>My Profile</span>
        </EnhancedMenu.Item>
        <EnhancedMenu.Item key="logout">
          <EnhancedIcon type="logout" />
          <span style={{ paddingLeft: 20 }}>Logout</span>
        </EnhancedMenu.Item>
      </EnhancedMenu>
    );

    const pendingCountDropDown = (
      <EnhancedMenu>
        <EnhancedMenu.Item>
          <span
            onClick={() => this.props.history.push(TEAM_LEAVE_REQUEST)}
            onKeyPress={() => this.props.history.push(TEAM_LEAVE_REQUEST)}
            style={{ padding: 8 }}
            role="button"
            tabIndex={0}
          >
            {pendingCount} leaves Pending for Approval
          </span>
        </EnhancedMenu.Item>
      </EnhancedMenu>
    );

    return (
      <div className={collapsed ? 'user-fold' : 'user-unfold'}>
        {user && user.isManager && (
          <EnhancedDropdown overlay={pendingCountDropDown} trigger={['click', 'hover']}>
            <EnhancedBadge count={pendingCount} className="badge">
              <EnhancedIcon type="bell" style={{ fontSize: '23px', paddingRight: '4%' }} />
            </EnhancedBadge>
          </EnhancedDropdown>
        )}
        <span className="header-user-info">
          <EnhancedAvatar
            className="profile-img user-detail-img"
            src={userImage || DefaultUserImage}
          />
          <span>
            <EnhancedDropdown overlay={menu}>
              <div
                className="ant-dropdown-link"
                style={{ paddingLeft: 10, color: Colors.MAIN_BACKGROUND_THEME_COLOR }}
              >
                {username} <EnhancedIcon type="down" />
              </div>
            </EnhancedDropdown>
          </span>
        </span>
      </div>
    );
  }
}

function mapStateToProps({ auth, teamLeavesReducer }) {
  return {
    user: auth.user,
    teamLeaves: teamLeavesReducer.teamLeaves,
  };
}

export default connect(mapStateToProps)(UserDetails);
