import {
  EnhancedCol,
  EnhancedRow,
  EnhancedCard,
  EnhancedAutoComplete,
} from 'components/shared/antd';
import React from 'react';
import './employeeDirectory.css';
import Permissions from '../../constants/AccessPermissions';

import Authorize from '../Authorize';

// const { Option } = EnhancedAutoComplete;

const EmployeeOrgTreeHeader = (props) => {
  const { name, onAutoCompleteSelect, onAutoCompleteSearch, filter, isLoading } = props;
  return (
    <EnhancedCard
      style={{
        paddingBottom: 0,
        borderBottom: '3px solid #eee',
      }}
      bordered={false}
    >
      <EnhancedRow>
        <EnhancedCol lg={10}>
          <h2 className="heading-res" style={{ marginBottom: '0', color: '#000' }}>
            {name}
          </h2>
        </EnhancedCol>
        <EnhancedCol lg={{ offset: 8, span: 6 }}>
          {!isLoading && (
            <Authorize requiredPermissions={[Permissions.Employee.OrgChart.View]}>
              <EnhancedAutoComplete
                options={
                  filter &&
                  filter.map((employee) => {
                    return {
                      label: employee.name,
                      value: employee.id,
                    };
                  })
                }
                style={{ width: 249 }}
                onSelect={onAutoCompleteSelect}
                onSearch={onAutoCompleteSearch}
                placeholder="Search Name"
              >
                {/* {filter &&
                  filter.map((employee) => (
                    <Option key={employee.id} value={`${employee.id}`}>
                      {employee.name}
                    </Option>
                  ))} */}
              </EnhancedAutoComplete>
            </Authorize>
          )}
        </EnhancedCol>
      </EnhancedRow>
    </EnhancedCard>
  );
};

export default EmployeeOrgTreeHeader;
