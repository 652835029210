import { EnhancedRow, EnhancedCard, EnhancedSelect } from 'components/shared/antd';
import React from 'react';
// import { noData } from 'utils/FieldGenerateUtils';
import moment from 'moment';
import { DATETIME_FORMAT, ROW_GUTTER_SIZE } from 'configs/employeeProfileConstants';

import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';

const { Option } = EnhancedSelect;

class HistoryLog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sortedOrder: 'DESC',
    };

    this.sortingOptions = [
      { value: 'DESC', label: 'Latest' },
      { value: 'ASC', label: 'Oldest' },
    ];

    this.columns = [
      {
        key: 'category',
        label: 'Category',
        filterConfig: {
          type: 'search',
          key: 'category',
        },
      },
      {
        key: 'eventType',
        label: 'Event Type',
        filterConfig: {
          type: 'search',
          key: 'eventType',
        },
      },
      { key: 'fields', label: 'Fields' },
      {
        key: 'updatedBy',
        label: 'Updated By',
        filterConfig: {
          type: 'search',
          key: 'updatedBy',
        },
      },
      { key: 'createdAt', label: 'Date' },
    ];
  }

  componentDidMount() {
    const employeeId = this.props.match.params.id;
    this.props.getEmployeeHistoryLogs(employeeId);
  }

  getSortingDropdown = () => (
    <React.Fragment>
      <b className="sort-label">Sort by:</b>
      <EnhancedSelect
        defaultValue={this.state.sortedOrder}
        className="field-select"
        onChange={this.handleSorting}
      >
        {this.sortingOptions.map((option) => (
          <Option key={option.value} value={option.value}>
            {`${option.label}`}
          </Option>
        ))}
      </EnhancedSelect>
    </React.Fragment>
  );

  getColumnsInfo = () => {
    const { sortedOrder } = this.state;
    const columnsToRender = [];
    this.columns.forEach((col) => {
      const columnObj = {
        title: col.label,
        dataIndex: col.key,
        key: col.key,
        render: (value) => this.renderCustomCell(value, col.key),
        width: 300,
        sortOrder: col.key === 'createdAt' && sortedOrder,
      };

      if (col.filterConfig) {
        columnObj.filterConfig = col.filterConfig;
      }
      columnsToRender.push(columnObj);
    });
    return columnsToRender;
  };

  getTable = (auditLogs) => {
    const { isLoading } = this.props;
    const data = auditLogs;
    const columns = this.getColumnsInfo();

    return (
      <EnhancedClientSideTable
        loading={isLoading}
        columns={columns}
        data={data}
        scroll={{ x: 1300, y: 500 }}
      />
    );
  };

  sortData = () => {
    if (this.props.auditLogs) {
      const data = [...this.props.auditLogs];
      const { sortedOrder } = this.state;
      if (sortedOrder === 'DESC') {
        data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
      } else {
        data.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
      }
      return data;
    }
    return [];
  };

  handleSorting = (sortedOrder) => {
    this.setState({ sortedOrder });
  };
  renderCustomCell = (value, key) => {
    let valueData = value || '';
    if (key === 'fields') {
      valueData = value.join(', ');
    } else if (key === 'createdAt') {
      valueData = moment(value).format(`${DATETIME_FORMAT}`);
    }
    return (
      <ul className="audit-log-ul">
        <li>{valueData}</li>
      </ul>
    );
  };

  renderField = (fieldValue) => {
    return <div className="table-field-value">{fieldValue}</div>;
  };

  render() {
    const { isLoading } = this.props;
    const auditLogsData = this.sortData();
    return (
      <EnhancedCard className="mt-20" loading={isLoading} bordered={false}>
        <EnhancedRow className="flex justify-flex-end audit-log-sorter" gutter={ROW_GUTTER_SIZE}>
          {auditLogsData.length && this.getSortingDropdown()}
        </EnhancedRow>
        {this.getTable(auditLogsData)}
      </EnhancedCard>
    );
  }
}

export default HistoryLog;
