import { connect } from 'react-redux';

import ProjectReviews from 'components/pc/ProjectReviews';

import { getProjectReviewsCycleAction } from 'store/actions/ProjectReviewsActions';

function mapStateToProps(state) {
  const { projectReviewsReducer } = state;
  return {
    projectReviews: projectReviewsReducer.projectReviews,
    isLoading: projectReviewsReducer.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectReviewsCycleAction: () => dispatch(getProjectReviewsCycleAction()),
  };
}

const ProjectReviewsContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectReviews);

export default ProjectReviewsContainer;
