import React from 'react';
import { Breadcrumb } from 'antd';
import PropTypes from 'prop-types';

const EnhancedBreadcrumb = (props) => {
  const { children, style } = props;

  return <Breadcrumb style={style}>{children}</Breadcrumb>;
};

EnhancedBreadcrumb.propTypes = {
  style: PropTypes.shape({}),
};

EnhancedBreadcrumb.defaultProps = {
  style: {},
};

export default EnhancedBreadcrumb;
