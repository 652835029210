import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedIcon,
  EnhancedTooltip,
  EnhancedModal,
} from 'components/shared/antd';
import React from 'react';
import moment from 'moment';
import {
  ACTIONS,
  CREATED_AT,
  DATE_FORMAT,
  SELECT,
  VALUE_SIZE_XS,
  ROW_GUTTER_SIZE,
  documentTypes,
} from 'configs/employeeProfileConstants';
import Permissions from 'constants/AccessPermissions';
import { hasPermission } from 'utils/AccessUtils';
import { REQUIRED } from 'configs/validationConstants';
import { noData, shouldSort, createFields } from 'utils/FieldGenerateUtils';
import { createErrorMessage } from 'utils/validationUtils';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';

const { confirm } = EnhancedModal;

class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.documentRef = React.createRef();
    this.documentURL = null;
    this.isDocumentFetching = false;
    this.state = {
      sortedInfo: {
        columnKey: 'createdAt',
        field: 'createdAt',
        order: 'descend',
      },
      showUploadForm: false,
      errors: {
        uploadFile: '',
      },
      editDocumentVisible: false,
      clickedDocument: {},
      editPayload: {},
    };
    this.uploadedFiles = [];

    this.columns = [
      {
        key: 'name',
        label: 'Document Name',
        filterConfig: {
          type: 'search',
          key: 'name',
        },
      },
      {
        key: 'type',
        label: 'Document Type',
        filterConfig: {
          type: 'search',
          key: 'type',
        },
      },
      { key: 'createdAt', label: 'Date' },
      { key: ACTIONS, label: 'Actions' },
    ];
  }

  componentDidMount() {
    const employeeId = this.props.match.params.id;
    this.props.getEmployeeDocuments(employeeId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.employeeDocumentUrl && this.isDocumentFetching) {
      this.isDocumentFetching = false;
      this.documentRef.current.href = nextProps.employeeDocumentUrl;
      this.documentRef.current.click();
    }

    if (this.props.documents !== nextProps.documents) {
      if (this.state.showUploadForm) {
        this.handleCancel();
      }
    }
  }

  getDocumentUrl = (document) => {
    const employeeId = this.props.match.params.id;
    this.documentURL = null;
    this.isDocumentFetching = true;
    this.props.getEmployeeDocumentURL(employeeId, document.id);
  };

  getColumnsInfo = () => {
    let { sortedInfo } = this.state;

    sortedInfo = sortedInfo || {};
    const columnsToRender = [];

    this.columns.forEach((col) => {
      const columnObj = {
        title: col.label,
        dataIndex: col.key,
        key: col.key,
        render: (_, data) => this.renderField(data, data[col.key], col.key),
        width: 'auto',
        sorter: shouldSort(col.key) ? (a, b) => this.sortField(a, b, col.key) : false,
        sortOrder: sortedInfo.columnKey === col.key && sortedInfo.order,
      };

      if (col.filterConfig) {
        columnObj.filterConfig = col.filterConfig;
      }

      columnsToRender.push(columnObj);
    });

    return columnsToRender;
  };

  getTable = (documents) => {
    const columns = this.getColumnsInfo();

    return (
      <EnhancedClientSideTable
        columns={columns}
        data={documents}
        onChange={this.handleChange}
        scroll={{ x: 400 }}
      />
    );
  };

  handleFileUpload = (evt) => {
    // eslint-disable-next-line
    for (let i = 0; i < evt.target.files.length; i++) {
      const file = evt.target.files[i];
      const reader = new FileReader();
      reader.onload = this.hanldeFileLoad;
      reader.fileName = file.name;
      reader.readAsDataURL(file);
    }
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({ sortedInfo: sorter });
  };

  sortField = (a, b, key = null) => {
    switch (key) {
      case CREATED_AT:
        return new Date(a[key]) - new Date(b[key]);
      case ACTIONS:
        return null;
      default:
        return a[key].localeCompare(b[key]);
    }
  };

  handleCancel = () => {
    this.uploadedFiles = [];
    this.setState({
      showUploadForm: false,
      errors: {
        uploadFile: '',
      },
    });
  };

  handleEdit = () => {
    this.setState({
      showUploadForm: true,
    });
  };

  hanldeFileLoad = (evt) => {
    const base64 = evt.target.result;
    const { fileName } = evt.target;

    const splitedFileName = fileName.split('.')[0];

    this.uploadedFiles.push({
      fileName: splitedFileName,
      fileContent: base64,
    });

    if (this.state.errors.uploadFile) {
      this.validateFields();
    }
  };

  validateFields = () => {
    const documentError = createErrorMessage({
      value: this.uploadedFiles.length > 0,
      validations: [REQUIRED],
    });

    this.setState({
      errors: {
        uploadFile: documentError,
      },
    });
  };

  handleSave = () => {
    const { errors } = this.state;
    this.validateFields();

    if (this.uploadedFiles.length > 0 && !errors.uploadFile) {
      const employeeId = this.props.match.params.id;

      this.props.uploadEmployeeDocument(this.uploadedFiles, employeeId);
      this.uploadedFiles = [];
    }
  };

  handleDelete = (document) => {
    confirm({
      title: 'Are you sure you want to delete this document?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        const employeeId = this.props.match.params.id;
        this.props.deleteEmployeeDocument(employeeId, document.id);
      },
    });
  };

  editDocumentFormChangeHandler = (value, key) => {
    this.setState({
      editPayload: {
        [key]: value,
      },
    });
  };

  closeEditModal = () => {
    this.setState({
      editDocumentVisible: false,
      clickedDocument: {},
      editPayload: {},
    });
  };

  editSubmissionHandler = () => {
    const { editPayload, clickedDocument } = this.state;
    this.props.updateEmployeeDocument({
      payload: editPayload,
      employeeId: this.props.match.params.id,
      documentId: clickedDocument.id,
    });
    this.closeEditModal();
  };

  documentEditModal = () => {
    const { editDocumentVisible, clickedDocument, editPayload } = this.state;
    return (
      <EnhancedModal
        title="Edit Document"
        visible={editDocumentVisible}
        closable={false}
        footer={[
          <EnhancedButton key="back" onClick={this.closeEditModal}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            key="submit"
            type="primary"
            disabled={false}
            onClick={this.editSubmissionHandler}
          >
            Submit
          </EnhancedButton>,
        ]}
      >
        <React.Fragment>
          {createFields(
            [
              {
                key: 'type',
                label: 'Document Type',
                options: Object.keys(documentTypes).map((type) => ({ key: type, value: type })),
                value: editPayload.type || clickedDocument.type,
                type: SELECT,
              },
            ],
            1,
            true,
            [],
            this.editDocumentFormChangeHandler,
          )}
        </React.Fragment>
      </EnhancedModal>
    );
  };

  handleEditDocument = (document) => {
    this.setState({
      editDocumentVisible: true,
      clickedDocument: document,
      editPayload: {
        type: document.type,
      },
    });
  };

  renderCreateDocumentForm = () => {
    const { errors } = this.state;

    return (
      <EnhancedCard className="mb-10 mt-20 upload-document" title="Upload Document">
        <EnhancedRow gutter={ROW_GUTTER_SIZE}>
          <EnhancedCol xs={VALUE_SIZE_XS} md={8}>
            <input
              type="file"
              id="element"
              accept="application/pdf,image/png,image/jpeg,.doc,.docx"
              onChange={this.handleFileUpload}
              multiple
            />
            {errors.uploadFile ? errors.uploadFile : null}
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow gutter={ROW_GUTTER_SIZE}>
          <EnhancedCol xs={24} md={24}>
            <div className="actions">
              <EnhancedButton className="btn-actions" onClick={this.handleSave}>
                Save
              </EnhancedButton>
              <EnhancedButton className="btn-actions" onClick={this.handleCancel}>
                Cancel
              </EnhancedButton>
            </div>
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedCard>
    );
  };

  renderField = (data, fieldValue, key = null) => {
    const { userPermissions } = this.props;
    const canDelete = hasPermission(userPermissions, [
      Permissions.Employee.Profile.Documents.Delete,
    ]);
    const canEdit = hasPermission(userPermissions, [Permissions.Employee.Profile.Documents.Update]);
    const canDownload =
      data.type === documentTypes.Open ||
      (data.type === documentTypes.Restricted &&
        hasPermission(userPermissions, [
          Permissions.Reportee.Profile.Compensation.View,
          Permissions.Employee.Profile.Compensation.View,
        ]));

    switch (key) {
      case ACTIONS:
        return (
          <div className="actions-wrapper">
            {canDownload && (
              <EnhancedTooltip placement="top" title="Download">
                <EnhancedIcon
                  className="table-download-link"
                  type="download"
                  onClick={() => this.getDocumentUrl(data)}
                />
              </EnhancedTooltip>
            )}
            {canDelete && (
              <EnhancedTooltip placement="top" title="Delete">
                <EnhancedIcon
                  className="table-download-link"
                  type="delete"
                  onClick={() => this.handleDelete(data)}
                />
              </EnhancedTooltip>
            )}
            {canEdit && (
              <EnhancedTooltip placement="top" title="Edit">
                <EnhancedIcon
                  className="table-download-link"
                  type="edit"
                  onClick={() => this.handleEditDocument(data)}
                />
              </EnhancedTooltip>
            )}
          </div>
        );
      case CREATED_AT:
        return (
          <div className="table-field-value">
            {fieldValue ? moment(fieldValue).format(DATE_FORMAT) : 'N/A'}
          </div>
        );
      default:
        return <div className="table-field-value">{fieldValue}</div>;
    }
  };

  render() {
    const { isLoading, userPermissions } = this.props;
    const documents = this.props.documents || [];
    const canCreate = hasPermission(userPermissions, [
      Permissions.Employee.Profile.Documents.Create,
    ]);
    return (
      <EnhancedCard loading={isLoading} bordered={false}>
        {!isLoading && canCreate && (
          <EnhancedRow gutter={ROW_GUTTER_SIZE}>
            <EnhancedCol xs={24} md={24} className="upload-document-btn">
              <EnhancedButton className="actions btn-create" onClick={this.handleEdit}>
                <EnhancedIcon className="table-download-link" type="upload" />
                <span>Upload</span>
              </EnhancedButton>
            </EnhancedCol>
          </EnhancedRow>
        )}
        {this.state.showUploadForm && this.renderCreateDocumentForm()}
        <EnhancedCard className="mt-20 documents-table" bordered={false}>
          <a
            target="_blank"
            href={this.documentURL}
            ref={this.documentRef}
            style={{ display: 'none' }}
          >
            link
          </a>
          {documents.length ? this.getTable(documents) : noData()}
        </EnhancedCard>
        {this.documentEditModal()}
      </EnhancedCard>
    );
  }
}

export default Documents;
