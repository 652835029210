import { EnhancedSelect } from 'components/shared/antd';
import React, { useState, useEffect } from 'react';

const { Option } = EnhancedSelect;

const PMCycleDropdown = (props) => {
  const { pmCycles, onChange } = props;
  const [selectedCycle, setSelectedCycle] = useState('Select Cycle');

  useEffect(() => {
    if (pmCycles && pmCycles.rows) {
      setSelectedCycle(pmCycles.rows[0].title);
      onChange(pmCycles.rows[0].id);
    }
  }, [pmCycles]);

  const onChangeHandler = (id, value) => {
    if (onChange && value.props.children) {
      onChange(id);
      setSelectedCycle(value.props.children || '');
    }
  };

  return (
    <React.Fragment>
      <span className="mr-10">Performance Cycle:</span>
      <EnhancedSelect
        style={{
          width: 260,
        }}
        className="action-selection-dropdown"
        placeholder="Select Cycle"
        value={selectedCycle}
        onChange={(a, b) => onChangeHandler(a, b)}
        optionFilterProp="children"
        showSearch
      >
        {pmCycles &&
          pmCycles.rows &&
          pmCycles.rows.length > 0 &&
          pmCycles.rows.map((cycle) => (
            <Option value={cycle.id} key={cycle.id}>
              {cycle.title}
            </Option>
          ))}
      </EnhancedSelect>
    </React.Fragment>
  );
};

export default PMCycleDropdown;
