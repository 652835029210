import { EnhancedButton } from 'components/shared/antd';
import React from 'react';

import PropTypes from 'prop-types';
import addIcon from 'images/add-icon.svg';

const addButton = (props) => {
  const { clickHandler, buttonText, disabled, buttonClasses } = props;
  return (
    <EnhancedButton
      onClick={clickHandler}
      className={`btn-create ${buttonClasses.join(' ')}`}
      disabled={disabled}
    >
      <img src={addIcon} alt="add icon" />
      <span>{buttonText}</span>
    </EnhancedButton>
  );
};

addButton.defaultProps = {
  disabled: false,
  buttonClasses: [],
};

addButton.PropTypes = {
  clickHandler: PropTypes.func,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  buttonClasses: PropTypes.array,
};

export default addButton;
