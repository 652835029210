import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedSpin,
  EnhancedPagination,
  EnhancedLayout,
  EnhancedSelect,
} from 'components/shared/antd';
import React from 'react';
import moment from 'moment';
import DefaultersList from 'components/DefaultersList';
import 'components/Defaulters.css';
import DateRangePicker from './DateRangePicker';

const { Header } = EnhancedLayout;
const { Option } = EnhancedSelect;

class Defaulers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 40,
      defaulters: props.defaulters,
      selectedEmployees: [],
      selectedManagers: [],
    };
  }

  componentDidMount() {
    const resolution = 'month';
    const startDate = moment().add(0, resolution).startOf(resolution);
    const endDate = moment().add(0, resolution).endOf(resolution);
    this.loadData(startDate, endDate);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.defaulters !== nextProps.defaulters) {
      this.setState({ defaulters: nextProps.defaulters });
    }
  }

  // onClose = () => {
  //   this.setState({ errorText: '' });
  // }

  onPageChange = (page) => {
    this.setState({ page });
  };

  onSearch = (value) => {
    this.setState({ selectedEmployees: value }, () => this.applyFilters());
  };

  onSearchManager = (value) => {
    this.setState({ selectedManagers: value }, () => this.applyFilters());
  };

  handleClearFilters = () => {
    this.setState(
      {
        defaulters: this.props.defaulters,
        selectedEmployees: [],
        selectedManagers: [],
        page: 1,
      },
      () => this.applyFilters(),
    );
  };

  applyFilters = () => {
    const { selectedManagers, selectedEmployees } = this.state;
    let defaulters = this.props.defaulters.slice();

    if (selectedManagers.length) {
      defaulters = defaulters.filter((employee) => selectedManagers.includes(employee.manager));
    }

    if (selectedEmployees.length) {
      defaulters = defaulters.filter((employee) => selectedEmployees.includes(employee.name));
    }

    this.setState({
      defaulters,
      page: 1,
    });
  };

  loadData = (startDate, endDate) => {
    this.props.getDefaulters(startDate, endDate);
  };

  showTotal = (total) => `Total ${total} items`;

  renderEmployees() {
    const { employees } = this.props;
    return employees ? employees.map((p) => <Option key={p}>{p}</Option>) : [];
  }

  renderManagers() {
    const { managers } = this.props;
    return managers ? managers.map((p) => <Option key={p}>{p}</Option>) : [];
  }

  render() {
    const { page, pageSize, defaulters, selectedManagers, selectedEmployees } = this.state;

    let content = (
      <EnhancedRow>
        <EnhancedSpin className="spin-with-bg" />
      </EnhancedRow>
    );
    if (!this.props.isLoading) {
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      content = <DefaultersList defaulters={defaulters.slice(startIndex, endIndex)} />;
    }
    return (
      <div>
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol span={10}>
              <h1>Missing Allocations</h1>
            </EnhancedCol>
            <EnhancedCol lg={14} md={14} sm={24} xs={24}>
              <DateRangePicker
                loadData={this.loadData}
                downloadDisabled={!this.props.defaulters || !this.props.defaulters.length}
                downloadClear={this.handleClearFilters}
                data={this.props.defaulters}
                filename="missing-allocations"
              />
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        <div className="project-content">
          <EnhancedRow>
            <EnhancedCol
              offset={11}
              span={6}
              style={{ textAlign: 'right', marginBottom: '2%', paddingRight: '20px' }}
            >
              <EnhancedSelect
                mode="multiple"
                placeholder="Managers"
                style={{ width: '100%' }}
                onChange={this.onSearchManager}
                value={selectedManagers}
                tokenSeparators={[',']}
                allowClear
              >
                {this.renderManagers()}
              </EnhancedSelect>
            </EnhancedCol>
            <EnhancedCol span={6} style={{ textAlign: 'right', marginBottom: '2%' }}>
              <EnhancedSelect
                mode="multiple"
                placeholder="Employees"
                style={{ width: '100%' }}
                onChange={this.onSearch}
                value={selectedEmployees}
                tokenSeparators={[',']}
                allowClear
              >
                {this.renderEmployees()}
              </EnhancedSelect>
            </EnhancedCol>
            <EnhancedCol span={1}>
              <EnhancedButton
                type="primary"
                shape="circle"
                icon="close"
                disabled={!selectedEmployees.length && !selectedManagers.length}
                onClick={this.handleClearFilters}
                style={{ float: 'right', marginBottom: '2%' }}
              />
            </EnhancedCol>
          </EnhancedRow>
          {content}
          <EnhancedRow>
            <EnhancedCol span={12} offset={12} style={{ textAlign: 'right', marginTop: '2%' }}>
              <EnhancedPagination
                size="small"
                pageSize={pageSize}
                total={defaulters.length}
                showTotal={this.showTotal}
                onChange={this.onPageChange}
                current={page || 1}
              />
            </EnhancedCol>
          </EnhancedRow>
        </div>
      </div>
    );
  }
}

export default Defaulers;
