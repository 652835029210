export const GET_GOAL_INIT = 'GET_GOAL_INIT';
export const GET_GOAL_SUCCESS = 'GET_GOAL_SUCCESS';
export const GET_GOAL_FAILURE = 'GET_GOAL_FAILURE';

export const MANAGER_ADD_GOAL_INIT = 'MANAGER_ADD_GOAL_INIT';
export const MANAGER_ADD_GOAL_SUCCESS = 'MANAGER_ADD_GOAL_SUCCESS';
export const MANAGER_ADD_GOAL_FAILURE = 'MANAGER_ADD_GOAL_FAILURE';

export const GET_GOAL_DETAILS_INIT = 'GET_GOAL_DETAILS_INIT';
export const GET_GOAL_DETAILS_SUCCESS = 'GET_GOAL_DETAILS_SUCCESS';
export const GET_GOAL_DETAILS_FAILURE = 'GET_GOAL_DETAILS_FAILURE';

export const UPDATE_GOAL_INIT = 'UPDATE_GOAL_INIT';
export const UPDATE_GOAL_SUCCESS = 'UPDATE_GOAL_SUCCESS';
export const UPDATE_GOAL_FAILURE = 'UPDATE_GOAL_FAILURE';

export const UPDATE_GOAL_STATUS_INIT = 'UPDATE_GOAL_STATUS_INIT';
export const UPDATE_GOAL_STATUS_SUCCESS = 'UPDATE_GOAL_STATUS_SUCCESS';
export const UPDATE_GOAL_STATUS_FAILURE = 'UPDATE_GOAL_STATUS_FAILURE';

export const DELETE_GOAL_INIT = 'DELETE_GOAL_INIT';
export const DELETE_GOAL_SUCCESS = 'DELETE_GOAL_SUCCESS';
export const DELETE_GOAL_FAILURE = 'DELETE_GOAL_FAILURE';
