import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import { leavesStatusesUrls } from 'configs/constants';
import { getResponseError } from 'utils/sourceUtils';
import { normalizeLeavesStatuses } from 'normalizers/leaveNormalizers';

export const requestLeavesStatusesSource = () =>
  new Promise((resolve, reject) => {
    const url = leavesStatusesUrls.GET;
    axios
      .get(url)
      .then((response) => {
        if (response && response.data.success) {
          const leavesStatuses = normalizeLeavesStatuses(response.data.data);
          resolve(leavesStatuses);
        } else {
          EnhancedNotification.error({
            message: getResponseError(response.message),
          });
          reject(response.message);
        }
      })
      .catch((err) => {
        EnhancedNotification.error({
          message: getResponseError(err),
        });
        reject(err);
      });
  });

export const newFunc = {};
