import {
  GET_RCA_PROJECTS_INIT,
  GET_RCA_PROJECTS_SUCCESS,
  GET_RCA_PROJECTS_FAILURE,
} from 'constants/RcaProjectsTypes';

import { getAllocationGroupedProjects } from 'sources/ProjectSource';

// normal actions
export const getRcaProjectsInit = () => ({
  type: GET_RCA_PROJECTS_INIT,
  loader: true,
});

export const getRcaProjectsSuccess = (data) => ({
  type: GET_RCA_PROJECTS_SUCCESS,
  data,
});

export const getRcaProjectsFailure = (data) => ({
  type: GET_RCA_PROJECTS_FAILURE,
  data,
});
export const getRcaProjectsActions = (startDate, endDate) =>
  getAllocationGroupedProjects(startDate, endDate);
