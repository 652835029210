import { EnhancedPopover, EnhancedTag } from 'components/shared/antd';
import React, { Component } from 'react';
import moment from 'moment';
import {
  claimIdToStatus,
  claimApproveOrder,
  claimApproveOrderTitle,
  claimStatus,
  dateFormat,
} from 'configs/constants';

class StatusTag extends Component {
  getDate = (status) => {
    let dateWithTitle = null;
    const date = moment(status.updatedAt).format(dateFormat.DDMMMYYYYwithSpace);
    if (status.statusId === claimStatus.APPROVED) {
      dateWithTitle = <p>{`Approved Date: ${date}`}</p>;
    } else if (status.statusId === claimStatus.DECLINED) {
      dateWithTitle = <p>{`Declined Date: ${date}`}</p>;
    } else {
      dateWithTitle = null;
    }
    return dateWithTitle;
  };

  statusTagColor = (statusDto) => {
    const { statuses } = this.props;
    let color = null;
    if (statusDto.approveOrder === 2) {
      if (statusDto.statusId === claimStatus.APPROVED) {
        color = this.color.green;
      } else if (statusDto.statusId === claimStatus.DECLINED) {
        color = this.color.red;
      } else if (statuses[0].statusId === claimStatus.APPROVED) {
        color = this.color.darkGray;
      } else {
        color = this.color.lightGray;
      }
    } else {
      if (statusDto.statusId === claimStatus.WAITING_FOR_APPROVAL) {
        color = this.color.lightGray;
      }
      if (statusDto.statusId === claimStatus.PENDING) {
        color = this.color.darkGray;
      }
      if (statusDto.statusId === claimStatus.APPROVED) {
        color = this.color.green;
      }
      if (statusDto.statusId === claimStatus.DECLINED) {
        color = this.color.red;
      }
    }
    return color;
  };

  color = {
    lightGray: '#d9d9da',
    darkGray: '#808080',
    green: '#87d068',
    red: '#ee2938',
  };

  render() {
    const { statuses } = this.props;
    return statuses.map((status) => {
      const content = (
        <div>
          {this.getDate(status)}
          <p style={{ width: '200px', marginTop: '0px' }}>
            Comments:{status.comments !== '' ? ` ${status.comments}` : ' Not available'}
          </p>
        </div>
      );
      return (
        <EnhancedPopover
          content={content}
          title={claimApproveOrderTitle[status.approveOrder] + claimIdToStatus[status.statusId]}
          trigger="hover"
        >
          <EnhancedTag color={this.statusTagColor(status)} key={status.approveOrder}>
            {claimApproveOrder[status.approveOrder]}
          </EnhancedTag>
        </EnhancedPopover>
      );
    });
  }
}

export default StatusTag;
