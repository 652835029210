import {
  EnhancedCol,
  EnhancedButton,
  EnhancedIcon,
  EnhancedCard,
  EnhancedRow,
  EnhancedDivider,
  EnhancedLayout,
  EnhancedModal,
  EnhancedInput,
} from 'components/shared/antd';
import React, { Component, Fragment } from 'react';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import Authorize from 'components/Authorize';
import Permission from 'constants/AccessPermissions';

const { confirm } = EnhancedModal;
const { Header } = EnhancedLayout;

class EmploymentTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editVisible: false,
      addVisible: false,
      employmentType: '',
      employmentTypePrefix: '',
      editEmploymentType: '',
      editEmploymentTypePrefix: '',
      selectedRecord: null,
      employmentTypes: this.props.employmentTypes || [],
    };
  }

  componentDidMount() {
    this.props.getEmploymentTypesAction();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.employmentTypes !== this.props.employmentTypes) {
      this.setState({ employmentTypes: nextProps.employmentTypes });
    }
  }

  getColumnsInfo = () => {
    const columns = [
      {
        title: 'Employment Type',
        dataIndex: 'name',
        key: 'name',
        width: '10%',
        sorter: (a, b) => a.name.localeCompare(b.name),
        filterConfig: {
          type: 'search',
          key: 'name',
        },
      },
      {
        title: 'Prefix',
        dataIndex: 'prefix',
        key: 'prefix',
        width: '10%',
      },
      {
        title: 'Action',
        key: 'action',
        width: '5%',
        render: (text, record) => (
          <span>
            <Authorize requiredPermissions={[Permission.Configuration.EmploymentTypes.Update]}>
              <EnhancedIcon
                type="edit"
                style={{ cursor: 'pointer' }}
                onClick={() => this.showEditEmploymentTypeModal(record)}
              />
            </Authorize>
            <EnhancedDivider type="vertical" />
            <Authorize requiredPermissions={[Permission.Configuration.EmploymentTypes.Delete]}>
              <EnhancedIcon
                type="delete"
                style={{ cursor: 'pointer' }}
                onClick={() => this.showDeleteEmploymentTypeModal(record)}
              />
            </Authorize>
          </span>
        ),
      },
    ];
    return columns;
  };

  showEditEmploymentTypeModal = (record) => {
    this.setState({
      editVisible: true,
      selectedRecord: record,
      editEmploymentType: record.name,
      editEmploymentTypePrefix: record.prefix,
    });
  };

  hideEditEmploymentTypeModal = () => {
    this.setState({
      editVisible: false,
      addVisible: false,
      selectedRecord: null,
      employmentType: '',
      employmentTypePrefix: '',
      editEmploymentType: '',
      editEmploymentTypePrefix: '',
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  handleAddEmploymentType = () => {
    const { employmentType, employmentTypePrefix } = this.state;
    const { addEmploymentType } = this.props;
    addEmploymentType({ name: employmentType, prefix: employmentTypePrefix });
    this.setState({ employmentType: '', employmentTypePrefix: '', addVisible: false });
  };

  handleEditEmploymentType = () => {
    const { selectedRecord, editEmploymentType, editEmploymentTypePrefix } = this.state;
    this.props.editEmploymentType({
      id: selectedRecord.id,
      name: editEmploymentType,
      prefix: editEmploymentTypePrefix,
    });
    this.setState({
      selectedRecord: null,
      editEmploymentType: '',
      employmentTypePrefix: '',
      editVisible: false,
    });
  };

  handleDeleteEmploymentType = (record) => {
    const { deleteEmploymentType } = this.props;
    deleteEmploymentType({ id: record.id });
  };

  editEmploymentType = () => {
    const {
      editVisible,
      addVisible,
      editEmploymentType,
      employmentType,
      employmentTypePrefix,
      editEmploymentTypePrefix,
    } = this.state;

    const EmploymentTypeValue = editVisible ? editEmploymentType : employmentType;
    const EmploymentTypePrefixValue = editVisible ? editEmploymentTypePrefix : employmentTypePrefix;
    return (
      <EnhancedModal
        title={editVisible ? 'Edit Employment Type' : 'Add Employment Type'}
        visible={editVisible || addVisible}
        onCancel={this.hideEditEmploymentTypeModal}
        footer={[
          <EnhancedButton key="back" onClick={this.hideEditEmploymentTypeModal}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            key="submit"
            type="primary"
            onClick={editVisible ? this.handleEditEmploymentType : this.handleAddEmploymentType}
            disabled={!EmploymentTypeValue || !EmploymentTypePrefixValue}
          >
            Save
          </EnhancedButton>,
        ]}
      >
        <EnhancedRow className="edit-skill" style={{ marginBottom: '20px' }}>
          <EnhancedCol span={24}>
            <EnhancedInput
              name={editVisible ? 'editEmploymentType' : 'employmentType'}
              value={EmploymentTypeValue}
              onChange={this.handleChange}
              placeholder="Enter Employment Type"
            />
          </EnhancedCol>
        </EnhancedRow>

        <EnhancedRow className="edit-skill">
          <EnhancedCol span={24}>
            <EnhancedInput
              name={editVisible ? 'editEmploymentTypePrefix' : 'employmentTypePrefix'}
              value={EmploymentTypePrefixValue}
              onChange={this.handleChange}
              placeholder="Enter Prefix"
            />
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedModal>
    );
  };

  showAddEmploymentModal = () => {
    this.setState({ addVisible: true });
  };

  showDeleteEmploymentTypeModal = (record) => {
    const { handleDeleteEmploymentType } = this;
    confirm({
      title: 'Are you sure you want to delete this Employment Type?',
      content: 'Note: Employment Type associated with employees cannot be deleted',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteEmploymentType(record);
      },
    });
  };

  render() {
    const columns = this.getColumnsInfo();
    const { employmentTypes } = this.state;
    const { isLoading } = this.props;
    return (
      <Fragment>
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol lg={12}>
              <h1 className="heading-pro">Employment Type</h1>
            </EnhancedCol>
            <EnhancedCol span={6} offset={6}>
              <Authorize requiredPermissions={[Permission.Configuration.EmploymentTypes.Create]}>
                <EnhancedButton
                  type="primary"
                  onClick={this.showAddEmploymentModal}
                  style={{ float: 'right', marginTop: '5%' }}
                >
                  {' '}
                  Add New Employment Type
                </EnhancedButton>
              </Authorize>
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        {this.editEmploymentType()}
        <div style={{ background: 'white' }}>
          <EnhancedCard loading={isLoading} bordered={false}>
            <EnhancedRow />
          </EnhancedCard>
          <div>
            <EnhancedClientSideTable
              style={{ marginLeft: '25px' }}
              loading={this.props.isLoading}
              columns={columns}
              data={employmentTypes}
              exportFileConfig={{
                fileName: 'Employment Type',
                fileHeaders: [
                  {
                    label: 'Employment Type',
                    key: 'name',
                  },
                  {
                    label: 'Prefix',
                    key: 'prefix',
                  },
                ],
                showExportButton: true,
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default EmploymentTypes;
