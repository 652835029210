import { EnhancedCol, EnhancedRow, EnhancedTabs, EnhancedLayout } from 'components/shared/antd';
import React, { Component } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { ATTRIBUTES, DASHBOARD_TABS } from 'configs/leaveConstants';
import LeaveDashboardCard from './LeaveDashboardCard';
import { paginationOptions } from '../../utils/getPagination';

const { TabPane } = EnhancedTabs;
export default class LeaveDashboard extends Component {
  constructor(props) {
    super(props);
    this.initialParams = {
      employeeName: '',
      managerName: '',
      sortOrder: '',
      sortBy: '',
      ...paginationOptions,
    };

    this.state = {
      params: {
        ...this.initialParams,
      },
      currentlyActiveTab: '',
      filteredAttributes: [],
    };
  }

  componentDidMount() {
    const { userId } = this.props;
    this.props.getUserAttributesAction(userId, 'TEAM_LEAVES');
  }

  componentWillReceiveProps(nextProps) {
    const { userAttributes: nextAttributes } = nextProps;
    const { userAttributes: prevAttributes } = this.props;

    if (nextAttributes.length && nextAttributes !== prevAttributes) {
      const filteredAttributes = nextAttributes.filter(
        (attr) => attr.name === ATTRIBUTES.LINE_MANAGER || attr.name === ATTRIBUTES.MANAGER,
      );
      if (filteredAttributes.length) {
        const {
          params: { page, size },
        } = this.state;

        const currentlyActiveTab = filteredAttributes[0].id;
        const params = { page, size };
        this.props.getTeamLeavesBalanceAction(currentlyActiveTab, params);
        this.setState({ currentlyActiveTab, filteredAttributes });
      }
    }
  }

  onUpdateParams = (updatedParams) => {
    const { currentlyActiveTab, params } = this.state;

    const newParams = {
      ...params,
      ...updatedParams,
      page: 0,
    };

    this.props.getTeamLeavesBalanceAction(currentlyActiveTab, newParams);

    this.setState((state) => ({
      params: {
        ...state.params,
        ...updatedParams,
        page: 0,
      },
    }));
  };

  activateUser = (key) => {
    const params = {
      ...this.initialParams,
    };
    this.props.getTeamLeavesBalanceAction(key, params);
    this.setState({
      currentlyActiveTab: key,
      params,
    });
  };

  handlePageChange = (pagination, filters, sorter) => {
    const { currentlyActiveTab, params } = this.state;

    const pageParams = {
      page: pagination.current - 1,
      size: pagination.pageSize,
    };

    if (sorter.columnKey && sorter.order) {
      pageParams.sortBy = sorter.columnKey;
      pageParams.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
    } else {
      pageParams.sortBy = '';
      pageParams.sortOrder = '';
    }

    const updatedParams = {
      ...params,
      ...pageParams,
    };
    this.props.getTeamLeavesBalanceAction(currentlyActiveTab, updatedParams);

    this.setState({
      params: updatedParams,
    });
  };

  render() {
    const directColumn = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'employeeName',
        filterConfig: {
          type: 'search',
          key: 'employeeName',
        },
        sorter: true,
      },
      {
        title: 'Department',
        dataIndex: 'deptt',
        key: 'deptt',
      },
      {
        title: 'Annual',
        dataIndex: 'annual',
        key: 'annual',
        sorter: true,
      },
      {
        title: 'Casual',
        dataIndex: 'casual',
        key: 'casual',
        sorter: true,
      },
      {
        title: 'Sick',
        dataIndex: 'sick',
        key: 'sick',
        sorter: true,
      },
    ];

    const IndirectColumn = [
      ...directColumn,
      {
        title: 'Line Manager',
        dataIndex: 'lineManager',
        key: 'managerName',
        filterConfig: {
          type: 'search',
          key: 'managerName',
        },
        sorter: true,
      },
    ];

    const { currentlyActiveTab, filteredAttributes } = this.state;
    const { isLoading, teamLeaveBalance, pageInfo } = this.props;

    return (
      <div style={{ background: 'white' }}>
        <EnhancedRow style={{ backgroundColor: '#fff', marginBottom: '3px' }}>
          <EnhancedLayout.Header className="leaves-header">
            <EnhancedCol lg={24} md={24} sm={24} xs={24}>
              <h1>Leaves Dashboard</h1>
            </EnhancedCol>
          </EnhancedLayout.Header>
        </EnhancedRow>
        {currentlyActiveTab && (
          <EnhancedTabs defaultActiveKey={currentlyActiveTab} onChange={this.activateUser}>
            {filteredAttributes.map((attribute) => (
              <TabPane
                tab={DASHBOARD_TABS[attribute.name]}
                key={attribute.id}
                style={{ background: '#F0F2F5' }}
              >
                <LeaveDashboardCard
                  heading={`${DASHBOARD_TABS[attribute.name]} Members`}
                  loading={isLoading}
                  dataSource={teamLeaveBalance}
                  columns={
                    attribute.name === ATTRIBUTES.LINE_MANAGER ? directColumn : IndirectColumn
                  }
                  onChange={this.handlePageChange}
                  pageInfo={pageInfo}
                  onUpdateParams={this.onUpdateParams}
                />
              </TabPane>
            ))}
          </EnhancedTabs>
        )}
      </div>
    );
  }
}
