import React from 'react';
import { List } from 'antd';
import PropTypes from 'prop-types';

const { Item } = List;
const { Meta } = Item;
const EnhancedListItem = (props) => {
  const { className, children } = props;

  return <Item className={className}>{children}</Item>;
};

EnhancedListItem.Meta = Meta;

EnhancedListItem.propTypes = {
  className: PropTypes.string,
};

EnhancedListItem.defaultProps = {
  className: '',
};

export default EnhancedListItem;
