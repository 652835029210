import UPDATE_BREADCRUMB from 'constants/BreadCrumbTypes';
import { BREADCRUMBS } from '../../configs/constants';

const initialState = {
  employee: {},
  project: {},
  cycle: {},
  cycleProj: {},
  projectReviewCycle: {},
  projectReviewCycleProj: {},
  pmCycle: {},
  dealDetail: {},
};

export default function breadCrumbReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_BREADCRUMB: {
      const { newVal, oldVal, key } = action;
      const newRoutes = {};
      newRoutes[oldVal] = newVal;
      return {
        ...state,
        employee: key === BREADCRUMBS.EMPLOYEE ? newRoutes : { ...state.employee },
        project: key === BREADCRUMBS.PROJECT ? newRoutes : { ...state.project },
        cycle: key === BREADCRUMBS.CYCLES ? newRoutes : { ...state.cycle },
        cycleProj: key === BREADCRUMBS.PROJECT_CYCLE ? newRoutes : { ...state.cycleProj },
        projectReviewCycle:
          key === BREADCRUMBS.PROJECT_REVIEWS_CYCLE ? newRoutes : { ...state.projectReviewCycle },
        projectReviewCycleProj:
          key === BREADCRUMBS.PROJECT_REVIEWS_CYCLE_PROJECT
            ? newRoutes
            : { ...state.projectReviewCycleProj },
        pmCycle: key === BREADCRUMBS.PERFORMANCE_MANAGEMENT ? newRoutes : { ...state.pmCycle },
        pmCycleEmployee:
          key === BREADCRUMBS.PERFORMANCE_MANAGEMENT_EMPLOYEE
            ? newRoutes
            : { ...state.pmCycleEmployee },
        goalCategoryDetail:
          key === BREADCRUMBS.GOAL_CATEGORY_DETAIL ? newRoutes : { ...state.goalCategoryDetail },
        dealDetail: key === BREADCRUMBS.DEAL_DETAIL ? newRoutes : { ...state.dealDetail },
      };
    }
    default:
      return state;
  }
}
