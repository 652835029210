/* eslint-disable */
import {
  EnhancedCard,
  EnhancedRow,
  EnhancedButton,
  EnhancedCol,
  EnhancedTabs,
} from 'components/shared/antd';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import React, { Component } from 'react';
import EmployeeRatingCards from './EmployeeRatingCards';
import isEmpty from 'lodash/isEmpty';
import RatingModalLM from 'components/pc/ratingModalLM';
import { BREADCRUMBS } from '../../configs/constants';
import './EmployeeHistory.css';

const { TabPane } = EnhancedTabs;

class EmployeeHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRatingModal: false,
      selectedRating: null,
    };

    this.transformedData = [];
    this.columnNames = [];
  }

  componentDidMount() {
    const { getEmployeeRatingsAction, getEmployeeRatingSummaryAction } = this.props;

    getEmployeeRatingsAction(this.props.match.params.id);
    getEmployeeRatingSummaryAction(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.employeeRatings !== this.props.employeeRatings &&
      !isEmpty(nextProps.employeeRatings)
    ) {
      this.transformedData = this.transformData(nextProps.employeeRatings);

      const { attributes, employee } = nextProps.employeeRatings;
      const { updateBreadCrumb } = this.props;

      this.columnNames = attributes;
      updateBreadCrumb(BREADCRUMBS.EMPLOYEE, employee.id, employee.fullName);
    }
  }

  transformData = (employeeRatings) => {
    let data = [];

    if (employeeRatings.ratings) {
      employeeRatings.ratings.forEach((rating) => {
        const myObj = {
          cycleName: rating.cycleName,
          cycleId: rating.cycleId,
          children: [],
        };

        rating.averageRatings.forEach((averageRating) => {
          const key = `${averageRating.raterRole}${averageRating.attributeName}`;
          myObj[key] = averageRating.rating;
        });

        rating.projects.forEach((project) => {
          const childrenObj = {
            cycleName: project.projectName,
            projectId: project.projectId,
            cycleId: rating.cycleId,
          };
          project.ratingsList.forEach((projectRating) => {
            const projectKey = `${projectRating.raterRole}${projectRating.attributeName}`;
            childrenObj[projectKey] = projectRating.rating;
          });

          myObj.children.push(childrenObj);
        });

        data.push(myObj);
      });
    }
    return data;
  };

  getKey = (raterRole, attributeName) => `${raterRole}${attributeName}`;

  renderAttributeValue = (value) => {
    let renderedValue = value;

    if (!value) {
      renderedValue = 0;
    }

    return <span>{renderedValue}</span>;
  };

  getColumnsInfo = () => {
    const { isArch, isPM, isFunctionalHead, isCrmc, isLineManager } = this.props;
    let PMParameters = [];
    let archParameters = [];

    if (isPM || isFunctionalHead || isCrmc || isLineManager) {
      const PMColumns = this.columnNames.filter((column) => column.raterRole === 'PM');

      PMParameters = PMColumns.map((column) => ({
        title: `${column.raterRole} ${column.attributeTitle}`,
        dataIndex: this.getKey(column.raterRole, column.attributeName),
        key: this.getKey(column.raterRole, column.attributeName),
        width: '6%',
        align: 'center',
        render: this.renderAttributeValue,
      }));
    }
    if (isArch || isFunctionalHead || isCrmc || isLineManager) {
      const archColumns = this.columnNames.filter((column) => column.raterRole === 'Architect');

      archParameters = archColumns.map((column) => ({
        title: `${column.raterRole} ${column.attributeTitle}`,
        dataIndex: this.getKey(column.raterRole, column.attributeName),
        key: this.getKey(column.raterRole, column.attributeName),
        width: '6%',
        align: 'center',
        render: this.renderAttributeValue,
      }));
    }

    const columns = [
      {
        title: 'Cycle',
        dataIndex: 'cycleName',
        key: 'cycleName',
        render: (name) => {
          if (name.length <= 15) return name;
          return <span title={name}>{`${name.slice(0, 15)}...`}</span>;
        },
      },
    ];
    columns.push(...PMParameters);
    columns.push(...archParameters);
    return columns;
  };

  handleOpenRatingModal = (record) => {
    const { getRatingDetails, userId } = this.props;
    const payload = {
      performanceCycleId: record.cycleId,
      projectId: record.projectId,
      rateeId: this.props.match.params.id,
    };
    getRatingDetails(payload);

    this.setState({
      showRatingModal: true,
    });
  };

  handleCloseRatingModal = () => {
    this.setState({
      showRatingModal: false,
    });
  };

  handleDownloadRatings = () => {
    const { employeeRatings } = this.props;
    const { employee } = employeeRatings;
    this.props.downloadEmployeeRatingsAction({
      employeeId: employee.id,
    });
  };

  renderRatingModal = () => {
    const { employeeRatings, ratingDetails, resetRatingDetails } = this.props;
    const { showRatingModal } = this.state;
    const { employee } = employeeRatings;
    let employeeName = '';
    if (employee) {
      employeeName = employee.fullName;
    }

    return (
      <RatingModalLM
        onModalClose={this.handleCloseRatingModal}
        resourceName={employeeName}
        visible={showRatingModal}
        ratingDetails={ratingDetails}
        resetRatingDetails={resetRatingDetails}
      />
    );
  };

  renderTabularView = () => {
    const { isLoading } = this.props;
    const { employeeRatings } = this.props;
    const { showRatingModal } = this.state;
    let ratings = employeeRatings.employeeRatings;
    ratings = ratings || [];
    return (
      <EnhancedCard style={{ marginTop: '10px' }} bordered={false}>
        <EnhancedRow>
          <EnhancedCol offset={20} lg={4}>
            <EnhancedButton
              style={{ float: 'right', marginBottom: '15px' }}
              onClick={this.handleDownloadRatings}
              icon="file-excel"
            >
              Export
            </EnhancedButton>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedClientSideTable
          rowKey={(record) => record.cycleId}
          loading={isLoading}
          columns={this.getColumnsInfo()}
          data={this.transformedData}
          scroll={{ x: 1840, y: false }}
          size="middle"
          onRow={(record) => ({
            onClick: () => {
              if (record.children === undefined) {
                this.handleOpenRatingModal(record);
              }
            },
          })}
          showResetFiltersButton={false}
        />
        {showRatingModal && this.renderRatingModal()}
      </EnhancedCard>
    );
  };

  render() {
    const { employeeRatings, employeeRatingSummary } = this.props;
    const { employee, overallRating } = employeeRatings;
    let employeeName = '';
    if (employee) {
      employeeName = employee.fullName;
    }
    return (
      <div style={{ background: 'white' }}>
        <EnhancedCard
          bordered={false}
          style={{
            paddingBottom: 0,
            borderBottom: '3px solid #eee',
          }}
        >
          <EnhancedRow>
            <EnhancedCol lg={24}>
              <h2 className="heading-res">{`Performance History - ${employeeName}`}</h2>
            </EnhancedCol>
            <EmployeeRatingCards employeeRatingSummary={employeeRatingSummary} />
          </EnhancedRow>
        </EnhancedCard>
        <EnhancedTabs defaultActiveKey="0">
          <TabPane tab="Tabular" key="0" style={{ backgroundColor: '#f0f2f5' }}>
            {this.renderTabularView()}
          </TabPane>
          {/* <TabPane tab="Graphical" key={1} style={{ background: '#f0f2f5' }}>
          </TabPane> */}
        </EnhancedTabs>
      </div>
    );
  }
}

export default EmployeeHistory;
