import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedIcon,
  EnhancedModal,
  EnhancedSelect,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';
import AddFormModal from './AddFormModal';
import {
  GOAL_DELETE_WARNING_MESSAGE_TITLE,
  GOAL_DELETE_WARNING_MESSAGE_DETAILS,
} from '../../../configs/messageConstants';

const { confirm } = EnhancedModal;
const { Option } = EnhancedSelect;

const Listing = (props) => {
  const {
    // userRole,
    addGoal,
    updateGoal,
    getGoal,
    removeGoal,
    getGoalDetails,
    list,
    isLoading,
    details,
  } = props;

  const [isGoalFormModalVisible, setIsGoalFormModalVisible] = useState(false);
  const [params, setParams] = useState({
    page: 0,
    size: 10,
  });

  const [isAdd, setIsAdd] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getGoal(params);
  }, [params]);

  const setInitialState = () => {
    setIsAdd(false);
    setIsEdit(false);
    setIsView(false);
  };

  const showGoalFormModal = () => {
    setIsAdd(true);
    setIsGoalFormModalVisible(true);
  };

  const handleGoalFormOk = () => {
    setIsGoalFormModalVisible(false);
    setInitialState();
  };

  const handleGoalFormCancel = () => {
    setIsGoalFormModalVisible(false);
    setInitialState();
  };

  const handleGoalDelete = ({ id, userId }) => {
    confirm({
      title: GOAL_DELETE_WARNING_MESSAGE_TITLE,
      content: GOAL_DELETE_WARNING_MESSAGE_DETAILS,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        let pageNumber = params.page;
        const dataCount = list.count;
        const pageLastRecord = (dataCount - 1) % 10;

        if (pageLastRecord === 0 && pageNumber > 0) {
          pageNumber = params.page - 1;
          setParams({
            ...params,
            page: pageNumber,
          });
        }

        removeGoal({
          id,
          userId,
          params: {
            ...params,
            page: pageNumber,
          },
        });
      },
    });
  };

  const changeActionHandler = (key, id, _record) => {
    setInitialState();
    if (key === '1') {
      setIsView(true);
      showGoalFormModal();
      getGoalDetails({ id, userId: _record.userId });
    } else if (key === '2') {
      setIsEdit(true);
      showGoalFormModal();
      getGoalDetails({ id, userId: _record.userId });
    } else if (key === '3') {
      handleGoalDelete({ id, userId: _record.userId });
    }
  };

  const optionMenu = [
    {
      value: '1',
      label: 'View',
    },
    {
      value: '2',
      label: 'Edit',
    },
    {
      value: '3',
      label: 'Delete',
    },
  ];

  const setActionsHandler = (id, _record) => {
    return (
      <EnhancedSelect
        style={{
          width: 120,
        }}
        className="action-selection-dropdown"
        placeholder="Options"
        value="Options"
        onChange={(key) => changeActionHandler(key, id, _record)}
      >
        {optionMenu.map((item) => (
          <Option value={item.value}>{item.label}</Option>
        ))}
      </EnhancedSelect>
    );
  };

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      page: pagination.current - 1,
      size: pagination.pageSize,
    });
  };

  const getColumnsInfo = () => {
    const columns = [
      {
        title: 'Goal Title',
        dataIndex: 'title',
        key: 'title',
        filterConfig: {
          type: 'search',
          key: 'title',
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
        render: (description) => <p className="column-truncated">{description}</p>,
      },
      {
        title: 'Measurement',
        dataIndex: 'measurement',
        render: (measurement) => <p className="column-truncated">{measurement}</p>,
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        render: (id, _record) => setActionsHandler(id, _record),
      },
    ];
    return columns;
  };

  return (
    <div>
      <EnhancedCard>
        <React.Fragment>
          <EnhancedRow type="flex" justify="space-between" align="middle" className="mb-20">
            <EnhancedCol>
              <h1 className="mb-0">Predefined Goals</h1>
            </EnhancedCol>
            <EnhancedCol>
              <EnhancedButton className="btn-edit" onClick={showGoalFormModal}>
                <EnhancedIcon type="plus-circle" />
                <span>Add New Goal</span>
              </EnhancedButton>
            </EnhancedCol>
          </EnhancedRow>

          <EnhancedServerSideTable
            rowKey={(record) => record.id}
            loading={isLoading}
            columns={getColumnsInfo()}
            data={list.rows}
            onChange={handleTableChange}
            className="goal-table-container"
            paginationInfo={{
              totalItems: list.count,
              pageSize: params.size,
              pageNumber: params.page + 1,
            }}
            updateParams={(updatedParams) => {
              setParams({
                ...params,
                ...updatedParams,
                pageNumber: 0, // move to page no 0 in case filters are applied
              });
            }}
          />

          <AddFormModal
            isModalVisible={isGoalFormModalVisible}
            handleOk={handleGoalFormOk}
            handleCancel={handleGoalFormCancel}
            addGoalActions={addGoal}
            updateGoalActions={updateGoal}
            params={params}
            isEdit={isEdit}
            isView={isView}
            isAdd={isAdd}
            editableHandler={setIsEdit}
            isLoading={isLoading}
            details={details}
          />
        </React.Fragment>
      </EnhancedCard>
    </div>
  );
};

export default Listing;
