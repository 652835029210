export const categories = {
  LOGWORK: 'Log Work',
  PROJECTS: 'Projects',
  REPORT: 'Report',
  MISSING_ALLOCATIONS: 'Missing Allocations',
  FAQS: 'FAQs',
  GENERAL: 'General',
  MY_LEAVES: 'My Leaves',
  TEAM_LEAVES: 'Team Leaves',
};

export const events = {
  ADD_LOG: 'Add Log',
  EDIT_LOG: 'Edit Log',
  ADD_MOOD: 'Add Mood',

  ADD_PROJECT: 'Add Project',
  FILTER_PROJECT: 'Filter Project',

  NAV_LEFT: 'Nav Left',
  NAV_RIGHT: 'Nav Right',
  CHANGE_RESOLUTION: 'Change Resolution',

  PAGE_CHANGE: 'Page Change',
  PAGE_SIZE_CHANGE: 'Page Size Change',

  EXPAND_DETAILS: 'Expand Details',

  TOGGLE_SIDER: 'Toggle Sider',
  LOGOUT: 'Logout',

  APPLY_LEAVE_ALL: 'Apply Leave All',
  APPLY_LEAVE_COMMON: 'Apply Leave Common',
  APPLY_LEAVE_OTHER: 'Other Leave Other',
  REQUEST: 'Request',
  CANCEL: 'Cancel',
  EDIT: 'Edit',
  APPROVE: 'Approve',
  DECLINE: 'Decline',
};
