import { EnhancedSpin, EnhancedBreadcrumb } from 'components/shared/antd';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';
import {
  CRMC_PROJECTS_CURRENT,
  CRMC_EMPLOYEES_CURRENT,
  CRMC_REVIEW_RATING,
  FINANCE_BILLING,
  PERFORMANCE_MANAGEMENT,
  PROFILE,
  EMPLOYEE_ORG_TREE,
  PREDEFINED_GOALS_CATEGORIES,
  DEALS,
} from '../configs/routesConstants';
import { BREADCRUMBS } from '../configs/constants';
import { capitalizeHyphenatedString } from '../utils/stringUtils';
import './breadcrumb.css';

class BreadCrumbComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      routesFinal: {},
    };
  }

  mapBreadCrumb = (current, routesLocation, index) => {
    const { location } = this.props;
    let routes = location.pathname.split('/');
    routes = routes.slice(0, 3 + index);

    if (current === BREADCRUMBS.PROJECT) {
      return CRMC_PROJECTS_CURRENT;
    }
    if (current === BREADCRUMBS.EMPLOYEE) {
      return CRMC_EMPLOYEES_CURRENT;
    }
    if (current === BREADCRUMBS.HISTORY) {
      return CRMC_REVIEW_RATING;
    }
    if (current === BREADCRUMBS.BILLING_PROJECT || current === BREADCRUMBS.BILLING_RESOURCE) {
      return FINANCE_BILLING;
    }
    if (current === BREADCRUMBS.PERFORMANCE_MANAGEMENT) {
      return PERFORMANCE_MANAGEMENT;
    }
    if (current === 'profile') {
      return PROFILE;
    }
    if (current === BREADCRUMBS.REPORTEE) {
      return EMPLOYEE_ORG_TREE;
    }
    if (current === 'goals-categories') {
      return PREDEFINED_GOALS_CATEGORIES;
    }
    if (current === 'deals') {
      return DEALS;
    }
    let paths = routes.join('/');

    // if its last index then append query string too
    if (index === routesLocation.length - 1) {
      paths = `${paths}${location.search}`;
    }

    return paths;
  };

  findInArr = (arr, val) => arr.find((value) => value.includes(val));

  parseDate = () => {
    const { routesFinal } = this.state;
    return Object.keys(routesFinal).find((val) => Date.parse(val));
  };

  renderNameBreadCrumb = (loc, routes, index) => {
    const {
      employee,
      project,
      cycle,
      cycleProj,
      projectReviewCycle,
      projectReviewCycleProj,
      pmCycle,
      pmCycleEmployee,
      goalCategoryDetail,
      dealDetail,
    } = this.props;
    let val = loc;

    if (parseInt(loc, 10)) {
      if (this.findInArr(routes, BREADCRUMBS.EMPLOYEE) && employee[loc]) {
        val = employee[loc];
      } else if (this.findInArr(routes, BREADCRUMBS.PROJECT) && project[loc]) {
        val = project[loc];
      } else if (this.findInArr(routes, BREADCRUMBS.CYCLES) && cycle[loc]) {
        val = cycle[loc];
      } else if (this.findInArr(routes, BREADCRUMBS.HISTORY) && employee[loc]) {
        val = employee[loc];
      } else if (this.parseDate() && cycleProj[loc]) {
        val = cycleProj[loc];
      } else if (
        this.findInArr(routes, BREADCRUMBS.PROJECT_REVIEWS_CYCLE) &&
        projectReviewCycle[loc]
      ) {
        val = projectReviewCycle[loc];
      } else if (this.parseDate() && projectReviewCycleProj[loc]) {
        val = projectReviewCycleProj[loc];
      } else if (pmCycle && pmCycle[loc]) {
        val = pmCycle[loc];
      } else if (pmCycleEmployee && pmCycleEmployee[loc]) {
        val = pmCycleEmployee[loc];
      } else if (goalCategoryDetail && goalCategoryDetail[loc]) {
        val = goalCategoryDetail[loc];
      } else if (dealDetail && dealDetail[loc]) {
        val = dealDetail[loc];
      }
    }
    const { routesFinal } = this.state;
    let newValue;
    if (!Number.isNaN(Number(loc))) {
      newValue = val;
    } else {
      newValue = capitalizeHyphenatedString(val);
    }
    routesFinal[index] = newValue;
    if (!Number.isNaN(Number(newValue))) {
      return <EnhancedSpin size="small" />;
    }
    return newValue;
  };

  render() {
    const { location } = this.props;
    const routesLocation = location.pathname.split('/');

    // Default case when a prefix is expected, e.g: /crmc/employees/current = '["",crmc,employees,current]'
    if (routesLocation.length > 3) {
      routesLocation.splice(0, 2);

      // New permission based cases when there is no prefix, e.g: /employees/all
      // routesLocation.length > 0 is equivalent to routesLocation.length.
      // However, routesLocation.length > 0 is more readable and resembles default condition.
    } else if (routesLocation.length > 0) {
      routesLocation.splice(0, 1);
    }

    // TODO: Refactor to go to parent route

    return (
      <div>
        {routesLocation.length > 1 ? (
          <EnhancedBreadcrumb style={{ paddingBottom: 20 }}>
            {routesLocation.map((loc, index) => (
              <BreadcrumbItem key={loc}>
                <Link to={this.mapBreadCrumb(loc, routesLocation, index)}>
                  <b className="breadcrumb">
                    {this.renderNameBreadCrumb(loc, routesLocation, index)}
                  </b>
                </Link>
              </BreadcrumbItem>
            ))}
          </EnhancedBreadcrumb>
        ) : null}
      </div>
    );
  }
}

export default BreadCrumbComponent;
