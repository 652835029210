import {
  EnhancedRow,
  EnhancedCol,
  EnhancedIcon,
  EnhancedDivider,
  EnhancedSpin,
} from 'components/shared/antd';
/* eslint-disable */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import defaultImageURL from 'images/default-placeholder.png';
import './NewsGridPlaceholder.css';
import RelatedNewsPlaceholder from '../news/RelatedNewsPlaceholder';

class NewsDetailPlaceholder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      response: props.relatedNews,
    };
  }
  render() {
    const { singleNews, loader, relatedNews } = this.props;
    return (
      <Fragment>
        <div className="font-change">
          <div className="clear-fix" />
          {loader ? (
            <div className="box">
              <div>
                <EnhancedSpin tip="loading"></EnhancedSpin>
              </div>
            </div>
          ) : (
            <EnhancedRow type="flex" justify="start">
              <EnhancedCol span={16} style={{ backgroundColor: 'white' }}>
                <EnhancedRow
                  type="flex"
                  justify="start"
                  style={{ flexDirection: 'column' }}
                  key={singleNews.id}
                >
                  <EnhancedCol style={{ padding: '2% 3%' }}>
                    <img
                      src={singleNews.coverImage || defaultImageURL}
                      alt="No News"
                      className="news-detail-image"
                    />
                  </EnhancedCol>
                  <EnhancedCol>
                    <div style={{ paddingLeft: '3%', fontSize: '16px' }}>{singleNews.category}</div>
                    <h1 style={{ paddingLeft: '3%', color: 'black' }}>{singleNews.title}</h1>
                    <div style={{ paddingLeft: '3%' }}>
                      <EnhancedIcon
                        type="calendar"
                        style={{ fontSize: '24px', color: '#8C8C8C', marginRight: '5px' }}
                      />
                      {singleNews.date}
                    </div>
                    <div className="news-detail-desc">{singleNews.description}</div>
                  </EnhancedCol>
                </EnhancedRow>
              </EnhancedCol>
              <EnhancedCol span={8} className="related-news-right-col">
                <h3>Related News</h3>
                <EnhancedDivider />
                <RelatedNewsPlaceholder relatedNews={relatedNews} />
              </EnhancedCol>
            </EnhancedRow>
          )}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { newsReducer } = state;
  return {
    relatedNews: newsReducer.relatedNews,
  };
}

export default connect(mapStateToProps, null)(NewsDetailPlaceholder);
