import React from 'react';
import { Affix } from 'antd';
import PropTypes from 'prop-types';

const EnhancedAffix = (props) => {
  const { children, className, offsetTop, ...rest } = props;
  return (
    <Affix
      className={className}
      offsetTop={offsetTop}
      {...rest} // this is defined for getting default props and behaviours
    >
      {children}
    </Affix>
  );
};

EnhancedAffix.propTypes = {
  className: PropTypes.string,
  offsetTop: PropTypes.number,
};

EnhancedAffix.defaultProps = {
  className: '',
  offsetTop: 0,
};

export default EnhancedAffix;
