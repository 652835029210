import moment from 'moment';
import htmlToText from 'html-to-text';
import ReactHtmlParser from 'react-html-parser';

/* eslint-disable */
const currentDate = moment(new Date()).format('YYYY-MM-DD');
export const normalizeEventsData = (data) =>
  data.length > 0
    ? data
        .filter(
          (events) =>
            events.start_date &&
            events.start_date !== 'Invalid date' &&
            (moment(events.start_date).format('YYYY-MM-DD') >= currentDate ||
              (events.end_date &&
                events.end_date !== 'Invalid date' &&
                moment(events.end_date).format('YYYY-MM-DD') >= currentDate)),
        )
        .map((events) => ({
          id: events.id,
          title: htmlToText.fromString(events.title.rendered),
          description: htmlToText.fromString(events.content.rendered),
          location: events.locations,
          coverImage: events.better_featured_image ? events.better_featured_image.source_url : null,
          date:
            moment(events.start_date).date() < 10
              ? `0${moment(events.start_date).date()}`
              : moment(events.start_date).date(),
          month: moment(events.start_date).format('MMM'),
          start_date: moment(events.start_date).format('DD MMM YYYY'),
          end_date: moment(events.end_date).format('DD MMM YYYY'),
          start_time: moment.utc(events.start_time, 'HH:mm').format('HH:mm'),
          end_time: moment.utc(events.end_time, 'HH:mm').format('HH:mm'),
          visit_page_link: events.visit_page_link,
          zoom_link: events.zoom_link || '',
          skype_link: events.skype_link || '',
          dateObj: new Date(moment(events.start_date).format('YYYY-MM-DD')),
          today:
            moment(events.start_date).format('YYYY-MM-DD') === currentDate
              ? true
              : moment(events.start_date).format('YYYY-MM-DD') > currentDate
                ? false
                : '',
        }))
        .sort((a, b) => {
          return a.dateObj - b.dateObj;
        })
    : '';

export const normalizeNewsGridViewData = (data, totalCount) => {
  const items = data
    ? data.map((news) => ({
        id: news.id,
        title: htmlToText.fromString(news.title.rendered),
        description: htmlToText.fromString(news.content.rendered),
        category: news.category,
        coverImage: news.better_featured_image && news.better_featured_image.source_url,
        date: moment(news.date).format('DD MMM YYYY'),
      }))
    : [];
  return { items, totalCount };
};

export const normalizeNewsDetailViewData = (data) =>
  data
    ? {
        id: data.id,
        title: htmlToText.fromString(data.title.rendered),
        description: ReactHtmlParser(data.content.rendered),
        // category: data.category,
        coverImage: data.better_featured_image && data.better_featured_image.source_url,
        date: moment(data.date).format('DD MMM YYYY'),
      }
    : '';

export const normalizeRelatedNewsDetailViewData = (data) =>
  data.length > 0
    ? data.map((news) => ({
        id: news.id,
        title: htmlToText.fromString(news.title.rendered),
        // category: news.category,
        coverImage: news.better_featured_image && news.better_featured_image.source_url,
        date: moment(news.date).format('DD MMM YYYY'),
      }))
    : '';

export const normalizeHomePageNewsData = (data) =>
  data.length > 0
    ? data.map((news) => ({
        id: news.id,
        title: htmlToText.fromString(news.title.rendered),
        coverImage: news.better_featured_image && news.better_featured_image.source_url,
      }))
    : '';

export const normalizeHomePageUsefulResourceVideoData = (data) =>
  data.length > 0
    ? data
        .filter((videos) => videos.status === 'publish')
        .map((videos) => ({
          id: videos.id,
          title: htmlToText.fromString(videos.title.rendered),
          source_url: videos.video_id,
          status: videos.status,
        }))
    : '';
