import { connect } from 'react-redux';
import BreadCrumbComponent from 'components/BreadCrumbComponent';

function mapStateToProps(state) {
  const {
    employee,
    project,
    cycle,
    cycleProj,
    projectReviewCycle,
    projectReviewCycleProj,
    pmCycle,
    pmCycleEmployee,
    goalCategoryDetail,
    dealDetail,
  } = state.breadCrumbReducer;

  return {
    employee,
    project,
    cycle,
    cycleProj,
    projectReviewCycle,
    projectReviewCycleProj,
    pmCycle,
    pmCycleEmployee,
    goalCategoryDetail,
    dealDetail,
  };
}
const BreadCrumbContainer = connect(mapStateToProps)(BreadCrumbComponent);

export default BreadCrumbContainer;
