import moment from 'moment';
import {
  GET_EMPLOYEE_AUDIT_LOGS_INIT,
  GET_EMPLOYEE_PROFILE_HEADER_INIT,
  GET_EMPLOYEE_PROFILE_HEADER_SUCCESS,
  GET_EMPLOYEE_PROFILE_HEADER_FAILURE,
  GET_EMPLOYEE_PROFILE_INIT,
  GET_EMPLOYEE_INFO_FAILURE,
  GET_EMPLOYEE_INFO_SUCCESS,
  UPDATE_EMPLOYEE_INFO_INIT,
  UPDATE_EMPLOYEE_INFO_SUCCESS,
  UPDATE_EMPLOYEE_INFO_FAILURE,
  CREATE_EMPLOYEE_FAMILY_INFO_INIT,
  CREATE_EMPLOYEE_FAMILY_INFO_SUCCESS,
  CREATE_EMPLOYEE_FAMILY_INFO_FAILURE,
  UPDATE_EMPLOYEE_FAMILY_INFO_INIT,
  UPDATE_EMPLOYEE_FAMILY_INFO_SUCCESS,
  UPDATE_EMPLOYEE_FAMILY_INFO_FAILURE,
  DELETE_EMPLOYEE_FAMILY_INFO_INIT,
  DELETE_EMPLOYEE_FAMILY_INFO_SUCCESS,
  DELETE_EMPLOYEE_FAMILY_INFO_FAILURE,
  JOB_INFO_CALL_INIT,
  JOB_INFO_CALL_FAILURE,
  GET_EMPLOYEE_JOB_INFO_SUCCESS,
  CREATE_EMPLOYEE_JOB_INFO_SUCCESS,
  UPDATE_EMPLOYEE_JOB_INFO_SUCCESS,
  DELETE_EMPLOYEE_JOB_INFO_SUCCESS,
  GET_EMPLOYEE_TRACKING_INFO_INIT,
  GET_EMPLOYEE_TRACKING_INFO_SUCCESS,
  GET_EMPLOYEE_TRACKING_INFO_FAILURE,
  UPDATE_EMPLOYEE_TRACKING_INFO_INIT,
  UPDATE_EMPLOYEE_TRACKING_INFO_SUCCESS,
  UPDATE_EMPLOYEE_TRACKING_INFO_FAILURE,
  GET_EMPLOYEE_COMPENSATION_INIT,
  GET_EMPLOYEE_COMPENSATION_FAILURE,
  GET_EMPLOYEE_COMPENSATION_SUCCESS,
  CREATE_EMPLOYEE_COMPENSATION_INIT,
  CREATE_EMPLOYEE_COMPENSATION_FAILURE,
  UPDATE_EMPLOYEE_COMPENSATION_INIT,
  UPDATE_EMPLOYEE_COMPENSATION_FAILURE,
  GET_PAY_FREQUENCIES_SUCCESS,
  GET_BENEFITS_SUCCESS,
  GET_REASONS_SUCCESS,
  GET_EMPLOYEE_JOBS_LISTING_SUCCESS,
  GET_EMPLOYEE_TALENT_SUCCESS,
  GET_EMPLOYEE_TALENT_FAILURE,
  UPDATE_EMPLOYEE_TALENT_INIT,
  UPDATE_EMPLOYEE_TALENT_SUCCESS,
  UPDATE_EMPLOYEE_TALENT_FAILURE,
  UPDATE_EMPLOYEE_CAREER_PLANNING_INIT,
  UPDATE_EMPLOYEE_CAREER_PLANNING_SUCCESS,
  UPDATE_EMPLOYEE_CAREER_PLANNING_FAILURE,
  GET_EMPLOYEE_CERTIFICATES_INIT,
  GET_EMPLOYEE_CERTIFICATES_SUCCESS,
  GET_EMPLOYEE_CERTIFICATES_FAILURE,
  CREATE_EMPLOYEE_CERTIFICATES_INIT,
  CREATE_EMPLOYEE_CERTIFICATES_SUCCESS,
  CREATE_EMPLOYEE_CERTIFICATES_FAILURE,
  UPDATE_EMPLOYEE_CERTIFICATES_INIT,
  UPDATE_EMPLOYEE_CERTIFICATES_SUCCESS,
  UPDATE_EMPLOYEE_CERTIFICATES_FAILURE,
  DELETE_EMPLOYEE_CERTIFICATES_INIT,
  DELETE_EMPLOYEE_CERTIFICATES_SUCCESS,
  DELETE_EMPLOYEE_CERTIFICATES_FAILURE,
  GET_EMPLOYEE_EMPLOYMENT_HISTORY_SUCCESS,
  GET_EMPLOYEE_EMPLOYMENT_HISTORY_FAILURE,
  GET_EMPLOYEE_EDUCATION_HISTORY_SUCCESS,
  GET_EMPLOYEE_EDUCATION_HISTORY_FAILURE,
  CREATE_EMPLOYEE_EDUCATION_HISTORY_INIT,
  CREATE_EMPLOYEE_EDUCATION_HISTORY_SUCCESS,
  EMPLOYMENT_CALL_INIT,
  EMPLOYMENT_CALL_FAILURE,
  UPDATE_EMPLOYEE_EMPLOYMENT_SUCCESS,
  CREATE_EMPLOYEE_EMPLOYMENT_SUCCESS,
  DELETE_EMPLOYEE_EMPLOYMENT_SUCCESS,
  CREATE_EMPLOYEE_EDUCATION_HISTORY_FAILURE,
  UPDATE_EMPLOYEE_EDUCATION_HISTORY_INIT,
  UPDATE_EMPLOYEE_EDUCATION_HISTORY_SUCCESS,
  UPDATE_EMPLOYEE_EDUCATION_HISTORY_FAILURE,
  DELETE_EMPLOYEE_EDUCATION_HISTORY_INIT,
  DELETE_EMPLOYEE_EDUCATION_HISTORY_SUCCESS,
  DELETE_EMPLOYEE_EDUCATION_HISTORY_FAILURE,
  GET_EMPLOYEE_DOCUMENT_URL_SUCCESS,
  GET_COMMENT_ATTACHMENT_URL_SUCCESS,
  GET_EMPLOYEE_DOCUMENT_URL_FAILURE,
  GET_COMMENT_ATTACHMENT_URL_FAILURE,
  CREATE_EMPLOYEE_DOCUMENT_SUCCESS,
  UPDATE_EMPLOYEE_PICTURE_SUCCESS,
  UPDATE_EMPLOYEE_PICTURE_FAILURE,
  UPDATE_EMPLOYEE_PICTURE_INIT,
  DELETE_EMPLOYEE_DOCUMENT_SUCCESS,
  GET_EMPLOYEE_DOCUMENTS_SUCCESS,
  GET_EMPLOYEE_DOCUMENTS_FAILURE,
  GET_EMPLOYEE_COMMENTS_SUCCESS,
  GET_EMPLOYEE_COMMENTS_FAILURE,
  CREATE_EMPLOYEE_COMMENT_INIT,
  CREATE_EMPLOYEE_COMMENT_SUCCESS,
  CREATE_EMPLOYEE_COMMENT_FAILURE,
  DELETE_EMPLOYEE_COMMENT_INIT,
  DELETE_EMPLOYEE_COMMENT_SUCCESS,
  DELETE_EMPLOYEE_COMMENT_FAILURE,
  GET_EMPLOYEE_AUDIT_LOGS_SUCCESS,
  GET_EMPLOYEE_AUDIT_LOGS_FAILURE,
  GET_MARITAL_STATUSES_INIT,
  GET_MARITAL_STATUSES_SUCCESS,
  GET_MARITAL_STATUSES_FAILURE,
  GET_EMPLOYMENT_STATUSES_INIT,
  GET_EMPLOYMENT_STATUSES_SUCCESS,
  GET_EMPLOYMENT_STATUSES_FAILURE,
  GET_BLOOD_GROUPS_INIT,
  GET_BLOOD_GROUPS_SUCCESS,
  GET_BLOOD_GROUPS_FAILURE,
  GET_RELIGIONS_INIT,
  GET_RELIGIONS_SUCCESS,
  GET_RELIGIONS_FAILURE,
  GET_QUALIFICATIONS_INIT,
  GET_QUALIFICATIONS_SUCCESS,
  GET_QUALIFICATIONS_FAILURE,
  EMPLOYEE_PROFILE_CLEAR,
  CREATE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS,
  UPDATE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS,
  DELETE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS,
  CREATE_EMPLOYEE_EMERGENCY_CONTACT_INIT,
  UPDATE_EMPLOYEE_EMERGENCY_CONTACT_INIT,
  DELETE_EMPLOYEE_EMERGENCY_CONTACT_INIT,
  CREATE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE,
  UPDATE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE,
  DELETE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE,
  GET_PERFORMANCE_REVIEW_INIT,
  GET_PERFORMANCE_REVIEW_SUCCESS,
  GET_PERFORMANCE_REVIEW_FAILURE,
} from 'constants/EmployeeProfileTypes';
import { DATE_FORMAT } from '../../configs/employeeProfileConstants';

const initialState = {
  profileHeader: {},
  employeeInfo: {},
  employeeCertificates: [],
  dropDowns: {},
  familyInfo: [],
  emergencyContacts: [],
  jobInfo: [],
  trackingInfo: {},
  compensation: {
    current: {},
    listing: [],
  },
  talentManagement: {
    careerPlanning: {},
    certificates: [],
    competency: null,
    skills: [],
  },
  employmentHistory: [],
  educationHistory: [],
  documents: [],
  comments: [],
  auditLogs: {},
  employeeDocumentUrl: null,
  commentAttachmentUrl: null,
  benefits: [],
  isLoading: false,
  cardLoading: false,
};

export default function myProfileReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_PROFILE_HEADER_INIT:
    case GET_EMPLOYEE_CERTIFICATES_INIT:
    case GET_MARITAL_STATUSES_INIT:
    case GET_EMPLOYMENT_STATUSES_INIT:
    case GET_BLOOD_GROUPS_INIT:
    case GET_RELIGIONS_INIT:
    case GET_QUALIFICATIONS_INIT:
    case CREATE_EMPLOYEE_EDUCATION_HISTORY_INIT:
    case UPDATE_EMPLOYEE_EDUCATION_HISTORY_INIT:
    case DELETE_EMPLOYEE_EDUCATION_HISTORY_INIT:
    case UPDATE_EMPLOYEE_TALENT_INIT:
    case UPDATE_EMPLOYEE_CAREER_PLANNING_INIT:
    case CREATE_EMPLOYEE_CERTIFICATES_INIT:
    case UPDATE_EMPLOYEE_CERTIFICATES_INIT:
    case DELETE_EMPLOYEE_CERTIFICATES_INIT:
      return {
        ...state,
      };
    case GET_EMPLOYEE_PROFILE_HEADER_SUCCESS:
      return {
        ...state,
        profileHeader: { ...action.data },
      };

    case GET_EMPLOYEE_CERTIFICATES_SUCCESS:
      return {
        ...state,
        employeeCertificates: [...action.data],
      };

    case EMPLOYEE_PROFILE_CLEAR:
      return {
        ...initialState,
      };

    case GET_EMPLOYEE_PROFILE_INIT:
    case GET_EMPLOYEE_COMPENSATION_INIT:
    case CREATE_EMPLOYEE_COMPENSATION_INIT:
    case UPDATE_EMPLOYEE_COMPENSATION_INIT:
      return {
        ...state,
        isLoading: true,
      };

    case UPDATE_EMPLOYEE_INFO_INIT:
    case DELETE_EMPLOYEE_COMMENT_INIT:
    case CREATE_EMPLOYEE_COMMENT_INIT:
    case EMPLOYMENT_CALL_INIT:
    case JOB_INFO_CALL_INIT:
    case GET_EMPLOYEE_AUDIT_LOGS_INIT:
      return {
        ...state,
        isLoading: true,
      };

    case GET_EMPLOYEE_INFO_FAILURE:
    case GET_EMPLOYEE_COMPENSATION_FAILURE:
    case GET_EMPLOYEE_TALENT_FAILURE:
    case GET_EMPLOYEE_EMPLOYMENT_HISTORY_FAILURE:
    case GET_EMPLOYEE_EDUCATION_HISTORY_FAILURE:
    case GET_EMPLOYEE_DOCUMENTS_FAILURE:
    case GET_EMPLOYEE_COMMENTS_FAILURE:
    case GET_EMPLOYEE_AUDIT_LOGS_FAILURE:
    case GET_EMPLOYEE_PROFILE_HEADER_FAILURE:
    case GET_EMPLOYEE_CERTIFICATES_FAILURE:
    case GET_MARITAL_STATUSES_FAILURE:
    case GET_EMPLOYMENT_STATUSES_FAILURE:
    case GET_BLOOD_GROUPS_FAILURE:
    case GET_RELIGIONS_FAILURE:
    case GET_QUALIFICATIONS_FAILURE:
    case UPDATE_EMPLOYEE_INFO_FAILURE:
    case DELETE_EMPLOYEE_COMMENT_FAILURE:
    case CREATE_EMPLOYEE_COMMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case CREATE_EMPLOYEE_FAMILY_INFO_INIT:
    case UPDATE_EMPLOYEE_FAMILY_INFO_INIT:
    case DELETE_EMPLOYEE_FAMILY_INFO_INIT:
    case GET_EMPLOYEE_TRACKING_INFO_INIT:
    case UPDATE_EMPLOYEE_TRACKING_INFO_INIT:
    case CREATE_EMPLOYEE_EMERGENCY_CONTACT_INIT:
    case UPDATE_EMPLOYEE_EMERGENCY_CONTACT_INIT:
    case DELETE_EMPLOYEE_EMERGENCY_CONTACT_INIT:
      return {
        ...state,
        cardLoading: true,
      };

    case EMPLOYMENT_CALL_FAILURE: {
      const employmentArray = [...state.employmentHistory];
      return {
        ...state,
        isLoading: false,
        employmentHistory: employmentArray,
      };
    }

    case UPDATE_EMPLOYEE_EMPLOYMENT_SUCCESS: {
      const employmentHistory = [...state.employmentHistory];
      employmentHistory[action.index] = action.data;
      return {
        ...state,
        isLoading: false,
        employmentHistory,
      };
    }
    case CREATE_EMPLOYEE_EMPLOYMENT_SUCCESS: {
      const employmentHistory = [action.data, ...state.employmentHistory];
      return {
        ...state,
        isLoading: false,
        employmentHistory,
      };
    }

    case DELETE_EMPLOYEE_EMPLOYMENT_SUCCESS: {
      const oldEmployment = [...state.employmentHistory];
      const employmentHistory = oldEmployment.filter((employment) => employment.id !== action.id);
      return {
        ...state,
        isLoading: false,
        employmentHistory,
      };
    }

    case CREATE_EMPLOYEE_FAMILY_INFO_FAILURE:
    case UPDATE_EMPLOYEE_FAMILY_INFO_FAILURE:
    case DELETE_EMPLOYEE_FAMILY_INFO_FAILURE: {
      const familyInfoArray = [...state.employeeInfo.familyInfo];
      const employeeInfo = { ...state.employeeInfo };
      employeeInfo.familyInfo = familyInfoArray;
      return {
        ...state,
        cardLoading: false,
        employeeInfo,
      };
    }
    case GET_EMPLOYEE_DOCUMENT_URL_FAILURE:
      return {
        ...state,
        employeeDocumentUrl: null,
      };

    case GET_COMMENT_ATTACHMENT_URL_FAILURE:
      return {
        ...state,
        commentAttachmentUrl: null,
      };

    case GET_EMPLOYEE_DOCUMENT_URL_SUCCESS:
      return {
        ...state,
        employeeDocumentUrl: action.url,
      };

    case GET_COMMENT_ATTACHMENT_URL_SUCCESS:
      return {
        ...state,
        commentAttachmentUrl: action.url,
      };

    case CREATE_EMPLOYEE_DOCUMENT_SUCCESS: {
      const { documents } = state;
      const updateDocs = [action.data, ...documents];
      return {
        ...state,
        documents: updateDocs,
      };
    }

    case UPDATE_EMPLOYEE_PICTURE_SUCCESS: {
      const { profileHeader } = state;
      const updatedProfileHeader = {
        ...profileHeader,
        imageUrl: action.url,
        isLoading: false,
      };
      return {
        ...state,
        profileHeader: updatedProfileHeader,
      };
    }

    case UPDATE_EMPLOYEE_PICTURE_FAILURE: {
      const { profileHeader } = state;
      const updatedProfileHeader = { ...profileHeader, isLoading: false };
      return {
        ...state,
        profileHeader: updatedProfileHeader,
      };
    }

    case UPDATE_EMPLOYEE_PICTURE_INIT: {
      const { profileHeader } = state;
      const updatedProfileHeader = { ...profileHeader, isLoading: true };
      return {
        ...state,
        profileHeader: updatedProfileHeader,
      };
    }

    case CREATE_EMPLOYEE_COMMENT_SUCCESS: {
      const newComments = [...action.data, ...state.comments];
      return {
        ...state,
        comments: newComments,
        isLoading: false,
      };
    }

    case DELETE_EMPLOYEE_COMMENT_SUCCESS: {
      const newComments = state.comments.filter((comment) => comment.id !== action.id);
      return {
        ...state,
        comments: newComments,
        isLoading: false,
      };
    }

    case DELETE_EMPLOYEE_DOCUMENT_SUCCESS: {
      const oldDocuments = [...state.documents];
      const documents = oldDocuments.filter((doc) => doc.id !== action.id);
      return {
        ...state,
        documents,
      };
    }

    case CREATE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE:
    case UPDATE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE:
    case DELETE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE:
      return {
        ...state,
        cardLoading: false,
      };

    case GET_EMPLOYEE_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        employeeInfo: { ...action.data },
      };

    case UPDATE_EMPLOYEE_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        employeeInfo: {
          ...state.employeeInfo,
          info: {
            ...state.employeeInfo.info,
            ...action.data,
          },
        },
      };

    case CREATE_EMPLOYEE_EDUCATION_HISTORY_SUCCESS: {
      const newEducationHistory = [action.data, ...state.educationHistory];
      return {
        ...state,
        educationHistory: newEducationHistory,
      };
    }

    case UPDATE_EMPLOYEE_EDUCATION_HISTORY_SUCCESS: {
      const educationHistory = [...state.educationHistory];
      educationHistory[action.index] = { ...action.data, isLoading: false };
      return {
        ...state,
        educationHistory,
      };
    }

    case DELETE_EMPLOYEE_EDUCATION_HISTORY_SUCCESS: {
      const newEducationHistory = state.educationHistory.filter(
        (education) => education.id !== action.id,
      );
      return {
        ...state,
        educationHistory: newEducationHistory,
      };
    }

    case CREATE_EMPLOYEE_EDUCATION_HISTORY_FAILURE: {
      // Replace with clone to force re-render with state before failure
      const educationHistory = [...state.educationHistory];
      return {
        ...state,
        educationHistory,
      };
    }

    case UPDATE_EMPLOYEE_EDUCATION_HISTORY_FAILURE:
    case DELETE_EMPLOYEE_EDUCATION_HISTORY_FAILURE: {
      const educationHistory = [...state.educationHistory];
      educationHistory[action.index].isLoading = false;
      return {
        ...state,
        educationHistory,
      };
    }

    case CREATE_EMPLOYEE_FAMILY_INFO_SUCCESS: {
      const createFamilyInfoArray = [...state.employeeInfo.familyInfo];
      const { data } = action;
      data.dateOfBirth = moment(data.dob).format(DATE_FORMAT);
      delete data.dob;
      createFamilyInfoArray.push(data);
      const employeeInfo = { ...state.employeeInfo };
      employeeInfo.familyInfo = createFamilyInfoArray;
      return {
        ...state,
        cardLoading: false,
        employeeInfo,
      };
    }

    case UPDATE_EMPLOYEE_FAMILY_INFO_SUCCESS: {
      const updateFamilyInfoArray = [...state.employeeInfo.familyInfo];
      updateFamilyInfoArray.splice(action.index, 1, action.data);
      const employeeInfo = { ...state.employeeInfo };
      employeeInfo.familyInfo = updateFamilyInfoArray;
      return {
        ...state,
        cardLoading: false,
        employeeInfo,
      };
    }

    case DELETE_EMPLOYEE_FAMILY_INFO_SUCCESS: {
      const familyInfoArray = [...state.employeeInfo.familyInfo];
      familyInfoArray.splice(action.index, 1);
      const employeeInfo = { ...state.employeeInfo };
      employeeInfo.familyInfo = familyInfoArray;
      return {
        ...state,
        cardLoading: false,
        employeeInfo,
      };
    }

    case GET_EMPLOYEE_JOB_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        jobInfo: [...action.data],
      };

    case GET_EMPLOYEE_TRACKING_INFO_SUCCESS:
    case UPDATE_EMPLOYEE_TRACKING_INFO_SUCCESS: {
      return {
        ...state,
        cardLoading: false,
        trackingInfo: {
          ...action.data,
        },
      };
    }

    case GET_EMPLOYEE_TRACKING_INFO_FAILURE: {
      return {
        ...state,
        cardLoading: false,
      };
    }

    case UPDATE_EMPLOYEE_TRACKING_INFO_FAILURE: {
      const trackingInfo = { ...state.trackingInfo };
      return {
        ...state,
        cardLoading: false,
        trackingInfo,
      };
    }

    case GET_EMPLOYEE_COMPENSATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        compensation: [...action.data],
      };

    case CREATE_EMPLOYEE_COMPENSATION_FAILURE:
    case UPDATE_EMPLOYEE_COMPENSATION_FAILURE: {
      const compensation = { ...state.compensation };
      return {
        ...state,
        compensation,
        isLoading: false,
      };
    }

    case GET_PAY_FREQUENCIES_SUCCESS:
      return {
        ...state,
        payFrequencies: [...action.data],
      };

    case GET_BENEFITS_SUCCESS:
      return {
        ...state,
        benefits: [...action.data],
      };

    case GET_REASONS_SUCCESS:
      return {
        ...state,
        reasons: [...action.data],
      };

    case GET_EMPLOYEE_JOBS_LISTING_SUCCESS:
      return {
        ...state,
        jobsListing: [...action.data],
      };

    case GET_EMPLOYEE_TALENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        talentManagement: { ...action.data },
      };

    case GET_EMPLOYEE_EMPLOYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        employmentHistory: [...action.data],
      };

    case GET_EMPLOYEE_EDUCATION_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        educationHistory: [...action.data],
      };

    case GET_EMPLOYEE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        documents: [...action.data],
      };

    case GET_EMPLOYEE_COMMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        comments: [...action.data],
      };

    case GET_EMPLOYEE_AUDIT_LOGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        auditLogs: { ...action.data },
      };

    case GET_MARITAL_STATUSES_SUCCESS:
      return {
        ...state,
        dropDowns: { ...state.dropDowns, maritalStatuses: [...action.data] },
      };

    case GET_EMPLOYMENT_STATUSES_SUCCESS:
      return {
        ...state,
        dropDowns: {
          ...state.dropDowns,
          employmentStatuses: [...action.data],
        },
      };

    case GET_BLOOD_GROUPS_SUCCESS:
      return {
        ...state,
        dropDowns: { ...state.dropDowns, bloodGroups: [...action.data] },
      };

    case GET_RELIGIONS_SUCCESS:
      return {
        ...state,
        dropDowns: { ...state.dropDowns, religions: [...action.data] },
      };

    case GET_QUALIFICATIONS_SUCCESS:
      return {
        ...state,
        dropDowns: { ...state.dropDowns, qualifications: [...action.data] },
      };

    case CREATE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS:
      return {
        ...state,
        cardLoading: false,
        employeeInfo: {
          ...state.employeeInfo,
          emergencyContacts: [...state.employeeInfo.emergencyContacts, action.data],
        },
      };

    case UPDATE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS: {
      return {
        ...state,
        cardLoading: false,
        employeeInfo: {
          ...state.employeeInfo,
        },
      };
    }

    case DELETE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS: {
      const oldEmergencyContacts = [...state.employeeInfo.emergencyContacts];
      const emergencyContacts = oldEmergencyContacts.filter(
        (contact) => contact.id !== action.emergencyContactId,
      );
      return {
        ...state,
        cardLoading: false,
        employeeInfo: {
          ...state.employeeInfo,
          emergencyContacts,
        },
      };
    }

    case JOB_INFO_CALL_FAILURE: {
      const jobInfo = [...state.jobInfo];
      return {
        ...state,
        isLoading: false,
        jobInfo,
      };
    }

    case CREATE_EMPLOYEE_JOB_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        jobInfo: [...state.jobInfo, action.data],
      };
    }

    case UPDATE_EMPLOYEE_JOB_INFO_SUCCESS: {
      const jobInfo = [...state.jobInfo];
      jobInfo[action.index] = { ...action.data, isLoading: false };

      return {
        ...state,
        isLoading: false,
        jobInfo,
      };
    }

    case DELETE_EMPLOYEE_JOB_INFO_SUCCESS: {
      const jobInfo = state.jobInfo.filter((job) => job.id !== action.id);
      return {
        ...state,
        isLoading: false,
        jobInfo,
      };
    }

    case UPDATE_EMPLOYEE_TALENT_SUCCESS:
      return {
        ...state,
        talentManagement: {
          ...state.talentManagement,
          ...action.data,
          isLoading: false,
          isEdit: false,
        },
      };

    case UPDATE_EMPLOYEE_TALENT_FAILURE:
      return {
        ...state,
        talentManagement: {
          ...state.talentManagement,
          isLoading: false,
        },
      };

    case UPDATE_EMPLOYEE_CAREER_PLANNING_SUCCESS:
      return {
        ...state,
        talentManagement: {
          ...state.talentManagement,
          careerPlanning: {
            ...action.data,
            isLoading: false,
            isEdit: false,
          },
        },
      };

    case UPDATE_EMPLOYEE_CAREER_PLANNING_FAILURE:
      return {
        ...state,
        talentManagement: {
          ...state.talentManagement,
          careerPlanning: {
            ...state.talentManagement.careerPlanning,
            isLoading: false,
          },
        },
      };

    case CREATE_EMPLOYEE_CERTIFICATES_SUCCESS: {
      const newCertificates = [action.data, ...state.employeeCertificates];
      return {
        ...state,
        employeeCertificates: [...newCertificates],
      };
    }

    case UPDATE_EMPLOYEE_CERTIFICATES_SUCCESS: {
      const certificates = [...state.employeeCertificates];
      certificates[action.index] = { ...action.data, isLoading: false };
      return {
        ...state,
        employeeCertificates: [...certificates],
      };
    }

    case DELETE_EMPLOYEE_CERTIFICATES_SUCCESS: {
      const newCertificates = state.employeeCertificates.filter((data) => data.id !== action.id);
      return {
        ...state,
        employeeCertificates: [...newCertificates],
      };
    }

    case CREATE_EMPLOYEE_CERTIFICATES_FAILURE: {
      // Replace with clone to force re-render with state before failure
      const certificates = [...state.employeeCertificates];
      return {
        ...state,
        employeeCertificates: certificates,
      };
    }

    case UPDATE_EMPLOYEE_CERTIFICATES_FAILURE:
    case DELETE_EMPLOYEE_CERTIFICATES_FAILURE: {
      const certificates = [...state.employeeCertificates];

      if (certificates[action.index]) {
        certificates[action.index].isLoading = false;
      }

      return {
        ...state,
        employeeCertificates: certificates,
      };
    }

    case GET_PERFORMANCE_REVIEW_INIT: {
      return {
        ...state,
        goals: [],
        isLoading: true,
      };
    }
    case GET_PERFORMANCE_REVIEW_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        performanceReviews: action.data,
      };
    }
    case GET_PERFORMANCE_REVIEW_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
