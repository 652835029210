import {
  getPEFData,
  submitPEFData,
  getMyPEFData,
  submitMyPEFData,
  submitForApproval,
  approvePEF,
  approvePEFStatus,
  reopenPEF,
  markPEFAsFinal,
  sendEvaluationsForApproval,
} from 'sources/PerformanceEvaluationFormSource';

import {
  GET_PEF_DATA_INIT,
  GET_PEF_DATA_SUCCESS,
  GET_PEF_DATA_FAILURE,
  SUBMIT_PEF_DATA_INIT,
  SUBMIT_PEF_DATA_SUCCESS,
  SUBMIT_PEF_DATA_FAILURE,
  SUBMIT_FOR_APPROVAL_INIT,
  SUBMIT_FOR_APPROVAL_SUCCESS,
  SUBMIT_FOR_APPROVAL_FAILURE,
  APPROVE_PEF_INIT,
  APPROVE_PEF_SUCCESS,
  APPROVE_PEF_FAILURE,
} from 'constants/PerformanceEvaluationFormTypes';

export const getPEFDataInit = () => ({
  type: GET_PEF_DATA_INIT,
});

export const getPEFDataSuccess = (data) => ({
  type: GET_PEF_DATA_SUCCESS,
  data,
});

export const getPEFDataFailure = (data) => ({
  type: GET_PEF_DATA_FAILURE,
  data,
});

export const submitPEFDataInit = () => ({
  type: SUBMIT_PEF_DATA_INIT,
});

export const submitPEFDataSuccess = (data) => ({
  type: SUBMIT_PEF_DATA_SUCCESS,
  data,
});

export const submitPEFDataFailure = (data) => ({
  type: SUBMIT_PEF_DATA_FAILURE,
  data,
});

export const submitForApprovalInit = () => ({
  type: SUBMIT_FOR_APPROVAL_INIT,
});

export const submitForApprovalSuccess = (data) => ({
  type: SUBMIT_FOR_APPROVAL_SUCCESS,
  data,
});

export const submitForApprovalFailure = (data) => ({
  type: SUBMIT_FOR_APPROVAL_FAILURE,
  data,
});

export const approvePEFInit = () => ({
  type: APPROVE_PEF_INIT,
});

export const approvePEFSuccess = (data) => ({
  type: APPROVE_PEF_SUCCESS,
  data,
});

export const approvePEFFailure = (data) => ({
  type: APPROVE_PEF_FAILURE,
  data,
});

export const getPEFDataActions = (requestObject) => (dispatch) => {
  dispatch(getPEFData(requestObject));
};

export const submitPEFDataActions = (requestObject) => (dispatch) => {
  dispatch(submitPEFData(requestObject));
};

export const getMyPEFDataActions = (requestObject) => (dispatch) => {
  dispatch(getMyPEFData(requestObject));
};

export const submitMyPEFDataActions = (requestObject) => (dispatch) => {
  dispatch(submitMyPEFData(requestObject));
};

export const submitForApprovalAction = (requestObject) => (dispatch) => {
  dispatch(submitForApproval(requestObject));
};

export const approvePEFAction = (requestObject) => (dispatch) => {
  dispatch(approvePEF(requestObject));
};

export const approvePEFStatusAction = (requestObject) => (dispatch) => {
  dispatch(approvePEFStatus(requestObject));
};

export const reopenPEFAction = (requestObject) => (dispatch) => {
  dispatch(reopenPEF(requestObject));
};

export const markPEFAsFinalAction = (requestObject) => (dispatch) => {
  dispatch(markPEFAsFinal(requestObject));
};

export const sendEvaluationsForApprovalAction = (requestObject) => (dispatch) => {
  dispatch(sendEvaluationsForApproval(requestObject));
};
