import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ReportContainer from 'containers/ReportContainer';
import DefaulterContainer from 'containers/DefaulterContainer';
import { ROOT } from 'configs/routesConstants';

class AdminRoutes extends React.Component {
  componentDidMount() {
    this.checkPermissions(this.props.isAdmin);
  }

  componentWillReceiveProps(nextProps) {
    this.checkPermissions(nextProps.isAdmin);
  }

  checkPermissions = (isAdmin) => {
    if (!isAdmin && this.props.history.location.pathname !== ROOT) {
      this.props.history.push(ROOT);
    }
  };

  render() {
    const { match } = this.props;
    return (
      <Switch>
        <Route path={`${match.path}/report`} component={ReportContainer} />
        <Route path={`${match.path}/missing-allocations`} component={DefaulterContainer} />
      </Switch>
    );
  }
}

export default AdminRoutes;
