/* eslint-disable */
import {
  EnhancedIcon,
  EnhancedRow,
  EnhancedCol,
  EnhancedAlert,
  EnhancedLayout,
  EnhancedSelect,
  EnhancedDatePicker,
  EnhancedInput,
  EnhancedForm,
} from 'components/shared/antd';
import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import RatingModal from 'components/pc/ratingModal';
import { dateFormat, BREADCRUMBS } from 'configs/constants';
import RequiredField from '../RequiredField';

const { Header } = EnhancedLayout;
const { Option } = EnhancedSelect;

class PerformanceCycle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: {
        valueQuality: 0,
        valueEfficiency: 0,
        valueSkill: 0,
        valueOwnership: 0,
        valueEthics: 0,
        valueDocumentation: 0,
        valueCollaboration: 0,
        valueCodeQuality: 0,
      },
      showAddNewForm: false,
      /*      searchProjectText: '',
      searchManagerText: '', */
      errorText: '',
    };
  }

  componentDidMount() {
    this.props.getProjectRatingAction(
      this.props.match.params.id,
      this.props.match.params.projectId,
    );
  }

  componentWillReceiveProps(nextProps) {
    const { projectRating, updateBreadCrumb, match } = nextProps;
    const previousProjectRating = this.props.projectRating;
    const { projectName, cycleName } = projectRating;
    const { params } = match;
    const { id, projectId } = params;

    if (previousProjectRating.cycleName !== cycleName) {
      updateBreadCrumb(BREADCRUMBS.CYCLES, id, cycleName);
    }
    if (previousProjectRating.projectName !== projectName) {
      updateBreadCrumb(BREADCRUMBS.PROJECT_CYCLE, projectId, projectName);
    }
    /*    if (this.props.match.params.status !== nextProps.match.params.status) {
      this.clearSearch();
    }
    if (!nextProps.isAdding && this.props.isAdding && this.state.modalOpen) {
      this.handleCancel();
    } */
  }
  onChange = (field, value) => {
    const project = { ...this.state.project };
    project[field] = value;
    this.setState({
      project,
    });
  };

  onStartDateChange = (value, option) => {
    this.onChange('startDate', option);
  };

  onEndDateChange = (value, option) => {
    this.onChange('endDate', option);
  };

  /*  onSearchProjectName = (e) => {
        const searchProjectText = e.target.value;
    this.setState({
      searchProjectText,
    });
  }
  onSearchProjectManager = (e) => {
        const searchManagerText = e.target.value;
    this.setState({
      searchManagerText,
    });
  }
  onClose = () => {
    this.setState({
      errorText: '',
      project: {
        ...this.state.project,
        name: '',
      },
    });
  } */

  getColumnsInfo = () => {
    let columns = [
      {
        title: 'Resource Name',
        dataIndex: 'name',
        key: 'name',
        width: '30%',
        render: this.renderProjectName,
        filterConfig: {
          type: 'search',
          key: 'name',
        },
      },
      {
        title: 'Line Manager',
        dataIndex: 'lineManager',
        key: 'lineManager',
        width: '30%',
        filterConfig: {
          type: 'search',
          key: 'lineManager',
        },
      },
      {
        title: 'Status',
        dataIndex: 'freeze',
        width: '25%',
        render: this.renderStatusName,
      },
      {
        title: 'Action',
        width: '25%',
        render: this.renderIcon,
      },
    ];

    return columns;
  };

  editEmployeeInProject = (data) => {
    const { getRatingDetails, userId } = this.props;
    const payload = {
      performanceCycleId: this.props.match.params.id,
      projectId: this.props.match.params.projectId,
      raterId: userId,
      rateeId: data.rateeId,
    };
    getRatingDetails(payload);
    this.setState({
      rating: data,
      addEditResourceModalVisible: true,
    });
  };

  handleEditClose = (close) => {
    this.setState({ addEditResourceModalVisible: close });
  };

  showTotal = (total) => `Total ${total} items`;

  showAddNew = () => {
    this.setState({
      showAddNewForm: !this.state.showAddNewForm,
    });
  };

  handleCancel = () => {
    this.setState({
      project: {
        name: '',
        startDate: '',
        endDate: '',
        projectManagerId: '',
      },
      errorText: '',
    });
  };

  handleNameChange = (value) => {
    const project = { ...this.state.project };
    project.name = value.target.value;
    this.setState({
      project,
    });
  };

  handleManagerChange = (manager) => {
    this.onChange('projectManagerId', manager);
  };

  disabledStartDate = (startValue) => {
    const endValue = this.state.project.endDate ? moment.utc(this.state.project.endDate) : Date();
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = (endValue) => {
    const startValue = moment.utc(this.state.project.startDate).add(1, 'days');
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  /* handleSave = (value) => {
    value.preventDefault();
    const { name } = this.state.project;
    const { projects } = this.props;
    const similarProject = projects.filter(project =>
      project.name.toLowerCase() === name.toLowerCase())[0];

    if (similarProject) {
      this.setState({
        errorText: 'Project with the same name already exists.',
      });
    } else {
      this.props.addProject(this.state.project);
      this.handleCancel();
    }
  } */
  shouldButtonDisable = () => {
    const { project } = this.state;
    if (project.name && project.projectManagerId && project.startDate) return false;
    return true;
  };
  clearSearch = () => {
    this.setState({
      /*     searchManagerText: '',
      searchProjectText: '', */
    });
  };

  handleSubmit = (value) => {};

  renderProjectName = (name, data) => <b style={{ color: '#5A5A5A' }}>{name}</b>;

  renderStatusName = (freeze) => {
    let statusText = 'Pending';
    if (freeze) {
      statusText = 'Complete';
    }
    return <b style={{ color: '#5A5A5A' }}>{statusText}</b>;
  };

  renderIcon = (name, data) => {
    const { projectRating } = this.props;
    const { dueDate, completeStatus } = projectRating;

    const keyStatus = 'freeze';
    const dueDateMoment = moment(dueDate);
    const currentDate = moment();
    const isView = currentDate > dueDateMoment;
    const iconType = data[keyStatus] || completeStatus || isView ? 'eye' : 'edit';

    return (
      <EnhancedIcon
        onClick={() => this.editEmployeeInProject(data)}
        type={iconType}
        style={{
          color: data[keyStatus] || completeStatus ? '#bcbcff' : 'green',
          name,
        }}
      />
    );
  };

  renderFormRow = () => {
    const { employees } = this.props;
    const { errorText } = this.state;
    return (
      <EnhancedRow type="flex" justify="center">
        {errorText && (
          <EnhancedAlert
            style={{ width: '100%' }}
            message={errorText}
            type="error"
            closable
            onClose={this.onClose}
          />
        )}
        <EnhancedForm layout="inline">
          <EnhancedCol span={24} className="form-col">
            <p>
              <RequiredField>Project Name </RequiredField>
            </p>
            <EnhancedInput
              placeholder="Project Name"
              id="projectName"
              onChange={this.handleNameChange}
              value={this.state.project.name}
            />
          </EnhancedCol>
          <EnhancedCol span={24} className="form-col">
            <p>
              <RequiredField>Project Manager</RequiredField>
            </p>
            <EnhancedSelect
              showSearch
              allowClear
              optionFilterProp="children"
              value={this.state.project.projectManagerId}
              style={{ width: '100%' }}
              placeholder="Please Select"
              onChange={this.handleManagerChange}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {employees && employees.length
                ? employees.map((employee) =>
                    employee.isActive ? (
                      <Option key={employee.id} value={employee.id}>
                        {employee.name}
                      </Option>
                    ) : (
                      ''
                    ),
                  )
                : null}
            </EnhancedSelect>
          </EnhancedCol>
          <EnhancedCol span={24} className="form-col">
            <p>
              <RequiredField>Start Date</RequiredField>
            </p>
            <EnhancedDatePicker
              style={{ width: '100%' }}
              onChange={this.onStartDateChange}
              placeholder="Enter Start Date"
              format={dateFormat.DDMMMYYYYwithSpace}
              allowClear
              disabledDate={this.disabledStartDate}
            />
          </EnhancedCol>
          <EnhancedCol span={24} className="form-col">
            <p>End Date</p>
            <EnhancedDatePicker
              style={{ width: '100%' }}
              onChange={this.onEndDateChange}
              placeholder="Enter End Date"
              format={dateFormat.DDMMMYYYYwithSpace}
              allowClear
              disabledDate={this.disabledEndDate}
            />
          </EnhancedCol>
        </EnhancedForm>
      </EnhancedRow>
    );
  };

  render() {
    const { isLoading, projectRating, ratingDetails, resetRatingDetails } = this.props;
    let { projectName, cycleName, dueDate } = projectRating;
    projectName = projectName || '';
    cycleName = cycleName || '';
    return (
      <div>
        <RatingModal
          ratingDetails={ratingDetails}
          dueDate={dueDate}
          onModalClose={this.handleEditClose}
          userId={this.props.userId}
          visible={this.state.addEditResourceModalVisible}
          addProjectRatingAction={this.props.addProjectRatingAction}
          cycleId={this.props.match.params.id}
          projectId={this.props.match.params.projectId}
          resetRatingDetails={resetRatingDetails}
        />
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol lg={24}>
              <h1 className="heading-pro">{`${projectName} - ${cycleName}`}</h1>
            </EnhancedCol>
            {/* <Col lg={12} style={{ textAlign: 'right' }} /> */}
          </EnhancedRow>
        </Header>
        <EnhancedCol lg={24} className="project-table">
          <EnhancedClientSideTable
            loading={isLoading}
            columns={this.getColumnsInfo()}
            data={projectRating.projectRatings || []}
          />
        </EnhancedCol>
      </div>
    );
  }
}

export default PerformanceCycle;
