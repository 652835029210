import React from 'react';
import { Empty } from 'antd';
import PropTypes from 'prop-types';

const EnhancedEmpty = (props) => {
  const { className, style, description } = props;

  return <Empty style={style} className={className} description={description} />;
};

EnhancedEmpty.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  description: PropTypes.string,
};

EnhancedEmpty.defaultProps = {
  className: '',
  style: {},
  description: '',
};

export default EnhancedEmpty;
