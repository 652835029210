export const normalizeDirectTeamLeaveBalanceList = ({ leaveDetails, employee } = []) => {
  const { Sick, Casual, Annual } = leaveDetails;
  const { name, department, managerName, designation, taleoId, email } = employee;
  const NOT_AVAILABLE = 'N/A';
  return {
    name: name || NOT_AVAILABLE,
    deptt: department || NOT_AVAILABLE,
    designation: designation || NOT_AVAILABLE,
    lineManager: managerName || NOT_AVAILABLE,
    sick: Sick ? Sick.quota : NOT_AVAILABLE,
    annual: Annual ? Annual.quota : NOT_AVAILABLE,
    casual: Casual ? Casual.quota : NOT_AVAILABLE,
    taleoId: taleoId || NOT_AVAILABLE,
    email: email || NOT_AVAILABLE,
  };
};

export const normalizeIndirectTeamLeaveBalanceList = (teamLeaveBalanceRequest) => ({
  leaveId: teamLeaveBalanceRequest.leaveId,
  firstName: teamLeaveBalanceRequest.firstName || '',
  lastName: teamLeaveBalanceRequest.lastName || '',
  deptt: teamLeaveBalanceRequest.deptt || '',
  annualUsed: teamLeaveBalanceRequest.annualUsed || '',
  annualTotal: teamLeaveBalanceRequest.annualTotal || '',
  casualUsed: teamLeaveBalanceRequest.casualUsed || '',
  casualTotal: teamLeaveBalanceRequest.casualTotal || '',
  sickUsed: teamLeaveBalanceRequest.sickUsed || '',
  sickTotal: teamLeaveBalanceRequest.sickTotal || '',
  lineManager: teamLeaveBalanceRequest.lineManager || '',
});

export function normalizeTeamLeaveBalance(teamLeaveBalanceRequest) {
  return teamLeaveBalanceRequest.map((tlb) => normalizeDirectTeamLeaveBalanceList(tlb));
}
