import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'utils/stringUtils';
// import { roundOffRating } from 'utils/conversionUtils';
import { Link } from 'react-router-dom';

const RatingRenderer = (props) => {
  const { employeeId, performanceCycleId, rating, reviewType, baseUrl } = props;

  const link = parse(baseUrl, {
    employeeId,
    performanceCycleId,
    reviewType,
  });

  return (
    <Link target="_blank" to={link}>
      <b className="underline">{rating}</b>
    </Link>
  );
};

RatingRenderer.propTypes = {
  employeeId: PropTypes.number.isRequired,
  performanceCycleId: PropTypes.number.isRequired,
  reviewType: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

export default RatingRenderer;
