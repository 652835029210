import PropTypes from 'prop-types';
import React from 'react';
import { EnhancedRate } from 'components/shared/antd';
import { roundOffRating } from 'utils/conversionUtils';
import RatingText from './ratingText';

const Ratings = (props) => {
  return (
    <div>
      <span className="mr-10">
        {props.reviewType.charAt(0).toUpperCase() + props.reviewType.slice(1)} Rating:{' '}
      </span>
      <EnhancedRate style={{ lineHeight: '20px' }} disabled allowHalf value={props.value} />
      <span className="mr-10">{roundOffRating(props.value)}</span>
      <RatingText rating={props.value} ratingOptions={props.options} />
    </div>
  );
};

Ratings.propTypes = {
  reviewType: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  options: PropTypes.arrayOf.isRequired,
};

export default Ratings;
