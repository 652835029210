import React from 'react';
import { Col } from 'antd';
import PropTypes from 'prop-types';

const EnhancedCol = (props) => {
  const {
    flex,
    className,
    style,
    span,
    offset,
    key,
    lg,
    sm,
    xs,
    md,
    children,
    align,
    onClick,
    ...rest
  } = props;

  return (
    <Col
      flex={flex}
      className={className}
      style={style}
      span={span}
      offset={offset}
      key={key}
      lg={lg}
      sm={sm}
      xs={xs}
      md={md}
      align={align}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Col>
  );
};

EnhancedCol.propTypes = {
  flex: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  span: PropTypes.number.isRequired,
  offset: PropTypes.number,
  align: PropTypes.string.isRequired,
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]).isRequired,
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]).isRequired,
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]).isRequired,
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]).isRequired,
  onClick: PropTypes.func,
};

EnhancedCol.defaultProps = {
  offset: 0,
  className: '',
  style: {},
  onClick: () => {},
};

export default EnhancedCol;
