export const ADD_PMCYCLE_INIT = 'ADD_PMCYCLE_INIT';
export const ADD_PMCYCLE_SUCCESS = 'ADD_PMCYCLE_SUCCESS';
export const ADD_PMCYCLE_FAILURE = 'ADD_PMCYCLE_FAILURE';

export const EDIT_PMCYCLE_INIT = 'EDIT_PMCYCLE_INIT';
export const EDIT_PMCYCLE_SUCCESS = 'EDIT_PMCYCLE_SUCCESS';
export const EDIT_PMCYCLE_FAILURE = 'EDIT_PMCYCLE_FAILURE';

export const DELETE_PMCYCLE_INIT = 'DELETE_PMCYCLE_INIT';
export const DELETE_PMCYCLE_SUCCESS = 'DELETE_PMCYCLE_SUCCESS';
export const DELETE_PMCYCLE_FAILURE = 'DELETE_PMCYCLE_FAILURE';

export const GET_PMCYCLE_INIT = 'GET_PMCYCLE_INIT';
export const GET_PMCYCLE_SUCCESS = 'GET_PMCYCLE_SUCCESS';
export const GET_PMCYCLE_FAILURE = 'GET_PMCYCLE_FAILURE';

export const GET_PREVIOUS_PMCYCLE_INIT = 'GET_PREVIOUS_PMCYCLE_INIT';
export const GET_PREVIOUS_PMCYCLE_SUCCESS = 'GET_PREVIOUS_PMCYCLE_SUCCESS';
export const GET_PREVIOUS_PMCYCLE_FAILURE = 'GET_PREVIOUS_PMCYCLE_FAILURE';

export const GET_PM_EMP_CYCLE_INIT = 'GET_PM_EMP_CYCLE_INIT';
export const GET_PM_EMP_CYCLE_SUCCESS = 'GET_PM_EMP_CYCLE_SUCCESS';
export const GET_PM_EMP_CYCLE_FAILURE = 'GET_PM_EMP_CYCLE_FAILURE';

export const GET_PMCYCLE_DETAIL_INIT = 'GET_PMCYCLE_DETAIL_INIT';
export const GET_PMCYCLE_DETAIL_SUCCESS = 'GET_PMCYCLE_DETAIL_SUCCESS';
export const GET_PMCYCLE_DETAIL_FAILURE = 'GET_PMCYCLE_DETAIL_FAILURE';

export const ADD_EMPLOYEES_PM_CYCLE_INIT = 'ADD_EMPLOYEES_PM_CYCLE_INIT';
export const ADD_EMPLOYEES_PM_CYCLE_SUCCESS = 'ADD_EMPLOYEES_PM_CYCLE_SUCCESS';
export const ADD_EMPLOYEES_PM_CYCLE_FAILURE = 'ADD_EMPLOYEES_PM_CYCLE_FAILURE';
export const DELETE_EMPLOYEE_PM_CYCLE_INIT = 'DELETE_EMPLOYEE_PM_CYCLE_INIT';
export const DELETE_EMPLOYEE_PM_CYCLE_SUCCESS = 'DELETE_EMPLOYEE_PM_CYCLE_SUCCESS';
export const DELETE_EMPLOYEE_PM_CYCLE_FAILURE = 'DELETE_EMPLOYEE_PM_CYCLE_FAILURE';

export const GET_RATING_OPTIONS_INIT = 'GET_RATING_OPTIONS_INIT';
export const GET_RATING_OPTIONS_SUCCESS = 'GET_RATING_OPTIONS_SUCCESS';
export const GET_RATING_OPTIONS_FAILURE = 'GET_RATING_OPTIONS_FAILURE';
