import React from 'react';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';

const EnhancedCheckbox = (props) => {
  const {
    children,
    className,
    style,
    name,
    checked,
    onChange,
    disabled,
    indeterminate,
    autoFocus,
    ...rest
  } = props;

  return (
    <Checkbox
      style={style}
      className={className}
      name={name}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      indeterminate={indeterminate}
      autoFocus={autoFocus}
      {...rest}
    >
      {children}
    </Checkbox>
  );
};

EnhancedCheckbox.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
};

EnhancedCheckbox.defaultProps = {
  className: '',
  style: {},
  checked: false,
  disabled: false,
  indeterminate: false,
  name: '',
  onChange: () => {},
  autoFocus: false,
};

EnhancedCheckbox.Group = Checkbox.Group;

export default EnhancedCheckbox;
