import {
  GET_TRANSIENT_RESOURCE_INIT,
  GET_TRANSIENT_RESOURCE_SUCCESS,
  GET_TRANSIENT_RESOURCE_FAILURE,
  CREATE_TRANSIENT_RESOURCE_INIT,
  CREATE_TRANSIENT_RESOURCE_SUCCESS,
  CREATE_TRANSIENT_RESOURCE_FAILURE,
  UPDATE_TRANSIENT_RESOURCE_INIT,
  UPDATE_TRANSIENT_RESOURCE_SUCCESS,
  UPDATE_TRANSIENT_RESOURCE_FAILURE,
  GET_BENCH_SUMMARY_INIT,
  GET_BENCH_SUMMARY_SUCCESS,
  GET_BENCH_SUMMARY_FAILURE,
  GET_RESOURCE_DEFICIT_REPORT_INIT,
  GET_RESOURCE_DEFICIT_REPORT_SUCCESS,
  GET_RESOURCE_DEFICIT_REPORT_FAILURE,
} from 'constants/resourcePlannerTypes';
import {
  DELETE_TRANSIENT_RESOURCE_FAILURE,
  DELETE_TRANSIENT_RESOURCE_INIT,
  DELETE_TRANSIENT_RESOURCE_SUCCESS,
} from '../../constants/resourcePlannerTypes';

const initialState = {
  transientResources: [],
  benchResources: [],
  pageInfo: {},
  isLoading: false,
  resourceDeficit: [],
};

export default function resourcePlannerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSIENT_RESOURCE_INIT:
      return {
        ...state,
        isLoading: true,
        pageInfo: {},
        transientResources: [],
      };
    case GET_TRANSIENT_RESOURCE_SUCCESS:
      return {
        ...state,
        transientResources: [...action.data.content],
        pageInfo: { ...action.data.pageInfo },
        isLoading: false,
      };
    case GET_TRANSIENT_RESOURCE_FAILURE:
      return {
        ...state,
        isLoading: false,
        transientResources: [],
        pageInfo: {},
      };
    case CREATE_TRANSIENT_RESOURCE_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_TRANSIENT_RESOURCE_SUCCESS:
      return {
        ...state,
        transientResources: [...state.transientResources, action.data],
        isLoading: false,
      };
    case CREATE_TRANSIENT_RESOURCE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_TRANSIENT_RESOURCE_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_TRANSIENT_RESOURCE_SUCCESS: {
      const id = action.data;
      const { transientResources } = state;
      const filteredTransientResources = transientResources.filter((elem) => elem.id !== id);
      return {
        ...state,
        isLoading: false,
        transientResources: filteredTransientResources,
      };
    }
    case DELETE_TRANSIENT_RESOURCE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_TRANSIENT_RESOURCE_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_TRANSIENT_RESOURCE_SUCCESS: {
      const { transientResources } = state;
      const filteredTransientResources = transientResources.map((resource) => {
        if (resource.id === action.data[0].id) {
          return action.data[0];
        }
        return resource;
      });
      return {
        ...state,
        isLoading: false,
        transientResources: filteredTransientResources,
      };
    }
    case UPDATE_TRANSIENT_RESOURCE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_BENCH_SUMMARY_INIT:
      return {
        ...state,
        isLoading: true,
        pageInfo: {},
        benchResources: [],
      };
    case GET_BENCH_SUMMARY_SUCCESS:
      return {
        ...state,
        benchResources: [...action.data.result],
        pageInfo: { ...action.data.pageInfo },
        isLoading: false,
      };
    case GET_BENCH_SUMMARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        benchResources: [],
        pageInfo: {},
      };
    case GET_RESOURCE_DEFICIT_REPORT_INIT:
      return {
        ...state,
        isLoading: true,
        pageInfo: {},
        resourceDeficit: [],
      };
    case GET_RESOURCE_DEFICIT_REPORT_SUCCESS:
      return {
        ...state,
        resourceDeficit: [...action.data.result],
        pageInfo: { ...action.data.pageInfo },
        isLoading: false,
      };
    case GET_RESOURCE_DEFICIT_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        resourceDeficit: [],
        pageInfo: {},
      };
    default:
      return state;
  }
}
