import React, { Component } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import { EnhancedCol, EnhancedRow, EnhancedCard, EnhancedLayout } from 'components/shared/antd';

const { Header } = EnhancedLayout;
export default class LeaveDashboard extends Component {
  componentDidMount() {
    //   this.checkPermissions(this.props.isManager);
    this.props.getLeaveCalendar();
  }

  render() {
    const localizer = BigCalendar.momentLocalizer(moment);
    const { loader, leaveCalendar } = this.props;
    return (
      <EnhancedRow style={{ backgroundColor: '#fff', marginTop: '20px' }}>
        <EnhancedCol span={24}>
          <Header className="leaves-header">
            <EnhancedCol lg={24} md={24} sm={24} xs={24}>
              <h1>Leave Calendar</h1>
            </EnhancedCol>
          </Header>
          <EnhancedCard bordered={false} loading={loader}>
            <h2 style={{ textAlign: 'center' }}>Team Leaves Timeline</h2>
            <BigCalendar
              localizer={localizer}
              events={leaveCalendar}
              startAccessor="start"
              endAccessor="end"
              popup
              className="rbc-month-view"
              style={{ minHeight: '580px', backgroundColor: 'white' }}
              views={['month']}
            />
          </EnhancedCard>
        </EnhancedCol>
      </EnhancedRow>
    );
  }
}
