import { connect } from 'react-redux';

import PerformanceCycleCRMC from 'components/pc/PerformanceCycleCRMC';
import { getProjectsAction } from 'store/actions/ProjectActions';
import {
  getPerformanceCRMCCyclesAction,
  createPerformanceCycleAction,
  markPerformanceCycleCompleteAction,
} from 'store/actions/PerformanceCycleActions';

function mapStateToProps(state) {
  const { auth, performanceCycleReducer, projectReducer } = state;
  return {
    userId: auth.user && auth.user.id,
    readOnly: auth.user && auth.user.isReadOnly,
    isCRMC1: auth.user && auth.user.isCRMC1,
    isCRMC2: auth.user && auth.user.isCRMC2,
    performanceCycle: performanceCycleReducer.performanceCycle,
    isLoading: performanceCycleReducer.isLoading,
    projects: projectReducer.projects.filter(
      (project) =>
        project.status === true &&
        !['Admin', 'HR', 'Leaves', 'Holiday', 'Services/Management'].includes(project.name),
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectsAction: () => dispatch(getProjectsAction()),
    getPerformanceCRMCCyclesAction: (employeeId) =>
      dispatch(getPerformanceCRMCCyclesAction(employeeId)),
    createPerformanceCycleAction: (req) => dispatch(createPerformanceCycleAction(req)),
    markPerformanceCycleCompleteAction: (req) => dispatch(markPerformanceCycleCompleteAction(req)),
  };
}

const PerformanceCycleCRMCContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PerformanceCycleCRMC);

export default PerformanceCycleCRMCContainer;
