export const GET_TRANSIENT_RESOURCE_INIT = 'GET_TRANSIENT_RESOURCE_INIT';
export const GET_TRANSIENT_RESOURCE_SUCCESS = 'GET_TRANSIENT_RESOURCE_SUCCESS';
export const GET_TRANSIENT_RESOURCE_FAILURE = 'GET_TRANSIENT_RESOURCE_FAILURE';

export const CREATE_TRANSIENT_RESOURCE_INIT = 'CREATE_TRANSIENT_RESOURCE_INIT';
export const CREATE_TRANSIENT_RESOURCE_SUCCESS = 'CREATE_TRANSIENT_RESOURCE_SUCCESS';
export const CREATE_TRANSIENT_RESOURCE_FAILURE = 'CREATE_TRANSIENT_RESOURCE_FAILURE';

export const DELETE_TRANSIENT_RESOURCE_INIT = 'DELETE_TRANSIENT_RESOURCE_INIT';
export const DELETE_TRANSIENT_RESOURCE_SUCCESS = 'DELETE_TRANSIENT_RESOURCE_SUCCESS';
export const DELETE_TRANSIENT_RESOURCE_FAILURE = 'DELETE_TRANSIENT_RESOURCE_FAILURE';

export const UPDATE_TRANSIENT_RESOURCE_INIT = 'UPDATE_TRANSIENT_RESOURCE_INIT';
export const UPDATE_TRANSIENT_RESOURCE_SUCCESS = 'UPDATE_TRANSIENT_RESOURCE_SUCCESS';
export const UPDATE_TRANSIENT_RESOURCE_FAILURE = 'UPDATE_TRANSIENT_RESOURCE_FAILURE';

export const GET_BENCH_SUMMARY_INIT = 'GET_BENCH_SUMMARY_INIT';
export const GET_BENCH_SUMMARY_SUCCESS = 'GET_BENCH_SUMMARY_SUCCESS';
export const GET_BENCH_SUMMARY_FAILURE = 'GET_BENCH_SUMMARY_FAILURE';

export const GET_RESOURCE_DEFICIT_REPORT_INIT = 'GET_RESOURCE_DEFICIT_REPORT_INIT';
export const GET_RESOURCE_DEFICIT_REPORT_SUCCESS = 'GET_RESOURCE_DEFICIT_REPORT_SUCCESS';
export const GET_RESOURCE_DEFICIT_REPORT_FAILURE = 'GET_RESOURCE_DEFICIT_REPORT_FAILURE';
