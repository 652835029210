import { EnhancedIcon, EnhancedPopover } from 'components/shared/antd';
import React from 'react';

const AttributeTitle = (props) => {
  const { title, description } = props;

  const getPopoverContent = () => <pre>{description}</pre>;

  return (
    <p className="mt-16">
      <b className="mr-16">{title}</b>
      <EnhancedPopover content={getPopoverContent()} title={title}>
        <EnhancedIcon type="info-circle" className="color-grey" />
      </EnhancedPopover>
    </p>
  );
};

export default AttributeTitle;
