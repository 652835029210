import { EnhancedRow, EnhancedButton, EnhancedTag, EnhancedSelect } from 'components/shared/antd';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';
import React, { useState, useEffect } from 'react';
import { ATTRIBUTES } from 'configs/leaveConstants';
import evaluationStatus from 'configs/performanceManagementConstants';
import { goalStatusToClassMapping } from 'configs/constants';
import AccessPermissions from 'constants/AccessPermissions';
import { hasPermission } from 'utils/AccessUtils';
import { reviewTypes } from 'configs/employeeProfileConstants';
import { PERFORMANCE_MANAGEMENT_ROUTES } from 'constants/UrlConstants';
import RatingRenderer from './RatingRenderer';
import ConfirmModal from '../performanceEvaluationForm/ConfirmModal';
import statusConfirmContentMap from '../performanceEvaluationForm/statusConfirmContentMap';
import ManagerRatingStatusLabel from '../performanceEvaluationForm/ManagerRatingStatusLabel';
import { roundOffRating } from '../../../utils/conversionUtils';
import PMCycleDropdown from './PMCycleDropdown';

const { Option } = EnhancedSelect;

const EvaluationsDashboard = (props) => {
  const {
    reporteesEvaluations,
    isLoading,
    pmCycles,
    approvePEF,
    getReporteesEvaluations,
    getPMCycleActions,
    submitForApproval,
    user,
    exportReporteesEvaluations,
  } = props;

  const baseUrlForEvaluation = PERFORMANCE_MANAGEMENT_ROUTES.LINE_MANAGER_VIEW_EVALUATION;

  const initialFilters = {
    oracleId: '',
    employeeName: '',
    managerName: '',
    departmentIds: '',
    competencyIds: '',
    approverName: '',
    isPromotion: '',
  };

  const initialValueForParams = {
    page: 0,
    size: 10,
    reportees: 'DIRECT',
    ...initialFilters,
  };

  const [params, setParams] = useState(initialValueForParams);

  const [selectedEmployeeRows, setSelectedEmployeeRows] = useState([]);
  const [selectedEmployeeRowKeys, setSelectedEmployeeRowKeys] = useState([]);
  const [confirmModalStatus, setConfirmModalStatus] = useState(null);

  useEffect(() => {
    // getReporteesEvaluations(params);

    getPMCycleActions({
      pageNumber: 0,
      pageSize: 100000,
    });
  }, []);

  useEffect(() => {
    getReporteesEvaluations(params);
  }, [params]);

  const canSubmitForApproval = (record) =>
    record.manager.status.replace(/\s/g, '') === evaluationStatus.PENDING;

  const canApprove = (record) =>
    record.manager.status.replace(/\s/g, '') === evaluationStatus.PENDING_APPROVAL;

  const isApprover = (record) =>
    record.lineManagerApprover && record.lineManagerApprover.id === user.id;

  const getColumnsInfo = () => {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'taleoId',
        key: 'oracleId',
        filterConfig: {
          type: 'search',
          key: 'oracleId',
        },
      },
      {
        title: 'Employee',
        dataIndex: 'name',
        key: 'employeeName',
        filterConfig: {
          type: 'search',
          key: 'employeeName',
        },
        width: 150,
      },
      {
        title: 'Review Manager',
        dataIndex: 'managerName',
        key: 'managerName',
        filterConfig: {
          type: 'search',
          key: 'managerName',
        },
        width: 150,
      },
      {
        title: 'Manager Rating',
        dataIndex: ['manager', 'rating'],
        render: (rating, _record) => (
          <RatingRenderer
            rating={roundOffRating(rating) || 0.0}
            reviewType={reviewTypes.MANAGER}
            baseUrl={baseUrlForEvaluation}
            performanceCycleId={params.performanceCycleId}
            employeeId={_record.employeeId}
          />
        ),
      },
      {
        title: 'Manager Rating Status',
        dataIndex: 'manager',
        render: ({ status, comment }) => (
          <ManagerRatingStatusLabel status={status} comment={comment} />
        ),
      },
      {
        title: 'Self Rating',
        dataIndex: ['self', 'rating'],
        render: (rating, _record) => (
          <RatingRenderer
            rating={roundOffRating(rating) || 0.0}
            reviewType={reviewTypes.SELF}
            baseUrl={baseUrlForEvaluation}
            performanceCycleId={params.performanceCycleId}
            employeeId={_record.employeeId}
          />
        ),
      },
      {
        title: 'Self Rating Status',
        dataIndex: ['self', 'status'],
        render: (status) => (
          <span className={`status-box ${goalStatusToClassMapping[status]}`}>{status}</span>
        ),
      },
      {
        title: 'Approver Name (LM+1)',
        dataIndex: ['lineManagerApprover', 'name'],
        key: 'approverName',
        filterConfig: {
          type: 'search',
          key: 'approverName',
        },
        width: 150,
      },
      {
        title: 'Department',
        dataIndex: 'department',
        width: 180,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'departmentIds',
          optionsConfig: {
            values: props.departments,
            valueVar: 'id',
          },
        },
      },
      {
        title: 'Competency',
        dataIndex: 'competency',
        width: 180,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'competencyIds',
          optionsConfig: {
            values: props.competencies,
            valueVar: 'id',
          },
        },
      },
    ];

    const isAuthorized = hasPermission(user.permissions, [
      AccessPermissions.Reportee.Profile.Compensation.View,
      AccessPermissions.Employee.Profile.Compensation.View,
    ]);

    if (isAuthorized) {
      columns.splice(4, 0, {
        title: 'Is promotion?',
        dataIndex: 'promotionInfo.isPromotion',
        key: 'promotionInfo.isPromotion',
        render: (value) => (
          <EnhancedTag color={value ? 'green' : 'red'}>{value ? 'Yes' : 'No'}</EnhancedTag>
        ),
        filterConfig: {
          type: 'enhanced_filter',
          key: 'isPromotion',
          optionsConfig: {
            values: [
              {
                text: 'Yes',
                value: 'yes',
              },
              {
                text: 'No',
                value: 'no',
              },
            ],
            valueVar: 'value',
            labelVar: 'text',
          },
        },
      });
    }

    return columns;
  };

  const onChangeSelection = (selectedRowKeys, selectedRows) => {
    let newSelectedRows = [...selectedEmployeeRows];
    newSelectedRows = newSelectedRows.filter((row) =>
      selectedRowKeys.some((key) => key === row.manager.pcRatingId),
    );
    selectedRows.forEach((row) => {
      const alreadyExists = newSelectedRows.some(
        (previousRecord) => previousRecord.manager.pcRatingId === row.manager.pcRatingId,
      );
      if (!alreadyExists) {
        newSelectedRows.push(row);
      }
    });

    setSelectedEmployeeRowKeys(selectedRowKeys);
    setSelectedEmployeeRows(newSelectedRows);
  };

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      page: pagination.current - 1,
      size: pagination.pageSize,
    });
  };

  const handleApproval = ({ comment }) => {
    const employeeIds = [];
    const ratingIds = [];

    selectedEmployeeRows.forEach((emp) => {
      employeeIds.push(emp.employeeId);
      ratingIds.push(emp.manager.pcRatingId);
    });

    const request = {
      payload: {
        employeeIds,
        ratingIds,
        status: confirmModalStatus,
        as: ATTRIBUTES.LINE_MANAGER,
        comment,
      },
      refetchFunc: () => {
        getReporteesEvaluations(params);
      },
    };

    approvePEF(request);
    setConfirmModalStatus(null);

    setSelectedEmployeeRowKeys([]);
    setSelectedEmployeeRows([]);
  };

  const handleSubmitForApproval = () => {
    const employeeIds = [];
    const ratingIds = [];

    selectedEmployeeRows.forEach((emp) => {
      employeeIds.push(emp.employeeId);
      ratingIds.push(emp.manager.pcRatingId);
    });

    const request = {
      payload: {
        employeeIds,
        ratingIds,
      },
      refetchFunc: () => {
        getReporteesEvaluations(params);
      },
    };

    submitForApproval(request);
    setConfirmModalStatus(null);
    setSelectedEmployeeRowKeys([]);
    setSelectedEmployeeRows([]);
  };

  const handleConfirmModalCancel = () => {
    setConfirmModalStatus(null);
  };

  const changePerformanceCycleHandler = (performanceCycleId) => {
    setParams({
      ...params,
      performanceCycleId,
    });
  };

  const performanceCycleDropdown = () => {
    return <PMCycleDropdown pmCycles={pmCycles} onChange={changePerformanceCycleHandler} />;
  };

  const handleReporteesSelect = (value) => {
    setParams({
      ...params,
      reportees: value,
    });
    setSelectedEmployeeRowKeys([]);
    setSelectedEmployeeRows([]);
  };

  const isPendingApprovalStatus = confirmModalStatus === evaluationStatus.PENDING_APPROVAL;

  const renderStatusConfirmModal = () => {
    const modalTitle = statusConfirmContentMap[confirmModalStatus];

    return (
      <ConfirmModal
        title={modalTitle}
        status={confirmModalStatus}
        onCancel={handleConfirmModalCancel}
        onOk={isPendingApprovalStatus ? handleSubmitForApproval : handleApproval}
      />
    );
  };

  const handleExport = () => {
    const updatedParams = {
      ...params,
      size: 1000000,
    };
    exportReporteesEvaluations(updatedParams);
  };

  return (
    <div>
      <React.Fragment>
        <EnhancedRow
          style={{ margin: '20px 0' }}
          type="flex"
          align="middle"
          justify="space-between"
        >
          <div className="add-btn-wrapper">
            <div>{performanceCycleDropdown()}</div>

            {/* <span>{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}</span> */}
          </div>
          <div className="add-btn-wrapper">
            {/* <EnhancedButton icon="filter" onClick={clearAllFilters} style={{ marginRight: 10 }}>
              Clear filters
            </EnhancedButton> */}
            {/* <EnhancedButton icon="export" onClick={handleExport} style={{ marginRight: 10 }}>
              Export
            </EnhancedButton> */}
            {/* <EnhancedButton
                style={{ marginRight: 10 }}
                onClick={() => handleBulkAction(GOAL_STATUS.APPROVED)}
                disabled={selectedRowKeys.length === 0}
              >
                <Icon type="carry-out" />
                <span>Approve Goals</span>
              </EnhancedButton> */}
            <span className="mr-10">Reporting Line:</span>
            <EnhancedSelect
              placeholder="Select Reportees"
              onChange={handleReporteesSelect}
              showArrow
              value={params.reportees}
              style={{ width: 158 }}
            >
              <Option key="ALL" value="ALL">
                All
              </Option>
              <Option key="DIRECT" value="DIRECT">
                Direct Reporting
              </Option>
              <Option key="INDIRECT" value="INDIRECT">
                Indirect Reporting
              </Option>
            </EnhancedSelect>
          </div>
        </EnhancedRow>

        <div className="add-btn-wrapper">
          {selectedEmployeeRowKeys.length > 0 && params.reportees === 'INDIRECT' && (
            <React.Fragment>
              <EnhancedButton
                className="btn-edit"
                style={{ marginRight: 10 }}
                onClick={() => setConfirmModalStatus(evaluationStatus.APPROVED)}
              >
                Approve ({selectedEmployeeRowKeys.length})
              </EnhancedButton>
              <EnhancedButton
                className="btn-edit"
                style={{ marginRight: 10 }}
                onClick={() => setConfirmModalStatus(evaluationStatus.REJECTED)}
              >
                Reject ({selectedEmployeeRowKeys.length})
              </EnhancedButton>
            </React.Fragment>
          )}
          {selectedEmployeeRowKeys.length > 0 && params.reportees === 'DIRECT' && (
            <React.Fragment>
              <EnhancedButton
                className="btn-edit"
                style={{ marginRight: 10 }}
                onClick={() => setConfirmModalStatus(evaluationStatus.PENDING_APPROVAL)}
              >
                Send for approval ({selectedEmployeeRowKeys.length})
              </EnhancedButton>
            </React.Fragment>
          )}
        </div>
        <br />
        {renderStatusConfirmModal()}

        <EnhancedServerSideTable
          rowKey={(record) => record.manager.pcRatingId}
          loading={isLoading}
          columns={getColumnsInfo()}
          data={reporteesEvaluations.rows}
          onChange={handleTableChange}
          className="goal-table-container"
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: selectedEmployeeRowKeys,
            onChange: onChangeSelection,
            getCheckboxProps: (record) => ({
              disabled:
                (params.reportees === 'INDIRECT' && !(canApprove(record) && isApprover(record))) ||
                (params.reportees === 'DIRECT' && !canSubmitForApproval(record)) ||
                params.reportees === 'ALL',
            }),
          }}
          paginationInfo={{
            totalItems: reporteesEvaluations.count,
            pageSize: params.size,
            pageNumber: params.page + 1,
          }}
          scroll={{ x: 'max-content' }}
          updateParams={(updatedParams) => {
            setParams({
              ...params,
              ...updatedParams,
              page: 0, // move to page no 0 in case filters are applied
            });
          }}
          exportFileConfig={{
            showExportButton: true,
            handler: handleExport,
          }}
        />
      </React.Fragment>
    </div>
  );
};
export default EvaluationsDashboard;
