import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedSpin,
  EnhancedAlert,
  EnhancedLayout,
  EnhancedTooltip,
  EnhancedCollapse,
  EnhancedSelect,
} from 'components/shared/antd';
import React, { Component } from 'react';
import moment from 'moment';
import BarCard from 'components/BarCard';
import 'components/Report.css';
import DateRangePicker from 'components/DateRangePicker';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';

const { Header } = EnhancedLayout;
const { Panel } = EnhancedCollapse;
const { Option } = EnhancedSelect;

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorText: '',
      report: props.report,
      locations: [],
      selectedLocations: [],
      selectedProjects: [],
      selectedManagers: [],
      selectedEmployees: [],
      selectedDepartments: [],
      // currentPage: 1,
      // pageSize: 40,
    };
  }

  componentDidMount() {
    const resolution = 'month';
    const startDate = moment().add(0, resolution).startOf(resolution);
    this.props.getAllLocations();
    const endDate = moment().add(0, resolution).endOf(resolution);
    this.loadData(startDate, endDate);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.report !== nextProps.report) {
      this.setState({ report: nextProps.report });
    }
    if (this.props.locations !== nextProps.locations) {
      this.setState({ locations: nextProps.locations });
    }
  }

  onClose = () => {
    this.setState({ errorText: '' });
  };

  // onTableChange = (currentPage) => {
  //   this.setState({ currentPage });
  // };

  getColumns = (report) => {
    if (!report || !report.length) {
      return [];
    }
    const columns = [
      {
        title: 'Name',
        fixed: 'left',
        width: 250,
        dataIndex: 'name',
        onFilter: (value, record) => record.name.toLowerCase().indexOf(value) > -1,
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: 'Allocation Ratio',
        dataIndex: 'allocations',
        render: (text, record) =>
          record.allocations && record.allocations.length ? (
            <BarCard details={record.allocations} total={record.total} className="alloc-bar" />
          ) : (
            '-'
          ),
      },
    ];
    return columns;
  };

  loadData = (startDate, endDate) => {
    const validationError = this.validateDuration(startDate, endDate);
    if (validationError) {
      this.setState({ errorText: validationError });
      return;
    }
    this.props.getReport(startDate, endDate);
    this.props.getCSVReport(startDate, endDate);
  };

  validateDuration = (startDate, endDate) => {
    if (startDate.isAfter(endDate, 'day')) {
      return 'Invalid duration. Start date cannot be same as or later than endDate';
    }
    return false;
  };

  handleClearFilters = () => {
    this.setState({
      report: this.props.report,
      selectedProjects: [],
      selectedLocations: [],
      selectedEmployees: [],
      selectedManagers: [],
      selectedDepartments: [],
      // currentPage: 1,
    });
  };

  handleOnBarClicked = (data) => {
    if (!data) return;
    const { selectedProjects } = this.state;
    let itemIndex = -1;
    const alreadyApplied = selectedProjects.some((project, index) => {
      if (project.toLowerCase() === data.activeLabel.toLowerCase()) {
        itemIndex = index;
        return true;
      }
      return false;
    });
    if (alreadyApplied) {
      selectedProjects.splice(itemIndex, 1);
    } else {
      selectedProjects.push(data.activeLabel);
    }
    this.setState({ selectedProjects }, () => this.applyFilters());
  };

  handleOnDepttChange = (value) => {
    this.setState({ selectedDepartments: value }, () => this.applyFilters());
  };

  handleOnManagerChange = (value) => {
    this.setState({ selectedManagers: value }, () => this.applyFilters());
  };

  handleOnEmployeeChange = (value) => {
    this.setState({ selectedEmployees: value }, () => this.applyFilters());
  };

  handleOnLocationChange = (value) => {
    this.setState({ selectedLocations: value }, () => this.applyFilters());
  };

  handleOnProjectChange = (value) => {
    this.setState({ selectedProjects: value }, () => this.applyFilters());
  };

  applyFilters = () => {
    const {
      selectedProjects,
      selectedLocations,
      selectedManagers,
      selectedEmployees,
      selectedDepartments,
    } = this.state;
    let { report } = this.props;

    if (selectedProjects.length) {
      report = report.filter((employee) =>
        employee.allocations.some(
          (project) => selectedProjects.includes(project.projectName) && project.percentage > 0,
        ),
      );
    }

    if (selectedLocations.length) {
      report = report.filter((employee) => selectedLocations.includes(employee.location));
    }

    if (selectedManagers.length) {
      report = report.filter((employee) => selectedManagers.includes(employee.manager));
    }

    if (selectedEmployees.length) {
      report = report.filter((employee) => selectedEmployees.includes(employee.name));
    }

    if (selectedDepartments.length) {
      report = report.filter((employee) => selectedDepartments.includes(employee.deptt));
    }

    this.setState({
      report,
    });
  };

  renderLocationOptions = () =>
    this.state.locations.map((l) => <Option key={l.name}>{l.name}</Option>);

  renderEmployees() {
    const { employees } = this.props;
    return employees ? employees.map((p) => <Option key={p}>{p}</Option>) : [];
  }

  renderManagers() {
    const { managers } = this.props;
    return managers ? managers.map((p) => <Option key={p}>{p}</Option>) : [];
  }

  renderDepartments() {
    const { departments } = this.props;
    return departments ? departments.map((p) => <Option key={p}>{p}</Option>) : [];
  }

  renderProjectOptions() {
    const { summary } = this.props;
    return summary ? summary.map((p) => <Option key={p.name}>{p.name}</Option>) : [];
  }

  render() {
    const { isLoading, isCSVLoading } = this.props;
    const {
      report,
      selectedLocations,
      selectedProjects,
      selectedManagers,
      selectedEmployees,
      selectedDepartments,
      // currentPage,
      // pageSize,
    } = this.state;

    const columns = this.getColumns(report);
    // const showTotal = (total) => `Total ${total} items`;

    return (
      <div>
        {this.state.errorText ? (
          <EnhancedAlert
            message={this.state.errorText}
            type="error"
            closable
            onClose={this.onClose}
          />
        ) : null}
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol span={10}>
              <h1>Allocations</h1>
            </EnhancedCol>
            <EnhancedCol lg={14} md={14} sm={24} xs={24}>
              <DateRangePicker
                loadData={this.loadData}
                downloadDisabled={
                  isCSVLoading || !this.props.csvReport || !this.props.csvReport.length
                }
                data={this.props.csvReport}
                filename="reports"
              />
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        <div className="project-chart">
          {isLoading && (
            <EnhancedRow>
              <EnhancedSpin className="spin-with-bg" />
            </EnhancedRow>
          )}
          {/* {!isLoading && (
            <ResponsiveContainer width="100%" height={250}>
              <BarChart barCategoryGap="10%" data={summary} onClick={this.handleOnBarClicked}>
                <CartesianGrid strokeDasharray="3" vertical={false} />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip formatter={(v) => +v.toFixed(2)} />
                <Bar dataKey="total" fill="#3AA0FF" />
              </BarChart>
            </ResponsiveContainer>
          )} */}
        </div>
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol>
              <h1>Allocation Details</h1>
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        <EnhancedCollapse bordered>
          <Panel header="Filters" showArrow>
            <EnhancedRow gutter={16} justify="space-between" style={{ marginBottom: '2%' }}>
              <EnhancedCol span={8}>
                <EnhancedSelect
                  mode="multiple"
                  placeholder="Departments"
                  style={{ width: '100%' }}
                  onChange={this.handleOnDepttChange}
                  value={selectedDepartments}
                  tokenSeparators={[',']}
                  allowClear
                >
                  {this.renderDepartments()}
                </EnhancedSelect>
              </EnhancedCol>
              <EnhancedCol span={8}>
                <EnhancedSelect
                  mode="multiple"
                  placeholder="Managers"
                  style={{ width: '100%' }}
                  onChange={this.handleOnManagerChange}
                  value={selectedManagers}
                  tokenSeparators={[',']}
                  allowClear
                >
                  {this.renderManagers()}
                </EnhancedSelect>
              </EnhancedCol>
              <EnhancedCol span={8}>
                <EnhancedSelect
                  mode="multiple"
                  placeholder="Employees"
                  style={{ width: '100%' }}
                  onChange={this.handleOnEmployeeChange}
                  value={selectedEmployees}
                  tokenSeparators={[',']}
                  allowClear
                >
                  {this.renderEmployees()}
                </EnhancedSelect>
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow gutter={16} justify="space-between" style={{ marginBottom: '2%' }}>
              <EnhancedCol span={15}>
                <EnhancedSelect
                  mode="multiple"
                  placeholder="Projects"
                  style={{ width: '100%' }}
                  onChange={this.handleOnProjectChange}
                  value={selectedProjects}
                  tokenSeparators={[',']}
                  allowClear
                >
                  {this.renderProjectOptions()}
                </EnhancedSelect>
              </EnhancedCol>
              <EnhancedCol span={8}>
                <EnhancedSelect
                  mode="multiple"
                  placeholder="Locations"
                  onChange={this.handleOnLocationChange}
                  style={{ width: '100%' }}
                  value={selectedLocations}
                  tokenSeparators={[',']}
                  allowClear
                >
                  {this.renderLocationOptions()}
                </EnhancedSelect>
              </EnhancedCol>
              <EnhancedCol span={1}>
                <EnhancedTooltip title="Clear filters">
                  <EnhancedButton
                    type="primary"
                    shape="circle"
                    icon="close"
                    onClick={this.handleClearFilters}
                    disabled={
                      !selectedProjects.length &&
                      !selectedLocations.length &&
                      !selectedManagers.length &&
                      !selectedDepartments.length &&
                      !selectedEmployees.length
                    }
                  />
                </EnhancedTooltip>
              </EnhancedCol>
            </EnhancedRow>
          </Panel>
        </EnhancedCollapse>
        <div className="project-table">
          <EnhancedClientSideTable
            loading={isLoading}
            columns={columns}
            data={report || []}
            scroll={{ x: columns.length * 150, y: 550 }}
            showResetFiltersButton={false}
          />
        </div>
      </div>
    );
  }
}

export default Report;
