import { EnhancedRow, EnhancedCol, EnhancedLayout, EnhancedCollapse } from 'components/shared/antd';
import React from 'react';

const { Panel } = EnhancedCollapse;
const { Header } = EnhancedLayout;

const FaqContainer = () => (
  <div>
    <Header className="project-header">
      <EnhancedRow>
        <EnhancedCol span={24}>
          <h1>
            FAQ<span style={{ textTransform: 'lowercase' }}>s</span>
          </h1>
        </EnhancedCol>
      </EnhancedRow>
    </Header>
    <div className="project-content">
      <EnhancedCollapse bordered={false} accordion>
        <Panel header="How to log allocation for multiple projects?" key="1">
          <p>
            You can add multiple projects allocation for a day depending upon the amount of
            percentage (%) you have worked on each project.
          </p>
          <p>The total allocation must be equal to 100% for a day.</p>
        </Panel>
        <Panel header="What if I`m not being allocated to a project?" key="2">
          <p>
            If you are not assigned to any project or on initial training phase, you shall be
            selecting <strong>BENCH</strong> project to log your allocation.
          </p>
        </Panel>
        <Panel header="How I`ll be allocated on off days?" key="3">
          <p>
            If you are on leaves (Sick, Causal, Annual), you shall be logging your allocation under
            the project named as <strong>LEAVES</strong>.
          </p>
        </Panel>
        <Panel header="Will I be getting notified about the missing allocations?" key="4">
          <p>
            You will be getting a reminder email on every end of week. If you still couldn’t make
            it, Competency Lead & Project Manager will be notified about your missing allocation
            dates.
          </p>
          <p>
            Perhaps at the end of the month, HR Management will be notified and they will be then
            asking for the missing allocations.
          </p>
        </Panel>
        <Panel header="Who should I contact with for any queries about VenDians?" key="5">
          <p>
            There is a slack channel available to cater support named as <mark>#VenDians</mark>.
            Apart from that, following are the contact persons: <br />
            <br />
            <strong>For queries and issues:</strong>
            <br />
            VenDians@venturedive.com
            <br />
            <br />
          </p>
        </Panel>
      </EnhancedCollapse>
    </div>
  </div>
);

export default FaqContainer;
