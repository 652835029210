import {
  getEmployees,
  getSkills,
  postSkill,
  editSkill,
  deleteSkill,
  updateEmployeeSkills,
  markEmployeeInactive,
  getEmployeesProject,
  deallocateEmployee,
  updateEmployeesProject,
  getEmployeesProjectRoles,
  wishEmployee,
  getAllEmployees,
  getEmployeesBirthday,
  getDepartments,
  postDepartment,
  editDepartment,
  deleteDepartment,
  getBusinessUnits,
  postBusinessUnit,
  editBusinessUnit,
  deleteBusinessUnit,
  getDivisions,
  postDivision,
  editDivision,
  deleteDivision,
  getCompetencies,
  postCompetency,
  editCompetency,
  deleteCompetency,
  getUserAttributes,
  updateEmployeesBulkInfo,
  getEmployeesFamilyInfo,
} from 'sources/EmployeeSource';
import {
  GET_EMPLOYEES_INIT,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_BIRTHDAY_SUCCESS,
  GET_EMPLOYEES_FAILURE,
  GET_EMPLOYEES_SKILLS_INIT,
  GET_EMPLOYEES_SKILLS_SUCCESS,
  GET_EMPLOYEES_SKILLS_FAILURE,
  UPDATE_EMPLOYEES_INIT,
  MARK_EMPLOYEE_INACTIVE_INIT,
  UPDATE_EMPLOYEES_SUCCESS,
  UPDATE_EMPLOYEES_FAILURE,
  DELETE_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_SUCCESS,
  ADD_SKILL_INIT,
  ADD_SKILL_SUCCESS,
  ADD_SKILL_FAILURE,
  EDIT_SKILL_SUCCESS,
  DELETE_SKILL_SUCCESS,
  UPDATE_EMPLOYEE_WISH,
  ADD_EMPLOYEE_BIRTHDAY_SUCCESS,
  ADD_DEPARTMENT_INIT,
  ADD_DEPARTMENT_SUCCESS,
  ADD_DEPARTMENT_FAILURE,
  EDIT_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_SUCCESS,
  ADD_BUSINESSUNIT_INIT,
  ADD_BUSINESSUNIT_SUCCESS,
  ADD_BUSINESSUNIT_FAILURE,
  EDIT_BUSINESSUNIT_SUCCESS,
  DELETE_BUSINESSUNIT_SUCCESS,
  GET_EMPLOYEE_DEPARTMENTS_INIT,
  GET_EMPLOYEE_DEPARTMENTS_FAILURE,
  GET_EMPLOYEE_DEPARTMENTS_SUCCESS,
  GET_ALL_EMPLOYEES_SUCCESS,
  GET_ALL_EMPLOYESS_FAILURE,
  GET_ALL_EMPLOYEES_INIT,
  ADD_DIVISION_INIT,
  ADD_DIVISION_SUCCESS,
  ADD_DIVISION_FAILURE,
  EDIT_DIVISION_SUCCESS,
  DELETE_DIVISION_SUCCESS,
  GET_EMPLOYEE_DIVISIONS_INIT,
  GET_EMPLOYEE_DIVISIONS_FAILURE,
  GET_EMPLOYEE_DIVISIONS_SUCCESS,
  GET_EMPLOYEE_BUSINESSUNITS_INIT,
  GET_EMPLOYEE_BUSINESSUNITS_FAILURE,
  GET_EMPLOYEE_BUSINESSUNITS_SUCCESS,
  ADD_COMPETENCY_INIT,
  ADD_COMPETENCY_SUCCESS,
  ADD_COMPETENCY_FAILURE,
  EDIT_COMPETENCY_SUCCESS,
  DELETE_COMPETENCY_SUCCESS,
  GET_EMPLOYEE_COMPETENCIES_INIT,
  GET_EMPLOYEE_COMPETENCIES_FAILURE,
  GET_EMPLOYEE_COMPETENCIES_SUCCESS,
  GET_USER_ATTRIBUTES_INIT,
  GET_USER_ATTRIBUTES_SUCCESS,
  GET_USER_ATTRIBUTES_FAILURE,
  GET_ALL_REPORTEES_SUCCESS,
  UPDATE_EMPLOYEES_BULK_INFO_INIT,
  UPDATE_EMPLOYEES_BULK_INFO_SUCCESS,
  UPDATE_EMPLOYEES_BULK_INFO_FAILURE,
} from 'constants/EmployeeTypes';
import {
  GET_EMPLOYEES_PROJECT_INIT,
  GET_EMPLOYEES_PROJECT_SUCCESS,
  GET_EMPLOYEES_PROJECT_FAILURE,
  DELETE_EMPLOYEES_PROJECT_INIT,
  DELETE_EMPLOYEES_PROJECT_SUCCESS,
  DELETE_EMPLOYEES_PROJECT_FAILURE,
  UPDATE_EMPLOYEES_PROJECT_INIT,
  UPDATE_EMPLOYEES_PROJECT_SUCCESS,
  UPDATE_EMPLOYEES_PROJECT_FAILURE,
  GET_EMPLOYEES_PROJECT_ROLES_SUCCESS,
  GET_EMPLOYEES_PROJECT_ROLES_INIT,
  GET_EMPLOYEES_PROJECT_ROLES_FAILURE,
} from 'constants/EmployeeProject';

// normal actions
export const getEmployeesInit = () => ({
  type: GET_EMPLOYEES_INIT,
  loader: true,
});

export const getAllEmployeesInit = () => ({
  type: GET_ALL_EMPLOYEES_INIT,
});

export const getEmployeesSuccess = (data) => ({
  type: GET_EMPLOYEES_SUCCESS,
  data,
});

export const getAllEmployeesSuccess = (data) => ({
  type: GET_ALL_EMPLOYEES_SUCCESS,
  data,
});

export const wishEmployeeAction = (id, data) => (dispatch) => {
  dispatch(wishEmployee(id, data));
};

export const getEmployeesBirthdaySuccess = (data) => ({
  type: GET_EMPLOYEES_BIRTHDAY_SUCCESS,
  data,
});

export const updateEmployeeWish = (data) => ({
  type: UPDATE_EMPLOYEE_WISH,
  data,
});

export const getAllEmployeesFailure = (data) => ({
  type: GET_ALL_EMPLOYESS_FAILURE,
  data,
});

export const getEmployeesFailure = (data) => ({
  type: GET_EMPLOYEES_FAILURE,
  data,
});

// get skills actions
export const getEmployeesSkillsInit = () => ({
  type: GET_EMPLOYEES_SKILLS_INIT,
});

export const getEmployeesSkillsSuccess = (data) => ({
  type: GET_EMPLOYEES_SKILLS_SUCCESS,
  data,
});

export const getEmployeesSkillsFailure = (data) => ({
  type: GET_EMPLOYEES_SKILLS_FAILURE,
  data,
});

export const markEmployeeInactiveInit = () => ({
  type: MARK_EMPLOYEE_INACTIVE_INIT,
});

// update employee skills actions
export const updateEmployeesSkillsInit = () => ({
  type: UPDATE_EMPLOYEES_INIT,
});

export const updateEmployeesSkillsSuccess = (data) => ({
  type: UPDATE_EMPLOYEES_SUCCESS,
  data,
});

export const deleteEmployeeFailure = (data) => ({
  type: DELETE_EMPLOYEE_FAILURE,
  data,
});

export const updateEmployeesSkillsFailure = (data) => ({
  type: UPDATE_EMPLOYEES_FAILURE,
  data,
});

// department actions
export const getEmployeeDepartmentsInit = () => ({
  type: GET_EMPLOYEE_DEPARTMENTS_INIT,
});

export const getEmployeeDepartmentsSuccess = (data) => ({
  type: GET_EMPLOYEE_DEPARTMENTS_SUCCESS,
  data,
});

export const getEmployeeDepartmentsFailure = (data) => ({
  type: GET_EMPLOYEE_DEPARTMENTS_FAILURE,
  data,
});

// division actions
export const getEmployeeDivisionsInit = () => ({
  type: GET_EMPLOYEE_DIVISIONS_INIT,
});

export const getEmployeeDivisionsSuccess = (data) => ({
  type: GET_EMPLOYEE_DIVISIONS_SUCCESS,
  data,
});

export const getEmployeeDivisionsFailure = (data) => ({
  type: GET_EMPLOYEE_DIVISIONS_FAILURE,
  data,
});

export const addDivisionInit = () => ({
  type: ADD_DIVISION_INIT,
});

export const addDivisionSuccess = (data) => ({
  type: ADD_DIVISION_SUCCESS,
  data,
});

export const addDivisionFailure = () => ({
  type: ADD_DIVISION_FAILURE,
});

export const editDivisionSuccess = (data) => ({
  type: EDIT_DIVISION_SUCCESS,
  data,
});

export const deleteDivisionSuccess = (data) => ({
  type: DELETE_DIVISION_SUCCESS,
  data,
});

export const getEmployeeCompetenciesInit = () => ({
  type: GET_EMPLOYEE_COMPETENCIES_INIT,
});

export const getEmployeeCompetenciesSuccess = (data) => ({
  type: GET_EMPLOYEE_COMPETENCIES_SUCCESS,
  data,
});

export const getEmployeeCompetenciesFailure = (data) => ({
  type: GET_EMPLOYEE_COMPETENCIES_FAILURE,
  data,
});

export const addCompetencyInit = () => ({
  type: ADD_COMPETENCY_INIT,
});

export const addCompetencySuccess = (data) => ({
  type: ADD_COMPETENCY_SUCCESS,
  data,
});

export const addCompetencyFailure = () => ({
  type: ADD_COMPETENCY_FAILURE,
});

export const editCompetencySuccess = (data) => ({
  type: EDIT_COMPETENCY_SUCCESS,
  data,
});

export const deleteCompetencySuccess = (data) => ({
  type: DELETE_COMPETENCY_SUCCESS,
  data,
});

// project actions
export const getEmployeesProjectInit = () => ({
  type: GET_EMPLOYEES_PROJECT_INIT,
});

export const getEmployeesProjectSuccess = (data) => ({
  type: GET_EMPLOYEES_PROJECT_SUCCESS,
  data,
});

export const getEmployeesProjectFailure = (data) => ({
  type: GET_EMPLOYEES_PROJECT_FAILURE,
  data,
});

export const deleteEmployeesProjectInit = () => ({
  type: DELETE_EMPLOYEES_PROJECT_INIT,
});

export const deleteEmployeesProjectSuccess = (data) => ({
  type: DELETE_EMPLOYEES_PROJECT_SUCCESS,
  data,
});

export const deleteEmployeesProjectFailure = (employeeProjectId) => ({
  type: DELETE_EMPLOYEES_PROJECT_FAILURE,
  employeeProjectId,
});

export const updateEmployeesProjectInit = () => ({
  type: UPDATE_EMPLOYEES_PROJECT_INIT,
});

export const updateEmployeesProjectSuccess = (employeeProjectInfo, currentInformation) => ({
  type: UPDATE_EMPLOYEES_PROJECT_SUCCESS,
  employeeProjectInfo,
  currentInformation,
});

export const deleteEmployeeSuccess = (employeeId) => ({
  type: DELETE_EMPLOYEE_SUCCESS,
  employeeId,
});

export const updateEmployeesProjectFailure = (employeeProjectInfo) => ({
  type: UPDATE_EMPLOYEES_PROJECT_FAILURE,
  employeeProjectInfo,
});

export const getEmployeesProjectRolesInit = () => ({
  type: GET_EMPLOYEES_PROJECT_ROLES_INIT,
});

export const getEmployeesProjectRolesSuccess = (data) => ({
  type: GET_EMPLOYEES_PROJECT_ROLES_SUCCESS,
  data,
});

export const getEmployeesProjectRolesFailure = () => ({
  type: GET_EMPLOYEES_PROJECT_ROLES_FAILURE,
});

export const addEmployeeSuccessful = (data) => ({
  type: ADD_EMPLOYEE_SUCCESS,
  data,
});

export const addSkillInit = () => ({
  type: ADD_SKILL_INIT,
});

export const addSkillSuccess = (data) => ({
  type: ADD_SKILL_SUCCESS,
  data,
});

export const addSkillFailure = () => ({
  type: ADD_SKILL_FAILURE,
});

export const editSkillSuccess = (data) => ({
  type: EDIT_SKILL_SUCCESS,
  data,
});

export const deleteSkillSuccess = (data) => ({
  type: DELETE_SKILL_SUCCESS,
  data,
});

export const addEmployeeBirthdaySuccess = (data) => ({
  type: ADD_EMPLOYEE_BIRTHDAY_SUCCESS,
  data,
});

export const addDepartmentInit = () => ({
  type: ADD_DEPARTMENT_INIT,
});

export const addDepartmentSuccess = (data) => ({
  type: ADD_DEPARTMENT_SUCCESS,
  data,
});

export const addDepartmentFailure = () => ({
  type: ADD_DEPARTMENT_FAILURE,
});

export const editDepartmentSuccess = (data) => ({
  type: EDIT_DEPARTMENT_SUCCESS,
  data,
});

export const deleteDepartmentSuccess = (data) => ({
  type: DELETE_DEPARTMENT_SUCCESS,
  data,
});
// Business Units
export const getEmployeeBusinessUnitsInit = () => ({
  type: GET_EMPLOYEE_BUSINESSUNITS_INIT,
});

export const getEmployeeBusinessUnitsSuccess = (data) => ({
  type: GET_EMPLOYEE_BUSINESSUNITS_SUCCESS,
  data,
});

export const getEmployeeBusinessUnitsFailure = (data) => ({
  type: GET_EMPLOYEE_BUSINESSUNITS_FAILURE,
  data,
});

export const addBusinessUnitInit = () => ({
  type: ADD_BUSINESSUNIT_INIT,
});

export const addBusinessUnitSuccess = (data) => ({
  type: ADD_BUSINESSUNIT_SUCCESS,
  data,
});

export const addBusinessUnitFailure = () => ({
  type: ADD_BUSINESSUNIT_FAILURE,
});

export const editBusinessUnitSuccess = (data) => ({
  type: EDIT_BUSINESSUNIT_SUCCESS,
  data,
});

export const deleteBusinessUnitSuccess = (data) => ({
  type: DELETE_BUSINESSUNIT_SUCCESS,
  data,
});

// Reportees
export const getAllReporteesSuccess = (data) => ({
  type: GET_ALL_REPORTEES_SUCCESS,
  data,
});

// Update employee increments bulk file
export const updateEmployeesBulkInfoInit = () => ({
  type: UPDATE_EMPLOYEES_BULK_INFO_INIT,
});

export const updateEmployeesBulkInfoSuccess = (data) => ({
  type: UPDATE_EMPLOYEES_BULK_INFO_SUCCESS,
  data,
});

export const updateEmployeesBulkInfoFailure = (data) => ({
  type: UPDATE_EMPLOYEES_BULK_INFO_FAILURE,
  data,
});

// Async Action
export const getEmployeesActions = (area) => getEmployees(area);
export const getAllEmployeesActions = (area) => getAllEmployees(area);

export const getEmployeesFamilyInfoActions = (status) => getEmployeesFamilyInfo(status);

export const getEmployeesSkillsActions = () => getSkills();
export const addSkillActions = (requestObject) => (dispatch) => {
  dispatch(postSkill(requestObject));
};
export const editSkillActions = (requestObject) => (dispatch) => {
  dispatch(editSkill(requestObject));
};
export const deleteSkillActions = (requestObject) => (dispatch) => {
  dispatch(deleteSkill(requestObject));
};
export const getEmployeeDepartmentsAction = () => getDepartments();
export const addDepartmentAction = (requestObject) => (dispatch) => {
  dispatch(postDepartment(requestObject));
};
export const editDepartmentAction = (requestObject) => (dispatch) => {
  dispatch(editDepartment(requestObject));
};
export const deleteDepartmentAction = (requestObject) => (dispatch) => {
  dispatch(deleteDepartment(requestObject));
};
// business unit
export const getEmployeeBusinessUnitAction = () => getBusinessUnits();
export const addBusinessUnitAction = (requestObject) => (dispatch) => {
  dispatch(postBusinessUnit(requestObject));
};
export const editBusinessUnitAction = (requestObject) => (dispatch) => {
  dispatch(editBusinessUnit(requestObject));
};

export const deleteBusinessUnitAction = (requestObject) => (dispatch) => {
  dispatch(deleteBusinessUnit(requestObject));
};
// division
export const getEmployeeDivisionsAction = () => getDivisions();
export const addDivisionAction = (requestObject) => (dispatch) => {
  dispatch(postDivision(requestObject));
};
export const editDivisionAction = (requestObject) => (dispatch) => {
  dispatch(editDivision(requestObject));
};
export const deleteDivisionAction = (requestObject) => (dispatch) => {
  dispatch(deleteDivision(requestObject));
};
// competency
export const getEmployeeCompetenciesAction = () => getCompetencies();
export const addCompetencyAction = (requestObject) => (dispatch) => {
  dispatch(postCompetency(requestObject));
};
export const editCompetencyAction = (requestObject) => (dispatch) => {
  dispatch(editCompetency(requestObject));
};
export const deleteCompetencyAction = (requestObject) => (dispatch) => {
  dispatch(deleteCompetency(requestObject));
};
export const updateEmployeeSkillsActions = (employee, isDelete) =>
  isDelete ? markEmployeeInactive(employee) : updateEmployeeSkills(employee);
export const getEmployeesProjectActions = (userId) => getEmployeesProject(userId);
export const deallocateEmployeeAction = (
  employeeProjectId,
  employeeId,
  projectId = null,
  comment = null,
  endDate = null,
  deleteEntry = false,
) => deallocateEmployee(employeeProjectId, employeeId, projectId, comment, endDate, deleteEntry);
export const updateEmployeesProjectActions = (employeeProjectInfo, currentInformation) =>
  updateEmployeesProject(employeeProjectInfo, currentInformation);

export const getEmployeesProjectRolesActions = () => getEmployeesProjectRoles();

export const getEmployeesBirthdayActions = () => getEmployeesBirthday();

export const employeesBulkInfoActions = (requestObject) => (dispatch) => {
  dispatch(updateEmployeesBulkInfo(requestObject));
};

export const getUserAttributesInit = () => ({
  type: GET_USER_ATTRIBUTES_INIT,
});

export const getUserAttributesSuccess = (data) => ({
  type: GET_USER_ATTRIBUTES_SUCCESS,
  data,
});

export const getUserAttributesFailure = () => ({
  type: GET_USER_ATTRIBUTES_FAILURE,
});

export const getUserAttributesAction = (employeeId, params) => (dispatch) => {
  dispatch(getUserAttributesInit());
  return getUserAttributes(employeeId, params)
    .then((response) => dispatch(getUserAttributesSuccess(response)))
    .catch((error) => dispatch(getUserAttributesFailure(error)));
};
