import { EnhancedLayout, EnhancedNotification } from 'components/shared/antd';
import React, { Component } from 'react';
import GoogleLogin from 'react-google-login';
import { GOOGLE_CLIENT_ID, APP_VERSION } from 'configs/constants';
import { HOME_PAGE } from 'configs/routesConstants';
import 'components/Login.css';
import GooglePlay from '../images/google-play.svg';
import AppStore from '../images/app-store.svg';
import {
  VENDIANS_APPLE_APP_STORE_LINK,
  VENDIANS_GOOGLE_PLAY_STORE_LINK,
} from '../configs/externalLinksConstants';

const HELP_DESK = require('images/help_desk.png');

const { Content, Footer } = EnhancedLayout;
class Login extends Component {
  componentDidMount() {
    this.checkPermissions(this.props.user);
  }

  componentWillReceiveProps(nextProps) {
    this.checkPermissions(nextProps.user);
  }

  onSuccess = (googleUser) => {
    this.props.loginSuccessRequest(googleUser && googleUser.tokenId);
  };

  onFailure = (response) => {
    if (response && response.error !== 'popup_closed_by_user') {
      EnhancedNotification.error({
        message: 'Login Error',
        description: response.details,
      });
    }
  };

  checkPermissions = (user) => {
    if (user && this.props.history.location.pathname !== HOME_PAGE) {
      this.props.history.push(HOME_PAGE);
    }
  };

  render() {
    return (
      <EnhancedLayout className="login-page">
        <Content className="main-container">
          <a
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#4285f4', float: 'left' }}
            href="https://helpdesk.venturedive.com/"
          >
            {' '}
            <img src={HELP_DESK} alt="Help desk link" width="25" />
            <span style={{ marginLeft: '5px' }}>Help Desk Support</span>
          </a>
          <div className="form-container">
            <h1 className="heading text-center">
              <span>VenD</span>ians
            </h1>
            <GoogleLogin
              clientId={GOOGLE_CLIENT_ID}
              onSuccess={this.onSuccess}
              onFailure={this.onFailure}
              className="loginBtn"
            >
              <span style={{ paddingLeft: 10 }}>Sign in with Google</span>
            </GoogleLogin>
            <div className="app-store-badges">
              <a href={VENDIANS_APPLE_APP_STORE_LINK} target="_blank" rel="noopener noreferrer">
                <img src={AppStore} alt="App Store" />
              </a>
              <a href={VENDIANS_GOOGLE_PLAY_STORE_LINK} target="_blank" rel="noopener noreferrer">
                <img src={GooglePlay} alt="Google Play" />
              </a>
            </div>
          </div>
        </Content>
        <Footer className="login-footer">
          All Rights Reserved © {new Date().getFullYear()} VentureDive Pvt Ltd.
          <span>{APP_VERSION}</span>
        </Footer>
      </EnhancedLayout>
    );
  }
}

export default Login;
