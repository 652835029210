/* eslint-disable */
import {
  EnhancedCard,
  EnhancedButton,
  EnhancedCol,
  EnhancedRow,
  EnhancedAlert,
  EnhancedLayout,
  EnhancedTooltip,
  EnhancedModal,
  EnhancedSelect,
  EnhancedInput,
  EnhancedCheckbox,
} from 'components/shared/antd';
import React, { Component } from 'react';
import { Popconfirm, message } from 'antd';
// eslint-disable-next-line
import imageCompression from 'browser-image-compression';
import RequiredField from 'components/RequiredField';
import moment from 'moment';
import {
  EMPLOYEE_EXPENSE_CLAIM,
  EMPLOYEE_EXPENSE_CATEGORY_BUSINESS,
  EMPLOYEE_EXPENSE_CATEGORY_TRAVEL,
  EMPLOYEE_EXPENSE_CATEGORY_ENTERTAINMENT,
  EMPLOYEE_EXPENSE_CATEGORY_OTHER,
  EMPLOYEE_EXPENSE_CATEGORY_TRAINING,
} from 'configs/constants';
import { EXPENSE_CLAIM_LIST } from 'configs/routesConstants';
import './AddExpenseClaim.css';
import ExpenseClaimInputGroup from './ExpenseClaimInputGroup';

const { Header } = EnhancedLayout;
const { confirm } = EnhancedModal;

const { Option, OptGroup } = EnhancedSelect;
const { TextArea } = EnhancedInput;

const compressionOption = {
  maxSizeMB: 1,
};

class AddExpenseClaim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      claimCategory: {
        business: false,
        entertainment: false,
        travel: false,
        other: false,
        training: false,
      },
      projectId: '',
      totalExpense: null,
      claimReceiptImages: [],
      claimReceiptImgList: [],
      allClaims: {},
      imageCompressionInProgress: false,
      claimComments: '',
    };
    this.allProjectMenu = null;
    this.supportAndOtherProject = null;
    this.completeDetails = {};
    this.confirmationPopupCategoryTitle = null;
  }

  componentDidMount() {
    // this.props.getEmployeesSkillsActions(this.props.userId);
    this.props.getClaimProjectList();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isSuccessfull && !nextProps.isLoading) {
      this.props.resetSuccessFlag();
      this.props.history.push(EXPENSE_CLAIM_LIST);
    }
  }

  onProjectSelect = (value) => {
    this.setState({
      projectId: value,
    });
  };
  onImagePick = (e) => {
    const file = e.target && e.target.files && e.target.files[0];
    if (this.fileIsAnImage(file.name)) {
      this.setState({ imageCompressionInProgress: true });
      imageCompression(e.target.files[0], compressionOption)
        .then((compressedImage) => {
          imageCompression.getDataUrlFromFile(compressedImage).then((imageInBase634) => {
            this.setState({
              claimReceiptImages: [...this.state.claimReceiptImages, imageInBase634],
              imageCompressionInProgress: false,
            });
          });
        })
        .catch(() => {
          this.setState({ imageCompressionInProgress: false });
          // message.error(err)
        });
    } else {
      message.error('Unsupported file type');
    }
  };

  onImagePickNew = (e) => {
    const files = e.target && e.target.files && e.target.files;
    for (let file of files) {
      if (file) {
        if (this.fileIsAnImage(file.name)) {
          this.setState({ imageCompressionInProgress: true });
          imageCompression(file, compressionOption)
            .then((compressedImage) => {
              imageCompression.getDataUrlFromFile(compressedImage).then((imageInBase634) => {
                this.setState({
                  claimReceiptImages: [...this.state.claimReceiptImages, imageInBase634],
                  claimReceiptImgList: [...this.state.claimReceiptImgList, file.name],
                  imageCompressionInProgress: false,
                });
                if (document.getElementById('upload-images')) {
                  // document.getElementById('upload-images').value = null;
                }
              });
            })
            .catch(() => {
              this.setState({ imageCompressionInProgress: false });
              // message.error(err)
            });
        } else {
          message.error('Unsupported file type');
        }
      }
    }
  };

  onClaimSubmit = () => {
    const { allClaims, projectId, claimReceiptImages, claimComments } = this.state;
    const { user } = this.props;
    const employeeDetails = {
      employeeName: user.fullName,
      date: moment(new Date()).format('LL'),
      designation: user.designation,
      oracleId: user.oracleId,
      department: user.deptt,
      manager: user.manager && user.manager ? user.manager.fullName : null,
      projectId,
      claimComments,
      location: user.location,
    };
    const expenseCompleteDetailDto = {
      allClaims,
      employeeDetails,
      claimReceiptImages,
    };
    this.props.addExpenseClaim(expenseCompleteDetailDto);
  };

  onCommentsChange = (event) => {
    const comments = event.target.value.replace(/ +(?= )/g, '');
    this.setState({ claimComments: comments });
  };

  discardDataIfCategoryDeSelect = (checkedValue) => {
    const { claimCategory } = this.state;
    if (checkedValue === false) {
      let anyCategorySelected = false;
      for (let key in claimCategory) {
        if (claimCategory.hasOwnProperty(key)) {
          if (claimCategory[key]) {
            // Any category selected
            anyCategorySelected = true;
          } else {
            anyCategorySelected = false;
          }
        }
      }
      if (!anyCategorySelected) {
        this.setState({
          projectId: '',
          // totalExpense: null,
          claimReceiptImages: [],
          claimReceiptImgList: [],
          claimComments: '',
        });
      }
    }
  };

  onclaimCategorySelect = (type, event) => {
    const { allClaims, claimCategory } = this.state;
    const checkedValue = event.target.checked;
    const deSelection = this.checkIfDeSelectCategoryHasData(type);

    // Check if user de-selected the claim category and there is entry added
    if (checkedValue === false) {
      if (deSelection.isExist) {
        confirm({
          title: 'Category already selected',
          content: 'Clicking ok will discard your entries',
          onOk: () => {
            const allClaimsBeforeDeleteEntry = allClaims;
            Object.keys(allClaimsBeforeDeleteEntry).forEach((key) => {
              if (deSelection.existanceIds.includes(Number(key))) {
                delete allClaimsBeforeDeleteEntry[key];
              }
            });
            this.setState(
              {
                claimCategory: {
                  ...this.state.claimCategory,
                  [type]: checkedValue,
                },
                allClaims: allClaimsBeforeDeleteEntry,
              },
              () => {
                const total = this.getTotalAmount();
                this.setState({ totalExpense: total });
                this.discardDataIfCategoryDeSelect(checkedValue);
              },
            );
          },
          onCancel() {},
        });
      } else {
        this.setState(
          {
            claimCategory: {
              ...this.state.claimCategory,
              [type]: checkedValue,
            },
          },
          () => {
            this.discardDataIfCategoryDeSelect(checkedValue);
          },
        );
      }
    } else {
      // if (type === 'business') {
      this.setState({
        claimCategory: {
          ...this.state.claimCategory,
          [type]: checkedValue,
        },
      });
    }
    // Remove un-selected fields
  };

  getTotalAmount = () => {
    const { allClaims } = this.state;
    let total = 0;
    // eslint-disable-next-line
    for (const i in allClaims) {
      if (allClaims[i] && allClaims[i].amount) {
        total += allClaims[i].amount;
      }
    }
    if (!Number.isInteger(total)) {
      total = total.toFixed(2);
    }
    return total > 0 ? total : null;
  };

  getSubmitButton = () => (
    <Popconfirm
      title={
        <p>
          Are you sure to continue? <br /> Once Submitted, cannot be edited or deleted.{' '}
        </p>
      }
      placement="topRight"
      onConfirm={this.onClaimSubmit}
      onCancel={this.cancel}
      okText="Yes"
      cancelText="No"
    >
      <EnhancedButton
        disabled={this.shouldButtonDisable()}
        type="primary"
        loading={this.props.isLoading}
      >
        Submit
      </EnhancedButton>
    </Popconfirm>
  );

  removeFromImageList = (index) => {
    const { claimReceiptImages, claimReceiptImgList } = this.state;
    claimReceiptImages.splice(index, 1);
    claimReceiptImgList.splice(index, 1);
    this.setState({
      claimReceiptImages,
      claimReceiptImgList,
    });
  };

  deleteImage = (imgIndex) => {
    const { claimReceiptImages } = this.state;
    claimReceiptImages.splice(imgIndex, 1);
    this.setState({
      claimReceiptImages,
    });
  };

  checkIfAnyCategorySelected = () => {
    const { claimCategory } = this.state;
    // eslint-disable-next-line
    for (const i in claimCategory) {
      // If any category selected it will return true
      if (claimCategory[i]) {
        return true;
      }
    }
    return false;
  };

  shouldButtonDisable = () => {
    const { allClaims, claimReceiptImages, claimComments, projectId } = this.state;
    // eslint-disable-next-line
    if (claimReceiptImages.length > 0 && claimComments !== '' && projectId !== '') {
      let shouldDisable = true;
      for (const i in allClaims) {
        if (
          allClaims[i].amount &&
          allClaims[i].selected &&
          allClaims[i].amount > 0 &&
          allClaims[i].startDate
        ) {
          shouldDisable = false;
        } else {
          shouldDisable = true;
          break;
        }
      }
      return shouldDisable;
    }
    return true;
  };

  claimDateSelected = (claimId, startDate) => {
    this.setState({
      allClaims: {
        ...this.state.allClaims,
        [claimId]: {
          ...this.state.allClaims[claimId],
          startDate,
        },
      },
    });
  };

  claimDateRangeSelected = (claimId, dateRange, amount) => {
    this.setState(
      {
        allClaims: {
          ...this.state.allClaims,
          [claimId]: {
            ...this.state.allClaims[claimId],
            startDate: dateRange[0],
            endDate: dateRange[1],
            amount: amount ? amount : this.state.allClaims[claimId].amount,
          },
        },
      },
      () => {
        const total = this.getTotalAmount();
        this.setState({ totalExpense: total });
      },
    );
  };

  selectedTypeofClaim = (claimId, isSelected) => {
    if (isSelected) {
      this.setState({
        allClaims: {
          ...this.state.allClaims,
          [claimId]: {
            ...this.state.allClaims[claimId],
            selected: isSelected,
          },
        },
      });
    } else {
      const { allClaims } = this.state;
      const allClaimsCopy = Object.assign({}, allClaims);
      delete allClaimsCopy[claimId];
      this.setState({ allClaims: allClaimsCopy }, () => {
        const total = this.getTotalAmount();
        this.setState({ totalExpense: total });
      });
    }
  };

  claimAmount = (claimId, amount) => {
    this.setState(
      {
        allClaims: {
          ...this.state.allClaims,
          [claimId]: {
            ...this.state.allClaims[claimId],
            amount: Number(amount),
          },
        },
      },
      () => {
        const total = this.getTotalAmount();
        this.setState({ totalExpense: total });
      },
    );
  };

  otherClaimDescription = (claimId, description) => {
    this.setState({
      allClaims: {
        ...this.state.allClaims,
        [claimId]: {
          ...this.state.allClaims[claimId],
          description,
          selected: this.state.claimCategory.other,
        },
      },
    });
  };

  trainingClaimDescription = (claimId, description) => {
    this.setState({
      allClaims: {
        ...this.state.allClaims,
        [claimId]: {
          ...this.state.allClaims[claimId],
          description,
          selected: this.state.claimCategory.training,
        },
      },
    });
  };

  checkIfDeSelectCategoryHasData = (type) => {
    const { allClaims } = this.state;
    // eslint-disable-next-line
    for (const v in allClaims) {
      if (type === 'business' && EMPLOYEE_EXPENSE_CATEGORY_BUSINESS.includes(Number(v))) {
        this.confirmationPopupCategoryTitle = 'Category selection warning';
        return {
          isExist: true,
          existanceIds: EMPLOYEE_EXPENSE_CATEGORY_BUSINESS,
        };
      }
      if (type === 'travel' && EMPLOYEE_EXPENSE_CATEGORY_TRAVEL.includes(Number(v))) {
        this.confirmationPopupCategoryTitle = 'Entered data for travel claim will be lost';
        return {
          isExist: true,
          existanceIds: EMPLOYEE_EXPENSE_CATEGORY_TRAVEL,
        };
      }
      if (type === 'entertainment' && EMPLOYEE_EXPENSE_CATEGORY_ENTERTAINMENT.includes(Number(v))) {
        this.confirmationPopupCategoryTitle = 'Entered data for entertainmet claim will be lost';
        return {
          isExist: true,
          existanceIds: EMPLOYEE_EXPENSE_CATEGORY_ENTERTAINMENT,
        };
      }
      if (type === 'training' && EMPLOYEE_EXPENSE_CATEGORY_TRAINING.includes(Number(v))) {
        this.confirmationPopupCategoryTitle = 'Entered data for training claim will be lost';
        return {
          isExist: true,
          existanceIds: EMPLOYEE_EXPENSE_CATEGORY_TRAINING,
        };
      }
      if (type === 'other' && EMPLOYEE_EXPENSE_CATEGORY_OTHER.includes(Number(v))) {
        this.confirmationPopupCategoryTitle = 'Entered data for other claim will be lost';
        return {
          isExist: true,
          existanceIds: EMPLOYEE_EXPENSE_CATEGORY_OTHER,
        };
      }
    }
    return false;
  };

  fileIsAnImage = (file) => {
    const allowedExtension = ['jpeg', 'jpg', 'png'];
    const fileExtension = file.split('.').pop().toLowerCase();
    let isValidFile = false;
    // for (const index in allowedExtension)
    for (let index = 0; index < allowedExtension.length; index += 1) {
      if (fileExtension === allowedExtension[index]) {
        isValidFile = true;
        break;
      }
    }
    return isValidFile;
  };

  render() {
    const {
      errorText,
      claimCategory,
      claimComments,
      claimReceiptImgList,
      totalExpense,
      imageCompressionInProgress,
    } = this.state;
    const { user, claimProjectList } = this.props;
    this.allProjectMenu =
      claimProjectList &&
      claimProjectList.map((project, i) => {
        if (project.id === 0) {
          return (
            <Option key={i} disabled>
              <hr />
            </Option>
          );
        } else {
          return <Option key={project.id}>{project.name}</Option>;
        }
      });
    return (
      <div>
        <Header className="project-header" style={{ marginBottom: '6px' }}>
          <h1 style={{ marginLeft: '-17px', color: '#0D2234' }}>Expense Claim Form</h1>
        </Header>
        {errorText ? (
          <EnhancedAlert
            message="Error"
            description={errorText}
            type="error"
            closable
            onClose={this.onCloseError}
          />
        ) : null}
        <EnhancedCard bordered={false} bodyStyle={{ padding: '4px 0px 4px 32px' }}>
          <h2 style={{ margin: '0px' }}>Employee Info</h2>
        </EnhancedCard>
        {user && (
          <EnhancedCard bordered={false} style={{ marginTop: '1px' }}>
            <EnhancedRow className="marginBottom12">
              <EnhancedCol lg={8}>
                <EnhancedRow>
                  <EnhancedCol md={8}>
                    <b>Employee #: </b>
                  </EnhancedCol>
                  <EnhancedCol md={16}>
                    <span>{user.taleoId}</span>
                  </EnhancedCol>
                </EnhancedRow>
              </EnhancedCol>
              <EnhancedCol lg={8}>
                <EnhancedRow>
                  <EnhancedCol md={9}>
                    <b>Employee Name: </b>
                  </EnhancedCol>
                  <EnhancedCol md={15}>
                    <span>{user.fullName}</span>
                  </EnhancedCol>
                </EnhancedRow>
              </EnhancedCol>
              <EnhancedCol lg={8}>
                <EnhancedRow>
                  <EnhancedCol md={7}>
                    <b>Department: </b>
                  </EnhancedCol>
                  <EnhancedCol md={17}>
                    <span>{user.deptt}</span>
                  </EnhancedCol>
                </EnhancedRow>
              </EnhancedCol>
            </EnhancedRow>

            <EnhancedRow className="marginBottom12">
              <EnhancedCol lg={8}>
                <EnhancedRow>
                  <EnhancedCol md={8}>
                    <b>Location: </b>
                  </EnhancedCol>
                  <EnhancedCol md={16}>
                    <span>{user.location}</span>
                  </EnhancedCol>
                </EnhancedRow>
              </EnhancedCol>
              <EnhancedCol lg={8}>
                <EnhancedRow>
                  <EnhancedCol md={9}>
                    <b>Manager Name: </b>
                  </EnhancedCol>
                  <EnhancedCol md={15}>
                    <span>{user.manager && user.manager ? user.manager.fullName : ''}</span>
                  </EnhancedCol>
                </EnhancedRow>
              </EnhancedCol>
              <EnhancedCol lg={8}>
                <EnhancedRow>
                  <EnhancedCol md={7}>
                    <b>Designation: </b>
                  </EnhancedCol>
                  <EnhancedCol md={17}>
                    <span>{user.designation}</span>
                  </EnhancedCol>
                </EnhancedRow>
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCard>
        )}
        <EnhancedCard
          bordered={false}
          style={{ marginTop: '8px' }}
          bodyStyle={{ padding: '4px 0px 4px 32px' }}
        >
          <h2 style={{ margin: '0px' }}>Claim Types</h2>
        </EnhancedCard>
        <EnhancedCard bordered={false} style={{ marginTop: '1px' }}>
          <EnhancedRow className="marginBottom12">
            <EnhancedCol lg={5}>
              <EnhancedCheckbox
                onChange={(e) => {
                  this.onclaimCategorySelect('business', e);
                }}
                checked={claimCategory.business}
              />{' '}
              &nbsp; Business
            </EnhancedCol>
            <EnhancedCol lg={5}>
              <EnhancedCheckbox
                onChange={(e) => {
                  this.onclaimCategorySelect('entertainment', e);
                }}
                checked={claimCategory.entertainment}
              />{' '}
              &nbsp; Entertainment
            </EnhancedCol>
            <EnhancedCol lg={5}>
              <EnhancedCheckbox
                onChange={(e) => {
                  this.onclaimCategorySelect('travel', e);
                }}
                checked={claimCategory.travel}
              />{' '}
              &nbsp; Travel
            </EnhancedCol>
            <EnhancedCol lg={4}>
              <EnhancedCheckbox
                onChange={(e) => {
                  this.onclaimCategorySelect('training', e);
                }}
                checked={claimCategory.training}
              />{' '}
              &nbsp; Training
            </EnhancedCol>
            <EnhancedCol lg={5}>
              <EnhancedCheckbox
                onChange={(e) => {
                  this.onclaimCategorySelect('other', e);
                }}
                checked={claimCategory.other}
              />{' '}
              &nbsp; Other
            </EnhancedCol>
          </EnhancedRow>
        </EnhancedCard>
        {claimCategory.business ? (
          <div>
            <EnhancedCard
              bordered={false}
              style={{ marginTop: '8px' }}
              bodyStyle={{ padding: '4px 0px 4px 32px' }}
            >
              <h2 style={{ margin: '0px' }}>Business</h2>
            </EnhancedCard>
            <EnhancedCard
              bordered={false}
              style={{ marginTop: '1px' }}
              bodyStyle={{ paddingBottom: '0px' }}
            >
              <EnhancedRow>
                <EnhancedCol lg={24}>
                  <div>
                    <ExpenseClaimInputGroup
                      claimType={EMPLOYEE_EXPENSE_CLAIM.PHONE_CALL_OR_INTERNET}
                      checkBoxSelection={this.selectedTypeofClaim}
                      claimAmount={this.claimAmount}
                      claimDateRangeSelected={this.claimDateRangeSelected}
                    />
                    <ExpenseClaimInputGroup
                      claimType={EMPLOYEE_EXPENSE_CLAIM.MARKETING}
                      checkBoxSelection={this.selectedTypeofClaim}
                      claimAmount={this.claimAmount}
                      claimDateRangeSelected={this.claimDateRangeSelected}
                    />
                  </div>
                </EnhancedCol>
              </EnhancedRow>
            </EnhancedCard>
          </div>
        ) : null}
        {claimCategory.entertainment ? (
          <div>
            <EnhancedCard
              bordered={false}
              style={{ marginTop: '8px' }}
              bodyStyle={{ padding: '4px 0px 4px 32px' }}
            >
              <h2 style={{ margin: '0px' }}>Entertainment</h2>
            </EnhancedCard>
            <EnhancedCard
              bordered={false}
              style={{ marginTop: '1px' }}
              bodyStyle={{ paddingBottom: '0px' }}
            >
              <EnhancedRow>
                <EnhancedCol lg={24}>
                  <div>
                    <div>
                      <ExpenseClaimInputGroup
                        claimType={EMPLOYEE_EXPENSE_CLAIM.MEAL_ALLOWANCE}
                        checkBoxSelection={this.selectedTypeofClaim}
                        claimAmount={this.claimAmount}
                        claimDateRangeSelected={this.claimDateRangeSelected}
                      />
                      <ExpenseClaimInputGroup
                        claimType={EMPLOYEE_EXPENSE_CLAIM.TEAM_RECREATION_AND_ENTERTAINMENT}
                        checkBoxSelection={this.selectedTypeofClaim}
                        claimAmount={this.claimAmount}
                        claimDateRangeSelected={this.claimDateRangeSelected}
                      />
                      <ExpenseClaimInputGroup
                        claimType={EMPLOYEE_EXPENSE_CLAIM.GUEST_ENTERTAINMENT}
                        checkBoxSelection={this.selectedTypeofClaim}
                        claimAmount={this.claimAmount}
                        claimDateRangeSelected={this.claimDateRangeSelected}
                      />
                    </div>
                  </div>
                </EnhancedCol>
              </EnhancedRow>
            </EnhancedCard>
          </div>
        ) : null}
        {claimCategory.travel ? (
          <div>
            <EnhancedCard
              bordered={false}
              style={{ marginTop: '8px' }}
              bodyStyle={{ padding: '4px 0px 4px 32px' }}
            >
              <h2 style={{ margin: '0px' }}>Travel</h2>
            </EnhancedCard>
            <EnhancedCard
              bordered={false}
              style={{ marginTop: '1px' }}
              bodyStyle={{ paddingBottom: '0px' }}
            >
              <EnhancedRow>
                <EnhancedCol lg={24}>
                  <div>
                    <ExpenseClaimInputGroup
                      claimType={EMPLOYEE_EXPENSE_CLAIM.HOTEL_ACCODMODATION_LODGING}
                      checkBoxSelection={this.selectedTypeofClaim}
                      claimAmount={this.claimAmount}
                      claimDateRangeSelected={this.claimDateRangeSelected}
                    />
                    <ExpenseClaimInputGroup
                      claimType={EMPLOYEE_EXPENSE_CLAIM.LAUNDRY}
                      checkBoxSelection={this.selectedTypeofClaim}
                      claimAmount={this.claimAmount}
                      claimDateRangeSelected={this.claimDateRangeSelected}
                    />
                    <ExpenseClaimInputGroup
                      claimType={EMPLOYEE_EXPENSE_CLAIM.AIR_TICKET_OR_TAXI_FARE}
                      checkBoxSelection={this.selectedTypeofClaim}
                      claimAmount={this.claimAmount}
                      claimDateRangeSelected={this.claimDateRangeSelected}
                    />
                    <ExpenseClaimInputGroup
                      claimType={EMPLOYEE_EXPENSE_CLAIM.INCIDENTALS}
                      checkBoxSelection={this.selectedTypeofClaim}
                      claimAmount={this.claimAmount}
                      claimDateRangeSelected={this.claimDateRangeSelected}
                    />
                    <ExpenseClaimInputGroup
                      claimType={EMPLOYEE_EXPENSE_CLAIM.TADA_LOCAL}
                      checkBoxSelection={this.selectedTypeofClaim}
                      claimAmount={this.claimAmount}
                      claimDateRangeSelected={this.claimDateRangeSelected}
                    />
                    <ExpenseClaimInputGroup
                      claimType={EMPLOYEE_EXPENSE_CLAIM.TADA_INTERNATIONAL}
                      checkBoxSelection={this.selectedTypeofClaim}
                      claimAmount={this.claimAmount}
                      claimDateRangeSelected={this.claimDateRangeSelected}
                    />
                  </div>
                </EnhancedCol>
              </EnhancedRow>
            </EnhancedCard>
          </div>
        ) : null}
        {claimCategory.training ? (
          <div>
            <EnhancedCard
              bordered={false}
              style={{ marginTop: '8px' }}
              bodyStyle={{ padding: '4px 0px 4px 32px' }}
            >
              <h2 style={{ margin: '0px' }}>Training</h2>
            </EnhancedCard>
            <EnhancedCard
              bordered={false}
              style={{ marginTop: '1px' }}
              bodyStyle={{ paddingBottom: '0px' }}
            >
              <EnhancedRow>
                <EnhancedCol lg={24}>
                  <div>
                    <ExpenseClaimInputGroup
                      claimType={EMPLOYEE_EXPENSE_CLAIM.TRAINING}
                      checkBoxSelection={this.selectedTypeofClaim}
                      claimAmount={this.claimAmount}
                      claimDateRangeSelected={this.claimDateRangeSelected}
                      otherClaimDescription={this.trainingClaimDescription}
                    />
                  </div>
                </EnhancedCol>
              </EnhancedRow>
            </EnhancedCard>
          </div>
        ) : null}
        {claimCategory.other ? (
          <div>
            <EnhancedCard
              bordered={false}
              style={{ marginTop: '8px' }}
              bodyStyle={{ padding: '4px 0px 4px 32px' }}
            >
              <h2 style={{ margin: '0px' }}>Other</h2>
            </EnhancedCard>
            <EnhancedCard
              bordered={false}
              style={{ marginTop: '1px' }}
              bodyStyle={{ paddingBottom: '0px' }}
            >
              <EnhancedRow>
                <EnhancedCol lg={24}>
                  <div>
                    <ExpenseClaimInputGroup
                      claimType={EMPLOYEE_EXPENSE_CLAIM.OTHER}
                      checkBoxSelection={this.selectedTypeofClaim}
                      claimAmount={this.claimAmount}
                      claimDateRangeSelected={this.claimDateRangeSelected}
                      otherClaimDescription={this.otherClaimDescription}
                    />
                  </div>
                </EnhancedCol>
              </EnhancedRow>
            </EnhancedCard>
          </div>
        ) : null}
        {this.checkIfAnyCategorySelected() ? (
          <EnhancedCard bordered={false} bodyStyle={{ paddingTop: '0px', paddingBottom: '0px' }}>
            <EnhancedRow style={{ marginBottom: '10px' }}>
              <EnhancedCol lg={18} style={{ textAlign: 'right', padding: '5px 10px 0px 0px' }}>
                <b>Total Expense</b>
              </EnhancedCol>
              <EnhancedCol lg={6}>
                <EnhancedInput
                  style={{ border: '2px solid #e8e8e6' }}
                  disabled
                  value={totalExpense}
                  type="text"
                  placeholder="Amount in PKR"
                  onChange={this.onAmountChange}
                />
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCard>
        ) : null}
        {this.checkIfAnyCategorySelected() ? (
          <div>
            <EnhancedCard
              bordered={false}
              style={{ marginTop: '8px' }}
              bodyStyle={{ padding: '4px 0px 4px 32px' }}
            >
              <h2 style={{ margin: '0px' }}>More Details</h2>
            </EnhancedCard>
            <EnhancedCard bordered={false} style={{ marginTop: '1px' }}>
              <EnhancedRow>
                <EnhancedCol lg={12}>
                  <EnhancedRow>
                    <EnhancedCol lg={24}>
                      <EnhancedCol lg={4}>
                        <RequiredField>Project</RequiredField>
                      </EnhancedCol>
                      <EnhancedCol lg={20}>
                        <EnhancedSelect
                          mode="default"
                          style={{ width: '100%' }}
                          placeholder="Select Project"
                          onChange={this.onProjectSelect}
                        >
                          {this.allProjectMenu}
                        </EnhancedSelect>
                        <span style={{ fontSize: '10px' }}>
                          {'*Admin, HR and Finance should select Support Staff'}
                        </span>
                      </EnhancedCol>
                    </EnhancedCol>
                  </EnhancedRow>
                  <br />
                  <EnhancedRow>
                    <EnhancedCol lg={24}>
                      <EnhancedCol lg={4}>
                        <RequiredField>Comments</RequiredField>
                      </EnhancedCol>
                      <EnhancedCol lg={20}>
                        <TextArea
                          style={{ resize: 'none' }}
                          placeholder="Mention your comments here"
                          rows="4"
                          value={claimComments}
                          onChange={this.onCommentsChange}
                        />
                      </EnhancedCol>
                    </EnhancedCol>
                  </EnhancedRow>
                </EnhancedCol>
                <EnhancedCol lg={12}>
                  <EnhancedRow>
                    <EnhancedCol lg={24}>
                      <EnhancedCol lg={1} />
                      <EnhancedCol lg={6}>
                        <RequiredField>{'  '}Upload Images</RequiredField>
                      </EnhancedCol>
                      <EnhancedCol lg={17}>
                        <div className="upload-images-section">
                          <EnhancedInput
                            id="upload-images"
                            title=""
                            multiple
                            style={{ border: 'none', width: '110px' }}
                            type="file"
                            accept="image/*"
                            disabled={imageCompressionInProgress}
                            onChange={this.onImagePickNew}
                          />
                          <p style={{ paddingLeft: '10px' }}>
                            {imageCompressionInProgress
                              ? 'Please wait! Image compression is in process'
                              : null}
                          </p>
                          {claimReceiptImgList.length ? (
                            <p style={{ paddingLeft: '10px' }}>
                              Image Selected: {claimReceiptImgList.length}
                            </p>
                          ) : null}
                          {claimReceiptImgList && claimReceiptImgList.length ? (
                            <div className="uploaded-images-checklist">
                              <ol>
                                {claimReceiptImgList.map((v, i) => (
                                  <li
                                    onKeyDown={() => {}}
                                    role="presentation"
                                    style={{ paddingLeft: '10px' }}
                                  >
                                    {v}{' '}
                                    <sup
                                      onClick={() => {
                                        this.removeFromImageList(i);
                                      }}
                                    >
                                      x
                                    </sup>
                                  </li>
                                ))}
                              </ol>
                            </div>
                          ) : null}
                        </div>
                      </EnhancedCol>
                    </EnhancedCol>
                  </EnhancedRow>
                </EnhancedCol>
              </EnhancedRow>
            </EnhancedCard>
          </div>
        ) : null}
        {this.checkIfAnyCategorySelected() ? (
          <EnhancedCard bordered={false}>
            <EnhancedRow style={{ marginTop: '10px' }}>
              <EnhancedCol lg={12} />
              <EnhancedCol lg={12} style={{ textAlign: 'right' }}>
                {this.shouldButtonDisable() ? (
                  <EnhancedTooltip title="Project, comments and images are mandatory">
                    {this.getSubmitButton()}
                  </EnhancedTooltip>
                ) : (
                  <span>{this.getSubmitButton()}</span>
                )}
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCard>
        ) : null}
      </div>
    );
  }
}

export default AddExpenseClaim;
