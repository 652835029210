import { connect } from 'react-redux';
import Employees from 'components/employees/NonBillable';
import { getAllDesignationActions } from 'store/actions/DesignationActions';
import { updateEmployeeSkillsActions } from 'store/actions/EmployeeActions';

function mapStateToProps(state) {
  const { auth, employeeReducer, designationsReducer } = state;
  return {
    userId: auth.user && auth.user.id,
    readOnly: auth.user && auth.user.isReadOnly,
    employees: employeeReducer.employees,
    designations: designationsReducer.designations,
    isLoading: employeeReducer.isLoading,
    user: auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateEmployeeSkillsActions: (employees, isDelete) =>
      dispatch(updateEmployeeSkillsActions(employees, isDelete)),
    getAllDesignations: () => dispatch(getAllDesignationActions()),
  };
}
const NonBillableContainer = connect(mapStateToProps, mapDispatchToProps)(Employees);

export default NonBillableContainer;
