import React from 'react';
import { connect } from 'react-redux';
import { deleteInterview } from '../../sources/interviewSources';
import { EnhancedModal } from '../../components/shared/antd';

const DeleteInterview = ({ visible, onClose, recordId, deleteRecord }) => {
  const handleOk = () => {
    if (recordId) {
      deleteRecord(recordId);
      onClose();
    }
  };
  return (
    <EnhancedModal
      title="Delete Interview Record"
      visible={visible || false}
      onOk={handleOk}
      onCancel={onClose}
    >
      <p>Are you sure you want to delete this interview record?</p>
    </EnhancedModal>
  );
};

const mapDispatch = (dispatch) => ({
  deleteRecord: (recordId) => dispatch(deleteInterview(recordId)),
});

export default connect(null, mapDispatch)(DeleteInterview);
