import {
  EnhancedButton,
  EnhancedCol,
  EnhancedRow,
  EnhancedSpin,
  EnhancedModal,
  EnhancedInput,
  EnhancedSelect,
  EnhancedDatePicker,
} from 'components/shared/antd';
import React from 'react';
import moment from 'moment';
import { dateFormat } from 'configs/constants';
import { invalidDate } from 'configs/additionalConstants';
import { CRMC_PROJECTS_COMPLETED } from 'configs/routesConstants';
import RequiredField from '../RequiredField';

const { Option } = EnhancedSelect;

class ProjectDetailsEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectDetailsModalVisible: props.visible,
      information: {
        startDate: undefined,
        endDate: undefined,
        projectManagerId: undefined,
        deliveryManagerId: undefined,
        architectId: undefined,
        name: undefined,
        projectId: undefined,
        isActive: true,
        employeeId: props.userId,
        leadershipRoles: [],
        businessUnitId: undefined,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.projectDetailsModalVisible !== nextProps.visible) {
      this.setState({
        projectDetailsModalVisible: nextProps.visible,
      });
    }
    const information = { ...nextProps.project };
    const dateFormatWithSpace = dateFormat.DDMMMYYYYwithSpace;
    if (information.startDate) {
      information.startDate = moment.utc(information.startDate, dateFormatWithSpace);
    }
    if (information.endDate) {
      information.endDate = moment.utc(information.endDate, dateFormatWithSpace);
    }
    this.setState({
      information: {
        startDate: information.startDate,
        endDate:
          moment.utc(information.endDate).toString() === invalidDate ? '' : information.endDate,
        name: information.name ? information.name : undefined,
        projectManagerId: information.manager ? information.manager.id : undefined,
        deliveryManagerId: information.deliveryManager ? information.deliveryManager.id : undefined,
        architectId: information.architect ? information.architect.id : undefined,
        projectId: information.id,
        employeeId: nextProps.userId,
        isActive: true,
        leadershipRoles: information.leadershipRoles || [],
        businessUnitId: information.businessUnit ? information.businessUnit.id : undefined,
      },
    });
  }

  onStartDateChange = (startDate) => {
    this.setState({
      information: {
        ...this.state.information,
        startDate,
      },
    });
  };
  onEndDateChange = (endDate) => {
    this.setState({
      information: {
        ...this.state.information,
        endDate,
      },
    });
  };

  onNameChange = (event) => {
    this.setState({
      information: {
        ...this.state.information,
        name: event.target.value,
      },
    });
  };
  onManagerIdChange = (projectManagerId) => {
    this.setState({
      information: {
        ...this.state.information,
        projectManagerId,
      },
    });
  };

  onDeliveryManagerIdChange = (deliveryManagerId) => {
    const deliveryManagerIdVar = deliveryManagerId === undefined ? null : deliveryManagerId;
    this.setState({
      information: {
        ...this.state.information,
        deliveryManagerId: deliveryManagerIdVar,
      },
    });
  };
  onBusineesUnitIdChange = (businessUnitId) => {
    const businessUnitIdVar = businessUnitId === undefined ? null : businessUnitId;
    this.setState({
      information: {
        ...this.state.information,
        businessUnitId: businessUnitIdVar,
      },
    });
  };
  onArchitectIdChange = (architectId) => {
    const architectIdVar = architectId === undefined ? null : architectId;
    this.setState({
      information: {
        ...this.state.information,
        architectId: architectIdVar,
      },
    });
  };

  getTitle = () => {
    const { project, isMarkAsCompleted } = this.props;
    if (isMarkAsCompleted) {
      return `Are you sure you want to mark project '${project.name}' as completed?`;
    }
    return `Edit Details of Project: '${project.name}'`;
  };

  getProjectRoleValue = (projectRole) => {
    const { information } = this.state;
    const ids = information.leadershipRoles
      .filter((lr) => lr.projectRoleId === projectRole.id)
      .map((lr) => lr.employeeId);
    return ids.length > 0 ? ids : undefined;
  };

  handleModalCancel = () => {
    this.setState({
      information: {
        ...this.state.information,
      },
      projectDetailsModalVisible: false,
    });
  };

  shouldButtonDisable = () => {
    const { information } = this.state;
    const { isMarkAsCompleted } = this.props;
    if (isMarkAsCompleted) {
      if (information.endDate) {
        return false;
      }
      return true;
    }
    if (information.startDate && information.name && information.businessUnitId) {
      return false;
    }
    return true;
  };
  disabledStartDate = (startValue) => {
    const endValue = this.state.information.endDate
      ? moment.utc(this.state.information.endDate)
      : Date();
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };
  disabledEndDate = (endValue) => {
    const startValue = moment.utc(this.state.information.startDate).add(1, 'days');
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  handleProjectDetailsEdit = (isMarkAsCompleted, employees) => {
    const { information } = this.state;

    information.isActive = !isMarkAsCompleted;
    this.setState({
      information: {
        ...information,
        isActive: !isMarkAsCompleted,
      },
      projectDetailsModalVisible: false,
    });
    this.props.editProjectDetails(information.projectId, information, this.props.userId);
    if (isMarkAsCompleted) {
      employees.forEach((emp) => {
        const previousDate = new Date(emp.endDate);
        const projectCompletedDate = new Date(information.endDate.format('DD MMM YYYY'));
        if (emp) {
          const newEmp = {
            ...emp,
            existing: false,
          };
          if (previousDate > projectCompletedDate || emp.endDate === '') {
            newEmp.endDate = information.endDate;
          }
          newEmp.extendedEndDate = information.endDate;
          newEmp.probability = 0;
          this.props.updateEmployeesProjectActions(emp, newEmp);
        }
      });
      this.props.history.push(CRMC_PROJECTS_COMPLETED);
    }
    this.props.onModalClose(false);
  };

  handleProjectRoleChange = (employeeIds, projectRole) => {
    let { information } = this.state;
    const leadershipRoles = [
      ...information.leadershipRoles.filter((lr) => lr.projectRoleId !== projectRole.id),
    ];
    leadershipRoles.push(
      ...employeeIds.map((employeeId) => ({
        employeeId,
        projectRoleId: projectRole.id,
      })),
    );
    information = {
      ...information,
      leadershipRoles,
    };
    this.setState({
      information,
    });
  };

  afterClose = () => {
    this.props.onModalClose(false);
  };

  renderDynamicFormRows = () => {
    const { managers, leadershipRoles } = this.props;
    return leadershipRoles.map((projectRole) => (
      <EnhancedRow className="margin-top10" key={projectRole.id}>
        <EnhancedCol lg={8}>{projectRole.role}</EnhancedCol>
        <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
          <EnhancedSelect
            showSearch
            allowClear
            mode="multiple"
            optionFilterProp="children"
            value={this.getProjectRoleValue(projectRole)}
            style={{ width: '100%' }}
            placeholder="Please Select"
            onChange={(value) => this.handleProjectRoleChange(value, projectRole)}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {managers && managers.length
              ? managers.map((employee) =>
                  employee.isActive ? (
                    <Option key={employee.id} value={employee.id}>
                      {employee.name}
                    </Option>
                  ) : (
                    ''
                  ),
                )
              : null}
          </EnhancedSelect>
        </EnhancedCol>
      </EnhancedRow>
    ));
  };

  render() {
    const { isMarkAsCompleted } = this.props;
    const { information, projectDetailsModalVisible } = this.state;
    const { managers, businessUnits } = this.props;

    return (
      <EnhancedModal
        title={this.getTitle()}
        visible={projectDetailsModalVisible}
        onCancel={this.handleModalCancel}
        footer={null}
        afterClose={this.afterClose}
        style={
          isMarkAsCompleted
            ? {}
            : {
                backgroundColor: 'white',
                maxHeight: '90%',
                padding: '0',
              }
        }
        bodyStyle={isMarkAsCompleted ? {} : { padding: '0', maxHeight: '90%' }}
      >
        <EnhancedRow
          style={isMarkAsCompleted ? {} : { padding: '24px', height: '500px', overflowY: 'scroll' }}
        >
          <EnhancedCol lg={24}>
            {!isMarkAsCompleted && (
              <EnhancedRow className="margin-top10">
                <EnhancedCol lg={8}>
                  <RequiredField>Name :</RequiredField>
                </EnhancedCol>
                <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
                  <EnhancedInput
                    style={{ width: '100%' }}
                    value={information.name}
                    onChange={this.onNameChange}
                  />
                </EnhancedCol>
              </EnhancedRow>
            )}

            {!isMarkAsCompleted && (
              <EnhancedRow className="margin-top10">
                <EnhancedCol lg={8}>Project Manager :</EnhancedCol>
                <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
                  <EnhancedSelect
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    value={information.projectManagerId}
                    style={{ width: '100%' }}
                    placeholder="Please Select"
                    onChange={this.onManagerIdChange}
                    notFoundContent={
                      this.props.isLoadingEmployees ? <EnhancedSpin size="small" /> : null
                    }
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {managers && managers.length
                      ? managers.map((manager) =>
                          manager.isActive ? (
                            <Option key={manager.id} value={manager.id}>
                              {manager.name}
                            </Option>
                          ) : (
                            ''
                          ),
                        )
                      : null}
                  </EnhancedSelect>
                </EnhancedCol>
              </EnhancedRow>
            )}

            {!isMarkAsCompleted && (
              <EnhancedRow className="margin-top10">
                <EnhancedCol lg={8}>Delivery Manager :</EnhancedCol>
                <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
                  <EnhancedSelect
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    value={information.deliveryManagerId}
                    style={{ width: '100%' }}
                    placeholder="Please Select"
                    onChange={this.onDeliveryManagerIdChange}
                    notFoundContent={
                      this.props.isLoadingEmployees ? <EnhancedSpin size="small" /> : null
                    }
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {managers && managers.length
                      ? managers.map((manager) =>
                          manager.isActive ? (
                            <Option key={manager.id} value={manager.id}>
                              {manager.name}
                            </Option>
                          ) : (
                            ''
                          ),
                        )
                      : null}
                  </EnhancedSelect>
                </EnhancedCol>
              </EnhancedRow>
            )}
            {!isMarkAsCompleted && (
              <EnhancedRow className="margin-top10">
                <EnhancedCol lg={8}>
                  <RequiredField>Business Unit :</RequiredField>
                </EnhancedCol>
                <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
                  <EnhancedSelect
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    value={information.businessUnitId}
                    style={{ width: '100%' }}
                    placeholder="Please Select"
                    onChange={this.onBusineesUnitIdChange}
                    notFoundContent={
                      this.props.isLoadingEmployees ? <EnhancedSpin size="small" /> : null
                    }
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {businessUnits && businessUnits.length
                      ? businessUnits.map((businessUnit) => (
                          <Option key={businessUnit.id} value={businessUnit.id}>
                            {`${businessUnit.name}`}
                          </Option>
                        ))
                      : null}
                  </EnhancedSelect>
                </EnhancedCol>
              </EnhancedRow>
            )}
            {!isMarkAsCompleted && (
              <EnhancedRow className="margin-top10">
                <EnhancedCol lg={8}>Architect :</EnhancedCol>
                <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
                  <EnhancedSelect
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    value={information.architectId}
                    style={{ width: '100%' }}
                    placeholder="Please Select"
                    onChange={this.onArchitectIdChange}
                    notFoundContent={
                      this.props.isLoadingEmployees ? <EnhancedSpin size="small" /> : null
                    }
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {managers && managers.length
                      ? managers.map((manager) =>
                          manager.isActive ? (
                            <Option key={manager.id} value={manager.id}>
                              {manager.name}
                            </Option>
                          ) : (
                            ''
                          ),
                        )
                      : null}
                  </EnhancedSelect>
                </EnhancedCol>
              </EnhancedRow>
            )}
            {!isMarkAsCompleted && this.renderDynamicFormRows()}
            {!isMarkAsCompleted && (
              <EnhancedRow className="margin-top10">
                <EnhancedCol lg={8}>
                  <RequiredField>Start Date :</RequiredField>
                </EnhancedCol>
                <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
                  <EnhancedDatePicker
                    style={{ width: '100%' }}
                    onChange={this.onStartDateChange}
                    allowClear
                    value={information.startDate}
                    format={dateFormat.DDMMMYYYYwithSpace}
                    disabledDate={this.disabledStartDate}
                  />
                </EnhancedCol>
              </EnhancedRow>
            )}

            <EnhancedRow className="margin-top10">
              <EnhancedCol lg={8}>
                {!isMarkAsCompleted ? 'End Date :' : <RequiredField>End Date :</RequiredField>}
              </EnhancedCol>
              <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
                <EnhancedDatePicker
                  style={{ width: '100%' }}
                  onChange={this.onEndDateChange}
                  allowClear
                  value={information.endDate}
                  format={dateFormat.DDMMMYYYYwithSpace}
                  disabledDate={this.disabledEndDate}
                />
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow style={{ padding: '24px' }} className="margin-top10">
          <EnhancedCol className="w-full">
            <EnhancedButton
              type="primary"
              style={{ float: 'right' }}
              onClick={() => this.handleProjectDetailsEdit(isMarkAsCompleted, this.props.employees)}
              disabled={this.shouldButtonDisable()}
              loading={this.props.isProjectLoading}
            >
              {isMarkAsCompleted ? 'Yes' : 'Edit Project'}
            </EnhancedButton>
            <EnhancedButton
              style={{ marginRight: 5, float: 'right' }}
              onClick={this.handleModalCancel}
            >
              {isMarkAsCompleted ? 'No' : 'Cancel'}
            </EnhancedButton>
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedModal>
    );
  }
}

export default ProjectDetailsEditModal;
