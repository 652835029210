import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ROOT, LOGIN } from 'configs/routesConstants';

import store from 'store/store';
import LoginContainer from 'containers/LoginContainer';
import AppContainer from 'containers/AppContainer';

import withTracker from 'components/withTracker';

const AppRoot = () => (
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path={LOGIN} component={withTracker(LoginContainer)} />
        <Route path={ROOT} component={withTracker(AppContainer)} />
      </Switch>
    </Router>
  </Provider>
);

export default AppRoot;
