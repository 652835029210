import { connect } from 'react-redux';
import Designations from 'components/designations/designations';
import {
  getAllDesignationActions,
  createDesignationActions,
  updateDesignationActions,
  deleteDesignationActions,
} from 'store/actions/DesignationActions';

function mapStateToProps(state) {
  const { designationsReducer, auth } = state;
  const { designations, isLoading } = designationsReducer;
  return {
    designations,
    isLoading,
    userId: auth.user && auth.user.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAllDesignations: () => dispatch(getAllDesignationActions()),
    createDesignation: (request) => dispatch(createDesignationActions(request)),
    updateDesignation: (request) => dispatch(updateDesignationActions(request)),
    deleteDesignation: (request) => dispatch(deleteDesignationActions(request)),
  };
}

const DesignationsContainer = connect(mapStateToProps, mapDispatchToProps)(Designations);

export default DesignationsContainer;
