import { connect } from 'react-redux';
import AddExpenseClaim from 'components/expense/AddExpenseClaim';
import {
  getClaimProjectListAction,
  addExpenseClaimAction,
  resetSuccessFlagAction,
} from 'store/actions/ExpenseClaimActions';

function mapStateToProps(state) {
  const { auth, addClaimReducer, getClaimReducer } = state;
  return {
    user: auth.user,
    isLoading: addClaimReducer.isLoading,
    isSuccessfull: addClaimReducer.isSuccessfull,
    claimProjectList: getClaimReducer.claimProjectList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getClaimProjectList: () => {
      dispatch(getClaimProjectListAction());
    },
    addExpenseClaim: (expenseClaimDto) => {
      dispatch(addExpenseClaimAction(expenseClaimDto));
    },
    resetSuccessFlag: () => {
      dispatch(resetSuccessFlagAction());
    },
  };
}
const AddExpenseClaimContainer = connect(mapStateToProps, mapDispatchToProps)(AddExpenseClaim);

export default AddExpenseClaimContainer;
