import { addEmployee } from 'sources/AddEmployeeSource';

import {
  ADD_EMPLOYEE_INIT,
  ADD_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_FAILURE,
  RESET_INITIAL_STATE,
} from 'constants/AddEmployeeTypes';

export const addEmployeeInit = () => ({
  type: ADD_EMPLOYEE_INIT,
});

export const addEmployeeSuccess = () => ({
  type: ADD_EMPLOYEE_SUCCESS,
});

export const addEmployeeFailure = (error = null) => ({
  type: ADD_EMPLOYEE_FAILURE,
  payload: error,
});

export const resetAddEmployeeAction = () => ({
  type: RESET_INITIAL_STATE,
});

export const addEmployeeAction = (req) => addEmployee(req);
