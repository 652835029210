import { EnhancedNotification } from 'components/shared/antd';
import { getTeamLeaveBalance } from 'sources/LeavesSource';
import { getCompanyWideDashboard, adjustLeaveQuota } from 'sources/hr/LeavesDashboardSource';
import {
  GET_TEAM_LEAVES_BALANCE_INIT,
  GET_TEAM_LEAVES_BALANCE_SUCCESS,
  GET_TEAM_LEAVES_BALANCE_FAILURE,
  ADJUST_LEAVES_QUOTA_INIT,
  ADJUST_LEAVES_QUOTA_SUCCESS,
  ADJUST_LEAVES_QUOTA_FAILURE,
} from 'constants/LeaveDashboard';
import { showLoader, hideLoader } from 'store/actions/AppLoaderActions';
import { generateAllLeavesReport, generateLeavesQuotasReport } from 'utils/leaveUtils';

export const getTeamLeavesBalanceInit = () => ({
  type: GET_TEAM_LEAVES_BALANCE_INIT,
});

export const getTeamLeavesBalanceSuccess = (data) => ({
  type: GET_TEAM_LEAVES_BALANCE_SUCCESS,
  data,
});

export const getTeamLeavesBalanceFailure = () => ({
  type: GET_TEAM_LEAVES_BALANCE_FAILURE,
});

// ASYNC ACTIONS
// export const getTeamLeavesBalanceAction = managerId => getTeamLeaveBalance(managerId);
export const getTeamLeavesBalanceAction = (attributeId, params) => (dispatch) => {
  dispatch(getTeamLeavesBalanceInit());
  dispatch(showLoader());
  return getTeamLeaveBalance(attributeId, params)
    .then((response) => {
      dispatch(hideLoader());
      dispatch(getTeamLeavesBalanceSuccess(response));
    })
    .catch((error) => {
      dispatch(hideLoader());
      dispatch(getTeamLeavesBalanceFailure(error));
    });
};

export const exportLeaveQuotasAction = (attributeId, params) => (dispatch) => {
  dispatch(showLoader());
  return getTeamLeaveBalance(attributeId, params)
    .then((response) => {
      generateLeavesQuotasReport(response.leaveData);
      dispatch(hideLoader());
    })
    .catch(() => {
      dispatch(hideLoader());
      EnhancedNotification.error({ message: 'Unable to download leave quotas.' });
    });
};

export const exportAllLeavesAction = (params) => (dispatch) => {
  dispatch(showLoader());
  return getCompanyWideDashboard(params)
    .then((response) => {
      generateAllLeavesReport(response.companyWideDashboard);
      dispatch(hideLoader());
    })
    .catch(() => {
      dispatch(hideLoader());
      EnhancedNotification.error({ message: 'Unable to download all leaves list.' });
    });
};

export const adjustLeaveQuotaInit = () => ({
  type: ADJUST_LEAVES_QUOTA_INIT,
});

export const adjustLeaveQuotaSuccess = (data) => ({
  type: ADJUST_LEAVES_QUOTA_SUCCESS,
  data,
});

export const adjustLeaveQuotaFailure = () => ({
  type: ADJUST_LEAVES_QUOTA_FAILURE,
});

export const adjustLeaveQuotaAction = (data) => (dispatch) => {
  dispatch(adjustLeaveQuotaInit());
  return adjustLeaveQuota(data)
    .then(() => dispatch(adjustLeaveQuotaSuccess()))
    .catch((error) => dispatch(adjustLeaveQuotaFailure(error)));
};
