import { EnhancedButton, EnhancedDatePicker } from 'components/shared/antd';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class DateRangeFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }

  handleRangeChange = (dates) => {
    const { setSelectedKeys } = this.props;

    setSelectedKeys({
      start: dates[0],
      end: dates[1],
    });
  };

  handleApply = () => {
    this.props.onApply({ ...this.props.selectedKeys });
  };

  handleClear = () => {
    this.props.onApply({});
  };

  render() {
    const { selectedKeys, filterDropdownVisible } = this.props;

    if (filterDropdownVisible !== this.state.visible) {
      this.setState({ visible: filterDropdownVisible });
      this.props.setSelectedKeys({ ...this.props.filteredValue });
    }

    return (
      <div className="daterange-filter">
        <EnhancedDatePicker.RangePicker
          ranges={{
            Today: [moment(), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
          }}
          onChange={this.handleRangeChange}
          value={[selectedKeys.start, selectedKeys.end]}
          allowClear={false}
        />
        <div className="enhanced-filter-actions">
          <EnhancedButton type="link" onClick={this.handleApply} className="mr-16">
            Apply
          </EnhancedButton>
          <EnhancedButton type="link" onClick={this.handleClear}>
            Clear
          </EnhancedButton>
        </div>
      </div>
    );
  }
}

export default DateRangeFilter;

DateRangeFilter.propTypes = {
  onApply: PropTypes.func.isRequired,
};
