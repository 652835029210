import React from 'react';
import { Progress } from 'antd';
import PropTypes from 'prop-types';

const EnhancedProgress = (props) => {
  const { children, className, percent, strokeColor, strokeLinecap, trailColor, type, ...rest } =
    props;

  return (
    <Progress
      trailColor={trailColor}
      className={className}
      percent={percent}
      strokeColor={strokeColor}
      strokeLinecap={strokeLinecap}
      type={type}
      {...rest}
    >
      {children}
    </Progress>
  );
};

EnhancedProgress.propTypes = {
  className: PropTypes.string,
  // style: PropTypes.shape({}),
  percent: PropTypes.number,
  strokeColor: PropTypes.string.isRequired,
  strokeLinecap: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

EnhancedProgress.defaultProps = {
  className: '',
  // style: {},
  percent: 0,
};

export default EnhancedProgress;
