import {
  EnhancedRow,
  EnhancedCol,
  EnhancedCard,
  EnhancedTag,
  EnhancedDatePicker,
  EnhancedInput,
  EnhancedNotification,
  EnhancedTypography,
} from 'components/shared/antd';
import React, { useEffect, useState } from 'react';
import PivotTable from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import maxBy from 'lodash/maxBy';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import moment from 'moment';
import { dateFormat } from 'configs/constants';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import { NOT_AVAILABLE } from 'configs/additionalConstants';
import { roundOffRating } from 'utils/conversionUtils';
import './BenchSummaryReport.css';

const { Text } = EnhancedTypography;

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

const BenchReport = (props) => {
  const {
    benchResources,
    getAllDesignations,
    getEmployeeDepartmentsAction,
    getEmployeeDivisionsAction,
    getEmployeeCompetenciesAction,
    getEmployeeBusinessUnitAction,
  } = props;
  const [state, setState] = useState({
    benchResources: [],
    selectedResources: [],
    pivotTableFilters: {},
    selectedDate: '',
    probability: '',
    remaining: 0,
    expectedBench: '',
  });
  const [showAllPrimarySkills, setShowAllPrimarySkills] = useState(-1);
  const [showAllSecondarySkills, setShowAllSecondarySkills] = useState(-1);
  const [pivotTable, setPivotTableState] = useState({
    rows: ['competency'],
    cols: ['allocationPlan'],
    aggregatorName: 'Sum',
    vals: ['aggregate'],
    rendererOptions: { plotly: { width: 600, height: 600 } },
    tableOptions: {
      clickCallback: (e, value, filters, pivotData) => {
        // in some cases, some key values are coming as null in filters, specially when we click "sum" rows at the bottom, hence it is effecting filters
        const correctFilters = {};

        Object.keys(filters).forEach((key) => {
          if (filters[key]) {
            correctFilters[key] = filters[key];
          }
        });
        const resources = [];
        pivotData.forEachMatchingRecord(correctFilters, (record) => {
          resources.push(record);
        });
        setState((prevState) => ({
          ...prevState,
          selectedResources: resources,
          pivotTableFilters: filters,
        }));
      },
    },
    unusedOrientationCutoff: Infinity,
  });

  useEffect(() => {
    getAllDesignations();
    getEmployeeDepartmentsAction();
    getEmployeeDivisionsAction();
    getEmployeeCompetenciesAction();
    getEmployeeBusinessUnitAction();
  }, []);

  const renderSkills = (skills, index, stateVar, setStateVar) => {
    const actionOutline = 'solid #BFBFBF 2px';
    let response = '';
    let splittedSkills = [];

    if (skills) {
      splittedSkills = skills.split(', ');
    }

    const tagStyle = {
      backgroundColor: '#fff',
      border: 'solid #DEDFD7 2px',
      minWidth: 30,
      height: 28,
      paddingTop: 2,
      marginRight: 5,
      marginTop: 5,
      borderRadius: '4px',
    };

    const showLessBadge = (
      <EnhancedTag
        style={{
          ...tagStyle,
          backgroundColor: 'white',
          color: 'rgb(90, 90, 90)',
          cursor: 'pointer',
          border: actionOutline,
        }}
        onClick={() => {
          setStateVar(-1);
        }}
      >
        Show Less
      </EnhancedTag>
    );

    const showMoreBadge = (
      <EnhancedTag
        style={{
          ...tagStyle,
          backgroundColor: 'white',
          color: 'rgb(90, 90, 90)',
          cursor: 'pointer',
          border: actionOutline,
        }}
        onClick={() => {
          setStateVar(index);
        }}
      >
        {`${splittedSkills.length - 2} More`}
      </EnhancedTag>
    );

    if (splittedSkills.length === 0) {
      response = NOT_AVAILABLE;
    } else if (splittedSkills.length > 2) {
      if (stateVar === index) {
        const allSkills = splittedSkills.map((skill) => (
          <EnhancedTag style={{ ...tagStyle }}>{skill}</EnhancedTag>
        ));
        response = [allSkills, showLessBadge];
      } else {
        const slicedSkills = splittedSkills.slice(0, 2).map((skill) => {
          return <EnhancedTag style={{ ...tagStyle }}>{skill}</EnhancedTag>;
        });
        response = [...slicedSkills, showMoreBadge];
      }
    } else {
      response = splittedSkills.map((skill) => {
        return (
          <EnhancedTag key={skill} style={{ ...tagStyle }}>
            {skill}
          </EnhancedTag>
        );
      });
    }

    return response;
  };

  const renderPrimarySkills = (skills, record, index) => {
    return renderSkills(skills, index, showAllPrimarySkills, setShowAllPrimarySkills);
  };

  const renderSecondarySkills = (skills, record, index) => {
    return renderSkills(skills, index, showAllSecondarySkills, setShowAllSecondarySkills);
  };

  const renderDetails = (details) => {
    let response = 'N/A';

    if (details.deals && details.deals.length > 0) {
      response = details.deals.map((deal) => {
        return (
          <pre className="mb-0">
            <a target="_blank" href={`/deals/${deal.dealId}`}>
              {deal.dealName}
            </a>
          </pre>
        );
      });
    } else if (details.projects && details.projects.length > 0) {
      response = details.projects.map((project) => {
        return (
          <pre className="mb-0">
            <span>{project.name}</span>
          </pre>
        );
      });
    } else if (details.leaveType) {
      response = details.leaveType;
    }

    return response;
  };

  const renderProbability = (details, _record) => {
    let response = '';

    if (_record.details.deals && _record.details.deals.length > 0) {
      response = _record.details.deals.map((deal) => {
        return (
          <pre className="mb-0">
            <span>{deal.probability}</span>
          </pre>
        );
      });
    }

    return response;
  };

  const getColumnsInfo = () => {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 110,
        sorter: (a, b) => a.name.length - b.name.length,
        filterConfig: {
          type: 'search',
          key: 'name',
        },
      },
      {
        title: 'Availability',
        dataIndex: 'availability',
        key: 'availability',
        filterConfig: {
          type: 'search',
          key: 'availability',
        },
        sorter: (a, b) => a.availability.length - b.availability.length,
        render: (availability) =>
          typeof availability === 'number' ? roundOffRating(availability) : availability,
      },
      {
        title: 'Allocation Plan',
        dataIndex: 'allocationPlan',
        key: 'allocationPlan',
        filterConfig: {
          type: 'search',
          key: 'allocationPlan',
        },
        sorter: (a, b) => a.allocationPlan.length - b.allocationPlan.length,
      },
      {
        title: 'Details',
        dataIndex: 'details',
        key: 'details',
        width: 250,
        filterConfig: {
          type: 'search',
          key: 'details',
          customFunc: (item, filterValue) =>
            (item.details.deals &&
              item.details.deals.some((deal) =>
                deal.dealName.toString().toLowerCase().includes(filterValue),
              )) ||
            (item.details.projects &&
              item.details.projects.some((project) =>
                project.name.toString().toLowerCase().includes(filterValue),
              )),
        },
        sorter: (a, b) => {
          let valueA = '';
          let valueB = '';

          if (a.details.deals && a.details.deals.length > 0) {
            const sortedDealsA = a.details.deals.sort();
            valueA = sortedDealsA[0].dealName;
          }

          if (b.details.deals && b.details.deals.length > 0) {
            const sortedDealsB = b.details.deals.sort();
            valueB = sortedDealsB[0].dealName;
          }

          return valueA.localeCompare(valueB);
        },
        render: renderDetails,
      },
      {
        title: 'Probability',
        dataIndex: 'probability',
        key: 'probability',
        filterConfig: {
          type: 'search',
          key: 'details',
          customFunc: (item, filterValue) =>
            item.details.deals &&
            item.details.deals.some((deal) => deal.probability.toString().includes(filterValue)),
        },
        width: 250,
        sorter: (a, b) => {
          let valueA = 0;
          let valueB = 0;

          if (a.details.deals && a.details.deals.length > 0) {
            valueA = maxBy(a.details.deals, 'probability').probability;
          }

          if (b.details.deals && b.details.deals.length > 0) {
            valueB = maxBy(b.details.deals, 'probability').probability;
          }

          return valueA - valueB;
        },
        render: renderProbability,
      },
      {
        title: 'Primary Skills',
        dataIndex: 'primarySkills',
        key: 'primarySkills',
        sorter: (a, b) => a.renderPrimarySkills.length - b.renderPrimarySkills.length,
        filterConfig: {
          type: 'search',
          key: 'primarySkills',
        },
        render: renderPrimarySkills,
      },
      {
        title: 'Secondary Skills',
        dataIndex: 'secondarySkills',
        key: 'secondarySkills',
        filterConfig: {
          type: 'search',
          key: 'secondarySkills',
        },
        sorter: (a, b) => a.secondarySkills.length - b.secondarySkills.length,
        render: renderSecondarySkills,
      },
      {
        title: 'Designation',
        dataIndex: 'designation',
        key: 'designation',
        filterConfig: {
          type: 'enhanced_filter',
          key: 'designation',
          optionsConfig: {
            values: props.designations,
          },
        },
        sorter: (a, b) => a.designation.length - b.designation.length,
        ellipsis: true,
      },
      {
        title: 'Department',
        dataIndex: 'department',
        key: 'department',
        filterConfig: {
          type: 'enhanced_filter',
          key: 'department',
          optionsConfig: {
            values: props.departments,
          },
        },
        sorter: (a, b) => a.department.length - b.department.length,
        ellipsis: true,
      },
      {
        title: 'Total Experience',
        dataIndex: 'totalExperience',
        key: 'totalExperience',
        sorter: (a, b) => a.totalExperience.length - b.totalExperience.length,
        filterConfig: {
          type: 'search',
          key: 'totalExperience',
        },
      },
      {
        title: 'Competency',
        dataIndex: 'competency',
        key: 'competency',
        filterConfig: {
          type: 'enhanced_filter',
          key: 'competency',
          optionsConfig: {
            values: props.competencies,
          },
        },
        sorter: (a, b) => a.competency.length - b.competency.length,
        ellipsis: true,
      },
      {
        title: 'Division',
        dataIndex: 'division',
        key: 'division',
        filterConfig: {
          type: 'enhanced_filter',
          key: 'division',
          optionsConfig: {
            values: props.divisions,
          },
        },
        sorter: (a, b) => a.division.length - b.division.length,
        ellipsis: true,
      },
      {
        title: 'Business Unit',
        dataIndex: 'businessUnit',
        key: 'businessUnit',
        filterConfig: {
          type: 'enhanced_filter',
          key: 'businessUnit',
          optionsConfig: {
            values: props.businessUnits,
          },
        },
        sorter: (a, b) => a.businessUnit.length - b.businessUnit.length,
        ellipsis: true,
      },
    ];
    return columns;
  };

  const getFileHeaders = () => {
    const headers = [
      {
        label: 'Name',
        key: 'name',
      },
      {
        label: 'Details',
        key: 'details',
        renderer: (value) => {
          let renderedValue = '';
          if (value.deals && value.deals.length > 0) {
            renderedValue = value.deals.map((deal) => deal.dealName).join(', ');
          }

          if (value.projects && value.projects.length > 0) {
            renderedValue = value.projects.map((project) => project.name).join(', ');
          }

          if (value.leaveType) {
            renderedValue = value.leaveType;
          }

          return renderedValue;
        },
      },
      {
        label: 'Probability',
        key: 'details',
        renderer: (value) => {
          let renderedValue = '';
          if (value.deals && value.deals.length > 0) {
            renderedValue = value.deals.map((deal) => deal.probability).join(', ');
          }

          return renderedValue;
        },
      },
      {
        label: 'Division',
        key: 'division',
      },
      {
        label: 'Competency',
        key: 'competency',
      },
      {
        label: 'Department',
        key: 'department',
      },
      {
        label: 'Business Unit',
        key: 'businessUnit',
      },
      {
        label: 'Designation',
        key: 'designation',
      },
      {
        label: 'Availability',
        key: 'availability',
      },
      {
        label: 'Primary Skills',
        key: 'primarySkills',
      },
      {
        label: 'Secondary Skills',
        key: 'secondarySkills',
      },
      {
        label: 'Total Experience',
        key: 'totalExperience',
      },
      {
        label: 'Allocation Plan',
        key: 'allocationPlan',
      },
    ];

    return headers;
  };

  const getFilename = () => {
    const { pivotTableFilters } = state;
    const fileName = ['Bench Summary Report'];

    if (pivotTableFilters.allocationPlan) {
      fileName.push(pivotTableFilters.allocationPlan);
    }
    if (pivotTableFilters.competency) {
      fileName.push(pivotTableFilters.competency);
    }

    return fileName.join('_');
  };

  const dateChange = (date, dateString) => {
    if (!dateString) {
      EnhancedNotification.error({
        message: 'Please select date',
      });
      return;
    }
    setState({ ...state, selectedDate: date, selectedResources: [] });
    props.getBenchSummaryActions(dateString);
  };

  useEffect(() => {
    const selectedDate = moment().add(1, 'd').format(dateFormat.YYYYMMDDwithHyphen);
    setState({ ...state, selectedDate });
    props.getBenchSummaryActions(selectedDate);
  }, []);

  useEffect(() => {
    const resources = benchResources.map((resource) =>
      resource.competency && resource.competency !== ''
        ? resource
        : { ...resource, competency: 'Others' },
    );
    setState({ ...state, benchResources: resources });
  }, [benchResources]);

  useEffect(() => {
    if (state.probability) {
      let bench = 0;
      let pitched = 0;
      let leaves = 0;
      let tableAllocateFilter = [];
      let tableCompetencyFilter = [];
      let tableBusinessUnitFilter = [];
      let tableDepartmentFilter = [];
      // check in table state filter
      if (pivotTable.valueFilter) {
        tableAllocateFilter = Object.keys(pivotTable.valueFilter.allocationPlan || {});
        tableCompetencyFilter = Object.keys(pivotTable.valueFilter.competency || {});
        tableBusinessUnitFilter = Object.keys(pivotTable.valueFilter.businessUnit || {});
        tableDepartmentFilter = Object.keys(pivotTable.valueFilter.department || {});
      }
      for (let index = 0; index < benchResources.length; index += 1) {
        const resource = benchResources[index];
        if (
          tableAllocateFilter.indexOf(resource.allocationPlan) === -1 &&
          tableCompetencyFilter.indexOf(resource.competency) === -1 &&
          tableBusinessUnitFilter.indexOf(resource.businessUnit) === -1 &&
          tableDepartmentFilter.indexOf(resource.department) === -1
        ) {
          if (resource.allocationPlan === 'Bench' || resource.allocationPlan === 'Bench Extension')
            bench += resource.aggregate;
          if (resource.allocationPlan === 'pitched') pitched += resource.aggregate;
          if (resource.allocationPlan === 'leaves') leaves += resource.aggregate;
        }
      }
      // expected Bench calculation formula
      /* eslint-disable */
      bench = parseFloat(bench.toFixed(2));
      pitched = parseFloat(pitched.toFixed(2));
      leaves = parseFloat(leaves.toFixed(2));
      const expectedBench = bench + leaves + pitched * (state.remaining / 100);
      setState({ ...state, expectedBench: expectedBench.toFixed(2) });
    }
  }, [pivotTable, state.probability]);

  return (
    <div>
      <EnhancedCard style={{ marginBottom: '10px' }}>
        <EnhancedRow style={{ marginBottom: '10px' }}>
          <EnhancedCol lg={18}>
            <h1 className="heading-emp">Bench Resource</h1>
          </EnhancedCol>
          <EnhancedCol style={{ textAlign: 'right' }} lg={3}>
            <EnhancedInput
              value={state.probability}
              className="mr-5"
              style={{ width: '90%' }}
              placeholder="Probability"
              type="number"
              onChange={(e) =>
                setState({ ...state, probability: e.target.value, remaining: 100 - e.target.value })
              }
            />
          </EnhancedCol>
          <EnhancedCol lg={3} style={{ textAlign: 'right' }} className="clearFilter">
            <EnhancedDatePicker
              onChange={dateChange}
              defaultValue={moment().add(1, 'd')}
              disabledDate={(current) => current.isBefore(moment())}
              format={dateFormat.YYYYMMDDwithHyphen}
            />
          </EnhancedCol>
          {state.expectedBench && (
            <React.Fragment>
              <EnhancedCol lg={8}>
                <h1>Probability: {state.probability}%</h1>
              </EnhancedCol>
              <EnhancedCol lg={6}>
                <h1>Remaining: {state.remaining}%</h1>
              </EnhancedCol>
              <EnhancedCol lg={6}>
                <h1>Expected Bench: {state.expectedBench}</h1>
                <Text strong className="italic-text">
                  Bench + Bench Extension + Leaves + (Pitched * Remaining %)
                </Text>
              </EnhancedCol>
            </React.Fragment>
          )}
        </EnhancedRow>
      </EnhancedCard>
      <EnhancedCard
        bordered={false}
        style={{
          paddingBottom: 0,
          borderBottom: '3px solid #eee',
          overflowY: 'scroll',
        }}
      >
        <PivotTable
          onChange={(s) => setPivotTableState(s)}
          renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          data={state.benchResources}
          {...pivotTable}
          onRefresh={(data) => {
            console.log('data ', data);
          }}
        />
      </EnhancedCard>
      <EnhancedCard style={{ marginTop: '30px' }}>
        {state.selectedResources && state.selectedResources.length > 0 && (
          <EnhancedRow>
            <EnhancedCol lg={12}>
              <div>
                {state.pivotTableFilters.competency && (
                  <h2 className="heading-emp">Competency: {state.pivotTableFilters.competency}</h2>
                )}
                {state.pivotTableFilters.allocationPlan && (
                  <h2 className="heading-emp">
                    Allocation Plan: {state.pivotTableFilters.allocationPlan}
                  </h2>
                )}
              </div>
            </EnhancedCol>
          </EnhancedRow>
        )}
        <div style={{ marginTop: '30px', overflowY: 'scroll' }}>
          <EnhancedClientSideTable
            data={state.selectedResources}
            columns={getColumnsInfo()}
            exportFileConfig={{
              fileName: getFilename(),
              fileHeaders: getFileHeaders(),
              showExportButton: true,
            }}
          />
        </div>
      </EnhancedCard>
    </div>
  );
};

export default BenchReport;
