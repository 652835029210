import {
  getPerformanceCycles,
  getProjectByCycleId,
  getProjectRating,
  addProjectRating,
  addPerformanceCycle,
  getProjectByCycleIdForCRMC,
  deleteProjectRating,
  addEmployeeProjectRating,
  deleteProjectCycle,
  markPerformanceCycleComplete,
  extendProjectCycleDueDates,
  updatePerformanceCycle,
  getCRMCPanelProjectData,
  getPerformanceCRMCCycles,
  downloadPerformanceRatings,
  getRatingDetailsCRMC,
  getRatingDetails,
  getProjectScore,
} from 'sources/PerformanceCycleSource';
import {
  GET_PERFORMANCE_CYCLES_INIT,
  GET_PERFORMANCE_CYCLES_SUCCESS,
  GET_PERFORMANCE_CYCLES_FAILURE,
  GET_PROJECT_BY_CYCLE_ID_INIT,
  GET_PROJECT_BY_CYCLE_ID_SUCCESS,
  GET_PROJECT_BY_CYCLE_ID_FAILURE,
  GET_PROJECT_BY_CYCLE_ID_CRMC_INIT,
  GET_PROJECT_BY_CYCLE_ID_CRMC_SUCCESS,
  GET_PROJECT_BY_CYCLE_ID_CRMC_FAILURE,
  GET_PROJECT_RATING_INIT,
  GET_PROJECT_RATING_SUCCESS,
  GET_PROJECT_RATING_FAILURE,
  ADD_PROJECT_RATING_INIT,
  ADD_PROJECT_RATING_SUCCESS,
  ADD_PROJECT_RATING_FAILURE,
  CREATE_PERFORMANCE_CYCLE_INIT,
  CREATE_PERFORMANCE_CYCLE_SUCCESS,
  CREATE_PERFORMANCE_CYCLE_FAILURE,
  MARK_PERFORMANCE_CYCLE_COMPLETE_INIT,
  MARK_PERFORMANCE_CYCLE_COMPLETES_SUCCESS,
  MARK_PERFORMANCE_CYCLE_COMPLETE_FAILURE,
  UPDATE_PERFORMANCE_CYCLE_INIT,
  UPDATE_PERFORMANCE_CYCLE_SUCCESS,
  UPDATE_PERFORMANCE_CYCLE_FAILURE,
  GET_CRMC_PANEL_PROJECT_DATA_INIT,
  GET_CRMC_PANEL_PROJECT_DATA_SUCCESS,
  GET_CRMC_PANEL_PROJECT_DATA_FAILURE,
  GET_PERFORMANCE_CRMC_CYCLES_INIT,
  GET_PERFORMANCE_CRMC_CYCLES_SUCCESS,
  GET_PERFORMANCE_CRMC_CYCLES_FAILURE,
  GET_RATING_DETAILS_INIT,
  GET_RATING_DETAILS_SUCCESS,
  GET_RATING_DETAILS_FAILURE,
  RESET_RATING_DETAILS,
  GET_PROJECT_SCORE_INIT,
  GET_PROJECT_SCORE_SUCCESS,
  GET_PROJECT_SCORE_FAILURE,
} from 'constants/PerformanceCycleTypes';

// normal actions
export const getPerformanceCyclesInit = () => ({
  type: GET_PERFORMANCE_CYCLES_INIT,
});

export const getPerformanceCyclesSuccess = (data) => ({
  type: GET_PERFORMANCE_CYCLES_SUCCESS,
  data,
});

export const getPerformanceCyclesFailure = (data) => ({
  type: GET_PERFORMANCE_CYCLES_FAILURE,
  data,
});

export const getCRMCPanelProjectDataInit = () => ({
  type: GET_CRMC_PANEL_PROJECT_DATA_INIT,
});

export const getCRMCPanelProjectDataSuccess = (data) => ({
  type: GET_CRMC_PANEL_PROJECT_DATA_SUCCESS,
  data,
});

export const getCRMCPanelProjectDataFailure = (data) => ({
  type: GET_CRMC_PANEL_PROJECT_DATA_FAILURE,
  data,
});

export const getPerformanceCRMCCyclesInit = () => ({
  type: GET_PERFORMANCE_CRMC_CYCLES_INIT,
});

export const getPerformanceCRMCCyclesSuccess = (data) => ({
  type: GET_PERFORMANCE_CRMC_CYCLES_SUCCESS,
  data,
});

export const getPerformanceCRMCCyclesFailure = (data) => ({
  type: GET_PERFORMANCE_CRMC_CYCLES_FAILURE,
  data,
});

export const getProjectByCycleIdInit = () => ({
  type: GET_PROJECT_BY_CYCLE_ID_INIT,
});

export const getProjectByCycleIdSuccess = (data) => ({
  type: GET_PROJECT_BY_CYCLE_ID_SUCCESS,
  data,
});

export const getProjectByCycleIdFailure = (data) => ({
  type: GET_PROJECT_BY_CYCLE_ID_FAILURE,
  data,
});
export const getProjectByCycleIdCRMCInit = () => ({
  type: GET_PROJECT_BY_CYCLE_ID_CRMC_INIT,
});

export const getProjectByCycleIdCRMCSuccess = (data) => ({
  type: GET_PROJECT_BY_CYCLE_ID_CRMC_SUCCESS,
  data,
});

export const getProjectByCycleIdCRMCFailure = (data) => ({
  type: GET_PROJECT_BY_CYCLE_ID_CRMC_FAILURE,
  data,
});

export const getProjectRatingInit = () => ({
  type: GET_PROJECT_RATING_INIT,
});

export const getProjectRatingSuccess = (data) => ({
  type: GET_PROJECT_RATING_SUCCESS,
  data,
});

export const getProjectRatingFailure = (data) => ({
  type: GET_PROJECT_RATING_FAILURE,
  data,
});

export const addProjectRatingInit = () => ({
  type: ADD_PROJECT_RATING_INIT,
});

export const addProjectRatingSuccess = (data) => ({
  type: ADD_PROJECT_RATING_SUCCESS,
  data,
});

export const addProjectRatingFailure = (data) => ({
  type: ADD_PROJECT_RATING_FAILURE,
  data,
});

export const createPerformanceCycleInit = () => ({
  type: CREATE_PERFORMANCE_CYCLE_INIT,
});

export const createPerformanceCycleSuccess = (data) => ({
  type: CREATE_PERFORMANCE_CYCLE_SUCCESS,
  data,
});

export const createPerformanceCycleFailure = (data) => ({
  type: CREATE_PERFORMANCE_CYCLE_FAILURE,
  data,
});

export const markPerformanceCycleCompleteInit = () => ({
  type: MARK_PERFORMANCE_CYCLE_COMPLETE_INIT,
});

export const markPerformanceCycleCompleteSuccess = (data) => ({
  type: MARK_PERFORMANCE_CYCLE_COMPLETES_SUCCESS,
  data,
});

export const markPerformanceCycleCompleteFailure = (data) => ({
  type: MARK_PERFORMANCE_CYCLE_COMPLETE_FAILURE,
  data,
});

export const updatePerformanceCycleInit = () => ({
  type: UPDATE_PERFORMANCE_CYCLE_INIT,
});

export const updatePerformanceCycleSuccess = (data) => ({
  type: UPDATE_PERFORMANCE_CYCLE_SUCCESS,
  data,
});

export const updatePerformanceCycleFailure = (data) => ({
  type: UPDATE_PERFORMANCE_CYCLE_FAILURE,
  data,
});

export const getRatingDetailsInit = () => ({
  type: GET_RATING_DETAILS_INIT,
});

export const getRatingDetailsSuccess = (data) => ({
  type: GET_RATING_DETAILS_SUCCESS,
  data,
});

export const getRatingDetailsFailure = (data) => ({
  type: GET_RATING_DETAILS_FAILURE,
  data,
});

export const getProjectScoreInit = () => ({
  type: GET_PROJECT_SCORE_INIT,
});

export const getProjectScoreSuccess = (data) => ({
  type: GET_PROJECT_SCORE_SUCCESS,
  data,
});

export const getProjectScoreFailure = (data) => ({
  type: GET_PROJECT_SCORE_FAILURE,
  data,
});

export const resetRatingDetails = () => ({
  type: RESET_RATING_DETAILS,
});

export const getPerformanceCyclesAction = () => getPerformanceCycles();
export const getPerformanceCRMCCyclesAction = (employeeId) => getPerformanceCRMCCycles(employeeId);
export const getProjectByCycleIdAction = (cycleId) => getProjectByCycleId(cycleId);
export const getProjectByCycleIdActionForCRMC = (cycleId) => getProjectByCycleIdForCRMC(cycleId);
export const getProjectRatingAction = (projectCycleId, projectId) =>
  getProjectRating(projectCycleId, projectId);
export const addProjectRatingAction = (obj) => addProjectRating(obj);

export const createPerformanceCycleAction = (req) => addPerformanceCycle(req);
export const addEmployeeProjectRatingAction = (req) => addEmployeeProjectRating(req);
export const deleteProjectRatingAction = (req) => deleteProjectRating(req);
export const deleteProjectCycleAction = (req) => deleteProjectCycle(req);
export const markPerformanceCycleCompleteAction = (req) => markPerformanceCycleComplete(req);
export const extendProjectCycleDueDatesAction = (data) => extendProjectCycleDueDates(data);
export const updatePerformanceCycleAction = (pc) => updatePerformanceCycle(pc);
export const getCRMCPanelProjectDataAction = (projectCycleId, projectId) =>
  getCRMCPanelProjectData(projectCycleId, projectId);
export const downloadPerformanceRatingsAction = (cycleInfo) =>
  downloadPerformanceRatings(cycleInfo);
export const getRatingDetailsCRMCAction = (payload) => getRatingDetailsCRMC(payload);
export const getRatingDetailsAction = (payload) => getRatingDetails(payload);
export const getProjectScoreAction = (performanceCycleId, projectId) =>
  getProjectScore(performanceCycleId, projectId);
