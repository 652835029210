import { employeeType } from 'configs/constants';
import { NOT_AVAILABLE } from 'configs/additionalConstants';
import { isDateInRange } from 'utils/DateUtils';

import { convertResignationLocal, convertLeavingLocal } from 'normalizers/employeeNormalizers';
import { mapEmployeePrimarySkills, mapEmployeeSecondarySkills } from 'utils/employeeUtils';

const Types = {
  billed: 'BI',
  backup: 'BA',
  internal: 'I',
  additional: 'A',
};

function updateValues(typeArray, array, iterator, sum, billedSum, backupSum, internalSum) {
  let sumValue = sum;
  let billedSumValue = billedSum;
  let backupSumValue = backupSum;
  let internalSumValue = internalSum;

  if (
    typeArray[iterator] === employeeType.BILLED ||
    typeArray[iterator] === employeeType.INTERNAL ||
    typeArray[iterator] === employeeType.BACKUP
  ) {
    sumValue += array[iterator];
  }
  if (typeArray[iterator] === employeeType.BILLED) {
    billedSumValue += array[iterator];
  }
  if (typeArray[iterator] === employeeType.BACKUP) {
    backupSumValue += array[iterator];
  }
  if (typeArray[iterator] === employeeType.INTERNAL) {
    internalSumValue += array[iterator];
  }
  return {
    sumValue,
    billedSumValue,
    backupSumValue,
    internalSumValue,
  };
}

function availableCalculator(array, typeArray, startDate, releaseAt, date) {
  let sum = 0;
  let billedSum = 0;
  let backupSum = 0;
  let internalSum = 0;
  let values = {};
  const available = {
    bench: 0,
    internal: 0,
    backup: 0,
    billed: 0,
    fte: {},
  };

  for (let iterator = 0; iterator < array.length; iterator += 1) {
    if (!date || isDateInRange(startDate[iterator], releaseAt[iterator], date)) {
      values = updateValues(typeArray, array, iterator, sum, billedSum, backupSum, internalSum);
      sum = values.sumValue;
      billedSum = values.billedSumValue;
      backupSum = values.backupSumValue;
      internalSum = values.internalSumValue;
    }
  }
  sum = sum > 100 ? 100 : sum;
  available.bench = 100 - sum;
  available.internal = 100 - sum;
  available.backup = 100 - sum;
  available.billed = 100 - sum;
  available.fte.internal = internalSum;
  available.fte.backup = backupSum;
  available.fte.billed = billedSum;
  return available;
}

function makeArray(project) {
  let array = [];
  if (Array.isArray(project)) {
    array = project;
  } else {
    array.push(project);
  }
  return array;
}

function allocationType(allocation, type) {
  let val = [];
  val = allocation.map((value, index) => `${value}% , ${Types[type[index]]}`);
  return val;
}

function normalizeResourceAvailability(res, date) {
  return res
    .map((data) => {
      let { allocation, billed } = data;
      if (!Array.isArray(allocation)) allocation = [allocation];
      if (!Array.isArray(billed)) billed = [billed];
      return {
        name: data.fullName,
        primarySkills: mapEmployeePrimarySkills(makeArray(data.skills)) || [],
        secondarySkills: mapEmployeeSecondarySkills(makeArray(data.skills)) || [],
        skills: makeArray(data.skills) || [],
        designation: data.designation || NOT_AVAILABLE,
        notes: data.notes || '',
        resourceType: data.resourceType || NOT_AVAILABLE,
        department: data.deptt || NOT_AVAILABLE,
        location: data.location || NOT_AVAILABLE,
        project: makeArray(data.projectName) || [],
        allocated: allocation,
        billed,
        available: availableCalculator(
          allocation || [],
          makeArray(data.type) || [],
          makeArray(data.startDate) || [],
          makeArray(data.releaseAt) || [],
          date,
        ),
        releasingOn: makeArray(data.releaseAt) || [],
        startOn: makeArray(data.startDate) || [],
        type: makeArray(data.type) || [],
        allocation_type: allocationType(allocation, data.type),
        id: data.employeeId,
        employeeId: data.employeeId,
        leavesDetails: data.leaveDetails,
        leavingDate: convertLeavingLocal(data.leavingDate),
        resignationDate: convertResignationLocal(data.resignationDate),
        isActive: data.isActive,
        businessUnit: data.businessUnit || NOT_AVAILABLE,
        division: data.division || NOT_AVAILABLE,
        competency: data.competency || NOT_AVAILABLE,
        totalExperience: data.experience.totalInWords || NOT_AVAILABLE,
        experienceInYears: data.experience.totalInYears || NOT_AVAILABLE,
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
}

export default normalizeResourceAvailability;
