import {
  EnhancedCol,
  EnhancedIcon,
  EnhancedDropdown,
  EnhancedButton,
} from 'components/shared/antd';
import React from 'react';
// import { Dropdown, Button } from 'antd';

const DashboardButtons = ({ menu }) => (
  <EnhancedCol style={{ textAlign: 'right' }}>
    {menu && (
      <EnhancedDropdown overlay={menu}>
        <EnhancedButton style={{ marginRight: '15px' }}>
          Leave Adjustments <EnhancedIcon type="down" />
        </EnhancedButton>
      </EnhancedDropdown>
    )}
  </EnhancedCol>
);

export default DashboardButtons;
