/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import { dateFormat } from 'configs/constants';

export function normalizeProjects(res) {
  return res.map((data, index) => {
    const project = data || {};
    return {
      key: index,
      id: project.id,
      name: project.name,
      status: project.isActive,
      startDate: moment.utc(project.startDate).format(dateFormat.DDMMMYYYYwithSpace).toString(),
      endDate: moment.utc(project.endDate).format(dateFormat.DDMMMYYYYwithSpace).toString(),
      manager: project.projectManager,
      deliveryManager: project.deliveryManager,
      architect: project.architect,
      color: project.colorCode,
      approvedResources: project.approvedResources,
      leadershipRoles: project.leadershipRoles,
      businessUnit: project.businessUnit,
    };
  });
  // .sort((a, b) => a.name.localeCompare(b.name));
}

export function normalizeProjectsForWorkLog(res) {
  return res
    .map((data, index) => {
      const project = data || {};
      return {
        key: index,
        id: project.id,
        name: project.name,
        color: project.colorCode,
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
}
