import { connect } from 'react-redux';
import Employees from 'components/employees/Employees';
import {
  updateEmployeeSkillsActions,
  getEmployeeDepartmentsAction,
  getEmployeeCompetenciesAction,
  getEmployeeDivisionsAction,
  getEmployeeBusinessUnitAction,
  getEmployeesActions,
} from 'store/actions/EmployeeActions';
import { getAllDesignationActions } from 'store/actions/DesignationActions';
import { getAllLocationActions } from 'store/actions/LocationActions';

function mapStateToProps(state) {
  const { auth, employeeReducer, publicHolidaysReducer, designationsReducer, locationsReducer } =
    state;
  return {
    userId: auth.user && auth.user.id,
    readOnly: auth.user && auth.user.isReadOnly,
    employees: employeeReducer.employees,
    designations: designationsReducer.designations,
    locations: locationsReducer.locations,
    isLoading: employeeReducer.isLoading,
    workingHolidays: publicHolidaysReducer.publicHolidays.workingHolidays,
    user: auth.user,
    departments: employeeReducer.departments,
    competencies: employeeReducer.competencies,
    divisions: employeeReducer.divisions,
    businessUnits: employeeReducer.businessUnits,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEmployeesActions: (area) => dispatch(getEmployeesActions(area)),
    getEmployeeDepartmentsAction: () => dispatch(getEmployeeDepartmentsAction()),
    getAllDesignations: () => dispatch(getAllDesignationActions()),
    getAllLocations: () => dispatch(getAllLocationActions()),
    getEmployeeCompetenciesAction: () => dispatch(getEmployeeCompetenciesAction()),
    getEmployeeDivisionsAction: () => dispatch(getEmployeeDivisionsAction()),
    getEmployeeBusinessUnitAction: () => dispatch(getEmployeeBusinessUnitAction()),
    updateEmployeeSkillsActions: (employees, isDelete) =>
      dispatch(updateEmployeeSkillsActions(employees, isDelete)),
  };
}
const EmployeesContainer = connect(mapStateToProps, mapDispatchToProps)(Employees);

export default EmployeesContainer;
