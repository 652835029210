import { connect } from 'react-redux';

import LogWorkList from 'components/LogWorkList';

import { getHistoryAction } from 'store/actions/HistoryActions';
import { getProjectsAction, getWorkLogProjectsAction } from 'store/actions/ProjectActions';

import { updateWorkLogAction, clearWorklogStatus } from 'store/actions/WorkLogActions';

function mapStateToProps(state) {
  const { auth, historyReducer, projectReducer } = state;
  return {
    userId: auth.user && auth.user.id,
    isLoading: historyReducer.isLoading,
    logs: historyReducer.logs,
    projects: projectReducer.projects,
    userArea: auth.user && auth.user.area,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getWorkLogProjects: () => dispatch(getWorkLogProjectsAction()),
    getProjects: () => dispatch(getProjectsAction()),
    getHistory: (employeeId) => dispatch(getHistoryAction(employeeId)),
    updateEmployeeWorkLog: (req, employeeId, workId, updateType = 'default') =>
      dispatch(updateWorkLogAction(req, employeeId, workId, updateType)),
    clearWorklogStatus: () => dispatch(clearWorklogStatus()),
  };
}

const LogWorkListContainer = connect(mapStateToProps, mapDispatchToProps)(LogWorkList);

export default LogWorkListContainer;
