import UPDATE_BREADCRUMB from 'constants/BreadCrumbTypes';

const updateBreadCrumb = (key, oldVal, newVal) => ({
  type: UPDATE_BREADCRUMB,
  key,
  oldVal,
  newVal,
});

export default updateBreadCrumb;
