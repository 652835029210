import {
  EnhancedCol,
  EnhancedRow,
  EnhancedCard,
  EnhancedButton,
  EnhancedSelect,
  EnhancedDatePicker,
  EnhancedRadio,
} from 'components/shared/antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { NOT_AVAILABLE } from 'configs/additionalConstants';
import XLSX from 'xlsx';
import moment from 'moment';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import _ from 'underscore';
import { dateFormat, employeeType } from '../../configs/constants';
import './resources.css';

import convertMonthRangeToDateRange from '../../utils/DateUtils';
import { FUTURE_MONTH_MESSAGE } from '../../configs/additionalConstants';

const { Option } = EnhancedSelect;

const FilterDropdown = (props) => {
  const { list, onChange, selectedValue, stateKey, valueKey } = props;

  const options = list.map((item) => <Option value={item[valueKey]}>{item.name}</Option>);

  return (
    <EnhancedSelect
      showSearch
      mode="multiple"
      value={selectedValue}
      onChange={(value) => onChange(stateKey, value)}
      optionFilterProp="children"
    >
      {options}
    </EnhancedSelect>
  );
};

class Resources extends Component {
  constructor(props) {
    super(props);
    const startDate = null;
    const endDate = null;
    const sortedInfo = {
      columnKey: 'projectName',
      order: 'asc',
    };
    this.emptyFilter = {
      manager: [],
      department: [],
      competency: [],
      businessUnit: [],
      division: [],
      resourceType: [],
    };
    this.state = {
      sortedInfo,
      resources: [],
      filteredInfo: {},
      projects: [],
      startDate,
      endDate,
      managers: [],
      resourceType: [employeeType.BILLABLE, employeeType.NONBILLABLE].map((item, index) => ({
        id: index,
        name: item,
      })),
      isProjectWise: true,
      gridViewType: 1,
      isMonthInFuture: false,
      filters: {
        ...this.emptyFilter,
      },
    };
  }

  componentDidMount() {
    this.loadDefaultData();
    this.props.getEmployeeDivisionsAction();
    this.props.getEmployeeCompetenciesAction();
    this.props.getEmployeeBusinessUnitAction();
    this.props.getEmployeeDepartmentsAction();
  }

  componentWillReceiveProps(nextProps) {
    const { resources } = this.props.resources !== nextProps.resources ? nextProps : this.props;
    const { projects } = this.props.projects !== nextProps.projects ? nextProps : this.props;

    let managers = [];
    if (this.state.managers.length === 0 && resources.length) {
      resources.forEach((resource) => {
        managers.push({
          id: resource.managerId,
          name: resource.managerName,
        });
      });
      managers = _.uniq(managers, false, 'id');
    }

    this.setState({
      resources,
      projects,
      managers,
    });
  }

  componentWillUnmount() {
    this.setState({
      projects: [],
      resources: [],
    });
    this.props.clearResourceData();
  }

  onFiltersChange = (stateKey, value) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [stateKey]: value,
      },
    });
  };

  getMappedResourceData = (data) => {
    const [keys, headers] = [[], []];
    this.resourceReportMeta.forEach((meta) => {
      keys.push(meta.key);
      headers.push(meta.label);
    });
    const rows = data.map((entry) =>
      keys.map((key) => {
        if (!this.allocationKeys.includes(key)) return entry[key];
        return parseFloat(entry[key]);
      }),
    );
    return [headers, ...rows];
  };

  getMappedProjectData = (data) => {
    const [keys, headers] = [[], []];
    this.projectReportMeta.forEach((meta) => {
      keys.push(meta.key);
      headers.push(meta.label);
    });
    const rows = data.map((entry) =>
      keys.map((key) => {
        if (!this.allocationKeys.includes(key)) return entry[key];
        return parseFloat(entry[key]);
      }),
    );
    return [headers, ...rows];
  };

  getMappedResourceProjectsData = (resources) => {
    if (this.state.isProjectWise) return [];
    const filteredAllocations = resources.filter((r) => !_.isEmpty(r.projects));
    const filteredMeta = _.reject(this.resourceReportMeta, (meta) => meta.key === 'bench');
    const rows = [];
    filteredAllocations.forEach((allocation) => {
      rows.push(
        ...allocation.projects
          .filter((project) => project.totalAllocation !== '0')
          .map((project) =>
            filteredMeta.map((meta) => {
              if (!this.allocationKeys.includes(meta.key)) return project[meta.key];
              return parseFloat(project[meta.key]);
            }),
          ),
      );
    });
    const headers = filteredMeta.map((meta) => meta.label);
    return [headers, ...rows];
  };

  getEmployeesColumnsInfo = () => {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'Taleo ID',
        dataIndex: 'taleoId',
        key: 'taleoId',
        width: 150,
        filterConfig: {
          type: 'search',
          key: 'taleoId',
        },
        sorter: (a, b) => a.taleoId.localeCompare(b.taleoId),
        sortOrder: sortedInfo.columnKey === 'taleoId' && sortedInfo.order,
      },
      {
        title: 'Name',
        dataIndex: 'employeeName',
        key: 'employeeName',
        filterConfig: {
          type: 'search',
          key: 'employeeName',
        },
        sorter: (a, b) => a.employeeName.localeCompare(b.employeeName),
        sortOrder: sortedInfo.columnKey === 'employeeName' && sortedInfo.order,
        render: this.renderEmployeeName,
      },
      {
        title: 'Type',
        dataIndex: 'resourceType',
        key: 'resourceType',
        render: this.renderEmployeeResourceType,
      },
      {
        title: 'Designation',
        dataIndex: 'designation',
        key: 'designation',
        width: 150,
        sorter: (a, b) => a.designation.localeCompare(b.designation),
        sortOrder: sortedInfo.columnKey === 'designation' && sortedInfo.order,
      },
      {
        title: 'Business Unit',
        dataIndex: 'businessUnit',
        key: 'businessUnit',
      },
      {
        title: 'Division',
        dataIndex: 'division',
        key: 'division',
      },
      {
        title: 'Competency',
        dataIndex: 'competency',
        key: 'competency',
      },
      {
        title: 'Department',
        dataIndex: 'department',
        key: 'department',
      },
      {
        title: 'Projects',
        dataIndex: 'projectName',
        key: 'projectName',
        // width: '8%',
        sorter: (a, b) => a.projectName.localeCompare(b.projectName),
        sortOrder: sortedInfo.columnKey === 'projectName' && sortedInfo.order,
      },
      {
        title: 'Billed',
        dataIndex: 'billed',
        key: 'billed',
        // width: '4%',
        sorter: (a, b) => parseFloat(a.billed) - parseFloat(b.billed),
        sortOrder: sortedInfo.columnKey === 'billed' && sortedInfo.order,
      },
      {
        title: 'Backup',
        dataIndex: 'backup',
        key: 'backup',
        // width: '4%',
        sorter: (a, b) => parseFloat(a.backup) - parseFloat(b.backup),
        sortOrder: sortedInfo.columnKey === 'backup' && sortedInfo.order,
      },
      {
        title: 'Internal',
        dataIndex: 'internal',
        key: 'internal',
        // width: '4%',
        sorter: (a, b) => parseFloat(a.internal) - parseFloat(b.internal),
        sortOrder: sortedInfo.columnKey === 'internal' && sortedInfo.order,
      },
      {
        title: 'Total Utilization',
        dataIndex: 'totalAllocation',
        key: 'totalAllocation',
        // width: '4%',
        sorter: (a, b) => parseFloat(a.totalAllocation) - parseFloat(b.totalAllocation),
        sortOrder: sortedInfo.columnKey === 'totalAllocation' && sortedInfo.order,
      },
      {
        title: 'Bench',
        dataIndex: 'bench',
        key: 'bench',
        // width: '4%',
        sorter: (a, b) => parseFloat(a.bench) - parseFloat(b.bench),
        sortOrder: sortedInfo.columnKey === 'bench' && sortedInfo.order,
      },
    ];
    return columns;
  };

  getProjectColumnsInfo = () => {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'Name',
        dataIndex: 'projectName',
        key: 'projectName',
        width: '15%',
        filterConfig: {
          type: 'search',
          key: 'projectName',
        },
        sorter: (a, b) => a.projectName.localeCompare(b.projectName),
        sortOrder: sortedInfo.columnKey === 'projectName' && sortedInfo.order,
        render: this.renderProjectName,
      },
      {
        title: 'Business Unit',
        dataIndex: 'businessUnit',
        key: 'businessUnit',
        width: '15%',
        filterConfig: {
          type: 'enhanced_filter',
          key: 'businessUnit',
          optionsConfig: {
            values: this.props.businessUnits,
          },
        },
      },
      {
        title: 'Billed',
        dataIndex: 'billed',
        key: 'billed',
        width: '8%',
        sorter: (a, b) => parseFloat(a.billed) - parseFloat(b.billed),
        sortOrder: sortedInfo.columnKey === 'billed' && sortedInfo.order,
      },
      {
        title: 'Backup',
        dataIndex: 'backup',
        key: 'backup',
        width: '8%',
        sorter: (a, b) => parseFloat(a.backup) - parseFloat(b.backup),
        sortOrder: sortedInfo.columnKey === 'backup' && sortedInfo.order,
      },
      {
        title: 'Internal',
        dataIndex: 'internal',
        key: 'internal',
        width: '8%',
        sorter: (a, b) => parseFloat(a.internal) - parseFloat(b.internal),
        sortOrder: sortedInfo.columnKey === 'internal' && sortedInfo.order,
      },
      {
        title: 'Total',
        dataIndex: 'totalAllocation',
        key: 'totalAllocation',
        width: '8%',
        sorter: (a, b) => parseFloat(a.totalAllocation) - parseFloat(b.totalAllocation),
        sortOrder: sortedInfo.columnKey === 'totalAllocation' && sortedInfo.order,
      },
      {
        title: 'Additional',
        dataIndex: 'additional',
        key: 'additional',
        width: '8%',
        sorter: (a, b) => parseFloat(a.additional) - parseFloat(b.additional),
        sortOrder: sortedInfo.columnKey === 'additional' && sortedInfo.order,
      },
    ];
    return columns;
  };

  getAggregatedInformation = (resources) => {
    const aggregatedInfo = {
      totalResources: resources.length,
      totalBench: 0,
      totalBilled: 0,
      totalInternal: 0,
      totalBackup: 0,
    };

    resources.forEach((resource) => {
      aggregatedInfo.totalBench += parseFloat(resource.bench);
      aggregatedInfo.totalBilled += parseFloat(resource.billed);
      aggregatedInfo.totalInternal += parseFloat(resource.internal);
      aggregatedInfo.totalBackup += parseFloat(resource.backup);
    });

    aggregatedInfo.totalBench = Math.round(aggregatedInfo.totalBench * 100) / 100;
    aggregatedInfo.totalBilled = Math.round(aggregatedInfo.totalBilled * 100) / 100;
    aggregatedInfo.totalInternal = Math.round(aggregatedInfo.totalInternal * 100) / 100;
    aggregatedInfo.totalBackup = Math.round(aggregatedInfo.totalBackup * 100) / 100;

    return aggregatedInfo;
  };

  getFilters = () => {
    const { filters } = this.state;
    const filtersConfig = [
      {
        list: this.state.managers,
        onChange: this.onFiltersChange,
        selectedValue: filters.manager,
        stateKey: 'manager',
        title: 'Manager',
        valueKey: 'id',
      },
      {
        list: this.props.businessUnits,
        onChange: this.onFiltersChange,
        selectedValue: filters.businessUnit,
        stateKey: 'businessUnit',
        title: 'Business Unit',
        valueKey: 'name',
      },
      {
        list: this.props.divisions,
        onChange: this.onFiltersChange,
        selectedValue: filters.division,
        stateKey: 'division',
        title: 'Division',
        valueKey: 'name',
      },
      {
        list: this.props.competencies,
        onChange: this.onFiltersChange,
        selectedValue: filters.competency,
        stateKey: 'competency',
        title: 'Competency',
        valueKey: 'name',
      },
      {
        list: this.props.departments,
        onChange: this.onFiltersChange,
        selectedValue: filters.department,
        stateKey: 'department',
        title: 'Department',
        valueKey: 'name',
      },
      {
        list: this.state.resourceType,
        onChange: this.onFiltersChange,
        selectedValue: filters.resourceType,
        stateKey: 'resourceType',
        title: 'Type',
        valueKey: 'name',
      },
    ];

    const numberOfRows = Math.ceil(filtersConfig.length / 2);
    const filterControls = [];

    for (let index = 0; index < numberOfRows; index += 1) {
      const filtersPerRow = filtersConfig.splice(0, 2);

      if (filtersPerRow.length) {
        filterControls.push(
          <EnhancedRow>
            {filtersPerRow.map((item) => {
              return (
                <EnhancedCol span={12} style={{ marginBottom: 20 }}>
                  <EnhancedRow>
                    <EnhancedCol span={5} style={{ paddingTop: '4px' }}>
                      <div>{item.title}:</div>
                    </EnhancedCol>
                    <EnhancedCol span={15}>
                      <FilterDropdown
                        list={item.list}
                        onChange={item.onChange}
                        selectedValue={item.selectedValue}
                        stateKey={item.stateKey}
                        valueKey={item.valueKey}
                      />
                    </EnhancedCol>
                  </EnhancedRow>
                </EnhancedCol>
              );
            })}
          </EnhancedRow>,
        );
      }
    }
    // const filterControls = filtersConfig.map((item) => {
    //   return (
    //     <React.Fragment>
    //       <EnhancedRow>
    //         <EnhancedCol span={12} style={{ marginBottom: 20 }}>
    //           <EnhancedCol span={5} style={{ paddingTop: '4px' }}>
    //             {item.title}:
    //           </EnhancedCol>
    //           <EnhancedCol span={7}>
    //             <FilterDropdown
    //               list={item.list}
    //               onChange={item.onChange}
    //               selectedValue={item.selectedValue}
    //               stateKey={item.stateKey}
    //               valueKey={item.valueKey}
    //             />
    //           </EnhancedCol>
    //         </EnhancedCol>
    //       </EnhancedRow>
    //     </React.Fragment>
    //   );
    // });
    return filterControls;
  };

  getFilteredResources = () => {
    let { resources } = this.state;

    const { filters, filteredInfo } = this.state;

    if (filteredInfo.projectName && filteredInfo.projectName.length) {
      resources = resources.filter((record) =>
        filteredInfo.projectName.includes(record.projectName),
      );
    }

    // let filterCondition = true;

    Object.keys(filters).forEach((key) => {
      const filterValue = filters[key];

      if (filterValue && filterValue.length > 0) {
        if (key === 'manager') {
          resources = this.filterResourcesByManagerId(resources, filterValue);
        } else {
          resources = resources.filter((record) => filterValue.includes(record[key]));
        }
      }
    });

    return resources;
  };

  /**
   * Util method for mapping, generating and downloading excel workbook containing
   * employee allocations. Creates two sheets, i) Summary ii) Project-wise.
   * @param {Array} data Array containing resource utilization details
   */
  generateResourceUtilizationReport = (data) => {
    const summarySheet = XLSX.utils.aoa_to_sheet(this.getMappedResourceData(data));
    const projectWiseSheet = XLSX.utils.aoa_to_sheet(this.getMappedResourceProjectsData(data));
    const book = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, summarySheet, 'Summary');
    XLSX.utils.book_append_sheet(book, projectWiseSheet, 'Details - Project Wise');
    const { startDate } = this.state;
    const startOfMonth = startDate.startOf('month').format(dateFormat.abbreviatedMonthAndYear);
    XLSX.writeFile(book, `Resource Utilization ${startOfMonth}.xlsx`);
  };

  allocationKeys = ['billed', 'backup', 'internal', 'totalAllocation', 'bench'];

  resourceReportMeta = [
    {
      key: 'taleoId',
      label: 'Taleo Id',
    },
    {
      key: 'employeeName',
      label: 'Name',
    },
    {
      key: 'resourceType',
      label: 'Type',
    },
    {
      key: 'designation',
      label: 'Designation',
    },
    {
      key: 'managerName',
      label: 'Line Manager',
    },
    {
      key: 'businessUnit',
      label: 'Business Unit',
    },
    {
      key: 'division',
      label: 'Division',
    },
    {
      key: 'competency',
      label: 'Competency',
    },
    {
      key: 'department',
      label: 'Department',
    },
    {
      key: 'projectName',
      label: 'Projects',
    },
    {
      key: 'billed',
      label: 'Billed',
    },
    {
      key: 'backup',
      label: 'Backup',
    },
    {
      key: 'internal',
      label: 'Internal',
    },
    {
      key: 'totalAllocation',
      label: 'Total Utilization',
    },
    {
      key: 'bench',
      label: 'Bench',
    },
  ];

  projectReportMeta = [
    {
      key: 'projectName',
      label: 'Project',
    },
    {
      key: 'businessUnit',
      label: 'BUsiness Unit',
    },
    {
      key: 'billed',
      label: 'Billed',
    },
    {
      key: 'backup',
      label: 'Backup',
    },
    {
      key: 'internal',
      label: 'Internal',
    },
    {
      key: 'totalAllocation',
      label: 'Total Utilization',
    },
    {
      key: 'additional',
      label: 'Additional',
    },
  ];

  loadDefaultData = () => {
    const previousMonth = moment().subtract(1, 'months');
    this.dateRangeChange(previousMonth);
  };

  dateRangeChange = (date) => {
    const startDate = date;
    const endDate = date;
    const currentDate = moment().format(dateFormat.abbreviatedMonthAndYear);
    const selectedMonth = endDate.format(dateFormat.abbreviatedMonthAndYear);
    const isFuture = moment(selectedMonth).isAfter(currentDate);
    this.setState({
      startDate,
      endDate,
      resources: [],
      projects: [],
      isMonthInFuture: isFuture,
    });
    if (this.props.userId !== undefined && date != null) {
      const dateRange = convertMonthRangeToDateRange(startDate, endDate);
      this.setState({
        dateRange,
      });
      if (this.state.isProjectWise) {
        this.props.getRcaProjectsActions(dateRange.startDate, dateRange.endDate);
      } else {
        this.props.getResourcesActions(dateRange.startDate, dateRange.endDate);
      }
    }
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  filterResourcesByManagerId = (resources, managerId) => {
    const reportees = {};
    const idsToProcess = [...managerId];
    while (idsToProcess.length > 0) {
      const id = idsToProcess.shift();
      resources.forEach((resource) => {
        if (
          resource.employeeId !== id &&
          resource.managerId === id &&
          !reportees[resource.employeeId]
        ) {
          reportees[resource.employeeId] = resource;
          if (!idsToProcess.includes(resource.employeeId)) {
            idsToProcess.push(resource.employeeId);
          }
        }
      });
    }
    return Object.values(reportees);
  };

  mapResourcesTableData = (resources) =>
    resources.map((resource) => {
      const { projects } = resource;
      if (projects && projects.length <= 1) return { ...resource };
      const children = projects
        .filter((project) => project.totalAllocation !== '0')
        .map((project) => ({
          key: `${resource.employeeId}#${project.projectId}`,
          taleoId: '',
          employeeName: '',
          projectName: project.projectName,
          billed: project.billed,
          backup: project.backup,
          internal: project.internal,
          totalAllocation: project.totalAllocation,
          bench: '',
        }));
      if (children.length <= 1) return { ...resource };
      return {
        ...resource,
        children,
      };
    });

  gridViewTypeChange = (event) => {
    if (event.target.value === 1) {
      // By Project
      this.setState(
        {
          gridViewType: 1,
          isProjectWise: true,
          resources: [],
          projects: [],
          startDate: null,
          filters: {
            ...this.emptyFilter,
          },
        },
        this.loadDefaultData,
      );
    } else {
      this.setState(
        {
          // By Employee
          gridViewType: 2,
          isProjectWise: false,
          resources: [],
          projects: [],
          startDate: null,
          filters: {
            ...this.emptyFilter,
          },
        },
        this.loadDefaultData,
      );
    }
  };

  /**
   * Util method for mapping, generating and downloading excel workbook containing
   * project allocations.
   * @param {Array} data Array containing project utilization details
   */
  generateProjectUtilizationReport = (data) => {
    const sheet = XLSX.utils.aoa_to_sheet(this.getMappedProjectData(data));
    const book = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, sheet, 'Summary');
    const { startDate } = this.state;
    const startOfMonth = startDate.startOf('month').format(dateFormat.abbreviatedMonthAndYear);
    XLSX.writeFile(book, `Project Utilization ${startOfMonth}.xlsx`);
  };

  renderAggregatedInformation = (aggregatedInfo) => {
    const response = (
      <EnhancedCol span={8} className="text-align-right">
        <EnhancedRow>
          <EnhancedCol span={24}>
            <h3 className="heading-rca">Employees Count: {aggregatedInfo.totalResources || 0}</h3>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow>
          <EnhancedCol span={24}>
            <h3 className="heading-rca">Billed: {aggregatedInfo.totalBilled || 0}</h3>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow>
          <EnhancedCol span={24}>
            <h3 className="heading-rca">Backup: {aggregatedInfo.totalBackup || 0}</h3>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow>
          <EnhancedCol span={24}>
            <h3 className="heading-rca">Internal: {aggregatedInfo.totalInternal || 0}</h3>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow>
          <EnhancedCol span={24}>
            <h3 className="heading-rca">Bench: {aggregatedInfo.totalBench || 0}</h3>
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedCol>
    );

    return response;
  };

  renderEmployeeName = (name, data) => {
    if (data.projectName === '') {
      return <b style={{ color: '#5A5A5A' }}>{name}</b>;
    }
    return (
      <Link
        to={`/rca/billing-employees/${data.employeeId}?startDate=${moment(
          this.state.startDate,
        ).format('YYYY-MM')}&endDate=${moment(this.state.endDate).format('YYYY-MM')}`}
      >
        <b style={{ color: '#5A5A5A' }}>{name}</b>
      </Link>
    );
  };
  renderEmployeeResourceType = (name, data) => (
    <span>{data.resourceType ? data.resourceType : NOT_AVAILABLE}</span>
  );

  renderProjectName = (name, data) => (
    <Link
      to={`/rca/billing-projects/${data.projectId}?startDate=${moment(this.state.startDate).format(
        'YYYY-MM',
      )}&endDate=${moment(this.state.endDate).format('YYYY-MM')}`}
    >
      <b style={{ color: '#5A5A5A' }}>{name}</b>
    </Link>
  );

  render() {
    const RadioGroup = EnhancedRadio.Group;
    let { resources, projects } = this.state;
    const { isProjectWise, startDate } = this.state;
    const startOfMonth =
      startDate && startDate.startOf('month').format(dateFormat.abbreviatedMonthAndYear);

    const { isLoading } = this.props;
    projects = projects || [];
    resources = resources || [];
    const columns = this.state.isProjectWise
      ? this.getProjectColumnsInfo()
      : this.getEmployeesColumnsInfo();

    let filterOptions;
    let headCount;
    if (this.state.isProjectWise) {
      headCount = (
        <EnhancedCol span={24} className="text-align-right">
          <h2 className="heading-emp">Total Projects Count: {projects.length || 0}</h2>
        </EnhancedCol>
      );
      filterOptions = '';
    } else {
      filterOptions = (
        <EnhancedCol span={16}>
          <h2 className="heading-rca">Filters:</h2>
          {this.getFilters()}
        </EnhancedCol>
      );

      resources = this.getFilteredResources();

      const aggregatedInformation = this.getAggregatedInformation(resources);

      headCount = this.renderAggregatedInformation(aggregatedInformation);
    }

    const gridData = this.state.isProjectWise ? projects : this.mapResourcesTableData(resources);
    const emptyGridMessage = <p style={{ textAlign: 'center' }}>Select Month to view the data</p>;
    let gridDataTable = emptyGridMessage;
    if (
      (this.state.projects && this.state.projects.length) ||
      this.state.resources.length ||
      isLoading
    ) {
      gridDataTable = (
        <div>
          <p>
            {' '}
            Data shown from
            <b style={{ fontWeight: 500 }}>
              {` ${moment(this.state.dateRange.startDate).format('DD MMM YYYY')}`} -
              {moment(this.state.dateRange.endDate).format('DD MMM YYYY')}
            </b>
          </p>
          <EnhancedClientSideTable
            loading={isLoading}
            columns={columns}
            data={gridData}
            onChange={this.handleChange}
            scroll={{ x: 2000, y: false }}
            exportFileConfig={{
              fileName: isProjectWise
                ? `Project Utilization ${startOfMonth}`
                : `Resource Utilization ${startOfMonth}`,
              fileHeaders: isProjectWise ? this.projectReportMeta : this.resourceReportMeta,
              showExportButton: false,
            }}
          />
        </div>
      );
    }
    const renderMessageProjectWise = () => {
      if (this.state.isMonthInFuture) return FUTURE_MONTH_MESSAGE;
      return <React.Fragment />;
    };
    return (
      <div>
        <EnhancedCard>
          {/* <EnhancedRow style={{ marginBottom: '10px' }}> */}
          <EnhancedRow style={{ marginBottom: '10px' }}>
            <h2 className="heading-emp">Resource Utilization Report</h2>
            <EnhancedCol span={24}>
              <RadioGroup onChange={this.gridViewTypeChange} value={this.state.gridViewType}>
                <EnhancedRadio.Button value={1}>By Project</EnhancedRadio.Button>
                <EnhancedRadio.Button value={2}>By Employee</EnhancedRadio.Button>
              </RadioGroup>
            </EnhancedCol>
          </EnhancedRow>
          <EnhancedRow style={{ paddingTop: 10 }}>
            {filterOptions}
            {headCount}
          </EnhancedRow>

          <EnhancedRow>
            <EnhancedCol span={18}>
              <React.Fragment>
                {this.state.isProjectWise ? renderMessageProjectWise() : <React.Fragment />}
              </React.Fragment>
            </EnhancedCol>
            <EnhancedCol span={6} style={{ textAlign: 'right' }}>
              <EnhancedDatePicker
                value={this.state.startDate}
                picker="month"
                onChange={this.dateRangeChange}
                format="MMM YYYY"
                placeholder="Select month"
                allowClear={false}
              />
            </EnhancedCol>
          </EnhancedRow>
          {!this.state.isProjectWise ? (
            <EnhancedRow>
              <EnhancedCol span={14} style={{ marginTop: 10 }}>
                {renderMessageProjectWise()}
              </EnhancedCol>
            </EnhancedRow>
          ) : (
            <React.Fragment />
          )}
          {/* </EnhancedRow> */}
        </EnhancedCard>
        <EnhancedCard>
          <EnhancedCol span={24} style={{ textAlign: 'right' }}>
            {gridData.length && this.state.isProjectWise ? (
              <EnhancedButton
                icon="file-excel"
                style={{ marginButtom: '15px' }}
                onClick={() => this.generateProjectUtilizationReport(gridData)}
              >
                Export
              </EnhancedButton>
            ) : (
              ''
            )}
            {gridData.length && !this.state.isProjectWise ? (
              <EnhancedButton
                icon="file-excel"
                style={{ marginButtom: '15px' }}
                onClick={() => this.generateResourceUtilizationReport(gridData)}
              >
                Export
              </EnhancedButton>
            ) : (
              ''
            )}
          </EnhancedCol>
          <EnhancedCol span={24}>{gridDataTable}</EnhancedCol>
        </EnhancedCard>
      </div>
    );
  }
}

export default Resources;
