import axios from 'axios';
import {
  createWorkLogInit,
  createWorkLogSuccess,
  createWorkLogFailure,
} from 'store/actions/WorkLogActions';
import { workLogUrls } from 'configs/constants';
import { normalizeCreateWorkLogResponse } from 'normalizers/workLogNormalizers';
import { gaEvent } from 'sources/AnalyticsSource';
import { categories, events } from 'configs/gaConstants';
import { emotionUpdated } from 'configs/additionalConstants';

export const createEmployeeWorkLog = (req, employeeId) => {
  const url = workLogUrls.CREATE.replace('{employeeId}', employeeId);
  return (dispatch) => {
    gaEvent(categories.LOGWORK, events.ADD_LOG);
    dispatch(createWorkLogInit());
    axios
      .post(url, req.worklogs[0])
      .then((response) => {
        if (response.status === 200) {
          // parse response through normalizer
          const normalizedResponse = normalizeCreateWorkLogResponse(response.data.data);
          // call success or failure action
          dispatch(
            createWorkLogSuccess({
              message: 'Updated Successfully',
              data: normalizedResponse,
            }),
          );
        } else {
          dispatch(
            createWorkLogFailure({
              message: 'Error while updating records',
            }),
          );
        }
      })
      .catch((error) => {
        // API Call errors
        dispatch(createWorkLogFailure({ message: error }));
      });
  };
};

export const updateEmployeeWorkLog = (req, employeeId, workId, updateType) => {
  let url = workLogUrls.UPDATE.replace('{employeeId}', employeeId);
  url = url.replace('{workId}', workId);
  return (dispatch) => {
    if (updateType === emotionUpdated) {
      gaEvent(categories.LOGWORK, events.ADD_MOOD);
    } else {
      gaEvent(categories.LOGWORK, events.EDIT_LOG);
    }
    dispatch(createWorkLogInit());
    axios
      .put(url, req)
      .then((response) => {
        if (response.status === 200) {
          // parse response through normalizer
          const normalizedResponse = normalizeCreateWorkLogResponse(response.data.data);
          // call success or failure action
          dispatch(
            createWorkLogSuccess({
              message: 'Updated Successfully',
              data: normalizedResponse,
            }),
          );
        } else {
          dispatch(createWorkLogFailure({ message: 'Error while updating records' }));
        }
      })
      .catch((error) => {
        // API Call errors
        dispatch(createWorkLogFailure({ message: error }));
      });
  };
};
