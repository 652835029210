import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import Cookies from 'js-cookie';
import { ENKEYRIPT, environments } from 'configs/constants';

const shouldNotEncrypt = process.env.NODE_ENV !== environments.PRODUCTION;

const hasPermissionsArray = (state) =>
  state &&
  state.auth &&
  state.auth.user &&
  state.auth.user.permissions &&
  Array.isArray(state.auth.user.permissions);
export const setCookie = (tokenId) => {
  Cookies.set('tokenid', tokenId, { domain: 'vendians.com' });
};

export const getCookie = Cookies.get('tokenid');

export const removeCookie = () => {
  Cookies.remove('tokenid');
};
export const getJWTtoken = () => {
  const bitsObject = JSON.parse(localStorage.getItem('bits'));
  const {
    auth: {
      user: { jwt },
    },
  } = bitsObject;
  return jwt;
};
export const getTokenIdFromCookie = () => {
  const tokenId = getCookie;
  return tokenId;
};

export const loadState = () => {
  const serializedState = localStorage.getItem('bits');
  if (serializedState === null) {
    return undefined;
  }
  let parsedState;
  if (shouldNotEncrypt) {
    parsedState = JSON.parse(serializedState);
    return hasPermissionsArray(parsedState) ? parsedState : undefined;
  }
  const bytes = AES.decrypt(serializedState.toString(), ENKEYRIPT);
  parsedState = JSON.parse(bytes.toString(Utf8));
  return hasPermissionsArray(parsedState) ? parsedState : undefined;
};

export const saveState = (state) => {
  if (shouldNotEncrypt) {
    localStorage.setItem('bits', JSON.stringify(state));
    return;
  }
  const serializedState = AES.encrypt(JSON.stringify(state), ENKEYRIPT);
  localStorage.setItem('bits', serializedState);
};
