export const ADD_GOAL_INIT = 'ADD_GOAL_INIT';
export const ADD_GOAL_SUCCESS = 'ADD_GOAL_SUCCESS';
export const ADD_GOAL_FAILURE = 'ADD_GOAL_FAILURE';

export const GET_GOAL_INIT = 'GET_GOAL_INIT';
export const GET_GOAL_SUCCESS = 'GET_GOAL_SUCCESS';
export const GET_GOAL_FAILURE = 'GET_GOAL_FAILURE';

export const DELETE_GOAL_INIT = 'DELETE_GOAL_INIT';
export const DELETE_GOAL_SUCCESS = 'DELETE_GOAL_SUCCESS';
export const DELETE_GOAL_FAILURE = 'DELETE_GOAL_FAILURE';

export const GET_GOAL_DETAILS_INIT = 'GET_GOAL_DETAILS_INIT';
export const GET_GOAL_DETAILS_SUCCESS = 'GET_GOAL_DETAILS_SUCCESS';
export const GET_GOAL_DETAILS_FAILURE = 'GET_GOAL_DETAILS_FAILURE';

export const GET_GOALS_REPORT_INIT = 'GET_GOALS_REPORT_INIT';
export const GET_GOALS_REPORT_SUCCESS = 'GET_GOALS_REPORT_SUCCESS';
export const GET_GOALS_REPORT_FAILURE = 'GET_GOALS_REPORT_FAILURE';

export const UPDATE_GOAL_INIT = 'UPDATE_GOAL_INIT';
export const UPDATE_GOAL_SUCCESS = 'UPDATE_GOAL_SUCCESS';
export const UPDATE_GOAL_FAILURE = 'UPDATE_GOAL_FAILURE';

export const SUBMIT_MY_GOAL_FOR_APPROVAL_INIT = 'SUBMIT_MY_GOAL_FOR_APPROVAL_INIT';
export const SUBMIT_MY_GOAL_FOR_APPROVAL_SUCCESS = 'SUBMIT_MY_GOAL_FOR_APPROVAL_SUCCESS';
export const SUBMIT_MY_GOAL_FOR_APPROVAL_FAILURE = 'SUBMIT_MY_GOAL_FOR_APPROVAL_FAILURE';
