import { EnhancedIcon, EnhancedPopover } from 'components/shared/antd';
import PropTypes from 'prop-types';
import { getMatchedRatingOption } from 'utils/performanceManagementUtils';
import React, { useEffect, useState } from 'react';

const RatingText = (props) => {
  const { rating, ratingOptions } = props;
  const [matchedRatingOption, setMatchedRatingOption] = useState({});

  useEffect(() => {
    setMatchedRatingOption(getMatchedRatingOption(rating, ratingOptions));
  }, [rating]);

  const getPopoverContent = (description) => <pre>{description}</pre>;

  return (
    <React.Fragment>
      {matchedRatingOption && (
        <React.Fragment>
          <span className="mr-10">{matchedRatingOption.name}</span>
          <EnhancedPopover
            content={getPopoverContent(matchedRatingOption.description)}
            title={matchedRatingOption.name}
          >
            <EnhancedIcon type="info-circle" className="color-grey" />
          </EnhancedPopover>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

RatingText.propTypes = {
  rating: PropTypes.number.isRequired,
  ratingOptions: PropTypes.arrayOf.isRequired,
};

export default RatingText;
