import moment from 'moment';

function normalizeFreezeAttribute(freeze) {
  // freeze attribute is returned as buffer or null from backend
  return freeze !== null;
}

export function normalizePerformanceCycles(res) {
  return res.map((data) => {
    const performanceCycles = data || {};
    return {
      id: performanceCycles.id,
      name: performanceCycles.cycleName,
      pmReviewed: performanceCycles.pmReviewed,
      archReviewed: performanceCycles.archReviewed,
      projectCount: performanceCycles.projectCount,
      status: performanceCycles.isActive ? 'Actived' : 'Complete',
      criteria: performanceCycles.criteria || 0,
      createdAt: performanceCycles.createdAt
        ? moment(performanceCycles.createdAt).format('DD-MMM-YYYY')
        : null,
      endDate: performanceCycles.endDate
        ? moment(performanceCycles.endDate).format('DD-MMM-YYYY')
        : null,
      dueDate: performanceCycles.dueDate
        ? moment(performanceCycles.dueDate).format('DD-MMM-YYYY')
        : null,
    };
  });
}
export function normalizeProject(res) {
  const resultData = {};
  resultData.cycleInfo = res.cycleInfo;
  resultData.projectCycles = res.projectCycles
    .map((data) => {
      const projectsCycle = data || {};
      // VENDIANS-2014: Reverse the audit array to show in descending order
      const logs = projectsCycle.auditLogs;
      const sortedLogs = Array.isArray(logs) ? logs.reverse() : logs;
      return {
        id: projectsCycle.id,
        performanceCycleId: projectsCycle.performanceCycleId,
        name: projectsCycle.name,
        projectManager: projectsCycle.projectManager || ' - ',
        projectArchitect: projectsCycle.projectArchitect || ' - ',
        startDate: projectsCycle.startDate,
        endDate: projectsCycle.endDate,
        projectManagerId: projectsCycle.projectManagerId,
        architectId: projectsCycle.architectId,
        status: projectsCycle.status,
        pmRatings: projectsCycle.pmRatings,
        archRatings: projectsCycle.archRatings,
        resourceRatings: projectsCycle.resourceRatings,
        projectCycleId: projectsCycle.projectCycleId,
        totalRatings: projectsCycle.totalRatings,
        hasArchitect: projectsCycle.hasArchitect,
        dueDate: projectsCycle.dueDate,
        allowedAfterDueDateBy: projectsCycle.allowedAfterDueDateBy,
        allowedAfterDueDateAt: projectsCycle.allowedAfterDueDateAt,
        auditLogs: sortedLogs,
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
  return resultData;
}
export function normalizeProjectRating(res) {
  const resultData = {};
  resultData.projectName = res.projectName;
  resultData.cycleName = res.cycleName;
  resultData.completeStatus = res.completeStatus;
  resultData.dueDate = res.dueDate;
  resultData.initiationDate = res.initiationDate;
  const projectRatings = res.ratings
    .map((data) => {
      const ProjectRating = data || {};
      // if (ProjectRating.employee.department) {
      //   ProjectRating.department = ProjectRating.employee.department.name;
      // }
      const rating = {
        // id: ProjectRating.id,
        employeeId: ProjectRating.employeeId,
        name: ProjectRating.employeeName,
        department: ProjectRating.competency,
        lineManager: ProjectRating.lineManagerName,
        PMStatus: ProjectRating.PMStatus,
        archStatus: ProjectRating.archStatus,
        feedbackStatus: ProjectRating.feedbackStatus,
        projectName: ProjectRating.projectName,
        projectId: ProjectRating.projectId,
        competency: ProjectRating.competency,
        projectManagerId: data.projectManagerId,
        architectId: data.architectId,
      };
      return rating;
    })
    .sort((a, b) => a.name.localeCompare(b.name));
  resultData.projectRatings = projectRatings;
  return resultData;
}

export function normalizeProjectSummary(res) {
  const { performanceCycle, project, ratees, dueDate } = res;
  const resultData = {};
  resultData.projectName = project.name;
  resultData.cycleName = performanceCycle.cycleName;
  resultData.completeStatus = !performanceCycle.isActive;
  resultData.dueDate = dueDate;
  resultData.initiationDate = performanceCycle.initiationDate;
  const projectRatings = ratees
    .map((data) => {
      const rating = {
        rateeId: data.rateeId,
        name: data.name,
        lineManager: data.lineManagerName,
        freeze: normalizeFreezeAttribute(data.freeze),
      };
      return rating;
    })
    .sort((a, b) => a.name.localeCompare(b.name));
  resultData.projectRatings = projectRatings;
  return resultData;
}

export function normalizeEmployeeRatings(res) {
  return res.map((data) => {
    const ProjectRating = data || {};
    const rating = {
      name: ProjectRating.name,
      projectManager: ProjectRating.projectManager,
      projectArchitect: ProjectRating.projectArchitect,
      projectName: ProjectRating.projectName,
      competency: ProjectRating.competency,
      PMQuality: ProjectRating.PMQuality,
      PMSkill: ProjectRating.PMSkill,
      PMOwnership: ProjectRating.PMOwnership,
      PMEfficiency: ProjectRating.PMEfficiency,
      PMEthics: ProjectRating.PMEthics,
      archQuality: ProjectRating.archQuality,
      archSkill: ProjectRating.archSkill,
      archOwnership: ProjectRating.archOwnership,
      archEfficiency: ProjectRating.archEfficiency,
      archEthics: ProjectRating.archEthics,
    };
    return rating;
  });
}

export function normalizeRatingDetails(res) {
  const ratingDetails = {
    dueDate: res.dueDate,
    completeStatus: res.completeStatus,
  };
  ratingDetails.ratings = res.ratings.map((rating) => {
    const normalizedObj = {
      ratingId: rating.ratingId,
      freeze: normalizeFreezeAttribute(rating.freeze),
      rateeName: rating.rateeName,
      rateeRole: rating.rateeRole,
      raterRole: rating.raterRole,
      raterName: rating.raterName,
      ratingAttributes: rating.ratingAttributes.map((ratingAttribute) => ({
        ratingAttributeId: ratingAttribute.ratingAttributeId,
        attributeName: ratingAttribute.attributeName,
        rating: ratingAttribute.rating,
        comment: ratingAttribute.comment,
        attributeDescription: ratingAttribute.attributeDescription,
      })),
    };

    return normalizedObj;
  });

  return ratingDetails;
}
