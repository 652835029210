/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import {
  getGoalInit,
  getGoalSuccess,
  getGoalFailure,
  managerAddGoalInit,
  managerAddGoalSuccess,
  managerAddGoallFailure,
  getGoalDetailsInit,
  getGoalDetailsSuccess,
  getGoalDetailsFailure,
  updateGoalInit,
  updateGoalSuccess,
  updateGoallFailure,
  updateGoalStatusInit,
  updateGoalStatusSuccess,
  updateGoalStatusFailure,
  deleteGoalInit,
  deleteGoalSuccess,
  deleteGoalFailure,
} from 'store/actions/admin/GoalsActions';

import { normalizeAllGoals } from 'normalizers/admin/goalsNormalizers';
import { adminGoalsUrls } from 'configs/constants';

export const getGoalExport = (params) =>
  new Promise((resolve, reject) => {
    const url = adminGoalsUrls.GET_GOALS;
    axios
      .get(url, {
        params,
      })
      .then((response) => {
        if (response && response.data.success) {
          const { rows } = response.data.data;
          const data = normalizeAllGoals(rows);
          resolve(data);
        } else {
          reject(response.message);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getGoal = (request) => (dispatch) => {
  const url = adminGoalsUrls.GET_GOALS;
  dispatch(getGoalInit());
  axios
    .get(url, { params: request })
    .then((response) => {
      if (response.status === 200) {
        dispatch(getGoalSuccess(response.data.data));
      } else {
        dispatch(getGoalFailure());
      }
    })
    .catch((error) => {
      dispatch(getGoalFailure(error));
    });
};

export const managerAddGoal = (request) => (dispatch) => {
  const url = adminGoalsUrls.CREATE_GOAL.replace('{userId}', request.payload.employee);
  const { payload, parameters } = request;
  dispatch(managerAddGoalInit());
  axios
    .post(url, payload)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Created Successfully',
        });
        dispatch(managerAddGoalSuccess(response.data));
        dispatch(getGoal(parameters));
      } else {
        EnhancedNotification.error({
          message: 'Error in creating a new goal',
        });
        dispatch(managerAddGoallFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in creating a new goal.',
      });
      dispatch(managerAddGoallFailure());
    });
};

export const getGoalDetails = (request) => (dispatch) => {
  const url = adminGoalsUrls.GET_GOAL_DETAILS.replace('{userId}', request.userId).replace(
    '{id}',
    request.id,
  );
  dispatch(getGoalDetailsInit());
  axios
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        //   const resourceNormalized = normalizeResourceAvailability(response.data.data, date);
        dispatch(getGoalDetailsSuccess(response.data.data));
      } else {
        dispatch(getGoalDetailsFailure());
      }
    })
    .catch((error) => {
      dispatch(getGoalDetailsFailure(error));
    });
};

export const updateGoal = (request) => (dispatch) => {
  const { id, payload, params } = request;
  const url = adminGoalsUrls.UPDATE_GOAL.replace('{userId}', request.userId).replace('{id}', id);
  dispatch(updateGoalInit());
  axios
    .put(url, payload)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Updated Successfully',
        });
        dispatch(updateGoalSuccess(response.data));
        dispatch(getGoal(params));
      } else {
        EnhancedNotification.error({
          message: 'Error in updating a new goal',
        });
        dispatch(updateGoallFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in updating a new goal.',
      });
      dispatch(updateGoallFailure());
    });
};

export const updateGoalStatus = (request) => (dispatch) => {
  const { payload, params } = request;
  const url = adminGoalsUrls.APPROVE_GOAL;

  dispatch(updateGoalStatusInit());
  axios
    .put(url, payload)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Updated Successfully',
        });
        dispatch(updateGoalStatusSuccess());
        dispatch(getGoal(params));
      } else {
        EnhancedNotification.error({
          message: 'Error in updating a goal',
        });
        dispatch(updateGoalStatusFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in updating the goals.',
      });
      dispatch(updateGoalStatusFailure());
    });
};

export const removeGoal = (request) => (dispatch) => {
  const { id, params } = request;
  const url = adminGoalsUrls.DELETE_GOAL.replace('{userId}', request.userId).replace('{id}', id);
  dispatch(deleteGoalInit());
  axios
    .delete(url)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Deleted Successfully',
        });

        dispatch(deleteGoalSuccess(response.data));
        dispatch(getGoal(params));
      } else {
        EnhancedNotification.error({
          message: 'Error in deleting goal.',
        });
        dispatch(deleteGoalFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({ message: error.response.message || 'Error in deleting goal.' });
      dispatch(deleteGoalFailure(error));
    });
};
