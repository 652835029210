/* eslint-disable no-nested-ternary */
import {
  EnhancedCol,
  EnhancedRow,
  EnhancedButton,
  EnhancedModal,
  EnhancedDatePicker,
} from 'components/shared/antd';
import React, { useState } from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { DATE_FORMAT } from 'configs/employeeProfileConstants';

import UploadSheet from './UploadSheet';

const employeesBulkInfoModel = (props) => {
  const { employeesBulkInfo, visible, onClose } = props;

  const [file, setFile] = useState([]);
  const [effectiveDate, setEffectiveDate] = useState(moment().format(DATE_FORMAT));

  const onDateChange = (date) => {
    setEffectiveDate(moment(date).format(DATE_FORMAT));
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('effectiveDate', effectiveDate);

    if (!isEmpty(file)) {
      formData.append('file', file[0]);
    }
    employeesBulkInfo(formData);
  };

  return (
    <EnhancedModal
      title="Update Employees Bulk Information"
      visible={visible}
      onOk={onClose}
      onCancel={onClose}
      width={650}
      footer={null}
      style={{
        backgroundColor: 'white',
        maxHeight: '90%',
        padding: '24',
      }}
      bodyStyle={{ padding: '40px 24px 8px', maxHeight: '90%' }}
    >
      <EnhancedRow className="field">
        <EnhancedCol span={24} className="mb-20">
          <EnhancedRow type="flex" align="middle">
            <EnhancedCol span={4}>Select Date</EnhancedCol>
            <EnhancedCol span={20}>
              <EnhancedDatePicker
                name="effectiveDate"
                dropdownClassName="dueDate-goal-field"
                style={{ width: '100%' }}
                onChange={onDateChange}
                value={moment(effectiveDate)}
                format={DATE_FORMAT}
                allowClear={false}
              />
            </EnhancedCol>
          </EnhancedRow>
        </EnhancedCol>
        <EnhancedCol span={24} className="file-w-full">
          <UploadSheet
            files={file}
            maxCount={1}
            onChange={(_file) => setFile(_file)}
            className="w-full"
          />
        </EnhancedCol>
        <EnhancedCol span={24} className="mt-20">
          <EnhancedRow type="flex" align="middle" justify="end">
            <EnhancedButton
              type="primary"
              className="mr-16"
              onClick={handleSubmit}
              disabled={isEmpty(file)}
            >
              {!isEmpty(file) ? 'Submit' : 'Submit'}
            </EnhancedButton>
            <EnhancedButton onClick={onClose}>Close</EnhancedButton>
          </EnhancedRow>
        </EnhancedCol>
      </EnhancedRow>
    </EnhancedModal>
  );
};

export default employeesBulkInfoModel;
