import {
  EnhancedCol,
  EnhancedRow,
  EnhancedCard,
  EnhancedDivider,
  EnhancedTabs,
  EnhancedLayout,
  EnhancedSelect,
  EnhancedInput,
  EnhancedRadio,
  EnhancedCheckbox,
} from 'components/shared/antd';
import React, { Component } from 'react';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';
import { leaveStatus } from 'configs/leaveConstants';
import LeaveRequestItem from 'components/leaves/LeaveRequestItem';
import LeaveRequestPopup from 'components/leaves/LeaveRequestPopup';
import { Desktop, Mobile } from 'components/Responsive';
import { MY_LEAVE_REQUEST } from 'configs/routesConstants';
import { getLeaveStatusColor } from 'utils/colorUtils';
import { paginationOptions } from '../../utils/getPagination';

const RadioButton = EnhancedRadio.Button;
const RadioGroup = EnhancedRadio.Group;
const { Search } = EnhancedInput;
const { Column } = EnhancedServerSideTable;
const { Header } = EnhancedLayout;
const { TabPane } = EnhancedTabs;
const { Option } = EnhancedSelect;
const { page, size } = paginationOptions;

export default class TeamLeavesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      filterStatus: '',
      selectedRequest: {},
      search: '',
      showMyPending: true,
    };
  }

  componentDidMount() {
    const { userId } = this.props;
    this.props.getTeamLeavesContainer(userId, 'TEAM_LEAVES');
    this.checkPermissions(
      this.props.isManager,
      this.props.isPM,
      this.props.isCRMC2,
      this.props.isDM,
    );
  }

  componentWillReceiveProps(nextProps) {
    this.checkPermissions(nextProps.isManager, nextProps.isPM, nextProps.isCRMC2, nextProps.isDM);
    const { updateSuccess } = nextProps;
    const { leavesStatuses, userAttributes } = this.props;

    if (updateSuccess) {
      const { currentlyActiveTab = userAttributes[0].id } = this.state;
      const { id: filterStatus } = leavesStatuses.find(
        (status) => status.systemKey.toLowerCase() === leaveStatus.PENDING,
      );
      this.callTeamLeavesApi(currentlyActiveTab, filterStatus);
      this.setState({ currentlyActiveTab, filterStatus });
    }
  }

  onUpdateLeave = (data) => {
    this.props.updateTeamLeave(data);
  };

  onMyPendingChange = ({ target }) => {
    const { checked: showMyPending } = target;
    const { currentlyActiveTab, search, filterStatus } = this.state;
    const params = {
      page,
      size,
      search,
      leaveStatus: filterStatus,
    };
    if (showMyPending) params.showMyPending = showMyPending;
    this.props.getPrimaryTeamLeaves(currentlyActiveTab, params);
    this.props.getSecondaryTeamLeaves(currentlyActiveTab, params);
    this.setState({ showMyPending });
  };

  setDropdownFilter = (e) => {
    this.setState({ filterStatus: e });
  };

  setRadioFilter = ({ target }) => {
    const { value } = target;
    const { currentlyActiveTab, search } = this.state;
    const params = { page, size, search };
    if (value !== leaveStatus.ALL) params.leaveStatus = value;

    const showMyPending = this.isPendingSelected(value);
    if (showMyPending) params.showMyPending = showMyPending;

    this.props.getPrimaryTeamLeaves(currentlyActiveTab, params);
    this.props.getSecondaryTeamLeaves(currentlyActiveTab, params);
    this.setState({ filterStatus: target.value, showMyPending });
  };

  setSearchText = ({ target }) => {
    const defaults = {
      timeout: 0,
      delayInSeconds: 2000,
    };

    const { value: search } = target;

    if (search.trim().length > 2 || search.trim().length === 0) {
      if (defaults.timeout) clearTimeout(defaults.timeout);
      defaults.timeout = setTimeout(() => {
        const { currentlyActiveTab, filterStatus, showMyPending } = this.state;
        const params = {
          page,
          size,
          search,
        };
        if (filterStatus !== leaveStatus.ALL) params.leaveStatus = filterStatus;
        if (showMyPending) params.showMyPending = showMyPending;

        this.props.getPrimaryTeamLeaves(currentlyActiveTab, params);
        this.props.getSecondaryTeamLeaves(currentlyActiveTab, params);
      }, defaults.delayInSeconds);
    }
    this.setState({ search });
  };

  isPendingSelected = (selectedStatus) => {
    let isPending = false;
    const { leavesStatuses } = this.props;

    const leaveStatusFound = leavesStatuses.find((status) => status.id === selectedStatus);

    if (leaveStatusFound && leaveStatusFound.systemKey.toLowerCase() === leaveStatus.PENDING) {
      isPending = true;
    }

    return isPending;
  };

  callTeamLeavesApi = (currentlyActiveTab, filterStatus) => {
    const { showMyPending } = this.state;
    const params = {
      page,
      size,
      leaveStatus: filterStatus,
    };
    if (showMyPending) params.showMyPending = showMyPending;
    this.props.getPrimaryTeamLeaves(currentlyActiveTab, params);
    this.props.getSecondaryTeamLeaves(currentlyActiveTab, params);
  };

  checkPermissions = (isManager, isPM, isCRMC2, isDM) => {
    if (
      !isManager &&
      !isPM &&
      !isCRMC2 &&
      !isDM &&
      this.props.history.location.pathname !== 'my-leave-requests'
    ) {
      this.props.history.push(MY_LEAVE_REQUEST);
    }
  };

  showPopup = (selectedRequest) => {
    this.setState({ showPopup: true, selectedRequest });
  };

  closePopup = () => this.setState({ showPopup: false });

  activateUser = (key) => {
    const { filterStatus, search } = this.state;
    const params = { page, size, search };

    const showMyPending = this.isPendingSelected(filterStatus);
    if (showMyPending) params.showMyPending = showMyPending;

    if (filterStatus.toString() !== leaveStatus.ALL) params.leaveStatus = filterStatus;

    this.props.getPrimaryTeamLeaves(key, params);
    this.props.getSecondaryTeamLeaves(key, params);

    this.setState({ currentlyActiveTab: key, showMyPending });
  };

  callPageParams = (current) => {
    const { currentlyActiveTab, filterStatus, search } = this.state;
    const params = { page: current - 1, size, search };
    if (filterStatus !== leaveStatus.ALL) params.leaveStatus = filterStatus;
    return { currentlyActiveTab, params };
  };

  handlePrimaryPageChange = ({ current }) => {
    const { currentlyActiveTab, params } = this.callPageParams(current);
    this.props.getPrimaryTeamLeaves(currentlyActiveTab, params);
  };

  handleSecondaryPageChange = ({ current }) => {
    const { currentlyActiveTab, params } = this.callPageParams(current);
    this.props.getSecondaryTeamLeaves(currentlyActiveTab, params);
  };

  expandedRowRender = (record) => {
    const { approvers } = record;

    return (
      approvers &&
      approvers.map((approver, index) => (
        <React.Fragment>
          <EnhancedRow>
            <EnhancedCol lg={5} md={5} sm={24} xs={24}>
              {approver.roles.map((role) => (
                <div>
                  <b>{role.name}</b>
                </div>
              ))}
            </EnhancedCol>
            <EnhancedCol lg={5} md={5} sm={24} xs={24}>
              <b>Status</b>
            </EnhancedCol>
            <EnhancedCol lg={5} md={5} sm={24} xs={24} className="mr-16">
              <b>Comments</b>
            </EnhancedCol>
            <EnhancedCol lg={5} md={5} sm={24} xs={24}>
              <b>Last updated</b>
            </EnhancedCol>
          </EnhancedRow>
          <EnhancedRow>
            <EnhancedCol lg={5} md={5} sm={24} xs={24}>
              {approver.name}
            </EnhancedCol>
            <EnhancedCol
              lg={5}
              md={5}
              sm={24}
              xs={24}
              style={{
                color: getLeaveStatusColor(approver.status),
                textTransform: 'capitalize',
              }}
            >
              <b>{approver.status}</b>
            </EnhancedCol>
            <EnhancedCol lg={5} md={5} sm={24} xs={24} className="mr-16">
              {approver.comments}
            </EnhancedCol>
            <EnhancedCol lg={5} md={5} sm={24} xs={24}>
              {approver.actionDate}
            </EnhancedCol>
          </EnhancedRow>
          {index < approvers.length - 1 && <EnhancedDivider />}
        </React.Fragment>
      ))
    );
  };

  render() {
    const {
      leavesStatuses,
      userAttributes,
      primary: { leaves: primaryLeaves, pageInfo: primaryPageInfo, primaryLoader },
      secondary: { leaves: secondaryLeaves, pageInfo: secondaryPageInfo, secondaryLoader },
    } = this.props;

    const { filterStatus, currentlyActiveTab } = this.state;

    const isPendingTab = leavesStatuses.find(
      (leave) => leave.id === filterStatus && leave.name.toLowerCase() === leaveStatus.PENDING,
    );

    return (
      <div style={{ background: 'white' }}>
        <EnhancedRow style={{ backgroundColor: '#fff', marginBottom: '3px' }}>
          <Header className="leaves-header">
            <EnhancedCol lg={24} md={24} sm={24} xs={24}>
              <h1>Team Leave Requests</h1>
            </EnhancedCol>
          </Header>
        </EnhancedRow>
        {currentlyActiveTab && (
          <EnhancedTabs defaultActiveKey={currentlyActiveTab} onChange={this.activateUser}>
            {userAttributes.map((attribute) => (
              <TabPane tab={attribute.name} key={attribute.id} style={{ background: '#F0F2F5' }}>
                <EnhancedCard
                  bordered={false}
                  className="history-list"
                  loading={primaryLoader}
                  style={{ marginTop: '20px' }}
                >
                  <EnhancedRow>
                    <EnhancedCol lg={4} md={4} sm={24} xs={24}>
                      <h2 className="heading">Team Leave Requests</h2>
                    </EnhancedCol>
                    <EnhancedCol lg={15} md={15} sm={24} xs={24}>
                      <Desktop>
                        {leavesStatuses.length > 0 && (
                          <RadioGroup
                            style={{ textTransform: 'capitalize' }}
                            className="right"
                            value={filterStatus}
                            onChange={this.setRadioFilter}
                          >
                            <RadioButton value={leaveStatus.ALL}>{leaveStatus.ALL}</RadioButton>
                            {leavesStatuses.map(
                              (status) =>
                                status.group === 'LeaveRequest' && (
                                  <RadioButton value={status.id} key={status.id}>
                                    {status.systemKey.replace('-', ' ')}
                                  </RadioButton>
                                ),
                            )}
                          </RadioGroup>
                        )}
                      </Desktop>
                      <Mobile>
                        {leavesStatuses.length > 0 && (
                          <EnhancedSelect
                            showSearch
                            style={{ width: '100%', textTransform: 'capitalize' }}
                            placeholder="Select Leave Status"
                            onChange={this.setDropdownFilter}
                            value={filterStatus}
                          >
                            <Option style={{ textTransform: 'capitalize' }} value={leaveStatus.ALL}>
                              {leaveStatus.ALL}
                            </Option>
                            {leavesStatuses.map(
                              (status) =>
                                status.group === 'LeaveRequest' && (
                                  <RadioButton value={status.id} key={status.id}>
                                    {status.systemKey.replace('-', ' ')}
                                  </RadioButton>
                                ),
                            )}
                          </EnhancedSelect>
                        )}
                      </Mobile>
                    </EnhancedCol>
                    <EnhancedCol lg={5} md={5} sm={24} xs={24}>
                      <Search
                        className="search-team-leaves"
                        placeholder="Search"
                        value={this.state.search}
                        onChange={this.setSearchText}
                      />
                    </EnhancedCol>
                  </EnhancedRow>
                  <div className="clear-fix" />
                  {isPendingTab && (
                    <div
                      style={{
                        margin: '20px 0 0',
                        background: '#f0f2f5',
                        padding: '15px',
                        borderRadius: '5px',
                      }}
                    >
                      <EnhancedCheckbox
                        checked={this.state.showMyPending}
                        onChange={this.onMyPendingChange}
                      >
                        Show my pending only
                      </EnhancedCheckbox>
                    </div>
                  )}
                  <div className="team-leaves-list-table">
                    <EnhancedServerSideTable
                      rowKey={(record) => record.id}
                      bodyStyle={{ padding: '10 0 10 0' }}
                      data={primaryLeaves}
                      showHeader={false}
                      showResetFiltersButton={false}
                      onChange={this.handlePrimaryPageChange}
                      paginationInfo={{
                        totalItems: primaryPageInfo.totalItems,
                        pageSize: primaryPageInfo.size,
                        pageNumber: primaryPageInfo.page + 1,
                      }}
                      expandedRowRender={(record) => this.expandedRowRender(record)}
                      loading={primaryLoader}
                    >
                      <Column
                        render={(text, record) => (
                          <LeaveRequestItem
                            onClick={() => this.showPopup(record)}
                            request={record}
                            onUpdateLeave={this.onUpdateLeave}
                            workingHolidays={this.props.workingHolidays}
                            currentlyActiveTab={currentlyActiveTab}
                            user={{ id: this.props.userId, name: this.props.userName }}
                          />
                        )}
                      />
                    </EnhancedServerSideTable>
                  </div>
                </EnhancedCard>
                <EnhancedCard
                  bordered={false}
                  className="history-list marginTop15"
                  loading={secondaryLoader}
                >
                  <EnhancedRow>
                    <EnhancedCol lg={6}>
                      <h2 className="heading">Special Leave Requests</h2>
                    </EnhancedCol>
                  </EnhancedRow>
                  <div className="clear-fix" />
                  <div className="marginTop30 padding10">
                    <EnhancedServerSideTable
                      rowKey={(record) => record.id}
                      data={secondaryLeaves}
                      showHeader={false}
                      onChange={this.handleSecondaryPageChange}
                      paginationInfo={{
                        totalItems: secondaryPageInfo.totalItems,
                        pageSize: secondaryPageInfo.size,
                        pageNumber: secondaryPageInfo.page + 1,
                      }}
                      showResetFiltersButton={false}
                      expandedRowRender={(record) => this.expandedRowRender(record)}
                      loading={secondaryLoader}
                    >
                      <Column
                        render={(text, record) => (
                          <LeaveRequestItem
                            onClick={() => this.showPopup(record)}
                            request={record}
                            onUpdateLeave={this.onUpdateLeave}
                            workingHolidays={this.props.workingHolidays}
                            currentlyActiveTab={currentlyActiveTab}
                            user={{ id: this.props.userId, name: this.props.userName }}
                          />
                        )}
                      />
                    </EnhancedServerSideTable>
                  </div>
                </EnhancedCard>
                <LeaveRequestPopup
                  user={{ id: this.props.userId, name: this.props.userName }}
                  visible={this.state.showPopup}
                  onPopupClose={this.closePopup}
                  request={this.state.selectedRequest}
                  onLeaves={primaryLeaves}
                  onUpdateLeave={this.onUpdateLeave}
                  loader={this.props.loader}
                  updateSuccess={this.props.updateSuccess}
                  onUpdateSuccess={this.props.updateTeamLeaveComplete}
                  workingHolidays={this.props.workingHolidays}
                  leavesStatuses={this.props.leavesStatuses}
                  currentlyActiveTab={currentlyActiveTab}
                />
              </TabPane>
            ))}
          </EnhancedTabs>
        )}
      </div>
    );
  }
}
