import { EnhancedRow, EnhancedCol, EnhancedCard, EnhancedForm } from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import {
  ROW_GUTTER_SIZE,
  FULL_GRID_SIZE,
  TEXTAREA,
  RADIO,
  SELECT,
  reviewTypes,
} from 'configs/employeeProfileConstants';
import { createFields } from 'utils/FieldGenerateUtils';
import Authorize from 'components/Authorize';
import Permissions from 'constants/AccessPermissions';

const TrainingAssessment = (props) => {
  const {
    isEditable,
    data,
    updateHandler,
    allEmployees,
    reviewType,
    selfEvaluation,
    showSelfEvaluationComments,
  } = props;
  const [trainingInfo, setTrainingInfo] = useState({});
  const [allCurrentEmployees, setAllCurrentEmployees] = useState([]);
  const [successor, setSuccessorFor] = useState({});

  const getSussessor = (id = undefined) => {
    if (allEmployees) {
      let successorForId = trainingInfo.successorFor;
      if (id) {
        successorForId = id;
      }
      const successorFor = allEmployees.find((emp) => emp.id === successorForId);
      setSuccessorFor(successorFor);
    }
  };

  const getCurrentEmployees = () => {
    const employees = allEmployees.filter((emp) => emp.isActive === true);
    setAllCurrentEmployees(employees);
  };

  useEffect(() => {
    getCurrentEmployees();
  }, [allEmployees]);

  useEffect(() => {
    if (!successor || Object.keys(successor).length === 0) {
      getSussessor();
    }
  }, [trainingInfo, data]);

  useEffect(() => {
    setTrainingInfo(data);
  }, [data]);

  const handleSave = () => {};

  const handleChange = (value, key) => {
    const clonedTrainingInfo = {
      ...trainingInfo,
      [key]: value,
    };

    updateHandler('trainingInfo', clonedTrainingInfo);
    setTrainingInfo(clonedTrainingInfo);

    if (key === 'successorFor') {
      getSussessor(value);
    }
  };

  const getFieldsConfig = (trainingObj) => {
    const fields = [
      {
        key: `strength`,
        label: 'Strengths',
        value: trainingObj.strength,
        type: TEXTAREA,
        noCapitalize: true,
      },
      {
        key: `developmentArea`,
        label: 'Development Areas',
        value: trainingObj.developmentArea,
        type: TEXTAREA,
        noCapitalize: true,
      },
      {
        key: `careerAmbition`,
        label: 'Career Ambitions',
        value: trainingObj.careerAmbition,
        type: TEXTAREA,
        noCapitalize: true,
      },
      {
        key: `shortCourse`,
        label: 'Short Course',
        value: trainingObj.shortCourse,
        type: TEXTAREA,
        noCapitalize: true,
      },
      {
        key: `technicalTraining`,
        label: 'Technical Trainings',
        value: trainingObj.technicalTraining,
        type: TEXTAREA,
        noCapitalize: true,
      },
      {
        key: `softSkillsTraining`,
        label: 'Soft Skills Trainings',
        value: trainingObj.softSkillsTraining,
        type: TEXTAREA,
        noCapitalize: true,
      },
    ];

    return fields;
  };

  return (
    <div>
      <EnhancedForm onSubmit={handleSave}>
        <EnhancedCard>
          <React.Fragment>
            <h2>Training Need Assessment</h2>
            <EnhancedRow key={data.email} gutter={ROW_GUTTER_SIZE}>
              <EnhancedCol span={FULL_GRID_SIZE}>
                <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
                  loading={false}
                  bordered={false}
                  className="card-pd-0"
                >
                  <div>
                    {createFields(getFieldsConfig(trainingInfo), 2, isEditable, [], handleChange)}
                  </div>

                  {selfEvaluation && showSelfEvaluationComments && (
                    <div className="mt-16">
                      <h3 className="category-name mb-10">Employee Comments</h3>
                      {createFields(
                        getFieldsConfig(selfEvaluation.trainingInfo || {}),
                        2,
                        false,
                        [],
                        handleChange,
                      )}
                    </div>
                  )}
                </EnhancedCard>
              </EnhancedCol>
            </EnhancedRow>
          </React.Fragment>
        </EnhancedCard>
        {reviewType !== reviewTypes.SELF && (
          <Authorize
            requiredPermissions={[
              Permissions.Employee.Profile.Compensation.View,
              Permissions.Reportee.Profile.Compensation.View,
            ]}
          >
            <EnhancedCard>
              <React.Fragment>
                <h2>Position Significance</h2>
                <EnhancedRow gutter={ROW_GUTTER_SIZE}>
                  <EnhancedCol span={FULL_GRID_SIZE}>
                    <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
                      loading={false}
                      bordered={false}
                      className="card-pd-0"
                    >
                      <div>
                        {createFields(
                          [
                            {
                              key: `criticalPosition`,
                              label: 'Critical Position',
                              value: trainingInfo.criticalPosition,
                              options: [
                                { key: true, value: 'yes' },
                                { key: false, value: 'no' },
                              ],
                              type: RADIO,
                              labelSize: 8,
                              valueSize: 12,
                            },
                            {
                              key: `highPotential`,
                              label: 'High Potential',
                              value: trainingInfo.highPotential,
                              options: [
                                { key: true, value: 'yes' },
                                { key: false, value: 'no' },
                              ],
                              type: RADIO,
                              labelSize: 8,
                              valueSize: 12,
                            },
                          ],
                          2,
                          isEditable,
                          [],
                          handleChange,
                        )}
                      </div>
                    </EnhancedCard>
                  </EnhancedCol>
                </EnhancedRow>
              </React.Fragment>
            </EnhancedCard>
          </Authorize>
        )}

        {reviewType !== reviewTypes.SELF && (
          <Authorize
            requiredPermissions={[
              Permissions.Employee.Profile.Compensation.View,
              Permissions.Reportee.Profile.Compensation.View,
            ]}
          >
            <EnhancedCard>
              <React.Fragment>
                <h2>Successor Recommendation</h2>
                <EnhancedRow gutter={ROW_GUTTER_SIZE}>
                  <EnhancedCol span={FULL_GRID_SIZE}>
                    <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
                      loading={false}
                      bordered={false}
                      className="card-pd-0"
                    >
                      <div>
                        {createFields(
                          [
                            {
                              key: `successorFor`,
                              label: 'Successor For',
                              value: successor && successor.name,
                              options: allCurrentEmployees,
                              type: SELECT,
                              placeholder: 'Select Successor',
                              labelSize: 8,
                              valueSize: 12,
                            },
                          ],
                          2,
                          isEditable,
                          [],
                          handleChange,
                        )}
                      </div>
                    </EnhancedCard>
                  </EnhancedCol>
                </EnhancedRow>
              </React.Fragment>
            </EnhancedCard>
          </Authorize>
        )}
      </EnhancedForm>
    </div>
  );
};
export default TrainingAssessment;
