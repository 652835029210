import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import {
  getProjectReviewsCycleInit,
  getProjectReviewsCycleSuccess,
  getProjectReviewsCycleFailure,
  getProjectReviewsByCycleIdInit,
  getProjectReviewsByCycleIdSuccess,
  getProjectReviewsByCycleIdFailure,
  getProjectReviewsProjectDataInit,
  getProjectReviewsProjectDataSuccess,
  getProjectReviewsProjectDataFailure,
  getProjectReviewsRatingDataInit,
  getProjectReviewsRatingDataSuccess,
  getProjectReviewsRatingDataFailure,
} from 'store/actions/ProjectReviewsActions';

import { projectReviewsCycleUrls, employeeRatingUrls } from 'configs/constants';
import { showLoader, hideLoader } from 'store/actions/AppLoaderActions';
import {
  normalizeProjectReviewsCycles,
  normalizeProjectReviewsProjects,
  normalizeProjectReviewsRating,
  normalizeProjectReviewsRatingData,
} from '../normalizers/projectReviewsNormalizer';
import DownloadExcelFile from '../utils/ExcelFilesUtils';

export const downloadResourceRatings = (cycleInfo) => (dispatch) => {
  const cycleFilter = `?cycleId=${cycleInfo.id}&origin=crmc_panel`;
  const url = employeeRatingUrls.DOWNLOAD_ALL_RATINGS.concat(cycleFilter);

  dispatch(showLoader());
  axios
    .get(url)
    .then((response) => {
      dispatch(hideLoader());
      if (response.data.success && response.data.data) {
        const headers = [
          { key: 'resourceName', label: 'Resource Name' },
          { key: 'department', label: 'Department' },
          { key: 'competency', label: 'Competency' },
          { key: 'lineManager', label: 'Line Manager' },
          { key: 'performance_Month', label: 'Performance_Month' },
          { key: 'performance_Year', label: 'Performance_Year' },
          { key: 'project', label: 'Project' },
          { key: 'projectManager', label: 'Project Manager' },
          { key: 'projectArchitect', label: 'Project Architect' },
        ];
        DownloadExcelFile(
          [
            {
              arrayOfObjects: response.data.data.RatingsByResource,
              headerList: headers,
              name: 'Ratings By Resource',
            },
          ],
          `Ratings - ${cycleInfo.cycleName}`,
        );
      }
    })
    .catch(() => {
      dispatch(hideLoader());
      EnhancedNotification.error({ message: 'Unable to download ratings.' });
    });
};

export const getProjectReviewsCycle = () => {
  const url = projectReviewsCycleUrls.GET_PROJECT_REVIEWS_CYCLE;
  return (dispatch) => {
    dispatch(getProjectReviewsCycleInit());
    axios
      .get(url)
      .then((response) => {
        if (response.data.success) {
          // parse response through normalizer
          const ProjectReviewsCycles = normalizeProjectReviewsCycles(response.data.data);
          // call success or failure action
          dispatch(getProjectReviewsCycleSuccess(ProjectReviewsCycles));
        } else {
          dispatch(getProjectReviewsCycleFailure(response.data.code));
        }
      })
      .catch((error) => {
        dispatch(getProjectReviewsCycleFailure(error));
      });
  };
};

export const getProjectReviewsByCycleId = (cycleId) => {
  const url = projectReviewsCycleUrls.GET_PROJECT_REVIEWS_BY_CYCLE_ID.replace('{cycleId}', cycleId);

  return (dispatch) => {
    dispatch(getProjectReviewsByCycleIdInit());
    axios
      .get(url)
      .then((response) => {
        if (response.data.success) {
          // parse response through normalizer
          const PRProjects = normalizeProjectReviewsProjects(response.data.data);
          // call success or failure action
          dispatch(getProjectReviewsByCycleIdSuccess(PRProjects));
        } else {
          dispatch(getProjectReviewsByCycleIdFailure(response.data.code));
        }
      })
      .catch((error) => {
        dispatch(getProjectReviewsByCycleIdFailure(error));
      });
  };
};

export const getProjectReviewsProjectData = (projectCycleId, projectId) => {
  const url = projectReviewsCycleUrls.GET_PROJECT_REVIEWS_PROJECT_DATA.replace(
    '{projectCycleId}',
    projectCycleId,
  ).replace('{projectId}', projectId);
  return (dispatch) => {
    dispatch(getProjectReviewsProjectDataInit());
    axios
      .get(url)
      .then((response) => {
        if (response.data.success) {
          // parse response through normalizer
          const ProjectReviewsRating = normalizeProjectReviewsRating(response.data.data);
          // call success or failure action
          dispatch(getProjectReviewsProjectDataSuccess(ProjectReviewsRating));
        } else {
          dispatch(getProjectReviewsProjectDataFailure(response.data.code));
        }
      })
      .catch((error) => {
        dispatch(getProjectReviewsProjectDataFailure(error));
      });
  };
};

export const getProjectReviewsRatingsData = (payload) => {
  const url = `${projectReviewsCycleUrls.GET_PROJECT_REVIEWS_RATING_DATA}`;
  return (dispatch) => {
    dispatch(getProjectReviewsRatingDataInit());
    axios
      .post(url, payload)
      .then((response) => {
        if (response.data.success) {
          // parse response through normalizer
          const ProjectReviewsRatingData = normalizeProjectReviewsRatingData(response.data.data);
          // call success or failure action
          dispatch(getProjectReviewsRatingDataSuccess(ProjectReviewsRatingData));
        } else {
          EnhancedNotification.error({ message: response.data.message });
          dispatch(getProjectReviewsRatingDataFailure(response.data.code));
        }
      })
      .catch((error) => {
        EnhancedNotification.error({ message: error.response.data.message });
        dispatch(getProjectReviewsRatingDataFailure(error));
      });
  };
};
