import { connect } from 'react-redux';
import {
  addGoalCategoryGoalActions,
  updateGoalCategoryGoalActions,
  getGoalCategoryGoalActions,
  getGoalCategoryGoalDetailsActions,
  removeGoalCategoryGoalActions,
  getGoalCategoryLogsActions,
  assignGoalCategoryAction,
} from 'store/actions/admin/PredefinedGoalCategoriesDetailActions';
import { getGoalCategoryDetailsActions } from 'store/actions/admin/PredefinedGoalCategoriesActions';
import { getGoalActions } from 'store/actions/admin/PredefinedGoalsActions';
import updateBreadCrumb from 'store/actions/BreadCrumbActions';
import {
  getAllEmployeesActions,
  getEmployeeDepartmentsAction,
  getEmployeeCompetenciesAction,
  getEmployeeDivisionsAction,
  getEmployeeBusinessUnitAction,
} from 'store/actions/EmployeeActions';
import Detail from '../../components/admin/goalCategories/Detail';

function mapStateToProps(state) {
  const {
    auth,
    predefinedGoalCategoryDetailReducer,
    predefinedGoalReducer,
    predefinedGoalCategoryReducer,
    employeeReducer,
  } = state;

  const { isLoading } = predefinedGoalCategoryDetailReducer;
  const { divisions, departments, competencies, businessUnits } = employeeReducer;

  return {
    isLoading,
    userRole: auth.user,
    logs: predefinedGoalCategoryReducer.goalCategoryLogs,
    goals: predefinedGoalReducer.predefinedGoals,
    list: predefinedGoalCategoryDetailReducer.goalCategoryGoals,
    details: predefinedGoalCategoryDetailReducer.goalDetails,
    categoryDetails: predefinedGoalCategoryReducer.goalCategoryDetails,
    employees: employeeReducer.allEmployees,
    divisions,
    departments,
    competencies,
    businessUnits,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getGoal: (req) => dispatch(getGoalActions(req)),
    addGoalCategoryGoal: (req) => dispatch(addGoalCategoryGoalActions(req)),
    updateGoalCategoryGoal: (req) => dispatch(updateGoalCategoryGoalActions(req)),
    getGoalCategoryGoal: (req) => dispatch(getGoalCategoryGoalActions(req)),
    getGoalCategoryGoalDetails: (req) => dispatch(getGoalCategoryGoalDetailsActions(req)),
    removeGoalCategoryGoal: (req) => dispatch(removeGoalCategoryGoalActions(req)),
    getGoalCategoryDetails: (req) => dispatch(getGoalCategoryDetailsActions(req)),
    updateBreadCrumb: (key, oldVal, newVal) => dispatch(updateBreadCrumb(key, oldVal, newVal)),
    getGoalCategoryLogs: (req) => dispatch(getGoalCategoryLogsActions(req)),
    assignGoalCategory: (req) => dispatch(assignGoalCategoryAction(req)),
    getAllEmployees: () => dispatch(getAllEmployeesActions()),
    getEmployeeDivisionsAction: () => dispatch(getEmployeeDivisionsAction()),
    getEmployeeDepartmentsAction: () => dispatch(getEmployeeDepartmentsAction()),
    getEmployeeCompetenciesAction: () => dispatch(getEmployeeCompetenciesAction()),
    getEmployeeBusinessUnitAction: (req) => dispatch(getEmployeeBusinessUnitAction(req)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
