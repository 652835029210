import { EnhancedCol, EnhancedRow, EnhancedAvatar, EnhancedTooltip } from 'components/shared/antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { parse } from 'utils/stringUtils';
import PROJECT_ROUTES from 'constants/UrlConstants';
import indirectReporteeImage from '../../images/indirect_reportees.png';
import directReporteeImage from '../../images/direct_reportees.png';

import './employeeDirectory.css';

const EmployeeOrgTreeCard = (props) => {
  const { reportee, onDirectReporteesCount, isDirectReportee } = props;
  const totalReportees = reportee.directReporteesCount + reportee.indirectReporteesCount;

  const goToProfile = (id) => {
    if (isDirectReportee) {
      const { history } = props;
      const link = parse(PROJECT_ROUTES.REPORTEE_PROFILE_ROUTE, {
        id,
      });
      history.push(link);
    }
  };

  return (
    <div className="tree-card">
      <EnhancedRow className="org-card-top" type="flex" justify="space-between" align="middle">
        <EnhancedCol>
          {reportee.imageUrl ? (
            <EnhancedAvatar className="org-tree-litteral" src={reportee.imageUrl} />
          ) : (
            <div className="org-tree-litteral">
              {reportee.fullName && reportee.fullName[0].substring(0, 1).toUpperCase()}
            </div>
          )}
        </EnhancedCol>
        {reportee.directReporteesCount > 0 && (
          <EnhancedCol>
            <EnhancedRow className="org-card-direct-indirect-count">
              <EnhancedCol>
                <EnhancedTooltip title="Total reportees count">
                  <EnhancedRow
                    className="org-card-indirect-count org-card-counts"
                    onClick={onDirectReporteesCount}
                  >
                    <EnhancedCol span={24}>
                      <img alt="" width="16px" src={indirectReporteeImage} />
                    </EnhancedCol>
                    <EnhancedCol span={24}>{totalReportees}</EnhancedCol>
                  </EnhancedRow>
                </EnhancedTooltip>
                <EnhancedTooltip title="Direct reportees count">
                  <EnhancedRow className="org-card-counts" onClick={onDirectReporteesCount}>
                    <EnhancedCol span={24}>
                      <img alt="" width="5px" src={directReporteeImage} />
                    </EnhancedCol>
                    <EnhancedCol span={24}>{reportee.directReporteesCount}</EnhancedCol>
                  </EnhancedRow>
                </EnhancedTooltip>
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCol>
        )}
      </EnhancedRow>
      <EnhancedRow className="org-card-bottom">
        <EnhancedCol
          className={`org-card-bottom-title ${
            isDirectReportee ? 'org-card-bottom-title-clickable' : ''
          }`}
          onClick={() => goToProfile(reportee.id)}
        >
          <strong>{reportee.fullName}</strong>
        </EnhancedCol>
        <EnhancedCol className="org-card-bottom-designation">{reportee.designation}</EnhancedCol>
      </EnhancedRow>
    </div>
  );
};

export default withRouter(EmployeeOrgTreeCard);
