import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PivotTable from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import evaluationStatus, { PMDashboardConstants } from 'configs/performanceManagementConstants';
import { DATE_FORMAT } from 'configs/employeeProfileConstants';
import moment from 'moment';
import EmployeesList from './EmployeesList';
import PMCycleDropdown from './PMCycleDropdown';

const getTextForBooleanValues = (value) => (value ? 'Yes' : 'No');

const getCustomizedColumns = () => {
  const columns = [
    {
      title: 'Review Manager',
      dataIndex: 'reviewManagerName',
      key: 'reviewManagerName',
      sorter: (a, b) => a.reviewManagerName.localeCompare(b.reviewManagerName),
      config: {
        needFilter: true,
        showInTable: true,
        showInExport: true,
      },
    },
    {
      title: 'Is promotion?',
      dataIndex: 'promotionInfo.isPromotion',
      key: 'promotionInfo.isPromotion',
      // render: (value) => (value ? 'Yes' : 'No'),
      config: {
        showInTable: true,
        showInExport: true,
      },
    },
    {
      title: 'Strengths',
      dataIndex: 'promotionInfo.strengths',
      key: 'promotionInfo.strengths',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
    {
      title: 'Areas of improvement',
      dataIndex: 'promotionInfo.areasOfImprovement',
      key: 'promotionInfo.areasOfImprovement',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
    {
      title: 'New grade',
      dataIndex: 'promotionInfo.newGrade',
      key: 'promotionInfo.newGrade',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
    {
      title: 'New designation after promotion',
      dataIndex: 'promotionInfo.designationAfterPromotion',
      key: 'promotionInfo.designationAfterPromotion',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
    {
      title: 'Promotion justification',
      dataIndex: 'promotionInfo.promotionJustification',
      key: 'promotionInfo.promotionJustification',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
    {
      title: 'Other comments',
      dataIndex: 'promotionInfo.otherComments',
      key: 'promotionInfo.otherComments',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
    {
      title: 'Is redesignation?',
      dataIndex: 'promotionInfo.isRedesignation',
      key: 'promotionInfo.isRedesignation',
      // render: (value) => (value ? 'Yes' : 'No'),
      config: {
        showInTable: true,
        showInExport: true,
      },
    },
    {
      title: 'New designation after redesignation',
      dataIndex: 'promotionInfo.designationAfterRedesignation',
      key: 'promotionInfo.designationAfterRedesignation',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
    {
      title: 'Recommended salary',
      dataIndex: 'promotionInfo.recommendedSalary',
      key: 'promotionInfo.recommendedSalary',
      config: {
        showInTable: true,
        showInExport: true,
      },
    },
    {
      title: 'Critical position?',
      dataIndex: 'manager.trainingInfo.criticalPosition',
      key: 'manager.trainingInfo.criticalPosition',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
    {
      title: 'High potential?',
      dataIndex: 'manager.trainingInfo.highPotential',
      key: 'manager.trainingInfo.highPotential',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
    {
      title: 'Successor for',
      dataIndex: 'manager.trainingInfo.successorFor',
      key: 'manager.trainingInfo.successorFor',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
  ];
  return columns;
};

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

const ManagerEvaluation = (props) => {
  const {
    pmCycles,
    evaluationReport,
    getPMCycleActions,
    getEvaluationReport,
    baseUrlForEvaluation,
    divisions,
    departments,
    competencies,
    businessUnits,
  } = props;
  const [employeesList, setEmployeesList] = useState([]);
  const [managerEvaluationSummary, setManagerEvaluationSummary] = useState([]);

  const [pivotTable, setPivotTable] = useState({
    cols: ['category'],
    rows: ['competency', 'department'],
    hiddenFromDragDrop: PMDashboardConstants.HIDDEN_COLUMNS_FROM_DRAG_DROP,
    rendererOptions: { plotly: { width: 600, height: 600 } },
    tableOptions: {
      height: '100vh',
      clickCallback: (e, value, filters, pivotData) => {
        // in some cases, some key values are coming as null in filters, specially when we click "sum" rows at the bottom, hence it is effecting filters
        const correctFilters = {};

        Object.keys(filters).forEach((key) => {
          if (filters[key]) {
            correctFilters[key] = filters[key];
          }
        });
        const resources = [];
        pivotData.forEachMatchingRecord(correctFilters, (record) => {
          resources.push(record);
        });
        setEmployeesList(resources);
      },
    },
    unusedOrientationCutoff: Infinity,
  });

  const getCategory = (promotionInfo) => {
    let category = '';
    const { isPromotion, isRedesignation, recommendedSalary } = promotionInfo;

    const valuesToConsider = [isPromotion, isRedesignation, recommendedSalary];

    const truthyValues = valuesToConsider.filter((item) => item);

    if (truthyValues.length > 1) {
      category = 'Combination';
    } else if (isPromotion) {
      category = 'Promotion case';
    } else if (isRedesignation) {
      category = 'Redesignation case';
    } else if (recommendedSalary) {
      category = 'Salary recommendation';
    }

    return category;
  };

  useEffect(() => {
    if (evaluationReport.length > 0) {
      // evaluations with status of Pending or Pending Approval will be considered for this one
      const normalizedData = [];

      evaluationReport.forEach((item) => {
        const { manager, promotionInfo } = item;
        if (
          [
            evaluationStatus.APPROVED,
            evaluationStatus.PENDING_APPROVAL,
            evaluationStatus.PENDING,
            evaluationStatus.FINAL,
          ].includes(manager.status) &&
          (promotionInfo.isPromotion ||
            promotionInfo.isRedesignation ||
            promotionInfo.recommendedSalary)
        ) {
          normalizedData.push({
            ...item,
            category: getCategory(promotionInfo),
            promotionInfo: {
              ...promotionInfo,
              isPromotion: getTextForBooleanValues(promotionInfo.isPromotion),
              isRedesignation: getTextForBooleanValues(promotionInfo.isRedesignation),
            },
            manager: {
              ...manager,
              trainingInfo: {
                ...manager.trainingInfo,
                highPotential: getTextForBooleanValues(manager.trainingInfo.highPotential),
                criticalPosition: getTextForBooleanValues(manager.trainingInfo.criticalPosition),
              },
            },
          });
        }
      });

      setManagerEvaluationSummary(normalizedData);
    } else {
      setManagerEvaluationSummary([]);
    }
  }, [evaluationReport]);

  useEffect(() => {
    const params = {
      pageNumber: 0,
      pageSize: 100000,
    };

    getPMCycleActions(params);
  }, []);

  const changeActionHandler = (id) => {
    getEvaluationReport(id);
  };

  const performanceCycleDropdown = () => {
    return <PMCycleDropdown pmCycles={pmCycles} onChange={changeActionHandler} />;
  };

  return (
    <div style={{ overflow: 'hidden', overflowY: 'auto' }} className="mt-20">
      <div className="mb-20">{performanceCycleDropdown()}</div>
      <PivotTable
        data={managerEvaluationSummary}
        renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
        {...pivotTable}
        onChange={(s) => setPivotTable(s)}
      />
      <div style={{ marginTop: '30px', overflowY: 'scroll' }}>
        <EmployeesList
          employees={employeesList}
          customizedColumns={getCustomizedColumns(baseUrlForEvaluation)}
          exportFileName={`Promotion Report - ${moment().format(DATE_FORMAT)}`}
          divisions={divisions}
          departments={departments}
          competencies={competencies}
          businessUnits={businessUnits}
        />
      </div>
    </div>
  );
};

ManagerEvaluation.propTypes = {
  evaluationReport: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      taleoId: PropTypes.string.isRequired,
      division: PropTypes.string.isRequired,
      competency: PropTypes.string.isRequired,
      department: PropTypes.string.isRequired,
      employeeId: PropTypes.number.isRequired,
      designation: PropTypes.string.isRequired,
      businessUnit: PropTypes.string.isRequired,
      reviewManagerName: PropTypes.string.isRequired,
      self: PropTypes.shape({
        status: PropTypes.string.isRequired,
        rating: PropTypes.number,
      }),
      manager: PropTypes.shape({
        status: PropTypes.string.isRequired,
        rating: PropTypes.number,
      }),
    }),
  ).isRequired,
  getEvaluationReport: PropTypes.func.isRequired,
};

export default ManagerEvaluation;
