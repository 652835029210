const crmcCyclesNormalizers = (antColumn, antData) => {
  const transformed = antData.map((data) =>
    antColumn.reduce((obj, column) => {
      const filteredObj = obj;
      if (!column.excludeFromExport) {
        filteredObj[column.key] = data[column.key];
      }
      return filteredObj;
    }, {}),
  );
  return transformed.sort((a, b) => a.name - b.name);
};

export default crmcCyclesNormalizers;
