export const GET_PERFORMANCE_CYCLES_INIT = 'GET_PERFORMANCE_CYCLES_INIT';
export const GET_PERFORMANCE_CYCLES_SUCCESS = 'GET_PERFORMANCE_CYCLES_SUCCESS';
export const GET_PERFORMANCE_CYCLES_FAILURE = 'GET_PERFORMANCE_CYCLES_FAILURE';

export const GET_PERFORMANCE_CRMC_CYCLES_INIT = 'GET_PERFORMANCE_CRMC_CYCLES_INIT';
export const GET_PERFORMANCE_CRMC_CYCLES_SUCCESS = 'GET_PERFORMANCE_CRMC_CYCLES_SUCCESS';
export const GET_PERFORMANCE_CRMC_CYCLES_FAILURE = 'GET_PERFORMANCE_CRMC_CYCLES_FAILURE';

export const GET_PROJECT_BY_CYCLE_ID_INIT = 'GET_PROJECT_BY_CYCLE_ID_INIT';
export const GET_PROJECT_BY_CYCLE_ID_SUCCESS = 'GET_PROJECT_BY_CYCLE_ID_SUCCESS';
export const GET_PROJECT_BY_CYCLE_ID_FAILURE = 'GET_PROJECT_BY_CYCLE_ID_FAILURE';

export const GET_PROJECT_BY_CYCLE_ID_CRMC_INIT = 'GET_PROJECT_BY_CYCLE_ID_CRMC_INIT';
export const GET_PROJECT_BY_CYCLE_ID_CRMC_SUCCESS = 'GET_PROJECT_BY_CYCLE_ID_CRMC_SUCCESS';
export const GET_PROJECT_BY_CYCLE_ID_CRMC_FAILURE = 'GET_PROJECT_BY_CYCLE_ID_CRMC_FAILURE';

export const GET_PROJECT_RATING_INIT = 'GET_PROJECT_RATING_INIT';
export const GET_PROJECT_RATING_SUCCESS = 'GET_PROJECT_RATING_SUCCESS';
export const GET_PROJECT_RATING_FAILURE = 'GET_PROJECT_RATING_FAILURE';

export const ADD_PROJECT_RATING_INIT = 'ADD_PROJECT_RATING_INIT';
export const ADD_PROJECT_RATING_SUCCESS = 'ADD_PROJECT_RATING_SUCCESS';
export const ADD_PROJECT_RATING_FAILURE = 'ADD_PROJECT_RATING_FAILURE';

export const CREATE_PERFORMANCE_CYCLE_INIT = 'CREATE_PERFORMANCE_CYCLE_INIT';
export const CREATE_PERFORMANCE_CYCLE_SUCCESS = 'CREATE_PERFORMANCE_CYCLE_SUCCESS';
export const CREATE_PERFORMANCE_CYCLE_FAILURE = 'CREATE_PERFORMANCE_CYCLE_FAILURE';

export const EDIT_PERFORMANCE_CYCLE_DETAILS_INIT = 'EDIT_PERFORMANCE_CYCLE_DETAILS_INIT';
export const EDIT_PERFORMANCE_CYCLE_DETAILS_SUCCESS = 'EDIT_PERFORMANCE_CYCLE_DETAILS_SUCCESS';
export const EDIT_PERFORMANCE_CYCLE_DETAILS_FAILURE = 'EDIT_PERFORMANCE_CYCLE_DETAILS_FAILURE';

export const MARK_PERFORMANCE_CYCLE_COMPLETE_INIT = 'MARK_PERFORMANCE_CYCLE_COMPLETE_INIT';
export const MARK_PERFORMANCE_CYCLE_COMPLETES_SUCCESS = 'MARK_PERFORMANCE_CYCLE_COMPLETE_SUCCESS';
export const MARK_PERFORMANCE_CYCLE_COMPLETE_FAILURE = 'MARK_PERFORMANCE_CYCLE_COMPLETE_FAILURE';

export const EXTEND_PROJECT_CYCLE_DUE_DATES = 'EXTEND_PROJECT_CYCLE_DUE_DATES';

export const UPDATE_PERFORMANCE_CYCLE_INIT = 'GET_PERFORMANCE_CYCLES_INIT';
export const UPDATE_PERFORMANCE_CYCLE_SUCCESS = 'GET_PERFORMANCE_CYCLES_SUCCESS';
export const UPDATE_PERFORMANCE_CYCLE_FAILURE = 'GET_PERFORMANCE_CYCLES_FAILURE';

export const GET_CRMC_PANEL_PROJECT_DATA_INIT = 'GET_CRMC_PANEL_PROJECT_DATA_INIT';
export const GET_CRMC_PANEL_PROJECT_DATA_SUCCESS = 'GET_CRMC_PANEL_PROJECT_DATA_SUCCESS';
export const GET_CRMC_PANEL_PROJECT_DATA_FAILURE = 'GET_CRMC_PANEL_PROJECT_DATA_FAILURE';

export const GET_RATING_DETAILS_INIT = 'GET_RATING_DETAILS_INIT';
export const GET_RATING_DETAILS_SUCCESS = 'GET_RATING_DETAILS_SUCCESS';
export const GET_RATING_DETAILS_FAILURE = 'GET_RATING_DETAILS_FAILURE';
export const RESET_RATING_DETAILS = 'RESET_RATING_DETAILS';

export const GET_PROJECT_SCORE_INIT = 'GET_PROJECT_SCORE_INIT';
export const GET_PROJECT_SCORE_SUCCESS = 'GET_PROJECT_SCORE_SUCCESS';
export const GET_PROJECT_SCORE_FAILURE = 'GET_PROJECT_SCORE_FAILURE';
