import {
  GET_MISCELLANEOUS_INIT,
  GET_MISCELLANEOUS_SUCCESS,
  GET_MISCELLANEOUS_FAILURE,
  GET_DESIGNATIONS_SUCCESS,
  GET_DESIGNATIONS_FAILURE,
  GET_JOB_DESCRIPTION_DOCUMENT_URL_INIT,
  GET_JOB_DESCRIPTION_DOCUMENT_URL_SUCCESS,
  GET_JOB_DESCRIPTION_DOCUMENT_URL_FAILURE,
} from 'constants/MiscellaneousTypes';

const initialState = {
  isMiscellaneousLoading: false,
  designations: [],
  divisions: [],
  employmentTypes: [],
  jobDescriptionDocumentURL: '',
};
export default function miscellaneousReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MISCELLANEOUS_INIT:
    case GET_JOB_DESCRIPTION_DOCUMENT_URL_INIT: {
      return Object.assign({}, state, {
        isMiscellaneousLoading: true,
      });
    }

    case GET_MISCELLANEOUS_SUCCESS: {
      const { data } = action;
      return Object.assign({}, state, {
        ...data,
        isMiscellaneousLoading: false,
      });
    }

    case GET_DESIGNATIONS_SUCCESS: {
      const { data } = action;
      return Object.assign({}, state, {
        designations: [...data],
        isMiscellaneousLoading: false,
      });
    }

    case GET_JOB_DESCRIPTION_DOCUMENT_URL_SUCCESS: {
      const { url } = action;
      return { ...state, jobDescriptionDocumentURL: url };
    }

    case GET_MISCELLANEOUS_FAILURE:
    case GET_DESIGNATIONS_FAILURE:
    case GET_JOB_DESCRIPTION_DOCUMENT_URL_FAILURE: {
      return Object.assign({}, state, {
        isMiscellaneousLoading: false,
      });
    }

    default:
      return state;
  }
}
