import { connect } from 'react-redux';
import Resources from 'components/rca/Resources';
import { getResourcesActions, clearResourceData } from 'store/actions/ResourceActions';
import { getRcaProjectsActions } from 'store/actions/RcaProjectsActions';
import {
  getEmployeeDivisionsAction,
  getEmployeeCompetenciesAction,
  getEmployeeBusinessUnitAction,
  getEmployeeDepartmentsAction,
} from 'store/actions/EmployeeActions';

function mapStateToProps(state) {
  const { auth, resourceReducer, rcaProjectsReducer, employeeReducer } = state;

  const { divisions, departments, competencies, businessUnits } = employeeReducer;
  return {
    userId: auth.user && auth.user.id,
    readOnly: auth.user && auth.user.isReadOnly,
    resources: resourceReducer.resources,
    projects: rcaProjectsReducer.projects,
    isLoading: resourceReducer.isLoading || rcaProjectsReducer.isLoading,
    divisions,
    departments,
    competencies,
    businessUnits,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getResourcesActions: (startDate, endDate) => dispatch(getResourcesActions(startDate, endDate)),
    getRcaProjectsActions: (startDate, endDate) =>
      dispatch(getRcaProjectsActions(startDate, endDate)),
    clearResourceData: () => dispatch(clearResourceData()),
    getEmployeeDivisionsAction: () => dispatch(getEmployeeDivisionsAction()),
    getEmployeeCompetenciesAction: () => dispatch(getEmployeeCompetenciesAction()),
    getEmployeeBusinessUnitAction: () => dispatch(getEmployeeBusinessUnitAction()),
    getEmployeeDepartmentsAction: () => dispatch(getEmployeeDepartmentsAction()),
  };
}
const ResourcesContainer = connect(mapStateToProps, mapDispatchToProps)(Resources);

export default ResourcesContainer;
