import {
  EnhancedCol,
  EnhancedButton,
  EnhancedIcon,
  EnhancedCard,
  EnhancedRow,
  EnhancedDivider,
  EnhancedLayout,
  EnhancedModal,
  EnhancedInput,
} from 'components/shared/antd';
import React, { Component, Fragment } from 'react';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';

const { Header } = EnhancedLayout;
const { confirm } = EnhancedModal;

class Skills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editVisible: false,
      addVisible: false,
      skillName: '',
      editSkillName: '',
      selectedRecord: null,
      skills: this.props.defaultSkills || [],
    };
  }

  componentDidMount() {
    this.props.getEmployeesSkillsAction(this.props.userId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultSkills !== this.props.defaultSkills) {
      this.setState({ skills: nextProps.defaultSkills });
    }
  }

  getColumnsInfo = () => {
    const columns = [
      {
        title: 'Skills',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        filterConfig: {
          type: 'search',
          key: 'name',
        },
      },
      // {
      //   title: 'Associated Active Employees',
      //   dataIndex: 'employee_skills',
      //   key: 'employee_skills',
      //   render: employeeSkills => employeeSkills
      //     .filter(employeeSkill => employeeSkill.employee && employeeSkill.employee.isActive)
      //     .map(activeItem => activeItem.employee.fullName)
      //     .join(', '),
      //   width: '50%',
      // },
      {
        title: 'Action',
        key: 'action',
        width: '5%',
        render: (text, record) => (
          <span>
            <EnhancedIcon
              type="edit"
              data-testid="edit-icon"
              style={{ cursor: 'pointer' }}
              onClick={() => this.showEditSkillModal(record)}
            />
            <EnhancedDivider type="vertical" />
            <EnhancedIcon
              type="delete"
              data-testid="delete-icon"
              style={{ cursor: 'pointer' }}
              onClick={() => this.showDeleteSkillModal(record)}
            />
          </span>
        ),
      },
    ];
    return columns;
  };

  showEditSkillModal = (record) => {
    this.setState({
      editVisible: true,
      selectedRecord: record,
      editSkillName: record.name,
    });
  };

  hideEditSkillModal = () => {
    this.setState({
      editVisible: false,
      addVisible: false,
      selectedRecord: null,
      editSkillName: '',
      skillName: '',
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleAddSkill = () => {
    const { skillName } = this.state;
    const { addSkill, userId } = this.props;
    addSkill({ name: skillName, employeeId: userId });
    this.setState({ skillName: '', addVisible: false });
  };

  handleEditSkill = () => {
    const { selectedRecord, editSkillName } = this.state;
    const { editSkill, userId } = this.props;
    editSkill({ id: selectedRecord.id, name: editSkillName, employeeId: userId });
    this.setState({ selectedRecord: null, editSkillName: '', editVisible: false });
  };

  handleDeleteSkill = (record) => {
    const { deleteSkill } = this.props;
    deleteSkill({ id: record.id, employeeId: this.props.userId });
  };

  shouldSaveDisable = () => {
    const { skillName, editSkillName } = this.state;
    if (skillName && skillName.trim().length > 0) return false;
    if (editSkillName && editSkillName.trim().length > 0) return false;
    return true;
  };

  editSkill = () => {
    const { editVisible, addVisible, skillName, editSkillName } = this.state;
    return (
      <EnhancedModal
        data-testid="modal-title"
        title={editVisible ? 'Edit Skill' : 'Add Skill'}
        visible={editVisible || addVisible}
        onCancel={this.hideEditSkillModal}
        footer={[
          <EnhancedButton key="back" onClick={this.hideEditSkillModal}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            data-testid="modal-save-button"
            key="add"
            type="primary"
            disabled={this.shouldSaveDisable()}
            onClick={editVisible ? this.handleEditSkill : this.handleAddSkill}
          >
            Save
          </EnhancedButton>,
        ]}
      >
        <EnhancedRow className="edit-skill">
          <EnhancedCol span={12}>
            <EnhancedInput
              name={editVisible ? 'editSkillName' : 'skillName'}
              value={editVisible ? editSkillName : skillName}
              onChange={this.handleChange}
              placeholder="Enter Skill"
            />
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedModal>
    );
  };

  showAddSkillModal = () => {
    this.setState({ addVisible: true });
  };

  showDeleteSkillModal = (record) => {
    const { handleDeleteSkill } = this;
    confirm({
      title: 'Are you sure you want to delete this skill?',
      content: 'Note: Skills possessed by employee(s) cannot be deleted',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteSkill(record);
      },
    });
  };

  render() {
    const columns = this.getColumnsInfo();
    const { skills } = this.state;
    const { isLoading } = this.props;
    return (
      <Fragment>
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol lg={12}>
              <h1 className="heading-pro">Skills</h1>
            </EnhancedCol>
            <EnhancedCol span={6} offset={6}>
              <EnhancedButton
                type="primary"
                onClick={this.showAddSkillModal}
                style={{ float: 'right', marginTop: '5%' }}
              >
                {' '}
                Add New Skills
              </EnhancedButton>
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        {this.editSkill()}
        <div style={{ background: 'white' }}>
          <EnhancedCard loading={isLoading} bordered={false}>
            <EnhancedRow />
          </EnhancedCard>
          <div>
            <EnhancedClientSideTable
              style={{ marginLeft: '25px' }}
              loading={isLoading}
              columns={columns}
              data={skills}
              exportFileConfig={{
                fileName: 'Skills',
                fileHeaders: [
                  {
                    label: 'Skill',
                    key: 'name',
                  },
                ],
                showExportButton: true,
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Skills;
