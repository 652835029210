import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PivotTable from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import evaluationStatus, { PMDashboardConstants } from 'configs/performanceManagementConstants';
import { reviewTypes, DATE_FORMAT } from 'configs/employeeProfileConstants';
import moment from 'moment';
import EmployeesList from './EmployeesList';
import RatingRenderer from './RatingRenderer';
import PMCycleDropdown from './PMCycleDropdown';

const getCustomizedColumns = (baseUrlForEvaluation) => {
  const columns = [
    {
      title: 'Review Manager',
      dataIndex: 'reviewManagerName',
      key: 'reviewManagerName',
      sorter: (a, b) => a.reviewManagerName.localeCompare(b.reviewManagerName),
      config: {
        needFilter: true,
        showInTable: true,
        showInExport: true,
      },
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      config: {
        showInTable: true,
        showInExport: true,
      },
    },
    {
      title: 'Self Rating',
      dataIndex: ['self', 'rating'],
      config: {
        showInTable: true,
        showInExport: true,
      },
      sorter: (a, b) => a.self.rating - b.self.rating,
      render: (rating, _record) => (
        <RatingRenderer
          rating={rating}
          reviewType={reviewTypes.SELF}
          baseUrl={baseUrlForEvaluation}
          performanceCycleId={_record.performanceCycleId}
          employeeId={_record.employeeId}
        />
      ),
    },
  ];
  return columns;
};

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

const SelfEvaluation = (props) => {
  const {
    pmCycles,
    evaluationReport,
    getPMCycleActions,
    getEvaluationReport,
    baseUrlForEvaluation,
    divisions,
    departments,
    competencies,
    businessUnits,
  } = props;
  const [employeesList, setEmployeesList] = useState([]);
  const [selfEvaluationSummary, setSelfEvaluationSummary] = useState([]);
  const [pivotTable, setPivotTable] = useState({
    cols: ['category'],
    rows: ['competency', 'department'],
    hiddenFromDragDrop: PMDashboardConstants.HIDDEN_COLUMNS_FROM_DRAG_DROP,
    rendererOptions: { plotly: { width: 600, height: 600 } },
    tableOptions: {
      height: '100vh',
      clickCallback: (e, value, filters, pivotData) => {
        // in some cases, some key values are coming as null in filters, specially when we click "sum" rows at the bottom, hence it is effecting filters
        const correctFilters = {};

        Object.keys(filters).forEach((key) => {
          if (filters[key]) {
            correctFilters[key] = filters[key];
          }
        });
        const resources = [];
        pivotData.forEachMatchingRecord(correctFilters, (record) => {
          resources.push(record);
        });
        setEmployeesList(resources);
      },
    },
    unusedOrientationCutoff: Infinity,
  });

  const getCategory = (selfEvaluationStatus) => {
    let category = '';

    if (selfEvaluationStatus === evaluationStatus.PENDING) {
      category = 'Self Evaluation Left';
    } else if (selfEvaluationStatus === evaluationStatus.APPROVED) {
      category = 'Self Evaluation Submitted';
    }

    return category;
  };

  useEffect(() => {
    const params = {
      pageNumber: 0,
      pageSize: 100000,
    };

    getPMCycleActions(params);
  }, []);

  useEffect(() => {
    if (evaluationReport.length > 0) {
      const normalizedData = evaluationReport.map((item) => {
        const { self } = item;
        return {
          ...item,
          category: getCategory(self.status),
        };
      });

      setSelfEvaluationSummary(normalizedData);
    } else {
      setSelfEvaluationSummary([]);
    }
  }, [evaluationReport]);

  const changeActionHandler = (id) => {
    getEvaluationReport(id);
  };

  const performanceCycleDropdown = () => {
    return <PMCycleDropdown pmCycles={pmCycles} onChange={changeActionHandler} />;
  };

  return (
    <div style={{ overflow: 'hidden', overflowY: 'auto' }} className="mt-20">
      <div className="mb-20">{performanceCycleDropdown()}</div>
      <PivotTable
        data={selfEvaluationSummary}
        renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
        {...pivotTable}
        onChange={(s) => setPivotTable(s)}
      />
      <div style={{ marginTop: '30px', overflowY: 'scroll' }}>
        <EmployeesList
          employees={employeesList}
          customizedColumns={getCustomizedColumns(baseUrlForEvaluation)}
          exportFileName={`Self Evaluation Status - ${moment().format(DATE_FORMAT)}`}
          divisions={divisions}
          departments={departments}
          competencies={competencies}
          businessUnits={businessUnits}
        />
      </div>
    </div>
  );
};

SelfEvaluation.propTypes = {
  evaluationReport: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      taleoId: PropTypes.string.isRequired,
      division: PropTypes.string.isRequired,
      competency: PropTypes.string.isRequired,
      department: PropTypes.string.isRequired,
      employeeId: PropTypes.number.isRequired,
      designation: PropTypes.string.isRequired,
      businessUnit: PropTypes.string.isRequired,
      reviewManagerName: PropTypes.string.isRequired,
      self: PropTypes.shape({
        status: PropTypes.string.isRequired,
        rating: PropTypes.number,
      }),
      manager: PropTypes.shape({
        status: PropTypes.string.isRequired,
        rating: PropTypes.number,
      }),
    }),
  ).isRequired,
  getEvaluationReport: PropTypes.func.isRequired,
};

export default SelfEvaluation;
