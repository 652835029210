import {
  EnhancedButton,
  EnhancedCol,
  EnhancedRow,
  EnhancedCard,
  EnhancedBadge,
  EnhancedTabs,
  EnhancedTooltip,
  EnhancedDatePicker,
} from 'components/shared/antd';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { dateFormat, employeeType, employeeTypeFiltered } from 'configs/constants';
import { NOT_AVAILABLE } from 'configs/additionalConstants';
import { DATE_FORMAT } from 'configs/employeeProfileConstants';
import LeaveDetailsModal from 'components/project/LeaveDetailsModal';
import leaveIndicatorIcon from 'images/ic_leave_indicator.svg';
import { CSVLink } from 'react-csv';
import PROJECT_ROUTES from 'constants/UrlConstants';
import {
  getFormattedDate,
  getFormattedDateWithHyphen,
  getParameterizedFormattedDate,
} from 'utils/DateUtils';
import { parse } from 'utils/stringUtils';
import { hasPermission } from 'utils/AccessUtils';
import './ResourceAvailability.css';
import AccessPermissions from '../../constants/AccessPermissions';

const { TabPane } = EnhancedTabs;

class ResourceAvailability extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterBy: 'bench',
      sortedInfo: null,
      selectedDate: '',
      leaveDetailsModalVisible: false,
      resourceAvailability: props.resourceAvailability || [],
      leaveDetailsEmployeeID: null,
      types: [...Object.values(employeeTypeFiltered), NOT_AVAILABLE.toLowerCase()].map(
        (item, index) => ({
          id: index,
          name: item,
        }),
      ),
      projects: [],
      resources: [],
      filteredRecords: [],
    };
  }

  componentDidMount() {
    this.props.getResourceAvailabilityActions();
    this.props.getEmployeeDepartmentsAction();
    this.props.getAllDesignations();
    this.props.getAllLocations();
    this.props.getEmployeeCompetenciesAction();
    this.props.getEmployeeDivisionsAction();
    this.props.getEmployeeBusinessUnitAction();
    this.props.getProjects();
  }

  componentWillReceiveProps(nextProps) {
    const { resourceAvailability, projects } = this.props;

    if (resourceAvailability !== nextProps.resourceAvailability) {
      this.setState({
        resourceAvailability: nextProps.resourceAvailability,
        resources: this.getResourcesAsPerType(this.state.filterBy, nextProps.resourceAvailability),
      });
    }

    if (projects !== nextProps.projects) {
      const activeProjects = nextProps.projects.filter((item) => item.status);
      const result = activeProjects.map((item) => ({
        id: item.id,
        name: item.name,
      }));
      this.setState({ projects: result });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filterBy !== this.state.filterBy) {
      const resources = this.getResourcesAsPerType(
        this.state.filterBy,
        this.state.resourceAvailability,
      );

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ resources });
    }
  }

  getResourcesAsPerType = (type, resourceAvailability) => {
    let resources = [];
    switch (type) {
      case employeeType.BENCH: {
        resources = this.getBenchResources(resourceAvailability);
        break;
      }
      case employeeType.INTERNAL: {
        resources = resourceAvailability.filter((resource) =>
          resource.type.includes(employeeType.INTERNAL),
        );
        break;
      }
      case employeeType.BACKUP: {
        resources = resourceAvailability.filter((resource) =>
          resource.type.includes(employeeType.BACKUP),
        );
        break;
      }
      case employeeType.BILLED: {
        resources = this.getBilledResources(resourceAvailability);
        break;
      }
      default:
        break;
    }

    return resources;
  };

  getColumnsInfo = () => {
    const columnNames = {
      firstColumn: {
        name: 'Name',
        keyToIdentify: 'name',
      },
      secondColumn: {
        name: 'Designation',
        keyToIdentify: 'designation',
      },
      thirdColumn: {
        name: 'Location',
        keyToIdentify: 'location',
      },
      experienceColumn: {
        name: 'Total Experience',
        keyToIdentify: 'totalExperience',
      },
      fourthColumn: {
        name: 'Project',
        keyToIdentify: 'project',
      },
      fifthColumn: {
        name: 'Allocated',
        keyToIdentify: 'allocated',
      },
      billedColumn: {
        name: 'Billing %',
        keyToIdentify: 'billed',
      },
      sixthColumn: {
        name: 'Type',
        keyToIdentify: 'type',
      },
      startingOn: {
        name: 'Starting on',
        keyToIdentify: 'startOn',
      },
      seventhColumn: {
        name: 'Releasing on',
        keyToIdentify: 'releasingOn',
      },
      eighthColumn: {
        name: 'Available',
        keyToIdentify: 'available',
      },
      ninthColumn: {
        name: 'Primary Skills',
        keyToIdentify: 'primarySkills',
      },
      ninthBColumn: {
        name: 'Secondary Skills',
        keyToIdentify: 'secondarySkills',
      },
      tenthColumn: {
        name: 'Department',
        keyToIdentify: 'department',
      },
      eleventhColumn: {
        name: 'Competency',
        keyToIdentify: 'competency',
      },
      twelfthColumn: {
        name: 'Division',
        keyToIdentify: 'division',
      },
      thirteenthColumn: {
        name: 'Business Unit',
        keyToIdentify: 'businessUnit',
      },
    };
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const {
      firstColumn,
      secondColumn,
      thirdColumn,
      experienceColumn,
      fourthColumn,
      fifthColumn,
      billedColumn,
      sixthColumn,
      startingOn,
      seventhColumn,
      eighthColumn,
      ninthColumn,
      ninthBColumn,
      tenthColumn,
      eleventhColumn,
      twelfthColumn,
      thirteenthColumn,
    } = columnNames;

    const columns = [
      {
        title: '',
        dataIndex: 'leaveIndicator',
        key: 'leaveIndicator',
        width: 50,
        render: this.renderLeaveIndicator,
      },
      {
        title: firstColumn.name,
        dataIndex: firstColumn.keyToIdentify,
        key: firstColumn.keyToIdentify,
        className: 'boldFont',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === firstColumn.keyToIdentify && sortedInfo.order,
        filterConfig: {
          type: 'search',
          key: firstColumn.keyToIdentify,
        },
        render: this.renderEmployeeFirstName,
      },
      {
        title: secondColumn.name,
        dataIndex: secondColumn.keyToIdentify,
        key: secondColumn.keyToIdentify,
        sorter: (a, b) => a.designation.localeCompare(b.designation),
        sortOrder: sortedInfo.columnKey === secondColumn.keyToIdentify && sortedInfo.order,
        filterConfig: {
          type: 'enhanced_filter',
          key: secondColumn.keyToIdentify,
          optionsConfig: {
            values: this.props.designations,
          },
        },
      },
      {
        title: thirteenthColumn.name,
        dataIndex: thirteenthColumn.keyToIdentify,
        key: thirteenthColumn.keyToIdentify,
        sorter: (a, b) => a.businessUnit.localeCompare(b.businessUnit),
        sortOrder: sortedInfo.columnKey === thirteenthColumn.keyToIdentify && sortedInfo.order,
        filterConfig: {
          type: 'enhanced_filter',
          key: thirteenthColumn.keyToIdentify,
          optionsConfig: {
            values: this.props.businessUnits,
          },
        },
      },
      {
        title: twelfthColumn.name,
        dataIndex: twelfthColumn.keyToIdentify,
        key: twelfthColumn.keyToIdentify,
        sorter: (a, b) => a.division.localeCompare(b.division),
        sortOrder: sortedInfo.columnKey === twelfthColumn.keyToIdentify && sortedInfo.order,
        filterConfig: {
          type: 'enhanced_filter',
          key: twelfthColumn.keyToIdentify,
          optionsConfig: {
            values: this.props.divisions,
          },
        },
      },
      {
        title: eleventhColumn.name,
        dataIndex: eleventhColumn.keyToIdentify,
        key: eleventhColumn.keyToIdentify,
        sorter: (a, b) => a.competency.localeCompare(b.competency),
        sortOrder: sortedInfo.columnKey === eleventhColumn.keyToIdentify && sortedInfo.order,
        filterConfig: {
          type: 'enhanced_filter',
          key: eleventhColumn.keyToIdentify,
          optionsConfig: {
            values: this.props.competencies,
          },
        },
      },
      {
        title: tenthColumn.name,
        dataIndex: tenthColumn.keyToIdentify,
        key: tenthColumn.keyToIdentify,
        sorter: (a, b) => a.department.localeCompare(b.department),
        sortOrder: sortedInfo.columnKey === tenthColumn.keyToIdentify && sortedInfo.order,
        filterConfig: {
          type: 'enhanced_filter',
          key: tenthColumn.keyToIdentify,
          optionsConfig: {
            values: this.props.departments,
          },
        },
      },
      {
        title: thirdColumn.name,
        dataIndex: thirdColumn.keyToIdentify,
        key: thirdColumn.keyToIdentify,
        sortOrder: sortedInfo.columnKey === thirdColumn.keyToIdentify && sortedInfo.order,
        sorter: (a, b) => {
          const first = a.location ? a.location : NOT_AVAILABLE;
          const second = b.location ? b.location : NOT_AVAILABLE;
          return first.localeCompare(second);
        },
        ellipsis: true,
        filterConfig: {
          type: 'enhanced_filter',
          key: thirdColumn.keyToIdentify,
          optionsConfig: {
            values: this.props.locations,
          },
        },
        render: this.renderLocation,
      },
      {
        title: experienceColumn.name,
        dataIndex: experienceColumn.keyToIdentify,
        key: experienceColumn.keyToIdentify,
        width: 190,
        sorter: (a, b) => b.experienceInYears - a.experienceInYears,
        sortOrder: sortedInfo.columnKey === 'totalExperience' && sortedInfo.order,
        filterConfig: {
          type: 'search',
          key: experienceColumn.keyToIdentify,
        },
        render: this.displayTotalExperience,
      },
      {
        title: fourthColumn.name,
        dataIndex: fourthColumn.keyToIdentify,
        key: fourthColumn.keyToIdentify,
        render: this.displayProjects,
        filterConfig: {
          type: 'enhanced_filter',
          key: fourthColumn.keyToIdentify,
          optionsConfig: {
            values: this.state.projects,
          },
        },
      },
      {
        title: fifthColumn.name,
        dataIndex: fifthColumn.keyToIdentify,
        key: fifthColumn.keyToIdentify,
        render: this.displayAllocatedResources,
        width: 100,
      },
      {
        title: billedColumn.name,
        dataIndex: billedColumn.keyToIdentify,
        key: billedColumn.keyToIdentify,
        render: this.displayBilledResources,
        width: 100,
      },
      {
        title: sixthColumn.name,
        dataIndex: sixthColumn.keyToIdentify,
        key: sixthColumn.keyToIdentify,
        render: this.displayProjects,
        filterConfig: {
          type: 'enhanced_filter',
          key: sixthColumn.keyToIdentify,
          optionsConfig: {
            values: this.state.types,
          },
        },
      },
      {
        title: startingOn.name,
        dataIndex: startingOn.keyToIdentify,
        key: startingOn.keyToIdentify,
        render: this.displayReleasingOn,
      },
      {
        title: seventhColumn.name,
        dataIndex: seventhColumn.keyToIdentify,
        key: seventhColumn.keyToIdentify,
        render: this.displayReleasingOn,
      },
      {
        title: eighthColumn.name,
        dataIndex: eighthColumn.keyToIdentify,
        key: eighthColumn.keyToIdentify,
        sorter: (a, b) => {
          const first = a.available[this.state.filterBy];
          const second = b.available[this.state.filterBy];
          if (first < second) return -1;
          else if (first > second) return 1;
          return 0;
        },
        sortOrder: sortedInfo.columnKey === eighthColumn.keyToIdentify && sortedInfo.order,
        render: this.displayAvailableResources,
        className: 'boldFont',
        width: 100,
      },
      {
        title: ninthColumn.name,
        dataIndex: ninthColumn.keyToIdentify,
        key: ninthColumn.keyToIdentify,
        render: this.displayPrimarySkills,
        filterConfig: {
          type: 'search',
          key: ninthColumn.keyToIdentify,
          customFunc: (item, filterValue) =>
            item[ninthColumn.keyToIdentify].some(
              (skill) => skill && skill.name.toLowerCase().includes(filterValue),
            ),
        },
      },
      {
        title: ninthBColumn.name,
        dataIndex: ninthBColumn.keyToIdentify,
        key: ninthBColumn.keyToIdentify,
        render: this.displaySecondarySkills,
        filterConfig: {
          type: 'search',
          key: ninthBColumn.keyToIdentify,
          customFunc: (item, filterValue) =>
            item[ninthBColumn.keyToIdentify].some(
              (skill) => skill && skill.name.toLowerCase().includes(filterValue),
            ),
        },
      },
    ];
    return columns;
  };

  getProjectStartAndEndDate = (startOn, releasingOn, dateValue) => {
    const startDate = getFormattedDate(startOn);
    // Set end date day plus 1 of today date if start date of project is not set.
    const dayPlusOne = new Date(new Date(dateValue).getDate() + 1);
    const releaseValue = releasingOn !== '' ? releasingOn : dayPlusOne;
    const releaseDate = getFormattedDate(releaseValue);
    return { startDate, releaseDate };
  };

  getBenchResources = (resourceAvailability = []) => {
    const { selectedDate } = this.state;
    const dateValue = getParameterizedFormattedDate(selectedDate, new Date());
    const data = resourceAvailability.filter((resource) => {
      const { length } = resource.startOn;
      const isBilled = [];
      for (let i = 0; i < length; i += 1) {
        const projectAllocationDates = this.getProjectStartAndEndDate(
          resource.startOn[i],
          resource.releasingOn[i],
          dateValue,
        );
        const isTypeAdditional = resource.type[i] !== employeeType.ADDITIONAL;
        const isAllocated = this.validateProjectAllocation(
          projectAllocationDates.startDate,
          projectAllocationDates.releaseDate,
          dateValue,
        );
        const fullyAllocated = !(isAllocated && isTypeAdditional);
        const partiallyAllocated = isAllocated && resource.allocated[i] < 100 && isTypeAdditional;
        const isFullyAdditional = isAllocated && !isTypeAdditional;
        const isPresent = fullyAllocated || partiallyAllocated || isFullyAdditional;
        isBilled.push(!isPresent);
      }
      const isBillableBench =
        resource.available.bench > 0 && resource.resourceType === employeeType.BILLABLE;
      const isBilledOnSelectedDate = isBilled.some((e) => e === false);
      return isBillableBench && isBilledOnSelectedDate;
    });
    return data;
  };

  getBilledResources = (resourceAvailability) => {
    const { selectedDate } = this.state;
    const dateValue = getParameterizedFormattedDate(selectedDate, new Date());
    const data = resourceAvailability.filter((resource) => {
      const { length } = resource.startOn;
      const billedArray = [];
      for (let i = 0; i < length; i += 1) {
        const projectAllocationDates = this.getProjectStartAndEndDate(
          resource.startOn[i],
          resource.releasingOn[i],
        );
        const isAllocated = this.validateProjectAllocation(
          projectAllocationDates.startDate,
          projectAllocationDates.releaseDate,
          dateValue,
        );
        const isBilledResource = resource.type[i] === employeeType.BILLED;
        billedArray.push(isAllocated && isBilledResource);
      }
      const isBilledOnSelectedDate = billedArray.some((e) => e === true);
      return isBilledOnSelectedDate;
    });
    return data;
  };

  /**
   * Returns array of arrays containing resources allocation data
   * @param {Array} resources Array of resources
   * @returns {Array<Array>} An array of arrays containing resource allocation data
   */
  getCSVData = (resources) => {
    const columns = [
      'Name',
      'Primary Skills',
      'Secondary Skills',
      'Designation',
      'Department',
      'Location',
      'Total Experience',
      'Project',
      'Billing (%)',
      'Allocated (%)',
      'Available (%)',
      'Releasing On',
      'Starting On',
      'Type',
      'Competency',
      'Division',
      'Business Unit',
    ];
    const rows = this.getCSVRows(resources);
    return [columns, ...rows];
  };

  /**
   * Maps resource allocation data
   * @param {Array} resources Array of resources containing allocation data
   * @returns {Array} Array of rows containing allocation data of employees
   */
  getCSVRows = (resources) => {
    const rows = [];
    resources.forEach((resource) => {
      const resourceData = [
        resource.name,
        resource.primarySkills.map((skill) => skill.name),
        resource.secondarySkills.map((skill) => skill.name),
        resource.designation,
        resource.department,
        resource.location,
        resource.totalExperience,
      ];
      resource.allocated.forEach((allocationValue, index) => {
        rows.push([
          ...resourceData,
          resource.project[index],
          resource.billed[index],
          resource.allocated[index],
          resource.available.bench,
          getFormattedDateWithHyphen(resource.releasingOn[index]),
          getFormattedDateWithHyphen(resource.startOn[index]),
          resource.type[index],
          resource.competency,
          resource.division,
          resource.businessUnit,
        ]);
      });
    });
    return rows;
  };

  displayProjects = (array) => (
    <div>
      {array.map((value) => (value !== null ? <div> {value} </div> : <div> {NOT_AVAILABLE}</div>))}
    </div>
  );

  displayTotalExperience = (name) => <div>{name}</div>;

  displayAllocatedResources = (array) => (
    <div>
      {array.map((value) => (
        <div> {`${value}%`} </div>
      ))}
    </div>
  );

  displayBilledResources = (array) => (
    <div>{array.length > 0 ? array.map((value) => <div> {`${value}%`} </div>) : '0%'}</div>
  );

  displayAvailableResources = (availableObject) => (
    <div>
      <div> {`${availableObject[this.state.filterBy]}%`} </div>
    </div>
  );

  displayPrimarySkills = (multipleSkills) => {
    // const primaryOutline = '0 0 0 2px #AAA59D inset';
    // const secondaryOutline = '0 0 0 2px #DEDFD7 inset';

    const badgeStyle = {
      backgroundColor: '#fff',
      color: '#999',
      minWidth: 30,
      marginRight: 5,
      marginTop: 5,
      borderRadius: '15%',
      boxShadow: '0 0 0 2px #DEDFD7 inset',
    };

    const skills = multipleSkills.filter((skill) => skill && skill.name);

    if (skills.length === 0) {
      return 'N/A';
    }

    return skills.map((skill) => (
      <EnhancedBadge
        style={{
          ...badgeStyle,
        }}
        count={skill !== undefined && skill !== null ? skill.name : ''}
      />
    ));
  };

  displaySecondarySkills = (multipleSkills) => {
    // const primaryOutline = '0 0 0 2px #AAA59D inset';
    // const secondaryOutline = '0 0 0 2px #DEDFD7 inset';

    const badgeStyle = {
      backgroundColor: '#fff',
      color: '#999',
      minWidth: 30,
      marginRight: 5,
      marginTop: 5,
      borderRadius: '15%',
      boxShadow: '0 0 0 2px #DEDFD7 inset',
    };

    const skills = multipleSkills.filter((skill) => skill && skill.name);

    if (skills.length === 0) {
      return 'N/A';
    }

    return skills.map((skill) => (
      <EnhancedBadge
        style={{
          ...badgeStyle,
        }}
        count={skill !== undefined && skill !== null ? skill.name : ''}
      />
    ));
  };

  dateChange = (date, dateString) => {
    this.setState({ selectedDate: date });
    this.props.getResourceAvailabilityActions(dateString);
  };

  handleLeaveDetails = (employeeId) => {
    this.setState({
      leaveDetailsEmployeeID: employeeId,
      leaveDetailsModalVisible: true,
    });
  };

  handleLeaveDetailsClose = (close) => {
    this.setState({
      leaveDetailsModalVisible: close,
    });
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  };

  validateProjectAllocation = (startDate, releaseDate, date) =>
    startDate <= date && date <= releaseDate;

  displayReleasingOn = (array) => (
    <div>
      {array.map((value) =>
        value ? (
          <div> {moment(value).format(dateFormat.DDMMMYYYYwithSpace)} </div>
        ) : (
          <div> {NOT_AVAILABLE}</div>
        ),
      )}
    </div>
  );

  handleProjectTypechange = (value) => {
    const filterBy = value;
    this.setState({ filterBy });
  };
  totalAvailability = (resourceAvailability) => {
    let result = 0;
    for (let iterator = 0; iterator < resourceAvailability.length; iterator += 1) {
      const { available } = resourceAvailability[iterator];
      if (available.bench && this.state.filterBy === 'bench') {
        result += available[this.state.filterBy];
      } else {
        result += available.fte[this.state.filterBy];
      }
    }
    return result / 100;
  };

  renderLeaveIndicator = (leaveIndicator, data) => (
    /* eslint-disable */
    <EnhancedTooltip title="Leave Indicator">
      <a onClick={() => this.handleLeaveDetails(data.id)}>
        <img src={leaveIndicatorIcon} alt="leave indicator" />
      </a>
    </EnhancedTooltip>
    /* eslint-enable */
  );

  renderLocation = (name, data) => <span>{data.location ? data.location : NOT_AVAILABLE}</span>;

  renderEmployeeFirstName = (name, data) => {
    let element = null;
    const { user } = this.props;
    const {
      Employee: { View },
    } = AccessPermissions;
    const { resignationDate, isActive, leavingDate } = data;
    const color = moment() >= moment(resignationDate) && isActive ? 'red' : '#5A5A5A';

    const isAuthorized = hasPermission(user.permissions, [View]);

    const link = parse(PROJECT_ROUTES.EMPLOYEE_DETAIL_ROUTE, {
      status: PROJECT_ROUTES.BILLABLE_RESOURCE,
      id: data.id,
    });
    const nameElement = <b style={{ color }}>{name}</b>;
    const linkedName = <Link to={link}>{nameElement}</Link>;
    const displayType = isAuthorized ? linkedName : nameElement;

    element =
      moment() >= moment(resignationDate) && isActive ? (
        <EnhancedTooltip
          title={`On Notice Period. ${
            leavingDate ? `Last Working Day: ${moment(leavingDate).format(DATE_FORMAT)}` : ''
          }`}
        >
          {displayType}
        </EnhancedTooltip>
      ) : (
        displayType
      );

    return element;
  };
  render() {
    const columns = this.getColumnsInfo();
    const { filterBy, resources, filteredRecords } = this.state;

    const totalAvailability = this.totalAvailability(filteredRecords);
    return (
      <div style={{ background: 'white' }}>
        <LeaveDetailsModal
          employeeId={this.state.leaveDetailsEmployeeID}
          leavesData={resources}
          onModalClose={this.handleLeaveDetailsClose}
          visible={this.state.leaveDetailsModalVisible}
          workingHolidays={this.props.workingHolidays}
        />
        <EnhancedCard style={{ paddingBottom: 0, borderBottom: '3px solid #eee' }} bordered={false}>
          <h2 className="heading-res">Billable Resources</h2>
        </EnhancedCard>
        <EnhancedTabs defaultActiveKey="bench" onChange={this.handleProjectTypechange}>
          <TabPane tab="Bench" key="bench" style={{ backgroundColor: '#f0f2f5' }}>
            <p />
          </TabPane>
          <TabPane tab="Internal" key="internal" style={{ backgroundColor: '#f0f2f5' }}>
            <p />
          </TabPane>
          <TabPane tab="Backup" key="backup" style={{ backgroundColor: '#f0f2f5' }}>
            <p />
          </TabPane>
          <TabPane tab="Billed" key="billed" style={{ backgroundColor: '#f0f2f5' }}>
            <p />
          </TabPane>
        </EnhancedTabs>
        <EnhancedCard style={{ marginTop: '10px' }} bordered={false}>
          <EnhancedRow>
            <EnhancedCol lg={12}>
              <h3 className="empAvailable">
                Count:
                <span className="totalEmployeesNumber">{`${filteredRecords.length}`}</span>
                <span style={{ marginLeft: '10%' }}>F.T.E:</span>
                <span className="totalEmployeesNumber">{`${totalAvailability}`}</span>
              </h3>
            </EnhancedCol>
            <EnhancedCol lg={12} style={{ textAlign: 'right' }} className="clearFilter">
              {resources.length ? (
                <CSVLink
                  filename={`Billable_${filterBy}_${new Date()
                    .toJSON()
                    .slice(0, 10)
                    .replace(/-/g, '/')}.csv`}
                  data={this.getCSVData(filteredRecords)}
                >
                  <EnhancedButton icon="file-excel" style={{ marginRight: '15px' }}>
                    Export
                  </EnhancedButton>
                </CSVLink>
              ) : (
                ''
              )}

              <EnhancedDatePicker
                style={{ width: 'auto' }}
                onChange={this.dateChange}
                format={dateFormat.DDMMMYYYYwithSpace}
              />
            </EnhancedCol>
          </EnhancedRow>
          <div>
            <EnhancedClientSideTable
              rowKey={(record) => record.id}
              columns={columns}
              rowClassName={(record) => {
                let classNames = '';
                if (!('leavesDetails' in record) || record.leavesDetails.length === 0) {
                  classNames += 'hide-li-icon ';
                }
                if (record.notes === '') {
                  classNames += 'hide-icon';
                }

                if (
                  record.resignationDate &&
                  moment() >= moment(record.resignationDate) &&
                  record.isActive
                ) {
                  classNames += ' on-notice ';
                }
                return classNames;
              }}
              expandedRowRender={(record) => {
                let data = '';
                if (record.notes !== '') {
                  data = record.notes;
                } else {
                  return false;
                }
                const commentSection = data.map((val) => (
                  <div key={val.comment}>
                    <p style={{ margin: 0, marginLeft: '5%' }}>
                      {val.comment}
                      <b style={{ float: 'right', marginRight: '5%' }}>
                        {val.projectName}|{' '}
                        {moment.utc(val.commentDate).format(dateFormat.DDMMMYYYYwithSpace)}
                      </b>
                    </p>
                    <br />
                  </div>
                ));
                return commentSection;
              }}
              data={resources || []}
              onChange={this.handleChange}
              scroll={{ x: 2200, y: false }}
              shouldResetFiltersOnDataChange={false}
              setFilteredRecords={(records) => {
                this.setState({
                  filteredRecords: records,
                });
              }}
              rowExpandable={(record) => record.notes}
            />
          </div>
        </EnhancedCard>
      </div>
    );
  }
}

export default ResourceAvailability;
