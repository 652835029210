import {
  GET_RESOURCE_AVAILABILITY_INIT,
  GET_RESOURCE_AVAILABILITY_SUCCESS,
  GET_RESOURCE_AVAILABILITY_FAILURE,
  GET_EMPLOYEE_DEPARTMENT_SUCCESS,
  GET_EMPLOYEE_DEPARTMENT_FAILURE,
} from 'constants/ResourceAvailabilityTypes';

const initialState = {
  resourceAvailability: [],
  departments: [],
  isLoading: false,
};

export default function resourceAvailabilityReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RESOURCE_AVAILABILITY_INIT: {
      return Object.assign({}, state, {
        resourceAvailability: [],
        isLoading: true,
      });
    }
    case GET_RESOURCE_AVAILABILITY_SUCCESS: {
      return Object.assign({}, state, {
        resourceAvailability: action.data,
        isLoading: false,
      });
    }
    case GET_RESOURCE_AVAILABILITY_FAILURE: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }
    case GET_EMPLOYEE_DEPARTMENT_SUCCESS: {
      return Object.assign({}, state, {
        departments: action.data,
        isLoading: false,
      });
    }
    case GET_EMPLOYEE_DEPARTMENT_FAILURE: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }
    default:
      return state;
  }
}
