import { connect } from 'react-redux';
import EmployeeRatingList from 'components/rating/EmployeeRatingList';
import {
  getEmployeeRatingListAction,
  downloadEmployeeRatingsAction,
} from 'store/actions/EmployeeRatingActions';
import {
  getPerformanceCRMCCyclesAction,
  getPerformanceCyclesAction,
} from 'store/actions/PerformanceCycleActions';

function mapStateToProps(state) {
  const { auth, employeeRatingReducer, performanceCycleReducer } = state;
  return {
    userId: auth.user && auth.user.id,
    isFunctionalHead: auth.user && auth.user.isFunctionalHead,
    isCrmc: auth.user && auth.user.isCRMC1,
    isPM: auth.user && auth.user.isPM,
    employeeRatingList: employeeRatingReducer.employeeRatingList,
    employeeRatings: employeeRatingReducer.employeeRatings,
    performanceCycles: performanceCycleReducer.performanceCycle,
    isLoading: employeeRatingReducer.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    downloadEmployeeRatingsAction: (params) => dispatch(downloadEmployeeRatingsAction(params)),
    getEmployeeRatingListAction: (performanceCycleId) =>
      dispatch(getEmployeeRatingListAction(performanceCycleId)),
    getPerformanceCyclesAction: () => dispatch(getPerformanceCyclesAction()),
    getPerformanceCRMCCyclesAction: (employeeId) =>
      dispatch(getPerformanceCRMCCyclesAction(employeeId)),
  };
}
const EmployeeRatingListContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeRatingList);

export default EmployeeRatingListContainer;
