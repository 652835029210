import {
  ADD_GOAL_INIT,
  ADD_GOAL_SUCCESS,
  ADD_GOAL_FAILURE,
  GET_GOAL_INIT,
  GET_GOAL_SUCCESS,
  GET_GOAL_FAILURE,
  DELETE_GOAL_INIT,
  DELETE_GOAL_SUCCESS,
  DELETE_GOAL_FAILURE,
  GET_GOAL_DETAILS_INIT,
  GET_GOAL_DETAILS_SUCCESS,
  GET_GOAL_DETAILS_FAILURE,
  UPDATE_GOAL_INIT,
  UPDATE_GOAL_SUCCESS,
  UPDATE_GOAL_FAILURE,
  SUBMIT_MY_GOAL_FOR_APPROVAL_INIT,
  SUBMIT_MY_GOAL_FOR_APPROVAL_SUCCESS,
  SUBMIT_MY_GOAL_FOR_APPROVAL_FAILURE,
} from 'constants/GoalTypes';

const initialState = {
  isLoading: false,
  goals: [],
  goalDetails: {},
  goalsReport: [],
};

export default function goalReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_GOAL_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADD_GOAL_SUCCESS: {
      const result = {
        ...state,
        isLoading: false,
        goals: action.data,
      };
      return result;
    }
    case ADD_GOAL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    }
    case GET_GOAL_INIT: {
      return {
        ...state,
        goals: [],
        isLoading: true,
      };
    }
    case GET_GOAL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        goals: action.data,
      };
    }
    case GET_GOAL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    }
    case DELETE_GOAL_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DELETE_GOAL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        goalDetails: action.data,
      };
    }
    case DELETE_GOAL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    }
    case GET_GOAL_DETAILS_INIT:
    case UPDATE_GOAL_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_GOAL_DETAILS_SUCCESS:
    case UPDATE_GOAL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        goalDetails: action.data,
      };
    }
    case GET_GOAL_DETAILS_FAILURE:
    case UPDATE_GOAL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    }
    case SUBMIT_MY_GOAL_FOR_APPROVAL_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUBMIT_MY_GOAL_FOR_APPROVAL_SUCCESS: {
      const result = {
        ...state,
        isLoading: false,
      };
      return result;
    }
    case SUBMIT_MY_GOAL_FOR_APPROVAL_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
