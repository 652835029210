/*eslint-disable */
import { EnhancedIcon, EnhancedRow, EnhancedAvatar, EnhancedModal } from 'components/shared/antd';
import React from 'react';
import { getInitials } from 'utils/stringUtils';
import Spinner from 'components/shared/Spinner';

class EmployeeProfileHeader extends React.Component {
  constructor(props) {
    super(props);
    this.documentRef = React.createRef();
    this.state = {
      showPreview: false,
    };
  }

  getPictureViewerModal = () => (
    <EnhancedModal
      visible={this.state.showPreview}
      footer={null}
      bodyStyle={{
        backgroundColor: '#f0f2f5',
        padding: '3vw',
      }}
      onCancel={this.closePreview}
    >
      <img
        src={`${this.props.profileHeader.imageUrl}`}
        height="100%"
        width="100%"
        alt="preview img"
      />
    </EnhancedModal>
  );

  viewPicture = () => {
    this.setState({ showPreview: true });
  };

  closePreview = () => {
    this.setState({ showPreview: false });
  };

  handleFileuploadClick = () => {
    this.documentRef.current.click();
  };

  hanldeFileLoad = (evt) => {
    const base64 = evt.target.result;
    const { employeeId } = this.props;
    this.props.updateEmployeePicture(employeeId, base64);
  };

  handleFileUpload = (evt) => {
    const file = evt.target.files[0];
    const reader = new FileReader();
    reader.onload = this.hanldeFileLoad;
    reader.fileName = file.name;
    reader.readAsDataURL(file);
  };

  render() {
    const { profileHeader } = this.props;
    const canUpdate = true;

    return (
      <EnhancedRow className="emp-details">
        <div className="emp-photo inline-block">
          {profileHeader ? (
            profileHeader.isLoading ? (
              <div className="picture-loader">
                <Spinner tip="loading" />
              </div>
            ) : profileHeader.imageUrl ? (
              <img
                src={`${profileHeader.imageUrl}`}
                className="profile-photo-emp"
                alt="Profile pic"
              />
            ) : (
              <EnhancedAvatar className="initials-avatar" size="large">
                {getInitials(profileHeader.fullName)}
              </EnhancedAvatar>
            )
          ) : (
            ''
          )}
          {profileHeader && !profileHeader.isLoading && (canUpdate || profileHeader.imageUrl) && (
            <div className="pp-edit">
              <ul>
                {profileHeader.imageUrl ? (
                  <li>
                    <span className="icon-wrapper" onClick={this.viewPicture}>
                      <EnhancedIcon style={{ fontSize: 24 }} type="eye" />
                      <span>View</span>
                    </span>
                  </li>
                ) : (
                  ''
                )}
                {canUpdate && (
                  <li>
                    <span className="icon-wrapper" onClick={this.handleFileuploadClick}>
                      <EnhancedIcon style={{ fontSize: 24 }} type="camera" />
                      <span>Upload</span>
                    </span>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
        <div className="emp-designation inline-block">
          <div className="profile-name">{profileHeader && profileHeader.fullName}</div>
        </div>
        {profileHeader && profileHeader.imageUrl ? this.getPictureViewerModal() : ''}
        <input
          type="file"
          id="element"
          accept="image/*"
          ref={this.documentRef}
          onChange={this.handleFileUpload}
          style={{ display: 'none' }}
        />
      </EnhancedRow>
    );
  }
}

export default EmployeeProfileHeader;
