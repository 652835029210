import { EnhancedRow, EnhancedIcon, EnhancedList } from 'components/shared/antd';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import defaultImageURL from 'images/default-placeholder.png';
import './RelatedNews.css';

class RelatedNewsPlaceholder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { relatedNews } = this.props;
    const styleForNewsTN = {
      width: '142px',
      height: '80px',
      marginBottom: '10%',
    };
    const styleForNewsText = {
      marginLeft: '5px',
    };
    const styleForNewsDate = {
      marginLeft: '5px',
    };

    return (
      <Fragment>
        <EnhancedRow className="related-news-container">
          <EnhancedList
            className="list"
            dataSource={relatedNews}
            locale={{ emptyText: 'No Related News in this category' }}
            renderItem={(item) => (
              <Fragment>
                <div className="description">
                  <Link to={`/news/${item.id}`}>
                    <img
                      src={item.coverImage || defaultImageURL}
                      style={styleForNewsTN}
                      alt="Feature Images"
                    />
                  </Link>
                  <h4 className="ant-list-item-meta-title">
                    <p style={styleForNewsText} className="list-element-title">
                      {item.title.length > 35 ? `${item.title.substr(0, 35)}...` : item.title}
                    </p>
                    <div>
                      <p style={styleForNewsDate}>
                        <EnhancedIcon type="calendar" style={{ marginRight: '2%' }} />
                        {item && item.date ? item.date : 'N/A'}
                      </p>
                    </div>
                  </h4>
                </div>
              </Fragment>
            )}
          />
        </EnhancedRow>
      </Fragment>
    );
  }
}

export default RelatedNewsPlaceholder;
