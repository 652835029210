import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedIcon,
  EnhancedDivider,
  EnhancedAlert,
  EnhancedSelect,
  EnhancedInput,
  EnhancedForm,
} from 'components/shared/antd';
import React, { Component } from 'react';
import moment from 'moment';
import { removeItemAtIndex, editItemAtIndex } from 'utils/arrayUtils';
import { convertToNumber } from 'utils/conversionUtils';
import { dateFormat } from 'configs/constants';

import { normalizeCreateWorkLogPayload } from 'normalizers/workLogNormalizers';

const { Option } = EnhancedSelect;
const FormItem = EnhancedForm.Item;

class LogWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workLogFields: [],
      errorText: '',
      successText: '',
    };
    this.selectedDate = moment(props.date).format(dateFormat.YYYYMMDDwithHyphen);
  }

  componentDidMount() {
    this.props.getWorkLogProjects();
    this.addWorkLogField();
    const { editData } = this.props;
    if (editData) {
      this.setWorkLogFieldsForEditing(editData);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.date && nextProps.date !== this.props.date) {
      this.selectedDate = moment(nextProps.date).format(dateFormat.YYYYMMDDwithHyphen);
      this.setState({
        workLogFields: [],
        errorText: '',
        successText: '',
      });
    }
    if (!nextProps.isLoading) {
      if (this.props.isLoading) {
        this.props.closeModal();
      } else if (nextProps.editData) {
        const { editData } = nextProps;
        this.setWorkLogFieldsForEditing(editData);
      } else {
        this.setState(
          {
            workLogFields: [],
          },
          () => this.addWorkLogField(),
        );
      }
    }

    this.setState({
      successText: nextProps.successMessage || '',
      errorText: nextProps.errorMessage || '',
    });
  }

  onCloseError = () => {
    this.setState({ errorText: '' });
  };

  onCloseSuccess = () => {
    this.setState({ successText: '' });
  };

  setWorkLogFieldsForEditing = (editData) => {
    const workLogFields = editData.map((details) => this.getWorkLogObject(details));
    this.setState({ workLogFields });
  };

  getWorkLogObject = (workLogObj, lastProject = '', remainingAllocation = 100) => {
    if (workLogObj) {
      return {
        projectId: workLogObj.projectId || '',
        allocation: workLogObj.allocation || workLogObj.hours || workLogObj.percentage || '',
      };
    }
    return {
      projectId: lastProject,
      allocation: remainingAllocation,
    };
  };

  addWorkLogField = () => {
    let workLog = [];
    const { mostEngagingProjects } = this.props;
    if (!this.state.workLogFields.length) {
      const projectId = mostEngagingProjects.length ? mostEngagingProjects[0] : '';
      workLog.push(this.getWorkLogObject(null, projectId, 100));
    } else {
      const remainingAllocation =
        100 -
        this.state.workLogFields
          .map((x) => x.allocation)
          .reduce((accumulator, currentValue) => +accumulator + +currentValue);
      const currentlyAddedProjects = this.state.workLogFields.map((x) => x.projectId);
      const projectId =
        mostEngagingProjects.filter((x) => !currentlyAddedProjects.includes(x))[0] || '';
      workLog = this.state.workLogFields.concat([
        this.getWorkLogObject(null, projectId, remainingAllocation),
      ]);
    }
    this.setState({
      workLogFields: workLog,
    });
  };

  removeLogField = (e) => {
    e.preventDefault();
    const index = e.target.attributes.getNamedItem('data-index').value;
    if (this.state.workLogFields.length > 1) {
      this.setState({
        workLogFields: removeItemAtIndex(this.state.workLogFields, index),
      });
    }
  };

  generateProjectOptions = (ref) =>
    this.props.projects.map((project) =>
      project.name &&
      project.name.toLowerCase() !== 'admin' &&
      project.name.toLowerCase().replace(' ', '') !== 'services/management' ? (
        <Option value={project.id} key={project.id} ref={ref}>
          {project.name}
        </Option>
      ) : null,
    );

  handleProjectChange = (value, event) => {
    const index = event.ref;
    const params = {};
    params.projectId = value;
    params.allocation = this.state.workLogFields[index].allocation;
    this.editWorkLogField(params, index);
  };

  handleAllocationChange = (value) => {
    value.preventDefault();
    const index = value.target.attributes.getNamedItem('data-index').value;
    const params = {};
    params.projectId = this.state.workLogFields[index].projectId;
    params.allocation = value.target.value;
    this.editWorkLogField(params, index);
  };

  editWorkLogField = (params, index) => {
    const updatedParams = Object.assign({}, this.state.workLogFields[index], params);
    this.setState({
      workLogFields: editItemAtIndex(this.state.workLogFields, updatedParams, index),
    });
  };

  checkTotalAllocation = () => {
    let total = 0;
    this.state.workLogFields.forEach((log) => {
      total += convertToNumber(log.allocation, true);
    });
    return total === 100;
  };

  validateFields = () => {
    // validate fields
    if (!this.selectedDate) {
      this.setState({ errorText: 'Please select a date', successText: '' });
      return;
    }
    const { workLogFields } = this.state;
    if (this.checkForBlankFields(workLogFields)) {
      this.setState({
        errorText: 'Please fill in all fields',
        successText: '',
      });
      return;
    }
    if (!this.checkTotalAllocation()) {
      this.setState({
        errorText: 'Allocation should be 100%',
        successText: '',
      });
      return;
    }
    const projects = workLogFields.map((log) => log.projectId);
    const isDuplicate = projects.some((log, index) => projects.indexOf(log) !== index);
    if (isDuplicate) {
      this.setState({
        errorText: 'Duplicate projects selected',
        successText: '',
      });
      return;
    }
    const normalizedWorkLogPayload = normalizeCreateWorkLogPayload(
      workLogFields,
      this.selectedDate,
    );
    if (this.props.mood || (this.props.editData && this.props.editData.length)) {
      const dataToInsert = {
        mood: this.props.mood,
        logs: normalizedWorkLogPayload.worklogs[0].logs,
        date: this.selectedDate,
      };

      this.props.updateEmployeeWorkLog(dataToInsert, this.props.user.id, this.props.selectedWorkId);
    } else {
      this.props.createWorkLog(normalizedWorkLogPayload, this.props.user.id);
    }
  };

  checkForBlankFields = (payload) => {
    let error = false;
    for (let i = 0; i < payload.length; i += 1) {
      if (!payload[i].projectId) {
        error = true;
        break;
      }
      if (!payload[i].allocation) {
        error = true;
        break;
      }
    }
    return error;
  };

  generateFields = () => {
    const formItemLayout = {
      labelCol: {
        xs: { span: 18, offset: 0 },
        sm: { span: 4, offset: 0 },
      },
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 0 },
      },
    };
    const lastIndex = this.state.workLogFields.length;

    return this.state.workLogFields.map((field, index) => (
      <div key={field.projectId} style={{ paddingTop: '0.8rem' }}>
        <FormItem {...formItemLayout} label="Projects">
          <EnhancedSelect
            showSearch
            optionFilterProp="children"
            style={{ width: '100%' }}
            placeholder="Projects"
            data-index={index}
            ref={index}
            id="projectId"
            prefix={<EnhancedIcon type="code-o" />}
            value={field.projectId}
            onChange={this.handleProjectChange}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {this.generateProjectOptions(index)}
          </EnhancedSelect>
        </FormItem>
        <FormItem {...formItemLayout} label="Allocation">
          <EnhancedInput
            placeholder="Log Work"
            id="allocation"
            data-index={index}
            onChange={this.handleAllocationChange}
            addonBefore="%"
            value={field.allocation}
          />
          {this.state.workLogFields.length !== 1 ? (
            <EnhancedRow>
              <EnhancedCol span={24}>
                <EnhancedButton
                  disabled={false}
                  onClick={this.removeLogField}
                  data-index={index}
                  style={{
                    borderColor: '#fff',
                    position: 'absolute',
                    top: -135,
                    right: -15,
                  }}
                >
                  <EnhancedIcon type="close" />
                </EnhancedButton>
              </EnhancedCol>
            </EnhancedRow>
          ) : null}
        </FormItem>
        {this.state.workLogFields.length !== 1 ? <EnhancedDivider /> : ''}
        {index === lastIndex - 1 ? (
          <EnhancedCol span={24}>
            <FormItem>
              <EnhancedButton
                onClick={this.addWorkLogField}
                disabled={false}
                style={{ float: 'right', color: '#319cff', borderColor: '#ffffff' }}
                data-index={index}
              >
                + Add allocation
              </EnhancedButton>
            </FormItem>
          </EnhancedCol>
        ) : null}
      </div>
    ));
  };

  render() {
    const { successMessage, errorMessage, isLoading } = this.props;
    const { successText, errorText } = this.state;

    const success = successMessage || successText;
    const error = errorMessage || errorText;

    return (
      <div>
        {error ? (
          <EnhancedAlert
            message="Error"
            description={error}
            type="error"
            closable
            onClose={this.onCloseError}
          />
        ) : null}
        {success ? (
          <EnhancedAlert
            message="Successfully Updated"
            type="success"
            closable
            onClose={this.onCloseSuccess}
          />
        ) : null}
        <EnhancedRow type="flex">
          <EnhancedCol span={24}>
            <EnhancedForm layout="horizontal">{this.generateFields()}</EnhancedForm>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow type="flex" justify="end">
          <EnhancedDivider />
          <EnhancedButton type="primary" loading={isLoading} onClick={this.validateFields}>
            Save
          </EnhancedButton>
        </EnhancedRow>
      </div>
    );
  }
}

export default LogWork;
