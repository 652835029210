import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedIcon,
  EnhancedMenu,
} from 'components/shared/antd';
import React, { Component } from 'react';
import moment from 'moment';
import { GENESIS_DATE } from 'configs/constants';
import { CSVLink } from 'react-csv';
import { gaEvent } from 'sources/AnalyticsSource';
import { categories, events } from 'configs/gaConstants';
import { Desktop, Mobile } from 'components/Responsive';

const duration = {
  WEEK: 'week',
  MONTH: 'month',
};

class DateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().startOf(duration.MONTH),
      endDate: moment().endOf(duration.MONTH),
      offset: 0,
      resolution: duration.MONTH,
      disableLeftNav:
        (moment().startOf(duration.MONTH).isSameOrBefore(moment(GENESIS_DATE), 'day') &&
          'disabled') ||
        '',
      disableRightNav:
        (moment().endOf(duration.MONTH).isSameOrAfter(moment(), 'day') && 'disabled') || '',
    };
  }

  onResolutionChange = (e) => {
    if (this.state.resolution !== e.key) {
      const offset = 0;
      const resolution = e.key;
      const startDate = moment().add(offset, resolution).startOf(resolution);
      const endDate = moment().add(offset, resolution).endOf(resolution);
      this.setState({
        offset,
        startDate,
        endDate,
        resolution,
        disableLeftNav: (startDate.isSameOrBefore(moment(GENESIS_DATE), 'day') && 'disabled') || '',
        disableRightNav: (endDate.isSameOrAfter(moment(), 'day') && 'disabled') || '',
      });
      this.props.loadData(startDate, endDate);
    }
  };

  onNavLeft = () => {
    const offset = this.state.offset - 1;
    const { resolution } = this.state;
    const startDate = moment().add(offset, resolution).startOf(resolution);
    const endDate = moment().add(offset, resolution).endOf(resolution);
    gaEvent({ category: categories.PROJECTS, action: events.NAV_LEFT });
    this.setState({
      offset,
      startDate,
      endDate,
      disableLeftNav: (startDate.isSameOrBefore(moment(GENESIS_DATE), 'day') && 'disabled') || '',
      disableRightNav: (endDate.isSameOrAfter(moment(), 'day') && 'disabled') || '',
    });
    this.props.loadData(startDate, endDate);
  };

  onNavRight = () => {
    const offset = this.state.offset + 1;
    const { resolution } = this.state;
    const startDate = moment().add(offset, resolution).startOf(resolution);
    const endDate = moment().add(offset, resolution).endOf(resolution);
    gaEvent({ category: categories.PROJECTS, action: events.NAV_RIGHT });
    this.setState({
      offset,
      startDate,
      endDate,
      disableLeftNav: (startDate.isSameOrBefore(moment(GENESIS_DATE), 'day') && 'disabled') || '',
      disableRightNav: (endDate.isSameOrAfter(moment(), 'day') && 'disabled') || '',
    });
    this.props.loadData(startDate, endDate);
  };

  render() {
    const { resolution, startDate, endDate, disableLeftNav, disableRightNav } = this.state;

    const { data, downloadDisabled, downloadClear, filename } = this.props;

    let dateRange;
    if (resolution === duration.WEEK) {
      dateRange = `${startDate.format('Do MMM')} - ${endDate.format('Do MMM')}`;
    } else {
      dateRange = `${startDate.format('MMMM YYYY')}`;
    }

    return (
      <div>
        <Desktop>
          <EnhancedCol span={11}>
            <EnhancedMenu
              onClick={this.onResolutionChange}
              selectedKeys={[this.state.resolution]}
              mode="horizontal"
              style={{ width: '74%', borderBottom: 'none' }}
            >
              <EnhancedMenu.Item key={duration.WEEK}>Weekly</EnhancedMenu.Item>
              <EnhancedMenu.Item key={duration.MONTH}>Monthly</EnhancedMenu.Item>
            </EnhancedMenu>
          </EnhancedCol>
          <EnhancedCol span={2} style={{ textAlign: 'right' }}>
            <EnhancedButton
              type="primary"
              shape="circle"
              onClick={this.onNavLeft}
              disabled={disableLeftNav}
            >
              <EnhancedIcon type="left" />
            </EnhancedButton>
          </EnhancedCol>
          <EnhancedCol span={6} style={{ textAlign: 'center', margin: '0 2%' }}>
            {dateRange}
          </EnhancedCol>
          <EnhancedCol span={2}>
            <EnhancedButton
              type="primary"
              shape="circle"
              onClick={this.onNavRight}
              disabled={disableRightNav}
            >
              <EnhancedIcon type="right" />
            </EnhancedButton>
          </EnhancedCol>
          <EnhancedCol span={2} style={{ paddingLeft: '3.5%' }}>
            {data && (
              <CSVLink data={data} filename={`${filename}-${dateRange}.csv`}>
                <EnhancedButton
                  type="primary"
                  shape="circle"
                  icon="download"
                  disabled={downloadDisabled}
                  onClick={downloadClear}
                />
              </CSVLink>
            )}
          </EnhancedCol>
        </Desktop>
        <Mobile>
          <EnhancedRow style={{ borderTop: '3px solid #eee' }}>
            <EnhancedCol span={24} style={{ textAlign: '-webkit-center' }}>
              <EnhancedMenu
                onClick={this.onResolutionChange}
                selectedKeys={[this.state.resolution]}
                mode="horizontal"
                style={{ width: 'fit-content' }}
              >
                <EnhancedMenu.Item key={duration.WEEK}>Weekly</EnhancedMenu.Item>
                <EnhancedMenu.Item key={duration.MONTH}>Monthly</EnhancedMenu.Item>
              </EnhancedMenu>
            </EnhancedCol>
          </EnhancedRow>
          <EnhancedRow style={{ borderBottom: '2px solid #eee' }}>
            <EnhancedCol span={data ? 2 : 4} style={{ textAlign: 'right', paddingLeft: '2%' }}>
              <EnhancedButton
                type="primary"
                shape="circle"
                onClick={this.onNavLeft}
                disabled={disableLeftNav}
              >
                <EnhancedIcon type="left" />
              </EnhancedButton>
            </EnhancedCol>
            <EnhancedCol span={14} style={{ textAlign: 'center', margin: '0 2%' }}>
              {dateRange}
            </EnhancedCol>
            <EnhancedCol span={data ? 2 : 4}>
              <EnhancedButton
                type="primary"
                shape="circle"
                onClick={this.onNavRight}
                disabled={disableRightNav}
              >
                <EnhancedIcon type="right" />
              </EnhancedButton>
            </EnhancedCol>
            {data && (
              <EnhancedCol span={4} style={{ textAlign: 'right' }}>
                <CSVLink data={data} filename={`${filename}-${dateRange}.csv`}>
                  <EnhancedButton
                    type="primary"
                    shape="circle"
                    icon="download"
                    disabled={downloadDisabled}
                    onClick={downloadClear}
                  />
                </CSVLink>
              </EnhancedCol>
            )}
          </EnhancedRow>
        </Mobile>
      </div>
    );
  }
}

export default DateRangePicker;
