import {
  GET_RCA_PROJECT_EMPLOYEES_INIT,
  GET_RCA_PROJECT_EMPLOYEES_SUCCESS,
  GET_RCA_PROJECT_EMPLOYEES_FAILURE,
} from 'constants/RcaProjectEmployeesTypes';

const initialState = {
  projectData: {},
  isLoading: false,
};

export default function rcaProjectEmployeesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RCA_PROJECT_EMPLOYEES_INIT:
      return {
        ...state,
        isLoading: true,
        projectData: {},
      };
    case GET_RCA_PROJECT_EMPLOYEES_SUCCESS:
      return {
        ...state,
        projectData: action.data,
      };
    case GET_RCA_PROJECT_EMPLOYEES_FAILURE:
      return {
        ...state,
        isLoading: false,
        projectData: action.data,
      };
    default:
      return state;
  }
}
