import { EnhancedCol, EnhancedCard, EnhancedRow } from 'components/shared/antd';
import React from 'react';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const LeaveDashboardCard = ({
  // loading,
  dataSource,
  columns,
  heading,
  onChange,
  pageInfo,
  onUpdateParams,
}) => (
  <EnhancedCard bordered={false} className="history-list" style={{ marginTop: '20px' }}>
    <EnhancedCol lg={24} md={24} sm={24} xs={24}>
      <h2 className="heading">{heading}</h2>
    </EnhancedCol>
    <EnhancedRow style={{ backgroundColor: '#fff', marginTop: '22px' }}>
      <EnhancedCol span={24}>
        <EnhancedServerSideTable
          data={dataSource}
          columns={columns}
          onChange={onChange}
          paginationInfo={{
            totalItems: pageInfo.totalItems,
            pageSize: pageInfo.size,
            pageNumber: pageInfo.page + 1,
          }}
          updateParams={(updatedParams) => {
            onUpdateParams(updatedParams);
          }}
        />
      </EnhancedCol>
    </EnhancedRow>
  </EnhancedCard>
);

export default LeaveDashboardCard;
