import {
  EnhancedButton,
  EnhancedCol,
  EnhancedIcon,
  EnhancedCard,
  EnhancedRow,
  EnhancedDatePicker,
  EnhancedInput,
  EnhancedSlider,
  EnhancedProgress,
} from 'components/shared/antd';
import React, { useState } from 'react';
import EditButton from 'components/shared/employeeProfile/EditButton';
import binIcon from 'images/bin.svg';
import { goalStatusToClassMapping } from 'configs/constants';
import {
  GOAL_STATUS,
  DATE_FORMAT,
  goalStatusToEditableFieldMapping,
} from 'configs/employeeProfileConstants';
import moment from 'moment';
import CommentsTable from './commentsTable';

const { TextArea } = EnhancedInput;
const GoalDetails = (props) => {
  const { viewDetailHandler, isLoading, details, updateGoalActions, params, deleteGoalActions } =
    props;
  const [isEdit, setEdit] = useState(false);
  const [goalsField, setGoalsField] = useState({
    title: '',
    weightage: '',
    measurement: '',
    description: '',
    dueDate: moment().format(DATE_FORMAT),
    status: 'Pending',
    progress: 0,
  });

  const [editableFields, setEditableFields] = useState([]);

  const handleEdit = () => {
    setEdit(true);
    setGoalsField({
      title: details.title,
      weightage: details.weightage,
      measurement: details.measurement,
      description: details.description,
      dueDate: details.dueDate,
      status: details.status,
      progress: details.progress,
    });

    if (details && details.status)
      setEditableFields(goalStatusToEditableFieldMapping[details.status]);
  };

  const updateGoal = () => {
    const payload = editableFields.reduce((acc, cur) => {
      acc[cur] = goalsField[cur];
      return acc;
    }, {});

    updateGoalActions({
      id: details.id,
      payload,
      parameters: params,
      viewDetails: true,
    });
    setEdit(false);
  };

  const deleteGoal = () => {
    deleteGoalActions(details.id, true);
    setEdit(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (editableFields.indexOf(name) !== -1) {
      setGoalsField({
        ...goalsField,
        [name]: value,
      });
    }
  };

  const onDueDateChange = (date) => {
    if (editableFields.indexOf('dueDate') !== -1) {
      setGoalsField({
        ...goalsField,
        dueDate: moment(date).format(DATE_FORMAT),
      });
    }
  };

  const onSliderChange = (value) => {
    if (editableFields.indexOf('progress') !== -1) {
      setGoalsField({
        ...goalsField,
        progress: value,
      });
    }
  };

  const canEdit = () =>
    details.status === GOAL_STATUS.PENDING ||
    details.status === GOAL_STATUS.PENDING_APPROVAL ||
    details.status === GOAL_STATUS.APPROVED;

  const canDelete = () => details.status === GOAL_STATUS.PENDING;

  const getRejectionReason = () => {
    let rejectionReason = null;

    if (details.status === GOAL_STATUS.REJECTED) {
      const rejectedApprover = details.approvers.find(
        (approver) => approver.status === GOAL_STATUS.REJECTED,
      );
      rejectionReason = (
        <EnhancedCol className="gutter-row" span={12}>
          <div className="goal-details-wrapper">
            <label htmlFor="Status">
              Rejection Reason <span>:</span>
            </label>
            <span>{rejectedApprover.comment}</span>
          </div>
        </EnhancedCol>
      );
    }

    return rejectionReason;
  };

  return (
    <EnhancedCard
      loading={isLoading}
      bordered={false}
      className="details-card-wrapper"
      style={{
        marginTop: 10,
      }}
    >
      <div className="goals-details-header">
        <div>
          <EnhancedIcon
            type="arrow-left"
            className="edit-button-icon"
            onClick={viewDetailHandler}
          />
          <h1>{details.title}</h1>
        </div>
        {isEdit ? (
          <div>
            <EnhancedButton
              disabled={!goalsField.title || !goalsField.dueDate}
              onClick={updateGoal}
              style={{
                marginRight: 10,
              }}
            >
              Save
            </EnhancedButton>
            <EnhancedButton onClick={() => setEdit(false)}>Cancel</EnhancedButton>
          </div>
        ) : (
          <div>
            {canDelete() && (
              <EnhancedButton
                className="btn-edit"
                style={{
                  marginRight: 10,
                }}
                onClick={deleteGoal}
              >
                <img src={binIcon} alt="delete icon" />
                <span>Delete</span>
              </EnhancedButton>
            )}
            {canEdit() && <EditButton clickHandler={handleEdit} buttonText="Edit" />}
          </div>
        )}
      </div>
      <div className="goals-details-body">
        <div className="goal-desc-container">
          <h2>Goal Details</h2>
          <EnhancedRow gutter={16}>
            <EnhancedCol className="gutter-row" span={12}>
              <div className="goal-details-wrapper">
                <label htmlFor="Goal Title">
                  Goal Title {isEdit && <i style={{ color: 'red' }}> * </i>}
                  <span>:</span>
                </label>
                {isEdit && editableFields.indexOf('title') !== -1 ? (
                  <EnhancedInput name="title" value={goalsField.title} onChange={handleChange} />
                ) : (
                  <span>{details.title}</span>
                )}
              </div>
            </EnhancedCol>
            <EnhancedCol className="gutter-row" span={12}>
              <div className="goal-details-wrapper">
                <label htmlFor="Due Date">
                  Due Date {isEdit && <i style={{ color: 'red' }}> * </i>}
                  <span>:</span>
                </label>
                {isEdit && editableFields.indexOf('dueDate') !== -1 ? (
                  <EnhancedDatePicker
                    name="dueDate"
                    dropdownClassName="dueDate-goal-field"
                    style={{ width: '100%' }}
                    onChange={onDueDateChange}
                    value={moment(goalsField.dueDate)}
                    format={DATE_FORMAT}
                    disabledDate={(current) => {
                      const customDate = moment().format(DATE_FORMAT);
                      return current && current < moment(customDate, DATE_FORMAT);
                    }}
                    allowClear={false}
                  />
                ) : (
                  <span>{moment.utc(details.dueDate).format(DATE_FORMAT)}</span>
                )}
              </div>
            </EnhancedCol>
            <EnhancedCol className="gutter-row" span={12}>
              <div className="goal-details-wrapper">
                <label htmlFor="Status">
                  Status <span>:</span>
                </label>
                <span className={`status-box ${goalStatusToClassMapping[details.status]}`}>
                  {details.status}
                </span>
              </div>
            </EnhancedCol>
            <EnhancedCol className="gutter-row" span={12}>
              <div className="goal-details-wrapper">
                <label htmlFor="Percent Complete">
                  Percent Complete <span>:</span>
                </label>
                {isEdit && editableFields.indexOf('progress') !== -1 ? (
                  <EnhancedRow>
                    <EnhancedCol span={16}>
                      <EnhancedSlider
                        min={0}
                        max={100}
                        step={10}
                        onChange={onSliderChange}
                        className="range-slider"
                        value={typeof goalsField.progress === 'number' ? goalsField.progress : 0}
                      />
                    </EnhancedCol>
                    <EnhancedCol span={4}>
                      <EnhancedInput
                        style={{ marginLeft: 12 }}
                        value={goalsField.progress}
                        readOnly
                      />
                    </EnhancedCol>
                  </EnhancedRow>
                ) : (
                  <EnhancedProgress
                    className={`progress-bar ${details.progress > 10 && 'font-white'}`}
                    percent={`${details.progress}`}
                    strokeColor="#1DAC8A"
                    strokeLinecap="square"
                    type="line"
                  />
                )}
              </div>
            </EnhancedCol>
            <EnhancedCol className="gutter-row" span={12}>
              <div className="goal-details-wrapper">
                <label htmlFor="Weighted">
                  Weighted (%) <span>:</span>
                </label>
                {isEdit && editableFields.indexOf('weightage') !== -1 ? (
                  <EnhancedInput
                    name="weightage"
                    value={goalsField.weightage}
                    onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                    onChange={handleChange}
                  />
                ) : (
                  <span>{details.weightage}</span>
                )}
              </div>
            </EnhancedCol>
            {getRejectionReason()}
          </EnhancedRow>
        </div>
      </div>
      <div className="goals-details-body">
        <div className="goal-desc-container">
          <h2>Goal Description & Measurement</h2>
          <EnhancedRow gutter={16}>
            <EnhancedCol className="gutter-row" span={12}>
              <div className="goal-details-wrapper">
                <label htmlFor="Goal Description">
                  Goal Description <span>:</span>
                </label>
                {isEdit && editableFields.indexOf('description') !== -1 ? (
                  <TextArea
                    name="description"
                    value={goalsField.description}
                    onChange={handleChange}
                    rows={4}
                  />
                ) : (
                  <span>{details.description}</span>
                )}
              </div>
            </EnhancedCol>
            <EnhancedCol className="gutter-row" span={12}>
              <div className="goal-details-wrapper">
                <label htmlFor="Goal Measurement">
                  Goal Measurement <span>:</span>
                </label>
                {isEdit && editableFields.indexOf('measurement') !== -1 ? (
                  <TextArea
                    name="measurement"
                    value={goalsField.measurement}
                    onChange={handleChange}
                    rows={4}
                  />
                ) : (
                  <span>{details.measurement}</span>
                )}
              </div>
            </EnhancedCol>
          </EnhancedRow>
        </div>
      </div>
      <div className="goals-details-body">
        <div className="goal-desc-container">
          <h2>Comments Table</h2>
          <CommentsTable />
        </div>
      </div>
    </EnhancedCard>
  );
};

export default GoalDetails;
