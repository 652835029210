import {
  EnhancedButton,
  EnhancedIcon,
  EnhancedTabs,
  EnhancedModal,
  EnhancedSelect,
  EnhancedProgress,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';
import {
  addGoalActions,
  getGoalActions,
  removeGoalActions,
  getGoalDetailsActions,
  updateGoalActions,
  submitMyGoalForApprovalActions,
} from 'store/actions/GoalsActions';
import { goalStatusToClassMapping } from 'configs/constants';
import { GOAL_STATUS, DATE_FORMAT } from 'configs/employeeProfileConstants';
import editIcon from '../../../images/edit-gray.svg';
import deleteIcon from '../../../images/delete-icon.svg';
import viewIcon from '../../../images/view-icon.svg';
import AddButton from '../../shared/employeeProfile/AddButton';
import AddGoalModal from './addGoalModal';
import GoalDetails from './goalDetails';

const { TabPane } = EnhancedTabs;
const { Option } = EnhancedSelect;
const { confirm } = EnhancedModal;

const EmployeeGoals = (props) => {
  const {
    userRole,
    addGoal,
    updateGoal,
    getGoal,
    removeGoal,
    getGoalDetails,
    history,
    list,
    isLoading,
    variant,
    details,
    submitMyGoalForApproval,
  } = props;
  const uId = history.location.pathname.split('/')[2];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState('1');
  const [params, setParams] = useState({
    id: variant ? userRole.id : uId,
    status: `${GOAL_STATUS.PENDING},${GOAL_STATUS.PENDING_APPROVAL},${GOAL_STATUS.APPROVED}`,
    pageNumber: 0,
    pageSize: 10,
  });
  const [isDetailsVisible, setDetailsVisibilty] = useState(false);
  const [isEdit, setEdit] = useState(false);

  const viewDetailHandler = () => {
    setDetailsVisibilty(!isDetailsVisible);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  useEffect(() => {
    getGoal(params);
  }, [params]);

  // count not updated in params when 1 doc available in paginaton that why I add this this code. Ticket No: VENDIANS-3234
  useEffect(() => {
    if (list.count && list.rows.length === 0 && params.pageNumber > 0) {
      setParams({
        ...params,
        pageNumber: params.pageNumber - 1,
      });
    }
  }, [list.count]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.status !== GOAL_STATUS.PENDING,
      name: record.name,
    }),
  };
  const hasSelected = selectedRowKeys.length > 0;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmitForApproval = () => {
    confirm({
      title: 'Are you sure you want to submit this goals for approval?',
      okText: 'Yes',
      okType: 'success',
      cancelText: 'No',
      onOk() {
        submitMyGoalForApproval({
          goalIds: selectedRowKeys,
          params,
        });
        setSelectedRowKeys([]);
      },
    });
  };

  const tabChangeHandler = (key) => {
    if (key === '1') {
      setParams({
        ...params,
        status: `${GOAL_STATUS.PENDING},${GOAL_STATUS.PENDING_APPROVAL},${GOAL_STATUS.APPROVED}`,
        pageNumber: 0,
        pageSize: 10,
      });
    } else if (key === '2') {
      setParams({
        ...params,
        status: `${GOAL_STATUS.COMPLETED},${GOAL_STATUS.FINAL}`,
        pageNumber: 0,
        pageSize: 10,
      });
    } else if (key === '3') {
      setParams({
        ...params,
        status: `${GOAL_STATUS.REJECTED},${GOAL_STATUS.DELETED}`,
        pageNumber: 0,
        pageSize: 10,
      });
    }
    setSelectedTab(key);
  };

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      pageNumber: pagination.current - 1,
      pageSize: pagination.pageSize,
    });
  };

  const removeGoalHandler = (id, viewDetails = false) => {
    const payload = {
      empId: userRole.id,
      goalId: id,
    };
    confirm({
      title: 'Are you sure you want to delete this goal?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        removeGoal({ payload, params, viewDetails });
      },
    });
  };

  const changeActionHandler = (key, id) => {
    if (key === '1') {
      viewDetailHandler();
      getGoalDetails({ id });
    }
  };

  const optionMenu = [
    {
      value: '1',
      label: 'View',
    },
    {
      value: '2',
      label: 'Reject',
    },
    {
      value: '3',
      label: 'Approved',
    },
  ];

  const canEdit = (status) =>
    status === GOAL_STATUS.PENDING ||
    status === GOAL_STATUS.PENDING_APPROVAL ||
    status === GOAL_STATUS.APPROVED;

  const canDelete = (status) => status === GOAL_STATUS.PENDING;

  const setActionsHandler = (id, _record) => {
    let actions;
    if (variant) {
      actions = (
        <span>
          {selectedTab === '1' && (
            <button
              className="transparent-btn"
              style={{ marginRight: 10 }}
              disabled={!canEdit(_record.status)}
              onClick={() => {
                showModal();
                setEdit(true);
                getGoalDetails({ id });
              }}
            >
              <img src={editIcon} alt="edit icon" />
            </button>
          )}
          {selectedTab === '1' && (
            <button
              className="transparent-btn"
              style={{ marginRight: 10 }}
              onClick={() => removeGoalHandler(id)}
              disabled={!canDelete(_record.status)}
            >
              <img src={deleteIcon} alt="delte icon" />
            </button>
          )}
          <button
            className="transparent-btn"
            onClick={() => {
              viewDetailHandler();
              getGoalDetails({ id });
            }}
          >
            <img src={viewIcon} alt="view icon" />
          </button>
        </span>
      );
    } else if (userRole.allReportees.length > 0 && selectedTab === '2') {
      actions = (
        <button
          className="transparent-btn"
          onClick={() => {
            viewDetailHandler();
            getGoalDetails({ id });
          }}
        >
          <img src={viewIcon} alt="view icon" />
        </button>
      );
    } else {
      actions = (
        <EnhancedSelect
          style={{
            width: 120,
          }}
          className="action-selection-dropdown"
          placeholder="Options"
          onChange={(key) => changeActionHandler(key, id)}
        >
          {optionMenu.map((item) => (
            <Option value={item.value}>{item.label}</Option>
          ))}
        </EnhancedSelect>
      );
    }
    return actions;
  };

  const columns = [
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: '15%',
      render: (value) => (value ? moment.utc(value).format(DATE_FORMAT) : 'N/A'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) => (
        <span className={`status-box ${goalStatusToClassMapping[status]}`}>{status}</span>
      ),
    },
    {
      title: 'Goal Title',
      dataIndex: 'title',
    },
    {
      title: 'Percent Complete',
      dataIndex: 'progress',
      width: 250,
      render: (progres) => (
        <EnhancedProgress
          className={`progress-bar ${progres > 10 && 'font-white'}`}
          percent={`${progres}`}
          strokeColor="#1DAC8A"
          strokeLinecap="square"
          type="line"
        />
      ),
    },
    {
      title: 'Weighted',
      dataIndex: 'weightage',
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render: (id, _record) => setActionsHandler(id, _record),
    },
  ];

  return (
    <div>
      {!isDetailsVisible ? (
        <React.Fragment>
          {!uId && (
            <div className="add-btn-wrapper">
              <EnhancedButton
                className="btn-edit"
                style={{
                  marginRight: 10,
                }}
                onClick={handleSubmitForApproval}
                disabled={
                  selectedRowKeys.length === 0 || selectedTab === '2' || selectedTab === '3'
                }
              >
                <EnhancedIcon type="check-circle" />
                <span>Submit for Approval</span>
              </EnhancedButton>
              <AddButton buttonText="Add New Goal" clickHandler={showModal} />
            </div>
          )}

          <div>
            <span
              style={{
                marginLeft: 8,
              }}
            >
              {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
            </span>
          </div>
          <EnhancedTabs defaultActiveKey={selectedTab} type="card" onChange={tabChangeHandler}>
            <TabPane tab="Active Goals" key="1">
              <EnhancedServerSideTable
                rowKey={(record) => record.id}
                rowSelection={rowSelection}
                loading={isLoading}
                columns={columns}
                data={list.rows}
                onChange={handleTableChange}
                className="goal-table-container"
                paginationInfo={{
                  totalItems: list.count,
                  pageSize: params.pageSize,
                  pageNumber: params.pageNumber + 1,
                }}
                showResetFiltersButton={false}
                scroll={{ x: 1300 }}
              />
            </TabPane>
            <TabPane tab="Completed Goals" key="2">
              <EnhancedServerSideTable
                rowKey={(record) => record.id}
                loading={isLoading}
                columns={columns}
                data={list.rows}
                onChange={handleTableChange}
                className="goal-table-container"
                paginationInfo={{
                  totalItems: list.count,
                  pageSize: params.pageSize,
                  pageNumber: params.pageNumber + 1,
                }}
                showResetFiltersButton={false}
                scroll={{ x: 1300 }}
              />
            </TabPane>
            <TabPane tab="Rejected/Deleted Goals" key="3">
              <EnhancedServerSideTable
                rowKey={(record) => record.id}
                loading={isLoading}
                columns={columns}
                data={list.rows}
                onChange={handleTableChange}
                className="goal-table-container"
                paginationInfo={{
                  totalItems: list.count,
                  pageSize: params.pageSize,
                  pageNumber: params.pageNumber + 1,
                }}
                showResetFiltersButton={false}
                scroll={{ x: 1300 }}
              />
            </TabPane>
          </EnhancedTabs>

          <AddGoalModal
            isModalVisible={isModalVisible}
            handleOk={handleOk}
            handleCancel={handleCancel}
            addGoalActions={addGoal}
            updateGoalActions={updateGoal}
            params={params}
            isEdit={isEdit}
            editableHandler={setEdit}
            isLoading={isLoading}
            details={details}
          />
        </React.Fragment>
      ) : (
        <GoalDetails
          viewDetailHandler={viewDetailHandler}
          isLoading={isLoading}
          details={details}
          updateGoalActions={updateGoal}
          deleteGoalActions={removeGoalHandler}
          params={params}
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { auth, goalReducer } = state;
  const { isLoading } = goalReducer;
  return {
    isLoading,
    userRole: auth.user,
    list: goalReducer.goals,
    details: goalReducer.goalDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addGoal: (req) => dispatch(addGoalActions(req)),
    getGoal: (req) => dispatch(getGoalActions(req)),
    removeGoal: (req) => dispatch(removeGoalActions(req)),
    getGoalDetails: (req) => dispatch(getGoalDetailsActions(req)),
    updateGoal: (req) => dispatch(updateGoalActions(req)),
    submitMyGoalForApproval: (req) => dispatch(submitMyGoalForApprovalActions(req)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeGoals);
