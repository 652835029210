import axios from 'axios';
import {
  addEmploymentTypeInit,
  addEmploymentTypeSuccess,
  addEmploymentTypeFailure,
  getEmploymentTypeInit,
  getEmploymentTypeSuccess,
  getEmploymentTypeFailure,
  deleteEmploymentTypeInit,
  deleteEmploymentTypeSuccess,
  deleteEmploymentTypeFailure,
  editEmploymentTypeInit,
  editEmploymentTypeSuccess,
  editEmploymentTypeFailure,
} from 'store/actions/EmploymentTypesActions';

import { showLoader, hideLoader } from 'store/actions/AppLoaderActions';

import { employmentTypesUrls } from 'configs/constants';
import { EnhancedNotification } from 'components/shared/antd';

export const getEmploymentType = () => (dispatch) => {
  dispatch(getEmploymentTypeInit());
  dispatch(showLoader());
  const url = employmentTypesUrls.GET;
  axios
    .get(url)
    .then((response) => {
      dispatch(hideLoader());
      dispatch(getEmploymentTypeSuccess(response.data.data));
    })
    .catch(() => {
      dispatch(hideLoader());
      EnhancedNotification.error({
        message: 'Error in Getting employment type Data',
      });
      dispatch(getEmploymentTypeFailure());
    });
};

export const postEmploymentType = (request) => (dispatch) => {
  const url = employmentTypesUrls.POST;
  dispatch(addEmploymentTypeInit());
  dispatch(showLoader());
  axios
    .post(url, request)
    .then((response) => {
      dispatch(hideLoader());
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Created Successfully',
        });
        dispatch(addEmploymentTypeSuccess(response.data.data));
      } else {
        EnhancedNotification.error({
          message: 'Error in creating a new employment type',
        });
        dispatch(addEmploymentTypeFailure(response.data.data));
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      EnhancedNotification.error({
        message: 'Entered employment type already exists. Cannot add it again',
      });
      dispatch(addEmploymentTypeFailure(error));
    });
};

export const editEmploymentType = (request) => (dispatch) => {
  const url = employmentTypesUrls.PUT.replace('{:id}', request.id);
  dispatch(editEmploymentTypeInit());
  dispatch(showLoader());
  axios
    .put(url, request)
    .then((response) => {
      dispatch(hideLoader());
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Updated Successfully',
        });
        dispatch(editEmploymentTypeSuccess(request));
      } else {
        EnhancedNotification.error({
          message: 'Error in editing the employment type',
        });
      }
    })
    .catch(() => {
      dispatch(hideLoader());
      EnhancedNotification.error({
        message: 'Entered employment type already exists. Cannot add it again',
      });
      dispatch(editEmploymentTypeFailure());
    });
};

export const deleteEmploymentType = (request) => (dispatch) => {
  const url = employmentTypesUrls.DELETE.replace('{:id}', request.id);
  dispatch(deleteEmploymentTypeInit());
  dispatch(showLoader());
  axios
    .delete(url, { data: request })
    .then((response) => {
      dispatch(hideLoader());
      if (response.status === 200) {
        dispatch(deleteEmploymentTypeSuccess({ id: request.id }));
        EnhancedNotification.success({
          message: 'Deleted Successfully',
        });
      } else {
        EnhancedNotification.error({
          message: 'Cannot delete because the employment type is associated with employees',
        });
        dispatch(deleteEmploymentTypeFailure());
      }
    })
    .catch(() => {
      dispatch(hideLoader());
      EnhancedNotification.error({
        message: 'Cannot delete because the employment type is associated with employees',
      });
    });
};
