import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedModal,
  EnhancedSelect,
  EnhancedInputNumber,
  EnhancedSlider,
  EnhancedForm,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import RequiredField from 'components/RequiredField';

const AddGoalModal = (props) => {
  const {
    goals,
    isAdd,
    params,
    isEdit,
    details,
    handleOk,
    isLoading,
    categoryId,
    handleCancel,
    isModalVisible,
    editableHandler,
    addGoalCategoryGoalActions,
    updateGoalCategoryGoalActions,
  } = props;

  const [goalsField, setGoalsField] = useState({
    title: '',
    progress: 0,
  });

  const createNewGoal = () => {
    addGoalCategoryGoalActions({
      payload: [
        {
          goalId: goalsField.title,
          weightage: goalsField.progress,
        },
      ],
      parameters: {
        ...params,
        categoryId,
      },
    });
    handleOk();
    setGoalsField({
      title: '',
      progress: '0',
    });
    editableHandler(false);
  };

  const editGoal = () => {
    const payload = {
      goals: [
        {
          goalId: goalsField.title,
          weightage: goalsField.progress,
        },
      ],
    };

    const paramsObj = {
      ...params,
      categoryId,
    };

    updateGoalCategoryGoalActions({
      categoryId,
      payload,
      params: paramsObj,
    });
    handleOk();
    setGoalsField({
      title: '',
    });
    editableHandler(false);
  };

  const closeModalHandler = () => {
    handleCancel();
    setGoalsField({
      title: '',
    });
    editableHandler(false);
  };

  const onSliderChange = (value) => {
    setGoalsField({
      ...goalsField,
      progress: value,
    });
  };

  const searchDropdown = (input, option) =>
    option.props.children.toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (details.title && isEdit) {
      setGoalsField({
        goalId: details.id,
        title: details.id,
        progress: details.weightage,
      });
    }
  }, [details, isEdit]);

  const modalTitle = () => {
    let titleText = '';
    if (isEdit) titleText = 'Edit Goal';
    else if (isAdd) titleText = 'Add Goal';

    return titleText;
  };

  return (
    <EnhancedModal
      title={modalTitle()}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={closeModalHandler}
      className="add-goals-modal"
      width="1041px"
      footer={[
        <EnhancedButton
          key="submit"
          type="primary"
          onClick={isEdit ? editGoal : createNewGoal}
          disabled={!goalsField.title}
        >
          {'Submit'}
        </EnhancedButton>,
      ]}
    >
      <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
        loading={isLoading}
        bordered={false}
        className="details-card-wrapper"
        style={{
          marginTop: 10,
        }}
      >
        <EnhancedRow>
          <EnhancedForm layout="inline" className="goals-form w-full">
            <EnhancedCol lg={4}>
              <RequiredField> Goal </RequiredField>
            </EnhancedCol>
            <EnhancedCol lg={20}>
              <EnhancedSelect
                placeholder="Goal"
                style={{ width: '100%' }}
                onChange={(value) => {
                  setGoalsField({
                    title: value,
                  });
                }}
                disabled={isEdit}
                value={goalsField.title}
                tokenSeparators={[',']}
                filterOption={(input, option) => searchDropdown(input, option)}
                allowClear
              >
                {goals.rows &&
                  goals.rows.length > 0 &&
                  goals.rows.map((goal) => (
                    <EnhancedSelect.Option value={goal.id} key={goal.id}>
                      {goal.title}
                    </EnhancedSelect.Option>
                  ))}
              </EnhancedSelect>
            </EnhancedCol>
            <EnhancedCol lg={4} style={{ marginTop: 20 }}>
              <RequiredField> Weightage </RequiredField>
            </EnhancedCol>
            <EnhancedCol lg={20} style={{ marginTop: 20 }}>
              <EnhancedRow>
                <EnhancedCol span={18}>
                  <EnhancedSlider
                    min={0}
                    max={100}
                    step={1}
                    onChange={onSliderChange}
                    className="range-slider"
                    value={typeof goalsField.progress === 'number' ? goalsField.progress : 0}
                  />
                </EnhancedCol>
                <EnhancedCol span={5}>
                  <EnhancedInputNumber
                    style={{ marginLeft: 12 }}
                    value={goalsField.progress}
                    readOnly
                  />
                </EnhancedCol>
              </EnhancedRow>
            </EnhancedCol>
          </EnhancedForm>
        </EnhancedRow>
      </EnhancedCard>
    </EnhancedModal>
  );
};

export default AddGoalModal;
