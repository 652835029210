export const GET_MISCELLANEOUS_INIT = 'GET_MISCELLANEOUS_INIT';
export const GET_MISCELLANEOUS_SUCCESS = 'GET_MISCELLANEOUS_SUCCESS';
export const GET_MISCELLANEOUS_FAILURE = 'GET_MISCELLANEOUS_FAILURE';
export const GET_DESIGNATIONS_SUCCESS = 'GET_DESIGNATIONS_SUCCESS';
export const GET_DESIGNATIONS_FAILURE = 'GET_DESIGNATIONS_FAILURE';

// Job description case types
export const GET_JOB_DESCRIPTION_DOCUMENT_URL_INIT = 'GET_JOB_DESCRIPTION_DOCUMENT_URL_INIT';
export const GET_JOB_DESCRIPTION_DOCUMENT_URL_SUCCESS = 'GET_JOB_DESCRIPTION_DOCUMENT_URL_SUCCESS';
export const GET_JOB_DESCRIPTION_DOCUMENT_URL_FAILURE = 'GET_JOB_DESCRIPTION_DOCUMENT_URL_FAILURE';
