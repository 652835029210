import { EnhancedCard, EnhancedTabs } from 'components/shared/antd';
import React from 'react';

import EmployeeGoals from './employeeGoals';
import PerformanceReview from './performanceReview';

const { TabPane } = EnhancedTabs;

const GoalsReviews = (props) => {
  return (
    <React.Fragment>
      <EnhancedCard
        className="mt-20"
        style={{
          color: 'rgba(0,0,0,0.55)',
        }}
      >
        <EnhancedTabs defaultActiveKey="1" centered type="card">
          <TabPane tab="Employee Goals" key="1">
            <EmployeeGoals {...props} />
          </TabPane>
          <TabPane tab="Performance Review" key="2">
            <PerformanceReview {...props} />
          </TabPane>
        </EnhancedTabs>
      </EnhancedCard>
    </React.Fragment>
  );
};

export default GoalsReviews;
