import { EnhancedCard, EnhancedLayout } from 'components/shared/antd';
import React, { useEffect } from 'react';
import TabsRenderer from 'components/shared/TabsRenderer';
import Insights from './Insights';
import EmployeeListing from './EmployeeListing';
import Logs from './Logs';
import { PERFORMANCE_MANAGEMENT_ROUTES } from '../../../constants/UrlConstants';

const { Header } = EnhancedLayout;

const tabsData = [
  {
    key: 'category-insights',
    title: 'Category Goals',
    component: Insights,
  },
  {
    key: 'employees-listing',
    title: 'Employees',
    component: EmployeeListing,
  },
  {
    key: 'logs',
    title: 'Logs',
    component: Logs,
  },
];

const Detail = (props) => {
  const {
    employees,
    getAllEmployees,
    getEmployeeDivisionsAction,
    getEmployeeDepartmentsAction,
    getEmployeeCompetenciesAction,
    getEmployeeBusinessUnitAction,
  } = props;

  useEffect(() => {
    getAllEmployees();
    getEmployeeDivisionsAction();
    getEmployeeDepartmentsAction();
    getEmployeeCompetenciesAction();
    getEmployeeBusinessUnitAction();
  }, []);

  return (
    <div>
      <Header
        style={{
          background: 'white',
          marginTop: '1%',
        }}
      >
        <h1>Categories Details</h1>
      </Header>
      <EnhancedCard loading={false}>
        <TabsRenderer
          tabsData={tabsData}
          isAdmin
          employees={employees}
          baseUrlForEvaluation={PERFORMANCE_MANAGEMENT_ROUTES.ADMIN_VIEW_EVALUATION}
          {...props}
        />
      </EnhancedCard>
    </div>
  );
};

export default Detail;
