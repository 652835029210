/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import {
  getGoalInit,
  getGoalSuccess,
  getGoalFailure,
  getGoalDetailsInit,
  getGoalDetailsSuccess,
  getGoalDetailsFailure,
  addGoalInit,
  addGoalSuccess,
  addGoallFailure,
  updateGoalInit,
  updateGoalSuccess,
  updateGoallFailure,
  deleteGoalInit,
  deleteGoalSuccess,
  deleteGoalFailure,
} from 'store/actions/admin/PredefinedGoalsActions';
import { adminPredefinedGoalsUrls } from 'configs/constants';

export const getGoal = (request) => (dispatch) => {
  const url = adminPredefinedGoalsUrls.GET;
  dispatch(getGoalInit());
  axios
    .get(url, { params: request })
    .then((response) => {
      if (response.status === 200) {
        dispatch(getGoalSuccess(response.data.data));
      } else {
        dispatch(getGoalFailure());
      }
    })
    .catch((error) => {
      dispatch(getGoalFailure(error));
    });
};

export const getGoalDetails = (request) => (dispatch) => {
  const url = adminPredefinedGoalsUrls.GET_DETAILS.replace('{userId}', request.userId).replace(
    '{id}',
    request.id,
  );
  dispatch(getGoalDetailsInit());
  axios
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        //   const resourceNormalized = normalizeResourceAvailability(response.data.data, date);
        dispatch(getGoalDetailsSuccess(response.data.data));
      } else {
        dispatch(getGoalDetailsFailure());
      }
    })
    .catch((error) => {
      dispatch(getGoalDetailsFailure(error));
    });
};

export const addGoal = (request) => (dispatch) => {
  const url = adminPredefinedGoalsUrls.CREATE;
  const { payload, parameters } = request;
  dispatch(addGoalInit());
  axios
    .post(url, payload)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Created Successfully',
        });
        dispatch(addGoalSuccess(response.data));
        dispatch(getGoal(parameters));
      } else {
        EnhancedNotification.error({
          message: 'Error in creating a new goal',
        });
        dispatch(addGoallFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in creating a new goal.',
      });
      dispatch(addGoallFailure());
    });
};

export const updateGoal = (request) => (dispatch) => {
  const { id, payload, params } = request;
  const url = adminPredefinedGoalsUrls.UPDATE.replace('{userId}', request.userId).replace(
    '{id}',
    id,
  );
  dispatch(updateGoalInit());
  axios
    .put(url, payload)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Updated Successfully',
        });
        dispatch(updateGoalSuccess(response.data));
        dispatch(getGoal(params));
      } else {
        EnhancedNotification.error({
          message: 'Error in updating a new goal',
        });
        dispatch(updateGoallFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in updating a new goal.',
      });
      dispatch(updateGoallFailure());
    });
};

export const removeGoal = (request) => (dispatch) => {
  const { id, params } = request;
  const url = adminPredefinedGoalsUrls.DELETE.replace('{userId}', request.userId).replace(
    '{id}',
    id,
  );
  dispatch(deleteGoalInit());
  axios
    .delete(url)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Deleted Successfully',
        });

        dispatch(deleteGoalSuccess(response.data));
        dispatch(getGoal(params));
      } else {
        EnhancedNotification.error({
          message: 'Error in deleting goal.',
        });
        dispatch(deleteGoalFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({ message: error.response.message || 'Error in deleting goal.' });
      dispatch(deleteGoalFailure(error));
    });
};
