export const getPagination = (props = {}) => {
  const { page, size, totalItems, sizeChange = false } = props;
  const pageInfo = {
    total: totalItems || 0,
    defaultCurrent: (page || 0) + 1,
    pageSize: size || 10,
    showSizeChanger: sizeChange,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} records`,
  };
  return pageInfo;
};

export const paginationOptions = {
  page: 0,
  size: 10,
};
