import { EnhancedCol, EnhancedButton, EnhancedCard, EnhancedForm } from 'components/shared/antd';
import React from 'react';
// import _ from 'underscore';
import { FULL_GRID_SIZE } from 'configs/employeeProfileConstants';

import { createEmergencyContact } from 'utils/FieldGenerateUtils';
import confirmModal from '../../shared/ConfirmModal';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      disableSave: false,
      loading: false,
      info: {
        ...props.val,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.val !== nextProps.val) {
      this.setState({
        info: { ...nextProps.val },
      });
    }
    if (this.props.cardLoading !== nextProps.cardLoading) {
      this.setLoading(nextProps.cardLoading);
    }
  }

  onDeleteClick = () => {
    const { id } = this.state.info;
    confirmModal('Are you sure you want to delete this contact?', () => {
      this.props.onDelete(id);
      this.setState({ loading: true });
    });
  };

  setLoading = (cardLoading) => {
    if (!cardLoading) {
      this.setState({ loading: false });
    }
  };

  handleEdit = () => {
    this.setState({ isEdit: true, disableSave: true });
  };

  handleCancel = () => {
    this.setState({
      isEdit: false,
      info: {
        ...this.props.val,
      },
    });
  };

  handleChange = (value, key, error) => {
    const currentData = this.state.info;
    const newData = Object.assign({}, currentData, { [key]: value });
    const isValid = Object.keys(newData).every((k) => newData[k] !== '');
    this.setState({
      info: newData,
      disableSave: !isValid,
    });
    if (error) {
      this.setState({ disableSave: true });
    }
  };

  handleSave = (e) => {
    e.preventDefault();
    if (!this.state.disableSave) {
      this.setState({ isEdit: false, loading: true });
      this.props.onSave(this.state.info);
    }
  };

  render() {
    const { canEdit, canDelete, userPermissions } = this.props;
    const { isEdit, disableSave, loading } = this.state;
    return (
      <EnhancedCol span={FULL_GRID_SIZE}>
        <EnhancedCard loading={loading} className="mt-20 br-5">
          <EnhancedForm>
            <div className="flex" style={{ justifyContent: 'space-between' }}>
              <span className="card-title mb-10">
                {`${this.state.info.fullname} - ${
                  this.state.info.relationship.charAt(0).toUpperCase() +
                  this.state.info.relationship.slice(1)
                }`}
              </span>
              <div className="flex justify-flex-end">
                <span>
                  {canEdit && !isEdit && (
                    <EnhancedButton onClick={this.handleEdit}>Edit</EnhancedButton>
                  )}
                </span>
                <span>
                  {canEdit && isEdit && (
                    <EnhancedButton className="ml-10" onClick={this.handleCancel}>
                      Cancel
                    </EnhancedButton>
                  )}
                </span>
                <span>
                  {canEdit && isEdit && (
                    <EnhancedButton
                      type="primary"
                      className="ml-10"
                      onClick={this.handleSave}
                      disabled={disableSave}
                    >
                      Save
                    </EnhancedButton>
                  )}
                </span>
                <span>
                  {canDelete && (
                    <EnhancedButton
                      className="ml-10 btn-delete"
                      onClick={this.onDeleteClick}
                      type="danger"
                    >
                      Delete
                    </EnhancedButton>
                  )}
                </span>
              </div>
            </div>
            {createEmergencyContact(this.state.info, isEdit, userPermissions, this.handleChange)}
          </EnhancedForm>
        </EnhancedCard>
      </EnhancedCol>
    );
  }
}
export default Contact;
