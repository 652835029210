import {
  EnhancedCol,
  EnhancedIcon,
  EnhancedCard,
  EnhancedRow,
  EnhancedInput,
} from 'components/shared/antd';
import React from 'react';
import './employeeDirectory.css';

const EmployeeDirectoryHeader = (props) => {
  const { name, onInputChange } = props;
  return (
    <EnhancedCard
      style={{
        paddingBottom: 0,
        borderBottom: '3px solid #eee',
      }}
      bordered={false}
    >
      <EnhancedRow>
        <EnhancedCol lg={10}>
          <h2 className="heading-res" style={{ marginBottom: '0', color: '#000' }}>
            {name}
          </h2>
        </EnhancedCol>
        <EnhancedCol lg={{ offset: 8, span: 6 }}>
          <EnhancedInput
            placeholder="Search Name"
            suffix={<EnhancedIcon type="search" />}
            onChange={onInputChange}
          />
        </EnhancedCol>
      </EnhancedRow>
    </EnhancedCard>
  );
};

export default EmployeeDirectoryHeader;
