import React, { Component } from 'react';
import moment from 'moment';
import * as DateColors from 'constants/ColorTypes';

// before #c96d6d
// current #98c96d
// after #00000073

class DateCard extends Component {
  getColorFromDate = (dateToCheck, isLogged) => {
    if (isLogged) {
      return DateColors.LOGGED_COLOR;
    }
    const currentDate = moment();
    const day = moment(dateToCheck).day();
    const isWeekend = day === 0 || day === 6;

    if (isWeekend || moment(dateToCheck).isAfter(currentDate, 'day')) {
      return DateColors.NOT_DUE_COLOR;
    }
    return DateColors.DUE_COLOR;
  };

  render() {
    const { date, isLogged } = this.props;
    return (
      <div style={{ backgroundColor: this.getColorFromDate(date, isLogged) }} className="DateCard">
        <div className="DateCard-month">{moment(date).format('MMMM YYYY')}</div>
        <div className="DateCard-date">{moment(date).format('D')}</div>
        <div className="DateCard-day">{moment(date).format('dddd')}</div>
      </div>
    );
  }
}

export default DateCard;
