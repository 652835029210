import { connect } from 'react-redux';
import { updateEmployeesProjectActions } from 'store/actions/EmployeeActions';
import EmployeeProjectEditModal from 'components/employees/EmployeeProjectEditModal';

function mapStateToProps(state) {
  const { auth, employeeReducer } = state;
  return {
    userId: auth.user && auth.user.id,
    employeesProject: employeeReducer.employeesProject || [],
    isLoading: employeeReducer.isLoading,
    roles: employeeReducer.roles,
  };
}

const mapDispatchToProps = (dispatch) => ({
  updateEmployeesProjectActions: (employeeProjectInfo, currentInformation) =>
    dispatch(updateEmployeesProjectActions(employeeProjectInfo, currentInformation)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProjectEditModal);
