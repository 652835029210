import { EnhancedDrawer } from 'components/shared/antd';
import React, { useEffect } from 'react';
import moment from 'moment';
import { DD_MMM_YYYY_HH_MM_SS, DATE_FORMAT } from 'configs/employeeProfileConstants';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';

const DealRequirementHistory = (props) => {
  const {
    onClose,
    isDrawerVisible,
    dealRequirementHistory,
    getDealRequirementHistory,
    dealRequirementObj,
  } = props;

  useEffect(() => {
    if (isDrawerVisible) {
      getDealRequirementHistory(dealRequirementObj.id);
    }
  }, [isDrawerVisible]);

  const getColumnsInfo = () => {
    const columns = [
      {
        title: 'Resource Name',
        dataIndex: ['resource', 'name'],
        filterConfig: {
          type: 'search',
          key: ['resource', 'name'],
        },
      },
      {
        title: 'System Status',
        dataIndex: 'systemStatus',
        filterConfig: {
          type: 'search',
          key: 'systemStatus',
        },
      },
      {
        title: 'CRMC Status',
        dataIndex: 'crmcStatus',
        filterConfig: {
          type: 'search',
          key: 'crmcStatus',
        },
      },
      {
        title: 'Comments',
        dataIndex: 'comments',
        filterConfig: {
          type: 'search',
          key: 'comments',
        },
        width: 250,
      },
      {
        title: 'Status Date',
        dataIndex: 'statusDate',
        render: (statusDate) => {
          return statusDate ? moment(statusDate).format(DATE_FORMAT) : 'N/A';
        },
      },
      {
        title: 'Timestamp',
        dataIndex: 'createdAt',
        render: (createdAt) => {
          return moment(createdAt).format(DD_MMM_YYYY_HH_MM_SS);
        },
      },
    ];
    return columns;
  };

  return (
    <EnhancedDrawer
      visible={isDrawerVisible}
      title={`History of Requirement: ${
        dealRequirementObj && dealRequirementObj.skills.join(' + ')
      }`}
      width={980}
      onClose={onClose}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <EnhancedClientSideTable
        data={dealRequirementHistory}
        columns={getColumnsInfo()}
        exportFileConfig={{
          fileName: `History of Requirement: ${
            dealRequirementObj && dealRequirementObj.skills.join(' + ')
          }`,
          fileHeaders: [
            {
              label: 'Resource Name',
              key: ['resource', 'name'],
            },
            {
              label: 'System Status',
              key: 'systemStatus',
            },
            {
              label: 'CRMC Status',
              key: 'crmcStatus',
            },
            {
              label: 'Comments',
              key: 'comments',
            },
            {
              label: 'Status Date',
              key: 'statusDate',
              renderer: (statusDate) => {
                return moment(statusDate).format('DD-MMM-YYYY');
              },
            },
            {
              label: 'Timestamp',
              key: 'createdAt',
              renderer: (createdAt) => {
                return moment(createdAt).format(DD_MMM_YYYY_HH_MM_SS);
              },
            },
          ],
          showExportButton: true,
        }}
        scroll={{ x: 'max-content' }}
      />
    </EnhancedDrawer>
  );
};

export default DealRequirementHistory;
