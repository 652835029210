export const GET_EMPLOYEES_INIT = 'GET_EMPLOYEES_INIT';
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS';
export const GET_EMPLOYEES_BIRTHDAY_SUCCESS = 'GET_EMPLOYEES_BIRTHDAY_SUCCESS';
export const GET_EMPLOYEES_FAILURE = 'GET_EMPLOYEES_FAILURE';

export const GET_EMPLOYEES_SKILLS_INIT = 'GET_EMPLOYEES_SKILLS_INIT';
export const GET_EMPLOYEES_SKILLS_SUCCESS = 'GET_EMPLOYEES_SKILLS_SUCCESS';
export const GET_EMPLOYEES_SKILLS_FAILURE = 'GET_EMPLOYEES_SKILLS_FAILURE';

export const GET_EMPLOYEE_DEPARTMENTS_INIT = 'GET_EMPLOYEE_DEPARTMENTS_INIT';
export const GET_EMPLOYEE_DEPARTMENTS_SUCCESS = 'GET_EMPLOYEE_DEPARTMENTS_SUCCESS';
export const GET_EMPLOYEE_DEPARTMENTS_FAILURE = 'GET_EMPLOYEE_DEPARTMENTS_FAILURE';

export const GET_EMPLOYEE_DIVISIONS_INIT = 'GET_EMPLOYEE_DIVISIONS_INIT';
export const GET_EMPLOYEE_DIVISIONS_SUCCESS = 'GET_EMPLOYEE_DIVISIONS_SUCCESS';
export const GET_EMPLOYEE_DIVISIONS_FAILURE = 'GET_EMPLOYEE_DIVISIONS_FAILURE';

export const GET_EMPLOYEE_BUSINESSUNITS_INIT = 'GET_EMPLOYEE_BUSINESSUNITS_INIT';
export const GET_EMPLOYEE_BUSINESSUNITS_SUCCESS = 'GET_EMPLOYEE_BUSINESSUNITS_SUCCESS';
export const GET_EMPLOYEE_BUSINESSUNITS_FAILURE = 'GET_EMPLOYEE_BUSINESSUNITS_FAILURE';

export const GET_EMPLOYEE_COMPETENCIES_INIT = 'GET_EMPLOYEE_COMPETENCIES_INIT';
export const GET_EMPLOYEE_COMPETENCIES_SUCCESS = 'GET_EMPLOYEE_COMPETENCIES_SUCCESS';
export const GET_EMPLOYEE_COMPETENCIES_FAILURE = 'GET_EMPLOYEE_COMPETENCIES_FAILURE';

export const UPDATE_EMPLOYEES_INIT = 'UPDATE_EMPLOYEES_INIT';
export const MARK_EMPLOYEE_INACTIVE_INIT = 'MARK_EMPLOYEE_INACTIVE_INIT';
export const UPDATE_EMPLOYEES_SUCCESS = 'UPDATE_EMPLOYEES_SUCCESS';
export const UPDATE_EMPLOYEES_FAILURE = 'UPDATE_EMPLOYEES_FAILURE';
export const DELETE_EMPLOYEE_FAILURE = 'DELETE_EMPLOYEE_FAILURE';

export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';

export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS';

export const ADD_SKILL_INIT = 'ADD_SKILL_INIT';
export const ADD_SKILL_SUCCESS = 'ADD_SKILL_SUCCESS';
export const ADD_SKILL_FAILURE = 'ADD_SKILL_FAILURE';
export const EDIT_SKILL_SUCCESS = 'EDIT_SKILL_SUCCESS';
export const DELETE_SKILL_SUCCESS = 'DELETE_SKILL_SUCCESS';

export const UPDATE_EMPLOYEE_WISH = 'UPDATE_EMPLOYEE_WISH';

export const ADD_EMPLOYEE_BIRTHDAY_SUCCESS = 'ADD_EMPLOYEE_BIRTHDAY_SUCCESS';

export const ADD_DEPARTMENT_INIT = 'ADD_DEPARTMENT_INIT';
export const ADD_DEPARTMENT_SUCCESS = 'ADD_DEPARTMENT_SUCCESS';
export const ADD_DEPARTMENT_FAILURE = 'ADD_DEPARTMENT_FAILURE';
export const EDIT_DEPARTMENT_SUCCESS = 'EDIT_DEPARTMENT_SUCCESS';
export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS';

export const GET_ALL_EMPLOYEES_INIT = 'GET_ALL_EMPLOYEES_INIT';

export const GET_ALL_EMPLOYEES_SUCCESS = 'GET_ALL_EMPLOYEES_SUCCESS';

export const GET_ALL_EMPLOYESS_FAILURE = 'GET_ALL_EMPLOYESS_FAILURE';
export const ADD_DIVISION_INIT = 'ADD_DIVISION_INIT';
export const ADD_DIVISION_SUCCESS = 'ADD_DIVISION_SUCCESS';
export const ADD_DIVISION_FAILURE = 'ADD_DIVISION_FAILURE';
export const EDIT_DIVISION_SUCCESS = 'EDIT_DIVISION_SUCCESS';
export const DELETE_DIVISION_SUCCESS = 'DELETE_DIVISION_SUCCESS';

export const ADD_BUSINESSUNIT_INIT = 'ADD_BUSINESSUNIT_INIT';
export const ADD_BUSINESSUNIT_SUCCESS = 'ADD_BUSINESSUNIT_SUCCESS';
export const ADD_BUSINESSUNIT_FAILURE = 'ADD_BUSINESSUNIT_FAILURE';
export const EDIT_BUSINESSUNIT_SUCCESS = 'EDIT_BUSINESSUNIT_SUCCESS';
export const DELETE_BUSINESSUNIT_SUCCESS = 'DELETE_BUSINESSUNIT_SUCCESS';

export const ADD_COMPETENCY_INIT = 'ADD_COMPETENCY_INIT';
export const ADD_COMPETENCY_SUCCESS = 'ADD_COMPETENCY_SUCCESS';
export const ADD_COMPETENCY_FAILURE = 'ADD_COMPETENCY_FAILURE';
export const EDIT_COMPETENCY_SUCCESS = 'EDIT_COMPETENCY_SUCCESS';
export const DELETE_COMPETENCY_SUCCESS = 'DELETE_COMPETENCY_SUCCESS';

export const GET_USER_ATTRIBUTES_INIT = 'GET_USER_ATTRIBUTES_INIT';
export const GET_USER_ATTRIBUTES_SUCCESS = 'GET_USER_ATTRIBUTES_SUCCESS';
export const GET_USER_ATTRIBUTES_FAILURE = 'GET_USER_ATTRIBUTES_FAILURE';

export const GET_ALL_REPORTEES_SUCCESS = 'GET_ALL_REPORTEES_SUCCESS';

export const UPDATE_EMPLOYEES_BULK_INFO_INIT = 'UPDATE_EMPLOYEES_BULK_INFO_INIT';
export const UPDATE_EMPLOYEES_BULK_INFO_SUCCESS = 'UPDATE_EMPLOYEES_BULK_INFO_SUCCESS';
export const UPDATE_EMPLOYEES_BULK_INFO_FAILURE = 'UPDATE_EMPLOYEES_BULK_INFO_FAILURE';
