import React, { Fragment } from 'react';
import evaluationStatus from 'configs/performanceManagementConstants';

const statusConfirmContentMap = {
  [evaluationStatus.APPROVED]: (
    <Fragment>
      Are you sure you want to approve <br /> the evaluation?
    </Fragment>
  ),
  [evaluationStatus.REJECTED]: (
    <Fragment>
      Are you sure you want to reject <br /> the evaluation?
    </Fragment>
  ),
  [evaluationStatus.PENDING_APPROVAL]: (
    <Fragment>Are you sure you want to send the evaluation forms for approval?</Fragment>
  ),
};

export default statusConfirmContentMap;
