import { EnhancedCol, EnhancedRow, EnhancedCard, EnhancedDivider } from 'components/shared/antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import projectIcon from 'images/project_icon.svg';

import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import moment from 'moment';
import convertMonthRangeToDateRange from '../../utils/DateUtils';
import { BREADCRUMBS } from '../../configs/constants';

class ProjectEmployees extends Component {
  constructor(props) {
    super(props);

    const queryParams = this.props.location.search.split('=');
    const startDate = queryParams[1].split('&')[0];
    const endDate = queryParams[2];
    const dateRange = convertMonthRangeToDateRange(moment(startDate), moment(endDate));
    this.state = {
      sortedInfo: null,
      employees: props.projectData.employees || [],
      startDate,
      endDate,
      project: this.props.projectData || [],
      dateRange,
      filteredRecords: null,
    };
  }

  componentWillMount() {
    if (this.props.userId !== undefined) {
      const { projectId } = this.props.match.params;
      const startDate = moment(this.state.startDate);
      const endDate = moment(this.state.endDate);
      const dateRange = convertMonthRangeToDateRange(startDate, endDate);
      this.props.getRcaProjectEmployeesActions(
        this.props.userId,
        projectId,
        dateRange.startDate,
        dateRange.endDate,
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    let { employees, project } = this.props;
    const { updateBreadCrumb, projectData } = nextProps;
    if (projectData && this.props.projectData !== projectData) {
      updateBreadCrumb(BREADCRUMBS.PROJECT_CYCLE, projectData.projectId, projectData.projectName);
    }

    employees = employees !== projectData.employees ? projectData.employees : employees;
    project = project !== projectData ? nextProps.projectData : project;
    if (employees === undefined) {
      employees = [];
    }
    if (project === undefined) {
      project = {
        ...nextProps.projectData,
      };
    }
    this.setState({
      employees,
      project,
    });
  }

  getColumnsInfo = () => {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'Taleo ID',
        dataIndex: 'taleoId',
        key: 'taleoId',
        filterConfig: {
          type: 'search',
          key: 'taleoId',
        },
        render: this.renderEmployeeID,
        width: '100',
        sortOrder: sortedInfo.columnKey === 'taleoId' && sortedInfo.order,
      },
      {
        title: 'Name',
        dataIndex: 'employeeName',
        key: 'employeeName',
        width: '100',
        sorter: (a, b) => a.employeeName.localeCompare(b.employeeName),
        sortOrder: sortedInfo.columnKey === 'employeeName' && sortedInfo.order,
        filterConfig: {
          type: 'search',
          key: 'employeeName',
        },
        render: this.renderEmployeeName,
      },
      {
        title: 'Designation',
        dataIndex: 'designation',
        key: 'designation',
        width: '100',
        sorter: (a, b) => a.designation.localeCompare(b.designation),
        sortOrder: sortedInfo.columnKey === 'designation' && sortedInfo.order,
      },
      {
        title: 'Business Unit',
        dataIndex: 'businessUnit',
        key: 'businessUnit',
        width: '100',
      },
      {
        title: 'Division',
        dataIndex: 'division',
        key: 'division',
        width: '100',
      },
      {
        title: 'Competency',
        dataIndex: 'competency',
        key: 'competency',
        width: '100',
      },
      {
        title: 'Department',
        dataIndex: 'department',
        key: 'department',
        width: '100',
      },
      {
        title: 'Billed',
        dataIndex: 'billed',
        key: 'billed',
        width: '100',
        sorter: (a, b) => parseFloat(a.billed) - parseFloat(b.billed),
        sortOrder: sortedInfo.columnKey === 'billed' && sortedInfo.order,
      },
      {
        title: 'Backup',
        dataIndex: 'backup',
        key: 'backup',
        width: '100',
        sorter: (a, b) => parseFloat(a.backup) - parseFloat(b.backup),
        sortOrder: sortedInfo.columnKey === 'backup' && sortedInfo.order,
      },
      {
        title: 'Internal',
        dataIndex: 'internal',
        key: 'internal',
        width: '100',
        sorter: (a, b) => parseFloat(a.internal) - parseFloat(b.internal),
        sortOrder: sortedInfo.columnKey === 'internal' && sortedInfo.order,
      },
      {
        title: 'Total',
        dataIndex: 'totalAllocation',
        key: 'totalAllocation',
        width: '100',
        sorter: (a, b) => parseFloat(a.totalAllocation) - parseFloat(b.totalAllocation),
        sortOrder: sortedInfo.columnKey === 'totalAllocation' && sortedInfo.order,
      },
      {
        title: 'Additional',
        dataIndex: 'additional',
        key: 'additional',
        width: '100',
        sorter: (a, b) => parseFloat(a.additional) - parseFloat(b.additional),
        sortOrder: sortedInfo.columnKey === 'additional' && sortedInfo.order,
      },
    ];
    return columns;
  };

  dateRangeChange = (dates, dateStrings) => {
    if (this.props.userId !== undefined) {
      const startDate = dateStrings[0];
      const endDate = dateStrings[1];
      this.props.getRcaProjectEmployeesActions(
        this.props.userId,
        this.props.match.params.projectId,
        startDate,
        endDate,
      );
    }
  };

  dateRangeChange = (date, dateString) => {
    const startDate = dateString;
    const endDate = dateString;
    this.setState({
      startDate,
      endDate,
    });
  };

  projectEmployeesMeta = [
    {
      key: 'taleoId',
      label: 'Taleo ID',
    },
    {
      key: 'employeeName',
      label: 'Employee',
    },
    {
      key: 'designation',
      label: 'Designation',
    },
    {
      key: 'businessUnit',
      label: 'Business Unit',
    },
    {
      key: 'division',
      label: 'Division',
    },
    {
      key: 'competency',
      label: 'Competency',
    },
    {
      key: 'department',
      label: 'Department',
    },
    {
      key: 'billed',
      label: 'Billed',
    },
    {
      key: 'backup',
      label: 'Backup',
    },
    {
      key: 'internal',
      label: 'Internal',
    },
    {
      key: 'totalAllocation',
      label: 'Total Utilization',
    },
    {
      key: 'additional',
      label: 'Additional',
    },
  ];

  allocationKeys = ['billed', 'backup', 'internal', 'totalAllocation', 'additional'];

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  };

  renderEmployeeID = (name, data) => {
    const employeeId = data.taleoId ? data.taleoId : ' N/A ';
    return <div style={{ color: '#5A5A5A', wordBreak: 'normal' }}>{employeeId}</div>;
  };

  renderEmployeeName = (name, data) => (
    <Link
      to={`/rca/billing-employees/${data.employeeId}?startDate=${this.state.startDate}&endDate=${this.state.endDate}`}
    >
      <b style={{ color: '#5A5A5A' }}>{name}</b>
    </Link>
  );

  render() {
    const { isLoading } = this.state;
    const { employees, filteredRecords } = this.state;
    const columns = this.getColumnsInfo();
    const { project } = this.state;
    const { projectName } = this.props.projectData;

    const style = {
      fontSize: 10,
      textTransform: 'capitalize',
    };

    return (
      <div>
        <EnhancedCard loading={this.props.isProjectLoading} bordered={false}>
          <EnhancedRow>
            <EnhancedCol lg={2}>
              <img src={projectIcon} alt="logo" style={{ width: '60px', height: '60px' }} />
            </EnhancedCol>
            <EnhancedCol lg={8}>
              <h2 style={{ color: 'black', fontSize: '10' }}>{project.projectName}</h2>
              <p>
                {' '}
                {moment(this.state.dateRange.startDate).format('DD MMM YYYY')} -{' '}
                {moment(this.state.dateRange.endDate).format('DD MMM YYYY')}
              </p>
            </EnhancedCol>
            <EnhancedCol lg={5}>
              <div
                style={{
                  display: 'flex',
                  marginTop: '5px',
                  flexDirection: 'row',
                }}
              >
                <EnhancedDivider type="vertical" style={{ fontSize: '60px', marginTop: '10px' }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h2 style={{ fontSize: '15px' }}>Business Unit</h2>
                  <b style={{ fontSize: '15px' }}>
                    {project.businessUnit ? project.businessUnit : 'N/A'}
                  </b>
                </div>
              </div>
            </EnhancedCol>
            <EnhancedCol lg={5}>
              <div
                style={{
                  display: 'flex',
                  marginTop: '5px',
                  flexDirection: 'row',
                }}
              >
                <EnhancedDivider type="vertical" style={{ fontSize: '60px', marginTop: '10px' }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h2 style={{ fontSize: '15px' }}>F.T.E</h2>
                  <b style={{ fontSize: '20px' }}>{project.totalAllocation}</b>
                </div>
                <div
                  style={{
                    marginLeft: '30px',
                    marginTop: '2px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h2 style={style}>Billed: {project.billed}</h2>
                  <h2 style={style}>Backup: {project.backup}</h2>
                  <h2 style={style}>Internal: {project.internal}</h2>
                  <h2 style={style}>Additional: {project.additional}</h2>
                </div>
              </div>
            </EnhancedCol>
            <EnhancedCol lg={4}>
              <div
                style={{
                  display: 'flex',
                  marginTop: '5px',
                  flexDirection: 'row',
                }}
              >
                <EnhancedDivider type="vertical" style={{ fontSize: '60px', marginTop: '10px' }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h2 style={{ fontSize: '15px' }}>Head count</h2>
                  <b style={{ fontSize: '20px' }}>
                    {filteredRecords ? filteredRecords.length : employees.length}
                  </b>
                </div>
                <div
                  style={{
                    marginLeft: '30px',
                    marginTop: '2px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                />
              </div>
            </EnhancedCol>
          </EnhancedRow>
        </EnhancedCard>
        <EnhancedCard>
          <EnhancedCol span={24}>
            <EnhancedClientSideTable
              loading={isLoading}
              columns={columns}
              data={employees}
              onChange={this.handleChange}
              exportFileConfig={{
                fileName: `Project Utilization - ${projectName}`,
                fileHeaders: this.projectEmployeesMeta,
                showExportButton: true,
              }}
              setFilteredRecords={(records) => {
                this.setState({
                  filteredRecords: records,
                });
              }}
            />
          </EnhancedCol>
        </EnhancedCard>
      </div>
    );
  }
}

export default ProjectEmployees;
