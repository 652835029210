/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  getDefaultersInit,
  getDefaultersSuccess,
  getDefaultersFailure,
} from 'store/actions/DefaulterActions';
import { defaultersUrls, dateFormat } from 'configs/constants';
import normalizeDefaulters from 'normalizers/defaultersNormalizers';

export const getDefaulters = (startDate = '', endDate = '') => {
  const url = defaultersUrls.GET;

  return (dispatch) => {
    dispatch(getDefaultersInit());
    axios
      .get(url, {
        params: {
          startDate: startDate && startDate.format(dateFormat.YYYYMMDDwithHyphen),
          endDate: endDate && endDate.format(dateFormat.YYYYMMDDwithHyphen),
        },
      })
      .then((response) => {
        if (response.data.success) {
          // parse response through normalizer
          const normalizedDefaulters = normalizeDefaulters(response.data.data);
          // call success or failure action
          dispatch(getDefaultersSuccess(normalizedDefaulters));
        } else {
          dispatch(getDefaultersFailure('There was an error fetching defaulters.'));
        }
      })
      .catch(() => {
        dispatch(getDefaultersFailure('There was an error fetching defaulters.'));
      });
  };
};
