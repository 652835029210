import {
  EnhancedCol,
  EnhancedRow,
  EnhancedDivider,
  EnhancedList,
  EnhancedListItem,
} from 'components/shared/antd';
import React from 'react';

import { dateFormat } from 'configs/constants';
import moment from 'moment';
import './ProjectEmployees.css';

class ActivityLogCard extends React.Component {
  formateDate = (date) => {
    const dateFormate = dateFormat.DDMMMYYYYwithSpace;
    if (date === moment().format(dateFormate)) {
      return 'Today';
    } else if (date === moment().subtract(1, 'days').format(dateFormate)) {
      return 'Yesterday';
    }
    return date;
  };
  makeHtml = (value) => ({ __html: value });
  render() {
    const { logs, keys } = this.props;
    if (logs.length) {
      const timeFormat = 'hh:mm:ss a';
      logs.sort(
        (log1, log2) =>
          new Date(moment(log2.time, timeFormat)) - new Date(moment(log1.time, timeFormat)),
      );
      return (
        <div>
          <EnhancedList
            size="small"
            header={
              <div>
                <EnhancedDivider orientation="left" className="date-divider">
                  {this.formateDate(keys)}
                </EnhancedDivider>
              </div>
            }
            dataSource={logs}
            split={false}
            renderItem={(item) => (
              <EnhancedListItem className="list-items-style">
                <EnhancedRow>
                  <EnhancedCol lg={18}>
                    {
                      <div dangerouslySetInnerHTML={this.makeHtml(item.value)} /> // eslint-disable-line
                    }
                  </EnhancedCol>
                  <EnhancedCol lg={5} offset={1}>
                    {item.time}
                  </EnhancedCol>
                </EnhancedRow>
              </EnhancedListItem>
            )}
            itemLayout="vertical"
          />
        </div>
      );
    }

    return (
      <div style={{ textAlign: 'center' }}>
        <p>No Record Found</p>
      </div>
    );
  }
}

export default ActivityLogCard;
