import { EnhancedSpin } from 'components/shared/antd';
import React from 'react';
import { connect } from 'react-redux';
import { wishEmployeeAction } from 'store/actions/EmployeeActions';

// Components
import VendiansBirthdays from 'components/homePageCMS/modules/mainScreen/VendiansBirthdays';
// import employeeBirthdayReducer from '../store/reducers/employeeBirthdayReducer';

const temp = ({ employees, authData, wishEmployee }) => {
  if (employees.length) {
    return (
      <VendiansBirthdays
        employees={employees}
        auth={authData}
        wishEmployeeBirthday={wishEmployee}
      />
    );
  }
  return <EnhancedSpin />;
};

function mapStateToProps(state) {
  const { auth, employeeBirthdayReducer } = state;
  return {
    authData: auth.user,
    employees: employeeBirthdayReducer.employeesBirthday,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    wishEmployee: (employeeId, data) => dispatch(wishEmployeeAction(employeeId, data)),
  };
}

const VendiansBirthdaysContainer = connect(mapStateToProps, mapDispatchToProps)(temp);

export default VendiansBirthdaysContainer;
