import { connect } from 'react-redux';
import ApplyLeaveModal from 'components/leaves/ApplyLeaveModal';
import {
  getLeaveTypesAction,
  requestLeave,
  requestEditLeave,
  getTotalLeavesCountAction,
  getPublicHolidaysAction,
} from 'store/actions/LeaveActions';

function mapStateToProps({ leaveTypesReducer, auth, myLeaves, publicHolidaysReducer }) {
  return {
    leaveTypes: leaveTypesReducer.leaveTypes,
    userId: auth.user && auth.user.id,
    gender: auth.user && auth.user.gender,
    onProbation: auth.user && auth.user.onProbation,
    joiningDate: auth.user && auth.user.joiningDate,
    requestLoader: myLeaves.requestLoader,
    workingDays: myLeaves.workingDays,
    currentBalance: myLeaves.quota && myLeaves.quota.currentBalance,
    workingHolidays: publicHolidaysReducer.publicHolidays.workingHolidays,
    loading: myLeaves.loader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLeaveTypes: () => dispatch(getLeaveTypesAction()),
    getTotalLeavesCount: (params) => dispatch(getTotalLeavesCountAction(params)),
    getPublicHolidays: () => dispatch(getPublicHolidaysAction()),
    requestLeave: (requestObject) => dispatch(requestLeave(requestObject)),
    requestEditLeave: (requestObject) => dispatch(requestEditLeave(requestObject)),
  };
}

const LeaveTypesContainer = connect(mapStateToProps, mapDispatchToProps)(ApplyLeaveModal);

export default LeaveTypesContainer;
