import React from 'react';

const RequiredField = (props) => (
  <span>
    {props.children}
    <span style={{ color: 'red' }}> * </span>
  </span>
);

export default RequiredField;
