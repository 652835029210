import { EnhancedIcon } from 'components/shared/antd';
import React, { Component } from 'react';

import 'components/BarCard.css';

class BarCard extends Component {
  onClick = () => {
    const { selectedWorkId, date, details } = this.props;
    if (this.props.onEditWorkLogClicked) {
      this.props.onEditWorkLogClicked(selectedWorkId, date, details);
    }
  };

  renderBars(project, index) {
    const hours = project.hours || project.percentage;
    let { color } = project;
    const { projects } = this.props;
    if (!color) {
      const projectInfo =
        projects && projects.find((projectObj) => projectObj.id === project.projectId);
      color = (projectInfo && `#${projectInfo.color}`) || '#eee';
    }
    const styleForBar = {
      backgroundColor: color,
      width: `${(hours / this.props.total) * 100}%`,
    };

    return <div key={index} style={styleForBar} className="bar-part" />;
  }

  renderText(project, index) {
    const hours = project.hours || project.percentage;
    const styleForBar = {
      width: `${(hours / this.props.total) * 100}%`,
      color: '#001529',
      verticalAlign: 'top',
      marginTop: '5px',
    };

    return (
      <div key={index} style={styleForBar} className="bar-part">
        {`${project.projectName} ${parseFloat((hours / this.props.total) * 100).toFixed(0)}%`}
      </div>
    );
  }

  render() {
    const projectsDetail = this.props.details || [];
    const editWorkIcon = (
      <div
        className="edit-button"
        role="button"
        tabIndex={0}
        onClick={this.onClick}
        onKeyPress={this.onClick}
      >
        <EnhancedIcon type="edit" className="edit-button-icon" />
        Edit
      </div>
    );
    return (
      <div style={{ marginLeft: '10%' }}>
        {this.props.onEditWorkLogClicked && editWorkIcon}
        <div className="bar-container">
          {projectsDetail.map((project, index) => this.renderBars(project, index))}
        </div>
        <div className="bar-container bar-container-text">
          {projectsDetail.map((project, index) => this.renderText(project, index))}
        </div>
      </div>
    );
  }
}

export default BarCard;
