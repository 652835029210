import React from 'react';
import { Dropdown } from 'antd';
import PropTypes from 'prop-types';

const EnhancedDropdown = (props) => {
  const { className, style, menu, trigger, children, ...rest } = props;

  return (
    <Dropdown className={className} style={style} menu={menu} trigger={trigger} {...rest}>
      {children}
    </Dropdown>
  );
};

EnhancedDropdown.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  menu: PropTypes.node.isRequired,
  trigger: PropTypes.arrayOf(PropTypes.string),
};

EnhancedDropdown.defaultProps = {
  className: '',
  style: '',
  trigger: ['hover'],
};

export default EnhancedDropdown;
