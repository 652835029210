import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedIcon,
  EnhancedDivider,
  EnhancedSpin,
} from 'components/shared/antd';
import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';
import defaultImageURL from 'images/default-placeholder.png';
import './NewsGridPlaceholder.css';

class NewsGridPlaceholder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      response: [],
    };
  }
  render() {
    const { data, loader, showMoreButton } = this.props;
    return (
      <Fragment>
        <div>
          <h2 className="news-grid-heading">NEWS</h2>
          <EnhancedDivider />
          <div className="clear-fix" />
          {loader ? (
            <div className="box">
              <div>
                <EnhancedSpin tip="loading" />
              </div>
            </div>
          ) : (
            <EnhancedRow type="flex" justify="start">
              {data &&
                data.map((news) => (
                  <EnhancedCol
                    className="border_radisus"
                    key={news.id}
                    span={8}
                    style={{ cursor: 'pointer' }}
                  >
                    <Link to={`/news/${news.id}`} style={{ all: 'unset' }}>
                      <EnhancedRow type="flex" justify="start" style={{ flexDirection: 'column' }}>
                        <EnhancedCol span={24}>
                          <img
                            src={news.coverImage || defaultImageURL}
                            alt="No News"
                            style={{ width: '100%', height: '260px', objectFit: 'cover' }}
                          />
                        </EnhancedCol>
                        <EnhancedCol span={24} style={{ padding: '5%', backgroundColor: 'white' }}>
                          <div>{news.categories}</div>
                          <h2 className="text_ellipsis" style={{ color: '#4A4A4A' }}>
                            {news.title}
                          </h2>
                          <div>
                            <EnhancedIcon type="calendar" style={{ marginRight: '5px' }} />
                            {news.date}
                          </div>
                        </EnhancedCol>
                      </EnhancedRow>
                    </Link>
                  </EnhancedCol>
                ))}
            </EnhancedRow>
          )}
          {data && showMoreButton ? (
            <EnhancedButton block="true" className="btn_center" onClick={this.props.handleViewMore}>
              View More ...
            </EnhancedButton>
          ) : (
            ''
          )}
        </div>
      </Fragment>
    );
  }
}

export default NewsGridPlaceholder;
