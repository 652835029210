export const ADD_EXPENSE_CLAIM_INIT = 'ADD_EXPENSE_CLAIM_INIT';
export const ADD_EXPENSE_CLAIM_SUCCESS = 'ADD_EXPENSE_CLAIM_SUCCESS';
export const ADD_EXPENSE_CLAIM_FAILURE = 'ADD_EXPENSE_CLAIM_FAILURE';

export const GET_CLAIMS_INIT = 'GET_CLAIMS_INIT';
export const GET_CLAIMS_SUCCESS = 'GET_CLAIMS_SUCCESS';
export const GET_CLAIMS_FAILURE = 'GET_CLAIMS_FAILURE';

export const CLAIM_PROJECT_LIST_SUCCESS = 'CLAIM_PROJECT_LIST_SUCCESS';
export const CLAIM_PROJECT_LIST_FAILURE = 'CLAIM_PROJECT_LIST_FAILURE';

export const GET_TEAM_CLAIMS_INIT = 'GET_TEAM_CLAIMS_INIT';
export const GET_TEAM_CLAIMS_SUCCESS = 'GET_TEAM_CLAIMS_SUCCESS';
export const GET_TEAM_CLAIMS_FAILURE = 'GET_TEAM_CLAIMS_FAILURE';

export const GET_CLAIM_FOR_FINANCE_INIT = 'GET_CLAIM_FOR_FINANCE_INIT';
export const GET_CLAIM_FOR_FINANCE_SUCCESS = 'GET_CLAIM_FOR_FINANCE_SUCCESS';
export const GET_CLAIM_FOR_FINANCE_FAILURE = 'GET_CLAIM_FOR_FINANCE_FAILURE';

export const GET_CLAIM_FOR_HR_INIT = 'GET_CLAIM_FOR_HR_INIT';
export const GET_CLAIM_FOR_HR_SUCCESS = 'GET_CLAIM_FOR_HR_SUCCESS';
export const GET_CLAIM_FOR_HR_FAILURE = 'GET_CLAIM_FOR_HR_FAILURE';

export const GET_CLAIM_TYPES_SUCCESS = 'GET_CLAIM_TYPES_SUCCESS';
export const GET_CLAIM_TYPES_FAILURE = 'GET_CLAIM_TYPES_FAILURE';

export const GET_CLAIM_IMAGES_SUCCESS = 'GET_CLAIM_IMAGES_SUCCESS';
export const GET_CLAIM_IMAGES_FAILURE = 'GET_CLAIM_IMAGES_FAILURE';

export const APPROVAL_STATUS_INIT = 'APPROVAL_STATUS_INIT';
export const APPROVAL_STATUS_SUCCESS = 'APPROVAL_STATUS_SUCCESS';
export const APPROVAL_STATUS_FAILURE = 'APPROVAL_STATUS_FAILURE';
