/* eslint-disable */
import {
  EnhancedRow,
  EnhancedCol,
  EnhancedDatePicker,
  EnhancedInput,
  EnhancedCheckbox,
} from 'components/shared/antd';
import React, { Component } from 'react';
import {
  // RESOURCETYPE,
  // dateFormat,
  TADA_LOCAL,
  TADA_INTERNATIONAL,
  EMPLOYEE_EXPENSE_CLAIM,
} from 'configs/constants';
import moment from 'moment';

const { RangePicker } = EnhancedDatePicker;
const INPUT_MAX_LENGTH = 250;
const AMOUNT_MAX = 1000000;

class ExpenseClaimInputGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkBoxSelection: false,
      dateSelect: null,
      amount: null,
      description: '',
      maxCharLengthExceed: false,
    };
  }

  onClaimTypeSelect = () => {
    const { claimType } = this.props;
    const { checkBoxSelection } = this.state;
    this.setState({
      checkBoxSelection: !this.state.checkBoxSelection,
    });
    this.props.checkBoxSelection(claimType.id, !this.state.checkBoxSelection);
    // If box in de-selected
    if (checkBoxSelection) {
      this.setState({
        dateSelect: null,
        amount: null,
      });
      // this.props.claimAmount(claimType.id, 0);
    }
  };

  onClaimDateChange = (date) => {
    const { claimType } = this.props;
    this.setState({
      // dateSelect: date,
    });
    this.props.claimDateSelected(claimType.id, date);
  };

  onRangeChange = (date) => {
    const { claimType } = this.props;
    if (
      claimType.id === EMPLOYEE_EXPENSE_CLAIM.TADA_LOCAL.id ||
      claimType.id === EMPLOYEE_EXPENSE_CLAIM.TADA_INTERNATIONAL.id
    ) {
      const fixedAmountForTADA =
        claimType.id === EMPLOYEE_EXPENSE_CLAIM.TADA_LOCAL.id ? TADA_LOCAL : TADA_INTERNATIONAL;
      const rangedDate = date;
      const dayCount = rangedDate[1].diff(rangedDate[0], 'days') + 1;
      const calcAmount = dayCount * fixedAmountForTADA;
      this.setState({
        dateSelect: date,
        amount: calcAmount,
      });
      this.props.claimDateRangeSelected(claimType.id, date, calcAmount);
    } else {
      this.setState({
        dateSelect: date,
      });
      this.props.claimDateRangeSelected(claimType.id, date);
    }
  };

  onAmountChange = (event) => {
    const { claimType } = this.props;
    const amount = event.target.value;
    if (amount && amount >= 1 && amount < AMOUNT_MAX) {
      this.setState({
        amount,
      });
      this.props.claimAmount(claimType.id, amount);
    } else if (!amount) {
      this.setState({
        amount: null,
      });
      this.props.claimAmount(claimType.id, null);
    }
  };

  otherClaimDescription = (event) => {
    const { claimType } = this.props;
    const description = event.target.value;
    this.setState(
      {
        description,
      },
      () => {
        if (this.state.description.length <= INPUT_MAX_LENGTH) {
          this.setState({ maxCharLengthExceed: false });
        } else {
          this.setState({ maxCharLengthExceed: true }, () => {
            this.setState({ description: this.state.description.slice(0, INPUT_MAX_LENGTH) });
          });
        }
      },
    );
    this.props.otherClaimDescription(claimType.id, description);
  };

  disabledDates = (current) =>
    current < moment().subtract(3, 'month') || current > moment().endOf('day');

  shouldAmountDisabled = () => {
    const { claimType } = this.props;
    const { checkBoxSelection } = this.state;
    let shouldDisable = false;
    // Complex checking because of shared component for multiple conditions
    // Amount field should be open if Other/Training
    // Amount field should be dislabled for TADA LOCAL/INT
    // Amount Should be disable until type is selected e.g checkBoxSelection
    if (
      claimType.id === EMPLOYEE_EXPENSE_CLAIM.TADA_LOCAL.id ||
      claimType.id === EMPLOYEE_EXPENSE_CLAIM.TADA_INTERNATIONAL.id
    ) {
      shouldDisable = false;
    } else if (
      claimType.id === EMPLOYEE_EXPENSE_CLAIM.OTHER.id ||
      claimType.id === EMPLOYEE_EXPENSE_CLAIM.TRAINING.id
    ) {
      shouldDisable = false;
    } else {
      shouldDisable = !checkBoxSelection;
    }
    return shouldDisable;
  };

  render() {
    const { checkBoxSelection, description, amount, maxCharLengthExceed, dateSelect } = this.state;
    const { claimType } = this.props;
    return (
      <EnhancedRow className="marginBottom12">
        <EnhancedCol lg={10}>
          {claimType.id === EMPLOYEE_EXPENSE_CLAIM.OTHER.id ||
          claimType.id === EMPLOYEE_EXPENSE_CLAIM.TRAINING.id ? (
            <React.Fragment>
              <EnhancedInput
                style={{ width: '85%' }}
                value={description}
                type="text"
                placeholder="Enter claim description here"
                onChange={(e) => {
                  this.otherClaimDescription(e);
                }}
              />
              {maxCharLengthExceed ? (
                <span style={{ color: 'red', display: 'block' }}>
                  Comment maximume limit exceeded
                </span>
              ) : null}
            </React.Fragment>
          ) : (
            <span>
              <EnhancedCheckbox
                onChange={() => {
                  this.onClaimTypeSelect(claimType);
                }}
                checked={this.state.checkBoxSelection}
              />
              &nbsp;
              {claimType.value}
            </span>
          )}
        </EnhancedCol>
        <EnhancedCol lg={6}>
          {/* TADA Local OR International */}
          {/* {claimType.id === 15 || claimType.id === 16 ? */}
          <RangePicker
            ranges={{
              Today: [moment(), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
            }}
            value={dateSelect}
            onChange={this.onRangeChange}
            disabled={
              claimType.id === EMPLOYEE_EXPENSE_CLAIM.OTHER.id ||
              claimType.id === EMPLOYEE_EXPENSE_CLAIM.TRAINING.id
                ? false
                : !checkBoxSelection
            }
            disabledDate={this.disabledDates}
          />
          {/* :
            <EnhancedDatePicker
              disabled={(claimType.id === EMPLOYEE_EXPENSE_CLAIM.OTHER.id
              || claimType.id === EMPLOYEE_EXPENSE_CLAIM.TRAINING.id) ? false : !checkBoxSelection}
              disabledDate={this.disabledDates}
              value={dateSelect}
              format={dateFormat.DDMMMYYYYwithSpace}
              style={{ width: '100%' }}
              onChange={this.onClaimDateChange}
              allowClear
            />} */}
        </EnhancedCol>
        <EnhancedCol lg={2} />
        <EnhancedCol lg={6}>
          <EnhancedInput
            value={amount}
            disabled={this.shouldAmountDisabled()}
            type="number"
            placeholder="Amount in PKR"
            onChange={this.onAmountChange}
          />
        </EnhancedCol>
      </EnhancedRow>
    );
  }
}

export default ExpenseClaimInputGroup;
