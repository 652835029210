import { EnhancedButton, EnhancedForm } from 'components/shared/antd';
import React from 'react';
import {
  TRACKING as stateName,
  NOTICE_PERIOD_TYPES,
  RESIGNED_TYPES,
  RESIGNATION_PRIMARY_REASONS,
} from 'configs/employeeProfileConstants';
import { createTrackingInfo, noData } from 'utils/FieldGenerateUtils';
import { isEmptyObject, subtractObject, trimObjValues } from 'utils/objectUtils';

import Spinner from '../../shared/Spinner';

class Tracking extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      [stateName]: this.props[stateName] || {},
      dropDowns: {},
      isEdit: false,
      disableSave: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps[stateName] !== this.props[stateName]) {
      this.setState({ [stateName]: nextProps[stateName] });
    }

    this.setState({
      dropDowns: {
        noticePeriodTypeOptions: NOTICE_PERIOD_TYPES,
        resignationTypeOptions: RESIGNED_TYPES,
        primaryReasonForLeavingOptions: RESIGNATION_PRIMARY_REASONS,
      },
    });
  }

  handleEdit = () => {
    this.setState({ isEdit: true, disableSave: true });
  };

  handleCancel = () => {
    this.setState({
      isEdit: false,
      [stateName]: this.props[stateName],
    });
  };

  handleSave = (e) => {
    e.preventDefault();
    if (!this.state.disableSave) {
      this.setState({ isEdit: false });

      const updatedObject = trimObjValues(
        subtractObject(this.state[stateName], this.props[stateName]),
        ['joiningDate'],
      );
      this.props.updateEmployeeTrackingInfo(this.props.employeeId, updatedObject);
    }
  };

  handleChange = (value, key, error) => {
    const currentData = this.state[stateName];
    const newData = Object.assign({}, currentData, { [key]: value });
    this.setState({ [stateName]: newData, error: false, disableSave: false });
    if (error) {
      this.setState({ disableSave: true });
    }
  };

  render() {
    const { tracking, dropDowns, isEdit, disableSave } = this.state;
    const { noticePeriodTypeOptions, resignationTypeOptions, primaryReasonForLeavingOptions } =
      dropDowns;
    const { userPermissions, cardLoading } = this.props;
    const isEmpty = isEmptyObject(tracking);
    const canEdit = false;

    return (
      <React.Fragment>
        {cardLoading ? (
          <Spinner tip="loading" />
        ) : (
          <EnhancedForm onSubmit={this.handleSave}>
            <div className="flex justify-flex-end pos-abs-top-right">
              <span>
                {canEdit && !isEmpty && !isEdit ? (
                  <EnhancedButton onClick={this.handleEdit}>Edit</EnhancedButton>
                ) : (
                  ''
                )}
              </span>
              <span className="pr-10">
                {canEdit && isEdit ? (
                  <EnhancedButton htmlType="submit" disabled={disableSave}>
                    Save
                  </EnhancedButton>
                ) : (
                  ''
                )}
              </span>
              <span>
                {canEdit && isEdit ? (
                  <EnhancedButton onClick={this.handleCancel}>Cancel</EnhancedButton>
                ) : (
                  ''
                )}
              </span>
            </div>
            {!isEmpty ? (
              <div className="mt-20">
                {createTrackingInfo(
                  tracking,
                  noticePeriodTypeOptions,
                  resignationTypeOptions,
                  primaryReasonForLeavingOptions,
                  isEdit,
                  userPermissions,
                  this.handleChange,
                )}
              </div>
            ) : (
              noData()
            )}
          </EnhancedForm>
        )}
      </React.Fragment>
    );
  }
}

export default Tracking;
