import React from 'react';
import validator from 'validator';

import {
  REQUIRED,
  NUMBERS_ONLY,
  POSITIVE_NUMBERS_ONLY,
  EMAIL,
  FILE_NAME,
} from 'configs/validationConstants';
import {
  REQUIRED_ERROR_MESSAGE,
  NUMBERS_ONLY_ERROR_MESSAGE,
  EMAIL_ERROR_MESSAGE,
  FILE_NAME_ERROR_MESSAGE,
  POSITIVE_NUMBERS_ONLY_ERROR_MESSAGE,
} from 'configs/messageConstants';

export const generateErrorObject = (object, exclusions = []) => {
  if (object) {
    const keys = Object.keys(object);
    const errorObject = {};
    for (let index = 0; index < keys.length; index += 1) {
      const currentKey = keys[index];
      if (exclusions.length) {
        if (!exclusions.includes(currentKey)) errorObject[currentKey] = false;
      } else {
        errorObject[currentKey] = false;
      }
    }
    return errorObject;
  }
  return {};
};

export const generateErrorsArrayFromJSON = (data, keysToExclude) => {
  if (data.length) {
    const errors = data.map((object) => generateErrorObject(object, keysToExclude));
    return errors;
  }
  return [];
};

export const fieldValidator = (value, validations) => {
  if (validations && validations.length) {
    let error = '';
    let isValid;

    // Validate to create appropiate error message
    for (let index = 0; index < validations.length; index += 1) {
      switch (validations[index]) {
        case REQUIRED:
          // Consider 0 truthy
          isValid = !!value || value === 0;
          if (typeof value === 'string') {
            isValid = value && !(value.trim() === '');
          }
          error = isValid ? '' : validations[index];
          break;

        case NUMBERS_ONLY:
          if (value) {
            const valueStr = String(value);
            isValid = validator.isNumeric(valueStr);
            error = isValid ? '' : validations[index];
          }
          break;

        case EMAIL:
          if (value) {
            isValid = validator.isEmail(value);
            error = isValid ? '' : validations[index];
          }
          break;
        case FILE_NAME:
          if (value) {
            isValid = !/[^a-z0-9_@()-\s]/i.test(value);
            error = isValid ? '' : validations[index];
          }
          break;

        case POSITIVE_NUMBERS_ONLY:
          if (value !== '' && value !== null) {
            isValid = value > 0;
            error = isValid ? '' : validations[index];
          }
          break;

        default:
          error = '';
      }
      if (error) {
        return error;
      }
    }
  }
  return false;
};

export const createErrorMessage = ({ value, validations }) => {
  let errorMessage = '';
  const failedValidation = fieldValidator(value, validations);
  switch (failedValidation) {
    case REQUIRED:
      errorMessage = REQUIRED_ERROR_MESSAGE;
      break;
    case NUMBERS_ONLY:
      errorMessage = NUMBERS_ONLY_ERROR_MESSAGE;
      break;
    case EMAIL:
      errorMessage = EMAIL_ERROR_MESSAGE;
      break;
    case FILE_NAME:
      errorMessage = FILE_NAME_ERROR_MESSAGE;
      break;
    case POSITIVE_NUMBERS_ONLY:
      errorMessage = POSITIVE_NUMBERS_ONLY_ERROR_MESSAGE;
      break;
    default:
      errorMessage = '';
  }
  if (errorMessage) {
    return <p style={{ color: 'red' }}>{errorMessage}</p>;
  }
  return errorMessage;
};
