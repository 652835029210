import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';

const EnhancedTypography = (props) => {
  const { children, style, copyable } = props;

  return (
    <Typography.Paragraph style={style} copyable={copyable}>
      {children}
    </Typography.Paragraph>
  );
};

EnhancedTypography.propTypes = {
  style: PropTypes.shape({}),
  copyable: PropTypes.bool,
};

EnhancedTypography.defaultProps = {
  style: {},
  copyable: false,
};

EnhancedTypography.Text = Typography.Text;

export default EnhancedTypography;
