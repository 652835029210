import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getNewsAction } from 'store/actions/NewsActions';
import { PER_PAGE_GRID } from 'configs/cmsConstants';

// CSS
import './NewsGridView.css';
import NewsGridPlaceholder from './NewsGridPlaceholder';

function NewsGridView(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        allNews: props.news,
        loader: false,
      };
      this.page = 1;
      this.showMoreButton = true;
    }

    componentWillMount() {
      this.setState({
        loader: true,
      });
      this.props.getGridViewNews(PER_PAGE_GRID, this.page);
    }

    componentWillReceiveProps(nextProps) {
      if (parseInt(nextProps.newsCount, 10) === nextProps.allNews.length) {
        this.showMoreButton = false;
      }
      if (nextProps.newsCount >= nextProps.allNews.length) {
        if (nextProps.allNews !== this.props.allNews) {
          const { allNews } = nextProps;
          const { loader } = nextProps;
          this.setState({ allNews, loader });
        }
      }
    }

    handleViewMore = () => {
      this.page += 1;
      this.props.getGridViewNews(PER_PAGE_GRID, this.page);
    };

    render() {
      return (
        <WrappedComponent
          title="News"
          filterOptions={false}
          data={this.state.allNews}
          loader={this.state.loader}
          handleViewMore={this.handleViewMore}
          showMoreButton={this.showMoreButton}
        />
      );
    }
  };
}

function mapStateToProps(state) {
  const { newsReducer } = state;
  return {
    allNews: newsReducer.allNews,
    // loader: newsReducer.loader,
    newsCount: newsReducer.totalNewsCount,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getGridViewNews: (count, page) => dispatch(getNewsAction(count, page)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsGridView(NewsGridPlaceholder));
