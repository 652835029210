import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getEvents } from 'store/actions/HomePageActions';

// CSS
import './UpcomingEvents.css';
import EventsPlaceholder from './EventsPlaceholder';

function UpcomingEvents(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        events: props.events,
      };
    }

    componentDidMount() {
      this.props.getEvents();
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.events !== this.props.events) {
        const { events } = nextProps;
        this.setState({ events });
      }
    }

    render() {
      return (
        <WrappedComponent title="Upcoming Events" filterOptions={false} data={this.state.events} />
      );
    }
  };
}

function mapStateToProps(state) {
  const { appReducer } = state;
  return {
    events: appReducer.events,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEvents: () => dispatch(getEvents()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingEvents(EventsPlaceholder));
