import React from 'react';
import { INPUT, INFO as stateName, DROP_DOWNS } from 'configs/employeeProfileConstants';
import { REQUIRED } from 'configs/validationConstants';
import {
  createFields,
  createSubHeading,
  // generateErrorObject, // TODO: This many not be needed
} from 'utils/FieldGenerateUtils';

class Addresses extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      [stateName]: {},
      // [`${stateName}Errors`]: {}, // TODO: This many not be needed
      [DROP_DOWNS]: {},
      isEdit: false,
      disableSaveButton: false,
    };
  }

  componentDidMount() {
    this.initializeValues(this.props[stateName]);
    this.initializeDropDowns(this.props[DROP_DOWNS]);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps[stateName] !== this.props[stateName]) {
      this.initializeValues(nextProps[stateName]);
      // this.initializeDropDowns(nextProps[DROP_DOWNS]);
      // TODO: This may not be needed -> Generates error state for each value in state
      // this.initializeErrors(nextProps[propsName]);
    }
  }

  initializeValues = (props) => this.setState({ [stateName]: props });
  initializeDropDowns = (props) => this.setState({ dropDowns: { ...props } });

  render() {
    const { userPermissions } = this.props;
    const { isEdit } = this.props;
    const data = this.state[stateName];

    return (
      <div className="mt-20 pos-rel">
        {createSubHeading('Present Address')}
        <div className="mt-20" />
        {createFields(
          [
            {
              key: 'presentAddress',
              label: 'Address',
              value: data.presentAddress,
              type: INPUT,
              validations: [REQUIRED],
            },
            {
              key: 'presentCity',
              label: 'City',
              value: data.presentCity,
              type: INPUT,
              validations: [REQUIRED],
            },
            {
              key: 'presentProvince',
              label: 'Province',
              value: data.presentProvince,
              type: INPUT,
            },
            {
              key: 'presentCountry',
              label: 'Country',
              value: data.presentCountry,
              type: INPUT,
              validations: [REQUIRED],
            },
            {
              key: 'presentPostalCode',
              label: 'Zip Code',
              value: data.presentPostalCode,
              type: INPUT,
            },
          ],
          2,
          isEdit,
          userPermissions,
          this.props.handleChange,
        )}
        {createSubHeading('Permanent Address')}
        <div className="mt-20" />
        {createFields(
          [
            {
              key: 'permanentAddress',
              label: 'Address',
              value: data.permanentAddress,
              type: INPUT,
              validations: [REQUIRED],
            },
            {
              key: 'permanentCity',
              label: 'City',
              value: data.permanentCity,
              type: INPUT,
              validations: [REQUIRED],
            },
            {
              key: 'permanentProvince',
              label: 'Province',
              value: data.permanentProvince,
              type: INPUT,
            },
            {
              key: 'permanentCountry',
              label: 'Country',
              value: data.permanentCountry,
              type: INPUT,
              validations: [REQUIRED],
            },
            {
              key: 'permanentPostalCode',
              label: 'Zip Code',
              value: data.permanentPostalCode,
              type: INPUT,
            },
          ],
          2,
          isEdit,
          userPermissions,
          this.props.handleChange,
        )}
      </div>
    );
  }
}

export default Addresses;
