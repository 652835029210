import { connect } from 'react-redux';
import { deallocateEmployeeAction } from 'store/actions/EmployeeActions';

import EmployeesProject from 'components/employees/EmployeesProject';

function mapStateToProps(state) {
  const { auth, employeeReducer } = state;
  return {
    userId: auth.user && auth.user.id,
    readOnly: auth.user.isReadOnly,
    employeesProject: employeeReducer.employeesProject || [],
    isProjectLoading: employeeReducer.isProjectLoading,
    userPermissions: auth.user && auth.user.permissions,
  };
}

const mapDispatchToProps = (dispatch) => ({
  deallocateEmployee: (
    employeeProjectId,
    employeeId,
    projectId = null,
    comment = null,
    endDate = null,
    deleteEntry = false,
  ) =>
    dispatch(
      deallocateEmployeeAction(
        employeeProjectId,
        employeeId,
        projectId,
        comment,
        endDate,
        deleteEntry,
      ),
    ),
});

const EmployeesProjectContainer = connect(mapStateToProps, mapDispatchToProps)(EmployeesProject);

export default EmployeesProjectContainer;
