import _ from 'underscore';

export const capitalizeString = (type) =>
  type ? type.substring(0, 1).toUpperCase() + type.substring(1).toLowerCase() : '';

export const escapeRegexFromString = (string) => {
  const matchOperatorsRegex = /[|\\{}()[\]^$+*?.-]/g;
  if (typeof string !== 'string') {
    throw new TypeError('Expected a string');
  }

  return string.replace(matchOperatorsRegex, '\\$&');
};

export const capitalizeHyphenatedString = (string) => {
  const subWords = string.includes(' ') ? string.split(' ') : string.split('-');
  const converted = subWords.map((element) => capitalizeString(element));
  return converted.join(' ');
};

export const parse = (constant, values) => {
  const template = _.template(constant);
  return template(values);
};

export const parseSearchParams = (search) => {
  const params = {
    text: '',
    column: '',
  };
  try {
    const tokens = search.replace('?', '').split('=');
    if (tokens && tokens.length === 2) {
      [params.column, params.text] = tokens;
    }
    return params;
  } catch (err) {
    return params;
  }
};

export const getInitials = (fullName) => {
  if (!fullName) return '';
  const splitNameArray = fullName.split(' ');
  const intialsArray = splitNameArray.map((nameParts) => nameParts[0]);
  return intialsArray.slice(0, 2).join(' ');
};
