import { connect } from 'react-redux';
import PRProject from 'components/pc/PRProject';
import updateBreadCrumb from 'store/actions/BreadCrumbActions';
import {
  getProjectReviewsByCycleIdAction,
  downloadResourceRatingsAction,
} from '../store/actions/ProjectReviewsActions';

function mapStateToProps(state) {
  const { projectReviewsReducer } = state;
  return {
    projectCycleData: projectReviewsReducer.projectCycleData,
    isLoading: projectReviewsReducer.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectReviewsByCycleIdAction: (cycleId) =>
      dispatch(getProjectReviewsByCycleIdAction(cycleId)),
    updateBreadCrumb: (key, oldVal, newVal) => dispatch(updateBreadCrumb(key, oldVal, newVal)),
    downloadResourceRatingsAction: (data) => dispatch(downloadResourceRatingsAction(data)),
  };
}

const PRProjectContainer = connect(mapStateToProps, mapDispatchToProps)(PRProject);

export default PRProjectContainer;
