import {
  GET_DESIGNATION_INIT,
  GET_DESIGNATION_SUCCESS,
  GET_DESIGNATION_FAILURE,
  CREATE_DESIGNATION_INIT,
  CREATE_DESIGNATION_SUCCESS,
  CREATE_DESIGNATION_FAILURE,
  DELETE_DESIGNATION_INIT,
  DELETE_DESIGNATION_SUCCESS,
  DELETE_DESIGNATION_FAILURE,
  UPDATE_DESIGNATION_INIT,
  UPDATE_DESIGNATION_SUCCESS,
  UPDATE_DESIGNATION_FAILURE,
} from 'constants/DesignationTypes';
import {
  getAllDesignations,
  createNewDesignation,
  deleteDesignation,
  updateDesignation,
} from 'sources/DesignationsSource';

export const getDesignationInit = () => ({
  type: GET_DESIGNATION_INIT,
});

export const getDesignationSuccess = (data) => ({
  type: GET_DESIGNATION_SUCCESS,
  data,
});

export const getDesignationFailure = () => ({
  type: GET_DESIGNATION_FAILURE,
});

export const createDesignationInit = () => ({
  type: CREATE_DESIGNATION_INIT,
});

export const createDesignationSuccess = (data) => ({
  type: CREATE_DESIGNATION_SUCCESS,
  data,
});

export const createDesignationFailure = () => ({
  type: CREATE_DESIGNATION_FAILURE,
});

export const deleteDesignationInit = () => ({
  type: DELETE_DESIGNATION_INIT,
});

export const deleteDesignationSuccess = (data) => ({
  type: DELETE_DESIGNATION_SUCCESS,
  data,
});

export const deleteDesignationFailure = () => ({
  type: DELETE_DESIGNATION_FAILURE,
});

export const updateDesignationInit = () => ({
  type: UPDATE_DESIGNATION_INIT,
});

export const updateDesignationSuccess = (data) => ({
  type: UPDATE_DESIGNATION_SUCCESS,
  data,
});

export const updateDesignationFailure = () => ({
  type: UPDATE_DESIGNATION_FAILURE,
});

// Async Actions

export const getAllDesignationActions = () => getAllDesignations();

export const createDesignationActions = (data) => createNewDesignation(data);

export const deleteDesignationActions = (data) => deleteDesignation(data);

export const updateDesignationActions = (data) => updateDesignation(data);
