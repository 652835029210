import { EnhancedCard, EnhancedLayout } from 'components/shared/antd';
import React, { useEffect } from 'react';
import ApprovedGoals from 'components/shared/PMDashboard/ApprovedGoals';
import SelfEvaluation from 'components/shared/PMDashboard/SelfEvaluation';
import ManagerEvaluation from 'components/shared/PMDashboard/ManagerEvaluation';
import EvaluationApproval from 'components/shared/PMDashboard/EvaluationApproval';
import Calibration from 'components/shared/PMDashboard/Calibration';
import TabsRenderer from 'components/shared/TabsRenderer';
import BulkGoalApproval from 'components/shared/PMDashboard/BulkGoalApproval';
import EvaluationsDashboard from 'components/shared/PMDashboard/EvaluationsDashboard';
import { PERFORMANCE_MANAGEMENT_ROUTES } from 'constants/UrlConstants';

const { Header } = EnhancedLayout;

const tabsData = [
  {
    key: 'bulk-goal-approval',
    title: 'Goals Dashboard',
    component: BulkGoalApproval,
  },
  {
    key: 'bulk-forms-approval',
    title: 'Forms Dashboard',
    component: EvaluationsDashboard,
  },
  {
    key: 'goals-approval',
    title: 'Goals Approval Status',
    component: ApprovedGoals,
  },
  {
    key: 'self-evaluation',
    title: 'Self Evaluation Status',
    component: SelfEvaluation,
  },
  {
    key: 'lm-evaluation',
    title: 'LM Evaluation Status',
    component: ManagerEvaluation,
  },
  {
    key: 'evaluation-approval',
    title: 'PM Forms Approval Status',
    component: EvaluationApproval,
  },
  {
    key: 'calibration',
    title: 'Calibration',
    component: Calibration,
  },
];

const PMDashboard = (props) => {
  const {
    getEmployeeDivisionsAction,
    getEmployeeDepartmentsAction,
    getEmployeeCompetenciesAction,
    getEmployeeBusinessUnitAction,
  } = props;

  useEffect(() => {
    getEmployeeDivisionsAction();
    getEmployeeDepartmentsAction();
    getEmployeeCompetenciesAction();
    getEmployeeBusinessUnitAction();
  }, []);

  return (
    <div>
      <Header
        style={{
          background: 'white',
          marginTop: '1%',
        }}
      >
        <h1>PM Dashboard</h1>
      </Header>
      <EnhancedCard loading={false}>
        <TabsRenderer
          tabsData={tabsData}
          isAdmin
          baseUrlForEvaluation={PERFORMANCE_MANAGEMENT_ROUTES.LINE_MANAGER_VIEW_EVALUATION}
          {...props}
        />
      </EnhancedCard>
    </div>
  );
};

export default PMDashboard;
