import {
  EnhancedCol,
  EnhancedButton,
  EnhancedIcon,
  EnhancedCard,
  EnhancedRow,
  EnhancedDivider,
  EnhancedLayout,
  EnhancedModal,
  EnhancedInput,
} from 'components/shared/antd';
import React, { Component, Fragment } from 'react';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';

const { confirm } = EnhancedModal;
const { Header } = EnhancedLayout;

class BusinessUnits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editVisible: false,
      addVisible: false,
      businessUnitName: '',
      editBusinessUnitName: '',
      selectedRecord: null,
      businessUnits: this.props.businessUnits || [],
    };
  }

  componentDidMount() {
    this.props.getEmployeeBusinessUnitAction();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.businessUnits !== this.props.businessUnits) {
      this.setState({ businessUnits: nextProps.businessUnits });
    }
  }

  getColumnsInfo = () => {
    const columns = [
      {
        title: 'Business Unit',
        dataIndex: 'name',
        key: 'name',
        width: '10%',
        filterConfig: {
          type: 'search',
          key: 'name',
        },
      },
      {
        title: 'Action',
        key: 'action',
        width: '5%',
        render: (text, record) => (
          <span>
            <EnhancedIcon
              type="edit"
              data-testid="edit-icon"
              style={{ cursor: 'pointer' }}
              onClick={() => this.showEditBusinessUnitModal(record)}
            />
            <EnhancedDivider type="vertical" />
            <EnhancedIcon
              type="delete"
              data-testid="delete-icon"
              style={{ cursor: 'pointer' }}
              onClick={() => this.showDeleteBusinessUnitModal(record)}
            />
          </span>
        ),
      },
    ];
    return columns;
  };

  showEditBusinessUnitModal = (record) => {
    this.setState({
      editVisible: true,
      selectedRecord: record,
      editBusinessUnitName: record.name,
    });
  };

  hideEditBusinessUnitModal = () => {
    this.setState({
      editVisible: false,
      addVisible: false,
      selectedRecord: null,
      editBusinessUnitName: '',
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleAddBusinessUnit = () => {
    const { businessUnitName } = this.state;
    const { addBusinessUnit } = this.props;
    addBusinessUnit({ name: businessUnitName });
    this.setState({ businessUnitName: '', addVisible: false });
  };

  handleEditBusinessUnit = () => {
    const { selectedRecord, editBusinessUnitName } = this.state;
    const { editBusinessUnit } = this.props;
    editBusinessUnit({ id: selectedRecord.id, name: editBusinessUnitName });
    this.setState({ selectedRecord: null, editBusinessUnitName: '', editVisible: false });
  };

  handleDeleteBusinessUnit = (record) => {
    const { deleteBusinessUnit } = this.props;
    deleteBusinessUnit({ id: record.id });
  };

  editBusinessUnit = () => {
    const { editVisible, addVisible, editBusinessUnitName, businessUnitName } = this.state;
    const businessUnitValue = editVisible ? editBusinessUnitName : businessUnitName;
    return (
      <EnhancedModal
        title={editVisible ? 'Edit Business Unit' : 'Add Business Unit'}
        visible={editVisible || addVisible}
        onCancel={this.hideEditBusinessUnitModal}
        footer={[
          <EnhancedButton key="back" onClick={this.hideEditBusinessUnitModal}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            key="submit"
            type="primary"
            onClick={editVisible ? this.handleEditBusinessUnit : this.handleAddBusinessUnit}
            disabled={!businessUnitValue || businessUnitValue === ''}
          >
            Save
          </EnhancedButton>,
        ]}
      >
        <EnhancedRow className="edit-skill">
          <EnhancedCol span={12}>
            <EnhancedInput
              name={editVisible ? 'editBusinessUnitName' : 'businessUnitName'}
              value={businessUnitValue}
              onChange={this.handleChange}
              placeholder="Enter Business Unit"
            />
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedModal>
    );
  };

  showAddBusinessUnitModal = () => {
    this.setState({ addVisible: true });
  };

  showDeleteBusinessUnitModal = (record) => {
    const { handleDeleteBusinessUnit } = this;
    confirm({
      title: 'Are you sure you want to delete this Business unit?',
      content: 'Note: Business Unit associated with employees cannot be deleted',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteBusinessUnit(record);
      },
    });
  };

  render() {
    const columns = this.getColumnsInfo();
    const { businessUnits } = this.state;
    const { isLoading } = this.props;
    return (
      <Fragment>
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol lg={12}>
              <h1 className="heading-pro">Business Unit</h1>
            </EnhancedCol>
            <EnhancedCol span={6} offset={6}>
              <EnhancedButton
                type="primary"
                onClick={this.showAddBusinessUnitModal}
                style={{ float: 'right', marginTop: '5%' }}
              >
                {' '}
                Add New Business Unit
              </EnhancedButton>
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        {this.editBusinessUnit()}
        <div style={{ background: 'white' }}>
          <EnhancedCard loading={isLoading} bordered={false}>
            <EnhancedRow />
          </EnhancedCard>
          <div>
            <EnhancedClientSideTable
              style={{ marginLeft: '25px' }}
              loading={isLoading}
              columns={columns}
              data={businessUnits}
              exportFileConfig={{
                fileName: 'Business Units',
                fileHeaders: [
                  {
                    label: 'Business Unit',
                    key: 'name',
                  },
                ],
                showExportButton: true,
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default BusinessUnits;
