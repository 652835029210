export function normalizeRoleEmployees(res) {
  const role = {};
  role.id = res.id;
  role.role = res.role;
  const employeesRoles = [];
  res.employee_roles.forEach((er) => {
    if (er.employee) {
      const employeeRole = {};
      employeeRole.id = er.id;
      employeeRole.employeeId = er.employee.id;
      employeeRole.name = er.employee.fullName;
      employeeRole.employeeDesignation = er.employee.employeeDesignation;
      employeeRole.roleId = role.id;
      employeesRoles.push(employeeRole);
    }
  });
  role.employeeRoles = employeesRoles;
  return role;
}

export function normalizeDDLEmployees(res) {
  return res.map((emp) => ({
    id: emp.id,
    name: `${emp.fullName} - ${emp.employeeDesignation ? emp.employeeDesignation.name : ''}`,
  }));
}
