import axios from 'axios';
import {
  getLocationInit,
  getLocationSuccess,
  getLocationFailure,
  createLocationInit,
  createLocationSuccess,
  createLocationFailure,
  updateLocationInit,
  updateLocationSuccess,
  updateLocationFailure,
  deleteLocationInit,
  deleteLocationSuccess,
  deleteLocationFailure,
} from 'store/actions/LocationActions';
import { EnhancedNotification } from 'components/shared/antd';
import { locationUrls } from 'configs/constants';

const cache = {};

export const getAllLocations = () => (dispatch) => {
  const url = locationUrls.GET;
  dispatch(getLocationInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        cache.locations = response.data.data;
        dispatch(getLocationSuccess(response.data.data));
      } else {
        dispatch(getLocationFailure());
      }
    })
    .catch(() => {
      dispatch(getLocationFailure());
    });
};

export const createNewLocation = (request) => (dispatch) => {
  const url = locationUrls.POST;
  dispatch(createLocationInit());
  axios
    .post(url, request)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Created Successfully',
        });
        dispatch(createLocationSuccess(response.data.data));
      } else {
        EnhancedNotification.error({
          message: 'Error in creating a new location',
        });
        dispatch(createLocationFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Entered location already exists. Cannot add it again',
      });
      dispatch(createLocationFailure());
    });
};

export const updateLocation = (request) => (dispatch) => {
  const url = locationUrls.PUT.replace('{locationId}', request.id);
  dispatch(updateLocationInit());
  axios
    .put(url, request)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Updated Successfully',
        });
        dispatch(updateLocationSuccess(request));
      } else {
        EnhancedNotification.error({
          message: 'Error in editing the location',
        });
        dispatch(updateLocationFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Entered location already exists. Cannot add it again',
      });
      dispatch(updateLocationFailure());
    });
};

export const deleteLocation = (request) => (dispatch) => {
  const url = locationUrls.DELETE.replace('{locationId}', request.id);
  dispatch(deleteLocationInit());
  axios
    .delete(url, { data: request })
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Deleted Successfully',
        });
        dispatch(deleteLocationSuccess(request.id));
      } else {
        EnhancedNotification.error({
          message: 'Cannot delete because the location is associated with employees',
        });
        dispatch(deleteLocationFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Cannot delete because the location is associated with employees',
      });
      dispatch(deleteLocationFailure());
    });
};
