import {
  EnhancedButton,
  EnhancedCol,
  EnhancedRow,
  EnhancedCard,
  EnhancedTag,
  EnhancedTabs,
} from 'components/shared/antd';
import React, { Component } from 'react';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import { parse } from 'utils/stringUtils';
import _ from 'underscore';
import { skillLevel, RESOURCETYPE, BREADCRUMBS, dateFormat, GENDERS } from 'configs/constants';
import SkillsBage from 'components/employees/SkillsBadge';
import EmployeeEditable from 'components/employees/EmployeeEditable';
import EmployeeEditableInputs from 'components/employees/EmployeeEditableInputs';
import EmployeeEditableSelect from 'components/employees/EmployeeEditableSelect';
import EmployeeEditableDate from 'components/employees/EmployeeEditableDate';
import EmployeesProjectContainer from 'containers/EmployeeProjectContainer';
import { hasPermission } from 'utils/AccessUtils';
import './EmployeeDetails.css';
import PROJECT_ROUTES from '../../constants/UrlConstants';
import AccessPermissions from '../../constants/AccessPermissions';

const { TabPane } = EnhancedTabs;
class EmployeeDetails extends Component {
  constructor(props) {
    super(props);
    const { employee } = props;
    if (employee.skills) employee.skills = this.setEmployeeSkills(employee.skills);
    this.state = {
      employee: props.employee || {},
      disable: true,
      locations: [],
      employeeType: undefined,
      saveChangesButtonDisable: false,
      userEmail: this.props.userEmail,
    };
  }

  componentWillMount() {
    // Employee detail screen route should have ID params.
    // For example the route of this screen is like "/employees/:status/:id"
    // in above route "id" is the param which is destructed in below line.
    const {
      Employee: { Update },
    } = AccessPermissions;
    const { id } = this.props.match.params;

    const { isLoading, employee, updateBreadCrumb } = this.props;
    if (!isLoading && !employee) this.props.history.push(PROJECT_ROUTES.EMPLOYEES_LISTING_ROUTE);
    if (hasPermission(this.props.permissions, [Update])) {
      this.props.getEmployeesSkillsAction(id);
      this.props.getEmployeeDepartments();
      this.props.getAllDesignations();
      this.props.getAllLocations();
      this.props.getEmployeeBusinessUnits();
      this.props.getEmployeeDivisionsAction();
      this.props.getEmployeeCompetenciesAction();
    }
    this.props.getEmployeesProjectActions(id);

    // When we redirect from project detail screen to employee detail screen then we don't
    // have the employee status wheather employee status is active or not, thats why we need
    // to update route accordingly after get employee detail in this component.
    if (employee && !_.isEmpty(employee) && !employee.isActive) {
      const link = parse(PROJECT_ROUTES.EMPLOYEE_DETAIL_ROUTE, {
        status: PROJECT_ROUTES.INACTIVE,
        id,
      });
      this.props.history.push(link);
    }
    if (employee.name) {
      updateBreadCrumb(BREADCRUMBS.EMPLOYEE, id, employee.name);
    }
  }
  componentWillReceiveProps(nextProps) {
    const { employee, updateBreadCrumb } = nextProps;
    if (employee !== this.state.employee) {
      const newEmployee = { ...employee };
      if (newEmployee.name) {
        updateBreadCrumb(BREADCRUMBS.EMPLOYEE, newEmployee.id, newEmployee.name);
      }
      if (employee.skills) {
        newEmployee.skills = this.setEmployeeSkills(newEmployee.skills);
        this.setState({ employee: newEmployee });
      }
    }
    if (this.props.locations !== nextProps.locations) {
      this.setState({ locations: nextProps.locations });
    }
  }

  onSavingChanges = () => {
    const { employee } = this.state;
    employee.location = employee.locationId;
    employee.userId = employee.id;
    employee.employeeId = this.props.userId;
    this.props.updateEmployeeSkillsActions(employee, false);
    this.setState({ disable: true });
  };

  onUpdateOfInput = (token, newValue) => {
    if (Array.isArray(newValue) && newValue.length === 0) {
      this.setState({
        saveChangesButtonDisable: true,
      });
    } else {
      this.setState({
        saveChangesButtonDisable: false,
      });
    }
    const { employee } = this.state;
    employee[token] = newValue;
    if (token === 'resignationDate' && newValue === null) {
      employee.leavingDate = newValue;
    }
    this.setState({
      employee,
    });
  };

  getEmployeesForSelect = () =>
    this.props.employees
      .filter((emp) => emp.id !== this.props.employee.id)
      .map((emp) => ({
        value: emp.name,
        key: emp.id,
      }));

  getLocationsForSelect = () =>
    this.state.locations.map((loc) => ({ value: loc.name, key: loc.id }));

  getDepartmentsForSelect = () => {
    const { departments } = this.props;
    if (departments && departments.length > 0) {
      return departments.map((department) => ({
        value: department.name,
        key: department.id,
      }));
    }
    return [];
  };
  getBusinessUnitsForSelect = () => {
    const { businessUnits } = this.props;
    if (businessUnits && businessUnits.length > 0) {
      return businessUnits.map((businessUnit) => ({
        value: businessUnit.name,
        key: businessUnit.id,
      }));
    }
    return [];
  };
  getDivisionForSelect = () => {
    const { divisions } = this.props;
    if (divisions && divisions.length > 0) {
      return divisions.map((division) => ({
        value: division.name,
        key: division.id,
      }));
    }
    return [];
  };
  getCompetencyForSelect = () => {
    const { competencies } = this.props;
    if (competencies && competencies.length > 0) {
      return competencies.map((competency) => ({
        value: competency.name,
        key: competency.id,
      }));
    }
    return [];
  };

  getDesignationsForSelect = () => {
    const { designations } = this.props;
    if (designations && designations.length > 0) {
      return designations.map((designation) => ({
        value: designation.name,
        key: designation.id,
      }));
    }
    return [];
  };

  getResourceTypesForSelect = () => RESOURCETYPE;

  setEmployeeSkills = (skills) =>
    skills
      .filter((skill) => skill.skill && skill.skill.name)
      .map((skill) => ({
        ...skill,
        ...skill.skill,
        id: skill.id,
        expertise: skill.expertise || skillLevel.BEGINNER,
      }));

  getPrimarySkills = () => {
    const { employee } = this.state;
    if (!employee.skills) return [];
    return employee.skills.filter((skill) => skill.expertise === skillLevel.EXPERT);
  };

  getSecondarySkills = () => {
    const { employee } = this.state;
    if (!employee.skills) return [];
    return employee.skills.filter((skill) => skill.expertise < skillLevel.EXPERT);
  };

  getSkillsRecords = (tags, removedTag = null) => {
    const { employee } = this.state;
    const filteredSkills = employee.skills.filter((skill) => skill.name !== removedTag);
    const skills = uniqBy([...filteredSkills, ...tags], 'name').filter(
      (skill) => skill.name !== removedTag,
    );
    employee.skills = skills;
    this.setState({ employee });
  };

  handleProjectTypechange = (employeeType) => {
    this.setState({
      employeeType,
    });
  };
  handleCancelChanges = () => {
    const newEmployee = { ...this.props.employee };
    newEmployee.skills = this.setEmployeeSkills(newEmployee.skills);
    this.setState(
      {
        employee: newEmployee,
        disable: true,
      },
      this.forceUpdate,
    );
  };
  enableEdit = () => {
    this.setState({ disable: false });
  };

  render() {
    const {
      defaultSkills,
      isLoading,
      employee: { email },
    } = this.props;
    const { employee, disable, employeeType, userEmail } = this.state;
    const isEditable = employee.isActive && !disable;

    const isLoggedInUser = email === userEmail;
    return (
      <div style={{ background: 'white' }}>
        <EnhancedCard loading={isLoading} bordered={false} bodyStyle={{ paddingBottom: 0 }}>
          <EnhancedRow>
            <EnhancedCol span={16} sm={24} xs={24} lg={16} md={16}>
              <h2 className="flex-display">
                <EmployeeEditable
                  value={employee.name}
                  inputName="firstName"
                  editingAllowed={isEditable}
                  updateInput={this.onUpdateOfInput}
                  field="First Name"
                  iconStyle={{ marginTop: '0.1rem' }}
                  style={{ backgroundColor: 'white' }}
                />
                {/* <EmployeeEditable
                  value={employee.lastName}
                  inputName="lastName"
                  editingAllowed={isEditable}
                  updateInput={this.onUpdateOfInput}
                  field="Last Name"
                  iconStyle={{ marginTop: '0.1rem' }}
                  style={{ backgroundColor: 'white' }}
                /> */}
                {employee.isActive &&
                  employee.resignationDate &&
                  moment() >= moment(employee.resignationDate) && (
                    <EnhancedTag color="red" style={{ margin: '5px 0 0 5px' }}>
                      On Notice Period
                    </EnhancedTag>
                  )}
              </h2>
            </EnhancedCol>
            <EnhancedCol span={8} sm={24} xs={24} lg={8} md={8} style={{ textAlign: 'right' }}>
              {isEditable && (
                <div>
                  <EnhancedButton onClick={this.handleCancelChanges} style={{ marginBottom: '5%' }}>
                    {' '}
                    Cancel Changes
                  </EnhancedButton>
                  <EnhancedButton
                    onClick={this.onSavingChanges}
                    style={{ marginLeft: '0.5rem' }}
                    type="primary"
                    disabled={this.state.saveChangesButtonDisable}
                  >
                    Save Changes
                  </EnhancedButton>
                </div>
              )}
            </EnhancedCol>
          </EnhancedRow>
          <EnhancedRow>
            <EnhancedCol span={16}>
              <EnhancedRow>
                <h3 className="employee-detail-heading">Employee Details</h3>
              </EnhancedRow>
              <EnhancedRow>
                <EnhancedCol md={6}>
                  <EnhancedRow>
                    <b>Taleo ID:</b>
                  </EnhancedRow>
                  <EnhancedRow>
                    <EmployeeEditable
                      value={employee.taleoId}
                      inputName="taleoId"
                      editingAllowed={isEditable}
                      updateInput={this.onUpdateOfInput}
                      field="taleoId"
                    />
                  </EnhancedRow>
                </EnhancedCol>
                <EnhancedCol md={6}>
                  <EnhancedRow>
                    <b>Location:</b>
                  </EnhancedRow>
                  <EnhancedRow>
                    <EmployeeEditableSelect
                      text={employee.location ? employee.location.name : ''}
                      value={employee.locationId}
                      inputName="locationId"
                      editingAllowed={isEditable}
                      updateInput={this.onUpdateOfInput}
                      values={this.getLocationsForSelect()}
                      field="Location"
                    />
                  </EnhancedRow>
                </EnhancedCol>
                <EnhancedCol md={6}>
                  <EnhancedRow>
                    <b>Joining:</b>
                  </EnhancedRow>
                  <EnhancedRow>
                    <EmployeeEditableDate
                      text={employee.joiningDate}
                      value={employee.joiningDate}
                      inputName="joiningDate"
                      editingAllowed={isEditable}
                      updateInput={this.onUpdateOfInput}
                      values={this.getResourceTypesForSelect()}
                      field="Joining Date"
                      disableAfter={employee.resignationDate}
                      allowNull={false}
                      disableDate
                    />
                  </EnhancedRow>
                </EnhancedCol>
                <EnhancedCol md={6}>
                  <EnhancedRow>
                    <b>Resignation:</b>
                  </EnhancedRow>
                  <EnhancedRow>
                    <EmployeeEditableDate
                      text={employee.resignationDate}
                      value={employee.resignationDate}
                      inputName="resignationDate"
                      editingAllowed={isEditable}
                      updateInput={this.onUpdateOfInput}
                      values={this.getResourceTypesForSelect()}
                      field="Resignation Date"
                      disableBefore={employee.joiningDate}
                      allowNull
                      disableDate
                    />
                  </EnhancedRow>
                </EnhancedCol>
              </EnhancedRow>
              {/* <br /> */}
              <EnhancedRow>
                <EnhancedCol md={6}>
                  <EnhancedRow>
                    <b>Resource Type:</b>
                  </EnhancedRow>
                  <EnhancedRow>
                    <EmployeeEditableSelect
                      text={employee.resourceType}
                      value={employee.resourceType}
                      inputName="resourceType"
                      editingAllowed={isEditable}
                      updateInput={this.onUpdateOfInput}
                      values={this.getResourceTypesForSelect()}
                      field="Resource Type"
                    />
                  </EnhancedRow>
                </EnhancedCol>
                <EnhancedCol md={6}>
                  <EnhancedRow>
                    <b>Manager:</b>
                  </EnhancedRow>
                  <EnhancedRow>
                    <EmployeeEditableSelect
                      text={employee.managerName}
                      value={employee.managerId}
                      inputName="managerId"
                      editingAllowed={isEditable}
                      updateInput={this.onUpdateOfInput}
                      values={this.getEmployeesForSelect()}
                      field="Manager"
                    />
                  </EnhancedRow>
                </EnhancedCol>
                <EnhancedCol md={6}>
                  <EnhancedRow>
                    <b>Designation</b>
                  </EnhancedRow>
                  <EnhancedRow>
                    {/* <EmployeeEditable
                      value={employee.designation}
                      inputName="designation"
                      editingAllowed={isEditable}
                      updateInput={this.onUpdateOfInput}
                      field="Designation"
                    /> */}
                    <EmployeeEditableSelect
                      text={employee.designation ? employee.designation.name : ''}
                      value={employee.designationId}
                      inputName="designationId"
                      editingAllowed={isEditable}
                      updateInput={this.onUpdateOfInput}
                      values={this.getDesignationsForSelect()}
                      field="Designation"
                    />
                  </EnhancedRow>
                </EnhancedCol>
                <EnhancedCol md={6}>
                  <EnhancedRow>
                    <b>Leaving:</b>
                  </EnhancedRow>
                  <EnhancedRow>
                    <EmployeeEditableDate
                      text={employee.leavingDate}
                      value={employee.leavingDate}
                      inputName="leavingDate"
                      editingAllowed={isEditable}
                      updateInput={this.onUpdateOfInput}
                      values={this.getResourceTypesForSelect()}
                      field="Leaving Date"
                      disableBefore={employee.resignationDate}
                      isDisabled={employee.resignationDate === null}
                      allowNull
                      disableDate
                    />
                  </EnhancedRow>
                </EnhancedCol>
              </EnhancedRow>
              {/* <br /> */}
              <EnhancedRow>
                <EnhancedCol md={6}>
                  <EnhancedRow>
                    <b>Business Unit</b>
                  </EnhancedRow>
                  <EnhancedRow>
                    <EmployeeEditableSelect
                      text={employee.businessUnit ? employee.businessUnit.name : ''}
                      value={employee.businessUnitId}
                      inputName="businessUnitId"
                      editingAllowed={isEditable}
                      updateInput={this.onUpdateOfInput}
                      values={this.getBusinessUnitsForSelect()}
                      field="BusinessUnit"
                    />
                  </EnhancedRow>
                </EnhancedCol>
                <EnhancedCol md={6}>
                  <EnhancedRow>
                    <b>Division</b>
                  </EnhancedRow>
                  <EnhancedRow>
                    <EmployeeEditableSelect
                      text={employee.division ? employee.division.name : ''}
                      value={employee.divisionId}
                      inputName="divisionId"
                      editingAllowed={isEditable}
                      updateInput={this.onUpdateOfInput}
                      values={this.getDivisionForSelect()}
                      field="Division"
                    />
                  </EnhancedRow>
                </EnhancedCol>
                <EnhancedCol md={6}>
                  <EnhancedRow>
                    <b>Competency</b>
                  </EnhancedRow>
                  <EnhancedRow>
                    <EmployeeEditableSelect
                      text={employee.competency ? employee.competency.name : ''}
                      value={employee.competencyId}
                      inputName="competencyId"
                      editingAllowed={isEditable}
                      updateInput={this.onUpdateOfInput}
                      values={this.getCompetencyForSelect()}
                      field="Competency"
                    />
                  </EnhancedRow>
                </EnhancedCol>
                <EnhancedCol md={6}>
                  <EnhancedRow>
                    <b>Department:</b>
                  </EnhancedRow>
                  <EnhancedRow>
                    <EmployeeEditableSelect
                      text={employee.department ? employee.department.name : ''}
                      value={employee.departmentId}
                      inputName="departmentId"
                      editingAllowed={isEditable}
                      updateInput={this.onUpdateOfInput}
                      values={this.getDepartmentsForSelect()}
                      field="Department"
                    />
                  </EnhancedRow>
                </EnhancedCol>
              </EnhancedRow>
              <EnhancedRow>
                <EnhancedCol md={6}>
                  <EnhancedRow>
                    <b>Gender</b>
                  </EnhancedRow>
                  <EnhancedRow>
                    <EmployeeEditableSelect
                      value={employee.genderId}
                      inputName="genderId"
                      editingAllowed={isEditable}
                      updateInput={this.onUpdateOfInput}
                      values={GENDERS}
                      field="Gender"
                    />
                  </EnhancedRow>
                </EnhancedCol>
                <EnhancedCol md={6}>
                  <EnhancedRow>
                    <b>Date of Birth</b>
                  </EnhancedRow>
                  <EnhancedRow>
                    <EmployeeEditableDate
                      text={employee.dateOfBirth}
                      value={employee.dateOfBirth}
                      inputName="dateOfBirth"
                      editingAllowed={isEditable}
                      updateInput={this.onUpdateOfInput}
                      values={this.getResourceTypesForSelect()}
                      field="Date of Birth"
                      allowNull={false}
                    />
                  </EnhancedRow>
                </EnhancedCol>
              </EnhancedRow>
            </EnhancedCol>
            <EnhancedCol span={6} offset={2}>
              <h3 className="skills-heading">Primary Skills</h3>
              <div style={{ paddingTop: '10px' }}>
                <SkillsBage
                  defaultSkills={defaultSkills}
                  skills={this.getPrimarySkills()}
                  expertise={skillLevel.EXPERT}
                  handleSkills={this.getSkillsRecords}
                  showAdd={isEditable}
                />
              </div>
            </EnhancedCol>
          </EnhancedRow>
          <EnhancedRow>
            <EnhancedCol span={16}>
              <EnhancedRow>
                <h3 className="employee-detail-heading">Work Details</h3>
              </EnhancedRow>
              <EnhancedRow>
                <EnhancedCol md={10}>
                  <EnhancedRow>
                    <b>Total Experience</b>
                  </EnhancedRow>
                  <EnhancedRow>{employee.totalExperience || '-'}</EnhancedRow>
                </EnhancedCol>
                <EnhancedCol md={7}>
                  <EnhancedRow>
                    <b>Graduation:</b>
                  </EnhancedRow>
                  <EnhancedRow>
                    <EmployeeEditableDate
                      text={employee.graduationDate}
                      value={employee.graduationDate}
                      inputName="graduationDate"
                      editingAllowed={isEditable}
                      updateInput={this.onUpdateOfInput}
                      values={this.getResourceTypesForSelect()}
                      displayFormat={dateFormat.MonthAndYearOnly}
                      field="Graduation Date"
                      allowNull
                    />
                  </EnhancedRow>
                </EnhancedCol>
                <EnhancedCol md={7}>
                  <EnhancedRow>
                    <b>External Experience:</b>
                  </EnhancedRow>
                  <EnhancedRow>
                    <EmployeeEditableInputs
                      externalExpYears={employee.externalExpYears}
                      externalExpMonths={employee.externalExpMonths}
                      inputName="externalExpYears"
                      editingAllowed={isEditable}
                      updateInput={this.onUpdateOfInput}
                      field="Designation"
                    />
                  </EnhancedRow>
                </EnhancedCol>
                <EnhancedCol md={7}>
                  <EnhancedRow>
                    <b>Career Start:</b>
                  </EnhancedRow>
                  <EnhancedRow>
                    <EmployeeEditableDate
                      text={employee.careerStartDate}
                      value={employee.careerStartDate}
                      inputName="careerStartDate"
                      editingAllowed={isEditable}
                      updateInput={this.onUpdateOfInput}
                      values={this.getResourceTypesForSelect()}
                      displayFormat={dateFormat.MonthAndYearOnly}
                      field="Career Start Date"
                      allowNull
                    />
                  </EnhancedRow>
                </EnhancedCol>
              </EnhancedRow>
            </EnhancedCol>
            <EnhancedCol span={6} offset={2}>
              <h3 className="skills-heading">Secondary Skills</h3>
              <div style={{ paddingTop: '10px' }}>
                <SkillsBage
                  defaultSkills={defaultSkills}
                  skills={this.getSecondarySkills()}
                  expertise={skillLevel.BEGINNER}
                  handleSkills={this.getSkillsRecords}
                  showAdd={isEditable}
                />
              </div>
            </EnhancedCol>
          </EnhancedRow>
          <EnhancedRow>
            <EnhancedCol span={16}>
              <EnhancedRow>
                <h3 className="employee-detail-heading">Contact Details</h3>
              </EnhancedRow>
              <EnhancedRow>
                <EnhancedCol md={10}>
                  <EnhancedRow>
                    <b>Email:</b>
                  </EnhancedRow>
                  <EnhancedRow>
                    <EmployeeEditable
                      value={employee.email}
                      inputName="email"
                      editingAllowed={isEditable && !isLoggedInUser}
                      updateInput={this.onUpdateOfInput}
                    />
                  </EnhancedRow>
                </EnhancedCol>
                <EnhancedCol md={7}>
                  <EnhancedRow>
                    <b>Phone Number:</b>
                  </EnhancedRow>
                  <EnhancedRow>
                    <EmployeeEditable
                      value={employee.phoneNumber}
                      inputName="phoneNumber"
                      editingAllowed={isEditable}
                      updateInput={this.onUpdateOfInput}
                      field="Phone Number"
                    />
                  </EnhancedRow>
                </EnhancedCol>
                <EnhancedCol md={7}>
                  <EnhancedRow>
                    <b>Skype:</b>
                  </EnhancedRow>
                  <EnhancedRow>
                    <EmployeeEditable
                      text={employee.skype}
                      value={employee.skype}
                      inputName="skype"
                      editingAllowed={isEditable}
                      updateInput={this.onUpdateOfInput}
                      field="Skype"
                    />
                  </EnhancedRow>
                </EnhancedCol>
              </EnhancedRow>
            </EnhancedCol>
            {/* <Col span={6} offset={2}>
              <h3 className="skills-heading">Secondary Skills</h3>
              <div style={{ paddingTop: '10px' }}>
                <SkillsBage
                  defaultSkills={defaultSkills}
                  skills={this.getSecondarySkills()}
                  expertise={skillLevel.BEGINNER}
                  handleSkills={this.getSkillsRecords}
                  showAdd={isEditable}
                />
              </div>
            </Col> */}
          </EnhancedRow>
          <EnhancedRow type="flex" justify="end" style={{ marginTop: '1.5rem' }}>
            <EnhancedCol />
          </EnhancedRow>
        </EnhancedCard>
        <h2 style={{ margin: '0 20px 20px 20px' }}> Allocations </h2>
        <EnhancedTabs defaultActiveKey={0} onChange={this.handleProjectTypechange}>
          <TabPane tab="Existing" key={0} style={{ background: '#f0f2f5' }}>
            <EmployeesProjectContainer type={employeeType} status={disable && employee.isActive} />
          </TabPane>

          <TabPane tab="Inactive" key={1} style={{ background: '#f0f2f5' }}>
            <EmployeesProjectContainer type={employeeType} status={disable && employee.isActive} />
          </TabPane>
        </EnhancedTabs>
      </div>
    );
  }
}

export default EmployeeDetails;
