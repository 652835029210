import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import DashboardContainer from 'containers/DashboardContainer';
import ResourceAvailabilityContainer from 'containers/ResourceAvailabilityContainer';
import NonBillableContainer from 'containers/NonBillableContainer';
import ProjectEmployeesContainer from 'containers/ProjectEmployeesContainer';
import ProjectContainer from 'containers/ProjectContainer';
import EmployeesContainer from 'containers/EmployeesContainer';
import AddEmployeeContainer from 'containers/AddEmployeeContainer';
import EmployeeDetailsContainer from 'containers/EmployeeDetailsContainer';
import { ROOT } from 'configs/routesConstants';
import PROJECT_ROUTES from '../constants/UrlConstants';
import AuthorizedRoute from '../components/AuthorizedRoute';

class CRMCRoutes extends React.Component {
  componentDidMount() {
    //  this.checkPermissions(this.props);
  }

  componentWillReceiveProps(/* nextProps */) {
    //  this.checkPermissions(nextProps);
  }

  checkPermissions = (nextProps) => {
    if (!(nextProps.isCRMC1 || nextProps.isPM || nextProps.isCRMC2)) {
      /*   this.props.history.push(ROOT); */
    }
  };

  render() {
    const { match } = this.props;
    return (
      <React.Fragment>
        <Switch>
          <AuthorizedRoute
            exact
            path={`${match.path}${PROJECT_ROUTES.CRMC_DASHBOARD_ROUTE}`}
            component={DashboardContainer}
          />
          <Redirect
            exact
            from={`${match.path}${PROJECT_ROUTES.CRMC_PROJECTS_ROUTE}`}
            to={`${match.path}${PROJECT_ROUTES.CRMC_CURRENT_PROJECTS_ROUTE}`}
          />
          <AuthorizedRoute
            exact
            path={`${match.path}${PROJECT_ROUTES.CRMC_PROJECTS_LISTING_ROUTE}`}
            component={ProjectContainer}
          />
          <AuthorizedRoute
            path={`${match.path}${PROJECT_ROUTES.CRMC_PROJECTS_DETAIL_ROUTE}`}
            component={ProjectEmployeesContainer}
          />
          <AuthorizedRoute
            exact
            path={`${match.path}${PROJECT_ROUTES.CRMC_BILLABLE_RESOURCE_ROUTE}`}
            component={ResourceAvailabilityContainer}
          />
          <AuthorizedRoute
            exact
            path={`${match.path}${PROJECT_ROUTES.CRMC_NON_BILLABLE_ROUTE}`}
            component={NonBillableContainer}
          />
          <AuthorizedRoute
            path={`${match.path}${PROJECT_ROUTES.CRMC_ADD_EMPLOYEES_ROUTE}`}
            component={AddEmployeeContainer}
          />
          <Redirect
            exact
            from={`${match.path}${PROJECT_ROUTES.CRMC_EMPLOYEES_ROUTE}`}
            to={`${match.path}${PROJECT_ROUTES.CRMC_CURRENT_EMPLOYEES_ROUTE}`}
          />
          <AuthorizedRoute
            exact
            path={`${match.path}${PROJECT_ROUTES.CRMC_EMPLOYEES_LISTING_ROUTE}`}
            component={EmployeesContainer}
          />
          <Redirect
            exact
            from={`${match.path}${PROJECT_ROUTES.CRMC_EMPLOYEES_ROUTE}`}
            to={`${match.path}${PROJECT_ROUTES.CRMC_CURRENT_EMPLOYEES_ROUTE}`}
          />
          <AuthorizedRoute
            exact
            component={EmployeeDetailsContainer}
            path={`${match.path}${PROJECT_ROUTES.CRMC_EMPLOYEE_DETAIL_ROUTE}`}
          />
          <Redirect to={ROOT} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default CRMCRoutes;
