import {
  EnhancedRow,
  EnhancedCol,
  EnhancedCard,
  EnhancedTabs,
  EnhancedLayout,
} from 'components/shared/antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import { getProjectsAction } from 'store/actions/ProjectActions';
import { XLS_FILENAME, XLS_PER_TRACK_META, XLS_PER_CLIENT_AND_TRACK_META } from './constants';
import {
  getQuestionsFrequencyPerTrack,
  getQuestionsFrequencyPerClientAndTrack,
  getReporteesTracks,
} from '../../sources/interviewSources';
import generatePerTrackColumns from './perTrackColumns';
import generatePerClientAndTrackColumns from './perClientAndTrackColumns';
import './question-frequency.css';

const { TabPane } = EnhancedTabs;

const InterviewQuestionsFrequency = (props) => {
  const {
    questionFrequencyPerTrackList,
    questionFrequencyPerClientAndTrackList,
    loading,
    getQuestionsFrequencyListPerTrack,
    getQuestionsFrequencyListPerClientAndTrack,
    getTracks,
    getProjects,
    trackList,
    projectList,
  } = props;
  const [questionsPerTrack, setQuestionsPerTrack] = useState([]);
  const [questionsPerClientAndTrack, setQuestionsPerClientAndTrack] = useState([]);

  useEffect(() => {
    getQuestionsFrequencyListPerTrack();
    getQuestionsFrequencyListPerClientAndTrack();
    getTracks();
    getProjects();
  }, []);

  useEffect(() => {
    setQuestionsPerTrack(questionFrequencyPerTrackList);
  }, [questionFrequencyPerTrackList]);

  useEffect(() => {
    setQuestionsPerClientAndTrack(questionFrequencyPerClientAndTrackList);
  }, [questionFrequencyPerClientAndTrackList]);

  useEffect(() => {
    const newData = [...questionFrequencyPerTrackList];

    setQuestionsPerTrack(newData);
  }, [questionFrequencyPerTrackList]);

  useEffect(() => {
    const newData = [...questionFrequencyPerClientAndTrackList];

    setQuestionsPerClientAndTrack(newData);
  }, [questionFrequencyPerClientAndTrackList]);

  const perTrackColumns = generatePerTrackColumns({ trackList });

  const perClientAndTrackColumns = generatePerClientAndTrackColumns({ trackList, projectList });

  return (
    <div style={{ background: 'white' }}>
      <EnhancedRow style={{ backgroundColor: '#fff', marginBottom: '3px' }}>
        <EnhancedLayout.Header className="question-frequency-header">
          <EnhancedCol lg={12} md={10} sm={24} xs={24}>
            <h1>Questions Frequency</h1>
          </EnhancedCol>
        </EnhancedLayout.Header>
      </EnhancedRow>
      <EnhancedTabs defaultActiveKey={1}>
        <TabPane tab="Track" key="1" style={{ background: '#F0F2F5' }}>
          <EnhancedCard bordered={false} className="history-list" style={{ marginTop: '20px' }}>
            <EnhancedRow>
              <EnhancedCol sm={12}>
                <h2 className="heading-emp">Questions Frequency - Per Track</h2>
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCard>
          <EnhancedCard>
            <EnhancedClientSideTable
              rowKey={(record) => record.id}
              columns={perTrackColumns}
              data={questionsPerTrack}
              loading={loading}
              scroll={{ x: 1300 }}
              exportFileConfig={{
                fileName: XLS_FILENAME,
                fileHeaders: XLS_PER_TRACK_META,
                showExportButton: true,
              }}
            />
          </EnhancedCard>
        </TabPane>
        <TabPane tab="Client and Track" key="2" style={{ background: '#F0F2F5' }}>
          <EnhancedCard bordered={false} className="history-list" style={{ marginTop: '20px' }}>
            <EnhancedRow>
              <EnhancedCol sm={12}>
                <h2 className="heading-emp">Questions Frequency - Per Client And Track</h2>
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCard>
          <EnhancedCard>
            <EnhancedClientSideTable
              rowKey={(record) => record.id}
              columns={perClientAndTrackColumns}
              data={questionsPerClientAndTrack}
              loading={loading}
              scroll={{ x: 1300 }}
              exportFileConfig={{
                fileName: XLS_FILENAME,
                fileHeaders: XLS_PER_CLIENT_AND_TRACK_META,
                showExportButton: true,
              }}
            />
          </EnhancedCard>
        </TabPane>
      </EnhancedTabs>
    </div>
  );
};

const mapState = (state) => ({
  loading: state.interviewReducer.loading,
  questionFrequencyPerTrackList: state.interviewReducer.questionFrequencyPerTrackList,
  questionFrequencyPerClientAndTrackList:
    state.interviewReducer.questionFrequencyPerClientAndTrackList,
  trackList: state.interviewReducer.trackList,
  projectList: state.projectReducer.projects.filter((v) => v.status),
});

const mapDispatch = (dispatch) => ({
  getQuestionsFrequencyListPerTrack: () => dispatch(getQuestionsFrequencyPerTrack()),
  getQuestionsFrequencyListPerClientAndTrack: () =>
    dispatch(getQuestionsFrequencyPerClientAndTrack()),
  getTracks: () => dispatch(getReporteesTracks()),
  getProjects: () => dispatch(getProjectsAction()),
});

export default connect(mapState, mapDispatch)(InterviewQuestionsFrequency);
