import { EnhancedSpin } from 'components/shared/antd';
import React from 'react';
import './style.css';

export default ({ tip }) => {
  return (
    <div className="box">
      <div>
        <EnhancedSpin tip={tip} />
      </div>
    </div>
  );
};
