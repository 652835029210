import {
  EnhancedCol,
  EnhancedRow,
  EnhancedCard,
  EnhancedIcon,
  EnhancedTabs,
  EnhancedLayout,
  EnhancedCheckbox,
  EnhancedMenu,
} from 'components/shared/antd';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';
import React, { Component } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { ATTRIBUTES, HR_DASHBOARD_TABS, ALL_LEAVES, ATTRIBUTE_IDS } from 'configs/leaveConstants';
import { findLeaveStatus, approvedLeavePermission } from 'utils/leaveUtils';
import DashboardButtons from './DashboardButtons';
import { VENDIANS_COLORS } from '../../constants/ColorTypes';
import { paginationOptions } from '../../utils/getPagination';
import { leaveStatusGroups } from '../../configs/leaveConstants';
import LeaveDashboardModal from './LeaveDashboardModal';
import ExpandedTableRow from './ExpandedTableRow';
import AdjustLeaveQuota from './AdjustLeaveQuota';
import LeaveAdjustmentLogs from './LeaveAdjustmentLogs';

const { TabPane } = EnhancedTabs;

export default class LeavesDashboard extends Component {
  constructor(props) {
    super(props);
    this.initialParams = {
      employeeName: '',
      managerName: '',
      adjustedByUser: '',
      oracleId: '',
      includeInactiveEmployees: false,
      sortOrder: '',
      sortBy: '',
      ...paginationOptions,
    };

    this.state = {
      params: {
        ...this.initialParams,
      },
      visible: false,
      quotaModalvisible: false,
      leave: {},
      comment: '',
      currentlyActiveTab: '',
      filteredAttributes: [],
    };
  }

  componentDidMount() {
    const { userId } = this.props;
    this.props.getUserAttributesAction(userId);
    this.props.getLeavesStatuses();
    this.props.getLeaveTypes();
    this.props.getEmployeeDepartmentsAction();
  }

  componentWillReceiveProps(nextProps) {
    const { userAttributes: nextAttributes, updateSuccess: nextSuccess, leaveQuota } = nextProps;
    const {
      userAttributes: prevAttributes,
      updateSuccess: prevSuccess,
      pageInfo,
      leaveQuota: prevLeaveQuota,
    } = this.props;

    if (nextAttributes.length && nextAttributes !== prevAttributes) {
      const {
        params: { page, size },
      } = this.state;

      const filteredAttributes = nextAttributes.filter((attr) => attr.name === ATTRIBUTES.HR);
      if (filteredAttributes.length) {
        const currentlyActiveTab = filteredAttributes[0].id;
        this.props.getTeamLeavesBalance(currentlyActiveTab, { page, size });

        this.setState({
          currentlyActiveTab,
          HRRoleId: currentlyActiveTab,
          filteredAttributes,
        });
      }
    }

    if (leaveQuota && leaveQuota !== prevLeaveQuota) {
      const { currentlyActiveTab, params } = this.state;
      const { page: p, size: s } = pageInfo;
      this.props.getTeamLeavesBalance(currentlyActiveTab, {
        ...params,
        page: p,
        size: s,
      });
      this.setState({ quotaModalvisible: false });
    }

    if (nextSuccess && nextSuccess !== prevSuccess) {
      const { params } = this.state;
      this.props.getCompanyWideDashboard(params);
      this.setState({ visible: false, comment: '' });
    }
  }

  onChangeComment = (e) => this.setState({ comment: e.target.value });

  onUpdateLeave = (status) => {
    const {
      leave: {
        leaveRequestData: { leaveId },
        approver,
      },
      comment,
    } = this.state;
    const { userId, leavesStatuses } = this.props;
    const statusObj = findLeaveStatus(status, leavesStatuses, leaveStatusGroups.APPROVER);

    const payload = {
      leaveRequestId: leaveId,
      approverId: userId,
      comments: comment,
      statusId: statusObj.id,
      reference: approver.reference,
    };
    this.props.updateTeamLeave(payload);
  };

  onInactiveEmployeesChange = ({ target }) => {
    const { checked: includeInactiveEmployees } = target;
    const { currentlyActiveTab, params } = this.state;

    const updatedParams = {
      ...params,
      includeInactiveEmployees,
    };

    if (currentlyActiveTab !== '0') {
      this.props.getTeamLeavesBalance(currentlyActiveTab, updatedParams);
    } else {
      this.props.getCompanyWideDashboard(updatedParams);
    }

    this.setState({ params: updatedParams });
  };

  onUpdateParams = (updatedParams) => {
    const { currentlyActiveTab, params } = this.state;

    const newParams = {
      ...params,
      ...updatedParams,
      page: 0,
    };
    if (+currentlyActiveTab === ATTRIBUTE_IDS.HR) {
      this.props.getTeamLeavesBalance(currentlyActiveTab, newParams);
    } else if (currentlyActiveTab === '0') {
      this.props.getCompanyWideDashboard(newParams);
    } else {
      this.props.getLeavesAdjustmentsLogs(newParams);
    }

    this.setState((state) => ({
      params: {
        ...state.params,
        ...updatedParams,
        page: 0,
      },
    }));
  };

  activateUser = (key) => {
    const {
      params: { includeInactiveEmployees },
    } = this.state;

    const params = {
      ...this.initialParams,
      includeInactiveEmployees,
    };

    if (+key === ATTRIBUTE_IDS.HR) {
      this.props.getTeamLeavesBalance(key, params);
    } else if (key === '0') {
      this.props.getCompanyWideDashboard(params);
    } else {
      this.props.getLeavesAdjustmentsLogs(params);
    }

    this.setState({
      currentlyActiveTab: key,
      params,
    });
  };

  generateLeaveQuotaReport = () => {
    const { currentlyActiveTab, params } = this.state;

    const updatedParams = {
      ...params,
      size: 1000000,
    };
    if (+currentlyActiveTab === ATTRIBUTE_IDS.HR) {
      this.props.exportLeaveQuotas(currentlyActiveTab, updatedParams);
    } else if (currentlyActiveTab === '0') {
      this.props.exportAllLeaves(updatedParams);
    } else {
      this.props.exportLeavesLog(updatedParams);
    }
  };

  actions = (leaveRequestData) => {
    const { HRRoleId } = this.state;
    const { canApprove, approver } = approvedLeavePermission({
      approvers: leaveRequestData.approvers,
      currentlyActiveTab: HRRoleId,
      currentStatus: leaveRequestData.currentStatus,
    });

    /*
    There was an isEscalated condition here i.e the leave which is in escalated mode
    can only be approved/rejected by HR
    But, since the mechanism which puts a leave in escalation mode has bugs and we did not have time
    to fix that, so after discussion with Anas I am removing that condition from here

    because right now, LMs and PMs contact Anas/HR manually to approve stucked leaves
    so escalation functionality is not being used for which it was intended to.
    */
    if (canApprove) {
      return (
        <EnhancedIcon
          type="edit"
          role="presentation"
          style={{ cursor: 'pointer' }}
          onClick={() => this.showModal({ leaveRequestData, approver })}
          onKeyDown={() => this.showModal({ leaveRequestData, approver })}
        />
      );
    }
    return 'N/A';
  };

  handlePageChange = (pagination, filters, sorter) => {
    const { currentlyActiveTab, params } = this.state;

    const pageParams = {
      page: pagination.current - 1,
      size: pagination.pageSize,
    };

    if (sorter.columnKey && sorter.order) {
      pageParams.sortBy = sorter.columnKey;
      pageParams.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
    } else {
      pageParams.sortBy = '';
      pageParams.sortOrder = '';
    }

    const updatedParams = {
      ...params,
      ...pageParams,
    };
    if (+currentlyActiveTab === ATTRIBUTE_IDS.HR) {
      this.props.getTeamLeavesBalance(currentlyActiveTab, updatedParams);
    } else if (currentlyActiveTab === '0') {
      this.props.getCompanyWideDashboard(updatedParams);
    } else {
      this.props.getLeavesAdjustmentsLogs(updatedParams);
    }

    this.setState({
      params: updatedParams,
    });
  };

  hideModal = () => {
    this.setState({ visible: false });
  };

  showModal = (leave) => {
    this.setState({ visible: true, leave });
  };

  showleaveQuotaModal = () => {
    this.setState({ quotaModalvisible: true });
  };

  hideleaveQuotaModal = () => {
    this.setState({ quotaModalvisible: false });
  };

  adjustQuota = (leavesQuota) => {
    this.props.adjustLeaveQuota(leavesQuota);
  };

  handleAdjustmentsClick = ({ key }) => {
    if (key === '1') {
      this.showleaveQuotaModal();
    } else {
      // console.log('export func');
    }
  };

  render() {
    const {
      currentlyActiveTab,
      filteredAttributes,
      params,
      leave,
      comment,
      visible,
      quotaModalvisible,
    } = this.state;
    const { page, includeInactiveEmployees } = params;
    const {
      isLoading,
      teamLeaveBalance,
      pageInfo,
      adjustLeaveQuotaLogsPageInfo,
      companyWideDashboard,
      adjustLeaveQuotaLogs,
      employees,
      leaveTypes,
    } = this.props;

    const leaveQuotasColumn = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'employeeName',
        filterConfig: {
          type: 'search',
          key: 'employeeName',
        },
        sorter: true,
      },
      {
        title: 'Taleo ID',
        dataIndex: 'taleoId',
        key: 'taleoId',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Designation',
        dataIndex: 'designation',
        key: 'designation',
      },
      {
        title: 'Manager',
        dataIndex: 'lineManager',
        key: 'managerName',
        filterConfig: {
          type: 'search',
          key: 'managerName',
        },
        sorter: true,
      },
      {
        title: 'Department',
        dataIndex: 'deptt',
        key: 'deptt',
      },
      {
        title: 'Casual Leaves',
        dataIndex: 'casual',
        key: 'casual',
        sorter: true,
      },
      {
        title: 'Sick Leaves',
        dataIndex: 'sick',
        key: 'sick',
        sorter: true,
      },
      {
        title: 'Annual Leaves',
        dataIndex: 'annual',
        key: 'annual',
        sorter: true,
      },
    ];

    const allLeavesColumn = [
      {
        title: 'Taleo ID',
        dataIndex: 'employeeTaleoId',
        key: 'employeeTaleoId',
      },
      {
        title: 'Name',
        dataIndex: 'employeeName',
        key: 'employeeName',
        filterConfig: {
          type: 'search',
          key: 'employeeName',
        },
        sorter: true,
      },
      {
        title: 'Designation',
        dataIndex: 'designation',
        key: 'designation',
      },
      {
        title: 'Manager',
        dataIndex: 'managerName',
        key: 'managerName',
        filterConfig: {
          type: 'search',
          key: 'managerName',
        },
        sorter: true,
      },
      {
        title: 'Department',
        dataIndex: 'deptt',
        key: 'departmentName',
        sorter: true,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'depttIds',
          optionsConfig: {
            values: this.props.departments,
            valueVar: 'id',
          },
        },
      },
      {
        title: 'Leave Type',
        dataIndex: 'type',
        key: 'leaveType',
        sorter: true,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'leaveTypeIds',
          optionsConfig: {
            values: this.props.leaveTypes,
            valueVar: 'id',
          },
        },
      },
      {
        title: 'Leave Status',
        dataIndex: 'status',
        key: 'leaveStatus',
        render: (text) => ({
          props: {
            style: {
              color: text === 'Approved' ? VENDIANS_COLORS.VEND_PROMISE : '',
            },
          },
          children: text,
        }),
        sorter: true,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'leaveStatusIds',
          optionsConfig: {
            values: this.props.leavesStatuses,
            valueVar: 'id',
          },
        },
      },
      {
        title: 'Request Date',
        dataIndex: 'requestDate',
        key: 'requestDate',
        sorter: true,
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        sorter: true,
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
        key: 'endDate',
        sorter: true,
      },
      {
        title: 'Half Day',
        dataIndex: 'isHalfDay',
        key: 'isHalfDay',
        render: (isHalfDay) => (isHalfDay ? 'Yes' : 'No'),
      },
      {
        title: 'Actions',
        render: this.actions,
        fixed: 'right',
      },
    ];

    const menu = (
      <EnhancedMenu onClick={this.handleAdjustmentsClick}>
        <EnhancedMenu.Item key="1">
          <EnhancedIcon type="form" /> Adjust Leave Quota
        </EnhancedMenu.Item>
      </EnhancedMenu>
    );

    return (
      <div style={{ background: 'white' }}>
        <EnhancedLayout.Header style={{ backgroundColor: 'white' }}>
          <EnhancedRow style={{ backgroundColor: '#fff', marginBottom: '3px' }}>
            <EnhancedCol span={18}>
              <h1 className="heading-pro">Company Wide Dashboard</h1>
            </EnhancedCol>
            <EnhancedCol span={6}>
              {(currentlyActiveTab === '0' || +currentlyActiveTab === ATTRIBUTE_IDS.HR) && (
                <EnhancedCheckbox
                  checked={includeInactiveEmployees}
                  onChange={this.onInactiveEmployeesChange}
                >
                  Include inactive employees too
                </EnhancedCheckbox>
              )}
            </EnhancedCol>
          </EnhancedRow>
        </EnhancedLayout.Header>
        {quotaModalvisible && (
          <AdjustLeaveQuota
            visible={quotaModalvisible}
            hideModal={this.hideleaveQuotaModal}
            leaveTypes={leaveTypes}
            employees={employees}
            adjustQuota={this.adjustQuota}
          />
        )}
        <LeaveDashboardModal
          onUpdateLeave={this.onUpdateLeave}
          visible={visible}
          leave={leave}
          hideModal={this.hideModal}
          onChangeComment={this.onChangeComment}
          comment={comment}
        />
        {currentlyActiveTab && (
          <EnhancedTabs
            defaultActiveKey={currentlyActiveTab}
            onChange={this.activateUser}
            className="leave-dashboard-tab"
          >
            {filteredAttributes.map((attribute) => (
              <TabPane
                tab={HR_DASHBOARD_TABS[attribute.name]}
                key={attribute.id}
                style={{ background: '#F0F2F5' }}
              >
                <EnhancedCard style={{ marginTop: '20px' }}>
                  <DashboardButtons menu={menu} />
                  <EnhancedRow style={{ backgroundColor: '#fff', marginTop: '30px' }}>
                    <EnhancedCol span={24}>
                      <EnhancedServerSideTable
                        data={teamLeaveBalance}
                        loading={isLoading}
                        columns={leaveQuotasColumn}
                        onChange={this.handlePageChange}
                        paginationInfo={{
                          totalItems: pageInfo.totalItems,
                          pageSize: pageInfo.size,
                          pageNumber: page + 1,
                        }}
                        updateParams={(updatedParams) => {
                          this.onUpdateParams(updatedParams);
                        }}
                        exportFileConfig={{
                          showExportButton: true,
                          handler: this.generateLeaveQuotaReport,
                        }}
                      />
                    </EnhancedCol>
                  </EnhancedRow>
                </EnhancedCard>
              </TabPane>
            ))}
            <TabPane tab={ALL_LEAVES.name} key={ALL_LEAVES.id} style={{ background: '#F0F2F5' }}>
              <EnhancedCard style={{ marginTop: '20px' }}>
                <EnhancedRow gutter={3} style={{ backgroundColor: '#fff', marginTop: '30px' }}>
                  <EnhancedCol span={24}>
                    <EnhancedServerSideTable
                      rowKey={(record) => record.leaveId}
                      data={companyWideDashboard}
                      loading={isLoading}
                      columns={allLeavesColumn}
                      expandedRowRender={(record) => <ExpandedTableRow record={record} />}
                      onChange={this.handlePageChange}
                      paginationInfo={{
                        totalItems: pageInfo.totalItems,
                        pageSize: pageInfo.size,
                        pageNumber: page + 1,
                      }}
                      scroll={{ x: 1400 }}
                      updateParams={(updatedParams) => {
                        this.onUpdateParams(updatedParams);
                      }}
                      exportFileConfig={{
                        showExportButton: true,
                        handler: this.generateLeaveQuotaReport,
                      }}
                    />
                  </EnhancedCol>
                </EnhancedRow>
              </EnhancedCard>
            </TabPane>
            <TabPane tab="Leaves Adjustments Logs" key={1} style={{ background: '#F0F2F5' }}>
              <LeaveAdjustmentLogs
                params={params}
                isLoading={isLoading}
                handlePageChange={this.handlePageChange}
                adjustLeaveQuotaLogs={adjustLeaveQuotaLogs}
                onUpdateParams={this.onUpdateParams}
                generateLeaveQuotaReport={this.generateLeaveQuotaReport}
                adjustLeaveQuotaLogsPageInfo={adjustLeaveQuotaLogsPageInfo}
              />
            </TabPane>
          </EnhancedTabs>
        )}
      </div>
    );
  }
}
