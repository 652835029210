import moment from 'moment';
import XLSX from 'xlsx';

import { allDealMeta } from '../configs/dealConstants';

const getMappedDealData = (args) => {
  const { data, sheetMeta } = args;

  const headers = [];
  sheetMeta.forEach((meta) => {
    headers.push(meta.label);
  });
  const rows = data.map((entry) =>
    sheetMeta.map((meta) => {
      if (meta.renderer) {
        return meta.renderer(entry[meta.key]);
      }
      return entry[meta.key];
    }),
  );
  return [headers, ...rows];
};

export function writeToXLSX(sheet, fileName) {
  const book = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(book, sheet, 'Summary');
  const currentDate = moment(new Date()).format('YYYY-MM-DD');
  XLSX.writeFile(book, `${fileName} - ${currentDate}.xlsx`);
}

export const generateAllDealReport = (data) => {
  const sheet = XLSX.utils.aoa_to_sheet(
    getMappedDealData({
      data,
      sheetMeta: allDealMeta,
    }),
  );
  writeToXLSX(sheet, 'All Deals');
};
