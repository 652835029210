import React from 'react';
import { EnhancedSpin } from 'components/shared/antd';

const AppLoaderComponent = (props) => {
  const { visible } = props;

  return <EnhancedSpin className={`loader ${!visible ? 'd-none' : ''}`} />;
};

export default AppLoaderComponent;
