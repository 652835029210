import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import { myLeaves, leavesDetailsUrl } from 'configs/constants';
import { getResponseError } from 'utils/sourceUtils';
import { normalizeLeaveRequests, normalizeLeaveBalance } from 'normalizers/leaveNormalizers';
import {
  requestLeaveSuccess,
  requestLeaveFail,
  editLeaveSuccess,
} from 'store/actions/LeaveActions';

export const requestMyLeavesSource = (employeeId, params) =>
  new Promise((resolve, reject) => {
    const url = myLeaves.GET.replace('{employeeId}', employeeId);
    axios
      .get(url, {
        params,
      })
      .then((response) => {
        if (response && response.data.success) {
          const {
            data: { content, pageInfo },
          } = response.data;
          resolve({
            leaveRequests: normalizeLeaveRequests(content),
            pageInfo,
          });
        } else {
          EnhancedNotification.error({
            message: getResponseError(response.message),
          });
          reject(response.message);
        }
      })
      .catch((err) => {
        EnhancedNotification.error({
          message: getResponseError(err),
        });
        reject(err);
      });
  });

export const createRequestLeaveSource = (request) => (dispatch) => {
  axios
    .post(myLeaves.POST, request)
    .then((response) => {
      if (response.status === 200) {
        const leave = response.data.data;
        leave.leaveName = leave.leaveType;
        dispatch(
          requestLeaveSuccess({
            message: 'Updated Successfully',
            leave,
          }),
        );
        EnhancedNotification.success({
          message: 'Created Successfully',
        });
      } else {
        EnhancedNotification.error({
          message: response.data.message,
        });
        dispatch(requestLeaveFail());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: getResponseError(error),
      });
      dispatch(requestLeaveFail());
    });
};

export const editLeaveSource = (request) => (dispatch) => {
  axios
    .put(myLeaves.PUT, request)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Updated Successfully',
        });
        dispatch(
          editLeaveSuccess({
            message: 'Updated Successfully',
            data: response.data,
            leave: request,
          }),
        );
      } else {
        EnhancedNotification.error({
          message: response.data.message,
        });
        dispatch(requestLeaveFail());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: getResponseError(error),
      });
      // API Call errors
      dispatch(requestLeaveFail());
    });
};

export const requestLeavesDetailsSource = (employeeId, payload) =>
  new Promise((resolve, reject) => {
    const url = leavesDetailsUrl.GET.replace('{employeeId}', employeeId);
    axios
      .get(url, {
        params: payload,
      })
      .then((response) => {
        if (response && response.data.success) {
          const { data } = response.data;
          resolve({
            currentBalance: normalizeLeaveBalance(data.leaveDetails),
          });
        } else {
          EnhancedNotification.error({
            message: getResponseError(response.message),
          });
          reject(response.message);
        }
      })
      .catch((err) => {
        EnhancedNotification.error({
          message: getResponseError(err),
        });
        reject(err);
      });
  });

export const cancelApprovedLeaveSource = (payload) =>
  new Promise((resolve, reject) => {
    const url = myLeaves.DELETE;
    axios
      .delete(url, { data: payload })
      .then((response) => {
        if (response && response.data.success) {
          const { data } = response.data;
          EnhancedNotification.success({
            message: 'Updated Successfully',
          });
          resolve(data);
        } else {
          EnhancedNotification.error({
            message: getResponseError(response.message),
          });
          reject(response.message);
        }
      })
      .catch((err) => {
        EnhancedNotification.error({
          message: getResponseError(err),
        });
        reject(err);
      });
  });

export const getTotalLeavesCountSource = (params) =>
  new Promise((resolve, reject) => {
    const url = myLeaves.TOTAL_COUNT;
    axios
      .get(url, {
        params,
      })
      .then((response) => {
        if (response && response.data.success) {
          const { data } = response.data;
          resolve(data);
        } else {
          EnhancedNotification.error({
            message: getResponseError(response.message),
          });
          reject(response.message);
        }
      })
      .catch((err) => {
        EnhancedNotification.error({
          message: getResponseError(err),
        });
        reject(err);
      });
  });
