import { EnhancedCard } from 'components/shared/antd';
import { Steps } from 'antd';
import React from 'react';

const StepsComponent = (props) => {
  const { currentStep, steps } = props;
  const { Step } = Steps;
  return (
    <div>
      <EnhancedCard>
        <React.Fragment>
          <Steps current={currentStep}>
            {steps.map((item) => {
              return <Step title={item.title} />;
            })}

            {/* <Step
              title="Competencies"
            />
            <Step title="Goals"  />
            <Step title="Training"  />
            <Step title="End Notes"  /> */}
          </Steps>
        </React.Fragment>
      </EnhancedCard>
    </div>
  );
};
export default StepsComponent;
