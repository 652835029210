export const LOGGED_COLOR = '#A9CD34';
export const DUE_COLOR = '#AAA59D';
export const NOT_DUE_COLOR = '#AAA59D';
export const LEAVE_PENDING_COLOR = '#F5A735';
export const LEAVE_DECLINED_COLOR = '#d2381f';
export const LEAVE_APPROVED_COLOR = '#0D2234';
export const LEAVE_CANCELLED_COLOR = '#9B9B9B';
export const LEAVE_PENDING_CANCELLATION_COLOR = '#ce7fe8';

export const DASHBOARD_BILLED_COLOR = '#36CBCB';
export const DASHBOARD_BILLABLE_COLOR = '#2C82BE';
export const DASHBOARD_NON_BILLABLE_COLOR = '#36CBCB';
export const DASHBOARD_BENCH_COLOR = '#975FE4';
export const DASHBOARD_INTERNAL_COLOR = '#F2637B';
export const DASHBOARD_BACKUP_COLOR = '#FAD337';
export const DASHBOARD_ADDITIONAL_COLOR = '#2C82BE';
export const MAIN_BACKGROUND_THEME_COLOR = '#0D2234';
export const VENDIANS_COLORS = {
  VEND_PROMISE: '#0D2234',
  VEND_PASSION: '#EE2737',
  VEND_GREEN: '#1DAC8A',
};

export const color = [
  '#98c96d',
  '#c96d6d',
  '#00000073',
  '#F5A735',
  '#d2381f',
  '#b1c3a1',
  '#9B9B9B',
  '#5e7fe8',
  '#46CBCB',
  '#2C82BE',
  '#31cb8d',
  '#1217e4',
  '#F2637B',
  '#FAD337',
  '#b5babe',
  '#cc95e4',
  '#e40fc3',
  '#e4436c',
  '#51e4d5',
  '#b9e4ca',
  '#0de404',
  '#0D2234',
];
