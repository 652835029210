import { connect } from 'react-redux';
import Login from 'components/Login';
import { loginRequest } from 'store/actions/LoginActions';

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginSuccessRequest: (data) => dispatch(loginRequest(data)),
  };
}

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default LoginContainer;
