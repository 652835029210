import {
  EnhancedCol,
  EnhancedButton,
  EnhancedIcon,
  EnhancedCard,
  EnhancedRow,
  EnhancedDivider,
  EnhancedTabs,
  EnhancedTooltip,
  EnhancedRadio,
} from 'components/shared/antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import _ from 'lodash';
import ProjectAddEditEmployeeModal from 'components/project/ProjectAddEditEmployeeModal';
import { DATE_FORMAT } from 'configs/employeeProfileConstants';
import ProjectDetailsEditModal from 'components/project/ProjectDetailsEditModal';
import LeaveDetailsModal from 'components/project/LeaveDetailsModal';
import OverAllocationModal from 'components/project/OverAllocationModal';
import projectIcon from 'images/project_icon.svg';
import leaveIndicatorIcon from 'images/ic_leave_indicator.svg';
import ActivityLog from 'components/project/ActivityLog';
import { parse } from 'utils/stringUtils';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';

import { invalidDate, NOT_AVAILABLE, completed } from 'configs/additionalConstants';
import { generateSheet, getMappedProjectEmployeesData } from '../../utils/projectEmployeesUtils';
import './ProjectEmployees.css';
import {
  dateFormat,
  employeeTypesFiltered,
  BREADCRUMBS,
  EMPLOYEE_PROJECT_MODAL_TYPE,
} from '../../configs/constants';
import ProjectDeleteEmployeeModal from './ProjectDeleteEmployeeModal';
import PROJECT_ROUTES from '../../constants/UrlConstants';
import Authorize from '../Authorize';
import Permissions from '../../constants/AccessPermissions';
import { hasPermission } from '../../utils/AccessUtils';

const { TabPane } = EnhancedTabs;

class ProjectDetails extends Component {
  constructor(props) {
    super(props);
    this.EMPLOYEE_TYPES = {
      Existing: 1,
      Previous: 2,
    };
    this.state = {
      sortedInfo: {},
      typeOfProject: 1,
      routeValue: null,
      addEditResourceModalVisible: false,
      deleteResourceModalVisible: false,
      projectDetailsEditModalVisible: false,
      leaveDetailsModalVisible: false,
      overAllocationModalVisible: false,
      projectData: {},
      counter: {},
      headCount: {},
      isEdit: false,
      isDeleteResourceModal: false,
      isDeallocateModal: false,
      fte: 0,
      isMarkAsCompleted: false,
      readOnly: this.props.readOnly,
      leaveDetailsEmployeeID: null,
    };
    props.getProjectRoles();
  }

  componentWillMount() {
    const { isLoading, employees, shouldFetchProjects, user, location } = this.props;
    const queryParams = location.search.split('=')[1];
    if (!isLoading && !employees) this.props.history.push('/crmc/projects');
    else if (queryParams === completed) {
      const isAuthorized = hasPermission(user.permissions, [Permissions.Project.Completed.List]);
      if (!isAuthorized) this.props.history.push('/crmc/projects');
    }
    this.props.getProjectDetailsActions(this.props.match.params.id);
    if (queryParams === completed) {
      this.setState({ routeValue: queryParams, typeOfProject: 2 });
    } else {
      this.setState({ routeValue: null, typeOfProject: 1 });
    }
    this.setState({ routeValue: queryParams });
    if (shouldFetchProjects) this.props.getProjectsAction();
  }

  componentDidMount() {
    this.props.getProjectActivityLogs(this.props.match.params.id, 1);
    this.props.getBusinessUnits();
    this.props.getAllLocations();
    this.props.getEmployeeDepartments();
    this.props.getEmployeeCompetencies();
  }

  componentWillReceiveProps(nextProps) {
    this.calculateTypeSum(nextProps.employees.filter((emp) => emp.existing));
    // this.getCorrectFilterValuesOfColumns(nextProps.employees);
    const { project, updateBreadCrumb } = nextProps;
    if (nextProps.project.name) {
      updateBreadCrumb(BREADCRUMBS.PROJECT, project.id, project.name);
    }

    if (this.props.overAllocation !== nextProps.overAllocation) {
      this.setState({
        overAllocationModalVisible: true,
      });
    }
  }

  onExportSheet = (filteredEmployees) => {
    const {
      project: { name },
    } = this.props;
    generateSheet(getMappedProjectEmployeesData(filteredEmployees), `${name} Allocations`);
  };

  getFileHeaders = () => {
    const headers = [
      {
        label: 'ID',
        key: 'taleoId',
      },
      {
        label: 'Employee Name',
        key: 'name',
      },
      {
        label: 'Role',
        key: 'designation.name',
      },
      {
        label: 'Allocation',
        key: 'allocation',
        renderer: this.percentageRenderer,
      },
      {
        label: 'Billing %',
        key: 'billed',
        renderer: this.percentageRenderer,
      },
      {
        label: 'Type',
        key: 'type',
      },
      {
        label: 'Location',
        key: 'location',
      },
      {
        label: 'Total Experience',
        key: 'totalExperience',
      },
      {
        label: 'Department',
        key: 'department',
      },
      {
        label: 'Competency',
        key: 'competency',
      },
      {
        label: 'Start Date',
        key: 'startDate',
        renderer: this.dateRenderer,
      },
      {
        label: 'End Date',
        key: 'endDate',
        renderer: this.dateRenderer,
      },
      {
        label: 'Extended End Date',
        key: 'extendedEndDate',
        renderer: this.dateRenderer,
      },
      {
        label: 'Probability',
        key: 'probability',
        renderer: this.percentageRenderer,
      },
    ];

    return headers;
  };

  getEmployeesLeaveDetails = () => this.props.employees;

  getFilteredEmployees = (typeOfProject) => {
    const { employees } = this.props;
    if (typeOfProject === this.EMPLOYEE_TYPES.Existing) {
      return employees.filter((emp) => emp.existing);
    } else if (typeOfProject === this.EMPLOYEE_TYPES.Previous) {
      return employees.filter((emp) => !emp.existing);
    }
    return employees;
  };

  getColumnsInfo = () => {
    let { sortedInfo } = this.state;
    const { project, user } = this.props;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: '',
        dataIndex: 'leaveIndicator',
        key: 'leaveIndicator',
        render: this.renderLeaveIndicator,
        width: 50,
        fixed: 'left',
      },
      {
        title: 'Taleo ID',
        dataIndex: 'taleoId',
        key: 'taleoId',
        width: 100,
        render: this.renderEmployeeID,
        sortOrder: sortedInfo.columnKey === 'taleoId' && sortedInfo.order,
        filterConfig: {
          type: 'search',
          key: 'taleoId',
        },
        fixed: 'left',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        filterConfig: {
          type: 'search',
          key: 'name',
        },
        render: this.renderEmployeeName,
        fixed: 'left',
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        width: 100,
        sorter: (a, b) => {
          const first = a.location ? a.location.name : NOT_AVAILABLE;
          const second = b.location ? b.location.name : NOT_AVAILABLE;
          return first.localeCompare(second);
        },
        sortOrder: sortedInfo.columnKey === 'location' && sortedInfo.order,
        ellipsis: true,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'location',
          optionsConfig: {
            values: this.props.locations,
          },
        },
        render: this.renderEmployeeLocation,
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        width: 150,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'role',
          optionsConfig: {
            values: this.props.roles,
            valueVar: 'role',
            labelVar: 'role',
          },
        },
        render: this.renderEmployeeRole,
      },
      {
        title: 'Allocation',
        dataIndex: 'allocation',
        defaultSortOrder: 'ascend',
        width: 100,
        sorter: (a, b) => a.allocation - b.allocation,
        sortOrder: sortedInfo.columnKey === 'allocation' && sortedInfo.order,
        render: this.renderAllocaion,
        key: 'allocations',
      },
      {
        title: 'Billing %',
        dataIndex: 'billed',
        defaultSortOrder: 'ascend',
        width: 100,
        sorter: (a, b) => a.billed - b.billed,
        sortOrder: sortedInfo.columnKey === 'billed' && sortedInfo.order,
        render: this.renderBilled,
        key: 'billed',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: 150,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'type',
          optionsConfig: {
            values: [
              {
                value: 'Billed',
              },
              {
                value: 'Backup',
              },
              {
                value: 'Internal',
              },
              {
                value: 'Additional',
              },
            ],
            valueVar: 'value',
            labelVar: 'value',
          },
        },
        render: this.renderEmployeeType,
      },
      {
        title: 'Competency',
        dataIndex: 'competency',
        key: 'competency',
        width: 150,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'competency',
          optionsConfig: {
            values: this.props.competencies,
          },
        },
        render: this.renderEmployeeCompetency,
      },
      {
        title: 'Department',
        dataIndex: 'department',
        key: 'department',
        width: 150,
        ellipsis: true,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'department',
          optionsConfig: {
            values: this.props.departments,
          },
        },
        render: this.renderEmployeeDepartment,
      },
      {
        title: 'Total Experience',
        dataIndex: 'totalExperience',
        defaultSortOrder: 'ascend',
        key: 'totalExperience',
        width: 190,
        sorter: (a, b) => b.experienceInYears - a.experienceInYears,
        sortOrder: sortedInfo.columnKey === 'totalExperience' && sortedInfo.order,
        render: this.renderTotalExperience,
        filterConfig: {
          type: 'search',
          key: 'totalExperience',
        },
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        defaultSortOrder: 'ascend',
        width: 100,
        render: this.formateDate,
        sortOrder: sortedInfo.columnKey === 'startDate' && sortedInfo.order,
        sorter: (a, b) => a.startDate > b.startDate,
        key: 'startDate',
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
        defaultSortOrder: 'ascend',
        width: 100,
        render: this.formateDate,
        key: 'endDate',
        sorter: (a, b) => a.endDate > b.endDate,
      },
      {
        title: 'Extended End Date',
        dataIndex: 'extendedEndDate',
        width: 100,
        defaultSortOrder: 'ascend',
        render: this.formateDate,
        key: 'extendedEndDate',
        sorter: (a, b) => a.extendedEndDate > b.extendedEndDate,
      },
      {
        title: 'Probability',
        dataIndex: 'probability',
        defaultSortOrder: 'ascend',
        width: 100,
        sorter: (a, b) => a.probability - b.probability,
        sortOrder: sortedInfo.columnKey === 'probability' && sortedInfo.order,
        render: this.renderProbability,
        key: 'probability',
      },
    ];
    if (
      project.status &&
      // this.state.typeOfProject === this.EMPLOYEE_TYPES.Existing &&
      !this.state.readOnly &&
      hasPermission(user.permissions, [
        Permissions.Project.Allocation.Update,
        Permissions.Project.Allocation.Delete,
      ])
    ) {
      columns.push({
        title: 'Actions',
        render: this.actions,
        key: 'x',
        width: 150,
        fixed: 'right',
      });
    }
    return columns;
  };
  setFTE = (employees) => {
    let fte = 0;
    employees.forEach((element) => {
      fte += element.allocation;
    });
    fte /= 100;
    this.setState({
      fte,
    });
  };

  percentageRenderer = (value) => (value ? `${value}%` : '');

  dateRenderer = (value) => (value ? moment(value).format(dateFormat.DDMMMYYYYwithSpace) : '');

  showTotal = (total) => `Total ${total} items`;

  formateDate = (date) =>
    !date ? NOT_AVAILABLE : moment(date).format(dateFormat.DDMMMYYYYwithSpace);

  editEmployeeInProject = (projectData) => {
    this.setState({
      addEditResourceModalVisible: true,
      projectData,
      isEdit: true,
    });
  };

  deleteEmployeeFromProject = (projectData, type) => {
    const { REMOVE } = EMPLOYEE_PROJECT_MODAL_TYPE;
    if (type === REMOVE) {
      this.setState({
        deleteResourceModalVisible: true,
        projectData,
        isDeleteResourceModal: true,
      });
    } else {
      this.setState({
        deleteResourceModalVisible: true,
        projectData,
        isDeallocateModal: true,
      });
    }
  };

  actions = (projectData) => {
    const { REMOVE, DEALLOCATE } = EMPLOYEE_PROJECT_MODAL_TYPE;
    return (
      <div>
        <Authorize requiredPermissions={[Permissions.Project.Allocation.Update]}>
          <EnhancedIcon
            type="edit"
            role="presentation"
            style={{ cursor: 'pointer' }}
            onClick={() => this.editEmployeeInProject(projectData)}
            onKeyDown={() => this.editEmployeeInProject(projectData)}
          />
          <EnhancedDivider type="vertical" />
        </Authorize>
        {projectData.existing && (
          <Authorize requiredPermissions={[Permissions.Project.Allocation.Delete]}>
            <EnhancedIcon
              type="delete"
              role="presentation"
              style={{ cursor: 'pointer' }}
              onClick={() => this.deleteEmployeeFromProject(projectData, REMOVE)}
              onKeyDown={() => this.deleteEmployeeFromProject(projectData, REMOVE)}
            />
            <EnhancedDivider type="vertical" />
            <EnhancedIcon
              type="user-delete"
              role="presentation"
              style={{ cursor: 'pointer' }}
              onClick={() => this.deleteEmployeeFromProject(projectData, DEALLOCATE)}
              onKeyDown={() => this.deleteEmployeeFromProject(projectData, DEALLOCATE)}
            />
          </Authorize>
        )}
      </div>
    );
  };

  handleProjectTypechange = (event) => {
    const typeOfProject = event.target.value;
    this.setState({ typeOfProject });
  };

  handleEditClose = (close) => {
    this.setState({ addEditResourceModalVisible: close });
  };

  handleDeleteWithNoteClose = (close) => {
    this.setState({
      deleteResourceModalVisible: close,
      isDeallocateModal: false,
      isDeleteResourceModal: false,
    });
  };

  handleProjectDetailsClose = (close) => {
    this.setState({
      projectDetailsEditModalVisible: close,
      isMarkAsCompleted: false,
    });
  };
  handleProjectEditSuccess = () => {
    this.props.getProjectsAction();
  };

  handleAddResource = () => {
    this.setState({
      addEditResourceModalVisible: true,
      isEdit: false,
      projectData: {},
    });
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  };
  handleProjectDetailsEdit = () => {
    this.setState({
      projectDetailsEditModalVisible: true,
    });
  };
  handleMarksAsCompletedProject = () => {
    this.setState({
      projectDetailsEditModalVisible: true,
      isMarkAsCompleted: true,
    });
  };

  handleLeaveDetails = (employeeId) => {
    this.setState({
      leaveDetailsEmployeeID: employeeId,
      leaveDetailsModalVisible: true,
    });
  };

  handleLeaveDetailsClose = (close) => {
    this.setState({
      leaveDetailsModalVisible: close,
    });
  };

  handleOverAllocationClose = (close) => {
    this.setState({
      overAllocationModalVisible: close,
    });
  };

  shouldButtonDisable = () => {
    if (this.props.project.status) {
      return false;
    }
    return true;
  };

  calculateTypeSum = (employees) => {
    const counter = {};
    const headCount = {};
    this.setFTE(employees);
    const uniqueEmployees = _.chain(employees)
      .groupBy('type')
      .mapValues((item) => _.uniqBy(item, 'employeeId'))
      .values()
      .flattenDeep()
      .value();
    employees.forEach((element) => {
      if (counter[element.type] >= 0) {
        counter[element.type] += element.allocation;
      } else {
        counter[element.type] = element.allocation;
      }
    });
    uniqueEmployees.forEach((element) => {
      if (headCount[element.type] >= 0) {
        headCount[element.type] += 100;
      } else {
        headCount[element.type] = 100;
      }
    });
    Object.keys(counter).forEach((key) => {
      counter[key] /= 100;
      counter[key] = counter[key];
    });
    Object.keys(headCount).forEach((key) => {
      headCount[key] /= 100;
      headCount[key] = headCount[key];
    });
    this.setState({
      counter,
      headCount,
    });
  };

  renderLeaveIndicator = (leaveIndicator, data) => (
    /* eslint-disable */
    <EnhancedTooltip title="Leave Indicator">
      <a onClick={() => this.handleLeaveDetails(data.employeeId)}>
        <img src={leaveIndicatorIcon} alt="leave indicator" />
      </a>
    </EnhancedTooltip>
    /* eslint-enable */
  );

  renderEmployeeID = (name, data) => {
    const employeeId = data.taleoId ? data.taleoId : ' N/A ';
    const { resignationDate, isActive } = data;
    const color = moment() >= moment(resignationDate) && isActive ? 'red' : '#5A5A5A';
    return <div style={{ color, wordBreak: 'normal' }}>{employeeId}</div>;
  };

  renderDepartmentName = (name, data) => <span>{data.department}</span>;

  renderCompetencyName = (name, data) => <span>{data.competency}</span>;

  renderEmployeeName = (name, data) => {
    let element = null;
    const { resignationDate, isActive, leavingDate } = data;
    const color = moment() >= moment(resignationDate) && isActive ? 'red' : '#5A5A5A';
    const { permissions } = this.props.user;
    const isAuthorized = hasPermission(permissions, [Permissions.Employee.View]);
    const link = parse(PROJECT_ROUTES.EMPLOYEE_DETAIL_ROUTE, {
      status: PROJECT_ROUTES.CURRENT,
      id: data.employeeId,
    });

    const nameElement = <b style={{ color }}>{name}</b>;
    const linkedName = <Link to={link}>{nameElement}</Link>;

    const displayType = isAuthorized ? linkedName : nameElement;
    element =
      moment() >= moment(resignationDate) && isActive ? (
        <EnhancedTooltip
          title={`On Notice Period. ${
            leavingDate ? `Last Working Day: ${moment(leavingDate).format(DATE_FORMAT)}` : ''
          }`}
        >
          {displayType}
        </EnhancedTooltip>
      ) : (
        displayType
      );

    return element;
  };

  renderEmployeeLocation = (name, data) => (
    <span>{data.location ? data.location : NOT_AVAILABLE}</span>
  );

  renderEmployeeRole = (name, data) => <span>{data.role ? data.role : NOT_AVAILABLE}</span>;

  renderEmployeeType = (name, data) => <span>{data.type ? data.type : NOT_AVAILABLE}</span>;
  renderEmployeeDepartment = (name, data) => (
    <span>{data.department ? data.department : NOT_AVAILABLE}</span>
  );
  renderEmployeeCompetency = (name, data) => (
    <span>{data.competency ? data.competency : NOT_AVAILABLE}</span>
  );

  renderTotalExperience = (name, data) => <div>{data.totalExperience || NOT_AVAILABLE}</div>;

  renderAllocaion = (name, data) => <div>{data.allocation}%</div>;

  renderProbability = (name, data) => <div>{data.probability}%</div>;

  renderBilled = (name, data) => <div>{data.billed}%</div>;

  renderEmployeeTypeCounts = () => {
    const types = [];
    const { counter } = this.state;
    const style = {
      fontSize: 10,
      textTransform: 'capitalize',
    };
    employeeTypesFiltered.forEach((element) => {
      if (counter[element]) {
        types.push(
          <h2 style={style}>
            {element.toLowerCase()}: {counter[element]}
          </h2>,
        );
      }
    });
    return types;
  };

  renderEmployeeHeadCounts = () => {
    const types = [];
    const { headCount } = this.state;
    const style = {
      fontSize: 10,
      textTransform: 'capitalize',
    };
    employeeTypesFiltered.forEach((element) => {
      if (headCount[element]) {
        types.push(
          <h2 style={style}>
            {element.toLowerCase()}: {headCount[element]}
          </h2>,
        );
      }
    });
    return types;
  };

  render() {
    const columns = this.getColumnsInfo();
    const { typeOfProject } = this.state;
    const {
      allEmployees,
      user,
      project: { name },
    } = this.props;
    const { employees, projectRoles } = this.props;

    const existingEmployees = employees.filter((emp) => emp.existing);
    const headCount = Object.keys(_.groupBy(existingEmployees, 'employeeId')).length;
    const currentEmployees = existingEmployees.map((employee) => employee.employeeId);
    if (!currentEmployees.includes(this.props.userId)) {
      currentEmployees.push(this.props.userId);
    }

    const filteredEmployees = this.getFilteredEmployees(typeOfProject);

    const leadershipRoles = projectRoles.filter((pr) => pr.isLeadership);
    return (
      <div style={{ background: 'white' }}>
        <EnhancedCard loading={this.props.isProjectLoading} bordered={false}>
          <EnhancedRow>
            <EnhancedCol lg={2}>
              <img src={projectIcon} alt="logo" style={{ width: '60px', height: '60px' }} />
            </EnhancedCol>
            <EnhancedCol lg={8}>
              <h2 style={{ color: 'black', fontSize: '10' }}>{this.props.project.name}</h2>
              <p>
                {this.props.project.startDate
                  ? moment.utc(this.props.project.startDate).format(dateFormat.DDMMMYYYYwithSpace)
                  : NOT_AVAILABLE}{' '}
                -
                {moment.utc(this.props.project.endDate).toString() === invalidDate ||
                this.props.project.endDate === undefined
                  ? 'Present'
                  : moment.utc(this.props.project.endDate).format(dateFormat.DDMMMYYYYwithSpace)}
              </p>
              {!this.shouldButtonDisable() && !this.state.readOnly ? (
                <div>
                  <Authorize requiredPermissions={[Permissions.Project.Update]}>
                    <EnhancedButton
                      style={{ marginBottom: '10px' }}
                      onClick={this.handleProjectDetailsEdit}
                    >
                      Edit Details
                    </EnhancedButton>
                  </Authorize>
                  <Authorize requiredPermissions={[Permissions.Project.Update]}>
                    <EnhancedButton
                      style={{ marginLeft: '10px', marginBottom: '10px' }}
                      onClick={this.handleMarksAsCompletedProject}
                      loading={this.props.isProjectLoading}
                    >
                      Mark as Completed
                    </EnhancedButton>
                  </Authorize>
                </div>
              ) : (
                ''
              )}
            </EnhancedCol>
            <EnhancedCol lg={5}>
              <div
                style={{
                  display: 'flex',
                  marginTop: '5px',
                  flexDirection: 'row',
                }}
              >
                <EnhancedDivider type="vertical" style={{ fontSize: '60px', marginTop: '10px' }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h2 style={{ fontSize: '15px' }}>Business Unit</h2>
                  <b style={{ fontSize: '15px' }}>
                    {this.props.project.businessUnit ? this.props.project.businessUnit.name : 'N/A'}
                  </b>
                </div>
              </div>
            </EnhancedCol>
            <EnhancedCol lg={5}>
              <div
                style={{
                  display: 'flex',
                  marginTop: '5px',
                  flexDirection: 'row',
                }}
              >
                <EnhancedDivider type="vertical" style={{ fontSize: '60px', marginTop: '10px' }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h2 style={{ fontSize: '15px' }}>F.T.E</h2>
                  <b style={{ fontSize: '20px' }}>{this.state.fte}</b>
                </div>
                <div
                  style={{
                    marginLeft: '30px',
                    marginTop: '2px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {this.renderEmployeeTypeCounts()}
                </div>
              </div>
            </EnhancedCol>
            <EnhancedCol lg={4}>
              <div
                style={{
                  display: 'flex',
                  marginTop: '5px',
                  flexDirection: 'row',
                }}
              >
                <EnhancedDivider type="vertical" style={{ fontSize: '60px', marginTop: '10px' }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h2 style={{ fontSize: '15px' }}>Head count</h2>
                  <b style={{ fontSize: '20px' }}>{headCount}</b>
                </div>
                <div
                  style={{
                    marginLeft: '30px',
                    marginTop: '2px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {this.renderEmployeeHeadCounts()}
                </div>
              </div>
            </EnhancedCol>
          </EnhancedRow>
        </EnhancedCard>
        <EnhancedTabs defaultActiveKey="1">
          <TabPane tab="Resources" key="1" style={{ background: '#F0F2F5' }}>
            <EnhancedCard style={{ marginTop: '20px' }}>
              <EnhancedRow>
                <EnhancedCol lg={12}>
                  <EnhancedRadio.Group
                    className="table-operations"
                    onChange={this.handleProjectTypechange}
                    size="small"
                    defaultValue={
                      this.state.routeValue && this.state.routeValue === completed ? 2 : 1
                    }
                  >
                    {this.state.routeValue && this.state.routeValue === completed ? (
                      <EnhancedRadio.Button value={this.EMPLOYEE_TYPES.Previous}>
                        Previous
                      </EnhancedRadio.Button>
                    ) : (
                      Object.keys(this.EMPLOYEE_TYPES).map((key) => (
                        <EnhancedRadio.Button value={this.EMPLOYEE_TYPES[key]} selected>
                          {key}
                        </EnhancedRadio.Button>
                      ))
                    )}
                  </EnhancedRadio.Group>
                </EnhancedCol>
                <EnhancedCol lg={12} style={{ textAlign: 'right' }}>
                  {/* {filteredEmployees.length ? (
                    <Authorize requiredPermissions={[Permissions.Project.Export]}>
                      <EnhancedButton
                        icon="file-excel"
                        style={{ marginRight: '15px' }}
                        onClick={() => this.onExportSheet(filteredEmployees)}
                      >
                        Export
                      </EnhancedButton>
                    </Authorize>
                  ) : (
                    ''
                  )} */}

                  {!this.shouldButtonDisable() && !this.state.readOnly ? (
                    <Authorize requiredPermissions={[Permissions.Project.Allocation.Create]}>
                      <EnhancedButton
                        onClick={this.handleAddResource}
                        style={{ marginLeft: '15px' }}
                        type="primary"
                        size="small"
                      >
                        Add New Resource
                      </EnhancedButton>
                    </Authorize>
                  ) : (
                    ''
                  )}
                </EnhancedCol>
              </EnhancedRow>
              <EnhancedRow />
              <EnhancedClientSideTable
                rowKey={(record) => record.employeeProjectId}
                loading={this.props.isLoading}
                columns={columns}
                expandRowByClick={false}
                rowClassName={(record) => {
                  let classNames = '';
                  if (!('leavesDetails' in record) || record.leavesDetails.length === 0) {
                    classNames += 'hide-li-icon ';
                  }
                  if (record.comment === '') {
                    classNames += 'hide-icon';
                  }

                  if (
                    record.resignationDate &&
                    moment() >= moment(record.resignationDate) &&
                    record.isActive
                  ) {
                    classNames += ' on-notice ';
                  }

                  return classNames;
                }}
                expandedRowRender={(record) => {
                  let data = '';
                  if (record.comment !== '' && record.comment.includes('{')) {
                    data = JSON.parse(record.comment);
                  } else if (record.comment !== '') {
                    data = [{ comment: record.comment, commentDate: moment.utc() }];
                  } else {
                    return null;
                  }

                  data = data.sort((a, b) => new Date(b.commentDate) - new Date(a.commentDate));

                  const commentSection = data.map((val) => (
                    <EnhancedRow type="flex" justify="space-between" gutter={10}>
                      <EnhancedCol span={14}>
                        <p>{val.comment}</p>
                      </EnhancedCol>
                      <EnhancedCol span={10}>
                        <b>{moment.utc(val.commentDate).format(dateFormat.DDMMMYYYYwithSpace)}</b>
                      </EnhancedCol>
                      <br />
                    </EnhancedRow>
                  ));
                  return commentSection;
                }}
                data={filteredEmployees}
                onChange={this.handleChange}
                style={{ textTransform: 'capitalize' }}
                scroll={{ x: 1600 }}
                exportFileConfig={{
                  fileName: `${name} Allocations`,
                  fileHeaders: this.getFileHeaders(),
                  showExportButton: hasPermission(user.permissions, [Permissions.Project.Export]),
                }}
              />
            </EnhancedCard>
          </TabPane>
          <TabPane tab="Activity Log" key="2" style={{ background: '#F0F2F5' }}>
            <ActivityLog
              activityLogAction={this.props.getProjectActivityLogs}
              projectId={this.props.match.params.id}
              activityLogs={this.props.activityLogs}
              isLoading={this.props.isLoadingLogs}
            />
          </TabPane>
        </EnhancedTabs>
        <ProjectAddEditEmployeeModal
          activityLogAction={this.props.getProjectActivityLogs}
          typeOfProject={this.state.typeOfProject}
          userId={this.props.userId}
          visible={this.state.addEditResourceModalVisible}
          projectData={this.state.projectData}
          roles={this.props.roles}
          updateEmployeesProjectActions={this.props.updateEmployeesProjectActions}
          onModalClose={this.handleEditClose}
          projectId={this.props.match.params.id}
          isEdit={this.state.isEdit}
          allEmployees={allEmployees}
          currentEmployees={currentEmployees}
          isLoadingEmployees={this.props.isLoadingEmployees}
          addNewResourceProject={this.props.addNewResourceProject}
          project={this.props.project}
        />
        <ProjectDetailsEditModal
          userId={this.props.userId}
          project={this.props.project}
          onModalClose={this.handleProjectDetailsClose}
          visible={this.state.projectDetailsEditModalVisible}
          editProjectDetails={this.props.editProjectDetails}
          managers={this.props.allEmployees}
          isLoadingEmployees={this.props.isLoadingEmployees}
          isProjectLoading={this.props.isProjectLoading}
          isMarkAsCompleted={this.state.isMarkAsCompleted}
          employees={filteredEmployees}
          updateEmployeesProjectActions={this.props.updateEmployeesProjectActions}
          businessUnits={this.props.businessUnits}
          leadershipRoles={leadershipRoles}
          history={this.props.history}
        />
        <LeaveDetailsModal
          employeeId={this.state.leaveDetailsEmployeeID}
          leavesData={employees}
          onModalClose={this.handleLeaveDetailsClose}
          visible={this.state.leaveDetailsModalVisible}
          workingHolidays={this.props.workingHolidays}
        />
        <OverAllocationModal
          data={this.props.overAllocation}
          onModalClose={this.handleOverAllocationClose}
          visible={this.state.overAllocationModalVisible}
        />
        <ProjectDeleteEmployeeModal
          assignorId={this.props.userId}
          visible={this.state.deleteResourceModalVisible}
          projectData={this.state.projectData}
          onModalClose={this.handleDeleteWithNoteClose}
          onDeallocateEmployee={this.props.deallocateEmployee}
          projectName={this.props.project ? this.props.project.name : ''}
          isDeallocateModal={this.state.isDeallocateModal}
          isDeleteResourceModal={this.state.isDeleteResourceModal}
        />
      </div>
    );
  }
}

export default ProjectDetails;
