import { connect } from 'react-redux';
import BusinessUnit from 'components/businessUnit/businessUnit';
import {
  getEmployeeBusinessUnitAction,
  addBusinessUnitAction,
  editBusinessUnitAction,
  deleteBusinessUnitAction,
} from 'store/actions/EmployeeActions';

function mapStateToProps(state) {
  const { employeeReducer, auth } = state;
  const { departments, isLoading, businessUnits } = employeeReducer;
  return {
    departments,
    isLoading,
    userId: auth.user && auth.user.id,
    businessUnits,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEmployeeBusinessUnitAction: () => dispatch(getEmployeeBusinessUnitAction()),
    addBusinessUnit: (request) => dispatch(addBusinessUnitAction(request)),
    editBusinessUnit: (request) => dispatch(editBusinessUnitAction(request)),
    deleteBusinessUnit: (request) => dispatch(deleteBusinessUnitAction(request)),
  };
}

const BusinessUnitContainer = connect(mapStateToProps, mapDispatchToProps)(BusinessUnit);

export default BusinessUnitContainer;
