import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PivotTable from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import evaluationStatus, { PMDashboardConstants } from 'configs/performanceManagementConstants';
import { reviewTypes, DATE_FORMAT } from 'configs/employeeProfileConstants';
import moment from 'moment';
import EmployeesList from './EmployeesList';
import RatingRenderer from './RatingRenderer';
import PMCycleDropdown from './PMCycleDropdown';

const getCustomizedColumns = (baseUrlForEvaluation) => {
  const columns = [
    {
      title: 'Review Manager',
      dataIndex: 'reviewManagerName',
      key: 'reviewManagerName',
      sorter: (a, b) => a.reviewManagerName.localeCompare(b.reviewManagerName),
      config: {
        needFilter: true,
        showInTable: true,
        showInExport: true,
      },
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      config: {
        showInTable: true,
        showInExport: true,
      },
    },
    {
      title: 'Self Rating',
      dataIndex: ['self', 'rating'],
      config: {
        showInTable: true,
        showInExport: true,
      },
      sorter: (a, b) => a.self.rating - b.self.rating,
      render: (rating, _record) => (
        <RatingRenderer
          rating={rating}
          reviewType={reviewTypes.SELF}
          baseUrl={baseUrlForEvaluation}
          performanceCycleId={_record.performanceCycleId}
          employeeId={_record.employeeId}
        />
      ),
    },
    {
      title: 'Manager Rating',
      dataIndex: ['manager', 'rating'],
      config: {
        showInTable: true,
        showInExport: true,
      },
      sorter: (a, b) => a.manager.rating - b.manager.rating,
      render: (rating, _record) => (
        <RatingRenderer
          rating={rating}
          reviewType={reviewTypes.MANAGER}
          baseUrl={baseUrlForEvaluation}
          performanceCycleId={_record.performanceCycleId}
          employeeId={_record.employeeId}
        />
      ),
    },
  ];
  return columns;
};

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

const ManagerEvaluation = (props) => {
  const {
    pmCycles,
    evaluationReport,
    getPMCycleActions,
    getEvaluationReport,
    baseUrlForEvaluation,
    divisions,
    departments,
    competencies,
    businessUnits,
  } = props;
  const [employeesList, setEmployeesList] = useState([]);
  const [managerEvaluationSummary, setManagerEvaluationSummary] = useState([]);

  const [pivotTable, setPivotTable] = useState({
    cols: ['category'],
    rows: ['competency', 'department'],
    hiddenFromDragDrop: PMDashboardConstants.HIDDEN_COLUMNS_FROM_DRAG_DROP,
    rendererOptions: { plotly: { width: 600, height: 600 } },
    tableOptions: {
      height: '100vh',
      clickCallback: (e, value, filters, pivotData) => {
        // in some cases, some key values are coming as null in filters, specially when we click "sum" rows at the bottom, hence it is effecting filters
        const correctFilters = {};

        Object.keys(filters).forEach((key) => {
          if (filters[key]) {
            correctFilters[key] = filters[key];
          }
        });
        const resources = [];
        pivotData.forEachMatchingRecord(correctFilters, (record) => {
          resources.push(record);
        });
        setEmployeesList(resources);
      },
    },
    unusedOrientationCutoff: Infinity,
  });

  const getCategory = (status, rating) => {
    let category = '';

    if (status === evaluationStatus.PENDING && !rating) {
      category = 'Evaluation not filled';
    } else if (status === evaluationStatus.PENDING && rating) {
      category = 'Evaluation partially filled';
    } else if (status === evaluationStatus.PENDING_APPROVAL) {
      category = 'Employees evaluated';
    }

    return category;
  };

  useEffect(() => {
    if (evaluationReport.length > 0) {
      // evaluations with status of Pending or Pending Approval will be considered for this one
      const normalizedData = [];

      evaluationReport.forEach((item) => {
        const { manager } = item;
        if (
          [evaluationStatus.PENDING, evaluationStatus.PENDING_APPROVAL].includes(manager.status)
        ) {
          normalizedData.push({
            ...item,
            category: getCategory(manager.status, manager.rating),
          });
        }
      });

      setManagerEvaluationSummary(normalizedData);
    } else {
      setManagerEvaluationSummary([]);
    }
  }, [evaluationReport]);

  useEffect(() => {
    const params = {
      pageNumber: 0,
      pageSize: 100000,
    };

    getPMCycleActions(params);
  }, []);

  const changeActionHandler = (id) => {
    getEvaluationReport(id);
  };

  const performanceCycleDropdown = () => {
    return <PMCycleDropdown pmCycles={pmCycles} onChange={changeActionHandler} />;
  };

  return (
    <div style={{ overflow: 'hidden', overflowY: 'auto' }} className="mt-20">
      <div className="mb-20">{performanceCycleDropdown()}</div>
      <PivotTable
        data={managerEvaluationSummary}
        renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
        {...pivotTable}
        onChange={(s) => setPivotTable(s)}
      />
      <div style={{ marginTop: '30px', overflowY: 'scroll' }}>
        <EmployeesList
          employees={employeesList}
          customizedColumns={getCustomizedColumns(baseUrlForEvaluation)}
          exportFileName={`LM Evaluation Status - ${moment().format(DATE_FORMAT)}`}
          divisions={divisions}
          departments={departments}
          competencies={competencies}
          businessUnits={businessUnits}
        />
      </div>
    </div>
  );
};

ManagerEvaluation.propTypes = {
  evaluationReport: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      taleoId: PropTypes.string.isRequired,
      division: PropTypes.string.isRequired,
      competency: PropTypes.string.isRequired,
      department: PropTypes.string.isRequired,
      employeeId: PropTypes.number.isRequired,
      designation: PropTypes.string.isRequired,
      businessUnit: PropTypes.string.isRequired,
      reviewManagerName: PropTypes.string.isRequired,
      self: PropTypes.shape({
        status: PropTypes.string.isRequired,
        rating: PropTypes.number,
      }),
      manager: PropTypes.shape({
        status: PropTypes.string.isRequired,
        rating: PropTypes.number,
      }),
    }),
  ).isRequired,
  getEvaluationReport: PropTypes.func.isRequired,
};

export default ManagerEvaluation;
