import {
  EnhancedRow,
  EnhancedCol,
  EnhancedCard,
  EnhancedDivider,
  EnhancedForm,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  ROW_GUTTER_SIZE,
  FULL_GRID_SIZE,
  TEXT,
  TEXTAREA,
  RATING,
  DATE_FORMAT,
  reviewTypes,
} from 'configs/employeeProfileConstants';
import { createFields } from 'utils/FieldGenerateUtils';
import AsteriskLabel from '../../shared/performanceEvaluationForm/asteriskHighlighted';
import { calculateGoalsOverallRating } from '../../../utils/performanceManagementUtils';
import Ratings from '../../shared/performanceEvaluationForm/Ratings';

const GoalsSteps = (props) => {
  const {
    viewer,
    isEditable,
    data,
    updateHandler,
    reviewType,
    selfEvaluation,
    showSelfEvaluationComments,
    ratingOptions,
    pefData,
    emptyRatings,
    setEmptyRatings,
  } = props;
  const [goals, setGoals] = useState([]);
  const [goalsOverallRating, setGoalsOverallRating] = useState({ manager: 0.0, self: 0.0 });

  useEffect(() => {
    setGoals(data);
  }, [data]);

  useEffect(() => {
    if (reviewType === reviewTypes.MANAGER) {
      setGoalsOverallRating({
        manager: calculateGoalsOverallRating(pefData.goals),
        self:
          pefData.selfEvaluation === undefined
            ? goalsOverallRating.self
            : calculateGoalsOverallRating(pefData.selfEvaluation.goals),
      });
    } else {
      setGoalsOverallRating({
        manager: goalsOverallRating.manager,
        self: calculateGoalsOverallRating(pefData.goals),
      });
    }
  }, []);

  const handleSave = () => {};

  const handleChange = (value, key, error) => {
    if (!error) {
      const cloneGoals = [...goals];
      const index = key.split('-');
      cloneGoals[index[1]][index[0]] = value;

      if (reviewType === reviewTypes.MANAGER) {
        setGoalsOverallRating({
          manager: calculateGoalsOverallRating(pefData.goals),
          self: goalsOverallRating.self,
        });
      } else {
        setGoalsOverallRating({
          manager: goalsOverallRating.manager,
          self: calculateGoalsOverallRating(pefData.goals),
        });
      }

      // deletes the goal name from error list if the rating is marked and becomes greater than 0
      const updatedGoalsErrorArr = emptyRatings.goals.filter(
        (item) => item !== cloneGoals[index[1]].title,
      );
      setEmptyRatings({
        competencies: emptyRatings.competencies,
        goals: updatedGoalsErrorArr,
        commentLength: emptyRatings.commentLength,
        hasError: emptyRatings.hasError,
      });

      updateHandler('goals', cloneGoals);
      setGoals(cloneGoals);
    }
  };

  const getFieldsConfig = (goal, index) => {
    const fields = [
      {
        key: `rating-${index}`,
        label: <AsteriskLabel asterisk={`${viewer} Rating`} />,
        value: goal.rating,
        type: RATING,
        showRatingText: true,
        ratingOptions,
      },
      {
        key: `comment-${index}`,
        label: `${viewer} Comments`,
        value: goal.comment,
        type: TEXTAREA,
        noCapitalize: true,
        // labelSize: 4,
        // valueSize: 20,
        className: 'break-spaces',
      },
    ];

    if (selfEvaluation) {
      const goalObj = selfEvaluation.goals.find((item) => item.goalId === goal.goalId);

      fields.push({
        key: `selfrating-${index}`,
        label: `Employee Rating`,
        value: goalObj.rating,
        type: RATING,
        disabled: true,
        showRatingText: true,
        ratingOptions,
      });

      if (showSelfEvaluationComments) {
        fields.push({
          key: `selfcomment-${index}`,
          label: `Employee Comments`,
          value: goalObj.comment,
          type: TEXT,
          noCapitalize: true,
          className: 'break-spaces',
        });
      }
    }

    return fields;
  };

  return (
    <div>
      <EnhancedCard>
        <React.Fragment>
          <EnhancedRow type="flex" justify="space-between">
            <h2 style={{ marginBottom: 0 }}>
              Goals <strong>(Weight: 70.00%)</strong>
            </h2>
            {reviewType === reviewTypes.MANAGER && (
              <Ratings
                reviewType="manager"
                value={goalsOverallRating.manager}
                options={ratingOptions}
              />
            )}
            {(reviewType === reviewTypes.SELF ||
              (reviewType === reviewTypes.MANAGER && pefData.selfEvaluation !== undefined)) && (
              <Ratings
                reviewType="employee"
                value={goalsOverallRating.self}
                options={ratingOptions}
              />
            )}
          </EnhancedRow>
          <EnhancedDivider />
          {goals.map((goal, index) => {
            return (
              <React.Fragment>
                <EnhancedRow key={goal.email} gutter={ROW_GUTTER_SIZE}>
                  <EnhancedCol span={FULL_GRID_SIZE}>
                    <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
                      loading={false}
                      bordered={false}
                      className="card-pd-0"
                    >
                      <div>
                        <EnhancedForm onSubmit={handleSave}>
                          {createFields(
                            [
                              {
                                key: `title-${index}`,
                                label: 'Goal Title',
                                value: goal.title,
                                type: TEXT,
                                noCapitalize: true,
                                className: 'break-spaces',
                              },
                              {
                                key: `weightage-${index}`,
                                label: 'Weighted',
                                value: goal.weightage,
                                type: TEXT,
                              },
                              {
                                key: `description-${index}`,
                                label: 'Description',
                                value: goal.description,
                                type: TEXT,
                                noCapitalize: true,
                                className: 'break-spaces',
                              },
                              {
                                key: `createdAt-${index}`,
                                label: 'Date Created',
                                value: moment.utc(goal.createdAt).format(DATE_FORMAT),
                                type: TEXT,
                              },
                              {
                                key: `dueDate-${index}`,
                                label: 'Due Date',
                                value: moment.utc(goal.dueDate).format(DATE_FORMAT),
                                type: TEXT,
                              },
                            ],
                            2,
                            isEditable,
                            [],
                            handleChange,
                          )}
                          {createFields(
                            getFieldsConfig(goal, index),
                            2,
                            isEditable,
                            [],
                            handleChange,
                          )}
                        </EnhancedForm>
                      </div>
                    </EnhancedCard>
                  </EnhancedCol>
                </EnhancedRow>
                <EnhancedDivider />
              </React.Fragment>
            );
          })}
        </React.Fragment>
      </EnhancedCard>
    </div>
  );
};
export default GoalsSteps;
