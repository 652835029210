import {
  LEAVES_STATUSES_GET,
  LEAVES_STATUSES_GET_SUCCESS,
  LEAVES_STATUSES_GET_FAIL,
} from 'constants/MyLeavesTypes';
import { requestLeavesStatusesSource } from 'sources/LeavesSource';

// Leaves Statuses
export const requestLeavesStatuses = () => {
  const action = {
    type: LEAVES_STATUSES_GET,
  };
  return action;
};

export const LeavesStatusesSuccess = (data) => {
  const action = {
    type: LEAVES_STATUSES_GET_SUCCESS,
    data,
  };
  return action;
};

export const LeavesStatusesFail = () => {
  const action = {
    type: LEAVES_STATUSES_GET_FAIL,
  };
  return action;
};

export const getLeavesStatuses = () => (dispatch) => {
  dispatch(requestLeavesStatuses());
  return requestLeavesStatusesSource()
    .then((response) => dispatch(LeavesStatusesSuccess(response)))
    .catch((error) => dispatch(LeavesStatusesFail(error)));
};
