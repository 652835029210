import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedModal,
  EnhancedDatePicker,
  EnhancedInput,
  EnhancedForm,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import { dateFormat } from 'configs/constants';
import RequiredField from 'components/RequiredField';
import moment from 'moment';

const AddPMCycleModal = (props) => {
  const {
    isModalVisible,
    handleOk,
    isLoading,
    handleCancel,
    addPMCycleActions,
    editPMCycleActions,
    params,
    isEdit,
    getPMCycleDetail,
    id,
    cycleDetails,
  } = props;
  const [pmcField, setPMCField] = useState({
    id: '',
    title: '',
    startDate: moment().format(dateFormat.YYYYMMDDwithHyphen),
    endDate: moment().format(dateFormat.YYYYMMDDwithHyphen),
    dueDate: moment().format(dateFormat.YYYYMMDDwithHyphen),
    // category: 'pending',
  });
  useEffect(() => {
    if (id) getPMCycleDetail(id);
  }, []);
  const createNewPMC = () => {
    addPMCycleActions({ payload: pmcField, parameters: params });
    handleOk();
  };
  const editPMC = () => {
    editPMCycleActions({ payload: pmcField, parameters: params });
    handleOk();
  };
  useEffect(() => {
    if (isEdit && !pmcField.title) {
      setPMCField({
        id,
        title: cycleDetails.title,
        startDate: moment(cycleDetails.startDate).format(dateFormat.YYYYMMDDwithHyphen),
        endDate: moment(cycleDetails.endDate).format(dateFormat.YYYYMMDDwithHyphen),
        dueDate: moment(cycleDetails.dueDate).format(dateFormat.YYYYMMDDwithHyphen),
      });
    }
  }, [cycleDetails]);

  const onDueDateChange = (date) => {
    setPMCField({
      ...pmcField,
      dueDate: moment(date).format(dateFormat.YYYYMMDDwithHyphen),
    });
  };

  const onStartDateChange = (date) => {
    setPMCField({
      ...pmcField,
      startDate: moment(date).format(dateFormat.YYYYMMDDwithHyphen),
    });
  };

  const onEndDateChange = (date) => {
    setPMCField({
      ...pmcField,
      endDate: moment(date).format(dateFormat.YYYYMMDDwithHyphen),
    });
  };

  const closeModalHandler = () => {
    setPMCField({
      id: '',
      title: '',
      startDate: moment().format(dateFormat.YYYYMMDDwithHyphen),
      endDate: moment().format(dateFormat.YYYYMMDDwithHyphen),
      dueDate: moment().format(dateFormat.YYYYMMDDwithHyphen),
    });
    handleCancel();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPMCField({
      ...pmcField,
      [name]: value,
    });
  };
  return (
    <EnhancedModal
      title={isEdit ? 'Edit PM Cycle' : 'Add New PM Cycle'}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={closeModalHandler}
      className="add-goals-modal"
      width="1041px"
      footer={[
        <EnhancedButton
          disabled={
            !pmcField.title || !pmcField.startDate || !pmcField.dueDate || !pmcField.endDate
          }
          key="submit"
          type="primary"
          onClick={isEdit ? editPMC : createNewPMC}
        >
          {'Save Changes'}
        </EnhancedButton>,
      ]}
    >
      <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
        loading={isLoading}
        bordered={false}
        className="details-card-wrapper"
        style={{
          marginTop: 10,
        }}
      >
        <EnhancedRow>
          <EnhancedForm layout="inline" className="goals-form">
            {/* <EnhancedCol span={24} className="form-col"> */}
            <EnhancedCol lg={4}>
              <RequiredField> Title </RequiredField>
            </EnhancedCol>
            <EnhancedCol lg={7}>
              <EnhancedInput name="title" value={pmcField.title} onChange={handleChange} />
            </EnhancedCol>
            <EnhancedCol lg={2} />
            {/* <Col lg={4}>
                <RequiredField> Category </RequiredField>
              </Col>
              <Col lg={7}>
                <EnhancedInput
                  type="text"
                  name="category"
                  value={pmcField.category}
                  onChange={handleChange}
                />
              </Col> */}

            <EnhancedCol lg={4}>
              <RequiredField> Start Date </RequiredField>
            </EnhancedCol>
            <EnhancedCol lg={7}>
              <EnhancedDatePicker
                name="startDate"
                style={{ width: '100%' }}
                onChange={onStartDateChange}
                value={moment(pmcField.startDate, dateFormat.YYYYMMDDwithHyphen)}
                format="YYYY-MM-DD"
                // disabledDate={(current) => {
                //   const customDate = moment().format(dateFormat.YYYYMMDDwithHyphen);
                //   return current && current < moment(customDate, dateFormat.YYYYMMDDwithHyphen);
                // }}
                allowClear={false}
              />
            </EnhancedCol>

            <EnhancedCol lg={4} style={{ marginTop: 20 }}>
              <RequiredField> Due Date </RequiredField>
            </EnhancedCol>
            <EnhancedCol lg={7} style={{ marginTop: 20 }}>
              <EnhancedDatePicker
                name="dueDate"
                style={{ width: '100%' }}
                onChange={onDueDateChange}
                value={moment(pmcField.dueDate, dateFormat.YYYYMMDDwithHyphen)}
                format="YYYY-MM-DD"
                disabledDate={(current) => {
                  const customDate = moment().format(dateFormat.YYYYMMDDwithHyphen);
                  return current && current < moment(customDate, dateFormat.YYYYMMDDwithHyphen);
                }}
                allowClear={false}
              />
            </EnhancedCol>
            <EnhancedCol lg={2} />
            <EnhancedCol lg={4} style={{ marginTop: 20 }}>
              <RequiredField> End Date </RequiredField>
            </EnhancedCol>
            <EnhancedCol lg={7} style={{ marginTop: 20 }}>
              <EnhancedDatePicker
                name="endDate"
                style={{ width: '100%' }}
                onChange={onEndDateChange}
                value={moment(pmcField.endDate, dateFormat.YYYYMMDDwithHyphen)}
                format="YYYY-MM-DD"
                disabledDate={(current) => {
                  const customDate = moment().format(dateFormat.YYYYMMDDwithHyphen);
                  return current && current < moment(customDate, dateFormat.YYYYMMDDwithHyphen);
                }}
                allowClear={false}
              />
            </EnhancedCol>
            {/* </EnhancedCol> */}
          </EnhancedForm>
        </EnhancedRow>
      </EnhancedCard>
    </EnhancedModal>
  );
};
export default AddPMCycleModal;
