import { getDashboard } from 'sources/DashboardSource';
import {
  GET_DASHBOARD_INIT,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILURE,
} from 'constants/DashboardTypes';

// normal actions
export const getDashboardInit = () => ({
  type: GET_DASHBOARD_INIT,
});

export const getDashboardSuccess = (data) => ({
  type: GET_DASHBOARD_SUCCESS,
  data,
});

export const getDashboardFailure = () => ({
  type: GET_DASHBOARD_FAILURE,
});

// Async Action
export const getDashboardActions = () => getDashboard();
