import {
  addGoal,
  getGoal,
  removeGoal,
  getGoalDetails,
  updateGoal,
  submitMyGoalForApproval,
} from 'sources/GoalsSource';

import {
  ADD_GOAL_INIT,
  ADD_GOAL_SUCCESS,
  ADD_GOAL_FAILURE,
  GET_GOAL_INIT,
  GET_GOAL_SUCCESS,
  GET_GOAL_FAILURE,
  DELETE_GOAL_INIT,
  DELETE_GOAL_SUCCESS,
  DELETE_GOAL_FAILURE,
  GET_GOAL_DETAILS_INIT,
  GET_GOAL_DETAILS_SUCCESS,
  GET_GOAL_DETAILS_FAILURE,
  UPDATE_GOAL_INIT,
  UPDATE_GOAL_SUCCESS,
  UPDATE_GOAL_FAILURE,
  SUBMIT_MY_GOAL_FOR_APPROVAL_INIT,
  SUBMIT_MY_GOAL_FOR_APPROVAL_SUCCESS,
  SUBMIT_MY_GOAL_FOR_APPROVAL_FAILURE,
} from 'constants/GoalTypes';

export const addGoalInit = () => ({
  type: ADD_GOAL_INIT,
});

export const addGoalSuccess = (data) => ({
  type: ADD_GOAL_SUCCESS,
  data,
});

export const addGoallFailure = () => ({
  type: ADD_GOAL_FAILURE,
});

export const getGoalInit = () => ({
  type: GET_GOAL_INIT,
});

export const getGoalSuccess = (data) => ({
  type: GET_GOAL_SUCCESS,
  data,
});

export const getGoalFailure = (data) => ({
  type: GET_GOAL_FAILURE,
  data,
});

export const deleteGoalInit = () => ({
  type: DELETE_GOAL_INIT,
});

export const deleteGoalSuccess = (data) => ({
  type: DELETE_GOAL_SUCCESS,
  data,
});

export const deleteGoalFailure = () => ({
  type: DELETE_GOAL_FAILURE,
});

export const getGoalDetailsInit = () => ({
  type: GET_GOAL_DETAILS_INIT,
});

export const getGoalDetailsSuccess = (data) => ({
  type: GET_GOAL_DETAILS_SUCCESS,
  data,
});

export const getGoalDetailsFailure = (data) => ({
  type: GET_GOAL_DETAILS_FAILURE,
  data,
});

export const updateGoalInit = () => ({
  type: UPDATE_GOAL_INIT,
});

export const updateGoalSuccess = (data) => ({
  type: UPDATE_GOAL_SUCCESS,
  data,
});

export const updateGoallFailure = () => ({
  type: UPDATE_GOAL_FAILURE,
});

export const submitMyGoalForApprovalInit = () => ({
  type: SUBMIT_MY_GOAL_FOR_APPROVAL_INIT,
});

export const submitMyGoalForApprovalSuccess = (data) => ({
  type: SUBMIT_MY_GOAL_FOR_APPROVAL_SUCCESS,
  data,
});

export const submitMyGoalForApprovalFailure = () => ({
  type: SUBMIT_MY_GOAL_FOR_APPROVAL_FAILURE,
});

export const addGoalActions = (requestObject) => (dispatch) => {
  dispatch(addGoal(requestObject));
};

export const getGoalActions = (requestObject) => (dispatch) => {
  dispatch(getGoal(requestObject));
};

export const removeGoalActions = (requestObject) => (dispatch) => {
  dispatch(removeGoal(requestObject));
};

export const getGoalDetailsActions = (requestObject) => (dispatch) => {
  dispatch(getGoalDetails(requestObject));
};

export const updateGoalActions = (requestObject) => (dispatch) => {
  dispatch(updateGoal(requestObject));
};

export const submitMyGoalForApprovalActions = (requestObject) => (dispatch) => {
  dispatch(submitMyGoalForApproval(requestObject));
};
