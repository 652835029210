import { connect } from 'react-redux';

import Report from 'components/Report';

import { getReportAction, getReportWithFormatAction } from 'store/actions/ReportActions';
import { getAllLocationActions } from 'store/actions/LocationActions';

function mapStateToProps(state) {
  const { reportReducer, locationsReducer } = state;
  return {
    report: reportReducer.report,
    summary: reportReducer.summary,
    employees: reportReducer.employees,
    managers: reportReducer.managers,
    locations: locationsReducer.locations,
    departments: reportReducer.departments,
    isLoading: reportReducer.isLoading,
    isCSVLoading: reportReducer.isCSVLoading,
    csvReport: reportReducer.csvReport,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getReport: (startDate, endDate) => dispatch(getReportAction(startDate, endDate)),
    getAllLocations: () => dispatch(getAllLocationActions()),
    getCSVReport: (startDate, endDate) => dispatch(getReportWithFormatAction(startDate, endDate)),
  };
}

const ReportContainer = connect(mapStateToProps, mapDispatchToProps)(Report);

export default ReportContainer;
