import {
  MY_LEAVES_GET,
  MY_LEAVES_GET_SUCCESS,
  MY_LEAVES_GET_FAIL,
  REQUEST_LEAVE,
  REQUEST_LEAVE_SUCCESS,
  REQUEST_LEAVE_FAIL,
  REQUEST_EDIT_LEAVE,
  REQUEST_EDIT_LEAVE_SUCCESS,
  GET_LEAVES_DETAILS_INIT,
  GET_LEAVES_DETAILS_SUCCESS,
  GET_LEAVES_DETAILS_FAIL,
  CANCEL_APPROVED_LEAVE_INIT,
  CANCEL_APPROVED_LEAVE_SUCCESS,
  CANCEL_APPROVED_LEAVE_FAIL,
  GET_TOTAL_LEAVES_COUNT_INIT,
  GET_TOTAL_LEAVES_COUNT_SUCCESS,
  GET_TOTAL_LEAVES_COUNT_FAILURE,
} from 'constants/MyLeavesTypes';
import {
  requestMyLeavesSource,
  createRequestLeaveSource,
  editLeaveSource,
  requestLeavesDetailsSource,
  cancelApprovedLeaveSource,
  getTotalLeavesCountSource,
} from 'sources/LeavesSource';

// get myLeaves
const requestMyLeaves = () => {
  const action = {
    type: MY_LEAVES_GET,
  };
  return action;
};

const myLeavesSuccess = (data) => {
  const action = {
    type: MY_LEAVES_GET_SUCCESS,
    data,
  };
  return action;
};

const myLeavesFail = () => {
  const action = {
    type: MY_LEAVES_GET_FAIL,
  };
  return action;
};

export const createLeave = () => ({
  type: REQUEST_LEAVE,
});

export const requestLeaveSuccess = (requestObject) => ({
  type: REQUEST_LEAVE_SUCCESS,
  leave: requestObject.leave,
});

export const requestLeaveFail = () => ({
  type: REQUEST_LEAVE_FAIL,
});

export const getMyLeaves = (employeeId, payload) => (dispatch) => {
  dispatch(requestMyLeaves());
  return requestMyLeavesSource(employeeId, payload)
    .then((response) => dispatch(myLeavesSuccess(response)))
    .catch((error) => dispatch(myLeavesFail(error)));
};

export const editLeave = () => ({
  type: REQUEST_EDIT_LEAVE,
});

export const editLeaveSuccess = (requestObject) => ({
  type: REQUEST_EDIT_LEAVE_SUCCESS,
  leave: requestObject.leave,
});
export const requestEditLeave = (requestObject) => (dispatch) => {
  dispatch(editLeaveSource(requestObject));
  dispatch(editLeave());
};

export const requestLeave = (requestObject) => (dispatch) => {
  dispatch(createRequestLeaveSource(requestObject));
  dispatch(createLeave());
};

// get leaves details
const leavesDetailsInit = () => {
  const action = {
    type: GET_LEAVES_DETAILS_INIT,
  };
  return action;
};

const leavesDetailsSuccess = (data) => {
  const action = {
    type: GET_LEAVES_DETAILS_SUCCESS,
    data,
  };
  return action;
};

const leavesDetailsFail = () => {
  const action = {
    type: GET_LEAVES_DETAILS_FAIL,
  };
  return action;
};

// get leaves details
const cancelApprovedLeaveInit = () => {
  const action = {
    type: CANCEL_APPROVED_LEAVE_INIT,
  };
  return action;
};

const cancelApprovedLeaveSuccess = (data) => {
  const action = {
    type: CANCEL_APPROVED_LEAVE_SUCCESS,
    data,
  };
  return action;
};

const cancelApprovedLeaveFail = () => {
  const action = {
    type: CANCEL_APPROVED_LEAVE_FAIL,
  };
  return action;
};

export const getLeavesDetails = (employeeId) => (dispatch) => {
  dispatch(leavesDetailsInit());
  return requestLeavesDetailsSource(employeeId)
    .then((response) => dispatch(leavesDetailsSuccess(response)))
    .catch((error) => dispatch(leavesDetailsFail(error)));
};

export const cancelApprovedLeave = (payload) => (dispatch) => {
  dispatch(cancelApprovedLeaveInit());
  return cancelApprovedLeaveSource(payload)
    .then((response) => {
      dispatch(cancelApprovedLeaveSuccess(response));
      dispatch(getLeavesDetails(payload.employeeId));
    })
    .catch((error) => dispatch(cancelApprovedLeaveFail(error)));
};

export const getTotalLeavesCountInit = () => ({
  type: GET_TOTAL_LEAVES_COUNT_INIT,
});

export const getTotalLeavesCountSuccess = (data) => ({
  type: GET_TOTAL_LEAVES_COUNT_SUCCESS,
  data,
});

export const getTotalLeavesCountFailure = (data) => ({
  type: GET_TOTAL_LEAVES_COUNT_FAILURE,
  data,
});

export const getTotalLeavesCountAction = (params) => (dispatch) => {
  dispatch(getTotalLeavesCountInit());
  return getTotalLeavesCountSource(params)
    .then((response) => dispatch(getTotalLeavesCountSuccess(response)))
    .catch((error) => dispatch(getTotalLeavesCountFailure(error)));
};
