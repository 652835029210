import {
  EnhancedCol,
  EnhancedRow,
  EnhancedButton,
  EnhancedCard,
  EnhancedDivider,
  EnhancedModal,
  EnhancedInput,
} from 'components/shared/antd';
import React from 'react';
import moment from 'moment';
import { getLeaveStatusColor } from 'utils/colorUtils';
import { leaveStatus } from 'configs/leaveConstants';

const { TextArea } = EnhancedInput;
const DATE_FORMAT = 'DD/MM/YYYY';

const LeaveDashboardModal = ({
  visible,
  leave: { leaveRequestData = {} },
  onChangeComment,
  onUpdateLeave,
  hideModal,
  comment,
}) => {
  const { employeeName, currentStatus, requesterNote, type } = leaveRequestData;

  let { startDate, endDate } = leaveRequestData;

  startDate = moment.utc(startDate);
  endDate = moment.utc(endDate);

  return (
    <EnhancedModal
      width="60%"
      visible={visible}
      footer={null}
      bodyStyle={{
        backgroundColor: '#f0f2f5',
        padding: '3vw',
      }}
      onCancel={hideModal}
    >
      <EnhancedRow gutter={8}>
        <EnhancedCol lg={24}>
          <EnhancedCard className="leave-form">
            <div className="leave-form-header">{`${employeeName} Leave Request`}</div>
            <EnhancedDivider />
            <EnhancedRow>
              <EnhancedCol lg={4} md={4} sm={24} xs={24} style={{ fontWeight: 450 }}>
                Dates:{' '}
              </EnhancedCol>
              <EnhancedCol lg={20} md={20} sm={24} xs={24}>
                {`${startDate.format(DATE_FORMAT)} - ${endDate.format(DATE_FORMAT)}`}
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="marginTop15">
              <EnhancedCol lg={4} md={4} sm={24} xs={24} style={{ fontWeight: 450 }}>
                Type:{' '}
              </EnhancedCol>
              <EnhancedCol lg={20} md={20} sm={24} xs={24} style={{ textTransform: 'capitalize' }}>
                {`${type} Leave`}
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="marginTop15">
              <EnhancedCol lg={4} md={4} sm={24} xs={24} style={{ fontWeight: 450 }}>
                Status:{' '}
              </EnhancedCol>
              <EnhancedCol
                lg={20}
                md={20}
                sm={24}
                xs={24}
                style={{
                  color: getLeaveStatusColor(currentStatus),
                  textTransform: 'capitalize',
                }}
              >
                {currentStatus}
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="marginTop15">
              <EnhancedCol lg={4} md={4} sm={24} xs={24} style={{ fontWeight: 450 }}>
                Reason:{' '}
              </EnhancedCol>
              <EnhancedCol lg={20} md={20} sm={24} xs={24}>
                {requesterNote}
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="marginTop15">
              <EnhancedCol lg={4}>Note:</EnhancedCol>
              <EnhancedCol lg={20}>
                <TextArea
                  style={{ resize: 'none' }}
                  placeholder="Mention your comments here"
                  rows="4"
                  // disabled={!canApprove}
                  value={comment}
                  onChange={onChangeComment}
                />
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="marginTop15">
              <EnhancedCol
                offset={13}
                lg={5}
                md={5}
                sm={18}
                xs={18}
                className="leave-modal-buttons"
              >
                <EnhancedButton
                  size="large"
                  style={{ width: '100%' }}
                  onClick={() => onUpdateLeave(leaveStatus.DECLINED)}
                >
                  Decline
                </EnhancedButton>
              </EnhancedCol>
              <EnhancedCol offset={1} lg={5} sm={18} xs={18} className="leave-modal-buttons">
                <EnhancedButton
                  type="primary"
                  size="large"
                  style={{ width: '100%' }}
                  onClick={() => onUpdateLeave(leaveStatus.APPROVED)}
                  // loading={this.props.requestLoader}
                  // disabled={!requestEnable}
                >
                  Approve
                </EnhancedButton>
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCard>
        </EnhancedCol>
      </EnhancedRow>
    </EnhancedModal>
  );
};

export default LeaveDashboardModal;
