import { EnhancedCard, EnhancedIcon, EnhancedDivider } from 'components/shared/antd';
import React, { Component } from 'react';
import EmployeeProjectModalContainer from 'containers/EmployeeProjectModalContainer';
import { Link } from 'react-router-dom';
import { dateFormat } from 'configs/constants';
import PROJECT_ROUTES from 'constants/UrlConstants';
import { parse } from 'utils/stringUtils';
import { NOT_AVAILABLE, current, completed } from 'configs/additionalConstants';
import moment from 'moment';
import { hasPermission } from 'utils/AccessUtils';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import Permissions from '../../constants/AccessPermissions';
import ProjectDeleteEmployeeModal from '../project/ProjectDeleteEmployeeModal';

const projectState = {
  EXISTING: 0,
  PREVIOUS: 1,
};

class EmployeesProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectData: {},
      visible: false,
      readOnly: this.props.readOnly,
    };
  }

  onModalClose = () => {
    this.setState({
      visible: false,
    });
  };
  getColumnsInfo = () => {
    const columns = [
      {
        title: 'Project Name',
        dataIndex: 'projectName',
        key: 'projectName',
        filterConfig: {
          type: 'enhanced_filter',
          key: 'projectName',
          optionsConfig: {
            values: this.props.employeesProject, // TODO: unique project names
            valueVar: 'projectName',
            labelVar: 'projectName',
          },
        },
        render: this.renderProject,
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Allocation',
        dataIndex: 'allocation',
        render: this.renderAllocation,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        filterConfig: {
          type: 'enhanced_filter',
          key: 'type',
          optionsConfig: {
            values: [
              {
                value: 'Billed',
              },
              {
                value: 'Backup',
              },
              {
                value: 'Internal',
              },
              {
                value: 'Additional',
              },
            ],
            valueVar: 'value',
            labelVar: 'value',
          },
        },
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        render: (value) =>
          value !== undefined
            ? moment.utc(value).format(dateFormat.DDMMMYYYYwithSpace)
            : NOT_AVAILABLE,
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
        key: 'endDate',
        render: (value) =>
          value !== undefined ? moment(value).format(dateFormat.DDMMMYYYYwithSpace) : NOT_AVAILABLE,
      },
      {
        title: 'Extended End Date',
        dataIndex: 'extendedEndDate',
        key: 'extendedEndDate',
        render: (value) =>
          value !== undefined ? moment(value).format(dateFormat.DDMMMYYYYwithSpace) : NOT_AVAILABLE,
      },
      {
        title: 'Probability',
        dataIndex: 'probability',
        key: 'probability',
        render: this.renderProbability,
      },
    ];
    if (
      (parseInt(this.props.type, 10) === projectState.EXISTING || !this.props.type) &&
      !this.state.readOnly
    ) {
      const {
        Project: {
          Allocation: { Delete, Update },
        },
      } = Permissions;
      if (hasPermission(this.props.userPermissions, [Delete, Update])) {
        columns.push({ title: 'Actions', render: this.removeEmployeeProject });
      }
    }
    return columns;
  };

  deleteEmployeeFromProject = (projectData) => {
    this.setState({
      deleteResourceModalVisible: true,
      projectData,
    });
  };

  handleDeleteWithNoteClose = (close) => {
    this.setState({ deleteResourceModalVisible: close });
  };

  removeEmployeeProject = (projectData) => {
    let { type } = this.props;
    type = parseInt(type, 10) || 0;
    return (
      <div style={{ display: 'flex' }}>
        {type === projectState.EXISTING ? (
          <span>
            <EnhancedIcon
              type="delete"
              onClick={() => this.deleteEmployeeFromProject(projectData)}
              onKeyDown={() => this.deleteEmployeeFromProject(projectData)}
            />
            <EnhancedDivider type="vertical" />
            <EnhancedIcon
              type="edit"
              style={{ cursor: 'pointer' }}
              onClick={() => this.handleEdit(projectData)}
            />
          </span>
        ) : null}
      </div>
    );
  };

  handleEdit = (projectData) => {
    this.setState({
      visible: true,
      projectData,
    });
  };
  renderAllocation = (name, data) => <div>{data.allocation}%</div>;

  renderProbability = (name, data) => (
    <div>{data.probability ? `${data.probability}%` : 'N/A'}</div>
  );

  renderProject = (name) => {
    const { project } = this.props.employeesProject.filter((proj) => proj.projectName === name)[0];
    const status = project.isActive ? current : completed;
    const link = parse(PROJECT_ROUTES.PROJECTS_DETAIL_ROUTE, { status, id: project.id });

    return (
      <Link to={`${link}?status=${status}`}>
        <b style={{ color: '#5A5A5A' }}>{name}</b>
      </Link>
    );
  };

  render() {
    const { isProjectLoading, status } = this.props;
    let columns = this.getColumnsInfo();
    let data = this.props.employeesProject;
    let { type } = this.props;
    type = parseInt(type, 10) || 0;
    if (type === projectState.EXISTING) {
      data = data.filter((obj) => obj.status);
    } else if (type === projectState.PREVIOUS) {
      data = data.filter((obj) => !obj.status);
    }
    if (!status) {
      columns = columns.filter((col) => col.title !== 'Actions');
    }

    return (
      <div>
        <EmployeeProjectModalContainer
          style={{ float: 'right' }}
          selectedProject={this.state.projectData}
          visible={this.state.visible}
          onModalClose={this.onModalClose}
        />
        <EnhancedCard style={{ marginTop: '20px' }}>
          <EnhancedClientSideTable
            rowKey={(record) => record.employeeProjectId}
            style={{ textTransform: 'capitalize' }}
            loading={isProjectLoading}
            rowClassName={(record) =>
              record.comment === '' || record.comment === null ? 'hide-icon' : ''
            }
            expandedRowRender={(record) => {
              let comment = '';
              if (record.comment !== '') {
                comment = JSON.parse(record.comment);
              } else {
                return null;
              }
              const commentSection =
                record !== null || record !== ''
                  ? comment.map((commentRecord) => (
                      <span>
                        <p style={{ margin: 0, marginLeft: '5%' }}>
                          {commentRecord.comment}
                          <b style={{ float: 'right', marginRight: '5%' }}>
                            {moment
                              .utc(commentRecord.commentDate)
                              .format(dateFormat.DDMMMYYYYwithSpace)}
                          </b>
                        </p>
                        <br />
                      </span>
                    ))
                  : '';
              return commentSection;
            }}
            columns={columns}
            data={data}
          />
        </EnhancedCard>
        <ProjectDeleteEmployeeModal
          assignorId={this.props.userId}
          visible={this.state.deleteResourceModalVisible}
          projectData={this.state.projectData}
          onModalClose={this.handleDeleteWithNoteClose}
          onDeallocateEmployee={this.props.deallocateEmployee}
        />
      </div>
    );
  }
}

export default EmployeesProject;
