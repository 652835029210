import React from 'react';
import {
  LeftOutlined,
  AppstoreOutlined,
  UserDeleteOutlined,
  CheckCircleOutlined,
  RightOutlined,
  EditOutlined,
  CloseOutlined,
  HomeOutlined,
  CalendarOutlined,
  LayoutOutlined,
  TeamOutlined,
  WalletOutlined,
  LogoutOutlined,
  CodeOutlined,
  PlusCircleOutlined,
  UserOutlined,
  BellOutlined,
  DownOutlined,
  CarryOutOutlined,
  FilterOutlined,
  InfoCircleOutlined,
  SearchOutlined,
  EyeOutlined,
  CameraOutlined,
  UploadOutlined,
  ManOutlined,
  CompassOutlined,
  WomanOutlined,
  HeartOutlined,
  DeleteOutlined,
  ArrowLeftOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DownloadOutlined,
  FileOutlined,
  LaptopOutlined,
  MedicineBoxOutlined,
  TableOutlined,
  ClockCircleOutlined,
  FileExcelOutlined,
  ApiOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

const EnhancedIcon = (props) => {
  const getIconComponent = () => {
    const { type, ...rest } = props;
    switch (type) {
      case 'left':
        return <LeftOutlined {...rest} />;
      case 'right':
        return <RightOutlined {...rest} />;
      case 'edit':
        return <EditOutlined {...rest} />;
      case 'close':
        return <CloseOutlined {...rest} />;
      case 'home':
        return <HomeOutlined {...rest} />;
      case 'calendar':
        return <CalendarOutlined {...rest} />;
      case 'layout':
        return <LayoutOutlined {...rest} />;
      case 'team':
        return <TeamOutlined {...rest} />;
      case 'wallet':
        return <WalletOutlined {...rest} />;
      case 'logout':
        return <LogoutOutlined {...rest} />;
      case 'code':
        return <CodeOutlined {...rest} />;
      case 'plus':
        return <PlusCircleOutlined {...rest} />;
      case 'user':
        return <UserOutlined {...rest} />;
      case 'bell':
        return <BellOutlined {...rest} />;
      case 'down':
        return <DownOutlined {...rest} />;
      case 'carryOut':
        return <CarryOutOutlined {...rest} />;
      case 'filter':
        return <FilterOutlined {...rest} />;
      case 'check-circle':
        return <CheckCircleOutlined {...rest} />;
      case 'info-circle':
        return <InfoCircleOutlined {...rest} />;
      case 'search':
        return <SearchOutlined {...rest} />;
      case 'eye':
        return <EyeOutlined {...rest} />;
      case 'camera':
        return <CameraOutlined {...rest} />;
      case 'upload':
        return <UploadOutlined {...rest} />;
      case 'man':
        return <ManOutlined {...rest} />;
      case 'compass':
        return <CompassOutlined {...rest} />;
      case 'woman':
        return <WomanOutlined {...rest} />;
      case 'heart-o':
        return <HeartOutlined {...rest} />;
      case 'delete':
        return <DeleteOutlined {...rest} />;
      case 'arrow-left':
        return <ArrowLeftOutlined {...rest} />;
      case 'menu-fold':
        return <MenuFoldOutlined {...rest} />;
      case 'menu-unfold':
        return <MenuUnfoldOutlined {...rest} />;
      case 'appstore-o':
        return <AppstoreOutlined {...rest} />;
      case 'user-delete':
        return <UserDeleteOutlined {...rest} />;
      case 'download':
        return <DownloadOutlined {...rest} />;
      case 'file':
        return <FileOutlined {...rest} />;
      case 'medicine-box':
        return <MedicineBoxOutlined {...rest} />;
      case 'laptop':
        return <LaptopOutlined {...rest} />;
      case 'table':
        return <TableOutlined {...rest} />;
      case 'clock-circle-o':
        return <ClockCircleOutlined {...rest} />;
      case 'file-excel':
        return <FileExcelOutlined {...rest} />;
      case 'api':
        return <ApiOutlined {...rest} />;
      case 'retweet':
        return <RetweetOutlined {...rest} />;
      default:
        return <DeleteOutlined {...rest} />;
    }
  };

  return getIconComponent();
};

EnhancedIcon.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  theme: PropTypes.oneOf(['filled', 'outlined', 'twoTone']),
  twoToneColor: PropTypes.string,
  spin: PropTypes.bool,
  rotate: PropTypes.number,
};

EnhancedIcon.defaultProps = {
  className: '',
  style: {},
  onClick: () => {},
  theme: 'outlined',
  twoToneColor: '',
  spin: false,
  rotate: 0,
};

export default EnhancedIcon;
