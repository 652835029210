import moment from 'moment';
import XLSX from 'xlsx';
import { MOBILE, FUEL_ALLOWANCE, EMPLOYEES } from 'configs/employeeProfileConstants';
import { ROOT, EMPLOYEES_ALL } from 'configs/routesConstants';
import { skillLevel } from 'configs/constants';
import { getMappedDataForExport, writeToXLSX, dateRenderer } from 'utils/ExcelFilesUtils';

export default function convertRolesToObjectForm(currentRoles, previousRoles, allRolesObj) {
  const newRolesKey = [];
  const deletedRolesKey = [];
  const newRoles = currentRoles.filter((role) => !previousRoles.includes(role));
  allRolesObj.forEach((roles) => {
    if (newRoles.includes(roles.role)) {
      newRolesKey.push(roles.id);
    }
  });

  const deletedRoles = previousRoles.filter((role) => !currentRoles.includes(role));
  allRolesObj.forEach((roles) => {
    if (deletedRoles.includes(roles.role)) {
      deletedRolesKey.push(roles.id);
    }
  });

  const updatedRolesAdd = newRolesKey.map((x) => ({
    projectRoleId: x,
    status: 1,
  }));
  const updatedRolesDelete = deletedRolesKey.map((x) => ({
    projectRoleId: x,
    status: 0,
  }));
  return [...updatedRolesAdd, ...updatedRolesDelete];
}

export const getFirstMobileNumber = (contacts) => {
  if (contacts.length) {
    for (let index = 0; index < contacts.length; index += 1) {
      if (contacts[index].type === MOBILE) {
        // Return first mobile number found
        return contacts[index].number;
      }
    }
  }
  return null;
};

export const formatTenure = (tenure) =>
  tenure
    ? `${tenure.years || 0} years, ${tenure.months || 0} months, ${tenure.days || 0} days`
    : '';

export const getFuelAllowance = (benefits) => {
  for (let index = 0; index < benefits.length; index += 1) {
    if (benefits[index].name === FUEL_ALLOWANCE) {
      // Return first fuel allowance amount found
      return benefits[index].amount;
    }
  }
  return '';
};

export const getAllSkills = (skills) => {
  const employeeSkills = [];
  for (let skillIndex = 0; skillIndex < skills.length; skillIndex += 1) {
    employeeSkills.push(skills[skillIndex].skill.name);
  }
  return employeeSkills;
};

export const filterSkills = (skills, expertiseLevel) => {
  const filteredSkills = [];
  for (let skillIndex = 0; skillIndex < skills.length; skillIndex += 1) {
    if (skills[skillIndex].expertise === expertiseLevel) {
      filteredSkills.push(skills[skillIndex].skill.name);
    }
  }
  return filteredSkills;
};

export const redirectToAllEmployees = () => {
  window.location = `${ROOT}${EMPLOYEES}${EMPLOYEES_ALL}`;
};

export const redirectToCurrentEmployee = (employeeId) => {
  window.location = `${ROOT}${EMPLOYEES}/${employeeId}`;
};

export const mapEmployeePrimarySkills = (employeeSkills) =>
  employeeSkills.filter((res) => res.expertise === skillLevel.EXPERT);

export const mapEmployeeSecondarySkills = (employeeSkills) =>
  employeeSkills.filter((res) => res.expertise !== skillLevel.EXPERT);

export const getActiveEmployees = (employees) => employees.filter((employee) => employee.isActive);

export const getActiveEmployeesWithOptions = (employees) =>
  employees.reduce((filteredEmployees, currentEmployee) => {
    if (currentEmployee.isActive) {
      const { id, name } = currentEmployee;
      filteredEmployees.push({ key: id, value: name });
    }
    return filteredEmployees;
  }, []);
export const disabledDateDOB = (current) => {
  // Can not select days after today and today
  return current && current >= moment().endOf('day');
};

export const disabledFutureMonths = (current) => {
  // Can only select uptil current month
  return current && current >= moment().add('1', 'months').startOf('month');
};

export const disabledPastDatesByGivenDate = (current, date = null) => {
  if (moment(date).isValid()) {
    return current && current < moment(date);
  }

  return true;
};

export const generateEmployeeFamilyInfoReport = (data) => {
  const headers = [
    { key: 'employee.id', label: 'Employee ID' },
    { key: 'employee.name', label: 'Employee Name' },
    { key: 'familyMember.name', label: 'Family Member Name' },
    { key: 'familyMember.dob', label: 'Family Member DOB', renderer: dateRenderer },
    { key: 'familyMember.gender', label: 'Family Member Gender' },
    { key: 'familyMember.relationship', label: 'relationship' },
  ];
  const sheet = XLSX.utils.aoa_to_sheet(
    getMappedDataForExport({
      data,
      sheetMeta: headers,
    }),
  );
  writeToXLSX(sheet, 'Employee Family Info');
};
