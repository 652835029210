import moment from 'moment';
import { isArray } from 'utils/arrayUtils';

import { convertResignationLocal, convertLeavingLocal } from 'normalizers/employeeNormalizers';

function displayRole(projectRole) {
  if (isArray(projectRole)) {
    const filteredArray = projectRole.filter((role) => !!role);
    const roleArray = filteredArray.map((role) => role.role);
    return roleArray.join(', ');
  }
  return projectRole.role;
}

export default function normalizeGetProjectResponse(res) {
  return res.map((data) => {
    const newData = data || {};
    const { employeeProject, leaveRequests } = newData;

    const currentDate = moment().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const totalExperience = employeeProject.experience.totalInWords;
    const experienceInYears = employeeProject.experience.totalInYears;
    return {
      name: employeeProject.employee.fullName,
      role: displayRole(newData.projectRole),
      allocation: employeeProject.allocation || 0,
      billed: employeeProject.billed || 0,
      comment: employeeProject.notes || '',
      type: employeeProject.type || '',
      startDate: employeeProject.startDate ? employeeProject.startDate : '',
      endDate: employeeProject.endDate ? employeeProject.endDate : '',
      extendedEndDate: employeeProject.extendedEndDate ? employeeProject.extendedEndDate : '',
      probability: employeeProject.probability || 0,
      leavingDate: convertLeavingLocal(employeeProject.leavingDate),
      resignationDate: convertResignationLocal(employeeProject.resignationDate),
      isActive: employeeProject.isActive,
      employeeId: employeeProject.employeeId,
      taleoId: employeeProject.taleoId,
      projectId: employeeProject.projectId,
      location: employeeProject.employee ? employeeProject.employee.location : '',
      employeeProjectId: data.employeeProjectId,
      existing: employeeProject.status,
      leavesDetails: moment(leaveRequests.fromDate) >= currentDate ? leaveRequests : '',
      department: employeeProject.department,
      competency: employeeProject.competency,
      totalExperience,
      experienceInYears,
    };
  });
}
