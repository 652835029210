import moment from 'moment';
import XLSX from 'xlsx';
import { writeToXLSX as writeToXLSXUtil, getMappedDataForExport } from 'utils/ExcelFilesUtils';
import { allGoalsMeta, allGoalsKeys, reporteeGoalsMeta } from '../configs/goalConstants';

const getMappedGoalsData = (args) => {
  const { data, sheetMeta, sheetKeys } = args;

  const [keys, headers] = [[], []];
  sheetMeta.forEach((meta) => {
    keys.push(meta.key);
    headers.push(meta.label);
  });
  const rows = data.map((entry) =>
    keys.map((key) => {
      if (!sheetKeys.includes(key)) return entry[key];
      return entry[key];
    }),
  );
  return [headers, ...rows];
};

export function writeToXLSX(sheet, fileName) {
  const book = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(book, sheet, 'Summary');
  const currentDate = moment(new Date()).format('YYYY-MM-DD');
  XLSX.writeFile(book, `${fileName} - ${currentDate}.xlsx`);
}

export const generateAllGoalsReport = (data) => {
  const sheet = XLSX.utils.aoa_to_sheet(
    getMappedGoalsData({
      data,
      sheetMeta: allGoalsMeta,
      sheetKeys: allGoalsKeys,
    }),
  );
  writeToXLSX(sheet, 'All Goals');
};

export const generateReporteeGoalsReport = (data, fileName) => {
  const sheet = XLSX.utils.aoa_to_sheet(
    getMappedDataForExport({
      data,
      sheetMeta: reporteeGoalsMeta,
    }),
  );
  writeToXLSXUtil(sheet, `${fileName}`);
};
