/* eslint-disable arrow-body-style */
import React, { Component } from 'react';
// Utils
import { getRemainingDaysFromBirthDay } from 'utils/sourceUtils';

// CSS
import './VendiansBirthdays.css';

// HOC
import UserPlaceholder from './UserPlaceholder';

function normalizeData(employees) {
  return employees
    .filter((employee) => {
      if (employee.dateOfBirth !== null) {
        const daysTogo = getRemainingDaysFromBirthDay(employee.dateOfBirth);
        if (daysTogo >= 0) return true;
      }
      return false;
    })
    .map((birthdayEmployee) => {
      const daysTogo = getRemainingDaysFromBirthDay(birthdayEmployee.dateOfBirth);
      return {
        id: birthdayEmployee.id,
        title: birthdayEmployee.name,
        isWish: birthdayEmployee.isWish,
        location: birthdayEmployee.location,
        description: (
          <p className="vb-description-text margin-top set-font-size">
            {' '}
            {daysTogo === 0
              ? `Today | ${birthdayEmployee.location}`
              : `${daysTogo} days remaining | ${birthdayEmployee.location}`}{' '}
          </p>
        ),
        disable: daysTogo !== 0,
        daysTogo,
        wishList: birthdayEmployee.wishList,
        imageUrl: birthdayEmployee.imageUrl,
      };
    })
    .sort((a, b) => a.daysTogo - b.daysTogo);
}

function VendiansBirthdays(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        data: normalizeData(props.employees),
        authUser: props.auth,
      };
    }

    componentWillReceiveProps(nextProps) {
      if (this.props.employees !== nextProps.employees) {
        this.setState({ data: normalizeData(nextProps.employees) });
      }
    }

    handleChange = (value) => {
      const data = normalizeData(this.props.employees);
      if (value === 'All') {
        this.setState({
          data,
        });
      } else {
        const locationFilter = data.filter((employee) => employee.location === value);
        this.setState({
          data: locationFilter,
        });
      }
    };

    render() {
      return (
        <WrappedComponent
          title="Vendians' Birthdays"
          filterOptions
          data={this.state.data}
          authData={this.state.authUser}
          wishEmployeeBirthday={this.props.wishEmployeeBirthday}
          handleChange={this.handleChange}
        />
      );
    }
  };
}

export default VendiansBirthdays(UserPlaceholder);
