import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedModal,
  EnhancedInput,
  EnhancedForm,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import RequiredField from 'components/RequiredField';

const { TextArea } = EnhancedInput;
const AddGoalModal = (props) => {
  const {
    isAdd,
    params,
    isEdit,
    isView,
    details,
    handleOk,
    isLoading,
    handleCancel,
    isModalVisible,
    addGoalActions,
    editableHandler,
    updateGoalActions,
  } = props;

  const [goalsField, setGoalsField] = useState({
    title: '',
    measurement: '',
    description: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setGoalsField({
      ...goalsField,
      [name]: value,
    });
  };

  const createNewGoal = () => {
    addGoalActions({
      payload: goalsField,
      parameters: params,
    });
    handleOk();
    setGoalsField({
      title: '',
      measurement: '',
      description: '',
    });
    editableHandler(false);
  };

  const editGoal = () => {
    updateGoalActions({
      id: details.id,
      userId: details.userId,
      payload: goalsField,
      params,
    });
    handleOk();
    setGoalsField({
      title: '',
      measurement: '',
      description: '',
    });
    editableHandler(false);
  };

  const closeModalHandler = () => {
    handleCancel();
    setGoalsField({
      title: '',
      measurement: '',
      description: '',
    });
    editableHandler(false);
  };

  useEffect(() => {
    if (isEdit || isView) {
      setGoalsField({
        title: details.title,
        measurement: details.measurement,
        description: details.description,
      });
    }
  }, [isLoading]);

  const modalTitle = () => {
    let titleText = '';
    if (isEdit) titleText = 'Edit Goal';
    else if (isView) titleText = 'View Goal';
    else if (isAdd) titleText = 'Add Goal';

    return titleText;
  };

  return (
    <EnhancedModal
      title={modalTitle()}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={closeModalHandler}
      className="add-goals-modal"
      width="1041px"
      footer={[
        <EnhancedButton
          key="submit"
          type="primary"
          onClick={isEdit ? editGoal : createNewGoal}
          disabled={!goalsField.title || isView}
        >
          {'Submit'}
        </EnhancedButton>,
      ]}
    >
      <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
        loading={isLoading}
        bordered={false}
        className="details-card-wrapper"
        style={{
          marginTop: 10,
        }}
      >
        <EnhancedRow>
          <EnhancedForm layout="inline" className="goals-form">
            {/* <EnhancedCol span={24} className="form-col"> */}
            <EnhancedCol lg={4}>
              <RequiredField> Goal Title </RequiredField>
            </EnhancedCol>
            <EnhancedCol lg={20}>
              <EnhancedInput
                readOnly={isView}
                name="title"
                value={goalsField.title}
                onChange={handleChange}
              />
            </EnhancedCol>
            <EnhancedCol lg={4} style={{ marginTop: 20 }}>
              <label htmlFor="Description"> Description </label>
            </EnhancedCol>
            <EnhancedCol lg={20} style={{ marginTop: 20 }}>
              <TextArea
                readOnly={isView}
                name="description"
                value={goalsField.description}
                onChange={handleChange}
                rows={4}
              />
            </EnhancedCol>
            <EnhancedCol lg={4} style={{ marginTop: 20 }}>
              <label htmlFor="Measurements"> Measurements </label>
            </EnhancedCol>
            <EnhancedCol lg={20} style={{ marginTop: 20 }}>
              <TextArea
                name="measurement"
                readOnly={isView}
                value={goalsField.measurement}
                onChange={handleChange}
                rows={4}
              />
            </EnhancedCol>
            {/* </EnhancedCol> */}
          </EnhancedForm>
        </EnhancedRow>
      </EnhancedCard>
    </EnhancedModal>
  );
};

export default AddGoalModal;
