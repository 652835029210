import { getEmployeeWorkLog } from 'sources/HistorySource';

import { GET_HISTORY_INIT, GET_HISTORY_SUCCESS, GET_HISTORY_FAILURE } from 'constants/HistoryTypes';

export const getHistoryInit = () => ({
  type: GET_HISTORY_INIT,
});

export const getHistorySuccess = (data) => ({
  type: GET_HISTORY_SUCCESS,
  data,
});

export const getHistoryFailure = (data) => ({
  type: GET_HISTORY_FAILURE,
  data,
});

export const getHistoryAction = (employeeId) => getEmployeeWorkLog(employeeId);
