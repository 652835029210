import React from 'react';
import { dateFormat } from 'configs/constants';
import {
  EnhancedCol,
  EnhancedButton,
  EnhancedRow,
  EnhancedModal,
  EnhancedDatePicker,
} from 'components/shared/antd';

class ProjectAddEditEmployeeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: props.visible,
      projectData: {},
      selectedDate: null,
      dateString: null,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.isActivated) {
      this.props.closeActivationModal(nextProps.isActivated);
    }
    if (this.state.visible !== nextProps.visible) {
      this.setState({
        visible: nextProps.visible,
        projectData: nextProps.projectData,
      });
    }
  }
  onCancel = () => {
    this.setState({
      visible: false,
    });
    this.props.closeActivationModal();
  };
  onDateChange = (date, dateString) => {
    this.setState({
      selectedDate: date || null,
      dateString,
    });
  };
  onSubmit = () => {
    const { id } = this.state.projectData;
    const req = {
      projectId: id,
      employeeId: this.props.user.id,
      endDate: this.state.dateString || null,
    };
    this.props.activateProjectAction(req);
  };
  render() {
    return (
      <EnhancedModal
        title="Do you want to activate this project?"
        visible={this.state.visible}
        onCancel={this.onCancel}
        footer={[
          <EnhancedButton key="back" onClick={this.onCancel}>
            Return
          </EnhancedButton>,
          <EnhancedButton
            key="submit"
            loading={this.props.isLoading}
            type="primary"
            onClick={this.onSubmit}
          >
            Activate
          </EnhancedButton>,
        ]}
      >
        <EnhancedRow>
          <EnhancedCol lg={8}>End Date (Optional):</EnhancedCol>
          <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
            <EnhancedDatePicker
              className="width-100"
              onChange={this.onDateChange}
              allowClear
              value={this.state.selectedDate}
              format={dateFormat.DDMMMYYYYwithSpace}
              // disabledDate={this.disabledStartDate}
              disabled={this.props.isEdit}
            />
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedModal>
    );
  }
}
export default ProjectAddEditEmployeeModal;
