import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import { normalizeLeaveRequests, normalizeLeaveCalendar } from 'normalizers/leaveNormalizers';
import { teamLeavesUrls, teamLeavesAsHRUrls } from 'configs/constants';
import { getResponseError } from 'utils/sourceUtils';
import {
  getPrimaryTeamLeavesInit,
  getPrimaryTeamLeavesSuccess,
  getPrimaryTeamLeavesFailure,
  getSecondaryTeamLeavesInit,
  getSecondaryTeamLeavesSuccess,
  getSecondaryTeamLeavesFailure,
  updateTeamLeaveInit,
  updateTeamLeaveSuccess,
  updateTeamLeaveFailure,
  updateTeamLeaveComplete,
  getLeaveCalendarInit,
  getLeaveCalendarSuccess,
  getLeaveCalendarFail,
} from 'store/actions/LeaveActions';
import { leaveCalendarUrls } from '../../configs/constants';

export const getPrimaryTeamLeaves = (attributeId, params) => {
  const url = teamLeavesUrls.GET.replace('{attributeId}', attributeId);
  return (dispatch) => {
    dispatch(getPrimaryTeamLeavesInit());
    return axios
      .get(url, {
        params,
      })
      .then((response) => {
        if (response.status === 200) {
          const {
            data: { content, pageInfo },
          } = response.data;
          const leaves = normalizeLeaveRequests(content);
          dispatch(getPrimaryTeamLeavesSuccess({ leaves, pageInfo }));
        } else {
          EnhancedNotification.error({ message: response.data.message });
          dispatch(getPrimaryTeamLeavesFailure());
        }
      })
      .catch((error) => {
        EnhancedNotification.error({ message: getResponseError(error) });
        dispatch(getPrimaryTeamLeavesFailure());
      });
  };
};

export const getSecondaryTeamLeaves = (attributeId, params) => {
  const url = teamLeavesUrls.GET.replace('{attributeId}', attributeId);
  return (dispatch) => {
    dispatch(getSecondaryTeamLeavesInit());
    return axios
      .get(url, {
        params,
      })
      .then((response) => {
        if (response.status === 200) {
          const {
            data: { content, pageInfo },
          } = response.data;
          const leaves = normalizeLeaveRequests(content);
          dispatch(getSecondaryTeamLeavesSuccess({ leaves, pageInfo }));
        } else {
          EnhancedNotification.error({ message: response.data.message });
          dispatch(getSecondaryTeamLeavesFailure());
        }
      })
      .catch((error) => {
        EnhancedNotification.error({ message: getResponseError(error) });
        dispatch(getSecondaryTeamLeavesFailure());
      });
  };
};

export const updateTeamLeave = (payload) => {
  const url = teamLeavesUrls.POST;
  return (dispatch) => {
    dispatch(updateTeamLeaveInit());
    return axios
      .post(url, payload)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          EnhancedNotification.success({ message: response.data.message });
          dispatch(updateTeamLeaveSuccess());
        } else {
          EnhancedNotification.error({ message: response.data.message });
          dispatch(updateTeamLeaveFailure());
        }
      })
      .catch((error) => {
        EnhancedNotification.error({ message: getResponseError(error) });
        dispatch(updateTeamLeaveFailure());
      });
  };
};

export const updateTeamLeaveAsHR = (leaveId, params) => {
  const url = teamLeavesAsHRUrls.PUT.replace('{leaveId}', leaveId);
  return (dispatch) => {
    dispatch(updateTeamLeaveInit());
    return axios
      .put(url, params)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          EnhancedNotification.success({ message: response.data.message });
          dispatch(updateTeamLeaveSuccess());
          dispatch(updateTeamLeaveComplete());
        } else {
          EnhancedNotification.error({ message: response.data.message });
          dispatch(updateTeamLeaveFailure());
        }
      })
      .catch((error) => {
        EnhancedNotification.error({ message: getResponseError(error) });
        dispatch(updateTeamLeaveFailure());
      });
  };
};

export const getLeaveCalendarSource = () => {
  const url = leaveCalendarUrls.GET;
  return (dispatch) => {
    dispatch(getLeaveCalendarInit());
    return axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          const { data } = response.data;
          const leaves = normalizeLeaveCalendar(data);
          dispatch(getLeaveCalendarSuccess(leaves));
        } else {
          EnhancedNotification.error({ message: response.data.message });
          dispatch(getLeaveCalendarFail());
        }
      })
      .catch((error) => {
        EnhancedNotification.error({ message: getResponseError(error) });
        dispatch(getLeaveCalendarFail());
      });
  };
};
