import React, { Component } from 'react';
import EmployeeOrgTreeHeader from './EmployeeOrgTreeHeader';
import EmployeeOrgTreeCard from './EmployeeOrgTreeCard';
import EmployeeDirectorySpinner from './EmployeeDirectorySpinner';

class EmployeeOrgTree extends Component {
  constructor(props) {
    super(props);
    this.defaultPageNo = 1;
    this.delayInSeconds = 500;
    this.state = {
      value: '',
      employees: null,
      filteredData: null,
    };
  }

  componentDidMount() {
    this.props.getEmployeOrgTreeAction(this.props.userId);
    this.props.getEmployeeSearchAction({
      name: this.state.value,
      page: this.defaultPageNo,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.employeeDirectory !== nextProps.employeeDirectory) {
      const filteredData = nextProps.employeeDirectory;
      this.setState({
        filteredData,
      });
    }

    if (this.props.employeeOrgTree !== nextProps.employeeOrgTree) {
      if (nextProps.employeeOrgTree === 403) {
        this.props.getEmployeOrgTreeAction(this.props.userId);
      }
      this.setState({ employees: nextProps.employeeOrgTree });
    }
  }

  onDirectReporteesCount = (employeeId) => {
    this.props.getEmployeOrgTreeAction(employeeId);
  };

  onAutoCompleteSearch = (value) => {
    if (value.trim().length > 2 || value.trim().length === 0) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.props.getEmployeeSearchAction({
          name: value,
          page: this.defaultPageNo,
        });
      }, this.delayInSeconds);
    }
  };

  onAutoCompleteSelect = (employeeId) => {
    this.props.getEmployeeSearchAction({
      name: this.state.value,
      page: this.defaultPageNo,
    });
    this.props.getEmployeOrgTreeAction(employeeId);
  };

  renderData = (employees) => {
    const data = (
      <div className="tree">
        <ul>
          <li>
            {employees.manager && (
              <EmployeeOrgTreeCard
                reportee={employees.manager}
                onDirectReporteesCount={() => this.onDirectReporteesCount(employees.manager.id)}
              />
            )}
            <ul>
              <li>
                <EmployeeOrgTreeCard reportee={employees} />
                <ul>
                  {employees.directReportees &&
                    employees.directReportees.map((reportee) => (
                      <li key={reportee.id}>
                        <EmployeeOrgTreeCard
                          reportee={reportee}
                          onDirectReporteesCount={() => this.onDirectReporteesCount(reportee.id)}
                          isDirectReportee
                        />
                      </li>
                    ))}
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
    return data;
  };

  render() {
    const { employees, filteredData } = this.state;
    const { isLoading } = this.props;
    return (
      <div style={{ backgroundColor: '#fff', paddingBottom: '20px' }}>
        <EmployeeOrgTreeHeader
          name="Organization Chart"
          onAutoCompleteSelect={this.onAutoCompleteSelect}
          onAutoCompleteSearch={this.onAutoCompleteSearch}
          filter={filteredData && filteredData}
          isLoading={isLoading}
        />
        {isLoading ? <EmployeeDirectorySpinner /> : employees && this.renderData(employees)}
      </div>
    );
  }
}

export default EmployeeOrgTree;
