import {
  EnhancedCol,
  EnhancedRow,
  EnhancedCard,
  EnhancedDivider,
  EnhancedSelect,
  EnhancedRadio,
} from 'components/shared/antd';
import React, { Component } from 'react';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';
import { leaveStatus } from 'configs/leaveConstants';
import HistoryListItem from 'components/leaves/HistoryListItem';
import { Desktop, Mobile } from 'components/Responsive';
import { getLeaveStatusColor } from 'utils/colorUtils';
import { paginationOptions } from '../../utils/getPagination';

const { Column } = EnhancedServerSideTable;
const { Option } = EnhancedSelect;
const RadioButton = EnhancedRadio.Button;
const { page, size } = paginationOptions;

class LeavesHistoryList extends Component {
  onFilterRadioLeaves = ({ target }) => {
    const { value } = target;
    const params = { page, size };
    const {
      user: { id },
    } = this.props;
    if (value !== leaveStatus.ALL) params.leaveStatus = value;

    this.props.getMyLeaves(id, params);
    this.props.setStatus(target.value);
  };

  onFilterDropdownLeaves = (target) => {
    this.props.setStatus(target);
  };

  expandedRowRender = (record) => {
    const { approvers } = record;

    return (
      approvers &&
      approvers.map((approver, index) => (
        <React.Fragment>
          <EnhancedRow>
            <EnhancedCol lg={5} md={5} sm={24} xs={24}>
              {approver.roles.map((role) => (
                <div>
                  <b>{role.name}</b>
                </div>
              ))}
            </EnhancedCol>
            <EnhancedCol lg={5} md={5} sm={24} xs={24}>
              <b>Status</b>
            </EnhancedCol>
            <EnhancedCol lg={5} md={5} sm={24} xs={24}>
              <b>Comments</b>
            </EnhancedCol>
          </EnhancedRow>
          <EnhancedRow>
            <EnhancedCol lg={5} md={5} sm={24} xs={24}>
              {approver.name}
            </EnhancedCol>
            <EnhancedCol
              lg={5}
              md={5}
              sm={24}
              xs={24}
              style={{
                color: getLeaveStatusColor(approver.status),
                textTransform: 'capitalize',
              }}
            >
              <b>{approver.status}</b>
            </EnhancedCol>
            <EnhancedCol lg={5} md={5} sm={24} xs={24}>
              {approver.comments}
            </EnhancedCol>
          </EnhancedRow>
          {index < approvers.length - 1 && <EnhancedDivider />}
        </React.Fragment>
      ))
    );
  };

  handlePageChange = (pageOptions) => {
    const { current } = pageOptions;
    const { filteredText } = this.props;

    const params = { page: current - 1, size };
    if (filteredText !== leaveStatus.ALL) params.leaveStatus = filteredText;
    this.props.getMyLeaves(this.props.user.id, params);
  };

  renderListItems = (leaveRequests = []) =>
    leaveRequests.map((leaveRequest) => (
      <div key={leaveRequest.id}>
        <HistoryListItem
          leaveRequest={leaveRequest}
          onCancel={this.props.onCancelClicked}
          onEdit={this.props.onEditClicked}
          workingHolidays={this.props.workingHolidays}
        />
        <EnhancedDivider />
      </div>
    ));

  render() {
    const { leaveRequests, loading, leavesStatuses, pageInfo, filteredText } = this.props;
    return (
      <EnhancedCard bordered={false} className="history-list marginTop15" loading={loading}>
        <div className="left">
          <h2 className="heading" style={{ paddingBottom: '10px' }}>
            My Leaves History
          </h2>
        </div>
        <Desktop>
          <div className="right">
            {leavesStatuses.length > 0 && (
              <EnhancedRadio.Group
                style={{ textTransform: 'capitalize' }}
                value={filteredText}
                onChange={this.onFilterRadioLeaves}
              >
                <EnhancedRadio.Button value={leaveStatus.ALL}>
                  {leaveStatus.ALL}
                </EnhancedRadio.Button>
                {leavesStatuses.map(
                  (status) =>
                    status.group === 'LeaveRequest' && (
                      <RadioButton
                        style={{ textTransform: 'capitalize' }}
                        value={status.id}
                        key={status.id}
                      >
                        {status.systemKey.replace('-', ' ')}
                      </RadioButton>
                    ),
                )}
              </EnhancedRadio.Group>
            )}
          </div>
        </Desktop>
        <Mobile>
          <div>
            {leavesStatuses.length > 0 && (
              <EnhancedSelect
                showSearch
                style={{ width: '100%' }}
                placeholder="Select Leave Status"
                onChange={this.onFilterDropdownLeaves}
                value={filteredText}
              >
                {
                  <Option style={{ textTransform: 'capitalize' }} value={leaveStatus.ALL}>
                    {leaveStatus.ALL}
                  </Option>
                }
                {leavesStatuses.map(
                  (status) =>
                    status.group === 'LeaveRequest' && (
                      <RadioButton
                        style={{ textTransform: 'capitalize' }}
                        value={status.id}
                        key={status.id}
                      >
                        {status.systemKey.replace('-', ' ')}
                      </RadioButton>
                    ),
                )}
              </EnhancedSelect>
            )}
          </div>
        </Mobile>
        <div className="clear-fix" />
        <div className="team-leaves-list-table">
          <EnhancedServerSideTable
            rowKey={(record) => record.id}
            showHeader={false}
            data={leaveRequests}
            onChange={this.handlePageChange}
            showResetFiltersButton={false}
            paginationInfo={{
              totalItems: pageInfo.totalItems,
              pageSize: pageInfo.size,
              pageNumber: pageInfo.page + 1,
            }}
            expandedRowRender={(record) => this.expandedRowRender(record)}
          >
            <Column
              render={(text, record) => (
                <HistoryListItem
                  leaveRequest={record}
                  onCancel={this.props.onCancelClicked}
                  onEdit={this.props.onEditClicked}
                  workingHolidays={this.props.workingHolidays}
                />
              )}
            />
          </EnhancedServerSideTable>
        </div>
      </EnhancedCard>
    );
  }
}

export default LeavesHistoryList;
