import { EnhancedButton, EnhancedNotification } from 'components/shared/antd';
/* eslint-disable no-unused-expressions */
import React from 'react';
import { Upload } from 'antd';

function UploadSheet(props) {
  const { files, disabled, type = 'primary', maxCount = 1, onChange } = props;

  const fileProps = {
    name: 'questions',
    maxCount,
    onRemove: (file) => {
      const index = files.indexOf(file);
      const newFileList = files.slice();
      newFileList.splice(index, 1);
      onChange && onChange(newFileList);
    },
    beforeUpload: (file) => {
      const acceptedExt = new RegExp('(.*?).(xlsx|sheet)$');
      if (!acceptedExt.test(file.type)) {
        EnhancedNotification.error({ message: `${file.name} is not an excel sheet` });
        return false;
      }
      if (maxCount === files.length) {
        const newFiles = files.slice();
        newFiles.pop();
        onChange && onChange([...newFiles, file]);
        return false;
      }
      onChange && onChange([...files, file]);
      return false;
    },
    fileList: files,
  };

  return (
    <Upload {...fileProps}>
      <EnhancedButton icon="upload" type={type} disabled={disabled}>
        Select Sheet
      </EnhancedButton>
    </Upload>
  );
}

export default UploadSheet;
