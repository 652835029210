/* eslint-disable */

import {
  GET_INTERVIEWS_INIT,
  GET_INTERVIEWS_SUCCESS,
  GET_INTERVIEWS_FAIL,
  CREATE_INTERVIEW_INIT,
  CREATE_INTERVIEW_SUCCESS,
  CREATE_INTERVIEW_FAIL,
  UPDATE_INTERVIEW_INIT,
  UPDATE_INTERVIEW_SUCCESS,
  UPDATE_INTERVIEW_FAIL,
  GET_REPORTEES_TRACKS_INIT,
  GET_REPORTEES_TRACKS_SUCCESS,
  GET_REPORTEES_TRACKS_FAIL,
  GET_QUESTIONS_INIT,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_FAIL,
  CREATE_QUESTION_INIT,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_FAIL,
  DELETE_QUESTION_INIT,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAIL,
  UPDATE_QUESTION_INIT,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_FAIL,
  DELETE_INTERVIEW_INIT,
  DELETE_INTERVIEW_SUCCESS,
  DELETE_INTERVIEW_FAIL,
  GET_QUESTIONS_FREQUENCY_PER_TRACK_INIT,
  GET_QUESTIONS_FREQUENCY_PER_TRACK_SUCCESS,
  GET_QUESTIONS_FREQUENCY_PER_TRACK_FAIL,
  GET_QUESTIONS_FREQUENCY_PER_CLIENT_AND_TRACK_INIT,
  GET_QUESTIONS_FREQUENCY_PER_CLIENT_AND_TRACK_SUCCESS,
  GET_QUESTIONS_FREQUENCY_PER_CLIENT_AND_TRACK_FAIL,
} from '../../constants/interviewTypes';

const getInterviewsInit = () => ({
  type: GET_INTERVIEWS_INIT,
});

const getInterviewsSuccess = (payload) => ({
  type: GET_INTERVIEWS_SUCCESS,
  payload,
});

const getInterviewsFail = () => ({
  type: GET_INTERVIEWS_FAIL,
});

// create

const createInterviewInit = () => ({
  type: CREATE_INTERVIEW_INIT,
});

const createInterviewSuccess = (payload) => ({
  type: CREATE_INTERVIEW_SUCCESS,
  payload,
});

const createInterviewFail = () => ({
  type: CREATE_INTERVIEW_FAIL,
});

const updateInterviewInit = () => ({
  type: UPDATE_INTERVIEW_INIT,
});

const updateInterviewSuccess = (payload) => ({
  type: UPDATE_INTERVIEW_SUCCESS,
  payload,
});

const updateInterviewFail = () => ({
  type: UPDATE_INTERVIEW_FAIL,
});

const deleteInterviewInit = (payload) => ({
  type: DELETE_INTERVIEW_INIT,
  payload,
});

const deleteInterviewSuccess = (payload) => ({
  type: DELETE_INTERVIEW_SUCCESS,
  payload,
});

const deleteInterviewFail = () => ({
  type: DELETE_INTERVIEW_FAIL,
});

// track

const getReporteesTracksInit = () => ({
  type: GET_REPORTEES_TRACKS_INIT,
});

const getReporteesTracksSuccess = (payload) => ({
  type: GET_REPORTEES_TRACKS_SUCCESS,
  payload,
});

const getReporteesTracksFail = () => ({
  type: GET_REPORTEES_TRACKS_FAIL,
});

// questions

const getQuestionsInit = () => ({
  type: GET_QUESTIONS_INIT,
});
const getQuestionsSuccess = (payload) => ({
  type: GET_QUESTIONS_SUCCESS,
  payload,
});
const getQuestionsFail = () => ({
  type: GET_QUESTIONS_FAIL,
});
const createQuestionInit = () => ({
  type: CREATE_QUESTION_INIT,
});
const createQuestionSuccess = (payload) => ({
  type: CREATE_QUESTION_SUCCESS,
  payload,
});
const createQuestionFail = () => ({
  type: CREATE_QUESTION_FAIL,
});
const deleteQuestionInit = (payload) => ({
  type: DELETE_QUESTION_INIT,
  payload,
});
const deleteQuestionSuccess = (payload) => ({
  type: DELETE_QUESTION_SUCCESS,
  payload,
});
const deleteQuestionFail = () => ({
  type: DELETE_QUESTION_FAIL,
});
const updateQuestionInit = () => ({
  type: UPDATE_QUESTION_INIT,
});
const updateQuestionSuccess = (payload) => ({
  type: UPDATE_QUESTION_SUCCESS,
  payload,
});
const updateQuestionFail = () => ({
  type: UPDATE_QUESTION_FAIL,
});

// QUESTIONS FREQUENCY

const getQuestionsFrequencyPerTrackInit = () => ({
  type: GET_QUESTIONS_FREQUENCY_PER_TRACK_INIT,
});
const getQuestionsFrequencyPerTrackSuccess = (payload) => ({
  type: GET_QUESTIONS_FREQUENCY_PER_TRACK_SUCCESS,
  payload,
});
const getQuestionsFrequencyPerTrackFail = () => ({
  type: GET_QUESTIONS_FREQUENCY_PER_TRACK_FAIL,
});

const getQuestionsFrequencyPerClientAndTrackInit = () => ({
  type: GET_QUESTIONS_FREQUENCY_PER_CLIENT_AND_TRACK_INIT,
});
const getQuestionsFrequencyPerClientAndTrackSuccess = (payload) => ({
  type: GET_QUESTIONS_FREQUENCY_PER_CLIENT_AND_TRACK_SUCCESS,
  payload,
});
const getQuestionsFrequencyPerClientAndTrackFail = () => ({
  type: GET_QUESTIONS_FREQUENCY_PER_CLIENT_AND_TRACK_FAIL,
});

export {
  getInterviewsInit,
  getInterviewsSuccess,
  getInterviewsFail,
  createInterviewInit,
  createInterviewSuccess,
  createInterviewFail,
  updateInterviewInit,
  updateInterviewSuccess,
  updateInterviewFail,
  deleteInterviewInit,
  deleteInterviewSuccess,
  deleteInterviewFail,
  getReporteesTracksInit,
  getReporteesTracksSuccess,
  getReporteesTracksFail,
  createQuestionInit,
  createQuestionSuccess,
  createQuestionFail,
  updateQuestionInit,
  updateQuestionSuccess,
  updateQuestionFail,
  deleteQuestionInit,
  deleteQuestionSuccess,
  deleteQuestionFail,
  getQuestionsInit,
  getQuestionsSuccess,
  getQuestionsFail,
  getQuestionsFrequencyPerTrackInit,
  getQuestionsFrequencyPerTrackSuccess,
  getQuestionsFrequencyPerTrackFail,
  getQuestionsFrequencyPerClientAndTrackInit,
  getQuestionsFrequencyPerClientAndTrackSuccess,
  getQuestionsFrequencyPerClientAndTrackFail,
};
