import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedIcon,
  EnhancedSelect,
  EnhancedModal,
  EnhancedInput,
  EnhancedProgress,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Table } from 'antd';
// import { isEqual } from 'lodash';
import { goalStatusToClassMapping } from 'configs/constants';
import { GOAL_STATUS, DATE_FORMAT } from 'configs/employeeProfileConstants';
import ViewGoalModal from 'components/admin/Goals/AddGoalModal';
import EditGoalModal from 'components/employeeProfile/goalsReviews/addGoalModal';
import RejectGoalModal from 'components/employeeProfile/goalsReviews/RejectGoalModal';
import EnhancedFilter from 'components/EnhancedFIlter';
import DateRangeFilter from 'components/shared/tableColumnFilters/DateRangeFilter';

const { confirm } = EnhancedModal;
const { Option } = EnhancedSelect;

const getDueDateStartAndEnd = () => {
  const now = moment();
  const response = {};

  const month = now.month();
  const currentYear = now.year();

  if (month > 5) {
    // July to december
    response.start = moment([currentYear, 6, 1]); // 1st July of current year
    response.end = moment([currentYear, 11, 31]); // 31st December of current year
  } else {
    // January to June
    response.start = moment([currentYear, 0, 1]); // 1st January of current year
    response.end = moment([currentYear, 5, 30]); // 30th June of current year
  }

  return response;
};

const BulkGoalApproval = (props) => {
  const {
    isLoading,
    reporteesGoals,
    goals,
    goalDetails,
    updateGoal,
    getReporteesGoals,
    exportReporteesGoals,
    getGoalDetails,
    updateGoalStatus,
    removeGoal,
  } = props;
  const [reportees, setReportees] = useState('DIRECT');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [paginationState, setPaginationstate] = useState({
    pageNumber: 0,
    pageSize: 10,
  });
  const [empID, setEmpID] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [viewGoalModalVisible, setViewGoalModalVisible] = useState(false);
  const [editGoalModalVisible, setEditGoalModalVisible] = useState(false);
  const [rejectGoalModalVisible, setRejectGoalModalVisible] = useState(false);

  const initialFiltersState = {
    isFilterVisible: false,
    filterColumn: '',
    searchText: '',
    oracleId: '',
    employeeName: '',
    managerName: '',
    departmentIds: '',
    competencyIds: '',
    status: [GOAL_STATUS.PENDING_APPROVAL],
    dueDate: getDueDateStartAndEnd(),
    filterInfo: {},
  };

  const [filterState, setFilterState] = useState(initialFiltersState);

  // eslint-disable-next-line
  const statusValues = Object.entries(GOAL_STATUS).map(([key, value]) => value);

  const canEdit = (status) =>
    status !== GOAL_STATUS.FINAL &&
    status !== GOAL_STATUS.DELETED &&
    status !== GOAL_STATUS.REJECTED;

  const canDelete = (status, isGoalPCRelated) =>
    status !== GOAL_STATUS.FINAL &&
    status !== GOAL_STATUS.DELETED &&
    status !== GOAL_STATUS.REJECTED &&
    !isGoalPCRelated;

  const canApprove = (status) => status === GOAL_STATUS.PENDING_APPROVAL;

  const canReject = (status, isGoalPCRelated) =>
    (status === GOAL_STATUS.PENDING_APPROVAL || status === GOAL_STATUS.APPROVED) &&
    !isGoalPCRelated;

  const canView = () => true;

  const onRowSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onRowSelectChange,
    getCheckboxProps: (record) => ({
      disabled: !canApprove(record.status),
    }),
  };

  const hasSelected = selectedRowKeys.length > 0;

  const viewGoalModal = () => {
    setViewGoalModalVisible(true);
  };

  const viewGoalModalHandleOk = () => {
    setViewGoalModalVisible(false);
  };

  const viewGoalModalHandleCancel = () => {
    setViewGoalModalVisible(false);
  };

  const editGoalModal = () => {
    setEditGoalModalVisible(true);
  };

  const editGoalModalHandleOk = () => {
    setEditGoalModalVisible(false);
  };

  const editGoalModalHandleCancel = () => {
    setEditGoalModalVisible(false);
  };

  const rejectGoalModal = () => {
    setRejectGoalModalVisible(true);
  };

  const rejectGoalModalHandleOk = () => {
    setRejectGoalModalVisible(false);
  };

  const rejectGoalModalHandleCancel = () => {
    setRejectGoalModalVisible(false);
  };

  const handleBulkAction = (action) => {
    const payload = {
      status: action,
      goalIds: selectedRowKeys,
      as: 'LM',
    };

    const parameters = {
      ...paginationState,
      status: action,
    };

    confirm({
      title: `Are you sure you want to ${
        action === GOAL_STATUS.APPROVED ? 'approve' : 'reject'
      } all selected goals?`,
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      async onOk() {
        await updateGoalStatus({
          payload,
          parameters,
          viewDetails: false,
        });
        setSelectedRowKeys([]);
      },
    });
  };

  const handleSingleApprove = (id) => {
    const payload = {
      status: GOAL_STATUS.APPROVED,
      goalIds: [id],
      as: 'LM',
    };

    const parameters = {
      ...paginationState,
      status: GOAL_STATUS.APPROVED,
    };

    confirm({
      title: 'Are you sure you want to approve the selected goal?',
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      onOk() {
        updateGoalStatus({
          payload,
          parameters,
          viewDetails: false,
        });
      },
    });
  };

  const handleGoalDelete = (goalId, empId) => {
    const payload = {
      empId,
      goalId,
    };

    const params = {
      ...paginationState,
      status: GOAL_STATUS.DELETED,
    };

    confirm({
      title: 'Are you sure you want to delete this goal?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        removeGoal({
          payload,
          params,
          viewDetails: false,
        });
      },
    });
  };

  const optionMenu = [
    {
      value: '1',
      label: 'View',
      canCallback: canView,
    },
    {
      value: '2',
      label: 'Edit',
      canCallback: (record) => canEdit(record.status),
    },
    {
      value: '3',
      label: 'Delete',
      canCallback: (record) => canDelete(record.status, record.isEvaluatedCurrently),
    },
    {
      value: '4',
      label: 'Approve',
      canCallback: (record) => canApprove(record.status),
    },
    {
      value: '5',
      label: 'Reject',
      canCallback: (record) => canReject(record.status, record.isEvaluatedCurrently),
    },
  ];

  const changeActionHandler = (key, id, _record) => {
    setEmpID(_record.userId);

    if (key === '1') {
      viewGoalModal();
      setIsEditMode(false);
      getGoalDetails({ id, empId: _record.userId });
    } else if (key === '2') {
      editGoalModal();
      setIsEditMode(true);
      getGoalDetails({ id, empId: _record.userId });
    } else if (key === '3') {
      handleGoalDelete(id, _record.userId);
    } else if (key === '4') {
      handleSingleApprove(id);
    } else if (key === '5') {
      rejectGoalModal();
      getGoalDetails({ id, empId: _record.userId });
    }
  };

  const setActionsHandler = (id, _record) => {
    return (
      <EnhancedSelect
        style={{ width: 120 }}
        className="action-selection-dropdown"
        placeholder="Options"
        value="Options"
        onChange={(key) => changeActionHandler(key, id, _record)}
      >
        {optionMenu.map(
          (item) => item.canCallback(_record) && <Option value={item.value}>{item.label}</Option>,
        )}
      </EnhancedSelect>
    );
  };

  const handleReporteesSelect = (value) => {
    setReportees(value);
  };

  const clearAllFilters = () => {
    setFilterState({
      ...initialFiltersState,
      status: [],
      dueDate: {},
    });
  };

  const handleTableChange = (pagination, filters) => {
    setPaginationstate({
      ...paginationState,
      pageNumber: pagination.current - 1,
      pageSize: pagination.pageSize,
    });
    setFilterState({
      ...filterState,
      status: filters.status,
    });
  };

  const handleFilterVisible = (visible, filterColumn) => {
    setFilterState({
      ...filterState,
      searchText: filterColumn !== filterState.filterColumn ? '' : filterState.searchText,
      isFilterVisible: visible,
      filterColumn,
    });
  };

  const handleFilterApply = (dataIndex, selection) => {
    setFilterState({
      ...filterState,
      filterInfo: {
        ...filterState.filterInfo,
        [dataIndex]: selection,
      },
      isFilterVisible: false,
      dueDate: dataIndex === 'dueDate' ? selection : filterState.dueDate,
      departmentIds: dataIndex === 'department' ? selection.toString() : filterState.departmentIds,
      competencyIds: dataIndex === 'competency' ? selection.toString() : filterState.competencyIds,
    });
  };

  const getEnhancedFilterOptions = (dataIndex, options) => {
    if (props[options] !== undefined) {
      const filters = props[options].map((item) => ({
        text: item.name,
        value: item.id,
      }));
      const filteredValue = filterState.filterInfo[dataIndex] || [];
      return {
        filters,
        filteredValue,
        filterDropdownVisible:
          filterState.isFilterVisible && filterState.filterColumn === dataIndex,
        onFilterDropdownVisibleChange: (visible) => handleFilterVisible(visible, dataIndex),
        filterDropdown: (filterProps) => (
          <EnhancedFilter
            filters={filters}
            filterDropdownVisible={
              filterState.isFilterVisible && filterState.filterColumn === dataIndex
            }
            filteredValue={filteredValue}
            {...filterProps}
            onApply={(selection) => handleFilterApply(dataIndex, selection)}
          />
        ),
      };
    }

    return false;
  };

  const getDateRangeFilterOptions = (dataIndex) => {
    const filteredValue = filterState.dueDate;
    return {
      filteredValue: filteredValue.start ? [filteredValue.start] : '',
      // filterIcon: (filtered) => (
      //   <Icon type="calendar" style={{ color: filtered ? '#1DAC8A' : undefined }} />
      // ),
      filterDropdownVisible: filterState.isFilterVisible && filterState.filterColumn === dataIndex,
      onFilterDropdownVisibleChange: (visible) => handleFilterVisible(visible, dataIndex),
      filterDropdown: (filterProps) => (
        <DateRangeFilter
          filterDropdownVisible={
            filterState.isFilterVisible && filterState.filterColumn === dataIndex
          }
          filteredValue={filteredValue}
          {...filterProps}
          onApply={(selection) => handleFilterApply(dataIndex, selection)}
        />
      ),
    };
  };

  const onSearchInputChange = (event) => {
    setFilterState({
      ...filterState,
      searchText: event.target.value,
    });
  };

  const onSearch = () => {
    if (filterState.filterColumn) {
      const { filterColumn, searchText } = filterState;

      setFilterState({
        ...filterState,
        [filterColumn]: searchText,
      });
    }
  };

  const filterSearch = (
    <EnhancedRow className="drop-down-filter">
      <EnhancedCol span={19}>
        <EnhancedInput
          placeholder="Search"
          value={
            filterState.searchText !== ''
              ? filterState.searchText
              : filterState[filterState.filterColumn]
          }
          onChange={onSearchInputChange}
          onPressEnter={onSearch}
        />
      </EnhancedCol>
      <EnhancedCol offset={1} span={4}>
        <EnhancedButton type="primary" shape="circle" icon="search" onClick={onSearch} />
      </EnhancedCol>
    </EnhancedRow>
  );

  const filterSearchIcon = (value) => (
    <EnhancedIcon
      type="search"
      style={value !== '' ? { color: '#1DAC8A' } : { color: '#bfbfbf' }}
    />
  );

  const columns = [
    {
      title: 'Employee ID',
      dataIndex: ['employee', 'taleoId'],
      key: 'oracleId',
      filterDropdownVisible: filterState.isFilterVisible && filterState.filterColumn === 'oracleId',
      filterIcon: filterSearchIcon(filterState.oracleId),
      onFilterDropdownVisibleChange: (visible) => handleFilterVisible(visible, 'oracleId'),
      filterDropdown: filterSearch,
    },
    {
      title: 'Employee Name',
      dataIndex: ['employee', 'name'],
      key: 'employeeName',
      filterDropdownVisible:
        filterState.isFilterVisible && filterState.filterColumn === 'employeeName',
      filterIcon: filterSearchIcon(filterState.employeeName),
      onFilterDropdownVisibleChange: (visible) => handleFilterVisible(visible, 'employeeName'),
      filterDropdown: filterSearch,
      width: 150,
    },
    {
      title: 'Manager Name',
      dataIndex: ['employee', 'managerName'],
      key: 'managerName',
      filterDropdownVisible:
        filterState.isFilterVisible && filterState.filterColumn === 'managerName',
      filterIcon: filterSearchIcon(filterState.managerName),
      onFilterDropdownVisibleChange: (visible) => handleFilterVisible(visible, 'managerName'),
      filterDropdown: filterSearch,
      width: 150,
    },
    {
      title: 'Competency',
      dataIndex: ['employee', 'competency'],
      key: 'competency',
      width: 150,
      ...getEnhancedFilterOptions('competency', 'competencies'),
    },
    {
      title: 'Department',
      dataIndex: ['employee', 'department'],
      key: 'department',
      width: 150,
      ...getEnhancedFilterOptions('department', 'departments'),
    },
    {
      title: 'Goal Title',
      dataIndex: 'title',
      width: 200,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) => (
        <span className={`status-box ${goalStatusToClassMapping[text]}`}>{text}</span>
      ),
      width: 150,
      filters: statusValues.map((value) => ({ text: value, value })),
      filteredValue: filterState.status,
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: '15%',
      render: (value) => (value ? moment.utc(value).format(DATE_FORMAT) : 'N/A'),
      ...getDateRangeFilterOptions('dueDate'),
    },
    {
      title: 'Weightage',
      dataIndex: 'weightage',
    },
    {
      title: 'Percent Complete',
      dataIndex: 'progress',
      width: 200,
      render: (progress) => (
        <EnhancedProgress
          className={`progress-bar ${progress > 10 && 'font-white'}`}
          percent={`${progress}`}
          strokeColor="#1DAC8A"
          strokeLinecap="square"
          type="line"
        />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render: (id, _record) => setActionsHandler(id, _record),
    },
  ];

  const { pageNumber, pageSize } = paginationState;
  const { oracleId, employeeName, managerName, departmentIds, competencyIds, status, dueDate } =
    filterState;

  const getPayloadForDueDate = () => {
    let payload = {};
    const dateFormat = 'YYYY-MM-DD';

    if (dueDate.start && dueDate.end) {
      payload = {
        start: dueDate.start.format(dateFormat),
        end: dueDate.end.format(dateFormat),
      };
    }

    return payload;
  };

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(goals, 'count')) {
      getReporteesGoals({
        status: status && status.length > 0 ? status.toString() : statusValues.toString(),
        page: pageNumber,
        size: pageSize,
        oracleId,
        employeeName,
        managerName,
        businessUnitIds: '',
        departmentIds,
        competencyIds,
        divisionIds: '',
        reportees,
        dueDate: getPayloadForDueDate(),
      });
    }
  }, [goals]);

  useEffect(() => {
    getReporteesGoals({
      status: status && status.length > 0 ? status.toString() : statusValues.toString(),
      page: pageNumber,
      size: pageSize,
      oracleId,
      employeeName,
      managerName,
      businessUnitIds: '',
      departmentIds,
      competencyIds,
      divisionIds: '',
      reportees,
      dueDate: getPayloadForDueDate(),
    });
  }, [
    pageNumber,
    pageSize,
    reportees,
    oracleId,
    employeeName,
    managerName,
    departmentIds,
    competencyIds,
    status,
    dueDate,
  ]);

  const handleExport = () => {
    const params = {
      status: status && status.length > 0 ? status.toString() : statusValues.toString(),
      oracleId,
      employeeName,
      managerName,
      departmentIds,
      competencyIds,
      reportees,
      page: 0,
      size: 1000000,
      dueDate: getPayloadForDueDate(),
    };
    exportReporteesGoals(params);
  };

  return (
    <React.Fragment>
      <EnhancedRow style={{ margin: '20px 0' }} type="flex" align="middle" justify="space-between">
        <div className="add-btn-wrapper">
          <EnhancedButton
            icon="filter"
            onClick={clearAllFilters}
            // disabled={isEqual(filterState, initialFiltersState)}
            style={{ marginRight: 10 }}
          >
            Clear all filters
          </EnhancedButton>
          <EnhancedButton icon="export" onClick={handleExport}>
            Export
          </EnhancedButton>
          <span>{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}</span>
        </div>
        <div className="add-btn-wrapper">
          <EnhancedButton
            style={{ marginRight: 10 }}
            onClick={() => handleBulkAction(GOAL_STATUS.APPROVED)}
            disabled={selectedRowKeys.length === 0}
          >
            <EnhancedIcon type="carry-out" />
            <span>Approve Goals</span>
          </EnhancedButton>
          {/* <EnhancedButton
            style={{ marginRight: 10 }}
            onClick={() => handleBulkAction(GOAL_STATUS.REJECTED)}
            disabled={selectedRowKeys.length === 0}
          >
            <Icon type="close-square" />
            <span>Reject Goals</span>
          </EnhancedButton> */}
          <span className="mr-10">Reporting Line:</span>
          <EnhancedSelect
            placeholder="Select Reportees"
            onChange={handleReporteesSelect}
            showArrow
            value={reportees}
            style={{ width: 158 }}
          >
            <Option key="ALL" value="ALL">
              All
            </Option>
            <Option key="DIRECT" value="DIRECT">
              Direct Reporting
            </Option>
            <Option key="INDIRECT" value="INDIRECT">
              Indirect Reporting
            </Option>
          </EnhancedSelect>
        </div>
      </EnhancedRow>
      <Table
        rowKey={(record) => record.id}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={reporteesGoals.rows}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
        pagination={{
          showSizeChanger: true,
          total: reporteesGoals.count,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} records`,
          current: paginationState.pageNumber + 1,
          pageSize: paginationState.pageSize,
          size: 'small',
          style: {
            float: 'right',
            textAlign: 'center',
          },
        }}
      />
      <ViewGoalModal
        isModalVisible={viewGoalModalVisible}
        handleOk={viewGoalModalHandleOk}
        handleCancel={viewGoalModalHandleCancel}
        updateGoalActions={updateGoal}
        params={{ ...paginationState, empId: empID }}
        isEdit={isEditMode}
        editableHandler={setIsEditMode}
        isLoading={isLoading}
        details={goalDetails}
      />
      <EditGoalModal
        isModalVisible={editGoalModalVisible}
        handleOk={editGoalModalHandleOk}
        handleCancel={editGoalModalHandleCancel}
        updateGoalActions={updateGoal}
        params={{ ...paginationState, empId: empID, status: goalDetails.status }}
        isEdit={isEditMode}
        editableHandler={setIsEditMode}
        isLoading={isLoading}
        details={goalDetails}
      />
      <RejectGoalModal
        isModalVisible={rejectGoalModalVisible}
        handleOk={rejectGoalModalHandleOk}
        handleCancel={rejectGoalModalHandleCancel}
        params={{ ...paginationState, status: GOAL_STATUS.REJECTED }}
        updateGoalStatus={updateGoalStatus}
        isLoading={isLoading}
        details={false}
        rejectedIds={[goalDetails.id]}
      />
    </React.Fragment>
  );
};

BulkGoalApproval.propTypes = {};

export default BulkGoalApproval;
