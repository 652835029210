export const GET_GOAL_CATEGORY_INIT = 'GET_GOAL_CATEGORY_INIT';
export const GET_GOAL_CATEGORY_SUCCESS = 'GET_GOAL_CATEGORY_SUCCESS';
export const GET_GOAL_CATEGORY_FAILURE = 'GET_GOAL_CATEGORY_FAILURE';

export const GET_GOAL_CATEGORY_DETAILS_INIT = 'GET_GOAL_CATEGORY_DETAILS_INIT';
export const GET_GOAL_CATEGORY_DETAILS_SUCCESS = 'GET_GOAL_CATEGORY_DETAILS_SUCCESS';
export const GET_GOAL_CATEGORY_DETAILS_FAILURE = 'GET_GOAL_CATEGORY_DETAILS_FAILURE';

export const ADD_GOAL_CATEGORY_INIT = 'ADD_GOAL_CATEGORY_INIT';
export const ADD_GOAL_CATEGORY_SUCCESS = 'ADD_GOAL_CATEGORY_SUCCESS';
export const ADD_GOAL_CATEGORY_FAILURE = 'ADD_GOAL_CATEGORY_FAILURE';

export const UPDATE_GOAL_CATEGORY_INIT = 'UPDATE_GOAL_CATEGORY_INIT';
export const UPDATE_GOAL_CATEGORY_SUCCESS = 'UPDATE_GOAL_CATEGORY_SUCCESS';
export const UPDATE_GOAL_CATEGORY_FAILURE = 'UPDATE_GOAL_CATEGORY_FAILURE';

export const DELETE_GOAL_CATEGORY_INIT = 'DELETE_GOAL_CATEGORY_INIT';
export const DELETE_GOAL_CATEGORY_SUCCESS = 'DELETE_GOAL_CATEGORY_SUCCESS';
export const DELETE_GOAL_CATEGORY_FAILURE = 'DELETE_GOAL_CATEGORY_FAILURE';
