import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { ROOT } from 'configs/routesConstants';
import AllEmployeesContainer from 'containers/AllEmployeesContainer';
import EmployeeProfileContainer from 'containers/EmployeeProfileContainer';
import ReporteeProfileContainer from 'containers/ReporteeProfileContainer';
import CreateEmployeeContainer from 'containers/CreateEmployeeContainer';
import EmployeePerformanceEvaluationFormContainer from 'containers/EmployeePerformanceEvaluationFormContainer';
import AuthorizedRoute from './AuthorizedRoute';
import {
  EMPLOYEES_PROFILE,
  EMPLOYEES_ALL,
  EMPLOYEES_CREATE,
  REPORTEE_PROFILE,
} from '../configs/routesConstants';

class EmployeeRoutes extends React.PureComponent {
  render() {
    const { match } = this.props;
    return (
      <React.Fragment>
        <Switch>
          <AuthorizedRoute
            exact
            path={`${match.path}${EMPLOYEES_ALL}`}
            component={AllEmployeesContainer}
          />
          <AuthorizedRoute
            exact
            path={`${match.path}${EMPLOYEES_CREATE}`}
            component={CreateEmployeeContainer}
          />
          <AuthorizedRoute
            exact
            path={`${match.path}${EMPLOYEES_PROFILE}`}
            component={EmployeeProfileContainer}
          />
          <Route
            exact
            path={`${match.path}${REPORTEE_PROFILE}`}
            component={ReporteeProfileContainer}
          />
          <AuthorizedRoute
            exact
            path={`${match.path}${EMPLOYEES_PROFILE}/:performanceCycleId`}
            component={EmployeePerformanceEvaluationFormContainer}
          />
          <Route
            exact
            path={`${match.path}${REPORTEE_PROFILE}/:performanceCycleId`}
            component={EmployeePerformanceEvaluationFormContainer}
          />
          <Redirect to={ROOT} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default EmployeeRoutes;
