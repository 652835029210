import { connect } from 'react-redux';
import {
  getPMCycleActions,
  addPMCycleActions,
  editPMCycleActions,
  getPMCycleDetailAction,
  deletePMCycleActions,
  closePMCycleActions,
} from '../store/actions/PerformanceMangementActions';
import PerformanceManagement from '../components/admin/performanceManagement';

const mapStateToProps = (state) => {
  const { performanceManagementReducer, auth } = state;
  return {
    list: performanceManagementReducer.pmCycles,
    isLoading: performanceManagementReducer.isLoading,
    pmCycleDetail: performanceManagementReducer.pmCycleDetail,
    userPermissions: auth.user && auth.user.permissions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPMCycleActions: (params) => dispatch(getPMCycleActions(params)),
  addPMCycleActions: (params) => dispatch(addPMCycleActions(params)),
  editPMCycleActions: (params) => dispatch(editPMCycleActions(params)),
  deletePMCycleActions: (params) => dispatch(deletePMCycleActions(params)),
  getPMCycleDetail: (params) => dispatch(getPMCycleDetailAction(params)),
  closePMCycle: (params) => dispatch(closePMCycleActions(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceManagement);
