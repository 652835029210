import XLSX from 'xlsx';
import moment from 'moment';
import { dateFormat } from 'configs/constants';

const PROJECT_EMPLOYEES_META = [
  {
    key: 'taleoId',
    label: 'Taleo ID',
  },
  {
    key: 'name',
    label: 'Employee',
  },
  {
    key: 'role',
    label: 'Role',
  },
  {
    key: 'allocation',
    label: 'Allocation',
  },
  {
    key: 'billed',
    label: 'Billing %',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'location',
    label: 'Location',
  },
  {
    key: 'totalExperience',
    label: 'Total Experience',
  },
  {
    key: 'department',
    label: 'Department',
  },
  {
    key: 'competency',
    label: 'Competency',
  },
  {
    key: 'startDate',
    label: 'Start Date',
  },
  {
    key: 'endDate',
    label: 'End Date',
  },
  {
    key: 'extendedEndDate',
    label: 'Extended End Date',
  },
  {
    key: 'probability',
    label: 'Probability',
  },
];

export const getMappedProjectEmployeesData = (data) => {
  const [keys, headers] = [[], []];
  PROJECT_EMPLOYEES_META.forEach((meta) => {
    keys.push(meta.key);
    headers.push(meta.label);
  });
  const rows = data.map((entry) =>
    keys.map((key) => {
      if (key === 'department') {
        return entry.department;
      }
      if (key === 'allocation') {
        return `${entry[key]}%`;
      }
      if (key === 'probability') {
        return `${entry[key]}%`;
      }
      if (key === 'billed') {
        return `${entry[key]}%`;
      }
      if ((key === 'startDate' || key === 'endDate' || key === 'extendedEndDate') && entry[key]) {
        return moment(entry[key]).format(dateFormat.DDMMMYYYYwithSpace);
      }
      return entry[key];
    }),
  );
  return [headers, ...rows];
};
/**
 *
 * @param {Array<object>} data Array of Objects containing headers and rows;
 * @param {String} sheetName Sheet name of which will be generating;
 */
export const generateSheet = (data, sheetName) => {
  const sheet = XLSX.utils.aoa_to_sheet(data);
  const book = XLSX.utils.book_new();
  const currentDate = moment().format(dateFormat.DDMMMYYYYwithSpace);
  XLSX.utils.book_append_sheet(book, sheet);
  XLSX.writeFile(book, `${sheetName} - ${currentDate}.xlsx`);
};
