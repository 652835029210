import { connect } from 'react-redux';

import ProjectRating from 'components/pc/ProjectRating';

import {
  getProjectRatingAction,
  addProjectRatingAction,
  getRatingDetailsAction,
  resetRatingDetails,
} from 'store/actions/PerformanceCycleActions';

import updateBreadCrumb from 'store/actions/BreadCrumbActions';

function mapStateToProps(state) {
  const { auth, performanceCycleReducer, projectReducer } = state;
  return {
    userId: auth.user && auth.user.id,
    readOnly: auth.user && auth.user.isReadOnly,
    isCRMC1: auth.user && auth.user.isCRMC1,
    isCRMC2: auth.user && auth.user.isCRMC2,
    isPM: auth.user && auth.user.isPM,
    isArch: auth.user && auth.user.isArch,
    projectRating: performanceCycleReducer.projectRating,
    isLoading: performanceCycleReducer.isLoading,
    ratingDetails: performanceCycleReducer.ratingDetails,
    projects: projectReducer.projects,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectRatingAction: (projectCycleId, projectId) =>
      dispatch(getProjectRatingAction(projectCycleId, projectId)),
    addProjectRatingAction: (payload) => dispatch(addProjectRatingAction(payload)),
    updateBreadCrumb: (key, oldVal, newVal) => dispatch(updateBreadCrumb(key, oldVal, newVal)),
    getRatingDetails: (payload) => dispatch(getRatingDetailsAction(payload)),
    resetRatingDetails: () => dispatch(resetRatingDetails()),
  };
}

const ProjectRatingContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectRating);

export default ProjectRatingContainer;
