import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedModal,
  EnhancedInput,
  EnhancedForm,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import RequiredField from 'components/RequiredField';
import { GOAL_STATUS } from 'configs/employeeProfileConstants';

const { TextArea } = EnhancedInput;
const RejectGoalModal = (props) => {
  const { isModalVisible, handleOk, handleCancel, params, updateGoalStatus, isLoading, details } =
    props;
  const [reason, setReason] = useState('');

  const editGoal = () => {
    updateGoalStatus({
      payload: {
        status: GOAL_STATUS.REJECTED,
        goalIds: [details.id],
        as: 'HR',
        reason,
      },
      params,
    });
    handleOk();
  };

  const closeModalHandler = () => {
    handleCancel();
  };

  useEffect(() => {
    //
  }, [isLoading]);

  return (
    <EnhancedModal
      title="Reject Goal"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={closeModalHandler}
      className="add-goals-modal"
      width="661px"
      footer={[
        <EnhancedButton
          disabled={reason.length === 0}
          key="submit"
          type="primary"
          onClick={editGoal}
        >
          Reject Goal
        </EnhancedButton>,
      ]}
    >
      <EnhancedCard
        loading={isLoading}
        bordered={false}
        className="details-card-wrapper"
        style={{
          marginTop: 10,
        }}
      >
        <EnhancedRow>
          <EnhancedForm layout="inline" className="goals-form w-full">
            <EnhancedCol span={6}>
              <RequiredField> Reason </RequiredField>
            </EnhancedCol>
            <EnhancedCol span={18}>
              <TextArea
                name="reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                rows={4}
                required
              />
            </EnhancedCol>
          </EnhancedForm>
        </EnhancedRow>
      </EnhancedCard>
    </EnhancedModal>
  );
};

export default RejectGoalModal;
