import { EnhancedSelect } from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { goalStatusToClassMapping } from 'configs/constants';
import { DATE_FORMAT } from 'configs/employeeProfileConstants';
import { roundOffRating } from 'utils/conversionUtils';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';
import ManagerRatingStatusLabel from '../../shared/performanceEvaluationForm/ManagerRatingStatusLabel';

const { Option } = EnhancedSelect;

const PerformanceReview = (props) => {
  const { getEmployeePerformanceReview, performanceReviews, isLoading, history } = props;
  const employeeId = props.match.params.id;
  const [params, setParams] = useState({
    empId: employeeId,
    pageNumber: 0,
    pageSize: 10,
  });

  useEffect(() => {
    getEmployeePerformanceReview(params);
  }, [params]);

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      pageNumber: pagination.current - 1,
      pageSize: pagination.pageSize,
    });
  };

  const changeActionHandler = (key, performanceCycleId) => {
    history.push(`${props.match.url}/${performanceCycleId}?type=${key}`);
  };

  const canSelfReview = (_record) => _record.self.rating;

  const optionMenu = [
    {
      value: 'self',
      label: 'Self Review',
      canCallback: canSelfReview,
    },
    {
      value: 'manager',
      label: 'Manager Review',
    },
  ];

  const setActionsHandler = (id, _record) => {
    return (
      <EnhancedSelect
        style={{
          width: 120,
        }}
        className="action-selection-dropdown"
        placeholder="Options"
        onChange={(key) => changeActionHandler(key, id)}
      >
        {optionMenu.map(
          (item) =>
            (!item.canCallback || item.canCallback(_record)) && (
              <Option value={item.value}>{item.label}</Option>
            ),
        )}
      </EnhancedSelect>
    );
  };

  const renderStatus = (status) => (
    <span className={`status-box ${goalStatusToClassMapping[status]}`}>{status}</span>
  );

  const columns = [
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      render: (dueDate) => moment(dueDate).format(DATE_FORMAT),
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Cycle Status',
      dataIndex: 'status',
      render: renderStatus,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      render: (startDate) => moment(startDate).format(DATE_FORMAT),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      render: (endDate) => moment(endDate).format(DATE_FORMAT),
    },
    {
      title: 'Manager Rating',
      dataIndex: ['manager', 'rating'],
      render: (rating) => roundOffRating(rating),
    },
    {
      title: 'Manager Rating Status',
      dataIndex: 'manager',
      render: ({ status, comment }) => (
        <ManagerRatingStatusLabel status={status} comment={comment} />
      ),
    },
    {
      title: 'Self Rating',
      dataIndex: ['self', 'rating'],
      render: (rating) => roundOffRating(rating),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render: (id, _record) => setActionsHandler(id, _record),
    },
  ];

  return (
    <div>
      <React.Fragment>
        <div>
          <span
            style={{
              marginLeft: 8,
            }}
          />
        </div>
        <EnhancedServerSideTable
          rowKey={(record) => record.id}
          loading={isLoading}
          columns={columns}
          data={performanceReviews.rows}
          onChange={handleTableChange}
          className="goal-table-container"
          paginationInfo={{
            totalItems: performanceReviews.count,
            pageSize: params.pageSize,
            pageNumber: params.pageNumber + 1,
          }}
          showResetFiltersButton={false}
        />
      </React.Fragment>
    </div>
  );
};

function mapStateToProps(state) {
  const { auth, employeeProfileReducer } = state;
  const { isLoading } = employeeProfileReducer;
  return {
    isLoading,
    userRole: auth.user,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceReview);
