import { EnhancedCard, EnhancedRow, EnhancedDivider, EnhancedModal } from 'components/shared/antd';
import React from 'react';
import moment from 'moment';
import { dateFormat } from 'configs/constants';
import './leaveDetailsModal.css';
import { EnhancedCol } from '../shared/antd';

class LeaveDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectDetailsModalVisible: props.visible,
      information: [
        {
          type: '',
          fromDate: '',
          toDate: '',
          total: '',
          status: '',
        },
      ],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.projectDetailsModalVisible !== nextProps.visible) {
      this.setState({
        projectDetailsModalVisible: nextProps.visible,
      });
    }
    const employeeLeaves = nextProps.leavesData.find((employee) => {
      const employeeId = employee.id || employee.employeeId;
      return employeeId === nextProps.employeeId;
    });

    if (employeeLeaves && employeeLeaves.leavesDetails) {
      const leaveRequests = employeeLeaves.leavesDetails.map((leaves) => {
        const fromDate = moment(leaves.startDate).format(dateFormat.DDMMYYYYwithForwardSlash);
        const toDate = moment(leaves.endDate).format(dateFormat.DDMMYYYYwithForwardSlash);
        return {
          type: leaves.leaveType,
          fromDate,
          toDate,
          total: leaves.duration,
          status: leaves.currentStatus,
        };
      });
      this.setState({
        information: leaveRequests,
      });
    }
  }

  getTitle = () => 'Leave Details';

  handleModalCancel = () => {
    this.setState({
      projectDetailsModalVisible: false,
    });
  };

  afterClose = () => {
    this.props.onModalClose(false);
  };

  render() {
    return (
      <EnhancedModal
        title={this.getTitle()}
        visible={this.state.projectDetailsModalVisible}
        onCancel={this.handleModalCancel}
        footer={null}
        afterClose={this.afterClose}
      >
        {this.state.information
          ? this.state.information.map((leaveData) => (
              <EnhancedCard
                key={leaveData.fromDate + leaveData.type}
                bordered
                style={{ marginTop: '10px' }}
              >
                <div>
                  <EnhancedRow style={{ float: 'left', marginRight: '20px' }}>
                    <EnhancedCol>
                      <small>Type</small>
                      <p>{leaveData.type ? leaveData.type : 'N/A'}</p>
                    </EnhancedCol>
                  </EnhancedRow>
                  <EnhancedRow style={{ float: 'left', marginRight: '20px' }}>
                    <EnhancedCol>
                      <small>From Date</small>
                      <p>{leaveData.fromDate ? leaveData.fromDate : 'N/A'}</p>
                    </EnhancedCol>
                  </EnhancedRow>
                  <EnhancedRow style={{ float: 'left', marginRight: '20px' }}>
                    <EnhancedCol>
                      <small>To Date</small>
                      <p>{leaveData.toDate ? leaveData.toDate : 'N/A'}</p>
                    </EnhancedCol>
                  </EnhancedRow>
                  <EnhancedRow style={{ float: 'left', marginRight: '20px' }}>
                    <EnhancedCol>
                      <small>Total</small>
                      <p>{leaveData.total ? leaveData.total : 'N/A'}</p>
                    </EnhancedCol>
                  </EnhancedRow>
                  <EnhancedRow>
                    <EnhancedCol>
                      <small>Status</small>
                      <p
                        className={
                          leaveData.status === 'Approved' ? 'leave-modal-approved' : 'leave-modal'
                        }
                      >
                        {leaveData.status ? leaveData.status : 'N/A'}
                      </p>
                    </EnhancedCol>
                  </EnhancedRow>
                  <EnhancedDivider style={{ margin: 0 }} />
                </div>
              </EnhancedCard>
            ))
          : ''}
      </EnhancedModal>
    );
  }
}

export default LeaveDetailsModal;
