import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSingleNewsActionSource } from 'store/actions/NewsActions';
// CSS
import './NewsGridView.css';
import NewsDetailPlaceholder from './NewsDetailPlaceholder';

function NewsDetailView(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        singleNews: props.singleNews,
        relatedNews: props.relatedNews,
        loader: false,
      };
    }

    componentWillMount() {
      this.setState({
        loader: true,
      });
      this.props.getSingleViewNews(this.props.match.params.id);
    }

    componentWillReceiveProps(nextProps) {
      if (this.props.match.params.id !== nextProps.match.params.id) {
        this.setState({
          loader: true,
        });
        this.props.getSingleViewNews(nextProps.match.params.id);
      }
      if (nextProps.singleNews !== this.props.singleNews) {
        const { singleNews } = nextProps;
        const { loader } = nextProps;
        this.setState({ singleNews, loader });
      }
    }

    render() {
      return (
        <WrappedComponent
          title="News"
          filterOptions={false}
          singleNews={this.state.singleNews}
          relatedNews={this.state.relatedNews}
          loader={this.state.loader}
        />
      );
    }
  };
}

function mapStateToProps(state) {
  const { newsReducer, loader } = state;
  return {
    singleNews: newsReducer.singleNews,
    relatedNews: newsReducer.relatedNews,
    loader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSingleViewNews: (newsId) => dispatch(getSingleNewsActionSource(newsId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetailView(NewsDetailPlaceholder));
