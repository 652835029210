import Axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import {
  getLatestHomeNewsAction,
  getGridNewsAction,
  getSingleNewsAction,
  getRelatedNewsAction,
  // getAPIFAILURE,
  appendNewsGridData,
} from 'store/actions/NewsActions';
import {
  normalizeHomePageNewsData,
  normalizeNewsGridViewData,
  normalizeNewsDetailViewData,
  normalizeRelatedNewsDetailViewData,
} from 'components/homePageCMS/normalize/NormalizeWordpressData';
import { homepageNewsUrl, HTTP_STATUSES } from 'configs/constants';
import { getResponseError } from 'utils/sourceUtils';

export const getAllNewsSource = (count, page) => (dispatch) => {
  let url = homepageNewsUrl.GET;
  if (count !== null && page === null) {
    url = `${url}?per_page=${count}`;
  } else {
    url = `${url}?per_page=${count}&page=${page}`;
  }
  Axios.get(url)
    .then((response) => {
      if (response.data.code === HTTP_STATUSES.SUCCESS) {
        if (count !== null && page === null) {
          const normalizedNews = normalizeHomePageNewsData(response.data.data);
          dispatch(getLatestHomeNewsAction(normalizedNews));
        } else {
          const normalizedNews = normalizeNewsGridViewData(
            response.data.data,
            response.data.headers['x-wp-total'],
          );

          if (page > 1) {
            dispatch(appendNewsGridData(normalizedNews));
          } else {
            dispatch(getGridNewsAction(normalizedNews));
          }
        }
      } else {
        EnhancedNotification.error({ message: response.data.data.message });
        // dispatch(getAPIFAILURE());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({ message: getResponseError(error) });
      // dispatch(getAPIFAILURE());
    });
};
export const getSingleNewsSource = (newsId) => (dispatch) => {
  const url = `${homepageNewsUrl.GET}/${newsId}`;
  Axios.get(url)
    .then((response) => {
      if (response.data.code === HTTP_STATUSES.SUCCESS) {
        const { data } = response.data;
        const singleNewsNormalizer = normalizeNewsDetailViewData(data);
        const relatedNewsNormalizer = normalizeRelatedNewsDetailViewData(data.relatedNews);
        dispatch(getSingleNewsAction(singleNewsNormalizer));
        if (relatedNewsNormalizer.length > 0) {
          const lists = relatedNewsNormalizer.filter((x) => x.id !== singleNewsNormalizer.id);
          dispatch(getRelatedNewsAction(lists));
        } else {
          dispatch(getRelatedNewsAction(relatedNewsNormalizer));
        }
      } else {
        EnhancedNotification.error({ message: response.data.data.message });
        // dispatch(getAPIFAILURE());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({ message: getResponseError(error) });
      // dispatch(getAPIFAILURE());
    });
};
