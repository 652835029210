import { EnhancedCol, EnhancedRow } from 'components/shared/antd';
import React, { Component } from 'react';
import moment from 'moment';
import { getLeaveStatusColor } from 'utils/colorUtils';
import { leaveStatus } from 'configs/leaveConstants';
import { approvedLeavePermission } from 'utils/leaveUtils';
import { Desktop, Mobile } from 'components/Responsive';

const DATE_FORMAT = 'DD/MM/YYYY';
export default class LeaveRequestItem extends Component {
  updateLeaveRequest = (leaveId, lmStatus) => {
    if (lmStatus === leaveStatus.APPROVED) {
      this.props.onUpdateLeave(leaveId, { lmStatus });
    }
  };

  updateLeaveRequestAsPM = (leaveId, pmStatus) => {
    if (pmStatus === leaveStatus.APPROVED) {
      const pmStatusObj = [
        {
          id: this.props.user && this.props.user.id,
          name: this.props.user && this.props.user.name,
          status: pmStatus,
        },
      ];
      this.props.onUpdateLeaveAsPM(leaveId, { pmStatus: pmStatusObj });
    }
  };

  updateLeaveRequestAsCRMC = (leaveId, pmStatus) => {
    if (pmStatus === leaveStatus.APPROVED) {
      const pmStatusObj = [
        {
          id: this.props.user && this.props.user.id,
          name: this.props.user && this.props.user.name,
          status: pmStatus,
        },
      ];
      this.props.onUpdateLeaveAsCRMC(leaveId, { pmStatus: pmStatusObj });
    }
  };

  render() {
    const { duration, requester, currentStatus, leaveName, approvers } = this.props.request;

    let { startDate, endDate, requestDate } = this.props.request;

    startDate = moment.utc(startDate);
    endDate = moment.utc(endDate);
    requestDate = moment.utc(requestDate);

    const {
      user: { id: userId },
      currentlyActiveTab,
    } = this.props;

    const { canApprove } = approvedLeavePermission({
      approvers,
      currentlyActiveTab,
      currentStatus,
      userId,
    });

    return (
      <div>
        <Desktop>
          <EnhancedRow
            onClick={this.props.onClick}
            style={{
              height: 48,
              display: 'flex',
              alignItems: 'center',
              fontSize: '10px',
            }}
          >
            <EnhancedCol lg={1}>
              <div
                className="counter"
                style={{
                  textAlign: 'center',
                  fontSize: 22,
                  backgroundColor: getLeaveStatusColor(currentStatus),
                  width: 48,
                  heigh: 48,
                }}
              >
                {requester.initial}
              </div>
            </EnhancedCol>
            <EnhancedCol lg={5} style={{ paddingLeft: '20px' }}>
              <h4>{requester.name}</h4>
              <h4 className="grey-color">
                {`${duration} ${duration !== 1 ? 'Days' : 'Day'} Leave`}
              </h4>
            </EnhancedCol>
            <EnhancedCol lg={3}>
              <h4 className="grey-color">Status</h4>
              <h4
                style={{
                  color: getLeaveStatusColor(currentStatus),
                  textTransform: 'capitalize',
                }}
              >
                {currentStatus}
              </h4>
            </EnhancedCol>
            <EnhancedCol lg={3}>
              <h4 className="grey-color">Type</h4>
              <h4 style={{ textTransform: 'capitalize' }}>{leaveName}</h4>
            </EnhancedCol>
            <EnhancedCol lg={3}>
              <h4 className="grey-color">Requested Date</h4>
              <h4 className="grey-color">{requestDate.format(DATE_FORMAT)}</h4>
            </EnhancedCol>
            <EnhancedCol lg={2} className="grey-color">
              <h4 className="grey-color">From Date</h4>
              <h4 className="grey-color">{startDate.format(DATE_FORMAT)}</h4>
            </EnhancedCol>
            <EnhancedCol lg={2} className="grey-color">
              <h4 className="grey-color">To Date</h4>
              <h4 className="grey-color">{endDate.format(DATE_FORMAT)}</h4>
            </EnhancedCol>
            <EnhancedCol lg={4}>
              {canApprove && (
                <span>
                  <button className="grey-color transparent-button" onClick={this.props.onClick}>
                    Approve
                  </button>
                  <span> | </span>
                  <button className="grey-color transparent-button" onClick={this.props.onClick}>
                    Decline
                  </button>
                  <span> | </span>
                  <button className="grey-color transparent-button" onClick={this.props.onClick}>
                    Comment
                  </button>
                </span>
              )}
            </EnhancedCol>
          </EnhancedRow>
        </Desktop>
        <Mobile>
          <div
            role="presentation"
            onClick={this.props.onClick}
            onKeyDown={this.props.onClick}
            style={{ margin: -16, padding: '10 0 10 0' }}
          >
            <EnhancedRow style={{ paddingTop: 10 }}>
              <EnhancedCol span={4}>
                <div
                  className="counter"
                  style={{
                    textAlign: 'center',
                    fontSize: 20,
                    height: 45,
                    width: 50,
                    backgroundColor: getLeaveStatusColor(currentStatus),
                  }}
                >
                  {requester.initial}
                </div>
              </EnhancedCol>
              <EnhancedCol span={20} style={{ paddingLeft: 20, wordBreak: 'normal' }}>
                <h4>{requester.name}</h4>
                <h4 className="grey-color">
                  {`${duration} ${duration !== 1 ? 'Days' : 'Day'} Leave`}
                </h4>
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow>
              <EnhancedCol span={12}>
                <h4 className="grey-color">Status</h4>
              </EnhancedCol>
              <EnhancedCol span={12}>
                <h4
                  style={{
                    color: getLeaveStatusColor(currentStatus),
                    textTransform: 'capitalize',
                  }}
                >
                  {currentStatus}
                </h4>
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow>
              <EnhancedCol span={12}>
                <h4 className="grey-color">Type</h4>
              </EnhancedCol>
              <EnhancedCol span={12}>
                <h4 style={{ textTransform: 'capitalize' }}>{leaveName}</h4>
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow>
              <EnhancedCol span={12} className="grey-color">
                <h4 className="grey-color">From Date</h4>
                <h4 className="grey-color">{startDate.format(DATE_FORMAT)}</h4>
              </EnhancedCol>
              <EnhancedCol span={12} className="grey-color">
                <h4 className="grey-color">To Date</h4>
                <h4 className="grey-color">{endDate.format(DATE_FORMAT)}</h4>
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow style={{ paddingBottom: 10, paddingTop: 10 }}>
              <EnhancedCol
                span={24}
                style={{ display: 'flex', justifyContent: 'center', fontWeight: 400 }}
              >
                {canApprove && (
                  <span>
                    <button
                      className="grey-color transparent-button"
                      onClick={this.props.onClick}
                      style={{ fontWeight: 900 }}
                    >
                      Approve
                    </button>
                    <span> | </span>
                    <button
                      className="grey-color transparent-button"
                      onClick={this.props.onClick}
                      style={{ fontWeight: 900 }}
                    >
                      Decline
                    </button>
                  </span>
                )}
              </EnhancedCol>
            </EnhancedRow>
          </div>
        </Mobile>
      </div>
    );
  }
}
