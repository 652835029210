import {
  EnhancedCol,
  EnhancedCard,
  EnhancedRow,
  EnhancedTag,
  EnhancedTooltip,
} from 'components/shared/antd';
import React, { Component } from 'react';
import moment from 'moment';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import { Link } from 'react-router-dom';
import LeaveDetailsModal from 'components/project/LeaveDetailsModal';
import leaveIndicatorIcon from 'images/ic_leave_indicator.svg';
import { NOT_AVAILABLE } from 'configs/additionalConstants';
import { skillLevel, employeeType } from 'configs/constants';
import { parse } from 'utils/stringUtils';
import { DATE_FORMAT } from 'configs/employeeProfileConstants';
import 'components/employees/Employees.css';
import PROJECT_ROUTES from '../../constants/UrlConstants';

const STATUS_INACTIVE = 'inactive';

class Employees extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortedInfo: null,
      employees: this.filterEmployeesAsPerStatus(props.employees || [], props.match.params.status),
      showAllPrimary: -1,
      showAllSecondary: -1,
      readOnly: this.props.readOnly,
      leaveDetailsModalVisible: false,
      leaveDetailsEmployeeID: null,
      resourceTypes: [
        employeeType.BILLABLE,
        employeeType.NONBILLABLE,
        NOT_AVAILABLE.toLowerCase(),
      ].map((item, index) => ({
        id: index,
        name: item,
      })),
    };
  }

  componentDidMount() {
    this.props.getEmployeesActions();
    this.props.getEmployeeDepartmentsAction();
    this.props.getEmployeeCompetenciesAction();
    this.props.getAllDesignations();
    this.props.getAllLocations();
    this.props.getEmployeeDivisionsAction();
    this.props.getEmployeeBusinessUnitAction();
  }

  componentWillReceiveProps(nextProps) {
    const { employees, match } = this.props;

    if (
      nextProps.employees !== employees ||
      nextProps.match.params.status !== match.params.status
    ) {
      this.setState({
        employees: this.filterEmployeesAsPerStatus(
          nextProps.employees,
          nextProps.match.params.status,
        ),
      });
    }
  }

  getColumnsInfo = () => {
    let { sortedInfo } = this.state;

    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: '',
        dataIndex: 'leaveIndicator',
        key: 'leaveIndicator',
        render: this.renderLeaveIndicator,
        width: 50,
        fixed: 'left',
      },
      {
        title: 'Taleo ID',
        dataIndex: 'taleoId',
        key: 'taleoId',
        render: this.renderEmployeeID,
        width: 100,
        sorter: (a, b) => a.taleoId.localeCompare(b.taleoId, undefined, { numeric: true }),
        sortOrder: sortedInfo.columnKey === 'taleoId' && sortedInfo.order,
        fixed: 'left',
        filterConfig: {
          type: 'search',
          key: 'taleoId',
        },
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 150,
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        render: this.renderEmployeeFirstName,
        fixed: 'left',
        filterConfig: {
          type: 'search',
          key: 'name',
        },
      },
      {
        title: 'Designation',
        dataIndex: 'designation',
        key: 'designation',
        width: 150,
        sorter: (a, b) => {
          const first = a.designation ? a.designation.name : NOT_AVAILABLE;
          const second = b.designation ? b.designation.name : NOT_AVAILABLE;
          return first.localeCompare(second);
        },
        sortOrder: sortedInfo.columnKey === 'designation' && sortedInfo.order,
        ellipsis: true,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'designation.name',
          optionsConfig: {
            values: this.props.designations,
          },
        },
        render: this.renderEmployeeDesignation,
      },
      {
        title: 'Manager',
        dataIndex: 'managerName',
        key: 'manager',
        width: 150,
        sorter: (a, b) => a.managerName.localeCompare(b.managerName),
        sortOrder: sortedInfo.columnKey === 'manager' && sortedInfo.order,
        render: this.renderEmployeeManager,
        filterConfig: {
          type: 'search',
          key: 'managerName',
        },
      },
      {
        title: 'Type',
        dataIndex: 'resourceType',
        key: 'resourceType',
        width: 110,
        sorter: (a, b) => a.resourceType.localeCompare(b.resourceType),
        sortOrder: sortedInfo.columnKey === 'resourceType' && sortedInfo.order,
        ellipsis: true,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'resourceType',
          optionsConfig: {
            values: this.state.resourceTypes,
          },
        },
        render: this.renderEmployeeResourceType,
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        width: 110,
        sorter: (a, b) => {
          const first = a.location ? a.location.name : NOT_AVAILABLE;
          const second = b.location ? b.location.name : NOT_AVAILABLE;
          return first.localeCompare(second);
        },
        sortOrder: sortedInfo.columnKey === 'location' && sortedInfo.order,
        ellipsis: true,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'location.name',
          optionsConfig: {
            values: this.props.locations,
          },
        },
        render: this.renderLocation,
      },
      {
        title: 'Total Experience',
        dataIndex: 'totalExperience',
        key: 'totalExperience',
        width: 190,
        sorter: (a, b) => b.experienceInYears - a.experienceInYears,
        sortOrder: sortedInfo.columnKey === 'totalExperience' && sortedInfo.order,
        ellipsis: true,
        filterConfig: {
          type: 'search',
          key: 'totalExperience',
        },
        render: this.renderTotalExperience,
      },
      {
        title: 'Business Unit',
        dataIndex: 'businessUnit',
        key: 'businessUnit',
        width: 150,
        sorter: (a, b) => {
          const first = a.businessUnit ? a.businessUnit.name : NOT_AVAILABLE;
          const second = b.businessUnit ? b.businessUnit.name : NOT_AVAILABLE;
          return first.localeCompare(second);
        },
        sortOrder: sortedInfo.columnKey === 'businessUnit' && sortedInfo.order,
        ellipsis: true,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'businessUnit.name',
          optionsConfig: {
            values: this.props.businessUnits,
          },
        },
        render: this.renderEmployeeBusinessUnit,
      },
      {
        title: 'Division',
        dataIndex: 'division',
        key: 'division',
        width: 150,
        sorter: (a, b) => {
          const first = a.division ? a.division.name : NOT_AVAILABLE;
          const second = b.division ? b.division.name : NOT_AVAILABLE;
          return first.localeCompare(second);
        },
        sortOrder: sortedInfo.columnKey === 'division' && sortedInfo.order,
        ellipsis: true,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'division.name',
          optionsConfig: {
            values: this.props.divisions,
          },
        },
        render: this.renderEmployeeDivision,
      },
      {
        title: 'Competency',
        dataIndex: 'competency',
        key: 'competency',
        width: 150,
        sorter: (a, b) => {
          const first = a.competency ? a.competency.name : NOT_AVAILABLE;
          const second = b.competency ? b.competency.name : NOT_AVAILABLE;
          return first.localeCompare(second);
        },
        sortOrder: sortedInfo.columnKey === 'competency' && sortedInfo.order,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'competency.name',
          optionsConfig: {
            values: this.props.competencies,
          },
        },
        render: this.renderEmployeeCompetency,
      },
      {
        title: 'Department',
        dataIndex: 'department',
        key: 'department',
        width: 150,
        sorter: (a, b) => {
          const first = a.department ? a.department.name : NOT_AVAILABLE;
          const second = b.department ? b.department.name : NOT_AVAILABLE;
          return first.localeCompare(second);
        },
        sortOrder: sortedInfo.columnKey === 'department' && sortedInfo.order,
        ellipsis: true,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'department.name',
          optionsConfig: {
            values: this.props.departments,
          },
        },
        render: this.renderEmployeeDepartment,
      },
      {
        title: 'Primary Skills',
        dataIndex: 'primarySkills',
        key: 'primarySkills',
        render: this.renderPrimarySkills,
        filterConfig: {
          type: 'search',
          key: 'primarySkills',
          customFunc: (item, filterValue) =>
            item.primarySkills.some(
              (skill) => skill.skill && skill.skill.name.toLowerCase().includes(filterValue),
            ),
        },
      },
      {
        title: 'Secondary Skills',
        dataIndex: 'secondarySkills',
        key: 'secondarySkills',
        render: this.renderSecondarySkills,
        filterConfig: {
          type: 'search',
          key: 'secondarySkills',
          customFunc: (item, filterValue) =>
            item.secondarySkills.some(
              (skill) => skill.skill && skill.skill.name.toLowerCase().includes(filterValue),
            ),
        },
      },
    ];
    return columns;
  };

  getFileHeaders = () => {
    const headers = [
      {
        label: 'Taleo ID',
        key: 'taleoId',
      },
      {
        label: 'Name',
        key: 'name',
      },
      {
        label: 'Designation',
        key: 'designation.name',
      },
      {
        label: 'Line Manager',
        key: 'managerName',
      },
      {
        label: 'Type',
        key: 'resourceType',
      },
      {
        label: 'Location',
        key: 'location.name',
      },
      {
        label: 'Total Experience',
        key: 'totalExperience',
      },
      {
        label: 'Competency',
        key: 'competency.name',
      },
      {
        label: 'Department',
        key: 'department.name',
      },

      {
        label: 'Division',
        key: 'division.name',
      },

      {
        label: 'Business Unit',
        key: 'businessUnit.name',
      },
      {
        label: 'Primary Skills',
        key: 'primarySkills',
        renderer: this.skillsRendererForExport,
      },
      {
        label: 'Secondary Skills',
        key: 'secondarySkills',
        renderer: this.skillsRendererForExport,
      },
    ];
    return headers;
  };

  filterEmployeesAsPerStatus = (employees, paramStatus) => {
    const isActive = paramStatus !== STATUS_INACTIVE;

    return employees.filter((emp) => emp.isActive === isActive);
  };

  skillsRendererForExport = (skills) => {
    let response = NOT_AVAILABLE;

    if (skills.length > 0) {
      response = skills
        .map((employeeSkill) => {
          const result = employeeSkill.skill ? employeeSkill.skill.name : NOT_AVAILABLE;
          return result;
        })
        .join(', ');
    }

    return response;
  };

  handleLeaveDetails = (employeeId) => {
    this.setState({
      leaveDetailsEmployeeID: employeeId,
      leaveDetailsModalVisible: true,
    });
  };

  handleLeaveDetailsClose = (close) => {
    this.setState({
      leaveDetailsModalVisible: close,
    });
  };

  handleAddEmployee = () => this.props.history.push('/crmc/employees/add');

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  };

  renderLeaveIndicator = (leaveIndicator, data) => (
    /* eslint-disable */
    <EnhancedTooltip title="Leave Indicator">
      <a onClick={() => this.handleLeaveDetails(data.id)}>
        <img src={leaveIndicatorIcon} alt="leave indicator" />
      </a>
    </EnhancedTooltip>
    /* eslint-enable */
  );

  renderEmployeeFirstName = (name, data) => {
    const { status } = this.props.match.params;
    const { resignationDate, isActive, leavingDate } = data;
    const color = moment() >= moment(resignationDate) && isActive ? 'red' : '#5A5A5A';
    const link = parse(PROJECT_ROUTES.EMPLOYEE_DETAIL_ROUTE, { status, id: data.id });

    const cell = (
      <Link to={link}>
        <b style={{ color }}>{name}</b>
      </Link>
    );

    return moment() >= moment(resignationDate) && isActive ? (
      <EnhancedTooltip
        title={`On Notice Period. ${
          leavingDate ? `Last Working Day: ${moment(leavingDate).format(DATE_FORMAT)}` : ''
        }`}
      >
        {cell}
      </EnhancedTooltip>
    ) : (
      cell
    );
  };

  renderEmployeeID = (name, data) => {
    const employeeId = data.taleoId ? data.taleoId : NOT_AVAILABLE;
    const { resignationDate, isActive } = data;
    const color = moment() >= moment(resignationDate) && isActive ? 'red' : '#5A5A5A';
    return <div style={{ color, wordBreak: 'normal' }}>{employeeId}</div>;
  };

  renderEmployeeDepartment = (name, data) => {
    const { resignationDate, isActive } = data;
    const color = moment() >= moment(resignationDate) && isActive ? 'red' : '#5A5A5A';
    return (
      <div style={{ color, wordBreak: 'normal' }}>
        {data.department ? data.department.name : NOT_AVAILABLE}
      </div>
    );
  };

  renderEmployeeDivision = (name, data) => {
    const { resignationDate, isActive } = data;
    const color = moment() >= moment(resignationDate) && isActive ? 'red' : '#5A5A5A';
    return (
      <div style={{ color, wordBreak: 'normal' }}>
        {data.division ? data.division.name : NOT_AVAILABLE}
      </div>
    );
  };

  renderEmployeeBusinessUnit = (name, data) => {
    const { resignationDate, isActive } = data;
    const color = moment() >= moment(resignationDate) && isActive ? 'red' : '#5A5A5A';
    return (
      <div style={{ color, wordBreak: 'normal' }}>
        {data.businessUnit ? data.businessUnit.name : NOT_AVAILABLE}
      </div>
    );
  };

  renderEmployeeCompetency = (name, data) => {
    const { resignationDate, isActive } = data;
    const color = moment() >= moment(resignationDate) && isActive ? 'red' : '#5A5A5A';
    return (
      <div style={{ color, wordBreak: 'normal' }}>
        {data.competency ? data.competency.name : NOT_AVAILABLE}
      </div>
    );
  };

  renderEmployeeResourceType = (name, data) => (
    <span>{data.resourceType ? data.resourceType : NOT_AVAILABLE}</span>
  );

  renderLocation = (name, data) => (
    <span>{data.location ? data.location.name : NOT_AVAILABLE}</span>
  );

  renderTotalExperience = (name, data) => (
    <span>{data.totalExperience ? data.totalExperience : NOT_AVAILABLE}</span>
  );

  renderEmployeeDesignation = (name, data) => {
    const { resignationDate, isActive } = data;
    const color = moment() >= moment(resignationDate) && isActive ? 'red' : '#5A5A5A';
    return (
      <div style={{ color, wordBreak: 'normal' }}>
        {data.designation ? data.designation.name : NOT_AVAILABLE}
      </div>
    );
  };

  renderEmployeeManager = (name, data) => {
    const { resignationDate, isActive } = data;
    const color = moment() >= moment(resignationDate) && isActive ? 'red' : '#5A5A5A';
    return (
      <div style={{ color, wordBreak: 'normal' }}>
        {data.managerName ? data.managerName : NOT_AVAILABLE}
      </div>
    );
  };

  renderPrimarySkills = (multipleSkills, record, index) => {
    const actionOutline = 'solid #BFBFBF 2px';
    const { resignationDate, isActive } = record;
    const color = moment() >= moment(resignationDate) && isActive ? 'red' : '#5A5A5A';

    const tagStyle = {
      backgroundColor: '#fff',
      color,
      border: 'solid #DEDFD7 2px',
      minWidth: 30,
      height: 28,
      paddingTop: 2,
      marginRight: 5,
      marginTop: 5,
      borderRadius: '4px',
    };

    const skills = multipleSkills.filter((skill) => skill.skill && skill.skill.name);

    if (skills.length === 0) {
      return NOT_AVAILABLE;
    }

    const showLessBadge = (
      <EnhancedTag
        style={{
          ...tagStyle,
          backgroundColor: 'white',
          color: 'rgb(90, 90, 90)',
          cursor: 'pointer',
          border: actionOutline,
        }}
        onClick={() => {
          this.setState({ showAllPrimary: -1 });
        }}
      >
        Show Less
      </EnhancedTag>
    );

    if (skills.length > 2) {
      if (this.state.showAllPrimary === index) {
        const allSkills = skills.map((skill) => (
          <EnhancedTag style={{ ...tagStyle }}>{skill.skill.name}</EnhancedTag>
        ));
        return [allSkills, showLessBadge];
      }
      const showMoreBadge = (
        <EnhancedTag
          style={{
            ...tagStyle,
            backgroundColor: 'white',
            color: 'rgb(90, 90, 90)',
            cursor: 'pointer',
            border: actionOutline,
          }}
          onClick={() => {
            this.setState({ showAllPrimary: index });
          }}
        >
          {`${skills.length - 2} More`}
        </EnhancedTag>
      );

      const slicedSkills = skills.slice(0, 2).map((skill) => {
        return (
          <EnhancedTag style={{ ...tagStyle }}>
            {skill.skill !== undefined && skill.skill !== null ? skill.skill.name : ''}
          </EnhancedTag>
        );
      });
      return [...slicedSkills, showMoreBadge];
    }
    return skills.map((skill) => {
      if (skill && skill.skill) {
        return (
          <EnhancedTag key={skill.skill.name} style={{ ...tagStyle }}>
            {skill.skill !== undefined && skill.skill !== null ? skill.skill.name : ''}
          </EnhancedTag>
        );
      }
      return null;
    });
  };

  renderSecondarySkills = (multipleSkills, record, index) => {
    const actionOutline = 'solid #BFBFBF 2px';
    const { resignationDate, isActive } = record;
    const color = moment() >= moment(resignationDate) && isActive ? 'red' : '#5A5A5A';

    const tagStyle = {
      backgroundColor: '#fff',
      color,
      border: 'solid #DEDFD7 2px',
      minWidth: 30,
      height: 28,
      paddingTop: 2,
      marginRight: 5,
      marginTop: 5,
      borderRadius: '4px',
    };

    const skills = multipleSkills.filter((skill) => skill.skill && skill.skill.name);

    if (skills.length === 0) {
      return NOT_AVAILABLE;
    }

    skills.sort((a, b) => {
      if (a.expertise === skillLevel.INTERMIDIATE) return -1;
      else if (b.expertise === skillLevel.INTERMIDIATE) return 1;
      return -1;
    });

    const showLessBadge = (
      <EnhancedTag
        style={{
          ...tagStyle,
          backgroundColor: 'white',
          color: 'rgb(90, 90, 90)',
          cursor: 'pointer',
          border: actionOutline,
        }}
        onClick={() => {
          this.setState({ showAllSecondary: -1 });
        }}
      >
        Show Less
      </EnhancedTag>
    );

    if (skills.length > 2) {
      if (this.state.showAllSecondary === index) {
        const allSkills = skills.map((skill) => (
          <EnhancedTag style={{ ...tagStyle }}>{skill.skill.name}</EnhancedTag>
        ));
        return [allSkills, showLessBadge];
      }
      const showMoreBadge = (
        <EnhancedTag
          style={{
            ...tagStyle,
            backgroundColor: 'white',
            color: 'rgb(90, 90, 90)',
            cursor: 'pointer',
            border: actionOutline,
          }}
          onClick={() => {
            this.setState({ showAllSecondary: index });
          }}
        >
          {`${skills.length - 2} More`}
        </EnhancedTag>
      );

      const slicedSkills = skills.slice(0, 2).map((skill) => {
        return (
          <EnhancedTag style={{ ...tagStyle }}>
            {skill.skill !== undefined && skill.skill !== null ? skill.skill.name : ''}
          </EnhancedTag>
        );
      });
      return [...slicedSkills, showMoreBadge];
    }
    return skills.map((skill) => {
      if (skill && skill.skill) {
        return (
          <EnhancedTag key={skill.skill.name} style={{ ...tagStyle }}>
            {skill.skill !== undefined && skill.skill !== null ? skill.skill.name : ''}
          </EnhancedTag>
        );
      }
      return null;
    });
  };

  render() {
    const { isLoading, match } = this.props;
    const { employees } = this.state;
    let columns = this.getColumnsInfo();

    const statusHeading = match.params.status;

    if (statusHeading === STATUS_INACTIVE || this.state.readOnly) {
      columns = columns.filter((col) => col.title !== 'Actions');
    }

    return (
      <div>
        <LeaveDetailsModal
          employeeId={this.state.leaveDetailsEmployeeID}
          leavesData={employees}
          onModalClose={this.handleLeaveDetailsClose}
          visible={this.state.leaveDetailsModalVisible}
          workingHolidays={this.props.workingHolidays}
        />
        <EnhancedCard>
          <EnhancedRow style={{ marginBottom: '10px' }}>
            <EnhancedCol lg={12}>
              <h2 className="heading-emp">{statusHeading} Employees</h2>
            </EnhancedCol>
            <EnhancedCol span={12}>
              <h2 className="heading-emp" style={{ float: 'right' }}>
                Total Employees Count: {employees.length}
              </h2>
            </EnhancedCol>
          </EnhancedRow>
        </EnhancedCard>
        <EnhancedCard>
          <div>
            <EnhancedClientSideTable
              loading={isLoading}
              columns={columns}
              rowKey={(row) => row.id}
              rowClassName={(record) => {
                let classNames = '';
                if (!('leavesDetails' in record) || record.leavesDetails.length === 0) {
                  classNames += 'hide-li-icon-leave-indicator';
                }

                if (
                  record.resignationDate &&
                  moment() >= moment(record.resignationDate) &&
                  record.isActive
                ) {
                  classNames += ' on-notice ';
                }

                return classNames;
              }}
              data={employees}
              onChange={this.handleChange}
              scroll={{ x: 2000 }}
              exportFileConfig={{
                fileName: `${statusHeading} Employees`,
                fileHeaders: this.getFileHeaders(),
                showExportButton: true,
              }}
            />
          </div>
        </EnhancedCard>
      </div>
    );
  }
}

export default Employees;
