import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedModal,
  EnhancedDatePicker,
  EnhancedInput,
  EnhancedInputNumber,
  EnhancedSlider,
  EnhancedForm,
  EnhancedNotification,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import RequiredField from 'components/RequiredField';
import { DATE_FORMAT } from 'configs/employeeProfileConstants';
import moment from 'moment';

const { TextArea } = EnhancedInput;
const AddGoalModal = (props) => {
  const {
    isModalVisible,
    handleOk,
    handleCancel,
    updateGoalActions,
    params,
    isEdit,
    editableHandler,
    isLoading,
    details,
  } = props;
  const [goalsField, setGoalsField] = useState({
    title: '',
    weightage: '',
    measurement: '',
    description: '',
    dueDate: moment().format(DATE_FORMAT),
    status: 'pending',
    progress: 0,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setGoalsField({
      ...goalsField,
      [name]: value,
    });
  };

  const onDueDateChange = (date) => {
    setGoalsField({
      ...goalsField,
      dueDate: moment(date).format(DATE_FORMAT),
    });
  };

  const onSliderChange = (value) => {
    setGoalsField({
      ...goalsField,
      progress: value,
    });
  };

  const editGoal = () => {
    if (goalsField.weightage > 100 || goalsField.weightage < 1) {
      EnhancedNotification.error({
        message: 'weightage must be greater than 0 and less than or equal to 100',
      });
      return;
    }
    updateGoalActions({
      id: details.id,
      userId: details.userId,
      payload: goalsField,
      params,
    });
    handleOk();
    setGoalsField({
      title: '',
      weightage: '',
      measurement: '',
      description: '',
      dueDate: moment().format(DATE_FORMAT),
      status: 'pending',
      progress: 0,
    });
    editableHandler(false);
  };

  const closeModalHandler = () => {
    handleCancel();
    setGoalsField({
      title: '',
      weightage: '',
      measurement: '',
      description: '',
      dueDate: moment().format(DATE_FORMAT),
      status: 'pending',
      progress: 0,
    });
    editableHandler(false);
  };

  useEffect(() => {
    setGoalsField({
      title: details.title,
      weightage: details.weightage,
      measurement: details.measurement,
      description: details.description,
      dueDate: details.dueDate,
      status: details.status,
      progress: details.progress,
    });
  }, [isLoading]);

  return (
    <EnhancedModal
      title={isEdit ? 'Edit Goal' : 'View Goal'}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={closeModalHandler}
      className="add-goals-modal"
      width="1041px"
      footer={
        isEdit && [
          <EnhancedButton
            key="submit"
            type="primary"
            onClick={editGoal}
            disabled={!goalsField.title || !goalsField.dueDate || !goalsField.weightage}
          >
            {'Edit Changes'}
          </EnhancedButton>,
        ]
      }
    >
      <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
        loading={isLoading}
        bordered={false}
        className="details-card-wrapper"
        style={{
          marginTop: 10,
        }}
      >
        <EnhancedRow>
          <EnhancedForm layout="inline" className="goals-form">
            {/* <EnhancedCol span={24} className="form-col"> */}
            <EnhancedCol lg={4}>
              <RequiredField> Goal Title </RequiredField>
            </EnhancedCol>
            <EnhancedCol lg={7}>
              <EnhancedInput
                name="title"
                value={goalsField.title}
                onChange={handleChange}
                disabled={!isEdit}
              />
            </EnhancedCol>
            <EnhancedCol lg={2} />
            <EnhancedCol lg={4}>
              <RequiredField> Weightage (%) </RequiredField>
            </EnhancedCol>
            <EnhancedCol lg={7}>
              <EnhancedInput
                type="number"
                name="weightage"
                value={goalsField.weightage}
                onChange={handleChange}
                disabled={!isEdit}
              />
            </EnhancedCol>
            <EnhancedCol lg={4} style={{ marginTop: 20 }}>
              <label htmlFor="Description"> Description </label>
            </EnhancedCol>
            <EnhancedCol lg={7} style={{ marginTop: 20 }}>
              <TextArea
                name="description"
                value={goalsField.description}
                onChange={handleChange}
                rows={4}
                disabled={!isEdit}
              />
            </EnhancedCol>
            <EnhancedCol lg={2} />
            <EnhancedCol lg={4} style={{ marginTop: 20 }}>
              <label htmlFor="Measurements"> Measurements </label>
            </EnhancedCol>
            <EnhancedCol lg={7} style={{ marginTop: 20 }}>
              <TextArea
                name="measurement"
                value={goalsField.measurement}
                onChange={handleChange}
                rows={4}
                disabled={!isEdit}
              />
            </EnhancedCol>
            <EnhancedCol lg={4} style={{ marginTop: 20 }}>
              <RequiredField> Due Date </RequiredField>
            </EnhancedCol>
            <EnhancedCol lg={7} style={{ marginTop: 20 }}>
              <EnhancedDatePicker
                name="dueDate"
                style={{ width: '100%' }}
                onChange={onDueDateChange}
                value={moment(goalsField.dueDate)}
                format={DATE_FORMAT}
                disabledDate={(current) => {
                  const customDate = moment().format(DATE_FORMAT);
                  return current && current < moment(customDate, DATE_FORMAT);
                }}
                allowClear={false}
                disabled={!isEdit}
              />
            </EnhancedCol>
            <EnhancedCol lg={2} />
            {/* <Col lg={4} style={{marginTop: 20}}>
                  <label htmlFor="Status"> Status </label>
                </Col> */}
            {/* <Col lg={7} style={{marginTop: 20}}>
                <EnhancedSelect value={!isEdit ? 'New' : goalsField.status} name="status" onChange={handleSelect} disabled={!isEdit}>
                  {
                    !isEdit ? (<Option value="Pending">New</Option>) : 
                    editStatusOptionMenu.map(item => <Option value={item.value}>{item.title}</Option>)
                  }
                </EnhancedSelect>
                </Col> */}
            <EnhancedCol lg={4} style={{ marginTop: 20 }}>
              <label htmlFor="Percent Complete"> Percent Complete </label>
            </EnhancedCol>
            <EnhancedCol lg={7} style={{ marginTop: 20 }}>
              <EnhancedRow>
                <EnhancedCol span={18}>
                  <EnhancedSlider
                    min={0}
                    max={100}
                    step={10}
                    onChange={onSliderChange}
                    className="range-slider"
                    value={typeof goalsField.progress === 'number' ? goalsField.progress : 0}
                    disabled={!isEdit}
                  />
                </EnhancedCol>
                <EnhancedCol span={5}>
                  <EnhancedInputNumber
                    style={{ marginLeft: 12 }}
                    value={goalsField.progress}
                    readOnly
                  />
                </EnhancedCol>
              </EnhancedRow>
            </EnhancedCol>
            {/* </EnhancedCol> */}
          </EnhancedForm>
        </EnhancedRow>
      </EnhancedCard>
    </EnhancedModal>
  );
};

export default AddGoalModal;
