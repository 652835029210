import axios from 'axios';
import {
  getResourceAvailabilityInit,
  getResourceAvailabilitySuccess,
  getResourceAvailabilityFailure,
  getEmployeeDepartmentsSuccess,
  getEmployeeDepartmentsFailure,
} from 'store/actions/ResourceAvailabilityActions';
import { showLoader, hideLoader } from 'store/actions/AppLoaderActions';
import { resourceAvailabilityUrls, dateFormat, departmentUrls } from 'configs/constants';
import normalizeResourceAvailability from 'normalizers/resourceAvailabilityNormalizers';
import moment from 'moment';

const getResourceAvailability = (date) => {
  let url = resourceAvailabilityUrls.GET;
  const dateParam = date || moment().format(`${dateFormat.DDMMMYYYYwithSpace}`);
  url = url.replace('data', `data/releaseDate/${moment(dateParam).utc().format().toString()}`);
  return (dispatch) => {
    dispatch(showLoader());
    dispatch(getResourceAvailabilityInit());
    axios
      .get(url)
      .then((response) => {
        dispatch(hideLoader());
        if (response.data.success) {
          const resourceNormalized = normalizeResourceAvailability(response.data.data, dateParam);
          dispatch(getResourceAvailabilitySuccess(resourceNormalized));
        } else {
          dispatch(getResourceAvailabilityFailure());
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch(getResourceAvailabilityFailure(error));
      });
  };
};
export const getDepartments = () => (dispatch) => {
  const url = departmentUrls.GET;
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        dispatch(getEmployeeDepartmentsSuccess(response.data.data));
      } else {
        dispatch(getEmployeeDepartmentsFailure());
      }
    })
    .catch(() => {
      // dispatch(getEmployeeDepartmentsFailure());
    });
};

export default getResourceAvailability;
