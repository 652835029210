import { EnhancedRow, EnhancedCol, EnhancedDrawer } from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';
import { roundOffRating } from 'utils/conversionUtils';

const DealsListDrawer = (props) => {
  const {
    onClose,
    isDrawerVisible,
    selectedSkill,
    getDealsSkillWise,
    dealsSkillWiseRows,
    dealsSkillWisePageInfo,
  } = props;
  const { probability, selectedDeals, selectedDate, selectedBusinessUnits } = props.formData;
  const [state, setState] = useState({ page: 0, size: 10, tableColumns: [] });

  const renderResourceRequired = (row) => {
    const probabilityInDecimal = row.probability / 100;
    return `${probabilityInDecimal} * ${row.resourceRequired} = ${roundOffRating(
      probabilityInDecimal * row.resourceRequired,
    )}`;
  };

  useEffect(() => {
    if (isDrawerVisible) {
      const skills = selectedSkill.includes(' + ') ? selectedSkill.split(' + ') : selectedSkill;

      getDealsSkillWise({
        probability,
        dealIds: selectedDeals,
        endDate: `${moment(selectedDate).format('YYYY-MM-DD')}`,
        pageNumber: 0,
        pageSize: 10,
        skills,
        businessUnits: selectedBusinessUnits,
      });
    }
  }, [isDrawerVisible]);

  const onDealNameClick = (dealId) => {
    window.open(`/deals/${dealId}`);
  };

  const tableColumns = [
    {
      title: 'Deal Name',
      dataIndex: 'dealName',
      key: 'dealName',
      render: (name, data) => {
        return (
          <b
            className="pointer-enabled"
            aria-hidden="true"
            onClick={() => onDealNameClick(data.id)}
          >
            {name}
          </b>
        );
      },
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
    },
    {
      title: 'Deal Number',
      dataIndex: 'dealNumber',
      key: 'dealNumber',
    },
    {
      title: 'Probability',
      dataIndex: 'probability',
      key: 'probability',
      sorter: (a, b) => a.probability - b.probability,
    },
    {
      title: 'Resource Required',
      dataIndex: 'resourceRequired',
      key: 'resourceRequired',
      sorter: (a, b) => a.resourceRequired - b.resourceRequired,
    },
    {
      title: 'Required',
      dataIndex: 'required',
      key: 'required',
      render: (text, record) => renderResourceRequired(record),
    },
    {
      title: 'Expected Start Date',
      dataIndex: 'expectedStartDate',
      key: 'expectedStartDate',
      render: (text) => <div>{moment(text).format('YYYY-MM-DD')}</div>,
    },
    {
      title: 'Min Experience Level',
      dataIndex: 'minExperienceLevel',
      key: 'minExperienceLevel',
    },
    {
      title: 'Max Experience Level',
      dataIndex: 'maxExperienceLevel',
      key: 'maxExperienceLevel',
    },
  ];

  const handleTableChange = (pagination) => {
    setState((prevState) => ({
      ...prevState,
      page: pagination.current - 1,
      size: pagination.pageSize,
    }));
  };

  return (
    <EnhancedDrawer
      visible={isDrawerVisible}
      width={880}
      onClose={onClose}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <EnhancedServerSideTable
        data={dealsSkillWiseRows}
        columns={tableColumns}
        onChange={handleTableChange}
        className="deals-table-container"
        paginationInfo={{
          totalItems: dealsSkillWisePageInfo && dealsSkillWisePageInfo.totalItems,
          pageSize: state.size,
          pageNumber: state.page + 1,
        }}
        scroll={{ x: 'max-content' }}
        showResetFiltersButton={false}
        title={() => (
          <EnhancedRow type="flex" align="middle">
            <EnhancedCol span={16}>
              <strong>{`Enlist all Deals having ${selectedSkill} requirements`}</strong>
            </EnhancedCol>
          </EnhancedRow>
        )}
      />
    </EnhancedDrawer>
  );
};

export default DealsListDrawer;
