import React, { Component } from 'react';
import EmployeeSearchCard from './EmployeeSearchCard';
import EmployeeDirectoryHeader from './EmployeeDirectoryHeader';

class EmployeeSearchList extends Component {
  constructor(props) {
    super(props);
    this.timeout = 0;
    this.defaultPageNo = 1;
    this.delayInSeconds = 1000;
    this.showMoreButton = false;
    this.state = {
      pageSize: 12,
      page: 1,
      loading: true,
      filteredData: [],
      value: '',
      totalElements: 0,
    };
  }

  componentDidMount() {
    this.props.getEmployeeSearchAction({
      name: this.state.name,
      page: this.state.page,
      pageSize: this.state.pageSize,
    });
  }

  componentWillReceiveProps(nextProps) {
    const filteredData = this.state.filteredData.concat(nextProps.employeeDirectory);
    this.setState({
      filteredData,
      propsLoading: nextProps.isLoading,
      totalElements: nextProps.employeePageInfo ? nextProps.employeePageInfo.totalElements : 0,
    });

    if (
      this.state.filteredData.length + nextProps.employeeDirectory.length !==
      (nextProps.employeePageInfo ? nextProps.employeePageInfo.totalElements : null)
    ) {
      this.showMoreButton = true;
    } else {
      this.showMoreButton = false;
    }
  }

  onChange = (e) => {
    const { value } = e.target;
    const page = this.defaultPageNo;
    if (value.trim().length > 2 || value.trim().length === 0) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.props.getEmployeeSearchAction({
          name: this.state.value || value,
          page,
          pageSize: this.state.pageSize,
        });
        this.setState({
          filteredData: [],
          loading: true,
          name: value,
          page,
        });
      }, this.delayInSeconds);
    }
  };

  loadMoreItems = () => {
    const { name, pageSize } = this.state;
    const page = this.state.page + this.defaultPageNo;
    const loading = false;
    this.props.getEmployeeSearchAction({
      name,
      page,
      pageSize,
      loading,
    });
    this.setState({ page, loading });
  };

  render() {
    const { filteredData, loading, propsLoading, totalElements } = this.state;
    return (
      <div style={{ backgroundColor: '#fff', paddingBottom: '20px' }}>
        <EmployeeDirectoryHeader
          name="Employee Directory"
          onInputChange={this.onChange}
          filteredData={filteredData}
          totalEmployees={totalElements}
        />
        <EmployeeSearchCard
          filteredData={filteredData}
          loadMoreItems={this.loadMoreItems}
          loading={loading && propsLoading}
          showMoreButton={this.showMoreButton}
        />
      </div>
    );
  }
}

export default EmployeeSearchList;
