import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedIcon,
  EnhancedInput,
} from 'components/shared/antd';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import isEmpty from 'lodash/isEmpty';
import EnhancedFilter from 'components/EnhancedFIlter';

const EnhancedServerSideTable = (props) => {
  const {
    data,
    exportFileConfig: { showExportButton, handler: exportHandler },
    rowSelection,
    rowKey,
    rowClassName,
    showResetFiltersButton,
    columns,
    loading,
    scroll,
    onChange,
    style,
    expandedRowRender,
    locale,
    className,
    updateParams,
    paginationInfo,
    title,
    showHeader,
    children,
  } = props;

  const filterKeyToCustomConfigMap = useRef({});
  /*
  In some cases, parent component will have its own filters as well
  So when we trigger reset all filters from this component, we also need to tell
  the parent component to clear its filters as well.
  So this boolean variable will tell the parent if reset all filters is triggered from here.
  */
  const areAllFiltersReset = useRef(false);

  const [filterInfo, setFilterInfo] = useState({});
  const [searchBoxState, setSearchBoxState] = useState({});
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterColumn, setFilterColumn] = useState();

  const handleResetSearch = () => {
    const emptyFilters = {};
    areAllFiltersReset.current = true;
    Object.keys(filterInfo).forEach((k) => {
      emptyFilters[k] = '';
    });
    setFilterInfo(emptyFilters);
    setSearchBoxState({});
  };

  useEffect(() => {
    if (Object.keys(filterInfo).length > 0) {
      // send filters to parent so that it can call API accordingly
      // not doing API call here because we may have filters outside table at the parent level
      updateParams(
        {
          ...filterInfo,
        },
        areAllFiltersReset.current,
      );
      areAllFiltersReset.current = false;
    }
  }, [filterInfo]);

  const handleFilterVisible = (visible, column) => {
    setIsFilterVisible(visible);
    setFilterColumn(column);
  };

  const handleFilterApply = (dataIndex, selection) => {
    setFilterInfo({
      ...filterInfo,
      [dataIndex]: selection,
    });
    setIsFilterVisible(false);
  };

  const getEnhancedFilterOptions = (optionsConfig, dataIndex) => {
    const { values, valueVar = 'name', labelVar = 'name' } = optionsConfig;

    const filters = values.map((item) => ({
      text: item[labelVar],
      value: item[valueVar],
    }));

    const filteredValue = filterInfo[dataIndex] || [];

    return {
      filters,
      filteredValue,
      filterDropdownVisible: isFilterVisible && filterColumn === dataIndex,
      onFilterDropdownVisibleChange: (visible) => handleFilterVisible(visible, dataIndex),
      filterDropdown: (filterProps) => (
        <EnhancedFilter
          filters={filters}
          filterDropdownVisible={isFilterVisible && filterColumn === dataIndex}
          filteredValue={filteredValue}
          {...filterProps}
          onApply={(selection) => handleFilterApply(dataIndex, selection)}
        />
      ),
    };
  };

  const onSearchInputChange = (value, dataIndex) => {
    setSearchBoxState({
      ...searchBoxState,
      [dataIndex]: value,
    });
  };

  const onSearch = () => {
    setFilterInfo({
      ...filterInfo,
      [filterColumn]: searchBoxState[filterColumn],
    });
  };

  const getColumnSearchProps = (dataIndex, columnTitle) => ({
    filterDropdown: () => (
      <EnhancedRow className="drop-down-filter">
        <EnhancedCol span={19}>
          <EnhancedInput
            placeholder={`Search ${columnTitle}`}
            value={searchBoxState[dataIndex]}
            onChange={(e) => onSearchInputChange(e.target.value, dataIndex)}
            onPressEnter={onSearch}
          />
        </EnhancedCol>
        <EnhancedCol offset={1} span={4}>
          <EnhancedButton type="primary" shape="circle" icon="search" onClick={onSearch} />
        </EnhancedCol>
      </EnhancedRow>
    ),
    filterIcon: (filtered) => (
      <EnhancedIcon type="search" style={{ color: filtered ? '#1DAC8A' : undefined }} />
    ),
    filteredValue: filterInfo[dataIndex] ? [filterInfo[dataIndex]] : '',
    onFilterDropdownVisibleChange: (visible) => handleFilterVisible(visible, dataIndex),
    filterDropdownVisible: isFilterVisible && filterColumn === dataIndex,
  });

  const getColumnsInfo = () => {
    return columns.map((columnConfig) => {
      const { filterConfig } = columnConfig;
      let filterProps = {};

      if (filterConfig) {
        if (filterConfig.type === 'search') {
          filterProps = getColumnSearchProps(filterConfig.key, columnConfig.title);
        } else if (filterConfig.type === 'enhanced_filter') {
          filterProps = getEnhancedFilterOptions(filterConfig.optionsConfig, filterConfig.key);
        }

        filterKeyToCustomConfigMap.current = {
          ...filterKeyToCustomConfigMap.current,
          [columnConfig.dataIndex]: {
            dataKey: filterConfig.key,
            customFilterFunc: filterConfig.customFunc,
          },
        };
      }

      return {
        ...columnConfig,
        ...filterProps,
      };
    });
  };

  const handleGenerateExcelFile = () => {
    exportHandler();
  };

  const getTableProps = () => {
    return {
      loading,
      dataSource: data,
      rowSelection,
      rowKey,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} records`,
        total: paginationInfo && paginationInfo.totalItems,
        current: paginationInfo.pageNumber,
        pageSize: paginationInfo.pageSize,
        size: 'small',
        style: {
          float: 'right',
          textAlign: 'center',
        },
      },
      onChange,
      rowClassName,
      scroll,
      style,
      expandedRowRender,
      locale,
      className,
      title,
      showHeader,
    };
  };

  return (
    <React.Fragment>
      {(showResetFiltersButton || showExportButton) && (
        <EnhancedRow type="flex" justify="end" align="middle" className="mb-20 mt-4 mr-5">
          <EnhancedCol>
            {showResetFiltersButton && (
              <EnhancedButton
                data-testid="clear-filters"
                icon="filter"
                onClick={() => handleResetSearch()}
              >
                Clear Filters
              </EnhancedButton>
            )}
            {!isEmpty(data) && showExportButton && (
              <EnhancedButton icon="file-excel" className="ml-10" onClick={handleGenerateExcelFile}>
                Export
              </EnhancedButton>
            )}
          </EnhancedCol>
        </EnhancedRow>
      )}
      {/* we have some tables without columns, so we need to avoid passing columns prop to the actual antd table */}
      {columns && (
        <Table columns={[...getColumnsInfo()]} {...getTableProps()}>
          {children}
        </Table>
      )}
      {!columns && <Table {...getTableProps()}>{children}</Table>}
    </React.Fragment>
  );
};

EnhancedServerSideTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataIndex: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      renderer: PropTypes.func.isRequired,
      filterConfig: PropTypes.shape({
        type: PropTypes.string.isRequired,
        optionsConfig: PropTypes.arrayOf(
          PropTypes.shape({
            values: PropTypes.any,
            valueVar: PropTypes.string.isRequired,
            labelVar: PropTypes.string.isRequired,
          }),
        ),
        key: PropTypes.string.isRequired, // name of variable which will be passed in the query string
        customFunc: PropTypes.func,
      }),
      sorter: PropTypes.func.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  exportFileConfig: PropTypes.shape({
    showExportButton: PropTypes.bool,
    handler: PropTypes.func,
  }),
  updateParams: PropTypes.func,
  paginationInfo: PropTypes.shape({
    totalItems: PropTypes.number,
    pageNumber: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
  showResetFiltersButton: PropTypes.bool,
  loading: PropTypes.bool,
  rowClassName: PropTypes.string,
  scroll: PropTypes.Object,
  style: PropTypes.Object,
  className: PropTypes.string,
  showHeader: PropTypes.bool,
};

EnhancedServerSideTable.defaultProps = {
  showResetFiltersButton: true,
  loading: false,
  rowClassName: '',
  scroll: {},
  style: {},
  className: '',
  exportFileConfig: {
    showExportButton: false,
  },
  updateParams: () => {},
  showHeader: true,
  columns: null,
};

EnhancedServerSideTable.Column = Table.Column;

export default EnhancedServerSideTable;
