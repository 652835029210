import {
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedIcon,
  EnhancedModal,
} from 'components/shared/antd';
/* eslint-disable */
import React, { Component } from 'react';
import './EventsModal.css';
import { MapMarkerSVG, ZoomLogo, SkypeLogo } from '../utils/CustomIcons';
import defaultImageURL from 'images/default-placeholder.png';

class EventsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorText: false,
      visible: props.visible,
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({
        data: nextProps.data,
        eventDataID: nextProps.eventDataID,
      });
    }

    if (nextProps.visible !== this.state.visible) {
      this.setState({
        visible: nextProps.visible,
      });
    }
  }

  hideModal = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible, data, eventDataID } = this.state;

    let index = 0;
    if (data) {
      index = data.findIndex((event) => event.id === eventDataID);
    }

    return (
      <div>
        <EnhancedModal
          height="735px"
          width="651px"
          visible={visible}
          footer={null}
          onCancel={this.hideModal}
          bodyStyle={{
            backgroundColor: '#f0f2f5',
            paddingBottom: '6%',
            paddingLeft: '4%',
            paddingTop: '6%',
            paddingRight: '4%',
          }}
        >
          <EnhancedCard
            cover={
              <img
                src={
                  data && data[index] && data[index].coverImage
                    ? data[index].coverImage
                    : defaultImageURL
                }
                className="event-cover-image"
              />
            }
          >
            <EnhancedCol lg={14}>
              <div className="event-modal-inside">
                <div className="event-title">
                  {data && data[index] && data[index].title ? data[index].title : 'N/A'}
                </div>
                <div className="event-desc">
                  {data && data[index] && data[index].description ? data[index].description : 'N/A'}
                </div>
              </div>
            </EnhancedCol>
            <EnhancedCol lg={10}>
              <div className="event-location">
                {MapMarkerSVG('icon')}
                {data && data[index] && data[index].location ? data[index].location : 'N/A'}
              </div>
              <div className="event-date">
                <EnhancedIcon type="calendar" className="icon top-allignment" />
                {data && data[index] && data[index].start_date
                  ? data[index].start_date
                  : 'N/A'} -{' '}
                {data && data[index] && data[index].end_date ? data[index].end_date : 'N/A'}
              </div>
              <div className="event-time">
                <EnhancedIcon type="clock-circle" className="icon top-allignment" />
                {data && data[index] && data[index].start_time
                  ? data[index].start_time
                  : 'N/A'} -{' '}
                {data && data[index] && data[index].end_time ? data[index].end_time : 'N/A'}
              </div>

              {data && data[index] && data[index].zoom_link ? (
                <div className="event-time">
                  <a
                    href={
                      data && data[index] && data[index].zoom_link ? data[index].zoom_link : 'N/A'
                    }
                    target="_blank"
                  >
                    {ZoomLogo('icon')}
                    <span className="textColor"> Zoom Link </span>
                  </a>
                </div>
              ) : (
                ''
              )}
              {data && data[index] && data[index].skype_link ? (
                <div className="event-time">
                  <a
                    href={
                      data && data[index] && data[index].skype_link ? data[index].skype_link : 'N/A'
                    }
                    target="_blank"
                  >
                    {SkypeLogo('icon')}
                    <span className="textColor"> Skype Link </span>
                  </a>
                </div>
              ) : (
                ''
              )}

              {data && data[index] && data[index].visit_page_link ? (
                <div className="event-link">
                  <EnhancedButton
                    type="primary"
                    size="large"
                    target="_blank"
                    href={
                      data && data[index] && data[index].visit_page_link
                        ? data[index].visit_page_link
                        : '#'
                    }
                  >
                    Visit Event Page
                  </EnhancedButton>
                </div>
              ) : (
                ''
              )}
            </EnhancedCol>
          </EnhancedCard>
        </EnhancedModal>
      </div>
    );
  }
}

export default EventsModal;
