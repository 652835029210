export const GET_PROJECT_DETAILS_INIT = 'GET_PROJECT_DETAILS_INIT';
export const GET_PROJECT_DETAILS_SUCCESS = 'GET_PROJECT_DETAILS_SUCCESS';
export const GET_PROJECT_DETAILS_FAILURE = 'GET_PROJECT_DETAILS_FAILURE';

export const ADD_NEW_RESOURCE_PROJECT_INIT = 'ADD_NEW_RESOURCE_PROJECT_INIT';
export const ADD_NEW_RESOURCE_PROJECT_SUCCESS = 'ADD_NEW_RESOURCE_PROJECT_SUCCESS';
export const ADD_NEW_RESOURCE_PROJECT_FAILURE = 'ADD_NEW_RESOURCE_PROJECT_FAILURE';

export const DELETE_EMPLOYEE_FROM_PROJECT = 'DELETE_EMPLOYEE_FROM_PROJECT';

export const UPDATE_EMPLOYEE_IN_PROJECT = 'UPDATE_EMPLOYEES_IN_PROJECT';
