import {
  GET_GOAL_CATEGORY_GOAL_INIT,
  GET_GOAL_CATEGORY_GOAL_SUCCESS,
  GET_GOAL_CATEGORY_GOAL_FAILURE,
  GET_GOAL_CATEGORY_GOAL_DETAILS_INIT,
  GET_GOAL_CATEGORY_GOAL_DETAILS_SUCCESS,
  GET_GOAL_CATEGORY_GOAL_DETAILS_FAILURE,
} from 'constants/admin/PredefinedGoalCategoryDetailTypes';

const initialState = {
  isLoading: false,
  goalCategoryGoals: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_GOAL_CATEGORY_GOAL_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_GOAL_CATEGORY_GOAL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        goalCategoryGoals: action.data,
      };
    }
    case GET_GOAL_CATEGORY_GOAL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    }
    case GET_GOAL_CATEGORY_GOAL_DETAILS_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_GOAL_CATEGORY_GOAL_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        goalDetails: action.data,
      };
    }
    case GET_GOAL_CATEGORY_GOAL_DETAILS_FAILURE:
    default:
      return state;
  }
}
