/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { DealUrls } from 'configs/constants';

export const getDeal = (request) => {
  const url = DealUrls.GET_DEALS;
  return axios.get(url, { params: request });
};

export const getDealName = () => {
  return axios.get(DealUrls.GET_DEALS_NAME);
};

export const getDealOwner = () => {
  return axios.get(DealUrls.GET_DEALS_OWNER);
};

export const getDealStages = () => {
  return axios.get(DealUrls.GET_DEAL_STAGES);
};

export const getDealTypeCount = (request) => {
  return axios.get(DealUrls.GET_DEAL_TYPE_COUNT, { params: request });
};

export const getClient = () => {
  return axios.get(DealUrls.GET_CLIENTS);
};

export const getDealResourceStatus = () => {
  return axios.get(DealUrls.GET_DEAL_RESOURCE_STATUS);
};

export const getDealDetail = (id) => {
  const url = DealUrls.GET_DEAL_DETAIL.replace('{dealId}', id);
  return axios.get(url);
};

export const getDealRequirements = (request, id) => {
  const url = DealUrls.GET_DEAL_REQUIREMENTS.replace('{dealId}', id);
  return axios.get(url, { params: request });
};

export const syncDeal = () => {
  return axios.post(DealUrls.POST_SYNC_DEAL);
};

export const planRunner = () => {
  return axios.post(DealUrls.POST_PLAN_RUNNER);
};

export const addDealRequirements = (request, dealId) => {
  const url = DealUrls.ADD_DEAL_REQUIREMENTS.replace('{dealId}', dealId);
  return axios.post(url, { ...request });
};

export const deleteDealRequirements = (dealRequirementId) => {
  const url = DealUrls.DELETE_DEAL_REQUIREMENTS.replace('{dealRequirementId}', dealRequirementId);
  return axios.delete(url);
};

export const updateDealRequirements = (request, dealRequirementId) => {
  const url = DealUrls.UPDATE_DEAL_REQUIREMENTS.replace('{dealRequirementId}', dealRequirementId);
  return axios.put(url, { ...request });
};

export const suggestResource = (request, id) => {
  const url = DealUrls.SUGGEST_RESOURCE.replace('{dealRequirementId}', id);
  return axios.post(url, { ...request });
};

export const getDealRequirementsSuggestions = (dealRequirementId, params) => {
  const url = DealUrls.GET_DEAL_REQUIREMENTS_SUGGESTIONS.replace(
    '{dealRequirementId}',
    dealRequirementId,
  );
  return axios.get(url, { params });
};

export const getDealRequirementHistory = (dealRequirementId, params) => {
  const url = DealUrls.GET_DEAL_REQUIREMENT_HISTORY.replace(
    '{dealRequirementId}',
    dealRequirementId,
  );
  return axios.get(url, { params });
};

export const updateDealRequirementSuggestion = (suggestionId, payload) => {
  const url = DealUrls.UPDATE_DEAL_REQUIREMENT_SUGGESTION.replace('{suggestionId}', suggestionId);
  return axios.put(url, { ...payload });
};

export const getDealEvents = () => {
  return axios.get(DealUrls.GET_DEAL_EVENTS);
};

export const getDealRequirementsSuggestionOtherDeals = (suggestionId, params) => {
  const url = DealUrls.GET_DEAL_REQUIREMENT_SUGGESTION_OTHER_DEALS.replace(
    '{suggestionId}',
    suggestionId,
  );
  return axios.get(url, { params });
};

export const getDealRequirementMatchingResources = (dealRequirementId) => {
  const url = DealUrls.GET_DEAL_REQUIREMENTS_MATCHING_RESOURCES.replace(
    '{dealRequirementId}',
    dealRequirementId,
  );
  return axios.get(url);
};

export const getDealsSkillWise = (params) => {
  const url = DealUrls.GET_DEALS_SKILL_WISE;
  return axios.get(url, { params });
};
