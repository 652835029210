/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import {
  addGoalInit,
  addGoalSuccess,
  addGoallFailure,
  getGoalInit,
  getGoalSuccess,
  getGoalFailure,
  deleteGoalInit,
  deleteGoalSuccess,
  deleteGoalFailure,
  getGoalDetailsInit,
  getGoalDetailsSuccess,
  getGoalDetailsFailure,
  updateGoalInit,
  updateGoalSuccess,
  updateGoallFailure,
  submitMyGoalForApprovalInit,
  submitMyGoalForApprovalSuccess,
  submitMyGoalForApprovalFailure,
} from 'store/actions/GoalsActions';
import { goalsUrls } from 'configs/constants';

export const getGoal = (request) => (dispatch) => {
  const url = goalsUrls.GET_MY_GOALS.replace('{id}', request.id)
    .replace('{status}', request.status)
    .replace('{pageNumber}', request.pageNumber)
    .replace('{pageSize}', request.pageSize);
  dispatch(getGoalInit());
  axios
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        //   const resourceNormalized = normalizeResourceAvailability(response.data.data, date);
        dispatch(getGoalSuccess(response.data.data));
      } else {
        dispatch(getGoalFailure());
      }
    })
    .catch((error) => {
      dispatch(getGoalFailure(error));
    });
};

export const getGoalDetails = (request) => (dispatch) => {
  const url = goalsUrls.GET_GOAL_DETAILS.replace('{id}', request.id);
  dispatch(getGoalDetailsInit());
  axios
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        //   const resourceNormalized = normalizeResourceAvailability(response.data.data, date);
        dispatch(getGoalDetailsSuccess(response.data.data));
      } else {
        dispatch(getGoalDetailsFailure());
      }
    })
    .catch((error) => {
      dispatch(getGoalDetailsFailure(error));
    });
};

export const addGoal = (request) => (dispatch) => {
  const url = goalsUrls.CREATE_GOAL;
  const { payload, parameters } = request;
  dispatch(addGoalInit());
  axios
    .post(url, payload)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Created Successfully',
        });
        dispatch(addGoalSuccess(response.data));
        dispatch(getGoal(parameters));
      } else {
        EnhancedNotification.error({
          message: 'Error in creating a new goal',
        });
        dispatch(addGoallFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in creating a new goal.',
      });
      dispatch(addGoallFailure());
    });
};

export const removeGoal = (request) => (dispatch) => {
  const { payload, params, viewDetails } = request;
  const url = goalsUrls.DELETE_GOAL.replace('{gId}', payload.goalId);
  dispatch(deleteGoalInit());
  axios
    .delete(url)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Deleted Successfully',
        });
        if (viewDetails) {
          dispatch(getGoalDetails({ id: payload.goalId }));
        }
        dispatch(deleteGoalSuccess(response.data));
        dispatch(getGoal(params));
      } else {
        EnhancedNotification.error({
          message: 'Error in deleting goal.',
        });
        dispatch(deleteGoalFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({ message: error.response.message || 'Error in deleting goal.' });
      dispatch(deleteGoalFailure(error));
    });
};

export const updateGoal = (request) => (dispatch) => {
  const { id, payload, parameters, viewDetails } = request;
  const url = goalsUrls.UPDATE_GOAL.replace('{id}', id);
  dispatch(updateGoalInit());
  axios
    .put(url, payload)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Updated Successfully',
        });
        dispatch(updateGoalSuccess(response.data));
        dispatch(getGoal(parameters));
        if (viewDetails) {
          dispatch(getGoalDetails({ id }));
        }
      } else {
        EnhancedNotification.error({
          message: 'Error in updating a new goal',
        });
        dispatch(updateGoallFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in updating a new goal.',
      });
      dispatch(updateGoallFailure());
    });
};

export const submitMyGoalForApproval = (request) => (dispatch) => {
  const { goalIds, params } = request;
  const url = goalsUrls.SUBMIT_MY_GOALS_FOR_APPROVAL;
  dispatch(submitMyGoalForApprovalInit());
  axios
    .put(url, { goalIds })
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Successfully submitted for goal approval',
        });
        dispatch(submitMyGoalForApprovalSuccess());
        dispatch(getGoal(params));
      } else {
        EnhancedNotification.error({
          message: 'Error in submitting a goals for approval.',
        });
        dispatch(submitMyGoalForApprovalFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in submitting a goals for approval.',
      });
      dispatch(submitMyGoalForApprovalFailure());
    });
};
