import getEmployeeOrgTree from 'sources/EmployeeOrgTreeSource';
import {
  GET_EMPLOYEE_ORG_TREE_INIT,
  GET_EMPLOYEE_ORG_TREE_SUCCESS,
  GET_EMPLOYEE_ORG_TREE_FAILURE,
} from 'constants/EmployeeDirectoryTypes';

// normal actions
export const getEmployeeOrgTreeInit = () => ({
  type: GET_EMPLOYEE_ORG_TREE_INIT,
});

export const getEmployeeOrgTreeSuccesss = (data) => ({
  type: GET_EMPLOYEE_ORG_TREE_SUCCESS,
  data,
});

export const getEmployeeOrgTreeFailure = (data) => ({
  type: GET_EMPLOYEE_ORG_TREE_FAILURE,
  data,
});

export const getEmployeOrgTreeAction = (id) => getEmployeeOrgTree(id);
