import { connect } from 'react-redux';
import PMDashboard from 'components/admin/PMDashboard';
import { getEvaluationReportAction, getGoalsReportAction } from 'store/slices/PMDashboard';
import { getPMCycleActions } from 'store/actions/PerformanceMangementActions';
import {
  getEmployeeDepartmentsAction,
  getEmployeeCompetenciesAction,
  getEmployeeDivisionsAction,
  getEmployeeBusinessUnitAction,
} from 'store/actions/EmployeeActions';

function mapStateToProps(state) {
  const { employeeReducer, auth } = state;

  const { divisions, departments, competencies, businessUnits } = employeeReducer;

  return {
    pmCycles: state.performanceManagementReducer.pmCycles,
    goalsReport: state.pmDashboardReducer.goalsReport,
    evaluationReport: state.pmDashboardReducer.evaluationReport,
    divisions,
    departments,
    competencies,
    businessUnits,
    user: auth.user,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getGoalsReport: () => dispatch(getGoalsReportAction()),
  getEvaluationReport: (id) => dispatch(getEvaluationReportAction(id)),
  getPMCycleActions: (params) => dispatch(getPMCycleActions(params)),
  getEmployeeDivisionsAction: () => dispatch(getEmployeeDivisionsAction()),
  getEmployeeDepartmentsAction: () => dispatch(getEmployeeDepartmentsAction()),
  getEmployeeCompetenciesAction: () => dispatch(getEmployeeCompetenciesAction()),
  getEmployeeBusinessUnitAction: (req) => dispatch(getEmployeeBusinessUnitAction(req)),
});

const PMDashboardContainer = connect(mapStateToProps, mapDispatchToProps)(PMDashboard);

export default PMDashboardContainer;
