import React from 'react';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import noData from '../../../images/no-data.svg';

const CommentsTable = () => {
  const columns = [
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
    },
    {
      title: 'Creator',
      dataIndex: 'creator',
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
    },
  ];

  const locale = {
    emptyText: (
      <span className="no-data-custom-image">
        <p>
          <img src={noData} alt="no data iamge" />
        </p>
      </span>
    ),
  };

  const data = [];
  return (
    <div>
      <EnhancedClientSideTable
        locale={locale}
        columns={columns}
        data={data}
        showResetFiltersButton={false}
      />
    </div>
  );
};

export default CommentsTable;
