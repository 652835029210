import {
  EnhancedButton,
  EnhancedCol,
  EnhancedRow,
  EnhancedDivider,
  EnhancedModal,
  EnhancedSelect,
  EnhancedDatePicker,
  EnhancedInputNumber,
} from 'components/shared/antd';
import React, { Component } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { employeeTypesFiltered, dateFormat } from 'configs/constants';
import './EmployeeDetails.css';

const { Option } = EnhancedSelect;

class EmployeeProjectEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleList: props.roles || [],
      information: this.getInformationState(props),
      oldInformation: this.getInformationState(props),
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      roleList: nextProps.roles,
      information: this.getInformationState(nextProps),
      oldInformation: this.getInformationState(nextProps),
    });
  }

  onStartDateChange = (option) => {
    this.setState({
      information: {
        ...this.state.information,
        startDate: option,
      },
    });
  };

  onEndDateChange = (option) => {
    this.setState({
      information: {
        ...this.state.information,
        endDate: option,
      },
    });
  };
  onAllocationChange = (allocation) => {
    this.setState({
      information: {
        ...this.state.information,
        allocation,
      },
    });
  };

  getInformationState = (props) => {
    const {
      selectedProject: { endDate },
    } = props;
    const {
      selectedProject: { startDate },
    } = props;
    return {
      startDate: startDate !== undefined ? moment.utc(startDate) : startDate,
      endDate: endDate !== undefined ? moment.utc(endDate) : '',
      allocation: props.selectedProject.allocation,
      type: props.selectedProject.type,
      roles: props.selectedProject.role || [],
      employeeProjectId: props.selectedProject.employeeProjectId,
      employeeId: props.userId,
      projectId: props.selectedProject.projectId,
    };
  };

  roleHandleChange = (value) => {
    const information = { ...this.state.information };
    information.roles = value;
    this.setState({
      information,
    });
  };

  handleOk = () => {
    this.updateEmployeeProjects();
    this.props.onModalClose();
  };

  handleCancel = () => {
    this.props.onModalClose();
    this.setState({
      information: this.state.oldInformation,
    });
  };

  convertRolesToObjectForm = (currentRoles, previousRoles, allRolesObj) => {
    const newRolesKey = [];
    const deletedRolesKey = [];

    const newRoles = currentRoles.filter((role) => !previousRoles.includes(role));
    allRolesObj.forEach((roles) => {
      if (newRoles.includes(roles.role)) {
        newRolesKey.push(roles.id);
      }
    });
    const deletedRoles = previousRoles.filter((role) => !currentRoles.includes(role));
    allRolesObj.forEach((roles) => {
      if (deletedRoles.includes(roles.role)) {
        deletedRolesKey.push(roles.id);
      }
    });

    const updatedRolesAdd = newRolesKey.map((x) => ({
      projectRoleId: x,
      status: 1,
    }));
    const updatedRolesDelete = deletedRolesKey.map((x) => ({
      projectRoleId: x,
      status: 0,
    }));
    return [...updatedRolesAdd, ...updatedRolesDelete];
  };

  updateEmployeeProjects = () => {
    const completeRoleList = [...this.state.roleList];
    let oldRoleList = [];
    const information = { ...this.state.information };
    if (information.startDate) {
      information.startDate = information.startDate.format(dateFormat.YYYYMMDDwithHyphen);
    }
    if (information.endDate) {
      information.endDate = information.endDate.format(dateFormat.YYYYMMDDwithHyphen);
    }
    const currentRoleList = information.roles;
    oldRoleList = this.props.selectedProject.role;
    const equal =
      currentRoleList.length === oldRoleList.length &&
      currentRoleList.every((element, index) => element === oldRoleList[index]);

    if (!equal) {
      const finalRolesObject = this.convertRolesToObjectForm(
        [currentRoleList],
        oldRoleList,
        completeRoleList,
      );
      information.roles = finalRolesObject;
    } else {
      delete information.roles;
    }

    const { id } = this.props.match.params;
    information.assigneeId = parseInt(id, 10);
    const currentInformation = { ...information };
    currentInformation.currentRolesList = currentRoleList;
    delete currentInformation.roles;
    if (information.allocation === this.state.oldInformation.allocation) {
      delete information.allocation;
    }
    if (information.type === this.state.oldInformation.type) {
      delete information.type;
    }
    if (
      moment(information.startDate).format(dateFormat.YYYYMMDDwithHyphen) ===
      moment.utc(this.state.oldInformation.startDate).format(dateFormat.YYYYMMDDwithHyphen)
    ) {
      delete information.startDate;
    }
    if (
      moment(information.endDate).format(dateFormat.YYYYMMDDwithHyphen) ===
      moment(this.state.oldInformation.endDate).format(dateFormat.YYYYMMDDwithHyphen)
    ) {
      delete information.endDate;
    }
    this.props.updateEmployeesProjectActions(currentInformation, information);
  };

  typeHandleChange = (value) => {
    this.setState({
      information: {
        ...this.state.information,
        type: value,
      },
    });
  };
  disabledStartDate = (startDate) => {
    const { endDate } = this.state;
    if (!startDate || !endDate) {
      return false;
    }
    return startDate.valueOf() > endDate.valueOf();
  };

  disabledEndDate = (endDate) => {
    const { startDate } = this.state;
    if (!endDate || !startDate) {
      return false;
    }
    return endDate.valueOf() <= startDate.valueOf();
  };

  shouldButtonDisable = () => {
    const { information } = this.state;
    return !information.startDate || !information.type || !information.roles.length;
  };

  checkEndDate = (data) => {
    if (data === '-') {
      return undefined;
    }
    return data;
  };

  render() {
    const { roleList } = this.state;
    const roles = roleList.map((x) => <Option key={x.role}>{x.role}</Option>);
    const types = employeeTypesFiltered.map((currElement) => (
      <Option key={currElement} value={currElement}>
        {currElement}
      </Option>
    ));

    return (
      <div>
        <EnhancedModal
          title="Update Employee"
          visible={this.props.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <EnhancedRow className="editEmployeeProjects">
            <EnhancedCol span={6}>
              <h3>Roles</h3>
            </EnhancedCol>
            <EnhancedCol span={15}>
              <EnhancedSelect
                mode="default"
                style={{ width: '100%' }}
                placeholder="Role"
                value={this.state.information.roles}
                onChange={this.roleHandleChange}
                disabled
              >
                {roles}
              </EnhancedSelect>
            </EnhancedCol>
          </EnhancedRow>
          <EnhancedRow className="editEmployeeProjects">
            <EnhancedCol span={6}>
              <h3>Allocation</h3>
            </EnhancedCol>
            <EnhancedCol span={15}>
              <EnhancedInputNumber
                placeholder="Please Enter Allocation"
                min={0}
                max={100}
                style={{ width: '100%' }}
                formatter={(value) => (value ? `${value}%` : '')}
                value={this.state.information.allocation}
                onChange={this.onAllocationChange}
                disabled
              />
            </EnhancedCol>
          </EnhancedRow>
          <EnhancedRow className="editEmployeeProjects">
            <EnhancedCol span={6}>
              <h3>Type</h3>
            </EnhancedCol>
            <EnhancedCol span={15}>
              <EnhancedSelect
                mode="default"
                style={{ width: '100%' }}
                placeholder="Type"
                value={this.state.information.type}
                onChange={this.typeHandleChange}
                disabled
              >
                {types}
              </EnhancedSelect>
            </EnhancedCol>
          </EnhancedRow>

          <EnhancedRow className="editEmployeeProjects">
            <EnhancedCol span={6}>
              <h3>Start Date</h3>
            </EnhancedCol>
            <EnhancedCol span={15}>
              <EnhancedDatePicker
                allowClear
                disabledDate={this.disabledStartDate}
                onChange={this.onStartDateChange}
                format={dateFormat.DDMMMYYYYwithSpace}
                value={this.state.information.startDate}
                style={{ width: '100%' }}
                disabled
              />
            </EnhancedCol>
          </EnhancedRow>
          <EnhancedRow className="editEmployeeProjects">
            <EnhancedCol span={6}>
              <h3>End Date</h3>
            </EnhancedCol>
            <EnhancedCol span={15}>
              <EnhancedDatePicker
                allowClear
                disabledDate={this.disabledEndDate}
                onChange={this.onEndDateChange}
                format={dateFormat.DDMMMYYYYwithSpace}
                value={this.state.information.endDate}
                style={{ width: '100%' }}
              />
            </EnhancedCol>
          </EnhancedRow>
          <EnhancedRow>
            <EnhancedCol span={24}>
              <EnhancedDivider />
              <EnhancedButton
                style={{ float: 'right' }}
                type="primary"
                onClick={this.handleOk}
                disabled={this.shouldButtonDisable()}
              >
                Edit Resource
              </EnhancedButton>
              <EnhancedButton
                style={{ float: 'right', marginRight: 5 }}
                onClick={this.handleCancel}
              >
                Cancel
              </EnhancedButton>
            </EnhancedCol>
          </EnhancedRow>
        </EnhancedModal>
      </div>
    );
  }
}

export default withRouter(EmployeeProjectEditModal);
