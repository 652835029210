import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedForm,
} from 'components/shared/antd';
import React from 'react';
import {
  INPUT,
  SELECT,
  INFO as stateName,
  GRADES as gradeOptions,
  SUB_GRADES as subGradeOptions,
  // EMPLOYEE_INFO as propsName,
  DROP_DOWNS,
  ROW_GUTTER_SIZE,
  FULL_GRID_SIZE,
  TEXT,
  NUMBER,
  PASSWORD,
  CHECKBOX,
} from 'configs/employeeProfileConstants';
import {
  calculateBasicSalary,
  calculateMedicalAllowance,
  calculateTotalSalary,
} from 'utils/employeeProfileUtils';
import { REQUIRED, EMAIL, NUMBERS_ONLY, POSITIVE_NUMBERS_ONLY } from 'configs/validationConstants';
import { GENDERS, IPDEligibilityOptions } from 'configs/constants';
import {
  createFields,
  noData,
  // createSubHeading,
} from 'utils/FieldGenerateUtils';
import Permissions from 'constants/AccessPermissions';
import { hasPermission } from 'utils/AccessUtils';
import { subtractObject, isEmptyObject } from 'utils/objectUtils';
import { getValueForKeyinJSON } from 'utils/arrayUtils';
import EditButton from 'components/shared/employeeProfile/EditButton';

class BasicInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      [stateName]: {},
      errors: {},
      [DROP_DOWNS]: {},
      isEdit: false,
      showIpdEligibility: false,
      disableSaveButton: false,
    };
  }

  componentDidMount() {
    this.initializeValues(this.props[stateName]);
    this.initializeDropDowns(this.props[DROP_DOWNS]);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps[stateName] !== this.props[stateName]) {
      this.initializeValues(nextProps[stateName]);
      // this.initializeDropDowns(nextProps[DROP_DOWNS]);
      // TODO: This may not be needed -> Generates error state for each value in state
      // this.initializeErrors(nextProps[propsName]);
    }
  }
  initializeValues = (props) => {
    this.setState({ [stateName]: props });
    this.setState({ showIpdEligibility: props.ipdEligibility === true });
  };
  initializeDropDowns = (props) => this.setState({ dropDowns: { ...props } });

  handleEdit = () => {
    const {
      businessUnitId,
      competencyId,
      email,
      employmentStatusId,
      externalExpMonths,
      externalExpYears,
      firstName,
      genderId,
      lastName,
      location,
    } = this.state[stateName];
    // Mutate error object according to required conditions for appropiate validation
    const newErrorObject = {
      businessUnitId: !businessUnitId,
      competencyId: !competencyId,
      email: !email,
      employmentStatusId: !employmentStatusId,
      externalExpMonths: !externalExpMonths,
      externalExpYears: !externalExpYears,
      firstName: !firstName,
      genderId: !genderId,
      lastName: !lastName,
      // managerId: !managerId,
      // oracleId: !oracleId,
      location: !location,
    };

    this.setState({ isEdit: true, disableSaveButton: true, errors: newErrorObject });
  };

  handleCancel = () => {
    this.setState({
      isEdit: false,
      [stateName]: this.props[stateName],
    });
  };

  handleSave = (e) => {
    e.preventDefault();
    if (!this.state.disableSaveButton) {
      this.setState({ isEdit: false });
      let updatedObject = subtractObject(this.state[stateName], this.props[stateName]);
      //   const employeeId = this.props.match.params.id;
      if (updatedObject.ipdEligibility === false) {
        updatedObject = { ...updatedObject, ipdEligibilityOptions: null };
      }
      this.props.updateEmployeeInfo(this.props.employeeId, updatedObject);
    }
  };

  handleChange = (value, key, error) => {
    const currentData = this.state[stateName];
    const newData = Object.assign({}, currentData, { [key]: value });
    // Update specific error object and field
    let { errors } = this.state;
    errors = Object.assign({}, { ...errors }, { [key]: error });
    if (key === 'ipdEligibility') {
      this.setState({ showIpdEligibility: value });
      if (!value) {
        currentData.ipdEligibilityOptions = null;
        newData.ipdEligibilityOptions = null;
        errors.ipdEligibilityOptions = false;
      } else {
        errors.ipdEligibilityOptions = true;
      }
    }
    // Check to see if there is any error
    // if any, disable save button
    // if none, enable save button
    const allValid = Object.values(errors).every((valueError) => valueError === false);
    this.setState({ [stateName]: newData, disableSaveButton: !allValid, errors });
    if (error) {
      this.setState({ disableSaveButton: true });
    }
  };

  render() {
    const { isLoading } = this.props;
    const {
      userPermissions,
      cardLoading,
      businessUnits,
      competencies,
      employmentTypes,
      locations,
      divisions,
      designations,
      employees,
      departments,
    } = this.props;
    const { isEdit, disableSaveButton, showIpdEligibility } = this.state;
    const data = this.state[stateName];
    const dropDowns = this.props[DROP_DOWNS];
    // const errors = this.state[`${stateName}Errors`]; // TODO: This may not be needed
    const canEdit = hasPermission(userPermissions, [Permissions.Employee.Profile.Info.Update]);

    return (
      <div className="mt-20 pos-rel">
        {/* {createSubHeading('Basic Info')} */}
        {!isEmptyObject(data) ? (
          <EnhancedRow gutter={ROW_GUTTER_SIZE}>
            <EnhancedCol span={FULL_GRID_SIZE}>
              {/* If loop index is equals to current active card (card in which some action perfomed) */}
              <EnhancedCard
                loading={cardLoading && isLoading}
                bordered={false}
                className="card-pd-0"
              >
                <div>
                  <EnhancedForm>
                    <div className="flex justify-flex-end">
                      <span>
                        {canEdit && !isEdit ? (
                          <EditButton clickHandler={this.handleEdit} buttonText="Edit" />
                        ) : (
                          ''
                        )}
                      </span>
                      <span className="pr-10">
                        {canEdit && isEdit ? (
                          <EnhancedButton
                            // htmlType="submit"
                            type="primary"
                            disabled={disableSaveButton}
                            onClick={this.handleSave}
                          >
                            Save
                          </EnhancedButton>
                        ) : (
                          ''
                        )}
                      </span>
                      <span>
                        {canEdit && isEdit ? (
                          <EnhancedButton onClick={this.handleCancel}>Cancel</EnhancedButton>
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                    {createFields(
                      [
                        {
                          key: 'oracleId',
                          label: 'Emp ID',
                          value: data.oracleId,
                          type: TEXT,
                        },
                        {
                          key: 'email',
                          label: 'Email Address',
                          value: data.email,
                          type: INPUT,
                          validations: [REQUIRED, EMAIL],
                        },
                        {
                          key: 'firstName',
                          label: 'First Name',
                          value: data.firstName,
                          type: INPUT,
                          validations: [REQUIRED],
                        },
                        {
                          key: 'middleName',
                          label: 'Middle Name',
                          value: data.middleName,
                          type: INPUT,
                        },
                        {
                          key: 'lastName',
                          label: 'Last Name',
                          value: data.lastName,
                          type: INPUT,
                          validations: [REQUIRED],
                        },
                        {
                          key: 'genderId',
                          label: 'Gender',
                          options: GENDERS,
                          value: getValueForKeyinJSON(GENDERS, data.genderId),
                          type: SELECT,
                          validations: [REQUIRED],
                        },
                        {
                          key: 'joiningDate',
                          label: 'Date Of Joining',
                          value: data.joiningDate,
                          type: TEXT,
                          validations: [REQUIRED],
                        },
                        {
                          key: 'externalExpYears',
                          label: 'External Experience in Years',
                          value: data.externalExpYears,
                          type: NUMBER,
                          validations: [REQUIRED],
                          min: 0,
                        },
                        {
                          key: 'externalExpMonths',
                          label: 'External Experience in Months',
                          value: data.externalExpMonths,
                          type: NUMBER,
                          validations: [REQUIRED],
                          min: 0,
                        },
                        {
                          key: 'experience',
                          label: 'Total Experience',
                          value: data.experience.totalInWords,
                          type: TEXT,
                        },
                        {
                          key: 'designationId',
                          label: 'Job Title',
                          options: designations,
                          value: getValueForKeyinJSON(designations, data.designationId),
                          type: SELECT,
                          validations: [REQUIRED],
                        },
                        {
                          key: 'grade',
                          label: 'Grade',
                          options: gradeOptions,
                          value: getValueForKeyinJSON(gradeOptions, data.grade),
                          type: SELECT,
                          validations: [REQUIRED],
                        },
                        {
                          key: 'subGrade',
                          label: 'Sub Grade',
                          options: subGradeOptions,
                          value: getValueForKeyinJSON(subGradeOptions, data.subGrade),
                          type: SELECT,
                          validations: [REQUIRED],
                        },
                        {
                          key: 'employmentStatusId',
                          label: 'Employment Status',
                          options: dropDowns.employmentStatuses,
                          value: getValueForKeyinJSON(
                            dropDowns.employmentStatuses,
                            data.employmentStatusId,
                          ),
                          type: SELECT,
                          validations: [REQUIRED],
                        },
                        {
                          key: 'businessUnitId',
                          label: 'Business Unit',
                          options: businessUnits,
                          value: getValueForKeyinJSON(businessUnits, data.businessUnitId),
                          type: SELECT,
                          validations: [REQUIRED],
                        },
                        {
                          key: 'competencyId',
                          label: 'Competency',
                          options: competencies,
                          value: getValueForKeyinJSON(competencies, data.competencyId),
                          type: SELECT,
                          validations: [REQUIRED],
                        },
                        {
                          key: 'divisionId',
                          label: 'Division',
                          options: divisions,
                          value: getValueForKeyinJSON(divisions, data.divisionId),
                          type: SELECT,
                          validations: [REQUIRED],
                        },
                        {
                          key: 'departmentId',
                          label: 'Department',
                          options: departments,
                          value: getValueForKeyinJSON(departments, data.departmentId),
                          type: SELECT,
                          validations: [REQUIRED],
                        },
                        {
                          key: 'managerId',
                          label: 'Line Manager',
                          options: employees,
                          value:
                            getValueForKeyinJSON(employees, data.managerId) || data.managerName,
                          type: SELECT,
                          validations: [REQUIRED],
                        },
                        {
                          key: 'employmentTypeId',
                          label: 'Employment Type:',
                          options: employmentTypes,
                          value: getValueForKeyinJSON(employmentTypes, data.employmentTypeId),
                          type: SELECT,
                        },
                        {
                          key: 'location',
                          label: 'Location',
                          options: locations,
                          value: getValueForKeyinJSON(locations, data.location),
                          type: SELECT,
                          validations: [REQUIRED],
                        },
                        {
                          key: 'basicSalary',
                          label: 'Basic Salary',
                          value: calculateBasicSalary(data.grossSalary),
                          disabled: true,
                          type: PASSWORD,
                          viewPermissions: [
                            Permissions.Employee.Profile.Compensation.View,
                            Permissions.Reportee.Profile.Compensation.View,
                          ],
                        },
                        {
                          key: 'medicalAllowance',
                          label: 'Medical Allowance',
                          value: calculateMedicalAllowance(data.grossSalary),
                          disabled: true,
                          type: PASSWORD,
                          viewPermissions: [
                            Permissions.Employee.Profile.Compensation.View,
                            Permissions.Reportee.Profile.Compensation.View,
                          ],
                        },
                        {
                          key: 'grossSalary',
                          label: 'Gross Salary',
                          value: data.grossSalary,
                          type: PASSWORD,
                          validations: [REQUIRED, NUMBERS_ONLY, POSITIVE_NUMBERS_ONLY],
                          viewPermissions: [
                            Permissions.Employee.Profile.Compensation.View,
                            Permissions.Reportee.Profile.Compensation.View,
                          ],
                        },
                        {
                          key: 'inflationSupport',
                          label: 'Inflation Support',
                          value: data.inflationSupport,
                          type: PASSWORD,
                          validations: [NUMBERS_ONLY, POSITIVE_NUMBERS_ONLY],
                          viewPermissions: [
                            Permissions.Employee.Profile.Compensation.View,
                            Permissions.Reportee.Profile.Compensation.View,
                          ],
                        },
                        {
                          key: 'totalSalary',
                          label: 'Total Salary',
                          value: calculateTotalSalary(data.grossSalary, data.inflationSupport),
                          disabled: true,
                          type: PASSWORD,
                          viewPermissions: [
                            Permissions.Employee.Profile.Compensation.View,
                            Permissions.Reportee.Profile.Compensation.View,
                          ],
                        },
                        {
                          key: 'fuelAllowance',
                          label: 'Fuel Allowance',
                          value: data.fuelAllowance,
                          type: PASSWORD,
                          validations: [NUMBERS_ONLY, POSITIVE_NUMBERS_ONLY],
                          viewPermissions: [
                            Permissions.Employee.Profile.Compensation.View,
                            Permissions.Reportee.Profile.Compensation.View,
                          ],
                        },
                        {
                          key: 'opdEligibility',
                          label: 'OPD Eligibility',
                          value: data.opdEligibility,
                          type: CHECKBOX,
                          viewPermissions: [
                            Permissions.Employee.Profile.Compensation.View,
                            Permissions.Reportee.Profile.Compensation.View,
                          ],
                        },
                        {
                          key: 'ipdEligibility',
                          label: 'IPD Eligibility',
                          value: data.ipdEligibility,
                          type: CHECKBOX,
                          viewPermissions: [
                            Permissions.Employee.Profile.Compensation.View,
                            Permissions.Reportee.Profile.Compensation.View,
                          ],
                        },
                        showIpdEligibility && {
                          key: 'ipdEligibilityOptions',
                          label: 'IPD Eligibility drop down',
                          options: IPDEligibilityOptions,
                          value: getValueForKeyinJSON(
                            IPDEligibilityOptions,
                            data.ipdEligibilityOptions,
                          ),
                          type: SELECT,
                          validations: [showIpdEligibility ? REQUIRED : ''],
                          viewPermissions: [
                            Permissions.Employee.Profile.Compensation.View,
                            Permissions.Reportee.Profile.Compensation.View,
                          ],
                        },
                        {
                          key: 'transportAssistanceEligibility',
                          label: 'Transport Assistance Eligibility ',
                          value: data.transportAssistanceEligibility,
                          type: CHECKBOX,
                          viewPermissions: [
                            Permissions.Employee.Profile.Compensation.View,
                            Permissions.Reportee.Profile.Compensation.View,
                          ],
                        },
                      ],
                      2,
                      isEdit,
                      userPermissions,
                      this.handleChange,
                    )}
                  </EnhancedForm>
                </div>
              </EnhancedCard>
            </EnhancedCol>
          </EnhancedRow>
        ) : (
          noData()
        )}
      </div>
    );
  }
}

export default BasicInfo;
