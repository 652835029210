export const GET_ROLES_INIT = 'GET_ROLES_INIT';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE';

export const GET_ROLE_EMPLOYEES_INIT = 'GET_ROLE_EMPLOYEES_INIT';
export const GET_ROLE_EMPLOYEES_SUCCESS = 'GET_ROLE_EMPLOYEES_SUCCESS';
export const GET_ROLE_EMPLOYEES_FAILURE = 'GET_ROLE_EMPLOYEES_FAILURE';

export const ADD_ROLE_INIT = 'ADD_ROLE_INIT';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const ADD_ROLE_FAILURE = 'ADD_ROLE_FAILURE';

export const UPDATE_ROLE_INIT = 'UPDATE_ROLE_INIT';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE';

export const DELETE_ROLE_INIT = 'DELETE_ROLE_INIT';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';

export const GET_DDL_EMPLOYEES_INIT = 'GET_DDL_EMPLOYEES_INIT';
export const GET_DDL_EMPLOYEES_SUCCESS = 'GET_DDL_EMPLOYEES_SUCCESS';
export const GET_DDL_EMPLOYEES_FAILURE = 'GET_DDL_EMPLOYEES_FAILURE';

export const ASSIGN_ROLE_TO_EMPLOYEE_INIT = 'ASSIGN_ROLE_TO_EMPLOYEE_INIT';
export const ASSIGN_ROLE_TO_EMPLOYEE_SUCCESS = 'ASSIGN_ROLE_TO_EMPLOYEE_SUCCESS';
export const ASSIGN_ROLE_TO_EMPLOYEE_FAILURE = 'ASSIGN_ROLE_TO_EMPLOYEE_FAILURE';

export const DELETE_ROLE_FROM_EMPLOYEE_INIT = 'DELETE_ROLE_FROM_EMPLOYEE_INIT';
export const DELETE_ROLE_FROM_EMPLOYEE_SUCCESS = 'DELETE_ROLE_FROM_EMPLOYEE_SUCCESS';
export const DELETE_ROLE_FROM_EMPLOYEE_FAILURE = 'DELETE_ROLE_FROM_EMPLOYEE_FAILURE';
