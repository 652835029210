/* eslint-disable max-len */
import {
  EnhancedCol,
  EnhancedRow,
  EnhancedDivider,
  EnhancedTabs,
  EnhancedModal,
  EnhancedRate,
} from 'components/shared/antd';
import React from 'react';
import './pc.css';
import NoFeedback from './shared/NoFeedback';
import { starRatingDescription, raterRoles } from '../../configs/constants';
import AttributeTitle from './shared/AttributeTitle';

const { TabPane } = EnhancedTabs;

class RatingModalCRMC extends React.Component {
  constructor(props) {
    super(props);

    this.tabs = [
      {
        key: '1',
        title: 'Architect',
        raterRole: raterRoles.ARCH,
        showRateeDetails: false,
        showRaterDetails: true,
      },
      {
        key: '2',
        title: 'Project Manager',
        raterRole: raterRoles.PM,
        showRateeDetails: false,
        showRaterDetails: true,
      },
      {
        key: '3',
        title: 'Feedback',
        raterRole: raterRoles.RESOURCE,
        showRateeDetails: true,
        showRaterDetails: false,
      },
    ];
  }

  componentWillUnmount() {
    const { resetRatingDetails } = this.props;
    resetRatingDetails();
  }

  getAttributeRatingField = (ratingValue) => (
    <React.Fragment>
      <EnhancedRate tooltips={starRatingDescription} value={ratingValue} disabled />
      {ratingValue ? (
        <div className="ant-rate-text">{starRatingDescription[ratingValue - 1]}</div>
      ) : (
        ''
      )}
    </React.Fragment>
  );

  getAttributeCommentField = (commentValue) => (
    <p className="vd-ratings-comment">
      <b>Comment:</b> {commentValue}
    </p>
  );

  getRatingTitle = (ratingObj, tabItem) => {
    const { showRateeDetails, showRaterDetails } = tabItem;
    let response = null;

    if (showRaterDetails) {
      const { raterName } = ratingObj;
      response = <strong className="text-capitalize">{raterName}</strong>;
    } else if (showRateeDetails) {
      const { rateeRole, rateeName } = ratingObj;
      response = <strong className="text-capitalize">{`${rateeRole} - ${rateeName}`}</strong>;
    }

    return response;
  };

  getTabContent = (tabItem) => {
    const {
      ratingDetails: { data },
    } = this.props;
    const { raterRole } = tabItem;
    let tabContent = null;

    // const attributeDescription = (
    //   <div className="scrollable-popover-content">
    //     <p>1- is able to listen and understands the point of view of all team members with compassion</p>
    //     <p>2- is able to facilitate team members as per rules and policies</p>
    //     <p>3- is able to safeguard the team from counter productive factors</p>
    //     <p>4- is able to communicate with team members with respect</p>
    //     <p>5- is able to absorb pressure and protect resources in front of the client.</p>
    //     <p>1- is able to listen and understands the point of view of all team members with compassion</p>
    //     <p>2- is able to facilitate team members as per rules and policies</p>
    //     <p>3- is able to safeguard the team from counter productive factors</p>
    //     <p>4- is able to communicate with team members with respect</p>
    //     <p>5- is able to absorb pressure and protect resources in front of the client.</p>
    //   </div>
    // );

    if (data.ratings) {
      const filteredData = data.ratings.filter((rating) => rating.raterRole === raterRole);

      tabContent = filteredData.map((item) => (
        <EnhancedCol span={8} key={item.ratingId}>
          {this.getRatingTitle(item, tabItem)}

          {item.freeze ? (
            item.ratingAttributes.map((ratingAttribute) => (
              <div key={ratingAttribute.ratingAttributeId}>
                <EnhancedDivider className="vd-divider" />

                <AttributeTitle
                  title={ratingAttribute.attributeName}
                  description={ratingAttribute.attributeDescription}
                />

                {this.getAttributeRatingField(ratingAttribute.rating)}

                {this.getAttributeCommentField(ratingAttribute.comment)}
              </div>
            ))
          ) : (
            <div>
              <EnhancedDivider className="vd-divider" />

              <NoFeedback />
            </div>
          )}
        </EnhancedCol>
      ));
    }

    if (tabContent) {
      tabContent = (
        <EnhancedRow className="mx-16 my-24" gutter={16}>
          {tabContent}
        </EnhancedRow>
      );
    }
    return tabContent;
  };

  getTabs = () => {
    const response = this.tabs.map((tabItem) => (
      <TabPane tab={tabItem.title} key={tabItem.key}>
        {this.getTabContent(tabItem)}
      </TabPane>
    ));

    return response;
  };

  handleModalCancel = () => {
    this.props.onModalClose(false);
  };

  render() {
    const { resourceName } = this.props;

    return (
      <EnhancedModal
        title={resourceName}
        onCancel={this.handleModalCancel}
        okText="Save"
        visible
        afterClose={this.handleOnClose}
        footer={null}
        width="1000px"
        height="400px"
        style={{
          backgroundColor: 'white',
          overflowY: 'scroll',
          maxHeight: '80%',
          padding: '0',
        }}
        className="modal-custom"
        bodyStyle={{ padding: 0 }}
      >
        <EnhancedTabs defaultActiveKey="1">{this.getTabs()}</EnhancedTabs>
      </EnhancedModal>
    );
  }
}
export default RatingModalCRMC;
