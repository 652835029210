import {
  EnhancedRow,
  EnhancedCol,
  EnhancedDivider,
  EnhancedList,
  EnhancedListItem,
} from 'components/shared/antd';
import React, { Fragment } from 'react';

import './ListPlaceholder.css';

const { Meta } = EnhancedListItem;

const VideoPlaceHolder = (props) => (
  <Fragment>
    <EnhancedRow className="list-header">
      <EnhancedCol lg={{ span: 14 }} xs={{ span: 15 }}>
        <div className="list-title">{props.title}</div>
      </EnhancedCol>
    </EnhancedRow>
    <EnhancedRow className="list-container">
      <EnhancedList
        className="list"
        dataSource={props.data}
        locale={{ emptyText: 'No Videos' }}
        renderItem={(item, listIndex) => (
          <div className="list-element">
            <EnhancedListItem>
              <Meta
                title={<p className="list-element-title">{item.title}</p>}
                description={item.description}
              />
            </EnhancedListItem>
            {listIndex < props.data.length - 1 && (
              <EnhancedDivider className="remove-divider-margin" />
            )}
          </div>
        )}
      />
    </EnhancedRow>
  </Fragment>
);

export default VideoPlaceHolder;
