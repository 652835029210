import { getProjectDetails, addNewResource } from 'sources/ProjectEmployeesSource';

import {
  GET_PROJECT_DETAILS_INIT,
  GET_PROJECT_DETAILS_SUCCESS,
  GET_PROJECT_DETAILS_FAILURE,
  ADD_NEW_RESOURCE_PROJECT_INIT,
  ADD_NEW_RESOURCE_PROJECT_SUCCESS,
  ADD_NEW_RESOURCE_PROJECT_FAILURE,
  DELETE_EMPLOYEE_FROM_PROJECT,
  UPDATE_EMPLOYEE_IN_PROJECT,
} from 'constants/ProjectEmployeesTypes';

export const getProjectDetailsInit = () => ({
  type: GET_PROJECT_DETAILS_INIT,
});

export const getProjectDetailsSuccess = (data) => ({
  type: GET_PROJECT_DETAILS_SUCCESS,
  data,
});

export const getProjectDetailsFailure = () => ({
  type: GET_PROJECT_DETAILS_FAILURE,
});

export const getProjectDetailsActions = (projectId) => getProjectDetails(projectId);

export const addNewResourceInit = () => ({
  type: ADD_NEW_RESOURCE_PROJECT_INIT,
});

export const addNewResourceSuccess = (
  employeeProjectId,
  data,
  roles,
  employeeName,
  department,
  competency,
  location,
  taleoId,
) => ({
  type: ADD_NEW_RESOURCE_PROJECT_SUCCESS,
  employeeProjectId,
  data,
  roles,
  employeeName,
  department,
  competency,
  location,
  taleoId,
});

export const addNewResourceFailure = () => ({
  type: ADD_NEW_RESOURCE_PROJECT_FAILURE,
});

export const updateEmployeesInProject = (employeeProjectInfo, currentInformation) => ({
  type: UPDATE_EMPLOYEE_IN_PROJECT,
  employeeProjectInfo,
  currentInformation,
});

export const deleteEmployeeFromProject = (employeeProjectId) => ({
  employeeProjectId,
  type: DELETE_EMPLOYEE_FROM_PROJECT,
});

export const addNewResourceAction = (
  req,
  roles,
  employeeName,
  department,
  competency,
  location,
  taleoId,
) => addNewResource(req, roles, employeeName, department, competency, location, taleoId);
