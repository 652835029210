import { getReport, getReportWithFormat } from 'sources/ReportSource';
import {
  GET_REPORT_INIT,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAILURE,
  GET_REPORT_WITH_FORMAT_INIT,
  GET_REPORT_WITH_FORMAT_FAILURE,
  GET_REPORT_WITH_FORMAT_SUCCESS,
} from 'constants/ReportTypes';

// normal actions
export const getReportInit = () => ({
  type: GET_REPORT_INIT,
});

export const getReportSuccess = (data) => ({
  type: GET_REPORT_SUCCESS,
  data,
});

export const getReportFailure = (data) => ({
  type: GET_REPORT_FAILURE,
  data,
});

export const getReportWithFormatInit = () => ({
  type: GET_REPORT_WITH_FORMAT_INIT,
});

export const getReportWithFormatSuccess = (data) => ({
  type: GET_REPORT_WITH_FORMAT_SUCCESS,
  data,
});

export const getReportWithFormatFailure = (data) => ({
  type: GET_REPORT_WITH_FORMAT_FAILURE,
  data,
});

// Async Action
export const getReportAction = (startDate, endDate) => getReport(startDate, endDate);
export const getReportWithFormatAction = (startDate, endDate) =>
  getReportWithFormat(startDate, endDate, 'csv');
