import {
  GET_DESIGNATION_INIT,
  GET_DESIGNATION_SUCCESS,
  GET_DESIGNATION_FAILURE,
  CREATE_DESIGNATION_INIT,
  CREATE_DESIGNATION_SUCCESS,
  CREATE_DESIGNATION_FAILURE,
  UPDATE_DESIGNATION_INIT,
  UPDATE_DESIGNATION_SUCCESS,
  UPDATE_DESIGNATION_FAILURE,
  DELETE_DESIGNATION_INIT,
  DELETE_DESIGNATION_SUCCESS,
  DELETE_DESIGNATION_FAILURE,
} from 'constants/DesignationTypes';

const initialState = {
  designations: [],
  isLoading: false,
};

export default function designationsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DESIGNATION_INIT:
      return {
        ...state,
        isLoading: true,
        designations: [],
      };
    case GET_DESIGNATION_SUCCESS:
      return {
        ...state,
        designations: [...action.data],
        isLoading: false,
      };
    case GET_DESIGNATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        designations: [],
      };
    case CREATE_DESIGNATION_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_DESIGNATION_SUCCESS:
      return {
        ...state,
        designations: [...state.designations, action.data],
        isLoading: false,
      };
    case CREATE_DESIGNATION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_DESIGNATION_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_DESIGNATION_SUCCESS: {
      const id = action.data;
      const { designations } = state;
      const filteredDesignations = designations.filter((elem) => elem.id !== id);
      return {
        ...state,
        isLoading: false,
        designations: filteredDesignations,
      };
    }
    case DELETE_DESIGNATION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_DESIGNATION_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_DESIGNATION_SUCCESS: {
      const { designations } = state;
      const filteredDesignations = designations.map((designation) => {
        if (designation.id === action.data.id) {
          return action.data;
        }
        return designation;
      });
      return {
        ...state,
        isLoading: false,
        designations: filteredDesignations,
      };
    }
    case UPDATE_DESIGNATION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
