export const APP_VERSION = '8.0.4';

export const GENESIS_DATE = '2018-03-01';

export const API_URL = process.env.REACT_APP_API_URL;

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const GA_API = process.env.REACT_APP_GA_API;
export const ENKEYRIPT = process.env.REACT_APP_ENKEYRIPT;
export const FEEDBACK_REDIRECT = process.env.REACT_APP_FEEDBACK_REDIRECT_VEND360;
export const APPSMITH_DEAL_SUMMARY = process.env.REACT_APP_APPSMITH_DEAL_SUMMARY;
// export const APPSMITH_REPORT_RESOURCE_DEFICIT =
//   process.env.REACT_APP_APPSMITH_REPORT_RESOURCE_DEFICIT;
export const APPSMITH_REPORT_PLANNING_SUMMARY =
  process.env.REACT_APP_APPSMITH_REPORT_PLANNING_SUMMARY;

export const appSmithNavigationPath = [
  {
    key: '/resourcePlanner/dealSummary',
    path: APPSMITH_DEAL_SUMMARY,
  },
  // {
  //   key: '/resourcePlanner/reports/resource-deficit',
  //   path: APPSMITH_REPORT_RESOURCE_DEFICIT,
  // },
  {
    key: '/resourcePlanner/reports/planning-summary',
    path: APPSMITH_REPORT_PLANNING_SUMMARY,
  },
];

export const workLogUrls = {
  GET: `${API_URL}/employees/{employeeId}/worklogs`,
  CREATE: `${API_URL}/employees/{employeeId}/worklogs`,
  UPDATE: `${API_URL}/employees/{employeeId}/worklogs/{workId}`,
};

export const workLogProjectListUrls = {
  GET: `${API_URL}/worklogs/projects`,
};

export const projectsUrls = {
  GET: `${API_URL}/admin/projects`,
  CREATE: `${API_URL}/admin/projects`,
  PUT: `${API_URL}/admin/projects/{projectId}`,
  ACTIVATE_PROJECT: `${API_URL}/admin/projects/{{projectId}}/activate`,
};

export const performanceCycleUrls = {
  GET: `${API_URL}/performance_cycle/summary`,
  GET_CRMC_CYCLE: `${API_URL}/crmc/performance_cycle/summary`,
  GET_PROJECT_BY_CYCLE_ID: `${API_URL}/performance_cycle/summary/{cycleId}`,
  GET_PROJECT_BY_CYCLE_ID_CRMC: `${API_URL}/crmc/performance_cycle/summary/{cycleId}`,
  GET_PROJECT_RATING: `${API_URL}/project-cycle/{projectCycleId}/project/{projectId}`,
  GET_CRMC_PANEL_PROJECT_DATA: `${API_URL}/crmc/project-cycle/{projectCycleId}/project/{projectId}`,
  CREATE: `${API_URL}/performance_cycle/summary/employee/{employeeId}`,
  PUT: `${API_URL}/performance_cycles/rating`,
  POST: `${API_URL}/performance_cycles`,
  POST_PROJECT_RATING: `${API_URL}/project-employee-rating`,
  DELETE: `${API_URL}/employee_rating`,
  DELETE_PROJECT_CYCLE: `${API_URL}/project-cycle`,
  COMPLETE: `${API_URL}/performance_cycles/{performanceCycleId}/complete`,
  ENABLE_RATING_AFTER_DUE_DATE: `${API_URL}/performance_cycles/project_cycle/rating`,
  EXTEND_PROJECT_CYCLE_DUE_DATES: `${API_URL}/performance_cycles/project_cycles/extend`,
  UPDATE_PERFORMANCE_CYCLE: `${API_URL}/performance_cycles`,
  GET_RATING_DETAILS_CRMC: `${API_URL}/crmc/performance_cycles/rating/{performanceCycleId}/{projectId}/{resourceId}`,
  GET_RATING_DETAILS: `${API_URL}/performance_cycles/rating`,
  GET_PROJECT_SCORE: `${API_URL}/performance_cycle/project_score/{performanceCycleId}/{projectId}`,
};

export const projectReviewsCycleUrls = {
  GET_PROJECT_REVIEWS_CYCLE: `${API_URL}/crmc/performance_cycle/summary`,
  GET_PROJECT_REVIEWS_BY_CYCLE_ID: `${API_URL}/performance_cycle/project_reporting/{cycleId}`,
  GET_PROJECT_REVIEWS_PROJECT_DATA: `${API_URL}/performance_cycle/project_reporting/{projectCycleId}/project/{projectId}`,
  GET_PROJECT_REVIEWS_RATING_DATA: `${API_URL}/performance_cycles/rating`,
};

export const employeeSearchUrls = {
  GET: `${API_URL}/employees/search`,
};

export const employeeOrgTreeUrls = {
  GET: `${API_URL}/employees`,
};

export const projectDetailsUrls = {
  GET: `${API_URL}/employee/project/{projectId}`,
};

export const AddNewResourceProjectUrls = {
  POST: `${API_URL}/employee/project/`,
};

export const rcaUrls = {
  EMPLOYEES: {
    SUMMARY: `${API_URL}/rca/employees`,
    DETAILS: `${API_URL}/rca/employees/{employeeId}`,
  },
  PROJECTS: {
    SUMMARY: `${API_URL}/rca/projects`,
    DETAILS: `${API_URL}/rca/projects/{projectId}`,
  },
};

export const employeesUrls = {
  GET: `${API_URL}/employees`,
  PUT: `${API_URL}/employees`,
  POST: `${API_URL}/employees`,
  GET_ALL: `${API_URL}/employees/all`,
  DELETE: `${API_URL}/employees/{employeeId}`,
  GET_FAMILY_INFO: `${API_URL}/employees/family-members?status={status}`,
};

export const employeeProfileUrls = {
  PROFILE_PICTURE: {
    UPDATE: `${API_URL}/management/employees/{employeeId}/photo`,
  },
  PROFILE_HEADER: {
    GET: `${API_URL}/management/employees/{employeeId}/profile-header`,
  },
  INFO: {
    GET: `${API_URL}/management/employees/{employeeId}/info`,
    UPDATE: `${API_URL}/management/employees/{employeeId}/info`,
    FAMILY: {
      CREATE: `${API_URL}/management/employees/{employeeId}/family-info`,
      UPDATE: `${API_URL}/management/employees/{employeeId}/family-info/{familyInfoId}`,
      DELETE: `${API_URL}/management/employees/{employeeId}/family-info/{familyInfoId}`,
    },
  },
  JOB_INFO: {
    GET: `${API_URL}/management/employees/{employeeId}/jobs/all`,
    UPDATE: `${API_URL}/management/employees/{employeeId}/jobs/{jobInfoId}`,
    DELETE: `${API_URL}/management/employees/{employeeId}/jobs/{jobInfoId}`,
    CREATE: `${API_URL}/management/employees/{employeeId}/jobs`,
  },
  TRACKING: {
    GET: `${API_URL}/management/employees/{employeeId}/tracking`,
    UPDATE: `${API_URL}/management/employees/{employeeId}/tracking`,
  },
  COMPENSATION: {
    GET: `${API_URL}/management/employees/{employeeId}/compensation`,
    CREATE: `${API_URL}/management/employees/{employeeId}/compensation`,
    UPDATE: `${API_URL}/management/employees/{employeeId}/compensation/{compensationId}`,
  },
  TALENT: {
    GET: `${API_URL}/management/employees/{employeeId}/talent`,
    UPDATE: `${API_URL}/management/employees/{employeeId}/talent`,
    CAREER_PLANNING: {
      UPDATE: `${API_URL}/management/employees/{employeeId}/career-planning`,
    },

    CERTIFICATES: {
      CREATE: `${API_URL}/management/employees/{employeeId}/certificates`,
      UPDATE: `${API_URL}/management/employees/{employeeId}/certificates/{certificateId}`,
      DELETE: `${API_URL}/management/employees/{employeeId}/certificates/{certificateId}`,
    },
  },
  EMPLOYMENT_HISTORY: {
    GET: `${API_URL}/management/employees/{employeeId}/employments`,
    CREATE: `${API_URL}/management/employees/{employeeId}/employments`,
    UPDATE: `${API_URL}/management/employees/{employeeId}/employments/{employmentId}`,
    DELETE: `${API_URL}/management/employees/{employeeId}/employments/{employmentId}`,
  },
  EMPLOYEE_CERTIFICATES: {
    GET: `${API_URL}/management/employees/{employeeId}/certificates`,
    CREATE: `${API_URL}/certificates/{employeeId}/employments`,
    UPDATE: `${API_URL}/certificates/{employeeId}/certificates/{certificateId}`,
    DELETE: `${API_URL}/certificates/{employeeId}/certificates/{certificateId}`,
  },
  EDUCATION_HISTORY: {
    GET: `${API_URL}/management/employees/{employeeId}/educations`,
    UPDATE: `${API_URL}/management/employees/{employeeId}/educations/{educationId}`,
    CREATE: `${API_URL}/management/employees/{employeeId}/educations`,
    DELETE: `${API_URL}/management/employees/{employeeId}/educations/{educationId}`,
  },
  EMPLOYEE_DOCUMENTS: {
    DELETE: `${API_URL}/management/employees/{employeeId}/documents/{documentId}`,
    CREATE: `${API_URL}/management/employees/{employeeId}/documents`,
    GET: `${API_URL}/management/employees/{employeeId}/documents/{documentId}`,
    GET_ALL: `${API_URL}/management/employees/{employeeId}/documents`,
    UPDATE: `${API_URL}/management/employees/{employeeId}/documents/{documentId}`,
  },
  COMMENTS: {
    GET: `${API_URL}/management/employees/{employeeId}/comments`,
    GET_ATTACHMENT: `${API_URL}/management/employees/{employeeId}/comments/{commentId}/attachment`,
    CREATE: `${API_URL}/management/employees/{employeeId}/comments`,
    DELETE: `${API_URL}/management/employees/{employeeId}/comments/{commentId}`,
  },
  AUDIT_LOGS: {
    GET: `${API_URL}/management/employees/{employeeId}/audit-logs`,
  },
  EMERGENCY_CONTACT: {
    CREATE: `${API_URL}/management/employees/{employeeId}/emergency-contact`,
    UPDATE: `${API_URL}/management/employees/{employeeId}/emergency-contact/{emergencyContactId}`,
    DELETE: `${API_URL}/management/employees/{employeeId}/emergency-contact/{emergencyContactId}`,
  },
  PAY_FREQUENCIES: {
    GET: `${API_URL}/pay-frequencies`,
  },
  BENEFITS: {
    GET: `${API_URL}/benefits`,
  },
  JOBS_LISTING: {
    GET: `${API_URL}/management/employees/{employeeId}/jobs`,
  },
  REASONS: {
    GET: `${API_URL}/reasons`,
  },
  GET_MY_PERFORMANCE_REVIEWS: `${API_URL}/employees/me/goals?status={status}&page={pageNumber}&size={pageSize}`,
};

export const myProfileUrls = {
  PROFILE_PICTURE: {
    UPDATE: `${API_URL}/employees/{employeeId}/photo`,
  },
  PROFILE_HEADER: {
    GET: `${API_URL}/employees/{employeeId}/profile-header`,
  },
  INFO: {
    GET: `${API_URL}/employees/{employeeId}/info`,
    UPDATE: `${API_URL}/employees/{employeeId}/info`,
    FAMILY: {
      CREATE: `${API_URL}/employees/{employeeId}/family-info`,
      UPDATE: `${API_URL}/employees/{employeeId}/family-info/{familyInfoId}`,
      DELETE: `${API_URL}/employees/{employeeId}/family-info/{familyInfoId}`,
    },
  },
  JOB_INFO: {
    GET: `${API_URL}/employees/{employeeId}/jobs/all`,
    UPDATE: `${API_URL}/employees/{employeeId}/jobs/{jobInfoId}`,
    DELETE: `${API_URL}/employees/{employeeId}/jobs/{jobInfoId}`,
    CREATE: `${API_URL}/employees/{employeeId}/jobs`,
  },
  TRACKING: {
    GET: `${API_URL}/employees/{employeeId}/tracking`,
    UPDATE: `${API_URL}/employees/{employeeId}/tracking`,
  },
  COMPENSATION: {
    GET: `${API_URL}/employees/{employeeId}/compensation`,
    CREATE: `${API_URL}/employees/{employeeId}/compensation`,
    UPDATE: `${API_URL}/employees/{employeeId}/compensation/{compensationId}`,
  },
  TALENT: {
    GET: `${API_URL}/employees/{employeeId}/talent`,
    UPDATE: `${API_URL}/employees/{employeeId}/talent`,
    CAREER_PLANNING: {
      UPDATE: `${API_URL}/employees/{employeeId}/career-planning`,
    },

    CERTIFICATES: {
      CREATE: `${API_URL}/employees/{employeeId}/certificates`,
      UPDATE: `${API_URL}/employees/{employeeId}/certificates/{certificateId}`,
      DELETE: `${API_URL}/employees/{employeeId}/certificates/{certificateId}`,
    },
  },
  EMPLOYMENT_HISTORY: {
    GET: `${API_URL}/employees/{employeeId}/employments`,
    CREATE: `${API_URL}/employees/{employeeId}/employments`,
    UPDATE: `${API_URL}/employees/{employeeId}/employments/{employmentId}`,
    DELETE: `${API_URL}/employees/{employeeId}/employments/{employmentId}`,
  },
  EMPLOYEE_CERTIFICATES: {
    GET: `${API_URL}/employees/{employeeId}/certificates`,
    CREATE: `${API_URL}/certificates/{employeeId}/employments`,
    UPDATE: `${API_URL}/certificates/{employeeId}/certificates/{certificateId}`,
    DELETE: `${API_URL}/certificates/{employeeId}/certificates/{certificateId}`,
  },
  EDUCATION_HISTORY: {
    GET: `${API_URL}/employees/{employeeId}/educations`,
    UPDATE: `${API_URL}/employees/{employeeId}/educations/{educationId}`,
    CREATE: `${API_URL}/employees/{employeeId}/educations`,
    DELETE: `${API_URL}/employees/{employeeId}/educations/{educationId}`,
  },
  EMPLOYEE_DOCUMENTS: {
    DELETE: `${API_URL}/employees/{employeeId}/documents/{documentId}`,
    CREATE: `${API_URL}/employees/{employeeId}/documents`,
    GET: `${API_URL}/employees/{employeeId}/documents/{documentId}`,
    GET_ALL: `${API_URL}/employees/{employeeId}/documents`,
  },
  COMMENTS: {
    GET: `${API_URL}/employees/{employeeId}/comments`,
    GET_ATTACHMENT: `${API_URL}/employees/{employeeId}/comments/{commentId}/attachment`,
    CREATE: `${API_URL}/employees/{employeeId}/comments`,
    DELETE: `${API_URL}/employees/{employeeId}/comments/{commentId}`,
  },
  AUDIT_LOGS: {
    GET: `${API_URL}/employees/{employeeId}/audit-logs`,
  },
  EMERGENCY_CONTACT: {
    CREATE: `${API_URL}/employees/{employeeId}/emergency-contact`,
    UPDATE: `${API_URL}/employees/{employeeId}/emergency-contact/{emergencyContactId}`,
    DELETE: `${API_URL}/employees/{employeeId}/emergency-contact/{emergencyContactId}`,
  },
  PAY_FREQUENCIES: {
    GET: `${API_URL}/pay-frequencies`,
  },
  BENEFITS: {
    GET: `${API_URL}/benefits`,
  },
  JOBS_LISTING: {
    GET: `${API_URL}/employees/{employeeId}/jobs`,
  },
  REASONS: {
    GET: `${API_URL}/reasons`,
  },
};

export const designationUrls = {
  GET: `${API_URL}/designations`,
  POST: `${API_URL}/designations`,
  PUT: `${API_URL}/designations/{designationId}`,
  DELETE: `${API_URL}/designations/{designationId}`,
};

export const locationUrls = {
  GET: `${API_URL}/locations`,
  POST: `${API_URL}/locations`,
  PUT: `${API_URL}/locations/{locationId}`,
  DELETE: `${API_URL}/locations/{locationId}`,
};

export const transientResourceUrls = {
  GET: `${API_URL}/resourcePlanner/resource`,
  POST: `${API_URL}/resourcePlanner/resource`,
  PUT: `${API_URL}/resourcePlanner/resource/{resourceId}`,
  DELETE: `${API_URL}/resourcePlanner/resource/{resourceId}`,
};

export const benchResourceUrls = {
  POST: `${API_URL}/resourcePlanner/reports/bench/resources`,
};

export const resourceDeficitUrls = {
  POST: `${API_URL}/resourcePlanner/reports/resourceDeficit`,
};

export const employeesBirthdayUrls = {
  PUT: `${API_URL}/employees/{employeeId}/birthday`,
  GET: `${API_URL}/employees/birthday`,
};

export const userAttrUrls = {
  GET: `${API_URL}/employees/{employeeId}/attributes`,
};

export const homepageNewsUrl = {
  GET: `${API_URL}/wp/news`,
};

export const homepageEventsUrl = {
  GET: `${API_URL}/wp/events`,
};

export const homepageYoutubeUrl = {
  GET: `${API_URL}/wp/youtube/links`,
};

export const homepageCategoryUrl = {
  GET: `${API_URL}/wp/categories`,
};

export const homepageMediaUrl = {
  GET: `${API_URL}/wp/media`,
};

export const homepageVideoUrl = {
  GET: `${API_URL}/wp/video`,
};

export const activityLogUrls = {
  GET: `${API_URL}/logs/{projectId}/page/{pageNumber}`,
};
export const skillsUrls = {
  GET: `${API_URL}/skills`,
  POST: `${API_URL}/skills`,
  PUT: `${API_URL}/skills`,
  DELETE: `${API_URL}/skills`,
};

export const uploadIncrementsBulkUrl = {
  PUT: `${API_URL}/management/employees/bulk-update`,
};

export const departmentUrls = {
  GET: `${API_URL}/departments`,
  POST: `${API_URL}/departments`,
  PUT: `${API_URL}/departments/{departmentId}`,
  DELETE: `${API_URL}/departments/{departmentId}`,
};

export const divisionUrls = {
  GET: `${API_URL}/divisions`,
  POST: `${API_URL}/divisions`,
  PUT: `${API_URL}/divisions/{divisionId}`,
  DELETE: `${API_URL}/divisions/{divisionId}`,
};
export const competencyUrls = {
  GET: `${API_URL}/competencies`,
  POST: `${API_URL}/competencies`,
  PUT: `${API_URL}/competencies/{competencyId}`,
  DELETE: `${API_URL}/competencies/{competencyId}`,
};

export const businessUnitUrls = {
  GET: `${API_URL}/businessUnits`,
  POST: `${API_URL}/businessUnits`,
  PUT: `${API_URL}/businessUnits/{businessUnitId}`,
  DELETE: `${API_URL}/businessUnits/{businessUnitId}`,
};

export const projectRoleUrls = {
  GET: `${API_URL}/project_roles`,
  POST: `${API_URL}/project_roles`,
  PUT: `${API_URL}/project_roles/{id}`,
  DELETE: `${API_URL}/project_roles/{id}`,
};

export const dashboardUrls = {
  GET: `${API_URL}/crmc/employees/allocations/reports`,
};

export const employeesProjectUrls = {
  GET: `${API_URL}/employee/{employeeId}/project`,
  DEALLOCATE: `${API_URL}/employee/{employeeId}/project/{employeeProjectId}/allocation`,
  DELETE: `${API_URL}/employee/{employeeId}/project/{employeeProjectId}`,
  PUT: `${API_URL}/employee/project`,
};
export const employeesProjectRolesUrls = {
  GET: `${API_URL}/projects/roles`,
};
export const leaveTypeUrls = {
  GET: `${API_URL}/leave-types`,
};

export const teamLeavesUrls = {
  GET: `${API_URL}/leaves/team/{attributeId}`,
  PUT: `${API_URL}/leave/{leaveId}/status`,
  POST: `${API_URL}/leaves/approve`,
};

export const leaveCalendarUrls = {
  GET: `${API_URL}/leaves/calendar`,
};

export const benchLeavesUrls = {
  GET: `${API_URL}/leaves/bench`,
};

export const teamLeavesAsPMUrls = {
  PUT: `${API_URL}/leave/{leaveId}/pmstatus`,
};

export const teamLeavesAsHRUrls = {
  PUT: `${API_URL}/leave/{leaveId}/hrstatus`,
};

export const PublicHolidaysUrls = {
  GET: `${API_URL}/leaves/holidays`,
};

export const REPORT_URL = `${API_URL}/admin/reports`;

export const defaultersUrls = {
  GET: `${REPORT_URL}/defaulters`,
};

export const leavesStatusesUrls = {
  GET: `${API_URL}/leave-statuses`,
};

export const leavesDetailsUrl = {
  GET: `${API_URL}/leaves/details/{employeeId}`,
};

export const myLeaves = {
  GET: `${API_URL}/leaves/employee/{employeeId}`,
  POST: `${API_URL}/leave`,
  PUT: `${API_URL}/leave`,
  DELETE: `${API_URL}/leave`,
  TOTAL_COUNT: `${API_URL}/leaves/working-days`,
};

export const leaveDashboardUrls = {
  GET: `${API_URL}/leaves/quota/{attributeId}`,
};

export const HRleavesDashboardUrls = {
  GET: `${API_URL}/company-wide/dashboard`,
  GET_LOGS: `${API_URL}/leaves-adjustment-logs`,
  POST: `${API_URL}/adjust-leaves-quota`,
};

export const resourceAvailabilityUrls = {
  GET: `${API_URL}/crmc/data/employee`,
};

export const employeeRatingUrls = {
  GET: `${API_URL}/employees/rating/{:employeeId}`,
  GET_LIST: `${API_URL}/employees/rating`,
  DOWNLOAD_ALL_RATINGS: `${API_URL}/employees/rating/download`,
  DOWNLOAD_EMPLOYEE_RATINGS: `${API_URL}/employees/rating/{:employeeId}/download`,
  GET_RATINGS_SUMMARY: `${API_URL}/employees/ratingSummary/{:employeeId}`,
};
export const roleUrls = {
  GET: `${API_URL}/roles`,
  POST: `${API_URL}/role`,
  PUT: `${API_URL}/role`,
  DELETE: `${API_URL}/role/{:id}`,
};

export const roleEmployeesURls = {
  GET: `${API_URL}/role/{:id}`,
};

export const employeeDropDownUrls = {
  GET: `${API_URL}/employees/dropdown`,
  MARITAL_STATUSES: {
    GET: `${API_URL}/marital-statuses`,
  },
  EMPLOYMENT_STATUSES: {
    GET: `${API_URL}/employment-statuses`,
  },
  BLOOD_GROUPS: {
    GET: `${API_URL}/bloodgroups`,
  },
  RELIGIONS: {
    GET: `${API_URL}/religions`,
  },
  QUALIFICATIONS: {
    GET: `${API_URL}/qualifications`,
  },
};

export const miscellaneousUrls = {
  DIVISIONS: {
    GET: `${API_URL}/divisions`,
  },
  DESIGNATIONS: {
    GET: `${API_URL}/designations`,
  },
  EMPLOYMENT_TYPES: {
    GET: `${API_URL}/employment-types`,
  },
  JOB_DESCRIPTION_DOCUMENT: {
    GET: `${API_URL}/job-descriptions/{jobDescriptionId}`,
  },
};

export const employmentTypesUrls = {
  GET: `${API_URL}/employment-types`,
  POST: `${API_URL}/employment-types`,
  PUT: `${API_URL}/employment-types/{:id}`,
  DELETE: `${API_URL}/employment-types/{:id}`,
};

export const assignRoleToEmployeeUrls = {
  POST: `${API_URL}/role/employee`,
  DELETE: `${API_URL}/role/employee/{:id}`,
};

export const claimURLs = {
  CLAIM_PROJECT_LIST: `${API_URL}/claim/projects`,
  ADD_CLAIM_REQUEST: `${API_URL}/claims`,
  GET_CLAIM_REQUESTS: `${API_URL}/claims/me`,
  CLAIM_TYPES: `${API_URL}/claim-types`,
  CLAIM_IMAGES: `${API_URL}/claim/{:id}/images`,
  TEAM_CLAIMS: `${API_URL}/claims`,
  CLAIMS_FOR_FINANCE: `${API_URL}/claims/finance`,
  CLAIMS_FOR_HR: `${API_URL}/claims/hr`,
  CLAIM_APPROVAL: `${API_URL}/claim/approval`,
  CLAIM_APPROVED: `${API_URL}/claims/{:claimRequestId}/approvers`,
  CLAIM_DECLINED: `${API_URL}/claims/{:claimRequestId}/approvers`,
  CLAIM_EXPORT: `${API_URL}/claims/download`,
};

export const loggerAreas = ['not_set', 'dev', 'qa', 'architect', 'design', 'manager', 'marketing'];
export const LOCATIONS = [
  'Not Set',
  'Karachi',
  'Lahore',
  'Mountain View',
  'Dubai',
  'London',
  'Remote',
];
export const LOCATIONS_WITH_KEY = [
  { key: 0, value: 'Not Set' },
  { key: 1, value: 'Karachi' },
  { key: 3, value: 'Lahore' },
  { key: 4, value: 'Mountain View' },
  { key: 5, value: 'Dubai' },
  { key: 6, value: 'London' },
  { key: 7, value: 'Remote' },
];
export const interviewUrls = {
  IINTERVIEWS: `${API_URL}/interviews`,
  TRACKS: `${API_URL}/tracks`,
  QUESTIONS: `${API_URL}/questions`,
  UPLOAD_QUESTIONS_BY_FILE: `${API_URL}/questions-file`,
  QUESTIONSFREQUENCYPERTRACK: `${API_URL}/questions-frequency?type=per_track`,
  QUESTIONSFREQUENCYPERCLIENTANDTRACK: `${API_URL}/questions-frequency?type=per_client_and_track`,
};

export const reporteesUrls = {
  GET_ALL_REPORTEES: `${API_URL}/employees/reportees`,
};

export const goalsUrls = {
  CREATE_GOAL: `${API_URL}/employees/goals`,
  GET_MY_GOALS: `${API_URL}/employees/me/goals?status={status}&page={pageNumber}&size={pageSize}`,
  GET_GOAL_DETAILS: `${API_URL}/employees/me/goalDetails/{id}`,
  DELETE_GOAL: `${API_URL}/employees/me/goals/{gId}`,
  UPDATE_GOAL: `${API_URL}/employees/me/goals/{id}`,
  GOAL_MARK_AS_COMPLETED: `${API_URL}/management/employees/markcompleted/goals/{gId}`,
  GOAL_MARK_AS_FINAL: `${API_URL}/management/employees/markfinal/goals`,
  SUBMIT_MY_GOALS_FOR_APPROVAL: `${API_URL}/employees/me/submit/goals`,
  GET_GOALS_AS_LM: `${API_URL}/management/employees/{empId}/goals?status={status}&page={pageNumber}&size={pageSize}`,
  GET_GOAL_DETAILS_AS_LM: `${API_URL}/management/employees/{empId}/goalDetails/{gId}`,
  UPDATE_GOAL_AS_LM: `${API_URL}/management/employees/{empId}/goals/{gId}`,
  DELETE_GOAL_AS_LM: `${API_URL}/management/employees/{empId}/goals/{gId}`,
  APPROVE_GOAL: `${API_URL}/management/employees/goals/approval`,
  // APPROVE_GOAL_AS_HR: `${API_URL}/management/employees/{empId}/goals/{gId}/hrapproval`,
};

export const DealUrls = {
  GET_DEALS: `${API_URL}/resourcePlanner/deals`,
  GET_DEALS_NAME: `${API_URL}/resourcePlanner/deals-name`,
  GET_DEALS_OWNER: `${API_URL}/resourcePlanner/deal-owners`,
  GET_DEAL_STAGES: `${API_URL}/resourcePlanner/deal-stages`,
  GET_DEAL_TYPE_COUNT: `${API_URL}/resourcePlanner/deals-type-count`,
  GET_CLIENTS: `${API_URL}/resourcePlanner/clients`,
  GET_DEAL_RESOURCE_STATUS: `${API_URL}/resourcePlanner/deal-resource-status`,
  GET_DEAL_DETAIL: `${API_URL}/resourcePlanner/deal/{dealId}`,
  POST_SYNC_DEAL: `${API_URL}/resourcePlanner/sync`,
  POST_PLAN_RUNNER: `${API_URL}/resourcePlanner/plan`,
  GET_DEAL_REQUIREMENTS: `${API_URL}/resourcePlanner/deal/{dealId}/requirements`,
  ADD_DEAL_REQUIREMENTS: `${API_URL}/resourcePlanner/deal/{dealId}/requirements`,
  DELETE_DEAL_REQUIREMENTS: `${API_URL}/resourcePlanner/deal/requirements/{dealRequirementId}`,
  UPDATE_DEAL_REQUIREMENTS: `${API_URL}/resourcePlanner/deal/requirements/{dealRequirementId}`,
  SUGGEST_RESOURCE: `${API_URL}/resourcePlanner/deal/requirements/{dealRequirementId}/suggestions`,
  GET_DEAL_REQUIREMENTS_SUGGESTIONS: `${API_URL}/resourcePlanner/deal/requirements/{dealRequirementId}/suggestions`,
  GET_DEAL_REQUIREMENT_HISTORY: `${API_URL}/resourcePlanner/deal/requirements/suggestions/{dealRequirementId}/logs`,
  GET_DEAL_REQUIREMENTS_MATCHING_RESOURCES: `${API_URL}/resourcePlanner/deal/requirements/{dealRequirementId}/matching-resources`,
  UPDATE_DEAL_REQUIREMENT_SUGGESTION: `${API_URL}/resourcePlanner/deal/requirements/suggestions/{suggestionId}`,
  GET_DEAL_REQUIREMENT_SUGGESTION_OTHER_DEALS: `${API_URL}/resourcePlanner/deal/requirements/suggestions/{suggestionId}/other-deals`,
  GET_DEAL_EVENTS: `${API_URL}/resourcePlanner/deal-events`,
  GET_DEALS_SKILL_WISE: `${API_URL}/resourcePlanner/deals/skill-wise`,
};

export const performanceReviewUrls = {
  GET_MY_PERFORMANCE_REVIEWS: `${API_URL}/employees/me/performanceCycles?status={status}&page={pageNumber}&size={pageSize}`,
  GET_PERFORMANCE_REVIEWS_AS_LM: `${API_URL}/performance-management/{empId}/performanceCycles?page={pageNumber}&size={pageSize}`,
};

export const adminGoalsUrls = {
  GET_GOALS: `${API_URL}/management/employees/goals`,
  GET_GOAL_DETAILS: `${API_URL}/management/employees/{userId}/goalDetails/{id}`,
  UPDATE_GOAL: `${API_URL}/management/employees/{userId}/goals/{id}`,
  APPROVE_GOAL: `${API_URL}/management/employees/goals/approval`,
  DELETE_GOAL: `${API_URL}/management/employees/{userId}/goals/{id}`,
  CREATE_GOAL: `${API_URL}/management/employees/{userId}/goals`,
};

export const pmDashboardUrls = {
  GET_GOALS_REPORT: `${API_URL}/performance-management/reports/goals`,
  GET_EVALUATION_REPORT: `${API_URL}/performance-management/reports/evaluation`,
  GET_GOALS_REPORT_LM: `${API_URL}/performance-management/lm-reports/goals`,
  GET_EVALUATION_REPORT_LM: `${API_URL}/performance-management/lm-reports/evaluation`,
  GET_REPORTEES_GOALS_LM: `${API_URL}/management/reportees/goals`,
  GET_REPORTEES_EVALUATIONS_LM: `${API_URL}/management/reportees/evaluations`,
};

export const adminPredefinedGoalsUrls = {
  GET: `${API_URL}/goals`,
  GET_DETAILS: `${API_URL}/goals/{id}`,
  CREATE: `${API_URL}/goals`,
  UPDATE: `${API_URL}/goals/{id}`,
  DELETE: `${API_URL}/goals/{id}`,
};

export const adminPredefinedGoalCategoriesUrls = {
  GET: `${API_URL}/goal-categories`,
  GET_DETAILS: `${API_URL}/goal-categories/{id}`,
  CREATE: `${API_URL}/goal-categories`,
  UPDATE: `${API_URL}/goal-categories/{id}`,
  DELETE: `${API_URL}/goal-categories/{id}`,
};

export const adminPredefinedGoalCategoryGoalsUrls = {
  GET: `${API_URL}/goal-categories/{categoryId}/goals`,
  CREATE: `${API_URL}/goal-categories/{categoryId}/goals`,
  UPDATE: `${API_URL}/goal-categories/{categoryId}/goals`,
  DELETE: `${API_URL}/goal-categories/{categoryId}/goals/{goalId}`,
  GOAL_CATEGORY_LOG: `${API_URL}/goal-categories/{categoryId}/employees/logs`,
  UPDATE_ASSIGN_GOAL: `${API_URL}/goal-categories/{categoryId}/employees`,
};

export const GENDERS = [
  { key: 'male', value: 'Male' },
  { key: 'female', value: 'Female' },
];

export const IPDEligibilityOptions = [
  { key: '100%', value: '100%' },
  { key: '50/50', value: '50/50' },
];

export const DEPARTMENTS = [
  'Visual',
  'Resource Management',
  'QA Automation',
  'Technical Support',
  'Business Analysis',
  'Admin',
  'Android',
  'Architecture',
  'Automation',
  'Backend',
  'Business Development',
  'Carbon',
  'Consultancy',
  'Data Sciences',
  'Design',
  'DevOps',
  'Finance',
  'HR',
  'iOS',
  'UI/cccm',
  'Java',
  'Management',
  'Marketing',
  'not_set',
  'PM',
  'Project Management',
  'Product-IF',
  'QA',
  'UI/UX',
  'Web',
];
export const COMPETENCY = [
  'Visual',
  'Resource Management',
  'QA Automation',
  'Technical Support',
  'Business Analysis',
  'Admin',
  'Android',
  'Architecture',
  'Automation',
  'Backend',
  'Business Development',
  'Carbon',
  'Consultancy',
  'Data Sciences',
  'Design',
  'DevOps',
  'Finance',
  'HR',
  'iOS',
  'Java',
  'Management',
  'Marketing',
  'not_set',
  'PM',
  'Product-IF',
  'QA',
  'UI/UX',
  'Web',
];
export const RESOURCETYPE = [
  { key: 'Billable', value: 'Billable' },
  { key: 'Non_Billable', value: 'Non-Billable' },
];
export const COST_CENTER = ['Karachi', 'Lahore'];
export const TRANSIENT_RESOURCE_TYPES = {
  OFFER_ACCEPTED: 'Offer Accepted',
  CONDITIONAL: 'Conditional',
  CONTRACTOR: 'Contractor',
};
export const TRANSIENT_RESOURCE_STATUSES = {
  Active: true,
  Inactive: false,
};
export const LENGTHCONSTRAINT = { COMMENT_LENGTH: 50 };
export const skillLevel = {
  BEGINNER: 1,
  INTERMIDIATE: 2,
  EXPERT: 3,
};

export const dateFormat = {
  DDMMYYYYwithForwardSlash: 'DD/MM/YYYY',
  YYYYMMDDwithHyphen: 'YYYY-MM-DD',
  YYYYMMDDwithSpace: 'YYYY MM DD',
  DDMMMYYYYwithSpace: 'DD MMM YYYY',
  DDMMwithForwardSlash: 'DD/MM',
  YYYYMMDDwithoutOffSet: 'YYYY-MM-DDTHH:mm:ss',
  MonthAndYearOnly: 'MMM YYYY',
  abbreviatedMonthAndYear: 'MMM-YYYY',
};

export const environments = {
  PRODUCTION: 'production',
  DEV: 'dev',
};
export const employeeType = {
  BILLED: 'billed',
  BACKUP: 'backup',
  INTERNAL: 'internal',
  BENCH: 'bench',
  BILLABLE: 'billable',
  NONBILLABLE: 'non_billable',
  ADDITIONAL: 'additional',
};
export const employeeTypeFiltered = {
  BILLED: 'billed',
  BACKUP: 'backup',
  ADDITIONAL: 'additional',
  INTERNAL: 'internal',
};
export const radix = {
  DECIMAL: 10,
};

export const LEAVES_TYPES = {
  sick: 1,
  casual: 2,
  annual: 3,
  hajj: 4,
  compassionate: 5,
  paternity: 6,
  maternity: 7,
  sabbatical: 8,
  leave_without_pay: 9,
};

export const LEAVES_STATUS = {
  approved: 'approved',
  pending: 'pending',
  pending_cancellation: 'pending-cancellation',
  cancelled: 'cancelled',
  rejected: 'rejected',
  declined: 'declined',
};

export const BREADCRUMBS = {
  PROJECT: 'project',
  EMPLOYEE: 'employee',
  REPORTEE: 'reportee',
  HISTORY: 'report',
  CYCLES: 'cycles',
  PROJECT_CYCLE: 'cycleProj',
  BILLING_PROJECT: 'billing-projects',
  BILLING_RESOURCE: 'billing-employees',
  PROJECT_REVIEWS_CYCLE: 'project-reviews',
  PROJECT_REVIEWS_CYCLE_PROJECT: 'projectReviewsProj',
  PERFORMANCE_MANAGEMENT: 'performance-management',
  PERFORMANCE_MANAGEMENT_EMPLOYEE: 'performance-management-employee',
  GOAL_CATEGORY_DETAIL: 'goal-category-detail',
  DEAL_DETAIL: 'deal-detail',
};

export const EMPLOYEE_EXPENSE_CLAIM_CONST_TO_ID = {
  HOTEL_ACCODMODATION_LODGING: 5,
  LAUNDRY: 6,
  AIR_TICKET_OR_TAXI_FARE: 7,
  PHONE_CALL_OR_INTERNET: 8,
  CLUB_MEMBER_SUBSCRIPTION: 9,
  MARKETING: 10,
  MEAL_ALLOWANCE: 11,
  TEAM_RECREATION_AND_ENTERTAINMENT: 12,
  GUEST_ENTERTAINMENT: 13,
  INCIDENTALS: 14,
  TADA_LOCAL: 15,
  TADA_INTERNATIONAL: 16,
  OTHER: 17,
  TRAINING: 19,
};

export const EMPLOYEE_EXPENSE_CATEGORY_BUSINESS = [
  EMPLOYEE_EXPENSE_CLAIM_CONST_TO_ID.PHONE_CALL_OR_INTERNET,
  EMPLOYEE_EXPENSE_CLAIM_CONST_TO_ID.MARKETING,
];

export const EMPLOYEE_EXPENSE_CATEGORY_TRAVEL = [
  EMPLOYEE_EXPENSE_CLAIM_CONST_TO_ID.HOTEL_ACCODMODATION_LODGING,
  EMPLOYEE_EXPENSE_CLAIM_CONST_TO_ID.LAUNDRY,
  EMPLOYEE_EXPENSE_CLAIM_CONST_TO_ID.AIR_TICKET_OR_TAXI_FARE,
  EMPLOYEE_EXPENSE_CLAIM_CONST_TO_ID.INCIDENTALS,
  EMPLOYEE_EXPENSE_CLAIM_CONST_TO_ID.TADA_LOCAL,
  EMPLOYEE_EXPENSE_CLAIM_CONST_TO_ID.TADA_INTERNATIONAL,
];

export const EMPLOYEE_EXPENSE_CATEGORY_ENTERTAINMENT = [
  EMPLOYEE_EXPENSE_CLAIM_CONST_TO_ID.CLUB_MEMBER_SUBSCRIPTION,
  EMPLOYEE_EXPENSE_CLAIM_CONST_TO_ID.MEAL_ALLOWANCE,
  EMPLOYEE_EXPENSE_CLAIM_CONST_TO_ID.TEAM_RECREATION_AND_ENTERTAINMENT,
  EMPLOYEE_EXPENSE_CLAIM_CONST_TO_ID.GUEST_ENTERTAINMENT,
];

export const EMPLOYEE_EXPENSE_CATEGORY_OTHER = [EMPLOYEE_EXPENSE_CLAIM_CONST_TO_ID.OTHER];

export const EMPLOYEE_EXPENSE_CATEGORY_TRAINING = [EMPLOYEE_EXPENSE_CLAIM_CONST_TO_ID.TRAINING];

export const EMPLOYEE_EXPENSE_CLAIM = {
  HOTEL_ACCODMODATION_LODGING: {
    id: 5,
    value: 'Hotel / Accommodation / Lodging',
  },
  LAUNDRY: {
    id: 6,
    value: 'Laundry',
  },
  AIR_TICKET_OR_TAXI_FARE: {
    id: 7,
    value: 'Air Tickert / Train / Bus / Taxi Fare',
  },
  PHONE_CALL_OR_INTERNET: {
    id: 8,
    value: 'Phone Calls / Internet',
  },
  CLUB_MEMBER_SUBSCRIPTION: {
    id: 9,
    value: 'Club Membership Subscription',
  },
  MARKETING: {
    id: 10,
    value: 'Marketing',
  },
  MEAL_ALLOWANCE: {
    id: 11,
    value: 'Meal Allowance',
  },
  TEAM_RECREATION_AND_ENTERTAINMENT: {
    id: 12,
    value: 'Team Recreation and Entertainment',
  },
  GUEST_ENTERTAINMENT: {
    id: 13,
    value: 'Guests Entertainment',
  },
  INCIDENTALS: {
    id: 14,
    value: 'Incidentals',
  },
  TADA_LOCAL: {
    id: 15,
    value: 'TADA Local',
  },
  TADA_INTERNATIONAL: {
    id: 16,
    value: 'TADA International',
  },
  OTHER: {
    id: 17,
    value: 'other',
  },
  TRAINING: {
    id: 19,
    value: 'training',
  },
};

export const claimIdToStatus = {
  1: '',
  2: 'Pending',
  3: 'Approved',
  4: 'Declined',
  5: 'Released',
  9: '',
};

export const claimApproveOrder = {
  1: 'LM',
  2: 'FH',
  3: 'FM',
};

export const claimApproveOrderTitle = {
  1: 'Line Manager: ',
  2: 'Functional Head: ',
  3: 'Finance Manager: ',
};

export const claimStatus = {
  WAITING_FOR_APPROVAL: 1,
  PENDING: 2,
  APPROVED: 3,
  DECLINED: 4,
  RELEASED: 5,
  NOT_SHOW_ANY_STATUS: 9,
};

export const TADA_LOCAL = 3000;
export const TADA_INTERNATIONAL = 14000;

export const employeeTypes = Object.values(employeeType).map((e) => e.toLowerCase());

export const dateOfCarryForward = '2019-07-01';

export const EMAIL_REGEX = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

export const employeeTypesFiltered = Object.values(employeeTypeFiltered).map((e) =>
  e.toLowerCase(),
);

export const HTTP_STATUSES = {
  SUCCESS: 200,
};
export const CRMC_FILE_TITLE = {
  CYCLE_DETAILS: 'Cycle Details ',
  EMPLOYEE_RATINGS: 'Employees Ratings ',
};

export const LM_REPORTEE_TYPES = {
  ALL: 'ALL',
  DIRECT: 'DIRECT',
  INDIRECT: 'INDIRECT',
};

export const ALLOCATION_TYPES = {
  BILLED: 'billed',
  ADDITIONAL: 'additional',
  BACKUP: 'backup',
  INTERNAL: 'internal',
};

export const EMPLOYEE_PROJECT_MODAL_TYPE = {
  REMOVE: 'remove',
  DEALLOCATE: 'deallocate',
};

export const starRatingDescription = [
  'Poor',
  'Below Expectation',
  'Meets Expectation',
  'Exceed Expectation',
  'Exceptional',
];

export const raterRoles = {
  PM: 'Project Manager',
  ARCH: 'Architect',
  RESOURCE: 'Resource',
  PROJECT: 'Project',
};

export const goalStatusToClassMapping = {
  Pending: 'progress-red',
  'Pending Approval': 'progress-red',
  Approved: 'progress-green',
  Completed: 'progress-green',
  Final: 'progress-green',
  Rejected: 'progress-red',
  Deleted: 'progress-red',
  Active: 'progress-green',
};

export const editStatusOptionMenu = [
  {
    value: 'inprogress',
    title: 'In Progress',
  },
  {
    value: 'paused',
    title: 'Paused',
  },
  {
    value: 'postponed',
    title: 'Postponed',
  },
  {
    value: 'completed',
    title: 'Completed',
  },
  {
    value: 'final',
    title: 'Final',
  },
];

export const minLengthRequiredForComments = 20;
export const PMCycleUrls = {
  CREATE_PMCYCLE: `${API_URL}/performance-management/performance-cycle`,
  EDIT_PMCYCLE: `${API_URL}/performance-management/performance-cycle/{pmId}`,
  DELETE_PMCYCLE: `${API_URL}/performance-management/performance-cycle/{pmId}`,
  CLOSE_PMCYCLE: `${API_URL}/performance-management/performance-cycle/{performanceCycleId}/close`,
  GET_PMCYCLE: `${API_URL}/performance-management/performance-cycle?isActive={isActive}&page={pageNumber}&size={pageSize}`,
  GET_PMCYCLE_DETAIL: `${API_URL}/performance-management/performance-cycle/{id}`,
  GET_PM_EMP_CYCLE: `${API_URL}/performance-management/performance-cycle/{id}/employees`,
  ADD_EMPLOYEES_PM_EMP_CYCLE: `${API_URL}/performance-management/performance-cycle/{performanceCycleId}/add-employees`,
  DELETE_EMPLOYEE_PM_EMP_CYCLE: `${API_URL}/performance-management/performance-cycle/{performanceCycleId}/{employeeId}`,
  GET_EMPLOYEE_PE: `${API_URL}/performance-management/performance-cycle/{performanceCycleId}/{empId}/evaluation?reviewType={reviewType}`,
  SUBMIT_PM_EMP_CYCLE: `${API_URL}/performance-management/performance-cycle/{performanceCycleId}/{empId}/evaluation`,
  GET_MY_PEF: `${API_URL}/employees/me/performance-cycle/{performanceCycleId}/evaluation?reviewType={reviewType}`,
  SUBMIT_MY_PEF: `${API_URL}/employees/me/performance-cycle/{performanceCycleId}/evaluation`,
  SUBMIT_FOR_APPROVAL: `${API_URL}/performance-management/performance-cycle/evaluation/submitForApproval`,
  APPROVE_PEF: `${API_URL}/performance-management/performance-cycle/evaluation/approval`,
  REOPEN_PEF: `${API_URL}/performance-management/performance-cycle/{performanceCycleId}/{employeeId}/evaluation/reopen`,
  MARK_PEF_AS_FINAL: `${API_URL}/performance-management/performance-cycle/{performanceCycleId}/{employeeId}/evaluation/mark-as-final`,
  POST_PMCYCLE_INITIATION_EMAIL: `${API_URL}/performance-management/performance-cycle/post-initiation-email/{performanceCycleId}`,
  GET_RATING_OPTIONS: `${API_URL}/performance-management/rating-options`,
};
