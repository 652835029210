import moment from 'moment';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'react-pivottable/pivottable.css';
import { DATE_FORMAT } from 'configs/employeeProfileConstants';
import EmployeesList from './EmployeesList';
import PMCycleDropdown from './PMCycleDropdown';

const getCustomizedColumns = () => {
  const columns = [
    {
      title: 'Line Manager',
      dataIndex: 'reviewManagerName',
      key: 'reviewManagerName',
      config: {
        needFilter: true,
        showInTable: true,
        showInExport: true,
      },
      sorter: (a, b) => a.reviewManagerName.localeCompare(b.reviewManagerName),
    },
    {
      title: 'Development Areas (self)',
      dataIndex: 'self.trainingInfo.developmentArea',
      key: 'self.trainingInfo.developmentArea',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
    {
      title: 'Strengths (self)',
      dataIndex: 'self.trainingInfo.strength',
      key: 'self.trainingInfo.strength',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
    {
      title: 'Career Ambitions (self)',
      dataIndex: 'self.trainingInfo.careerAmbition',
      key: 'self.trainingInfo.careerAmbition',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
    {
      title: 'Short Course (self)',
      dataIndex: 'self.trainingInfo.shortCourse',
      key: 'self.trainingInfo.shortCourse',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
    {
      title: 'Technical Trainings (self)',
      dataIndex: 'self.trainingInfo.technicalTraining',
      key: 'self.trainingInfo.technicalTraining',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
    {
      title: 'Soft Skills Trainings (self)',
      dataIndex: 'self.trainingInfo.softSkillsTraining',
      key: 'self.trainingInfo.softSkillsTraining',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
    {
      title: 'Development Areas (manager)',
      dataIndex: 'manager.trainingInfo.developmentArea',
      key: 'manager.trainingInfo.developmentArea',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
    {
      title: 'Strengths (manager)',
      dataIndex: 'manager.trainingInfo.strength',
      key: 'manager.trainingInfo.strength',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
    {
      title: 'Career Ambitions (manager)',
      dataIndex: 'manager.trainingInfo.careerAmbition',
      key: 'manager.trainingInfo.careerAmbition',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
    {
      title: 'Short Course (manager)',
      dataIndex: 'manager.trainingInfo.shortCourse',
      key: 'manager.trainingInfo.shortCourse',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
    {
      title: 'Technical Trainings (manager)',
      dataIndex: 'manager.trainingInfo.technicalTraining',
      key: 'manager.trainingInfo.technicalTraining',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
    {
      title: 'Soft Skills Trainings (manager)',
      dataIndex: 'manager.trainingInfo.softSkillsTraining',
      key: 'manager.trainingInfo.softSkillsTraining',
      config: {
        showInTable: false,
        showInExport: true,
      },
    },
  ];
  return columns;
};

const Training = (props) => {
  const {
    pmCycles,
    evaluationReport,
    getEvaluationReport,
    baseUrlForEvaluation,
    getPMCycleActions,
    divisions,
    departments,
    competencies,
    businessUnits,
  } = props;
  const [trainingSummary, setTrainingSummary] = useState([]);

  useEffect(() => {
    if (evaluationReport.length > 0) {
      const normalizedData = [];

      evaluationReport.forEach((item) => {
        const { manager } = item;
        normalizedData.push({
          ...item,
          category: 'Manager Rating',
          managerRating: manager.rating,
        });
      });

      setTrainingSummary(normalizedData);
    } else {
      setTrainingSummary([]);
    }
  }, [evaluationReport]);

  useEffect(() => {
    const params = {
      pageNumber: 0,
      pageSize: 100000,
    };

    getPMCycleActions(params);
  }, []);

  const changeActionHandler = (id) => {
    getEvaluationReport(id);
  };

  const performanceCycleDropdown = () => {
    return <PMCycleDropdown pmCycles={pmCycles} onChange={changeActionHandler} />;
  };

  return (
    <div style={{ overflow: 'hidden', overflowY: 'auto' }} className="mt-20">
      <div className="mb-20">{performanceCycleDropdown()}</div>
      <div style={{ overflowY: 'scroll' }}>
        <EmployeesList
          employees={trainingSummary}
          customizedColumns={getCustomizedColumns(baseUrlForEvaluation)}
          exportFileName={`Training - ${moment().format(DATE_FORMAT)}`}
          divisions={divisions}
          departments={departments}
          competencies={competencies}
          businessUnits={businessUnits}
        />
      </div>
    </div>
  );
};

Training.propTypes = {
  evaluationReport: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      taleoId: PropTypes.string.isRequired,
      division: PropTypes.string.isRequired,
      competency: PropTypes.string.isRequired,
      department: PropTypes.string.isRequired,
      employeeId: PropTypes.number.isRequired,
      designation: PropTypes.string.isRequired,
      businessUnit: PropTypes.string.isRequired,
      reviewManagerName: PropTypes.string.isRequired,
      self: PropTypes.shape({
        status: PropTypes.string.isRequired,
        rating: PropTypes.number,
      }),
      manager: PropTypes.shape({
        status: PropTypes.string.isRequired,
        rating: PropTypes.number,
      }),
    }),
  ).isRequired,
  getEvaluationReport: PropTypes.func.isRequired,
};

export default Training;
