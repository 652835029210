import {
  EnhancedTabs,
  EnhancedSelect,
  EnhancedModal,
  EnhancedProgress,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';
import {
  removeGoalActions,
  getGoalDetailsActions,
  updateGoalActions,
  updateGoalStatusActions,
} from 'store/actions/EmployeeProfileActions';
import { goalStatusToClassMapping } from 'configs/constants';
import { GOAL_STATUS, DATE_FORMAT } from 'configs/employeeProfileConstants';
import RejectButton from 'components/shared/employeeProfile/RejectButton';
import ApproveButton from 'components/shared/employeeProfile/ApproveButton';
import editIcon from '../../../images/edit-gray.svg';
import deleteIcon from '../../../images/delete-icon.svg';
import viewIcon from '../../../images/view-icon.svg';
import AddGoalModal from './addGoalModal';
import RejectGoalModal from './RejectGoalModal';
import GoalDetails from '../../employeeProfile/goalsReviews/goalDetails';

const { TabPane } = EnhancedTabs;
const { Option } = EnhancedSelect;
const { confirm } = EnhancedModal;

const EmployeeGoals = (props) => {
  const {
    userRole,
    addGoal,
    updateGoal,
    getGoal,
    removeGoal,
    getGoalDetails,
    goals,
    isLoading,
    variant,
    details,
    updateGoalStatus,
  } = props;
  const employeeId = props.match.params.id;
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isGoalRejectModalVisible, setIsGoalRejectModalVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState('1');
  const [params, setParams] = useState({
    empId: employeeId,
    status: `${GOAL_STATUS.PENDING_APPROVAL},${GOAL_STATUS.APPROVED}`,
    pageNumber: 0,
    pageSize: 10,
  });
  const [selectedGoalRowsKeys, setSelectedGoalRowsKeys] = useState([]);
  const [rejectedGoalsIds, setRejectedGoalsIds] = useState([]);
  const [isDetailsVisible, setDetailsVisibilty] = useState(false);
  const [isEdit, setEdit] = useState(false);

  const viewDetailHandler = () => {
    setDetailsVisibilty(!isDetailsVisible);
  };
  // const onSelectChange = (newSelectedRowKeys) => {
  // 	setSelectedRowKeys(newSelectedRowKeys);
  // };

  useEffect(() => {
    getGoal(params);
  }, [params]);

  // count not updated in params when 1 doc available in paginaton that why I add this this code. Ticket No: VENDIANS-3234
  useEffect(() => {
    if (goals.count && goals.rows.length === 0 && params.pageNumber > 0) {
      setParams({
        ...params,
        pageNumber: params.pageNumber - 1,
      });
    }
  }, [goals.count]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showGoalRejectModal = () => {
    setIsGoalRejectModalVisible(true);
  };

  const handleGoalRejectOk = () => {
    setSelectedGoalRowsKeys((currSelectedGoalKeys) =>
      currSelectedGoalKeys.filter(
        (goalId) => !rejectedGoalsIds.some((rejectedGoalId) => rejectedGoalId === goalId),
      ),
    );
    setIsGoalRejectModalVisible(false);
    setRejectedGoalsIds([]);
  };

  const handleGoalRejectCancel = () => {
    setIsGoalRejectModalVisible(false);
    setRejectedGoalsIds([]);
  };

  const tabChangeHandler = (key) => {
    if (key === '1') {
      setParams({
        ...params,
        status: `${GOAL_STATUS.PENDING_APPROVAL},${GOAL_STATUS.APPROVED}`,
        pageNumber: 0,
        pageSize: 10,
      });
      setSelectedGoalRowsKeys([]);
    } else if (key === '2') {
      setParams({
        ...params,
        status: `${GOAL_STATUS.COMPLETED},${GOAL_STATUS.FINAL}`,
        pageNumber: 0,
        pageSize: 10,
      });
    } else if (key === '3') {
      setParams({
        ...params,
        status: `${GOAL_STATUS.REJECTED},${GOAL_STATUS.DELETED}`,
        pageNumber: 0,
        pageSize: 10,
      });
    }
    setSelectedTab(key);
  };

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      pageNumber: pagination.current - 1,
      pageSize: pagination.pageSize,
    });
  };

  const removeGoalHandler = (id) => {
    const payload = {
      empId: params.empId,
      goalId: id,
    };
    confirm({
      title: 'Are you sure you want to delete this goal?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        removeGoal({ payload, params, viewDetails: isDetailsVisible });
      },
    });
  };

  const updateGoalStatusHandler = (goalIds, status, viewDetails) => {
    const payload = {
      // empId: params.empId,
      goalIds,
      status,
      as: 'Line Manager',
    };
    confirm({
      title: `Are you sure you want to ${status === 'Approved' ? 'Approve' : 'Reject'} this goal?`,
      okText: 'Yes',
      okType: 'success',
      cancelText: 'No',
      onOk() {
        setSelectedGoalRowsKeys((currSelectedGoalKeys) =>
          currSelectedGoalKeys.filter(
            (goalId) => !goalIds.some((approvedGoalId) => approvedGoalId === goalId),
          ),
        );
        updateGoalStatus({ payload, parameters: params, viewDetails });
      },
    });
  };

  const changeActionHandler = (key, id) => {
    if (key === '1') {
      showModal();
      setEdit(true);
      getGoalDetails({ empId: params.empId, id });
    } else if (key === '2') {
      removeGoalHandler(id);
    } else if (key === '3') {
      viewDetailHandler();
      getGoalDetails({ empId: params.empId, id });
    } else if (key === '4') {
      showGoalRejectModal();
      setRejectedGoalsIds([id]);
      getGoalDetails({ empId: params.empId, id });
    } else if (key === '5') {
      updateGoalStatusHandler([id], GOAL_STATUS.APPROVED, false);
    }
  };

  const onChangeActiveGoalsSelection = (selectedRowKeys) => {
    setSelectedGoalRowsKeys(selectedRowKeys);
  };

  const canEdit = (status) => status !== GOAL_STATUS.DELETED && status !== GOAL_STATUS.REJECTED;

  const canDelete = (status, isGoalPCRelated) =>
    status !== GOAL_STATUS.DELETED && status !== GOAL_STATUS.REJECTED && !isGoalPCRelated;

  const canApprove = (status) => status === GOAL_STATUS.PENDING_APPROVAL;

  const canReject = (status, isGoalPCRelated) =>
    (status === GOAL_STATUS.PENDING_APPROVAL || status === GOAL_STATUS.APPROVED) &&
    !isGoalPCRelated;

  const canView = () => true;

  const optionMenu = [
    {
      value: '1',
      label: 'Edit Goal',
      canCallback: (record) => canEdit(record.status),
    },
    {
      value: '2',
      label: 'Delete Goal',
      canCallback: (record) => canDelete(record.status, record.isEvaluatedCurrently),
    },
    {
      value: '3',
      label: 'View',
      canCallback: canView,
    },
    {
      value: '4',
      label: 'Reject',
      canCallback: (record) => canReject(record.status, record.isEvaluatedCurrently),
    },
    {
      value: '5',
      label: 'Approve',
      canCallback: (record) => canApprove(record.status),
    },
  ];
  const setActionsHandler = (id, _record) => {
    let actions;
    if (variant) {
      actions = (
        <span>
          {selectedTab === '1' && (
            <button
              className="transparent-btn"
              style={{ marginRight: 10 }}
              onClick={() => {
                showModal();
                setEdit(true);
                getGoalDetails({ empId: params.empId, id });
              }}
            >
              <img src={editIcon} alt="edit icon" />
            </button>
          )}
          {selectedTab === '1' && (
            <button
              className="transparent-btn"
              style={{ marginRight: 10 }}
              onClick={() => removeGoalHandler(id)}
            >
              <img src={deleteIcon} alt="delte icon" />
            </button>
          )}
          <button
            className="transparent-btn"
            onClick={() => {
              viewDetailHandler();
              getGoalDetails({ empId: params.empId, id });
            }}
          >
            <img src={viewIcon} alt="view icon" />
          </button>
        </span>
      );
    } else if (userRole.allReportees.length > 0 && selectedTab === '2') {
      actions = (
        <button
          className="transparent-btn"
          onClick={() => {
            viewDetailHandler();
            getGoalDetails({ empId: params.empId, id });
          }}
        >
          <img src={viewIcon} alt="view icon" />
        </button>
      );
    } else {
      actions = (
        <EnhancedSelect
          style={{
            width: 120,
          }}
          className="action-selection-dropdown"
          placeholder="Actions"
          value="Actions"
          onChange={(key) => changeActionHandler(key, id)}
        >
          {optionMenu.map(
            (item) => item.canCallback(_record) && <Option value={item.value}>{item.label}</Option>,
          )}
        </EnhancedSelect>
      );
    }
    return actions;
  };

  const columns = [
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: '15%',
      render: (value) => (value ? moment.utc(value).format(DATE_FORMAT) : 'N/A'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) => (
        <span className={`status-box ${goalStatusToClassMapping[status]}`}>{status}</span>
      ),
    },
    {
      title: 'Goal Title',
      dataIndex: 'title',
    },
    {
      title: 'Percent Complete',
      dataIndex: 'progress',
      width: 250,
      render: (progres) => (
        <EnhancedProgress
          className={`progress-bar ${progres > 10 && 'font-white'}`}
          percent={`${progres}`}
          strokeColor="#1DAC8A"
          strokeLinecap="square"
          type="line"
        />
      ),
    },
    {
      title: 'Weighted',
      dataIndex: 'weightage',
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render: (id, _record) => setActionsHandler(id, _record),
    },
  ];

  const activeGoalsTabRightButtons = selectedGoalRowsKeys.length && (
    <div className="add-btn-wrapper" style={{ marginTop: 0 }}>
      <ApproveButton
        style={{ marginRight: 10 }}
        buttonText={`Approve (${selectedGoalRowsKeys.length})`}
        clickHandler={() => {
          updateGoalStatusHandler(selectedGoalRowsKeys, GOAL_STATUS.APPROVED, false);
        }}
      />

      <RejectButton
        style={{ marginRight: 10 }}
        buttonText={`Reject (${selectedGoalRowsKeys.length})`}
        clickHandler={() => {
          setRejectedGoalsIds(selectedGoalRowsKeys);
          setIsGoalRejectModalVisible(true);
        }}
      />
    </div>
  );

  return (
    <div>
      {!isDetailsVisible ? (
        <React.Fragment>
          <div>
            <span
              style={{
                marginLeft: 8,
              }}
            >
              {/* {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''} */}
            </span>
          </div>
          <EnhancedTabs
            tabBarExtraContent={activeGoalsTabRightButtons}
            defaultActiveKey={selectedTab}
            onChange={tabChangeHandler}
          >
            <TabPane tab="Active Goals" key="1">
              <EnhancedServerSideTable
                rowKey={(record) => record.id}
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: selectedGoalRowsKeys,
                  onChange: onChangeActiveGoalsSelection,
                  getCheckboxProps: (record) => ({
                    disabled:
                      !canApprove(record.status) ||
                      !canReject(record.status, record.isEvaluatedCurrently),
                  }),
                }}
                loading={isLoading}
                columns={columns}
                data={goals.rows}
                onChange={handleTableChange}
                className="goal-table-container"
                showResetFiltersButton={false}
                paginationInfo={{
                  totalItems: goals.count,
                  pageSize: params.pageSize,
                  pageNumber: params.pageNumber + 1,
                }}
                scroll={{ x: 1300 }}
              />
            </TabPane>
            <TabPane tab="Completed Goals" key="2">
              <EnhancedServerSideTable
                rowKey={(record) => record.id}
                // rowSelection={rowSelection}
                loading={isLoading}
                columns={columns}
                data={goals.rows}
                onChange={handleTableChange}
                className="goal-table-container"
                showResetFiltersButton={false}
                paginationInfo={{
                  totalItems: goals.count,
                  pageSize: params.pageSize,
                  pageNumber: params.pageNumber + 1,
                }}
                scroll={{ x: 1300 }}
              />
            </TabPane>
            <TabPane tab="Rejected/Deleted Goals" key="3">
              <EnhancedServerSideTable
                rowKey={(record) => record.id}
                // rowSelection={rowSelection}
                loading={isLoading}
                columns={columns}
                data={goals.rows}
                onChange={handleTableChange}
                className="goal-table-container"
                showResetFiltersButton={false}
                paginationInfo={{
                  totalItems: goals.count,
                  pageSize: params.pageSize,
                  pageNumber: params.pageNumber + 1,
                }}
                scroll={{ x: 1300 }}
              />
            </TabPane>
          </EnhancedTabs>

          <AddGoalModal
            isModalVisible={isModalVisible}
            handleOk={handleOk}
            handleCancel={handleCancel}
            addGoalActions={addGoal}
            updateGoalActions={updateGoal}
            params={params}
            isEdit={isEdit}
            editableHandler={setEdit}
            isLoading={isLoading}
            details={details}
          />

          <RejectGoalModal
            isModalVisible={isGoalRejectModalVisible}
            handleOk={handleGoalRejectOk}
            handleCancel={handleGoalRejectCancel}
            params={params}
            updateGoalStatus={updateGoalStatus}
            isLoading={isLoading}
            details={details}
            rejectedIds={rejectedGoalsIds}
          />
        </React.Fragment>
      ) : (
        <GoalDetails
          viewDetailHandler={viewDetailHandler}
          isLoading={isLoading}
          details={details}
          updateGoalActions={updateGoal}
          deleteGoalActions={removeGoalHandler}
          updateGoalStatusActions={updateGoalStatusHandler}
          updateGoalStatus={updateGoalStatus}
          canEdit={canEdit}
          canDelete={canDelete}
          canApprove={canApprove}
          canReject={canReject}
          params={params}
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { auth, goalReducer: employeeProfileReducer } = state;
  const { isLoading } = employeeProfileReducer;
  return {
    isLoading,
    userRole: auth.user,
    details: employeeProfileReducer.goalDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeGoal: (req) => dispatch(removeGoalActions(req)),
    getGoalDetails: (req) => dispatch(getGoalDetailsActions(req)),
    updateGoal: (req) => dispatch(updateGoalActions(req)),
    updateGoalStatus: (req) => dispatch(updateGoalStatusActions(req)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeGoals);
