import { EnhancedRow, EnhancedCol } from 'components/shared/antd';
import React from 'react';
import DateCard from 'components/DateCard';
import BarCard from 'components/BarCard';
import EmotionCard from 'components/EmotionCard';

const LogWorkCard = (props) => {
  const { selectedWorkId, log, projects, date } = props;
  const { workLogDetails, mood } = log;
  return (
    <EnhancedRow className="logwork-card">
      <EnhancedCol lg={3} md={3} sm={24} xs={24}>
        <DateCard date={date} isLogged />
      </EnhancedCol>
      <EnhancedCol lg={17} md={17} sm={24} xs={24}>
        <BarCard
          details={workLogDetails && workLogDetails.details}
          total={workLogDetails && workLogDetails.total}
          selectedWorkId={selectedWorkId}
          projects={projects}
          date={date}
          onEditWorkLogClicked={props.onEditWorkLogClicked}
        />
      </EnhancedCol>
      <EnhancedCol lg={4} md={4} sm={24} xs={24}>
        <EmotionCard
          selectedWorkId={selectedWorkId}
          mood={mood}
          date={date}
          details={workLogDetails && workLogDetails.details}
          onEditEmotionClicked={props.onEditEmotionClicked}
        />
      </EnhancedCol>
    </EnhancedRow>
  );
};

export default LogWorkCard;
