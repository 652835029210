import React from 'react';
import { Row } from 'antd';
import PropTypes from 'prop-types';

const EnhancedRow = (props) => {
  const { align, gutter, justify, type, children, className, style, onClick, ...rest } = props;

  return (
    <Row
      style={style}
      align={align}
      gutter={gutter}
      type={type}
      justify={justify}
      className={className}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Row>
  );
};

EnhancedRow.propTypes = {
  align: PropTypes.string,
  gutter: PropTypes.number,
  justify: PropTypes.string,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
};

EnhancedRow.defaultProps = {
  align: 'top',
  gutter: 0,
  justify: 'start',
  className: '',
  style: {},
  onClick: () => {},
};

export default EnhancedRow;
