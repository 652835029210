import { connect } from 'react-redux';
import ExpenseClaimList from 'components/expense/ExpenseClaimList';
import {
  getClaimsAction,
  getTeamClaimsAction,
  getClaimsforFinanceAction,
  getClaimsforHRAction,
  getClaimTypesAction,
  getClaimImagesAction,
  removeImageFromState,
  resetApprovalStatusAfterSuccess,
  getClaimProjectListAction,
  getExportClaimAction,
} from 'store/actions/ExpenseClaimActions';

function mapStateToProps(state) {
  const { auth, getClaimReducer, addClaimReducer } = state;
  return {
    user: auth.user,
    isLoading: getClaimReducer.isLoading,
    claimList: getClaimReducer.claimList,
    myClaims: getClaimReducer.myClaims,
    teamClaims: getClaimReducer.teamClaims,
    claimForFinance: getClaimReducer.claimForFinance,
    claimForHR: getClaimReducer.claimForHR,
    claimTypes: getClaimReducer.claimTypes,
    claimCategory: getClaimReducer.claimCategory,
    claimImages: getClaimReducer.claimImages,
    claimImagesSuccess: getClaimReducer.claimImagesSuccess,
    approvalStatusUpdated: addClaimReducer.approvalStatusUpdated,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getClaimProjectList: () => {
      dispatch(getClaimProjectListAction());
    },
    getClaims: (params) => {
      dispatch(getClaimsAction(params));
    },
    getTeamClaims: (params) => {
      dispatch(getTeamClaimsAction(params));
    },
    getClaimsforFinance: (params) => {
      dispatch(getClaimsforFinanceAction(params));
    },
    getClaimsforHR: (params) => {
      dispatch(getClaimsforHRAction(params));
    },
    getClaimTypes: () => {
      dispatch(getClaimTypesAction());
    },
    getClaimImages: (claimId) => {
      dispatch(getClaimImagesAction(claimId));
    },
    exportClaim: () => {
      dispatch(getExportClaimAction());
    },
    removeImageFromState: () => {
      dispatch(removeImageFromState());
    },
    resetApprovalStatus: () => {
      dispatch(resetApprovalStatusAfterSuccess());
    },
  };
}
const ExpenseClaimListContainer = connect(mapStateToProps, mapDispatchToProps)(ExpenseClaimList);

export default ExpenseClaimListContainer;
