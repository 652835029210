import { connect } from 'react-redux';

import PCProjectCRMC from 'components/pc/PCProjectCRMC';

import updateBreadCrumb from 'store/actions/BreadCrumbActions';
import {
  getProjectByCycleIdAction,
  getProjectRatingAction,
  getProjectByCycleIdActionForCRMC,
  deleteProjectCycleAction,
  deleteProjectRatingAction,
  markPerformanceCycleCompleteAction,
  updatePerformanceCycleAction,
  getCRMCPanelProjectDataAction,
  extendProjectCycleDueDatesAction,
  downloadPerformanceRatingsAction,
  getRatingDetailsCRMCAction,
  resetRatingDetails,
} from 'store/actions/PerformanceCycleActions';

function mapStateToProps(state) {
  const { auth, performanceCycleReducer } = state;
  return {
    userId: auth.user && auth.user.id,
    readOnly: auth.user && auth.user.isReadOnly,
    isCRMC1: auth.user && auth.user.isCRMC1,
    isCRMC2: auth.user && auth.user.isCRMC2,
    projectCycleData: performanceCycleReducer.projectCycleData,
    isLoading: performanceCycleReducer.isLoading,
    ratingDetails: performanceCycleReducer.ratingDetails,
    projectRating: performanceCycleReducer.projectRating,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectByCycleIdAction: (cycleId) => dispatch(getProjectByCycleIdAction(cycleId)),
    getProjectByCycleIdActionForCRMC: (cycleId) =>
      dispatch(getProjectByCycleIdActionForCRMC(cycleId)),
    deleteProjectCycleAction: (obj) => dispatch(deleteProjectCycleAction(obj)),
    getProjectRatingAction: (projectCycleId, projectId) =>
      dispatch(getProjectRatingAction(projectCycleId, projectId)),
    getCRMCPanelProjectDataAction: (projectCycleId, projectId) =>
      dispatch(getCRMCPanelProjectDataAction(projectCycleId, projectId)),
    removeProjectRating: (req) => dispatch(deleteProjectRatingAction(req)),
    markPerformanceCycleCompleteAction: (req) => dispatch(markPerformanceCycleCompleteAction(req)),
    updateBreadCrumb: (key, oldVal, newVal) => dispatch(updateBreadCrumb(key, oldVal, newVal)),
    extendProjectCycleDueDatesAction: (data) => dispatch(extendProjectCycleDueDatesAction(data)),
    updatePerformanceCycleAction: (req) => dispatch(updatePerformanceCycleAction(req)),
    downloadPerformanceRatingsAction: (cycleInfo) =>
      dispatch(downloadPerformanceRatingsAction(cycleInfo)),
    getRatingDetailsCRMC: (payload) => dispatch(getRatingDetailsCRMCAction(payload)),
    resetRatingDetails: () => dispatch(resetRatingDetails()),
  };
}

const PCProjectCRMCContainer = connect(mapStateToProps, mapDispatchToProps)(PCProjectCRMC);

export default PCProjectCRMCContainer;
