import {
  getProjects,
  getWorkLogProjects,
  addProject,
  editProjectDetails,
  activateProject,
} from 'sources/ProjectSource';
import {
  GET_PROJECTS_INIT,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILURE,
  CREATE_PROJECT_INIT,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILURE,
  EDIT_PROJECT_DETAILS_INIT,
  EDIT_PROJECT_DETAILS_SUCCESS,
  EDIT_PROJECT_DETAILS_FAILURE,
  ACTIVATE_PROJECT_INIT,
  ACTIVATE_PROJECT_SUCCESS,
  ACTIVATE_PROJECT_FAILURE,
  RESET_PROJECT_ACTIVATION,
} from 'constants/ProjectTypes';

// normal actions
export const getProjectsInit = () => ({
  type: GET_PROJECTS_INIT,
});

export const getProjectsSuccess = (data) => ({
  type: GET_PROJECTS_SUCCESS,
  data,
});

export const getProjectsFailure = (data) => ({
  type: GET_PROJECTS_FAILURE,
  data,
});

export const createProjectInit = () => ({
  type: CREATE_PROJECT_INIT,
});

export const createProjectSuccess = (data) => ({
  type: CREATE_PROJECT_SUCCESS,
  data,
});

export const createProjectFailure = (data) => ({
  type: CREATE_PROJECT_FAILURE,
  data,
});

export const editProjectDetailsInit = () => ({
  type: EDIT_PROJECT_DETAILS_INIT,
});

export const editProjectDetailsSuccess = (data) => ({
  type: EDIT_PROJECT_DETAILS_SUCCESS,
  data,
});

export const editProjectDetailsFailure = () => ({
  type: EDIT_PROJECT_DETAILS_FAILURE,
});

export const activateProjectInit = () => ({
  type: ACTIVATE_PROJECT_INIT,
});

export const activateProjectSuccess = () => ({
  type: ACTIVATE_PROJECT_SUCCESS,
});

export const activateProjectFailure = () => ({
  type: ACTIVATE_PROJECT_FAILURE,
});

export const resetProjectActivation = () => ({
  type: RESET_PROJECT_ACTIVATION,
});

// Async Action

export const editProjectDetailsAction = (projectId, ProjectData, employeeId) =>
  editProjectDetails(projectId, ProjectData, employeeId);

export const getProjectsAction = () => getProjects();

export const getWorkLogProjectsAction = () => getWorkLogProjects();

export const createProjectAction = (req) => addProject(req);

export const activateProjectAction = (req) => activateProject(req);
