import { EnhancedRow, EnhancedCol, EnhancedLayout } from 'components/shared/antd';
import React, { Component } from 'react';
import moment from 'moment';

import LogWorkCard from 'components/LogWorkCard';
import AddLogWorkCard from 'components/AddLogWorkCard';
import DateRangePicker from 'components/DateRangePicker';
import LogWorkModal from 'components/LogWorkModal';

import { convertToNumber } from 'utils/conversionUtils';
import { loggerAreas, dateFormat } from 'configs/constants';
import { FAQS, PROFILE } from 'configs/routesConstants';
import { emotionUpdated } from 'configs/additionalConstants';

import 'components/LogWork.css';

const { Header } = EnhancedLayout;

class LogWorkList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      duration: 0,
      start: moment().startOf('month'),
      // end: new Date(),
      selectedDate: new Date(),
      editData: null,
      selectedWorkId: null,
    };
  }

  componentDidMount() {
    this.checkPermissions(this.props.userArea);
    this.props.getWorkLogProjects();
    this.props.getHistory(this.props.userId);
    this.handleDateChange(moment().startOf('month'), moment().endOf('month'));
  }

  componentWillReceiveProps(nextProps) {
    this.checkPermissions(nextProps.userArea);
  }

  onEditEmotionClicked = (selectedWorkId, date, details, icon) => {
    const dataToInsert = {
      mood: Number(icon),
      logs: this.updateProjectStructure(details),
      date: details.date,
    };
    this.props.updateEmployeeWorkLog(
      dataToInsert,
      this.props.userId,
      selectedWorkId,
      emotionUpdated,
    );
  };

  onEditWorkLogClicked = (selectedWorkId, date, details) => {
    this.setState({
      visible: true,
      selectedDate: date,
      selectedWorkId,
      editData: details,
    });
    this.props.clearWorklogStatus();
  };

  onAddWorkLogClicked = (dateClicked) => {
    this.props.clearWorklogStatus();
    this.setState({
      visible: true,
      selectedDate: dateClicked.toDate(),
    });
  };
  onModalCancel = () => {
    this.setState({ visible: false, editData: null, selectedWorkId: null });
    this.props.clearWorklogStatus();
  };

  checkPermissions = (userArea) => {
    if (!loggerAreas.includes(userArea) && this.props.history.location.pathname !== FAQS) {
      this.props.history.push(PROFILE);
    }
  };

  handleDateChange = (start, end) => {
    let days = moment(start).startOf('day').diff(moment(end).endOf('day'), 'days');
    days = Math.abs(days) + 1;
    this.setState({
      duration: days,
      start,
      // end,
    });
  };

  // TODO : Have to move this to normalizer
  updateProjectStructure = (projects) =>
    projects.map((log) => ({
      projectId: log.projectId,
      percentage: convertToNumber(log.percentage) || null,
    }));

  renderLogs = () => {
    const { logs } = this.props;
    const logCards = [];
    let currentDate = moment(this.state.start);

    const dateFilterFunc = (log) => currentDate.format(dateFormat.YYYYMMDDwithHyphen) === log.date;
    for (let i = 0; i < this.state.duration; i += 1) {
      const currentLog = logs.filter(dateFilterFunc)[0];

      if (currentLog) {
        logCards.push(
          <LogWorkCard
            key={i}
            onEditEmotionClicked={this.onEditEmotionClicked}
            onEditWorkLogClicked={this.onEditWorkLogClicked}
            log={currentLog}
            selectedWorkId={currentLog && currentLog.id}
            date={currentLog && currentLog.date}
            projects={this.props.projects}
          />,
        );
      } else {
        logCards.push(
          <AddLogWorkCard
            key={i}
            onAddWorkLogClicked={this.onAddWorkLogClicked}
            date={currentDate}
          />,
        );
      }

      currentDate = currentDate.clone().add(1, 'days');
    }

    return logCards;
  };

  render() {
    const logs = this.renderLogs();
    return (
      <div>
        <Header className="log-work-header" style={{ marginBottom: '10px' }}>
          <EnhancedRow>
            <EnhancedCol lg={10} md={10} sm={24} xs={24}>
              <h1 style={{ marginBottom: 0 }}>Log Work</h1>
            </EnhancedCol>
            <EnhancedCol lg={14} md={14} sm={24} xs={24} style={{ backgroundColor: '#fff' }}>
              <DateRangePicker loadData={this.handleDateChange} data={false} />
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        <LogWorkModal
          handleCancel={this.onModalCancel}
          visible={this.state.visible}
          selectedWorkId={this.state.selectedWorkId}
          date={this.state.selectedDate}
          editData={this.state.editData}
        />
        {logs}
      </div>
    );
  }
}

export default LogWorkList;
