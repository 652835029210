import {
  EnhancedCol,
  EnhancedRow,
  EnhancedDivider,
  EnhancedModal,
  EnhancedRate,
} from 'components/shared/antd';
import React from 'react';
import './pc.css';
import NoFeedback from './shared/NoFeedback';
import { starRatingDescription } from '../../configs/constants';
import AttributeTitle from './shared/AttributeTitle';

class RatingModalPR extends React.Component {
  componentWillUnmount() {
    this.props.clearModalDate();
  }

  handleModalCancel = () => {
    this.props.onModalClose(false);
  };

  render() {
    const { resourceName, projectRating } = this.props;
    return (
      <React.Fragment>
        <EnhancedModal
          title={resourceName}
          onCancel={this.handleModalCancel}
          okText="Save"
          visible
          afterClose={this.handleOnClose}
          footer={null}
          width="1000px"
          height="430px"
          style={{
            backgroundColor: 'white',
            overflowY: 'scroll',
            maxHeight: '80%',
            padding: '0',
          }}
          className="modal-custom pr-page-modal"
          bodyStyle={{ padding: '24px' }}
        >
          <EnhancedRow gutter={16}>
            {projectRating.ratings.map((data) => (
              <EnhancedCol span={8} key={data.ratingId}>
                <div className="modal-heading mb-24">
                  {data.rateeRole !== '' ? data.rateeRole : 'N/A'}{' '}
                  <span className="font-weight-normal">
                    - {data.rateeName !== '' ? data.rateeName : 'N/A'}
                  </span>
                </div>
                {data.freeze ? (
                  data.ratingAttributes.map((res) => (
                    <div key={res.ratingAttributeId}>
                      <EnhancedDivider className="vd-divider" />
                      <AttributeTitle
                        title={res.attributeName}
                        description={res.attributeDescription}
                      />
                      <EnhancedRate
                        tooltips={starRatingDescription}
                        value={res.rating}
                        disabled="true"
                      />
                      {res.rating ? (
                        <div className="ant-rate-text">
                          <em>{starRatingDescription[res.rating - 1]}</em>
                        </div>
                      ) : (
                        ''
                      )}
                      <p className="vd-ratings-comment">
                        <b>Comment:</b> {res.comment}
                      </p>
                    </div>
                  ))
                ) : (
                  <div>
                    <EnhancedDivider className="vd-divider" />
                    <NoFeedback />
                  </div>
                )}
              </EnhancedCol>
            ))}
          </EnhancedRow>
        </EnhancedModal>
      </React.Fragment>
    );
  }
}
export default RatingModalPR;
