import {
  EnhancedCol,
  EnhancedButton,
  EnhancedRow,
  EnhancedDrawer,
  EnhancedSelect,
  EnhancedInput,
  EnhancedForm,
  EnhancedDatePicker,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import RequiredField from 'components/RequiredField';
import moment from 'moment';

const { TextArea } = EnhancedInput;

const UpdateSuggestionStatus = (props) => {
  const {
    onClose,
    isDrawerVisible,
    dealResourceStatus,
    suggestionObj,
    dealRequirementObj,
    updateDealRequirementSuggestion,
    dealRequirementSuggestionsParams,
    dealRequirementParams,
    dealDetail,
  } = props;

  const [formData, setFormData] = useState({
    statusId: '',
    comments: '',
    statusDate: null,
  });

  useEffect(() => {
    if (suggestionObj.id) {
      setFormData({
        statusId: suggestionObj.crmcStatusId,
        comments: suggestionObj.comments,
        statusDate: suggestionObj.statusDate ? moment(suggestionObj.statusDate) : null,
      });
    }
  }, [suggestionObj]);

  const handleStatusChange = (value) => {
    setFormData({
      ...formData,
      statusId: value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      statusDate: date ? moment(date).startOf('day') : null,
    });
  };

  const handleCommentChange = (e) => {
    setFormData({
      ...formData,
      comments: e.target.value,
    });
  };

  const handleSubmit = () => {
    updateDealRequirementSuggestion(
      suggestionObj.id,
      {
        action: 'updateStatus',
        data: {
          statusId: formData.statusId,
          comments: formData.comments,
          statusDate:
            formData.statusDate ||
            (suggestionObj.suggestedDate
              ? moment(suggestionObj.suggestedDate).startOf('day')
              : null),
        },
      },
      { dealRequirementId: dealRequirementObj.id, params: dealRequirementSuggestionsParams },
      { dealId: dealDetail.id, params: dealRequirementParams },
    );
  };

  const disabledDate = (current) => {
    return current && current < moment(suggestionObj.suggestedDate).startOf('day');
  };

  return (
    <EnhancedDrawer
      visible={isDrawerVisible}
      title="Update Status"
      width={720}
      onClose={onClose}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <EnhancedForm layout="vertical" hideRequiredMark>
        <EnhancedRow type="flex" justify="space-between" align="middle">
          <EnhancedCol span={12} style={{ marginBottom: 16 }}>
            <EnhancedRow type="flex" justify="space-between" align="middle">
              <EnhancedCol span={8}>
                <b>Resource Name</b>
              </EnhancedCol>
              <EnhancedCol span={1}>:</EnhancedCol>
              <EnhancedCol span={15}>
                <span>{suggestionObj.resourceName}</span>
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow gutter={16}>
          <EnhancedCol span={12}>
            <EnhancedForm.Item>
              <RequiredField>
                <span className="color-black">Status</span>
              </RequiredField>
              <EnhancedSelect
                className="mt-8"
                name="statusId"
                onSelect={handleStatusChange}
                value={formData.statusId}
                showSearch
                optionFilterProp="children"
              >
                {dealResourceStatus
                  .filter((status) => status.group === 'userdefined')
                  .map((item) => (
                    <EnhancedSelect.Option value={item.id} key={item.id}>
                      {item.label}
                    </EnhancedSelect.Option>
                  ))}
              </EnhancedSelect>
            </EnhancedForm.Item>
          </EnhancedCol>
          <EnhancedCol span={12}>
            <EnhancedForm.Item>
              <RequiredField>
                <span className="color-black">Date</span>
              </RequiredField>
              <EnhancedDatePicker
                className="mt-8"
                onChange={handleDateChange}
                value={formData.statusDate}
                disabledDate={disabledDate}
              />
            </EnhancedForm.Item>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow gutter={16}>
          <EnhancedCol span={24}>
            <EnhancedForm.Item>
              <RequiredField>
                <span className="color-black">Comments</span>
              </RequiredField>
              <TextArea
                name="comments"
                onChange={handleCommentChange}
                placeholder="Please enter your comments"
                className="mt-8"
                rows={5}
                value={formData.comments}
              />
            </EnhancedForm.Item>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow type="flex" justify="end" align="middle">
          <EnhancedCol>
            <EnhancedButton
              style={{ width: 120 }}
              key="submit"
              type="primary"
              onClick={handleSubmit}
              disabled={!formData.statusId || !formData.comments || !formData.statusDate}
            >
              Submit
            </EnhancedButton>
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedForm>
    </EnhancedDrawer>
  );
};

export default UpdateSuggestionStatus;
