import { connect } from 'react-redux';
import {
  addGoalActions,
  updateGoalActions,
  getGoalActions,
  removeGoalActions,
  getGoalDetailsActions,
} from 'store/actions/admin/PredefinedGoalsActions';
import GoalsListing from '../../components/admin/predefinedGoals/Listing';

function mapStateToProps(state) {
  const { auth, adminGoalReducer } = state;

  const { isLoading } = adminGoalReducer;
  return {
    isLoading,
    userRole: auth.user,
    list: adminGoalReducer.goals,
    details: adminGoalReducer.goalDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addGoal: (req) => dispatch(addGoalActions(req)),
    updateGoal: (req) => dispatch(updateGoalActions(req)),
    getGoal: (req) => dispatch(getGoalActions(req)),
    getGoalDetails: (req) => dispatch(getGoalDetailsActions(req)),
    removeGoal: (req) => dispatch(removeGoalActions(req)),
  };
}

const GoalsContainer = connect(mapStateToProps, mapDispatchToProps)(GoalsListing);

export default GoalsContainer;
