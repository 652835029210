import { createSlice } from '@reduxjs/toolkit';
import { EnhancedNotification } from 'components/shared/antd';
import { showLoader, hideLoader } from 'store/actions/AppLoaderActions';
import { generateAllDealReport } from '../../utils/dealUtils';
import {
  getDeal,
  getDealName,
  getDealOwner,
  getDealStages,
  getDealTypeCount,
  getClient,
  getDealDetail,
  getDealRequirements,
  syncDeal,
  planRunner,
  addDealRequirements,
  updateDealRequirements,
  deleteDealRequirements,
  suggestResource,
  getDealRequirementsSuggestions,
  getDealEvents,
  updateDealRequirementSuggestion,
  getDealResourceStatus,
  getDealRequirementsSuggestionOtherDeals,
  getDealRequirementMatchingResources,
  getDealsSkillWise,
  getDealRequirementHistory,
} from '../../sources/DealSource';

const dealSlice = createSlice({
  name: 'deal',
  initialState: {
    deal: [],
    dealName: [],
    dealOwner: [],
    dealStages: [],
    clients: [],
    dealTypeCount: [],
    dealDetails: {},
    dealRequirements: [],
    dealRequirementsSuggestions: [],
    dealRequirementHistory: [],
    dealEvents: {},
    dealResourceStatus: [],
    dealRequirementSuggestionOtherDeals: [],
    dealRequirementMatchingResources: {},
    dealsSkillWise: [],
  },
  reducers: {
    getDealInit(state) {
      return { ...state, deal: [] };
    },
    getDealSuccess(state, action) {
      return { ...state, deal: action.payload };
    },
    getDealFailure(state) {
      return { ...state, deal: [] };
    },
    getDealNameInit(state) {
      return { ...state, dealName: [] };
    },
    getDealNameSuccess(state, action) {
      return { ...state, dealName: action.payload };
    },
    getDealNameFailure(state) {
      return { ...state, dealName: [] };
    },
    getDealOwnerInit(state) {
      return { ...state, dealOwner: [] };
    },
    getDealOwnerSuccess(state, action) {
      return { ...state, dealOwner: action.payload };
    },
    getDealOwnerFailure(state) {
      return { ...state, dealOwner: [] };
    },
    getDealStagesInit(state) {
      return { ...state, dealStages: [] };
    },
    getDealStagesSuccess(state, action) {
      return { ...state, dealStages: action.payload };
    },
    getDealStagesFailure(state) {
      return { ...state, dealStages: [] };
    },
    getDealTypeCountInit(state) {
      return { ...state, dealTypeCount: [] };
    },
    getDealTypeCountSuccess(state, action) {
      return { ...state, dealTypeCount: action.payload };
    },
    getDealTypeCountFailure(state) {
      return { ...state, dealTypeCount: [] };
    },
    getClientInit(state) {
      return { ...state, clients: [] };
    },
    getClientSuccess(state, action) {
      return { ...state, clients: action.payload };
    },
    getClientFailure(state) {
      return { ...state, clients: [] };
    },
    getDealResourceStatusInit(state) {
      return { ...state, dealResourceStatus: [] };
    },
    getDealResourceStatusSuccess(state, action) {
      return { ...state, dealResourceStatus: action.payload };
    },
    getDealResourceStatusFailure(state) {
      return { ...state, dealResourceStatus: [] };
    },
    getDealDetailInit(state) {
      return { ...state, dealDetails: {} };
    },
    getDealDetailSuccess(state, action) {
      return { ...state, dealDetails: action.payload };
    },
    getDealDetailFailure(state) {
      return { ...state, dealDetails: {} };
    },
    getDealRequirementsInit(state) {
      return { ...state, dealRequirements: [] };
    },
    getDealRequirementsSuccess(state, action) {
      return { ...state, dealRequirements: action.payload };
    },
    getDealRequirementsFailure(state) {
      return { ...state, dealRequirements: [] };
    },
    syncDealInit(state) {
      return { ...state };
    },
    syncDealSuccess(state) {
      return { ...state };
    },
    syncDealFailure(state) {
      return { ...state };
    },
    planRunnerInit(state) {
      return { ...state };
    },
    planRunnerSuccess(state) {
      return { ...state };
    },
    planRunnerFailure(state) {
      return { ...state };
    },
    addDealRequirementsInit(state) {
      return { ...state };
    },
    addDealRequirementsSuccess(state) {
      return { ...state };
    },
    addDealRequirementsFailure(state) {
      return { ...state };
    },
    deleteDealRequirementsInit(state) {
      return { ...state };
    },
    deleteDealRequirementsSuccess(state) {
      return { ...state };
    },
    deleteDealRequirementsFailure(state) {
      return { ...state };
    },
    updateDealRequirementsInit(state) {
      return { ...state };
    },
    updateDealRequirementsSuccess(state) {
      return { ...state };
    },
    updateDealRequirementsFailure(state) {
      return { ...state };
    },
    suggestResourceInit(state) {
      return { ...state };
    },
    suggestResourceSuccess(state) {
      return { ...state };
    },
    suggestResourceFailure(state) {
      return { ...state };
    },
    getDealRequirementsSuggestionsInit(state) {
      return { ...state, dealRequirementsSuggestions: [] };
    },
    getDealRequirementsSuggestionsSuccess(state, action) {
      return { ...state, dealRequirementsSuggestions: action.payload };
    },
    getDealRequirementsSuggestionsFailure(state) {
      return { ...state, dealRequirementsSuggestions: [] };
    },
    getDealRequirementHistoryInit(state) {
      return { ...state, dealRequirementHistory: [] };
    },
    getDealRequirementHistorySuccess(state, action) {
      return { ...state, dealRequirementHistory: action.payload };
    },
    getDealRequirementHistoryFailure(state) {
      return { ...state, dealRequirementHistory: [] };
    },
    getDealRequirementSuggestionOtherDealsInit(state) {
      return { ...state, dealRequirementSuggestionOtherDeals: [] };
    },
    getDealRequirementSuggestionOtherDealsSuccess(state, action) {
      return { ...state, dealRequirementSuggestionOtherDeals: action.payload };
    },
    getDealRequirementSuggestionOtherDealsFailure(state) {
      return { ...state, dealRequirementSuggestionOtherDeals: [] };
    },
    getDealEventsInit(state) {
      return { ...state, dealEvents: {} };
    },
    getDealEventsSuccess(state, action) {
      return { ...state, dealEvents: action.payload };
    },
    getDealEventsFailure(state) {
      return { ...state, dealEvents: {} };
    },
    getDealRequirementMatchingResourcesInit(state) {
      return { ...state, dealRequirementMatchingResources: {} };
    },
    getDealRequirementMatchingResourcesSuccess(state, action) {
      return { ...state, dealRequirementMatchingResources: action.payload };
    },
    getDealRequirementMatchingResourcesFailure(state) {
      return { ...state, dealRequirementMatchingResources: {} };
    },
    getDealsSkillWiseInit(state) {
      return { ...state, dealsSkillWise: [] };
    },
    getDealsSkillWiseSuccess(state, action) {
      return { ...state, dealsSkillWise: action.payload };
    },
    getDealsSkillWiseFailure(state) {
      return { ...state, dealsSkillWise: [] };
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = dealSlice;

export const getDealEventsAction = () => async (dispatch) => {
  dispatch(showLoader());
  try {
    dispatch(actions.getDealEventsInit());
    const response = await getDealEvents();
    dispatch(actions.getDealEventsSuccess(response.data.data.dealsEvents));
    dispatch(hideLoader());
  } catch (error) {
    dispatch(actions.getDealEventsFailure());
    dispatch(hideLoader());
  }
};

export const getDealAction = (request) => async (dispatch) => {
  dispatch(showLoader());
  try {
    dispatch(actions.getDealInit());
    const response = await getDeal(request);
    dispatch(actions.getDealSuccess(response.data.data));
    dispatch(hideLoader());
  } catch (error) {
    dispatch(actions.getDealFailure());
    dispatch(hideLoader());
  }
};

export const exportDealAction = (request) => async (dispatch) => {
  dispatch(showLoader());
  try {
    const response = await getDeal(request);
    generateAllDealReport(response.data.data.rows);
    dispatch(hideLoader());
  } catch (error) {
    dispatch(hideLoader());
    EnhancedNotification.error({ message: 'Unable to download all deals list.' });
  }
};

export const getDealNameAction = () => async (dispatch) => {
  dispatch(showLoader());
  try {
    dispatch(actions.getDealNameInit());
    const response = await getDealName();
    dispatch(actions.getDealNameSuccess(response.data.data.deals));
    dispatch(hideLoader());
  } catch (error) {
    dispatch(actions.getDealNameFailure());
    dispatch(hideLoader());
  }
};

export const getDealOwnerAction = () => async (dispatch) => {
  dispatch(showLoader());
  try {
    dispatch(actions.getDealOwnerInit());
    const response = await getDealOwner();
    dispatch(actions.getDealOwnerSuccess(response.data.data.dealsOwners));
    dispatch(hideLoader());
  } catch (error) {
    dispatch(actions.getDealOwnerFailure());
    dispatch(hideLoader());
  }
};

export const getDealStagesAction = () => async (dispatch) => {
  dispatch(showLoader());
  try {
    dispatch(actions.getDealStagesInit());
    const response = await getDealStages();
    dispatch(actions.getDealStagesSuccess(response.data.data.dealStages));
    dispatch(hideLoader());
  } catch (error) {
    dispatch(actions.getDealStagesFailure());
    dispatch(hideLoader());
  }
};

export const getDealTypeCountAction = (req) => async (dispatch) => {
  dispatch(showLoader());
  try {
    dispatch(actions.getDealTypeCountInit());
    const response = await getDealTypeCount(req);
    dispatch(actions.getDealTypeCountSuccess(response.data.data));
    dispatch(hideLoader());
  } catch (error) {
    dispatch(actions.getDealTypeCountFailure());
    dispatch(hideLoader());
  }
};

export const getClientAction = () => async (dispatch) => {
  dispatch(showLoader());
  try {
    dispatch(actions.getClientInit());
    const response = await getClient();
    dispatch(actions.getClientSuccess(response.data.data.clients));
    dispatch(hideLoader());
  } catch (error) {
    // EnhancedNotification.error({ message: error.response.data.message });
    dispatch(actions.getClientFailure());
    dispatch(hideLoader());
  }
};

export const getDealResourceStatusAction = () => async (dispatch) => {
  try {
    dispatch(actions.getDealResourceStatusInit());
    const response = await getDealResourceStatus();
    dispatch(actions.getDealResourceStatusSuccess(response.data.data.dealResourceStatus));
  } catch (error) {
    dispatch(actions.getDealResourceStatusFailure());
  }
};

export const getDealDetailAction = (id) => async (dispatch) => {
  dispatch(showLoader());
  try {
    dispatch(actions.getDealDetailInit());
    const response = await getDealDetail(id);
    dispatch(actions.getDealDetailSuccess(response.data.data));
    dispatch(hideLoader());
  } catch (error) {
    // EnhancedNotification.error({ message: error.response.data.message });
    dispatch(actions.getDealDetailFailure());
    dispatch(hideLoader());
  }
};

export const getDealRequirmentsAction = (request, id) => async (dispatch) => {
  dispatch(showLoader());
  try {
    dispatch(actions.getDealRequirementsInit());
    const response = await getDealRequirements(request, id);
    dispatch(actions.getDealRequirementsSuccess(response.data.data));
    dispatch(hideLoader());
  } catch (error) {
    // EnhancedNotification.error({ message: error.response.data.message });
    dispatch(actions.getDealRequirementsFailure());
    dispatch(hideLoader());
  }
};

export const syncDealAction = (dealParams) => async (dispatch) => {
  dispatch(showLoader());
  try {
    dispatch(actions.syncDealInit());
    const response = await syncDeal();
    dispatch(actions.syncDealSuccess());
    dispatch(hideLoader());

    dispatch(getDealEventsAction());
    dispatch(getDealAction(dealParams));
    dispatch(getDealTypeCountAction(dealParams));
    dispatch(getDealNameAction());
    dispatch(getDealOwnerAction());
    dispatch(getClientAction());
    dispatch(getDealStagesAction());
    EnhancedNotification.success({
      message: response.data.data && response.data.data.result && response.data.data.result.message,
    });
  } catch (error) {
    EnhancedNotification.error({ message: error.response.data.message });
    dispatch(actions.syncDealFailure());
    dispatch(hideLoader());
  }
};

export const planRunnerAction = () => async (dispatch) => {
  dispatch(showLoader());
  try {
    dispatch(actions.planRunnerInit());
    const response = await planRunner();
    dispatch(actions.planRunnerSuccess());
    dispatch(hideLoader());
    EnhancedNotification.success({
      message: response.data.data && response.data.data.result && response.data.data.result.message,
    });
  } catch (error) {
    EnhancedNotification.error({ message: error.response.data.message });
    dispatch(actions.planRunnerFailure());
    dispatch(hideLoader());
  }
};

export const addDealRequirementsAction = (request, dealId, params) => async (dispatch) => {
  dispatch(showLoader());
  try {
    dispatch(actions.addDealRequirementsInit());
    const response = await addDealRequirements(request, dealId);
    dispatch(actions.addDealRequirementsSuccess(response.data.data));
    dispatch(hideLoader());

    dispatch(getDealRequirmentsAction(params, dealId));
    EnhancedNotification.success({ message: 'Requirement created successfully.' });
  } catch (error) {
    EnhancedNotification.error({ message: error.response.data.message });
    dispatch(actions.addDealRequirementsFailure());
    dispatch(hideLoader());
  }
};

export const updateDealRequirementsAction =
  (request, dealRequirementId, dealId, params) => async (dispatch) => {
    dispatch(showLoader());
    try {
      dispatch(actions.updateDealRequirementsInit());
      const response = await updateDealRequirements(request, dealRequirementId);
      dispatch(actions.updateDealRequirementsSuccess(response.data.data));
      dispatch(hideLoader());

      dispatch(getDealRequirmentsAction(params, dealId));
      EnhancedNotification.success({ message: 'Requirement updated successfully' });
    } catch (error) {
      EnhancedNotification.error({ message: error.response.data.message });
      dispatch(actions.updateDealRequirementsFailure());
      dispatch(hideLoader());
    }
  };

export const deleteDealRequirementsAction =
  (dealRequirementId, dealId, params) => async (dispatch) => {
    dispatch(showLoader());
    try {
      dispatch(actions.deleteDealRequirementsInit());
      const response = await deleteDealRequirements(dealRequirementId);
      dispatch(actions.deleteDealRequirementsSuccess(response.data.data));
      dispatch(hideLoader());

      dispatch(getDealRequirmentsAction(params, dealId));
      EnhancedNotification.success({ message: 'Requirement deleted successfully' });
    } catch (error) {
      EnhancedNotification.error({ message: error.response.data.message });
      dispatch(actions.deleteDealRequirementsFailure());
      dispatch(hideLoader());
    }
  };

export const suggestResourceAction =
  (request, dealRequirementId, dealId, dealRequirementParams) => async (dispatch) => {
    dispatch(showLoader());
    try {
      dispatch(actions.suggestResourceInit());
      const response = await suggestResource(request, dealRequirementId);
      dispatch(actions.suggestResourceSuccess(response.data.data));
      dispatch(hideLoader());

      dispatch(getDealRequirmentsAction(dealRequirementParams, dealId));
      EnhancedNotification.success({ message: 'Resource suggested successfully' });
    } catch (error) {
      EnhancedNotification.error({ message: error.response.data.message });
      dispatch(actions.suggestResourceFailure());
      dispatch(hideLoader());
    }
  };

export const getDealRequirementsSuggestionsAction =
  (dealRequirementId, params) => async (dispatch) => {
    dispatch(showLoader());
    try {
      dispatch(actions.getDealRequirementsSuggestionsInit());
      const response = await getDealRequirementsSuggestions(dealRequirementId, params);
      dispatch(actions.getDealRequirementsSuggestionsSuccess(response.data.data));
      dispatch(hideLoader());
    } catch (error) {
      dispatch(actions.getDealRequirementsSuggestionsFailure());
      dispatch(hideLoader());
    }
  };

export const getDealRequirementHistoryAction = (dealRequirementId) => async (dispatch) => {
  dispatch(showLoader());
  try {
    dispatch(actions.getDealRequirementHistoryInit());
    const response = await getDealRequirementHistory(dealRequirementId);
    dispatch(actions.getDealRequirementHistorySuccess(response.data.data));
    dispatch(hideLoader());
  } catch (error) {
    dispatch(actions.getDealRequirementHistoryFailure());
    dispatch(hideLoader());
  }
};

export const updateDealRequirementSuggestionAction =
  (suggestionId, payload, refetchSuggestionsConfig, refetchRequirementsConfig) =>
  async (dispatch) => {
    dispatch(showLoader());
    try {
      await updateDealRequirementSuggestion(suggestionId, payload);
      dispatch(hideLoader());

      dispatch(
        getDealRequirementsSuggestionsAction(
          refetchSuggestionsConfig.dealRequirementId,
          refetchSuggestionsConfig.params,
        ),
      );
      dispatch(
        getDealRequirmentsAction(
          refetchRequirementsConfig.params,
          refetchRequirementsConfig.dealId,
        ),
      );
      EnhancedNotification.success({ message: 'Updated successfully.' });
    } catch (error) {
      EnhancedNotification.error({ message: error.response.data.message });
      dispatch(hideLoader());
    }
  };

export const getDealRequirementSuggestionOtherDealsAction =
  (suggestionId, params) => async (dispatch) => {
    dispatch(showLoader());
    try {
      dispatch(actions.getDealRequirementSuggestionOtherDealsInit());
      const response = await getDealRequirementsSuggestionOtherDeals(suggestionId, params);
      dispatch(actions.getDealRequirementSuggestionOtherDealsSuccess(response.data.data));
      dispatch(hideLoader());
    } catch (error) {
      dispatch(actions.getDealRequirementSuggestionOtherDealsFailure());
      dispatch(hideLoader());
    }
  };

export const getDealRequirementMatchingResourcesAction =
  (dealRequirementId) => async (dispatch) => {
    dispatch(showLoader());
    try {
      dispatch(actions.getDealRequirementMatchingResourcesInit());
      const response = await getDealRequirementMatchingResources(dealRequirementId);
      dispatch(actions.getDealRequirementMatchingResourcesSuccess(response.data.data));
      dispatch(hideLoader());
    } catch (error) {
      dispatch(actions.getDealRequirementMatchingResourcesFailure());
      dispatch(hideLoader());
    }
  };

export const getDealsSkillWiseAction = (request) => async (dispatch) => {
  dispatch(showLoader());
  try {
    dispatch(actions.getDealsSkillWiseInit());
    const response = await getDealsSkillWise(request);
    dispatch(actions.getDealsSkillWiseSuccess(response.data.data.result));
    dispatch(hideLoader());
  } catch (error) {
    dispatch(actions.getDealsSkillWiseFailure());
    dispatch(hideLoader());
  }
};

// Export the reducer, either as a default or named export
export default reducer;
