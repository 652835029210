import { SHOW_LOADER, HIDE_LOADER } from 'constants/AppLoaderTypes';

const initialState = {
  visible: false,
};

export default function appLoaderReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_LOADER: {
      return {
        ...state,
        visible: true,
      };
    }
    case HIDE_LOADER: {
      return {
        ...state,
        visible: false,
      };
    }
    default:
      return state;
  }
}
