import { dateFormat } from 'configs/constants';
import moment from 'moment';

export const INTERVIEW_RESULT = {
  ON_HOLD: 'ON-HOLD',
  ACCEPTED: 'PASS',
  REJECTED: 'FAIL',
};

export const INTERVIEW_RESULT_FILTER = [
  {
    text: 'On Hold',
    value: 'ON-HOLD',
  },
  {
    text: 'Pass',
    value: 'PASS',
  },
  {
    text: 'Fail',
    value: 'FAIL',
  },
];

export const INTERVIEW_RESULT_MAP = {
  'ON-HOLD': {
    value: 'ON-HOLD',
    label: 'On Hold',
    status: 'default',
    color: 'grey',
    icon: 'hourglass',
  },
  PASS: {
    value: 'PASS',
    label: 'Pass',
    status: 'success',
    color: 'green',
    icon: 'check-circle',
  },
  FAIL: {
    value: 'FAIL',
    label: 'Fail',
    status: 'error',
    color: 'red',
    icon: 'warning',
  },
};

export const XLS_FILENAME = 'Interview Tracking';

export const XLS_META = [
  {
    key: 'resourceName',
    label: 'Resource',
  },
  {
    key: 'clientName',
    label: 'Client',
  },
  {
    key: 'trackName',
    label: 'Track',
  },
  {
    key: 'scheduledAt',
    label: 'Interview Date',
    renderer: (value) => moment(value).format(dateFormat.DDMMMYYYYwithSpace),
  },
  {
    key: 'result',
    label: 'Result',
  },
  {
    key: 'clientComments',
    label: 'Client Comments',
  },
  {
    key: 'lmComments',
    label: 'LM/Lead Comments',
  },
];
