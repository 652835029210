import React, { Fragment } from 'react';
import { EnhancedButton, EnhancedCol, EnhancedRow, EnhancedCard } from 'components/shared/antd';

import {
  ROW_GUTTER_SIZE,
  FULL_GRID_SIZE,
  CURRENT_COMPENSATION as stateName,
  INPUT,
  TEXTAREA,
  DATE,
  SELECT,
  BONUS_TYPES,
  COMPENSATION_TYPES,
} from 'configs/employeeProfileConstants';
import { REQUIRED } from 'configs/validationConstants';
import {
  // createSubHeading,
  noData,
  createFields,
} from 'utils/FieldGenerateUtils';
import { generateErrorsArrayFromJSON, generateErrorObject } from 'utils/validationUtils';
// import { trimObjValues } from 'utils/objectUtils';
import { getValueForKeyinJSON } from 'utils/arrayUtils';
import { DELETE_CONFIRM_PROMPT_MESSAGE } from 'configs/messageConstants';
import EditButton from 'components/shared/employeeProfile/EditButton';
import AddButton from 'components/shared/employeeProfile/AddButton';
import confirmModal from '../../shared/ConfirmModal';

class Compensation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      [stateName]: this.props[stateName] || [],
      errors: [],
      showEditDeleteButtons: true,
      disableCreateButton: false,
      workingCardIndex: null,
      cancelSaveButtons: this.updateArrayIndexesFalse(this.props[stateName]),
    };
  }

  componentDidMount() {
    const employeeId = 'me';
    this.props.getEmployeeCompensation(employeeId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps[stateName] !== this.props[stateName]) {
      const errors = generateErrorsArrayFromJSON(nextProps[stateName], []);
      this.setState({ [stateName]: nextProps[stateName], errors });
    }
  }
  // Fill provided array indexes to false
  // When user clicks on Create or Save button, a new array created with the length of total cards
  // and make all indexes to false
  updateArrayIndexesFalse = (familyInfo) => {
    return new Array(familyInfo && familyInfo.length).fill(false);
  };

  // Update index of cancelSaveButtons array
  updatecancelSaveButtonsIndex = (index, value, fill) => {
    const cancelSaveButtons = [...this.state.cancelSaveButtons];

    // if fill check is true, filled all array indexes to false so buttons would be hidden from all cards
    if (fill) {
      cancelSaveButtons.fill(!value);
    }

    // Update boolean value of array index for currently opened card
    // true when use clicks on Edit button
    // false when use clicks on Cancel button
    cancelSaveButtons[index] = value;
    return cancelSaveButtons;
  };

  handleEdit = (index) => {
    // Fill all the cancelSaveButtons indexes to false and update current cancelSaveButtons index to true
    const cancelSaveButtons = this.updatecancelSaveButtonsIndex(index, true, true);
    this.setState({
      cancelSaveButtons,
      disableSaveButton: true,
      showEditDeleteButtons: false,
      disableCreateButton: true,
    });
  };

  handleCancel = (index) => {
    // Update current cancelSaveButtons index to false
    const cancelSaveButtons = this.updatecancelSaveButtonsIndex(index, false);
    const [...compensation] = this.state[stateName];

    // remove newly created card if user clicks on cancel button
    if (!('id' in compensation[0])) {
      compensation.shift();
      cancelSaveButtons.shift();
    }
    const propsData = [...this.props[stateName]];
    const errorsData = generateErrorsArrayFromJSON(propsData);
    this.setState({ errors: errorsData });

    this.setState({
      cancelSaveButtons,
      [stateName]: this.props[stateName],
      showEditDeleteButtons: true,
      disableCreateButton: false,
    });
  };

  handleChange = (value, key, error) => {
    // Umar : 07/11/2020 : Made generic to ease reusability
    // Json.parse used instead of Object.assign as a deep clone is needed here.
    const newData = JSON.parse(JSON.stringify(this.state[stateName]));
    // split key and index and assign value to array index key (i.e: familyInfo[0].fullName = 'Name')
    const index = key.split('-')[1];
    const field = key.split('-')[0];

    newData[index][field] = value;

    // Update specific error object and field
    const { errors } = this.state;
    errors[index] = Object.assign({}, { ...errors[index] }, { [field]: error });
    // Check to see if there is any error
    // if any, disable save button
    // if none, enable save button
    const allValid = Object.values(errors[index]).every((valueError) => valueError === false);

    this.setState({
      [stateName]: newData,
      error: false,
      disableSaveButton: !allValid,
      errors,
    });
    if (error) {
      this.setState({ disableSaveButton: true });
    }
  };

  handleCreate = () => {
    const [...compensation] = this.state[stateName];

    // Create new object and set pre-defined fullName, relationship and dateOfBirth values
    compensation.unshift({
      amount: '',
      date: '',
      reason: '',
    });
    const cancelSaveButtons = this.updateArrayIndexesFalse(compensation);
    cancelSaveButtons[0] = true;
    let newErrorObject = generateErrorObject(compensation[0], []);

    // Mutate error object according to required conditions for appropiate validation
    newErrorObject = {
      ...newErrorObject,
      amount: true,
      date: true,
      reason: true,
    };

    // const updatedArray = [familyInfo[0], ...this.state[stateName]];
    const updatedErrorsArray = [newErrorObject, ...this.state.errors];
    this.setState({
      compensation,
      errors: updatedErrorsArray,
      cancelSaveButtons,
      showEditDeleteButtons: false,
      disableCreateButton: true,
      disableSaveButton: true,
    });
  };

  handleSave = (e, index, id) => {
    e.preventDefault();
    const employeeId = this.props.match.params.id;
    if (!this.state.disableSaveButton) {
      const cancelSaveButtons = this.updateArrayIndexesFalse(this.props[stateName]);
      // const updatedObject = trimObjValues(this.state[stateName][index], [
      //   'fullName',
      //   'relationship',
      // ]);
      if (id) {
        this.props.updateEmployeeCompensation(employeeId, id, this.state[stateName][index]);
      } else {
        this.props.createEmployeeCompensation(employeeId, this.state[stateName][index]);
      }
      this.setState({
        workingCardIndex: index,
        cancelSaveButtons,
        showEditDeleteButtons: true,
        disableCreateButton: false,
      });
    }
  };

  handleDelete = (index, id) => {
    confirmModal(DELETE_CONFIRM_PROMPT_MESSAGE, () => {
      const employeeId = this.props.match.params.id;
      const [...cancelSaveButtons] = this.state.cancelSaveButtons;
      cancelSaveButtons.splice(id, 1);
      this.props.deleteEmployeeCompensation(employeeId, id);
      this.setState({ workingCardIndex: index, cancelSaveButtons });
    });
  };

  render() {
    const {
      cancelSaveButtons,
      disableSaveButton,
      disableCreateButton,
      compensation,
      showEditDeleteButtons,
    } = this.state;
    const { userPermissions, cardLoading } = this.props;
    const canCreate = false;
    const canEdit = false;
    const canDelete = false;

    return (
      <div className="mt-20 compensation">
        {/* {canCreate && ( */}
        <div className="flex justify-flex-end">
          {canCreate && (
            <AddButton
              disabled={disableCreateButton}
              clickHandler={this.handleCreate}
              buttonText="Add New Bonus"
            />
          )}
        </div>
        {/* // )} */}
        {/* {createSubHeading('Bonus')} */}
        {compensation.length ? (
          <EnhancedRow gutter={ROW_GUTTER_SIZE}>
            {compensation.map((val, index) => {
              compensation[index].type = COMPENSATION_TYPES[0].key;
              return (
                <EnhancedCol key={val.id} span={FULL_GRID_SIZE}>
                  <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
                    loading={cardLoading && index === this.state.workingCardIndex}
                    className="mb-10 br-5"
                  >
                    <div className="flex justify-flex-end">
                      <span>
                        {showEditDeleteButtons ? (
                          <Fragment>
                            {canDelete && (
                              <EnhancedButton
                                className="mr-10 btn-delete"
                                onClick={() => this.handleDelete(index, val.id)}
                                type="danger"
                              >
                                Delete
                              </EnhancedButton>
                            )}
                            {canEdit && (
                              <EditButton
                                clickHandler={() => this.handleEdit(index)}
                                buttonText="Edit"
                              />
                            )}
                          </Fragment>
                        ) : (
                          ''
                        )}
                      </span>
                      <span className="pr-10">
                        {cancelSaveButtons[index] ? (
                          <EnhancedButton
                            onClick={(e) => this.handleSave(e, index, val.id)}
                            htmlType="submit"
                            disabled={disableSaveButton}
                          >
                            Save
                          </EnhancedButton>
                        ) : (
                          ''
                        )}
                      </span>
                      <span>
                        {cancelSaveButtons[index] ? (
                          <EnhancedButton onClick={() => this.handleCancel(index)}>
                            Cancel
                          </EnhancedButton>
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                    {createFields(
                      [
                        {
                          key: `amount-${index}`,
                          label: 'Bonus Disbursed',
                          value: val.amount,
                          type: INPUT,
                          validations: [REQUIRED],
                        },
                        {
                          key: `date-${index}`,
                          label: 'Date',
                          value: val.date,
                          type: DATE,
                          validations: [REQUIRED],
                        },
                        {
                          key: `reason-${index}`,
                          label: 'Type of Bonus',
                          value: getValueForKeyinJSON(BONUS_TYPES, val.reason),
                          type: SELECT,
                          options: BONUS_TYPES,
                          validations: [REQUIRED],
                        },
                        // {
                        //   key: `type-${index}`,
                        //   label: 'Type',
                        //   value: val.type,
                        //   type: INPUT,
                        //   hidden: true,
                        // },
                        {
                          key: `commitmentUntil-${index}`,
                          label: 'Commitment Until',
                          value: val.commitmentUntil ? val.commitmentUntil : '',
                          type: DATE,
                        },
                        {
                          key: `comments-${index}`,
                          label: 'Comments',
                          value: val.comments,
                          type: TEXTAREA,
                        },
                      ],
                      2,
                      cancelSaveButtons[index],
                      userPermissions,
                      this.handleChange,
                    )}
                  </EnhancedCard>
                </EnhancedCol>
              );
            })}
          </EnhancedRow>
        ) : (
          noData()
        )}
      </div>
    );
  }
}

export default Compensation;
