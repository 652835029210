import {
  GET_EMPLOYEES_INIT,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_BIRTHDAY_SUCCESS,
  GET_EMPLOYEES_FAILURE,
  GET_EMPLOYEES_SKILLS_INIT,
  GET_EMPLOYEES_SKILLS_SUCCESS,
  GET_EMPLOYEES_SKILLS_FAILURE,
  UPDATE_EMPLOYEES_INIT,
  MARK_EMPLOYEE_INACTIVE_INIT,
  UPDATE_EMPLOYEES_SUCCESS,
  UPDATE_EMPLOYEES_FAILURE,
  DELETE_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_SUCCESS,
  ADD_SKILL_INIT,
  ADD_SKILL_SUCCESS,
  ADD_SKILL_FAILURE,
  EDIT_SKILL_SUCCESS,
  DELETE_SKILL_SUCCESS,
  GET_EMPLOYEE_DEPARTMENTS_INIT,
  GET_EMPLOYEE_DEPARTMENTS_SUCCESS,
  GET_EMPLOYEE_DEPARTMENTS_FAILURE,
  ADD_DEPARTMENT_INIT,
  ADD_DEPARTMENT_SUCCESS,
  ADD_DEPARTMENT_FAILURE,
  EDIT_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_SUCCESS,
  GET_ALL_EMPLOYEES_SUCCESS,
  GET_ALL_EMPLOYESS_FAILURE,
  GET_ALL_EMPLOYEES_INIT,
  ADD_DIVISION_INIT,
  ADD_DIVISION_SUCCESS,
  ADD_DIVISION_FAILURE,
  EDIT_DIVISION_SUCCESS,
  DELETE_DIVISION_SUCCESS,
  GET_EMPLOYEE_DIVISIONS_INIT,
  GET_EMPLOYEE_DIVISIONS_FAILURE,
  GET_EMPLOYEE_DIVISIONS_SUCCESS,
  ADD_BUSINESSUNIT_INIT,
  ADD_BUSINESSUNIT_SUCCESS,
  ADD_BUSINESSUNIT_FAILURE,
  EDIT_BUSINESSUNIT_SUCCESS,
  DELETE_BUSINESSUNIT_SUCCESS,
  ADD_COMPETENCY_INIT,
  ADD_COMPETENCY_SUCCESS,
  ADD_COMPETENCY_FAILURE,
  EDIT_COMPETENCY_SUCCESS,
  DELETE_COMPETENCY_SUCCESS,
  GET_EMPLOYEE_BUSINESSUNITS_INIT,
  GET_EMPLOYEE_BUSINESSUNITS_SUCCESS,
  GET_EMPLOYEE_BUSINESSUNITS_FAILURE,
  GET_EMPLOYEE_COMPETENCIES_INIT,
  GET_EMPLOYEE_COMPETENCIES_SUCCESS,
  GET_EMPLOYEE_COMPETENCIES_FAILURE,
  GET_USER_ATTRIBUTES_INIT,
  GET_USER_ATTRIBUTES_SUCCESS,
  GET_USER_ATTRIBUTES_FAILURE,
  GET_ALL_REPORTEES_SUCCESS,
} from 'constants/EmployeeTypes';

import {
  GET_EMPLOYEES_PROJECT_INIT,
  GET_EMPLOYEES_PROJECT_SUCCESS,
  GET_EMPLOYEES_PROJECT_FAILURE,
  DELETE_EMPLOYEES_PROJECT_INIT,
  DELETE_EMPLOYEES_PROJECT_SUCCESS,
  DELETE_EMPLOYEES_PROJECT_FAILURE,
  UPDATE_EMPLOYEES_PROJECT_INIT,
  UPDATE_EMPLOYEES_PROJECT_SUCCESS,
  UPDATE_EMPLOYEES_PROJECT_FAILURE,
  GET_EMPLOYEES_PROJECT_ROLES_INIT,
  GET_EMPLOYEES_PROJECT_ROLES_SUCCESS,
  GET_EMPLOYEES_PROJECT_ROLES_FAILURE,
} from 'constants/EmployeeProject';
import { mapEmployeePrimarySkills, mapEmployeeSecondarySkills } from 'utils/employeeUtils';

const initialState = {
  skills: [],
  departments: [],
  divisions: [],
  employees: [],
  employeesProject: [],
  allEmployees: [],
  roles: [],
  isLoading: false,
  isProjectLoading: false,
  skillLoading: false,
  businessUnitsLoading: false,
  businessUnits: [],
  competencies: [],
  competenciesLoading: false,
  userAttributes: [],
  reportees: [],
};

export default function employeeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEES_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ALL_EMPLOYEES_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        employees: action.data,
      };
    case GET_ALL_EMPLOYEES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allEmployees: action.data,
      };
    case GET_EMPLOYEES_BIRTHDAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        employees: action.data,
      };
    case GET_EMPLOYEES_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    case GET_ALL_EMPLOYESS_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    case GET_EMPLOYEES_SKILLS_INIT:
      return {
        ...state,
        skillLoading: true,
      };
    case GET_EMPLOYEES_SKILLS_SUCCESS:
      return {
        ...state,
        skillLoading: false,
        skills: action.data,
      };
    case GET_EMPLOYEES_SKILLS_FAILURE:
      return {
        ...state,
        skillLoading: false,
        ...action.data,
      };
    case GET_EMPLOYEE_DEPARTMENTS_INIT:
      return {
        ...state,
        departmentsLoading: true,
      };
    case GET_EMPLOYEE_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departmentsLoading: false,
        departments: action.data,
      };
    case GET_EMPLOYEE_DEPARTMENTS_FAILURE:
      return {
        ...state,
        departmentsLoading: false,
        ...action.data,
      };
    case GET_EMPLOYEE_BUSINESSUNITS_INIT:
      return {
        ...state,
        businessUnitsLoading: true,
      };
    case GET_EMPLOYEE_BUSINESSUNITS_SUCCESS:
      return {
        ...state,
        businessUnitsLoading: false,
        businessUnits: action.data,
      };
    case GET_EMPLOYEE_BUSINESSUNITS_FAILURE:
      return {
        ...state,
        businessUnitsLoading: false,
        ...action.data,
      };
    case GET_EMPLOYEE_COMPETENCIES_INIT:
      return {
        ...state,
        competenciesLoading: true,
      };
    case GET_EMPLOYEE_COMPETENCIES_SUCCESS:
      return {
        ...state,
        competenciesLoading: false,
        competencies: action.data,
      };
    case GET_EMPLOYEE_COMPETENCIES_FAILURE:
      return {
        ...state,
        competenciesLoading: false,
        ...action.data,
      };

    case UPDATE_EMPLOYEES_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case MARK_EMPLOYEE_INACTIVE_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_EMPLOYEES_SUCCESS: {
      const { data } = action.data;
      const emp = state.employees.filter((value) => value.id === data.id)[0];

      if (emp) {
        emp.firstName = data.firstName;
        emp.lastName = data.lastName;
        emp.phoneNumber = data.phoneNumber;
        emp.resourceType = data.resourceType;
        emp.skype = data.skype;
        emp.designation = data.designation;
        emp.managerId = data.managerId;
        emp.department = data.department;
        emp.departmentId = data.departmentId;
        emp.locationId = parseInt(data.location, 10);
        emp.genderId = data.genderId;
        emp.primarySkills = mapEmployeePrimarySkills(data.employee_skills);
        emp.secondarySkills = mapEmployeeSecondarySkills(data.employee_skills);
        emp.skills = data.employee_skills;
        emp.taleoId = data.oracleId;
        emp.joiningDate = data.joiningDate;
        emp.resignationDate = data.resignationDate;
        emp.leavingDate = data.leavingDate;
        emp.graduationDate = data.graduationDate;
        emp.careerStartDate = data.careerStartDate;
        emp.totalExperience = data.totalExperience;
        emp.businessUnit = data.businessUnit;
        emp.division = data.division;
        emp.competency = data.competency;
        emp.externalExpYears = data.externalExpYears;
        emp.externalExpMonths = data.externalExpMonths;
        emp.email = data.email;
        emp.dateOfBirth = data.dateOfBirth;
      }
      return {
        ...state,
        isLoading: false,
        ...action.data,
        employees: [...state.employees],
      };
    }
    case DELETE_EMPLOYEE_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    case UPDATE_EMPLOYEES_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    case DELETE_EMPLOYEE_SUCCESS: {
      const employee = state.employees.filter((employees) => employees.id === action.employeeId)[0];
      if (employee && employee.isActive === true) {
        employee.isActive = false;
      }
      return {
        ...state,
        isLoading: false,
        employees: [...state.employees],
      };
    }
    case GET_EMPLOYEES_PROJECT_INIT: {
      return Object.assign({}, state, {
        employeesProject: [],
        isProjectLoading: true,
      });
    }
    case GET_EMPLOYEES_PROJECT_SUCCESS: {
      return Object.assign({}, state, {
        employeesProject: action.data,
        isProjectLoading: false,
      });
    }
    case GET_EMPLOYEES_PROJECT_FAILURE: {
      return Object.assign({}, state, {
        isProjectLoading: false,
      });
    }
    case DELETE_EMPLOYEES_PROJECT_INIT: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }
    case DELETE_EMPLOYEES_PROJECT_SUCCESS: {
      let employeesProject = [...state.employeesProject];
      if (action.data.deleteEntry) {
        employeesProject = employeesProject.filter(
          (ep) => ep.employeeProjectId !== action.data.employeeProjectId,
        );
      } else {
        employeesProject = employeesProject.map((ep) => {
          if (ep.employeeProjectId !== action.data.employeeProjectId) return ep;
          const allocation = { ...ep };
          if (ep.status === true) {
            allocation.status = false;
            allocation.endDate = action.data.endDate;
          }
          return allocation;
        });
      }
      return {
        ...state,
        employeesProject,
        isLoading: false,
      };
    }
    case DELETE_EMPLOYEES_PROJECT_FAILURE: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }
    case UPDATE_EMPLOYEES_PROJECT_INIT: {
      return Object.assign({}, state, {
        isProjectLoading: true,
      });
    }
    case UPDATE_EMPLOYEES_PROJECT_SUCCESS: {
      let info = action.currentInformation;
      if (action.employeeProjectInfo) {
        info = action.employeeProjectInfo;
        info.role = action.employeeProjectInfo.currentRolesList
          ? action.employeeProjectInfo.currentRolesList
          : action.currentInformation.role;
        info.existing =
          action.currentInformation.existing !== undefined
            ? action.currentInformation.existing
            : action.employeeProjectInfo.existing;
      }

      const proj = state.employeesProject.filter(
        (value) => value.employeeProjectId === info.employeeProjectId,
      )[0];

      if (proj) {
        proj.allocation = info.allocation;
        proj.startDate = info.startDate || undefined;
        proj.endDate = info.endDate || undefined;
        proj.type = info.type;
        proj.role = info.role;
        proj.existing = info.existing;
        proj.comment = info.notes;
      }
      return {
        ...state,
        isProjectLoading: false,
        employeesProject: [...state.employeesProject],
      };
    }
    case UPDATE_EMPLOYEES_PROJECT_FAILURE: {
      return Object.assign({}, state, {
        isProjectLoading: false,
      });
    }

    case GET_EMPLOYEES_PROJECT_ROLES_INIT: {
      return Object.assign({}, state, {
        roles: [],
        isRolesLoading: true,
      });
    }
    case GET_EMPLOYEES_PROJECT_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.data,
        isRolesLoading: false,
      };
    case GET_EMPLOYEES_PROJECT_ROLES_FAILURE: {
      return Object.assign({}, state, {
        roles: [],
        isRolesLoading: false,
      });
    }

    case ADD_EMPLOYEE_SUCCESS: {
      const emp = action.data;
      const managerInfo = state.employees.filter(
        (employee) => employee.id === parseInt(emp.managerId, 10),
      );
      if (managerInfo && Array.isArray(managerInfo) && managerInfo.length > 0) {
        const [manager] = managerInfo;
        if (manager) {
          emp.managerName = `${manager.firstName} ${manager.lastName}`;
        }
      }
      const result = {
        ...state,
        employees: [...state.employees, emp],
      };
      return result;
    }
    case ADD_SKILL_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADD_SKILL_SUCCESS: {
      const skill = action.data;
      const result = {
        ...state,
        isLoading: false,
        skills: [...state.skills, skill.data],
      };
      return result;
    }
    case ADD_SKILL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    }
    case EDIT_SKILL_SUCCESS: {
      const { id, name } = action.data;
      const { skills } = state;

      const newSkills = [...skills];

      const index = newSkills.findIndex((skill) => skill.id === id);
      if (index > -1) {
        newSkills[index] = { ...newSkills[index], name };
      }

      const result = {
        ...state,
        isLoading: false,
        skills: newSkills,
      };
      return result;
    }
    case DELETE_SKILL_SUCCESS: {
      const id = action.data;
      const { skills } = state;
      const filteredSkills = skills.filter((elem) => elem.id !== id);
      return {
        ...state,
        isLoading: false,
        skills: filteredSkills,
      };
    }
    case ADD_DEPARTMENT_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADD_DEPARTMENT_SUCCESS: {
      const department = action.data;
      const result = {
        ...state,
        isLoading: false,
        departments: [...state.departments, department.data],
      };
      return result;
    }
    case ADD_DEPARTMENT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    }
    case EDIT_DEPARTMENT_SUCCESS: {
      const { id, name } = action.data;
      const { departments } = state;
      const newDepartments = [...departments];

      const index = newDepartments.findIndex((department) => department.id === id);
      if (index > -1) {
        newDepartments[index] = { ...newDepartments[index], name };
      }

      const result = {
        ...state,
        isLoading: false,
        departments: newDepartments,
      };
      return result;
    }
    case DELETE_DEPARTMENT_SUCCESS: {
      const id = action.data;
      const { departments } = state;
      const filteredDepartments = departments.filter((elem) => elem.id !== id);
      return {
        ...state,
        isLoading: false,
        departments: filteredDepartments,
      };
    }
    case GET_EMPLOYEE_DIVISIONS_INIT:
      return {
        ...state,
        divisionsLoading: true,
      };
    case GET_EMPLOYEE_DIVISIONS_SUCCESS:
      return {
        ...state,
        divisionsLoading: false,
        divisions: action.data,
      };
    case GET_EMPLOYEE_DIVISIONS_FAILURE:
      return {
        ...state,
        divisionsLoading: false,
        ...action.data,
      };
    case ADD_BUSINESSUNIT_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADD_BUSINESSUNIT_SUCCESS: {
      const businessUnit = action.data;
      const result = {
        ...state,
        isLoading: false,
        businessUnits: [...state.businessUnits, businessUnit.data],
      };
      return result;
    }
    case ADD_BUSINESSUNIT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    }
    case EDIT_BUSINESSUNIT_SUCCESS: {
      const { id, name } = action.data;
      const { businessUnits } = state;
      const newBusinessUnits = [...businessUnits];

      const index = newBusinessUnits.findIndex((businessUnit) => businessUnit.id === id);
      if (index > -1) {
        newBusinessUnits[index] = { ...newBusinessUnits[index], name };
      }
      const result = {
        ...state,
        isLoading: false,
        businessUnits: newBusinessUnits,
      };
      return result;
    }
    case DELETE_BUSINESSUNIT_SUCCESS: {
      const id = action.data;
      const { businessUnits } = state;
      const filteredBusinessUnits = businessUnits.filter((elem) => elem.id !== id);
      return {
        ...state,
        isLoading: false,
        businessUnits: filteredBusinessUnits,
      };
    }
    case ADD_DIVISION_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADD_DIVISION_SUCCESS: {
      const division = action.data;
      const result = {
        ...state,
        isLoading: false,
        divisions: [...state.divisions, division.data],
      };
      return result;
    }
    case ADD_DIVISION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    }
    case EDIT_DIVISION_SUCCESS: {
      const { id, name } = action.data;
      const { divisions } = state;

      const newDivisions = [...divisions];

      const index = newDivisions.findIndex((division) => division.id === id);
      if (index > -1) {
        newDivisions[index] = { ...newDivisions[index], name };
      }
      const result = {
        ...state,
        isLoading: false,
        divisions: newDivisions,
      };
      return result;
    }
    case DELETE_DIVISION_SUCCESS: {
      const id = action.data;
      const { divisions } = state;
      const filteredDivisions = divisions.filter((elem) => elem.id !== id);
      return {
        ...state,
        isLoading: false,
        divisions: filteredDivisions,
      };
    }
    case ADD_COMPETENCY_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADD_COMPETENCY_SUCCESS: {
      const competencies = action.data;
      const result = {
        ...state,
        isLoading: false,
        competencies: [...state.competencies, competencies.data],
      };
      return result;
    }
    case ADD_COMPETENCY_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    }
    case EDIT_COMPETENCY_SUCCESS: {
      const { id, name } = action.data;
      const { competencies } = state;
      const newCompetencies = [...competencies];

      const index = newCompetencies.findIndex((competency) => competency.id === id);
      if (index > -1) {
        newCompetencies[index] = { ...newCompetencies[index], name };
      }
      const result = {
        ...state,
        isLoading: false,
        competencies: newCompetencies,
      };
      return result;
    }
    case DELETE_COMPETENCY_SUCCESS: {
      const id = action.data;
      const { competencies } = state;
      const filteredCompetencies = competencies.filter((elem) => elem.id !== id);
      return {
        ...state,
        isLoading: false,
        competencies: filteredCompetencies,
      };
    }

    case GET_USER_ATTRIBUTES_INIT: {
      return {
        ...state,
        userAttrLoader: true,
      };
    }
    case GET_USER_ATTRIBUTES_SUCCESS: {
      return {
        ...state,
        userAttrLoader: false,
        userAttributes: action.data,
      };
    }
    case GET_USER_ATTRIBUTES_FAILURE: {
      return {
        ...state,
        userAttrLoader: false,
      };
    }
    case GET_ALL_REPORTEES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reportees: action.data,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
