import { EnhancedCard, EnhancedTabs } from 'components/shared/antd';
import React from 'react';
import { JOB_INFO, EMPLOYMENT_HISTORY } from 'configs/employeeProfileConstants';
import { prepareOptionsFromJSON } from 'utils/arrayUtils';
import PreviousEmpHistory from 'components/MyProfile/employmentHistory/previousEmpHistory';
import OrganizationEmpHistory from 'components/MyProfile/employmentHistory/organizationEmpHistory';

const { TabPane } = EnhancedTabs;

class EmploymentHistory extends React.Component {
  componentDidMount() {
    const employeeId = 'me';
    this.props.getEmployeeEmploymentHistory(employeeId);

    this.props.getEmployeeJobInfo(employeeId);
  }

  render() {
    const { isLoading: globalLoading } = this.props;
    return (
      <EnhancedCard className="mt-20" loading={globalLoading} bordered={false}>
        <EnhancedTabs defaultActiveKey="1" centered type="card">
          <TabPane tab="Within Organization" key="1">
            <OrganizationEmpHistory
              jobInfo={this.props[JOB_INFO]}
              userPermissions={this.props.userPermissions}
              employeeId="me"
              createEmployeeJobInfo={this.props.createEmployeeJobInfo}
              updateEmployeeJobInfo={this.props.updateEmployeeJobInfo}
              deleteEmployeeJobInfo={this.props.deleteEmployeeJobInfo}
              divisionOptions={this.props.divisions}
              designationOptions={this.props.designations}
              departmentOptions={prepareOptionsFromJSON(this.props.departments)}
            />
          </TabPane>
          <TabPane tab="Previous Employment" key="2">
            <PreviousEmpHistory
              employmentHistory={this.props[EMPLOYMENT_HISTORY]}
              employeeId="me"
              deleteEmployeeEmployment={this.props.deleteEmployeeEmployment}
              updateEmployeeEmployment={this.props.updateEmployeeEmployment}
              createEmployeeEmployment={this.props.createEmployeeEmployment}
              isLoading={this.props.isLoading}
              userPermissions={this.props.userPermissions}
            />
          </TabPane>
        </EnhancedTabs>
      </EnhancedCard>
    );
  }
}

export default EmploymentHistory;
