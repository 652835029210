import moment from 'moment';
import { dateFormat } from 'configs/constants';
import { DATE_FORMAT } from 'configs/employeeProfileConstants';

const DATE_MAX_VALUE = '8640000000000000';

export function extractDateFromDatetime(datetime) {
  let newDate = datetime;
  if (newDate) {
    const dateObj = new Date(newDate);
    const formattedDate = `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;
    newDate = formattedDate;
  }

  return newDate;
}

export default function convertMonthRangeToDateRange(startMonth, endMonth) {
  const startOfMonth = startMonth.startOf('month').format('YYYY-MM-DD');
  const endOfMonth = endMonth.endOf('month').format('YYYY-MM-DD');
  const dateRange = {
    startDate: startOfMonth,
    endDate: endOfMonth,
  };
  return dateRange;
}

export function getFormattedDate(date) {
  return moment(date).format(dateFormat.YYYYMMDDwithSpace);
}

export function getFormattedDateWithHyphen(date) {
  const momentDate = moment(date);
  return momentDate.isValid() ? momentDate.format(dateFormat.YYYYMMDDwithHyphen) : '';
}

export function getParameterizedFormattedDate(date, defaultDate) {
  let dateValue = '';
  if (date) {
    dateValue = getFormattedDate(date);
  } else {
    dateValue = getFormattedDate(defaultDate);
  }
  return dateValue;
}

export function isDateInRange(startDate, endDate, date) {
  let selectedDate = '';
  if (!date) {
    selectedDate = new Date();
  } else {
    selectedDate = new Date(date);
  }
  // Set start date day minus 1 if start date of project is not set.
  let startingDate = startDate !== '' ? startDate : new Date(selectedDate.getDate() - 1);
  // Set end date maximum if their is end date of project is not set.
  const releaseDate = endDate !== '' ? endDate : new Date(DATE_MAX_VALUE);
  startingDate = getFormattedDate(startingDate);
  const dateValue = getFormattedDate(selectedDate);
  const endingDate = getFormattedDate(releaseDate);

  return startingDate <= dateValue && dateValue <= endingDate;
}

export function dateRenderer(dateText, format = DATE_FORMAT) {
  return dateText ? moment(dateText).format(format) : '';
}
