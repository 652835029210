import {
  GET_LATEST_VIDEO,
  GET_YOUTUBE_VIDEOS,
  GET_EVENTS,
  GET_API_HOMEPAGE_FAILURE,
} from 'constants/HomePageTypes';
import { getHomePageVideo, getHomePageVideoLinks, getEventsSource } from 'sources/HomePageSource';

export const getHomePageVideoAction = (data) => ({
  type: GET_LATEST_VIDEO,
  data,
});

export const getYoutubeVideoActions = (data) => ({
  type: GET_YOUTUBE_VIDEOS,
  data,
});

export const getEventsAction = (data) => ({
  type: GET_EVENTS,
  data,
});

export const getAPIFAILURE = () => ({
  type: GET_API_HOMEPAGE_FAILURE,
});

export const getVideoAction = () => (dispatch) => {
  dispatch(getHomePageVideo());
};

export const getYoutubeVideoAction = () => (dispatch) => {
  dispatch(getHomePageVideoLinks());
};

export const getEvents = () => (dispatch) => {
  dispatch(getEventsSource());
};
