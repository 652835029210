import { createEmployeeWorkLog, updateEmployeeWorkLog } from 'sources/WorkLogSource';

import {
  CREATE_WORKLOG_INIT,
  CREATE_WORKLOG_SUCCESS,
  CREATE_WORKLOG_FAILURE,
  CLEAR_WORKLOG_STATUS,
} from 'constants/WorkLogTypes';

export const createWorkLogInit = () => ({
  type: CREATE_WORKLOG_INIT,
});

export const createWorkLogSuccess = (data) => ({
  type: CREATE_WORKLOG_SUCCESS,
  data,
});

export const createWorkLogFailure = (data) => ({
  type: CREATE_WORKLOG_FAILURE,
  data,
});

export const clearWorklogStatus = () => ({
  type: CLEAR_WORKLOG_STATUS,
});

export const createWorkLogAction = (req, employeeId) => createEmployeeWorkLog(req, employeeId);

export const updateWorkLogAction = (req, employeeId, workId, updateType) =>
  updateEmployeeWorkLog(req, employeeId, workId, updateType);

export const clearWorkLogStatusAction = (dispatch) => dispatch(clearWorklogStatus());
