import {
  GET_RESOURCE_AVAILABILITY_INIT,
  GET_RESOURCE_AVAILABILITY_SUCCESS,
  GET_RESOURCE_AVAILABILITY_FAILURE,
  GET_EMPLOYEE_DEPARTMENT_SUCCESS,
  GET_EMPLOYEE_DEPARTMENT_FAILURE,
} from 'constants/ResourceAvailabilityTypes';
import getResourceAvailability, { getDepartments } from 'sources/ResourceAvailabilitySource';

export const getResourceAvailabilityInit = () => ({
  type: GET_RESOURCE_AVAILABILITY_INIT,
});

export const getResourceAvailabilitySuccess = (data) => ({
  type: GET_RESOURCE_AVAILABILITY_SUCCESS,
  data,
});

export const getResourceAvailabilityFailure = () => ({
  type: GET_RESOURCE_AVAILABILITY_FAILURE,
});

export const getEmployeeDepartmentsSuccess = (data) => ({
  type: GET_EMPLOYEE_DEPARTMENT_SUCCESS,
  data,
});

export const getEmployeeDepartmentsFailure = () => ({
  type: GET_EMPLOYEE_DEPARTMENT_FAILURE,
});
// Async Action
export const getResourceAvailabilityActions = (date) => getResourceAvailability(date);
export const getEmployeeDepartmentsAction = () => getDepartments();
