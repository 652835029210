import { EnhancedIcon, EnhancedCard, EnhancedDivider } from 'components/shared/antd';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class LeavesSummaryCard extends Component {
  formatNumber = (number) => {
    if (number && number.toString().length < 2) {
      return `${number}.0`;
    }
    return number.toFixed(2);
  };

  render() {
    const {
      totalDays,
      availableDays,
      icon,
      heading,
      pendingRequests,
      onClick,
      loading,
      notes,
      disabled,
    } = this.props;

    const { showPending } = this.props;

    let pending = '';
    if (pendingRequests) {
      pending = (
        <div>
          <hr />
          <div className="request">
            <b> {pendingRequests} </b>
            Request Pending
          </div>
        </div>
      );
    } else {
      pending = (
        <div>
          <hr />
          <div className="request">No Request</div>
        </div>
      );
    }
    const hoverClass = !disabled ? 'leave-card' : 'leave-card leave-card-disabled';
    return (
      <EnhancedCard
        bordered={false}
        className={hoverClass}
        loading={loading}
        style={{ marginBottom: '7px' }}
      >
        <h4 className="heading">{heading}</h4>
        <span className="icon" style={{ display: 'block', width: '100%' }}>
          <EnhancedIcon style={{ marginRight: 15 }} type={icon} />
          <span>
            {!disabled ? this.formatNumber(availableDays) : '0.0'}
            {totalDays ? <span className="small-text"> Out of </span> : null}
            {totalDays ? this.formatNumber(totalDays) : null}
          </span>
        </span>
        <div className="days-available">Days Available</div>
        {showPending && pending}
        {!disabled && onClick && (
          <button onClick={onClick} className="leave-card-hover">
            + Apply Leave
          </button>
        )}
        {notes && (
          <div className="date-note">
            <EnhancedDivider />
            {notes}
          </div>
        )}
      </EnhancedCard>
    );
  }
}

LeavesSummaryCard.propTypes = {
  heading: PropTypes.string,
  icon: PropTypes.string,
  totalDays: PropTypes.number,
  availableDays: PropTypes.number,
  pendingRequests: PropTypes.number,
  onClick: PropTypes.func,
  showPending: PropTypes.bool,
  notes: PropTypes.string,
  disabled: PropTypes.bool,
};

LeavesSummaryCard.defaultProps = {
  icon: 'clock-circle-o',
  onClick: null,
  showPending: false,
  heading: '',
  totalDays: 0,
  availableDays: 0,
  pendingRequests: 0,
  notes: null,
  disabled: false,
};

export default LeavesSummaryCard;
