import {
  GET_EMPLOYEES_RATING_LIST_INIT,
  GET_EMPLOYEES_RATING_LIST_SUCCESS,
  GET_EMPLOYEES_RATING_LIST_FAILURE,
  GET_EMPLOYEE_RATING_INIT,
  GET_EMPLOYEE_RATING_SUCCESS,
  GET_EMPLOYEE_RATING_FAILURE,
  GET_EMPLOYEES_RATING_SUMMARY_INIT,
  GET_EMPLOYEES_RATING_SUMMARY_SUCCESS,
  GET_EMPLOYEES_RATING_SUMMARY_FAILURE,
} from 'constants/EmployeeRatingTypes';

const initialState = {
  employeeRatingList: {},
  employeeRatings: {},
  isLoading: false,
  employeeRatingSummary: {
    loading: false,
    data: [],
    error: {},
  },
};

export default function employeeRatingReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEES_RATING_LIST_INIT: {
      return Object.assign({}, state, {
        employeeRatingList: {},
        isLoading: true,
      });
    }
    case GET_EMPLOYEES_RATING_LIST_SUCCESS: {
      return Object.assign({}, state, {
        employeeRatingList: action.data,
        isLoading: false,
      });
    }
    case GET_EMPLOYEES_RATING_LIST_FAILURE: {
      return Object.assign({}, state, {
        employeeRatingList: {},
        isLoading: false,
      });
    }
    case GET_EMPLOYEE_RATING_INIT: {
      return Object.assign({}, state, {
        employeeRatings: {},
        isLoading: true,
      });
    }
    case GET_EMPLOYEE_RATING_SUCCESS: {
      return Object.assign({}, state, {
        employeeRatings: action.data,
        isLoading: false,
      });
    }
    case GET_EMPLOYEE_RATING_FAILURE: {
      return Object.assign({}, state, {
        employeeRatings: {},
        isLoading: false,
      });
    }
    case GET_EMPLOYEES_RATING_SUMMARY_INIT: {
      return Object.assign({}, state, {
        employeeRatingSummary: {
          ...state.employeeRatingSummary,
          loading: true,
          data: [],
        },
      });
    }
    case GET_EMPLOYEES_RATING_SUMMARY_SUCCESS: {
      return Object.assign({}, state, {
        employeeRatingSummary: {
          data: action.data,
          loading: false,
          error: {},
        },
      });
    }
    case GET_EMPLOYEES_RATING_SUMMARY_FAILURE: {
      return Object.assign({}, state, {
        employeeRatingSummary: {
          data: [],
          loading: false,
          error: action.data,
        },
      });
    }
    default:
      return state;
  }
}
