/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import { getVideoAction } from 'store/actions/HomePageActions';
import './VideoSection.css';

class VideoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      video: props.video,
      // showVideoTitle: true,
    };
  }

  componentWillMount() {
    this.props.getVideoAction();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.video !== this.props.video) {
      const { video } = nextProps;
      this.setState({ video });
    }
  }

  render() {
    return (
      <div className="video-container">
        {/* <iframe className="video" title="Vendians" src={this.state.video.source_url} /> */}
        <ReactPlayer
          config={{
            file: { attributes: { controlsList: 'nodownload', disablepictureinpicture: 'true' } },
          }}
          onPlay={this.hideTitle}
          url={this.state.video ? this.state.video.source_url : ''}
          width="100%"
          height="350px"
          className="react-player"
          controls
        />
        <div className="video-text-block">
          <div className="video-caption">{this.state.video ? this.state.video.title : 'N/A'}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { appReducer } = state;
  return {
    video: appReducer.video,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getVideoAction: () => dispatch(getVideoAction()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoSection);
