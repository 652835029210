export const subtractObject = (newObject, oldObject) => {
  const newObjectKeys = Object.keys(newObject);
  const updatedObject = {};
  for (let keyIndex = 0; keyIndex < newObjectKeys.length; keyIndex += 1) {
    const currentKey = newObjectKeys[keyIndex];
    if (newObject[currentKey] !== oldObject[currentKey]) {
      updatedObject[currentKey] = newObject[currentKey];
    }
  }
  return updatedObject;
};

export const isEmptyObject = (object) => JSON.stringify(object) === '{}';

export const prepareOptionsFromObject = (object = {}) => {
  const keys = Object.keys(object);
  const options = [];
  for (let index = 0; index < keys.length; index += 1) {
    const currentKey = keys[index];
    const currentValue = object[currentKey];
    options.push({ key: [currentKey], value: currentValue });
  }
  return options;
};

export const trimObjValues = (obj, keys) => {
  return Object.keys(obj).reduce((acc, curr) => {
    if (keys.includes(curr)) {
      acc[curr] = String(obj[curr]).trim();
    } else {
      acc[curr] = obj[curr];
    }
    return acc;
  }, {});
};
