import { EnhancedIcon } from 'components/shared/antd';
import React, { Component } from 'react';
import moment from 'moment';
import 'components/BarCard.css';

const messages = {
  DUE: 'Your allocation for this day is due',
  FUTURE: 'Would you like to log allocations for future?',
  WEEKEND: 'If you worked on this weekend, you can log allocation',
};

class LogWorkBar extends Component {
  onClick = () => {
    if (this.props.onAddWorkLogClicked) {
      this.props.onAddWorkLogClicked(this.props.date);
    }
  };

  isFuture = () => moment(this.props.date).isAfter(new Date(), 'days');

  isWeekend = () => {
    const weekday = moment(this.props.date).day();
    return weekday === 0 || weekday === 6;
  };

  render() {
    let message = messages.DUE;
    if (this.isFuture()) {
      message = messages.FUTURE;
    }
    if (this.isWeekend()) {
      message = messages.WEEKEND;
    }

    return (
      <div
        role="button"
        tabIndex={0}
        onClick={this.onClick}
        onKeyPress={this.onClick}
        className="bar-container-add-log-work"
      >
        <div>
          <EnhancedIcon type="plus-circle-o" className="bar-container-add-icon" />
          <span className="bar-container-add-text">{message}</span>
        </div>
      </div>
    );
  }
}

export default LogWorkBar;
