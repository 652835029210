import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import { activityLogUrls } from 'configs/constants';
import {
  getProjectActivityLogInit,
  getProjectActivityLogSuccess,
  getProjectActivityLogFailure,
} from 'store/actions/ProjectActivityLogActions';

import normalizeGetActivityLogResponse from 'normalizers/projectActivityLogNormalizers';

const getProjectActivityLog = (projectId, pageNumber) => (dispatch) => {
  let url = activityLogUrls.GET.replace('{projectId}', projectId);
  url = url.replace('{pageNumber}', pageNumber);
  dispatch(getProjectActivityLogInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedLogs = normalizeGetActivityLogResponse(response.data.data);
        dispatch(getProjectActivityLogSuccess(normalizedLogs));
      } else {
        EnhancedNotification.error({ message: 'Fetching Logs failed.' });
        dispatch(getProjectActivityLogFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({ message: 'Fetching Logs failed.' });
      dispatch(getProjectActivityLogFailure());
    });
};

export default getProjectActivityLog;
