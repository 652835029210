import React from 'react';
import { notSetVariable } from 'configs/additionalConstants';
import { EnhancedIcon, EnhancedInputNumber, EnhancedForm } from 'components/shared/antd';

const FormItem = EnhancedForm.Item;

class EmployeeEditableInputs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      externalExpYears: '',
      externalExpMonths: '',
      editable: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { externalExpYears, externalExpMonths } = nextProps;
    if (externalExpYears !== this.state.externalExpYears) {
      this.setState({ externalExpYears });
    }
    if (externalExpMonths !== this.state.externalExpMonths) {
      this.setState({ externalExpMonths });
    }
  }

  onSubmit = () => {
    const { updateInput } = this.props;
    const { externalExpYears, externalExpMonths } = this.state;
    // if (externalExpYears.length === 0 || externalExpMonths.length === 0) {
    //   EnhancedNotification.warning({
    //     message: 'Validation Error',
    //     description: `${inputName} can not be empty.`,
    //   });
    //   return;
    // }
    updateInput('externalExpYears', externalExpYears);
    updateInput('externalExpMonths', externalExpMonths);
    this.setState({ editable: false });
  };

  onRemoveCategory = () => {
    const {
      removeCategory,
      category: { categoryId: id },
      login: {
        user: { token },
      },
    } = this.props;
    removeCategory(token, { id });
  };

  onCancelUpdate = () => {
    this.setState({ editable: false });
  };
  onEdit = () => {
    const { externalExpYears, externalExpMonths } = this.props;
    this.setState({
      editable: true,
      externalExpYears,
      externalExpMonths,
    });
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value }, () => {
      if (this.props.updateInput) {
        this.props.updateInput(name, value);
      }
    });
  };

  validate = (value) => {
    if (!value || notSetVariable.includes(value)) {
      return false;
    }
    return true;
  };

  renderExperienceDetails = (externalExpYears, externalExpMonths) => {
    if (
      (externalExpYears !== null || externalExpMonths !== null) &&
      (externalExpYears > 0 || externalExpMonths > 0)
    ) {
      const yearText =
        externalExpYears !== null
          ? `${externalExpYears} ${externalExpYears > 1 ? 'Years' : 'Year'}`
          : '';
      const monthText =
        externalExpMonths !== null
          ? `${externalExpMonths} ${externalExpMonths > 1 ? 'Months' : 'Month'}`
          : '';
      return `${yearText} ${monthText}`;
    }
    return <span className="field-details">Not Set</span>;
  };

  render() {
    const { editable } = this.state;
    // eslint-disable-next-line object-curly-newline
    const { editingAllowed, externalExpYears, externalExpMonths } = this.props;
    if (!editingAllowed) {
      return (
        <div className="editable-cell">
          {this.renderExperienceDetails(externalExpYears, externalExpMonths)}
        </div>
      );
    }
    return (
      <div
        className="editable-cell"
        style={{
          cursor: 'pointer',
          ...this.props.style,
        }}
      >
        {editable ? (
          <div className="wrapper">
            <FormItem
              label="Years"
              style={{
                display: 'flex',
                marginBottom: '5px',
                textAlign: 'left',
              }}
            >
              <EnhancedInputNumber
                name="externalExpYears"
                value={externalExpYears}
                min={0}
                max={99}
                // eslint-disable-next-line arrow-parens
                onChange={(val) => this.handleChange('externalExpYears', val)}
                onPressEnter={this.onUpdateOfInput}
              />
            </FormItem>
            <FormItem label="Months" style={{ display: 'flex' }}>
              <EnhancedInputNumber
                name="externalExpMonths"
                value={externalExpMonths}
                min={0}
                max={12}
                // eslint-disable-next-line arrow-parens
                onChange={(val) => this.handleChange('externalExpMonths', val)}
                onPressEnter={this.onUpdateOfInput}
              />
            </FormItem>

            <EnhancedIcon
              type="check"
              className="input-icon check"
              style={{
                marginLeft: '0.5em',
                marginTop: '0.4rem',
                cursor: 'pointer',
                ...this.props.iconStyle,
              }}
              onClick={this.onSubmit}
              title="Click to save"
            />
            <EnhancedIcon
              type="close"
              className="input-icon close"
              style={{
                marginLeft: '0.5em',
                marginTop: '0.4rem',
                cursor: 'pointer',
                ...this.props.iconStyle,
              }}
              onClick={this.onCancelUpdate}
              title="Click to close"
            />
          </div>
        ) : (
          <div
            className="inline"
            role="presentation"
            onClick={this.onEdit}
            onKeyDown={this.onEdit}
            style={{ cursor: 'pointer' }}
          >
            {this.renderExperienceDetails(externalExpYears, externalExpMonths)}
          </div>
        )}
      </div>
    );
  }
}

export default EmployeeEditableInputs;
