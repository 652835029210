import { EnhancedTag, EnhancedTooltip, EnhancedSelect } from 'components/shared/antd';
import React from 'react';
import 'components/employees/SkillsBadge.css';
import addIcon from 'images/ic_ADD.svg';

const { Option } = EnhancedSelect;

class SkillsBage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      skills: props.skills || [],
      inputVisible: false,
      inputValue: '',
      expertise: props.expertise,
    };
  }
  componentWillReceiveProps(nextProps) {
    const { skills } = nextProps;
    this.setState({
      skills,
    });
  }
  handleClose = (removedTag) => {
    const skills = this.state.skills.filter((skill) => skill.name !== removedTag);
    this.setState({ skills });
    this.props.handleSkills(skills, removedTag);
  };
  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };
  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };
  handleInputConfirm = (e) => {
    if (e.keyCode === 13) {
      const { inputValue, expertise } = this.state;
      let { skills } = this.state;
      if (inputValue && skills.indexOf(inputValue) === -1) {
        skills = [...skills, { name: inputValue, expertise }];
      }
      this.setState({
        skills,
        inputVisible: false,
        inputValue: '',
      });
      this.props.handleSkills(skills);
    }
  };
  handleInputBlur = () => {
    this.setState({ inputVisible: false });
  };
  saveInputRef = (input) => {
    this.input = input;
  };

  render() {
    const { skills, inputVisible, inputValue } = this.state;
    const { defaultSkills, showAdd } = this.props;
    const children = defaultSkills
      .filter((item) => item.name !== '')
      .map((skill) => <Option key={skill.name}>{skill.name}</Option>);
    return (
      <div>
        {skills.length ? (
          skills.map((tag, i) => {
            const isLongTag = tag.name.length > 20;
            const tagElement = (
              <EnhancedTag
                style={{ marginBottom: '10px' }}
                key={tag.id || i}
                closable={showAdd}
                afterClose={() => this.handleClose(tag.name)}
              >
                {isLongTag ? `${tag.name.slice(0, 20)}...` : tag.name}
              </EnhancedTag>
            );
            return isLongTag ? (
              <EnhancedTooltip title={tag.name} key={tag.name}>
                {tagElement}
              </EnhancedTooltip>
            ) : (
              tagElement
            );
          })
        ) : (
          <span className="field-details"> No skills found </span>
        )}
        {inputVisible && (
          <EnhancedSelect
            mode="combobox"
            showAction="focus"
            size="small"
            style={{ width: 120 }}
            ref={this.saveInputRef}
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputBlur}
            onInputKeyDown={this.handleInputConfirm}
          >
            {children}
          </EnhancedSelect>
        )}
        {showAdd && !inputVisible && (
          <img
            src={addIcon}
            onClick={this.showInput}
            onKeyDown={this.showInput}
            alt="add"
            role="presentation"
          />
        )}
      </div>
    );
  }
}

export default SkillsBage;
