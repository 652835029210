import {
  GET_GOAL_CATEGORY_INIT,
  GET_GOAL_CATEGORY_SUCCESS,
  GET_GOAL_CATEGORY_FAILURE,
  GET_GOAL_CATEGORY_DETAILS_INIT,
  GET_GOAL_CATEGORY_DETAILS_SUCCESS,
  GET_GOAL_CATEGORY_DETAILS_FAILURE,
} from 'constants/admin/PredefinedGoalCategoryTypes';

import {
  GET_GOAL_CATEGORY_LOG_INIT,
  GET_GOAL_CATEGORY_LOG_SUCCESS,
  GET_GOAL_CATEGORY_LOG_FAILURE,
  UPDATE_GOAL_CATEGORY_DETAIL_ASSIGN_GOAL_INIT,
  UPDATE_GOAL_CATEGORY_DETAIL_ASSIGN_GOAL_SUCCESS,
  UPDATE_GOAL_CATEGORY_DETAIL_ASSIGN_GOAL_FAILURE,
} from 'constants/admin/PredefinedGoalCategoryDetailTypes';

const initialState = {
  isLoading: false,
  goalCategory: [],
  goalCategoryLogs: [],
  goalCategoryDetails: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_GOAL_CATEGORY_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_GOAL_CATEGORY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        goalCategory: action.data,
      };
    }
    case GET_GOAL_CATEGORY_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    }
    case GET_GOAL_CATEGORY_LOG_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_GOAL_CATEGORY_LOG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        goalCategoryLogs: action.data,
      };
    }
    case GET_GOAL_CATEGORY_LOG_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    }
    case UPDATE_GOAL_CATEGORY_DETAIL_ASSIGN_GOAL_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPDATE_GOAL_CATEGORY_DETAIL_ASSIGN_GOAL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case UPDATE_GOAL_CATEGORY_DETAIL_ASSIGN_GOAL_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case GET_GOAL_CATEGORY_DETAILS_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_GOAL_CATEGORY_DETAILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        goalCategoryDetails: action.data,
      };
    }
    case GET_GOAL_CATEGORY_DETAILS_FAILURE:
    default:
      return state;
  }
}
