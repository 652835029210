import { EnhancedRow, EnhancedCol, EnhancedButton, EnhancedIcon } from 'components/shared/antd';
import React, { useState, useEffect } from 'react';

import EmployeesList from 'components/shared/PMDashboard/EmployeesList';
import AssignGoalFormModal from './AssignGoalFormModal';

const getCustomizedColumns = () => {
  const columns = [
    {
      title: 'Grade',
      dataIndex: 'grade',
      key: 'grade',
      config: {
        needFilter: true,
        showInTable: true,
        showInExport: true,
      },
      sorter: (a, b) => a.grade.localeCompare(b.grade),
    },
    {
      title: 'Sub grade',
      dataIndex: 'subGrade',
      key: 'subGrade',
      config: {
        needFilter: false,
        showInTable: true,
        showInExport: true,
      },
    },
  ];
  return columns;
};

const EmployeeListing = (props) => {
  const {
    employees,
    isLoading,
    assignGoalCategory,
    divisions,
    departments,
    competencies,
    businessUnits,
  } = props;

  const [currentEmployees, setCurrentEmployees] = useState([]);

  const getCurrentEmployees = () => {
    const currentEmployeesData = employees.filter((emp) => emp.isActive === true);
    const normalized = currentEmployeesData.map((employee) => {
      return {
        ...employee,
        division: employee.division ? employee.division.name : '',
        department: employee.department ? employee.department.name : '',
        businessUnit: employee.businessUnit ? employee.businessUnit.name : '',
        competency: employee.competency ? employee.competency.name : '',
      };
    });
    setCurrentEmployees(normalized);
  };

  useEffect(() => {
    getCurrentEmployees();
  }, [employees]);

  const [isGoalFormModalVisible, setIsGoalFormModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const showGoalFormModal = () => {
    setIsGoalFormModalVisible(true);
  };

  const handleGoalFormOk = () => {
    setIsGoalFormModalVisible(false);
    setSelectedRowKeys([]);
  };

  const handleGoalFormCancel = () => {
    setIsGoalFormModalVisible(false);
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowsKeys) => {
      setSelectedRowKeys(selectedRowsKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <React.Fragment>
      <EnhancedRow type="flex" justify="end" align="middle" className="mt-20 mb-20">
        <EnhancedCol>
          <EnhancedButton
            className="btn-edit"
            disabled={!hasSelected}
            onClick={() => showGoalFormModal(false)}
          >
            <EnhancedIcon type="plus-circle" />
            <span>Assign Goal Category</span>
          </EnhancedButton>
        </EnhancedCol>
      </EnhancedRow>
      <div>
        <span
          style={{
            marginLeft: 8,
          }}
        >
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
        </span>
      </div>
      <EmployeesList
        employees={currentEmployees}
        divisions={divisions}
        departments={departments}
        competencies={competencies}
        businessUnits={businessUnits}
        rowSelection={rowSelection}
        rowKey={(record) => record.id}
        showExportButton={false}
        customizedColumns={getCustomizedColumns()}
      />
      <AssignGoalFormModal
        isModalVisible={isGoalFormModalVisible}
        handleOk={handleGoalFormOk}
        categoryId={props.match.params.categoryId}
        handleCancel={handleGoalFormCancel}
        assignGoalCategory={assignGoalCategory}
        isLoading={isLoading}
        selectedEmployeeIds={selectedRowKeys}
      />
    </React.Fragment>
  );
};

export default EmployeeListing;
