import moment from 'moment';
import { dateFormat } from 'configs/constants';

export const normalizeAllGoalsList = (goal) => {
  const { employee } = goal;
  return {
    employeeId: employee.taleoId,
    employeeName: employee.name,
    employeeCompetency: employee.competency,
    employeeDepartment: employee.department,
    dueDate: moment(goal.dueDate).format(dateFormat.DDMMMYYYYwithSpace) || '',
    status: goal.status.replace(/^[a-z]/, (x) => x.toUpperCase()) || '',
    title: goal.title,
    progress: goal.progress,
    weightage: goal.weightage,
  };
};

export function normalizeAllGoals(goals) {
  return goals.map((lq) => normalizeAllGoalsList(lq));
}
