import {
  EnhancedButton,
  EnhancedCol,
  EnhancedRow,
  EnhancedCard,
  EnhancedDivider,
  EnhancedSpin,
  EnhancedModal,
  EnhancedSelect,
  EnhancedDatePicker,
  EnhancedInput,
  EnhancedCheckbox,
} from 'components/shared/antd';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import LeavesSummaryCard from 'components/leaves/LeavesSummaryCard';
import { leaveStatus, commonLeaves, leaveTypes } from 'configs/leaveConstants';
import { gaEvent } from 'sources/AnalyticsSource';
import { categories, events } from 'configs/gaConstants';
import { dateFormat } from 'configs/constants';
import { genders } from 'configs/additionalConstants';

const { Option } = EnhancedSelect;
const { TextArea } = EnhancedInput;

export const leaveMode = {
  COMMON: 'common',
  ALL: 'all',
  OTHER: 'other',
};

class ApplyLeaveModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorText: false,
      visible: props.visible,
      leaveType: props.leaveType,
    };
  }

  componentDidMount() {
    this.props.getLeaveTypes();
    this.props.getPublicHolidays();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.visible !== this.state.visible) {
      this.setState({
        visible: nextProps.visible,
      });
    }

    const { selectedLeave, isEdit } = nextProps;
    const { requestLoader } = this.props;
    if (!this.state.visible) {
      let startDate = null;
      let endDate = null;
      let workingDays = null;
      let { leaveType } = nextProps;
      let { isHalfDay, requesterNote } = selectedLeave || {};
      isHalfDay = isHalfDay || false;
      requesterNote = requesterNote || null;
      if (isEdit && selectedLeave) {
        startDate = moment.utc(selectedLeave.startDate);
        endDate = moment.utc(selectedLeave.endDate);
        workingDays = selectedLeave.duration;
        leaveType = this.props.leaveTypes.filter(
          (leave) => leave.id === selectedLeave.leaveTypeId,
        )[0].id;
      }
      this.setState({
        startDate,
        endDate,
        leaveType,
        isHalfDay,
        requesterNote,
        workingDays,
      });
    } else if (nextProps.workingDays !== this.props.workingDays) {
      this.setState({ workingDays: nextProps.workingDays });
    }

    if (!nextProps.requestLoader && requestLoader) this.hideModal();
  }

  onStartDateChange = (momentDate) => {
    const { endDate } = this.state;
    if (endDate && momentDate) {
      this.callTotalCountHandler({ startDate: momentDate, endDate });
    }
    this.setState({ startDate: momentDate, isHalfDay: false });
  };

  onEndDateChange = (momentDate) => {
    const { startDate } = this.state;
    if (startDate && momentDate) {
      this.callTotalCountHandler({ startDate, endDate: momentDate });
    }
    this.setState({ endDate: momentDate, isHalfDay: false });
  };

  onTypeChange = (value) => {
    this.setState({ leaveType: value });
  };

  onHalfDayChanged = (event) => {
    const { startDate, endDate } = this.state;
    if (startDate && endDate) {
      this.callTotalCountHandler({ startDate, endDate, isHalfDay: event.target.checked });
    }
    this.setState({ isHalfDay: event.target.checked });
  };

  onNotesChanges = (event) => {
    this.setState({ requesterNote: event.target.value });
  };

  onSubmit = () => {
    const { startDate, endDate, isHalfDay, leaveType, requesterNote } = this.state;

    if (requesterNote.trim() === '') {
      this.setState({ errorText: 'Notes are required' });
      return false;
    }
    this.setState({ errorText: '' });

    gaEvent({ category: categories.APPLY_LEAVE, action: events.REQUEST });

    const { isEdit, selectedLeave, userId, requestEditLeave, requestLeave } = this.props;
    if (!startDate || !endDate) {
      return true;
    }

    const isDateValid = endDate.isSameOrAfter(startDate);
    if (isDateValid) {
      const requestObject = {
        isHalfDay,
        startDate: startDate.format(dateFormat.YYYYMMDDwithHyphen),
        endDate: endDate.format(dateFormat.YYYYMMDDwithHyphen),
        employeeId: userId,
        requesterNote,
        leaveType,
      };
      if (isEdit) {
        requestObject.id = selectedLeave.id;
        requestEditLeave({ ...requestObject });
      } else {
        requestLeave(requestObject);
      }
    }
    return true;
  };

  getHolidays = (type) => {
    const { workingHolidays } = this.props;
    const holidays = workingHolidays.map((value) => value.date);
    return holidays.find((date) => date === moment(type).format('MM-DD'));
  };

  calculateAnnualCount = () => {
    const { startDate } = this.state;
    let monthDif = 0;
    if (startDate) {
      monthDif = moment().diff(startDate, 'months');
    }
    const { currentBalance } = this.props;
    return currentBalance.annualAvailable + Math.abs(monthDif) * currentBalance.carryForward;
  };

  hideModal = () => {
    gaEvent({ category: categories.APPLY_LEAVE, action: events.CANCEL });
    this.setState({ visible: false });
    this.props.onHideModal();
  };

  callTotalCountHandler = (params) => {
    const { startDate, endDate, isHalfDay = false } = params;
    const updatedParams = {
      startDate: startDate.format(dateFormat.YYYYMMDDwithHyphen),
      endDate: endDate.format(dateFormat.YYYYMMDDwithHyphen),
      isHalfDay,
    };
    this.props.getTotalLeavesCount(updatedParams);
  };

  disabledStartDate = (startDate) => {
    const { endDate } = this.state;
    const holidays = this.getHolidays(startDate);
    if (!startDate || !endDate) {
      return holidays;
    }
    return startDate.valueOf() > endDate.valueOf() || holidays;
  };

  disabledEndDate = (endDate) => {
    const holidays = this.getHolidays(endDate);
    const { startDate } = this.state;
    if (!endDate || !startDate) {
      return holidays;
    }
    return endDate.valueOf() <= startDate.valueOf() - 1 || holidays;
  };

  render() {
    const { startDate, endDate, leaveType, requesterNote, visible, workingDays } = this.state;
    const { gender, selectedLeave, isEdit, loading } = this.props;
    const mode = this.props.leaveMode;

    const { sickTotal, sickAvailable, casualTotal, casualAvailable } = this.props.currentBalance;

    const currentStatus = selectedLeave && selectedLeave.currentStatus;
    const requestEnable = startDate && endDate && leaveType && requesterNote && workingDays;
    let filteredLeaves = this.props.leaveTypes.filter((leave) => {
      if (mode === leaveMode.OTHER) return !commonLeaves.includes(leave.id);
      if (mode === leaveMode.COMMON) return commonLeaves.includes(leave.id);
      return true;
    });

    filteredLeaves = filteredLeaves.filter((leave) => {
      if (gender === genders.FEMALE && leave.id === leaveTypes.PATERNITY) {
        return false;
      } else if (gender === genders.MALE && leave.id === leaveTypes.MATERNITY) {
        return false;
      }
      return true;
    });

    return (
      <div>
        <EnhancedModal
          width="80%"
          visible={visible}
          footer={null}
          bodyStyle={{
            backgroundColor: '#f0f2f5',
            padding: '3vw',
          }}
          onCancel={this.hideModal}
        >
          <EnhancedRow gutter={8}>
            <EnhancedCol lg={16}>
              <EnhancedCard className="leave-form">
                <div className="leave-form-header">
                  <h4 className="left">Request Leave</h4>
                  <h4 className="right">
                    Applying on: {moment().utc().format(dateFormat.DDMMMYYYYwithSpace)}{' '}
                  </h4>
                </div>
                <EnhancedDivider />
                <EnhancedRow>
                  <EnhancedCol lg={3}>From:</EnhancedCol>
                  <EnhancedCol lg={8}>
                    <EnhancedDatePicker
                      disabledDate={this.disabledStartDate}
                      onChange={this.onStartDateChange}
                      value={startDate}
                      style={{ width: '100%' }}
                      required
                    />
                  </EnhancedCol>
                  <EnhancedCol offset={3} lg={2}>
                    To:
                  </EnhancedCol>
                  <EnhancedCol lg={8}>
                    <EnhancedDatePicker
                      disabledDate={this.disabledEndDate}
                      style={{ width: '100%' }}
                      value={endDate}
                      onChange={this.onEndDateChange}
                      disabled={!startDate}
                      defaultPickerValue={startDate}
                      required
                    />
                  </EnhancedCol>
                </EnhancedRow>
                <EnhancedRow className="marginTop15">
                  <EnhancedCol lg={3}>Type:</EnhancedCol>
                  <EnhancedCol lg={21}>
                    <EnhancedSelect
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select Leave Type"
                      onChange={this.onTypeChange}
                      value={this.state.leaveType}
                      disabled={isEdit && currentStatus !== leaveStatus.PENDING}
                      required
                    >
                      {filteredLeaves.map((type) => (
                        <Option key={type.id} value={type.id}>
                          {type.name}
                        </Option>
                      ))}
                    </EnhancedSelect>
                  </EnhancedCol>
                </EnhancedRow>
                <EnhancedRow className="marginTop15">
                  <EnhancedCol offset={3}>
                    <EnhancedCheckbox
                      disabled={workingDays !== 1 && workingDays !== 0.5}
                      onChange={this.onHalfDayChanged}
                      checked={this.state.isHalfDay}
                    />{' '}
                    &nbsp; Mark as Half Day
                  </EnhancedCol>
                </EnhancedRow>
                <EnhancedRow className="marginTop15">
                  <EnhancedCol lg={3}>Amount of leaves:</EnhancedCol>
                  <EnhancedCol lg={19}>
                    {loading ? (
                      <EnhancedSpin size="small" style={{ marginLeft: '40%' }} />
                    ) : (
                      `${+workingDays || 0} day${workingDays > 1 ? 's' : ''}`
                    )}
                  </EnhancedCol>
                </EnhancedRow>
                <EnhancedRow className="marginTop15">
                  <EnhancedCol lg={3}>Note:</EnhancedCol>
                  <EnhancedCol lg={21}>
                    <TextArea
                      onChange={this.onNotesChanges}
                      rows={4}
                      style={{ width: '100%', resize: 'none' }}
                      resize="none"
                      value={this.state.requesterNote}
                      required
                    />
                    {this.state.errorText && <div className="error">{this.state.errorText}</div>}
                  </EnhancedCol>
                </EnhancedRow>
                <EnhancedRow className="marginTop15">
                  <EnhancedCol
                    offset={13}
                    lg={5}
                    md={5}
                    sm={18}
                    xs={18}
                    className="leave-modal-buttons"
                  >
                    <EnhancedButton size="large" style={{ width: '100%' }} onClick={this.hideModal}>
                      Cancel
                    </EnhancedButton>
                  </EnhancedCol>
                  <EnhancedCol offset={1} lg={5} sm={18} xs={18} className="leave-modal-buttons">
                    <EnhancedButton
                      type="primary"
                      size="large"
                      style={{ width: '100%' }}
                      onClick={this.onSubmit}
                      loading={this.props.requestLoader}
                      disabled={!requestEnable}
                    >
                      Request
                    </EnhancedButton>
                  </EnhancedCol>
                </EnhancedRow>
              </EnhancedCard>
            </EnhancedCol>
            <EnhancedCol lg={8}>
              <div>
                <LeavesSummaryCard
                  showPending={false}
                  heading="Casual Leaves"
                  icon="clock-circle-o"
                  availableDays={casualAvailable}
                  totalDays={casualTotal}
                  consumedDays="4.0"
                />
              </div>
              <div style={{ marginTop: 5 }}>
                <LeavesSummaryCard
                  showPending={false}
                  heading="Sick Leaves"
                  icon="clock-circle-o"
                  availableDays={sickAvailable}
                  totalDays={sickTotal}
                />
              </div>
              <div style={{ marginTop: 5 }}>
                <LeavesSummaryCard
                  showPending={false}
                  heading="Annual Leaves"
                  icon="clock-circle-o"
                  availableDays={this.props.annualAvailable}
                />
              </div>
            </EnhancedCol>
          </EnhancedRow>
        </EnhancedModal>
      </div>
    );
  }
}

export default ApplyLeaveModal;

ApplyLeaveModal.propTypes = {
  leaveMode: PropTypes.string,
};

ApplyLeaveModal.defaultProps = {
  leaveMode: leaveMode.ALL,
};
