import {
  EnhancedCol,
  EnhancedButton,
  EnhancedIcon,
  EnhancedCard,
  EnhancedRow,
  EnhancedDivider,
  EnhancedLayout,
  EnhancedModal,
  EnhancedInput,
} from 'components/shared/antd';
import React, { Component, Fragment } from 'react';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';

const { Header } = EnhancedLayout;
const { confirm } = EnhancedModal;

class Locations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editVisible: false,
      addVisible: false,
      locationName: '',
      editLocationName: '',
      selectedRecord: null,
      locations: this.props.locations || [],
    };
  }

  componentDidMount() {
    this.props.getAllLocations();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.locations !== this.props.locations) {
      this.setState({ locations: nextProps.locations });
    }
  }

  getColumnsInfo = () => {
    const columns = [
      {
        title: 'Location',
        dataIndex: 'name',
        key: 'name',
        width: '10%',
        filterConfig: {
          type: 'search',
          key: 'name',
        },
      },
      {
        title: 'Action',
        key: 'action',
        width: '5%',
        render: (text, record) => (
          <span>
            <EnhancedIcon
              data-testid="edit-icon"
              type="edit"
              style={{ cursor: 'pointer' }}
              onClick={() => this.showEditLocationModal(record)}
            />
            <EnhancedDivider type="vertical" />
            <EnhancedIcon
              data-testid="delete-icon"
              type="delete"
              style={{ cursor: 'pointer' }}
              onClick={() => this.showDeleteLocationModal(record)}
            />
          </span>
        ),
      },
    ];
    return columns;
  };

  showEditLocationModal = (record) => {
    this.setState({
      editVisible: true,
      selectedRecord: record,
      editLocationName: record.name,
    });
  };

  hideEditLocationModal = () => {
    this.setState({
      editVisible: false,
      addVisible: false,
      selectedRecord: null,
      editLocationName: '',
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleAddLocation = () => {
    const { locationName } = this.state;
    const { createLocation } = this.props;
    createLocation({ name: locationName });
    this.setState({ locationName: '', addVisible: false });
  };

  handleEditLocation = () => {
    const { selectedRecord, editLocationName } = this.state;
    const { updateLocation } = this.props;
    updateLocation({ id: selectedRecord.id, name: editLocationName });
    this.setState({ selectedRecord: null, editLocationName: '', editVisible: false });
  };

  handleDeleteLocation = (record) => {
    const { deleteLocation } = this.props;
    deleteLocation({ id: record.id });
  };

  showAddLocationModal = () => {
    this.setState({ addVisible: true });
  };

  showDeleteLocationModal = (record) => {
    const { handleDeleteLocation } = this;
    confirm({
      title: 'Are you sure you want to delete this Location?',
      content: 'Note: Locations associated with employees cannot be deleted',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteLocation(record);
      },
    });
  };

  editLocation = () => {
    const { editVisible, addVisible, editLocationName, locationName } = this.state;
    const locationValue = editVisible ? editLocationName : locationName;
    return (
      <EnhancedModal
        title={editVisible ? 'Edit Location' : 'Add Location'}
        visible={editVisible || addVisible}
        onCancel={this.hideEditLocationModal}
        footer={[
          <EnhancedButton key="back" onClick={this.hideEditLocationModal}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            data-testid="modal-save-button"
            key="submit"
            type="primary"
            onClick={editVisible ? this.handleEditLocation : this.handleAddLocation}
            disabled={!locationValue || locationValue === ''}
          >
            Save
          </EnhancedButton>,
        ]}
      >
        <EnhancedRow className="edit-skill">
          <EnhancedCol span={24}>
            <EnhancedInput
              name={editVisible ? 'editLocationName' : 'locationName'}
              value={locationValue}
              onChange={this.handleChange}
              placeholder="Enter Location"
            />
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedModal>
    );
  };

  render() {
    const columns = this.getColumnsInfo();
    const { locations } = this.state;
    const { isLoading } = this.props;
    return (
      <Fragment>
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol lg={12}>
              <h1 className="heading-pro">Locations</h1>
            </EnhancedCol>
            <EnhancedCol span={6} offset={6}>
              <EnhancedButton
                type="primary"
                onClick={this.showAddLocationModal}
                style={{ float: 'right', marginTop: '5%' }}
              >
                {' '}
                Add New Location
              </EnhancedButton>
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        {this.editLocation()}
        <div style={{ background: 'white' }}>
          <EnhancedCard loading={isLoading} bordered={false}>
            <EnhancedRow />
          </EnhancedCard>
          <div>
            <EnhancedClientSideTable
              style={{ marginLeft: '25px' }}
              loading={isLoading}
              columns={columns}
              data={locations}
              exportFileConfig={{
                fileName: 'Locations',
                fileHeaders: [
                  {
                    label: 'Location',
                    key: 'name',
                  },
                ],
                showExportButton: true,
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Locations;
