/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import {
  getGoalCategoryInit,
  getGoalCategorySuccess,
  getGoalCategoryFailure,
  getGoalCategoryDetailsInit,
  getGoalCategoryDetailsSuccess,
  getGoalCategoryDetailsFailure,
  addGoalCategoryInit,
  addGoalCategorySuccess,
  addGoalCategorylFailure,
  updateGoalCategoryInit,
  updateGoalCategorySuccess,
  updateGoalCategorylFailure,
  deleteGoalCategoryInit,
  deleteGoalCategorySuccess,
  deleteGoalCategoryFailure,
} from 'store/actions/admin/PredefinedGoalCategoriesActions';
import { adminPredefinedGoalCategoriesUrls } from 'configs/constants';

export const getGoalCategory = (request) => (dispatch) => {
  const url = adminPredefinedGoalCategoriesUrls.GET;
  dispatch(getGoalCategoryInit());
  axios
    .get(url, { params: request })
    .then((response) => {
      if (response.status === 200) {
        dispatch(getGoalCategorySuccess(response.data.data));
      } else {
        dispatch(getGoalCategoryFailure());
      }
    })
    .catch((error) => {
      dispatch(getGoalCategoryFailure(error));
    });
};

export const getGoalCategoryDetails = (request) => (dispatch) => {
  const url = adminPredefinedGoalCategoriesUrls.GET_DETAILS.replace(
    '{userId}',
    request.userId,
  ).replace('{id}', request.id);
  dispatch(getGoalCategoryDetailsInit());
  axios
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        //   const resourceNormalized = normalizeResourceAvailability(response.data.data, date);
        dispatch(getGoalCategoryDetailsSuccess(response.data.data));
      } else {
        dispatch(getGoalCategoryDetailsFailure());
      }
    })
    .catch((error) => {
      dispatch(getGoalCategoryDetailsFailure(error));
    });
};

export const addGoalCategory = (request) => (dispatch) => {
  const url = adminPredefinedGoalCategoriesUrls.CREATE;
  const { payload, parameters } = request;
  dispatch(addGoalCategoryInit());
  axios
    .post(url, payload)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Created Successfully',
        });
        dispatch(addGoalCategorySuccess(response.data));
        dispatch(getGoalCategory(parameters));
      } else {
        EnhancedNotification.error({
          message: 'Error in creating a new goal',
        });
        dispatch(addGoalCategorylFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in creating a new goal.',
      });
      dispatch(addGoalCategorylFailure());
    });
};

export const updateGoalCategory = (request) => (dispatch) => {
  const { id, payload, params } = request;
  const url = adminPredefinedGoalCategoriesUrls.UPDATE.replace('{userId}', request.userId).replace(
    '{id}',
    id,
  );
  dispatch(updateGoalCategoryInit());
  axios
    .put(url, payload)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Updated Successfully',
        });
        dispatch(updateGoalCategorySuccess(response.data));
        dispatch(getGoalCategory(params));
      } else {
        EnhancedNotification.error({
          message: 'Error in updating a new goal',
        });
        dispatch(updateGoalCategorylFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in updating a new goal.',
      });
      dispatch(updateGoalCategorylFailure());
    });
};

export const removeGoalCategory = (request) => (dispatch) => {
  const { id, params } = request;
  const url = adminPredefinedGoalCategoriesUrls.DELETE.replace('{userId}', request.userId).replace(
    '{id}',
    id,
  );
  dispatch(deleteGoalCategoryInit());
  axios
    .delete(url)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Deleted Successfully',
        });

        dispatch(deleteGoalCategorySuccess(response.data));
        dispatch(getGoalCategory(params));
      } else {
        EnhancedNotification.error({
          message: 'Error in deleting goal.',
        });
        dispatch(deleteGoalCategoryFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({ message: error.response.message || 'Error in deleting goal.' });
      dispatch(deleteGoalCategoryFailure(error));
    });
};
