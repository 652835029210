import { connect } from 'react-redux';
import Skills from 'components/skills/Skills';
import {
  getEmployeesSkillsActions,
  addSkillActions,
  editSkillActions,
  deleteSkillActions,
} from 'store/actions/EmployeeActions';

function mapStateToProps(state) {
  const { employeeReducer, auth } = state;
  const { skills, isLoading } = employeeReducer;
  return {
    defaultSkills: skills,
    isLoading,
    userId: auth.user && auth.user.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEmployeesSkillsAction: () => dispatch(getEmployeesSkillsActions()),
    addSkill: (request) => dispatch(addSkillActions(request)),
    editSkill: (request) => dispatch(editSkillActions(request)),
    deleteSkill: (request) => dispatch(deleteSkillActions(request)),
  };
}

const SkillsContainer = connect(mapStateToProps, mapDispatchToProps)(Skills);

export default SkillsContainer;
