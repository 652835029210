import {
  GET_LOCATION_INIT,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAILURE,
  CREATE_LOCATION_INIT,
  CREATE_LOCATION_SUCCESS,
  CREATE_LOCATION_FAILURE,
  DELETE_LOCATION_INIT,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
  UPDATE_LOCATION_INIT,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILURE,
} from 'constants/LocationTypes';
import {
  getAllLocations,
  createNewLocation,
  deleteLocation,
  updateLocation,
} from 'sources/LocationsSource';

export const getLocationInit = () => ({
  type: GET_LOCATION_INIT,
});

export const getLocationSuccess = (data) => ({
  type: GET_LOCATION_SUCCESS,
  data,
});

export const getLocationFailure = () => ({
  type: GET_LOCATION_FAILURE,
});

export const createLocationInit = () => ({
  type: CREATE_LOCATION_INIT,
});

export const createLocationSuccess = (data) => ({
  type: CREATE_LOCATION_SUCCESS,
  data,
});

export const createLocationFailure = () => ({
  type: CREATE_LOCATION_FAILURE,
});

export const deleteLocationInit = () => ({
  type: DELETE_LOCATION_INIT,
});

export const deleteLocationSuccess = (data) => ({
  type: DELETE_LOCATION_SUCCESS,
  data,
});

export const deleteLocationFailure = () => ({
  type: DELETE_LOCATION_FAILURE,
});

export const updateLocationInit = () => ({
  type: UPDATE_LOCATION_INIT,
});

export const updateLocationSuccess = (data) => ({
  type: UPDATE_LOCATION_SUCCESS,
  data,
});

export const updateLocationFailure = () => ({
  type: UPDATE_LOCATION_FAILURE,
});

// Async Actions

export const getAllLocationActions = () => getAllLocations();

export const createLocationActions = (data) => createNewLocation(data);

export const deleteLocationActions = (data) => deleteLocation(data);

export const updateLocationActions = (data) => updateLocation(data);
