import { EnhancedCard, EnhancedTabs } from 'components/shared/antd';
import React from 'react';
import EmployeeInfo from 'components/MyProfile/employeeInfo';
import Tracking from 'components/MyProfile/tracking';
import EmployeeProfileHeader from 'components/MyProfile/header';
import Compensation from 'components/MyProfile/compensation';
import GoalsReviews from 'components/MyProfile/goalsReviews';
import TalentManagement from 'components/MyProfile/talentManagment';
import EmploymentHistory from 'components/MyProfile/employmentHistory';
import Documents from 'components/MyProfile/documents';
import { isEmptyObject } from 'utils/objectUtils';
import { BREADCRUMBS } from 'configs/constants';

const { TabPane } = EnhancedTabs;

class MyProfile extends React.Component {
  componentWillMount() {
    const employeeId = 'me';
    this.props.getEmployeeProfileHeader(employeeId);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.profileHeader !== nextProps.profileHeader) {
      this.updateBreadCrumbs(nextProps.profileHeader, 'me');
    }
  }

  updateBreadCrumbs = (data, id) => {
    if (!isEmptyObject(data) && id) {
      this.props.updateBreadCrumb(BREADCRUMBS.EMPLOYEE, id, data.fullName);
    }
  };

  renderTab = (tab) => {
    const { key, title } = tab;
    const Component = tab.component;
    return (
      <TabPane key={key} tab={title}>
        <Component {...this.props} />
      </TabPane>
    );
  };

  render() {
    const employeeId = 'me';
    const tabsData = [
      {
        key: 'employee-info',
        title: 'Employee Info',
        component: EmployeeInfo,
      },
      {
        key: 'tracking',
        title: 'Tracking',
        component: Tracking,
      },
      {
        key: 'compensation',
        title: 'Bonus',
        component: Compensation,
      },
      {
        key: 'goals-and-reviews',
        title: 'Goals and Reviews',
        component: GoalsReviews,
      },
      {
        key: 'talent-managment',
        title: 'Talent Management',
        component: TalentManagement,
      },
      {
        key: 'employment-history',
        title: 'Employment History',
        component: EmploymentHistory,
      },
      {
        key: 'documents',
        title: 'Documents',
        component: Documents,
      },
    ];

    const profileHeader =
      (!isEmptyObject(this.props.profileHeader) && {
        ...this.props.profileHeader,
      }) ||
      {};

    return (
      <div className="emp-profile">
        <EnhancedCard loading={false} bordered={false}>
          <EmployeeProfileHeader
            profileHeader={profileHeader}
            updateEmployeePicture={this.props.updateEmployeePicture}
            employeeId={employeeId}
          />
          <EnhancedTabs defaultActiveKey="1">
            {tabsData.map((tab) => {
              return this.renderTab(tab);
            })}
          </EnhancedTabs>
        </EnhancedCard>
      </div>
    );
  }
}

export default MyProfile;
