import { EnhancedCol, EnhancedRow, EnhancedModal } from 'components/shared/antd';
import React, { Component } from 'react';
import LeavesRequestCard from 'components/leaves/LeaveRequestCard';
import OnLeavesCard from 'components/leaves/OnLeavesCard';

export default class LeaveRequestPopup extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.updateSuccess) {
      this.props.onUpdateSuccess();
      this.props.onPopupClose();
    }
  }

  render() {
    const { onUpdateLeave, currentlyActiveTab } = this.props;

    return (
      <EnhancedModal
        visible={this.props.visible}
        footer={null}
        onCancel={this.props.onPopupClose}
        width={1080}
        className="popup-body"
        destroyOnClose
      >
        <EnhancedRow gutter={16}>
          <EnhancedCol md={17}>
            <LeavesRequestCard
              user={{ id: this.props.user.id, name: this.props.user.name }}
              request={this.props.request}
              onUpdateLeave={onUpdateLeave}
              loader={this.props.loader}
              closeModal={this.props.onPopupClose}
              workingHolidays={this.props.workingHolidays}
              leavesStatuses={this.props.leavesStatuses}
              currentlyActiveTab={currentlyActiveTab}
            />
          </EnhancedCol>
          <EnhancedCol md={7}>
            <OnLeavesCard onLeaves={this.props.onLeaves} />
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedModal>
    );
  }
}
