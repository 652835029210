// routes
export const ROOT = 'http://localhost/vendians/index.php/wp-json/wp/v2/';
export const EVENTS = `${ROOT}events`;
export const NEWS = `${ROOT}news`;
export const RELATED_NEWS = `${ROOT}news/?categories`;
export const CATEGORY = `${ROOT}categories`;
export const MEDIA = `${ROOT}media`;
export const HOMEPAGE_NEWS = `${ROOT}news?per_page`;
export const RESOURCE_VIDEO = `${ROOT}resource_video`;

// constants data
export const PER_PAGE = 4;
export const PER_PAGE_GRID = 9;
