import React from 'react';
import './ArticleSection.css';
import { AllNewsIconSVG } from '../../utils/CustomIcons';

// Apply different styles of large and small sections here dynamically
const STYLES = {
  large: {
    imgSize: { height: 320, width: '97.5%' },
    captionStyle: {
      fontSize: 27,
      paddingLeft: 20,
      paddingRight: 150,
    },
  },
  small: {
    imgSize: { height: '148px', width: '218px' },
    captionStyle: {
      fontSize: 15,
      paddingLeft: 10,
      paddingRight: 30,
    },
  },
};

class ArticleSection extends React.Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { nothing: true };
  }

  render() {
    const { title, imgSrc, backgroundColor, size, height, noCaption } = this.props;

    return (
      <div style={{ position: 'relative' }}>
        <div
          style={{
            backgroundImage: `url(${imgSrc})`,
            backgroundColor: `${backgroundColor}`,
            height,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            borderRadius: '5px',
          }}
        />
        {noCaption ? (
          AllNewsIconSVG('newspaper-icon')
        ) : (
          <div className="article-text-block">
            <div className="article-caption" style={STYLES[size].captionStyle}>
              {title}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ArticleSection;
