import {
  EnhancedRow,
  EnhancedCol,
  EnhancedBadge,
  EnhancedList,
  EnhancedListItem,
  EnhancedCollapse,
} from 'components/shared/antd';
import React from 'react';
import moment from 'moment';

const { Panel } = EnhancedCollapse;

const Title = (props) => (
  <EnhancedRow>
    <EnhancedCol span={6}>
      <span>{props.name}</span>
    </EnhancedCol>
    <EnhancedCol span={6}>
      <span>{props.email}</span>
    </EnhancedCol>
    <EnhancedCol offset={23}>
      <EnhancedBadge count={props.count} />
    </EnhancedCol>
  </EnhancedRow>
);

const DefaultersList = (props) => {
  if (!props.defaulters || !props.defaulters.length) {
    return <div className="no-data">No data</div>;
  }
  return (
    <EnhancedCollapse>
      {props.defaulters.map((employee) => (
        <Panel
          header={
            <Title
              name={employee.name}
              email={employee.email}
              count={employee.missingAllocations.length}
            />
          }
          key={employee.email}
        >
          <EnhancedList
            grid={{ gutter: 18, column: 4 }}
            dataSource={employee.missingAllocations}
            renderItem={(date) => (
              <EnhancedListItem>{moment(date).format('dddd, Do MMM YYYY')}</EnhancedListItem>
            )}
          />
        </Panel>
      ))}
    </EnhancedCollapse>
  );
};

export default DefaultersList;
