/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { pmDashboardUrls } from 'configs/constants';

export const getEvaluationReport = (id = null) => {
  const params = {};
  if (id) {
    params.id = id;
  }
  return axios.get(pmDashboardUrls.GET_EVALUATION_REPORT, { params });
};

export const getGoalsReport = () => {
  return axios.get(pmDashboardUrls.GET_GOALS_REPORT);
};

export const getEvaluationReportForLM = (id = null) => {
  const params = {};
  if (id) {
    params.id = id;
  }
  return axios.get(pmDashboardUrls.GET_EVALUATION_REPORT_LM, { params });
};

export const getGoalsReportForLM = () => {
  return axios.get(pmDashboardUrls.GET_GOALS_REPORT_LM);
};

export const getReporteesGoalsForLM = (params) => {
  return axios.get(pmDashboardUrls.GET_REPORTEES_GOALS_LM, { params });
};

export const getReporteesEvaluationsForLM = (params) => {
  return axios.get(pmDashboardUrls.GET_REPORTEES_EVALUATIONS_LM, { params });
};
