import React from 'react';
import { EnhancedButton, EnhancedCol, EnhancedRow, EnhancedCard } from 'components/shared/antd';

import {
  ROW_GUTTER_SIZE,
  FULL_GRID_SIZE,
  EMPLOYMENT_HISTORY as stateName,
  IS_EDIT,
  INPUT,
  MONTH,
  TEXTAREA,
  NUMBER,
} from 'configs/employeeProfileConstants';

import {
  createFields,
  // createSubHeading,
  noData,
} from 'utils/FieldGenerateUtils';
import { generateErrorsArrayFromJSON } from 'utils/validationUtils';
import { DELETE_EMPLOYMENT_CONFIRM_PROMPT_MESSAGE } from 'configs/messageConstants';
import confirmModal from 'components/shared/ConfirmModal';

class PreviousEmploymentHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      [stateName]: this.props[stateName] || [],
      disableSaveButton: true,
      disableCreateButton: false,
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps[stateName] !== this.props[stateName]) {
      const newProps = nextProps[stateName] || [];

      const errors = generateErrorsArrayFromJSON(newProps, [IS_EDIT]);

      this.setState({
        [stateName]: newProps,
        errors,
      });
    }
  }

  handleDelete = (employmentId) => {
    const { employeeId } = this.props;
    confirmModal(DELETE_EMPLOYMENT_CONFIRM_PROMPT_MESSAGE, () => {
      this.props.deleteEmployeeEmployment(employeeId, employmentId);
      const currentData = this.state[stateName];
      this.setState({ [stateName]: currentData });
    });
  };

  handleSave = (index) => {
    if (!this.state.disableSaveButton) {
      const currentData = this.state[stateName];
      currentData[index] = {
        ...currentData[index],
        isEdit: false,
      };
      this.setState({ [stateName]: currentData, disableCreateButton: false });
      const { employeeId } = this.props;
      // const dataToUpdate = { ...currentData[index] };
      // delete dataToUpdate.isEdit;
      if (currentData[index].id) {
        this.props.updateEmployeeEmployment(
          employeeId,
          currentData[index].id,
          currentData[index],
          index,
        );
      } else {
        // delete dataToUpdate.id;
        this.props.createEmployeeEmployment(employeeId, currentData[index], index);
      }
    }
  };

  handleCancel = (index) => {
    const currentData = [...this.state[stateName]];

    // If the object already existed and has an id,
    // then replace data from props on cancel
    if (currentData[index].id) {
      const propsDataAtIndex = this.props[stateName][index];

      // Replace specific object on which cancel was clicked with props version
      currentData[index] = Object.assign({}, propsDataAtIndex, {
        isEdit: false,
      });
      this.setState({
        [stateName]: currentData,
        disableCreateButton: false,
      });
    } else {
      // else, replace the state from props, remove it from state.
      const propsData = [...this.props[stateName]];
      const errorsData = generateErrorsArrayFromJSON(propsData, [IS_EDIT]);
      this.setState({ [stateName]: propsData, errors: errorsData, disableCreateButton: false });
    }
  };

  handleChange = (value, key, error) => {
    const newData = [...this.state[stateName]];

    const index = key.split('-')[1];
    const field = key.split('-')[0];

    newData[index] = Object.assign({}, { ...newData[index] }, { [field]: value });

    // Update specific error object and field
    const { errors } = this.state;
    errors[index] = Object.assign({}, { ...errors[index] }, { [field]: error });

    const allValid = Object.values(errors[index]).every((valueError) => valueError === false);

    this.setState({
      [stateName]: newData,
      errors,
      disableSaveButton: !allValid,
    });
  };

  handleEdit = (index) => {
    const newData = [...this.state[stateName]];
    newData[index] = Object.assign({}, newData[index], { isEdit: true });
    this.setState({
      [stateName]: newData,
      disableCreateButton: true,
    });
  };

  render() {
    const { isLoading } = this.props;
    const { disableSaveButton, editIndex } = this.state;
    // const isDataLoading = isLoading && editIndex === null;
    const data = this.state[stateName];
    const canEdit = false;
    const canDelete = false;

    return (
      <div className="mt-20 pos-rel">
        {/* <Card className="mt-20" loading={isDataLoading} bordered={false}> */}
        {data.length ? (
          <EnhancedRow gutter={ROW_GUTTER_SIZE}>
            {data.map((val, index) => {
              const {
                positionTitle,
                startDate,
                endDate,
                companyName,
                city,
                country,
                grossSalary,
                reasonForLeaving,
                isEdit,
              } = val;
              return (
                <EnhancedCol span={FULL_GRID_SIZE}>
                  <EnhancedCard className="mb-10 br-5" loading={isLoading && editIndex === index}>
                    <div className="flex" style={{ justifyContent: 'space-between' }}>
                      <span className="card-title mb-10">{val.positionTitle}</span>
                      {(canEdit || canDelete) && (
                        <div className="flex justify-flex-end">
                          {canEdit && (
                            <React.Fragment>
                              {!val.isEdit && (
                                <EnhancedButton
                                  className="mr-5"
                                  onClick={() => this.handleEdit(index)}
                                >
                                  Edit
                                </EnhancedButton>
                              )}
                              {val.isEdit && (
                                <EnhancedButton
                                  type="primary"
                                  className="mr-10"
                                  onClick={() => this.handleSave(index)}
                                  disabled={disableSaveButton}
                                >
                                  Save
                                </EnhancedButton>
                              )}
                              {val.isEdit && (
                                <EnhancedButton onClick={() => this.handleCancel(index)}>
                                  Cancel
                                </EnhancedButton>
                              )}
                            </React.Fragment>
                          )}
                          {canDelete && !val.isEdit && (
                            <EnhancedButton onClick={() => this.handleDelete(val.id)}>
                              Delete
                            </EnhancedButton>
                          )}
                        </div>
                      )}
                    </div>
                    {createFields(
                      [
                        {
                          key: `companyName-${index}`,
                          label: 'Company Name:',
                          value: companyName,
                          type: INPUT,
                        },
                        {
                          key: `positionTitle-${index}`,
                          label: 'Job Title:',
                          value: positionTitle,
                          type: INPUT,
                        },
                        {
                          key: `startDate-${index}`,
                          label: 'Start Date:',
                          value: startDate,
                          type: MONTH,
                        },
                        {
                          key: `endDate-${index}`,
                          label: 'End Date:',
                          value: endDate,
                          type: MONTH,
                        },
                        {
                          key: `reasonForLeaving-${index}`,
                          label: 'Reason For Leaving:',
                          value: reasonForLeaving,
                          type: TEXTAREA,
                        },
                        {
                          key: `grossSalary-${index}`,
                          label: 'Gross Salary',
                          value: grossSalary,
                          type: NUMBER,
                        },
                        {
                          key: `city-${index}`,
                          label: 'City',
                          value: city,
                          type: INPUT,
                        },
                        {
                          key: `country-${index}`,
                          label: 'Country',
                          value: country,
                          type: INPUT,
                        },
                      ],
                      2,
                      isEdit,
                      [],
                      this.handleChange,
                    )}
                  </EnhancedCard>
                </EnhancedCol>
              );
            })}
          </EnhancedRow>
        ) : (
          noData()
        )}
        {/* </Card> */}
      </div>
    );
  }
}

export default PreviousEmploymentHistory;
