import {
  EnhancedButton,
  EnhancedCol,
  EnhancedRow,
  EnhancedCard,
  EnhancedForm,
} from 'components/shared/antd';
import React from 'react';
import {
  INPUT,
  TEXT,
  DATE,
  CHECKBOX,
  INFO as stateName,
  DROP_DOWNS,
  ROW_GUTTER_SIZE,
  FULL_GRID_SIZE,
} from 'configs/employeeProfileConstants';
import {
  createFields,
  noData,
  // generateErrorObject, // TODO: This many not be needed
} from 'utils/FieldGenerateUtils';
import EditButton from 'components/shared/employeeProfile/EditButton';
import { subtractObject, isEmptyObject } from 'utils/objectUtils';
import { capitalizeString } from 'utils/stringUtils';
import { EMAIL } from 'configs/validationConstants';
import Addresses from './addresses';

class PersonalInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      [stateName]: {},
      errors: {},
      [DROP_DOWNS]: {},
      isEdit: false,
      disableSaveButton: false,
    };
  }

  componentDidMount() {
    this.initializeValues(this.props[stateName]);
    this.initializeDropDowns(this.props[DROP_DOWNS]);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps[stateName] !== this.props[stateName]) {
      this.initializeValues(nextProps[stateName]);
      // this.initializeDropDowns(nextProps[DROP_DOWNS]);
      // TODO: This may not be needed -> Generates error state for each value in state
      // this.initializeErrors(nextProps[propsName]);
    }
  }

  initializeValues = (props) => this.setState({ [stateName]: props });
  initializeDropDowns = (props) => this.setState({ dropDowns: { ...props } });

  // TODO: This may not be needed -> Generates error state for each value in state
  // initializeErrors = (props) => {
  //   const errorObject = generateErrorObject(props[stateName]);
  //   this.setState({ [`${stateName}Errors`]: errorObject });
  // };

  handleEdit = () => {
    const {
      dateOfBirth,
      permanentAddress,
      permanentCity,
      permanentCountry,
      phoneNumber,
      presentAddress,
      presentCity,
    } = this.state[stateName];

    // Mutate error object according to required conditions for appropiate validation
    const newErrorObject = {
      dateOfBirth: !dateOfBirth,
      permanentAddress: !permanentAddress,
      permanentCity: !permanentCity,
      permanentCountry: !permanentCountry,
      phoneNumber: !phoneNumber,
      presentAddress: !presentAddress,
      presentCity: !presentCity,
    };

    this.setState({ isEdit: true, disableSaveButton: true, errors: newErrorObject });
  };

  handleCancel = () => {
    this.setState({
      isEdit: false,
      [stateName]: this.props[stateName],
    });
  };

  handleSave = (e) => {
    e.preventDefault();
    this.setState({ isEdit: false });
    const updatedObject = subtractObject(this.state[stateName], this.props[stateName]);
    this.props.updateEmployeeInfo(this.props.employeeId, updatedObject);
  };

  handleChange = (value, key, error) => {
    const currentData = this.state[stateName];
    const newData = Object.assign({}, currentData, { [key]: value });
    // Update specific error object and field
    let { errors } = this.state;
    errors = Object.assign({}, { ...errors }, { [key]: error });

    // Check to see if there is any error
    // if any, disable save button
    // if none, enable save button
    const allValid = Object.values(errors).every((valueError) => valueError === false);
    this.setState({ [stateName]: newData, disableSaveButton: !allValid, errors });
    if (error) {
      this.setState({ disableSaveButton: true });
    }
  };

  render() {
    const { isLoading } = this.props;
    const { userPermissions, cardLoading } = this.props;
    const { disableSaveButton, isEdit } = this.state;
    const data = this.state[stateName];
    // const errors = this.state[`${stateName}Errors`]; // TODO: This may not be needed
    const canEdit = true;

    return (
      <div className="mt-20 pos-rel">
        {!isEmptyObject(data) ? (
          <EnhancedRow gutter={ROW_GUTTER_SIZE}>
            <EnhancedCol span={FULL_GRID_SIZE}>
              <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
                loading={cardLoading && isLoading}
                className="mb-24 br-5"
              >
                <div>
                  <EnhancedForm>
                    <div className="flex justify-flex-end">
                      <span>
                        {canEdit && !isEdit ? (
                          <EditButton clickHandler={this.handleEdit} buttonText="Edit" />
                        ) : (
                          ''
                        )}
                      </span>
                      <span className="pr-10">
                        {canEdit && isEdit ? (
                          <EnhancedButton onClick={this.handleSave} disabled={disableSaveButton}>
                            Save
                          </EnhancedButton>
                        ) : (
                          ''
                        )}
                      </span>
                      <span>
                        {canEdit && isEdit ? (
                          <EnhancedButton onClick={this.handleCancel}>Cancel</EnhancedButton>
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                    {createFields(
                      [
                        {
                          key: 'placeOfBirth',
                          label: 'Nationality',
                          value: data.placeOfBirth,
                          type: TEXT,
                        },
                        {
                          key: 'religion',
                          label: 'Religion',
                          value: capitalizeString(data.religion),
                          type: TEXT,
                        },
                        {
                          key: 'fatherName',
                          label: 'Father/Husband Name',
                          value: data.fatherName,
                          type: INPUT,
                        },
                        {
                          key: 'personalEmail',
                          label: 'Personal Email Address',
                          value: data.personalEmail,
                          noCapitalize: true,
                          type: INPUT,
                          validations: [EMAIL],
                        },
                        {
                          key: 'maritalStatus',
                          label: 'Marital Status',
                          value: capitalizeString(data.maritalStatus),
                          type: TEXT,
                        },
                        {
                          key: 'phoneNumber',
                          label: 'Phone #',
                          value: data.phoneNumber,
                          type: TEXT,
                        },
                        {
                          key: 'mobileNumber',
                          label: 'Mobile #',
                          value: data.mobileNumber,
                          type: TEXT,
                        },
                        {
                          key: 'dateOfBirth',
                          label: 'DOB',
                          type: TEXT,
                          value: data.dateOfBirth,
                        },
                        {
                          key: 'bloodGroup',
                          label: 'Blood Group',
                          value: capitalizeString(data.bloodGroup),
                          type: TEXT,
                        },
                        {
                          key: 'cnic',
                          label: 'CNIC',
                          value: data.cnic,
                          type: TEXT,
                        },
                        {
                          key: 'cnicExpiry',
                          label: 'CNIC Expiry Date',
                          value: data.cnicExpiry,
                          type: TEXT,
                        },
                        {
                          key: 'passportNo',
                          label: 'Passport #',
                          value: data.passportNo,
                          type: INPUT,
                        },
                        {
                          key: 'passportExpiry',
                          label: 'Passport Expiry Date',
                          value: data.passportExpiry,
                          type: DATE,
                        },
                        {
                          key: 'taxReturnFiler',
                          label: 'Tax Return Filer',
                          value: data.taxReturnFiler,
                          type: CHECKBOX,
                        },
                      ],
                      2,
                      isEdit,
                      userPermissions,
                      this.handleChange,
                    )}
                    <Addresses
                      userPermissions={userPermissions}
                      info={this.state[stateName]}
                      isEdit={isEdit}
                      handleChange={this.handleChange}
                    />
                  </EnhancedForm>
                </div>
                {this.props.children}
              </EnhancedCard>
            </EnhancedCol>
          </EnhancedRow>
        ) : (
          noData()
        )}
      </div>
    );
  }
}

export default PersonalInfo;
