import { EnhancedCol, EnhancedRow, EnhancedCard, EnhancedBadge } from 'components/shared/antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import { NOT_AVAILABLE } from 'configs/additionalConstants';
import { skillLevel } from 'configs/constants';
import { parse } from 'utils/stringUtils';
import PROJECT_ROUTES from '../../constants/UrlConstants';

const STATUS_INACTIVE = 'inactive';
class NonBillable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: null,
      employees: this.filterNonBillable(props.employees) || [],
      showAll: -1,
      readOnly: this.props.readOnly,
    };
  }

  componentDidMount() {
    this.props.getAllDesignations();
  }

  componentWillReceiveProps(nextProps) {
    const { employees } = this.props;

    if (employees !== nextProps.employees) {
      this.setState({
        employees: this.filterNonBillable(nextProps.employees),
      });
    }
  }

  getColumnsInfo = () => {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '16%',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        filterConfig: {
          type: 'search',
          key: 'name',
        },
        render: this.renderEmployeeFirstName,
      },
      {
        title: 'Designation',
        dataIndex: 'designation',
        key: 'designation',
        width: '18%',
        sorter: (a, b) => {
          const first = a.designation ? a.designation.name : NOT_AVAILABLE;
          const second = b.designation ? b.designation.name : NOT_AVAILABLE;
          return first.localeCompare(second);
        },
        sortOrder: sortedInfo.columnKey === 'designation' && sortedInfo.order,
        ellipsis: true,
        render: this.renderEmployeeDesignation,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'designation.name',
          optionsConfig: {
            values: this.props.designations,
          },
        },
      },
      {
        title: 'Manager',
        dataIndex: 'managerName',
        key: 'manager',
        width: '20%',
        sorter: (a, b) => a.managerName.localeCompare(b.managerName),
        sortOrder: sortedInfo.columnKey === 'manager' && sortedInfo.order,
        filterConfig: {
          type: 'search',
          key: 'managerName',
        },
      },
      {
        title: 'Skills',
        dataIndex: 'skills',
        key: 'skills',
        width: '21%',
        render: this.renderSkills,
        filterConfig: {
          type: 'search',
          key: 'skills',
          customFunc: (item, filterValue) =>
            item.skills.some(
              (skill) => skill.skill && skill.skill.name.toLowerCase().includes(filterValue),
            ),
        },
      },
    ];

    return columns;
  };

  getFileHeaders = () => {
    const headers = [
      {
        label: 'Name',
        key: 'name',
      },
      {
        label: 'Designation',
        key: 'designation.name',
      },
      {
        label: 'Manager Name',
        key: 'managerName',
      },
      {
        label: 'Skills',
        key: 'Skills',
      },
    ];
    return headers;
  };

  filterNonBillable = (employees) =>
    employees.filter((emp) => emp.isActive === true && emp.resourceType === 'non_billable');

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  };

  renderEmployeeDesignation = (name, data) => {
    return (
      <div style={{ color: '#5A5A5A', wordBreak: 'normal' }}>
        {data.designation ? data.designation.name : NOT_AVAILABLE}
      </div>
    );
  };

  renderEmployeeFirstName = (name, data) => {
    const link = parse(PROJECT_ROUTES.EMPLOYEE_DETAIL_ROUTE, {
      status: PROJECT_ROUTES.NON_BILLABLE,
      id: data.id,
    });
    return (
      <Link to={link}>
        <b style={{ color: '#5A5A5A' }}>{name}</b>
      </Link>
    );
  };

  renderSkills = (multipleSkills, record, index) => {
    const primaryOutline = '0 0 0 2px #AAA59D inset';
    const secondaryOutline = '0 0 0 2px #DEDFD7 inset';
    const actionOutline = '0 0 0 2px ##DEDFD7 inset';

    const badgeStyle = {
      backgroundColor: '#fff',
      color: '#999',
      boxShadow: primaryOutline,
      minWidth: 30,
      marginRight: 5,
      marginTop: 5,
      borderRadius: '15%',
    };

    const skills = multipleSkills.filter((skill) => skill.skill && skill.skill.name);

    if (skills.length === 0) {
      return NOT_AVAILABLE;
    }

    const showLessBadge = (
      <EnhancedBadge
        style={{
          ...badgeStyle,
          backgroundColor: 'white',
          color: 'rgb(90, 90, 90)',
          cursor: 'pointer',
          boxShadow: actionOutline,
        }}
        onClick={() => {
          this.setState({ showAll: -1 });
        }}
        count="Show Less"
      />
    );

    if (skills.length > 2) {
      if (this.state.showAll === index) {
        const allSkills = skills.map((skill) =>
          skill.expertise === skillLevel.EXPERT ? (
            <EnhancedBadge style={badgeStyle} count={skill.skill.name} />
          ) : (
            <EnhancedBadge
              style={{ ...badgeStyle, boxShadow: secondaryOutline }}
              count={skill.skill.name}
            />
          ),
        );
        return [allSkills, showLessBadge];
      }
      const showMoreBadge = (
        <EnhancedBadge
          style={{
            ...badgeStyle,
            backgroundColor: 'white',
            color: 'rgb(90, 90, 90)',
            cursor: 'pointer',
            boxShadow: actionOutline,
          }}
          onClick={() => {
            this.setState({ showAll: index });
          }}
          count={`${skills.length - 2} More`}
        />
      );

      const slicedSkills = skills.slice(0, 2).map((skill) => {
        badgeStyle.boxShadow =
          skill.expertise === skillLevel.EXPERT ? primaryOutline : secondaryOutline;
        return (
          <EnhancedBadge
            style={badgeStyle}
            count={skill.skill !== undefined && skill.skill !== null ? skill.skill.name : ''}
          />
        );
      });
      return [...slicedSkills, showMoreBadge];
    }
    return skills.map((skill) => {
      if (skill && skill.skill) {
        return (
          <EnhancedBadge
            style={badgeStyle}
            count={skill.skill !== undefined && skill.skill !== null ? skill.skill.name : ''}
          />
        );
      }
      return null;
    });
  };

  render() {
    const { isLoading, match } = this.props;
    const { employees } = this.state;
    let columns = this.getColumnsInfo();
    const statusHeading = match.params.status;

    if (statusHeading === STATUS_INACTIVE || this.state.readOnly) {
      columns = columns.filter((col) => col.title !== 'Actions');
    }

    // employees = employees.filter(
    //   (emp) => emp.isActive === true && emp.resourceType === 'non_billable',
    // );

    return (
      <div>
        <EnhancedCard>
          <EnhancedRow style={{ marginBottom: '10px' }}>
            <EnhancedCol lg={12}>
              <h2 className="heading-emp">{statusHeading} Non Billable Resources</h2>
            </EnhancedCol>
            <EnhancedCol span={12}>
              <h2 className="heading-emp" style={{ float: 'right' }}>
                Total Employees Count: {employees.length}
              </h2>
            </EnhancedCol>
          </EnhancedRow>
        </EnhancedCard>
        <EnhancedCard>
          <div>
            <EnhancedClientSideTable
              data={employees}
              columns={columns}
              loading={isLoading}
              onChange={this.handleChange}
              exportFileConfig={{
                fileName: 'Non-Billable Employees',
                fileHeaders: this.getFileHeaders(),
                showExportButton: true,
              }}
            />
          </div>
        </EnhancedCard>
      </div>
    );
  }
}

export default NonBillable;
