import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedModal,
  EnhancedSelect,
  EnhancedDatePicker,
  EnhancedInput,
  EnhancedSlider,
  EnhancedForm,
  EnhancedNotification,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import RequiredField from 'components/RequiredField';
import {
  DATE_FORMAT,
  goalStatusToEditableFieldMapping,
  GOAL_STATUS,
} from 'configs/employeeProfileConstants';
// import { editStatusOptionMenu } from 'configs/constants';
import moment from 'moment';

const { Option } = EnhancedSelect;
const { TextArea } = EnhancedInput;

const AddManagerGoalModal = (props) => {
  const {
    isModalVisible,
    handleOk,
    handleCancel,
    allEmployees,
    managerAddGoalActions,
    updateGoalActions,
    params,
    isEdit,
    editableHandler,
    isLoading,
    details,
  } = props;
  const [goalsField, setGoalsField] = useState({
    title: '',
    weightage: '',
    measurement: '',
    description: '',
    employee: '',
    dueDate: moment().format(DATE_FORMAT),
    status: 'pending',
    progress: 0,
  });
  const [editableFields, setEditableFields] = useState(
    goalStatusToEditableFieldMapping[GOAL_STATUS.PENDING],
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    // if field is not editable don't need to update state
    // safety check
    if (editableFields.indexOf(name) !== -1) {
      setGoalsField({
        ...goalsField,
        [name]: value,
      });
    }
  };

  const handleEmployeeChange = (value) => {
    setGoalsField({
      ...goalsField,
      employee: value,
    });
  };

  const onDueDateChange = (date) => {
    // if field is not editable don't need to update state
    // safety check
    if (editableFields.indexOf('dueDate') !== -1) {
      setGoalsField({
        ...goalsField,
        dueDate: moment(date).format(DATE_FORMAT),
      });
    }
  };

  // const handleSelect = (value) => {
  //   setGoalsField({
  //     ...goalsField,
  //     employee: value
  //   })
  // }

  const onSliderChange = (value) => {
    if (editableFields.indexOf('progress') !== -1) {
      setGoalsField({
        ...goalsField,
        progress: value,
      });
    }
  };

  const createNewGoal = () => {
    if (goalsField.weightage > 100 || goalsField.weightage < 1) {
      EnhancedNotification.error({
        message: 'weightage must be greater than 0 and less than or equal to 100',
      });
      return;
    }
    managerAddGoalActions({
      payload: goalsField,
      parameters: params,
    });

    handleOk();
    setGoalsField({
      title: '',
      weightage: '',
      measurement: '',
      description: '',
      employee: '',
      dueDate: moment().format(DATE_FORMAT),
      status: 'pending',
      progress: 0,
    });
    editableHandler(false);
  };

  const editGoal = () => {
    if (goalsField.weightage > 100 || goalsField.weightage < 1) {
      EnhancedNotification.error({
        message: 'weightage must be greater than 0 and less than or equal to 100',
      });
      return;
    }
    const payload = editableFields.reduce((acc, cur) => {
      acc[cur] = goalsField[cur];
      return acc;
    }, {});
    updateGoalActions({
      id: details.id,
      payload,
      parameters: params,
      viewDetails: false,
    });
    handleOk();
    setGoalsField({
      title: '',
      weightage: '',
      measurement: '',
      description: '',
      employee: '',
      dueDate: moment().format(DATE_FORMAT),
      status: 'pending',
      progress: 0,
    });
    editableHandler(false);
    setEditableFields([]);
  };

  const closeModalHandler = () => {
    handleCancel();
    setGoalsField({
      title: '',
      weightage: '',
      measurement: '',
      description: '',
      employee: '',
      dueDate: moment().format(DATE_FORMAT),
      status: 'pending',
      progress: 0,
    });
    editableHandler(false);
    setEditableFields(goalStatusToEditableFieldMapping[GOAL_STATUS.PENDING]);
  };

  useEffect(() => {
    if (isEdit) {
      setGoalsField({
        title: details.title,
        weightage: details.weightage,
        measurement: details.measurement,
        description: details.description,
        employee: details.employee,
        dueDate: details.dueDate,
        status: details.status,
        progress: details.progress,
      });
      if (details && details.status)
        setEditableFields(goalStatusToEditableFieldMapping[details.status]);
    } else {
      setEditableFields(goalStatusToEditableFieldMapping[GOAL_STATUS.PENDING]);
    }
  }, [isLoading]);

  return (
    <EnhancedModal
      title={isEdit ? 'Edit Goal' : 'Add New Goal'}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={closeModalHandler}
      className="add-goals-modal"
      width="1041px"
      footer={[
        <EnhancedButton
          key="submit"
          type="primary"
          onClick={isEdit ? editGoal : createNewGoal}
          disabled={
            !goalsField.title ||
            !goalsField.dueDate ||
            !goalsField.weightage ||
            !goalsField.employee
          }
        >
          Save Changes
        </EnhancedButton>,
      ]}
    >
      <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
        loading={isLoading}
        bordered={false}
        className="details-card-wrapper"
        style={{
          marginTop: 10,
        }}
      >
        <EnhancedRow>
          <EnhancedForm layout="inline" className="goals-form">
            {/* <EnhancedCol span={24} className="form-col"> */}
            <EnhancedCol lg={4}>
              <RequiredField> Select Employee </RequiredField>
            </EnhancedCol>
            <EnhancedCol lg={20}>
              <EnhancedSelect
                showSearch
                optionFilterProp="children"
                name="employee"
                onChange={handleEmployeeChange}
                value={goalsField.employee}
              >
                {allEmployees
                  .filter((employee) => {
                    return employee.isActive;
                  })
                  .map((item) => {
                    return <Option value={item.id}>{item.name}</Option>;
                  })}
              </EnhancedSelect>
            </EnhancedCol>
            <EnhancedCol lg={24} />
            <EnhancedCol lg={4} style={{ marginTop: 20 }}>
              <RequiredField> Goal Title </RequiredField>
            </EnhancedCol>
            <EnhancedCol lg={7} style={{ marginTop: 20 }}>
              <EnhancedInput name="title" value={goalsField.title} onChange={handleChange} />
            </EnhancedCol>
            <EnhancedCol lg={2} />
            <EnhancedCol lg={4} style={{ marginTop: 20 }}>
              <RequiredField> Weightage (%) </RequiredField>
            </EnhancedCol>
            <EnhancedCol lg={7} style={{ marginTop: 20 }}>
              <EnhancedInput
                type="number"
                name="weightage"
                value={goalsField.weightage}
                onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                onChange={handleChange}
              />
            </EnhancedCol>
            <EnhancedCol lg={4} style={{ marginTop: 20 }}>
              <label htmlFor="Description"> Description </label>
            </EnhancedCol>
            <EnhancedCol lg={7} style={{ marginTop: 20 }}>
              <TextArea
                name="description"
                value={goalsField.description}
                onChange={handleChange}
                rows={4}
              />
            </EnhancedCol>
            <EnhancedCol lg={2} />
            <EnhancedCol lg={4} style={{ marginTop: 20 }}>
              <label htmlFor="Measurements"> Measurements </label>
            </EnhancedCol>
            <EnhancedCol lg={7} style={{ marginTop: 20 }}>
              <TextArea
                name="measurement"
                value={goalsField.measurement}
                onChange={handleChange}
                rows={4}
              />
            </EnhancedCol>
            <EnhancedCol lg={4} style={{ marginTop: 20 }}>
              <RequiredField> Due Date </RequiredField>
            </EnhancedCol>
            <EnhancedCol lg={7} style={{ marginTop: 20 }}>
              <EnhancedDatePicker
                name="dueDate"
                dropdownClassName="dueDate-goal-field"
                style={{ width: '100%' }}
                onChange={onDueDateChange}
                value={moment(goalsField.dueDate)}
                format={DATE_FORMAT}
                disabledDate={(current) => {
                  const customDate = moment().format(DATE_FORMAT);
                  return current && current < moment(customDate, DATE_FORMAT);
                }}
                allowClear={false}
              />
            </EnhancedCol>
            <EnhancedCol lg={2} />
            {/* <Col lg={4} style={{marginTop: 20}}>
                  <label htmlFor="Status"> Status </label>
                </Col> */}
            {/* <Col lg={7} style={{marginTop: 20}}>
                <EnhancedSelect value={!isEdit ? 'New' : goalsField.status} name="status" onChange={handleSelect} disabled={!isEdit}>
                  {
                    !isEdit ? (<Option value="Pending">New</Option>) : 
                    editStatusOptionMenu.map(item => <Option value={item.value}>{item.title}</Option>)
                  }
                </EnhancedSelect>
                </Col> */}
            <EnhancedCol lg={4} style={{ marginTop: 20 }}>
              <label htmlFor="Percent Complete"> Percent Complete </label>
            </EnhancedCol>
            <EnhancedCol lg={7} style={{ marginTop: 20 }}>
              <EnhancedRow>
                <EnhancedCol span={18}>
                  <EnhancedSlider
                    min={0}
                    max={100}
                    step={10}
                    onChange={onSliderChange}
                    className="range-slider"
                    value={typeof goalsField.progress === 'number' ? goalsField.progress : 0}
                  />
                </EnhancedCol>
                <EnhancedCol span={5}>
                  <EnhancedInput style={{ marginLeft: 12 }} value={goalsField.progress} readOnly />
                </EnhancedCol>
              </EnhancedRow>
            </EnhancedCol>
            {/* </EnhancedCol> */}
          </EnhancedForm>
        </EnhancedRow>
      </EnhancedCard>
    </EnhancedModal>
  );
};

export default AddManagerGoalModal;
