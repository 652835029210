import React from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';

const EnhancedForm = (props) => {
  const {
    className,
    style,
    children,
    onSubmit,
    onFinish,
    onFinishFailed,
    layout,
    hideRequiredMark,
    label,
  } = props;

  return (
    <Form
      className={className}
      style={style}
      onSubmit={onSubmit}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout={layout}
      hideRequiredMark={hideRequiredMark}
      label={label}
    >
      {children}
    </Form>
  );
};

EnhancedForm.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  onFinish: PropTypes.func,
  onFinishFailed: PropTypes.func,
  onSubmit: PropTypes.func,
  layout: PropTypes.string,
  hideRequiredMark: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

EnhancedForm.defaultProps = {
  className: '',
  style: {},
  layout: 'horizontal',
  hideRequiredMark: false,
  onSubmit: () => {},
  onFinish: () => {},
  onFinishFailed: () => {},
  label: '',
};

EnhancedForm.Item = Form.Item;

export default EnhancedForm;
