import { EnhancedButton, EnhancedCard, EnhancedSpin } from 'components/shared/antd';
import React from 'react';

import { generateErrorObject } from 'utils/validationUtils';
import {
  SKILL_BADGE,
  TALENT_MANAGEMENT as stateName,
  IS_EDIT,
  IS_LOADING,
  SELECT,
} from 'configs/employeeProfileConstants';
import { RESOURCETYPE } from 'configs/constants';
import { createFields, noData } from 'utils/FieldGenerateUtils';
import { prepareOptionsFromJSON } from 'utils/arrayUtils';
import { isEmptyObject } from 'utils/objectUtils';
import { hasPermission } from 'utils/AccessUtils';

// import CareerPlanning from 'components/employeeProfile/talentManagment/careerPlanning';
import Permissions from 'constants/AccessPermissions';
import EditButton from 'components/shared/employeeProfile/EditButton';

class TalentManagment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      [stateName]: this.props[stateName] || {},
      errors: [],
      skillsOptions: [],
      disableSaveButton: true,
    };
  }

  componentDidMount() {
    this.props.getEmployeesSkills();
    const employeeId = this.props.match.params.id;
    this.props.getEmployeeTalent(employeeId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps[stateName] !== this.props[stateName]) {
      const newProps = nextProps[stateName];

      // Expecting Array of Objects
      // Make clean error object with exclusions
      const errors = generateErrorObject(newProps, [IS_EDIT, IS_LOADING]);

      this.setState({
        [stateName]: newProps,
        errors,
      });
    }
    if (nextProps.skillsOptions !== this.props.skillsOptions) {
      this.setState({
        skillsOptions: nextProps.skillsOptions,
      });
    }
  }

  handleEdit = () => {
    const editState = Object.assign({}, { ...this.state[stateName] }, { isEdit: true });
    this.setState({
      [stateName]: editState,
    });
  };

  handleCancel = () => {
    // Default props have isEdit value set to false
    const propsData = Object.assign({}, this.props[stateName]);
    this.setState({
      [stateName]: propsData,
    });
  };

  handleChange = (value, key, error) => {
    const currentData = this.state[stateName];
    const newData = Object.assign({}, currentData, { [key]: value });

    // Update error object and field
    const { errors } = this.state;
    const newErrors = Object.assign({}, { ...errors }, { [key]: error });

    // Check to see if there is any error
    // if any, disable save button
    // if none, enable save button
    const allValid = Object.values(newErrors).every((valueError) => valueError === false);

    this.setState({
      [stateName]: newData,
      errors,
      disableSaveButton: !allValid,
    });
  };

  handleSave = () => {
    if (!this.state.disableSaveButton) {
      const data = Object.assign({}, this.state[stateName], {
        isLoading: true,
      });
      this.setState({
        [stateName]: data,
      });
      const employeeId = this.props.match.params.id;
      this.props.updateEmployeeTalent(employeeId, data);
    }
  };

  render() {
    const { userPermissions, isLoading: globalLoading } = this.props;

    const data = this.state[stateName];
    const { isEdit, isLoading } = this.state[stateName];
    const { disableSaveButton, skillsOptions } = this.state;
    const canEdit = hasPermission(userPermissions, [
      Permissions.Employee.Profile.TalentManagement.Update,
    ]);

    return (
      <EnhancedCard loading={globalLoading} bordered={false}>
        {/* <Authorize
          requiredPermissions={[
            Permissions.Employee.Profile.TalentManagement.View,
          ]}
        > */}
        <EnhancedSpin spinning={isLoading || false}>
          {!isLoading && canEdit && (
            <div className="flex justify-flex-end">
              {canEdit && (
                <React.Fragment>
                  {!isEdit && <EditButton clickHandler={this.handleEdit} buttonText="Edit" />}
                  {isEdit && (
                    <EnhancedButton
                      type="primary"
                      className="mr-10"
                      onClick={this.handleSave}
                      disabled={disableSaveButton}
                    >
                      Save
                    </EnhancedButton>
                  )}
                  {isEdit && <EnhancedButton onClick={this.handleCancel}>Cancel</EnhancedButton>}
                </React.Fragment>
              )}
            </div>
          )}
          <React.Fragment>
            {!isEmptyObject(data)
              ? createFields(
                  [
                    {
                      key: 'primarySkills',
                      label: 'Primary Skills',
                      value: data.primarySkills,
                      options: prepareOptionsFromJSON(skillsOptions),
                      type: SKILL_BADGE,
                    },
                    {
                      key: 'secondarySkills',
                      label: 'Secondary Skills',
                      value: data.secondarySkills,
                      options: prepareOptionsFromJSON(skillsOptions),
                      type: SKILL_BADGE,
                    },
                    {
                      key: 'resourceType',
                      label: 'Resource Type',
                      value: data.resourceType,
                      options: RESOURCETYPE,
                      type: SELECT,
                    },
                  ],
                  2,
                  isEdit,
                  userPermissions,
                  this.handleChange,
                )
              : noData()}
          </React.Fragment>
        </EnhancedSpin>
        {/* </Authorize> */}
        {/* <Authorize
          requiredPermissions={[
            Permissions.Employee.Profile.TalentManagement.CareerPlanning.View,
            Permissions.Employee.Profile.TalentManagement.Management.View,
          ]}
        > */}
        {/* <CareerPlanning
          userPermissions={userPermissions}
          employeeId={this.props.match.params.id}
          careerPlanning={this.props[stateName].careerPlanning}
          skillsOptions={this.props.skillsOptions}
          updateEmployeeCareerPlanning={this.props.updateEmployeeCareerPlanning}
        /> */}
        {/* </Authorize> */}
      </EnhancedCard>
    );
  }
}

export default TalentManagment;
