import { connect } from 'react-redux';
import Departments from 'components/departments/departments';
import {
  getEmployeeDepartmentsAction,
  addDepartmentAction,
  editDepartmentAction,
  deleteDepartmentAction,
} from 'store/actions/EmployeeActions';

function mapStateToProps(state) {
  const { employeeReducer, auth } = state;
  const { departments, isLoading } = employeeReducer;
  return {
    departments,
    isLoading,
    userId: auth.user && auth.user.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEmployeeDepartmentsAction: () => dispatch(getEmployeeDepartmentsAction()),
    addDepartment: (request) => dispatch(addDepartmentAction(request)),
    editDepartment: (request) => dispatch(editDepartmentAction(request)),
    deleteDepartment: (request) => dispatch(deleteDepartmentAction(request)),
  };
}

const DepartmentsContainer = connect(mapStateToProps, mapDispatchToProps)(Departments);

export default DepartmentsContainer;
