import React, { useEffect, useState } from 'react';
import {
  EnhancedSelect,
  EnhancedModal,
  EnhancedDrawer,
  EnhancedInput,
  EnhancedDatePicker,
} from 'components/shared/antd';
import { DEAL_RESOURCE_STATUS } from 'configs/dealConstants';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';
import moment from 'moment';

import UpdateSuggestionStatus from './UpdateSuggestionStatus';
import DealRequirementSuggestionOtherDeals from './DealRequirementSuggestionOtherDeals';

const { Option } = EnhancedSelect;
const { TextArea } = EnhancedInput;

const RequirementsSuggestionsTable = (props) => {
  const {
    onClose,
    isDrawerVisible,
    dealRequirementsSuggestions,
    getDealRequirementsSuggestions,
    dealRequirementObj,
    updateDealRequirementSuggestion,
    dealResourceStatus,
    getDealRequirementSuggestionOtherDeals,
    dealRequirementSuggestionOtherDeals,
    dealRequirementParams,
    dealDetail,
  } = props;

  const canLock = (record) => record.systemStatusKey !== DEAL_RESOURCE_STATUS.LOCKED;
  const canUnlock = (record) => record.systemStatusKey !== DEAL_RESOURCE_STATUS.UNLOCKED;

  const optionMenu = [
    { value: 'lock', label: 'Lock', canCallback: canLock },
    { value: 'unlock', label: 'Unlock', canCallback: canUnlock },
    { value: 'release', label: 'Release' },
    { value: 'updateStatus', label: 'Update Status' },
  ];

  const [params, setParams] = useState({ page: 0, size: 10 });
  const [showUpdateSuggestionStatus, setShowUpdateSuggestionStatus] = useState(false);
  const [showDealRequirementSuggestionOtherDeals, setDealRequirementSuggestionOtherDeals] =
    useState(false);
  const [clickedRecord, setClickedRecord] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [actionType, setActionType] = useState('');
  const [comment, setComment] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (isDrawerVisible) {
      getDealRequirementsSuggestions(dealRequirementObj.id, params);
    }
  }, [isDrawerVisible]);

  useEffect(() => {
    getDealRequirementsSuggestions(dealRequirementObj.id, params);
  }, [params]);

  const updateSuggestionHandler = (id, payload) => {
    updateDealRequirementSuggestion(
      id,
      payload,
      { dealRequirementId: dealRequirementObj.id, params },
      { dealId: dealDetail.id, params: dealRequirementParams },
    );
  };

  const handleOk = () => {
    if (comment) {
      updateSuggestionHandler(clickedRecord.id, {
        action: actionType,
        data: {
          comments: comment,
          statusDate: selectedDate
            ? selectedDate.startOf('day').format('YYYY-MM-DD')
            : clickedRecord.suggestedDate,
        },
      });
      setModalVisible(false);
      setComment('');
      setSelectedDate(null);
    }
  };

  const handleCancel = () => {
    setModalVisible(false);
    setComment('');
    setSelectedDate(null);
  };

  const changeActionHandler = (key, id, record) => {
    setClickedRecord(record);
    if (key === 'lock' || key === 'unlock' || key === 'release') {
      setActionType(key);
      setModalVisible(true);
    } else if (key === 'updateStatus') {
      setShowUpdateSuggestionStatus(true);
    }
  };

  const setActionsHandler = (id, record) => {
    return (
      <EnhancedSelect
        style={{ width: 120 }}
        className="action-selection-dropdown"
        placeholder="Options"
        value="Options"
        onSelect={(key) => changeActionHandler(key, id, record)}
      >
        {optionMenu.map(
          (item) =>
            (!item.canCallback || item.canCallback(record)) && (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ),
        )}
      </EnhancedSelect>
    );
  };

  const onResourceNameClick = (record) => {
    setClickedRecord(record);
    setDealRequirementSuggestionOtherDeals(true);
  };

  const getColumnsInfo = () => {
    const columns = [
      {
        title: 'Resource Name',
        dataIndex: 'resourceName',
        key: 'resourceName',
        render: (name, record) => (
          <b
            className="pointer-enabled"
            aria-hidden="true"
            onClick={() => onResourceNameClick(record)}
          >
            {name}
          </b>
        ),
      },
      { title: 'CRMC Status', dataIndex: 'crmcStatusLabel', key: 'crmcStatus' },
      { title: 'System Status', dataIndex: 'systemStatusLabel', key: 'systemStatus' },
      { title: 'Comments', dataIndex: 'comments', key: 'comments' },
      {
        title: 'Actions',
        dataIndex: 'id',
        render: (id, record) => setActionsHandler(id, record),
      },
    ];
    return columns;
  };

  const handleTableChange = (pagination) => {
    setParams({ ...params, page: pagination.current - 1, size: pagination.pageSize });
  };

  const disabledDate = (current) => {
    return current && current < moment(clickedRecord.suggestedDate);
  };

  return (
    <EnhancedDrawer
      visible={isDrawerVisible}
      title={`Suggested Resources in: ${dealRequirementObj && dealRequirementObj.skills.join(' + ')}`}
      width={880}
      onClose={onClose}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <EnhancedServerSideTable
        data={dealRequirementsSuggestions.rows}
        columns={getColumnsInfo()}
        onChange={handleTableChange}
        className="deals-table-container"
        paginationInfo={{
          totalItems: dealRequirementsSuggestions.pageInfo?.totalItems,
          pageSize: params.size,
          pageNumber: params.page + 1,
        }}
        scroll={{ x: 'max-content' }}
        showResetFiltersButton={false}
      />
      <UpdateSuggestionStatus
        isDrawerVisible={showUpdateSuggestionStatus}
        onClose={() => setShowUpdateSuggestionStatus(false)}
        dealResourceStatus={dealResourceStatus}
        updateDealRequirementSuggestion={updateDealRequirementSuggestion}
        suggestionObj={clickedRecord}
        dealRequirementObj={dealRequirementObj}
        dealRequirementSuggestionsParams={params}
        dealRequirementParams={dealRequirementParams}
        dealDetail={dealDetail}
      />
      <DealRequirementSuggestionOtherDeals
        isDrawerVisible={showDealRequirementSuggestionOtherDeals}
        onClose={() => setDealRequirementSuggestionOtherDeals(false)}
        getDealRequirementSuggestionOtherDeals={getDealRequirementSuggestionOtherDeals}
        suggestionObj={clickedRecord}
        dealRequirementSuggestionOtherDeals={dealRequirementSuggestionOtherDeals}
      />
      <EnhancedModal
        title={`Enter Comment and Date for ${actionType}`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: !comment || !selectedDate }}
      >
        <div style={{ marginBottom: 16 }}>
          <label>
            Date <span style={{ color: 'red' }}>*</span>
          </label>
          <EnhancedDatePicker
            style={{ display: 'block', marginTop: 8 }}
            onChange={(date) => setSelectedDate(date ? moment(date) : null)}
            value={selectedDate}
            disabledDate={disabledDate}
          />
        </div>
        <div>
          <label>
            Comment <span style={{ color: 'red' }}>*</span>
          </label>
          <TextArea
            style={{ display: 'block', marginTop: 8 }}
            placeholder="Enter your comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      </EnhancedModal>
    </EnhancedDrawer>
  );
};

export default RequirementsSuggestionsTable;
