export default function reportNormalizer(res) {
  const projectsData = {};
  const departments = new Set();
  const managers = new Set();
  const employees = new Set();
  const report = res.map((record, index) => {
    if (record.name) employees.add(record.name);
    if (record.deptt) departments.add(record.deptt);
    if (record.manager) managers.add(record.manager);
    const trimmedRecord = {
      key: index,
      name: record.name,
      total: record.total,
      location: record.location,
      deptt: record.deptt,
      manager: record.manager,
      allocations: [],
    };
    const blacklistedKeys = ['name', 'total', 'location', 'deptt', 'manager'];
    Object.keys(record).forEach((key) => {
      if (!blacklistedKeys.includes(key) && record[key]) {
        const project = record[key];
        if (projectsData[key]) {
          projectsData[key].total += +project.percentage;
        } else {
          projectsData[key] = {
            id: project.id,
            name: key,
            color: project.colorCode && `#${project.colorCode}`,
            total: +project.percentage,
          };
        }
        trimmedRecord.allocations.push({
          projectName: key,
          percentage: project.percentage,
          color: project.colorCode && `#${project.colorCode}`,
          projectId: project.id,
        });
      }
    });
    return trimmedRecord;
  });

  const summary = [];
  Object.keys(projectsData).forEach((project) => {
    projectsData[project].total = projectsData[project].total;
    summary.push(projectsData[project]);
  });

  return {
    report,
    summary,
    employees: [...employees].sort(), // Set to Array
    departments: [...departments].sort(), // Set to Array
    managers: [...managers].sort(), // Set to Array
  };
}
