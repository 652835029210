import { connect } from 'react-redux';
import TransientResource from 'components/TransientResource/TransientResource';
import { getEmployeesSkillsActions } from 'store/actions/EmployeeActions';
import {
  getTransientResourceActions,
  getAllTransientResourceActions,
  createTransientResourceActions,
  deleteTransientResourceActions,
  updateTransientResourceActions,
} from 'store/actions/ResourcePlannerActions';

function mapStateToProps(state) {
  const { employeeReducer, auth, resourcePlannerReducer } = state;
  const { isLoading } = resourcePlannerReducer;
  return {
    isLoading,
    transientResources: resourcePlannerReducer.transientResources,
    allTransientResources: resourcePlannerReducer.allTransientResources,
    pageInfo: resourcePlannerReducer.pageInfo,
    skills: employeeReducer.skills,
    userId: auth.user && auth.user.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSkillsActions: () => dispatch(getEmployeesSkillsActions()),
    getTransientResourceActions: (req) => dispatch(getTransientResourceActions(req)),
    getAllTransientResourceActions: (params) => dispatch(getAllTransientResourceActions(params)),
    deleteTransientResourceActions: (req) => dispatch(deleteTransientResourceActions(req)),
    updateTransientResourceActions: (req, id) => dispatch(updateTransientResourceActions(req, id)),
    createTransientResourceActions: (req) => dispatch(createTransientResourceActions(req)),
  };
}

const TransientResourceContainer = connect(mapStateToProps, mapDispatchToProps)(TransientResource);

export default TransientResourceContainer;
