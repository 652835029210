import { EnhancedCard } from 'components/shared/antd';
import React from 'react';

import Tracking from 'components/MyProfile/tracking/tracking';

class JobInfo extends React.Component {
  componentDidMount() {
    const employeeId = 'me';
    // this.props.getMiscellaneousData();
    this.props.getEmployeeTrackingInfo(employeeId);
  }

  render() {
    const { userPermissions, isLoading: globalLoading } = this.props;
    return (
      <EnhancedCard className="mt-20" loading={globalLoading} bordered={false}>
        <Tracking
          tracking={this.props.trackingInfo}
          userPermissions={userPermissions}
          employeeId="me"
          updateEmployeeTrackingInfo={this.props.updateEmployeeTrackingInfo}
          cardLoading={this.props.cardLoading}
        />
      </EnhancedCard>
    );
  }
}

export default JobInfo;
