/* eslint-disable */
import {
  EnhancedCol,
  EnhancedRow,
  EnhancedButton,
  EnhancedLayout,
  EnhancedModal,
  EnhancedInput,
  EnhancedForm,
} from 'components/shared/antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';

import RequiredField from '../RequiredField';

const { Header } = EnhancedLayout;

class PerformanceCycle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mo: '',
      yr: '',
      showAddNewForm: false,
      modalOpen: false,
      /*     searchProjectText: '',
      searchManagerText: '',
      errorText: '', */
      readOnly: this.props.readOnly,
    };
  }

  componentWillMount() {
    this.props.getPerformanceCyclesAction();
  }

  getExportFileHeaders = () => {
    return [
      {
        key: 'name',
        label: 'Cycle Name',
      },
      {
        key: 'status',
        label: 'Cycle Status',
      },
      {
        key: 'dueDate',
        label: 'Due Date',
      },
    ];
  };

  getColumnsInfo = () => {
    let columns = [
      {
        title: 'Cycle',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        render: this.renderCycleName,
        filterConfig: {
          type: 'search',
          key: 'name',
        },
      },
      {
        title: 'Cycle Status',
        dataIndex: 'status',
        key: 'status',
        width: '20%',
        render: this.renderStatusName,
      },
      {
        title: 'Due Date',
        dataIndex: 'dueDate',
        key: 'dueDate',
        width: '20%',
        render: this.renderDueDate,
      },
    ];
    return columns;
  };

  renderStatusName = (data) => {
    if (data === 'Actived') {
      return 'Active';
    } else {
      return 'Complete';
    }
  };

  renderDueDate = (dueDate, data) => {
    return <p> {data.dueDate} </p>;
  };

  showTotal = (total) => `Total ${total} items`;

  showAddNew = () => {
    this.setState({
      showAddNewForm: !this.state.showAddNewForm,
    });
  };

  showModal = () => {
    this.setState({
      modalOpen: true,
    });
  };

  handleCancel = () => {
    this.setState({
      modalOpen: false /*
      errorText: '', */,
    });
  };

  handleNameChange = (e) => {
    const attribute = e.target.getAttribute('attr');
    switch (attribute) {
      case 'mo':
        this.setState({
          mo: e.target.value,
        });
        break;
      case 'yr':
        this.setState({
          yr: e.target.value,
        });
        break;
      default:
        break;
    }
  };

  renderCycleName = (name, data) => (
    <Link to={`performance-cycles/${data.id}`}>
      <b style={{ color: '#5A5A5A' }}>{name}</b>
    </Link>
  );
  renderModal = () => {
    const { isAdding } = this.props;
    const { modalOpen } = this.state;
    return (
      <EnhancedModal
        title="New Performance Cycle"
        visible={modalOpen}
        userId={this.props.userId}
        onCancel={this.handleCancel}
        width={650}
        footer={[
          <EnhancedButton key="back" onClick={this.handleCancel}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton key="add" type="primary" loading={isAdding} onClick={this.handleSubmit}>
            Save
          </EnhancedButton>,
        ]}
      >
        {this.renderFormRow()}
      </EnhancedModal>
    );
  };
  renderFormRow = () => (
    <EnhancedRow type="flex" justify="center">
      <EnhancedForm layout="inline">
        <EnhancedCol span={24} className="form-col">
          <p>
            <RequiredField>Cycle Month</RequiredField>
          </p>
          <EnhancedInput
            placeholder="Add Cycle Month"
            id="mo"
            attr="mo"
            onChange={this.handleNameChange}
            value={this.state.mo}
          />
        </EnhancedCol>
        <EnhancedCol span={24} className="form-col">
          <p>
            <RequiredField>Cycle Year</RequiredField>
          </p>
          <EnhancedInput
            placeholder="Add Cycle Year"
            id="yr"
            attr="yr"
            onChange={this.handleNameChange}
            value={this.state.yr}
          />
        </EnhancedCol>
      </EnhancedForm>
    </EnhancedRow>
  );
  render() {
    const { isLoading } = this.props;
    return (
      <div>
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol lg={24}>
              <h1 className="heading-pro">Performance Cycle</h1>
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        {this.state.modalOpen ? this.renderModal() : ''}
        <div className="project-table">
          <EnhancedClientSideTable
            data={this.props.performanceCycle}
            columns={this.getColumnsInfo()}
            loading={isLoading}
            exportFileConfig={{
              fileName: 'Performance Cycles',
              fileHeaders: this.getExportFileHeaders(),
              showExportButton: true,
            }}
          />
        </div>
      </div>
    );
  }
}

export default PerformanceCycle;
