import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedModal,
  EnhancedInput,
  EnhancedForm,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import RequiredField from 'components/RequiredField';

const AddGoalModal = (props) => {
  const {
    isAdd,
    isEdit,
    params,
    details,
    handleOk,
    isLoading,
    handleCancel,
    isModalVisible,
    editableHandler,
    addGoalCategoryActions,
    updateGoalCategoryActions,
  } = props;

  const [goalsField, setGoalsField] = useState({
    title: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setGoalsField({
      ...goalsField,
      [name]: value,
    });
  };

  const create = () => {
    addGoalCategoryActions({
      payload: goalsField,
      parameters: params,
    });
    handleOk();
    setGoalsField({
      title: '',
    });
    editableHandler(false);
  };

  const edit = () => {
    updateGoalCategoryActions({
      id: details.id,
      userId: details.userId,
      payload: goalsField,
      params,
    });
    setGoalsField({
      title: '',
    });
    editableHandler(false);
    handleOk();
  };

  const closeModalHandler = () => {
    handleCancel();
    setGoalsField({
      title: '',
    });
    editableHandler(false);
  };

  useEffect(() => {
    if (isEdit) {
      setGoalsField({
        title: details.title,
      });
    }
  }, [isLoading]);

  const modalTitle = () => {
    let titleText = '';
    if (isEdit) titleText = 'Edit Category';
    else if (isAdd) titleText = 'Add Category';

    return titleText;
  };

  return (
    <EnhancedModal
      title={modalTitle()}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={closeModalHandler}
      className="add-goals-modal"
      width="1041px"
      footer={[
        <EnhancedButton
          key="submit"
          type="primary"
          onClick={isEdit ? edit : create}
          disabled={!goalsField.title}
        >
          {'Submit'}
        </EnhancedButton>,
      ]}
    >
      <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
        loading={isLoading}
        bordered={false}
        className="details-card-wrapper"
        style={{
          marginTop: 10,
        }}
      >
        <EnhancedRow>
          <EnhancedForm layout="inline" className="goals-form w-full">
            <EnhancedCol lg={4}>
              <RequiredField> Title </RequiredField>
            </EnhancedCol>
            <EnhancedCol lg={20}>
              <EnhancedInput name="title" value={goalsField.title} onChange={handleChange} />
            </EnhancedCol>
          </EnhancedForm>
        </EnhancedRow>
      </EnhancedCard>
    </EnhancedModal>
  );
};

export default AddGoalModal;
