import {
  EnhancedCol,
  EnhancedRow,
  EnhancedCard,
  EnhancedButton,
  EnhancedRadio,
  EnhancedDropdown,
  EnhancedIcon,
  EnhancedMenu,
} from 'components/shared/antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { parse } from 'utils/stringUtils';
import { NOT_AVAILABLE } from 'configs/additionalConstants';
import 'components/employees/Employees.css';
import PROJECT_ROUTES from 'constants/UrlConstants';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import { hasPermission } from 'utils/AccessUtils';
import { calculateBasicSalary, calculateMedicalAllowance } from 'utils/employeeProfileUtils';
import {
  STATUS_ACTIVE,
  STATUS_ALL,
  STATUS_INACTIVE,
  DATE_FORMAT,
  GRADES,
} from 'configs/employeeProfileConstants';
import Authorize from '../Authorize';
import AccessPermissions from '../../constants/AccessPermissions';
import EmployeesBulkInfoUpload from './EmployeesBulkInfoUpload';

class AllEmployees extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortedInfo: null,
      employees: props.employees || [],
      filterType: STATUS_ALL,
      openBulkInfoModal: false,
    };
  }

  componentDidMount() {
    this.props.getAllEmployeesActions();
    this.props.getAllDesignations();
    this.props.getAllLocations();
    this.props.getEmployeeDepartmentsAction();
    this.props.getEmployeeDivisionsAction();
    this.props.getEmploymentStatuses();
  }

  componentWillReceiveProps(nextProps) {
    let { employees } = this.props;

    employees = employees !== nextProps.employees ? nextProps.employees : employees;

    this.setState({
      employees,
      filterType: STATUS_ALL,
    });
  }

  onClickButton = () => {
    this.setState({ openBulkInfoModal: true });
  };

  onCloseModal = () => {
    this.setState({ openBulkInfoModal: false });
  };

  getColumnsInfo = () => {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'ID',
        dataIndex: 'taleoId',
        key: 'taleoId',
        render: this.renderEmployeeID,
        width: 100,
        sortOrder: sortedInfo.columnKey === 'taleoId' && sortedInfo.order,
        filterConfig: {
          type: 'search',
          key: 'taleoId',
        },
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 'auto',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        render: this.renderEmployeeName,
        filterConfig: {
          type: 'search',
          key: 'name',
        },
      },
      {
        title: 'Designation',
        dataIndex: 'designation',
        key: 'designation',
        width: 'auto',
        sorter: (a, b) => this.sorterForNestedObj(a, b, 'designation'),
        sortOrder: sortedInfo.columnKey === 'designation' && sortedInfo.order,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'designation.name',
          optionsConfig: {
            values: this.props.designations,
          },
        },
        ellipsis: true,
        render: this.renderEmployeeDesignation,
      },
      {
        title: 'Grade',
        dataIndex: 'grade',
        key: 'grade',
        width: 'auto',
        sorter: (a, b) => a.grade.localeCompare(b.grade),
        sortOrder: sortedInfo.columnKey === 'grade' && sortedInfo.order,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'grade',
          optionsConfig: {
            values: GRADES,
            valueVar: 'value',
            labelVar: 'value',
          },
        },
        render: this.renderEmployeeGrade,
      },
      {
        title: 'Line Manager',
        dataIndex: 'managerName',
        key: 'manager',
        width: 'auto',
        sorter: (a, b) => a.managerName.localeCompare(b.managerName),
        sortOrder: sortedInfo.columnKey === 'manager' && sortedInfo.order,
        filterConfig: {
          type: 'search',
          key: 'managerName',
        },
        render: this.renderEmployeeManager,
      },
      {
        title: 'Department',
        dataIndex: 'department',
        key: 'department',
        width: 'auto',
        sorter: (a, b) => this.sorterForNestedObj(a, b, 'department'),
        sortOrder: sortedInfo.columnKey === 'department' && sortedInfo.order,
        render: this.renderEmployeeDepartment,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'department.name',
          optionsConfig: {
            values: this.props.departments,
          },
        },
      },
      {
        title: 'Division',
        dataIndex: 'division',
        key: 'division',
        width: 'auto',
        render: this.renderDivision,
        sorter: (a, b) => this.sorterForNestedObj(a, b, 'division'),
        sortOrder: sortedInfo.columnKey === 'division' && sortedInfo.order,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'division.name',
          optionsConfig: {
            values: this.props.divisions,
          },
        },
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        width: 'auto',
        sorter: (a, b) => this.sorterForNestedObj(a, b, 'location'),
        sortOrder: sortedInfo.columnKey === 'location' && sortedInfo.order,
        ellipsis: true,
        render: this.renderLocation,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'location.name',
          optionsConfig: {
            values: this.props.locations,
          },
        },
      },
      {
        title: 'Employment Status',
        dataIndex: 'employmentStatus',
        key: 'employmentStatus',
        width: 'auto',
        render: (data) => (data ? data.name : NOT_AVAILABLE),
        sorter: (a, b) => this.sorterForNestedObj(a, b, 'employmentStatus'),
        sortOrder: sortedInfo.columnKey === 'employmentStatus' && sortedInfo.order,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'employmentStatus.name',
          optionsConfig: {
            values: this.props.dropDowns.employmentStatuses || [],
            valueVar: 'value',
            labelVar: 'value',
          },
        },
      },
    ];
    return columns;
  };

  getFileHeaders = () => {
    const headers = [
      {
        label: 'ID',
        key: 'taleoId',
      },
      {
        label: 'First Name',
        key: 'firstName',
      },
      {
        label: 'Middle Name',
        key: 'middleName',
      },
      {
        label: 'Last Name',
        key: 'lastName',
      },
      {
        label: 'Name',
        key: 'name',
      },
      {
        label: 'Father/Husband Name',
        key: 'details.fatherName',
      },
      {
        label: 'Designation',
        key: 'designation.name',
      },
      {
        label: 'Email',
        key: 'email',
      },
      {
        label: 'Personal Email',
        key: 'details.personalEmail',
      },
      {
        label: 'Mobile Number',
        key: 'mobileNumber',
      },
      {
        label: 'Phone Number',
        key: 'phoneNumber',
      },
      {
        label: 'CNIC',
        key: 'details.cnic',
      },
      {
        label: 'CNIC Expiry Date',
        key: 'details.cnicExpiry',
        renderer: this.dateRenderer,
      },
      {
        label: 'Date Of Birth',
        key: 'dateOfBirth',
        renderer: this.dateRenderer,
      },
      {
        label: 'Line Manager',
        key: 'managerName',
      },
      {
        label: 'Manager ID',
        key: 'managerOracleId',
      },
      {
        label: 'Grade',
        key: 'grade',
      },
      {
        label: 'Sub Grade',
        key: 'subGrade',
      },
      {
        label: 'Business Unit',
        key: 'businessUnit.name',
      },
      {
        label: 'Division',
        key: 'division.name',
      },
      {
        label: 'Competency',
        key: 'competency.name',
      },
      {
        label: 'Department',
        key: 'department.name',
      },
      {
        label: 'Location',
        key: 'location.name',
      },
      {
        label: 'Present Address',
        key: 'presentAddress',
      },
      {
        label: 'Permanent Address',
        key: 'permanentAddress',
      },
      {
        label: 'Employment Status',
        key: 'employmentStatus.name',
      },
      {
        label: 'Date Of Joining',
        key: 'joiningDate',
        renderer: this.dateRenderer,
      },
      {
        label: 'Last Working Day',
        key: 'leavingDate',
        renderer: this.dateRenderer,
      },
      {
        label: 'Basic Salary',
        key: 'grossSalary',
        renderer: (value) => calculateBasicSalary(value),
      },
      {
        label: 'Medical Allowance',
        key: 'grossSalary',
        renderer: (value) => calculateMedicalAllowance(value),
      },
      {
        label: 'Gross Salary',
        key: 'grossSalary',
      },
      {
        label: 'Inflation Support',
        key: 'inflationSupport',
      },
      {
        label: 'Fuel Allowance',
        key: 'fuelAllowance',
      },
      {
        label: 'Religion',
        key: 'details.religion',
      },
      {
        label: 'Marital Status',
        key: 'details.maritalStatus',
      },
      {
        label: 'Employment Type',
        key: 'employmentType.name',
      },
      {
        label: 'Total Experience',
        key: 'totalExperience',
      },
      {
        label: 'Gender',
        key: 'genderId',
      },
      {
        label: 'OPD Eligibility',
        key: 'details.opdEligibility',
        renderer: this.booleanRenderer,
      },
      {
        label: 'IPD Eligibility',
        key: 'details.ipdEligibility',
        renderer: this.booleanRenderer,
      },
      {
        label: 'IPD Eligibility Options',
        key: 'details.ipdEligibilityOptions',
      },
      {
        label: 'Transport Assistance Eligibility',
        key: 'details.transportAssistanceEligibility',
        renderer: this.booleanRenderer,
      },
    ];

    return headers;
  };

  getDownloadsMenu = () => {
    const menuItems = [];
    const { user } = this.props;

    if (hasPermission(user.permissions, [AccessPermissions.Employee.Profile.Info.Family.Export])) {
      menuItems.push(
        <EnhancedMenu.Item key="employee-family-info">Employees Family Info</EnhancedMenu.Item>,
      );
    }
    return <EnhancedMenu onClick={this.handleDownloadsMenuClick}>{menuItems}</EnhancedMenu>;
  };

  handleDownloadsMenuClick = (event) => {
    switch (event.key) {
      case 'employee-family-info':
        this.handleDownloadFamilyInfo();
        break;
      default:
        break;
    }
  };

  sorterForNestedObj = (a, b, key) => {
    const first = a[key] ? a[key].name : NOT_AVAILABLE;
    const second = b[key] ? b[key].name : NOT_AVAILABLE;
    return first.localeCompare(second);
  };

  dateRenderer = (value) => (value ? moment(value).format(DATE_FORMAT) : '');

  booleanRenderer = (value) => (value ? 'Yes' : 'No');

  handleAddEmployee = () => {
    this.props.history.push(`${PROJECT_ROUTES.EMPLOYEE_CREATE_ROUTE}`);
  };

  handleDownloadFamilyInfo = async () => {
    const { filterType } = this.state;
    this.props.getEmployeesFamilyInfoActions(filterType);
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  };

  handleFilterChange = (event) => {
    if (this.props.employees.length) {
      let filteredEmployees = this.props.employees;
      const filterType = event.target.value;
      if (filterType !== STATUS_ALL) {
        const isActive = filterType === STATUS_ACTIVE;
        const { employees } = this.props;
        filteredEmployees = employees.filter((emp) => emp.isActive === isActive);
      }
      this.setState({ employees: filteredEmployees, filterType });
    }
  };

  renderEmployeeName = (name, data) => {
    const link = parse(PROJECT_ROUTES.EMPLOYEE_PROFILE_ROUTE, {
      id: data.id,
    });
    return (
      <Link to={link}>
        <b style={{ color: '#5A5A5A' }}>{name}</b>
      </Link>
    );
  };

  renderEmployeeID = (_, data) => {
    const employeeId = data.taleoId ? data.taleoId : NOT_AVAILABLE;
    return <div style={{ color: '#5A5A5A', wordBreak: 'normal' }}>{employeeId}</div>;
  };

  renderEmployeeDepartment = (_, data) => (
    <div style={{ color: '#5A5A5A', wordBreak: 'normal' }}>
      {data.department ? data.department.name : NOT_AVAILABLE}
    </div>
  );

  renderEmployeeGrade = (_, data) => <span>{data.grade ? data.grade : NOT_AVAILABLE}</span>;

  renderLocation = (_, data) => <span>{data.location ? data.location.name : NOT_AVAILABLE}</span>;

  renderEmployeeDesignation = (_, data) => (
    <div style={{ color: '#5A5A5A', wordBreak: 'normal' }}>
      {data.designation ? data.designation.name : NOT_AVAILABLE}
    </div>
  );

  renderEmployeeManager = (_, data) => (
    <div style={{ color: '#5A5A5A', wordBreak: 'normal' }}>
      {data.managerName ? data.managerName : NOT_AVAILABLE}
    </div>
  );

  renderDivision = (_, data) => (
    <div style={{ color: '#5A5A5A', wordBreak: 'normal' }}>
      {data.division ? data.division.name : NOT_AVAILABLE}
    </div>
  );

  renderModal = () => {
    const { employeesBulkInfo } = this.props;
    const { openBulkInfoModal } = this.state;

    return (
      <EmployeesBulkInfoUpload
        visible={openBulkInfoModal}
        onClose={this.onCloseModal}
        employeesBulkInfo={employeesBulkInfo}
      />
    );
  };

  render() {
    const { isLoading, match, user } = this.props;
    const { employees } = this.state;
    const { filterType } = this.state;
    const columns = this.getColumnsInfo();
    const statusHeading = match.params.status;

    return (
      <div>
        <EnhancedCard>
          <EnhancedRow style={{ marginBottom: '10px' }}>
            <EnhancedCol span={12}>
              <h2 className="heading-emp">{statusHeading} Employees</h2>
            </EnhancedCol>
            <EnhancedCol span={12}>
              <h2 className="heading-emp" style={{ float: 'right', textTransform: 'capitalize' }}>
                {filterType} Employees Count: {employees.length}
              </h2>
            </EnhancedCol>
            <EnhancedCol span={8} style={{ textAlign: 'left' }}>
              <EnhancedRadio.Group onChange={this.handleFilterChange} value={filterType}>
                <EnhancedRadio.Button style={{ textTransform: 'capitalize' }} value={STATUS_ALL}>
                  {STATUS_ALL}
                </EnhancedRadio.Button>
                <EnhancedRadio.Button style={{ textTransform: 'capitalize' }} value={STATUS_ACTIVE}>
                  {STATUS_ACTIVE}
                </EnhancedRadio.Button>
                <EnhancedRadio.Button
                  style={{ textTransform: 'capitalize' }}
                  value={STATUS_INACTIVE}
                >
                  {STATUS_INACTIVE}
                </EnhancedRadio.Button>
              </EnhancedRadio.Group>
            </EnhancedCol>
            <EnhancedCol span={16} style={{ textAlign: 'right' }}>
              <Authorize
                requiredPermissions={[AccessPermissions.Employee.Profile.Info.Family.Export]}
              >
                <EnhancedDropdown overlay={this.getDownloadsMenu()} className="mr-16">
                  <EnhancedButton>
                    Downloads
                    <EnhancedIcon type="down" />
                  </EnhancedButton>
                </EnhancedDropdown>
              </Authorize>

              <Authorize requiredPermissions={[AccessPermissions.Employee.Profile.Info.BulkUpdate]}>
                <EnhancedButton className="mb-20" onClick={() => this.onClickButton()}>
                  Employee Info Bulk Upload
                </EnhancedButton>
              </Authorize>

              <Authorize requiredPermissions={[AccessPermissions.Employee.Create]}>
                <EnhancedButton
                  onClick={this.handleAddEmployee}
                  style={{ marginLeft: '15px' }}
                  type="primary"
                >
                  Create new employee
                </EnhancedButton>
              </Authorize>
            </EnhancedCol>
          </EnhancedRow>
        </EnhancedCard>
        <EnhancedCard>
          <div>
            <EnhancedClientSideTable
              data={employees}
              columns={columns}
              loading={isLoading}
              onChange={this.handleChange}
              exportFileConfig={{
                fileName: 'All Employees',
                fileHeaders: this.getFileHeaders(),
                showExportButton: hasPermission(user.permissions, [
                  AccessPermissions.Employee.All.Export,
                ]),
              }}
            />
          </div>
        </EnhancedCard>
        {this.state.openBulkInfoModal ? this.renderModal() : ''}
      </div>
    );
  }
}

export default AllEmployees;
