import {
  EnhancedIcon,
  EnhancedInput,
  EnhancedForm,
  EnhancedNotification,
} from 'components/shared/antd';
import React from 'react';
import { notSetVariable } from 'configs/additionalConstants';

const FormItem = EnhancedForm.Item;

class EmployeeEditable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      editable: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    if (value !== this.state.value) this.setState({ value });
  }
  onSubmit = () => {
    const { updateInput, inputName } = this.props;
    const { value } = this.state;
    if (value.length === 0) {
      EnhancedNotification.warning({
        message: 'Validation Error',
        description: `${inputName} can not be empty.`,
      });
      return;
    }
    updateInput(inputName, value);
    this.setState({ editable: false });
  };

  onRemoveCategory = () => {
    const {
      removeCategory,
      category: { categoryId: id },
      login: {
        user: { token },
      },
    } = this.props;
    removeCategory(token, { id });
  };

  onCancelUpdate = () => {
    this.setState({ editable: false });
  };
  onEdit = () => {
    const { value } = this.props;
    this.setState({ editable: true, value });
  };
  handleChange = (e) => {
    const { value } = e.target;
    this.setState({ value }, () => {
      if (this.props.updateInput) this.props.updateInput(this.props.inputName, value);
    });
  };
  validate = (value) => {
    if (!value || notSetVariable.includes(value)) {
      return false;
    }
    return true;
  };

  render() {
    const { editable } = this.state;
    const { editingAllowed, value, field } = this.props;
    if (!editingAllowed) {
      return (
        <div className="editable-cell">
          {this.validate(value) ? value : <span className="field-details">Not Set</span>}
        </div>
      );
    }
    return (
      <div
        className="editable-cell"
        style={{
          cursor: 'pointer',
          ...this.props.style,
        }}
      >
        {editable ? (
          <div className="wrapper">
            <FormItem style={{ margin: 0 }}>
              <EnhancedInput
                value={value}
                onChange={this.handleChange}
                onPressEnter={this.onSubmit}
                style={{
                  width: '100%',
                  cursor: 'pointer',
                }}
                size="small"
                required
              />
            </FormItem>
            <EnhancedIcon
              type="check"
              className="input-icon check"
              style={{
                marginLeft: '0.5em',
                marginTop: '0.4rem',
                cursor: 'pointer',
                ...this.props.iconStyle,
              }}
              onClick={this.onSubmit}
              title="Click to save"
            />
            <EnhancedIcon
              type="close"
              className="input-icon close"
              style={{
                marginLeft: '0.5em',
                marginTop: '0.4rem',
                cursor: 'pointer',
                ...this.props.iconStyle,
              }}
              onClick={this.onCancelUpdate}
              title="Click to close"
            />
          </div>
        ) : (
          <div
            className="inline"
            role="presentation"
            onClick={this.onEdit}
            onKeyDown={this.onEdit}
            style={{ cursor: 'pointer' }}
          >
            {this.validate(value, field) ? value : <span className="field-details">Not Set</span>}
          </div>
        )}
      </div>
    );
  }
}

export default EmployeeEditable;
