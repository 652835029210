import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';

const EnhancedAvatar = (props) => {
  const { children, className, src, size, icon, style } = props;

  const getIcon = () => {
    switch (icon) {
      case 'user':
        return <UserOutlined />;
      default:
        return null;
    }
  };

  return (
    <Avatar className={className} src={src} size={size} icon={getIcon(icon)} style={style}>
      {children}
    </Avatar>
  );
};

EnhancedAvatar.propTypes = {
  src: PropTypes.string.isRequired,
  size: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

EnhancedAvatar.defaultProps = {
  className: '',
  style: {},
  size: 'default',
  icon: '',
};

export default EnhancedAvatar;
