import { connect } from 'react-redux';
import { getDealNameAction, getDealsSkillWiseAction } from 'store/slices/Deal';
import { getResourceDeficitReportActions } from 'store/actions/ResourcePlannerActions';
import ResourceDeficitReport from 'components/resourcePlanner/Reports/ResourceDeficitReport/ResourceDeficitReport';
import { getEmployeeBusinessUnitAction } from 'store/actions/EmployeeActions';

const mapStateToProps = (state) => {
  const { dealReducer, resourcePlannerReducer, employeeReducer } = state;
  const { businessUnits } = employeeReducer;

  return {
    dealNames: dealReducer.dealName,
    resourceDeficit: resourcePlannerReducer.resourceDeficit,
    dealsSkillWiseRows: dealReducer.dealsSkillWise.rows,
    dealsSkillWisePageInfo: dealReducer.dealsSkillWise.pageInfo,
    businessUnits,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getDealName: (req) => dispatch(getDealNameAction(req)),
    getDealsSkillWise: (req) => dispatch(getDealsSkillWiseAction(req)),
    getResourceDeficitReport: (req) => dispatch(getResourceDeficitReportActions(req)),
    getEmployeeBusinessUnitAction: (req) => dispatch(getEmployeeBusinessUnitAction(req)),
  };
}

const ResourceDeficitContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResourceDeficitReport);

export default ResourceDeficitContainer;
