import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import EnhancedIcon from '../EnhancedIcon';

const EnhancedButton = (props) => {
  const {
    children,
    className,
    style,
    type,
    onClick,
    loading,
    key,
    icon,
    disabled,
    shape,
    htmlType,
    block,
    size,
    'data-testid': testId,
    ...rest
  } = props;

  return (
    <Button
      style={style}
      type={type}
      className={className}
      onClick={onClick}
      loading={loading}
      key={key}
      icon={icon && <EnhancedIcon type={icon} />}
      disabled={disabled}
      shape={shape}
      htmlType={htmlType}
      block={block}
      size={size}
      data-testid={testId}
      {...rest}
    >
      {children}
    </Button>
  );
};

EnhancedButton.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  key: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  shape: PropTypes.string.isRequired,
  htmlType: PropTypes.string,
  block: PropTypes.bool,
  size: PropTypes.string.isRequired,
};

EnhancedButton.defaultProps = {
  loading: false,
  disabled: false,
  htmlType: 'button',
  block: false,
  className: '',
  style: {},
};

export default EnhancedButton;
