/* eslint-disable no-prototype-builtins */
import {
  EnhancedButton,
  EnhancedCol,
  EnhancedRow,
  EnhancedCard,
  EnhancedAlert,
  EnhancedModal,
  EnhancedSelect,
  EnhancedDatePicker,
  EnhancedInput,
  EnhancedForm,
} from 'components/shared/antd';
import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import RatingModal from 'components/pc/ratingModal';
import projectIcon from 'images/project_icon.svg';
import { dateFormat, BREADCRUMBS } from 'configs/constants';
import RequiredField from '../RequiredField';

const { Option } = EnhancedSelect;

class PerformanceCycle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: {
        name: '',
        startDate: '',
        endDate: '',
        projectManagerId: undefined,
        employeeId: props.userId,
      },
      showAddNewForm: false,
      modalOpen: false,
      /*      searchProjectText: '',
      searchManagerText: '', */
      errorText: '',
      addRatingModal: false,
    };
  }

  componentWillMount() {
    this.props.getProjectByCycleIdAction(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    const { projectCycleData, updateBreadCrumb, isAdding, match } = nextProps;
    const previousProjectCycleData = this.props.projectCycleData;
    const { cycleInfo = {} } = projectCycleData;
    const { cycleName = '' } = cycleInfo;
    const { params } = match;
    const { id } = params;

    if (previousProjectCycleData.cycleInfo !== cycleInfo) {
      updateBreadCrumb(BREADCRUMBS.CYCLES, id, cycleName);
    }
    if (this.props.match.params.status !== params.status) {
      this.clearSearch();
    }
    if (!isAdding && this.props.isAdding && this.state.modalOpen) {
      this.handleCancel();
    }
  }

  getColumnsInfo = () => {
    const columns = [
      {
        title: 'Project Name',
        dataIndex: 'name',
        key: 'name',
        width: '25%',
        filterConfig: {
          type: 'search',
          key: 'name',
        },
        render: this.renderProjectName,
      },
      {
        title: 'Project Manager',
        dataIndex: 'projectManager',
        key: 'projectManager',
        filterConfig: {
          type: 'search',
          key: 'projectManager',
        },
        width: '30%',
      },
      {
        title: 'Project Architect',
        dataIndex: 'projectArchitect',
        key: 'projectArchitect',
        filterConfig: {
          type: 'search',
          key: 'projectArchitect',
        },
        width: '30%',
      },
      {
        title: 'Due Date',
        dataIndex: 'dueDate',
        key: 'dueDate',
        width: '15%',
        render: (value) =>
          value ? moment.utc(value).format(dateFormat.DDMMMYYYYwithSpace) : 'N/A',
      },
    ];
    return columns;
  };

  showTotal = (total) => `Total ${total} items`;

  showAddNew = () => {
    this.setState({
      showAddNewForm: !this.state.showAddNewForm,
    });
  };

  showModal = () => {
    this.setState({
      modalOpen: true,
    });
  };

  handleCancel = () => {
    this.setState({
      modalOpen: false,
      project: {
        name: '',
        startDate: '',
        endDate: '',
        projectManagerId: '',
      },
      errorText: '',
    });
  };

  handleNameChange = (value) => {
    const project = { ...this.state.project };
    project.name = value.target.value;
    this.setState({
      project,
    });
  };

  handleManagerChange = (manager) => {
    this.onChange('projectManagerId', manager);
  };

  disabledStartDate = (startValue) => {
    const endValue = this.state.project.endDate ? moment.utc(this.state.project.endDate) : Date();
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = (endValue) => {
    const startValue = moment.utc(this.state.project.startDate).add(1, 'days');
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  /* handleSave = (value) => {
    value.preventDefault();
    const { name } = this.state.project;
    const { projects } = this.props;
    const similarProject = projects.filter(project =>
      project.name.toLowerCase() === name.toLowerCase())[0];

    if (similarProject) {
      this.setState({
        errorText: 'Project with the same name already exists.',
      });
    } else {
      this.props.addProject(this.state.project);
      this.handleCancel();
    }
  } */
  shouldButtonDisable = () => {
    const { project } = this.state;
    if (project.name && project.projectManagerId && project.startDate) return false;
    return true;
  };
  clearSearch = () => {
    this.setState({
      /*     searchManagerText: '',
      searchProjectText: '', */
    });
  };
  openRatingModal = (data) => {
    const { getRatingDetails, userId } = this.props;
    const payload = {
      performanceCycleId: this.props.match.params.id,
      projectId: data.id,
      raterId: userId,
    };
    getRatingDetails(payload);
    this.setState({
      addRatingModal: true,
    });
  };

  handleAddRatingClose = () => {
    this.setState({ addRatingModal: false });
  };

  renderFormRow = () => {
    const { employees } = this.props;
    const { errorText } = this.state;
    return (
      <EnhancedRow type="flex" justify="center">
        {errorText && (
          <EnhancedAlert
            style={{ width: '100%' }}
            message={errorText}
            type="error"
            closable
            onClose={this.onClose}
          />
        )}
        <EnhancedForm layout="inline">
          <EnhancedCol span={24} className="form-col">
            <p>
              <RequiredField>Project Name </RequiredField>
            </p>
            <EnhancedInput
              placeholder="Project Name"
              id="projectName"
              onChange={this.handleNameChange}
              value={this.state.project.name}
            />
          </EnhancedCol>
          <EnhancedCol span={24} className="form-col">
            <p>
              <RequiredField>Project Manager</RequiredField>
            </p>
            <EnhancedSelect
              showSearch
              allowClear
              optionFilterProp="children"
              value={this.state.project.projectManagerId}
              style={{ width: '100%' }}
              placeholder="Please Select"
              onChange={this.handleManagerChange}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {employees && employees.length
                ? employees.map((employee) =>
                    employee.isActive ? (
                      <Option key={employee.id} value={employee.id}>
                        {employee.name}
                      </Option>
                    ) : (
                      ''
                    ),
                  )
                : null}
            </EnhancedSelect>
          </EnhancedCol>
          <EnhancedCol span={24} className="form-col">
            <p>
              <RequiredField>Start Date</RequiredField>
            </p>
            <EnhancedDatePicker
              style={{ width: '100%' }}
              onChange={this.onStartDateChange}
              placeholder="Enter Start Date"
              format={dateFormat.DDMMMYYYYwithSpace}
              allowClear
              disabledDate={this.disabledStartDate}
            />
          </EnhancedCol>
          <EnhancedCol span={24} className="form-col">
            <p>End Date</p>
            <EnhancedDatePicker
              style={{ width: '100%' }}
              onChange={this.onEndDateChange}
              placeholder="Enter End Date"
              format={dateFormat.DDMMMYYYYwithSpace}
              allowClear
              disabledDate={this.disabledEndDate}
            />
          </EnhancedCol>
        </EnhancedForm>
      </EnhancedRow>
    );
  };

  renderModal = () => {
    const { isAdding } = this.props;
    const { modalOpen } = this.state;
    return (
      <EnhancedModal
        title="New Project"
        visible={modalOpen}
        userId={this.props.userId}
        onCancel={this.handleCancel}
        width={650}
        footer={[
          <EnhancedButton key="back" onClick={this.handleCancel}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            key="add"
            type="primary"
            loading={isAdding}
            disabled={this.shouldButtonDisable()}
            onClick={this.handleSubmit}
          >
            Save
          </EnhancedButton>,
        ]}
      >
        {this.renderFormRow()}
      </EnhancedModal>
    );
  };

  renderProjectName = (name, data) => {
    const { userId } = this.props;
    let response = null;

    // if he is a resource
    if (data.projectManagerId !== userId && data.architectId !== userId) {
      response = (
        // eslint-disable-next-line
        <div style={{ cursor: 'pointer' }} onClick={() => this.openRatingModal(data)}>
          <b style={{ color: '#5A5A5A' }}>{name}</b>
        </div>
      );
    } else {
      // if he is a PM or Arch
      response = (
        <Link to={`/pc/performance-cycles/${this.props.match.params.id}/${data.id}`}>
          <b style={{ color: '#5A5A5A' }}>{name}</b>
        </Link>
      );
    }
    return response;
  };

  render() {
    const { isLoading, projectCycleData, ratingDetails, userId, resetRatingDetails } = this.props;
    const projectCycles = projectCycleData.projectCycles || [];
    let { cycleInfo } = projectCycleData;
    cycleInfo = cycleInfo || {};
    return (
      <div>
        <RatingModal
          ratingDetails={ratingDetails}
          onModalClose={this.handleAddRatingClose}
          userId={userId}
          visible={this.state.addRatingModal}
          addProjectRatingAction={this.props.addProjectRatingAction}
          cycleId={this.props.match.params.id}
          resetRatingDetails={resetRatingDetails}
          // cycleInActive={this.props.projectRating.completeStatus}
        />
        <div style={{ background: 'white' }}>
          <EnhancedCard bordered={false}>
            <EnhancedRow>
              <EnhancedCol lg={2}>
                <img src={projectIcon} alt="logo" style={{ width: '60px', height: '60px' }} />
              </EnhancedCol>
              <EnhancedCol lg={22}>
                <h2 style={{ color: 'black', fontSize: '10' }}>
                  {`Cycle Details - ${cycleInfo.cycleName}`}
                </h2>
                <p>
                  {`${
                    cycleInfo.startDate
                      ? moment(cycleInfo.startDate).format(dateFormat.DDMMMYYYYwithSpace)
                      : ''
                  }
                      -
                      ${
                        cycleInfo.endDate
                          ? moment(cycleInfo.endDate).format(dateFormat.DDMMMYYYYwithSpace)
                          : ''
                      }`}
                </p>
                <p>{`Eligiblity criteria: ${cycleInfo.criteria} days`}</p>
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCard>
        </div>
        {this.state.modalOpen ? this.renderModal() : ''}
        <EnhancedRow style={{ background: 'white' }}>
          <EnhancedCol lg={24}>
            <EnhancedClientSideTable
              loading={isLoading}
              columns={this.getColumnsInfo()}
              data={projectCycles}
            />
          </EnhancedCol>
        </EnhancedRow>
      </div>
    );
  }
}

export default PerformanceCycle;
