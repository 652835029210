import PROJECT_ROUTES from '../constants/UrlConstants';
import { EMPLOYEES } from '../configs/employeeProfileConstants';
import Permissions from '../constants/AccessPermissions';
import {
  ROOT,
  EMPLOYEES_ALL,
  EMPLOYEES_PROFILE,
  EMPLOYEES_CREATE,
} from '../configs/routesConstants';

const routeConfigs = {
  [`/crmc${PROJECT_ROUTES.CRMC_PROJECTS_LISTING_ROUTE}`]: {
    permissions: [Permissions.Project.List, Permissions.Project.Current.List],
  },
  [`/crmc${PROJECT_ROUTES.CRMC_PROJECTS_DETAIL_ROUTE}`]: {
    permissions: [Permissions.Project.List, Permissions.Project.Current.List],
  },
  [`/crmc${PROJECT_ROUTES.CRMC_EMPLOYEES_LISTING_ROUTE}`]: {
    permissions: [Permissions.Employee.List],
  },
  [`${ROOT}${EMPLOYEES}${EMPLOYEES_PROFILE}`]: {
    permissions: [Permissions.Employee.Profile.View],
  },
  [`${ROOT}${EMPLOYEES}${EMPLOYEES_PROFILE}/:performanceCycleId`]: {
    permissions: [Permissions.PerformanceManagement.PerformanceCycle.Evaluation.View],
  },
  [`${ROOT}${EMPLOYEES}${EMPLOYEES_ALL}`]: {
    permissions: [Permissions.Employee.All.List],
  },
  [`${ROOT}${EMPLOYEES}${EMPLOYEES_CREATE}`]: {
    permissions: [Permissions.Employee.Create],
  },
  [`/crmc${PROJECT_ROUTES.CRMC_BILLABLE_RESOURCE_ROUTE}`]: {
    permissions: [Permissions.Employee.Billable.List],
  },
  [`/crmc${PROJECT_ROUTES.CRMC_NON_BILLABLE_ROUTE}`]: {
    permissions: [Permissions.Employee.List],
  },
  [`/crmc${PROJECT_ROUTES.CRMC_DASHBOARD_ROUTE}`]: {
    permissions: [Permissions.Dashboard.View],
  },
  [`/crmc${PROJECT_ROUTES.CRMC_EMPLOYEE_DETAIL_ROUTE}`]: {
    permissions: [Permissions.Employee.View],
  },
  [`/crmc${PROJECT_ROUTES.CRMC_ADD_EMPLOYEES_ROUTE}`]: {
    permissions: [Permissions.Employee.Create],
  },
  '/employee-organization-tree': {
    permissions: [Permissions.Employee.OrgChart.View, Permissions.Reportee.Allowed],
  },
  '/departments': {
    permissions: [Permissions.Configuration.Department.List],
  },
  '/business-unit': {
    permissions: [Permissions.Configuration.BusinessUnit.List],
  },
  '/division': {
    permissions: [Permissions.Configuration.Division.List],
  },
  '/designations': {
    permissions: [Permissions.Configuration.Designation.List],
  },
  '/locations': {
    permissions: [Permissions.Configuration.Location.List],
  },
  '/competencies': {
    permissions: [Permissions.Configuration.Competency.List],
  },
  '/project-roles': {
    permissions: [Permissions.Configuration.ProjectRoles.List],
  },
  '/skills': {
    permissions: [Permissions.Configuration.Skills.List],
  },
  '/employment-types': {
    permissions: [Permissions.Configuration.EmploymentTypes.List],
  },
  '/roles': {
    permissions: [Permissions.Configuration.Roles.List],
  },
  '/performance-management/:id/:empId': {
    permissions: [Permissions.PerformanceManagement.PerformanceCycle.Evaluation.View],
  },
  '/performance-management': {
    permissions: [Permissions.PerformanceManagement.PerformanceCycle.View],
  },
  '/performance-management/:id': {
    permissions: [Permissions.PerformanceManagement.PerformanceCycle.Employee.View],
  },
  '/resourcePlanner/dealSummary': {
    permissions: [Permissions.ResourcePlanner.Deal.Summary.View],
  },
  '/resourcePlanner/transientResource': {
    permissions: [Permissions.ResourcePlanner.TransientResource.View],
  },
  '/resourcePlanner/reports/resource-deficit': {
    permissions: [Permissions.ResourcePlanner.Reports.ResourceDeficit.View],
  },
  '/resourcePlanner/reports/planning-summary': {
    permissions: [Permissions.ResourcePlanner.Reports.Summary.Resources.View],
  },
  '/resourcePlanner/reports/bench-summary': {
    permissions: [Permissions.ResourcePlanner.Reports.Bench.Resources.View],
  },
  '/pm-admin-dashboard': {
    permissions: [Permissions.PerformanceManagement.Reports.Goals],
  },
};

export default routeConfigs;
