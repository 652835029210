import { EnhancedCol, EnhancedRow, EnhancedIcon, EnhancedLayout } from 'components/shared/antd';
import React, { Component } from 'react';
import _ from 'lodash';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import RatingModalPR from 'components/pc/ratingModalPR';
import ProjectScore from './shared/ProjectScore';
import { BREADCRUMBS } from '../../configs/constants';

const { Header } = EnhancedLayout;

class ProjectReviewsRating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ratingData: [],
    };
  }

  componentDidMount() {
    const { getProjectReviewsProjectDataAction, match, getProjectScoreAction } = this.props;
    getProjectReviewsProjectDataAction(match.params.id, match.params.projectId);
    getProjectScoreAction(match.params.id, match.params.projectId);
  }

  componentWillReceiveProps(nextProps) {
    const { projectRating, projectRatingData, updateBreadCrumb, match } = nextProps;
    const previousProjectRating = this.props.projectRating;
    const { projectName, cycleName } = projectRating;
    const { params } = match;
    const { id, projectId } = params;
    if (!_.isEmpty(projectRatingData)) {
      this.setState({
        ratingData: projectRatingData,
        addEditResourceModalVisible: true,
      });
    }
    if (previousProjectRating.cycleName !== cycleName) {
      updateBreadCrumb(BREADCRUMBS.PROJECT_REVIEWS_CYCLE, id, cycleName);
    }
    if (previousProjectRating.projectName !== projectName) {
      updateBreadCrumb(BREADCRUMBS.PROJECT_REVIEWS_CYCLE_PROJECT, projectId, projectName);
    }
  }

  getColumnsInfo = () => {
    const columns = [
      {
        title: 'Resource Name',
        dataIndex: 'name',
        key: 'name',
        width: '100',
        filterConfig: {
          type: 'search',
          key: 'name',
        },
        render: this.renderResourceName,
      },
      {
        title: 'Line Manager',
        dataIndex: 'lineManager',
        key: 'lineManager',
        width: '100',
        filterConfig: {
          type: 'search',
          key: 'lineManager',
        },
      },
      {
        title: 'Competency',
        dataIndex: 'department',
        key: 'department',
        width: '100',
        filterConfig: {
          type: 'search',
          key: 'department',
        },
      },
      {
        title: 'PM Status',
        dataIndex: 'PMStatus',
        key: 'PMStatus',
        width: '100',
        render: this.renderStatusName,
      },
      {
        title: 'Architect Status',
        dataIndex: 'archStatus',
        key: 'archStatus',
        width: '100',
        render: this.renderStatusName,
      },
      {
        title: 'Feedback Status',
        dataIndex: 'feedbackStatus',
        key: 'feedbackStatus',
        width: '100',
        render: this.renderStatusName,
      },
      {
        title: 'Action',
        width: '100',
        render: this.renderIcon,
      },
    ];
    return columns;
  };

  showTotal = (total) => `Total ${total} items`;

  openRatingModal = (data) => {
    const { getProjectReviewsRatingDataAction, match } = this.props;
    const payload = {
      performanceCycleId: Number(match.params.id),
      projectId: Number(match.params.projectId),
      raterId: Number(data.employeeId),
      employeeId: this.props.userId,
    };
    getProjectReviewsRatingDataAction(payload);
    this.setState({
      selectedResourceName: data.name,
    });
  };

  handleEditClose = (close) => {
    this.setState({ addEditResourceModalVisible: close });
  };

  renderResourceName = (name) => <b style={{ color: '#5A5A5A' }}>{name}</b>;

  renderStatusName = (data) => {
    let checkFreeze = null;
    if (data) {
      checkFreeze = 'Complete';
    } else {
      checkFreeze = 'Pending';
    }
    return checkFreeze;
  };

  renderIcon = (name, data) => (
    <span>
      <EnhancedIcon
        onClick={() => this.openRatingModal(data)}
        type="eye"
        className="pr-eye-icon-style"
      />
    </span>
  );

  render() {
    const { isLoading, projectRating, clearProjectReviewsRatingDataAction, projectScore } =
      this.props;
    const { projectRatings } = projectRating;
    let { projectName, cycleName } = projectRating;
    projectName = projectName || '';
    cycleName = cycleName || '';

    return (
      <div>
        {this.state.addEditResourceModalVisible && (
          <RatingModalPR
            onModalClose={this.handleEditClose}
            resourceName={this.state.selectedResourceName}
            projectRating={this.state.ratingData}
            visible="true"
            clearModalDate={clearProjectReviewsRatingDataAction}
          />
        )}
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol lg={12}>
              <h1 className="heading-pro">{`${projectName} - ${cycleName}`}</h1>
            </EnhancedCol>
            <EnhancedCol lg={12} style={{ textAlign: 'right' }}>
              <ProjectScore projectScore={projectScore} />
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        <div className="project-table">
          <EnhancedClientSideTable
            loading={isLoading}
            columns={this.getColumnsInfo()}
            data={projectRatings || []}
          />
        </div>
      </div>
    );
  }
}

export default ProjectReviewsRating;
