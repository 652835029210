import {
  GET_TRANSIENT_RESOURCE_INIT,
  GET_TRANSIENT_RESOURCE_SUCCESS,
  GET_TRANSIENT_RESOURCE_FAILURE,
  CREATE_TRANSIENT_RESOURCE_INIT,
  CREATE_TRANSIENT_RESOURCE_SUCCESS,
  CREATE_TRANSIENT_RESOURCE_FAILURE,
  DELETE_TRANSIENT_RESOURCE_INIT,
  DELETE_TRANSIENT_RESOURCE_SUCCESS,
  DELETE_TRANSIENT_RESOURCE_FAILURE,
  UPDATE_TRANSIENT_RESOURCE_INIT,
  UPDATE_TRANSIENT_RESOURCE_SUCCESS,
  UPDATE_TRANSIENT_RESOURCE_FAILURE,
  GET_BENCH_SUMMARY_INIT,
  GET_BENCH_SUMMARY_SUCCESS,
  GET_BENCH_SUMMARY_FAILURE,
  GET_RESOURCE_DEFICIT_REPORT_INIT,
  GET_RESOURCE_DEFICIT_REPORT_SUCCESS,
  GET_RESOURCE_DEFICIT_REPORT_FAILURE,
} from 'constants/resourcePlannerTypes';
import {
  getTransientResource,
  getAllTransientResources,
  createNewTransientResource,
  deleteTransientResource,
  updateTransientResource,
  getBenchSummary,
  getResourceDeficitReport,
} from 'sources/ResourcePlannerSource';

export const getBenchSummaryInit = () => ({
  type: GET_BENCH_SUMMARY_INIT,
});

export const getBenchSummarySuccess = (data) => ({
  type: GET_BENCH_SUMMARY_SUCCESS,
  data,
});

export const getBenchSummaryFailure = () => ({
  type: GET_BENCH_SUMMARY_FAILURE,
});

export const getResourceDeficitReportInit = () => ({
  type: GET_RESOURCE_DEFICIT_REPORT_INIT,
});

export const getResourceDeficitReportSuccess = (data) => ({
  type: GET_RESOURCE_DEFICIT_REPORT_SUCCESS,
  data,
});

export const getResourceDeficitReportFailure = () => ({
  type: GET_RESOURCE_DEFICIT_REPORT_FAILURE,
});

export const getTransientResourceInit = () => ({
  type: GET_TRANSIENT_RESOURCE_INIT,
});

export const getTransientResourceSuccess = (data) => ({
  type: GET_TRANSIENT_RESOURCE_SUCCESS,
  data,
});

export const getTransientResourceFailure = () => ({
  type: GET_TRANSIENT_RESOURCE_FAILURE,
});

export const createTransientResourceInit = () => ({
  type: CREATE_TRANSIENT_RESOURCE_INIT,
});

export const createTransientResourceSuccess = (data) => ({
  type: CREATE_TRANSIENT_RESOURCE_SUCCESS,
  data,
});

export const createTransientResourceFailure = () => ({
  type: CREATE_TRANSIENT_RESOURCE_FAILURE,
});

export const deleteTransientResourceInit = () => ({
  type: DELETE_TRANSIENT_RESOURCE_INIT,
});

export const deleteTransientResourceSuccess = (data) => ({
  type: DELETE_TRANSIENT_RESOURCE_SUCCESS,
  data,
});

export const deleteTransientResourceFailure = () => ({
  type: DELETE_TRANSIENT_RESOURCE_FAILURE,
});

export const updateTransientResourceInit = () => ({
  type: UPDATE_TRANSIENT_RESOURCE_INIT,
});

export const updateTransientResourceSuccess = (data) => ({
  type: UPDATE_TRANSIENT_RESOURCE_SUCCESS,
  data,
});

export const updateTransientResourceFailure = () => ({
  type: UPDATE_TRANSIENT_RESOURCE_FAILURE,
});

// Async Actions
export const getTransientResourceActions = (data) => getTransientResource(data);

export const getAllTransientResourceActions = (params) => getAllTransientResources(params);

export const createTransientResourceActions = (data) => createNewTransientResource(data);

export const deleteTransientResourceActions = (data) => deleteTransientResource(data);

export const updateTransientResourceActions = (data, id) => updateTransientResource(data, id);

export const getBenchSummaryActions = (data) => getBenchSummary(data);

export const getResourceDeficitReportActions = (data) => getResourceDeficitReport(data);
