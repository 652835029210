import { dateRenderer } from 'utils/DateUtils';

export const allDealMeta = [
  {
    label: 'Business Unit',
    key: 'businessUnit',
  },
  {
    label: 'Deal Name',
    key: 'dealName',
  },
  {
    label: 'Deal Number',
    key: 'dealNumber',
  },
  {
    label: 'Deal Owner',
    key: 'dealOwner',
  },
  {
    label: 'Client',
    key: 'clientName',
  },
  {
    label: 'Probability',
    key: 'probability',
  },
  {
    label: 'Expected Start Date',
    key: 'expectedStartDate',
    renderer: dateRenderer,
  },
  {
    label: 'Total Required',
    key: 'totalRequired',
  },
  {
    label: 'Stage',
    key: 'stage',
  },
  {
    label: 'Closed At',
    key: 'closedAt',
    renderer: dateRenderer,
  },
];

export const EXPERIENCE = [
  { key: 'A (0-2 years)', value: 'A (0-2 years)' },
  { key: 'B (2-4 years)', value: 'B (2-4 years)' },
  { key: 'C (4-6 years)', value: 'C (4-6 years)' },
  { key: 'D (6-9 years)', value: 'D (6-9 years)' },
  { key: 'E (9+ years)', value: 'E (9+ years)' },
];

export const DEAL_RESOURCE_STATUS = {
  SUGGESTED: 'suggested',
  LOCKED: 'locked',
  UNLOCKED: 'unlocked',
  RELEASED: 'released',
  PROFILE_REJECTED: 'profilerejected',
  INTERVIEW_REJECTED: 'interviewrejected',
  PLANNED: 'planned',
};
