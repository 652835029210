import React from 'react';
import {
  EnhancedCol,
  EnhancedRow,
  EnhancedLayout,
  EnhancedCollapse,
} from '../../components/shared/antd';

const { Header } = EnhancedLayout;
const { Panel } = EnhancedCollapse;

const ClaimFaq = () => (
  <div>
    <Header className="project-header">
      <EnhancedRow>
        <EnhancedCol span={24}>
          <h1>
            FAQ<span style={{ textTransform: 'lowercase' }}>s</span>
          </h1>
        </EnhancedCol>
      </EnhancedRow>
    </Header>
    <div className="project-content">
      <EnhancedCollapse bordered={false} accordion>
        <Panel header="What type of expenses can be claimed?" key="1">
          <p style={{ marginLeft: '25px' }}>You can claim for the below categories:</p>
          <ul>
            <li>Business</li>
            <li>Entertainment</li>
            <li>Travel</li>
            <li>Training</li>
            <li>Other</li>
          </ul>
        </Panel>
        <Panel header="What is the approval process of my expense claim form?" key="2">
          <p style={{ marginLeft: '25px' }}>
            Once employee submits the claim form, it will be sent to the respective line manager for
            approval. Once approved, it will be approved by the relevant functional head. After both
            the approvals, Finance will receive the claim form and will process them according to
            designed SOPs.
          </p>
          <p style={{ marginLeft: '25px' }}>
            Those employees who do not have FH, then after LM, approval request will go to FM
          </p>
        </Panel>
        <Panel header="What do LM, FH, and FM stand for?" key="3">
          <ul>
            <li>LM - Line Manager</li>
            <li>FH - Functional Head</li>
            <li>FM - Finance Manager</li>
          </ul>
        </Panel>
        <Panel
          header="When and how will I be notified about the progress on my claim form?"
          key="4"
        >
          <p style={{ marginLeft: '25px' }}>
            Every action performed on the claim form will be notified to the employee via email.
            Similarly, an email will be sent to LM or FH from whom the action is required i.e.
            initially it will be notified to LM when an employee submits the request and after LM
            approval email will be sent to FH and after FH approval email will be sent to FM
          </p>
        </Panel>
        <Panel header="How to read the status of the claim form?" key="5">
          <ul>
            <li>Dark Grey - Pending (i.e. Action needs to be performed)</li>
            <li>
              Light Grey - Waiting (i.e. Waiting for the previous recipient to perform action first)
            </li>
            <li>Green - Approved</li>
            <li>Red - Declined</li>
          </ul>
        </Panel>
        <Panel header="How do I enter the TADA and how it is calculated?" key="6">
          <p style={{ marginLeft: '25px' }}>
            It is auto calculated by the system depending on the number of days selected. The amount
            is determined by the Travel Policy. For international travel, the conversion rate has
            been pre-determined by Finance.
          </p>
        </Panel>
      </EnhancedCollapse>
    </div>
  </div>
);

export default ClaimFaq;
