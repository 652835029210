import {
  GET_PROJECTS_INIT,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILURE,
  CREATE_PROJECT_INIT,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILURE,
  EDIT_PROJECT_DETAILS_INIT,
  EDIT_PROJECT_DETAILS_SUCCESS,
  EDIT_PROJECT_DETAILS_FAILURE,
  ACTIVATE_PROJECT_INIT,
  ACTIVATE_PROJECT_SUCCESS,
  ACTIVATE_PROJECT_FAILURE,
  RESET_PROJECT_ACTIVATION,
} from 'constants/ProjectTypes';
import { dateFormat } from 'configs/constants';
import moment from 'moment';

const initialState = {
  projects: [],
  isLoading: false,
  isAdding: false,
  shouldFetchProjects: true,
  isActivated: false,
};
export default function projectReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROJECTS_INIT: {
      return Object.assign({}, state, {
        projects: [],
        isLoading: true,
        shouldFetchProjects: false,
      });
    }
    case GET_PROJECTS_SUCCESS: {
      return Object.assign({}, state, {
        projects: action.data,
        isLoading: false,
      });
    }
    case GET_PROJECTS_FAILURE: {
      return Object.assign({}, state, {
        projects: [],
        isLoading: false,
      });
    }
    case CREATE_PROJECT_INIT: {
      return Object.assign({}, state, {
        isAdding: true,
      });
    }
    case CREATE_PROJECT_SUCCESS: {
      // const { projects } = state;
      // projects.push(action.data);
      return {
        ...state,
        // projects,
        isAdding: false,
      };
    }
    case CREATE_PROJECT_FAILURE: {
      return Object.assign({}, state, {
        isAdding: false,
      });
    }
    case EDIT_PROJECT_DETAILS_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case EDIT_PROJECT_DETAILS_SUCCESS: {
      const proj = state.projects.filter((value) => value.id === action.data.id)[0];
      if (proj) {
        proj.startDate = action.data.startDate
          ? moment.utc(action.data.startDate).format(dateFormat.DDMMMYYYYwithSpace)
          : undefined;
        proj.endDate = action.data.endDate
          ? moment.utc(action.data.endDate).format(dateFormat.DDMMMYYYYwithSpace)
          : undefined;
        proj.name = action.data.name;
        proj.status = action.data.isActive;
        if (!proj.architect) {
          proj.architect = {};
        }
        if (!proj.deliveryManager) {
          proj.deliveryManager = {};
        }
        proj.architect.id = action.data.architectId;
        proj.deliveryManager.id = action.data.deliveryManagerId;
        if (action.data.projectManager && action.data.projectManager.id) {
          proj.manager = proj.manager ? proj.manager : {};
          proj.manager.id = action.data.projectManager.id;
          proj.manager.firstName = action.data.projectManager.firstName || '';
          proj.manager.lastName = action.data.projectManager.lastName || '';
        }
      }
      return {
        ...state,
        projects: [...state.projects],
        isLoading: false,
      };
    }
    case EDIT_PROJECT_DETAILS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ACTIVATE_PROJECT_INIT: {
      return {
        ...state,
        isLoading: true,
        isActivated: false,
      };
    }
    case ACTIVATE_PROJECT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isActivated: true,
      };
    }
    case ACTIVATE_PROJECT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isActivated: false,
      };
    }
    case RESET_PROJECT_ACTIVATION: {
      return {
        ...state,
        isLoading: false,
        isActivated: false,
      };
    }
    default:
      return state;
  }
}
