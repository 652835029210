import { connect } from 'react-redux';
import BenchSummaryReport from 'components/resourcePlanner/Reports/BenchSummaryReport';
import { getBenchSummaryActions } from 'store/actions/ResourcePlannerActions';
import { getAllDesignationActions } from 'store/actions/DesignationActions';
import {
  getEmployeeDepartmentsAction,
  getEmployeeCompetenciesAction,
  getEmployeeDivisionsAction,
  getEmployeeBusinessUnitAction,
} from 'store/actions/EmployeeActions';

function mapStateToProps(state) {
  const {
    auth,
    resourceReducer,
    rcaProjectsReducer,
    employeeReducer,
    resourcePlannerReducer,
    designationsReducer,
  } = state;

  const { divisions, departments, competencies, businessUnits } = employeeReducer;
  const { benchResources } = resourcePlannerReducer;
  return {
    userId: auth.user && auth.user.id,
    readOnly: auth.user && auth.user.isReadOnly,
    resources: resourceReducer.resources,
    designations: designationsReducer.designations,
    projects: rcaProjectsReducer.projects,
    isLoading: resourceReducer.isLoading || rcaProjectsReducer.isLoading,
    divisions,
    departments,
    competencies,
    businessUnits,
    benchResources,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEmployeeDivisionsAction: () => dispatch(getEmployeeDivisionsAction()),
    getEmployeeDepartmentsAction: () => dispatch(getEmployeeDepartmentsAction()),
    getEmployeeCompetenciesAction: () => dispatch(getEmployeeCompetenciesAction()),
    getEmployeeBusinessUnitAction: (req) => dispatch(getEmployeeBusinessUnitAction(req)),
    getBenchSummaryActions: (req) => dispatch(getBenchSummaryActions(req)),
    getAllDesignations: (req) => dispatch(getAllDesignationActions(req)),
  };
}
const BenchContainer = connect(mapStateToProps, mapDispatchToProps)(BenchSummaryReport);

export default BenchContainer;
