import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import {
  getTransientResourceInit,
  getTransientResourceSuccess,
  getTransientResourceFailure,
  createTransientResourceFailure,
  createTransientResourceInit,
  createTransientResourceSuccess,
  deleteTransientResourceFailure,
  deleteTransientResourceInit,
  deleteTransientResourceSuccess,
  updateTransientResourceFailure,
  updateTransientResourceInit,
  updateTransientResourceSuccess,
  getBenchSummaryInit,
  getBenchSummarySuccess,
  getBenchSummaryFailure,
  getResourceDeficitReportInit,
  getResourceDeficitReportSuccess,
  getResourceDeficitReportFailure,
} from 'store/actions/ResourcePlannerActions';
import { showLoader, hideLoader } from 'store/actions/AppLoaderActions';
import { transientResourceUrls, benchResourceUrls, resourceDeficitUrls } from 'configs/constants';
import { generateTransientResourceReportCSV } from '../utils/transientResourceUtils';
// import normalizeResourceAvailability from 'normalizers/resourceAvailabilityNormalizers';

export const getTransientResource = (request) => {
  const url = transientResourceUrls.GET;
  return (dispatch) => {
    dispatch(getTransientResourceInit());
    axios
      .get(url, { params: request })
      .then((response) => {
        if (response.status === 200) {
          //   const resourceNormalized = normalizeResourceAvailability(response.data.data, date);
          dispatch(getTransientResourceSuccess(response.data.data));
        } else {
          dispatch(getTransientResourceFailure());
        }
      })
      .catch((error) => {
        dispatch(getTransientResourceFailure(error));
      });
  };
};

export const getAllTransientResources = (params) => {
  const url = transientResourceUrls.GET;
  return (dispatch) => {
    dispatch(showLoader());
    axios
      .get(url, { params })
      .then((response) => {
        if (response.status === 200) {
          generateTransientResourceReportCSV(response.data.data);
          dispatch(hideLoader());
        } else {
          dispatch(hideLoader());
        }
      })
      .catch(() => {
        dispatch(hideLoader());
      });
  };
};

export const createNewTransientResource = (request) => (dispatch) => {
  const url = transientResourceUrls.POST;
  dispatch(createTransientResourceInit());
  axios
    .post(url, request)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Resource Created Successfully',
        });
        dispatch(createTransientResourceSuccess(response.data.data));
      } else {
        EnhancedNotification.error({
          message: 'Error in adding a new resource',
        });
        dispatch(createTransientResourceFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Unknown Error occured while adding resource!',
      });
      dispatch(createTransientResourceFailure);
    });
};

export const deleteTransientResource = (request) => (dispatch) => {
  const url = transientResourceUrls.DELETE.replace('{resourceId}', request.id);
  dispatch(deleteTransientResourceInit());
  axios
    .delete(url)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Resource Deleted Successfully!',
        });
        dispatch(deleteTransientResourceSuccess(request.id));
      } else {
        EnhancedNotification.error({
          message: 'Cannot delete because the resource is associated with Skills',
        });
        dispatch(deleteTransientResourceFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Cannot delete because the resource is associated with Skills',
      });
      dispatch(deleteTransientResourceFailure());
    });
};

export const updateTransientResource = (request, id) => (dispatch) => {
  const url = transientResourceUrls.PUT.replace('{resourceId}', id);
  dispatch(updateTransientResourceInit());
  axios
    .put(url, request)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Resource Updated Successfully!',
        });
        dispatch(updateTransientResourceSuccess(response.data.data));
      } else {
        EnhancedNotification.error({
          message: 'Error in Updating the Resource!',
        });
        dispatch(updateTransientResourceFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({
        message: 'Entered Resource already exists. Cannot add it again',
      });
      dispatch(updateTransientResourceFailure());
    });
};

export const getBenchSummary = (date) => {
  const url = benchResourceUrls.POST;
  const asOnDate = date;
  return (dispatch) => {
    dispatch(getBenchSummaryInit());
    dispatch(showLoader());
    axios
      .post(url, { asOnDate })
      .then((response) => {
        dispatch(hideLoader());
        if (response.status === 200) {
          //   const resourceNormalized = normalizeResourceAvailability(response.data.data, date);
          dispatch(getBenchSummarySuccess(response.data.data));
        } else {
          dispatch(getBenchSummaryFailure());
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch(getBenchSummaryFailure(error));
      });
  };
};

export const getResourceDeficitReport = (request) => {
  const url = resourceDeficitUrls.POST;
  return (dispatch) => {
    dispatch(getResourceDeficitReportInit());
    dispatch(showLoader());
    axios
      .post(url, request)
      .then((response) => {
        dispatch(hideLoader());
        if (response.status === 200) {
          dispatch(getResourceDeficitReportSuccess(response.data.data));
        } else {
          dispatch(getResourceDeficitReportFailure());
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch(getResourceDeficitReportFailure(error));
      });
  };
};
