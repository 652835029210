export const GET_PROJECTS_INIT = 'GET_PROJECTS_INIT';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE';

export const CREATE_PROJECT_INIT = 'CREATE_PROJECT_INIT';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE';

export const EDIT_PROJECT_DETAILS_INIT = 'EDIT_PROJECT_DETAILS_INIT';
export const EDIT_PROJECT_DETAILS_SUCCESS = 'EDIT_PROJECT_DETAILS_SUCCESS';
export const EDIT_PROJECT_DETAILS_FAILURE = 'EDIT_PROJECT_DETAILS_FAILURE';

export const ACTIVATE_PROJECT_INIT = 'ACTIVATE_PROJECT_INIT';
export const ACTIVATE_PROJECT_SUCCESS = 'ACTIVATE_PROJECT_SUCCESS';
export const ACTIVATE_PROJECT_FAILURE = 'ACTIVATE_PROJECT_FAILURE';
export const RESET_PROJECT_ACTIVATION = 'RESET_PROJECT_ACTIVATION';
