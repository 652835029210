import { EnhancedNotification } from 'components/shared/antd';
import {
  getGoal,
  managerAddGoal,
  removeGoal,
  getGoalDetails,
  updateGoal,
  updateGoalStatus,
  getGoalExport,
} from 'sources/admin/GoalsSource';

import {
  GET_GOAL_INIT,
  GET_GOAL_SUCCESS,
  GET_GOAL_FAILURE,
  MANAGER_ADD_GOAL_INIT,
  MANAGER_ADD_GOAL_SUCCESS,
  MANAGER_ADD_GOAL_FAILURE,
  GET_GOAL_DETAILS_INIT,
  GET_GOAL_DETAILS_SUCCESS,
  GET_GOAL_DETAILS_FAILURE,
  UPDATE_GOAL_INIT,
  UPDATE_GOAL_SUCCESS,
  UPDATE_GOAL_FAILURE,
  UPDATE_GOAL_STATUS_INIT,
  UPDATE_GOAL_STATUS_SUCCESS,
  UPDATE_GOAL_STATUS_FAILURE,
  DELETE_GOAL_INIT,
  DELETE_GOAL_SUCCESS,
  DELETE_GOAL_FAILURE,
} from 'constants/admin/GoalTypes';

import { showLoader, hideLoader } from 'store/actions/AppLoaderActions';

import { generateAllGoalsReport } from 'utils/goalUtils';

export const getGoalInit = () => ({
  type: GET_GOAL_INIT,
});

export const getGoalSuccess = (data) => ({
  type: GET_GOAL_SUCCESS,
  data,
});

export const getGoalFailure = (data) => ({
  type: GET_GOAL_FAILURE,
  data,
});

export const getGoalDetailsInit = () => ({
  type: GET_GOAL_DETAILS_INIT,
});

export const getGoalDetailsSuccess = (data) => ({
  type: GET_GOAL_DETAILS_SUCCESS,
  data,
});

export const updateGoalInit = () => ({
  type: UPDATE_GOAL_INIT,
});

export const updateGoalSuccess = (data) => ({
  type: UPDATE_GOAL_SUCCESS,
  data,
});

export const updateGoallFailure = () => ({
  type: UPDATE_GOAL_FAILURE,
});

export const getGoalDetailsFailure = (data) => ({
  type: GET_GOAL_DETAILS_FAILURE,
  data,
});

export const updateGoalStatusInit = () => ({
  type: UPDATE_GOAL_STATUS_INIT,
});

export const updateGoalStatusSuccess = (data) => ({
  type: UPDATE_GOAL_STATUS_SUCCESS,
  data,
});

export const updateGoalStatusFailure = () => ({
  type: UPDATE_GOAL_STATUS_FAILURE,
});

export const deleteGoalInit = () => ({
  type: DELETE_GOAL_INIT,
});

export const deleteGoalSuccess = (data) => ({
  type: DELETE_GOAL_SUCCESS,
  data,
});

export const deleteGoalFailure = () => ({
  type: DELETE_GOAL_FAILURE,
});

export const managerAddGoalInit = () => ({
  type: MANAGER_ADD_GOAL_INIT,
});

export const managerAddGoalSuccess = (data) => ({
  type: MANAGER_ADD_GOAL_SUCCESS,
  data,
});

export const managerAddGoallFailure = () => ({
  type: MANAGER_ADD_GOAL_FAILURE,
});

export const getGoalActions = (requestObject) => (dispatch) => {
  dispatch(getGoal(requestObject));
};

export const getGoalDetailsActions = (requestObject) => (dispatch) => {
  dispatch(getGoalDetails(requestObject));
};

export const updateGoalActions = (requestObject) => (dispatch) => {
  dispatch(updateGoal(requestObject));
};

export const updateGoalStatusActions = (requestObject) => (dispatch) => {
  dispatch(updateGoalStatus(requestObject));
};

export const removeGoalActions = (requestObject) => (dispatch) => {
  dispatch(removeGoal(requestObject));
};

export const managerAddGoalActions = (requestObject) => (dispatch) => {
  dispatch(managerAddGoal(requestObject));
};

export const exportAllGoalsAction = (params) => (dispatch) => {
  dispatch(showLoader());
  return getGoalExport(params)
    .then((response) => {
      generateAllGoalsReport(response);
      dispatch(hideLoader());
    })
    .catch(() => {
      dispatch(hideLoader());
      EnhancedNotification.error({ message: 'Unable to download all goals list.' });
    });
};
