/* INTERVIEWS */

export const GET_INTERVIEWS_INIT = 'GET_INTERVIEWS_INIT';
export const GET_INTERVIEWS_SUCCESS = 'GET_INTERVIEWS_SUCCESS';
export const GET_INTERVIEWS_FAIL = 'GET_INTERVIEWS_FAIL';

export const CREATE_INTERVIEW_INIT = 'CREATE_INTERVIEWS_INIT';
export const CREATE_INTERVIEW_SUCCESS = 'CREATE_INTERVIEWS_SUCCESS';
export const CREATE_INTERVIEW_FAIL = 'CREATE_INTERVIEWS_FAIL';

export const DELETE_INTERVIEW_INIT = 'DELETE_INTERVIEWS_INIT';
export const DELETE_INTERVIEW_SUCCESS = 'DELETE_INTERVIEWS_SUCCESS';
export const DELETE_INTERVIEW_FAIL = 'DELETE_INTERVIEWS_FAIL';

export const UPDATE_INTERVIEW_INIT = 'UPDATE_INTERVIEWS_INIT';
export const UPDATE_INTERVIEW_SUCCESS = 'UPDATE_INTERVIEWS_SUCCESS';
export const UPDATE_INTERVIEW_FAIL = 'UPDATE_INTERVIEWS_FAIL';

/*  TRACKS */

export const GET_REPORTEES_TRACKS_INIT = 'GET_REPORTEES_TRACKS_INIT';
export const GET_REPORTEES_TRACKS_SUCCESS = 'GET_REPORTEES_TRACKS_SUCCESS';
export const GET_REPORTEES_TRACKS_FAIL = 'GET_REPORTEES_TRACKS_FAIL';

/* QUESTIONS */

export const GET_QUESTIONS_INIT = 'GET_QUSTIONS_INIT';
export const GET_QUESTIONS_SUCCESS = 'GET_QUSTIONS_SUCCESS';
export const GET_QUESTIONS_FAIL = 'GET_QUSTIONS_FAIL';

export const CREATE_QUESTION_INIT = 'CREATE_QUESTION_INIT';
export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS';
export const CREATE_QUESTION_FAIL = 'CREATE_QUESTION_FAIL';

export const DELETE_QUESTION_INIT = 'DELETE_QUESTION_INIT';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
export const DELETE_QUESTION_FAIL = 'DELETE_QUESTION_FAIL';

export const UPDATE_QUESTION_INIT = 'UPDATE_QUESTION_INIT';
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS';
export const UPDATE_QUESTION_FAIL = 'UPDATE_QUESTION_FAIL';

/* QUESTIONS FREQUENCY */

export const GET_QUESTIONS_FREQUENCY_PER_TRACK_INIT = 'GET_QUESTIONS_FREQUENCY_PER_TRACK_INIT';
export const GET_QUESTIONS_FREQUENCY_PER_TRACK_SUCCESS =
  'GET_QUESTIONS_FREQUENCY_PER_TRACK_SUCCESS';
export const GET_QUESTIONS_FREQUENCY_PER_TRACK_FAIL = 'GET_QUESTIONS_FREQUENCY_PER_TRACK_FAIL';

export const GET_QUESTIONS_FREQUENCY_PER_CLIENT_AND_TRACK_INIT =
  'GET_QUESTIONS_FREQUENCY_PER_CLIENT_AND_TRACK_INIT';
export const GET_QUESTIONS_FREQUENCY_PER_CLIENT_AND_TRACK_SUCCESS =
  'GET_QUESTIONS_FREQUENCY_PER_CLIENT_AND_TRACK_SUCCESS';
export const GET_QUESTIONS_FREQUENCY_PER_CLIENT_AND_TRACK_FAIL =
  'GET_QUESTIONS_FREQUENCY_PER_CLIENT_AND_TRACK_FAIL';
