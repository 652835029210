import { connect } from 'react-redux';
import AppLoaderComponent from 'components/AppLoaderComponent';

function mapStateToProps(state) {
  const { appLoaderReducer } = state;
  return {
    visible: appLoaderReducer.visible,
  };
}
const AppLoaderContainer = connect(mapStateToProps)(AppLoaderComponent);

export default AppLoaderContainer;
