/* eslint-disable */
import { EnhancedButton, EnhancedCol, EnhancedRow, EnhancedCard } from 'components/shared/antd';
import React, { Component } from 'react';
// import DashboardPieChart from 'components/dashboard/DashboardPieChart';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import { capitalizeString, parse } from 'utils/stringUtils';
import { Link } from 'react-router-dom';
import PROJECT_ROUTES from 'constants/UrlConstants';
import { CRMC_EMPLOYEES_CURRENT, CRMC_EMPLOYEES_BILLABLE } from 'configs/routesConstants';

import './Dashboard.css';
import { hasPermission } from '../../utils/AccessUtils';
import AccessPermissions from '../../constants/AccessPermissions';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndexResourceCount: 0,
      activeIndexDivisionCompetency: 0,
      activeIndexDivisionResourceType: 0,
      searchProjectText: '',
      searchBillableSkillsText: '',
      searchBenchSkillsText: '',
      searchBillableCompetencyText: '',
      searchBenchCompetencyText: '',
    };
  }

  componentWillMount() {
    const { getDashboardActions } = this.props;
    getDashboardActions();
  }

  onPieResourceCount = (data, index) => {
    this.setState({
      activeIndexResourceCount: index,
    });
  };
  onPieDivisionCompetency = (data, index) => {
    this.setState({
      activeIndexDivisionCompetency: index,
    });
  };
  onPieDivisionResourceType = (data, index) => {
    this.setState({
      activeIndexDivisionResourceType: index,
    });
  };

  onSearchProjectName = (e) => {
    const searchProjectText = e.target.value;
    this.setState({
      searchProjectText,
    });
  };
  onSearchBillableSkillsText = (e) => {
    const searchBillableSkillsText = e.target.value;
    this.setState({
      searchBillableSkillsText,
    });
  };
  onSearchBenchSkillsText = (e) => {
    const searchBenchSkillsText = e.target.value;
    this.setState({
      searchBenchSkillsText,
    });
  };
  onSearchBillableCompetencyText = (e) => {
    const searchBillableCompetencyText = e.target.value;
    this.setState({
      searchBillableCompetencyText,
    });
  };
  onSearchBenchCompetencyText = (e) => {
    const searchBenchCompetencyText = e.target.value;
    this.setState({
      searchBenchCompetencyText,
    });
  };

  getProject = (name, data) => {
    const { user } = this.props;
    const { id, projectManagerId, architectId, deliveryManagerId } = data;
    let isAuthorized = hasPermission(user.permissions, [AccessPermissions.Project.List]);
    if ([projectManagerId, architectId, deliveryManagerId].includes(user.id)) isAuthorized = true;
    const titleMarkup = <b style={{ color: '#5A5A5A' }}>{name}</b>;
    if (!isAuthorized) return titleMarkup;
    const link = parse(PROJECT_ROUTES.PROJECTS_DETAIL_ROUTE, {
      status: PROJECT_ROUTES.CURRENT,
      id,
    });
    return <Link to={link}>{titleMarkup}</Link>;
  };

  getColumnsInfo = () => {
    const columns = [
      {
        title: 'Project Name',
        dataIndex: 'name',
        key: 'name',
        filterConfig: {
          type: 'search',
          key: 'name',
        },
        render: this.getProject,
      },
      {
        title: 'Billed',
        dataIndex: 'billed',
        key: 'billed',
      },
      {
        title: 'Internal',
        dataIndex: 'internal',
        key: 'internal',
      },
      {
        title: 'Backup',
        dataIndex: 'backup',
        key: 'backup',
      },
      {
        title: 'Additional',
        dataIndex: 'additional',
        key: 'additional',
      },
    ];
    return columns;
  };

  toggle = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };
  sumValues = (object = {}) => {
    let sum = 0;
    Object.keys(object).map((obj) => {
      sum += Number(object[obj].value);
      return sum;
    });
    return sum;
  };

  renderResourceCompetency = (data) => {
    const values = {
      fe_android: 'FE-Android',
      fe_ios: 'FE-iOS',
      web: 'Web',
      'ui/ux': 'UI/UX',
      architect: 'Architect',
    };
    return data.map((value) => {
      const newVal = {
        ...value,
        name: values[value.name],
      };
      return newVal;
    });
  };

  renderResourceType = (data) => {
    const values = {
      additional: 'Additional',
      billed: 'Billed',
      bench: 'Unallocated',
      backup: 'Backup',
      internal: 'Internal',
      overAllocated: 'Over Allocated',
    };
    return data.map((value) => {
      const newVal = {
        ...value,
        name: values[value.name],
      };
      return newVal;
    });
  };

  renderBillableCompetencyColumn = (name) => {
    const { user } = this.props;
    const billableMarkup = <b style={{ color: '#5A5A5A' }}>{name}</b>;
    const isAuthorized = hasPermission(user.permissions, [AccessPermissions.Employee.List]);
    if (!isAuthorized) return billableMarkup;
    return <Link to={`${CRMC_EMPLOYEES_CURRENT}?department=${name}`}>{billableMarkup}</Link>;
  };

  renderBenchCompetencyColumn = (name) => {
    const { user } = this.props;
    const benchMarkup = <b style={{ color: '#5A5A5A' }}>{name}</b>;
    const isAuthorized = hasPermission(user.permissions, [
      AccessPermissions.Employee.Billable.List,
    ]);
    if (!isAuthorized) return benchMarkup;
    return <Link to={`${CRMC_EMPLOYEES_BILLABLE}?competency=${name}`}>{benchMarkup}</Link>;
  };

  render() {
    const {
      // activeIndexResourceCount,
      // activeIndexDivisionCompetency,
      // activeIndexDivisionResourceType,
      searchProjectText,
      searchBillableCompetencyText,
      searchBillableSkillsText,
      searchBenchCompetencyText,
      searchBenchSkillsText,
    } = this.state;
    const { dashboard, isDashboardLoading, isLoading } = this.props;

    let resourceCount = [];
    let projectDetails = [];
    let competencyDetails = [];
    let divisionResourceType = [];
    let billableBySkills = [];
    let billableByCompetency = [];
    let benchByCompetency = [];
    let benchBySkills = [];
    let filteredProjects = [];

    if (dashboard.length) {
      [
        resourceCount,
        divisionResourceType,
        projectDetails,
        competencyDetails,
        billableByCompetency,
        billableBySkills,
        benchByCompetency,
        benchBySkills,
      ] = dashboard;
    }

    const benchQuantity = benchByCompetency
      ? benchByCompetency.map((a) => parseFloat(a.value, 10))
      : [];
    const benchValue = benchQuantity ? benchQuantity.reduce((a, b) => a + b, 0) : 0;
    const totalResourceCount = this.sumValues(resourceCount);
    const totalResourceTypeCount = this.sumValues(divisionResourceType);
    filteredProjects = searchProjectText
      ? projectDetails.filter((project) =>
          project.name.toLowerCase().includes(searchProjectText.toLowerCase()),
        )
      : projectDetails;
    const newResourceCount = resourceCount.map((value) => {
      const newVal = {
        ...value,
        name: value.name === 'non_billable' ? 'Non-Billable' : capitalizeString(value.name),
      };
      return newVal;
    });
    const billableHeadCount = newResourceCount.map((entry) => entry.value);
    const column = this.getColumnsInfo();
    const newDivisionCompetency = competencyDetails;
    const newDivisionResourceType = this.renderResourceType(divisionResourceType);
    let section = (
      <EnhancedRow className="section">
        {/* <DashboardPieChart
          indexTotal={billableByCompetency.size}
          data={billableByCompetency}
          onPieChange={this.onPieResourceCount}
          label="Employees"
          dtype="float"
        /> */}
      </EnhancedRow>
    );
    if (!this.state.disabled) {
      section = (
        <EnhancedRow className="section">
          <h4 style={{ position: 'absolute', left: '5%', color: '#8C8C8C' }}>
            Division by Competency
          </h4>
          <EnhancedClientSideTable
            loading={isDashboardLoading}
            className="table-dashboard"
            showResetFiltersButton={false}
            columns={[
              {
                title: 'Competency',
                dataIndex: 'name',
                key: 'name',
                filterConfig: {
                  type: 'search',
                  key: 'name',
                },
                width: 150,
                render: this.renderBillableCompetencyColumn,
              },
              {
                title: 'Resource Count',
                dataIndex: 'value',
                sorter: (a, b) => a.value - b.value,
                width: 150,
              },
            ]}
            data={
              searchBillableCompetencyText
                ? billableByCompetency.filter((bc) =>
                    bc.name.toLowerCase().includes(searchBillableCompetencyText.toLowerCase()),
                  )
                : billableByCompetency
            }
            scroll={{ y: 240 }}
          />
          <span className="headerDivider" />
          <h4 style={{ position: 'absolute', left: '55%', color: '#8C8C8C' }}>
            {' '}
            Division by Skill
          </h4>
          <EnhancedClientSideTable
            loading={isDashboardLoading}
            className="table-dashboard"
            showResetFiltersButton={false}
            columns={[
              {
                title: 'Skills',
                dataIndex: 'name',
                filterConfig: {
                  type: 'search',
                  key: 'name',
                },
                width: 150,
              },
              {
                title: 'Resource Count',
                dataIndex: 'value',
                width: 150,
                sorter: (a, b) => a.value - b.value,
              },
            ]}
            data={
              searchBillableSkillsText
                ? billableBySkills.filter((bs) =>
                    bs.name.toLowerCase().includes(searchBillableSkillsText.toLowerCase()),
                  )
                : billableBySkills
            }
            scroll={{ y: 240 }}
          />
        </EnhancedRow>
      );
    }

    return (
      <div>
        <EnhancedCard loading={isDashboardLoading}>
          <span>
            <h2>DASHBOARD</h2>
          </span>
          <div style={{ color: '#8C8C8C' }}>
            The total number of bench resources is
            <span style={{ fontWeight: 700 }}> {Math.round(benchValue * 100) / 100}</span>. Below is
            the breakup of all the resources as per their type.
          </div>
        </EnhancedCard>
        <EnhancedRow gutter={12}>
          <EnhancedCol span={8}>
            <EnhancedCard
              title={<span style={{ color: '#8C8C8C', fontWeight: 'bold' }}>Resource Count</span>}
              bodyStyle={{ padding: '2px', paddingBottom: 12 }}
              loading={isDashboardLoading && isLoading}
            >
              <div className="chart-style">
                {/* <DashboardPieChart
                  indexTotal={activeIndexResourceCount}
                  data={newResourceCount}
                  onPieChange={this.onPieResourceCount}
                  label="Employees"
                  dtype="int"
                /> */}
                <div style={{ textAlign: 'center', width: '100%', height: '25px' }}>
                  {newResourceCount.map((entry) => (
                    <span>
                      <span
                        className="piechart-legend-icon"
                        style={{
                          background: entry.color,
                        }}
                      />
                      <span className="piechart-legend">
                        {entry.name} : {entry.value}(
                        {Math.round((entry.value / totalResourceCount) * 100)}%)
                      </span>
                    </span>
                  ))}
                </div>
              </div>
            </EnhancedCard>
          </EnhancedCol>
          <EnhancedCol span={16}>
            <EnhancedCard
              title={
                <div style={{ color: '#8C8C8C', fontWeight: 'bold', textAlign: 'center' }}>
                  Division of Resources
                </div>
              }
              bodyStyle={{
                padding: '4px',
                paddingBottom: 28,
                height: '17em',
                'overflow-y': 'auto',
                'white-space': 'nowrap',
              }}
              loading={isDashboardLoading}
            >
              <EnhancedCol span={12} style={{ paddingTop: '1%' }}>
                <span className="pie-chart-heading" style={{ fontWeight: 'bold' }}>
                  Billable By Allocation Type
                </span>
                <div style={{ display: 'flex', height: '25px' }}>
                  <div
                    style={{
                      flex: 0.5,
                      paddingTop: '8%',
                      paddingLeft: '4%',
                    }}
                  >
                    {newDivisionResourceType.map((entry) => (
                      <span>
                        <EnhancedRow>
                          <span
                            className="piechart-legend-icon"
                            style={{
                              background: entry.color,
                              paddingLeft: '4%',
                            }}
                          />
                          <span className="piechart-legend">
                            {entry.name} : {entry.value}(
                            {Math.ceil((entry.value / totalResourceTypeCount) * 100)}%)
                          </span>
                        </EnhancedRow>
                      </span>
                    ))}
                  </div>
                  <div style={{ flex: 0.5 }}>
                    {/* <DashboardPieChart
                      indexTotal={activeIndexDivisionResourceType}
                      data={newDivisionResourceType}
                      onPieChange={this.onPieDivisionResourceType}
                      size={175}
                      innerRadius={60}
                      outerRadius={80}
                      label="F.T.E"
                    /> */}
                  </div>
                </div>
              </EnhancedCol>
              <EnhancedCol span={12} style={{ paddingTop: '1%' }}>
                <span className="pie-chart-heading" style={{ fontWeight: 'bold' }}>
                  Bench by Competency
                </span>
                <div style={{ display: 'flex' }}>
                  <div className="competency">
                    {newDivisionCompetency.map((entry) => (
                      <span>
                        <EnhancedRow>
                          <span
                            className="piechart-legend-icon"
                            style={{
                              background: entry.color,
                            }}
                          />
                          <span className="piechart-legend">
                            {entry.name} : {entry.value}
                          </span>
                        </EnhancedRow>
                      </span>
                    ))}
                  </div>
                  <div style={{ flex: 0.5 }}>
                    {/* <DashboardPieChart
                      indexTotal={activeIndexDivisionCompetency}
                      data={newDivisionCompetency}
                      onPieChange={this.onPieDivisionCompetency}
                      size={175}
                      innerRadius={60}
                      outerRadius={80}
                      label="F.T.E"
                    /> */}
                  </div>
                </div>
              </EnhancedCol>
            </EnhancedCard>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow
          className="search-row"
          style={{
            marginBottom: '2px',
          }}
        >
          <div className="billable-resources">
            <span className="heading-billable">BILLABLE RESOURCES |</span>{' '}
            <span className="heading-bench-sub">
              {' '}
              TOTAL RESOURCES <span className="counter-text">{billableHeadCount[0]}</span>
            </span>
          </div>
          <EnhancedButton onClick={this.toggle} className="toggle">
            {this.state.disabled ? 'Graphical' : 'Tabular'}
          </EnhancedButton>
        </EnhancedRow>
        {section}
        <EnhancedRow
          className="search-row"
          style={{
            marginBottom: '2px',
          }}
        >
          <div className="billable-resources">
            <span className="heading-bench">BENCH |</span>
            <span className="heading-bench-sub">
              {' '}
              TOTAL RESOURCES <span className="counter-text">{benchValue}</span>
            </span>
          </div>
        </EnhancedRow>
        <EnhancedRow className="section">
          <h4 style={{ position: 'absolute', left: '5%', color: '#8C8C8C' }}>
            Division by Competency
          </h4>
          <EnhancedClientSideTable
            loading={isDashboardLoading}
            className="table-dashboard"
            columns={[
              {
                title: 'Competency',
                dataIndex: 'name',
                width: 150,
                render: this.renderBenchCompetencyColumn,
                filterConfig: {
                  type: 'search',
                  key: 'name',
                },
              },
              {
                title: 'Resource Count',
                dataIndex: 'value',
                width: 150,
                sorter: (a, b) => a.value - b.value,
              },
            ]}
            data={
              searchBenchCompetencyText
                ? benchByCompetency.filter((bc) =>
                    bc.name.toLowerCase().includes(searchBenchCompetencyText.toLowerCase()),
                  )
                : benchByCompetency
            }
            scroll={{ y: 240 }}
            showResetFiltersButton={false}
          />
          <span className="headerDivider" />
          <h4 style={{ position: 'absolute', left: '55%', color: '#8C8C8C' }}>Division by Skill</h4>
          <EnhancedClientSideTable
            loading={isDashboardLoading}
            className="table-dashboard"
            columns={[
              {
                title: 'Skills',
                dataIndex: 'name',
                width: 150,
                filterConfig: {
                  type: 'search',
                  key: 'name',
                },
              },
              {
                title: 'Resource Count',
                dataIndex: 'value',
                width: 150,
                sorter: (a, b) => a.value - b.value,
              },
            ]}
            data={
              searchBenchSkillsText
                ? benchBySkills.filter((bs) =>
                    bs.name.toLowerCase().includes(searchBenchSkillsText.toLowerCase()),
                  )
                : benchBySkills
            }
            scroll={{ y: 240 }}
            showResetFiltersButton={false}
          />
        </EnhancedRow>
        <EnhancedRow className="search-row">
          <div style={{ fontSize: 20, color: '#8A8A8A', marginLeft: '10%' }}>
            Division of Billable Resources - by project
          </div>
        </EnhancedRow>
        <EnhancedClientSideTable
          loading={isDashboardLoading}
          data={filteredProjects}
          columns={column}
          onChange={this.handleChange}
          style={{ textTransform: 'capitalize' }}
        />
      </div>
    );
  }
}

export default Dashboard;
