export const GET_TEAM_LEAVES_INIT = 'GET_TEAM_LEAVES_INIT';
export const GET_TEAM_LEAVES_SUCCESS = 'GET_TEAM_LEAVES_SUCCESS';
export const GET_TEAM_LEAVES_FAILURE = 'GET_TEAM_LEAVES_FAILURE';

export const GET_PRIMARY_TEAM_LEAVES_INIT = 'GET_PRIMARY_TEAM_LEAVES_INIT';
export const GET_PRIMARY_TEAM_LEAVES_SUCCESS = 'GET_PRIMARY_TEAM_LEAVES_SUCCESS';
export const GET_PRIMARY_TEAM_LEAVES_FAILURE = 'GET_PRIMARY_TEAM_LEAVES_FAILURE';

export const GET_SECONDARY_TEAM_LEAVES_INIT = 'GET_SECONDARY_TEAM_LEAVES_INIT';
export const GET_SECONDARY_TEAM_LEAVES_SUCCESS = 'GET_SECONDARY_TEAM_LEAVES_SUCCESS';
export const GET_SECONDARY_TEAM_LEAVES_FAILURE = 'GET_SECONDARY_TEAM_LEAVES_FAILURE';

export const GET_BENCH_LEAVES_INIT = 'GET_BENCH_LEAVES_INIT';
export const GET_BENCH_LEAVES_SUCCESS = 'GET_BENCH_LEAVES_SUCCESS';
export const GET_BENCH_LEAVES_FAILURE = 'GET_BENCH_LEAVES_FAILURE';

export const UPDATE_TEAM_LEAVE_INIT = 'UPDATE_TEAM_LEAVE_INIT';
export const UPDATE_TEAM_LEAVE_SUCCESS = 'UPDATE_TEAM_LEAVE_SUCCESS';
export const UPDATE_TEAM_LEAVE_FAILURE = 'UPDATE_TEAM_LEAVE_FAILURE';

export const UPDATE_TEAM_LEAVE_COMPLETE = 'UPDATE_TEAM_LEAVE_COMPLETE';

export const GET_LEAVE_CALENDAR_INIT = 'GET_LEAVE_CALENDAR_INIT';
export const GET_LEAVE_CALENDAR_SUCCESS = 'GET_LEAVE_CALENDAR_SUCCESS';
export const GET_LEAVE_CALENDAR_FAILURE = 'GET_LEAVE_CALENDAR_FAILURE';
