import { EnhancedIcon, EnhancedTypography, EnhancedTooltip } from 'components/shared/antd';
import React from 'react';

import { ACTION_TOOLTIP } from './constants';

function generateColumns(state, fn) {
  const [{ projectList, trackList }, { handleEdit, handleDelete }] = [state, fn];

  return [
    {
      title: 'Question',
      key: 'text',
      dataIndex: 'text',
      width: '35%',
      sorter: (a, b) => a.text.localeCompare(b.text),
      filterConfig: {
        type: 'search',
        key: 'text',
      },
      render: (text) => (
        <EnhancedTypography copyable style={{ whiteSpace: 'pre-wrap' }}>
          {text}
        </EnhancedTypography>
      ),
    },
    {
      title: 'Answer',
      key: 'answer',
      dataIndex: 'answer',
      width: '35%',
      sorter: (a, b) => a.answer.localeCompare(b.answer),
      filterConfig: {
        type: 'search',
        key: 'answer',
      },
      render: (text) => (
        <EnhancedTypography copyable style={{ whiteSpace: 'pre-wrap' }}>
          {text}
        </EnhancedTypography>
      ),
    },
    {
      title: 'Client',
      key: 'projectId',
      dataIndex: 'projectId',
      width: 'auto',
      ellipsis: true,
      render: (_text, record) => (record.project ? record.project.name : ''),
      sorter: (a, b) => a.project.name.localeCompare(b.project.name),
      filterConfig: {
        type: 'enhanced_filter',
        key: 'project.name',
        optionsConfig: {
          values: projectList,
        },
      },
    },
    {
      title: 'Track',
      key: 'trackId',
      dataIndex: 'trackId',
      width: 'auto',
      render: (_text, record) => (record.track ? record.track.name : ''),
      sorter: (a, b) => a.track.name.localeCompare(b.track.name),
      filterConfig: {
        type: 'enhanced_filter',
        key: 'track.name',
        optionsConfig: {
          values: trackList,
        },
      },
    },
    {
      title: 'Actions',
      width: 100,
      fixed: 'right',
      render: (_text, record) => {
        if (record.interviewId) {
          return (
            <EnhancedTooltip placement="bottom" title={ACTION_TOOLTIP}>
              <EnhancedIcon type="question-circle" style={{ color: 'orange' }} />
            </EnhancedTooltip>
          );
        }
        return (
          <div style={{ display: 'flex', gap: 8 }}>
            <EnhancedIcon
              data-id={record.id}
              role="button"
              style={{ cursor: 'pointer' }}
              onClick={handleEdit}
              type="edit"
            />
            <EnhancedIcon
              data-id={record.id}
              role="button"
              style={{ cursor: 'pointer' }}
              onClick={handleDelete}
              type="delete"
            />
          </div>
        );
      },
    },
  ];
}

export default generateColumns;
