import { connect } from 'react-redux';
import {
  addGoalCategoryActions,
  updateGoalCategoryActions,
  getGoalCategoryActions,
  removeGoalCategoryActions,
  getGoalCategoryDetailsActions,
} from 'store/actions/admin/PredefinedGoalCategoriesActions';
import GoalsListing from '../../components/admin/goalCategories/Listing';

function mapStateToProps(state) {
  const { auth, predefinedGoalCategoryReducer } = state;

  const { isLoading } = predefinedGoalCategoryReducer;
  return {
    isLoading,
    userRole: auth.user,
    list: predefinedGoalCategoryReducer.goalCategory,
    details: predefinedGoalCategoryReducer.goalCategoryDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addGoalCategory: (req) => dispatch(addGoalCategoryActions(req)),
    updateGoalCategory: (req) => dispatch(updateGoalCategoryActions(req)),
    getGoalCategory: (req) => dispatch(getGoalCategoryActions(req)),
    getGoalCategoryDetails: (req) => dispatch(getGoalCategoryDetailsActions(req)),
    removeGoalCategory: (req) => dispatch(removeGoalCategoryActions(req)),
  };
}

const GoalsContainer = connect(mapStateToProps, mapDispatchToProps)(GoalsListing);

export default GoalsContainer;
