import { connect } from 'react-redux';
import AppSmithWindow from 'components/resourcePlanner/AppSmithWindow';

const mapStateToProps = (state) => ({
  ...state,
});

//   const mapDispatchToProps = dispatch => ({
//     addEmployee: (req, id) =>
//       dispatch(addEmployeeAction(req, id)),
//     resetAddEmployee: () =>
//       dispatch(resetAddEmployeeAction()),
//     getEmployeesSkillsActions: () =>
//       dispatch(getEmployeesSkillsActions()),
//     getEmployeeDepartments: () => dispatch(getEmployeeDepartmentsAction()),
//     getBusinessUnits: () => dispatch(getEmployeeBusinessUnitAction()),
//     getDivisions: () => dispatch(getEmployeeDivisionsAction()),
//     getCompetencies: () => dispatch(getEmployeeCompetenciesAction()),
//   });

export default connect(mapStateToProps)(AppSmithWindow);
