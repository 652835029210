import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

const EnhancedSpin = (props) => {
  const { type, children, className, style, size, tip, spinning } = props;

  return (
    <Spin style={style} type={type} className={className} size={size} tip={tip} spinning={spinning}>
      {children}
    </Spin>
  );
};

EnhancedSpin.propTypes = {
  size: PropTypes.string,
  tip: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

EnhancedSpin.defaultProps = {
  className: '',
  style: {},
  size: 'default',
  tip: '',
};

export default EnhancedSpin;
