import {
  ADD_PMCYCLE_INIT,
  ADD_PMCYCLE_SUCCESS,
  ADD_PMCYCLE_FAILURE,
  EDIT_PMCYCLE_INIT,
  EDIT_PMCYCLE_SUCCESS,
  EDIT_PMCYCLE_FAILURE,
  DELETE_PMCYCLE_INIT,
  DELETE_PMCYCLE_SUCCESS,
  DELETE_PMCYCLE_FAILURE,
  GET_PMCYCLE_INIT,
  GET_PMCYCLE_SUCCESS,
  GET_PMCYCLE_FAILURE,
  GET_PM_EMP_CYCLE_INIT,
  GET_PM_EMP_CYCLE_SUCCESS,
  GET_PM_EMP_CYCLE_FAILURE,
  GET_PMCYCLE_DETAIL_INIT,
  GET_PMCYCLE_DETAIL_SUCCESS,
  GET_PMCYCLE_DETAIL_FAILURE,
  GET_PREVIOUS_PMCYCLE_INIT,
  GET_PREVIOUS_PMCYCLE_SUCCESS,
  GET_PREVIOUS_PMCYCLE_FAILURE,
  ADD_EMPLOYEES_PM_CYCLE_INIT,
  ADD_EMPLOYEES_PM_CYCLE_SUCCESS,
  ADD_EMPLOYEES_PM_CYCLE_FAILURE,
  DELETE_EMPLOYEE_PM_CYCLE_INIT,
  DELETE_EMPLOYEE_PM_CYCLE_SUCCESS,
  DELETE_EMPLOYEE_PM_CYCLE_FAILURE,
  GET_RATING_OPTIONS_INIT,
  GET_RATING_OPTIONS_SUCCESS,
  GET_RATING_OPTIONS_FAILURE,
} from 'constants/PerformanceManagementTypes';

const initialState = {
  isLoading: false,
  pmCycles: [],
  pmCycleDetail: {},
  pmEmpCycle: [],
  previousCycle: [],
  ratingOptions: [],
};

export default function performanceManagementReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_PMCYCLE_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADD_PMCYCLE_SUCCESS: {
      const result = {
        ...state,
        isLoading: false,
        pmCycles: action.data,
      };
      return result;
    }
    case ADD_PMCYCLE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    }
    case EDIT_PMCYCLE_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case EDIT_PMCYCLE_SUCCESS: {
      const result = {
        ...state,
        isLoading: false,
        pmCycles: action.data,
      };
      return result;
    }
    case EDIT_PMCYCLE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    }
    case DELETE_PMCYCLE_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DELETE_PMCYCLE_SUCCESS: {
      const result = {
        ...state,
        isLoading: false,
        pmCycles: action.data,
      };
      return result;
    }
    case DELETE_PMCYCLE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    }
    case GET_PMCYCLE_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_PMCYCLE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        pmCycles: action.data,
      };
    }
    case GET_PMCYCLE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    }
    case GET_PREVIOUS_PMCYCLE_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_PREVIOUS_PMCYCLE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        previousCycle: action.data,
      };
    }
    case GET_PREVIOUS_PMCYCLE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    }

    case ADD_EMPLOYEES_PM_CYCLE_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADD_EMPLOYEES_PM_CYCLE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ADD_EMPLOYEES_PM_CYCLE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case DELETE_EMPLOYEE_PM_CYCLE_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DELETE_EMPLOYEE_PM_CYCLE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case DELETE_EMPLOYEE_PM_CYCLE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case GET_PM_EMP_CYCLE_INIT: {
      return {
        ...state,
        isLoading: true,
        pmEmpCycle: [],
      };
    }
    case GET_PM_EMP_CYCLE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        pmEmpCycle: action.data,
      };
    }
    case GET_PM_EMP_CYCLE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    }
    case GET_PMCYCLE_DETAIL_INIT: {
      return {
        ...state,
        // isLoading: true,
        pmCycleDetail: {},
      };
    }
    case GET_PMCYCLE_DETAIL_SUCCESS: {
      return {
        ...state,
        // isLoading: false,
        pmCycleDetail: action.data,
      };
    }
    case GET_PMCYCLE_DETAIL_FAILURE: {
      return {
        ...state,
        // isLoading: false,
        ...action.data,
      };
    }
    case GET_RATING_OPTIONS_INIT: {
      return {
        ...state,
        ratingOptions: [],
      };
    }
    case GET_RATING_OPTIONS_SUCCESS: {
      return {
        ...state,
        ratingOptions: action.data,
      };
    }
    case GET_RATING_OPTIONS_FAILURE: {
      return {
        ...state,
        ratingOptions: [],
      };
    }
    default:
      return state;
  }
}
