import {
  EnhancedButton,
  EnhancedCol,
  EnhancedRow,
  EnhancedCard,
  EnhancedModal,
} from 'components/shared/antd';
import React from 'react';
import {
  ROW_GUTTER_SIZE,
  FULL_GRID_SIZE,
  JOB_INFO as stateName,
  GRADES as gradeOptions,
  SUB_GRADES as subGradeOptions,
  IS_EDIT,
  DATE,
  SELECT,
  TEXT,
  PASSWORD,
} from 'configs/employeeProfileConstants';
import { createFields, noData } from 'utils/FieldGenerateUtils';
import { REQUIRED, NUMBERS_ONLY } from 'configs/validationConstants';
import { getValueForKeyinJSON } from 'utils/arrayUtils';
import { generateErrorsArrayFromJSON } from 'utils/validationUtils';

import {
  DELETE_CONFIRM_PROMPT_MESSAGE,
  DELETE_CONFIRM_PROMPT_WARNING_MESSAGE,
} from 'configs/messageConstants';

class OrganizationEmploymentHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      [stateName]: this.props[stateName] || [],
      errors: [],
      disableSaveButton: true,
      disableCreateButton: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps[stateName] !== this.props[stateName]) {
      const newProps = nextProps[stateName] || [];

      // Expecting Array of Objects
      // Make clean error object with exclusions
      const errors = generateErrorsArrayFromJSON(newProps, [IS_EDIT]);

      this.setState({
        [stateName]: newProps,
        errors,
      });
    }
  }

  handleEdit = (index) => {
    const newData = [...this.state[stateName]];
    newData[index] = Object.assign({}, newData[index], { isEdit: true });
    this.setState({
      [stateName]: newData,
      disableCreateButton: true,
    });
  };

  handleCancel = (index) => {
    const currentData = [...this.state[stateName]];

    // If the object already existed and has an id,
    // then replace data from props on cancel
    if (currentData[index].id) {
      const propsDataAtIndex = this.props[stateName][index];
      // Replace specific object on which cancel was clicked with props version
      currentData[index] = Object.assign({}, propsDataAtIndex, {
        isEdit: false,
      });
      this.setState({
        [stateName]: currentData,
        disableCreateButton: false,
      });
    } else {
      const propsData = [...this.props[stateName]];
      const errorsData = generateErrorsArrayFromJSON(propsData, [IS_EDIT]);
      this.setState({ [stateName]: propsData, errors: errorsData, disableCreateButton: false });
    }
  };

  handleChange = (value, key, error) => {
    const newData = [...this.state[stateName]];

    const index = key.split('-')[1];
    const field = key.split('-')[0];

    newData[index] = Object.assign({}, { ...newData[index] }, { [field]: value });

    // Update specific error object and field
    const { errors } = this.state;
    errors[index] = Object.assign({}, { ...errors[index] }, { [field]: error });

    const allValid = Object.values(errors[index]).every((valueError) => valueError === false);

    this.setState({
      [stateName]: newData,
      errors,
      disableSaveButton: !allValid,
    });
  };

  handleSave = (index) => {
    if (!this.state.disableSaveButton) {
      const currentData = this.state[stateName];
      currentData[index] = {
        ...currentData[index],
        isEdit: false,
      };
      this.setState({ [stateName]: currentData, disableCreateButton: false });

      const { employeeId } = this.props;

      if (currentData[index].id) {
        this.props.updateEmployeeJobInfo(
          employeeId,
          currentData[index].id,
          currentData[index],
          index,
        );
      } else {
        this.props.createEmployeeJobInfo(employeeId, currentData[index]);
      }
    }
  };

  handleDelete = (jobInfoId) => {
    EnhancedModal.confirm({
      title: DELETE_CONFIRM_PROMPT_MESSAGE,
      content: DELETE_CONFIRM_PROMPT_WARNING_MESSAGE,
      onOk: () => {
        const { employeeId } = this.props;
        this.props.deleteEmployeeJobInfo(employeeId, jobInfoId);
        const currentData = this.state[stateName];
        this.setState({ [stateName]: currentData });
      },
      onCancel() {},
    });
  };

  render() {
    const { divisionOptions, designationOptions, departmentOptions } = this.props;
    const data = this.state[stateName];
    const { disableSaveButton } = this.state;
    const canEdit = false;
    const canDelete = false;

    return (
      <div className="mt-20 pos-rel">
        {data.length ? (
          <EnhancedRow gutter={ROW_GUTTER_SIZE}>
            {data.map((val, index) => {
              const {
                startDate,
                endDate,
                grade,
                subGrade,
                designationId,
                departmentId,
                managerName,
                divisionId,
                grossSalary,
                inflationSupport,
                fuelAllowance,
                isEdit = false,
              } = val;
              const designation = getValueForKeyinJSON(designationOptions, designationId);
              return (
                <EnhancedCol span={FULL_GRID_SIZE}>
                  <EnhancedCard className="mb-10 br-5">
                    <div className="flex" style={{ justifyContent: 'space-between' }}>
                      <span className="card-title mb-10">{designation}</span>

                      {(canDelete || canEdit) && (
                        <div className="flex justify-flex-end">
                          {canEdit && !val.isEdit && (
                            <EnhancedButton
                              className="mr-10"
                              onClick={() => this.handleEdit(index)}
                            >
                              Edit
                            </EnhancedButton>
                          )}
                          {canDelete && val.id && !val.isEdit && (
                            <EnhancedButton onClick={() => this.handleDelete(val.id)}>
                              Delete
                            </EnhancedButton>
                          )}
                          {val.isEdit && (
                            <EnhancedButton
                              type="primary"
                              className="mr-10"
                              onClick={() => this.handleSave(index)}
                              disabled={disableSaveButton}
                            >
                              Save
                            </EnhancedButton>
                          )}
                          {val.isEdit && (
                            <EnhancedButton onClick={() => this.handleCancel(index)}>
                              Cancel
                            </EnhancedButton>
                          )}
                        </div>
                      )}
                    </div>
                    {createFields(
                      [
                        {
                          key: `startDate-${index}`,
                          label: 'Start Date',
                          value: startDate,
                          type: DATE,
                          validations: [REQUIRED],
                        },
                        {
                          key: `endDate-${index}`,
                          label: 'End Date',
                          value: endDate,
                          type: DATE,
                        },
                        {
                          key: `designationId-${index}`,
                          label: 'Job Title',
                          value: getValueForKeyinJSON(designationOptions, designationId),
                          options: designationOptions,
                          type: SELECT,
                          validations: [REQUIRED],
                        },
                        {
                          key: `grade-${index}`,
                          label: 'Grade',
                          value: getValueForKeyinJSON(gradeOptions, grade),
                          options: gradeOptions,
                          type: SELECT,
                          validations: [REQUIRED],
                        },
                        {
                          key: `subGrade-${index}`,
                          label: 'Sub Grade',
                          value: getValueForKeyinJSON(subGradeOptions, subGrade),
                          options: subGradeOptions,
                          type: SELECT,
                          validations: [REQUIRED],
                        },
                        {
                          key: `divisionId-${index}`,
                          label: 'Division',
                          value: getValueForKeyinJSON(divisionOptions, divisionId),
                          options: divisionOptions,
                          type: SELECT,
                          // validations: [REQUIRED],
                        },
                        {
                          key: `departmentId-${index}`,
                          label: 'Department',
                          value: getValueForKeyinJSON(departmentOptions, departmentId),
                          options: departmentOptions,
                          type: SELECT,
                          validations: [REQUIRED],
                        },
                        {
                          key: 'managerName',
                          label: 'Line Manager',
                          value: managerName,
                          type: TEXT,
                        },
                        {
                          key: `grossSalary-${index}`,
                          label: 'Gross Salary',
                          value: grossSalary,
                          type: PASSWORD,
                          validations: [REQUIRED, NUMBERS_ONLY],
                        },
                        {
                          key: `inflationSupport-${index}`,
                          label: 'Inflation Support',
                          value: inflationSupport,
                          type: PASSWORD,
                          validations: [REQUIRED, NUMBERS_ONLY],
                        },
                        {
                          key: `fuelAllowance-${index}`,
                          label: 'Fuel Allowance',
                          value: fuelAllowance,
                          type: PASSWORD,
                          validations: [NUMBERS_ONLY],
                        },
                      ],
                      2,
                      isEdit,
                      [],
                      this.handleChange,
                    )}
                  </EnhancedCard>
                </EnhancedCol>
              );
            })}
          </EnhancedRow>
        ) : (
          noData()
        )}
      </div>
    );
  }
}

export default OrganizationEmploymentHistory;
