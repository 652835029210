import { EnhancedCard, EnhancedTabs } from 'components/shared/antd';
import React from 'react';
import { JOB_INFO, EMPLOYMENT_HISTORY } from 'configs/employeeProfileConstants';
import { prepareOptionsFromJSON } from 'utils/arrayUtils';
import { getActiveEmployeesWithOptions } from 'utils/employeeUtils';
// import Authorize from 'components/Authorize';
import PreviousEmpHistory from 'components/employeeProfile/employmentHistory/previousEmpHistory';
import OrganizationEmpHistory from 'components/employeeProfile/employmentHistory/organizationEmpHistory';
import { hasPermission } from 'utils/AccessUtils';
import Permissions from 'constants/AccessPermissions';

const { TabPane } = EnhancedTabs;
class EmploymentHistory extends React.Component {
  componentDidMount() {
    const employeeId = this.props.match.params.id;
    this.props.getEmployeeEmploymentHistory(employeeId);

    if (hasPermission(this.props.userPermissions, [Permissions.Employee.All.List])) {
      this.props.getAllEmployees();
    }
    this.props.getEmployeeJobInfo(employeeId);
  }

  render() {
    const { isLoading: globalLoading } = this.props;
    return (
      <EnhancedCard className="mt-20" loading={globalLoading} bordered={false}>
        <EnhancedTabs defaultActiveKey="1" centered type="card">
          <TabPane tab="Within Organization" key="1">
            {/* <Authorize
              requiredPermissions={[
                Permissions.Employee.Profile.JobInfo.View
              ]}
            > */}
            <OrganizationEmpHistory
              jobInfo={this.props[JOB_INFO]}
              userPermissions={this.props.userPermissions}
              employeeId={this.props.match.params.id}
              createEmployeeJobInfo={this.props.createEmployeeJobInfo}
              updateEmployeeJobInfo={this.props.updateEmployeeJobInfo}
              deleteEmployeeJobInfo={this.props.deleteEmployeeJobInfo}
              managerOptions={getActiveEmployeesWithOptions(this.props.allEmployees)}
              divisionOptions={this.props.divisions}
              designationOptions={this.props.designations}
              competencyOptions={prepareOptionsFromJSON(this.props.competencies)}
              businessUnitOptions={prepareOptionsFromJSON(this.props.businessUnits)}
              departmentOptions={prepareOptionsFromJSON(this.props.departments)}
            />
            {/* </Authorize> */}
          </TabPane>
          <TabPane tab="Previous Employment" key="2">
            {/* <Authorize
              requiredPermissions={[
                Permissions.Employee.Profile.EmploymentHistory.View
              ]}
            > */}
            <PreviousEmpHistory
              employmentHistory={this.props[EMPLOYMENT_HISTORY]}
              employeeId={this.props.match.params.id}
              deleteEmployeeEmployment={this.props.deleteEmployeeEmployment}
              updateEmployeeEmployment={this.props.updateEmployeeEmployment}
              createEmployeeEmployment={this.props.createEmployeeEmployment}
              isLoading={this.props.isLoading}
              userPermissions={this.props.userPermissions}
            />
            {/* </Authorize> */}
          </TabPane>
        </EnhancedTabs>
      </EnhancedCard>
    );
  }
}

export default EmploymentHistory;
