import { EnhancedCard, EnhancedTabs, EnhancedModal, EnhancedSelect } from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import { hasPermission } from 'utils/AccessUtils';
import AccessPermissions from 'constants/AccessPermissions';
import Authorize from 'components/Authorize';
import moment from 'moment';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';
import AddButton from '../../shared/employeeProfile/AddButton';
import AddPMCycleModal from './addPMCycleModal';
import { DATE_FORMAT } from '../../../configs/employeeProfileConstants';

const { Option } = EnhancedSelect;

const PerformanceManagement = (props) => {
  const { TabPane } = EnhancedTabs;
  const { confirm } = EnhancedModal;

  const {
    history,
    isLoading,
    getPMCycleActions,
    list,
    addPMCycleActions,
    editPMCycleActions,
    getPMCycleDetail,
    deletePMCycleActions,
    closePMCycle,
    userPermissions,
  } = props;
  const [selectedTab, setSelectedTab] = useState('1');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [pmId, setPMId] = useState('');
  const [params, setParams] = useState({
    isActive: 1,
    pageNumber: 0,
    pageSize: 10,
  });

  useEffect(() => {
    getPMCycleActions(params);
  }, []);

  useEffect(() => {
    getPMCycleActions(params);
  }, [params]);

  const canEdit = (record) => !record.freeze;

  const canDelete = (record) => !record.freeze;

  const canClose = (record) => !record.freeze;

  const optionMenu = [
    {
      value: 'view',
      label: 'View Details',
      isAllowed: hasPermission(userPermissions, [
        AccessPermissions.PerformanceManagement.PerformanceCycle.Employee.View,
      ]),
    },
    {
      value: 'edit',
      label: 'Edit',
      canCallback: canEdit,
      isAllowed: hasPermission(userPermissions, [
        AccessPermissions.PerformanceManagement.PerformanceCycle.Update,
      ]),
    },
    {
      value: 'delete',
      label: 'Delete',
      canCallback: canDelete,
      isAllowed: hasPermission(userPermissions, [
        AccessPermissions.PerformanceManagement.PerformanceCycle.Delete,
      ]),
    },
    {
      value: 'close',
      label: 'Close',
      canCallback: canClose,
      isAllowed: hasPermission(userPermissions, [
        AccessPermissions.PerformanceManagement.PerformanceCycle.Close,
      ]),
    },
  ];

  const tabChangeHandler = (key) => {
    if (key === '1') {
      setParams({
        ...params,
        isActive: 1,
        pageNumber: 0,
        pageSize: 10,
      });
    } else if (key === '2') {
      setParams({
        ...params,
        isActive: 0,
        pageNumber: 0,
        pageSize: 10,
      });
    }
    setSelectedTab(key);
  };

  const viewEmp = (key) => {
    history.push(`/performance-management/${key}`);
  };
  const editPerformanceCycle = (key) => {
    getPMCycleDetail(key);
    setIsEdit(true);
    setPMId(key);
    setIsModalVisible(true);
  };

  const showDeleteConfirm = (key) => {
    confirm({
      title: 'Are you sure delete this Performance Cycle?',
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deletePMCycleActions({ payload: key, parameters: params });
      },
      onCancel() {},
    });
  };

  const deletePerformanceCycle = (key) => {
    showDeleteConfirm(key);
  };

  const closePerformanceCycle = (key) => {
    confirm({
      title: 'Are you sure you want to close this cycle?',
      content: '',
      okText: 'Yes',
      okType: 'success',
      cancelText: 'No',
      onOk() {
        closePMCycle({ performanceCycleId: key, parameters: params });
      },
      onCancel() {},
    });
  };

  const changeActionHandler = (key, id) => {
    if (key === 'view') {
      viewEmp(id);
    } else if (key === 'edit') {
      editPerformanceCycle(id);
    } else if (key === 'delete') {
      deletePerformanceCycle(id);
    } else if (key === 'close') {
      closePerformanceCycle(id);
    }
  };

  const setActionsHandler = (id, record) => {
    const actions = (
      <EnhancedSelect
        style={{
          width: 120,
        }}
        className="action-selection-dropdown"
        placeholder="Options"
        value="Options"
        onChange={(key) => changeActionHandler(key, id)}
      >
        {optionMenu.map(
          (item) =>
            (!item.canCallback || item.canCallback(record)) &&
            item.isAllowed && <Option value={item.value}>{item.label}</Option>,
        )}
      </EnhancedSelect>
    );
    return actions;
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      render: (dueDate) => moment(dueDate).format(DATE_FORMAT),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      render: (startDate) => moment(startDate).format(DATE_FORMAT),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      render: (endDate) => moment(endDate).format(DATE_FORMAT),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render: (id, _record) => setActionsHandler(id, _record),
    },
  ];

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      pageNumber: pagination.current - 1,
      pageSize: pagination.pageSize,
    });
  };

  const showModal = () => {
    setIsEdit(false);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <EnhancedCard>
        <React.Fragment>
          <Authorize
            requiredPermissions={[AccessPermissions.PerformanceManagement.PerformanceCycle.Create]}
          >
            <AddButton buttonText="Launch New Performance Cycle" clickHandler={showModal} />
          </Authorize>

          <EnhancedTabs defaultActiveKey={selectedTab} onChange={tabChangeHandler}>
            <TabPane tab="Active Performance Cycle" key="1">
              <EnhancedServerSideTable
                rowKey={(record) => record.id}
                loading={isLoading}
                columns={columns}
                data={list.rows}
                onChange={handleTableChange}
                className="goal-table-container"
                paginationInfo={{
                  totalItems: list.count,
                  pageSize: params.pageSize,
                  pageNumber: params.pageNumber + 1,
                }}
                showResetFiltersButton={false}
              />
            </TabPane>
            <TabPane tab="Completed Performance Cycle" key="2">
              <EnhancedServerSideTable
                rowKey={(record) => record.id}
                loading={isLoading}
                columns={columns}
                data={list.rows}
                onChange={handleTableChange}
                className="goal-table-container"
                paginationInfo={{
                  totalItems: list.count,
                  pageSize: params.pageSize,
                  pageNumber: params.pageNumber + 1,
                }}
                showResetFiltersButton={false}
              />
            </TabPane>
          </EnhancedTabs>
          <AddPMCycleModal
            isModalVisible={isModalVisible}
            handleOk={handleOk}
            handleCancel={handleCancel}
            params={params}
            isLoading={isLoading}
            addPMCycleActions={addPMCycleActions}
            editPMCycleActions={editPMCycleActions}
            getPMCycleDetail={getPMCycleDetail}
            isEdit={isEdit}
            cycleDetails={props.pmCycleDetail}
            id={pmId}
          />
        </React.Fragment>
      </EnhancedCard>
    </div>
  );
};

export default PerformanceManagement;
