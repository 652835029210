import {
  GET_LEAVE_TYPES_INIT,
  GET_LEAVE_TYPES_SUCCESS,
  GET_LEAVE_TYPES_FAILURE,
} from 'constants/LeaveTypes';

const initialState = {
  leaveTypes: [],
};
export default function leaveTypesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LEAVE_TYPES_INIT: {
      return Object.assign({}, state, {
        leaveTypes: [],
      });
    }
    case GET_LEAVE_TYPES_SUCCESS: {
      return Object.assign({}, state, {
        leaveTypes: action.data,
      });
    }
    case GET_LEAVE_TYPES_FAILURE: {
      return Object.assign({}, state, {
        leaveTypes: [],
      });
    }
    default:
      return state;
  }
}
