import { basicSalaryFactor } from 'configs/employeeProfileConstants';

export const calculateBasicSalary = (grossSalary) => Math.round(grossSalary * basicSalaryFactor);

export const calculateMedicalAllowance = (grossSalary) =>
  Math.round(grossSalary * (1 - basicSalaryFactor));

export const calculateTotalSalary = (grossSalary, inflationSupport) => {
  const sumArray = [grossSalary, inflationSupport];

  const totalSalary = sumArray.reduce((accumulator, currentValue) => {
    let response = accumulator + currentValue;

    if (typeof currentValue === 'string' && currentValue) {
      response = accumulator + parseInt(currentValue, 10);
    }

    return response;
  }, 0);

  return totalSalary;
};
