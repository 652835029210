import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedIcon,
  EnhancedModal,
  EnhancedSelect,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';
import { BREADCRUMBS } from 'configs/constants';
import AddGoalFormModal from './AddGoalFormModal';

const { confirm } = EnhancedModal;
const { Option } = EnhancedSelect;

const CategoryInsights = (props) => {
  const {
    list,
    goals,
    getGoal,
    isLoading,
    addGoalCategoryGoal,
    getGoalCategoryGoal,
    updateGoalCategoryGoal,
    removeGoalCategoryGoal,
    getGoalCategoryDetails,
    categoryDetails,
    updateBreadCrumb,
  } = props;

  const [isGoalFormModalVisible, setIsGoalFormModalVisible] = useState(false);
  const [params, setParams] = useState({
    page: 0,
    size: 10,
  });
  const [selectedGoal, setSelectedGoal] = useState({});

  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getGoal({
      ...params,
      size: 100000, // Set size 100000 for getting all predefined goals in dropdown.
    });
    const paramsData = {
      ...params,
      categoryId: props.match.params.categoryId,
    };
    getGoalCategoryGoal(paramsData);
    getGoalCategoryDetails({ id: paramsData.categoryId });
  }, []);

  useEffect(() => {
    if (categoryDetails) {
      updateBreadCrumb(BREADCRUMBS.GOAL_CATEGORY_DETAIL, categoryDetails.id, categoryDetails.title);
    }
  }, [categoryDetails]);

  const setInitialState = () => {
    setIsAdd(false);
    setIsEdit(false);
  };

  const showGoalFormModal = (isEditValue) => {
    if (isEditValue) setIsEdit(isEditValue);
    else setIsAdd(true);

    setIsGoalFormModalVisible(true);
  };

  const handleGoalFormOk = () => {
    setIsGoalFormModalVisible(false);
    setInitialState();
  };

  const handleGoalFormCancel = () => {
    setIsGoalFormModalVisible(false);
    setInitialState();
  };

  const handleGoalDelete = (record) => {
    confirm({
      title: 'Are you sure you want to delete this goal?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        let pageNumber = params.page;
        const dataCount = list.count;
        const pageLastRecord = (dataCount - 1) % 10;

        if (pageLastRecord === 0 && pageNumber > 0) {
          pageNumber = params.page - 1;
          setParams({
            ...params,
            page: pageNumber,
          });
        }

        const payload = {
          goalId: record.id,
          params: {
            ...params,
            page: pageNumber,
            categoryId: props.match.params.categoryId,
          },
        };
        removeGoalCategoryGoal(payload);
      },
    });
  };

  const changeActionHandler = (key, id, _record) => {
    setInitialState();
    if (key === '1') {
      setIsEdit(true);
      setSelectedGoal(_record);
      showGoalFormModal(true);
    } else if (key === '2') {
      handleGoalDelete(_record);
    }
  };

  const optionMenu = [
    {
      value: '1',
      label: 'Edit',
    },
    {
      value: '2',
      label: 'Delete',
    },
  ];

  const setActionsHandler = (id, _record) => {
    return (
      <EnhancedSelect
        style={{
          width: 120,
        }}
        className="action-selection-dropdown"
        placeholder="Options"
        value="Options"
        onChange={(key) => changeActionHandler(key, id, _record)}
      >
        {optionMenu.map((item) => (
          <Option value={item.value}>{item.label}</Option>
        ))}
      </EnhancedSelect>
    );
  };

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      page: pagination.current - 1,
      size: pagination.pageSize,
    });
    // getGoalCategoryGoal({
    //   page: pagination.current - 1,
    //   size: pagination.pageSize,
    //   categoryId: props.match.params.categoryId,
    // });
  };

  useEffect(() => {
    const paramsData = {
      ...params,
      categoryId: props.match.params.categoryId,
    };
    getGoalCategoryGoal(paramsData);
  }, [params]);

  const getColumnsInfo = () => {
    const columns = [
      {
        title: 'Goal Title',
        dataIndex: 'title',
        key: 'title',
        filterConfig: {
          type: 'search',
          key: 'title',
        },
      },
      {
        title: 'Weightage',
        dataIndex: 'weightage',
        key: 'weightage',
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        render: (id, _record) => setActionsHandler(id, _record),
      },
    ];
    return columns;
  };

  return (
    <React.Fragment>
      <EnhancedRow type="flex" justify="end" align="middle" className="mt-20 mb-20">
        <EnhancedCol>
          <EnhancedButton className="btn-edit" onClick={() => showGoalFormModal(false)}>
            <EnhancedIcon type="plus-circle" />
            <span>Add New Goal</span>
          </EnhancedButton>
        </EnhancedCol>
      </EnhancedRow>
      <EnhancedServerSideTable
        rowKey={(record) => `${record.id}-${record.weightage}`}
        columns={getColumnsInfo()}
        data={list.rows}
        onChange={handleTableChange}
        className="goal-table-container"
        paginationInfo={{
          totalItems: list.count,
          pageSize: params.size,
          pageNumber: params.page + 1,
        }}
        scroll={{ x: 'max-content' }}
        updateParams={(updatedParams) => {
          setParams({
            ...params,
            ...updatedParams,
            page: 0, // move to page no 0 in case filters are applied
          });
        }}
      />
      <AddGoalFormModal
        isModalVisible={isGoalFormModalVisible}
        handleOk={handleGoalFormOk}
        handleCancel={handleGoalFormCancel}
        goals={goals}
        selectedGoal={selectedGoal}
        categoryId={props.match.params.categoryId}
        addGoalCategoryGoalActions={addGoalCategoryGoal}
        updateGoalCategoryGoalActions={updateGoalCategoryGoal}
        params={params}
        isEdit={isEdit}
        isAdd={isAdd}
        editableHandler={setIsEdit}
        isLoading={isLoading}
        details={selectedGoal}
      />
    </React.Fragment>
  );
};

export default CategoryInsights;
