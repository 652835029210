import {
  GET_PUBLIC_HOLIDAYS,
  GET_PUBLIC_HOLIDAYS_SUCCESS,
  GET_PUBLIC_HOLIDAYS_FAILURE,
} from 'constants/PublicHolidays';

const initialState = {
  publicHolidays: { workingHolidays: [] },
};
export default function publicHolidaysReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PUBLIC_HOLIDAYS: {
      return state;
    }
    case GET_PUBLIC_HOLIDAYS_SUCCESS: {
      return {
        ...state,
        publicHolidays: action.data,
      };
    }
    case GET_PUBLIC_HOLIDAYS_FAILURE: {
      return state;
    }
    default:
      return state;
  }
}
