import {
  getRoles,
  getRoleEmployees,
  addRole,
  updateRole,
  deleteRole,
  getDDLEmployees,
  assignRoleToEmployee,
  deleteRoleFromEmployee,
} from 'sources/RoleSource';

import {
  GET_ROLES_INIT,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  GET_ROLE_EMPLOYEES_INIT,
  GET_ROLE_EMPLOYEES_SUCCESS,
  GET_ROLE_EMPLOYEES_FAILURE,
  ADD_ROLE_INIT,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAILURE,
  UPDATE_ROLE_INIT,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  DELETE_ROLE_INIT,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
  GET_DDL_EMPLOYEES_INIT,
  GET_DDL_EMPLOYEES_SUCCESS,
  GET_DDL_EMPLOYEES_FAILURE,
  ASSIGN_ROLE_TO_EMPLOYEE_INIT,
  ASSIGN_ROLE_TO_EMPLOYEE_SUCCESS,
  ASSIGN_ROLE_TO_EMPLOYEE_FAILURE,
  DELETE_ROLE_FROM_EMPLOYEE_INIT,
  DELETE_ROLE_FROM_EMPLOYEE_SUCCESS,
  DELETE_ROLE_FROM_EMPLOYEE_FAILURE,
} from 'constants/RoleTypes';

// normal actions
export const getRolesInit = () => ({
  type: GET_ROLES_INIT,
});

export const getRolesSuccess = (data) => ({
  type: GET_ROLES_SUCCESS,
  data,
});

export const getRolesFailure = (data) => ({
  type: GET_ROLES_FAILURE,
  data,
});

// normal actions
export const getRoleEmployeesInit = () => ({
  type: GET_ROLE_EMPLOYEES_INIT,
});

export const getRoleEmployeesSuccess = (data) => ({
  type: GET_ROLE_EMPLOYEES_SUCCESS,
  data,
});

export const getRoleEmployeesFailure = (data) => ({
  type: GET_ROLE_EMPLOYEES_FAILURE,
  data,
});

// normal actions
export const addRoleInit = () => ({
  type: ADD_ROLE_INIT,
});

export const addRoleSuccess = (data) => ({
  type: ADD_ROLE_SUCCESS,
  data,
});

export const addRoleFailure = (data) => ({
  type: ADD_ROLE_FAILURE,
  data,
});

// normal actions
export const updateRoleInit = () => ({
  type: UPDATE_ROLE_INIT,
});

export const updateRoleSuccess = (data) => ({
  type: UPDATE_ROLE_SUCCESS,
  data,
});

export const updateRoleFailure = (data) => ({
  type: UPDATE_ROLE_FAILURE,
  data,
});

// normal actions
export const deleteRoleInit = () => ({
  type: DELETE_ROLE_INIT,
});

export const deleteRoleSuccess = (data) => ({
  type: DELETE_ROLE_SUCCESS,
  data,
});

export const deleteRoleFailure = (data) => ({
  type: DELETE_ROLE_FAILURE,
  data,
});

// normal actions
export const getDDLEmployeesInit = () => ({
  type: GET_DDL_EMPLOYEES_INIT,
});

export const getDDLEmployeesSuccess = (data) => ({
  type: GET_DDL_EMPLOYEES_SUCCESS,
  data,
});

export const getDDLEmployeesFailure = (data) => ({
  type: GET_DDL_EMPLOYEES_FAILURE,
  data,
});

// normal actions
export const assignRoleToEmployeeInit = () => ({
  type: ASSIGN_ROLE_TO_EMPLOYEE_INIT,
});

export const assignRoleToEmployeeSuccess = (data) => ({
  type: ASSIGN_ROLE_TO_EMPLOYEE_SUCCESS,
  data,
});

export const assignRoleToEmployeeFailure = (data) => ({
  type: ASSIGN_ROLE_TO_EMPLOYEE_FAILURE,
  data,
});

// normal actions
export const deleteRoleFromEmployeeInit = () => ({
  type: DELETE_ROLE_FROM_EMPLOYEE_INIT,
});

export const deleteRoleFromEmployeeSuccess = (data) => ({
  type: DELETE_ROLE_FROM_EMPLOYEE_SUCCESS,
  data,
});

export const deleteRoleFromEmployeeFailure = (data) => ({
  type: DELETE_ROLE_FROM_EMPLOYEE_FAILURE,
  data,
});

// Async Action

export const getRolesAction = () => getRoles();

export const getRoleEmployeesAction = (id) => getRoleEmployees(id);

export const addRoleAction = (req) => addRole(req);

export const updateRoleAction = (req) => updateRole(req);

export const deleteRoleAction = (id) => deleteRole(id);

export const getDDLEmployeesAction = (userId) => getDDLEmployees(userId);

export const assignRoleToEmployeeAction = (roleId, employeeId, userId) =>
  assignRoleToEmployee(roleId, employeeId, userId);

export const deleteRoleFromEmployeeAction = (employeeRoleId, roleId, userId) =>
  deleteRoleFromEmployee(employeeRoleId, roleId, userId);
