import {
  GET_RCA_PROJECTS_INIT,
  GET_RCA_PROJECTS_SUCCESS,
  GET_RCA_PROJECTS_FAILURE,
} from 'constants/RcaProjectsTypes';

import { CLEAR_RESOURCE_DATA } from 'constants/ResourceTypes';

const initialState = {
  projects: [],
  isLoading: false,
};

export default function rcaProjectsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RCA_PROJECTS_INIT:
      return {
        ...state,
        isLoading: true,
        projects: [],
      };
    case GET_RCA_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.data,
        isLoading: false,
      };
    case GET_RCA_PROJECTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        projects: action.data,
      };
    case CLEAR_RESOURCE_DATA:
      return {
        ...state,
        isLoading: false,
        projects: [],
      };
    default:
      return state;
  }
}
