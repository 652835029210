import {
  getProjectRoles,
  addProjectRole,
  editProjectRole,
  deleteProjectRole,
} from '../../sources/ProjectRolesSource';

import {
  PROJECT_ROLES_INIT,
  PROJECT_ROLES_FAILURE,
  GET_PROJECT_ROLES_SUCCESS,
  ADD_PROJECT_ROLE_SUCCESS,
  EDIT_PROJECT_ROLE_SUCCESS,
  DELETE_PROJECT_ROLE_SUCCESS,
} from '../../constants/ProjectRoles';

export const projectRolesInit = () => ({
  type: PROJECT_ROLES_INIT,
});

export const addProjectRoleSuccess = (data) => ({
  type: ADD_PROJECT_ROLE_SUCCESS,
  data,
});

export const projectRolesFailure = () => ({
  type: PROJECT_ROLES_FAILURE,
});

export const editProjectRoleSuccess = (data) => ({
  type: EDIT_PROJECT_ROLE_SUCCESS,
  data,
});

export const deleteProjectRoleSuccess = (data) => ({
  type: DELETE_PROJECT_ROLE_SUCCESS,
  data,
});

export const getProjectRolesSuccess = (data) => ({
  type: GET_PROJECT_ROLES_SUCCESS,
  data,
});

export const getProjectRolesAction = () => getProjectRoles();
export const addProjectRoleAction = (requestObject) => (dispatch) => {
  dispatch(addProjectRole(requestObject));
};
export const editProjectRoleAction = (requestObject) => (dispatch) => {
  dispatch(editProjectRole(requestObject));
};
export const deleteProjectRoleAction = (requestObject) => (dispatch) => {
  dispatch(deleteProjectRole(requestObject));
};
