import * as ExpenseClaimTypes from 'constants/ExpenseClaimTypes';

const initialState = {
  isLoading: false,
  isSuccessfull: false,
  approvalStatusUpdated: false,
};

export default function addClaimReducer(state = initialState, action) {
  switch (action.type) {
    case ExpenseClaimTypes.ADD_EXPENSE_CLAIM_INIT: {
      return {
        ...state,
        isLoading: true,
        isSuccessfull: false,
      };
    }
    case ExpenseClaimTypes.ADD_EXPENSE_CLAIM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccessfull: true,
      };
    }
    case ExpenseClaimTypes.ADD_EXPENSE_CLAIM_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isSuccessfull: false,
      };
    }
    case ExpenseClaimTypes.APPROVAL_STATUS_INIT: {
      return {
        ...state,
        approvalStatusUpdated: false,
      };
    }
    case ExpenseClaimTypes.APPROVAL_STATUS_SUCCESS: {
      return {
        ...state,
        approvalStatusUpdated: true,
      };
    }
    case ExpenseClaimTypes.APPROVAL_STATUS_FAILURE: {
      return {
        ...state,
        approvalStatusUpdated: false,
      };
    }
    default:
      return state;
  }
}
