/* eslint-disable no-param-reassign */
import * as DefaulterTypes from 'constants/DefaulterTypes';

const initialState = {
  defaulters: [],
  isLoading: false,
  errorText: '',
  successText: '',
};
export default function defaulterReducer(state = initialState, action) {
  switch (action.type) {
    case DefaulterTypes.GET_DEFAULTERS_INIT: {
      state = Object.assign({}, initialState, { isLoading: true });
      return state;
    }
    case DefaulterTypes.GET_DEFAULTERS_SUCCESS: {
      state = Object.assign({}, state, {
        defaulters: action.data.defaulters,
        employees: action.data.employees,
        managers: action.data.managers,
        isLoading: false,
      });
      return state;
    }
    case DefaulterTypes.GET_DEFAULTERS_FAILURE: {
      state = Object.assign({}, initialState);
      return state;
    }
    default:
      return state;
  }
}
