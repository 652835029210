import {
  EnhancedCol,
  EnhancedButton,
  EnhancedRow,
  EnhancedDivider,
  EnhancedSpin,
  EnhancedInput,
  EnhancedModal,
  EnhancedSelect,
  EnhancedDatePicker,
  EnhancedInputNumber,
} from 'components/shared/antd';
import React from 'react';
import moment from 'moment';
import convertRolesToObjectForm from 'utils/employeeUtils';
import { employeeTypesFiltered, dateFormat, LENGTHCONSTRAINT } from 'configs/constants';
import { invalidDate } from 'configs/additionalConstants';
import RequiredField from '../RequiredField';

const { Option } = EnhancedSelect;
const { TextArea } = EnhancedInput;

class ProjectAddEditEmployeeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialValuesForState();
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.addEditModalVisible !== nextProps.visible) {
      this.setState({
        addEditModalVisible: nextProps.visible,
      });
    }
    if (this.state.information !== nextProps.projectData) {
      let depart = '';
      let compet = '';
      const information = { ...nextProps.projectData };
      // const dateFormatWithSpace = dateFormat.DDMMMYYYYwithSpace;
      if (information.startDate) {
        information.startDate = moment.utc(information.startDate);
      }
      if (information.endDate) {
        if (information.endDate !== invalidDate) {
          information.endDate = moment.utc(information.endDate);
        }
      }
      if (information.extendedEndDate) {
        if (information.extendedEndDate !== invalidDate) {
          information.extendedEndDate = moment.utc(information.extendedEndDate);
        }
      }
      if (information.role) {
        information.roles = information.role;
      }
      if (information.department) {
        depart = information.department;
      }
      if (information.competency) {
        compet = information.competency;
      }
      this.setState({
        information: {
          ...information,
          comment: '',
          assigneeId: information.employeeId,
          projectId: parseInt(nextProps.projectId, 10),
        },
        department: depart,
        competency: compet,
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      information: this.getInitialValuesForState(),
    });
  }

  onStartDateChange = (date) => {
    this.setState({
      information: {
        ...this.state.information,
        startDate: date,
      },
    });
  };
  onEndDateChange = (date) => {
    this.setState({
      information: {
        ...this.state.information,
        endDate: date,
      },
    });
  };

  onExtendedEndDateChange = (date) => {
    this.setState({
      information: {
        ...this.state.information,
        extendedEndDate: date,
      },
    });
  };

  onProbabilityChange = (probability) => {
    this.setState({
      information: {
        ...this.state.information,
        probability,
      },
    });
  };

  onCommentChange = (e) => {
    this.setState({
      information: {
        ...this.state.information,
        comment: e.target.value,
      },
    });
  };

  onAllocationChange = (allocation) => {
    this.setState({
      information: {
        ...this.state.information,
        allocation,
      },
    });
  };

  onBilledChange = (billed) => {
    this.setState({
      information: {
        ...this.state.information,
        billed,
      },
    });
  };

  onTypeChange = (type) => {
    this.setState({
      information: {
        ...this.state.information,
        type,
      },
    });
  };
  onEmployeeChange = (employeeId, object) => {
    const employee = this.props.allEmployees.filter((emp) => emp.id === employeeId);
    let department = '';
    let competency = '';
    let locat = '';
    let empTaleoId = '';
    const foundDepartment = employee.find((emp) => emp.department);
    const foundComptency = employee.find((emp) => emp.competency);
    const foundLocation = employee.find((emp) => emp.location);
    const foundTaleoId = employee.find((emp) => emp.taleoId);
    if (foundDepartment) {
      department = foundDepartment.department.name;
    }
    if (foundComptency) {
      competency = foundComptency.competency.name;
    }
    if (foundLocation) {
      locat = foundLocation.location.name;
    }
    if (foundTaleoId) {
      empTaleoId = foundTaleoId.taleoId;
    }
    this.setState({
      information: {
        ...this.state.information,
        assigneeId: employeeId && parseInt(employeeId, 10),
      },
      department,
      competency,
      location: locat,
      employeeName: employeeId ? object.props.title : '',
      taleoId: empTaleoId,
    });
  };

  getInitialValuesForState = () => ({
    addEditModalVisible: this.props.visible,
    employeeName: '',
    information: {
      type: undefined,
      startDate: undefined,
      endDate: undefined,
      allocation: 0,
      billed: 0,
      employeeId: parseInt(this.props.userId, 10),
      employeeProjectId: undefined,
      assigneeId: undefined,
      roles: [],
      projectId: this.props.projectId,
      comment: '',
    },
    department: '',
    competency: '',
  });

  getUpdatedInformation = (information) => {
    const previousInformation = this.props.projectData;
    const updatedInformation = {};
    const dateFormatWithSpace = dateFormat.DDMMMYYYYwithSpace;
    updatedInformation.assigneeId = information.assigneeId;
    updatedInformation.employeeId = information.employeeId;
    updatedInformation.projectId = information.projectId;
    updatedInformation.employeeProjectId = information.employeeProjectId;
    updatedInformation.comment = information.comment;

    /*
    updatedInformation.comment = JSON.parse(JSON.stringify(this.state.information.comment));
    updatedInformation.comment.push({
      comment: information.comment,
      commentDate: moment.utc(),
    });
*/

    if (information.roles.length) {
      updatedInformation.roles = information.roles;
    }
    if (previousInformation.allocation !== information.allocation) {
      updatedInformation.allocation = information.allocation;
    }
    if (previousInformation.billed !== information.billed) {
      updatedInformation.billed = information.billed;
    }

    if (previousInformation.type !== information.type) {
      updatedInformation.type = information.type;
    }
    if (
      moment(previousInformation.startDate).format(dateFormatWithSpace) !==
      moment.utc(information.startDate).format(dateFormatWithSpace)
    ) {
      updatedInformation.startDate = information.startDate;
    }
    if (
      moment(previousInformation.endDate).format(dateFormatWithSpace) !==
      moment.utc(information.endDate).format(dateFormatWithSpace)
    ) {
      updatedInformation.endDate = information.endDate;
    }
    if (
      moment(previousInformation.extendedEndDate).format(dateFormatWithSpace) !==
      moment.utc(information.extendedEndDate).format(dateFormatWithSpace)
    ) {
      updatedInformation.extendedEndDate = information.extendedEndDate;
    }
    if (previousInformation.probability !== information.probability) {
      updatedInformation.probability = information.probability;
    }
    return updatedInformation;
  };

  editRoles = (roles) => {
    this.setState({
      information: {
        ...this.state.information,
        roles,
      },
    });
  };

  handleModalEditResource = () => {
    let { roles } = this.state.information;
    const allRolesObj = this.props.roles;
    const { isEdit } = this.props;
    const rolesString = roles;

    if (isEdit) {
      const currentRoles = this.state.information.roles;
      const previousRoles = [this.props.projectData.role];
      roles = convertRolesToObjectForm([currentRoles], previousRoles, allRolesObj);
    } else {
      const currentRoles = [];
      allRolesObj.forEach((rolesObj) => {
        if (roles.includes(rolesObj.role)) {
          currentRoles.push(rolesObj.id);
        }
      });
      roles = currentRoles;
    }

    const information = {
      ...this.state.information,
      roles,
      employeeId: parseInt(this.props.userId, 10),
    };
    this.setState({
      information,
    });

    this.setState({
      addEditModalVisible: false,
    });

    /*
     * we are facing a critical bug where we are getting wrong calculations in RCA Report
     * with respect to resources' allocation dates. Because we are also saving time
     * with data in allocation end and start date
     * For example, we allocated a resource at 1am pakistan time, in UTC it will go
     * to previous date
     * Hence I am hardcoding a fix time in end and start dates so that they remain the same day
     * when converted into UTC
     */

    const fixHour = '16';

    if (isEdit) {
      const updatedInformation = this.getUpdatedInformation(information);

      if (updatedInformation.startDate) {
        updatedInformation.startDate.set('hours', fixHour);
      }

      if (updatedInformation.endDate) {
        updatedInformation.endDate.set('hours', fixHour);
      }

      if (updatedInformation.extendedEndDate) {
        updatedInformation.extendedEndDate.set('hours', fixHour);
      }

      information.roles = rolesString;
      this.props.updateEmployeesProjectActions(information, updatedInformation);
      this.setState({
        information: {
          comment: '',
        },
      });
    } else {
      if (information.startDate) {
        information.startDate.set('hours', fixHour);
      }
      if (information.endDate) {
        information.endDate.set('hours', fixHour);
      }
      if (information.extendedEndDate) {
        information.extendedEndDate.set('hours', fixHour);
      }
      this.props.addNewResourceProject(
        information,
        rolesString,
        this.state.employeeName,
        this.state.department,
        this.state.competency,
        this.state.location,
        this.state.taleoId,
      );
    }
    this.props.onModalClose(false);
  };

  handleModalCancel = () => {
    this.props.onModalClose(false);
  };

  handleOnClose = () => {
    this.setState({
      ...this.getInitialValuesForState(),
      addEditModalVisible: false,
    });
  };
  disabledStartDate = (startValue) => {
    const { endDate } = this.state.information;
    const { project } = this.props;
    const projectStartDate = project.startDate ? moment.utc(project.startDate) : false;
    const projectEndDate = project.endDate ? moment.utc(project.endDate) : false;
    let isLessthanProjectStartDate = false;
    let isGreaterthanProjectEndDate = false;
    let isGreaterthanEndDate = false;
    if (startValue) {
      isLessthanProjectStartDate = projectStartDate
        ? startValue.valueOf() <= projectStartDate.valueOf()
        : false;
      isGreaterthanProjectEndDate = projectEndDate
        ? startValue.valueOf() >= projectEndDate.valueOf()
        : false;
      isGreaterthanEndDate = endDate ? startValue.valueOf() > endDate.valueOf() : false;
    }
    if (isLessthanProjectStartDate || isGreaterthanProjectEndDate || isGreaterthanEndDate) {
      return true;
    }
    return false;
  };

  disabledEndDate = (endValue) => {
    const { startDate } = this.state.information;
    const { project } = this.props;
    const projectStartDate = project.startDate ? moment.utc(project.startDate) : false;
    const projectEndDate = project.endDate ? moment.utc(project.endDate) : false;
    let isLessthanProjectStartDate = false;
    let isGreaterthanProjectEndDate = false;
    let isLessthanStartDate = false;
    if (endValue) {
      isLessthanProjectStartDate = projectStartDate
        ? endValue.valueOf() <= projectStartDate.valueOf()
        : false;
      isGreaterthanProjectEndDate = projectEndDate
        ? endValue.valueOf() >= projectEndDate.valueOf()
        : false;
      isLessthanStartDate = startDate ? endValue.valueOf() < startDate.valueOf() : false;
    }
    if (isLessthanProjectStartDate || isGreaterthanProjectEndDate || isLessthanStartDate) {
      return true;
    }
    return false;
  };
  shouldButtonDisable = () => {
    const { information } = this.state;
    if (
      information.type &&
      information.roles &&
      information.allocation &&
      information.startDate &&
      information.billed >= 0 &&
      information.assigneeId &&
      information.comment &&
      information.comment.length > LENGTHCONSTRAINT.COMMENT_LENGTH
    ) {
      return false;
    }
    return true;
  };
  renderEmployees = () => {
    const employees = this.props.allEmployees || [];
    // use to filter out the current employees from all employees
    // this.props.currentEmployees.push(this.props.userId);
    // if (!this.props.isEdit) {
    //   employees = employees.filter(emp => !this.props.currentEmployees.includes(emp.id));
    // }
    return (
      <EnhancedSelect
        showSearch
        allowClear
        optionFilterProp="children"
        value={this.state.information.assigneeId}
        style={{ width: '100%' }}
        placeholder="Please Select Employee"
        onChange={this.onEmployeeChange}
        notFoundContent={this.props.isLoadingEmployees ? <EnhancedSpin size="small" /> : null}
        disabled={this.props.isEdit}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().includes(input.toLowerCase())
        }
      >
        {employees.map((employee) =>
          employee.isActive ? (
            <Option key={employee.id} value={employee.id} title={employee.name}>
              {employee.name}
            </Option>
          ) : (
            ''
          ),
        )}
      </EnhancedSelect>
    );
  };
  render() {
    let { information } = this.state;
    if (!information) information = {};
    const { isEdit } = this.props;
    let heading = ` Resource in '${this.props.project.name}'  Project`;
    heading = (isEdit ? 'Edit' : 'Add') + heading;
    return (
      <EnhancedModal
        title={heading}
        visible={this.state.addEditModalVisible}
        onCancel={this.handleModalCancel}
        footer={null}
        afterClose={this.handleOnClose}
      >
        <EnhancedRow>
          <EnhancedCol lg={24}>
            <EnhancedRow className="margin-top10">
              <EnhancedCol lg={8}>
                <RequiredField>Employee</RequiredField>
              </EnhancedCol>
              <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
                {this.renderEmployees()}
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="margin-top10">
              <EnhancedCol lg={8}>Competency</EnhancedCol>
              <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
                <EnhancedInput value={this.state.competency} disabled />
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="margin-top10">
              <EnhancedCol lg={8}>Department</EnhancedCol>
              <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
                <EnhancedInput value={this.state.department} disabled />
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="margin-top10">
              <EnhancedCol lg={8}>
                <RequiredField>Start Date</RequiredField>
              </EnhancedCol>
              <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
                <EnhancedDatePicker
                  className="width-100"
                  onChange={this.onStartDateChange}
                  allowClear
                  value={information.startDate}
                  format={dateFormat.DDMMMYYYYwithSpace}
                  disabledDate={this.disabledStartDate}
                  disabled={this.props.isEdit}
                />
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="margin-top10">
              <EnhancedCol lg={8}>End Date</EnhancedCol>
              <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
                <EnhancedDatePicker
                  className="width-100"
                  onChange={this.onEndDateChange}
                  allowClear
                  value={
                    information.endDate !== invalidDate && information.endDate
                      ? moment(information.endDate, dateFormat.DDMMMYYYYwithSpace)
                      : undefined
                  }
                  format={dateFormat.DDMMMYYYYwithSpace}
                  disabledDate={this.disabledEndDate}
                />
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="margin-top10">
              <EnhancedCol lg={8}>Extended End Date</EnhancedCol>
              <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
                <EnhancedDatePicker
                  className="width-100"
                  onChange={this.onExtendedEndDateChange}
                  allowClear
                  value={
                    information.extendedEndDate !== invalidDate && information.extendedEndDate
                      ? moment(information.extendedEndDate, dateFormat.DDMMMYYYYwithSpace)
                      : undefined
                  }
                  format={dateFormat.DDMMMYYYYwithSpace}
                />
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="margin-top10">
              <EnhancedCol lg={8}>Probability %</EnhancedCol>
              <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
                <EnhancedInputNumber
                  className="width-100"
                  placeholder="Please Enter Probability %"
                  formatter={(value) => (value >= 0 ? `${value}%` : '')}
                  onChange={this.onProbabilityChange}
                  value={information.probability}
                  max={100}
                  min={0}
                />
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="margin-top10">
              <EnhancedCol lg={8}>
                <RequiredField>Allocation</RequiredField>
              </EnhancedCol>
              <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
                <EnhancedInputNumber
                  className="width-100"
                  placeholder="Please Enter Allocation"
                  formatter={(value) => (value ? `${value}%` : '')}
                  onChange={this.onAllocationChange}
                  value={information.allocation}
                  disabled={this.props.isEdit}
                  max={100}
                  min={0}
                />
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="margin-top10">
              <EnhancedCol lg={8}>
                <RequiredField>Billing %</RequiredField>
              </EnhancedCol>
              <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
                <EnhancedInputNumber
                  className="width-100"
                  placeholder="Please Enter Billing %"
                  formatter={(value) => (value >= 0 ? `${value}%` : '')}
                  onChange={this.onBilledChange}
                  value={information.billed}
                  disabled={this.props.isEdit}
                  max={100}
                  min={0}
                />
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="margin-top10">
              <EnhancedCol lg={8}>
                <RequiredField>Role</RequiredField>
              </EnhancedCol>
              <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
                <EnhancedSelect
                  allowClear
                  mode="default"
                  className="width-100"
                  placeholder="Please Select Role"
                  onChange={this.editRoles}
                  value={information.roles}
                  disabled={this.props.isEdit}
                >
                  {this.props.roles
                    ? this.props.roles.map((role) => (
                        <Option key={role.id} value={role.role}>
                          {`${role.role}`}
                        </Option>
                      ))
                    : null}
                </EnhancedSelect>
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="margin-top10">
              <EnhancedCol lg={8}>
                <RequiredField>Type</RequiredField>
              </EnhancedCol>
              <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
                <EnhancedSelect
                  allowClear
                  style={{ textTransform: 'capitalize' }}
                  className="width-100"
                  placeholder="Please Select Type"
                  onChange={this.onTypeChange}
                  value={this.state.information.type}
                  disabled={this.props.isEdit}
                >
                  {employeeTypesFiltered.map((type) => (
                    <Option value={type} style={{ textTransform: 'capitalize' }}>
                      {type}
                    </Option>
                  ))}
                </EnhancedSelect>
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="margin-top10">
              <EnhancedCol lg={8}>
                {' '}
                <RequiredField>Notes</RequiredField>
              </EnhancedCol>
              <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
                <TextArea
                  placeholder="Mention your comments here"
                  // autoSize
                  value={information.comment}
                  onChange={this.onCommentChange}
                />
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="margin-top10">
              <EnhancedDivider style={{ marginTop: 10 }} />
            </EnhancedRow>
            <EnhancedRow className="margin-top10">
              <EnhancedCol span={24}>
                <EnhancedButton
                  type="primary"
                  style={{ float: 'right' }}
                  onClick={this.handleModalEditResource}
                  disabled={this.shouldButtonDisable()}
                  loading={this.props.isLoading || this.props.isLoadingEmployees}
                >
                  {isEdit ? 'Edit Resource' : 'Add Resource'}
                </EnhancedButton>
                <EnhancedButton
                  style={{ marginRight: 5, float: 'right' }}
                  onClick={this.handleModalCancel}
                >
                  Cancel
                </EnhancedButton>
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedModal>
    );
  }
}
export default ProjectAddEditEmployeeModal;
