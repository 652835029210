export const MY_LEAVES_GET = 'MY_LEAVES_GET';
export const MY_LEAVES_GET_SUCCESS = 'MY_LEAVES_GET_SUCCESS';
export const MY_LEAVES_GET_FAIL = 'MY_LEAVES_GET_FAIL';
export const REQUEST_LEAVE = 'REQUEST_LEAVE';
export const REQUEST_LEAVE_SUCCESS = 'REQUEST_LEAVE_SUCCESS';
export const REQUEST_LEAVE_FAIL = 'REQUEST_LEAVE_FAIL';
export const REQUEST_EDIT_LEAVE = 'REQUEST_EDIT_LEAVE';
export const REQUEST_EDIT_LEAVE_SUCCESS = 'REQUEST_EDIT_LEAVE_SUCCESS';
export const REQUEST_EDIT_LEAVE_FAIL = 'REQUEST_EDIT_LEAVE_FAIL';
export const LEAVES_STATUSES_GET = 'LEAVES_STATUSES_GET';
export const LEAVES_STATUSES_GET_SUCCESS = 'LEAVES_STATUSES_GET_SUCCESS';
export const LEAVES_STATUSES_GET_FAIL = 'LEAVES_STATUSES_GET_FAIL';
export const GET_LEAVES_DETAILS_INIT = 'GET_LEAVES_DETAILS_INIT';
export const GET_LEAVES_DETAILS_SUCCESS = 'GET_LEAVES_DETAILS_SUCCESS';
export const GET_LEAVES_DETAILS_FAIL = 'GET_LEAVES_DETAILS_FAIL';
export const CANCEL_APPROVED_LEAVE_INIT = 'CANCEL_APPROVED_LEAVE_INIT';
export const CANCEL_APPROVED_LEAVE_SUCCESS = 'CANCEL_APPROVED_LEAVE_SUCCESS';
export const CANCEL_APPROVED_LEAVE_FAIL = 'CANCEL_APPROVED_LEAVE_FAIL';
export const GET_TOTAL_LEAVES_COUNT_INIT = 'GET_TOTAL_LEAVES_COUNT_INIT';
export const GET_TOTAL_LEAVES_COUNT_SUCCESS = 'GET_TOTAL_LEAVES_COUNT_SUCCESS';
export const GET_TOTAL_LEAVES_COUNT_FAILURE = 'GET_TOTAL_LEAVES_COUNT_FAILURE';
