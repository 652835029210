import {
  GET_ROLES_INIT,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  GET_ROLE_EMPLOYEES_INIT,
  GET_ROLE_EMPLOYEES_SUCCESS,
  GET_ROLE_EMPLOYEES_FAILURE,
  ADD_ROLE_INIT,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAILURE,
  UPDATE_ROLE_INIT,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  DELETE_ROLE_INIT,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
  GET_DDL_EMPLOYEES_INIT,
  GET_DDL_EMPLOYEES_SUCCESS,
  GET_DDL_EMPLOYEES_FAILURE,
  ASSIGN_ROLE_TO_EMPLOYEE_INIT,
  ASSIGN_ROLE_TO_EMPLOYEE_SUCCESS,
  ASSIGN_ROLE_TO_EMPLOYEE_FAILURE,
  DELETE_ROLE_FROM_EMPLOYEE_INIT,
  DELETE_ROLE_FROM_EMPLOYEE_SUCCESS,
  DELETE_ROLE_FROM_EMPLOYEE_FAILURE,
} from 'constants/RoleTypes';

const initialState = {
  roles: [],
  employees: [],
  role: {},
  isLoading: false,
};
export default function roleReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ROLES_INIT: {
      return Object.assign({}, state, {
        roles: [],
        isLoading: true,
      });
    }
    case GET_ROLES_SUCCESS: {
      return Object.assign({}, state, {
        roles: action.data,
        isLoading: false,
      });
    }
    case GET_ROLES_FAILURE: {
      return Object.assign({}, state, {
        roles: [],
        isLoading: false,
      });
    }
    case GET_ROLE_EMPLOYEES_INIT: {
      return Object.assign({}, state, {
        role: {},
        isLoading: true,
      });
    }
    case GET_ROLE_EMPLOYEES_SUCCESS: {
      return Object.assign({}, state, {
        role: action.data,
        isLoading: false,
      });
    }
    case GET_ROLE_EMPLOYEES_FAILURE: {
      return Object.assign({}, state, {
        role: {},
        isLoading: false,
      });
    }
    case ADD_ROLE_INIT: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }
    case ADD_ROLE_SUCCESS: {
      const { roles } = state;
      roles.push(action.data);
      return {
        ...state,
        roles,
        isLoading: false,
      };
    }
    case ADD_ROLE_FAILURE: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }
    case UPDATE_ROLE_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPDATE_ROLE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case UPDATE_ROLE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case DELETE_ROLE_INIT: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }
    case DELETE_ROLE_SUCCESS: {
      return {
        isLoading: false,
      };
    }
    case DELETE_ROLE_FAILURE: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }
    case DELETE_ROLE_FROM_EMPLOYEE_INIT: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }
    case DELETE_ROLE_FROM_EMPLOYEE_SUCCESS: {
      return {
        isLoading: false,
      };
    }
    case DELETE_ROLE_FROM_EMPLOYEE_FAILURE: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }
    case GET_DDL_EMPLOYEES_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_DDL_EMPLOYEES_SUCCESS: {
      return {
        ...state,
        employees: action.data,
        isLoading: false,
      };
    }
    case GET_DDL_EMPLOYEES_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ASSIGN_ROLE_TO_EMPLOYEE_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ASSIGN_ROLE_TO_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ASSIGN_ROLE_TO_EMPLOYEE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
