/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import { getResponseError } from 'utils/sourceUtils';

import {
  getLeaveTypesInit,
  getLeaveTypesSuccess,
  getLeaveTypesFailure,
} from 'store/actions/LeaveActions';
import { leaveTypeUrls } from 'configs/constants';

export const getLeaveTypes = () => {
  const url = leaveTypeUrls.GET;
  return (dispatch) => {
    dispatch(getLeaveTypesInit());
    return axios
      .get(url)
      .then((response) => {
        // API response
        if (response.status === 200) {
          const leaveTypes = response.data.data;
          // call success or failure action
          dispatch(getLeaveTypesSuccess(leaveTypes));
        } else {
          dispatch(getLeaveTypesFailure(response.message));
        }
      })
      .catch((error) => {
        EnhancedNotification.error({
          message: getResponseError(error),
        });
        dispatch(getLeaveTypesFailure(error.message));
      });
  };
};
