import { connect } from 'react-redux';
import EmployeeHistory from 'components/rating/EmployeeHistory';
import {
  getEmployeeRatingsAction,
  downloadEmployeeRatingsAction,
  getEmployeeRatingSummaryAction,
} from 'store/actions/EmployeeRatingActions';
import { getRatingDetailsAction, resetRatingDetails } from 'store/actions/PerformanceCycleActions';
import updateBreadCrumb from 'store/actions/BreadCrumbActions';

function mapStateToProps(state) {
  const { auth, employeeRatingReducer, performanceCycleReducer } = state;
  return {
    isLineManager: auth.user && auth.user.isManager,
    userId: auth.user && auth.user.id,
    isFunctionalHead: auth.user && auth.user.isFunctionalHead,
    isCrmc: auth.user && auth.user.isCRMC1,
    isPM: auth.user && auth.user.isPM,
    isArch: auth.user && auth.user.isArch,
    employeeRatings: employeeRatingReducer.employeeRatings,
    employeeRatingSummary: employeeRatingReducer.employeeRatingSummary,
    ratingDetails: performanceCycleReducer.ratingDetails,
    isLoading: employeeRatingReducer.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    downloadEmployeeRatingsAction: (params) => dispatch(downloadEmployeeRatingsAction(params)),
    getEmployeeRatingsAction: (employeeId) => dispatch(getEmployeeRatingsAction(employeeId)),
    getEmployeeRatingSummaryAction: (employeeId) =>
      dispatch(getEmployeeRatingSummaryAction(employeeId)),
    updateBreadCrumb: (key, oldVal, newVal) => dispatch(updateBreadCrumb(key, oldVal, newVal)),
    getRatingDetails: (payload) => dispatch(getRatingDetailsAction(payload)),
    resetRatingDetails: () => dispatch(resetRatingDetails()),
  };
}
const EmployeeHistoryContainer = connect(mapStateToProps, mapDispatchToProps)(EmployeeHistory);

export default EmployeeHistoryContainer;
