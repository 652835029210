import { connect } from 'react-redux';
import CreateEmployee from 'components/createEmployee';
import { getDropdownOptionsAction } from 'store/actions/EmployeeProfileActions';
import {
  getAllEmployeesActions,
  getEmployeeBusinessUnitAction,
  getEmployeeDivisionsAction,
  getEmployeeCompetenciesAction,
  getEmployeeDepartmentsAction,
} from 'store/actions/EmployeeActions';
import { getMiscellaneousDataAction } from 'store/actions/MiscellaneousActions';
import updateBreadCrumb from 'store/actions/BreadCrumbActions';
import { getAllLocationActions } from 'store/actions/LocationActions';
import { addEmployeeAction, resetAddEmployeeAction } from 'store/actions/AddEmployeeActions';

const mapStateToProps = ({
  auth,
  employeeProfileReducer,
  employeeReducer,
  locationsReducer,
  miscellaneousReducer,
  addEmployeeReducer,
}) => ({
  user: auth.user,
  isLoading: addEmployeeReducer.isLoading,
  isSuccessfull: addEmployeeReducer.isSuccessfull,
  createdEmployeeId: addEmployeeReducer.data ? addEmployeeReducer.data.id : '',
  allEmployees: employeeReducer.allEmployees,
  employees: employeeReducer.allEmployees,
  businessUnits: employeeReducer.businessUnits,
  empDivisions: employeeReducer.divisions,
  competencies: employeeReducer.competencies,
  departments: employeeReducer.departments,
  locations: locationsReducer.locations,
  employmentTypes: miscellaneousReducer.employmentTypes,
  designations: miscellaneousReducer.designations,
  dropDowns: employeeProfileReducer.dropDowns,
});

function mapDispatchToProps(dispatch) {
  return {
    getAllEmployees: () => dispatch(getAllEmployeesActions()),
    getEmployeeBusinessUnits: () => dispatch(getEmployeeBusinessUnitAction()),
    getEmployeeDivisionsAction: () => dispatch(getEmployeeDivisionsAction()),
    getEmployeeCompetenciesAction: () => dispatch(getEmployeeCompetenciesAction()),
    getEmployeeDepartments: () => dispatch(getEmployeeDepartmentsAction()),
    getAllLocations: () => dispatch(getAllLocationActions()),
    getMiscellaneousData: () => dispatch(getMiscellaneousDataAction()),
    getDropdownOptions: () => dispatch(getDropdownOptionsAction()),
    updateBreadCrumb: (key, oldVal, newVal) => dispatch(updateBreadCrumb(key, oldVal, newVal)),
    addEmployee: (req, id) => dispatch(addEmployeeAction(req, id)),
    resetAddEmployee: () => dispatch(resetAddEmployeeAction()),
  };
}

const CreateEmployeeContainer = connect(mapStateToProps, mapDispatchToProps)(CreateEmployee);

export default CreateEmployeeContainer;
