import { EnhancedRow, EnhancedCol, EnhancedButton, EnhancedCard } from 'components/shared/antd';
import React from 'react';

import {
  ROW_GUTTER_SIZE,
  FULL_GRID_SIZE,
  EDUCATION_HISTORY as stateName,
  DROP_DOWNS,
  IS_EDIT,
  IS_LOADING,
} from 'configs/employeeProfileConstants';
import {
  createEducationHistory,
  // createSubHeading,
  noData,
} from 'utils/FieldGenerateUtils';

import { generateErrorsArrayFromJSON, generateErrorObject } from 'utils/validationUtils';

import Permissions from 'constants/AccessPermissions';
import { hasPermission } from 'utils/AccessUtils';
import { getValueForKeyinJSON } from 'utils/arrayUtils';
import {
  DELETE_CONFIRM_PROMPT_MESSAGE,
  // DELETE_CONFIRM_PROMPT_WARNING_MESSAGE,
} from 'configs/messageConstants';
import AddButton from 'components/shared/employeeProfile/AddButton';
import EditButton from 'components/shared/employeeProfile/EditButton';
import confirmModal from '../../shared/ConfirmModal';

class EducationHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      [stateName]: this.props[stateName] || [],
      errors: [],
      dropDowns: {},
      disableSaveButton: true,
      disableCreateButton: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps[stateName] !== this.props[stateName]) {
      const newProps = nextProps[stateName];

      // Expecting Array of Objects
      // Make clean error object with exclusions
      const errors = generateErrorsArrayFromJSON(newProps, [IS_EDIT, IS_LOADING]);

      this.setState({
        [stateName]: newProps,
        errors,
      });
    }
  }

  handleEdit = (index) => {
    const newData = [...this.state[stateName]];
    newData[index] = Object.assign({}, newData[index], { isEdit: true });
    this.setState({
      [stateName]: newData,
      disableCreateButton: true,
    });
  };

  handleCancel = (index) => {
    const currentData = [...this.state[stateName]];

    // If the object already existed and has an id,
    // then replace data from props on cancel
    if (currentData[index].id) {
      const propsDataAtIndex = this.props[stateName][index];
      // Replace specific object on which cancel was clicked with props version
      currentData[index] = Object.assign({}, propsDataAtIndex, {
        isEdit: false,
      });
      this.setState({
        [stateName]: currentData,
        disableCreateButton: false,
      });
    } else {
      // else, replace the state from props, remove it from state.
      const propsData = [...this.props[stateName]];
      const errorsData = generateErrorsArrayFromJSON(propsData, [IS_EDIT, IS_LOADING]);
      this.setState({ [stateName]: propsData, errors: errorsData, disableCreateButton: false });
    }
  };

  handleChange = (value, key, error) => {
    // Umar : 07/11/2020 : Made generic to ease reusability
    const newData = [...this.state[stateName]];

    // split key and index and assign value to array index key (i.e: familyInfo[0].fullName = 'Name')
    const index = key.split('-')[1];
    const field = key.split('-')[0];

    newData[index] = Object.assign({}, { ...newData[index] }, { [field]: value });

    // Update specific error object and field
    const { errors } = this.state;
    errors[index] = Object.assign({}, { ...errors[index] }, { [field]: error });

    // Check to see if there is any error
    // if any, disable save button
    // if none, enable save button
    const allValid = Object.values(errors[index]).every((valueError) => valueError === false);

    this.setState({
      [stateName]: newData,
      errors,
      disableSaveButton: !allValid,
    });
  };

  handleSave = (index) => {
    if (!this.state.disableSaveButton) {
      const currentData = this.state[stateName];
      currentData[index] = {
        ...currentData[index],
        isEdit: false,
        isLoading: true,
      };
      this.setState({ [stateName]: currentData, disableCreateButton: false });

      const { employeeId } = this.props;

      if (currentData[index].id) {
        this.props.updateEmployeeEducationHistory(
          employeeId,
          currentData[index].id,
          currentData[index],
          index,
        );
      } else {
        this.props.createEmployeeEducationHistory(employeeId, currentData[index], index);
      }
    }
  };

  handleCreate = () => {
    const newEducationHistoryObject = {
      id: null,
      dateFrom: '',
      dateTo: '',
      major: '',
      degree: '',
      qualificationTypeId: '',
      institute: '',
      score: '',
      city: '',
      country: '',
      isEdit: true,
      isLoading: false,
    };
    let newEducationHistoryErrorObject = generateErrorObject(newEducationHistoryObject, [
      IS_EDIT,
      IS_LOADING,
    ]);

    // Mutate error object according to required conditions for appropiate validation
    newEducationHistoryErrorObject = {
      ...newEducationHistoryErrorObject,
      institute: true,
      qualificationTypeId: true,
      major: true,
      degree: true,
      dateTo: true,
      dateFrom: true,
      city: true,
      country: true,
    };

    const updatedArray = [newEducationHistoryObject, ...this.state[stateName]];
    const updatedErrorsArray = [newEducationHistoryErrorObject, ...this.state.errors];

    this.setState({
      [stateName]: updatedArray,
      errors: updatedErrorsArray,
      disableSaveButton: true,
      disableCreateButton: true,
    });
  };

  handleDelete = (itemId, index) => {
    confirmModal(DELETE_CONFIRM_PROMPT_MESSAGE, () => {
      const { employeeId } = this.props;
      this.props.deleteEmployeeEducationHistory(employeeId, itemId, index);
      const currentData = this.state[stateName];
      currentData[index].isLoading = true;
      this.setState({ [stateName]: currentData });
    });
  };

  render() {
    const { userPermissions } = this.props;
    const data = this.state[stateName];
    const { disableSaveButton, disableCreateButton } = this.state;
    const dropDowns = this.props[DROP_DOWNS];
    const canCreate = hasPermission(userPermissions, [
      Permissions.Employee.Profile.EducationHistory.Create,
    ]);
    const canEdit = hasPermission(userPermissions, [
      Permissions.Employee.Profile.EducationHistory.Update,
    ]);
    const canDelete = hasPermission(userPermissions, [
      Permissions.Employee.Profile.EducationHistory.Delete,
    ]);

    return (
      <div className="mt-20 pos-rel">
        <div className="flex justify-flex-end">
          {canCreate && (
            <AddButton
              clickHandler={this.handleCreate}
              buttonText="Add New Degree"
              disabled={disableCreateButton}
            />
          )}
        </div>
        {/* {createSubHeading('Education History')} */}
        {data.length ? (
          <EnhancedRow gutter={ROW_GUTTER_SIZE}>
            {data.map((val, index) => {
              const qualificationName = getValueForKeyinJSON(
                dropDowns.qualifications,
                val.qualificationTypeId,
              );
              return (
                <EnhancedCol span={FULL_GRID_SIZE}>
                  <EnhancedCard className="mb-24 br-5" loading={val.isLoading}>
                    <div className="flex" style={{ justifyContent: 'space-between' }}>
                      <span className="card-title mb-24">{qualificationName}</span>
                      {(canDelete || canEdit) && (
                        <div className="flex justify-flex-end">
                          {canDelete && val.id && !val.isEdit && (
                            <EnhancedButton
                              className="mr-10 btn-delete"
                              type="danger"
                              onClick={() => this.handleDelete(val.id, index)}
                            >
                              Delete
                            </EnhancedButton>
                          )}
                          {canEdit && !val.isEdit && (
                            <EditButton
                              clickHandler={() => this.handleEdit(index)}
                              buttonText="Edit"
                            />
                          )}
                          {val.isEdit && (
                            <EnhancedButton
                              type="primary"
                              className="mr-10"
                              onClick={() => this.handleSave(index)}
                              disabled={disableSaveButton}
                            >
                              Save
                            </EnhancedButton>
                          )}
                          {val.isEdit && (
                            <EnhancedButton onClick={() => this.handleCancel(index)}>
                              Cancel
                            </EnhancedButton>
                          )}
                        </div>
                      )}
                    </div>
                    {createEducationHistory(
                      val,
                      index,
                      dropDowns.qualifications,
                      this.handleChange,
                    )}
                  </EnhancedCard>
                </EnhancedCol>
              );
            })}
          </EnhancedRow>
        ) : (
          noData()
        )}
      </div>
    );
  }
}

export default EducationHistory;
