import { roundOffRating } from 'utils/conversionUtils';

const ratingRenderer = (rating) => roundOffRating(rating);

export const allEmployeesMeta = [
  {
    key: 'employeeId',
    label: 'ID',
  },
  {
    key: 'name',
    label: 'Employee',
  },
  {
    key: 'reviewManager',
    label: 'Review Manager',
  },
  {
    label: 'Manager Rating',
    key: 'manager.rating',
    renderer: ratingRenderer,
  },
  {
    label: 'Manager Rating Status',
    key: 'manager.status',
  },
  {
    label: 'Self Rating',
    key: 'self.rating',
    renderer: ratingRenderer,
  },
  {
    label: 'Self Rating Status',
    key: 'self.status',
  },
  {
    key: 'lineManagerApprover.name',
    label: 'Approver Name (LM+1)',
  },
  {
    key: 'department',
    label: 'Department',
  },
  {
    key: 'competency',
    label: 'Competency',
  },
  {
    key: 'businessUnit',
    label: 'Business Unit',
  },
  {
    key: 'division',
    label: 'Division',
  },
];

const evaluationStatus = {
  PENDING: 'Pending',
  PENDING_APPROVAL: 'PendingApproval',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  COMPLETED: 'Completed',
  FINAL: 'Final',
};

export const PMDashboardConstants = {
  HIDDEN_COLUMNS_FROM_DRAG_DROP: ['self', 'manager', 'performanceCycleId', 'promotionInfo'],
};

export default evaluationStatus;
