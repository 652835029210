import { EnhancedRow, EnhancedCol, EnhancedCard, EnhancedForm } from 'components/shared/antd';
import React, { useEffect, useState } from 'react';
import { ROW_GUTTER_SIZE, FULL_GRID_SIZE, TEXTAREA, TEXT } from 'configs/employeeProfileConstants';
import { createFields } from 'utils/FieldGenerateUtils';
import AsteriskLabel from '../../shared/performanceEvaluationForm/asteriskHighlighted';

const EndNoteStep = (props) => {
  const { viewer, isEditable, data, updateHandler, selfEvaluation, showSelfEvaluationComments } =
    props;
  const [comment, setComment] = useState('');

  useEffect(() => {
    setComment(data);
  }, [data]);
  const handleSave = () => {};
  const handleChange = (value) => {
    setComment(value);
    updateHandler('overallComment', value);
  };

  const getFieldsConfig = () => {
    const fields = [
      {
        key: `comment`,
        label: <AsteriskLabel asterisk={`${viewer} Comments`} />,
        value: comment,
        type: TEXTAREA,
        noCapitalize: true,
        className: 'break-spaces',
      },
    ];

    if (selfEvaluation && showSelfEvaluationComments) {
      fields.push({
        key: `comment`,
        label: `Employee Comments`,
        value: selfEvaluation.overallComment,
        type: TEXT,
        noCapitalize: true,
        className: 'break-spaces',
      });
    }

    return fields;
  };

  return (
    <div>
      <EnhancedCard>
        <React.Fragment>
          <EnhancedRow key={data.email} gutter={ROW_GUTTER_SIZE}>
            <EnhancedCol span={FULL_GRID_SIZE}>
              <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
                loading={false}
                bordered={false}
                className="card-pd-0"
              >
                <div>
                  <EnhancedForm onSubmit={handleSave}>
                    {createFields(getFieldsConfig(), 1, isEditable, [], handleChange)}
                  </EnhancedForm>
                </div>
              </EnhancedCard>
            </EnhancedCol>
          </EnhancedRow>
        </React.Fragment>
      </EnhancedCard>
    </div>
  );
};
export default EndNoteStep;
