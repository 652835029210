import axios from 'axios';
import {
  getEmployeeSearchInit,
  getEmployeeSearchSuccesss,
  getEmployeeSearchFailure,
} from 'store/actions/EmployeeSearchActions';

import { employeeSearchUrls } from 'configs/constants';
import { normalizeGetEmployeesListingResponse } from 'normalizers/employeeNormalizers';

const getEmployeeSearch = (params) => {
  let filter = '';
  if (params.name) {
    filter += `?name=${params.name}`;
  }
  if (params.page) {
    if (params.name) filter += `&page=${params.page}`;
    else filter += `?page=${params.page}`;
  }
  if (params.pageSize) {
    if (params.page) filter += `&limit=${params.pageSize}`;
  }

  const url = employeeSearchUrls.GET.concat(filter);
  return (dispatch) => {
    dispatch(getEmployeeSearchInit());
    axios
      .get(url)
      .then((response) => {
        if (response.data.success) {
          // parse response through normalizer
          const { data, pageInfo } = response.data;
          const EmployeeSearchResponse = normalizeGetEmployeesListingResponse(data);
          // call success or failure action
          dispatch(getEmployeeSearchSuccesss(EmployeeSearchResponse, pageInfo));
        } else {
          dispatch(getEmployeeSearchFailure(response.data.code));
        }
      })
      .catch((error) => {
        dispatch(getEmployeeSearchFailure(error));
      });
  };
};
export default getEmployeeSearch;
