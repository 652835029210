import React from 'react';
import { Divider } from 'antd';
import PropTypes from 'prop-types';

const EnhancedDivider = (props) => {
  const { type, children, className, style, orientation } = props;

  return (
    <Divider style={style} type={type} className={className} orientation={orientation}>
      {children}
    </Divider>
  );
};

EnhancedDivider.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  orientation: PropTypes.string,
};

EnhancedDivider.defaultProps = {
  className: '',
  style: {},
  type: 'horizontal',
  orientation: 'center',
};

export default EnhancedDivider;
