import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedDivider,
  EnhancedAvatar,
  EnhancedList,
  EnhancedTooltip,
  EnhancedSelect,
  EnhancedListItem,
} from 'components/shared/antd';
import React, { Fragment } from 'react';
import filled from 'images/cupcake_selected.svg';
import unfilled from 'images/cupcake_unselected.svg';
import './ListPlaceholder.css';

const { Meta } = EnhancedListItem;
const { Option } = EnhancedSelect;

class UserPlaceholder extends React.Component {
  wishBirthday = (e) => {
    const employeeId = e.id;
    const data = {
      id: this.props.authData.id,
      name: this.props.authData.fullName,
    };
    this.props.wishEmployeeBirthday(employeeId, data);
  };

  handleOnClick = (item) => {
    if (!item.disable) {
      this.wishBirthday(item);
    }
  };

  arrangeData = (employees, employeeID) => {
    const currentUser = [];
    employees.map((employee, index) => {
      const emp = { ...employee };
      if (emp.id === employeeID) {
        emp.title = `${emp.title} ( You )`;
        currentUser.push(emp);
        employees.splice(index, 1);
      }
      return false;
    });
    const newArray1 = currentUser.concat(employees);
    return newArray1;
  };

  makeList = (item) => item.wishList.map((employee) => <p key={employee.id}>{employee.name}</p>);

  render() {
    const { title, filterOptions, data, authData } = this.props;
    const newData = this.arrangeData(this.props.data, this.props.authData.id);
    return (
      <Fragment>
        <EnhancedRow className="list-header">
          <EnhancedCol lg={{ span: 14 }} xs={{ span: 15 }}>
            <div className="list-title">{title}</div>
          </EnhancedCol>
          {filterOptions && (
            <EnhancedCol lg={{ span: 7, offset: 3 }} xs={{ span: 6, offset: 2 }}>
              <EnhancedSelect
                defaultValue="All"
                style={{ width: 95 }}
                onChange={this.props.handleChange}
              >
                <Option value="All">All</Option>
                <Option value="Karachi">Karachi</Option>
                <Option value="Lahore">Lahore</Option>
              </EnhancedSelect>
            </EnhancedCol>
          )}
        </EnhancedRow>
        <EnhancedRow className="list-container">
          <EnhancedList
            className="list"
            dataSource={newData}
            renderItem={(item, listIndex) => (
              <div className="list-element" key={listIndex}>
                <EnhancedListItem>
                  <Meta
                    avatar={
                      <EnhancedAvatar
                        className="avatar-test"
                        src={item.imageUrl ? item.imageUrl : null}
                        icon={item.imageUrl ? !item.imageUrl : 'user'}
                        size="large"
                      />
                    }
                    title={<p className="list-element-title">{item.title}</p>}
                    description={item.description}
                  />
                  {authData.id === item.id ? (
                    <EnhancedTooltip placement="bottom" title={() => this.makeList(item)}>
                      <EnhancedButton className="wish-button ant-tooltip-open">
                        {item.wishList && item.wishList.length}{' '}
                        {item.wishList && item.wishList.length === 1 ? 'wish' : 'wishes'}
                      </EnhancedButton>
                    </EnhancedTooltip>
                  ) : (
                    <EnhancedTooltip
                      placement="top"
                      className="ant-tooltip-inner-copy"
                      title="Send a Birthday Wish"
                    >
                      <EnhancedButton
                        onClick={() => this.handleOnClick(item)}
                        className={`img-wish-button ${
                          !item.disable && !item.isWish ? 'pointer-enabled' : 'pointer-disabled'
                        }`}
                      >
                        <img
                          src={`${item.isWish ? filled : unfilled}`}
                          value={item.id}
                          alt="filled_cake"
                          style={{ width: '22px' }}
                        />
                      </EnhancedButton>
                    </EnhancedTooltip>
                  )}
                </EnhancedListItem>
                {listIndex < data.length - 1 && (
                  <EnhancedDivider className="remove-divider-margin" />
                )}
              </div>
            )}
          />
        </EnhancedRow>
      </Fragment>
    );
  }
}

export default UserPlaceholder;
