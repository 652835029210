/* eslint-disable */
import {
  EnhancedRow,
  EnhancedButton,
  EnhancedDivider,
  EnhancedInput,
  EnhancedRate,
} from 'components/shared/antd';
import React, { Component } from 'react';
import moment from 'moment';
import './pc.css';
import { starRatingDescription, minLengthRequiredForComments } from '../../configs/constants';
import AttributeTitle from './shared/AttributeTitle';
import { EnhancedModal } from 'components/shared/antd';

const { TextArea } = EnhancedInput;
const { confirm } = EnhancedModal;

export default class RatingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ratingDetails: {},
      currentStep: 0,
      viewOnly: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.ratingDetails !== this.props.ratingDetails) {
      const stateValue = this.constructStateValue(nextProps.ratingDetails);
      const viewOnly = this.checkViewOnly(nextProps.ratingDetails);
      this.setState({
        ratingDetails: stateValue,
        viewOnly,
      });
    }
  }

  componentWillUnmount() {
    const { resetRatingDetails } = this.props;
    resetRatingDetails();
  }

  saveHandler = () => {
    const { addProjectRatingAction, onModalClose } = this.props;
    const payload = this.normalizePayload(false);

    addProjectRatingAction(payload);
    onModalClose(false);
  };

  submitHandler = () => {
    const { addProjectRatingAction, onModalClose } = this.props;
    const payload = this.normalizePayload(true);

    addProjectRatingAction(payload);
    onModalClose(false);
  };

  shouldEnableSubmitButton = () => {
    /*
     * if a rating is rated below 3, then its comment must be present with length > 20
     * if a rating is rated >= 3, then comment is not required
     * if all fields satisfy above criteria then only submit button will be enabled
     */
    const { ratingDetails } = this.state;
    let isEnabled = true;

    for (const ratingId in ratingDetails) {
      for (const ratingAttributeId in ratingDetails[ratingId].ratingAttributes) {
        const currentObj = ratingDetails[ratingId].ratingAttributes[ratingAttributeId];

        const condition =
          (currentObj.rating < 3 &&
            currentObj.comment.value &&
            currentObj.comment.value.length >= minLengthRequiredForComments) ||
          currentObj.rating >= 3;
        isEnabled = isEnabled && condition;
      }
    }

    return isEnabled;
  };

  shouldEnableSaveButton = () => {
    /*
     * if any rating or comment field is filled, then this button will be enabled
     */
    const { ratingDetails } = this.state;
    let isEnabled = false;

    for (const ratingId in ratingDetails) {
      for (const ratingAttributeId in ratingDetails[ratingId].ratingAttributes) {
        const currentObj = ratingDetails[ratingId].ratingAttributes[ratingAttributeId];

        if (currentObj.rating || currentObj.comment.value) {
          isEnabled = true;
          break;
        }
      }
    }

    return isEnabled;
  };

  normalizePayload = (freeze) => {
    const { ratingDetails } = this.state;
    const { cycleId, projectId } = this.props;

    const payload = {
      cycleId,
      ratings: [],
      projectId,
    };
    for (const ratingId in ratingDetails) {
      const ratingsObj = {
        id: ratingId,
        freeze: freeze ? 1 : 0,
        ratingAttributes: [],
      };

      for (const ratingAttributeId in ratingDetails[ratingId].ratingAttributes) {
        const currentObj = ratingDetails[ratingId].ratingAttributes[ratingAttributeId];

        ratingsObj.ratingAttributes.push({
          id: ratingAttributeId,
          rating: currentObj.rating,
          comment: currentObj.comment.value,
        });
      }

      payload.ratings.push(ratingsObj);
    }

    return payload;
  };

  handleRatingChange = (ratingId, ratingAttributeId, value) => {
    const { ratingDetails } = this.state;
    ratingDetails[ratingId].ratingAttributes[ratingAttributeId].rating = value;

    this.setState({
      ratingDetails,
    });
  };

  handleCommentsChange = (ratingId, ratingAttributeId, value) => {
    const { ratingDetails } = this.state;
    ratingDetails[ratingId].ratingAttributes[ratingAttributeId].comment.value = value;

    this.setState({
      ratingDetails,
    });
  };

  onCommentFocus = (ratingId, ratingAttributeId) => {
    const { ratingDetails } = this.state;
    ratingDetails[ratingId].ratingAttributes[ratingAttributeId].comment.dirty = true;
    ratingDetails[ratingId].ratingAttributes[ratingAttributeId].comment.focused = true;

    this.setState({
      ratingDetails,
    });
  };

  onCommentBlur = (ratingId, ratingAttributeId) => {
    const { ratingDetails } = this.state;
    ratingDetails[ratingId].ratingAttributes[ratingAttributeId].comment.dirty = true;
    ratingDetails[ratingId].ratingAttributes[ratingAttributeId].comment.focused = false;

    this.setState({
      ratingDetails,
    });
  };

  handleAfterClose = () => {
    this.setState({
      currentStep: 0,
      // viewOnly: false,
    });
  };

  showConfirm = () => {
    confirm({
      title: 'Are you sure you want to submit?',
      content: 'Once review is submitted you will not be able to change',
      onOk: () => {
        this.submitHandler();
      },
      onCancel() {},
    });
  };

  handleModalCancel = () => {
    const { onModalClose } = this.props;
    onModalClose(false);
  };

  handleNextClick = () => {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep + 1,
    }));
  };

  handlePreviousClick = () => {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep - 1,
    }));
  };

  getNextPrevWidget = () => {
    let nextButton = null;
    let prevButton = null;
    let response = null;

    const {
      ratingDetails: { data },
    } = this.props;
    const { currentStep } = this.state;

    let totalSteps = 0;

    if (data.ratings) {
      totalSteps = data.ratings.length;
    }

    // currntStep is started from 0
    if (totalSteps > 1 && currentStep < totalSteps - 1) {
      nextButton = (
        <EnhancedButton
          type="primary"
          style={{ float: 'right', marginLeft: '8px' }}
          onClick={this.handleNextClick}
        >
          Next
        </EnhancedButton>
      );
    }

    if (currentStep > 0) {
      prevButton = (
        <EnhancedButton
          type="primary"
          style={{ float: 'right' }}
          onClick={this.handlePreviousClick}
        >
          Previous
        </EnhancedButton>
      );
    }

    if (nextButton || prevButton) {
      response = (
        <EnhancedRow style={{ padding: '24px' }} className="margin-top10">
          {nextButton}
          {prevButton}
        </EnhancedRow>
      );
    }
    return response;
  };

  getAttributeRatingField = (ratingId, ratingAttributeId, freeze) => {
    const { ratingDetails, viewOnly } = this.state;
    let ratingValue = null;

    if (ratingDetails[ratingId]) {
      ratingValue = ratingDetails[ratingId].ratingAttributes[ratingAttributeId].rating;
    }
    return (
      <React.Fragment>
        <EnhancedRate
          tooltips={starRatingDescription}
          onChange={(val) => this.handleRatingChange(ratingId, ratingAttributeId, val)}
          value={ratingValue}
          disabled={freeze || viewOnly}
        />
        {ratingValue ? (
          <div className="ant-rate-text">{starRatingDescription[ratingValue - 1]}</div>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  };

  getAttributeCommentField = (ratingId, ratingAttributeId, freeze) => {
    let response = '';
    const { ratingDetails, viewOnly } = this.state;

    let targetedObject = {};
    let rating, value, dirty, focused, comment;

    if (ratingDetails[ratingId]) {
      targetedObject = ratingDetails[ratingId].ratingAttributes[ratingAttributeId];
      ({ rating, comment } = targetedObject);
      ({ value, dirty, focused } = comment);
    }

    if (freeze || viewOnly) {
      response = <p>{value}</p>;
    } else {
      response = (
        <React.Fragment>
          <TextArea
            attr="quality"
            placeholder={`Enter at least ${minLengthRequiredForComments} characters`}
            onChange={(val) =>
              this.handleCommentsChange(ratingId, ratingAttributeId, val.target.value)
            }
            value={value}
            onFocus={() => this.onCommentFocus(ratingId, ratingAttributeId)}
            onBlur={() => this.onCommentBlur(ratingId, ratingAttributeId)}
          />
          {dirty &&
            !focused &&
            rating < 3 &&
            value &&
            value.length < minLengthRequiredForComments && (
              <p style={{ textAlign: 'right', color: 'red' }}>
                Minimum {minLengthRequiredForComments} characters required
              </p>
            )}
          {dirty &&
            focused &&
            rating < 3 &&
            value &&
            value.trim().length < minLengthRequiredForComments && (
              <p style={{ textAlign: 'right' }}>
                {value.trim().length}/{minLengthRequiredForComments}
              </p>
            )}
        </React.Fragment>
      );
    }
    return response;
  };

  getStepTitle = (rating) => {
    const { rateeName, rateeRole } = rating;
    return (
      <React.Fragment>
        <strong className="text-capitalize">{`${rateeRole} - ${rateeName}`}</strong>
        <EnhancedDivider style={{ margin: '7px 0px' }} />
      </React.Fragment>
    );
  };

  getStep = (rating, index) => {
    const { freeze, ratingAttributes, ratingId } = rating;
    const { currentStep } = this.state;
    const stepTitle = this.getStepTitle(rating);

    const attributes = ratingAttributes.map((ratingAttribute) => (
      <div>
        <AttributeTitle
          title={ratingAttribute.attributeName}
          description={ratingAttribute.attributeDescription}
        />

        <EnhancedDivider style={{ margin: '7px 0px' }} />

        {this.getAttributeRatingField(ratingId, ratingAttribute.ratingAttributeId, freeze)}
        {this.getAttributeCommentField(ratingId, ratingAttribute.ratingAttributeId, freeze)}
      </div>
    ));

    return (
      <div className={index !== currentStep ? 'd-none' : ''}>
        {stepTitle}
        {attributes}
      </div>
    );
  };

  constructStateValue = (ratingDetails) => {
    const response = {};

    if (ratingDetails.data.ratings && ratingDetails.data.ratings.length > 0) {
      ratingDetails.data.ratings.forEach((rating) => {
        const { ratingId } = rating;
        if (!response[ratingId]) {
          response[ratingId] = {
            ratingAttributes: {},
          };
        }

        rating.ratingAttributes.forEach((ratingAttribute) => {
          const { ratingAttributeId } = ratingAttribute;
          if (!response[ratingId].ratingAttributes[ratingAttributeId]) {
            response[ratingId].ratingAttributes[ratingAttributeId] = {};
          }

          response[ratingId].ratingAttributes[ratingAttributeId].rating = ratingAttribute.rating;
          response[ratingId].ratingAttributes[ratingAttributeId].comment = {
            value: ratingAttribute.comment,
            dirty: false,
            focused: false,
          };
        });
      });
    }

    return response;
  };

  checkViewOnly = (ratingDetails) => {
    // completeStatus means cycle is marked as complete
    const {
      data: { dueDate, completeStatus, ratings },
    } = ratingDetails;

    let isFreezed = false;

    if (ratings) {
      isFreezed = ratings.reduce((accumulator, currentValue) => {
        return accumulator && currentValue.freeze;
      }, true);
    }

    /*
     * So, we need to keep everything to viewonly if any of the below conditions are true
     * 1. cycle is marked as completed i.e its not active anymore
     * 2. performance cycle due date is passed
     * 3. The rater has submitted the feedback
     */
    return completeStatus || moment() > moment(dueDate) || isFreezed;
  };

  render() {
    const {
      ratingDetails: { data },
      visible,
      userId,
    } = this.props;
    const { viewOnly } = this.state;
    return (
      <EnhancedModal
        onCancel={this.handleModalCancel}
        okText="Save"
        visible={visible}
        userId={userId}
        afterClose={this.handleAfterClose}
        okButtonProps={{ disabled: true }}
        maskClosable={false}
        footer={null}
        style={{
          backgroundColor: 'white',
          maxHeight: '90%',
          padding: '0',
        }}
        bodyStyle={{ padding: '0', maxHeight: '90%' }}
      >
        <React.Fragment>
          <div style={{ padding: '24px', height: '500px', overflowY: 'scroll' }}>
            {this.getNextPrevWidget()}
            {data.ratings
              ? data.ratings.map((rating, index) => {
                  return this.getStep(rating, index);
                })
              : null}
          </div>

          {!viewOnly && (
            <EnhancedRow style={{ padding: '24px' }} className="margin-top10">
              <span>
                {' '}
                <EnhancedButton
                  type="primary"
                  style={{ float: 'right' }}
                  onClick={this.showConfirm}
                  disabled={!this.shouldEnableSubmitButton()}
                >
                  Submit
                </EnhancedButton>
                <EnhancedButton
                  type="primary"
                  style={{ marginRight: 5, float: 'right' }}
                  onClick={this.saveHandler}
                  disabled={!this.shouldEnableSaveButton()}
                >
                  Save
                </EnhancedButton>
                <EnhancedButton
                  style={{ marginRight: 5, float: 'right' }}
                  onClick={this.handleModalCancel}
                >
                  Cancel
                </EnhancedButton>
              </span>
            </EnhancedRow>
          )}
        </React.Fragment>
      </EnhancedModal>
    );
  }
}
