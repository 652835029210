import {
  EnhancedCol,
  EnhancedButton,
  EnhancedIcon,
  EnhancedCard,
  EnhancedTabs,
  EnhancedRadio,
} from 'components/shared/antd';
import React, { Component } from 'react';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import moment from 'moment';
import _ from 'underscore';
import ClaimDetailsContainer from 'containers/expense/ClaimDetailsContainer';
import AddExpenseClaimContainer from 'containers/expense/AddExpenseClaimContainer';
import { claimStatus, dateFormat } from 'configs/constants';
import './AddExpenseClaim.css';
import StatusTag from './StatusTag';

const { TabPane } = EnhancedTabs;
const RadioButton = EnhancedRadio.Button;
const RadioGroup = EnhancedRadio.Group;

const FILTER_STATUS = {
  ALL: 'All',
  PENDING: claimStatus.PENDING,
  APPROVED: claimStatus.APPROVED,
  DECLINED: claimStatus.DECLINED,
};

const TAB_INDEX = {
  MY_CLAIM: 1,
  TEAM_CLAIM: 2,
  FINANCE_CLAIM: 3,
  HR_CLAIM: 4,
};

class ExpenseClaimList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewClaimModal: false,

      claimDetailsModal: false,
      selectedClaim: {},
      selectedTabIndex: 1,
      filterStatus: FILTER_STATUS.PENDING,
      claimList: [],
    };
  }

  componentDidMount() {
    const params = {
      statusId: FILTER_STATUS.PENDING,
    };
    this.props.getClaims(params);
    this.props.getClaimTypes();
    this.props.getClaimProjectList();
  }

  componentWillReceiveProps(nextProps) {
    let { selectedTabIndex } = this.state;
    selectedTabIndex = Number(selectedTabIndex);
    if (nextProps.approvalStatusUpdated === true) {
      this.setState(
        {
          claimDetailsModal: false,
        },
        () => {
          this.props.resetApprovalStatus();
        },
      );
      const params = {
        statusId: FILTER_STATUS.PENDING,
      };
      if (selectedTabIndex === TAB_INDEX.TEAM_CLAIM) {
        this.props.getTeamClaims(params);
      } else if (selectedTabIndex === TAB_INDEX.FINANCE_CLAIM) {
        this.props.getClaimsforFinance(params);
      } else if (selectedTabIndex === TAB_INDEX.HR_CLAIM) {
        this.props.getClaimsforHR(params);
      } else {
        // my claim
      }
    }
    this.setState({
      claimList: nextProps.claimList,
    });
  }

  onTabChange = (tabIndex) => {
    const selectedTabIndex = Number(tabIndex);
    this.setState(
      {
        claimList: [],
        selectedTabIndex: tabIndex,
        filterStatus: FILTER_STATUS.PENDING,
      },
      () => {
        const params = {
          statusId: FILTER_STATUS.PENDING,
        };
        if (selectedTabIndex === TAB_INDEX.MY_CLAIM) {
          this.props.getClaims(params);
        }
        if (selectedTabIndex === TAB_INDEX.TEAM_CLAIM) {
          this.props.getTeamClaims(params);
        }
        if (selectedTabIndex === TAB_INDEX.FINANCE_CLAIM) {
          this.props.getClaimsforFinance(params);
        }
        if (selectedTabIndex === TAB_INDEX.HR_CLAIM) {
          this.props.getClaimsforHR(params);
        }
      },
    );
  };

  // claimStatus = (data, row) => {
  //   const { status } = row;
  //   let makeAllStatusRejected = false
  //   const statusUpdateForTags = status.map(v => {

  //     if (!makeAllStatusRejected) {
  //       if (v.statusId === 4) {
  //         v.statusIdUpdated = 4;
  //         makeAllStatusRejected = true
  //       }
  //       else {
  //         v.statusIdUpdated = v.statusId;
  //       }
  //     }
  //     // Make all other record rejected
  //     if (makeAllStatusRejected) {
  //       v.statusIdUpdated = 4;
  //     }
  //     return v
  //   });

  //   console.log('statusUpdateForTags', statusUpdateForTags)
  //   const statuses = statusUpdateForTags.map((v, i) => {
  //     return (
  //       // <div>
  //       <Tooltip placement="topLeft" title={claimIdToStatus[v.statusIdUpdated]}>
  //         <EnhancedTag
  //           // style={{ ...tagStyle }}
  //           color={this.statusTagColor(v)}
  //           key={i}
  //         >
  //           {claimApproveOrder[v.approveOrder]}
  //           {/* {claimIdToStatus[v.statusId]} */}
  //         </EnhancedTag>
  //       </Tooltip>
  //       // </div>
  //     )
  //   })
  //   return statuses;
  // }

  getColumns = () => {
    // let { selectedTabIndex } = this.state;
    let variableColumn = [];
    // selectedTabIndex = Number(selectedTabIndex);

    // if (selectedTabIndex === 1) {
    //   // My claims
    //   variableColumn = [
    //     // {
    //     //   title: 'Actions',
    //     //   width: '100px',
    //     //   render: (a) => this.claimDetailsButton(a),
    //     // },
    //   ];
    // }
    // if (selectedTabIndex === 2) {
    //   // Team claim
    //   variableColumn = [

    //     {
    //       title: 'Employee Name',
    //       align: 'center',
    //       // width: '100px',
    //       render: this.employeeName,
    //       sortId: 7,
    //     },
    //     {
    //       title: 'Line Manger',
    //       align: 'center',
    //       // width: '100px',
    //       render: this.renderLineManger,
    //       sortId: 9,
    //     },
    //   ];
    // }
    // if (selectedTabIndex === 4) {
    //   // Company claim
    //   variableColumn = [
    //     {
    //       title: 'Employee Name',
    //       align: 'center',
    //       // width: '100px',
    //       render: this.employeeName,
    //       sortId: 7,
    //     },
    //     {
    //       title: 'Line Manger',
    //       align: 'center',
    //       // width: '100px',
    //       render: this.renderLineManger,
    //       sortId: 9,
    //     },
    //   ];
    // }
    const commonColumns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        align: 'left',
        // width: '25px',
        sortId: 1,
        // ...this.getColumnSearchProps('id'),
        // sorter: (a, b) => a.id.localeCompare(b.id),
        // sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
        // filterDropdownVisible: isFilterVisible && filterColumn === 'id',
        filterConfig: {
          type: 'search',
          key: 'id',
        },
      },
      {
        title: 'Submission Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        align: 'left',
        // width: '60px',
        render: this.renderDate,
        sortId: 3,
      },
      (variableColumn = [
        {
          title: 'Employee Name',
          align: 'center',
          dataIndex: 'employee',
          // width: '100px',
          render: this.employeeName,
          sortId: 7,
          filterConfig: {
            type: 'search',
            key: 'employee.fullName',
          },
        },
        {
          title: 'Line Manger',
          align: 'center',
          dataIndex: 'employee.manager',
          // width: '100px',
          render: this.renderLineManger,
          sortId: 9,
          filterConfig: {
            type: 'search',
            key: 'employee.manager.fullName',
          },
        },
      ]),
      // {
      //   title: 'Const center',
      //   dataIndex: 'projectId',
      //   key: 'projectId',
      //   width: '60px',
      //   sortId: 5
      // },
      {
        title: 'Total Amount',
        dataIndex: 'amount',
        key: 'amount',
        align: 'right',
        className: 'amount-align-right',
        // width: '50px',
        render: this.calcTotalAmount,
        sortId: 13,
      },
      {
        title: 'Approved Amount',
        dataIndex: 'amountReleased',
        key: 'amountReleased',
        align: 'right',
        className: 'amount-align-right',
        // width: '50px',
        sortId: 15,
        sorter: (a, b) => a.amountReleased - b.amountReleased,
        render: this.releasedAmount,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'left',
        // width: '100px',
        render: this.claimStatus,
        sortId: 11,
      },
      {
        title: 'Ageing',
        align: 'center',
        render: this.getAgeing,
        sortId: 16,
      },
      {
        title: 'Action',
        align: 'center',
        // width: '40px',
        render: (a) => this.claimDetailsButton(a),
        sortId: 17,
      },
    ];
    const concatedColumns = commonColumns.concat(variableColumn);

    const t = concatedColumns.sort((a, b) => (a.sortId > b.sortId ? 1 : -1));

    // let t = Object.keys(concatedColumns)
    //   .sort((a, b) => {
    //     return concatedColumns[a].sortId - concatedColumns[b].sortId;
    //   })
    return t;
  };

  getTableData = () => {
    let { selectedTabIndex } = this.state;
    selectedTabIndex = Number(selectedTabIndex);
    const { myClaims, teamClaims, claimForFinance } = this.props;
    let tableData = [];
    if (selectedTabIndex === TAB_INDEX.TEAM_CLAIM) {
      tableData = teamClaims;
      return tableData;
    }
    if (selectedTabIndex === TAB_INDEX.FINANCE_CLAIM) {
      tableData = claimForFinance;
      return tableData;
    }

    tableData = myClaims;
    return myClaims;
  };

  setRadioFilter = (e) => {
    const statusValue = e.target.value;
    let { selectedTabIndex } = this.state;
    selectedTabIndex = Number(selectedTabIndex);
    const params = {
      statusId: statusValue,
    };
    if (selectedTabIndex === TAB_INDEX.MY_CLAIM) {
      this.props.getClaims(params);
    }
    if (selectedTabIndex === TAB_INDEX.TEAM_CLAIM) {
      this.props.getTeamClaims(params);
    }
    if (selectedTabIndex === TAB_INDEX.FINANCE_CLAIM) {
      this.props.getClaimsforFinance(params);
    }
    if (selectedTabIndex === TAB_INDEX.HR_CLAIM) {
      this.props.getClaimsforHR(params);
    }
    // This will be used when filters come in action
    this.setState({ filterStatus: statusValue });
  };

  getAgeing = (data, row) => {
    const { createdAt, status } = row;
    let approvalDate = null;
    let ageing = '-';
    for (let i = 0; i < status.length; i += 1) {
      // If any declined or last approved (checking with status.length))
      if (
        status[i].statusId === claimStatus.DECLINED ||
        (status[i].statusId === claimStatus.APPROVED && i + 1 === status.length)
      ) {
        approvalDate = status[i].updatedAt;
        break;
      }
    }

    if (approvalDate) {
      ageing = moment(approvalDate).diff(moment(createdAt), 'days');
    }
    return ageing;
  };

  closeNewClaimModal = () => {
    this.setState({ showNewClaimModal: false });
  };

  openNewClaimModal = () => {
    this.setState({ showNewClaimModal: true });
  };

  releasedAmount = (data, row) => {
    if (row.amountReleased === null) {
      return '-';
    }
    return `${row.amountReleased.toLocaleString()}`;
  };

  calcTotalAmount = (data, row) => {
    const { claimDetails } = row;
    let total = 0;
    claimDetails.forEach((element) => {
      if (element && element.amount) {
        total += Number(element.amount);
      }
    });
    return `${total.toLocaleString()}`;
  };

  claimStatus = (data, row) => <StatusTag statuses={row.status} />;

  statusTagColor = (statusDto) => {
    let color = 'gray';
    if (statusDto.statusId === claimStatus.PENDING) {
      color = 'gray';
    }
    if (statusDto.statusId === claimStatus.APPROVED) {
      color = 'green';
    }
    if (statusDto.statusId === claimStatus.DECLINED) {
      color = 'red';
    }
    return color;
  };

  employeeName = (data, row) => row.employee.fullName;

  claimDetailsButton = (row) => {
    let { selectedTabIndex } = this.state;
    selectedTabIndex = Number(selectedTabIndex);
    const { user } = this.props;
    const { status } = row;
    let statusText = 'View';
    let showApprovalButton = false;

    if (selectedTabIndex !== TAB_INDEX.HR_CLAIM && selectedTabIndex !== TAB_INDEX.MY_CLAIM) {
      const isDeclinedAlready = _.findWhere(status, { statusId: claimStatus.DECLINED });
      status.forEach((element) => {
        if (
          ((element.referenceId === user.id && element.statusId === claimStatus.PENDING) ||
            (user.isFinanceManager &&
              element.statusId === claimStatus.PENDING &&
              element.approveOrder === 3)) &&
          !isDeclinedAlready
        ) {
          statusText = 'Approve | Decline';
          showApprovalButton = true;
        }
      });
    }

    if (showApprovalButton) {
      return (
        <div
          className="claim-details-view-button"
          role="presentation"
          onClick={(e) => this.showClaimDetails(e, row)}
          onKeyDown={() => {}}
        >
          {statusText}
        </div>
      );
    }
    return (
      <EnhancedIcon
        className="claim-details-view-button"
        onClick={(e) => this.showClaimDetails(e, row)}
        type="eye"
      />
    );
  };

  showClaimDetails = (e, row) => {
    this.props.getClaimImages(row.id);
    this.setState({
      selectedClaim: row,
      claimDetailsModal: true,
    });
  };

  hideModal = () => {
    this.setState({
      claimDetailsModal: false,
      selectedClaim: [],
    });
    this.props.removeImageFromState();
  };

  exportClaim = () => {
    this.props.exportClaim();
  };

  renderLineManger = (data, row) =>
    row.employee && row.employee.manager && row.employee.manager.fullName;

  renderDate = (date) => moment(date).format(dateFormat.DDMMMYYYYwithSpace);

  render() {
    const {
      claimList,
      claimDetailsModal,
      selectedClaim,
      selectedTabIndex,
      showNewClaimModal,
      filterStatus,
    } = this.state;
    const { isLoading, user } = this.props;
    const columns = this.getColumns();
    // const tableData = this.getTableData();
    if (!user) {
      return <div />;
    }
    return (
      <div>
        <EnhancedCol lg={24} style={{ backgroundColor: 'white' }}>
          <h1 style={{ marginLeft: '20px', color: '#0D2234' }}>Claim List</h1>

          <EnhancedTabs defaultActiveKey={selectedTabIndex.toString()} onChange={this.onTabChange}>
            <TabPane tab="My Claim" key={TAB_INDEX.MY_CLAIM} />
            {user.isManager ? <TabPane tab="Team Claim" key={TAB_INDEX.TEAM_CLAIM} /> : null}
            {user.isFinanceManager ? (
              <TabPane tab="All Claim" key={TAB_INDEX.FINANCE_CLAIM} />
            ) : null}
            {user.isLeadership ? <TabPane tab="Company Claim" key={TAB_INDEX.HR_CLAIM} /> : null}
          </EnhancedTabs>
        </EnhancedCol>
        <EnhancedCol lg={24} style={{ backgroundColor: 'white', marginTop: '10px' }}>
          <EnhancedCard>
            {(Number(selectedTabIndex) === TAB_INDEX.FINANCE_CLAIM ||
              Number(selectedTabIndex) === TAB_INDEX.HR_CLAIM) &&
            filterStatus === FILTER_STATUS.ALL ? (
              <EnhancedButton onClick={this.exportClaim}>Export Report</EnhancedButton>
            ) : null}

            <RadioGroup className="right" value={filterStatus} onChange={this.setRadioFilter}>
              <RadioButton value={FILTER_STATUS.ALL}>All</RadioButton>
              <RadioButton value={FILTER_STATUS.PENDING}>Pending</RadioButton>
              <RadioButton value={FILTER_STATUS.APPROVED}>Approved</RadioButton>
              <RadioButton value={FILTER_STATUS.DECLINED}>Declined</RadioButton>
            </RadioGroup>
            <br />
            {/* <Row>
              <Col lg={20} />
              <Col lg={4}>
                <EnhancedButton
                  onClick={this.openNewClaimModal}
                >Add new claim
                </EnhancedButton>
              </Col>
            </Row> */}
            <br />
            <EnhancedClientSideTable
              loading={isLoading}
              columns={columns}
              scroll={{ x: 'max-content' }}
              // onRow={(record, rowIndex) => ({
              //   onClick: (event) => { this.onRowClicked(event, record, rowIndex); }, // clic
              // })}
              data={claimList}
              size="middle"
              // scroll={{ x: 1100 }}
            />
          </EnhancedCard>
        </EnhancedCol>
        {
          <ClaimDetailsContainer
            visible={claimDetailsModal}
            hideModal={this.hideModal}
            selectedClaim={selectedClaim}
            selectedTabIndex={selectedTabIndex}
            {...this.props}
          />
        }
        {showNewClaimModal ? (
          <AddExpenseClaimContainer
            visible={showNewClaimModal}
            closeNewClaimModal={this.closeNewClaimModal}
            {...this.props}
          />
        ) : null}
      </div>
    );
  }
}
export default ExpenseClaimList;
