import React from 'react';
import { Pagination } from 'antd';
import PropTypes from 'prop-types';

const EnhancedPagination = (props) => {
  const { size, pageSize, total, showTotal, onChange, current, style, children } = props;

  return (
    <Pagination
      style={style}
      size={size}
      pageSize={pageSize}
      total={total}
      showTotal={showTotal}
      onChange={onChange}
      current={current}
    >
      {children}
    </Pagination>
  );
};

EnhancedPagination.propTypes = {
  size: PropTypes.string,
  pageSize: PropTypes.number.isRequired,
  total: PropTypes.number,
  showTotal: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  current: PropTypes.number,
  style: PropTypes.Object,
};

EnhancedPagination.defaultProps = {
  current: 1,
  total: 0,
  size: '',
  style: {},
};

export default EnhancedPagination;
