/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import {
  addPMCycleInit,
  addPMCycleSuccess,
  addPMCyclelFailure,
  editPMCycleInit,
  editPMCycleSuccess,
  editPMCyclelFailure,
  deletePMCycleInit,
  deletePMCycleSuccess,
  deletePMCyclelFailure,
  getPMCycleInit,
  getPMCycleSuccess,
  getPMCycleFailure,
  addEmployeesInit,
  addEmployeesSuccess,
  addEmployeesFailure,
  deleteEmployeeInit,
  deleteEmployeeSuccess,
  deleteEmployeeFailure,
  getPMEmpCycleInit,
  getPMEmpCycleSuccess,
  getPMEmpCycleFailure,
  getPMCycleDetailInit,
  getPMCycleDetailSuccess,
  getPMCycleDetailFailure,
  getPreviousPMCycleFailure,
  getPreviousPMCycleSuccess,
  getRatingOptionsInit,
  getRatingOptionsSuccess,
  getRatingOptionsFailure,
} from 'store/actions/PerformanceMangementActions';
import { PMCycleUrls, employeeRatingUrls } from 'configs/constants';
import { showLoader, hideLoader } from 'store/actions/AppLoaderActions';
import { normalizePerformanceCycleEmployees } from '../normalizers/performanceManagementNormalizer';

export const getPMCycle = (request) => (dispatch) => {
  dispatch(getPMCycleInit());
  let url;
  if (request.isActive || request.isActive === 0) {
    url = PMCycleUrls.GET_PMCYCLE.replace('{isActive}', request.isActive)
      .replace('{pageNumber}', request.pageNumber)
      .replace('{pageSize}', request.pageSize);
  } else {
    url = PMCycleUrls.GET_PMCYCLE.replace('{isActive}', '')
      .replace('{pageNumber}', request.pageNumber)
      .replace('{pageSize}', request.pageSize);
  }

  axios
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        dispatch(getPMCycleSuccess(response.data.data));
      } else {
        dispatch(getPMCycleFailure());
      }
    })
    .catch((error) => {
      dispatch(getPMCycleFailure(error));
    });
};

export const getPMEmpCycle = (request) => (dispatch) => {
  dispatch(getPMEmpCycleInit());
  const url = PMCycleUrls.GET_PM_EMP_CYCLE.replace('{id}', request.id);

  const params = {
    oracleId: request.oracleId,
    employeeName: request.name,
    approverName: request.approverName,
    managerName: request.managerName,
    departmentIds: request.departmentIds,
    competencyIds: request.competencyIds,
    businessUnitIds: request.businessUnitIds,
    divisionIds: request.divisionIds,
    page: request.pageNumber,
    size: request.pageSize,
  };

  axios
    .get(url, { params })
    .then((response) => {
      if (response.status === 200) {
        //   const resourceNormalized = normalizeResourceAvailability(response.data.data, date);
        dispatch(getPMEmpCycleSuccess(response.data.data));
      } else {
        dispatch(getPMEmpCycleFailure());
      }
    })
    .catch((error) => {
      dispatch(getPMEmpCycleFailure(error));
    });
};
export const addPMCycle = (request) => (dispatch) => {
  const url = PMCycleUrls.CREATE_PMCYCLE;
  const { payload, parameters } = request;
  dispatch(addPMCycleInit());
  axios
    .post(url, payload)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Created Successfully',
        });
        dispatch(addPMCycleSuccess(response.data));
        dispatch(getPMCycle(parameters));
      } else {
        EnhancedNotification.error({
          message: 'Error in creating a new PM Cycle',
        });
        dispatch(addPMCyclelFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in creating a new PM Cycle.',
      });
      dispatch(addPMCyclelFailure());
    });
};

export const addEmployees = (request, handleHideModal) => (dispatch) => {
  const { employeesIds, parameters, id } = request;
  const url = PMCycleUrls.ADD_EMPLOYEES_PM_EMP_CYCLE.replace('{performanceCycleId}', id);

  dispatch(showLoader());
  dispatch(addEmployeesInit());
  axios
    .post(url, { employeesIds })
    .then((response) => {
      dispatch(hideLoader());
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Added Successfully',
        });
        handleHideModal();
        dispatch(addEmployeesSuccess(response.data));
        dispatch(getPMEmpCycle(parameters));
      } else {
        EnhancedNotification.error({
          message: 'Error in adding new employees in PM Cycle',
        });
        dispatch(addEmployeesFailure());
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in adding new employees in PM Cycle.',
      });
      dispatch(addEmployeesFailure());
    });
};

export const deleteEmployee = (request) => (dispatch) => {
  const { employeeId, parameters, id } = request;

  const url = PMCycleUrls.DELETE_EMPLOYEE_PM_EMP_CYCLE.replace('{performanceCycleId}', id).replace(
    '{employeeId}',
    employeeId,
  );

  dispatch(deleteEmployeeInit());
  axios
    .delete(url)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Removed Successfully',
        });
        dispatch(deleteEmployeeSuccess());
        dispatch(getPMEmpCycle(parameters));
      } else {
        EnhancedNotification.error({
          message: 'Error in removing an employees from PM Cycle',
        });
        dispatch(deleteEmployeeFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in removing an employees from PM Cycle.',
      });
      dispatch(deleteEmployeeFailure());
    });
};

export const getPMCycleDetail = (id) => (dispatch) => {
  dispatch(getPMCycleDetailInit());

  const url = PMCycleUrls.GET_PMCYCLE_DETAIL.replace('{id}', id);

  axios
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        dispatch(getPMCycleDetailSuccess(response.data.data));
      } else {
        dispatch(getPMCycleDetailFailure());
      }
    })
    .catch((error) => {
      dispatch(getPMCycleDetailFailure(error));
    });
};

export const getEmployeesExport = (param) =>
  new Promise((resolve, reject) => {
    const url = PMCycleUrls.GET_PM_EMP_CYCLE.replace('{id}', param.id);
    const params = { ...param };
    delete params.id;
    axios
      .get(url, { params })
      .then((response) => {
        if (response && response.data.success) {
          const { rows } = response.data.data;
          const data = normalizePerformanceCycleEmployees(rows);
          resolve(data);
        } else {
          reject(response.message);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });

export const sendPMCycleInitiationEmail = (param) =>
  new Promise((resolve, reject) => {
    const url = PMCycleUrls.POST_PMCYCLE_INITIATION_EMAIL.replace(
      '{performanceCycleId}',
      param.performanceCycleId,
    );
    const params = { ...param };
    delete params.performanceCycleId;
    axios
      .post(url, { params })
      .then((response) => {
        if (response && response.data.success) {
          resolve(response.data.data);
        } else {
          reject(response.message);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });

export const editPMCycle = (request) => (dispatch) => {
  const url = PMCycleUrls.EDIT_PMCYCLE.replace('{pmId}', request.payload.id);
  const { payload, parameters } = request;
  dispatch(editPMCycleInit());
  axios
    .put(url, payload)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Edited Successfully',
        });
        dispatch(editPMCycleSuccess(response.data));
        dispatch(getPMCycle(parameters));
      } else {
        EnhancedNotification.error({
          message: 'Error in editing a new PM Cycle',
        });
        dispatch(editPMCyclelFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in editing a PM Cycle.',
      });
      dispatch(editPMCyclelFailure());
    });
};

export const showPreviousPMCycle = (request) => (dispatch) => {
  const { payload, parameters } = request;
  const url = employeeRatingUrls.GET_RATINGS_SUMMARY.replace('{:employeeId}', parameters.empId);

  dispatch(showLoader());
  axios
    .get(url, {
      params: {
        monthFrom: payload.startMonth,
        monthTo: payload.endMonth,
        includeAllAttributes: true,
      },
    })
    .then((response) => {
      dispatch(hideLoader());
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Average monthly performance ratings fetched successfully',
        });
        dispatch(getPreviousPMCycleSuccess(response.data.data));
      } else {
        EnhancedNotification.error({
          message: 'Error in fetching previous PM Cycle',
        });
        dispatch(getPreviousPMCycleFailure());
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in fetching a previous Cycle.',
      });
      dispatch(getPreviousPMCycleFailure());
    });
};

export const deletePMCycle = (request) => (dispatch) => {
  const url = PMCycleUrls.DELETE_PMCYCLE.replace('{pmId}', request.payload);
  const { parameters } = request;
  dispatch(deletePMCycleInit());
  axios
    .delete(url)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'deleted Successfully',
        });
        dispatch(deletePMCycleSuccess(response.data));
        dispatch(getPMCycle(parameters));
      } else {
        EnhancedNotification.error({
          message: 'Error in deleting a new PM Cycle',
        });
        dispatch(deletePMCyclelFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in deleting a PM Cycle.',
      });
      dispatch(deletePMCyclelFailure());
    });
};

export const closePMCycle = (request) => (dispatch) => {
  const { performanceCycleId, parameters } = request;

  const url = PMCycleUrls.CLOSE_PMCYCLE.replace('{performanceCycleId}', performanceCycleId);

  dispatch(showLoader());

  axios
    .put(url)
    .then((response) => {
      dispatch(hideLoader());
      if (response.status === 200) {
        EnhancedNotification.success({ message: 'Performance Cycle closed successfully' });
        dispatch(getPMCycle(parameters));
      } else {
        EnhancedNotification.error({
          message: 'An error occured',
        });
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      EnhancedNotification.error({
        message: error.response.data.message,
      });
    });
};

export const getRatingOptions = () => (dispatch) => {
  const url = PMCycleUrls.GET_RATING_OPTIONS;

  dispatch(getRatingOptionsInit());

  axios
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        dispatch(getRatingOptionsSuccess(response.data.data));
      } else {
        dispatch(getRatingOptionsFailure());
      }
    })
    .catch(() => {
      dispatch(getRatingOptionsFailure());
    });
};
