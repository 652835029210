import React from 'react';
import {
  INPUT,
  SELECT,
  DATE,
  DROP_DOWNS,
  ROW_GUTTER_SIZE,
  FULL_GRID_SIZE,
  NUMBER,
  GRADES as gradeOptions,
  SUB_GRADES as subGradeOptions,
} from 'configs/employeeProfileConstants';
import { REQUIRED, EMAIL, NUMBERS_ONLY, POSITIVE_NUMBERS_ONLY } from 'configs/validationConstants';
import { GENDERS, RESOURCETYPE } from 'configs/constants';
import { createFields, createSubHeading } from 'utils/FieldGenerateUtils';
import { getValueForKeyinJSON } from 'utils/arrayUtils';
import { withRouter } from 'react-router-dom';
import Permissions from 'constants/AccessPermissions';
import {
  EnhancedButton,
  EnhancedCol,
  EnhancedRow,
  EnhancedCard,
  EnhancedForm,
} from 'components/shared/antd';

class CreateBasicInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        fuelAllowance: '',
      },
      errors: {},
      [DROP_DOWNS]: {},
      isEdit: true,
      disableSaveButton: false,
      shouldRedirect: false,
    };
  }

  componentDidMount() {
    this.initializeDropDowns(this.props[DROP_DOWNS]);
    this.handleInitialValidation();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps[DROP_DOWNS] !== this.props[DROP_DOWNS]) {
      this.initializeDropDowns(nextProps[DROP_DOWNS]);
      // TODO: This may not be needed -> Generates error state for each value in state
      // this.initializeErrors(nextProps[propsName]);
    }
    if (nextProps.isSuccessfull && !nextProps.isLoading) {
      // this.clear();
      if (this.state.shouldRedirect) {
        this.props.history.push(`${nextProps.createdEmployeeId}`);
      }
    }
  }

  initializeDropDowns = (props) => this.setState({ [DROP_DOWNS]: { ...props } });

  handleInitialValidation = () => {
    // Mutate error object according to required conditions for appropiate validation
    const newErrorObject = {
      businessUnitId: true,
      competencyId: true,
      departmentId: true,
      divisionId: true,
      email: true,
      employmentStatusId: true,
      externalExpMonths: true,
      externalExpYears: true,
      firstName: true,
      genderId: true,
      lastName: true,
      managerId: true,
      location: true,
      joiningDate: true,
      cnic: true,
      cnicExpiry: true,
      employmentTypeId: true,
      // grossSalary: true,
      resourceType: true,
    };

    this.setState({ disableSaveButton: true, errors: newErrorObject });
  };

  handleSave = (e) => {
    e.preventDefault();
    if (!this.state.disableSaveButton) {
      this.setState({ shouldRedirect: true });
      this.props.createEmployeeInfo(this.state.data);
    }
  };

  handleChange = (value, key, error) => {
    const currentData = this.state.data;
    const newData = Object.assign({}, currentData, { [key]: value });
    // Update specific error object and field
    let { errors } = this.state;
    errors = Object.assign({}, { ...errors }, { [key]: error });
    // Check to see if there is any error
    // if any, disable save button
    // if none, enable save button
    const allValid = Object.values(errors).every((valueError) => valueError === false);
    this.setState({ data: newData, disableSaveButton: !allValid, errors });
    if (error) {
      this.setState({ disableSaveButton: true });
    }
  };

  render() {
    const {
      userPermissions,
      employees,
      businessUnits,
      designations,
      divisions,
      competencies,
      departments,
      employmentTypes,
      locations,
    } = this.props;
    const { disableSaveButton, data, isEdit } = this.state;
    const dropDowns = this.state[DROP_DOWNS];
    return (
      <div className="mt-20 pos-rel">
        {createSubHeading('Basic Info')}
        {/* {!isEmptyObject(data) ? ( */}
        <EnhancedRow gutter={ROW_GUTTER_SIZE}>
          <EnhancedCol span={FULL_GRID_SIZE}>
            <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
              className="mb-10 card-shadow"
              hoverable
            >
              <div>
                <EnhancedForm>
                  <div className="flex justify-flex-end">
                    {/* <span>
                                                {canEdit && !isEdit ? (
                                                    <EnhancedButton onClick={this.handleEdit}>Edit</EnhancedButton>
                                                ) : (
                                                    ''
                                                )}
                                            </span> */}
                    <span className="pr-10">
                      {/* {canEdit && isEdit ? ( */}
                      <EnhancedButton onClick={this.handleSave} disabled={disableSaveButton}>
                        Save
                      </EnhancedButton>
                      {/* ) : (
                                                    ''
                                                )} */}
                    </span>
                  </div>
                  {createFields(
                    [
                      {
                        key: 'email',
                        label: 'Email Address',
                        value: data.email,
                        type: INPUT,
                        validations: [REQUIRED, EMAIL],
                      },
                      {
                        key: 'firstName',
                        label: 'First Name',
                        value: data.firstName,
                        type: INPUT,
                        validations: [REQUIRED],
                      },
                      {
                        key: 'middleName',
                        label: 'Middle Name',
                        value: data.middleName,
                        type: INPUT,
                      },
                      {
                        key: 'lastName',
                        label: 'Last Name',
                        value: data.lastName,
                        type: INPUT,
                        validations: [REQUIRED],
                      },
                      {
                        key: 'genderId',
                        label: 'Gender',
                        options: GENDERS,
                        value: getValueForKeyinJSON(GENDERS, data.genderId),
                        type: SELECT,
                        validations: [REQUIRED],
                      },
                      {
                        key: 'joiningDate',
                        label: 'Date Of Joining',
                        value: data.joiningDate ? data.joiningDate : '',
                        type: DATE,
                        validations: [REQUIRED],
                      },
                      {
                        key: 'externalExpYears',
                        label: 'External Experience in Years',
                        value: data.externalExpYears,
                        type: NUMBER,
                        validations: [REQUIRED],
                        min: 0,
                      },
                      {
                        key: 'externalExpMonths',
                        label: 'External Experience in Months',
                        value: data.externalExpMonths,
                        type: NUMBER,
                        validations: [REQUIRED],
                        min: 0,
                      },
                      {
                        key: 'employmentStatusId',
                        label: 'Employment Status',
                        options: dropDowns.employmentStatuses,
                        value: getValueForKeyinJSON(
                          dropDowns.employmentStatuses,
                          data.employmentStatusId,
                        ),
                        type: SELECT,
                        validations: [REQUIRED],
                      },
                      {
                        key: 'businessUnitId',
                        label: 'Business Unit',
                        options: businessUnits,
                        value: getValueForKeyinJSON(businessUnits, data.businessUnitId),
                        type: SELECT,
                        validations: [REQUIRED],
                      },
                      {
                        key: 'competencyId',
                        label: 'Competency',
                        options: competencies,
                        value: getValueForKeyinJSON(competencies, data.competencyId),
                        type: SELECT,
                        validations: [REQUIRED],
                      },
                      {
                        key: 'divisionId',
                        label: 'Division',
                        options: divisions,
                        value: getValueForKeyinJSON(divisions, data.divisionId),
                        type: SELECT,
                        validations: [REQUIRED],
                      },
                      {
                        key: 'departmentId',
                        label: 'Department',
                        options: departments,
                        value: getValueForKeyinJSON(departments, data.departmentId),
                        type: SELECT,
                        validations: [REQUIRED],
                      },
                      {
                        key: 'resourceType',
                        label: 'Resource Type',
                        value: data.resourceType,
                        options: RESOURCETYPE,
                        type: SELECT,
                        validations: [REQUIRED],
                      },
                      {
                        key: 'designationId',
                        label: 'Job Title',
                        options: designations,
                        value: getValueForKeyinJSON(designations, data.designationId),
                        type: SELECT,
                        validations: [REQUIRED],
                      },
                      {
                        key: 'grade',
                        label: 'Grade',
                        options: gradeOptions,
                        value: getValueForKeyinJSON(gradeOptions, data.grade),
                        type: SELECT,
                        validations: [REQUIRED],
                      },
                      {
                        key: 'subGrade',
                        label: 'Sub Grade',
                        options: subGradeOptions,
                        value: getValueForKeyinJSON(subGradeOptions, data.subGrade),
                        type: SELECT,
                        validations: [REQUIRED],
                      },
                      {
                        key: 'managerId',
                        label: 'Line Manager',
                        options: employees,
                        value: getValueForKeyinJSON(employees, data.managerId),
                        type: SELECT,
                        validations: [REQUIRED],
                      },
                      {
                        key: 'employmentTypeId',
                        label: 'Employment Type:',
                        options: employmentTypes,
                        value: getValueForKeyinJSON(employmentTypes, data.employmentTypeId),
                        type: SELECT,
                        validations: [REQUIRED],
                      },
                      {
                        key: 'location',
                        label: 'Location',
                        options: locations,
                        value: getValueForKeyinJSON(locations, data.location),
                        type: SELECT,
                        validations: [REQUIRED],
                      },
                      {
                        key: 'cnic',
                        label: 'CNIC',
                        value: data.cnic,
                        type: INPUT,
                        validations: [REQUIRED],
                      },
                      {
                        key: 'cnicExpiry',
                        label: 'CNIC Expiry Date',
                        value: data.cnicExpiry ? data.cnicExpiry : '',
                        type: DATE,
                        validations: [REQUIRED],
                      },
                      {
                        key: 'grossSalary',
                        label: 'Gross Salary',
                        value: data.grossSalary,
                        type: NUMBER,
                        validations: [NUMBERS_ONLY],
                        viewPermissions: [
                          Permissions.Employee.Profile.Compensation.View,
                          Permissions.Reportee.Profile.Compensation.View,
                        ],
                      },
                      {
                        key: 'inflationSupport',
                        label: 'Inflation Support',
                        value: data.inflationSupport,
                        type: NUMBER,
                        validations: [NUMBERS_ONLY],
                        viewPermissions: [
                          Permissions.Employee.Profile.Compensation.View,
                          Permissions.Reportee.Profile.Compensation.View,
                        ],
                      },
                      {
                        key: 'fuelAllowance',
                        label: 'Fuel Allowance',
                        value: data.fuelAllowance,
                        type: NUMBER,
                        validations: [NUMBERS_ONLY, POSITIVE_NUMBERS_ONLY],
                        viewPermissions: [
                          Permissions.Employee.Profile.Compensation.View,
                          Permissions.Reportee.Profile.Compensation.View,
                        ],
                      },
                    ],
                    2,
                    isEdit,
                    userPermissions,
                    this.handleChange,
                  )}
                </EnhancedForm>
              </div>
            </EnhancedCard>
          </EnhancedCol>
        </EnhancedRow>
        {/* ) : (
                    noData()
                )} */}
      </div>
    );
  }
}

export default withRouter(CreateBasicInfo);
