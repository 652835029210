import { EnhancedCard } from 'components/shared/antd';
import React from 'react';

import Tracking from 'components/employeeProfile/tracking/tracking';

class JobInfo extends React.Component {
  componentDidMount() {
    const employeeId = this.props.match.params.id;
    // this.props.getMiscellaneousData();
    this.props.getEmployeeTrackingInfo(employeeId);
  }

  render() {
    const { userPermissions, isLoading: globalLoading } = this.props;
    return (
      <EnhancedCard className="mt-20" loading={globalLoading} bordered={false}>
        {/* <Authorize
          requiredPermissions={[
            Permissions.Employee.Profile.JobInfo.Tracking.View,
          ]}
        > */}
        <Tracking
          tracking={this.props.trackingInfo}
          userPermissions={userPermissions}
          employeeId={this.props.match.params.id}
          updateEmployeeTrackingInfo={this.props.updateEmployeeTrackingInfo}
          cardLoading={this.props.cardLoading}
          employeeDeactivation={this.props.employeeDeactivation}
          deActivateEmployee={this.props.deActivateEmployee}
          getEmployeeTrackingInfo={this.props.getEmployeeTrackingInfo}
        />
        {/* </Authorize> */}
        {/* <Authorize
          requiredPermissions={[
            Permissions.Employee.Profile.JobInfo.History.View,
          ]}
        >
          <JobHistory jobHistory={this.props.jobInfo.listing} />
        </Authorize> */}
      </EnhancedCard>
    );
  }
}

export default JobInfo;
