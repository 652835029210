import React from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';

const EnhancedTag = (props) => {
  const { color, key, closeable, children, className, style, onClick, ...rest } = props;

  return (
    <Tag
      style={style}
      className={className}
      closeable={closeable}
      key={key}
      color={color}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Tag>
  );
};

EnhancedTag.propTypes = {
  key: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  closeable: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

EnhancedTag.defaultProps = {
  className: '',
  style: {},
  closeable: false,
};

export default EnhancedTag;
