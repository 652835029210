import {
  EnhancedCol,
  EnhancedRow,
  EnhancedButton,
  EnhancedCard,
  EnhancedDivider,
  EnhancedModal,
  EnhancedSelect,
} from 'components/shared/antd';
import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';
import { DATE_FORMAT } from 'configs/employeeProfileConstants';
import { roundOffRating } from 'utils/conversionUtils';
import { BREADCRUMBS } from 'configs/constants';
import { hasPermission } from 'utils/AccessUtils';
import RequirementForm from './RequirementForm';
import SuggestResourceForm from './SuggestResourceForm';
import DealRequirementsSuggestions from './dealRequirementsSuggestions';
import DealRequirementHistory from './DealRequirementHistory';
import AccessPermissions from '../../constants/AccessPermissions';
import Authorize from '../Authorize';

const { confirm } = EnhancedModal;
const { Option } = EnhancedSelect;

const dateRenderer = (dateText) => (dateText ? moment(dateText).format(DATE_FORMAT) : '');

const summaryLabels = [
  {
    label: 'Deal No',
    dataKey: 'dealNumber',
  },
  {
    label: 'Start Date',
    renderer: dateRenderer,
    dataKey: 'expectedStartDate',
  },
  {
    label: 'Deal Name',
    dataKey: 'dealName',
  },
  {
    label: 'Deal Type',
    dataKey: 'type',
  },
  {
    label: 'Client Name',
    dataKey: 'clientName',
  },
  {
    label: 'Probability',
    dataKey: 'probability',
  },
  {
    label: 'Business Unit',
    dataKey: 'businessUnit',
  },
  {
    label: 'Deal Owner',
    dataKey: 'dealOwner',
  },
  {
    label: 'Project Duration',
    dataKey: 'projectDuration',
  },
  {
    label: 'Stage',
    dataKey: 'stage',
  },
  {
    label: 'Proposal Type',
    dataKey: 'proposalType',
  },
  {
    label: 'Closed At',
    renderer: dateRenderer,
    dataKey: 'closedAt',
  },
];

const DealDetails = (props) => {
  const {
    getDealDetail,
    getDealRequirements,
    match,
    dealRequirements,
    dealDetail,
    skills,
    getEmployeeSkills,
    addDealRequirements,
    updateDealRequirements,
    deleteDealRequirements,
    suggestResource,
    dealRequirementsSuggestions,
    getDealRequirementsSuggestions,
    updateBreadCrumb,
    updateDealRequirementSuggestion,
    getDealResourceStatus,
    dealResourceStatus,
    getDealRequirementSuggestionOtherDeals,
    dealRequirementSuggestionOtherDeals,
    getDealRequirementMatchingResources,
    dealRequirementMatchingResources,
    userPermissions,
    getDealRequirementHistory,
    dealRequirementHistory,
  } = props;

  const [showRequirementForm, setShowRequirementForm] = useState(false);
  const [showSuggestResourceForm, setShowSuggestResourceForm] = useState(false);
  const [showDealRequirementSuggestions, setShowDealRequirementSuggestions] = useState(false);
  const [showDealRequirementHistory, setShowDealRequirementHistory] = useState(false);
  const [clickedRecord, setClickedRecord] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [dealRequirementParams, setDealRequirementParams] = useState({
    page: 0,
    size: 10,
  });

  useEffect(() => {
    getDealDetail(match.params.id);
    getDealRequirements(
      {
        ...dealRequirementParams,
      },
      match.params.id,
    );
    getEmployeeSkills();
    getDealResourceStatus();
  }, []);

  useEffect(() => {
    getDealRequirements(
      {
        ...dealRequirementParams,
      },
      match.params.id,
    );
  }, [dealRequirementParams]);

  useEffect(() => {
    if (dealDetail.id) {
      updateBreadCrumb(BREADCRUMBS.DEAL_DETAIL, dealDetail.id, dealDetail.dealName);
    }
  }, [dealDetail]);

  const handleTableChange = (pagination) => {
    setDealRequirementParams({
      ...dealRequirementParams,
      page: pagination.current - 1,
      size: pagination.pageSize,
    });
  };

  // Create Requirements Form with Edit
  const handleCreateRequirement = () => {
    setShowRequirementForm(true);
    setIsEdit(false);
    setClickedRecord(false);
  };

  const closeRequirementForm = () => {
    setShowRequirementForm(false);
    setIsEdit(false);
    setClickedRecord(false);
  };

  const handleDealRequirementsSuggestionsOnClose = () => {
    setShowDealRequirementSuggestions(false);
  };

  const handleDealRequirementHistoryOnClose = () => {
    setShowDealRequirementHistory(false);
  };

  // Delete Requirements Suggestions Row
  const handleRequirementDelete = (record) => {
    confirm({
      title: 'Are you sure you want to delete this requirement?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteDealRequirements(record.id, dealDetail.id, dealRequirementParams);
      },
    });
  };

  const changeActionHandler = (key, id, record) => {
    if (key === '1') {
      setShowSuggestResourceForm(true);
      setClickedRecord(record);
    } else if (key === '2') {
      setShowRequirementForm(true);
      setIsEdit(true);
      setClickedRecord(record);
    } else if (key === '3') {
      handleRequirementDelete(record);
    } else if (key === '4') {
      setShowDealRequirementSuggestions(true);
      setClickedRecord(record);
    } else if (key === '5') {
      setShowDealRequirementHistory(true);
      setClickedRecord(record);
    }
  };

  const optionMenu = [
    {
      value: '1',
      label: 'Suggest Resource',
      isAllowed: hasPermission(userPermissions, [
        AccessPermissions.ResourcePlanner.Deal.Requirements.Suggestions.Create,
      ]),
    },
    {
      value: '2',
      label: 'Edit',
      isAllowed: hasPermission(userPermissions, [
        AccessPermissions.ResourcePlanner.Deal.Requirements.Update,
      ]),
    },
    {
      value: '3',
      label: 'Delete',
      isAllowed: hasPermission(userPermissions, [
        AccessPermissions.ResourcePlanner.Deal.Requirements.Delete,
      ]),
    },
    {
      value: '4',
      label: 'View Suggested Resources',
      isAllowed: hasPermission(userPermissions, [
        AccessPermissions.ResourcePlanner.Deal.Requirements.Suggestions.List,
      ]),
    },
    {
      value: '5',
      label: 'View History',
      isAllowed: hasPermission(userPermissions, [
        AccessPermissions.ResourcePlanner.Deal.Requirements.Suggestions.List,
      ]),
    },
  ];

  const setActionsHandler = (id, record) => {
    return (
      <EnhancedSelect
        style={{
          width: 200,
        }}
        className="action-selection-dropdown"
        placeholder="Options"
        value="Options"
        onSelect={(key) => {
          changeActionHandler(key, id, record);
        }}
      >
        {optionMenu.map(
          (item) => item.isAllowed && <Option value={item.value}>{item.label}</Option>,
        )}
      </EnhancedSelect>
    );
  };

  const renderResourceRequired = (resourceCount) => {
    const probabilityInDecimal = dealDetail.probability / 100;
    return `${probabilityInDecimal} * ${resourceCount} = ${roundOffRating(
      probabilityInDecimal * resourceCount,
    )}`;
  };

  const renderSuggestedResources = (suggestedResources) => {
    return suggestedResources.map((resource) => resource.name).join(', ');
  };

  const getColumnsInfo = () => {
    const columns = [
      {
        title: 'Skills',
        dataIndex: 'skills',
        key: 'skills',
        filterConfig: {
          type: 'search',
          key: 'skills',
        },
        render: (data) => data.join(' + '),
        // ellipsis: true,
        width: 400,
      },
      {
        title: 'Required',
        dataIndex: 'resourceRequired',
        key: 'resourceRequired',
        render: renderResourceRequired,
      },
      {
        title: 'Min Experience',
        dataIndex: 'minExperienceLevel',
        key: 'minExperienceLevel',
        filterConfig: {
          type: 'search',
          key: 'minExperienceLevel',
        },
        ellipsis: true,
      },
      {
        title: 'Max Experience',
        dataIndex: 'maxExperienceLevel',
        key: 'maxExperienceLevel',
        filterConfig: {
          type: 'search',
          key: 'maxExperienceLevel',
        },
        ellipsis: true,
      },
      {
        title: 'Suggested',
        dataIndex: 'suggestedResources',
        key: 'suggestedResources',
        render: renderSuggestedResources,
        width: 400,
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        render: (id, record) => setActionsHandler(id, record),
      },
    ];
    return columns;
  };

  return (
    <Fragment>
      <EnhancedCard>
        <EnhancedRow type="flex" align="middle" justify="space-between" className="mb-20">
          <EnhancedCol>
            <h2 className="capitalize">{dealDetail.dealName}</h2>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow type="flex" justify="space-between" align="middle">
          <EnhancedCol span={24}>
            <EnhancedRow type="flex" justify="space-between" align="middle">
              {summaryLabels.map((item) => (
                <EnhancedCol span={12} style={{ marginBottom: 16 }}>
                  <EnhancedRow type="flex" justify="space-between" align="middle">
                    <EnhancedCol span={8}>
                      <b>{item.label}</b>
                    </EnhancedCol>
                    <EnhancedCol span={1}>:</EnhancedCol>
                    <EnhancedCol span={15}>
                      <span>
                        {item.renderer
                          ? item.renderer(dealDetail[item.dataKey])
                          : dealDetail[item.dataKey]}
                      </span>
                    </EnhancedCol>
                  </EnhancedRow>
                </EnhancedCol>
              ))}
            </EnhancedRow>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow type="flex" justify="end" align="middle" className="mb-20">
          <EnhancedDivider />
          <EnhancedCol span={6}>
            <h2 className="capitalize">Resource Requirements</h2>
          </EnhancedCol>
          <EnhancedCol span={18} className="text-align-right">
            <Authorize
              requiredPermissions={[AccessPermissions.ResourcePlanner.Deal.Requirements.Create]}
            >
              <EnhancedButton className="mr-10" onClick={handleCreateRequirement}>
                Create Requirement
              </EnhancedButton>
            </Authorize>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow>
          <EnhancedCol span={24}>
            <EnhancedServerSideTable
              data={dealRequirements.rows}
              columns={getColumnsInfo()}
              onChange={handleTableChange}
              className="deals-table-container"
              paginationInfo={{
                totalItems: dealRequirements.pageInfo && dealRequirements.pageInfo.totalItems,
                pageSize: dealRequirements.pageInfo && dealRequirements.pageInfo.size,
                pageNumber: dealRequirements.pageInfo && dealRequirements.pageInfo.page + 1,
              }}
              scroll={{ x: 'max-content' }}
              updateParams={(updatedParams) => {
                setDealRequirementParams({
                  ...dealRequirementParams,
                  ...updatedParams,
                  page: 0, // move to page no 0 in case filters are applied
                });
              }}
            />
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedCard>
      <RequirementForm
        isModelVisible={showRequirementForm}
        handleClose={closeRequirementForm}
        addDealRequirementsAction={addDealRequirements}
        updateDealRequirementsAction={updateDealRequirements}
        dealRequirementParams={dealRequirementParams}
        isEdit={isEdit}
        details={clickedRecord}
        skills={skills}
        dealDetail={dealDetail}
      />
      <SuggestResourceForm
        isDrawerVisible={showSuggestResourceForm}
        onClose={() => setShowSuggestResourceForm(false)}
        suggestResource={suggestResource}
        dealRequirementObj={clickedRecord}
        getDealRequirementMatchingResources={getDealRequirementMatchingResources}
        dealRequirementMatchingResources={dealRequirementMatchingResources}
        dealRequirementParams={dealRequirementParams}
        dealDetail={dealDetail}
      />
      <DealRequirementsSuggestions
        isDrawerVisible={showDealRequirementSuggestions}
        onClose={handleDealRequirementsSuggestionsOnClose}
        getDealRequirementsSuggestions={getDealRequirementsSuggestions}
        dealRequirementsSuggestions={dealRequirementsSuggestions}
        dealRequirementObj={clickedRecord}
        updateDealRequirementSuggestion={updateDealRequirementSuggestion}
        dealResourceStatus={dealResourceStatus}
        getDealRequirementSuggestionOtherDeals={getDealRequirementSuggestionOtherDeals}
        dealRequirementSuggestionOtherDeals={dealRequirementSuggestionOtherDeals}
        dealRequirementParams={dealRequirementParams}
        dealDetail={dealDetail}
      />
      <DealRequirementHistory
        isDrawerVisible={showDealRequirementHistory}
        onClose={handleDealRequirementHistoryOnClose}
        getDealRequirementHistory={getDealRequirementHistory}
        dealRequirementHistory={dealRequirementHistory}
        dealRequirementObj={clickedRecord}
        dealDetail={dealDetail}
      />
    </Fragment>
  );
};

export default DealDetails;
