import {
  getGoalCategory,
  removeGoalCategory,
  getGoalCategoryDetails,
  addGoalCategory,
  updateGoalCategory,
} from 'sources/admin/PredefinedGoalCategoriesSource';

import {
  GET_GOAL_CATEGORY_INIT,
  GET_GOAL_CATEGORY_SUCCESS,
  GET_GOAL_CATEGORY_FAILURE,
  GET_GOAL_CATEGORY_DETAILS_INIT,
  GET_GOAL_CATEGORY_DETAILS_SUCCESS,
  GET_GOAL_CATEGORY_DETAILS_FAILURE,
  ADD_GOAL_CATEGORY_INIT,
  ADD_GOAL_CATEGORY_SUCCESS,
  ADD_GOAL_CATEGORY_FAILURE,
  UPDATE_GOAL_CATEGORY_INIT,
  UPDATE_GOAL_CATEGORY_SUCCESS,
  UPDATE_GOAL_CATEGORY_FAILURE,
  DELETE_GOAL_CATEGORY_INIT,
  DELETE_GOAL_CATEGORY_SUCCESS,
  DELETE_GOAL_CATEGORY_FAILURE,
} from 'constants/admin/PredefinedGoalCategoryTypes';

export const getGoalCategoryInit = () => ({
  type: GET_GOAL_CATEGORY_INIT,
});

export const getGoalCategorySuccess = (data) => ({
  type: GET_GOAL_CATEGORY_SUCCESS,
  data,
});

export const getGoalCategoryFailure = (data) => ({
  type: GET_GOAL_CATEGORY_FAILURE,
  data,
});

export const getGoalCategoryDetailsInit = () => ({
  type: GET_GOAL_CATEGORY_DETAILS_INIT,
});

export const getGoalCategoryDetailsSuccess = (data) => ({
  type: GET_GOAL_CATEGORY_DETAILS_SUCCESS,
  data,
});

export const getGoalCategoryDetailsFailure = (data) => ({
  type: GET_GOAL_CATEGORY_DETAILS_FAILURE,
  data,
});

export const addGoalCategoryInit = () => ({
  type: ADD_GOAL_CATEGORY_INIT,
});

export const addGoalCategorySuccess = (data) => ({
  type: ADD_GOAL_CATEGORY_SUCCESS,
  data,
});

export const addGoalCategorylFailure = () => ({
  type: ADD_GOAL_CATEGORY_FAILURE,
});

export const updateGoalCategoryInit = () => ({
  type: UPDATE_GOAL_CATEGORY_INIT,
});

export const updateGoalCategorySuccess = (data) => ({
  type: UPDATE_GOAL_CATEGORY_SUCCESS,
  data,
});

export const updateGoalCategorylFailure = () => ({
  type: UPDATE_GOAL_CATEGORY_FAILURE,
});

export const deleteGoalCategoryInit = () => ({
  type: DELETE_GOAL_CATEGORY_INIT,
});

export const deleteGoalCategorySuccess = (data) => ({
  type: DELETE_GOAL_CATEGORY_SUCCESS,
  data,
});

export const deleteGoalCategoryFailure = () => ({
  type: DELETE_GOAL_CATEGORY_FAILURE,
});

export const getGoalCategoryActions = (requestObject) => (dispatch) => {
  dispatch(getGoalCategory(requestObject));
};

export const getGoalCategoryDetailsActions = (requestObject) => (dispatch) => {
  dispatch(getGoalCategoryDetails(requestObject));
};

export const addGoalCategoryActions = (requestObject) => (dispatch) => {
  dispatch(addGoalCategory(requestObject));
};

export const updateGoalCategoryActions = (requestObject) => (dispatch) => {
  dispatch(updateGoalCategory(requestObject));
};

export const removeGoalCategoryActions = (requestObject) => (dispatch) => {
  dispatch(removeGoalCategory(requestObject));
};
