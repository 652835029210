import {
  GET_EMPLOYEE_SEARCH_INIT,
  GET_EMPLOYEE_SEARCH_SUCCESS,
  GET_EMPLOYEE_SEARCH_FAILURE,
} from 'constants/EmployeeDirectoryTypes';

const initialState = {
  employeeDirectory: [],
  employeePageInfo: null,
  isLoading: false,
};
export default function employeeSearchReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_SEARCH_INIT: {
      return Object.assign({}, state, {
        employeeDirectory: [],
        employeePageInfo: null,
        isLoading: true,
      });
    }
    case GET_EMPLOYEE_SEARCH_SUCCESS: {
      return Object.assign({}, state, {
        employeeDirectory: action.data,
        employeePageInfo: action.pageInfo,
        isLoading: false,
      });
    }
    case GET_EMPLOYEE_SEARCH_FAILURE: {
      return Object.assign({}, state, {
        employeeDirectory: [],
        employeePageInfo: null,
        isLoading: false,
      });
    }
    default:
      return state;
  }
}
