import {
  EnhancedCol,
  EnhancedRow,
  EnhancedButton,
  EnhancedCard,
  EnhancedAvatar,
} from 'components/shared/antd';
import React, { Component } from 'react';

import './employeeDirectory.css';
import EmployeeDirectoryModal from './EmployeeDirectoryModal';
import EmployeeDirectorySpinner from './EmployeeDirectorySpinner';

class EmployeeDirectoryCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  openModal = (emp) => {
    this.setState({ visible: true, emp });
  };

  hideModal = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible, emp } = this.state;
    const { showMoreButton, loading } = this.props;
    return (
      <div>
        {this.props.loading ? (
          <EmployeeDirectorySpinner />
        ) : (
          <EnhancedRow gutter={16} type="flex" style={{ margin: '20px 10px 0' }} align="middle">
            {this.props.filteredData.length > 0 ? (
              this.props.filteredData.map((employee) => (
                <EnhancedCol
                  onClick={() => this.openModal(employee)}
                  key={employee.id}
                  xs={{ span: 24 }}
                  lg={{ span: 8 }}
                  style={{ marginBottom: '20px' }}
                >
                  <EnhancedCard
                    className="event-card"
                    bordered={false}
                    bodyStyle={{
                      padding: '15px',
                      height: '124px',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    <EnhancedCol lg={4}>
                      <EnhancedAvatar
                        className="event-avatar"
                        src={employee.imageUrl}
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '100%',
                          background: '#0D2234',
                        }}
                      >
                        {employee.name.match(/\b(\w)/g).join('')}
                      </EnhancedAvatar>
                    </EnhancedCol>
                    <EnhancedCol lg={19} offset={1}>
                      <li>
                        <strong>{employee.name}</strong>
                      </li>
                      <li>{employee.designation}</li>
                      {employee.department !== 'not_set' && <li>{employee.department}</li>}
                      {employee.location !== 'Not Set' && <li>{employee.location}</li>}
                    </EnhancedCol>
                  </EnhancedCard>
                </EnhancedCol>
              ))
            ) : (
              <p style={{ margin: '0 auto 20px', textAlign: 'center', fontWeight: 'bold' }}>
                {' '}
                No employee found.
              </p>
            )}
            {emp && (
              <EmployeeDirectoryModal employee={emp} hideModal={this.hideModal} visible={visible} />
            )}
          </EnhancedRow>
        )}
        {!loading && showMoreButton ? (
          <EnhancedButton
            block="true"
            className="btn_center"
            style={{ alignItems: 'center' }}
            onClick={this.props.loadMoreItems}
          >
            View More
          </EnhancedButton>
        ) : null}
      </div>
    );
  }
}

export default EmployeeDirectoryCard;
