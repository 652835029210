import React from 'react';
import { Menu } from 'antd';
import PropTypes from 'prop-types';

const EnhancedMenu = (props) => {
  const {
    children,
    defaultSelectedKeys,
    selectedKeys,
    className,
    onDeselect,
    onOpenChange,
    onSelect,
    style,
    mode,
    onClick,
    theme,
    selectable,
    subMenuCloseDelay,
    triggerSubMenuAction,
    subMenuOpenDelay,
    forceSubMenuRender,
    inlineCollapsed,
    inlineIndent,
    item,
    ...rest
  } = props;
  return (
    <Menu
      className={className}
      style={style}
      onDeselect={onDeselect}
      onOpenChange={onOpenChange}
      onSelect={onSelect}
      triggerSubMenuAction={triggerSubMenuAction}
      onClick={onClick}
      theme={theme}
      mode={mode}
      defaultSelectedKeys={defaultSelectedKeys}
      selectedKeys={selectedKeys}
      selectable={selectable}
      subMenuCloseDelay={subMenuCloseDelay}
      subMenuOpenDelay={subMenuOpenDelay}
      forceSubMenuRender={forceSubMenuRender}
      inlineCollapsed={inlineCollapsed}
      item={item}
      inlineIndent={inlineIndent}
      {...rest}
    >
      {children}
    </Menu>
  );
};

EnhancedMenu.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
  onDeselect: PropTypes.func,
  onOpenChange: PropTypes.func,
  onSelect: PropTypes.func,
  triggerSubMenuAction: PropTypes.string,
  theme: PropTypes.string,
  mode: PropTypes.string,
  selectable: PropTypes.bool,
  subMenuCloseDelay: PropTypes.number,
  subMenuOpenDelay: PropTypes.number,
  forceSubMenuRender: PropTypes.bool,
  inlineIndent: PropTypes.number,
  selectedKeys: PropTypes.arrayOf(PropTypes.string),
  defaultSelectedKeys: PropTypes.arrayOf(PropTypes.string),
  item: PropTypes.arrayOf(PropTypes.shape({})),
  inlineCollapsed: PropTypes.bool,
};

EnhancedMenu.defaultProps = {
  className: '',
  style: {},
  onDeselect: () => {},
  onOpenChange: () => {},
  onSelect: () => {},
  onClick: () => {},
  triggerSubMenuAction: 'hover',
  theme: 'light',
  mode: 'vertical',
  selectable: true,
  subMenuCloseDelay: 0.1,
  subMenuOpenDelay: 0,
  forceSubMenuRender: false,
  inlineIndent: 24,
  selectedKeys: [],
  defaultSelectedKeys: [],
  item: [],
  inlineCollapsed: false,
};

// static properties
EnhancedMenu.Item = Menu.Item;
EnhancedMenu.SubMenu = Menu.SubMenu;

export default EnhancedMenu;
