import React from 'react';
import { EnhancedTypography } from '../shared/antd';

function generatePerClientAndTrackColumns(state, fn) {
  const [{ trackList, projectList }] = [state, fn];

  return [
    {
      title: 'Frequency',
      key: 'frequency',
      dataIndex: 'frequency',
      width: '25%',
      sorter: (a, b) => a.frequency - b.frequency,
    },
    {
      title: 'Question',
      key: 'question',
      dataIndex: 'question',
      width: '25%',
      sorter: (a, b) => a.question.localeCompare(b.question),
      filterConfig: {
        type: 'search',
        key: 'question',
      },
      render: (question) => (
        <EnhancedTypography copyable style={{ whiteSpace: 'pre-wrap' }}>
          {question}
        </EnhancedTypography>
      ),
    },
    {
      title: 'Client',
      key: 'project.name',
      dataIndex: 'project.name',
      width: '25%',
      ellipsis: true,
      render: (_text, record) => (record.project ? record.project.name : ''),
      sorter: (a, b) => a.project.name.localeCompare(b.project.name),
      filterConfig: {
        type: 'enhanced_filter',
        key: 'project.name',
        optionsConfig: {
          values: projectList,
        },
      },
    },
    {
      title: 'Track',
      key: 'track.name',
      dataIndex: 'track.name',
      width: 'auto',
      render: (_text, record) => (record.track ? record.track.name : ''),
      sorter: (a, b) => a.track.name.localeCompare(b.track.name),
      filterConfig: {
        type: 'enhanced_filter',
        key: 'track.name',
        optionsConfig: {
          values: trackList,
        },
      },
    },
  ];
}

export default generatePerClientAndTrackColumns;
