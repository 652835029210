import React from 'react';
import { EnhancedModal } from 'components/shared/antd';
import moment from 'moment';
import LogWorkContainer from 'containers/LogWorkContainer';
import { dateFormat } from 'configs/constants';

const LogWorkModal = (props) => {
  const title = props.editData ? 'Editing Logs For' : 'Adding Work For';
  const date = moment(props.date).format(dateFormat.DDMMMYYYYwithSpace);

  return (
    <EnhancedModal
      title={<Title title={title} date={date} />}
      visible={props.visible}
      onCancel={props.handleCancel}
      footer={null}
    >
      <LogWorkContainer
        selectedWorkId={props.selectedWorkId}
        editData={props.editData}
        date={props.date}
        closeModal={props.handleCancel}
      />
    </EnhancedModal>
  );
};

const Title = (props) => (
  <span>
    {props.title} <strong>{props.date}</strong>
  </span>
);

export default LogWorkModal;
