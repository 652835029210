import {
  GET_TEAM_LEAVES_BALANCE_INIT,
  GET_TEAM_LEAVES_BALANCE_SUCCESS,
  GET_TEAM_LEAVES_BALANCE_FAILURE,
  GET_COMPANY_WIDE_DASHBOARD_INIT,
  GET_COMPANY_WIDE_DASHBOARD_SUCCESS,
  GET_COMPANY_WIDE_DASHBOARD_FAILURE,
  ADJUST_LEAVES_QUOTA_INIT,
  ADJUST_LEAVES_QUOTA_SUCCESS,
  ADJUST_LEAVES_QUOTA_FAILURE,
  GET_LEAVES_ADJUSTMENTS_LOGS_INIT,
  GET_LEAVES_ADJUSTMENTS_LOGS_SUCCESS,
  GET_LEAVES_ADJUSTMENTS_LOGS_FAILURE,
} from 'constants/LeaveDashboard';

const initialState = {
  teamLeaveBalance: [],
  isLoading: false,
  pageInfo: {},
  companyWideDashboard: [],
  adjustLeaveQuotaLogs: [],
  adjustLeaveQuotaLogsPageInfo: {},
  adjustLeaveQuota: false,
};

export default function teamLeaveBalanceReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TEAM_LEAVES_BALANCE_INIT: {
      return Object.assign({}, state, {
        teamLeaveBalance: [],
        pageInfo: {},
        isLoading: true,
      });
    }
    case GET_TEAM_LEAVES_BALANCE_SUCCESS: {
      return Object.assign({}, state, {
        teamLeaveBalance: action.data.leaveData,
        pageInfo: action.data.pageInfo,
        isLoading: false,
      });
    }
    case GET_TEAM_LEAVES_BALANCE_FAILURE: {
      return Object.assign({}, state, {
        ...state,
        isLoading: false,
      });
    }
    case GET_COMPANY_WIDE_DASHBOARD_INIT: {
      return Object.assign({}, state, {
        companyWideDashboard: [],
        pageInfo: {},
        isLoading: true,
      });
    }
    case GET_COMPANY_WIDE_DASHBOARD_SUCCESS: {
      return Object.assign({}, state, {
        companyWideDashboard: action.data.companyWideDashboard,
        pageInfo: action.data.pageInfo,
        isLoading: false,
      });
    }
    case GET_COMPANY_WIDE_DASHBOARD_FAILURE: {
      return Object.assign({}, state, {
        ...state,
        isLoading: false,
      });
    }
    case ADJUST_LEAVES_QUOTA_INIT: {
      return Object.assign({}, state, {
        isLoading: true,
        adjustLeaveQuota: false,
      });
    }
    case ADJUST_LEAVES_QUOTA_SUCCESS: {
      return Object.assign({}, state, {
        adjustLeaveQuota: true,
        isLoading: false,
      });
    }
    case ADJUST_LEAVES_QUOTA_FAILURE: {
      return Object.assign({}, state, {
        ...state,
        isLoading: false,
        adjustLeaveQuota: false,
      });
    }
    case GET_LEAVES_ADJUSTMENTS_LOGS_INIT: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }
    case GET_LEAVES_ADJUSTMENTS_LOGS_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        adjustLeaveQuotaLogs: action.data.rows,
        adjustLeaveQuotaLogsPageInfo: action.data.pageInfo,
      });
    }
    case GET_LEAVES_ADJUSTMENTS_LOGS_FAILURE: {
      return Object.assign({}, state, {
        ...state,
        isLoading: false,
      });
    }
    default:
      return state;
  }
}
