import * as ExpenseClaimTypes from 'constants/ExpenseClaimTypes';

const initialState = {
  isLoading: false,
  isClaimForFinanceSuccessful: false,
  isClaimForHRSuccessful: false,
  isSuccessfull: false,
  isTeamClaimSuccessfull: false,
  claimImagesSuccess: false,
  claimList: [],
  myClaims: [],
  teamClaims: [],
  claimForFinance: [],
  claimForHR: [],
  claimTypes: [],
  claimCategory: [],
  claimImages: [],
  claimProjectList: [],
};

export default function getClaimReducer(state = initialState, action) {
  switch (action.type) {
    case ExpenseClaimTypes.CLAIM_PROJECT_LIST_SUCCESS: {
      return {
        ...state,
        claimProjectList: action.claimProjectList,
      };
    }
    case ExpenseClaimTypes.CLAIM_PROJECT_LIST_FAILURE: {
      return {
        ...state,
        claimProjectList: [],
      };
    }
    case ExpenseClaimTypes.GET_CLAIMS_INIT: {
      return {
        ...state,
        isLoading: true,
        isSuccessfull: false,
      };
    }
    case ExpenseClaimTypes.GET_CLAIMS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccessfull: true,
        claimList: action.myClaims,
      };
    }
    case ExpenseClaimTypes.GET_CLAIMS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isSuccessfull: false,
        claimList: [],
      };
    }

    case ExpenseClaimTypes.GET_TEAM_CLAIMS_INIT: {
      return {
        ...state,
        isLoading: true,
        isTeamClaimSuccessfull: false,
      };
    }
    case ExpenseClaimTypes.GET_TEAM_CLAIMS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isTeamClaimSuccessfull: true,
        claimList: action.teamClaims,
      };
    }
    case ExpenseClaimTypes.GET_TEAM_CLAIMS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isTeamClaimSuccessfull: false,
        claimList: [],
      };
    }
    // For finance
    case ExpenseClaimTypes.GET_CLAIM_FOR_FINANCE_INIT: {
      return {
        ...state,
        isLoading: true,
        isClaimForFinanceSuccessful: false,
      };
    }
    case ExpenseClaimTypes.GET_CLAIM_FOR_FINANCE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isClaimForFinanceSuccessful: true,
        claimList: action.claimForFinance,
      };
    }
    case ExpenseClaimTypes.GET_CLAIM_FOR_FINANCE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isClaimForFinanceSuccessful: false,
        claimList: [],
      };
    }
    case ExpenseClaimTypes.GET_CLAIM_FOR_HR_INIT: {
      return {
        ...state,
        isLoading: true,
        isClaimForHRSuccessful: false,
        claimList: [],
      };
    }
    case ExpenseClaimTypes.GET_CLAIM_FOR_HR_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isClaimForHRSuccessful: true,
        claimList: action.claimForHR,
      };
    }
    case ExpenseClaimTypes.GET_CLAIM_FOR_HR_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isClaimForHRSuccessful: false,
        claimList: [],
      };
    }

    case ExpenseClaimTypes.GET_CLAIM_TYPES_SUCCESS: {
      const category = [];
      const types = [];
      if (action.claimTypes) {
        action.claimTypes.forEach((v) => {
          if (!v.parentId) {
            category.push(v);
          } else {
            types.push(v);
          }
        });
      }
      return {
        ...state,
        claimTypes: types,
        claimCategory: category,
      };
    }

    case ExpenseClaimTypes.GET_CLAIM_TYPES_FAILURE: {
      return {
        ...state,
        claimTypes: [],
        claimCategory: [],
      };
    }
    case ExpenseClaimTypes.GET_CLAIM_IMAGES_SUCCESS: {
      return {
        ...state,
        claimImagesSuccess: true,
        claimImages: action.claimImages,
      };
    }
    case ExpenseClaimTypes.GET_CLAIM_IMAGES_FAILURE: {
      return {
        ...state,
        claimImagesSuccess: false,
        claimImages: [],
      };
    }
    default:
      return state;
  }
}
