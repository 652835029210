/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { getHistoryInit, getHistorySuccess, getHistoryFailure } from 'store/actions/HistoryActions';
import { workLogUrls } from 'configs/constants';
import { normalizeHistory } from 'normalizers/historyNormalizers';

export const getEmployeeWorkLog = (employeeId) => {
  const url = workLogUrls.GET.replace('{employeeId}', employeeId);
  return (dispatch) => {
    dispatch(getHistoryInit());
    return axios
      .get(url)
      .then((response) => {
        // API response
        // parse response through normalizer
        if (response.status === 200) {
          const history = normalizeHistory(response.data.data);
          // call success or failure action
          dispatch(getHistorySuccess(history));
        } else {
          dispatch(getHistoryFailure(response.message));
        }
      })
      .catch((error) => {
        dispatch(getHistoryFailure(error));
      });
  };
};
