import { EnhancedRow, EnhancedCol } from 'components/shared/antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// Images
import { PER_PAGE } from 'configs/cmsConstants';
import { getNewsAction } from 'store/actions/NewsActions';
import ArticleSection from './ArticleSection';

class ArticleContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: props.news,
    };
  }

  componentWillMount() {
    this.props.getNewsAction(PER_PAGE);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.news !== this.props.news) {
      const { news } = nextProps;
      this.setState({ news });
    }
  }

  // renderNews = () => {}
  render() {
    const { news } = this.state;
    const linkToNewsOne = `/news/${news[0] && news[0].id}`;
    const linkToNewsTwo = `/news/${news[1] && news[1].id}`;
    const linkToNewsThree = `/news/${news[2] && news[2].id}`;
    const linkToNewsFour = `/news/${news[3] && news[3].id}`;
    return (
      <EnhancedRow type="flex" justify="start">
        <EnhancedCol lg={{ span: 14 }} xs={{ span: 24 }}>
          <Link
            to={news[0] ? linkToNewsOne : '#'}
            className={news[0] ? 'null' : 'pointer-disabled'}
          >
            <ArticleSection
              title={news[0] && news[0].title}
              imgSrc={news[0] && news[0].coverImage}
              size="large"
              height="36.5vh"
            />
          </Link>
        </EnhancedCol>
        <EnhancedCol lg={{ span: 9 }} xs={{ span: 24 }} style={{ marginLeft: '2%' }}>
          <EnhancedRow type="flex" justify="start">
            <EnhancedCol lg={{ span: 11 }} xs={{ span: 12 }} style={{ marginBottom: '4%' }}>
              <Link
                to={news[1] ? linkToNewsTwo : '#'}
                className={news[1] ? 'null' : 'pointer-disabled'}
              >
                <ArticleSection
                  title={news[1] && news[1].title}
                  imgSrc={news[1] && news[1].coverImage}
                  size="small"
                  className="article-container"
                  height="17vh"
                />
              </Link>
            </EnhancedCol>
            <EnhancedCol
              lg={{ span: 11 }}
              xs={{ span: 12 }}
              offset={1}
              style={{ marginBottom: '2%' }}
            >
              <Link
                to={news[2] ? linkToNewsThree : '#'}
                className={news[2] ? 'null' : 'pointer-disabled'}
              >
                <ArticleSection
                  title={news[2] && news[2].title}
                  imgSrc={news[2] && news[2].coverImage}
                  size="small"
                  className="article-container"
                  height="17vh"
                />
              </Link>
            </EnhancedCol>
            <EnhancedCol lg={{ span: 11 }} xs={{ span: 12 }} style={{ marginRight: '2%' }}>
              <Link
                to={news[3] ? linkToNewsFour : '#'}
                className={news[3] ? 'null' : 'pointer-disabled'}
              >
                <ArticleSection
                  title={news[3] && news[3].title}
                  imgSrc={news[3] && news[3].coverImage}
                  size="small"
                  className="article-container"
                  height="17vh"
                />
              </Link>
            </EnhancedCol>
            <EnhancedCol
              lg={{ span: 11 }}
              xs={{ span: 12 }}
              offset={1}
              style={{ marginTop: '0', marginLeft: '2%' }}
            >
              <Link to="/news">
                <ArticleSection
                  backgroundColor="rgb(13,34,52)"
                  size="small"
                  className="article-container"
                  height="17vh"
                  noCaption
                />
              </Link>
            </EnhancedCol>
          </EnhancedRow>
        </EnhancedCol>
      </EnhancedRow>
    );
  }
}

function mapStateToProps(state) {
  const { appReducer } = state;
  return {
    news: appReducer.news,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getNewsAction: (count) => dispatch(getNewsAction(count)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleContainer);
