import * as AddEmployeeTypes from 'constants/AddEmployeeTypes';

const initialState = {
  isLoading: false,
  isSuccessfull: false,
  error: null,
};

export default function addEmployeeReducer(state = initialState, action) {
  switch (action.type) {
    case AddEmployeeTypes.RESET_INITIAL_STATE: {
      return initialState;
    }
    case AddEmployeeTypes.ADD_EMPLOYEE_INIT: {
      return {
        ...state,
        isLoading: true,
        isSuccessfull: false,
      };
    }
    case AddEmployeeTypes.ADD_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isLoading: false,
        isSuccessfull: true,
      };
    }
    case AddEmployeeTypes.ADD_EMPLOYEE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isSuccessfull: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
