import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import crmcCyclesNormalizers from 'normalizers/crmcCyclesNormalizers';
import { dateFormat, BREADCRUMBS, CRMC_FILE_TITLE } from 'configs/constants';
import projectIcon from 'images/project_icon.svg';
import { CSVLink } from 'react-csv';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import {
  EnhancedCol,
  EnhancedRow,
  EnhancedCard,
  EnhancedIcon,
  EnhancedMenu,
  EnhancedDropdown,
  EnhancedButton,
} from 'components/shared/antd';

class PRProject extends Component {
  componentWillMount() {
    const { getProjectReviewsByCycleIdAction } = this.props;
    getProjectReviewsByCycleIdAction(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    const { projectCycleData, updateBreadCrumb, match } = nextProps;
    const { cycleInfo = {} } = projectCycleData;
    const { cycleName = '' } = cycleInfo;
    const {
      params: { id },
    } = match;
    if (cycleName) {
      updateBreadCrumb(BREADCRUMBS.PROJECT_REVIEWS_CYCLE, id, cycleName);
    }
  }

  getColumnsInfo = () => {
    const columns = [
      {
        title: '',
        dataIndex: 'dueDateIndicator',
        key: 'dueDateIndicator',
        render: this.renderDueDateIndicator,
        excludeFromExport: true,
      },
      {
        title: 'Project Name',
        dataIndex: 'name',
        key: 'name',
        width: '15%',
        filterConfig: {
          type: 'search',
          key: 'name',
        },
        render: this.renderProjectName,
      },
      {
        title: 'Project Manager',
        dataIndex: 'projectManager',
        key: 'projectManager',
        width: '15%',
        filterConfig: {
          type: 'search',
          key: 'projectManager',
        },
      },
      {
        title: 'Project Architect',
        dataIndex: 'projectArchitect',
        key: 'projectArchitect',
        width: '15%',
        filterConfig: {
          type: 'search',
          key: 'projectArchitect',
        },
        render: this.renderProjectArchitect,
      },
      {
        title: 'Project Score',
        dataIndex: 'projectAverageRating',
        key: 'projectAverageRating',
        width: '15%',
        sorter: (a, b) => a.projectAverageRating.localeCompare(b.projectAverageRating),
      },
      {
        title: 'Average Rating received by PM',
        dataIndex: 'pmAverageRating',
        key: 'pmAverageRating',
        width: '20%',
        sorter: (a, b) => a.pmAverageRating.localeCompare(b.pmAverageRating),
      },
      {
        title: 'Average Rating received by Architect',
        dataIndex: 'architectAverageRating',
        key: 'architectAverageRating',
        width: '20%',
        render: this.renderArchitectRating,
        // sorter: (a, b) => a.renderArchitectRating.localeCompare(b.renderArchitectRating),
      },
    ];
    return columns;
  };

  isCycleComplete = () => {
    const { projectCycleData } = this.props;
    let { cycleInfo } = projectCycleData;
    cycleInfo = cycleInfo || {};
    if (cycleInfo.status) {
      return true;
    }
    return false;
  };

  showTotal = (total) => `Total ${total} items`;

  handleExportMenuClick = (event) => {
    if (event.key === 'ratings') {
      const { cycleInfo } = this.props.projectCycleData;
      this.props.downloadResourceRatingsAction(cycleInfo);
    }
  };

  renderProjectName = (name, data) => (
    <Link to={`/pc/project-reviews/${this.props.match.params.id}/${data.id}`}>
      <b style={{ color: '#5A5A5A' }}>{name}</b>
    </Link>
  );

  renderProjectArchitect = (pmRatings, data) => {
    if (data.projectArchitect) {
      return data.projectArchitect;
    }
    return '-';
  };

  renderArchitectRating = (architectRating, data) => {
    if (data.hasArchitect) {
      return architectRating;
    }
    return '-';
  };

  renderIcon = (data) => (
    <div>
      <EnhancedIcon onClick={() => this.confirmDelete(data)} type="delete" />
    </div>
  );

  render() {
    const { isLoading } = this.props;
    const { projectCycleData } = this.props;
    const projectCycles = projectCycleData.projectCycles || [];
    const { cycleInfo } = projectCycleData;
    const fileName = CRMC_FILE_TITLE.CYCLE_DETAILS;
    const dataForExcelFile = crmcCyclesNormalizers(
      this.getColumnsInfo(),
      JSON.parse(JSON.stringify(projectCycles)),
    );

    const exportRatingsMenu = (
      <EnhancedMenu onClick={this.handleExportMenuClick}>
        <EnhancedMenu.Item key="summary">
          <CSVLink
            data={dataForExcelFile}
            filename={`${fileName} (${
              cycleInfo && cycleInfo.cycleName ? cycleInfo.cycleName : ''
            }).csv`}
          >
            Summary
          </CSVLink>
        </EnhancedMenu.Item>
        <EnhancedMenu.Item key="ratings">Detailed Reporting</EnhancedMenu.Item>
      </EnhancedMenu>
    );

    return (
      <div>
        <div style={{ background: 'white', marginBottom: '14px' }}>
          <EnhancedCard bordered={false}>
            <EnhancedRow>
              <EnhancedCol lg={2}>
                <img src={projectIcon} alt="logo" style={{ width: '60px', height: '60px' }} />
              </EnhancedCol>
              <EnhancedCol lg={20}>
                <h2 style={{ color: 'black', fontSize: '10' }}>
                  {`Cycle Details - ${cycleInfo && cycleInfo.cycleName ? cycleInfo.cycleName : ''}`}
                </h2>
                <p>
                  {`${
                    cycleInfo && cycleInfo.startDate
                      ? moment(cycleInfo.startDate).format(dateFormat.DDMMMYYYYwithSpace)
                      : ''
                  }
                      -
                      ${
                        cycleInfo && cycleInfo.endDate
                          ? moment(cycleInfo.endDate).format(dateFormat.DDMMMYYYYwithSpace)
                          : ''
                      }`}
                </p>
                <p>
                  {`Eligiblity criteria: ${
                    cycleInfo && cycleInfo.criteria ? cycleInfo.criteria : ''
                  } days`}
                </p>
              </EnhancedCol>
              <EnhancedCol lg={2}>
                {projectCycles && projectCycles.length ? (
                  <EnhancedDropdown overlay={exportRatingsMenu}>
                    <EnhancedButton>
                      Export <EnhancedIcon type="down" />
                    </EnhancedButton>
                  </EnhancedDropdown>
                ) : (
                  ''
                )}
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCard>
        </div>
        <EnhancedCol lg={24} style={{ backgroundColor: 'white' }}>
          <EnhancedClientSideTable
            loading={isLoading}
            columns={this.getColumnsInfo()}
            data={projectCycles}
          />
        </EnhancedCol>
      </div>
    );
  }
}

export default PRProject;
