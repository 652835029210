import {
  EnhancedCol,
  EnhancedCard,
  EnhancedRow,
  EnhancedPagination,
  EnhancedDatePicker,
} from 'components/shared/antd';
import React from 'react';
import { dateFormat } from 'configs/constants';
import ActivityLogCard from 'components/project/ActivityLogCard';
import './ProjectEmployees.css';

class ActivityLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: undefined,
      currentPageNumber: 1,
      totalPages: props.activityLogs.totalPages || 1,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.activityLogs.totalPages) {
      this.setState({
        totalPages: nextProps.activityLogs.totalPages,
      });
    }
  }
  onDateFilterChange = (date) => {
    this.setState({ date });
  };
  onPageNumberChange = (currentPageNumber) => {
    this.setState({
      currentPageNumber,
    });
    this.props.activityLogAction(this.props.projectId, currentPageNumber);
  };
  getTotal = () => {
    const { date, totalPages } = this.state;
    const { activityLogs } = this.props;
    if (date) {
      const formattedDate = date.format(dateFormat.DDMMMYYYYwithSpace);
      if (activityLogs.activityLog[formattedDate]) {
        return activityLogs.activityLog[formattedDate].length;
      }

      return 0;
    }
    return totalPages * activityLogs.numberOfActivities;
  };
  showTotal = () => {
    const { activityLogs } = this.props;
    const { date } = this.state;
    if (date) {
      const formattedDate = date.format(dateFormat.DDMMMYYYYwithSpace);
      if (activityLogs.activityLog[formattedDate]) {
        return `Total ${activityLogs.activityLog[formattedDate].length} items`;
      }

      return 'Total 0 items';
    }
    return `Total ${activityLogs.numberOfActivities} items`;
  };
  renderLogs = () => {
    const { activityLogs } = this.props;
    if (activityLogs && activityLogs.activityLog && activityLogs.numberOfActivities !== 0) {
      if (this.state.date) {
        const formattedDate = this.state.date.format(dateFormat.DDMMMYYYYwithSpace);
        return Object.keys(activityLogs.activityLog)
          .filter((date) => date === formattedDate)
          .map((key) => <ActivityLogCard logs={activityLogs.activityLog[key]} keys={`${key}`} />);
      }
      return Object.keys(activityLogs.activityLog).map((key) => (
        <ActivityLogCard logs={activityLogs.activityLog[key]} keys={`${key}`} />
      ));
    }
    return <ActivityLogCard logs="" keys="" />;
  };
  render() {
    const { activityLogs } = this.props;
    const { date, currentPageNumber } = this.state;
    return (
      <EnhancedCard style={{ marginTop: '20px' }} loading={this.props.isLoading}>
        <EnhancedRow>
          {activityLogs.numberOfActivities !== 0 && (
            <EnhancedCol span={24} style={{ textAlign: 'right' }}>
              <EnhancedDatePicker
                style={{ width: '25%' }}
                onChange={this.onDateFilterChange}
                value={date}
              />
            </EnhancedCol>
          )}
        </EnhancedRow>
        <EnhancedRow>
          <EnhancedCol span={24}>
            {this.renderLogs().length ? (
              this.renderLogs()
            ) : (
              <p style={{ textAlign: 'center' }}>No record found</p>
            )}
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedPagination
          current={currentPageNumber}
          size="small"
          total={this.getTotal()}
          style={{ float: 'right', textAlign: 'center' }}
          onChange={this.onPageNumberChange}
          pageSize={activityLogs ? activityLogs.numberOfActivities : 10}
          showTotal={this.showTotal}
        />
      </EnhancedCard>
    );
  }
}

export default ActivityLog;
