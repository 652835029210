import moment from 'moment';

export function normalizeProjectReviewsCycles(res) {
  return res.map((data) => {
    const projectReviewsCycles = data || {};
    return {
      id: projectReviewsCycles.id,
      name: projectReviewsCycles.cycleName,
      pmReviewed: projectReviewsCycles.pmReviewed,
      archReviewed: projectReviewsCycles.archReviewed,
      projectCount: projectReviewsCycles.projectCount,
      status: projectReviewsCycles.isActive ? 'Actived' : 'Complete',
      criteria: projectReviewsCycles.criteria || 0,
      createdAt: projectReviewsCycles.createdAt
        ? moment(projectReviewsCycles.createdAt).format('DD-MMM-YYYY')
        : null,
      endDate: projectReviewsCycles.endDate
        ? moment(projectReviewsCycles.endDate).format('DD-MMM-YYYY')
        : null,
      dueDate: projectReviewsCycles.dueDate
        ? moment(projectReviewsCycles.dueDate).format('DD-MMM-YYYY')
        : null,
    };
  });
}

export function normalizeProjectReviewsProjects(res) {
  const resultData = {};
  resultData.cycleInfo = res.cycleInfo;
  resultData.projectCycles = res.projectCycles
    .map((data) => {
      const projectsCycle = data || {};
      return {
        id: projectsCycle.id,
        name: projectsCycle.name,
        projectManager: projectsCycle.projectManager || ' - ',
        projectArchitect: projectsCycle.projectArchitect || ' - ',
        projectAverageRating: projectsCycle.projectAverageRating,
        pmAverageRating: projectsCycle.pmAverageRating,
        architectAverageRating: projectsCycle.architectAverageRating,
        hasArchitect: projectsCycle.hasArchitect,
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
  return resultData;
}

export function normalizeProjectReviewsRating(res) {
  const resultData = {};
  resultData.projectName = res.projectName;
  resultData.cycleName = res.cycleName;
  resultData.completeStatus = res.completeStatus;
  resultData.dueDate = res.dueDate;
  resultData.initiationDate = res.initiationDate;
  const projectRatings = res.ratings
    .map((data) => {
      const ProjectRating = data || {};
      const rating = {
        employeeId: ProjectRating.employeeId,
        name: ProjectRating.employeeName,
        department: ProjectRating.competency,
        lineManager: ProjectRating.lineManagerName,
        PMStatus: ProjectRating.PMStatus,
        archStatus: ProjectRating.archStatus,
        feedbackStatus: ProjectRating.feedbackStatus,
        projectName: ProjectRating.projectName,
        projectId: ProjectRating.projectId,
        competency: ProjectRating.competency,
      };
      return rating;
    })
    .sort((a, b) => a.name.localeCompare(b.name));
  resultData.projectRatings = projectRatings;
  return resultData;
}

export function normalizeProjectReviewsRatingData(res) {
  const ratingsData = {};
  ratingsData.dueDate = res.dueDate;
  ratingsData.performanceCycleId = res.performanceCycleId;
  ratingsData.projectId = res.projectId;
  ratingsData.ratings = res.ratings.map((data) => {
    const ratingData = data || {};
    const rating = {
      freeze: ratingData.freeze,
      rateeId: ratingData.rateeId,
      rateeName: ratingData.rateeName,
      rateeRole: ratingData.rateeRole,
      rateeType: ratingData.rateeType,
      raterId: ratingData.raterId,
      raterName: ratingData.raterName,
      ratingId: ratingData.ratingId,
      ratingAttributes: ratingData.ratingAttributes.map((response) => response),
    };
    return rating;
  });
  return ratingsData;
}
