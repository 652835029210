import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedModal,
} from 'components/shared/antd';
import React from 'react';
import { createComments, noData, createFields } from 'utils/FieldGenerateUtils';
import { REQUIRED } from 'configs/validationConstants';
import {
  INPUT,
  COMMENTS as stateName,
  COMMENT,
  LABEL_SIZE_XS,
  VALUE_SIZE_XS,
  LABEL_SIZE_MD,
  VALUE_SIZE_MD,
  ROW_GUTTER_SIZE,
} from 'configs/employeeProfileConstants';
import { hasPermission } from 'utils/AccessUtils';
import Permissions from 'constants/AccessPermissions';
import {
  DELETE_CONFIRM_PROMPT_MESSAGE,
  // DELETE_CONFIRM_PROMPT_WARNING_MESSAGE,
} from 'configs/messageConstants';
import AddButton from 'components/shared/employeeProfile/AddButton';
import confirmModal from '../../shared/ConfirmModal';

class CommentsIncidents extends React.PureComponent {
  constructor(props) {
    super(props);
    this.isAttachmentFetching = false;
    this.documentURL = null;
    this.attachmentRef = React.createRef();
    this.state = {
      [stateName]: this.props[stateName] || [],
      createModalVisible: false,
      disableCreateButton: false,
      newCommentTitle: '',
      newCommentContent: '',
      attachment: null,
      errors: {
        newCommentTitle: true,
        newCommentContent: true,
      },
      disableSaveButton: true,
    };
  }
  componentDidMount() {
    const employeeId = this.props.match.params.id;
    this.props.getEmployeeComment(employeeId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.commentAttachmentUrl && this.isAttachmentFetching) {
      this.isAttachmentFetching = false;
      this.attachmentRef.current.href = nextProps.commentAttachmentUrl;
      this.attachmentRef.current.click();
    }

    if (nextProps[stateName] !== this.props[stateName]) {
      this.setState({ [stateName]: nextProps[stateName] });
    }
  }

  getAttachment = (commentId) => {
    const employeeId = this.props.match.params.id;
    this.documentURL = null;
    this.isAttachmentFetching = true;
    this.props.getCommentAttachmentURL(employeeId, commentId);
  };

  handleChange = (value, key, error) => {
    const currentData = this.state;
    const newData = Object.assign({}, currentData, { [key]: value });
    this.setState({ ...newData });

    const { errors } = this.state;
    if (error) {
      this.setState({
        errors: { ...errors, [key]: true },
        disableSaveButton: true,
      });
    } else {
      // Using currentData.errors here instead triggers eslint unused state for some reason.
      this.setState({
        errors: { ...errors, [key]: false },
      });
      const noError = Object.values(errors).every((valueError) => valueError === false);
      if (noError) {
        this.setState({ disableSaveButton: false });
      }
    }
  };

  showCreateModal = () => {
    this.setState({
      createModalVisible: true,
      errors: {
        newCommentTitle: true,
        newCommentContent: true,
      },
    });
    this.clearNewComment();
  };

  handleCreate = () => {
    const employeeId = this.props.match.params.id;
    const { newCommentTitle, newCommentContent, attachment } = this.state;
    const dataToCreate = {
      title: newCommentTitle,
      content: newCommentContent,
    };
    if (attachment) {
      dataToCreate.attachment = attachment;
    }
    this.props.createEmployeeComment(employeeId, dataToCreate);
    this.closeCreateModal();
  };

  handleDelete = (commentId) => {
    confirmModal(DELETE_CONFIRM_PROMPT_MESSAGE, () => {
      const employeeId = this.props.match.params.id;
      this.props.deleteEmployeeComment(employeeId, commentId);
    });
  };

  hanldeFileLoad = (evt) => {
    const attachment = evt.target.result;
    this.setState({ attachment });
  };

  handleFileUpload = (evt) => {
    const file = evt.target.files[0];
    const reader = new FileReader();
    reader.onload = this.hanldeFileLoad;
    reader.fileName = file.name;
    reader.readAsDataURL(file);
  };

  closeCreateModal = () => {
    this.setState({
      disableCreateButton: false,
      createModalVisible: false,
      attachment: null,
    });
  };

  clearNewComment = () => {
    this.setState({
      newCommentTitle: '',
      newCommentContent: '',
    });
  };

  render() {
    const { isLoading } = this.props;
    const comments = Object.values(this.props[stateName]) || [];
    const {
      disableCreateButton,
      createModalVisible,
      disableSaveButton,
      newCommentTitle,
      newCommentContent,
    } = this.state;
    const { userPermissions } = this.props;
    const canCreate = hasPermission(userPermissions, [
      Permissions.Employee.Profile.Comments.Create,
    ]);
    const canDelete = hasPermission(userPermissions, [
      Permissions.Employee.Profile.Comments.Delete,
    ]);

    const newComment = [
      {
        key: `newCommentTitle`,
        label: 'Subject',
        value: newCommentTitle,
        type: INPUT,
        validations: [REQUIRED],
      },
      {
        key: `newCommentContent`,
        label: 'Comment',
        value: newCommentContent,
        type: COMMENT,
        validations: [REQUIRED],
      },
    ];

    return (
      <EnhancedCard loading={isLoading} className="mt-20" bordered={false}>
        {canCreate && (
          <React.Fragment>
            <div className="flex justify-flex-end">
              <AddButton
                clickHandler={this.showCreateModal}
                buttonText="Add New"
                disabled={disableCreateButton}
                buttonClasses={['createCardSectionButton']}
              />
            </div>
            <EnhancedModal
              title="Add Comment"
              visible={createModalVisible}
              closable={false}
              footer={[
                <EnhancedButton key="back" onClick={this.closeCreateModal}>
                  Cancel
                </EnhancedButton>,
                <EnhancedButton
                  key="submit"
                  type="primary"
                  disabled={disableSaveButton}
                  onClick={this.handleCreate}
                >
                  Submit
                </EnhancedButton>,
              ]}
            >
              <React.Fragment>
                {createFields(newComment, 1, true, this.props.userPermissions, this.handleChange)}
                <EnhancedRow gutter={ROW_GUTTER_SIZE}>
                  <EnhancedCol xs={LABEL_SIZE_XS} md={LABEL_SIZE_MD}>
                    <b className="label">Attachment</b>
                  </EnhancedCol>
                  <EnhancedCol xs={VALUE_SIZE_XS} md={VALUE_SIZE_MD}>
                    <input
                      type="file"
                      id="element"
                      accept="application/pdf,image/png,image/jpeg,.doc,.docx"
                      onChange={this.handleFileUpload}
                    />
                    {/* {errors.uploadFile ? errors.uploadFile : null} */}
                  </EnhancedCol>
                </EnhancedRow>
              </React.Fragment>
            </EnhancedModal>
          </React.Fragment>
        )}
        <a
          target="_blank"
          href={this.documentURL}
          ref={this.attachmentRef}
          style={{ display: 'none' }}
        >
          link
        </a>
        {comments.length
          ? createComments(comments, canDelete, {
              handleDelete: this.handleDelete,
              getAttachment: this.getAttachment,
            })
          : noData()}
      </EnhancedCard>
    );
  }
}

export default CommentsIncidents;
