import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedModal,
} from 'components/shared/antd';
import React from 'react';
import {
  ROW_GUTTER_SIZE,
  FULL_GRID_SIZE,
  CERTIFICATES as stateName,
  CERTIFICATE_COMPETENCIES as certificatesCompetenciesOptions,
  IS_EDIT,
  IS_LOADING,
} from 'configs/employeeProfileConstants';
import { createCertificate, noData } from 'utils/FieldGenerateUtils';

import { generateErrorsArrayFromJSON, generateErrorObject } from 'utils/validationUtils';

import {
  DELETE_CONFIRM_PROMPT_MESSAGE,
  DELETE_CONFIRM_PROMPT_WARNING_MESSAGE,
} from 'configs/messageConstants';
import EditButton from 'components/shared/employeeProfile/EditButton';
import AddButton from 'components/shared/employeeProfile/AddButton';

class CertificatesLicenses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      [stateName]: this.props[stateName] || [],
      errors: this.props[stateName]
        ? generateErrorsArrayFromJSON(this.props[stateName], [IS_EDIT, IS_LOADING])
        : [],
      disableSaveButton: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps[stateName] !== this.props[stateName]) {
      const newProps = [...nextProps[stateName]];
      this.setState({
        [stateName]: newProps,
      });
    }
  }

  handleEdit = (index) => {
    const newData = [...this.state[stateName]];
    newData[index] = Object.assign({}, newData[index], { isEdit: true });
    this.setState({
      [stateName]: newData,
    });
  };

  handleCancel = (index) => {
    const currentData = [...this.state[stateName]];

    // If the object already existed and has an id,
    // then replace data from props on cancel
    if (currentData[index].id) {
      const propsDataAtIndex = this.props[stateName][index];
      // Replace specific object on which cancel was clicked with props version
      currentData[index] = Object.assign({}, propsDataAtIndex, {
        isEdit: false,
      });
      this.setState({
        [stateName]: currentData,
      });
    } else {
      // else, replace the state from props, remove it from state.
      const propsData = [...this.props[stateName]];
      const errorsData = generateErrorsArrayFromJSON(propsData, [IS_EDIT, IS_LOADING]);
      this.setState({ [stateName]: propsData, errors: errorsData });
    }
  };

  handleChange = (value, key, error) => {
    // Umar : 07/11/2020 : Made generic to ease reusability
    const newData = [...this.state[stateName]];

    // split key and index and assign value to array index key (i.e: familyInfo[0].fullName = 'Name')
    const index = key.split('-')[1];
    const field = key.split('-')[0];

    newData[index] = Object.assign({}, { ...newData[index] }, { [field]: value });

    // Update specific error object and field
    const { errors } = this.state;
    errors[index] = Object.assign({}, { ...errors[index] }, { [field]: error });
    if (!newData[index].issuingBody.trim()) {
      errors[index].issuingBody = true;
    }

    // Check to see if there is any error
    // if any, disable save button
    // if none, enable save button
    const allValid = Object.values(errors[index]).every((valueError) => valueError === false);

    this.setState({
      [stateName]: newData,
      errors,
      disableSaveButton: !allValid,
    });
  };

  handleSave = (index) => {
    if (!this.state.disableSaveButton) {
      const currentData = this.state[stateName];
      currentData[index] = Object.assign(
        {},
        { ...currentData[index] },
        { isEdit: false, isLoading: true },
      );
      this.setState({ [stateName]: currentData });

      const { employeeId } = this.props;

      if (currentData[index].id) {
        this.props.updateEmployeeCertificates(
          employeeId,
          currentData[index].id,
          currentData[index],
          index,
        );
      } else {
        this.props.createEmployeeCertificates(employeeId, currentData[index], index);
      }
    }
  };

  handleCreate = () => {
    const newCertificateObject = {
      id: null,
      name: '',
      certificateNo: null,
      date: '',
      validTill: '',
      issuingBody: '',
      certificateCompetency: '',
      isEdit: true,
      isLoading: false,
    };
    let newCertificateErrorObject = generateErrorObject(newCertificateObject, [
      IS_EDIT,
      IS_LOADING,
    ]);

    // Mutate error object according to required conditions for appropiate validation
    newCertificateErrorObject = {
      ...newCertificateErrorObject,
      name: true,
      date: true,
    };

    const updatedArray = [newCertificateObject, ...this.state[stateName]];
    const updatedErrorsArray = [newCertificateErrorObject, ...this.state.errors];

    this.setState({
      [stateName]: updatedArray,
      errors: updatedErrorsArray,
      disableSaveButton: true,
    });
  };

  handleDelete = (itemId, index) => {
    EnhancedModal.confirm({
      title: DELETE_CONFIRM_PROMPT_MESSAGE,
      content: DELETE_CONFIRM_PROMPT_WARNING_MESSAGE,
      onOk: () => {
        const { employeeId } = this.props;
        this.props.deleteEmployeeCertificates(employeeId, itemId, index);
        const currentData = this.state[stateName];
        currentData[index].isLoading = true;
        this.setState({ [stateName]: currentData });
      },
      onCancel() {},
    });
  };

  render() {
    const data = this.state[stateName];
    const { disableSaveButton } = this.state;
    const canCreate = true;
    const canEdit = true;
    const canDelete = true;

    return (
      <div className="pos-rel mt-20">
        <div className="flex justify-flex-end">
          {canCreate && (
            <AddButton clickHandler={this.handleCreate} buttonText="Add New Certificate/Training" />
          )}
        </div>
        {data.length ? (
          <React.Fragment>
            <EnhancedRow gutter={ROW_GUTTER_SIZE}>
              {data.map((val, index) => {
                return (
                  <EnhancedCol span={FULL_GRID_SIZE}>
                    <EnhancedCard className="mb-24 br-5" loading={val.isLoading}>
                      <div className="flex" style={{ justifyContent: 'space-between' }}>
                        <span className="card-title mb-24">{val.name}</span>
                        {(canDelete || canEdit) && (
                          <div className="flex justify-flex-end">
                            {canDelete && val.id && !val.isEdit && (
                              <EnhancedButton
                                className="mr-10 btn-delete"
                                type="danger"
                                onClick={() => this.handleDelete(val.id, index)}
                              >
                                Delete
                              </EnhancedButton>
                            )}
                            {canEdit && !val.isEdit && (
                              <EditButton
                                clickHandler={() => this.handleEdit(index)}
                                buttonText="Edit"
                              />
                            )}
                            {val.isEdit && (
                              <EnhancedButton
                                type="primary"
                                className="mr-10"
                                onClick={() => this.handleSave(index)}
                                disabled={disableSaveButton}
                              >
                                Save
                              </EnhancedButton>
                            )}
                            {val.isEdit && (
                              <EnhancedButton onClick={() => this.handleCancel(index)}>
                                Cancel
                              </EnhancedButton>
                            )}
                          </div>
                        )}
                      </div>
                      {createCertificate(
                        val,
                        index,
                        certificatesCompetenciesOptions,
                        this.handleChange,
                      )}
                    </EnhancedCard>
                  </EnhancedCol>
                );
              })}
            </EnhancedRow>
          </React.Fragment>
        ) : (
          noData()
        )}
      </div>
    );
  }
}
export default CertificatesLicenses;
