import {
  GET_PEF_DATA_INIT,
  GET_PEF_DATA_SUCCESS,
  GET_PEF_DATA_FAILURE,
  SUBMIT_PEF_DATA_INIT,
  SUBMIT_PEF_DATA_SUCCESS,
  SUBMIT_PEF_DATA_FAILURE,
  SUBMIT_FOR_APPROVAL_INIT,
  SUBMIT_FOR_APPROVAL_SUCCESS,
  SUBMIT_FOR_APPROVAL_FAILURE,
  APPROVE_PEF_INIT,
  APPROVE_PEF_SUCCESS,
  APPROVE_PEF_FAILURE,
} from 'constants/PerformanceEvaluationFormTypes';

const initialState = {
  isLoading: false,
  pefData: {},
};

export default function performanceEvaluationFormReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PEF_DATA_INIT: {
      return {
        ...state,
        isLoading: true,
        // pefData: {},
      };
    }
    case GET_PEF_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        pefData: action.data,
      };
    }
    case GET_PEF_DATA_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
        pefData: {},
      };
    }
    case SUBMIT_PEF_DATA_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUBMIT_PEF_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SUBMIT_PEF_DATA_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SUBMIT_FOR_APPROVAL_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUBMIT_FOR_APPROVAL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SUBMIT_FOR_APPROVAL_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case APPROVE_PEF_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case APPROVE_PEF_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case APPROVE_PEF_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
