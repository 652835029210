/* eslint-disable import/prefer-default-export */
/* eslint-disable */
import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import {
  addExpenseClaimInit,
  addExpenseClaimSuccess,
  addExpenseClaimFailure,
  getClaimsInit,
  getClaimsSuccess,
  getClaimsFailure,
  getTeamClaimsInit,
  getTeamClaimsSuccess,
  getTeamClaimsFailure,
  getTeamClaimsForFinanceInit,
  getTeamClaimsForFinanceSuccess,
  getTeamClaimsForFinanceFailure,
  getTeamClaimsForHRInit,
  getTeamClaimsForHRSuccess,
  getTeamClaimsForHRFailure,
  getClaimTypesSuccess,
  getClaimTypesFailure,
  getClaimImagesSuccess,
  getClaimImagesFailure,
  approvalStatusInit,
  approvalStatusSuccess,
  approvalStatusFailure,
  claimProjectListSuccess,
  claimProjectListFailure,
} from 'store/actions/ExpenseClaimActions';
import { normalizeAddEmployeesResponse } from 'normalizers/employeeNormalizers';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { claimURLs, dateFormat } from 'configs/constants';
import { showLoader, hideLoader } from 'store/actions/AppLoaderActions';

export const addExpenseClaim = (expenseClaimDto) => (dispatch) => {
  dispatch(addExpenseClaimInit());
  axios
    .post(claimURLs.ADD_CLAIM_REQUEST, expenseClaimDto)
    .then((response) => {
      if (response.status === 201) {
        // EnhancedNotification.success({ message: 'Claim requested' });
        dispatch(addExpenseClaimSuccess());
      } else {
        EnhancedNotification.error({ message: 'Claim request failed' });
        dispatch(addExpenseClaimFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({ message: 'Claim request failed' });
      dispatch(addExpenseClaimFailure());
    });
};

export const claimTypes = () => (dispatch) => {
  axios
    .get(claimURLs.CLAIM_TYPES)
    .then((response) => {
      if (response.status === 200) {
        dispatch(getClaimTypesSuccess(response.data.data));
      } else {
        dispatch(getClaimTypesFailure());
      }
    })
    .catch(() => {
      dispatch(getClaimTypesFailure());
    });
};

export const getClaims = (params) => (dispatch) => {
  dispatch(getClaimsInit());
  axios
    .get(claimURLs.GET_CLAIM_REQUESTS, { params })
    .then((response) => {
      if (response.status === 200) {
        // EnhancedNotification.success({ message: 'Claim requests fetched' });
        let record = response.data.data;
        record = record.reverse();
        dispatch(getClaimsSuccess(record));
      } else {
        EnhancedNotification.error({ message: 'Claim request failed' });
        dispatch(getClaimsFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({ message: 'Claim request failed' });
      dispatch(getClaimsFailure());
    });
};

export const getClaimImages = (claimId) => (dispatch) => {
  axios
    .get(claimURLs.CLAIM_IMAGES.replace('{:id}', claimId))
    .then((response) => {
      if (response.status === 200) {
        dispatch(getClaimImagesSuccess(response.data.data.claimImages));
      } else {
        dispatch(getClaimImagesFailure());
      }
    })
    .catch(() => {
      dispatch(getClaimImagesFailure());
    });
};

export const getTeamClaims = (params) => (dispatch) => {
  dispatch(getTeamClaimsInit());
  axios
    .get(claimURLs.TEAM_CLAIMS, { params })
    .then((response) => {
      if (response.status === 200) {
        // EnhancedNotification.success({ message: 'Team claims fetched' });
        let records = response.data.data;
        records = records.reverse();
        dispatch(getTeamClaimsSuccess(records));
      } else {
        EnhancedNotification.error({ message: 'Claim request failed' });
        dispatch(getTeamClaimsFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({ message: 'Claim request failed' });
      dispatch(getTeamClaimsFailure());
    });
};

export const getClaimsforFinance = (params) => (dispatch) => {
  dispatch(getTeamClaimsForFinanceInit());
  axios
    .get(claimURLs.CLAIMS_FOR_FINANCE, { params })
    .then((response) => {
      if (response.status === 200) {
        // EnhancedNotification.success({ message: 'Company claims fetched' });
        let record = response.data.data;
        record = record.reverse();
        dispatch(getTeamClaimsForFinanceSuccess(record));
      } else {
        EnhancedNotification.error({ message: 'Error while fetching company claims' });
        dispatch(getTeamClaimsForFinanceFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({ message: 'Error while fetching finance claims' });
      dispatch(getTeamClaimsForFinanceFailure());
    });
};

export const getClaimsforHR = (params) => (dispatch) => {
  dispatch(getTeamClaimsForHRInit());
  axios
    .get(claimURLs.CLAIMS_FOR_HR, { params })
    .then((response) => {
      if (response.status === 200) {
        // EnhancedNotification.success({ message: 'Company claims fetched' });
        let record = response.data.data;
        record = record.reverse();
        dispatch(getTeamClaimsForHRSuccess(record));
      } else {
        EnhancedNotification.error({ message: 'Error while fetching company claims' });
        dispatch(getTeamClaimsForHRFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({ message: 'Error while fetching company claims' });
      dispatch(getTeamClaimsForHRFailure());
    });
};

export const claimApproved = (status) => (dispatch) => {
  dispatch(approvalStatusInit());
  axios
    .put(claimURLs.CLAIM_APPROVED.replace('{:claimRequestId}', status.claimRequestId), status)
    .then((response) => {
      if (response.status === 200) {
        // EnhancedNotification.success({ message: 'Success' });
        dispatch(approvalStatusSuccess());
      } else {
        EnhancedNotification.error({ message: 'Action failed' });
        dispatch(approvalStatusFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({ message: 'Action failed' });
      dispatch(approvalStatusFailure());
    });
};

export const claimDeclined = (status) => (dispatch) => {
  dispatch(approvalStatusInit());
  axios
    .delete(claimURLs.CLAIM_DECLINED.replace('{:claimRequestId}', status.claimRequestId), {
      data: {
        ...status,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        // EnhancedNotification.success({ message: 'Success' });
        dispatch(approvalStatusSuccess());
      } else {
        EnhancedNotification.error({ message: 'Action failed' });
        dispatch(approvalStatusFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({ message: 'Action failed' });
      dispatch(approvalStatusFailure());
    });
};
export const getClaimProjectList = (status) => (dispatch) => {
  axios
    .get(claimURLs.CLAIM_PROJECT_LIST, status)
    .then((response) => {
      if (response.status === 200) {
        let record = response.data.data;
        record = record.reverse();
        dispatch(claimProjectListSuccess(response.data.data));
      } else {
        dispatch(claimProjectListFailure());
      }
    })
    .catch(() => {
      dispatch(claimProjectListFailure());
    });
};

export const getExportClaim = () => (dispatch) => {
  dispatch(showLoader());
  axios
    .get(claimURLs.CLAIM_EXPORT, status)
    .then((response) => {
      dispatch(hideLoader());
      if (response.data.success && response.data.data) {
        const date = moment().format(dateFormat.DDMMYYYYwithForwardSlash);
        fileDownload(response.data.data.results, `Expense-claim-${date}.csv`);
      }
    })
    .catch((err) => {
      dispatch(hideLoader());
    });
};
