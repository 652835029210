import {
  PROJECT_ROLES_INIT,
  PROJECT_ROLES_FAILURE,
  GET_PROJECT_ROLES_SUCCESS,
  ADD_PROJECT_ROLE_SUCCESS,
  EDIT_PROJECT_ROLE_SUCCESS,
  DELETE_PROJECT_ROLE_SUCCESS,
} from '../../constants/ProjectRoles';

const initialState = {
  projectRoles: [],
  isLoading: false,
};

export default function employeeReducer(state = initialState, action) {
  switch (action.type) {
    case PROJECT_ROLES_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PROJECT_ROLES_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case GET_PROJECT_ROLES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        projectRoles: action.data,
      };
    }
    case ADD_PROJECT_ROLE_SUCCESS: {
      const projectRole = action.data;
      const result = {
        ...state,
        isLoading: false,
        projectRoles: [...state.projectRoles, projectRole],
      };
      return result;
    }
    case EDIT_PROJECT_ROLE_SUCCESS: {
      const { id, role, isLeadership } = action.data;
      const { projectRoles } = state;
      const newProjectRoles = [...projectRoles];

      const index = newProjectRoles.findIndex((projectRole) => projectRole.id === id);
      if (index > -1) {
        newProjectRoles[index] = { ...newProjectRoles[index], role, isLeadership };
      }

      const result = {
        ...state,
        isLoading: false,
        projectRoles: newProjectRoles,
      };
      return result;
    }
    case DELETE_PROJECT_ROLE_SUCCESS: {
      const id = action.data;
      const { projectRoles } = state;
      const filteredProjectRoles = projectRoles.filter((elem) => elem.id !== id);
      return {
        ...state,
        isLoading: false,
        projectRoles: filteredProjectRoles,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
