import { connect } from 'react-redux';

import ResourceAvailability from 'components/crmc/ResourceAvailability';

import {
  getResourceAvailabilityActions,
  getEmployeeDepartmentsAction,
} from 'store/actions/ResourceAvailabilityActions';
import {
  getEmployeeCompetenciesAction,
  getEmployeeDivisionsAction,
  getEmployeeBusinessUnitAction,
} from 'store/actions/EmployeeActions';
import { getAllDesignationActions } from 'store/actions/DesignationActions';
import { getAllLocationActions } from 'store/actions/LocationActions';
import { getProjectsAction } from 'store/actions/ProjectActions';

function mapStateToProps(state) {
  const {
    auth,
    resourceAvailabilityReducer,
    publicHolidaysReducer,
    employeeReducer,
    projectReducer,
    designationsReducer,
    locationsReducer,
  } = state;
  return {
    user: auth.user,
    resourceAvailability: resourceAvailabilityReducer.resourceAvailability,
    departments: resourceAvailabilityReducer.departments,
    designations: designationsReducer.designations,
    locations: locationsReducer.locations,
    competencies: employeeReducer.competencies,
    divisions: employeeReducer.divisions,
    businessUnits: employeeReducer.businessUnits,
    isLoading: resourceAvailabilityReducer.isLoading,
    workingHolidays: publicHolidaysReducer.publicHolidays.workingHolidays,
    projects: projectReducer.projects,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getResourceAvailabilityActions: (date) => dispatch(getResourceAvailabilityActions(date)),
    getEmployeeDepartmentsAction: () => dispatch(getEmployeeDepartmentsAction()),
    getAllDesignations: () => dispatch(getAllDesignationActions()),
    getAllLocations: () => dispatch(getAllLocationActions()),
    getEmployeeCompetenciesAction: () => dispatch(getEmployeeCompetenciesAction()),
    getEmployeeDivisionsAction: () => dispatch(getEmployeeDivisionsAction()),
    getEmployeeBusinessUnitAction: () => dispatch(getEmployeeBusinessUnitAction()),
    getProjects: () => dispatch(getProjectsAction()),
  };
}

const ResourceAvailabilityContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResourceAvailability);

export default ResourceAvailabilityContainer;
