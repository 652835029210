import React, { Component } from 'react';
import moment from 'moment';
import { EnhancedCol, EnhancedRow, EnhancedCard } from 'components/shared/antd';

const DATE_FORMAT = 'DD/MM/YYYY';

export default class OnLeavesCard extends Component {
  constructor(props) {
    super(props);
    this.setState = {};
  }

  render() {
    const leaves = this.props.onLeaves;
    return (
      <EnhancedCard
        bodyStyle={{
          maxHeight: 412,
          overflow: 'auto',
        }}
        title={<span style={{ fontSize: 16, fontWeight: 500 }}>Other Members on Leaves</span>}
      >
        {leaves.length ? (
          leaves.map((leave, index) => {
            const { requester } = leave;
            let { startDate, endDate } = leave;

            startDate = moment.utc(startDate);
            endDate = moment.utc(endDate);

            return (
              <EnhancedRow gutter={16} className={!!index && 'marginTop15'}>
                <EnhancedCol sm={6}>
                  <div
                    className="counter"
                    style={{
                      textAlign: 'center',
                      fontSize: 22,
                      backgroundColor: '#9B9B9B',
                      width: 48,
                      height: 48,
                    }}
                  >
                    {requester.initial}
                  </div>
                </EnhancedCol>
                <EnhancedCol sm={18}>
                  <h5>{requester.name}</h5>
                  <h5 className="grey-color" style={{ marginTop: 10 }}>
                    {`On Leaves: ${startDate.format(DATE_FORMAT)} - ${endDate.format(DATE_FORMAT)}`}
                  </h5>
                </EnhancedCol>
              </EnhancedRow>
            );
          })
        ) : (
          <h5>No Members on Leave.</h5>
        )}
      </EnhancedCard>
    );
  }
}
