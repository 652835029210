import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';

const EmployeesList = (props) => {
  const { employees, customizedColumns, exportFileName, rowSelection, rowKey, showExportButton } =
    props;
  const [employeesList, setEmployeesList] = useState([]);

  const getFileHeaders = () => {
    const headers = [
      {
        label: 'ID',
        key: 'taleoId',
      },
      {
        label: 'Employee Name',
        key: 'name',
      },
      {
        label: 'Business Unit',
        key: 'businessUnit',
      },
      {
        label: 'Division',
        key: 'division',
      },
      {
        label: 'Competency',
        key: 'competency',
      },
      {
        label: 'Department',
        key: 'department',
      },
    ];

    customizedColumns.forEach((column) => {
      if (column.config.showInExport) {
        headers.push({
          label: column.title,
          key: column.key,
        });
      }
    });

    return headers;
  };

  useEffect(() => {
    setEmployeesList(employees);
  }, [employees]);

  const getColumnsInfo = () => {
    // pre-defined columns for an employee record
    const columns = [
      {
        title: 'ID',
        dataIndex: 'taleoId',
        key: 'taleoId',
        sorter: (a, b) => a.taleoId.localeCompare(b.taleoId),
        filterConfig: {
          type: 'search',
          key: 'taleoId',
        },
      },
      {
        title: 'Employee Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        filterConfig: {
          type: 'search',
          key: 'name',
        },
      },
      {
        title: 'Division',
        dataIndex: 'division',
        key: 'division',
        sorter: (a, b) => a.division.localeCompare(b.division),
        filterConfig: {
          type: 'enhanced_filter',
          key: 'division',
          optionsConfig: {
            values: props.divisions,
          },
        },
        ellipsis: true,
      },
      {
        title: 'Competency',
        dataIndex: 'competency',
        key: 'competency',
        sorter: (a, b) => a.competency.localeCompare(b.competency),
        filterConfig: {
          type: 'enhanced_filter',
          key: 'competency',
          optionsConfig: {
            values: props.competencies,
          },
        },
        ellipsis: true,
      },
      {
        title: 'Department',
        dataIndex: 'department',
        key: 'department',
        sorter: (a, b) => a.department.localeCompare(b.department),
        filterConfig: {
          type: 'enhanced_filter',
          key: 'department',
          optionsConfig: {
            values: props.departments,
          },
        },
        ellipsis: true,
      },
      {
        title: 'Business Unit',
        dataIndex: 'businessUnit',
        key: 'businessUnit',
        sorter: (a, b) => a.businessUnit.localeCompare(b.businessUnit),
        filterConfig: {
          type: 'enhanced_filter',
          key: 'businessUnit',
          optionsConfig: {
            values: props.businessUnits,
          },
        },
        ellipsis: true,
      },
    ];
    return columns;
  };

  const getCustomizedColumns = () => {
    const columnsList = [];
    customizedColumns.forEach((customizedColumn) => {
      if (customizedColumn.config.showInTable) {
        let filtersConfig = {};
        if (customizedColumn.config.needFilter) {
          filtersConfig = {
            type: 'search',
            key: customizedColumn.dataIndex,
          };
        }

        columnsList.push({
          ...customizedColumn,
          filterConfig: {
            ...filtersConfig,
          },
        });
      }
    });

    return columnsList;
  };

  return (
    <EnhancedClientSideTable
      data={employeesList}
      columns={[...getColumnsInfo(), ...getCustomizedColumns()]}
      rowSelection={rowSelection}
      rowKey={rowKey}
      exportFileConfig={{
        fileName: `${exportFileName}`,
        fileHeaders: getFileHeaders(),
        showExportButton,
      }}
    />
  );
};

EmployeesList.propTypes = {
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      taleoId: PropTypes.string.isRequired,
      division: PropTypes.string.isRequired,
      competency: PropTypes.string.isRequired,
      department: PropTypes.string.isRequired,
      employeeId: PropTypes.number.isRequired,
      designation: PropTypes.string.isRequired,
      businessUnit: PropTypes.string.isRequired,
      managerName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  customizedColumns: PropTypes.arrayOf(
    PropTypes.shape({
      dataIndex: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      needFilter: PropTypes.bool.isRequired,
      sorter: PropTypes.func.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  exportFileName: PropTypes.string.isRequired,
  showExportButton: PropTypes.bool,
};

EmployeesList.defaultProps = {
  customizedColumns: [],
  showExportButton: true,
};

export default EmployeesList;
