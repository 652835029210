import { connect } from 'react-redux';
import updateBreadCrumb from 'store/actions/BreadCrumbActions';
import {
  getAllEmployeesActions,
  getEmployeeDepartmentsAction,
  getEmployeeCompetenciesAction,
  getEmployeeDivisionsAction,
  getEmployeeBusinessUnitAction,
} from 'store/actions/EmployeeActions';
import {
  getPMEmpCycleActions,
  getPMCycleDetailAction,
  exportAllEmpPerformanceCycleAction,
  sendInitiationEmailAction,
  addEmployeesAction,
  deleteEmployeeAction,
} from '../store/actions/PerformanceMangementActions';
import {
  approvePEFStatusAction,
  reopenPEFAction,
  markPEFAsFinalAction,
} from '../store/actions/PerformanceEvaluationFormActions';

import PMCycleEmp from '../components/admin/performanceManagement/PMCycleEmp';

const mapStateToProps = (state) => {
  const { performanceManagementReducer, employeeReducer, auth } = state;
  const { divisions, departments, competencies, businessUnits } = employeeReducer;
  return {
    employees: employeeReducer.employees,
    list: performanceManagementReducer.pmEmpCycle,
    isLoading: performanceManagementReducer.isLoading,
    pmCycleDetail: performanceManagementReducer.pmCycleDetail,
    divisions,
    departments,
    competencies,
    businessUnits,
    userPermissions: auth.user && auth.user.permissions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllEmployees: () => dispatch(getAllEmployeesActions()),
  getPMCycleDetail: (id) => dispatch(getPMCycleDetailAction(id)),
  getPMEmpCycle: (params) => dispatch(getPMEmpCycleActions(params)),
  exportAllEmpPerformanceCycle: (params) => dispatch(exportAllEmpPerformanceCycleAction(params)),
  sendInitiationEmail: (params) => dispatch(sendInitiationEmailAction(params)),
  deleteEmployee: (payload) => dispatch(deleteEmployeeAction(payload)),
  updateBreadCrumb: (key, oldVal, newVal) => dispatch(updateBreadCrumb(key, oldVal, newVal)),
  addEmployees: (payload, handleHideModal) =>
    dispatch(addEmployeesAction(payload, handleHideModal)),
  reopenPEF: (payload) => dispatch(reopenPEFAction(payload)),
  approvePEFStatus: (payload) => dispatch(approvePEFStatusAction(payload)),
  markPEFAsFinal: (payload) => dispatch(markPEFAsFinalAction(payload)),
  getEmployeeDivisions: () => dispatch(getEmployeeDivisionsAction()),
  getEmployeeDepartments: () => dispatch(getEmployeeDepartmentsAction()),
  getEmployeeCompetencies: () => dispatch(getEmployeeCompetenciesAction()),
  getEmployeeBusinessUnits: (req) => dispatch(getEmployeeBusinessUnitAction(req)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PMCycleEmp);
