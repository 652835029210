import {
  EnhancedCol,
  EnhancedButton,
  EnhancedRow,
  EnhancedModal,
  EnhancedSelect,
  EnhancedDatePicker,
  EnhancedForm,
} from 'components/shared/antd';
import React from 'react';
import moment from 'moment';
import { dateFormat } from 'configs/constants';
import RequiredField from '../RequiredField';

const { Option } = EnhancedSelect;

class DueDateExtensionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        dueDate: null,
        projectIds: [],
      },
    };
  }
  handleCancel = () => {
    this.props.onClose();
  };
  handleProjectsChanged = (values) => {
    this.setState({
      form: {
        ...this.state.form,
        projectIds: values,
      },
    });
  };
  handleDueDateChanged = (date, dateString) => {
    this.setState({
      form: {
        ...this.state.form,
        dueDate: dateString,
      },
    });
  };
  handleSubmit = () => {
    const { dueDate } = this.state.form;
    const projectDueDates = this.state.form.projectIds.map((id) => ({
      projectId: parseInt(id, 10),
      dueDate,
    }));
    this.props.onSave(projectDueDates);
  };
  shouldSaveButtonDisable = () => {
    const { form } = this.state;
    return !(form.dueDate && form.projectIds.length > 0);
  };
  disablePastDates = (current) => current && current < moment().startOf('day');
  renderProjectOptions = () => {
    const { projects } = this.props || [];
    return projects.map((p) => <Option key={p.id}>{p.name}</Option>);
  };
  render() {
    const { open } = this.props;
    return (
      <EnhancedModal
        title="Extend Rating Deadline"
        visible={open}
        onCancel={this.handleCancel}
        width={650}
        footer={[
          <EnhancedButton key="back" onClick={this.handleCancel}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            key="add"
            type="primary"
            onClick={this.handleSubmit}
            disabled={this.shouldSaveButtonDisable()}
          >
            Save
          </EnhancedButton>,
        ]}
      >
        <EnhancedRow type="flex">
          <EnhancedForm layout="inline">
            <EnhancedCol span={24} className="form-col">
              <p>
                <RequiredField>Due Date</RequiredField>
              </p>
              <EnhancedDatePicker
                id="dueDate"
                attr="dueDate"
                onChange={this.handleDueDateChanged}
                format={dateFormat.DDMMMYYYYwithSpace}
                disabledDate={this.disablePastDates}
                placeholder="Select Date"
              />
            </EnhancedCol>
            <EnhancedCol span={24} className="form-col">
              <p>
                <RequiredField>Projects</RequiredField>
              </p>
              <EnhancedSelect
                mode="multiple"
                size="large"
                placeholder="Please select"
                onChange={this.handleProjectsChanged}
                style={{ width: '100%' }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {this.renderProjectOptions()}
              </EnhancedSelect>
            </EnhancedCol>
          </EnhancedForm>
        </EnhancedRow>
      </EnhancedModal>
    );
  }
}

export default DueDateExtensionModal;
