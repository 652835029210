import { connect } from 'react-redux';
import EmployeeSearchList from 'components/employeeDirectory/EmployeeSearchList';
import { getEmployeeSearchAction } from 'store/actions/EmployeeSearchActions';

function mapStateToProps(state) {
  const { employeeSearchReducer } = state;
  return {
    employeeDirectory: employeeSearchReducer.employeeDirectory,
    isLoading: employeeSearchReducer.isLoading,
    employeePageInfo: employeeSearchReducer.employeePageInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEmployeeSearchAction: (params) => dispatch(getEmployeeSearchAction(params)),
  };
}
const EmployeeSearchListContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeSearchList);

export default EmployeeSearchListContainer;
