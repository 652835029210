import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import {
  getMiscellaneousDataInit,
  getMiscellaneousDataSuccess,
  getMiscellaneousDataFailure,
  getJobDescriptionDocumentURLInit,
  getJobDescriptionDocumentURLSuccess,
  getJobDescriptionDocumentURLFailure,
  getDesignationsDataSuccess,
} from 'store/actions/MiscellaneousActions';
import { miscellaneousUrls } from 'configs/constants';
import {
  JOB_DESCRIPTION_DOCUMENT_GET_FAILED_MESSAGE,
  MISCELLANEOUS_GET_FAILURE_MESSAGE,
} from 'configs/messageConstants';
import {
  normalizeMiscellaneousData,
  normalizeDesignationsData,
} from 'normalizers/miscellaneousNormalizers';

export const getMiscellaneousData = () => (dispatch) => {
  dispatch(getMiscellaneousDataInit());
  const { DIVISIONS, DESIGNATIONS, EMPLOYMENT_TYPES } = miscellaneousUrls;
  const urls = [DIVISIONS.GET, DESIGNATIONS.GET, EMPLOYMENT_TYPES.GET];
  const fetchJson = (url) => axios.get(url).then((res) => res.data.data);
  Promise.all(urls.map(fetchJson))
    .then(([divisionsData, designationsData, employmentTypesData]) => {
      const normalizedData = normalizeMiscellaneousData({
        divisionsData,
        designationsData,
        employmentTypesData,
      });
      dispatch(getMiscellaneousDataSuccess(normalizedData));
    })
    .catch((err) => {
      EnhancedNotification.error({
        message: MISCELLANEOUS_GET_FAILURE_MESSAGE,
      });
      dispatch(getMiscellaneousDataFailure(err));
    });
};

export const getDesignationsData = () => (dispatch) => {
  dispatch(getMiscellaneousDataInit());
  const { DESIGNATIONS } = miscellaneousUrls;
  const urls = [DESIGNATIONS.GET];
  const fetchJson = (url) => axios.get(url).then((res) => res.data.data);
  Promise.all(urls.map(fetchJson))
    .then(([designationsData]) => {
      const normalizedData = normalizeDesignationsData(designationsData);
      dispatch(getDesignationsDataSuccess(normalizedData));
    })
    .catch((err) => {
      EnhancedNotification.error({
        message: MISCELLANEOUS_GET_FAILURE_MESSAGE,
      });
      dispatch(getMiscellaneousDataFailure(err));
    });
};

export const getJobDescriptionDocumentURL = (jobDescriptionId) => (dispatch) => {
  dispatch(getJobDescriptionDocumentURLInit);
  const url = miscellaneousUrls.JOB_DESCRIPTION_DOCUMENT.GET.replace(
    '{jobDescriptionId}',
    jobDescriptionId,
  );
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const documentUrl = response.data.data.url;
        dispatch(getJobDescriptionDocumentURLSuccess(documentUrl));
      } else {
        EnhancedNotification.error({
          message: JOB_DESCRIPTION_DOCUMENT_GET_FAILED_MESSAGE,
        });
        dispatch(getJobDescriptionDocumentURLFailure());
      }
    })
    .catch((error) => {
      dispatch(getJobDescriptionDocumentURLFailure());
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          JOB_DESCRIPTION_DOCUMENT_GET_FAILED_MESSAGE,
      });
    });
};
