import { EnhancedRow, EnhancedModal } from 'components/shared/antd';

import React from 'react';
import _ from 'lodash';

class OverAllocationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: props.visible,
      data: {},
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.visible !== nextProps.visible && nextProps.data && nextProps.data.length) {
      this.setState({
        visible: nextProps.visible,
        data: nextProps.data,
      });
    }
  }

  onCancel = () => {
    this.setState({
      visible: false,
    });
    this.props.onModalClose();
  };

  render() {
    let text = '';
    _.each(this.state.data, (item, index) => {
      if (index === 0) {
        text = `Resource ${item.employee} will be over allocated by ${item.totalOverAllocations}% due to his ${item.projectAllocation}% allocation on ${item.project}`;
      }

      if (index > 0 && text !== '') {
        text = text.concat(' ', `and ${item.projectAllocation}% allocation on ${item.project}`);
      }
    });
    text = text.concat(' ', 'during this time duration');
    return (
      <EnhancedModal
        title="Over Allocation Warning"
        visible={this.state.visible}
        onCancel={this.onCancel}
        footer={null}
      >
        <EnhancedRow>{text}</EnhancedRow>
      </EnhancedModal>
    );
  }
}
export default OverAllocationModal;
