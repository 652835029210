import { dateRenderer } from 'utils/ExcelFilesUtils';

export const allGoalsKeys = [
  'employeeId',
  'employeeName',
  'employeeCompetency',
  'employeeDepartment',
  'dueDate',
  'status',
  'title',
  'progress',
  'weightage',
];

export const allGoalsMeta = [
  {
    key: 'employeeId',
    label: 'ID',
  },
  {
    key: 'employeeName',
    label: 'Employee',
  },
  {
    key: 'employeeCompetency',
    label: 'Competency',
  },
  {
    key: 'employeeDepartment',
    label: 'Department',
  },
  {
    key: 'dueDate',
    label: 'Due Date',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'title',
    label: 'Goal Title',
  },
  {
    key: 'progress',
    label: 'Percent Complete',
  },
  {
    key: 'weightage',
    label: 'Weighted',
  },
];

export const reporteeGoalsMeta = [
  {
    key: 'employee.taleoId',
    label: 'ID',
  },
  {
    key: 'employee.name',
    label: 'Employee',
  },
  {
    key: 'employee.managerName',
    label: 'Manager',
  },
  {
    key: 'employee.competency',
    label: 'Competency',
  },
  {
    key: 'employee.department',
    label: 'Department',
  },
  {
    key: 'title',
    label: 'Goal Title',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'dueDate',
    label: 'Due Date',
    renderer: dateRenderer,
  },
  {
    key: 'weightage',
    label: 'Weighted',
  },
  {
    key: 'progress',
    label: 'Percent Complete',
  },
];
