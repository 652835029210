import { EnhancedRow, EnhancedCol, EnhancedButton, EnhancedCard } from 'components/shared/antd';
import React, { Fragment } from 'react';

import {
  ROW_GUTTER_SIZE,
  FULL_GRID_SIZE,
  FAMILY_INFO as stateName,
} from 'configs/employeeProfileConstants';
import {
  // createSubHeading,
  createFamilyMember,
  noData,
} from 'utils/FieldGenerateUtils';
import { generateErrorsArrayFromJSON, generateErrorObject } from 'utils/validationUtils';
import { trimObjValues } from 'utils/objectUtils';
import { hasPermission } from 'utils/AccessUtils';
import Permissions from 'constants/AccessPermissions';
import { DELETE_CONFIRM_PROMPT_MESSAGE } from 'configs/messageConstants';
import EditButton from 'components/shared/employeeProfile/EditButton';
import AddButton from 'components/shared/employeeProfile/AddButton';
import confirmModal from '../../shared/ConfirmModal';

class FamilyInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      [stateName]: this.props[stateName] || [],
      errors: [],
      showEditDeleteButtons: true,
      disableCreateButton: false,
      workingCardIndex: null,
      cancelSaveButtons: this.updateArrayIndexesFalse(this.props[stateName]),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps[stateName] !== this.props[stateName]) {
      const errors = generateErrorsArrayFromJSON(nextProps[stateName], []);
      this.setState({ familyInfo: nextProps[stateName], errors });
    }
  }

  // Fill provided array indexes to false
  // When user clicks on Create or Save button, a new array created with the length of total cards
  // and make all indexes to false
  updateArrayIndexesFalse = (familyInfo) => {
    return new Array(familyInfo && familyInfo.length).fill(false);
  };

  // Update index of cancelSaveButtons array
  updatecancelSaveButtonsIndex = (index, value, fill) => {
    const cancelSaveButtons = [...this.state.cancelSaveButtons];

    // if fill check is true, filled all array indexes to false so buttons would be hidden from all cards
    if (fill) {
      cancelSaveButtons.fill(!value);
    }

    // Update boolean value of array index for currently opened card
    // true when use clicks on Edit button
    // false when use clicks on Cancel button
    cancelSaveButtons[index] = value;
    return cancelSaveButtons;
  };

  handleEdit = (index) => {
    // Fill all the cancelSaveButtons indexes to false and update current cancelSaveButtons index to true
    const cancelSaveButtons = this.updatecancelSaveButtonsIndex(index, true, true);
    this.setState({
      cancelSaveButtons,
      disableSaveButton: true,
      showEditDeleteButtons: false,
      disableCreateButton: true,
    });
  };

  handleCancel = (index) => {
    // Update current cancelSaveButtons index to false
    const cancelSaveButtons = this.updatecancelSaveButtonsIndex(index, false);
    const [...familyInfo] = this.state[stateName];

    // remove newly created card if user clicks on cancel button
    if (!('id' in familyInfo[0])) {
      familyInfo.shift();
      cancelSaveButtons.shift();
    }
    const propsData = [...this.props[stateName]];
    const errorsData = generateErrorsArrayFromJSON(propsData);
    this.setState({ errors: errorsData });

    this.setState({
      cancelSaveButtons,
      [stateName]: this.props[stateName],
      showEditDeleteButtons: true,
      disableCreateButton: false,
    });
  };

  handleChange = (value, key, error) => {
    // Umar : 07/11/2020 : Made generic to ease reusability
    // Json.parse used instead of Object.assign as a deep clone is needed here.
    const newData = JSON.parse(JSON.stringify(this.state[stateName]));
    // split key and index and assign value to array index key (i.e: familyInfo[0].fullName = 'Name')
    const index = key.split('-')[1];
    const field = key.split('-')[0];

    newData[index][field] = value;

    // Update specific error object and field
    const { errors } = this.state;
    errors[index] = Object.assign({}, { ...errors[index] }, { [field]: error });
    // Check to see if there is any error
    // if any, disable save button
    // if none, enable save button
    const allValid = Object.values(errors[index]).every((valueError) => valueError === false);

    this.setState({
      [stateName]: newData,
      error: false,
      disableSaveButton: !allValid,
      errors,
    });
    if (error) {
      this.setState({ disableSaveButton: true });
    }
  };

  handleCreate = () => {
    const [...familyInfo] = this.state[stateName];

    // Create new object and set pre-defined fullName, relationship and dateOfBirth values
    familyInfo.unshift({
      fullName: '',
      relationship: '',
      dateOfBirth: '',
    });
    const cancelSaveButtons = this.updateArrayIndexesFalse(familyInfo);
    cancelSaveButtons[0] = true;
    let newFamilyInfoErrorObject = generateErrorObject(familyInfo[0], []);

    // Mutate error object according to required conditions for appropiate validation
    newFamilyInfoErrorObject = {
      ...newFamilyInfoErrorObject,
      fullName: true,
      relationship: true,
      dateOfBirth: true,
      registeredInMedical: false,
      medicalPolicyNo: false,
      gender: true,
    };

    // const updatedArray = [familyInfo[0], ...this.state[stateName]];
    const updatedErrorsArray = [newFamilyInfoErrorObject, ...this.state.errors];
    this.setState({
      familyInfo,
      errors: updatedErrorsArray,
      cancelSaveButtons,
      showEditDeleteButtons: false,
      disableCreateButton: true,
      disableSaveButton: true,
    });
  };

  handleSave = (e, index, familyInfoId) => {
    e.preventDefault();
    if (!this.state.disableSaveButton) {
      const cancelSaveButtons = this.updateArrayIndexesFalse(this.props.familyInfo);
      const updatedObject = trimObjValues(this.state[stateName][index], [
        'fullName',
        'relationship',
      ]);

      if (familyInfoId) {
        this.props.updateEmployeeFamilyInfo(
          index,
          this.props.employeeId,
          familyInfoId,
          updatedObject,
        );
      } else {
        this.props.createEmployeeFamilyInfo(this.props.employeeId, updatedObject);
      }
      this.setState({
        workingCardIndex: index,
        cancelSaveButtons,
        showEditDeleteButtons: true,
        disableCreateButton: false,
      });
    }
  };

  handleDelete = (index, familyInfoId) => {
    confirmModal(DELETE_CONFIRM_PROMPT_MESSAGE, () => {
      const [...cancelSaveButtons] = this.state.cancelSaveButtons;
      cancelSaveButtons.splice(familyInfoId, 1);
      this.props.deleteEmployeeFamilyInfo(index, this.props.employeeId, familyInfoId);
      this.setState({ workingCardIndex: index, cancelSaveButtons });
    });
  };

  render() {
    const {
      cancelSaveButtons,
      disableSaveButton,
      disableCreateButton,
      familyInfo,
      showEditDeleteButtons,
    } = this.state;
    const { userPermissions, cardLoading } = this.props;
    const canCreate = hasPermission(userPermissions, [
      Permissions.Employee.Profile.Info.Family.Create,
    ]);
    const canEdit = hasPermission(userPermissions, [
      Permissions.Employee.Profile.Info.Family.Update,
    ]);
    const canDelete = hasPermission(userPermissions, [
      Permissions.Employee.Profile.Info.Family.Delete,
    ]);
    const setCardTitle = (data) => {
      let relationship = '';

      if (data.relationship === 'parent') {
        if (data.gender === 'male') {
          relationship = 'Father';
        } else {
          relationship = 'Mother';
        }
      } else {
        relationship = data.relationship.charAt(0).toUpperCase() + data.relationship.slice(1);
      }

      return `${data.fullName} - ${relationship}`;
    };
    return (
      <div className="mt-20 pos-rel">
        <div className="flex justify-flex-end">
          {canCreate && (
            <AddButton
              clickHandler={this.handleCreate}
              buttonText="Add New Member"
              disabled={disableCreateButton}
            />
          )}
        </div>
        {/* {createSubHeading('Family Info')} */}
        {familyInfo.length ? (
          <EnhancedRow gutter={ROW_GUTTER_SIZE}>
            {familyInfo.map((val, index) => {
              return (
                <EnhancedCol key={val.id} span={FULL_GRID_SIZE}>
                  <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
                    loading={cardLoading && index === this.state.workingCardIndex}
                    className="mb-24 br-5"
                  >
                    <div className="flex" style={{ justifyContent: 'space-between' }}>
                      <span className="card-title mb-24">{setCardTitle(val)}</span>
                      <div className="flex justify-flex-end">
                        <span>
                          {canDelete && showEditDeleteButtons ? (
                            <Fragment>
                              {canDelete && (
                                <EnhancedButton
                                  className="mr-10 btn-delete"
                                  type="danger"
                                  onClick={() => this.handleDelete(index, val.id)}
                                >
                                  Delete
                                </EnhancedButton>
                              )}
                              {canEdit && (
                                <EditButton
                                  clickHandler={() => this.handleEdit(index)}
                                  buttonText="Edit"
                                />
                              )}
                            </Fragment>
                          ) : (
                            ''
                          )}
                        </span>
                        <span className="pr-10">
                          {cancelSaveButtons[index] ? (
                            <EnhancedButton
                              type="primary"
                              onClick={(e) => this.handleSave(e, index, val.id)}
                              htmlType="submit"
                              disabled={disableSaveButton}
                            >
                              Save
                            </EnhancedButton>
                          ) : (
                            ''
                          )}
                        </span>
                        <span>
                          {cancelSaveButtons[index] ? (
                            <EnhancedButton onClick={() => this.handleCancel(index)}>
                              Cancel
                            </EnhancedButton>
                          ) : (
                            ''
                          )}
                        </span>
                      </div>
                    </div>
                    {createFamilyMember(
                      val,
                      2,
                      cancelSaveButtons[index],
                      index,
                      userPermissions,
                      this.handleChange,
                    )}
                  </EnhancedCard>
                </EnhancedCol>
              );
            })}
          </EnhancedRow>
        ) : (
          noData()
        )}
      </div>
    );
  }
}

export default FamilyInfo;
