import React from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

const EnhancedTooltip = (props) => {
  const {
    children,
    formatter,
    title,
    placement,
    key,
    className,
    onVisibleChange,
    defaultVisible,
    mouseEnterDelay,
    mouseLeaveDelay,
  } = props;

  return (
    <Tooltip
      formatter={formatter}
      title={title}
      placement={placement || 'top'}
      key={key}
      className={className}
      onVisibleChange={onVisibleChange || (() => {})}
      defaultVisible={defaultVisible}
      mouseEnterDelay={mouseEnterDelay || 0.1}
      mouseLeaveDelay={mouseLeaveDelay || 0.1}
      arrowPointAtCenter={false}
      autoAdjustOverflow
      transitionName="zoom-big-fast"
      trigger="hover"
    >
      {children}
    </Tooltip>
  );
};

EnhancedTooltip.propTypes = {
  defaultVisible: PropTypes.bool,
  // visible: PropTypes.bool,
  mouseEnterDelay: PropTypes.number,
  mouseLeaveDelay: PropTypes.number,
  //   className: PropTypes.string,
  //   placement: PropTypes.string
};

EnhancedTooltip.defaultProps = {
  defaultVisible: false,
  // visible: false,
  mouseLeaveDelay: 0.1,
  mouseEnterDelay: 0.1,
  // className: '',
  // placement: 'top'
};

export default EnhancedTooltip;
