import React from 'react';
import { AutoComplete } from 'antd';
// import PropTypes from 'prop-types';

const EnhancedAutoComplete = (props) => {
  const {
    children,
    options,
    style,
    onSelect,
    onSearch,
    placeholder,
    dataSource,
    filterOption,
    // value,
    onChange,
    slug,
    key,
    ...rest
  } = props;
  return (
    <AutoComplete
      options={options}
      style={style}
      onSelect={onSelect}
      onSearch={onSearch}
      placeholder={placeholder}
      dataSource={dataSource}
      filterOption={filterOption}
      // value={value}
      onChange={onChange}
      slug={slug}
      key={key}
      {...rest}
    >
      {children}
    </AutoComplete>
  );
};

EnhancedAutoComplete.propTypes = {};

EnhancedAutoComplete.defaultProps = {};

EnhancedAutoComplete.Option = AutoComplete.Option;

export default EnhancedAutoComplete;
