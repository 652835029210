import { getDashboardColor, getColor } from 'utils/colorUtils';
import { radix } from 'configs/constants';

export default function normalizeGetDashboardResponse(dashboardData) {
  const resourceCount = [];
  const divisionResourceType = [];
  const projectDetails = [];
  const competencyDetails = [];
  const billableByCompetency = [];
  const billableBySkills = [];
  const benchByCompetency = [];
  const benchBySkills = [];

  dashboardData.employees.map((empType) => {
    const data = {
      name: empType.resourceType,
      color: getDashboardColor(empType.resourceType),
      value: empType.count,
    };
    resourceCount.push(data);
    return null;
  });

  dashboardData.resourceTypes.map((resType) => {
    const data = resType.type
      ? {
          name: resType.type,
          color: getDashboardColor(resType.type),
          value: parseFloat(resType.count),
        }
      : {
          name: 'bench',
          color: getDashboardColor('bench'),
          value: resType.bench[0].count,
        };
    divisionResourceType.push(data);
    return null;
  });

  const getBillingTypes = (project, key) => {
    const value = project.resourceTypes
      .map((resourceType) => (resourceType.type === key ? resourceType.count : 0))
      .filter(Boolean)[0];
    return parseFloat(value) || 0;
  };

  dashboardData.projects.map((project) => {
    const data = {
      ...project,
      billed: getBillingTypes(project, 'billed'),
      internal: getBillingTypes(project, 'internal'),
      backup: getBillingTypes(project, 'backup'),
      additional: getBillingTypes(project, 'additional'),
    };

    projectDetails.push(data);
    return null;
  });
  dashboardData.competencyTypes.map((competencyType) => {
    const data = {
      name: competencyType.role,
      value: parseFloat(competencyType.count, radix.DECIMAL),
      color: getColor(competencyType.role),
    };
    competencyDetails.push(data);
    return null;
  });

  dashboardData.billableByCompetency.map((billableCompetency) => {
    const data = {
      name: billableCompetency.deptt,
      value: billableCompetency.count,
      color: getColor(billableCompetency.deptt),
    };
    billableByCompetency.push(data);
    return null;
  });

  dashboardData.billableBySKills.map((skills) => {
    const data = {
      name: skills.name,
      value: skills.count,
    };
    billableBySkills.push(data);
    return null;
  });
  dashboardData.benchByCompetency.map((Competency) => {
    const data = {
      name: Competency.role,
      value: Competency.count,
    };
    benchByCompetency.push(data);
    return null;
  });

  dashboardData.benchBySkills.map((Skills) => {
    const data = {
      name: Skills.name,
      value: parseFloat(Skills.count, 10).toFixed(2),
    };
    benchBySkills.push(data);
    return null;
  });

  return [
    resourceCount,
    divisionResourceType,
    projectDetails,
    competencyDetails,
    billableByCompetency,
    billableBySkills,
    benchByCompetency,
    benchBySkills,
  ];
}
