export const GET_DESIGNATION_INIT = 'GET_DESIGNATION_INIT';
export const GET_DESIGNATION_SUCCESS = 'GET_DESIGNATION_SUCCESS';
export const GET_DESIGNATION_FAILURE = 'GET_DESIGNATION_FAILURE';

export const CREATE_DESIGNATION_INIT = 'CREATE_DESIGNATION_INIT';
export const CREATE_DESIGNATION_SUCCESS = 'CREATE_DESIGNATION_SUCCESS';
export const CREATE_DESIGNATION_FAILURE = 'CREATE_DESIGNATION_FAILURE';

export const DELETE_DESIGNATION_INIT = 'DELETE_DESIGNATION_INIT';
export const DELETE_DESIGNATION_SUCCESS = 'DELETE_DESIGNATION_SUCCESS';
export const DELETE_DESIGNATION_FAILURE = 'DELETE_DESIGNATION_FAILURE';

export const UPDATE_DESIGNATION_INIT = 'UPDATE_DESIGNATION_INIT';
export const UPDATE_DESIGNATION_SUCCESS = 'UPDATE_DESIGNATION_SUCCESS';
export const UPDATE_DESIGNATION_FAILURE = 'UPDATE_DESIGNATION_FAILURE';
