/* eslint-disable */
import * as ActionTypes from '../../constants/interviewTypes';
import { normalizeInterview } from 'normalizers/interviewNormalizers';

const defaultState = {
  loading: false,
  loadingQuestions: false,
  interviewList: [],
  trackList: [],
  questionList: [],
  questionFrequencyPerTrackList: [],
  questionFrequencyPerClientAndTrackList: [],
};

const interviewReducer = (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_INTERVIEWS_INIT:
      return { ...state, loading: true };
    case ActionTypes.GET_INTERVIEWS_SUCCESS:
      return { ...state, loading: false, interviewList: payload };
    case ActionTypes.GET_REPORTEES_TRACKS_SUCCESS:
      return { ...state, trackList: payload };
    case ActionTypes.GET_QUESTIONS_INIT:
      return { ...state, loadingQuestions: true, questionList: [] };
    case ActionTypes.GET_QUESTIONS_SUCCESS:
      return { ...state, loadingQuestions: false, questionList: payload };
    case ActionTypes.GET_QUESTIONS_FAIL:
      return { ...state, loadingQuestions: false, questionList: [] };
    case ActionTypes.UPDATE_INTERVIEW_SUCCESS:
      return {
        ...state,
        interviewList: state.interviewList.map((item) =>
          item.id === payload.id ? { ...item, ...normalizeInterview(payload) } : item,
        ),
      };
    case ActionTypes.CREATE_QUESTION_INIT:
      return { ...state, loadingQuestions: true };
    case ActionTypes.CREATE_QUESTION_SUCCESS:
      return {
        ...state,
        questionList: [payload].concat(state.questionList),
        loadingQuestions: false,
      };
    case ActionTypes.CREATE_QUESTION_FAIL:
      return { ...state, loadingQuestions: false };
    case ActionTypes.DELETE_INTERVIEW_SUCCESS:
      return {
        ...state,
        interviewList: state.interviewList.filter((item) => item.id !== payload),
      };
    case ActionTypes.UPDATE_QUESTION_SUCCESS:
      return {
        ...state,
        questionList: state.questionList.map((item) =>
          item.id === payload.id ? { ...item, ...payload } : item,
        ),
      };
    case ActionTypes.DELETE_QUESTION_INIT:
      return {
        ...state,
        questionList: state.questionList.filter((item) => item.id !== payload),
      };
    case ActionTypes.GET_QUESTIONS_FREQUENCY_PER_TRACK_INIT:
      return { ...state, loading: true, questionFrequencyPerTrackList: [] };
    case ActionTypes.GET_QUESTIONS_FREQUENCY_PER_TRACK_SUCCESS:
      return { ...state, loading: false, questionFrequencyPerTrackList: payload };
    case ActionTypes.GET_QUESTIONS_FREQUENCY_PER_TRACK_FAIL:
      return { ...state, loading: false, questionFrequencyPerTrackList: [] };
    case ActionTypes.GET_QUESTIONS_FREQUENCY_PER_CLIENT_AND_TRACK_INIT:
      return { ...state, loading: true, questionFrequencyPerClientAndTrackList: [] };
    case ActionTypes.GET_QUESTIONS_FREQUENCY_PER_CLIENT_AND_TRACK_SUCCESS:
      return { ...state, loading: false, questionFrequencyPerClientAndTrackList: payload };
    case ActionTypes.GET_QUESTIONS_FREQUENCY_PER_CLIENT_AND_TRACK_FAIL:
      return { ...state, loading: false, questionFrequencyPerClientAndTrackList: [] };

    default:
      return state;
  }
};

export default interviewReducer;
