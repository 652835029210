import React from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';

const EnhancedRadio = (props) => {
  const {
    children,
    className,
    style,
    // value,
    options,
    autoFocus,
    defaultChecked,
    disabled,
    onChange,
    size,
    ...rest
  } = props;

  return (
    <Radio
      style={style}
      className={className}
      // value={value}
      options={options}
      defaultChecked={defaultChecked}
      autoFocus={autoFocus}
      disabled={disabled}
      onChange={onChange}
      size={size}
      {...rest}
    >
      {children}
    </Radio>
  );
};

EnhancedRadio.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  // value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        disabled: PropTypes.bool,
      }),
    ),
  ]),
  autoFocus: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.string,
};

EnhancedRadio.defaultProps = {
  className: '',
  style: {},
  // value: '',
  options: [],
  autoFocus: false,
  defaultChecked: false,
  disabled: false,
  onChange: () => {},
  size: 'default',
};

EnhancedRadio.Group = Radio.Group;
EnhancedRadio.Button = Radio.Button;

export default EnhancedRadio;
