import React from 'react';
import moment from 'moment';
import { LENGTHCONSTRAINT, dateFormat, EMPLOYEE_PROJECT_MODAL_TYPE } from 'configs/constants';
import {
  EnhancedButton,
  EnhancedCol,
  EnhancedRow,
  EnhancedDivider,
  EnhancedModal,
  EnhancedDatePicker,
  EnhancedInput,
} from 'components/shared/antd';

class ProjectDeleteEmployeeModal extends React.Component {
  constructor(props) {
    super(props);
    const endDate = moment();
    this.state = {
      deleteResourceModalVisible: props.visible,
      information: {
        comment: '',
        endDate,
        isFuture: false,
      },
      commentFieldState: {
        dirty: false,
        focused: false,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.deleteResourceModalVisible !== nextProps.visible) {
      this.setState({
        deleteResourceModalVisible: nextProps.visible,
      });
    }
    const information = { ...nextProps.project };
    const { projectData } = nextProps;
    const currentDate = moment();
    let endDate = currentDate;
    if (projectData.endDate && moment(projectData.endDate) < currentDate) {
      endDate = moment(projectData.endDate);
    }
    this.setState({
      information: {
        comment: information.comment,
        endDate,
        isFuture: moment(projectData.startDate) > currentDate,
      },
    });
  }

  onCommentChange = (event) => {
    const comment = event.target.value;
    this.setState({
      information: {
        ...this.state.information,
        comment,
      },
    });
  };

  onEndDateChange = (endDate) => {
    this.setState({
      information: {
        ...this.state.information,
        endDate,
      },
    });
  };

  onCommentFocus = () => {
    this.setState({
      commentFieldState: {
        dirty: true,
        focused: true,
      },
    });
  };

  onCommentBlur = () => {
    this.setState({
      commentFieldState: {
        dirty: true,
        focused: false,
      },
    });
  };

  disabledDate = (current) => current > moment();

  shouldDeallocateButtonDisable = () => {
    const { information } = this.state;
    const { comment, endDate } = information;
    if (!(comment && endDate)) return true;
    if (comment && comment.length < LENGTHCONSTRAINT.COMMENT_LENGTH) {
      return true;
    }
    return false;
  };

  shouldRemoveButtonDisable = () => {
    const { information } = this.state;
    const { comment } = information;
    if (!comment) return true;
    return comment.length < LENGTHCONSTRAINT.COMMENT_LENGTH;
  };

  handleModalCancel = () => {
    this.props.onModalClose(false);
  };

  deallocateEmployee = (deleteEntry = false) => {
    const { employeeProjectId, projectId } = this.props.projectData;
    const { comment, endDate } = this.state.information;
    this.props.onDeallocateEmployee(
      employeeProjectId,
      this.props.assignorId,
      projectId,
      comment,
      endDate,
      deleteEntry,
    );
    this.props.onModalClose(false);
    this.setState({
      deleteResourceModalVisible: false,
    });
  };

  handleEmployeeDeallocation = (deleteEntry = false) => {
    const { confirm } = EnhancedModal;
    const { projectData, projectName } = this.props;
    const title = `Are you sure you want to ${deleteEntry ? 'remove' : 'deallocate'} 
    ${projectData.name} from ${projectName || projectData.projectName}?`;
    let content;
    if (deleteEntry) {
      content = 'This will permanently remove the allocation entry.';
    }
    confirm({
      title,
      content,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.deallocateEmployee(deleteEntry);
      },
    });
  };

  render() {
    const { deleteResourceModalVisible, information } = this.state;
    const { isDeleteResourceModal, isDeallocateModal } = this.props;
    const { DEALLOCATE, REMOVE } = EMPLOYEE_PROJECT_MODAL_TYPE;
    return (
      <EnhancedModal
        title={`Are you sure you want to
          ${isDeleteResourceModal ? REMOVE : DEALLOCATE} ${
            this.props.projectData.name
          } from this project?`}
        visible={deleteResourceModalVisible}
        onCancel={this.handleModalCancel}
        footer={null}
      >
        <EnhancedRow>
          <EnhancedCol lg={24}>
            {!information.isFuture && (
              <React.Fragment>
                {isDeallocateModal ? (
                  <React.Fragment>
                    <span>End Date</span>
                    <EnhancedRow className="margin-top10" style={{ marginBottom: '10px' }}>
                      <EnhancedCol lg={10} style={{ marginLeft: '0px' }}>
                        <EnhancedDatePicker
                          disabledDate={this.disabledDate}
                          value={information.endDate}
                          format={dateFormat.DDMMMYYYYwithSpace}
                          style={{ width: '100%' }}
                          onChange={this.onEndDateChange}
                        />
                      </EnhancedCol>
                    </EnhancedRow>
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
              </React.Fragment>
            )}
            Please add your comments about {this.props.projectData.name}
            <EnhancedRow className="margin-top10">
              <EnhancedCol lg={23} style={{ marginLeft: '0px' }}>
                <EnhancedInput.TextArea
                  placeholder="Please Comment"
                  id="Please Comment"
                  onChange={this.onCommentChange}
                  value={this.state.information.comment}
                  onFocus={this.onCommentFocus}
                  onBlur={this.onCommentBlur}
                />
                {this.state.commentFieldState.dirty &&
                  !this.state.commentFieldState.focused &&
                  this.state.information.comment &&
                  this.state.information.comment.length < LENGTHCONSTRAINT.COMMENT_LENGTH && (
                    <p style={{ textAlign: 'right', color: 'red' }}>
                      Minimum {LENGTHCONSTRAINT.COMMENT_LENGTH} characters required
                    </p>
                  )}
                {this.state.commentFieldState.dirty &&
                  this.state.commentFieldState.focused &&
                  this.state.information.comment &&
                  this.state.information.comment.trim().length <
                    LENGTHCONSTRAINT.COMMENT_LENGTH && (
                    <p style={{ textAlign: 'right' }}>
                      {this.state.information.comment.trim().length}/
                      {LENGTHCONSTRAINT.COMMENT_LENGTH}
                    </p>
                  )}
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="margin-top10">
              <EnhancedDivider style={{ marginTop: 10 }} />
            </EnhancedRow>
            <EnhancedRow className="margin-top10">
              <EnhancedCol span={24}>
                {!information.isFuture && (
                  <React.Fragment>
                    {isDeallocateModal ? (
                      <EnhancedButton
                        style={{ float: 'right' }}
                        onClick={() => this.handleEmployeeDeallocation()}
                        disabled={this.shouldDeallocateButtonDisable()}
                        loading={this.props.isProjectLoading}
                        type="primary"
                      >
                        Deallocate
                      </EnhancedButton>
                    ) : (
                      <React.Fragment />
                    )}
                  </React.Fragment>
                )}
                {isDeleteResourceModal ? (
                  <EnhancedButton
                    style={{ marginRight: 5, float: 'right' }}
                    disabled={this.shouldRemoveButtonDisable()}
                    onClick={() => this.handleEmployeeDeallocation(true)}
                  >
                    Remove
                  </EnhancedButton>
                ) : (
                  <React.Fragment />
                )}
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedModal>
    );
  }
}

export default ProjectDeleteEmployeeModal;
