import {
  GET_PROJECT_REVIEWS_CYCLE_INIT,
  GET_PROJECT_REVIEWS_CYCLE_SUCCESS,
  GET_PROJECT_REVIEWS_CYCLE_FAILURE,
  GET_PROJECT_REVIEWS_BY_CYCLE_ID_INIT,
  GET_PROJECT_REVIEWS_BY_CYCLE_ID_SUCCESS,
  GET_PROJECT_REVIEWS_BY_CYCLE_ID_FAILURE,
  GET_PROJECT_REVIEWS_PROJECT_DATA_INIT,
  GET_PROJECT_REVIEWS_PROJECT_DATA_SUCCESS,
  GET_PROJECT_REVIEWS_PROJECT_DATA_FAILURE,
  POST_PROJECT_REVIEWS_RATING_DATA_INIT,
  POST_PROJECT_REVIEWS_RATING_DATA_SUCCESS,
  POST_PROJECT_REVIEWS_RATING_DATA_FAILURE,
  CLEAR_PROJECT_REVIEWS_RATING_DATA,
} from 'constants/ProjectReviewsTypes';

const initialState = {
  projectReviews: [],
  projectCycleData: [],
  projectRating: [],
  projectRatingData: [],
  isLoading: false,
};

export default function projectReviewsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROJECT_REVIEWS_CYCLE_INIT: {
      return Object.assign({}, state, {
        projectReviews: [],
        isLoading: true,
      });
    }
    case GET_PROJECT_REVIEWS_CYCLE_SUCCESS: {
      return Object.assign({}, state, {
        projectReviews: action.data,
        isLoading: false,
      });
    }
    case GET_PROJECT_REVIEWS_CYCLE_FAILURE: {
      return Object.assign({}, state, {
        projectReviews: [],
        isLoading: false,
      });
    }
    case GET_PROJECT_REVIEWS_BY_CYCLE_ID_INIT: {
      return Object.assign({}, state, {
        projectCycleData: {},
        isLoading: true,
      });
    }
    case GET_PROJECT_REVIEWS_BY_CYCLE_ID_SUCCESS: {
      return Object.assign({}, state, {
        projectCycleData: action.data,
        isLoading: false,
      });
    }
    case GET_PROJECT_REVIEWS_BY_CYCLE_ID_FAILURE: {
      return Object.assign({}, state, {
        projectCycleData: {},
        isLoading: false,
      });
    }
    case GET_PROJECT_REVIEWS_PROJECT_DATA_INIT: {
      return Object.assign({}, state, {
        projectRating: {},
        isLoading: true,
      });
    }
    case GET_PROJECT_REVIEWS_PROJECT_DATA_SUCCESS: {
      return Object.assign({}, state, {
        projectRating: action.data,
        isLoading: false,
      });
    }
    case GET_PROJECT_REVIEWS_PROJECT_DATA_FAILURE: {
      return Object.assign({}, state, {
        projectRating: {},
        isLoading: false,
      });
    }
    case POST_PROJECT_REVIEWS_RATING_DATA_INIT: {
      return Object.assign({}, state, {
        projectRatingData: {},
        isLoading: true,
      });
    }
    case POST_PROJECT_REVIEWS_RATING_DATA_SUCCESS: {
      return Object.assign({}, state, {
        projectRatingData: action.data,
        isLoading: false,
      });
    }
    case POST_PROJECT_REVIEWS_RATING_DATA_FAILURE: {
      return Object.assign({}, state, {
        projectRatingData: {},
        isLoading: false,
      });
    }
    case CLEAR_PROJECT_REVIEWS_RATING_DATA: {
      return Object.assign({}, state, {
        projectRatingData: {},
        isLoading: false,
      });
    }
    default:
      return state;
  }
}
