export const GET_TEAM_LEAVES_BALANCE_INIT = 'GET_TEAM_LEAVES_BALANCE_INIT';
export const GET_TEAM_LEAVES_BALANCE_SUCCESS = 'GET_TEAM_LEAVES_BALANCE_SUCCESS';
export const GET_TEAM_LEAVES_BALANCE_FAILURE = 'GET_TEAM_LEAVES_BALANCE_FAILURE';

export const GET_COMPANY_WIDE_DASHBOARD_INIT = 'GET_COMPANY_WIDE_DASHBOARD_INIT';
export const GET_COMPANY_WIDE_DASHBOARD_SUCCESS = 'GET_COMPANY_WIDE_DASHBOARD_SUCCESS';
export const GET_COMPANY_WIDE_DASHBOARD_FAILURE = 'GET_COMPANY_WIDE_DASHBOARD_FAILURE';

export const GET_LEAVES_ADJUSTMENTS_LOGS_INIT = 'GET_LEAVES_ADJUSTMENTS_LOGS_INIT';
export const GET_LEAVES_ADJUSTMENTS_LOGS_SUCCESS = 'GET_LEAVES_ADJUSTMENTS_LOGS_SUCCESS';
export const GET_LEAVES_ADJUSTMENTS_LOGS_FAILURE = 'GET_LEAVES_ADJUSTMENTS_LOGS_FAILURE';

export const ADJUST_LEAVES_QUOTA_INIT = 'ADJUST_LEAVES_QUOTA_INIT';
export const ADJUST_LEAVES_QUOTA_SUCCESS = 'ADJUST_LEAVES_QUOTA_SUCCESS';
export const ADJUST_LEAVES_QUOTA_FAILURE = 'ADJUST_LEAVES_QUOTA_FAILURE';
