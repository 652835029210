import {
  EnhancedCol,
  EnhancedButton,
  EnhancedIcon,
  EnhancedCard,
  EnhancedRow,
  EnhancedDivider,
  EnhancedLayout,
  EnhancedModal,
  EnhancedInput,
} from 'components/shared/antd';
import React, { Component, Fragment } from 'react';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';

const { confirm } = EnhancedModal;
const { Header } = EnhancedLayout;

class Divisions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editVisible: false,
      addVisible: false,
      divisionName: '',
      editDivisionName: '',
      selectedRecord: null,
      divisions: this.props.divisions || [],
    };
  }

  componentDidMount() {
    this.props.getEmployeeDivisionsAction();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.divisions !== this.props.divisions) {
      this.setState({ divisions: nextProps.divisions });
    }
  }

  getColumnsInfo = () => {
    const columns = [
      {
        title: 'Division',
        dataIndex: 'name',
        key: 'name',
        width: '10%',
        filterConfig: {
          type: 'search',
          key: 'name',
        },
      },
      {
        title: 'Action',
        key: 'action',
        width: '5%',
        render: (text, record) => (
          <span>
            <EnhancedIcon
              type="edit"
              style={{ cursor: 'pointer' }}
              onClick={() => this.showEditDivisionModal(record)}
            />
            <EnhancedDivider type="vertical" />
            <EnhancedIcon
              type="delete"
              style={{ cursor: 'pointer' }}
              onClick={() => this.showDeleteDivisionModal(record)}
            />
          </span>
        ),
      },
    ];
    return columns;
  };

  showEditDivisionModal = (record) => {
    this.setState({
      editVisible: true,
      selectedRecord: record,
      editDivisionName: record.name,
    });
  };

  hideEditDivisionModal = () => {
    this.setState({
      editVisible: false,
      addVisible: false,
      selectedRecord: null,
      editDivisionName: '',
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleAddDivision = () => {
    const { divisionName } = this.state;
    const { addDivision } = this.props;
    addDivision({ name: divisionName });
    this.setState({ divisionName: '', addVisible: false });
  };

  handleEditDivision = () => {
    const { selectedRecord, editDivisionName } = this.state;
    const { editDivision } = this.props;
    editDivision({ id: selectedRecord.id, name: editDivisionName });
    this.setState({ selectedRecord: null, editDivisionName: '', editVisible: false });
  };

  handleDeleteDivision = (record) => {
    const { deleteDivision } = this.props;
    deleteDivision({ id: record.id });
  };

  editDivision = () => {
    const { editVisible, addVisible, editDivisionName, divisionName } = this.state;
    const divisionValue = editVisible ? editDivisionName : divisionName;
    return (
      <EnhancedModal
        title={editVisible ? 'Edit Division' : 'Add Division'}
        visible={editVisible || addVisible}
        onCancel={this.hideEditDivisionModal}
        footer={[
          <EnhancedButton key="back" onClick={this.hideEditDivisionModal}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            key="submit"
            type="primary"
            onClick={editVisible ? this.handleEditDivision : this.handleAddDivision}
            disabled={!divisionValue || divisionValue === ''}
          >
            Save
          </EnhancedButton>,
        ]}
      >
        <EnhancedRow className="edit-skill">
          <EnhancedCol span={12}>
            <EnhancedInput
              name={editVisible ? 'editDivisionName' : 'divisionName'}
              value={divisionValue}
              onChange={this.handleChange}
              placeholder="Enter Division"
            />
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedModal>
    );
  };

  showAddDivisionModal = () => {
    this.setState({ addVisible: true });
  };

  showDeleteDivisionModal = (record) => {
    const { handleDeleteDivision } = this;
    confirm({
      title: 'Are you sure you want to delete this division?',
      content: 'Note: Division associated with employees cannot be deleted',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteDivision(record);
      },
    });
  };

  render() {
    const columns = this.getColumnsInfo();
    const { divisions } = this.state;
    const { isLoading } = this.props;
    return (
      <Fragment>
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol lg={12}>
              <h1 className="heading-pro">Division</h1>
            </EnhancedCol>
            <EnhancedCol span={6} offset={6}>
              <EnhancedButton
                type="primary"
                onClick={this.showAddDivisionModal}
                style={{ float: 'right', marginTop: '5%' }}
              >
                {' '}
                Add New Division
              </EnhancedButton>
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        {this.editDivision()}
        <div style={{ background: 'white' }}>
          <EnhancedCard loading={isLoading} bordered={false}>
            <EnhancedRow />
          </EnhancedCard>
          <div>
            <EnhancedClientSideTable
              style={{ marginLeft: '25px' }}
              loading={this.props.isLoading}
              columns={columns}
              data={divisions}
              exportFileConfig={{
                fileName: 'Divisions',
                fileHeaders: [
                  {
                    label: 'Division',
                    key: 'name',
                  },
                ],
                showExportButton: true,
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Divisions;
