export const ADD_EMPLOYMENT_TYPES_INIT = 'ADD_EMPLOYMENT_TYPES_INIT';
export const ADD_EMPLOYMENT_TYPES_SUCCESS = 'ADD_EMPLOYMENT_TYPES_SUCCESS';
export const ADD_EMPLOYMENT_TYPES_FAILURE = 'ADD_EMPLOYMENT_TYPES_FAILURE';

export const GET_EMPLOYMENT_TYPES_INIT = 'GET_EMPLOYMENT_TYPES_INIT';
export const GET_EMPLOYMENT_TYPES_SUCCESS = 'GET_EMPLOYMENT_TYPES_SUCCESS';
export const GET_EMPLOYMENT_TYPES_FAILURE = 'GET_EMPLOYMENT_TYPES_FAILURE';

export const DELETE_EMPLOYMENT_TYPES_INIT = 'DELETE_EMPLOYMENT_TYPES_INIT';
export const DELETE_EMPLOYMENT_TYPES_SUCCESS = 'DELETE_EMPLOYMENT_TYPES_SUCCESS';
export const DELETE_EMPLOYMENT_TYPES_FAILURE = 'DELETE_EMPLOYMENT_TYPES_FAILURE';

export const EDIT_EMPLOYMENT_TYPES_INIT = 'EDIT_EMPLOYMENT_TYPES_INIT';
export const EDIT_EMPLOYMENT_TYPES_SUCCESS = 'EDIT_EMPLOYMENT_TYPES_SUCCESS';
export const EDIT_EMPLOYMENT_TYPES_FAILURE = 'EDIT_EMPLOYMENT_TYPES_FAILURE';
