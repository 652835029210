/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import { getResponseError } from 'utils/sourceUtils';
import {
  getPublicHolidays,
  getPublicHolidaysSuccess,
  getPublicHolidaysFailure,
} from 'store/actions/LeaveActions';
import { PublicHolidaysUrls } from 'configs/constants';

export const getPublicHolidaysSource = () => {
  const url = PublicHolidaysUrls.GET;
  return (dispatch) => {
    dispatch(getPublicHolidays());
    return axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            getPublicHolidaysSuccess({
              workingHolidays: response.data.data.holidays,
            }),
          );
        } else {
          dispatch(getPublicHolidaysFailure(response.message));
        }
      })
      .catch((error) => {
        EnhancedNotification.error({ message: getResponseError(error) });
        dispatch(getPublicHolidaysFailure(error));
      });
  };
};
