import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedIcon,
  EnhancedModal,
  EnhancedSelect,
  EnhancedProgress,
} from 'components/shared/antd';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { goalStatusToClassMapping } from 'configs/constants';
import { GOAL_STATUS, DATE_FORMAT } from 'configs/employeeProfileConstants';
import _ from 'underscore';

import AddFilterModal from './AddFilterModal';
import AddGoalModal from './AddGoalModal';
import RejectGoalModal from './RejectGoalModal';
import ManagerAddGoalModal from './AddManagerGoalModal';

const { confirm } = EnhancedModal;
const { Option } = EnhancedSelect;

const GoalsListing = (props) => {
  const {
    // userRole,
    getGoal,
    removeGoal,
    managerAddGoal,
    updateGoal,
    getGoalDetails,
    updateGoalStatus,
    list,
    exportAllGoals,
    isLoading,
    details,
    departments,
    businessUnits,
    divisions,
    competencies,
    employees,
    allEmployees,
    getAllEmployeesActions,
    getEmployeeDepartments,
    getEmployeeBusinessUnits,
    getEmployeeDivisionsAction,
    getEmployeeCompetenciesAction,
  } = props;

  const [managers, setManagers] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isGoalFormModalVisible, setIsGoalFormModalVisible] = useState(false);
  const [isManagerGoalFormModalVisible, setManagerIsGoalFormModalVisible] = useState(false);
  const [isGoalRejectModalVisible, setIsGoalRejectModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [params, setParams] = useState({
    page: 0,
    size: 10,
  });

  const [isEdit, setIsEdit] = useState(false);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  useEffect(() => {
    let managersList = [];
    if (managers.length === 0 && employees.length) {
      employees.forEach((resource) => {
        managersList.push({
          id: resource.managerId,
          name: resource.managerName,
        });
      });
      managersList = _.uniq(managersList, false, 'id');
      setManagers(managersList);
    }
  }, [employees]);

  useEffect(() => {
    getGoal(params);
  }, [params]);

  useEffect(() => {
    getEmployeeDepartments();
    getEmployeeBusinessUnits();
    getEmployeeDivisionsAction();
    getEmployeeCompetenciesAction();
    getAllEmployeesActions();
  }, []);

  const canEdit = (status) =>
    status !== GOAL_STATUS.FINAL &&
    status !== GOAL_STATUS.DELETED &&
    status !== GOAL_STATUS.REJECTED;

  const canDelete = (status, isGoalPCRelated) =>
    status !== GOAL_STATUS.FINAL &&
    status !== GOAL_STATUS.DELETED &&
    status !== GOAL_STATUS.REJECTED &&
    !isGoalPCRelated;

  const canApprove = (status) => status === GOAL_STATUS.PENDING_APPROVAL;

  const canReject = (status, isGoalPCRelated) =>
    (status === GOAL_STATUS.PENDING_APPROVAL || status === GOAL_STATUS.APPROVED) &&
    !isGoalPCRelated;

  const canView = () => true;

  // const canUnlock = (status) => status === GOAL_STATUS.APPROVED;

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: !canApprove(record.status),
    }),
  };

  const hasSelected = selectedRowKeys.length > 0;

  const showGoalFormModal = () => {
    setIsGoalFormModalVisible(true);
  };

  const showManagerGoalModal = () => {
    setManagerIsGoalFormModalVisible(true);
  };

  const handleGoalManagerOk = () => {
    setManagerIsGoalFormModalVisible(false);
  };

  const handleGoalManagerCancel = () => {
    setManagerIsGoalFormModalVisible(false);
  };

  const handleGoalFormOk = () => {
    setIsGoalFormModalVisible(false);
  };

  const handleGoalFormCancel = () => {
    setIsGoalFormModalVisible(false);
  };

  const showGoalRejectModal = () => {
    setIsGoalRejectModalVisible(true);
  };

  const handleGoalRejectOk = () => {
    setIsGoalRejectModalVisible(false);
  };

  const handleGoalRejectCancel = () => {
    setIsGoalRejectModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setSelectedRowKeys([]);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleAllApprove = () => {
    confirm({
      title: 'Are you sure you want to approve all selected goals?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        await updateGoalStatus({
          payload: {
            status: GOAL_STATUS.APPROVED,
            goalIds: selectedRowKeys,
            as: 'HR',
          },
          params,
        });
        setSelectedRowKeys([]);
      },
    });
  };

  const handleSingleApprove = (id) => {
    confirm({
      title: 'Are you sure you want to approve the selected goal?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        updateGoalStatus({
          payload: {
            status: GOAL_STATUS.APPROVED,
            goalIds: [id],
            as: 'HR',
          },
          params,
        });
      },
    });
  };

  const handleGoalDelete = ({ id, userId }) => {
    confirm({
      title: 'Are you sure you want to delete this goal?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        removeGoal({
          id,
          userId,
          params,
        });
      },
    });
  };

  const generateGoalsReport = () => {
    const updatedParams = {
      ...params,
      size: 1000000,
    };
    exportAllGoals(updatedParams);
  };

  const changeActionHandler = (key, id, _record) => {
    if (key === '1') {
      showGoalFormModal();
      setIsEdit(false);
      getGoalDetails({ id, userId: _record.userId });
    } else if (key === '2') {
      showGoalFormModal();
      setIsEdit(true);
      getGoalDetails({ id, userId: _record.userId });
    } else if (key === '3') {
      handleGoalDelete({ id, userId: _record.userId });
    } else if (key === '4') {
      handleSingleApprove(id);
    } else if (key === '5') {
      showGoalRejectModal();
      getGoalDetails({ id, userId: _record.userId });
    }
  };

  const optionMenu = [
    {
      value: '1',
      label: 'View',
      canCallback: canView,
    },
    {
      value: '2',
      label: 'Edit',
      canCallback: (record) => canEdit(record.status),
    },
    {
      value: '3',
      label: 'Delete',
      canCallback: (record) => canDelete(record.status, record.isEvaluatedCurrently),
    },
    {
      value: '4',
      label: 'Approve',
      canCallback: (record) => canApprove(record.status),
    },
    {
      value: '5',
      label: 'Reject',
      canCallback: (record) => canReject(record.status, record.isEvaluatedCurrently),
    },
    // {
    //   value: '6',
    //   label: 'Unlock',
    //   canCallback: canUnlock,
    // },
  ];

  const setActionsHandler = (id, _record) => {
    return (
      <EnhancedSelect
        style={{
          width: 120,
        }}
        className="action-selection-dropdown"
        placeholder="Options"
        value="Options"
        onChange={(key) => changeActionHandler(key, id, _record)}
      >
        {optionMenu.map(
          (item) => item.canCallback(_record) && <Option value={item.value}>{item.label}</Option>,
        )}
      </EnhancedSelect>
    );
  };

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      page: pagination.current - 1,
      size: pagination.pageSize,
    });
  };

  const getColumnsInfo = () => {
    const columns = [
      {
        title: 'ID',
        dataIndex: ['employee', 'taleoId'],
        key: 'oracleId',
        filterConfig: {
          type: 'search',
          key: 'oracleId',
        },
        width: 120,
      },
      {
        title: 'Employee',
        dataIndex: ['employee', 'name'],
        key: 'employeeName',
        filterConfig: {
          type: 'search',
          key: 'employeeName',
        },
        width: 150,
      },
      {
        title: 'Competency',
        dataIndex: ['employee', 'competency'],
      },
      {
        title: 'Department',
        dataIndex: ['employee', 'department'],
      },
      {
        title: 'Due Date',
        dataIndex: 'dueDate',
        key: 'dueDate',
        width: '15%',
        render: (value) => (value ? moment.utc(value).format(DATE_FORMAT) : 'N/A'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (status) => (
          <span className={`status-box ${goalStatusToClassMapping[status]}`}>{status}</span>
        ),
        width: 150,
      },
      {
        title: 'Goal Title',
        dataIndex: 'title',
      },
      {
        title: 'Percent Complete',
        dataIndex: 'progress',
        width: 200,
        render: (progres) => (
          <EnhancedProgress
            className={`progress-bar ${progres > 10 && 'font-white'}`}
            percent={`${progres}`}
            strokeColor="#1DAC8A"
            strokeLinecap="square"
            type="line"
          />
        ),
      },
      {
        title: 'Weighted',
        dataIndex: 'weightage',
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        render: (id, _record) => setActionsHandler(id, _record),
      },
    ];
    return columns;
  };

  return (
    <div>
      <EnhancedCard>
        <React.Fragment>
          <EnhancedRow>
            <EnhancedCol span={6} style={{ backgroundColor: 'white' }}>
              <h1>All Goals</h1>
            </EnhancedCol>
            <EnhancedCol span={18} style={{ backgroundColor: 'white' }}>
              <div className="add-btn-wrapper">
                <EnhancedButton
                  className="btn-edit"
                  style={{
                    marginRight: 10,
                  }}
                  onClick={handleAllApprove}
                  disabled={selectedRowKeys.length === 0}
                >
                  <EnhancedIcon type="carry-out" />
                  <span>Approve Goals</span>
                </EnhancedButton>
                <EnhancedButton
                  className="btn-edit"
                  style={{
                    marginRight: 10,
                  }}
                  onClick={showModal}
                >
                  <EnhancedIcon type="filter" />
                  <span>Add Filter</span>
                </EnhancedButton>
                <EnhancedButton
                  className="btn-edit"
                  style={{ marginRight: 10 }}
                  onClick={showManagerGoalModal}
                >
                  <EnhancedIcon type="plus-circle" />
                  <span>Add New Goal</span>
                </EnhancedButton>
              </div>
            </EnhancedCol>
          </EnhancedRow>

          <div>
            <span
              style={{
                marginLeft: 8,
              }}
            >
              {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
            </span>
          </div>
          <EnhancedServerSideTable
            rowKey={(record) => record.id}
            rowSelection={rowSelection}
            loading={isLoading}
            columns={getColumnsInfo()}
            data={list.rows}
            onChange={handleTableChange}
            className="goal-table-container"
            paginationInfo={{
              totalItems: list.count,
              pageSize: params.size,
              pageNumber: params.page + 1,
            }}
            showResetFiltersButton={false}
            updateParams={(updatedParams) => {
              setParams({
                ...params,
                ...updatedParams,
                page: 0, // move to page no 0 in case filters are applied
              });
            }}
            scroll={{ x: 'max-content' }}
            exportFileConfig={{
              showExportButton: true,
              handler: generateGoalsReport,
            }}
          />

          <AddGoalModal
            isModalVisible={isGoalFormModalVisible}
            handleOk={handleGoalFormOk}
            handleCancel={handleGoalFormCancel}
            updateGoalActions={updateGoal}
            params={params}
            isEdit={isEdit}
            editableHandler={setIsEdit}
            isLoading={isLoading}
            details={details}
          />

          <RejectGoalModal
            isModalVisible={isGoalRejectModalVisible}
            handleOk={handleGoalRejectOk}
            handleCancel={handleGoalRejectCancel}
            params={params}
            updateGoalStatus={updateGoalStatus}
            isLoading={isLoading}
            details={details}
          />

          <AddFilterModal
            isModalVisible={isModalVisible}
            handleOk={handleOk}
            handleCancel={handleCancel}
            params={params}
            setParams={setParams}
            isLoading={isLoading}
            managers={managers}
            departments={departments}
            businessUnits={businessUnits}
            divisions={divisions}
            competencies={competencies}
          />

          <ManagerAddGoalModal
            isModalVisible={isManagerGoalFormModalVisible}
            handleOk={handleGoalManagerOk}
            handleCancel={handleGoalManagerCancel}
            managerAddGoalActions={managerAddGoal}
            params={params}
            isEdit={isEdit}
            editableHandler={setIsEdit}
            isLoading={isLoading}
            details={details}
            allEmployees={allEmployees}
          />
        </React.Fragment>
      </EnhancedCard>
    </div>
  );
};

export default GoalsListing;
