import moment from 'moment';
import { dateFormat } from 'configs/constants';
import { leaveStatus, leaveTypes } from '../configs/leaveConstants';

export const normalizeLeaveRequestsList = (leaveRequest = {}) => ({
  id: leaveRequest.id || '',
  currentStatus: leaveRequest.leaveStatus
    ? leaveRequest.leaveStatus.name.toLowerCase().replace(' ', '-')
    : '',
  employeeId: leaveRequest.employeeId || '',
  endDate: leaveRequest.requestEndDate || '',
  startDate: leaveRequest.requestStartDate || '',
  totalDays:
    moment(leaveRequest.requestEndDate).diff(moment(leaveRequest.requestStartDate), 'days') + 1 ||
    '',
  isHalfDay: leaveRequest.isHalfDay,
  duration: leaveRequest.duration || 0,
  leaveTypeId: leaveRequest.leaveType && leaveRequest.leaveType.id,
  leaveStatusId: leaveRequest.leaveStatus && leaveRequest.leaveStatus.id,
  leaveName: leaveRequest.leaveType ? leaveRequest.leaveType.name : '',
  requestDate: leaveRequest.requestDate || '',
  requesterNote: leaveRequest.comments || '',
  requester: leaveRequest.requester
    ? {
        initial: `${leaveRequest.requester.fullName[0].toUpperCase()}`,
        name: `${leaveRequest.requester.fullName}`,
      }
    : 'N/A',
  approvers: leaveRequest.approvers.map((approver) => {
    const { id, fullName, approverStatus, comments, role, reference, statusDate } = approver;
    const name = fullName;
    const comment = comments !== 'NULL' && comments;

    return {
      id,
      name: name || 'N/A',
      statusId: approverStatus.id,
      status: approverStatus.name.toLowerCase() || 'N/A',
      roles: role,
      comments: comment || 'N/A',
      reference,
      actionDate:
        approverStatus.name && approverStatus.name.toLowerCase() !== leaveStatus.PENDING
          ? moment(statusDate).format(dateFormat.DDMMMYYYYwithSpace)
          : 'N/A',
    };
  }),
});

export const normalizeLeaveRequests = (leaveRequests = []) =>
  leaveRequests.map((leaveRequest) => normalizeLeaveRequestsList(leaveRequest));

export const normalizeBenchLeaves = (res) => {
  let teamLeavesBench = {};
  teamLeavesBench = res.teamLeavesBench.map((data) => {
    const startDate = (data.startDate && moment(data.startDate)) || '';
    const endDate = (data.endDate && moment(data.endDate)) || '';
    const duration = (startDate && endDate && endDate.diff(startDate, 'days')) + 1 || 0;
    const requestDate =
      data.requestDate && moment(data.requestDate).format(dateFormat.DDMMMYYYYwithSpace);
    return {
      id: data.id || '',
      key: data.id || '',
      firstName: data.employee.firstName || '',
      lastName: data.employee.lastName || '',
      status: data.currentStatus || '',
      lmStatus: data.lmStatus || '',
      pmStatus: data.pmStatus || '',
      type: (data.leaveType && data.leaveType.name) || '',
      managerNote: data.managerNote || '',
      managerName:
        data.employee.manager && data.employee.manager.firstName
          ? data.employee.manager.firstName.concat(` ${data.employee.manager.lastName}`)
          : '',
      startDateString: (startDate && startDate.format(dateFormat.DDMMYYYYwithForwardSlash)) || '',
      endDateString: (endDate && endDate.format(dateFormat.DDMMYYYYwithForwardSlash)) || '',
      reason: data.requesterNote || '',
      requestDateString: requestDate,
      isHalfDay: data.isHalfDay,
      isEscalated: data.isEscalated,
      isBench: data.isBench,
      isDM: data.isDM,
      duration,
      startDate,
      endDate,
      employeeId: data.employeeId,
      annualBalance: data.employee.leave && data.employee.leave.annualAvailable,
      casualBalance: data.employee.leave && data.employee.leave.casualAvailable,
      sickBalance: data.employee.leave && data.employee.leave.sickAvailable,
    };
  });

  return {
    teamLeavesBench,
  };
};

export const normalizeTeamLeaves = (res) => {
  let teamLeaves = {};
  let teamLeavesAsPM = {};
  let teamLeavesAsDM = {};
  let indirect = {};

  teamLeaves = res.teamLeaves.map((data) => {
    const startDate = (data.startDate && moment(data.startDate)) || '';
    const endDate = (data.endDate && moment(data.endDate)) || '';
    const duration = (startDate && endDate && endDate.diff(startDate, 'days')) + 1 || 0;
    const requestDate =
      data.requestDate && moment(data.requestDate).format(dateFormat.DDMMMYYYYwithSpace);
    return {
      id: data.id || '',
      key: data.id || '',
      firstName: data.employee.firstName || '',
      lastName: data.employee.lastName || '',
      status: data.currentStatus || '',
      lmStatus: data.lmStatus || '',
      pmStatus: data.pmStatus || '',
      type: (data.leaveType && data.leaveType.name) || '',
      managerNote: data.managerNote || '',
      managerName:
        data.employee.manager && data.employee.manager.firstName
          ? data.employee.manager.firstName.concat(` ${data.employee.manager.lastName}`)
          : '',
      startDateString: (startDate && startDate.format(dateFormat.DDMMYYYYwithForwardSlash)) || '',
      endDateString: (endDate && endDate.format(dateFormat.DDMMYYYYwithForwardSlash)) || '',
      reason: data.requesterNote || '',
      requestDateString: requestDate,
      isHalfDay: data.isHalfDay,
      isEscalated: data.isEscalated,
      isBench: data.isBench,
      isDM: data.isDM,
      duration,
      startDate,
      endDate,
      employeeId: data.employeeId,
      annualBalance: data.employee.leave.annualAvailable || 0,
      casualBalance: data.employee.leave.casualAvailable,
      sickBalance: data.employee.leave.sickAvailable,
    };
  });

  teamLeavesAsPM = res.teamLeavesAsPM.map((data) => {
    const startDate = (data.startDate && moment(data.startDate)) || '';
    const endDate = (data.endDate && moment(data.endDate)) || '';
    const duration = (startDate && endDate && endDate.diff(startDate, 'days')) + 1 || 0;
    const requestDate =
      data.requestDate && moment(data.requestDate).format(dateFormat.DDMMMYYYYwithSpace);
    return {
      id: data.id || '',
      key: data.id || '',
      firstName: data.employee.firstName || '',
      lastName: data.employee.lastName || '',
      status: data.currentStatus || '',
      lmStatus: data.lmStatus || '',
      pmStatus: data.pmStatus || '',
      type: (data.leaveType && data.leaveType.name) || '',
      managerNote: data.managerNote || '',
      managerName:
        data.employee.manager && data.employee.manager.firstName
          ? data.employee.manager.firstName.concat(` ${data.employee.manager.lastName}`)
          : '',
      startDateString: (startDate && startDate.format(dateFormat.DDMMYYYYwithForwardSlash)) || '',
      endDateString: (endDate && endDate.format(dateFormat.DDMMYYYYwithForwardSlash)) || '',
      reason: data.requesterNote || '',
      requestDateString: requestDate,
      isHalfDay: data.isHalfDay,
      isEscalated: data.isEscalated,
      isBench: data.isBench,
      isDM: data.isDM,
      duration,
      startDate,
      endDate,
      employeeId: data.employeeId,
      annualBalance: data.employee.leave.annualAvailable || 0,
      casualBalance: data.employee.leave.casualAvailable,
      sickBalance: data.employee.leave.sickAvailable,
    };
  });

  teamLeavesAsDM = res.teamLeavesAsDM.map((data) => {
    const startDate = (data.startDate && moment(data.startDate)) || '';
    const endDate = (data.endDate && moment(data.endDate)) || '';
    const duration = (startDate && endDate && endDate.diff(startDate, 'days')) + 1 || 0;
    const requestDate =
      data.requestDate && moment(data.requestDate).format(dateFormat.DDMMMYYYYwithSpace);
    return {
      id: data.id || '',
      key: data.id || '',
      firstName: data.employee.firstName || '',
      lastName: data.employee.lastName || '',
      status: data.currentStatus || '',
      lmStatus: data.lmStatus || '',
      pmStatus: data.pmStatus || '',
      type: (data.leaveType && data.leaveType.name) || '',
      managerNote: data.managerNote || '',
      managerName:
        data.employee.manager && data.employee.manager.firstName
          ? data.employee.manager.firstName.concat(` ${data.employee.manager.lastName}`)
          : '',
      startDateString: (startDate && startDate.format(dateFormat.DDMMYYYYwithForwardSlash)) || '',
      endDateString: (endDate && endDate.format(dateFormat.DDMMYYYYwithForwardSlash)) || '',
      reason: data.requesterNote || '',
      requestDateString: requestDate,
      isHalfDay: data.isHalfDay,
      isEscalated: data.isEscalated,
      isBench: data.isBench,
      isDM: data.isDM,
      duration,
      startDate,
      endDate,
      employeeId: data.employeeId,
      annualBalance: data.employee.leave.annualAvailable || 0,
      casualBalance: data.employee.leave.casualAvailable,
      sickBalance: data.employee.leave.sickAvailable,
    };
  });

  indirect = res.indirect.map((data) => {
    const startDate = (data.startDate && moment(data.startDate)) || '';
    const endDate = (data.endDate && moment(data.endDate)) || '';
    const duration = (startDate && endDate && endDate.diff(startDate, 'days')) + 1 || 0;
    const requestDate =
      data.requestDate && moment(data.requestDate).format(dateFormat.DDMMMYYYYwithSpace);
    return {
      id: data.id || '',
      key: data.id || '',
      firstName: data.employee.firstName || '',
      lastName: data.employee.lastName || '',
      status: data.currentStatus || '',
      lmStatus: data.lmStatus || '',
      pmStatus: data.pmStatus || '',
      type: (data.leaveType && data.leaveType.name) || '',
      managerNote: data.managerNote || '',
      managerName:
        data.employee.manager && data.employee.manager.firstName
          ? data.employee.manager.firstName.concat(` ${data.employee.manager.lastName}`)
          : '',
      startDateString: (startDate && startDate.format(dateFormat.DDMMYYYYwithForwardSlash)) || '',
      endDateString: (endDate && endDate.format(dateFormat.DDMMYYYYwithForwardSlash)) || '',
      reason: data.requesterNote || '',
      requestDateString: requestDate,
      isHalfDay: data.isHalfDay,
      isEscalated: data.isEscalated,
      isBench: data.isBench,
      isDM: data.isDM,
      duration,
      startDate,
      endDate,
      employeeId: data.employeeId,
      annualBalance: data.employee.leave && data.employee.leave.annualAvailable,
      casualBalance: data.employee.leave && data.employee.leave.casualAvailable,
      sickBalance: data.employee.leave && data.employee.leave.sickAvailable,
    };
  });
  return {
    teamLeaves,
    teamLeavesAsPM,
    teamLeavesAsDM,
    indirect,
  };
};

export const normalizeLeaveBalance = (leaves = []) => {
  const sickLeave = leaves.find((leave) => leave.leaveTypeId === leaveTypes.SICK);
  const casualLeave = leaves.find((leave) => leave.leaveTypeId === leaveTypes.CASUAL);
  const annualLeave = leaves.find((leave) => leave.leaveTypeId === leaveTypes.ANNUAL);
  const otherPending = leaves
    .filter((leave) => leave.category === 'Secondary')
    .reduce((accumulator, current) => accumulator + current.pendingLeavesCount, 0);
  return {
    sickTotal: parseFloat(sickLeave.total, 10),
    sickAvailable: parseFloat(sickLeave.total - sickLeave.used, 10),
    sickPending: parseFloat(sickLeave.pendingLeavesCount, 10),
    annualUsed: parseFloat(annualLeave.used, 10),
    annualAvailable: parseFloat(annualLeave.total - annualLeave.used, 10),
    annualTotal: parseFloat(annualLeave.total, 10),
    annualPending: parseFloat(annualLeave.pendingLeavesCount, 10),
    casualAvailable: parseFloat(casualLeave.total - casualLeave.used, 10),
    casualTotal: parseFloat(casualLeave.total, 10),
    casualPending: parseFloat(casualLeave.pendingLeavesCount, 10),
    otherPending: parseFloat(otherPending, 10),
  };
};

export const normalizeLeaveCalendar = (leaves = []) =>
  leaves.map((leaveRequest) => ({
    start: moment.utc(leaveRequest.requestStartDate).format(),
    end: moment.utc(leaveRequest.requestEndDate).format(),
    title: `${leaveRequest.requester.firstName}  ${leaveRequest.requester.lastName} Leaves`,
  }));

export const normalizeLeavesStatuses = (leaves = []) =>
  leaves.map((leave) => ({
    id: leave.id,
    displayName: leave.displayName,
    group: leave.group,
    systemKey: leave.systemKey,
    name: leave.displayName,
  }));
