import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import {
  employeeProfileUrls,
  employeeDropDownUrls,
  employeesUrls,
  goalsUrls,
  performanceReviewUrls,
} from 'configs/constants';
import { showLoader, hideLoader } from 'store/actions/AppLoaderActions';
import {
  normalizeGetEmployeeProfileHeaderResponse,
  normalizeGetEmployeeInfoResponse,
  normalizeGetEmployeeJobInfoResponse,
  normalizeEmployeeJobInfoHistory,
  normalizeEmployeeJobInfoTracking,
  normalizeGetEmployeeCompensationResponse,
  normalizeGetEmployeeTalentResponse,
  normalizeEmployeeTalentCertificates,
  normalizeGetEmployeeEmploymentHistoryResponse,
  normalizeGetEmployeeEducationHistoryResponse,
  normalizeEmployeeEmployment,
  normalizeEmployeeCommentResponse,
  normalizeGetEmployeeDocumentsResponse,
  normalizeGetMaritalStatusesResponse,
  normalizeGetEmploymentStatusesResponse,
  normalizeGetBloodGroupsResponse,
  normalizeGetReligionsResponse,
  normalizeEmergencyContactResponse,
  normalizeGetPayFrequenciesResponse,
  normalizeGetBenefitsResponse,
  normalizeCompensationJobsInfoOnly,
  normalizeCompensationReasons,
  normalizeCreateEmployeeCompensationResponse,
  normalizeGetQualificationsResponse,
  normalizeGetEmployeeAuditLogsResponse,
} from 'normalizers/employeeProfileResponseNormalizers';
import {
  normalizeUpdateEmployeeBasicInfoRequest,
  normalizeUpdateEmployeeFamilyInfoRequest,
  normalizeUpdateEmployeeCompensationRequest,
  normalizeCreateEmployeeCompensationRequest,
  normalizeEmployeeEducationHistoryRequest,
  normalizeRequest,
  normalizeEmployeeTalentRequest,
} from 'normalizers/employeeProfileRequestNormalizers';
import {
  getEmployeeProfileInit,
  getAuditLogsInit,
  getEmployeeProfileHeaderInit,
  getEmployeeProfileHeaderSuccess,
  getEmployeeProfileHeaderFailure,
  employeeProfileClear,
  getEmployeeInfoSuccess,
  getEmployeeInfoFailure,
  updateEmployeeInfoInit,
  updateEmployeeInfoSuccess,
  updateEmployeeInfoFailure,
  createEmployeeFamilyInfoInit,
  createEmployeeFamilyInfoSuccess,
  createEmployeeFamilyInfoFailure,
  updateEmployeeFamilyInfoInit,
  updateEmployeeFamilyInfoSuccess,
  updateEmployeeFamilyInfoFailure,
  deleteEmployeeFamilyInfoInit,
  deleteEmployeeFamilyInfoSuccess,
  deleteEmployeeFamilyInfoFailure,
  getEmployeeJobInfoInit,
  getEmployeeJobInfoFailure,
  getEmployeeJobInfoSuccess,
  updateEmployeeJobInfoInit,
  updateEmployeeJobInfoFailure,
  updateEmployeeJobInfoSuccess,
  deleteEmployeeJobInfoInit,
  deleteEmployeeJobInfoFailure,
  deleteEmployeeJobInfoSuccess,
  createEmployeeJobInfoInit,
  createEmployeeJobInfoFailure,
  createEmployeeJobInfoSuccess,
  getEmployeeTrackingInfoInit,
  getEmployeeTrackingInfoSuccess,
  getEmployeeTrackingInfoFailure,
  updateEmployeeTrackingInfoInit,
  updateEmployeeTrackingInfoSuccess,
  updateEmployeeTrackingInfoFailure,
  getEmployeeCompensationinit,
  getEmployeeCompensationSuccess,
  getEmployeeCompensationFailure,
  getPayFrequenciesSuccess,
  getPayFrequenciesFailure,
  getBenefitsSuccess,
  getBenefitsFailure,
  getReasonsSuccess,
  getReasonsFailure,
  getEmployeeJobsListingSuccess,
  getEmployeeJobsListingFailure,
  updateEmployeeCompensationInit,
  updateEmployeeCompensationSuccess,
  updateEmployeeCompensationFailure,
  createEmployeeCompensationInit,
  createEmployeeCompensationSuccess,
  createEmployeeCompensationFailure,
  deleteEmployeeCompensationInit,
  deleteEmployeeCompensationSuccess,
  deleteEmployeeCompensationFailure,
  getEmployeeTalentInit,
  getEmployeeTalentSuccess,
  getEmployeeTalentFailure,
  getEmployeeEmploymentHistoryInit,
  getEmployeeEmploymentHistorySuccess,
  getEmployeeEmploymentHistoryFailure,
  getEmployeeEducationHistoryInit,
  getEmployeeEducationHistorySuccess,
  getEmployeeEducationHistoryFailure,
  getEmployeeDocumentsInit,
  getEmployeeDocumentsSuccess,
  getEmployeeDocumentsFailure,
  getEmployeeDocumentURLSuccess,
  getCommentAttachmentURLSuccess,
  createEmployeeDocumentInit,
  createEmployeeDocumentFailure,
  createEmployeeDocumentSuccess,
  updateEmployeePictureSuccess,
  updateEmployeePictureFailure,
  updateEmployeePictureInit,
  getEmployeeDocumentURLFailure,
  getEmployeeCommentInit,
  getEmployeeCommentSuccess,
  getEmployeeCommentFailure,
  createEmployeeCommentInit,
  createEmployeeCommentSuccess,
  createEmployeeCommentFailure,
  deleteEmployeeCommentInit,
  deleteEmployeeCommentSuccess,
  deleteEmployeeCommentFailure,
  getEmployeeAuditLogsSuccess,
  getEmployeeAuditLogsFailure,
  getReligionsInit,
  getReligionsFailure,
  getReligionsSuccess,
  getMartialStatusesInit,
  getMartialStatusesSuccess,
  getMartialStatusesFailure,
  getEmploymentStatusesInit,
  getEmploymentStatusesSuccess,
  getEmploymentStatusesFailure,
  getBloodGroupsInit,
  getBloodGroupsSuccess,
  getBloodGroupsFailure,
  getQualificationsInit,
  getQualificationsSuccess,
  getQualificationsFailure,
  employeeNotFoundAction,
  updateEmployeeEmergencyContactInit,
  updateEmployeeEmergencyContactSuccess,
  deleteEmergencyContactInit,
  deleteEmergencyContactSuccess,
  deleteEmergencyContactFailure,
  updateEmployeeEmergencyContactFailure,
  createEmergencyContactFailure,
  createEmergencyContactInit,
  createEmergencyContactSuccess,
  deleteEmployeeDocumentSuccess,
  deleteEmployeeDocumentInit,
  deleteEmployeeDocumentFailure,
  createEmployeeEducationHistoryInit,
  createEmployeeEducationHistorySuccess,
  createEmployeeEducationHistoryFailure,
  updateEmployeeEducationHistoryInit,
  updateEmployeeEducationHistorySuccess,
  updateEmployeeEducationHistoryFailure,
  deleteEmployeeEducationHistoryInit,
  deleteEmployeeEducationHistorySuccess,
  deleteEmployeeEducationHistoryFailure,
  updateEmployeeTalentInit,
  updateEmployeeTalentSuccess,
  updateEmployeeTalentFailure,
  updateEmployeeCareerPlanningSuccess,
  updateEmployeeCareerPlanningInit,
  updateEmployeeCareerPlanningFailure,
  getEmployeeCertificatesInit,
  getEmployeeCertificatesSuccess,
  getEmployeeCertificatesFailure,
  createEmployeeCertificatesInit,
  createEmployeeCertificatesSuccess,
  createEmployeeCertificatesFailure,
  updateEmployeeCertificatesInit,
  updateEmployeeCertificatesSuccess,
  updateEmployeeCertificatesFailure,
  deleteEmployeeCertificatesInit,
  deleteEmployeeCertificatesSuccess,
  deleteEmployeeCertificatesFailure,
  employmentCallInit,
  employmentCallFailure,
  updateEmployeeEmploymentSuccess,
  createEmployeeEmploymentSuccess,
  deleteEmployeeEmploymentSuccess,
  deActivateEmployeeInit,
  deActivateEmployeeSuccess,
  deActivateEmployeeFailure,
  getGoalInit,
  getGoalSuccess,
  getGoalFailure,
  getGoalDetailsInit,
  getGoalDetailsSuccess,
  getGoalDetailsFailure,
  deleteGoalInit,
  deleteGoalSuccess,
  deleteGoalFailure,
  updateGoalInit,
  updateGoalSuccess,
  updateGoallFailure,
  updateGoalStatusInit,
  updateGoalStatusSuccess,
  updateGoalStatusFailure,
  getPerformanceReviewInit,
  getPerformanceReviewSuccess,
  getPerformanceReviewFailure,
} from 'store/actions/EmployeeProfileActions';
// import { getDesignationsDataAction } from 'store/actions/MiscellaneousActions';
import { ERROR_404 } from 'configs/errorConstants';
import {
  CREATE_SUCCESS_MESSAGE,
  UPDATE_SUCCESS_MESSAGE,
  DELETE_SUCCESS_MESSAGE,
  EMPLOYEE_NOT_FOUND_MESSAGE,
  EMPLOYEE_INFO_UPDATE_FAILED_MESSAGE,
  EMPLOYEE_FAMILY_INFO_CREATE_FAILED_MESSAGE,
  EMPLOYEE_FAMILY_INFO_UPDATE_FAILED_MESSAGE,
  EMPLOYEE_FAMILY_INFO_DELETE_FAILED_MESSAGE,
  EMPLOYEE_EMERGENCY_CONTACT_CREATE_FAILED_MESSAGE,
  EMPLOYEE_EMERGENCY_CONTACT_DELETE_FAILED_MESSAGE,
  EMPLOYEE_JOB_INFO_UPDATE_FAILED_MESSAGE,
  EMPLOYEE_JOB_INFO_DELETE_FAILED_MESSAGE,
  EMPLOYEE_DOCUMENT_CREATE_FAILED_MESSAGE,
  EMPLOYEE_DOCUMENT_DELETE_FAILED_MESSAGE,
  EMPLOYEE_COMMENT_CREATE_FAILED_MESSAGE,
  EMPLOYEE_COMMENT_DELETE_FAILED_MESSAGE,
  EMPLOYEE_COMMENT_ATTACHMENT_GET_FAILED_MESSAGE,
  EMPLOYEE_EDUCATION_HISTORY_CREATE_FAILED_MESSAGE,
  EMPLOYEE_EDUCATION_HISTORY_UPDATE_FAILED_MESSAGE,
  EMPLOYEE_EDUCATION_HISTORY_DELETE_FAILED_MESSAGE,
  EMPLOYEE_TRACKING_INFO_UPDATE_FAILED_MESSAGE,
  EMPLOYEE_EMPLOYMENT_UPDATE_FAILED_MESSAGE,
  EMPLOYEE_EMPLOYMENT_CREATE_FAILED_MESSAGE,
  EMPLOYEE_EMPLOYMENT_DELETE_FAILED_MESSAGE,
  EMPLOYEE_TALENT_UPDATE_FAILED_MESSAGE,
  EMPLOYEE_CAREER_PLANNING_UPDATE_FAILED_MESSAGE,
  // EMPLOYEE_AUDIT_LOGS_GET_FAILED_MESSAGE,
  EMPLOYEE_CERTIFICATE_UPDATE_FAILED_MESSAGE,
  EMPLOYEE_CERTIFICATE_CREATE_FAILED_MESSAGE,
  EMPLOYEE_CERTIFICATE_DELETE_FAILED_MESSAGE,
  EMPLOYEE_PHOTO_UPDATE_FAILED_MESSAGE,
} from 'configs/messageConstants';

export const getEmployeeProfileHeader = (employeeId) => (dispatch) => {
  const url = employeeProfileUrls.PROFILE_HEADER.GET.replace('{employeeId}', employeeId);
  dispatch(employeeProfileClear());
  dispatch(getEmployeeProfileHeaderInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedData = normalizeGetEmployeeProfileHeaderResponse(response.data.data);
        dispatch(getEmployeeProfileHeaderSuccess(normalizedData));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_NOT_FOUND_MESSAGE,
        });
        dispatch(getEmployeeProfileHeaderFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_NOT_FOUND_MESSAGE,
      });
      dispatch(getEmployeeProfileHeaderFailure());
      if (error.response && error.response.status === ERROR_404) {
        dispatch(employeeNotFoundAction());
      }
    });
};

export const getEmployeeInfo = (employeeId) => (dispatch) => {
  const url = employeeProfileUrls.INFO.GET.replace('{employeeId}', employeeId);
  dispatch(getEmployeeProfileInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedData = normalizeGetEmployeeInfoResponse(response.data.data);
        dispatch(getEmployeeInfoSuccess(normalizedData));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_NOT_FOUND_MESSAGE,
        });
        dispatch(getEmployeeInfoFailure());
      }
    })
    .catch((error) => {
      // EnhancedNotification.error({
      //   message:
      //     (error.response &&
      //       error.response.data &&
      //       error.response.data.message) ||
      //     EMPLOYEE_NOT_FOUND_MESSAGE,
      // });
      dispatch(getEmployeeInfoFailure(error.response.data));
      if (error.response && error.response.status === ERROR_404) {
        dispatch(employeeNotFoundAction());
      }
    });
};

export const updateEmployeeInfo = (employeeId, data) => (dispatch) => {
  const url = employeeProfileUrls.INFO.UPDATE.replace('{employeeId}', employeeId);
  const normalizedData = normalizeUpdateEmployeeBasicInfoRequest(data);
  dispatch(updateEmployeeInfoInit());
  axios
    .put(url, normalizedData)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: UPDATE_SUCCESS_MESSAGE,
        });
        dispatch(updateEmployeeInfoSuccess(data));

        // refetch some APIs to show updated data on UI
        // dispatch(getEmployeeProfileHeader(employeeId));
        dispatch(getEmployeeInfo(employeeId));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_INFO_UPDATE_FAILED_MESSAGE,
        });
        dispatch(updateEmployeeInfoFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_INFO_UPDATE_FAILED_MESSAGE,
      });
      dispatch(getEmployeeInfoFailure());
      if (error.response && error.response.status === ERROR_404) {
        dispatch(employeeNotFoundAction());
      }
    });
};

export const createEmployeeFamilyInfo = (employeeId, data) => (dispatch) => {
  const url = employeeProfileUrls.INFO.FAMILY.CREATE.replace('{employeeId}', employeeId);
  const normalizedData = normalizeUpdateEmployeeFamilyInfoRequest(data);
  dispatch(createEmployeeFamilyInfoInit());
  axios
    .post(url, normalizedData)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: CREATE_SUCCESS_MESSAGE,
        });
        dispatch(createEmployeeFamilyInfoSuccess(response.data.data));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_FAMILY_INFO_CREATE_FAILED_MESSAGE,
        });
        dispatch(createEmployeeFamilyInfoFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_FAMILY_INFO_CREATE_FAILED_MESSAGE,
      });
      dispatch(createEmployeeFamilyInfoFailure());
    });
};

export const updateEmployeeFamilyInfo = (index, employeeId, familyInfoId, data) => (dispatch) => {
  const url = employeeProfileUrls.INFO.FAMILY.UPDATE.replace('{employeeId}', employeeId).replace(
    '{familyInfoId}',
    familyInfoId,
  );
  const normalizedData = normalizeUpdateEmployeeFamilyInfoRequest(data);
  dispatch(updateEmployeeFamilyInfoInit());
  axios
    .put(url, normalizedData)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: UPDATE_SUCCESS_MESSAGE,
        });
        dispatch(updateEmployeeFamilyInfoSuccess(index, data));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_FAMILY_INFO_UPDATE_FAILED_MESSAGE,
        });
        dispatch(updateEmployeeFamilyInfoFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_FAMILY_INFO_UPDATE_FAILED_MESSAGE,
      });
      dispatch(updateEmployeeFamilyInfoFailure());
    });
};

export const deleteEmployeeFamilyInfo = (index, employeeId, familyInfoId) => (dispatch) => {
  const url = employeeProfileUrls.INFO.FAMILY.DELETE.replace('{employeeId}', employeeId).replace(
    '{familyInfoId}',
    familyInfoId,
  );
  dispatch(deleteEmployeeFamilyInfoInit());
  axios
    .delete(url)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: DELETE_SUCCESS_MESSAGE,
        });
        dispatch(deleteEmployeeFamilyInfoSuccess(index));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_FAMILY_INFO_DELETE_FAILED_MESSAGE,
        });
        dispatch(deleteEmployeeFamilyInfoFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_FAMILY_INFO_DELETE_FAILED_MESSAGE,
      });
      dispatch(deleteEmployeeFamilyInfoFailure());
    });
};

export const getEmployeeJobInfo = (employeeId) => (dispatch) => {
  const url = employeeProfileUrls.JOB_INFO.GET.replace('{employeeId}', employeeId);
  dispatch(getEmployeeJobInfoInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedData = normalizeGetEmployeeJobInfoResponse(response.data.data);
        dispatch(getEmployeeJobInfoSuccess(normalizedData));
      } else {
        dispatch(getEmployeeJobInfoFailure());
      }
    })
    .catch(() => {
      dispatch(getEmployeeJobInfoFailure());
    });
};

export const deleteEmployeeJobInfo = (employeeId, jobInfoId) => (dispatch) => {
  const url = employeeProfileUrls.JOB_INFO.DELETE.replace('{employeeId}', employeeId).replace(
    '{jobInfoId}',
    jobInfoId,
  );
  dispatch(deleteEmployeeJobInfoInit());
  axios
    .delete(url)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: DELETE_SUCCESS_MESSAGE,
        });
        dispatch(deleteEmployeeJobInfoSuccess(jobInfoId));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_JOB_INFO_DELETE_FAILED_MESSAGE,
        });
        dispatch(deleteEmployeeJobInfoFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_JOB_INFO_DELETE_FAILED_MESSAGE,
      });
      dispatch(deleteEmployeeJobInfoFailure());
    });
};
export const updateEmployeeJobInfo = (employeeId, jobInfoId, data, index) => (dispatch) => {
  const url = employeeProfileUrls.JOB_INFO.UPDATE.replace('{employeeId}', employeeId).replace(
    '{jobInfoId}',
    jobInfoId,
  );
  const normalizedRequestData = normalizeRequest(data);
  dispatch(updateEmployeeJobInfoInit());
  axios
    .put(url, normalizedRequestData)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: UPDATE_SUCCESS_MESSAGE,
        });

        const normalizedResponseData = normalizeEmployeeJobInfoHistory(data);
        dispatch(updateEmployeeJobInfoSuccess(normalizedResponseData, index));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_JOB_INFO_UPDATE_FAILED_MESSAGE,
        });
        dispatch(updateEmployeeJobInfoFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_JOB_INFO_UPDATE_FAILED_MESSAGE,
      });
      dispatch(updateEmployeeJobInfoFailure());
    });
};

export const createEmployeeJobInfo = (employeeId, data) => (dispatch) => {
  const url = employeeProfileUrls.JOB_INFO.CREATE.replace('{employeeId}', employeeId);
  const normalizedDataRequest = normalizeRequest(data);
  dispatch(createEmployeeJobInfoInit());
  axios
    .post(url, normalizedDataRequest)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: CREATE_SUCCESS_MESSAGE,
        });

        const normalizedJobInfoData = normalizeEmployeeJobInfoHistory(response.data.data);
        dispatch(createEmployeeJobInfoSuccess(normalizedJobInfoData));
        dispatch(getEmployeeInfo(employeeId));
      } else {
        dispatch(createEmployeeJobInfoFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_JOB_INFO_UPDATE_FAILED_MESSAGE,
      });
      dispatch(createEmployeeJobInfoFailure());
    });
};

export const getEmployeeTrackingInfo = (employeeId) => (dispatch) => {
  const url = employeeProfileUrls.TRACKING.GET.replace('{employeeId}', employeeId);

  dispatch(getEmployeeTrackingInfoInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        // EnhancedNotification.success({
        //   message: UPDATE_SUCCESS_MESSAGE,
        // });
        const normalizedData = normalizeEmployeeJobInfoTracking(response.data.data);
        dispatch(getEmployeeTrackingInfoSuccess(normalizedData));
      } else {
        // EnhancedNotification.error({
        //   message: EMPLOYEE_INFO_UPDATE_FAILED_MESSAGE,
        // });
      }
    })
    .catch((error) => {
      // EnhancedNotification.error({
      //   message:
      //     error.response.data.message ||
      //     EMPLOYEE_TRACKING_INFO_UPDATE_FAILED_MESSAGE,
      // });
      dispatch(getEmployeeTrackingInfoFailure(error.response.data));
    });
};

export const updateEmployeeTrackingInfo = (employeeId, data) => (dispatch) => {
  const url = employeeProfileUrls.TRACKING.UPDATE.replace('{employeeId}', employeeId);

  dispatch(updateEmployeeTrackingInfoInit());
  axios
    .put(url, data)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: UPDATE_SUCCESS_MESSAGE,
        });
        const normalizedData = normalizeEmployeeJobInfoTracking(response.data.data);
        dispatch(updateEmployeeTrackingInfoSuccess(normalizedData));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_INFO_UPDATE_FAILED_MESSAGE,
        });
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: error.response.data.message || EMPLOYEE_TRACKING_INFO_UPDATE_FAILED_MESSAGE,
      });
      dispatch(updateEmployeeTrackingInfoFailure());
    });
};

export const getEmployeeCompensation = (employeeId) => (dispatch) => {
  const url = employeeProfileUrls.COMPENSATION.GET.replace('{employeeId}', employeeId);
  dispatch(getEmployeeCompensationinit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedData = normalizeGetEmployeeCompensationResponse(response.data.data);
        dispatch(getEmployeeCompensationSuccess(normalizedData));
      } else {
        dispatch(getEmployeeCompensationFailure());
      }
    })
    .catch((error) => {
      dispatch(getEmployeeCompensationFailure(error.response.data));
    });
};

export const getBenefits = () => (dispatch) => {
  const url = employeeProfileUrls.BENEFITS.GET;
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedData = normalizeGetBenefitsResponse(response.data.data);
        dispatch(getBenefitsSuccess(normalizedData));
      } else {
        dispatch(getBenefitsFailure());
      }
    })
    .catch(() => {
      dispatch(getBenefitsFailure());
    });
};

export const getPayFrequencies = (employeeId) => (dispatch) => {
  const url = employeeProfileUrls.PAY_FREQUENCIES.GET.replace('{employeeId}', employeeId);
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedData = normalizeGetPayFrequenciesResponse(response.data.data);
        dispatch(getPayFrequenciesSuccess(normalizedData));
      } else {
        dispatch(getPayFrequenciesFailure());
      }
    })
    .catch(() => {
      dispatch(getPayFrequenciesFailure());
    });
};

export const getReasons = () => (dispatch) => {
  const url = employeeProfileUrls.REASONS.GET;
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedData = normalizeCompensationReasons(response.data.data);
        dispatch(getReasonsSuccess(normalizedData));
      } else {
        dispatch(getReasonsFailure());
      }
    })
    .catch(() => {
      dispatch(getReasonsFailure());
    });
};

export const getEmployeeJobsListing = (employeeId) => (dispatch) => {
  const url = employeeProfileUrls.JOBS_LISTING.GET.replace('{employeeId}', employeeId);
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedData = normalizeCompensationJobsInfoOnly(response.data.data);
        dispatch(getEmployeeJobsListingSuccess(normalizedData));
      } else {
        dispatch(getEmployeeJobsListingFailure());
      }
    })
    .catch(() => {
      dispatch(getEmployeeJobsListingFailure());
    });
};

export const createEmployeeCompensation = (employeeId, data) => (dispatch) => {
  const url = employeeProfileUrls.COMPENSATION.CREATE.replace('{employeeId}', employeeId);
  const normalizedDataRequest = normalizeCreateEmployeeCompensationRequest(data);
  dispatch(createEmployeeCompensationInit());
  axios
    .post(url, normalizedDataRequest)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: CREATE_SUCCESS_MESSAGE,
        });
        const normalizedData = normalizeCreateEmployeeCompensationResponse(response.data.data);
        dispatch(createEmployeeCompensationSuccess(normalizedData));
      } else {
        dispatch(createEmployeeCompensationFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          error && error.response && error.response.data
            ? error.response.data.message
            : 'Something went wrong!',
      });
      dispatch(createEmployeeCompensationFailure());
    });
};

export const updateEmployeeCompensation = (employeeId, compensationId, data) => (dispatch) => {
  const url = employeeProfileUrls.COMPENSATION.UPDATE.replace('{employeeId}', employeeId).replace(
    '{compensationId}',
    compensationId,
  );
  const normalizedDataRequest = normalizeUpdateEmployeeCompensationRequest(data);
  dispatch(updateEmployeeCompensationInit());
  axios
    .put(url, normalizedDataRequest)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: UPDATE_SUCCESS_MESSAGE,
        });
        dispatch(updateEmployeeCompensationSuccess(data));
        dispatch(getEmployeeCompensation(employeeId));
      } else {
        dispatch(updateEmployeeCompensationFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          error && error.response && error.response.data
            ? error.response.data.message
            : 'Something went wrong!',
      });
      dispatch(updateEmployeeCompensationFailure());
    });
};

export const deleteEmployeeCompensation = (employeeId, compensationId) => (dispatch) => {
  const url = employeeProfileUrls.COMPENSATION.UPDATE.replace('{employeeId}', employeeId).replace(
    '{compensationId}',
    compensationId,
  );
  dispatch(deleteEmployeeCompensationInit());
  axios
    .delete(url)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: DELETE_SUCCESS_MESSAGE,
        });
        dispatch(deleteEmployeeCompensationSuccess());
        dispatch(getEmployeeCompensation(employeeId));
      } else {
        dispatch(deleteEmployeeCompensationFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          error && error.response && error.response.data
            ? error.response.data.message
            : 'Something went wrong!',
      });
      dispatch(deleteEmployeeCompensationFailure());
    });
};

export const getEmployeeTalent = (employeeId) => (dispatch) => {
  const url = employeeProfileUrls.TALENT.GET.replace('{employeeId}', employeeId);
  dispatch(getEmployeeTalentInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedData = normalizeGetEmployeeTalentResponse(response.data.data);
        dispatch(getEmployeeTalentSuccess(normalizedData));
      } else {
        dispatch(getEmployeeTalentFailure());
      }
    })
    .catch(() => {
      dispatch(getEmployeeTalentFailure());
    });
};

export const updateEmployeeTalent = (employeeId, data) => (dispatch) => {
  const url = employeeProfileUrls.TALENT.UPDATE.replace('{employeeId}', employeeId);
  const normalizedData = normalizeEmployeeTalentRequest(data);
  dispatch(updateEmployeeTalentInit());
  axios
    .put(url, normalizedData)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: UPDATE_SUCCESS_MESSAGE,
        });
        dispatch(updateEmployeeTalentSuccess(normalizedData));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_TALENT_UPDATE_FAILED_MESSAGE,
        });
        dispatch(updateEmployeeTalentFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_TALENT_UPDATE_FAILED_MESSAGE,
      });
      dispatch(updateEmployeeTalentFailure());
    });
};

export const updateEmployeeCareerPlanning = (employeeId, data) => (dispatch) => {
  const url = employeeProfileUrls.TALENT.CAREER_PLANNING.UPDATE.replace('{employeeId}', employeeId);
  const normalizedData = normalizeRequest(data);
  dispatch(updateEmployeeCareerPlanningInit());
  axios
    .put(url, normalizedData)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: UPDATE_SUCCESS_MESSAGE,
        });
        dispatch(updateEmployeeCareerPlanningSuccess(normalizedData));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_CAREER_PLANNING_UPDATE_FAILED_MESSAGE,
        });
        dispatch(updateEmployeeCareerPlanningFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_CAREER_PLANNING_UPDATE_FAILED_MESSAGE,
      });
      dispatch(updateEmployeeCareerPlanningFailure());
    });
};

export const getEmployeeCertificates = (employeeId) => (dispatch) => {
  const url = employeeProfileUrls.EMPLOYEE_CERTIFICATES.GET.replace('{employeeId}', employeeId);
  dispatch(getEmployeeCertificatesInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedData = normalizeEmployeeTalentCertificates(response.data.data);
        dispatch(getEmployeeCertificatesSuccess(normalizedData));
      } else {
        dispatch(getEmployeeCertificatesFailure());
      }
    })
    .catch(() => {
      dispatch(getEmployeeCertificatesFailure());
    });
};

export const createEmployeeCertificates = (employeeId, data) => (dispatch) => {
  const url = employeeProfileUrls.TALENT.CERTIFICATES.CREATE.replace('{employeeId}', employeeId);
  const normalizedRequestData = normalizeRequest(data);
  dispatch(createEmployeeCertificatesInit());
  axios
    .post(url, normalizedRequestData)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: CREATE_SUCCESS_MESSAGE,
        });
        const normalizedData = normalizeEmployeeTalentCertificates([response.data.data]);
        dispatch(createEmployeeCertificatesSuccess(...normalizedData));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_CERTIFICATE_CREATE_FAILED_MESSAGE,
        });
        dispatch(createEmployeeCertificatesFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_CERTIFICATE_CREATE_FAILED_MESSAGE,
      });
      dispatch(createEmployeeCertificatesFailure());
    });
};
export const updateEmployeeCertificates =
  (employeeId, certificateId, data, index) => (dispatch) => {
    let url = employeeProfileUrls.TALENT.CERTIFICATES.UPDATE.replace('{employeeId}', employeeId);
    url = url.replace('{certificateId}', certificateId);
    const normalizedRequestData = normalizeRequest(data);
    dispatch(updateEmployeeCertificatesInit());
    axios
      .put(url, normalizedRequestData)
      .then((response) => {
        if (response.data.success) {
          EnhancedNotification.success({
            message: UPDATE_SUCCESS_MESSAGE,
          });

          dispatch(updateEmployeeCertificatesSuccess(data, index));
        } else {
          EnhancedNotification.error({
            message: EMPLOYEE_CERTIFICATE_UPDATE_FAILED_MESSAGE,
          });
          dispatch(updateEmployeeCertificatesFailure(index));
        }
      })
      .catch((error) => {
        EnhancedNotification.error({
          message:
            (error.response && error.response.data && error.response.data.message) ||
            EMPLOYEE_CERTIFICATE_UPDATE_FAILED_MESSAGE,
        });
        dispatch(updateEmployeeCertificatesFailure(index));
      });
  };

export const deleteEmployeeCertificates = (employeeId, certificateId, index) => (dispatch) => {
  let url = employeeProfileUrls.TALENT.CERTIFICATES.DELETE.replace('{employeeId}', employeeId);
  url = url.replace('{certificateId}', certificateId);
  dispatch(deleteEmployeeCertificatesInit());
  axios
    .delete(url)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: DELETE_SUCCESS_MESSAGE,
        });
        dispatch(deleteEmployeeCertificatesSuccess(certificateId));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_CERTIFICATE_DELETE_FAILED_MESSAGE,
        });
        dispatch(deleteEmployeeCertificatesFailure(index));
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_CERTIFICATE_DELETE_FAILED_MESSAGE,
      });
      dispatch(deleteEmployeeCertificatesFailure(index));
    });
};

export const getEmployeeEmploymentHistory = (employeeId) => (dispatch) => {
  const url = employeeProfileUrls.EMPLOYMENT_HISTORY.GET.replace('{employeeId}', employeeId);
  dispatch(getEmployeeEmploymentHistoryInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedData = normalizeGetEmployeeEmploymentHistoryResponse(response.data.data);
        dispatch(getEmployeeEmploymentHistorySuccess(normalizedData));
      } else {
        dispatch(getEmployeeEmploymentHistoryFailure());
      }
    })
    .catch((error) => {
      dispatch(getEmployeeEmploymentHistoryFailure(error.response.data));
    });
};

export const updateEmployeeEmploymentHistory =
  (employeeId, employmentId, data, index) => (dispatch) => {
    const url = employeeProfileUrls.EMPLOYMENT_HISTORY.UPDATE.replace(
      '{employeeId}',
      employeeId,
    ).replace('{employmentId}', employmentId);
    dispatch(employmentCallInit());
    axios
      .put(url, data)
      .then((response) => {
        if (response.data.success) {
          EnhancedNotification.success({
            message: UPDATE_SUCCESS_MESSAGE,
          });
          dispatch(updateEmployeeEmploymentSuccess({ ...data }, index));
        } else {
          EnhancedNotification.error({
            message: EMPLOYEE_EMPLOYMENT_UPDATE_FAILED_MESSAGE,
          });
          dispatch(employmentCallFailure());
        }
      })
      .catch((error) => {
        EnhancedNotification.error({
          message:
            (error.response && error.response.data && error.response.data.message) ||
            EMPLOYEE_EMPLOYMENT_UPDATE_FAILED_MESSAGE,
        });
        dispatch(employmentCallFailure());
      });
  };

export const createEmployeeEmploymentHistory = (employeeId, data, index) => (dispatch) => {
  const url = employeeProfileUrls.EMPLOYMENT_HISTORY.CREATE.replace('{employeeId}', employeeId);
  dispatch(employmentCallInit());
  axios
    .post(url, data)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: CREATE_SUCCESS_MESSAGE,
        });
        const normalizedData = normalizeEmployeeEmployment(response.data.data);
        dispatch(createEmployeeEmploymentSuccess({ ...normalizedData }, index));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_EMPLOYMENT_CREATE_FAILED_MESSAGE,
        });
        dispatch(employmentCallFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_EMPLOYMENT_CREATE_FAILED_MESSAGE,
      });
      dispatch(employmentCallFailure());
    });
};

export const deleteEmployeeEmploymentHistory = (employeeId, employmentId) => (dispatch) => {
  const url = employeeProfileUrls.EMPLOYMENT_HISTORY.DELETE.replace(
    '{employeeId}',
    employeeId,
  ).replace('{employmentId}', employmentId);
  dispatch(employmentCallInit());
  axios
    .delete(url)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: DELETE_SUCCESS_MESSAGE,
        });
        dispatch(deleteEmployeeEmploymentSuccess(employmentId));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_EMPLOYMENT_DELETE_FAILED_MESSAGE,
        });
        dispatch(employmentCallFailure());
      }
    })
    .catch(() => {
      // EnhancedNotification.error({
      //   message:
      //     (error.response &&
      //       error.response.data &&
      //       error.response.data.message) ||
      //     EMPLOYEE_EMPLOYMENT_DELETE_FAILED_MESSAGE,
      // });
      dispatch(employmentCallFailure());
    });
};

export const getEmployeeEducationHistory = (employeeId) => (dispatch) => {
  const url = employeeProfileUrls.EDUCATION_HISTORY.GET.replace('{employeeId}', employeeId);
  dispatch(getEmployeeEducationHistoryInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedData = normalizeGetEmployeeEducationHistoryResponse(response.data.data);
        dispatch(getEmployeeEducationHistorySuccess(normalizedData));
      } else {
        dispatch(getEmployeeEducationHistoryFailure());
      }
    })
    .catch(() => {
      dispatch(getEmployeeEducationHistoryFailure());
    });
};

export const createEmployeeEducationHistory = (employeeId, data) => (dispatch) => {
  const url = employeeProfileUrls.EDUCATION_HISTORY.CREATE.replace('{employeeId}', employeeId);
  const normalizedRequestData = normalizeEmployeeEducationHistoryRequest(data);
  dispatch(createEmployeeEducationHistoryInit());
  axios
    .post(url, normalizedRequestData)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: CREATE_SUCCESS_MESSAGE,
        });
        const normalizedData = normalizeGetEmployeeEducationHistoryResponse([response.data.data]);
        dispatch(createEmployeeEducationHistorySuccess(...normalizedData));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_EDUCATION_HISTORY_CREATE_FAILED_MESSAGE,
        });
        dispatch(createEmployeeEducationHistoryFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_EDUCATION_HISTORY_CREATE_FAILED_MESSAGE,
      });
      dispatch(createEmployeeEducationHistoryFailure());
    });
};
export const updateEmployeeEducationHistory =
  (employeeId, educationId, data, index) => (dispatch) => {
    let url = employeeProfileUrls.EDUCATION_HISTORY.UPDATE.replace('{employeeId}', employeeId);
    url = url.replace('{educationId}', educationId);
    const normalizedRequestData = normalizeEmployeeEducationHistoryRequest(data);
    dispatch(updateEmployeeEducationHistoryInit());
    axios
      .put(url, normalizedRequestData)
      .then((response) => {
        if (response.data.success) {
          EnhancedNotification.success({
            message: UPDATE_SUCCESS_MESSAGE,
          });

          const normalizedData = normalizeGetEmployeeEducationHistoryResponse([data]);

          dispatch(updateEmployeeEducationHistorySuccess(...normalizedData, index));
        } else {
          EnhancedNotification.error({
            message: EMPLOYEE_EDUCATION_HISTORY_UPDATE_FAILED_MESSAGE,
          });
          dispatch(updateEmployeeEducationHistoryFailure(index));
        }
      })
      .catch((error) => {
        EnhancedNotification.error({
          message:
            (error.response && error.response.data && error.response.data.message) ||
            EMPLOYEE_EDUCATION_HISTORY_UPDATE_FAILED_MESSAGE,
        });
        dispatch(updateEmployeeEducationHistoryFailure(index));
      });
  };

export const deleteEmployeeEducationHistory = (employeeId, educationId, index) => (dispatch) => {
  let url = employeeProfileUrls.EDUCATION_HISTORY.DELETE.replace('{employeeId}', employeeId);
  url = url.replace('{educationId}', educationId);
  dispatch(deleteEmployeeEducationHistoryInit());
  axios
    .delete(url)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: DELETE_SUCCESS_MESSAGE,
        });
        dispatch(deleteEmployeeEducationHistorySuccess(educationId));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_EDUCATION_HISTORY_DELETE_FAILED_MESSAGE,
        });
        dispatch(deleteEmployeeEducationHistoryFailure(index));
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_EDUCATION_HISTORY_DELETE_FAILED_MESSAGE,
      });
      dispatch(deleteEmployeeEducationHistoryFailure(index));
    });
};

export const getEmployeeDocuments = (employeeId) => (dispatch) => {
  const url = employeeProfileUrls.EMPLOYEE_DOCUMENTS.GET_ALL.replace('{employeeId}', employeeId);
  dispatch(getEmployeeDocumentsInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedData = normalizeGetEmployeeDocumentsResponse(response.data.data);
        dispatch(getEmployeeDocumentsSuccess(normalizedData));
      } else {
        dispatch(getEmployeeDocumentsFailure());
      }
    })
    .catch((error) => {
      dispatch(getEmployeeDocumentsFailure(error.response.data));
    });
};

export const getEmployeeDocumentURL = (employeeId, documentId) => (dispatch) => {
  const url = employeeProfileUrls.EMPLOYEE_DOCUMENTS.GET.replace(
    '{employeeId}',
    employeeId,
  ).replace('{documentId}', documentId);
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const documentUrl = response.data.data.url;
        dispatch(getEmployeeDocumentURLSuccess(documentUrl));
      } else {
        dispatch(getEmployeeDocumentURLFailure());
      }
    })
    .catch(() => {
      dispatch(getEmployeeDocumentsFailure());
    });
};

export const getCommentAttachmentURL = (employeeId, commentId) => (dispatch) => {
  const url = employeeProfileUrls.COMMENTS.GET_ATTACHMENT.replace(
    '{employeeId}',
    employeeId,
  ).replace('{commentId}', commentId);
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const documentUrl = response.data.data.url;
        dispatch(getCommentAttachmentURLSuccess(documentUrl));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_COMMENT_ATTACHMENT_GET_FAILED_MESSAGE,
        });
        dispatch(getEmployeeDocumentURLFailure());
      }
    })
    .catch((error) => {
      dispatch(getEmployeeDocumentURLFailure());
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_COMMENT_ATTACHMENT_GET_FAILED_MESSAGE,
      });
    });
};

export const uploadEmployeeDocument = (documents, employeeId) => (dispatch) => {
  const url = employeeProfileUrls.EMPLOYEE_DOCUMENTS.CREATE.replace('{employeeId}', employeeId);
  const payload = {
    files: documents,
  };
  dispatch(createEmployeeDocumentInit());

  axios
    .post(url, payload)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: UPDATE_SUCCESS_MESSAGE,
        });
        dispatch(createEmployeeDocumentSuccess());
        dispatch(getEmployeeDocuments(employeeId));
      } else {
        dispatch(createEmployeeDocumentFailure());
        EnhancedNotification.error({
          message:
            // error.response && error.response.data && error.response.data.message ||
            EMPLOYEE_DOCUMENT_CREATE_FAILED_MESSAGE,
        });
        // dispatch(createEmployeeDocumentFailure());
      }
    })
    .catch((error) => {
      dispatch(createEmployeeDocumentFailure());
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_DOCUMENT_CREATE_FAILED_MESSAGE,
      });
      // dispatch(getEmployeeDocumentsFailure());
    });
};

export const updateEmployeeDocument = (request) => (dispatch) => {
  const { payload, documentId, employeeId } = request;
  const url = employeeProfileUrls.EMPLOYEE_DOCUMENTS.UPDATE.replace(
    '{employeeId}',
    employeeId,
  ).replace('{documentId}', documentId);
  dispatch(showLoader());
  axios
    .put(url, payload)
    .then((response) => {
      dispatch(hideLoader());
      if (response.data.success) {
        EnhancedNotification.success({
          message: UPDATE_SUCCESS_MESSAGE,
        });

        dispatch(getEmployeeDocuments(employeeId));
      } else {
        dispatch(createEmployeeDocumentFailure());
        EnhancedNotification.error({
          message: EMPLOYEE_DOCUMENT_CREATE_FAILED_MESSAGE,
        });
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      dispatch(createEmployeeDocumentFailure());
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_DOCUMENT_CREATE_FAILED_MESSAGE,
      });
    });
};

export const updateEmployeePicture = (employeeId, image) => (dispatch) => {
  const url = employeeProfileUrls.PROFILE_PICTURE.UPDATE.replace('{employeeId}', employeeId);
  dispatch(updateEmployeePictureInit());
  axios
    .put(url, { image })
    .then((response) => {
      if (response.data.success) {
        const imageUrl = response.data.data.image;
        EnhancedNotification.success({
          message: UPDATE_SUCCESS_MESSAGE,
        });
        dispatch(updateEmployeePictureSuccess(imageUrl));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_PHOTO_UPDATE_FAILED_MESSAGE,
        });
        dispatch(updateEmployeePictureFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_PHOTO_UPDATE_FAILED_MESSAGE,
      });
      dispatch(updateEmployeePictureFailure());
    });
};

export const deleteEmployeeDocument = (employeeId, documentId) => (dispatch) => {
  const url = employeeProfileUrls.EMPLOYEE_DOCUMENTS.DELETE.replace(
    '{employeeId}',
    employeeId,
  ).replace('{documentId}', documentId);
  dispatch(deleteEmployeeDocumentInit());

  axios
    .delete(url)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: DELETE_SUCCESS_MESSAGE,
        });
        dispatch(deleteEmployeeDocumentSuccess(documentId));
      } else {
        dispatch(deleteEmployeeDocumentFailure());
        EnhancedNotification.error({
          message: EMPLOYEE_DOCUMENT_DELETE_FAILED_MESSAGE,
        });
        // dispatch(createEmployeeDocumentFailure());
      }
    })
    .catch((error) => {
      dispatch(deleteEmployeeDocumentFailure());
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_DOCUMENT_DELETE_FAILED_MESSAGE,
      });
    });
};

export const getEmployeeComment = (employeeId) => (dispatch) => {
  const url = employeeProfileUrls.COMMENTS.GET.replace('{employeeId}', employeeId);
  dispatch(getEmployeeCommentInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedData = normalizeEmployeeCommentResponse(response.data.data);
        dispatch(getEmployeeCommentSuccess(normalizedData));
      } else {
        dispatch(getEmployeeCommentFailure());
      }
    })
    .catch((error) => {
      dispatch(getEmployeeCommentFailure(error.response.data));
    });
};

export const getEmployeeAuditLogs = (employeeId) => (dispatch) => {
  const url = employeeProfileUrls.AUDIT_LOGS.GET.replace('{employeeId}', employeeId);
  dispatch(getEmployeeProfileInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        // TODO: call normalizer
        dispatch(getEmployeeAuditLogsSuccess(response.data.data));
      } else {
        dispatch(getEmployeeAuditLogsFailure());
      }
    })
    .catch((error) => {
      dispatch(getEmployeeAuditLogsFailure(error.response.data));
    });
};

export const getMaritalStatuses = () => (dispatch) => {
  const url = employeeDropDownUrls.MARITAL_STATUSES.GET;
  dispatch(getMartialStatusesInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedData = normalizeGetMaritalStatusesResponse(response.data.data);
        dispatch(getMartialStatusesSuccess(normalizedData));
      } else {
        dispatch(getMartialStatusesFailure());
      }
    })
    .catch(() => {
      dispatch(getMartialStatusesFailure());
    });
};

export const getEmploymentStatuses = () => (dispatch) => {
  const url = employeeDropDownUrls.EMPLOYMENT_STATUSES.GET;
  dispatch(getEmploymentStatusesInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedData = normalizeGetEmploymentStatusesResponse(response.data.data);
        dispatch(getEmploymentStatusesSuccess(normalizedData));
      } else {
        dispatch(getEmploymentStatusesFailure());
      }
    })
    .catch(() => {
      dispatch(getEmploymentStatusesFailure());
    });
};

export const getBloodGroups = () => (dispatch) => {
  const url = employeeDropDownUrls.BLOOD_GROUPS.GET;
  dispatch(getBloodGroupsInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedData = normalizeGetBloodGroupsResponse(response.data.data);
        dispatch(getBloodGroupsSuccess(normalizedData));
      } else {
        dispatch(getBloodGroupsFailure());
      }
    })
    .catch(() => {
      dispatch(getBloodGroupsFailure());
    });
};

export const getReligions = () => (dispatch) => {
  const url = employeeDropDownUrls.RELIGIONS.GET;
  dispatch(getReligionsInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedData = normalizeGetReligionsResponse(response.data.data);
        dispatch(getReligionsSuccess(normalizedData));
      } else {
        dispatch(getReligionsFailure());
      }
    })
    .catch(() => {
      dispatch(getReligionsFailure());
    });
};

export const getQualifications = () => (dispatch) => {
  const url = employeeDropDownUrls.QUALIFICATIONS.GET;
  dispatch(getQualificationsInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedData = normalizeGetQualificationsResponse(response.data.data);
        dispatch(getQualificationsSuccess(normalizedData));
      } else {
        dispatch(getQualificationsFailure());
      }
    })
    .catch(() => {
      dispatch(getQualificationsFailure());
    });
};

export const createEmployeeEmergencyContact = (employeeId, data) => (dispatch) => {
  const url = employeeProfileUrls.EMERGENCY_CONTACT.CREATE.replace('{employeeId}', employeeId);
  dispatch(createEmergencyContactInit());
  axios
    .post(url, data)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: CREATE_SUCCESS_MESSAGE,
        });
        const normalizedData = normalizeEmergencyContactResponse(response.data.data);
        dispatch(createEmergencyContactSuccess(normalizedData));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_EMERGENCY_CONTACT_CREATE_FAILED_MESSAGE,
        });
        dispatch(createEmergencyContactFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_EMERGENCY_CONTACT_CREATE_FAILED_MESSAGE,
      });
      dispatch(createEmergencyContactFailure());
    });
};

export const updateEmployeeEmergencyContact = (employeeId, data) => (dispatch) => {
  let url = employeeProfileUrls.EMERGENCY_CONTACT.UPDATE.replace('{employeeId}', employeeId);
  url = url.replace('{emergencyContactId}', data.id);
  dispatch(updateEmployeeEmergencyContactInit());
  axios
    .put(url, data)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: UPDATE_SUCCESS_MESSAGE,
        });
        dispatch(updateEmployeeEmergencyContactSuccess(data));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_INFO_UPDATE_FAILED_MESSAGE,
        });
        dispatch(updateEmployeeEmergencyContactFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_INFO_UPDATE_FAILED_MESSAGE,
      });
      dispatch(updateEmployeeEmergencyContactFailure());
    });
};

export const deleteEmployeeEmergencyContact = (employeeId, emergencyContactId) => (dispatch) => {
  let url = employeeProfileUrls.EMERGENCY_CONTACT.DELETE.replace('{employeeId}', employeeId);
  url = url.replace('{emergencyContactId}', emergencyContactId);
  dispatch(deleteEmergencyContactInit());
  axios
    .delete(url)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: DELETE_SUCCESS_MESSAGE,
        });
        dispatch(deleteEmergencyContactSuccess(emergencyContactId));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_EMERGENCY_CONTACT_DELETE_FAILED_MESSAGE,
        });
        dispatch(deleteEmergencyContactFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_EMERGENCY_CONTACT_DELETE_FAILED_MESSAGE,
      });
      dispatch(deleteEmergencyContactFailure());
    });
};

export const createEmployeeComment = (employeeId, data) => (dispatch) => {
  const url = employeeProfileUrls.COMMENTS.CREATE.replace('{employeeId}', employeeId);
  dispatch(createEmployeeCommentInit());
  axios
    .post(url, data)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: CREATE_SUCCESS_MESSAGE,
        });
        const normalizedData = normalizeEmployeeCommentResponse([{ ...response.data.data }]);
        dispatch(createEmployeeCommentSuccess(normalizedData));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_COMMENT_CREATE_FAILED_MESSAGE,
        });
        dispatch(createEmployeeCommentFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_COMMENT_CREATE_FAILED_MESSAGE,
      });
      dispatch(createEmployeeCommentFailure());
    });
};

export const deleteEmployeeComment = (employeeId, commentId) => (dispatch) => {
  let url = employeeProfileUrls.COMMENTS.DELETE.replace('{employeeId}', employeeId);
  url = url.replace('{commentId}', commentId);
  dispatch(deleteEmployeeCommentInit());
  axios
    .delete(url)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({
          message: DELETE_SUCCESS_MESSAGE,
        });
        dispatch(deleteEmployeeCommentSuccess(commentId));
      } else {
        EnhancedNotification.error({
          message: EMPLOYEE_COMMENT_DELETE_FAILED_MESSAGE,
        });
        dispatch(deleteEmployeeCommentFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message:
          (error.response && error.response.data && error.response.data.message) ||
          EMPLOYEE_COMMENT_DELETE_FAILED_MESSAGE,
      });
    });
};

export const deActivateEmployee = (employeeId) => (dispatch) => {
  const url = employeesUrls.DELETE.replace('{employeeId}', employeeId);
  dispatch(deActivateEmployeeInit());
  axios
    .delete(url)
    .then((response) => {
      if (response.data.success) {
        EnhancedNotification.success({ message: 'Employee deactivated successfully!' });
        dispatch(deActivateEmployeeSuccess(employeeId));
      } else {
        dispatch(deActivateEmployeeFailure(response.data));
      }
    })
    .catch((e) => {
      EnhancedNotification.error({ message: e.response.data.message || 'An error occured' });
      dispatch(deActivateEmployeeFailure(e));
    });
};

// Employee Goal

export const getGoal = (request) => (dispatch) => {
  const url = goalsUrls.GET_GOALS_AS_LM.replace('{empId}', request.empId)
    .replace('{status}', request.status)
    .replace('{pageNumber}', request.pageNumber)
    .replace('{pageSize}', request.pageSize);
  dispatch(getGoalInit());
  axios
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        //   const resourceNormalized = normalizeResourceAvailability(response.data.data, date);
        dispatch(getGoalSuccess(response.data.data));
      } else {
        dispatch(getGoalFailure());
      }
    })
    .catch((error) => {
      dispatch(getGoalFailure(error.response.data));
    });
};

export const getGoalDetails = (request) => (dispatch) => {
  const url = goalsUrls.GET_GOAL_DETAILS_AS_LM.replace('{empId}', request.empId).replace(
    '{gId}',
    request.id,
  );
  dispatch(getGoalDetailsInit());
  axios
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        //   const resourceNormalized = normalizeResourceAvailability(response.data.data, date);
        dispatch(getGoalDetailsSuccess(response.data.data));
      } else {
        dispatch(getGoalDetailsFailure());
      }
    })
    .catch((error) => {
      dispatch(getGoalDetailsFailure(error));
    });
};

export const removeGoal = (request) => (dispatch) => {
  const { payload, params, viewDetails } = request;
  const url = goalsUrls.DELETE_GOAL_AS_LM.replace('{empId}', payload.empId).replace(
    '{gId}',
    payload.goalId,
  );
  dispatch(deleteGoalInit());
  axios
    .delete(url)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Deleted Successfully',
        });

        dispatch(deleteGoalSuccess(response.data));
        dispatch(getGoal(params));
        if (viewDetails) {
          dispatch(getGoalDetails({ id: payload.goalId, empId: payload.empId }));
        }
      } else {
        EnhancedNotification.error({
          message: 'Error in deleting goal.',
        });
        dispatch(deleteGoalFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({ message: error.response.message || 'Error in deleting goal.' });
      dispatch(deleteGoalFailure(error));
    });
};

export const updateGoal = (request) => (dispatch) => {
  const { id, payload, parameters, viewDetails } = request;
  const url = goalsUrls.UPDATE_GOAL_AS_LM.replace('{empId}', parameters.empId).replace('{gId}', id);
  dispatch(updateGoalInit());
  axios
    .put(url, payload)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Updated Successfully',
        });
        dispatch(updateGoalSuccess(response.data));
        dispatch(getGoal(parameters));
        if (viewDetails) {
          dispatch(getGoalDetails({ id, empId: parameters.empId }));
        }
      } else {
        EnhancedNotification.error({
          message: 'Error in updating a new goal',
        });
        dispatch(updateGoallFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in updating a new goal.',
      });
      dispatch(updateGoallFailure());
    });
};

export const updateGoalStatus = (request) => (dispatch) => {
  const { payload, parameters, viewDetails } = request;
  const url = goalsUrls.APPROVE_GOAL;
  // .replace('{empId}', parameters.empId)
  // .replace('{gId}', payload.goalId)
  dispatch(updateGoalStatusInit());
  axios
    .put(url, payload)
    .then((response) => {
      if (response.status === 200) {
        EnhancedNotification.success({
          message: 'Updated Successfully',
        });
        dispatch(updateGoalStatusSuccess());
        dispatch(getGoal(parameters));
        if (viewDetails) {
          // on detail page, only one goal is approved one time
          dispatch(getGoalDetails({ id: payload.goalIds[0], empId: parameters.empId }));
        }
      } else {
        EnhancedNotification.error({
          message: 'Error in updating a goal',
        });
        dispatch(updateGoalStatusFailure());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({
        message: error.response.data.message || 'Error in updating a goal.',
      });
      dispatch(updateGoalStatusFailure());
    });
};

// Performance Review

export const getPerformanceReview = (request) => (dispatch) => {
  const url = performanceReviewUrls.GET_PERFORMANCE_REVIEWS_AS_LM.replace('{empId}', request.empId)
    .replace('{status}', request.status)
    .replace('{pageNumber}', request.pageNumber)
    .replace('{pageSize}', request.pageSize);
  dispatch(getPerformanceReviewInit());
  axios
    .get(url)
    .then((response) => {
      if (response.status === 200) {
        //   const resourceNormalized = normalizeResourceAvailability(response.data.data, date);
        dispatch(getPerformanceReviewSuccess(response.data.data));
      } else {
        dispatch(getPerformanceReviewFailure());
      }
    })
    .catch((error) => {
      dispatch(getPerformanceReviewFailure(error));
    });
};

export const getDropdownOptions = () => (dispatch) => {
  const { MARITAL_STATUSES, EMPLOYMENT_STATUSES, BLOOD_GROUPS, RELIGIONS, QUALIFICATIONS } =
    employeeDropDownUrls;
  const urls = [
    MARITAL_STATUSES.GET,
    EMPLOYMENT_STATUSES.GET,
    BLOOD_GROUPS.GET,
    RELIGIONS.GET,
    QUALIFICATIONS.GET,
  ];

  const fetchJson = (url) => axios.get(url).then((res) => res.data.data);

  dispatch(getMartialStatusesInit());
  dispatch(getReligionsInit());
  dispatch(getEmploymentStatusesInit());
  dispatch(getBloodGroupsInit());
  dispatch(getQualificationsInit());

  Promise.all(urls.map(fetchJson))
    .then(([maritalStatuses, employmentStatuses, bloodGroups, religions, qualifications]) => {
      dispatch(getMartialStatusesSuccess(normalizeGetMaritalStatusesResponse(maritalStatuses)));
      dispatch(getReligionsSuccess(normalizeGetReligionsResponse(religions)));
      dispatch(
        getEmploymentStatusesSuccess(normalizeGetEmploymentStatusesResponse(employmentStatuses)),
      );
      dispatch(getBloodGroupsSuccess(normalizeGetBloodGroupsResponse(bloodGroups)));
      dispatch(getQualificationsSuccess(normalizeGetQualificationsResponse(qualifications)));
    })
    .catch(() => {
      dispatch(getEmploymentStatusesFailure());
      dispatch(getBloodGroupsFailure());
      dispatch(getQualificationsFailure());
      dispatch(getReligionsFailure());
      dispatch(getMartialStatusesFailure());
    });
};

export const getEmployeeHistoryLogs = (employeeId) => (dispatch) => {
  const url = employeeProfileUrls.AUDIT_LOGS.GET.replace('{employeeId}', employeeId);
  dispatch(getAuditLogsInit());

  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        const normalizedData = normalizeGetEmployeeAuditLogsResponse(response.data.data);
        dispatch(getEmployeeAuditLogsSuccess(normalizedData));
      } else {
        dispatch(getEmployeeAuditLogsFailure());
      }
    })
    .catch((error) => {
      dispatch(getEmployeeAuditLogsFailure(error.response.data));
    });
};
