import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedModal,
  EnhancedSelect,
  EnhancedForm,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import { GOAL_STATUS } from 'configs/employeeProfileConstants';

const AddFilterModal = (props) => {
  const {
    isModalVisible,
    handleOk,
    handleCancel,
    params,
    setParams,
    isLoading,
    managers,
    departments,
    businessUnits,
    divisions,
    competencies,
  } = props;

  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedBusinessUnits, setSelectedBusinessUnits] = useState([]);
  const [selectedDivisions, setSelectedDivisions] = useState([]);
  const [selectedCompetencies, setSelectedCompetencies] = useState([]);
  const [selectedLineManagers, setSelectedLineManagers] = useState([]);

  const FilterSubmit = () => {
    setParams({
      ...params,
      status: selectedStatuses.join(','),
      departmentIds: selectedDepartments.join(','),
      businessUnitIds: selectedBusinessUnits.join(','),
      divisionIds: selectedDivisions.join(','),
      competencyIds: selectedCompetencies.join(','),
      managerIds: selectedLineManagers.join(','),
    });
    handleOk();
  };

  useEffect(() => {
    //
  }, [isLoading]);

  const closeModalHandler = () => {
    handleCancel();
  };

  const searchDropdown = (input, option) =>
    option.props.children.toLowerCase().includes(input.toLowerCase());

  return (
    <EnhancedModal
      title="Add Filter"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={closeModalHandler}
      className="add-goals-modal"
      width="1041px"
      footer={[
        <EnhancedButton key="submit" type="primary" onClick={FilterSubmit}>
          {'Apply'}
        </EnhancedButton>,
      ]}
    >
      <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
        loading={isLoading}
        bordered={false}
        className="details-card-wrapper"
        style={{
          marginTop: 10,
        }}
      >
        <EnhancedRow>
          <EnhancedForm layout="inline" className="goals-form w-full">
            {/* <EnhancedCol span={24} className="form-col"> */}
            <EnhancedCol lg={4}>
              <label htmlFor="department"> Department </label>
            </EnhancedCol>
            <EnhancedCol lg={7}>
              <EnhancedSelect
                mode="multiple"
                placeholder="Departments"
                style={{ width: '100%' }}
                onChange={(value) => setSelectedDepartments(value)}
                value={selectedDepartments}
                tokenSeparators={[',']}
                filterOption={(input, option) => searchDropdown(input, option)}
                showSearch
                optionFilterProp="children"
                allowClear
              >
                {departments.map((department) => (
                  <EnhancedSelect.Option value={department.id} key={department.id}>
                    {department.name}
                  </EnhancedSelect.Option>
                ))}
              </EnhancedSelect>
            </EnhancedCol>
            <EnhancedCol lg={2} />
            <EnhancedCol lg={4}>
              <label htmlFor="division"> Division </label>
            </EnhancedCol>
            <EnhancedCol lg={7}>
              <EnhancedSelect
                mode="multiple"
                placeholder="Division"
                style={{ width: '100%' }}
                onChange={(value) => setSelectedDivisions(value)}
                value={selectedDivisions}
                tokenSeparators={[',']}
                filterOption={(input, option) => searchDropdown(input, option)}
                showSearch
                optionFilterProp="children"
                allowClear
              >
                {divisions.map((division) => (
                  <EnhancedSelect.Option value={division.id} key={division.id}>
                    {division.name}
                  </EnhancedSelect.Option>
                ))}
              </EnhancedSelect>
            </EnhancedCol>
            <EnhancedCol lg={4} style={{ marginTop: 20 }}>
              <label htmlFor="competency"> Competency </label>
            </EnhancedCol>
            <EnhancedCol lg={7} style={{ marginTop: 20 }}>
              <EnhancedSelect
                mode="multiple"
                placeholder="Competency"
                style={{ width: '100%' }}
                onChange={(value) => setSelectedCompetencies(value)}
                value={selectedCompetencies}
                tokenSeparators={[',']}
                filterOption={(input, option) => searchDropdown(input, option)}
                showSearch
                optionFilterProp="children"
                allowClear
              >
                {competencies.map((competency) => (
                  <EnhancedSelect.Option value={competency.id} key={competency.id}>
                    {competency.name}
                  </EnhancedSelect.Option>
                ))}
              </EnhancedSelect>
            </EnhancedCol>
            <EnhancedCol lg={2} />
            <EnhancedCol lg={4} style={{ marginTop: 20 }}>
              <label htmlFor="businessUnit"> Business Unit </label>
            </EnhancedCol>
            <EnhancedCol lg={7} style={{ marginTop: 20 }}>
              <EnhancedSelect
                mode="multiple"
                placeholder="Business Unit"
                style={{ width: '100%' }}
                onChange={(value) => setSelectedBusinessUnits(value)}
                value={selectedBusinessUnits}
                tokenSeparators={[',']}
                filterOption={(input, option) => searchDropdown(input, option)}
                showSearch
                optionFilterProp="children"
                allowClear
              >
                {businessUnits.map((businessUnit) => (
                  <EnhancedSelect.Option value={businessUnit.id} key={businessUnit.id}>
                    {businessUnit.name}
                  </EnhancedSelect.Option>
                ))}
              </EnhancedSelect>
            </EnhancedCol>
            <EnhancedCol lg={4} style={{ marginTop: 20 }}>
              <label htmlFor="status"> Status </label>
            </EnhancedCol>
            <EnhancedCol lg={7} style={{ marginTop: 20 }}>
              <EnhancedSelect
                mode="multiple"
                placeholder="Status"
                style={{ width: '100%' }}
                onChange={(value) => setSelectedStatuses(value)}
                value={selectedStatuses}
                tokenSeparators={[',']}
                filterOption={(input, option) => searchDropdown(input, option)}
                showSearch
                optionFilterProp="children"
                allowClear
              >
                {Object.entries(GOAL_STATUS).map(([key, value]) => (
                  <EnhancedSelect.Option value={value} key={key}>
                    {value}
                  </EnhancedSelect.Option>
                ))}
              </EnhancedSelect>
            </EnhancedCol>
            <EnhancedCol lg={2} />
            <EnhancedCol lg={4} style={{ marginTop: 20 }}>
              <label htmlFor="lineManager"> Line Manager </label>
            </EnhancedCol>
            <EnhancedCol lg={7} style={{ marginTop: 20 }}>
              <EnhancedSelect
                mode="multiple"
                placeholder="Line Manager"
                style={{ width: '100%' }}
                onChange={(value) => setSelectedLineManagers(value)}
                value={selectedLineManagers}
                tokenSeparators={[',']}
                filterOption={(input, option) => searchDropdown(input, option)}
                showSearch
                optionFilterProp="children"
                allowClear
              >
                {managers.map((manager) => (
                  <EnhancedSelect.Option value={manager.id} key={manager.id}>
                    {manager.name}
                  </EnhancedSelect.Option>
                ))}
              </EnhancedSelect>
            </EnhancedCol>
            {/* </EnhancedCol> */}
          </EnhancedForm>
        </EnhancedRow>
      </EnhancedCard>
    </EnhancedModal>
  );
};

export default AddFilterModal;
