export const GET_EMPLOYEES_RATING_LIST_INIT = 'GET_EMPLOYEES_RATING_LIST_INIT';
export const GET_EMPLOYEES_RATING_LIST_SUCCESS = 'GET_EMPLOYEES_RATING_LIST_SUCCESS';
export const GET_EMPLOYEES_RATING_LIST_FAILURE = 'GET_EMPLOYEES_RATING_LIST_FAILURE';

export const GET_EMPLOYEE_RATING_INIT = 'GET_EMPLOYEE_RATING_INIT';
export const GET_EMPLOYEE_RATING_SUCCESS = 'GET_EMPLOYEE_RATING_SUCCESS';
export const GET_EMPLOYEE_RATING_FAILURE = 'GET_EMPLOYEE_RATING_FAILURE';

export const GET_EMPLOYEES_RATING_SUMMARY_INIT = 'GET_EMPLOYEES_RATING_SUMMARY_INIT';
export const GET_EMPLOYEES_RATING_SUMMARY_SUCCESS = 'GET_EMPLOYEES_RATING_SUMMARY_SUCCESS';
export const GET_EMPLOYEES_RATING_SUMMARY_FAILURE = 'GET_EMPLOYEES_RATING_SUMMARY_FAILURE';
