import {
  GET_RCA_PROJECT_EMPLOYEES_INIT,
  GET_RCA_PROJECT_EMPLOYEES_SUCCESS,
  GET_RCA_PROJECT_EMPLOYEES_FAILURE,
} from 'constants/RcaProjectEmployeesTypes';

import { getAllocationGroupedProjectResources } from 'sources/EmployeeSource';

// normal actions
export const getRcaProjectEmployeesInit = () => ({
  type: GET_RCA_PROJECT_EMPLOYEES_INIT,
  loader: true,
});

export const getRcaProjectEmployeesSuccess = (data) => ({
  type: GET_RCA_PROJECT_EMPLOYEES_SUCCESS,
  data,
});

export const getRcaProjectEmployeesFailure = (data) => ({
  type: GET_RCA_PROJECT_EMPLOYEES_FAILURE,
  data,
});
export const getRcaProjectEmployeesActions = (userId, projectId, startDate, endDate) =>
  getAllocationGroupedProjectResources(userId, projectId, startDate, endDate);
