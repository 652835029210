import { connect } from 'react-redux';

import { getEmployeesSkillsActions } from 'store/actions/EmployeeActions';

import updateBreadCrumb from 'store/actions/BreadCrumbActions';

import {
  getDealDetailAction,
  getDealRequirmentsAction,
  addDealRequirementsAction,
  deleteDealRequirementsAction,
  updateDealRequirementsAction,
  suggestResourceAction,
  getDealRequirementsSuggestionsAction,
  getDealRequirementHistoryAction,
  updateDealRequirementSuggestionAction,
  getDealResourceStatusAction,
  getDealRequirementSuggestionOtherDealsAction,
  getDealRequirementMatchingResourcesAction,
} from '../store/slices/Deal';
import DealDetails from '../components/deal/dealDetails';

function mapStateToProps(state) {
  const { auth, dealReducer, employeeReducer } = state;
  const { skills } = employeeReducer;
  const {
    dealDetails,
    dealRequirements,
    dealRequirementsSuggestions,
    dealRequirementHistory,
    dealResourceStatus,
    dealRequirementSuggestionOtherDeals,
    dealRequirementMatchingResources,
  } = dealReducer;
  return {
    userRole: auth.user,
    dealDetail: dealDetails,
    dealRequirements,
    skills,
    dealRequirementsSuggestions,
    dealRequirementHistory,
    dealResourceStatus,
    dealRequirementSuggestionOtherDeals,
    dealRequirementMatchingResources,
    userPermissions: auth.user && auth.user.permissions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDealDetail: (id) => dispatch(getDealDetailAction(id)),
    getDealRequirements: (req, id) => dispatch(getDealRequirmentsAction(req, id)),
    addDealRequirements: (payload, dealId, params) =>
      dispatch(addDealRequirementsAction(payload, dealId, params)),
    deleteDealRequirements: (dealRequirementId, dealId, params) =>
      dispatch(deleteDealRequirementsAction(dealRequirementId, dealId, params)),
    updateDealRequirements: (payload, dealRequirementId, dealId, params) =>
      dispatch(updateDealRequirementsAction(payload, dealRequirementId, dealId, params)),
    suggestResource: (payload, dealRequirementId, dealId, dealRequirementParams) =>
      dispatch(suggestResourceAction(payload, dealRequirementId, dealId, dealRequirementParams)),
    getDealRequirementsSuggestions: (dealRequirementId, params) => {
      return dispatch(getDealRequirementsSuggestionsAction(dealRequirementId, params));
    },
    getEmployeeSkills: () => dispatch(getEmployeesSkillsActions()),
    updateBreadCrumb: (key, oldVal, newVal) => dispatch(updateBreadCrumb(key, oldVal, newVal)),
    updateDealRequirementSuggestion: (
      suggestionId,
      payload,
      refetchSuggestionsConfig,
      refetchRequirementsConfig,
    ) =>
      dispatch(
        updateDealRequirementSuggestionAction(
          suggestionId,
          payload,
          refetchSuggestionsConfig,
          refetchRequirementsConfig,
        ),
      ),
    getDealResourceStatus: () => dispatch(getDealResourceStatusAction()),
    getDealRequirementSuggestionOtherDeals: (suggestionId, params) => {
      return dispatch(getDealRequirementSuggestionOtherDealsAction(suggestionId, params));
    },
    getDealRequirementMatchingResources: (dealRequirementId) => {
      return dispatch(getDealRequirementMatchingResourcesAction(dealRequirementId));
    },
    getDealRequirementHistory: (dealRequirementId) => {
      return dispatch(getDealRequirementHistoryAction(dealRequirementId));
    },
  };
}

const DealContainer = connect(mapStateToProps, mapDispatchToProps)(DealDetails);

export default DealContainer;
