import { connect } from 'react-redux';

import ProjectEmployees from 'components/project/ProjectEmployees';

import {
  getProjectDetailsActions,
  addNewResourceAction,
} from 'store/actions/ProjectEmployeesActions';
import { getProjectsAction, editProjectDetailsAction } from 'store/actions/ProjectActions';
import {
  deallocateEmployeeAction,
  updateEmployeesProjectActions,
  getEmployeeDepartmentsAction,
  getEmployeeCompetenciesAction,
  getEmployeeBusinessUnitAction,
} from 'store/actions/EmployeeActions';
import updateBreadCrumb from 'store/actions/BreadCrumbActions';
import { getProjectActivityLogsAction } from 'store/actions/ProjectActivityLogActions';
import { getProjectRolesAction } from 'store/actions/ProjectRolesActions';
import { getAllLocationActions } from 'store/actions/LocationActions';

const mapStateToProps = (state, ownProps) => {
  const {
    auth,
    projectEmployeesReducer,
    projectReducer,
    employeeReducer,
    projectActivityLogReducer,
    publicHolidaysReducer,
    projectRolesReducer,
    locationsReducer,
  } = state;
  const { projects, shouldFetchProjects } = projectReducer;
  const { projectRoles } = projectRolesReducer;
  const { match } = ownProps;
  const project = projects.filter((proj) => proj.id === parseInt(match.params.id, 10))[0] || {};

  return {
    isLoading: projectEmployeesReducer.isLoading,
    user: auth.user,
    userId: auth.user && auth.user.id,
    readOnly: auth.user && auth.user.isReadOnly,
    overAllocation: projectEmployeesReducer.overAllocation,
    employees: projectEmployeesReducer.projectDetails || [],
    locations: locationsReducer.locations,
    project,
    projectRoles,
    allEmployees: employeeReducer.employees,
    isLoadingEmployees: employeeReducer.isLoading,
    shouldFetchProjects,
    isProjectLoading: projectReducer.isLoading,
    roles: employeeReducer.roles,
    activityLogs: projectActivityLogReducer.activityLogs,
    isLoadingLogs: projectActivityLogReducer.isLoading,
    workingHolidays: publicHolidaysReducer.publicHolidays.workingHolidays,
    departments: employeeReducer.departments,
    businessUnits: employeeReducer.businessUnits,
    competencies: employeeReducer.competencies,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProjectDetailsActions: (projectId) => dispatch(getProjectDetailsActions(projectId)),

  addNewResourceProject: (req, roles, employeeName, department, competency, location, taleoId) =>
    dispatch(
      addNewResourceAction(req, roles, employeeName, department, competency, location, taleoId),
    ),

  getProjectsAction: () => dispatch(getProjectsAction()),

  deallocateEmployee: (
    employeeProjectId,
    employeeId,
    projectId = null,
    comment = null,
    endDate = null,
    deleteEntry = false,
  ) =>
    dispatch(
      deallocateEmployeeAction(
        employeeProjectId,
        employeeId,
        projectId,
        comment,
        endDate,
        deleteEntry,
      ),
    ),

  updateEmployeesProjectActions: (employeeProjectInfo, currentInformation) =>
    dispatch(updateEmployeesProjectActions(employeeProjectInfo, currentInformation)),

  editProjectDetails: (projectId, projectDetails, employeeId) =>
    dispatch(editProjectDetailsAction(projectId, projectDetails, employeeId)),

  getAllLocations: () => dispatch(getAllLocationActions()),

  getProjectActivityLogs: (projectId, pageNumber) =>
    dispatch(getProjectActivityLogsAction(projectId, pageNumber)),

  getProjectRoles: () => dispatch(getProjectRolesAction()),

  updateBreadCrumb: (key, oldVal, newVal) => dispatch(updateBreadCrumb(key, oldVal, newVal)),
  getBusinessUnits: () => dispatch(getEmployeeBusinessUnitAction()),

  getEmployeeDepartments: () => dispatch(getEmployeeDepartmentsAction()),
  getEmployeeCompetencies: () => dispatch(getEmployeeCompetenciesAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEmployees);
