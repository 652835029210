import {
  EnhancedCol,
  EnhancedButton,
  EnhancedIcon,
  EnhancedCard,
  EnhancedRow,
  EnhancedDivider,
  EnhancedLayout,
  EnhancedModal,
  EnhancedInput,
  EnhancedCheckbox,
} from 'components/shared/antd';
import React, { Component, Fragment } from 'react';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';

const { confirm } = EnhancedModal;
const { Header } = EnhancedLayout;

class ProjectRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editVisible: false,
      addVisible: false,
      form: this.getInitialForm(),
      projectRoles: this.props.projectRoles || [],
    };
  }

  componentDidMount() {
    this.props.getProjectRoles();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.projectRoles !== this.props.projectRoles) {
      this.setState({ projectRoles: nextProps.projectRoles });
    }
  }

  getColumnsInfo = () => {
    const columns = [
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        width: '10%',
        filterConfig: {
          type: 'search',
          key: 'role',
        },
      },
      {
        title: 'Leadership',
        dataIndex: 'isLeadership',
        key: 'isLeadership',
        width: '10%',
        filterConfig: {
          type: 'enhanced_filter',
          optionsConfig: {
            values: [
              {
                text: 'Yes',
                value: 'yes',
              },
              {
                text: 'No',
                value: 'no',
              },
            ],
            valueVar: 'value',
            labelVar: 'text',
          },
          customFunc: this.filterLeadership,
        },
        render: this.renderLeadership,
      },
      {
        title: 'Action',
        key: 'action',
        width: '5%',
        render: (text, record) => (
          <span>
            <EnhancedIcon
              type="edit"
              style={{ cursor: 'pointer' }}
              onClick={() => this.showEditModal(record)}
            />
            <EnhancedDivider type="vertical" />
            <EnhancedIcon
              type="delete"
              style={{ cursor: 'pointer' }}
              onClick={() => this.showDeleteModal(record)}
            />
          </span>
        ),
      },
    ];
    return columns;
  };

  getInitialForm = () => ({
    role: '',
    isLeadership: false,
  });

  filterLeadership = (item, filterValue) => {
    if (filterValue.includes('no') && filterValue.length === 1) {
      return !item.isLeadership;
    } else if (filterValue.includes('yes') && filterValue.length === 1) {
      return item.isLeadership;
    }
    return true;
  };

  showEditModal = (record) => {
    const form = {
      id: record.id,
      role: record.role,
      isLeadership: record.isLeadership,
    };
    this.setState({
      editVisible: true,
      form,
    });
  };

  hideModal = () => {
    this.setState({
      editVisible: false,
      addVisible: false,
      form: this.getInitialForm(),
    });
  };

  handleNameChange = (event) => {
    const { value } = event.target;
    const form = {
      ...this.state.form,
      role: value,
    };
    this.setState({ form });
  };

  handleLeadershipChange = (event) => {
    const { checked } = event.target;
    const form = {
      ...this.state.form,
      isLeadership: checked,
    };
    this.setState({ form });
  };

  handleAdd = () => {
    const { form } = this.state;
    const { addProjectRole } = this.props;
    addProjectRole(form);
    this.setState({
      form: this.getInitialForm(),
      addVisible: false,
    });
  };

  handleEdit = () => {
    const { form } = this.state;
    const { editProjectRole } = this.props;
    editProjectRole(form);
    this.setState({
      form: this.getInitialForm(),
      editVisible: false,
    });
  };

  handleDelete = (record) => {
    const { deleteProjectRole } = this.props;
    deleteProjectRole(record.id);
  };

  isFormValid = () => this.state.form.role.length > 0;

  editProjectRole = () => {
    const { editVisible, addVisible, form } = this.state;
    return (
      <EnhancedModal
        title={editVisible ? 'Edit Project Role' : 'Add Project Role'}
        visible={editVisible || addVisible}
        onCancel={this.hideModal}
        footer={[
          <EnhancedButton key="back" onClick={this.hideModal}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            key="submit"
            type="primary"
            onClick={editVisible ? this.handleEdit : this.handleAdd}
            disabled={!this.isFormValid()}
          >
            Save
          </EnhancedButton>,
        ]}
      >
        <EnhancedRow className="edit-skill">
          <EnhancedCol lg={24}>
            <EnhancedInput
              name="role"
              value={form.role}
              onChange={this.handleNameChange}
              placeholder="Enter Project Role"
            />
          </EnhancedCol>
          <EnhancedCol lg={24}>
            <EnhancedCheckbox
              style={{ marginTop: '8px' }}
              name="isLeadership"
              checked={form.isLeadership}
              onChange={this.handleLeadershipChange}
            >
              Project Leadership
            </EnhancedCheckbox>
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedModal>
    );
  };

  showAddModal = () => {
    this.setState({ addVisible: true });
  };

  showDeleteModal = (record) => {
    const { handleDelete } = this;
    confirm({
      title: 'Are you sure you want to delete this project role?',
      content: 'Note: Project roles associated with employees cannot be deleted',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(record);
      },
    });
  };

  renderLeadership = (name, data) => {
    const { isLeadership } = data;
    return (
      <div style={{ color: '#5A5A5A', wordBreak: 'normal' }}>{isLeadership ? 'Yes' : 'No'}</div>
    );
  };

  render() {
    const columns = this.getColumnsInfo();
    const { projectRoles } = this.state;
    const { isLoading } = this.props;
    return (
      <Fragment>
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol lg={12}>
              <h1 className="heading-pro">Project Roles</h1>
            </EnhancedCol>
            <EnhancedCol span={6} offset={6}>
              <EnhancedButton
                type="primary"
                onClick={this.showAddModal}
                style={{ float: 'right', marginTop: '5%' }}
              >
                {' '}
                Add New Role
              </EnhancedButton>
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        {this.editProjectRole()}
        <div style={{ background: 'white' }}>
          <EnhancedCard loading={isLoading} bordered={false}>
            <EnhancedRow />
          </EnhancedCard>
          <div>
            <EnhancedClientSideTable
              style={{ marginLeft: '25px' }}
              loading={isLoading}
              columns={columns}
              data={projectRoles}
              exportFileConfig={{
                fileName: 'Project Roles',
                fileHeaders: [
                  {
                    label: 'Project Role',
                    key: 'role',
                  },
                  {
                    label: 'Is Leadership?',
                    key: 'isLeadership',
                    renderer: (value) => (value ? 'Yes' : 'No'),
                  },
                ],
                showExportButton: true,
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ProjectRoles;
