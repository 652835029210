import { EnhancedCol, EnhancedRow, EnhancedCard, EnhancedDivider } from 'components/shared/antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';
import { parse } from 'utils/stringUtils';
import { NOT_AVAILABLE } from 'configs/additionalConstants';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import convertMonthRangeToDateRange from '../../utils/DateUtils';
import { BREADCRUMBS, dateFormat } from '../../configs/constants';
import PROJECT_ROUTES from '../../constants/UrlConstants';

class ResourceDetail extends Component {
  constructor(props) {
    super(props);
    const queryParams = this.props.location.search.split('=');

    const startDate = queryParams[1].split('&')[0];
    const endDate = queryParams[2];
    const dateRange = convertMonthRangeToDateRange(moment(startDate), moment(endDate));
    this.state = {
      sortedInfo: null,
      searchText: '',
      resources: props.resourceData.ResourceDetails || [],
      resourceSummary: props.resourceData.resourceSummary || {},
      filteredInfo: {},
      startDate,
      endDate,
      dateRange,
    };
  }

  componentWillMount() {
    if (this.props.userId !== undefined) {
      const { employeeId } = this.props.match.params;
      const startDate = moment(this.state.startDate);
      const endDate = moment(this.state.endDate);
      const dateRange = convertMonthRangeToDateRange(startDate, endDate);

      this.props.getResourceDetailActions(
        this.props.userId,
        employeeId,
        dateRange.startDate,
        dateRange.endDate,
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    let { resourceData } = this.props;
    const { match, updateBreadCrumb } = this.props;
    resourceData = resourceData !== nextProps.resourceData ? nextProps.resourceData : resourceData;

    const { resourceSummary } = nextProps.resourceData;
    if (resourceSummary && resourceSummary.employeeName) {
      updateBreadCrumb(
        BREADCRUMBS.EMPLOYEE,
        resourceSummary.employeeId,
        resourceSummary.employeeName,
      );
    }

    if (resourceData === undefined || resourceData.resourceDetails === undefined) {
      resourceData.resourceDetails = [];
      resourceData.resourceSummary = {};
    }
    this.setState({
      resources: resourceData.resourceDetails,
      resourceSummary: resourceData.resourceSummary,
      searchText: match.params.status === nextProps.status ? this.state.searchText : '',
    });
  }

  getColumnsInfo = () => {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Project Name',
        dataIndex: 'projectName',
        key: 'projectName',
        width: '20%',
        sorter: (a, b) => a.projectName.localeCompare(b.projectName),
        sortOrder: sortedInfo.columnKey === 'projectName' && sortedInfo.order,
        render: this.renderProjectName,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: '20%',
        sorter: (a, b) => a.type.localeCompare(b.type),
        sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order,
      },
      {
        title: 'Allocation',
        dataIndex: 'allocation',
        key: 'additional',
        width: '20%',
        sorter: (a, b) => a.allocation.localeCompare(b.allocation),
        sortOrder: sortedInfo.columnKey === 'allocation' && sortedInfo.order,
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        width: '20%',
        sorter: (a, b) => a.startDate.localeCompare(b.startDate),
        sortOrder: sortedInfo.columnKey === 'startDate' && sortedInfo.order,
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
        key: 'endDate',
        width: '20%',
        render: this.formateDate,
        sorter: (a, b) => a.endDate.localeCompare(b.endDate),
        sortOrder: sortedInfo.columnKey === 'endDate' && sortedInfo.order,
      },
    ];
    return columns;
  };

  getFileHeaders = () => {
    const headers = [
      {
        label: 'Type',
        key: 'type',
      },
      {
        label: 'End Date',
        key: 'endDate',
        renderer: (value) =>
          value ? moment(value).format(dateFormat.DDMMMYYYYwithSpace) : NOT_AVAILABLE,
      },
      {
        label: 'Start Date',
        key: 'startDate',
      },
      {
        label: 'Allocation',
        key: 'allocation',
      },
      {
        label: 'Competency',
        key: 'competency',
      },
      {
        label: 'Designation',
        key: 'designation',
      },
      {
        label: 'Project Name',
        key: 'projectName',
      },
    ];

    return headers;
  };

  formateDate = (date) =>
    !date ? NOT_AVAILABLE : moment(date).format(dateFormat.DDMMMYYYYwithSpace);

  dateRangeChange = (date, dateString) => {
    const startDate = dateString;
    const endDate = dateString;
    this.setState({
      startDate,
      endDate,
    });
  };

  applyFilter = () => {
    const { employeeId } = this.props.match.params;
    if (this.props.userId !== undefined) {
      const startDate = moment(this.state.startDate);
      const endDate = moment(this.state.endDate);
      const dateRange = convertMonthRangeToDateRange(startDate, endDate);
      this.props.getResourceDetailActions(
        this.props.userId,
        employeeId,
        dateRange.startDate,
        dateRange.endDate,
      );
    }
  };

  handleRemoveFilters = () => {
    this.setState({
      searchText: '',
      filteredInfo: {},
    });
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  renderProjectName = (name, data) => {
    const link = parse(PROJECT_ROUTES.PROJECTS_DETAIL_ROUTE, {
      status: PROJECT_ROUTES.CURRENT,
      id: data.projectId,
    });
    return (
      <Link to={link}>
        <b style={{ color: '#5A5A5A' }}>{name}</b>
      </Link>
    );
  };

  render() {
    const { isLoading } = this.state;
    let { resources } = this.state;
    const columns = this.getColumnsInfo();
    const { resourceSummary } = this.state;
    if (this.state.filteredInfo.projectName && this.state.filteredInfo.projectName.length) {
      resources = resources.filter((record) =>
        this.state.filteredInfo.projectName.includes(record.projectName),
      );
    }

    const style = {
      fontSize: 10,
      textTransform: 'capitalize',
    };

    return (
      <div>
        <EnhancedCard loading={this.props.isProjectLoading} bordered={false}>
          <EnhancedRow>
            <EnhancedCol lg={10}>
              <EnhancedCol lg={20}>
                <h2 style={{ color: 'black', fontSize: '10' }}>{resourceSummary.employeeName}</h2>
                <p style={{ color: '#80808096', fontSize: '10' }}>{resourceSummary.designation}</p>
                <p>
                  {' '}
                  {moment(this.state.dateRange.startDate).format('DD MMM YYYY')} -{' '}
                  {moment(this.state.dateRange.endDate).format('DD MMM YYYY')}
                </p>
              </EnhancedCol>
            </EnhancedCol>
            <EnhancedCol lg={14}>
              <EnhancedCol lg={21}>
                <EnhancedCol lg={6} />
                <EnhancedCol lg={9}>
                  <div style={{ display: 'flex', marginTop: '5px', flexDirection: 'row' }}>
                    <EnhancedDivider
                      type="vertical"
                      style={{ fontSize: '60px', marginTop: '10px' }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <h2 style={{ fontSize: '15px' }}>Total Utilization</h2>
                      <b style={{ fontSize: '20px' }}>{resourceSummary.totalAllocation}</b>
                    </div>
                    <div
                      style={{
                        marginLeft: '30px',
                        marginTop: '2px',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <h2 style={style}>Billed: {resourceSummary.billed}</h2>
                      <h2 style={style}>Backup: {resourceSummary.backup}</h2>
                      <h2 style={style}>Internal: {resourceSummary.internal}</h2>
                      <h2 style={style}>
                        Bench:{resourceSummary.bench}{' '}
                        {resourceSummary.additional > 0 &&
                          `(Additional: ${resourceSummary.additional})`}
                      </h2>
                    </div>
                  </div>
                </EnhancedCol>
              </EnhancedCol>
            </EnhancedCol>
          </EnhancedRow>
        </EnhancedCard>
        <EnhancedCard>
          <EnhancedCol span={24}>
            <EnhancedClientSideTable
              showResetFiltersButton={false}
              loading={isLoading}
              columns={columns}
              data={resources}
              onChange={this.handleChange}
              size="middle"
              exportFileConfig={{
                fileName: resourceSummary.employeeName,
                fileHeaders: this.getFileHeaders(),
                showExportButton: true,
              }}
            />
          </EnhancedCol>
        </EnhancedCard>
      </div>
    );
  }
}

export default ResourceDetail;
