import { connect } from 'react-redux';
import ProjectRoles from 'components/projectRoles/ProjectRoles';
import {
  getProjectRolesAction,
  addProjectRoleAction,
  editProjectRoleAction,
  deleteProjectRoleAction,
} from '../store/actions/ProjectRolesActions';

function mapStateToProps(state) {
  const { projectRolesReducer, auth } = state;
  const { projectRoles, isLoading } = projectRolesReducer;
  return {
    projectRoles,
    isLoading,
    userId: auth.user && auth.user.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectRoles: () => dispatch(getProjectRolesAction()),
    addProjectRole: (request) => dispatch(addProjectRoleAction(request)),
    editProjectRole: (request) => dispatch(editProjectRoleAction(request)),
    deleteProjectRole: (request) => dispatch(deleteProjectRoleAction(request)),
  };
}

const ProjectRolesContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectRoles);

export default ProjectRolesContainer;
