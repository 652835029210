import {
  GET_LATEST_HOME_NEWS,
  APPEND_NEWS_GRID_DATA,
  GET_NEWS_GRID_DATA,
  GET_SINGLE_NEWS_DATA,
  GET_RELATED_NEWS_DATA,
  GET_API_NEWS_FAILURE,
} from 'constants/NewsTypes';
import { getAllNewsSource, getSingleNewsSource } from 'sources/NewsSource';

export const getLatestHomeNewsAction = (data) => ({
  type: GET_LATEST_HOME_NEWS,
  data,
});

export const getGridNewsAction = (data) => ({
  type: GET_NEWS_GRID_DATA,
  data,
});

export const appendNewsGridData = (data) => ({
  type: APPEND_NEWS_GRID_DATA,
  data,
});

export const getSingleNewsAction = (data) => ({
  type: GET_SINGLE_NEWS_DATA,
  data,
});

export const getRelatedNewsAction = (data) => ({
  type: GET_RELATED_NEWS_DATA,
  data,
});

export const getAPIFAILURE = (data) => ({
  type: GET_API_NEWS_FAILURE,
  data,
});

export const getNewsAction =
  (count = null, page = null) =>
  (dispatch) => {
    dispatch(getAllNewsSource(count, page));
  };

export const getSingleNewsActionSource = (id) => (dispatch) => {
  dispatch(getSingleNewsSource(id));
};
