import {
  EnhancedCol,
  EnhancedRow,
  EnhancedButton,
  EnhancedIcon,
  EnhancedCard,
  EnhancedAvatar,
  EnhancedTag,
  EnhancedSelect,
  EnhancedDatePicker,
  EnhancedInput,
  EnhancedInputNumber,
  EnhancedRadio,
  EnhancedCheckbox,
  EnhancedSlider,
  EnhancedRate,
} from 'components/shared/antd';
import React from 'react';
import moment from 'moment';
import { getInitials } from 'utils/stringUtils';
import { hasPermission } from 'utils/AccessUtils';
import { getValueForKeyinJSON } from 'utils/arrayUtils';
import { fieldValidator, createErrorMessage } from 'utils/validationUtils';

import {
  ROW_GUTTER_SIZE,
  FULL_GRID_SIZE,
  RELATIONSHIP_LABEL_SIZE,
  INPUT,
  NUMBER,
  TEXTAREA,
  PASSWORD,
  RADIO,
  TEXT,
  DATE,
  MONTH,
  SELECT,
  SELECT_WITH_UPLOAD,
  CHECKBOX,
  SKILL_BADGE,
  COMMENT,
  DATE_FORMAT,
  MONTH_FORMAT,
  AVATAR_COLORS,
  URL,
  ACTIONS,
  RELATIONS,
  SLIDER,
  RATING,
} from 'configs/employeeProfileConstants';

import { REQUIRED } from 'configs/validationConstants';
import { GENDERS } from 'configs/constants';

import RatingText from 'components/shared/performanceEvaluationForm/ratingText';

import 'components/employeeProfile/styles.css';

import {
  disabledDateDOB,
  disabledFutureMonths,
  disabledPastDatesByGivenDate,
} from './employeeUtils';

const { Option } = EnhancedSelect;
const { MonthPicker } = EnhancedDatePicker;

const handleInputChange = (newValue, field, setStateCallback) => {
  const { key, validations } = field;
  const error = fieldValidator(newValue, validations);
  setStateCallback(newValue, key, error);
};

const handleDateChange = (_, dateString, field, setStateCallback) => {
  const value = dateString || '';
  const { key, validations } = field;
  const error = fieldValidator(dateString, validations);
  setStateCallback(value, key, error);
};

const handlers = {
  [NUMBER]: handleInputChange,
  [INPUT]: handleInputChange,
  [DATE]: handleDateChange,
  [MONTH]: handleInputChange,
  [SELECT]: handleInputChange,
  [SELECT_WITH_UPLOAD]: handleInputChange,
  [CHECKBOX]: handleInputChange,
  [COMMENT]: handleInputChange,
  [TEXTAREA]: handleInputChange,
  [PASSWORD]: handleInputChange,
  [RADIO]: handleInputChange,
  [SKILL_BADGE]: handleInputChange,
  [RATING]: handleInputChange,
};

export const createInput = (field, isEdit, setStateCallback, handlerFunctions = {}) => {
  const { type, isAllowClear = false } = field;
  const inputField = (
    <EnhancedInput
      id={field.key}
      value={isEdit ? field.value : ''}
      disabled={!isEdit || field.disabled}
      className={`${!isEdit ? 'field-disabled' : ''}`}
      onChange={
        setStateCallback && handlers[field.type]
          ? (e) => {
              handlers[field.type](e.target.value, field, setStateCallback);
            }
          : null
      }
    />
  );

  switch (type) {
    case TEXT:
      return (
        <div className={`field-padding ${field.className ? field.className : ''}`}>
          {field.value}
        </div>
      );
    case NUMBER:
      return isEdit ? (
        <EnhancedInputNumber
          id={field.key}
          min={field.min}
          value={isEdit ? field.value : null}
          onChange={
            setStateCallback && handlers[field.type]
              ? (value) => {
                  handlers[field.type](value, field, setStateCallback);
                }
              : null
          }
        />
      ) : (
        <div className="field-padding">{field.value}</div>
      );
    case PASSWORD:
      return isEdit ? (
        <EnhancedInput.Password
          id={field.key}
          value={isEdit ? field.value : ''}
          disabled={!isEdit || field.disabled}
          className={`${!isEdit ? 'field-disabled' : ''}`}
          onChange={
            setStateCallback && handlers[field.type]
              ? (e) => {
                  handlers[field.type](e.target.value, field, setStateCallback);
                }
              : null
          }
        />
      ) : (
        <div
          className={`field-padding field-invisible-icon ${field.className ? field.className : ''}`}
        >
          <EnhancedInput.Password value={field.value} />
        </div>
      );
    case TEXTAREA:
      return isEdit ? (
        <EnhancedInput.TextArea
          autoSize={{ minRows: 3, maxRows: 6 }}
          id={field.key}
          value={isEdit ? field.value : ''}
          disabled={!isEdit}
          onChange={
            setStateCallback && handlers[field.type]
              ? (e) => {
                  handlers[field.type](e.target.value, field, setStateCallback);
                }
              : null
          }
        />
      ) : (
        <div className={`field-padding ${field.className ? field.className : ''}`}>
          {field.value}
        </div>
      );

    case RADIO:
      return isEdit ? (
        <React.Fragment>
          <EnhancedRadio.Group
            onChange={
              setStateCallback && handlers[field.type]
                ? (e) => {
                    handlers[field.type](e.target.value, field, setStateCallback);
                  }
                : null
            }
            id={field.key}
            disabled={!isEdit}
            value={field.value}
          >
            {field.options && field.options.length
              ? field.options.map(({ key, value }) => (
                  <EnhancedRadio value={key}>{value}</EnhancedRadio>
                ))
              : null}
          </EnhancedRadio.Group>
        </React.Fragment>
      ) : (
        <div className={`field-padding ${field.className ? field.className : ''}`}>
          {(field.options.find((option) => option.key === field.value) || {}).value}
        </div>
      );

    case INPUT:
      return isEdit ? inputField : <div className="field-padding">{field.value}</div>;

    case MONTH:
      return isEdit ? (
        <MonthPicker
          format={MONTH_FORMAT}
          disabledDate={field.disabledDate}
          onChange={
            setStateCallback && handlers[field.type]
              ? (month, monthString) => {
                  handlers[field.type](monthString, field, setStateCallback);
                }
              : null
          }
          value={moment(field.value).isValid() ? moment(field.value, MONTH_FORMAT) : ''}
        />
      ) : (
        <div className="field-padding">{field.value || ''}</div>
      );

    case DATE:
      return isEdit ? (
        <EnhancedDatePicker
          format={DATE_FORMAT}
          allowClear
          disabledDate={field.disabledDate}
          onChange={
            setStateCallback && handlers[field.type]
              ? (date, dateString) => {
                  handlers[field.type](date, dateString, field, setStateCallback);
                }
              : null
          }
          value={moment(field.value).isValid() ? moment(field.value, DATE_FORMAT) : ''}
        />
      ) : (
        <div className="field-padding">{field.value || ''}</div>
      );

    case SELECT:
      return isEdit ? (
        <React.Fragment>
          <EnhancedSelect
            showSearch
            allowClear={isAllowClear}
            optionFilterProp="children"
            value={field.value || undefined}
            className="field-select"
            disabled={field.disabled}
            onChange={
              setStateCallback && handlers[field.type]
                ? (value) => {
                    handlers[field.type](value, field, setStateCallback);
                  }
                : null
            }
          >
            {field.options && field.options.length
              ? field.options.map((option) => (
                  <Option key={option.key || option.id} value={option.key || option.id}>
                    {`${option.value || option.name}`}
                  </Option>
                ))
              : null}
          </EnhancedSelect>
        </React.Fragment>
      ) : (
        <div className="field-padding">{field.value}</div>
      );

    case SELECT_WITH_UPLOAD:
      return isEdit ? (
        <React.Fragment>
          <EnhancedSelect
            value={field.value || undefined}
            className="field-select"
            onChange={
              setStateCallback && handlers[field.type]
                ? (value) => {
                    handlers[field.type](value, field, setStateCallback);
                  }
                : null
            }
          >
            {field.options && field.options.length
              ? field.options.map((option) => (
                  <Option key={option.key} value={option.key}>
                    {`${option.value}`}
                  </Option>
                ))
              : null}
          </EnhancedSelect>
          {field.handlers && field.handlers.openModal && (
            <EnhancedButton
              className="ml-10"
              shape="circle"
              icon="upload"
              onClick={field.handlers.openModal}
            />
          )}
        </React.Fragment>
      ) : (
        <div className="field-padding">
          {field.value}
          {field.value && field.handlers && field.handlers.getDocument && (
            <EnhancedButton
              className="ml-10"
              shape="circle"
              icon="download"
              loading={field.loading || false}
              onClick={field.handlers.getDocument}
            />
          )}
        </div>
      );

    case CHECKBOX:
      return (
        <EnhancedCheckbox
          checked={field.value || false}
          disabled={!isEdit}
          className="field-padding"
          onChange={
            setStateCallback && handlers[field.type]
              ? (e) => {
                  handlers[field.type](e.target.checked, field, setStateCallback);
                }
              : null
          }
        />
      );

    case COMMENT:
      return isEdit ? (
        <EnhancedInput.TextArea
          autoSize={{ minRows: 3, maxRows: 6 }}
          id={field.key}
          value={isEdit ? field.value : ''}
          disabled={!isEdit}
          onChange={
            setStateCallback && handlers[field.type]
              ? (e) => {
                  handlers[field.type](e.target.value, field, setStateCallback);
                }
              : null
          }
        />
      ) : (
        <div className="flex">
          <div>
            <EnhancedAvatar
              size="large"
              style={{
                backgroundColor: AVATAR_COLORS[field.author[0].toLowerCase()],
              }}
            >
              {getInitials(field.author)}
            </EnhancedAvatar>
          </div>
          <div className="comment">
            <div className="comment-author">
              <span className="comment-author-name">{field.author}</span>
              <span className="comment-author-time">{field.dated}</span>
            </div>
            <div className="comment-content">
              <div className="comment-title">{field.title}</div>
              <div className="comment-content">{field.value}</div>
            </div>
            {field.document && (
              <div className="comment-document flex">
                <div>
                  <EnhancedIcon className="comment-document-icon" type="file" />
                  <span className="comment-attachment">Attachment</span>
                </div>
                <EnhancedButton
                  className="ml-10"
                  shape="circle"
                  icon="download"
                  onClick={() => handlerFunctions.getAttachment(field.id)}
                />
              </div>
            )}
          </div>
        </div>
      );

    case SKILL_BADGE: {
      let tagElement = <span />;
      if (field.value && field.value.length) {
        tagElement = field.value.map((value) => (
          <EnhancedTag className="mb-10" key={value} closable={false}>
            {value.length > 20 ? `${value.slice(0, 20)}...` : value}
          </EnhancedTag>
        ));
      }
      return isEdit ? (
        <EnhancedSelect
          mode="multiple"
          defaultValue={field.value || []}
          className="field-select"
          onChange={
            setStateCallback && handlers[field.type]
              ? (value) => {
                  handlers[field.type](value, field, setStateCallback);
                }
              : null
          }
        >
          {field.options && field.options.length
            ? field.options.map((option) => (
                <Option key={option.key} value={option.value}>
                  {`${option.value}`}
                </Option>
              ))
            : null}
        </EnhancedSelect>
      ) : (
        tagElement
      );
    }

    case SLIDER:
      return <EnhancedSlider className="range-slider" min={1} max={20} />;

    case RATING:
      return (
        <React.Fragment>
          <EnhancedRate
            allowHalf
            className="rating"
            disabled={!isEdit || field.disabled}
            value={field.value}
            style={{ lineHeight: '15px' }}
            onChange={
              setStateCallback && handlers[field.type]
                ? (value) => {
                    handlers[field.type](value, field, setStateCallback);
                  }
                : null
            }
          />
          {field.showRatingText && (
            <RatingText rating={field.value} ratingOptions={field.ratingOptions} />
          )}
        </React.Fragment>
      );

    default:
      return null;
  }
};

const calculateRows = (dividend, divider) => {
  // Round up quotient
  return Math.ceil(dividend / divider);
};

// const excludeEmptyFields = (fields) => fields.filter((field) => field.value);

/**
 * Utility to generate field rows with nested columns
 * @param {object[]} fields Fields to be generated
 * @param {number} colsPerRow Number of columns per row
 * @param {boolean} isEdit State of generated fields
 * @param {string[]} userPermissions Permissions available to user
 * @callback setStateCallback Component binded callback or callbacks to set state values
 */
export const createFields = (
  fields,
  colsPerRow = 2,
  isEdit = false,
  userPermissions = [],
  setStateCallback,
) => {
  // render all fields if in Edit mode, else, only render fields with values. UPDATE: Remove for now, commenting.
  // const fieldsToRender = isEdit ? fields : excludeEmptyFields(fields);

  const fieldsToRender = fields;
  // Length variable for loop duration should align with columns per row
  const numberOfRows = calculateRows(fieldsToRender.length, colsPerRow);
  const rowsToRender = [];
  if (numberOfRows) {
    for (let index = 0; index < numberOfRows; index += 1) {
      const fieldsPerRow = fieldsToRender.splice(0, colsPerRow);

      // 2nd safety check
      if (fieldsPerRow.length) {
        rowsToRender.push(
          <EnhancedRow key={`row-${index}`} className="field-row" gutter={ROW_GUTTER_SIZE}>
            {fieldsPerRow.map((field) => {
              const isRequired =
                isEdit && field.validations && field.validations.includes(REQUIRED);
              /*
              If a user does not have view permission then no need to create field at all
              */
              const canView = field.viewPermissions
                ? hasPermission(userPermissions, field.viewPermissions)
                : true;

              const fieldSpan = Math.floor(FULL_GRID_SIZE / colsPerRow);
              const labelSpan = Math.floor(fieldSpan * 0.34);
              const inputSpan = Math.floor(fieldSpan * 0.67);
              return (
                field.key &&
                canView && (
                  <React.Fragment key={field.key}>
                    {/* <EnhancedCol
                      className="pl-10 pr-10 pb-4"
                      span={Math.floor(FULL_GRID_SIZE / colsPerRow)}
                    > */}
                    <EnhancedCol key={`${field.key}-label`} span={labelSpan}>
                      <div className="field-label">
                        <span>
                          <b>{field.label || ''}</b>
                          {isRequired && <b style={{ color: 'red', marginLeft: '2px' }}>*</b>}
                        </span>
                        <span>:</span>
                      </div>
                    </EnhancedCol>
                    <EnhancedCol
                      key={`${field.key}-value`}
                      className={`${
                        // If email field, do not capitalize, else, capitalize
                        field.key.includes('email') || field.noCapitalize ? '' : 'capitalize'
                      }`}
                      span={inputSpan}
                    >
                      {createInput(
                        field,
                        isEdit &&
                          (field.editPermissions
                            ? hasPermission(userPermissions, field.editPermissions)
                            : true),
                        setStateCallback,
                      )}
                      {isEdit ? createErrorMessage(field) : ''}
                    </EnhancedCol>
                    {/* </EnhancedCol> */}
                  </React.Fragment>
                )
              );
            })}
          </EnhancedRow>,
        );
      }
    }
  }
  return rowsToRender;
};

export const createSubHeading = (label) => {
  return (
    <div>
      <h2 className="shadow">{label}</h2>
    </div>
  );
};

export const createComments = (comments, canDelete, commentHandlers) => {
  const { handleDelete, getAttachment } = commentHandlers;
  return comments.map((comment) => {
    return (
      comment.id && (
        <EnhancedRow
          key={`author-${comment.id}`}
          className="field-row"
          gutter={ROW_GUTTER_SIZE}
          style={{ borderBottom: '1px solid #e8e8e8' }}
        >
          <React.Fragment key={comment.id}>
            <EnhancedCol
              key={`comment-${comment.id}`}
              className="pl-10 pr-10 mb-10"
              xs={FULL_GRID_SIZE}
            >
              {createInput(
                {
                  key: `comment-${comment.id}`,
                  ...comment,
                  type: COMMENT,
                },
                false,
                null,
                { getAttachment },
              )}
              <ul className="comment-actions-list">
                {canDelete ? (
                  <li>
                    <EnhancedButton
                      shape="circle"
                      icon="delete"
                      onClick={() => handleDelete(comment.id)}
                      type="danger"
                      className="btn-delete"
                    />
                  </li>
                ) : (
                  ''
                )}
              </ul>
            </EnhancedCol>
          </React.Fragment>
        </EnhancedRow>
      )
    );
  });
};

export const createFamilyMember = (
  {
    fullName,
    relationship,
    gender,
    dateOfBirth,
    contactNo,
    cnic,
    registeredInMedical,
    medicalPolicyNo,
  },
  colsPerRow,
  cancelSaveButtons,
  index,
  userPermissions = [],
  handleChange,
) => (
  <React.Fragment>
    {createFields(
      [
        {
          key: `fullName-${index}`,
          label: 'Name',
          value: fullName,
          type: INPUT,
          validations: [REQUIRED],
        },
        {
          key: `relationship-${index}`,
          label: 'Relation',
          options: RELATIONS,
          value: getValueForKeyinJSON(RELATIONS, relationship),
          type: SELECT,
          validations: [REQUIRED],
        },
        {
          key: `gender-${index}`,
          label: 'Gender',
          options: GENDERS,
          value: getValueForKeyinJSON(GENDERS, gender),
          type: SELECT,
          validations: [REQUIRED],
        },
        {
          key: `dateOfBirth-${index}`,
          label: 'DOB',
          value: dateOfBirth,
          type: DATE,
          disabledDate: disabledDateDOB,
          validations: [REQUIRED],
        },
        {
          key: `contactNo-${index}`,
          label: 'Contact #',
          value: contactNo,
          type: INPUT,
        },
        {
          key: `cnic-${index}`,
          label: 'CNIC #',
          value: cnic,
          type: INPUT,
        },
        {
          key: `registeredInMedical-${index}`,
          label: 'Registered in Medical Policy',
          value: registeredInMedical,
          type: CHECKBOX,
        },
        {
          key: `medicalPolicyNo-${index}`,
          label: 'Medical Policy #',
          value: medicalPolicyNo,
          type: INPUT,
        },
      ],
      colsPerRow,
      cancelSaveButtons,
      userPermissions,
      handleChange,
    )}
  </React.Fragment>
);
export const createCertificate = (
  { name, certificateNo, date, validTill, certificateCompetency, issuingBody, isEdit },
  index,
  certificatesCompetenciesOptions,
  setStateCallback,
) => (
  <React.Fragment>
    {createFields(
      [
        {
          key: `name-${index}`,
          label: 'Training Certification Name',
          // labelSize: CERTIFICATE_LABEL_SIZE,
          value: name,
          // valueSize: CERTIFICATE_VALUE_SIZE,
          type: INPUT,
          validations: [REQUIRED],
        },
        {
          key: `date-${index}`,
          label: 'Issuing Year',
          value: date,
          type: MONTH,
          validations: [REQUIRED],
          disabledDate: (e) => disabledFutureMonths(e),
        },
        {
          key: `validTill-${index}`,
          label: 'Valid Till',
          value: validTill,
          type: MONTH,
          disabledDate: (e) => disabledPastDatesByGivenDate(e, date),
        },
        {
          key: `certificateNo-${index}`,
          label: 'Certificate #',
          value: certificateNo,
          type: INPUT,
        },
        {
          key: `issuingBody-${index}`,
          label: 'Issuing Body',
          value: issuingBody,
          type: INPUT,
          validations: [REQUIRED],
        },
        {
          key: `certificateCompetency-${index}`,
          label: 'Competency',
          value: getValueForKeyinJSON(certificatesCompetenciesOptions, certificateCompetency),
          options: certificatesCompetenciesOptions,
          type: SELECT,
        },
      ],
      2,
      isEdit,
      [],
      setStateCallback,
    )}
  </React.Fragment>
);

export const createEmergencyContact = (
  { fullname, relationship, contactNo },
  isEdit = false,
  userPermissions,
  handleFieldChange,
) => (
  <React.Fragment>
    {createFields(
      [
        {
          key: `fullname`,
          label: 'Name',
          value: fullname,
          type: INPUT,
          validations: [REQUIRED],
        },
        {
          key: `contactNo`,
          label: 'Number',
          value: contactNo,
          type: INPUT,
          validations: [REQUIRED],
        },
        {
          key: `relationship`,
          label: 'Relation',
          labelSize: RELATIONSHIP_LABEL_SIZE,
          value: relationship,
          type: INPUT,
          validations: [REQUIRED],
        },
      ],
      3,
      isEdit,
      userPermissions,
      handleFieldChange,
    )}
  </React.Fragment>
);

export const createJobHistory = ({
  jobTitle,
  department,
  division,
  managerName,
  location,
  reviewManager,
  employmentType,
  grade,
  subGrade,
  grossSalary,
}) => (
  <React.Fragment>
    {createFields([
      {
        key: `job-history-job-title-${jobTitle}-${grossSalary}`,
        label: 'Job Title:',
        value: jobTitle,
        type: INPUT,
      },
      {
        key: `job-history-department-${jobTitle}-${grossSalary}`,
        label: 'Department:',
        value: department,
        type: INPUT,
      },
      {
        key: `job-history-divsion-${jobTitle}-${grossSalary}`,
        label: 'Division:',
        value: division,
        type: INPUT,
      },
      {
        key: `job-history-manager-name-${jobTitle}-${grossSalary}`,
        label: 'Manager Name',
        value: managerName,
        type: INPUT,
      },
      {
        key: `job-history-location-${jobTitle}-${grossSalary}`,
        label: 'Location',
        value: location,
        type: INPUT,
      },
      {
        key: `job-history-review-manager-${jobTitle}-${grossSalary}`,
        label: 'Review Manager',
        value: reviewManager,
        type: INPUT,
      },
      {
        key: `job-history-employment-type-${jobTitle}-${grossSalary}`,
        label: 'Employment Type',
        value: employmentType,
        type: INPUT,
      },
      {
        key: `job-history-grade-${jobTitle}-${grossSalary}`,
        label: 'Grade',
        value: grade,
        type: INPUT,
      },
      {
        key: `job-history-sub-grade-${jobTitle}-${grossSalary}`,
        label: 'Sub-Grade',
        value: subGrade,
        type: INPUT,
      },
      {
        key: `job-history-gross-salary-${jobTitle}-${grossSalary}`,
        label: 'Gross Salary',
        value: grossSalary,
        type: INPUT,
      },
    ])}
  </React.Fragment>
);

export const createTrackingInfo = (
  {
    joiningDate,
    probationPeriodDuration,
    probationPeriodEnd,
    noticePeriodDuration,
    noticePeriodServed,
    createdAt,
    resignationDate,
    leavingDate,
    resignationType,
    primaryReasonForLeaving,
    secondaryReasonForLeaving,
    lastTrackingUpdate,
    isActive,
  },
  noticePeriodTypeOptions,
  resignationTypeOptions,
  primaryReasonForLeavingOptions,
  isEdit = false,
  userPermissions,
  setStateCallback,
) => (
  <React.Fragment>
    {createFields(
      [
        {
          key: 'joiningDate',
          label: 'Start Date',
          value: joiningDate,
          type: DATE,
          validations: [REQUIRED],
        },
        {
          key: 'createdAt',
          label: 'Added to System',
          value: createdAt,
          type: TEXT,
        },
        {
          key: 'probationPeriodDuration',
          label: 'Probation Period Duration (days)',
          value: probationPeriodDuration,
          type: NUMBER,
          min: 0,
        },
        {
          key: 'probationPeriodEnd',
          label: 'Probation Period End',
          value: probationPeriodDuration ? probationPeriodEnd : '',
          type: TEXT,
        },
        {
          key: 'resignationDate',
          label: 'Resigned Date',
          value: resignationDate,
          type: DATE,
          disabledDate: (e) => disabledPastDatesByGivenDate(e, joiningDate),
        },
        {
          key: 'leavingDate',
          label: 'Last Working Day',
          value: leavingDate,
          type: DATE,
          disabledDate: (e) => disabledPastDatesByGivenDate(e, resignationDate),
        },
        {
          key: 'noticePeriodDuration',
          label: 'Notice Period Duration (days)',
          value: noticePeriodDuration,
          type: SELECT,
          options: noticePeriodTypeOptions,
        },
        {
          key: 'noticePeriodServed',
          label: 'Notice Period Served (days)',
          value: noticePeriodServed,
          type: NUMBER,
          min: 0,
        },
        {
          key: 'resignationType',
          label: 'Resigned Type',
          value: getValueForKeyinJSON(resignationTypeOptions, resignationType),
          type: SELECT,
          options: resignationTypeOptions,
        },
        {
          key: 'primaryReasonForLeaving',
          label: 'Reason of Leaving (Primary)',
          value: getValueForKeyinJSON(primaryReasonForLeavingOptions, primaryReasonForLeaving),
          type: SELECT,
          options: primaryReasonForLeavingOptions,
        },
        {
          key: 'secondaryReasonForLeaving',
          label: 'Reason of Leaving (Secondary)',
          value: secondaryReasonForLeaving,
          type: TEXTAREA,
        },
        {
          key: 'lastTrackingUpdate',
          label: 'Last Updated',
          value: lastTrackingUpdate,
          type: TEXT,
        },
        {
          key: 'isActive',
          label: 'Status',
          value: isActive ? 'Active' : 'Inactive',
          type: TEXT,
        },
      ],
      2,
      isEdit,
      userPermissions,
      setStateCallback,
    )}
  </React.Fragment>
);

export const createEducationHistory = (
  {
    dateFrom,
    dateTo,
    major,
    degree,
    qualificationTypeId,
    institute,
    score,
    city,
    country,
    isEdit = false,
  },
  index,
  qualificationOptions,
  setStateCallback,
) => (
  <React.Fragment>
    {createFields(
      [
        {
          key: `institute-${index}`,
          label: 'Institute Name',
          value: institute,
          type: INPUT,
          validations: [REQUIRED],
        },
        {
          key: `qualificationTypeId-${index}`,
          label: 'Education Level',
          value: getValueForKeyinJSON(qualificationOptions, qualificationTypeId),
          options: qualificationOptions,
          type: SELECT,
          validations: [REQUIRED],
        },
        {
          key: `degree-${index}`,
          label: 'Degree Title',
          value: degree,
          type: INPUT,
          validations: [REQUIRED],
        },
        {
          key: `major-${index}`,
          label: 'Major',
          value: major,
          type: INPUT,
          validations: [REQUIRED],
        },
        {
          key: `dateFrom-${index}`,
          label: 'Date From',
          value: dateFrom,
          type: MONTH,
          validations: [REQUIRED],
        },
        {
          key: `dateTo-${index}`,
          label: 'Date to',
          value: dateTo,
          type: MONTH,
          validations: [REQUIRED],
          disabledDate: (e) => disabledPastDatesByGivenDate(e, dateFrom),
        },
        {
          key: `score-${index}`,
          label: 'CGPA | Percentage | Ratio',
          value: score,
          type: INPUT,
        },
        {
          key: `city-${index}`,
          label: 'City',
          value: city,
          type: INPUT,
          validations: [REQUIRED],
        },
        {
          key: `country-${index}`,
          label: 'Country',
          value: country,
          type: INPUT,
          validations: [REQUIRED],
        },
      ],
      2,
      isEdit,
      [],
      setStateCallback,
    )}
  </React.Fragment>
);

export const noData = () => {
  return (
    <EnhancedCard className="flex-center-container">
      <p
        style={{
          fontFamily: 'inherit',
          fontWeight: 500,
          fontSize: '20px',
        }}
      >
        No record exists
      </p>
    </EnhancedCard>
  );
};

export const shouldSort = (key) => {
  switch (key) {
    case ACTIONS:
      return false;
    case URL:
      return false;
    default:
      return true;
  }
};
