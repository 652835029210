import { connect } from 'react-redux';
import Roles from 'components/Roles';
import {
  getRolesAction,
  getRoleEmployeesAction,
  addRoleAction,
  updateRoleAction,
  deleteRoleAction,
  getDDLEmployeesAction,
  assignRoleToEmployeeAction,
  deleteRoleFromEmployeeAction,
} from 'store/actions/RoleActions';
import { getAllDesignationActions } from 'store/actions/DesignationActions';

function mapStateToProps(state) {
  const { auth, roleReducer, designationsReducer } = state;
  return {
    userId: auth.user && auth.user.id,
    roles: roleReducer.roles,
    role: roleReducer.role,
    isLoading: roleReducer.isLoading,
    employees: roleReducer.employees,
    designations: designationsReducer.designations,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRolesAction: () => dispatch(getRolesAction()),
    getRoleEmployeesAction: (id) => dispatch(getRoleEmployeesAction(id)),
    addRoleAction: (role) => dispatch(addRoleAction(role)),
    updateRoleAction: (role) => dispatch(updateRoleAction(role)),
    deleteRoleAction: (id) => dispatch(deleteRoleAction(id)),
    getDDLEmployeesAction: (userId) => dispatch(getDDLEmployeesAction(userId)),
    assignRoleToEmployeeAction: (roleId, employeeId, userId) =>
      dispatch(assignRoleToEmployeeAction(roleId, employeeId, userId)),
    deleteRoleFromEmployeeAction: (employeeRoleId, roleId, userId) =>
      dispatch(deleteRoleFromEmployeeAction(employeeRoleId, roleId, userId)),
    getAllDesignations: () => dispatch(getAllDesignationActions()),
  };
}

const RolesContainer = connect(mapStateToProps, mapDispatchToProps)(Roles);

export default RolesContainer;
