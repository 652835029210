/* eslint-disable */
import {
  EnhancedSpin,
  EnhancedIcon,
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedLayout,
  EnhancedModal,
  EnhancedSelect,
  EnhancedForm,
} from 'components/shared/antd';
import React, { Component } from 'react';
import moment from 'moment';
import RatingModalCRMC from 'components/pc/ratingModalCRMC';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import ProjectScore from './shared/ProjectScore';
import RequiredField from '../RequiredField';
import { BREADCRUMBS } from '../../configs/constants';

const { Header } = EnhancedLayout;
const { Option } = EnhancedSelect;
const { confirm } = EnhancedModal;

class PerformanceCycle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: {
        valueQuality: 0,
        valueEfficiency: 0,
        valueSkill: 0,
        valueOwnership: 0,
        valueEthics: 0,
      },
      showAddNewForm: false,
      sortedInfo: null,
      errorText: '',
    };
  }

  componentDidMount() {
    const {
      getCRMCPanelProjectDataAction,
      isCRMC1,
      match,
      userId,
      getProjectRatingAction,
      getProjectsAction,
      getProjectScoreAction,
    } = this.props;
    if (isCRMC1) {
      getProjectsAction();
      getCRMCPanelProjectDataAction(match.params.id, match.params.projectId);
      getProjectScoreAction(match.params.id, match.params.projectId);
    } else {
      getProjectRatingAction(match.params.projectId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { projectRating, updateBreadCrumb, match } = nextProps;
    const previousProjectRating = this.props.projectRating;
    const { projectName, cycleName } = projectRating;
    const { params } = match;
    const { id, projectId } = params;

    if (previousProjectRating.cycleName !== cycleName) {
      updateBreadCrumb(BREADCRUMBS.CYCLES, id, cycleName);
    }
    if (previousProjectRating.projectName !== projectName) {
      updateBreadCrumb(BREADCRUMBS.PROJECT_CYCLE, projectId, projectName);
    }
  }
  onChange = (field, value) => {
    const project = { ...this.state.project };
    project[field] = value;
    this.setState({
      project,
    });
  };

  onStartDateChange = (value, option) => {
    this.onChange('startDate', option);
  };

  onEndDateChange = (value, option) => {
    this.onChange('endDate', option);
  };

  getColumnsInfo = () => {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    let columns = [
      {
        title: 'Resource Name',
        dataIndex: 'name',
        key: 'name',
        width: '100',
        filterConfig: {
          type: 'search',
          key: 'name',
        },
        render: this.renderResourceName,
      },
      {
        title: 'Line Manager',
        dataIndex: 'lineManager',
        key: 'lineManager',
        width: '100',
        filterConfig: {
          type: 'search',
          key: 'lineManager',
        },
      },
      {
        title: 'Competency',
        dataIndex: 'department',
        key: 'department',
        width: '100',
        filterConfig: {
          type: 'search',
          key: 'department',
        },
      },
      {
        title: 'PM Status',
        dataIndex: 'PMStatus',
        key: 'PMStatus',
        width: '100',
        render: this.renderStatusName,
      },
      {
        title: 'Architect Status',
        dataIndex: 'archStatus',
        key: 'archStatus',
        width: '100',
        render: this.renderStatusName,
      },
      {
        title: 'Feedback Status',
        dataIndex: 'feedbackStatus',
        key: 'feedbackStatus',
        width: '100',
        render: this.renderStatusName,
      },
      {
        title: 'Action',
        width: '100',
        render: this.renderIcon,
      },
    ];
    return columns;
  };

  confirmDelete = (data) => {
    confirm({
      title: 'Are you sure, you want to Delete?',
      content: '',
      okText: 'Yes',
      cancelText: 'Cancel',
      onOk: () => {
        this.deleteRating(data);
      },
    });
  };

  openRatingModal = (data) => {
    this.setState({
      currentlyFocusId: data.id,
      rating: data,
      addEditResourceModalVisible: true,
      selectedResourceName: data.name,
    });
  };

  deleteRating = (data) => {
    const payload = {
      employeeId: this.props.userId,
      performanceCycleId: this.props.match.params.id,
      projectId: this.props.match.params.projectId,
      resourceId: data.employeeId,
      ignoreProjectIdForNextCall: false,
    };

    this.props.removeProjectRating(payload);
  };

  updateFocusId = (data) => {
    this.setState({
      currentlyFocusId: data.id,
      rating: data,
    });
  };

  handleEditClose = (close) => {
    this.setState({ addEditResourceModalVisible: close });
  };

  showTotal = (total) => `Total ${total} items`;

  showAddNew = () => {
    this.setState({
      showAddNewForm: !this.state.showAddNewForm,
    });
  };

  handleCancel = () => {
    this.setState({
      project: {
        name: '',
        startDate: '',
        endDate: '',
        projectManagerId: '',
      },
      errorText: '',
    });
  };

  handleNameChange = (value) => {
    const project = { ...this.state.project };
    project.name = value.target.value;
    this.setState({
      project,
    });
  };

  handleManagerChange = (manager) => {
    this.onChange('projectManagerId', manager);
  };

  disabledStartDate = (startValue) => {
    const endValue = this.state.project.endDate ? moment.utc(this.state.project.endDate) : Date();
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = (endValue) => {
    const startValue = moment.utc(this.state.project.startDate).add(1, 'days');
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  /* handleSave = (value) => {
    value.preventDefault();
    const { name } = this.state.project;
    const { projects } = this.props;
    const similarProject = projects.filter(project =>
      project.name.toLowerCase() === name.toLowerCase())[0];

    if (similarProject) {
      this.setState({
        errorText: 'Project with the same name already exists.',
      });
    } else {
      this.props.addProject(this.state.project);
      this.handleCancel();
    }
  } */
  shouldButtonDisable = () => {
    const { employee } = this.state;
    if (employee) return false;
    return true;
  };

  handleSubmit = (event) => {
    const modalOpen = event.target.name === 'save-and-add';
    let employee = this.state.employee;
    employee.employeeId = this.props.userId;
    employee.performanceCycleId = this.props.match.params.id;
    employee.projectId = this.props.match.params.projectId;
    this.props.addEmployeeProjectRatingAction(employee);
    this.setState({
      employee: null,
      modalOpen,
    });
  };

  renderResourceName = (name, data) => <b style={{ color: '#5A5A5A' }}>{name}</b>;

  renderStatusName = (data) => {
    if (data) {
      return 'Complete';
    } else {
      return 'Pending';
    }
  };

  onViewIconClick = (data) => {
    const { getRatingDetailsCRMC } = this.props;
    const payload = {
      performanceCycleId: this.props.match.params.id,
      projectId: this.props.match.params.projectId,
      resourceId: data.employeeId,
    };
    getRatingDetailsCRMC(payload);
    this.openRatingModal(data);
  };

  renderIcon = (name, data) => {
    const key = this.props.isArch ? 'arch' : 'PM';

    return (
      <span>
        <EnhancedIcon
          onClick={() => this.onViewIconClick(data)}
          onMouseOver={() => this.updateFocusId(data)}
          type="eye"
          style={{
            color: '#bcbcff',
            name,
          }}
        />
        {!this.isCycleComplete() && (
          <span>
            {' '}
            |{' '}
            <EnhancedIcon
              onClick={() => this.confirmDelete(data)}
              onMouseOver={() => this.updateFocusId(data)}
              type="delete"
              style={{
                color: '#bcbcff',
                name,
              }}
            />
          </span>
        )}
      </span>
    );
  };

  onEmployeeChange = (employeeId) => {
    if (employeeId) {
      const employee = {
        assigneeId: employeeId && parseInt(employeeId, 10),
        employeeId: this.props.userId,
        performanceCycleId: this.props.match.params.id,
        projectId: this.props.match.params.projectId,
      };
      this.setState({ employee });
    } else {
      this.setState({ employee: null });
    }
  };

  renderEmployees = () => {
    let employees = this.props.allEmployees || [];

    return (
      <EnhancedSelect
        value={this.state.employee ? this.state.employee.assigneeId : null}
        showSearch
        allowClear
        optionFilterProp="children"
        style={{ width: '100%' }}
        placeholder="Please Select Employee"
        onChange={this.onEmployeeChange}
        notFoundContent={this.props.isLoadingEmployees ? <EnhancedSpin size="small" /> : null}
        disabled={this.props.isEdit}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().includes(input.toLowerCase())
        }
      >
        {employees.map((employee) =>
          employee.isActive ? (
            <Option key={employee.id} value={employee.id} title={employee.name}>
              {employee.name}
            </Option>
          ) : (
            ''
          ),
        )}
      </EnhancedSelect>
    );
  };

  renderFormRow = () => {
    const { employees } = this.props;
    const { errorText } = this.state;
    return (
      <EnhancedForm layout="inline">
        <EnhancedRow>
          <EnhancedCol lg={24}>
            <EnhancedRow className="margin-top10">
              <EnhancedCol lg={8}>
                <RequiredField>Employee</RequiredField>
              </EnhancedCol>
              <EnhancedCol lg={14} style={{ marginLeft: '5px' }}>
                {this.renderEmployees()}
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedForm>
    );
  };

  showModal = () => {
    this.setState({
      modalOpen: true,
    });
  };

  isCycleComplete = () => {
    const { projectRating } = this.props;
    const { completeStatus } = projectRating;
    return completeStatus || false;
  };

  handleCancel = () => {
    this.setState({
      modalOpen: false /*
      errorText: '', */,
    });
  };

  renderModal = () => {
    const { isAdding } = this.props;
    const { modalOpen } = this.state;

    return (
      <EnhancedModal
        title="Add New Resource"
        visible={modalOpen}
        userId={this.props.userId}
        onCancel={this.handleCancel}
        width={650}
        footer={[
          <EnhancedButton key="back" onClick={this.handleCancel}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            key="save"
            name="save"
            type="primary"
            loading={isAdding}
            onClick={this.handleSubmit}
            disabled={this.shouldButtonDisable()}
          >
            Save
          </EnhancedButton>,
          <EnhancedButton
            key="save-and-add"
            name="save-and-add"
            type="primary"
            loading={isAdding}
            onClick={this.handleSubmit}
            disabled={this.shouldButtonDisable()}
          >
            Save and Add More
          </EnhancedButton>,
        ]}
      >
        {this.renderFormRow()}
      </EnhancedModal>
    );
  };

  render() {
    const { isLoading, projectRating, ratingDetails, resetRatingDetails, projectScore } =
      this.props;
    let { projectRatings } = projectRating;
    let { projectName, cycleName } = projectRating;
    projectName = projectName || '';
    cycleName = cycleName || '';

    return (
      <div>
        {this.state.addEditResourceModalVisible && (
          <RatingModalCRMC
            onModalClose={this.handleEditClose}
            resourceName={this.state.selectedResourceName}
            projectRating={this.state.rating}
            visible={true}
            ratingDetails={ratingDetails}
            resetRatingDetails={resetRatingDetails}
          />
        )}
        {this.state.modalOpen ? this.renderModal() : ''}
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol lg={12}>
              <h1 className="heading-pro">{`${projectName} - ${cycleName}`}</h1>
            </EnhancedCol>
            <EnhancedCol lg={12} style={{ textAlign: 'right' }}>
              <ProjectScore projectScore={projectScore} />
              {!this.isCycleComplete() && (
                <EnhancedButton type="primary" onClick={this.showModal} className="addNew-button">
                  Add new Resource
                </EnhancedButton>
              )}
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        <div className="project-table">
          <EnhancedClientSideTable
            loading={isLoading}
            columns={this.getColumnsInfo()}
            data={projectRatings || []}
          />
        </div>
      </div>
    );
  }
}

export default PerformanceCycle;
