/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  EnhancedButton,
  EnhancedSpin,
  EnhancedModal,
  EnhancedEmpty,
  EnhancedInput,
  EnhancedNotification,
} from 'components/shared/antd';

import {
  createInterviewQuestion,
  createInterviewQuestionByFile,
  deleteInterviewQuestion,
  getQuestionList as getQuestionListAction,
  updateInterviewQuestion,
} from '../../sources/interviewSources';
import UploadSheet from '../UploadSheet';
import './interview-form.css';

const QuestionEditor = (props) => {
  const {
    visible,
    onClose,
    interviewData,
    recordId,
    loading,
    questionList,
    getQuestionList,
    createQuestion,
    createQuestionByFile,
    updateQuestion,
    deleteQuestion,
  } = props;

  const [showing, setShowing] = useState({});
  const [editing, setEditing] = useState(null);
  const [text, setText] = useState('');
  const [answer, setAnswer] = useState('');
  const [file, setFile] = useState([]);

  useEffect(() => {
    if (recordId) {
      getQuestionList(recordId);
      setShowing({});
      setEditing(null);
      setText('');
      setAnswer('');
    }
  }, [recordId]);

  const handleView = (event) => {
    const index = Number(event.currentTarget.dataset.index);
    setShowing((state) => ({ ...state, [index]: !state[index] }));
  };

  const handleEdit = (event) => {
    const id = Number(event.currentTarget.dataset.id);
    const record = questionList.find((item) => item.id === id);
    if (record) {
      setText(record.text);
      setAnswer(record.answer);
      setEditing(id);
    }
  };
  const handleDelete = (event) => {
    const id = Number(event.currentTarget.dataset.id);
    deleteQuestion(id);
  };

  const handleSubmit = () => {
    const payload = {
      text,
      answer,
      interviewId: recordId,
      projectId: interviewData.projectId,
      employeeId: interviewData.employeeId,
      trackId: interviewData.trackId,
    };
    if (!isEmpty(file)) {
      const formData = new FormData();
      Object.keys(payload).forEach((key) => formData.append(key, payload[key]));
      formData.append('questions', file[0]);
      createQuestionByFile(formData).then(() => setFile([]));
      return;
    }
    if (!payload.text) {
      EnhancedNotification.error({ message: 'Question field is rquired' });
    } else {
      setText('');
      setAnswer('');
      if (editing) {
        const id = editing;
        setEditing('');
        updateQuestion(id, payload);
      } else {
        createQuestion(payload);
      }
    }
  };

  const handleDiscard = () => {
    setText('');
    setAnswer('');
    setEditing(null);
  };

  const handleClose = () => {
    setFile([]);
    onClose();
  };

  return (
    <EnhancedModal
      width={640}
      title="Interview Questions"
      visible={visible || false}
      onOk={handleClose}
      onCancel={handleClose}
      footer={null}
    >
      <div className="no-questions">
        {loading ? <EnhancedSpin /> : null}
        {!loading && !questionList.length ? (
          <EnhancedEmpty description="No Questions added yet!" />
        ) : null}
      </div>
      {questionList.map((item, index) => (
        <div className="question" key={item.id}>
          <div className="question-content">
            <div>
              <b>{item.text}</b>
            </div>
            {showing[index] ? <div>{item.answer}</div> : null}
          </div>
          <EnhancedButton
            style={{ flexShrink: 0 }}
            data-index={index}
            onClick={handleView}
            size="small"
            icon="eye"
          />
          <EnhancedButton
            style={{ flexShrink: 0 }}
            data-id={item.id}
            onClick={handleEdit}
            size="small"
            icon="edit"
          />
          <EnhancedButton
            style={{ flexShrink: 0 }}
            data-id={item.id}
            onClick={handleDelete}
            size="small"
            icon="delete"
          />
        </div>
      ))}
      <div className="field">
        <div className="large-label">
          <b>{editing ? 'Edit Question' : 'Add Question'}</b>
        </div>
        <EnhancedInput.TextArea
          style={{ marginBottom: 8 }}
          placeholder="Question"
          value={text}
          onChange={(e) => setText(e.target.value)}
          disabled={!isEmpty(file)}
        />
        <EnhancedInput.TextArea
          rows={3}
          placeholder="Answer"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          disabled={!isEmpty(file)}
        />
      </div>
      <div className="modal-footer">
        {!editing && (
          <UploadSheet
            disabled={!isEmpty(text.trim()) || !isEmpty(answer.trim())}
            files={file}
            maxCount={1}
            onChange={(_file) => setFile(_file)}
          />
        )}
        <EnhancedButton
          type="primary"
          onClick={handleSubmit}
          disabled={isEmpty(text.trim()) && isEmpty(file)}
        >
          {!isEmpty(file) ? 'Start upload' : editing ? 'Save Changes' : 'Save'}
        </EnhancedButton>
        {editing && (
          <EnhancedButton type="secondary" onClick={handleDiscard}>
            Discard
          </EnhancedButton>
        )}
      </div>
    </EnhancedModal>
  );
};

const mapState = (state) => ({
  questionList: state.interviewReducer.questionList || [],
  loading: state.interviewReducer.loadingQuestions,
});

const mapDispatch = (dispatch) => ({
  getQuestionList: (id) => dispatch(getQuestionListAction(id)),
  createQuestion: (payload) => dispatch(createInterviewQuestion(payload)),
  createQuestionByFile: (payload) => dispatch(createInterviewQuestionByFile(payload)),
  updateQuestion: (id, payload) => dispatch(updateInterviewQuestion(id, payload)),
  deleteQuestion: (id) => dispatch(deleteInterviewQuestion(id)),
});

export default connect(mapState, mapDispatch)(QuestionEditor);
