import {
  EnhancedCol,
  EnhancedButton,
  EnhancedRow,
  EnhancedModal,
  EnhancedSelect,
  EnhancedInput,
  EnhancedInputNumber,
  EnhancedRadio,
  EnhancedForm,
} from 'components/shared/antd';
import React from 'react';

const { Option } = EnhancedSelect;
const { TextArea } = EnhancedInput;
const actions = {
  ADD: 'add',
  ENCASH: 'encash',
};

class AdjustLeaveQuota extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leavesQuota: {
        action: actions.ADD,
      },
    };
  }

  onEmployeeChange = (e) => {
    this.onChange('employeeId', e);
  };

  onLeaveTypesChange = (e) => {
    this.onChange('leaveTypeId', e);
  };

  onLeaveCountChange = (e) => {
    this.onChange('count', e);
  };

  onLeaveCommentChange = (e) => {
    this.onChange('comment', e.target.value);
  };

  onActionChange = (e) => {
    this.onChange('action', e.target.value);
  };

  onChange = (field, value) => {
    const leavesQuota = { ...this.state.leavesQuota };
    leavesQuota[field] = value;
    this.setState({
      leavesQuota,
    });
  };

  handleSubmit = () => {
    const { leavesQuota } = this.state;
    this.props.adjustQuota({ ...leavesQuota });
  };

  render() {
    const { visible, employees, hideModal, leaveTypes } = this.props;

    const {
      leavesQuota: { employeeId, leaveTypeId, count, comment },
    } = this.state;
    const disabledSubmit = !employeeId || !leaveTypeId || !count || !comment;
    const primaryLeaves = leaveTypes.filter((leave) => leave.category === 'Primary');
    const activeEmployees = employees.filter((employee) => employee.isActive);

    return (
      <EnhancedModal
        title="Adjust Leaves Quota"
        width={650}
        visible={visible}
        footer={[
          <EnhancedButton key="back" onClick={hideModal}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            key="add"
            type="primary"
            disabled={disabledSubmit}
            onClick={this.handleSubmit}
          >
            Submit
          </EnhancedButton>,
        ]}
        onCancel={hideModal}
      >
        <EnhancedRow gutter={8}>
          <EnhancedForm layout="inline">
            <EnhancedCol span={24} className="form-col">
              <p>Employees:</p>
              <EnhancedSelect
                required
                showSearch
                allowClear
                optionFilterProp="children"
                style={{ width: '100%' }}
                placeholder="Please Select"
                onChange={this.onEmployeeChange}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {activeEmployees &&
                  activeEmployees.length &&
                  activeEmployees.map((employee) => (
                    <Option key={employee.id} name="employeeId" value={employee.id}>
                      {employee.name}
                    </Option>
                  ))}
              </EnhancedSelect>
            </EnhancedCol>
            <EnhancedCol span={24} className="form-col">
              <p>Leave Types:</p>

              <EnhancedSelect
                showSearch
                allowClear
                optionFilterProp="children"
                style={{ width: '100%' }}
                placeholder="Please Select"
                onChange={this.onLeaveTypesChange}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {primaryLeaves &&
                  primaryLeaves.length &&
                  primaryLeaves.map((type) => (
                    <Option key={type.id} value={type.id}>
                      {type.name}
                    </Option>
                  ))}
              </EnhancedSelect>
            </EnhancedCol>

            <EnhancedCol span={24} className="form-col">
              <p>Leaves Count:</p>
              <EnhancedInputNumber
                min={0.25}
                placeholder="Select leaves count"
                style={{ width: 150, height: 32 }}
                step={0.25}
                onChange={this.onLeaveCountChange}
              />
            </EnhancedCol>
            <EnhancedCol span={24} className="form-col">
              <p>Comment:</p>
              <TextArea
                style={{ resize: 'none' }}
                placeholder="Mention your comments here"
                rows="4"
                required
                onChange={this.onLeaveCommentChange}
              />
            </EnhancedCol>
            <EnhancedCol span={24} className="form-col">
              <p>Action:</p>
              <EnhancedRadio.Group
                defaultValue={actions.ADD}
                buttonStyle="solid"
                onChange={this.onActionChange}
              >
                <EnhancedRadio.Button value={actions.ADD}>Add</EnhancedRadio.Button>
                <EnhancedRadio.Button value={actions.ENCASH}>Encash</EnhancedRadio.Button>
              </EnhancedRadio.Group>
            </EnhancedCol>
          </EnhancedForm>
        </EnhancedRow>
      </EnhancedModal>
    );
  }
}

export default AdjustLeaveQuota;
