export const EMPLOYEES = 'employees';
export const CRMC = 'crmc';
export const ACTIONS = 'Actions';

// Maps
export const EMPLOYMENT_STATUS = {
  1: 'Employed',
  2: 'Terminated',
  3: 'Sabbatical',
};

// Status Filters
export const STATUS_ACTIVE = 'active';
export const STATUS_INACTIVE = 'inactive';
export const STATUS_ALL = 'all';

// Employee Profile Styling Constants

// Field Sizes
export const LABEL_SIZE_XS = 24;
export const VALUE_SIZE_XS = 24;
export const LABEL_SIZE_MD = 8;
export const VALUE_SIZE_MD = 14;
export const ROW_GUTTER_SIZE = 16;
export const FULL_GRID_SIZE = 24;

// Specific Field Sizes
export const CERTIFICATE_LABEL_SIZE = 4;
export const CERTIFICATE_VALUE_SIZE = 20;
export const RELATIONSHIP_LABEL_SIZE = 10;

// Input Types
export const NUMBER = 'number';
export const INPUT = 'input';
export const DATE = 'date';
export const PASSWORD = 'password';
export const MONTH = 'month';
export const SELECT = 'select';
export const SELECT_WITH_UPLOAD = 'selectWithUpload';
export const CHECKBOX = 'checkbox';
export const SKILL_BADGE = 'SkillBadge';
export const COMMENT = 'comment';
export const TEXTAREA = 'textarea';
export const RADIO = 'radio';
export const TEXT = 'text';
export const SLIDER = 'slider';
export const RATING = 'rating';
// Date Format
export const DATE_FORMAT = 'DD MMMM, YYYY';
export const DD_MMM_YYYY_HH_MM_SS = 'DD MMM YYYY HH:mm:ss';
export const DATETIME_FORMAT = 'DD MMMM, YYYY HH:mm:ss';
export const DATETIME_FORMAT_WITH_MERIDIAN = 'DD MMMM, YYYY hh:mm:ss A';
export const MONTH_FORMAT = 'MMMM, YYYY';
export const YEAR_FORMAT = 'YYYY';
export const COMMENT_TIME_FORMAT = 'dddd, MMMM DD, YYYY HH:mm';

// Table Key types
export const URL = 'url';
export const CREATED_AT = 'createdAt';

// Type constants
export const MOBILE = 'mobile';
export const FUEL_ALLOWANCE = 'Fuel Allowance';

// Styling constants
export const AVATAR_COLORS = {
  a: '#1abc9c',
  b: '#2ecc71',
  c: '#3498db',
  d: '#9b59b6',
  e: '#34495e',
  f: '#e67e22',
  g: '#7f8c8d',
  h: '#e74c3c',
  i: '#9c27b0',
  j: '#cddc39',
  k: '#e91e63',
  l: '#1abc9c',
  m: '#2ecc71',
  n: '#3498db',
  o: '#9b59b6',
  p: '#34495e',
  q: '#e67e22',
  r: '#7f8c8d',
  s: '#e74c3c',
  t: '#9c27b0',
  u: '#cddc39',
  v: '#e91e63',
  w: '#1abc9c',
  x: '#2ecc71',
  y: '#3498db',
  z: '#9b59b6',
};

// State|Props name constants

export const DROP_DOWNS = 'dropDowns';
export const EMPLOYEE_INFO = 'employeeInfo';
export const INFO = 'info';
export const FAMILY_INFO = 'familyInfo';
export const EMPLOYEE_CERTIFICATES = 'employeeCertificates';
export const EMERGENCY_CONTACT = 'emergencyContacts';
export const JOB_INFO = 'jobInfo';
export const CURRENT = 'current';
export const TRACKING = 'tracking';
export const CURRENT_COMPENSATION = 'compensation';
export const COMMENTS = 'comments';
export const EDUCATION_HISTORY = 'educationHistory';
export const CERTIFICATES = 'certificates';
export const EMPLOYMENT_HISTORY = 'employmentHistory';
export const TALENT_MANAGEMENT = 'talentManagement';
export const CAREER_PLANNING = 'careerPlanning';

// key name constants
export const ORACLE_ID = 'oracleId';
export const DATE_OF_BIRTH = 'dateOfBirth';
export const CNIC_EXPIRY = 'cnicExpiry';
export const PASSPORT_EXPIRY = 'passportExpiry';
export const NAME = 'name';
export const FULL_NAME = 'fullName';
export const BENEFITS = 'benefits';
export const JOB_TITLE = 'jobTitle';
export const REASON = 'reason';
export const DATE_TO = 'dateTo';
export const DATE_FROM = 'dateFrom';
export const IS_EDIT = 'isEdit';
export const ID = 'id';
export const SCORE = 'score';
export const IS_LOADING = 'isLoading';
export const COMPETENCY = 'competency';
export const SKILLS = 'skills';
export const PRIMARY_SKILLS = 'primarySkills';
export const SECONDARY_SKILLS = 'secondarySkills';
export const RESOURCE_TYPE = 'resourceType';
export const JOB_DESCRIPTION_ID = 'jobDescriptionId';
export const DESIGNATION_ID = 'designationId';
export const DIVISION_ID = 'divisionId';

// string constants
export const ORACLE_ID_PREFIX = 'VenD0';

export const GRADES = [
  { key: '1', value: '1' },
  { key: '2', value: '2' },
  { key: '3', value: '3' },
  { key: '4', value: '4' },
  { key: '5', value: '5' },
  { key: '6', value: '6' },
  { key: '7', value: '7' },
  { key: '8', value: '8' },
  { key: '9', value: '9' },
  { key: 'n/a', value: 'N/A' },
];
export const SUB_GRADES = [
  { key: 'a', value: 'A' },
  { key: 'b', value: 'B' },
  { key: 'n/a', value: 'N/A' },
];

export const CERTIFICATE_COMPETENCIES = [
  { key: 'Cloud Computing', value: 'Cloud Computing' },
  { key: 'Database Management', value: 'Database Management' },
  { key: 'Platform', value: 'Platform' },
];

export const COMPENSATION_TYPES = [
  { key: 'bonus', value: 'Bonus' },
  { key: 'csp', value: 'CSP' },
];

export const RELATIONS = [
  { key: 'parent', value: 'Parent' },
  { key: 'spouse', value: 'Spouse' },
  { key: 'child', value: 'Child' },
];

export const BONUS_TYPES = [
  { key: 'joining bonus', value: 'Joining Bonus' },
  { key: 'annual bonus', value: 'Annual Bonus' },
  { key: 'retention bonus', value: 'Retention Bonus' },
  { key: 'other', value: 'Other' },
];

export const NOTICE_PERIOD_TYPES = [
  { key: '30', value: '30' },
  { key: '45', value: '45' },
  { key: '60', value: '60' },
  { key: '90', value: '90' },
];

export const RESIGNED_TYPES = [
  { key: 'Voluntary', value: 'Voluntary' },
  { key: 'Involuntary', value: 'Involuntary' },
  { key: 'Voluntary Premature', value: 'Voluntary Premature' },
  { key: 'Involuntary Premature', value: 'Involuntary Premature' },
];

export const RESIGNATION_PRIMARY_REASONS = [
  { key: 'Abroad Opportunity', value: 'Abroad Opportunity' },
  { key: 'Pursuing Higher Studies', value: 'Pursuing Higher Studies' },
  { key: 'LM/PM Issue', value: 'LM/PM Issue' },
  { key: 'Better Salary', value: 'Better Salary' },
  { key: 'Other', value: 'Other' },
];

export const GOAL_STATUS = {
  PENDING: 'Pending',
  PENDING_APPROVAL: 'Pending Approval',
  APPROVED: 'Approved',
  COMPLETED: 'Completed',
  FINAL: 'Final',
  REJECTED: 'Rejected',
  DELETED: 'Deleted',
};

export const goalStatusToEditableFieldMapping = {
  [GOAL_STATUS.PENDING]: [
    'title',
    'description',
    'weightage',
    'measurement',
    'dueDate',
    'status',
    'progress',
  ],
  [GOAL_STATUS.PENDING_APPROVAL]: ['progress'],
  [GOAL_STATUS.APPROVED]: ['progress'],
  [GOAL_STATUS.COMPLETED]: [],
  [GOAL_STATUS.FINAL]: [],
  [GOAL_STATUS.REJECTED]: [],
  [GOAL_STATUS.DELETED]: [],
};

export const reviewTypes = {
  SELF: 'self',
  MANAGER: 'manager',
};

export const documentTypes = {
  Open: 'open',
  Restricted: 'restricted',
};

export const basicSalaryFactor = 0.90909;
