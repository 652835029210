import React from 'react';
import { Alert } from 'antd';
import PropTypes from 'prop-types';

const EnhancedAlert = (props) => {
  const { children, className, message, type, closable, style, onClose, showIcon } = props;
  return (
    <Alert
      className={className}
      message={message}
      type={type}
      style={style}
      showIcon={showIcon}
      closable={closable}
      onClose={onClose}
    >
      {children}
    </Alert>
  );
};

EnhancedAlert.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  showIcon: PropTypes.bool,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
};

EnhancedAlert.defaultProps = {
  className: '',
  style: {},
  showIcon: false,
  closable: false,
  onClose: () => {},
};

export default EnhancedAlert;
