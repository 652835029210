export const GET_PEF_DATA_INIT = 'GET_PEF_DATA_INIT';
export const GET_PEF_DATA_SUCCESS = 'GET_PEF_DATA_SUCCESS';
export const GET_PEF_DATA_FAILURE = 'GET_PEF_DATA_FAILURE';

export const SUBMIT_PEF_DATA_INIT = 'SUBMIT_PEF_DATA_INIT';
export const SUBMIT_PEF_DATA_SUCCESS = 'SUBMIT_PEF_DATA_SUCCESS';
export const SUBMIT_PEF_DATA_FAILURE = 'SUBMIT_PEF_DATA_FAILURE';

export const SUBMIT_FOR_APPROVAL_INIT = 'SUBMIT_FOR_APPROVAL_INIT';
export const SUBMIT_FOR_APPROVAL_SUCCESS = 'SUBMIT_FOR_APPROVAL_SUCCESS';
export const SUBMIT_FOR_APPROVAL_FAILURE = 'SUBMIT_FOR_APPROVAL_FAILURE';

export const APPROVE_PEF_INIT = 'APPROVE_PEF_INIT';
export const APPROVE_PEF_SUCCESS = 'APPROVE_PEF_SUCCESS';
export const APPROVE_PEF_FAILURE = 'APPROVE_PEF_FAILURE';
