import { connect } from 'react-redux';
import { getEmployeeBusinessUnitAction } from 'store/actions/EmployeeActions';

import {
  getDealAction,
  getDealNameAction,
  getDealOwnerAction,
  getDealStagesAction,
  getDealTypeCountAction,
  getClientAction,
  syncDealAction,
  planRunnerAction,
  exportDealAction,
  getDealEventsAction,
} from '../store/slices/Deal';
import DealListing from '../components/deal/dealsListing';

function mapStateToProps(state) {
  const { auth, employeeReducer, dealReducer } = state;
  const { businessUnits } = employeeReducer;

  // const { isLoading } = dealReducer;
  return {
    // isLoading,
    userRole: auth.user,
    list: dealReducer.deal,
    dealNames: dealReducer.dealName,
    dealOwners: dealReducer.dealOwner,
    dealStages: dealReducer.dealStages,
    dealTypeCounts: dealReducer.dealTypeCount,
    clientNames: dealReducer.clients,
    dealEvents: dealReducer.dealEvents,
    businessUnits,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDeal: (req) => dispatch(getDealAction(req)),
    exportDeal: (req) => dispatch(exportDealAction(req)),
    getDealName: (req) => dispatch(getDealNameAction(req)),
    getDealOwner: () => dispatch(getDealOwnerAction()),
    getDealStages: () => dispatch(getDealStagesAction()),
    getDealTypeCount: (req) => dispatch(getDealTypeCountAction(req)),
    getClient: () => dispatch(getClientAction()),
    syncDeal: (dealParams) => dispatch(syncDealAction(dealParams)),
    planRunner: () => dispatch(planRunnerAction()),
    getEmployeeBusinessUnits: () => dispatch(getEmployeeBusinessUnitAction()),
    getDealEvents: () => dispatch(getDealEventsAction()),
  };
}

const DealContainer = connect(mapStateToProps, mapDispatchToProps)(DealListing);

export default DealContainer;
