import { connect } from 'react-redux';
import ClaimDetailView from 'components/expense/ClaimDetailView';
import {
  getClaimsAction,
  claimApprovedAction,
  claimDeclinedAction,
} from 'store/actions/ExpenseClaimActions';

function mapStateToProps(state) {
  const { auth, getClaimReducer, addClaimReducer } = state;
  return {
    user: auth.user,
    myClaims: getClaimReducer.myClaims,
    approvalStatusUpdated: addClaimReducer.approvalStatusUpdated,
    claimProjectList: getClaimReducer.claimProjectList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getClaims: () => {
      dispatch(getClaimsAction());
    },
    claimApproved: (status) => {
      dispatch(claimApprovedAction(status));
    },
    claimDeclined: (status) => {
      dispatch(claimDeclinedAction(status));
    },
  };
}
const ClaimDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(ClaimDetailView);

export default ClaimDetailsContainer;
