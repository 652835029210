import {
  GET_RESOURCES_INIT,
  GET_RESOURCES_SUCCESS,
  GET_RESOURCES_FAILURE,
  GET_RESOURCE_DETAIL_INIT,
  GET_RESOURCE_DETAIL_SUCCESS,
  GET_RESOURCE_DETAIL_FAILURE,
  CLEAR_RESOURCE_DATA,
} from 'constants/ResourceTypes';

import {
  getResourcesWithAllocation,
  getResourcesDetailWithAllocation,
} from 'sources/EmployeeSource';

// normal actions
export const getResourcesInit = () => ({
  type: GET_RESOURCES_INIT,
  loader: true,
});

export const getResourcesSuccess = (data) => ({
  type: GET_RESOURCES_SUCCESS,
  data,
});

export const getResourcesFailure = (data) => ({
  type: GET_RESOURCES_FAILURE,
  data,
});
export const getResourcesActions = (startDate, endDate) =>
  getResourcesWithAllocation(startDate, endDate);

// normal actions
export const getResourceDetailInit = () => ({
  type: GET_RESOURCE_DETAIL_INIT,
  loader: true,
});

export const getResourceDetailSuccess = (data) => ({
  type: GET_RESOURCE_DETAIL_SUCCESS,
  data,
});

export const getResourceDetailFailure = (data) => ({
  type: GET_RESOURCE_DETAIL_FAILURE,
  data,
});
export const clearResourceData = () => ({
  type: CLEAR_RESOURCE_DATA,
  loader: true,
});
export const getResourceDetailActions = (userId, employeeId, startDate, endDate) =>
  getResourcesDetailWithAllocation(userId, employeeId, startDate, endDate);
