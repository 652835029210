import { EnhancedCol, EnhancedCard, EnhancedRow, EnhancedForm } from 'components/shared/antd';
import React from 'react';
import {
  SELECT,
  INFO as stateName,
  DROP_DOWNS,
  ROW_GUTTER_SIZE,
  FULL_GRID_SIZE,
  TEXT,
  NUMBER,
  PASSWORD,
} from 'configs/employeeProfileConstants';
import { REQUIRED } from 'configs/validationConstants';
import { GENDERS } from 'configs/constants';
import { createFields, noData } from 'utils/FieldGenerateUtils';
import {
  calculateBasicSalary,
  calculateMedicalAllowance,
  calculateTotalSalary,
} from 'utils/employeeProfileUtils';
import { isEmptyObject } from 'utils/objectUtils';
import { getValueForKeyinJSON } from 'utils/arrayUtils';

class BasicInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      [stateName]: {},
      errors: {},
      [DROP_DOWNS]: {},
      isEdit: false,
      disableSaveButton: false,
    };
  }

  componentDidMount() {
    this.initializeValues(this.props[stateName]);
    this.initializeDropDowns(this.props[DROP_DOWNS]);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps[stateName] !== this.props[stateName]) {
      this.initializeValues(nextProps[stateName]);
    }
  }

  initializeValues = (props) => this.setState({ [stateName]: props });
  initializeDropDowns = (props) => this.setState({ dropDowns: { ...props } });

  handleChange = (value, key, error) => {
    const currentData = this.state[stateName];
    const newData = Object.assign({}, currentData, { [key]: value });
    // Update specific error object and field
    let { errors } = this.state;
    errors = Object.assign({}, { ...errors }, { [key]: error });
    // Check to see if there is any error
    // if any, disable save button
    // if none, enable save button
    const allValid = Object.values(errors).every((valueError) => valueError === false);
    this.setState({ [stateName]: newData, disableSaveButton: !allValid, errors });
    if (error) {
      this.setState({ disableSaveButton: true });
    }
  };

  render() {
    const { isLoading } = this.props;
    const {
      userPermissions,
      cardLoading,
      businessUnits,
      competencies,
      employmentTypes,
      locations,
    } = this.props;
    const { isEdit } = this.state;
    const data = this.state[stateName];
    // const dropDowns = this.props[DROP_DOWNS];

    return (
      <div className="mt-20 pos-rel">
        {!isEmptyObject(data) ? (
          <EnhancedRow gutter={ROW_GUTTER_SIZE}>
            <EnhancedCol span={FULL_GRID_SIZE}>
              <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
                loading={cardLoading && isLoading}
                bordered={false}
                className="card-pd-0"
              >
                <div>
                  <EnhancedForm layout="inline" onSubmit={this.handleSave}>
                    {createFields(
                      [
                        {
                          key: 'oracleId',
                          label: 'Emp ID',
                          value: data.oracleId,
                          type: TEXT,
                        },
                        {
                          key: 'email',
                          label: 'Email Address',
                          value: data.email,
                          type: TEXT,
                        },
                        {
                          key: 'firstName',
                          label: 'First Name',
                          value: data.firstName,
                          type: TEXT,
                        },
                        {
                          key: 'middleName',
                          label: 'Middle Name',
                          value: data.middleName,
                          type: TEXT,
                        },
                        {
                          key: 'lastName',
                          label: 'Last Name',
                          value: data.lastName,
                          type: TEXT,
                        },
                        {
                          key: 'genderId',
                          label: 'Gender',
                          options: GENDERS,
                          value: getValueForKeyinJSON(GENDERS, data.genderId),
                          type: SELECT,
                          validations: [REQUIRED],
                        },
                        {
                          key: 'joiningDate',
                          label: 'Date Of Joining',
                          value: data.joiningDate,
                          type: TEXT,
                        },
                        {
                          key: 'externalExpYears',
                          label: 'External Experience in Years',
                          value: data.externalExpYears,
                          type: NUMBER,
                          min: 0,
                        },
                        {
                          key: 'externalExpMonths',
                          label: 'External Experience in Months',
                          value: data.externalExpMonths,
                          type: NUMBER,
                          min: 0,
                        },
                        {
                          key: 'experience',
                          label: 'Total Experience',
                          value: data.experience.totalInWords,
                          type: TEXT,
                        },
                        {
                          key: 'designationName',
                          label: 'Job Title',
                          value: data.designationName,
                          type: TEXT,
                        },
                        {
                          key: 'grade',
                          label: 'Grade',
                          value: data.grade,
                          type: TEXT,
                        },
                        {
                          key: 'subGrade',
                          label: 'Sub Grade',
                          value: data.subGrade,
                          type: TEXT,
                        },
                        {
                          key: 'employmentStatusId',
                          label: 'Employment Status',
                          value: data.employmentStatus.name,
                          type: TEXT,
                        },
                        {
                          key: 'businessUnitId',
                          label: 'Business Unit',
                          options: businessUnits,
                          value: getValueForKeyinJSON(businessUnits, data.businessUnitId),
                          type: SELECT,
                          validations: [REQUIRED],
                        },
                        {
                          key: 'competencyId',
                          label: 'Competency',
                          options: competencies,
                          value: getValueForKeyinJSON(competencies, data.competencyId),
                          type: SELECT,
                          validations: [REQUIRED],
                        },
                        {
                          key: 'divisionName',
                          label: 'Division',
                          value: data.divisionName,
                          type: TEXT,
                        },
                        {
                          key: 'departmentName',
                          label: 'Department',
                          value: data.departmentName,
                          type: TEXT,
                        },
                        {
                          key: 'managerName',
                          label: 'Line Manager',
                          value: data.managerName,
                          type: TEXT,
                        },
                        {
                          key: 'employmentTypeId',
                          label: 'Employment Type:',
                          options: employmentTypes,
                          value: getValueForKeyinJSON(employmentTypes, data.employmentTypeId),
                          type: SELECT,
                        },
                        {
                          key: 'location',
                          label: 'Location',
                          options: locations,
                          value: getValueForKeyinJSON(locations, data.location),
                          type: SELECT,
                          validations: [REQUIRED],
                        },
                        {
                          key: 'basicSalary',
                          label: 'Basic Salary',
                          value: calculateBasicSalary(data.grossSalary),
                          type: PASSWORD,
                        },
                        {
                          key: 'medicalAllowance',
                          label: 'Medical Allowance',
                          value: calculateMedicalAllowance(data.grossSalary),
                          type: PASSWORD,
                        },
                        {
                          key: 'grossSalary',
                          label: 'Gross Salary',
                          value: data.grossSalary,
                          type: PASSWORD,
                        },
                        {
                          key: 'inflationSupport',
                          label: 'Inflation Support',
                          value: data.inflationSupport,
                          type: PASSWORD,
                        },
                        {
                          key: 'totalSalary',
                          label: 'Total Salary',
                          value: calculateTotalSalary(data.grossSalary, data.inflationSupport),
                          type: PASSWORD,
                        },
                        {
                          key: 'fuelAllowance',
                          label: 'Fuel Allowance',
                          value: data.fuelAllowance,
                          type: PASSWORD,
                        },
                      ],
                      2,
                      isEdit,
                      userPermissions,
                      this.handleChange,
                    )}
                  </EnhancedForm>
                </div>
              </EnhancedCard>
            </EnhancedCol>
          </EnhancedRow>
        ) : (
          noData()
        )}
      </div>
    );
  }
}

export default BasicInfo;
