import React from 'react';
import { Card } from 'antd';
import PropTypes from 'prop-types';

const EnhancedCard = (props) => {
  const { children, className, style, bordered, loading, title, extra, hoverable, bodyStyle, key } =
    props;

  return (
    <Card
      className={className}
      style={style}
      bordered={bordered}
      loading={loading}
      title={title}
      extra={extra}
      hoverable={hoverable}
      bodyStyle={bodyStyle}
      key={key}
    >
      {children}
    </Card>
  );
};

EnhancedCard.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  bordered: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  extra: PropTypes.string.isRequired,
  hoverable: PropTypes.bool,
  bodyStyle: PropTypes.shape({}),
  key: PropTypes.string.isRequired,
};

EnhancedCard.defaultProps = {
  className: '',
  style: {},
  bodyStyle: {},
  bordered: true,
  hoverable: false,
  loading: false,
};

export default EnhancedCard;
