/* eslint-disable react/jsx-closing-tag-location */
import {
  EnhancedCol,
  EnhancedRow,
  EnhancedButton,
  EnhancedCard,
  EnhancedSpin,
  EnhancedAlert,
  EnhancedLayout,
  EnhancedSelect,
  EnhancedDatePicker,
  EnhancedInput,
  EnhancedInputNumber,
} from 'components/shared/antd';
import React, { Component } from 'react';
import { EMAIL_REGEX, skillLevel, GENDERS, RESOURCETYPE, dateFormat } from 'configs/constants';

import RequiredField from 'components/RequiredField';

const { Header } = EnhancedLayout;
const { Option } = EnhancedSelect;

class AddEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      information: {
        firstName: '',
        lastName: '',
        designationId: undefined,
        managerId: undefined,
        email: '',
        skype: '',
        phoneNumber: '',
        dateOfBirth: undefined,
        joiningDate: undefined,
        primarySkills: [],
        secondarySkills: [],
        location: undefined,
        departmentId: undefined,
        competency: undefined,
        genderId: undefined,
        resourceType: undefined,
        oracleId: '',
        businessUnitId: undefined,
        divisionId: undefined,
        competencyId: undefined,
        externalExpYears: '',
        externalExpMonths: '',
      },
      shouldRedirect: false,
      isSaveButtonLoad: false,
      isAddAnotherEmpLoad: false,
    };
  }

  componentDidMount() {
    this.props.getEmployeesSkillsActions();
    this.props.getEmployeeDepartments();
    this.props.getBusinessUnits();
    this.props.getDivisions();
    this.props.getAllLocations();
    this.props.getAllDesignations();
    this.props.getCompetencies();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isSuccessfull && !nextProps.isLoading) {
      this.clear();
      if (this.state.shouldRedirect) {
        this.props.history.push('current');
      }
    }
  }

  componentWillUnmount() {
    this.props.resetAddEmployee();
  }

  onFirstNameChange = (event) => {
    this.setState({
      information: {
        ...this.state.information,
        firstName: event.target.value,
      },
    });
  };
  onLastNameChange = (event) => {
    this.setState({
      information: {
        ...this.state.information,
        lastName: event.target.value,
      },
    });
  };
  onDesignationChange = (value) => {
    this.setState({
      information: {
        ...this.state.information,
        designationId: value,
      },
    });
  };

  onManagerChange = (managerValue) => {
    this.setState({
      information: {
        ...this.state.information,
        managerId: managerValue,
      },
    });
  };

  onResourceTypeChange = (typeValue) => {
    this.setState({
      information: {
        ...this.state.information,
        resourceType: typeValue,
      },
    });
  };

  onOracleIdChange = (event) => {
    this.setState({
      information: {
        ...this.state.information,
        oracleId: event.target.value,
      },
    });
  };

  onEmailChange = (event) => {
    this.setState({
      information: {
        ...this.state.information,
        email: event.target.value,
      },
    });
  };
  onSkypeChange = (event) => {
    this.setState({
      information: {
        ...this.state.information,
        skype: event.target.value,
      },
    });
  };
  onPhoneNumberChange = (event) => {
    this.setState({
      information: {
        ...this.state.information,
        phoneNumber: event.target.value,
      },
    });
  };
  onDateOfBirthChange = (date) => {
    this.setState({
      information: {
        ...this.state.information,
        dateOfBirth: date,
      },
    });
  };
  onJoiningDateChange = (date) => {
    this.setState({
      information: {
        ...this.state.information,
        joiningDate: date,
      },
    });
  };

  onPrimarySkillsChange = (value) => {
    const information = { ...this.state.information };
    information.primarySkills = value;
    this.setState({
      information,
    });
  };

  onSecondarySkillsChange = (value) => {
    const information = { ...this.state.information };
    information.secondarySkills = value;
    this.setState({
      information,
    });
  };
  onLocationChange = (value) => {
    this.setState({
      information: {
        ...this.state.information,
        location: value,
      },
    });
  };

  onDepartmentChange = (value) => {
    this.setState({
      information: {
        ...this.state.information,
        departmentId: value,
      },
    });
  };
  onBusinessUnitChange = (value) => {
    this.setState({
      information: {
        ...this.state.information,
        businessUnitId: value,
      },
    });
  };
  onDivisionChange = (value) => {
    this.setState({
      information: {
        ...this.state.information,
        divisionId: value,
      },
    });
  };
  onCompetencyChange = (value) => {
    this.setState({
      information: {
        ...this.state.information,
        competencyId: value,
      },
    });
  };
  onGenderChange = (value) => {
    this.setState({
      information: {
        ...this.state.information,
        genderId: value,
      },
    });
  };

  onChangeHandler = (name, value) => {
    this.setState({
      information: {
        ...this.state.information,
        [name]: value,
      },
    });
  };

  onSaveButtonClick = () => {
    this.setState({ shouldRedirect: true, isAddAnotherEmpLoad: false, isSaveButtonLoad: true });
    this.handleAddEmployee();
  };

  onSaveAndAnotherEmp = () => {
    this.setState({ shouldRedirect: false, isAddAnotherEmpLoad: true, isSaveButtonLoad: false });
    this.handleAddEmployee();
  };
  handleAddEmployee = () => {
    const { skills } = this.props || [];
    const primarySkills = this.state.information.primarySkills.map((skill) => {
      const foundSkill = skills.find((s) => s.name === skill);
      return {
        id: foundSkill.id,
        expertise: skillLevel.EXPERT,
      };
    });
    const secondarySkills = this.state.information.secondarySkills.map((skill) => {
      const foundSkill = skills.find((s) => s.name === skill);
      return {
        id: foundSkill.id,
        expertise: skillLevel.BEGINNER,
      };
    });
    const Skills = [...primarySkills, ...secondarySkills];

    const stateClone = Object.assign({}, this.state.information);
    stateClone.skills = Skills;

    delete stateClone.primarySkills;
    delete stateClone.secondarySkills;
    this.props.addEmployee(stateClone, this.props.userId);
  };

  shouldButtonDisable = () => {
    const { information } = this.state;
    if (
      information.firstName &&
      information.lastName &&
      information.designationId &&
      information.managerId &&
      information.phoneNumber &&
      information.location &&
      information.genderId !== undefined &&
      information.departmentId &&
      information.competencyId &&
      information.joiningDate &&
      information.dateOfBirth &&
      information.email &&
      information.businessUnitId &&
      information.divisionId &&
      information.externalExpYears !== '' &&
      information.externalExpMonths !== '' &&
      information.resourceType &&
      EMAIL_REGEX.test(information.email.toLowerCase())
    ) {
      return false;
    }
    return true;
  };

  clear = () => {
    this.setState({
      information: {
        dateOfBirth: undefined,
        joiningDate: undefined,
        genderId: undefined,
        designationId: undefined,
        location: undefined,
        primarySkills: [],
        secondarySkills: [],
        resourceType: undefined,
        oracleId: '',
      },
    });
    this.props.resetAddEmployee();
  };

  render() {
    const { information, errorText } = this.state;
    if (errorText) return null;
    const { error } = this.props;
    const resourceType = RESOURCETYPE.map((currElement) => (
      <Option key={currElement}>{currElement}</Option>
    ));
    const { employees } = this.props;
    let skillList = this.props.skills || [];
    skillList = this.state.information.primarySkills
      ? skillList.filter((element) => !this.state.information.primarySkills.includes(element.name))
      : [];
    skillList = this.state.information.secondarySkills
      ? skillList.filter(
          (element) => !this.state.information.secondarySkills.includes(element.name),
        )
      : [];
    const skills = skillList.map((currElement) => (
      <Option key={currElement.name} value={currElement.name}>
        {currElement.name}
      </Option>
    ));
    const departmentList = this.props.departments || [];
    const departments = departmentList.map((department) => (
      <Option key={department.id}>{department.name}</Option>
    ));
    const designationList = this.props.designations || [];
    const designations = designationList.map((designation) => (
      <Option key={designation.id}>{designation.name}</Option>
    ));
    const businessUnitList = this.props.businessUnits || [];
    const businessUnits = businessUnitList.map((businessUnit) => (
      <Option key={businessUnit.id}>{businessUnit.name}</Option>
    ));
    const divisionList = this.props.divisions || [];
    const divisions = divisionList.map((division) => (
      <Option key={division.id}>{division.name}</Option>
    ));
    const locationsList = this.props.locations || [];
    const locations = locationsList.map((location) => (
      <Option key={location.id}>{location.name}</Option>
    ));
    const competencyList = this.props.competencies || [];
    const competencies = competencyList.map((competency) => (
      <Option key={competency.id}>{competency.name}</Option>
    ));
    const genderList = GENDERS.map((currElement) => (
      <Option title={currElement.value} value={currElement.key} key={currElement.key}>
        {currElement.value}{' '}
      </Option>
    ));
    return (
      <div>
        <Header className="project-header" style={{ marginBottom: 3 }}>
          <EnhancedRow>
            <EnhancedCol lg={12}>
              <h1>Add New Employee</h1>
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        <EnhancedCard bordered={false}>
          {error ? (
            <EnhancedAlert
              message={error}
              type="error"
              showIcon
              style={{
                marginBottom: '20px',
              }}
            />
          ) : null}
          <EnhancedRow>
            <EnhancedCol lg={24}>
              <EnhancedRow className="marginBottom12">
                <EnhancedCol lg={11}>
                  <EnhancedCol lg={7}>
                    <RequiredField> First Name </RequiredField>
                  </EnhancedCol>
                  <EnhancedCol lg={17}>
                    <EnhancedInput
                      value={information.firstName}
                      onChange={this.onFirstNameChange}
                      placeholder="Enter First Name"
                    />
                  </EnhancedCol>
                </EnhancedCol>
                <EnhancedCol lg={13}>
                  <EnhancedCol lg={7}>
                    <span className="marginLeft12">
                      <RequiredField>Last Name</RequiredField>
                    </span>
                  </EnhancedCol>
                  <EnhancedCol lg={17}>
                    <EnhancedInput
                      value={information.lastName}
                      onChange={this.onLastNameChange}
                      placeholder="Enter Last Name"
                    />
                  </EnhancedCol>
                </EnhancedCol>
              </EnhancedRow>
              <EnhancedRow className="marginBottom12">
                <EnhancedCol lg={11}>
                  <EnhancedCol lg={7}>
                    <span>
                      <RequiredField>Resource Type</RequiredField>
                    </span>
                  </EnhancedCol>
                  <EnhancedCol lg={17}>
                    <EnhancedSelect
                      mode="default"
                      style={{ width: '100%' }}
                      value={information.resourceType}
                      placeholder="Select Resource Type"
                      onChange={this.onResourceTypeChange}
                    >
                      {resourceType}
                    </EnhancedSelect>
                  </EnhancedCol>
                </EnhancedCol>
                <EnhancedCol lg={13}>
                  <EnhancedCol lg={7}>
                    <span className="marginLeft12">
                      <RequiredField>Business Unit</RequiredField>
                    </span>
                  </EnhancedCol>
                  <EnhancedCol lg={17}>
                    <EnhancedSelect
                      optionFilterProp="children"
                      showSearch
                      mode="default"
                      style={{ width: '100%' }}
                      placeholder="Select Business Unit"
                      value={this.state.information.businessUnitId}
                      onChange={this.onBusinessUnitChange}
                    >
                      {businessUnits}
                    </EnhancedSelect>
                  </EnhancedCol>
                </EnhancedCol>
              </EnhancedRow>
              <EnhancedRow className="marginBottom12">
                {/* <Col lg={11}>
                  <Col lg={7}>
                    <RequiredField>
                      Designation
                    </RequiredField>
                  </Col>
                  <Col lg={17}>
                    <EnhancedInput
                      value={information.designation}
                      onChange={this.onDesignationChange}
                      placeholder="Enter Designation"
                    />
                  </Col>
                </Col> */}
                <EnhancedCol lg={11}>
                  <EnhancedCol lg={7}>
                    <span>
                      <RequiredField>Designation</RequiredField>
                    </span>
                  </EnhancedCol>
                  <EnhancedCol lg={17}>
                    <EnhancedSelect
                      optionFilterProp="children"
                      showSearch
                      mode="default"
                      style={{ width: '100%' }}
                      value={information.designationId}
                      placeholder="Select Designation"
                      onChange={this.onDesignationChange}
                    >
                      {designations}
                    </EnhancedSelect>
                  </EnhancedCol>
                </EnhancedCol>
                <EnhancedCol lg={13}>
                  <EnhancedCol lg={7}>
                    <span className="marginLeft12">
                      <RequiredField>Division</RequiredField>
                    </span>
                  </EnhancedCol>
                  <EnhancedCol lg={17}>
                    <EnhancedSelect
                      showSearch
                      mode="default"
                      style={{ width: '100%' }}
                      optionFilterProp="children"
                      placeholder="Select Division"
                      value={this.state.information.divisionId}
                      onChange={this.onDivisionChange}
                    >
                      {divisions}
                    </EnhancedSelect>
                  </EnhancedCol>
                </EnhancedCol>
              </EnhancedRow>
              <EnhancedRow className="marginBottom12">
                <EnhancedCol lg={11}>
                  <EnhancedCol lg={7}>
                    <span>
                      <RequiredField>Oracle Id</RequiredField>
                    </span>
                  </EnhancedCol>
                  <EnhancedCol lg={17}>
                    <EnhancedInput
                      type="text"
                      value={information.oracleId}
                      placeholder="Enter Employee Id"
                      onChange={this.onOracleIdChange}
                    />
                  </EnhancedCol>
                </EnhancedCol>
                <EnhancedCol lg={13}>
                  <EnhancedCol lg={7}>
                    <span className="marginLeft12">
                      <RequiredField>Competency</RequiredField>
                    </span>
                  </EnhancedCol>
                  <EnhancedCol lg={17}>
                    <EnhancedSelect
                      optionFilterProp="children"
                      showSearch
                      mode="default"
                      style={{ width: '100%' }}
                      placeholder="Select Competency"
                      value={this.state.information.competencyId}
                      onChange={this.onCompetencyChange}
                    >
                      {competencies}
                    </EnhancedSelect>
                  </EnhancedCol>
                </EnhancedCol>
              </EnhancedRow>
              <EnhancedRow className="marginBottom12">
                <EnhancedCol lg={11}>
                  <EnhancedCol lg={7}>
                    <RequiredField>Email</RequiredField>
                  </EnhancedCol>
                  <EnhancedCol lg={17}>
                    <EnhancedInput
                      value={information.email}
                      onChange={this.onEmailChange}
                      type="email"
                      placeholder="Enter Email Id"
                    />
                  </EnhancedCol>
                </EnhancedCol>

                <EnhancedCol lg={13}>
                  <EnhancedCol lg={7}>
                    <span className="marginLeft12">
                      <RequiredField>Department</RequiredField>
                    </span>
                  </EnhancedCol>
                  <EnhancedCol lg={17}>
                    <EnhancedSelect
                      optionFilterProp="children"
                      showSearch
                      mode="default"
                      style={{ width: '100%' }}
                      placeholder="Select Department"
                      value={this.state.information.departmentId}
                      onChange={this.onDepartmentChange}
                    >
                      {departments}
                    </EnhancedSelect>
                  </EnhancedCol>
                </EnhancedCol>
              </EnhancedRow>

              <EnhancedRow className="marginBottom12">
                <EnhancedCol lg={11}>
                  <EnhancedCol lg={7}>
                    <RequiredField>Mobile Number</RequiredField>
                  </EnhancedCol>
                  <EnhancedCol lg={17}>
                    <EnhancedInput
                      value={information.phoneNumber}
                      onChange={this.onPhoneNumberChange}
                      type="number"
                      placeholder="Enter Mobile Number"
                    />
                  </EnhancedCol>
                </EnhancedCol>

                <EnhancedCol lg={13}>
                  <EnhancedCol lg={7}>
                    <span className="marginLeft12">
                      <RequiredField>Gender</RequiredField>
                    </span>
                  </EnhancedCol>
                  <EnhancedCol lg={17}>
                    <EnhancedSelect
                      mode="default"
                      style={{ width: '100%' }}
                      placeholder="Select Gender"
                      value={information.genderId}
                      onChange={this.onGenderChange}
                    >
                      {genderList}
                    </EnhancedSelect>
                  </EnhancedCol>
                </EnhancedCol>
              </EnhancedRow>

              <EnhancedRow className="marginBottom12">
                <EnhancedCol lg={11}>
                  <EnhancedCol lg={7}>
                    <RequiredField>Date of Birth</RequiredField>
                  </EnhancedCol>
                  <EnhancedCol lg={17}>
                    <EnhancedDatePicker
                      value={information.dateOfBirth}
                      format={dateFormat.DDMMMYYYYwithSpace}
                      style={{ width: '100%' }}
                      onChange={this.onDateOfBirthChange}
                      allowClear
                    />
                  </EnhancedCol>
                </EnhancedCol>

                <EnhancedCol lg={13}>
                  <EnhancedCol lg={7}>
                    <span className="marginLeft12">
                      <RequiredField>Joining Date</RequiredField>
                    </span>
                  </EnhancedCol>
                  <EnhancedCol lg={17}>
                    <EnhancedDatePicker
                      value={information.joiningDate}
                      format={dateFormat.DDMMMYYYYwithSpace}
                      style={{ width: '100%' }}
                      onChange={this.onJoiningDateChange}
                      allowClear
                    />
                  </EnhancedCol>
                </EnhancedCol>
              </EnhancedRow>

              <EnhancedRow className="marginBottom12">
                <EnhancedCol lg={11}>
                  <EnhancedCol lg={7}>
                    <RequiredField>Location</RequiredField>
                  </EnhancedCol>
                  <EnhancedCol lg={17}>
                    <EnhancedSelect
                      mode="default"
                      onChange={this.onLocationChange}
                      placeholder="Select Location"
                      style={{ width: '100%' }}
                      value={this.state.information.location}
                    >
                      {locations}
                    </EnhancedSelect>
                  </EnhancedCol>
                </EnhancedCol>
                <EnhancedCol lg={13}>
                  <EnhancedCol lg={7}>
                    <span className="marginLeft12">
                      <RequiredField>Manager</RequiredField>
                    </span>
                  </EnhancedCol>
                  <EnhancedCol lg={17}>
                    <EnhancedSelect
                      showSearch
                      placeholder="Select Manager"
                      style={{ width: '100%' }}
                      optionFilterProp="children"
                      allowClear
                      notFoundContent={this.props.isLoading ? <EnhancedSpin size="small" /> : null}
                      value={information.managerId}
                      onChange={this.onManagerChange}
                    >
                      {employees && employees.length
                        ? employees.map(
                            (employee) =>
                              employee.id && (
                                <Option key={employee.id} value={employee.id.toString()}>
                                  {`${employee.firstName} ${employee.lastName}`}
                                </Option>
                              ),
                          )
                        : null}
                    </EnhancedSelect>
                  </EnhancedCol>
                </EnhancedCol>
              </EnhancedRow>
              <EnhancedRow className="marginBottom12">
                <EnhancedCol lg={11}>
                  <EnhancedCol lg={7}>Primary Skills</EnhancedCol>
                  <EnhancedCol lg={17}>
                    <EnhancedSelect
                      mode="multiple"
                      style={{ width: '100%' }}
                      placeholder="Select Primary Skills"
                      value={this.state.information.primarySkills}
                      onChange={this.onPrimarySkillsChange}
                    >
                      {skills}
                    </EnhancedSelect>
                  </EnhancedCol>
                </EnhancedCol>
                <EnhancedCol lg={13}>
                  <EnhancedCol lg={7}>
                    <span className="marginLeft12">Secondary Skills</span>
                  </EnhancedCol>
                  <EnhancedCol lg={17}>
                    <EnhancedSelect
                      mode="multiple"
                      style={{ width: '100%' }}
                      placeholder="Select Secondary Skills"
                      value={this.state.information.secondarySkills}
                      onChange={this.onSecondarySkillsChange}
                    >
                      {skills}
                    </EnhancedSelect>
                  </EnhancedCol>
                </EnhancedCol>
              </EnhancedRow>
              <EnhancedRow className="marginBottom12">
                <EnhancedCol lg={11}>
                  <EnhancedCol lg={7}>
                    <span>Skype</span>
                  </EnhancedCol>
                  <EnhancedCol lg={17}>
                    <EnhancedInput
                      value={information.skype}
                      onChange={this.onSkypeChange}
                      placeholder="Enter Skype Id"
                    />
                  </EnhancedCol>
                </EnhancedCol>
                <EnhancedCol lg={13}>
                  <EnhancedCol lg={7}>
                    <RequiredField>
                      <span className="marginLeft12">External Experience</span>
                    </RequiredField>
                  </EnhancedCol>
                  <EnhancedCol lg={17}>
                    <EnhancedInputNumber
                      placeholder="Select years"
                      style={{ width: '50%' }}
                      name="externalExpYears"
                      value={this.state.information.externalExpYears}
                      min={0}
                      max={99}
                      // eslint-disable-next-line arrow-parens
                      onChange={(val) => this.onChangeHandler('externalExpYears', val)}
                    />
                    <EnhancedInputNumber
                      placeholder="Select months"
                      style={{ width: '50%' }}
                      name="externalExpMonths"
                      value={this.state.information.externalExpMonths}
                      min={0}
                      max={12}
                      // eslint-disable-next-line arrow-parens
                      onChange={(val) => this.onChangeHandler('externalExpMonths', val)}
                    />
                  </EnhancedCol>
                </EnhancedCol>
              </EnhancedRow>
            </EnhancedCol>
            <EnhancedCol lg={24} style={{ textAlign: 'right' }}>
              <EnhancedButton onClick={this.clear} style={{ marginRight: '10px' }}>
                Clear
              </EnhancedButton>
              <EnhancedButton
                disabled={this.shouldButtonDisable()}
                type="primary"
                onClick={this.onSaveButtonClick}
                loading={this.props.isLoading && this.state.isSaveButtonLoad}
                style={{ marginRight: '10px' }}
              >
                Save
              </EnhancedButton>
              <EnhancedButton
                disabled={this.shouldButtonDisable()}
                type="primary"
                onClick={this.onSaveAndAnotherEmp}
                loading={this.props.isLoading && this.state.isAddAnotherEmpLoad}
              >
                Save and Add Another
              </EnhancedButton>
            </EnhancedCol>
          </EnhancedRow>
        </EnhancedCard>
      </div>
    );
  }
}

export default AddEmployee;
