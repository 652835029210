import React from 'react';
import { Rate } from 'antd';
import PropTypes from 'prop-types';

const EnhancedRate = (props) => {
  const { children, className, style, tooltips, ...rest } = props;

  return (
    <Rate style={style} className={className} tooltips={tooltips} {...rest}>
      {children}
    </Rate>
  );
};

EnhancedRate.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  tooltips: PropTypes.arrayOf('string'),
};

EnhancedRate.defaultProps = {
  className: '',
  style: {},
  tooltips: [],
};

export default EnhancedRate;
