import {
  EnhancedButton,
  EnhancedCol,
  EnhancedRow,
  EnhancedCard,
  EnhancedAffix,
  EnhancedModal,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import { BREADCRUMBS } from 'configs/constants';
import { reviewTypes } from 'configs/employeeProfileConstants';
import evaluationStatus from 'configs/performanceManagementConstants';
import PerformanceEvaluationFormHeader from 'components/shared/performanceEvaluationForm/header';
import calculateOverallRating, {
  checkEvaluationStatus,
  validateRatingsSubmission,
} from 'utils/performanceManagementUtils';
import CompetenciesStep from 'components/performanceEvaluationForm/steps/competenciesStep';
import EmployeeInformation from 'components/performanceEvaluationForm/steps/employeeInformationStep';
import EndNoteStep from 'components/performanceEvaluationForm/steps/endNoteStep';
import GoalsSteps from 'components/performanceEvaluationForm/steps/goalsStep';
import TrainingAssessment from 'components/performanceEvaluationForm/steps/trainingAssessmentStep';
import StepsComponent from 'components/performanceEvaluationForm/stepsComponent';
import ValidationErrors from '../../shared/performanceEvaluationForm/ValidationErrors';

const { confirm } = EnhancedModal;

const PerformanceEvaluationForm = (props) => {
  const [step, setStep] = useState(0);
  const {
    getPEFDataActions,
    submitPEFDataActions,
    pefData,
    getPMCycleDetail,
    pmCycleDetail,
    updateBreadCrumb,
    ratingOptions,
    getRatingOptions,
  } = props;
  const [pef, setPef] = useState({});
  const [emptyRatings, setEmptyRatings] = useState({
    competencies: [],
    goals: [],
    commentLength: null,
    hasError: false,
  });

  const reviewType = new URLSearchParams(props.location.search).get('type');
  const { performanceCycleId } = props.match.params;
  const [viewer] = useState(reviewType === reviewTypes.SELF ? 'Employee' : 'Manager');

  const steps = [
    {
      key: 0,
      title: 'Get Started',
      component: EmployeeInformation,
      dataKey: 'employeeInfo',
    },
    {
      key: 1,
      title: 'Competencies',
      component: CompetenciesStep,
      dataKey: 'competencies',
    },
    {
      key: 2,
      title: 'Goals',
      component: GoalsSteps,
      dataKey: 'goals',
    },
    {
      key: 3,
      title: 'Training',
      component: TrainingAssessment,
      dataKey: 'trainingInfo',
    },
    {
      key: 4,
      title: 'End Notes',
      component: EndNoteStep,
      dataKey: 'overallComment',
    },
  ];

  const updateDataFromComponent = (key, value) => {
    pef[key] = value;

    pef.overallRating = calculateOverallRating(pef.goals, pef.competencies);

    setPef({ ...pef });
  };

  useEffect(() => {
    getPEFDataActions({ reviewType, performanceCycleId });
    getPMCycleDetail(performanceCycleId);
    getRatingOptions();
  }, []);

  useEffect(() => {
    if (pmCycleDetail) {
      updateBreadCrumb(BREADCRUMBS.PERFORMANCE_MANAGEMENT, performanceCycleId, pmCycleDetail.title);
    }
  }, [pmCycleDetail]);

  useEffect(() => {
    setPef(pefData);
    // if (pefData.employeeInfo) {
    //   updateBreadCrumb(BREADCRUMBS.PERFORMANCE_MANAGEMENT_EMPLOYEE, empId, pefData.employeeInfo.name);
    // }
  }, [pefData]);

  const isEditable = () => {
    let editingAllowed = false;

    const isPending = checkEvaluationStatus(pef, evaluationStatus.PENDING);

    // employee cannot edit manager review in any case
    if (reviewType === reviewTypes.SELF && isPending) {
      editingAllowed = true;
    }

    return editingAllowed;
  };

  const renderSwitch = () => {
    const stepObj = steps.find((item) => item.key === step);

    const Component = stepObj.component;

    return (
      <Component
        viewer={viewer}
        isEditable={isEditable()}
        data={pef[stepObj.dataKey] || {}}
        updateHandler={updateDataFromComponent}
        designations={stepObj.dataKey === 'promotionInfo' ? [] : null}
        allEmployees={stepObj.dataKey === 'trainingInfo' ? [] : null}
        reviewType={reviewType}
        ratingOptions={ratingOptions}
        pefData={pefData}
        emptyRatings={emptyRatings}
        setEmptyRatings={setEmptyRatings}
      />
    );
  };

  const handleSubmit = () => {
    const unmarkedRatingsObj = validateRatingsSubmission(
      pef.goals,
      pef.competencies,
      pef.overallComment,
    );
    setEmptyRatings(unmarkedRatingsObj);

    if (!unmarkedRatingsObj.hasError) {
      const payload = {
        goals: pef.goals,
        competencies: pef.competencies,
        pcRatingId: pef.pcRatingId,
        finalComment: pef.overallComment,
        freeze: true,
        trainingInfo: pef.trainingInfo,
      };

      confirm({
        title: 'Are you sure you want to submit your evaluation?',
        okText: 'Yes',
        okType: 'success',
        cancelText: 'No',
        onOk() {
          submitPEFDataActions({ reviewType, performanceCycleId, payload });
        },
      });
    }
  };

  const handleSave = () => {
    const payload = {
      goals: pef.goals,
      competencies: pef.competencies,
      pcRatingId: pef.pcRatingId,
      finalComment: pef.overallComment,
      trainingInfo: pef.trainingInfo,
    };

    submitPEFDataActions({ reviewType, performanceCycleId, payload });
  };

  const renderStepsButtons = () => {
    return (
      <EnhancedRow className="mb-20 mr-16">
        <EnhancedCol md={14} lg={16}>
          {isEditable() && (
            <EnhancedButton
              onClick={() => {
                handleSave();
              }}
              className="ml-10 mr-10"
            >
              Save Progress
            </EnhancedButton>
          )}

          {step === 4 && isEditable() && (
            <EnhancedButton
              // disabled={!enableSubmitButton()}
              onClick={() => {
                handleSubmit();
              }}
              className="mr-10"
            >
              Submit
            </EnhancedButton>
          )}
        </EnhancedCol>
        <EnhancedCol md={10} lg={8} className="text-right">
          {step > 0 && (
            <EnhancedButton
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Previous Step
            </EnhancedButton>
          )}

          {step < 4 && (
            <EnhancedButton
              onClick={() => {
                setStep(step + 1);
              }}
              className="ml-10"
            >
              Next Step
            </EnhancedButton>
          )}
        </EnhancedCol>
      </EnhancedRow>
    );
  };

  return (
    <div>
      <EnhancedCard>
        <React.Fragment>
          <PerformanceEvaluationFormHeader
            viewer={viewer}
            pef={pef}
            reviewType={reviewType}
            ratingOptions={ratingOptions}
          />
          <EnhancedAffix offsetTop={165}>
            {renderStepsButtons()}
            <ValidationErrors emptyRatings={emptyRatings} reviewType={reviewType} />
          </EnhancedAffix>
          <StepsComponent currentStep={step} steps={steps} />
          {renderSwitch()}
        </React.Fragment>
      </EnhancedCard>
    </div>
  );
};
export default PerformanceEvaluationForm;
