import { getPublicHolidaysSource } from 'sources/LeavesSource';
import {
  GET_PUBLIC_HOLIDAYS,
  GET_PUBLIC_HOLIDAYS_SUCCESS,
  GET_PUBLIC_HOLIDAYS_FAILURE,
} from 'constants/PublicHolidays';

export const getPublicHolidays = () => ({
  type: GET_PUBLIC_HOLIDAYS,
});

export const getPublicHolidaysSuccess = (data) => ({
  type: GET_PUBLIC_HOLIDAYS_SUCCESS,
  data,
});

export const getPublicHolidaysFailure = (data) => ({
  type: GET_PUBLIC_HOLIDAYS_FAILURE,
  data,
});

export const getPublicHolidaysAction = () => getPublicHolidaysSource();
