import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';

const EnhancedLayout = (props) => {
  const { children, className, style, ...rest } = props;
  return (
    <Layout
      className={className}
      style={style}
      {...rest} // this is defined for getting default props and behaviours
    >
      {children}
    </Layout>
  );
};

EnhancedLayout.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

EnhancedLayout.defaultProps = {
  className: '',
  style: {},
};

EnhancedLayout.Header = Layout.Header;
EnhancedLayout.Content = Layout.Content;
EnhancedLayout.Footer = Layout.Footer;
EnhancedLayout.Sider = Layout.Sider;

export default EnhancedLayout;
