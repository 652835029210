import { connect } from 'react-redux';

import PerformanceCycle from 'components/pc/PerformanceCycle';

import { getPerformanceCyclesAction } from 'store/actions/PerformanceCycleActions';

function mapStateToProps(state) {
  const { auth, performanceCycleReducer } = state;
  return {
    userId: auth.user && auth.user.id,
    readOnly: auth.user && auth.user.isReadOnly,
    isCRMC1: auth.user && auth.user.isCRMC1,
    isCRMC2: auth.user && auth.user.isCRMC2,
    performanceCycle: performanceCycleReducer.performanceCycle,
    isLoading: performanceCycleReducer.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPerformanceCyclesAction: () => dispatch(getPerformanceCyclesAction()),
  };
}

const PerformanceCycleContainer = connect(mapStateToProps, mapDispatchToProps)(PerformanceCycle);

export default PerformanceCycleContainer;
