import getProjectActivityLog from 'sources/ProjectActivityLogSource';

import {
  GET_PROJECT_ACTIVITY_LOG_INIT,
  GET_PROJECT_ACTIVITY_LOG_SUCCESS,
  GET_PROJECT_ACTIVITY_LOG_FAILURE,
} from 'constants/ProjectActivityLogTypes';

export const getProjectActivityLogInit = () => ({
  type: GET_PROJECT_ACTIVITY_LOG_INIT,
});
export const getProjectActivityLogSuccess = (data) => ({
  type: GET_PROJECT_ACTIVITY_LOG_SUCCESS,
  data,
});
export const getProjectActivityLogFailure = () => ({
  type: GET_PROJECT_ACTIVITY_LOG_FAILURE,
});

export const getProjectActivityLogsAction = (projectId, pageNumber) =>
  getProjectActivityLog(projectId, pageNumber);
