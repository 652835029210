import getEmployeeSearch from 'sources/EmployeeSearchSource';
import {
  GET_EMPLOYEE_SEARCH_INIT,
  GET_EMPLOYEE_SEARCH_SUCCESS,
  GET_EMPLOYEE_SEARCH_FAILURE,
} from 'constants/EmployeeDirectoryTypes';

// normal actions
export const getEmployeeSearchInit = () => ({
  type: GET_EMPLOYEE_SEARCH_INIT,
});

export const getEmployeeSearchSuccesss = (data, pageInfo) => ({
  type: GET_EMPLOYEE_SEARCH_SUCCESS,
  data,
  pageInfo,
});

export const getEmployeeSearchFailure = (data) => ({
  type: GET_EMPLOYEE_SEARCH_FAILURE,
  data,
});

export const getEmployeeSearchAction = (params) => getEmployeeSearch(params);
