import moment from 'moment';
import { dateFormat } from 'configs/constants';
import { leaveStatus } from '../../configs/leaveConstants';

export const normalizeAllEmployeesLeaveQuotasList = (leaveQuota) => ({
  leaveId: leaveQuota.id,
  location: leaveQuota.location || '',
  employeeName: leaveQuota.requester.name || '',
  designation: leaveQuota.designation || '',
  managerName: leaveQuota.managerName || '',
  deptt: leaveQuota.deptt || '',
  annualUsed: leaveQuota.annualUsed || '',
  annualTotal: leaveQuota.annualTotal || '',
  annualAvailable: parseFloat(leaveQuota.annualTotal - leaveQuota.annualUsed, 10) || '',
  casualUsed: leaveQuota.casualUsed || '',
  casualTotal: leaveQuota.casualTotal || '',
  sickUsed: leaveQuota.sickUsed || '',
  sickTotal: leaveQuota.sickTotal || '',
});

export const normalizeAllLeavesList = (leaves) => {
  const { requester, approvers } = leaves;
  return {
    employeeId: requester.id,
    employeeTaleoId: requester.taleoId,
    leaveId: leaves.id,
    isHalfDay: leaves.isHalfDay,
    requesterNote: leaves.comments || '',
    employeeName: requester.name || '',
    designation: requester.designation || '',
    managerName: requester.managerName || '',
    deptt: requester.department || '',
    currentStatus: leaves.leaveStatus
      ? leaves.leaveStatus.name.toLowerCase().replace(' ', '-')
      : '',
    type: leaves.leaveType.name.replace(/^[a-z]/, (x) => x.toUpperCase()) || '',
    status: leaves.leaveStatus.name.replace(/^[a-z]/, (x) => x.toUpperCase()) || '',
    requestDate: moment(leaves.requestDate).format(dateFormat.DDMMMYYYYwithSpace) || '',
    startDate: moment(leaves.requestStartDate).format(dateFormat.DDMMMYYYYwithSpace) || '',
    endDate: moment(leaves.requestEndDate).format(dateFormat.DDMMMYYYYwithSpace) || '',
    approvers: approvers.map((approver) => {
      const { id, fullName, approverStatus, comments, role, reference, statusDate } = approver;
      const name = fullName;
      const comment = comments !== 'NULL' && comments;
      return {
        id,
        name: name || 'N/A',
        statusId: approverStatus.id,
        status: approverStatus.name.toLowerCase() || 'N/A',
        roles: role,
        comments: comment || 'N/A',
        reference,
        actionDate:
          approverStatus.name && approverStatus.name.toLowerCase() !== leaveStatus.PENDING
            ? moment(statusDate).format(dateFormat.DDMMMYYYYwithSpace)
            : 'N/A',
      };
    }),
  };
};

export function normalizeTeamLeaveBalance(leaveQuota) {
  return leaveQuota.leaveQuotas.map((lq) => normalizeAllEmployeesLeaveQuotasList(lq));
}

export function normalizeAllLeaves(leaves) {
  return leaves.map((lq) => normalizeAllLeavesList(lq));
}
