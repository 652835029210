// import moment from 'moment';
import {
  ORACLE_ID,
  ORACLE_ID_PREFIX,
  DATE_OF_BIRTH,
  // CNIC_EXPIRY,
  // PASSPORT_EXPIRY,
  // DATE_FORMAT,
  BENEFITS,
  JOB_TITLE,
  // REASON,
  FULL_NAME,
  IS_EDIT,
  ID,
  SCORE,
  IS_LOADING,
  // COMPETENCY,
  SKILLS,
  PRIMARY_SKILLS,
  SECONDARY_SKILLS,
  RESOURCE_TYPE,
} from 'configs/employeeProfileConstants';

export const normalizeUpdateEmployeeBasicInfoRequest = (data) => {
  const objectKeys = Object.keys(data);
  const normalizedObject = {};
  for (let keyIndex = 0; keyIndex < objectKeys.length; keyIndex += 1) {
    const key = objectKeys[keyIndex];
    switch (key) {
      case ORACLE_ID:
        normalizedObject[key] = ORACLE_ID_PREFIX + data[key];
        break;
      // case DATE_OF_BIRTH:
      // case CNIC_EXPIRY:
      // case PASSPORT_EXPIRY:
      //   normalizedObject[key] = moment(data[key], DATE_FORMAT).toDate();
      //   break;
      default:
        normalizedObject[key] = data[key];
    }
  }
  return normalizedObject;
};

export const normalizeUpdateEmployeeFamilyInfoRequest = (data) => {
  const objectKeys = Object.keys(data);
  const normalizedObject = {};
  for (let keyIndex = 0; keyIndex < objectKeys.length; keyIndex += 1) {
    const key = objectKeys[keyIndex];
    switch (key) {
      case FULL_NAME:
        normalizedObject.fullname = data[key];
        break;
      case DATE_OF_BIRTH:
        normalizedObject.dob = data[key];
        break;
      default:
        normalizedObject[key] = data[key];
    }
  }
  return normalizedObject;
};

export const normalizeUpdateEmployeeCompensationRequest = (compensation) => ({
  id: compensation.id || '',
  amount: compensation.amount || '',
  date: compensation.date || '',
  reason: compensation.reason || '',
  type: compensation.type || '',
  commitmentUntil: compensation.commitmentUntil || '',
  comments: compensation.comments || '',
});

export const normalizeCreateEmployeeCompensationRequest = (data) => {
  const objectKeys = Object.keys(data);
  const normalizedObject = {};
  for (let keyIndex = 0; keyIndex < objectKeys.length; keyIndex += 1) {
    const key = objectKeys[keyIndex];
    switch (key) {
      case BENEFITS:
        normalizedObject.benefits = data[key] || [];
        break;
      case JOB_TITLE:
        normalizedObject.jobInfoId = data[key];
        break;
      // case REASON:
      //   normalizedObject.reasonId = data[key];
      //   break;
      default:
        normalizedObject[key] = data[key];
    }
  }
  return normalizedObject;
};

export const normalizeEmployeeEducationHistoryRequest = (data) => {
  const objectKeys = Object.keys(data);
  const normalizedObject = {};
  for (let keyIndex = 0; keyIndex < objectKeys.length; keyIndex += 1) {
    const key = objectKeys[keyIndex];
    switch (key) {
      // These are basically excluded from the normalized object
      case IS_EDIT:
      case ID:
      case IS_LOADING:
        break;

      case SCORE:
        normalizedObject.value = data[key];
        break;

      default:
        normalizedObject[key] = data[key];
    }
  }
  return normalizedObject;
};

// Generic normalizer for the most common case
export const normalizeRequest = (data) => {
  const objectKeys = Object.keys(data);
  const normalizedObject = {};
  for (let keyIndex = 0; keyIndex < objectKeys.length; keyIndex += 1) {
    const key = objectKeys[keyIndex];
    switch (key) {
      // These are basically excluded from the normalized object
      case IS_EDIT:
      case ID:
      case IS_LOADING:
        break;

      default:
        normalizedObject[key] = data[key];
    }
  }
  return normalizedObject;
};

export const normalizeEmployeeTalentRequest = (data) => {
  const objectKeys = Object.keys(data);
  const normalizedObject = {};
  for (let keyIndex = 0; keyIndex < objectKeys.length; keyIndex += 1) {
    const key = objectKeys[keyIndex];
    switch (key) {
      case PRIMARY_SKILLS:
      case SECONDARY_SKILLS:
      case SKILLS:
      case RESOURCE_TYPE:
        normalizedObject[key] = data[key];
        break;

      default:
        break;
    }
  }
  return normalizedObject;
};
