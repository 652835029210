import moment from 'moment';
import XLSX from 'xlsx';
import { ATTRIBUTES } from 'configs/leaveConstants';
import get from 'lodash/get';

import { dateOfCarryForward } from 'configs/constants';
import {
  leaveStatus,
  leaveQuotaKeys,
  leaveQuotaMeta,
  allLeavesMeta,
  allLeavesKeys,
  leavesLogMeta,
} from '../configs/leaveConstants';

export function workDayCount(start, end, publicHolidays) {
  let total = 0;
  if (start.week() === end.week()) {
    total = end.diff(start, 'days') + 1;
    if (start.day() === 0 || start.day() === 6) total -= 1;
    if (end.day() === 0 || end.day() === 6) total -= 1;
  } else {
    const first = start.clone().endOf('week'); // end of first week
    const last = end.clone().startOf('week'); // start of last week
    const days = (last.diff(first, 'days') * 5) / 7; // this will always multiply of 7
    let wfirst = first.day() - start.day(); // check first week
    if (start.day() === 0) {
      wfirst -= 1; // -1 if start with sunday
    }
    let wlast = end.day() - last.day(); // check last week
    if (end.day() === 6) {
      wlast -= 1; // -1 if end with saturday
    }
    total = wfirst + days + wlast;
  }
  const checkedHolidays = {};
  publicHolidays.forEach((holiday) => {
    if (holiday.date in checkedHolidays) return;
    checkedHolidays[holiday.date] = true;
    let holidayDate = moment(new Date(`${start.year()}-${holiday.date}`));
    if (
      holidayDate.isBetween(start, end, 'day', '[]') &&
      ![0, 6].includes(moment(holidayDate).day())
    )
      total -= 1;
    if (start.year() !== end.year()) {
      holidayDate = moment(new Date(`${end.year()}-${holiday.date}`));
      if (
        holidayDate.isBetween(start, end, 'day', '[]') &&
        ![0, 6].includes(moment(holidayDate).day())
      )
        total -= 1;
    }
  });
  if (total < 0) total = 0;
  return total;
}

export function getLeaveMultiplierFromJoiningDateToDate(joiningDate, toDate) {
  const years = joiningDate.diff(toDate, 'years');
  if (years <= 4) {
    return 1.25;
  }
  return 1.667;
}

export function calculateAvailableLeaves(
  carryFwdDate,
  currentDate,
  dateOfJoining,
  annualAvailable,
) {
  let accruedLeaves = 0;
  while (carryFwdDate.diff(currentDate, 'months') > 0) {
    accruedLeaves += getLeaveMultiplierFromJoiningDateToDate(dateOfJoining, currentDate);
    currentDate.add(1, 'months');
  }
  return annualAvailable - accruedLeaves;
}

export function calculateAnnualLeaves(
  startDate,
  currentAnnual,
  totalAnnual,
  totalAnnualNextYear,
  carryCount,
  joiningDate,
) {
  let totalLeavesAvailable = currentAnnual;
  const carryForwardDate = moment(dateOfCarryForward);

  const currentDate = moment();
  let isCarryForwarded = false;
  while (startDate.diff(currentDate, 'months') > 0) {
    const leaveMultiplier = getLeaveMultiplierFromJoiningDateToDate(joiningDate, currentDate);
    if (currentDate.isAfter(carryForwardDate) && !isCarryForwarded) {
      if (totalLeavesAvailable > carryCount) {
        totalLeavesAvailable = carryCount;
      }
      isCarryForwarded = true;
    }
    totalLeavesAvailable += leaveMultiplier;
    currentDate.add(1, 'months');
  }

  if (startDate.isSameOrBefore(carryForwardDate) && totalLeavesAvailable > totalAnnual) {
    totalLeavesAvailable = totalAnnual;
  } else if (startDate.isAfter(carryForwardDate) && totalLeavesAvailable > totalAnnualNextYear) {
    totalLeavesAvailable = totalAnnualNextYear;
  }
  return totalLeavesAvailable;
}

export function sortByDate(request1, request2) {
  const startDate = moment(request1.startDate);
  const endDate = moment(request2.startDate);
  if (startDate.isBefore(endDate)) return 1;
  else if (startDate.isAfter(endDate)) return -1;
  return 0;
}

/* find leave status
 ** status = PENDING | DECLINED ...etc
 ** leaveStatuses  = leave statuses array
 ** leave group = Primary | Secondary
 */
export const findLeaveStatus = (status, leaveStatuses, leaveGoup) =>
  leaveStatuses.find((s) => s.systemKey.toLowerCase() === status && s.group === leaveGoup);

/* find approver from the approver list
 ** approvers = approvers array list (CRMC | HR)
 ** userRole  = check if the role exist in the approvers array list
 ** currentlyActiveTab = check if the current active tab is matched with approver roleId
 */
const findApprover = (approvers, userRole, currentlyActiveTab) =>
  approvers.find((approver) =>
    approver.roles.find(
      (role) =>
        role.name === userRole &&
        role.id === Number(currentlyActiveTab) &&
        approver.status === leaveStatus.PENDING,
    ),
  );

/* returned approver object who can approve the leave request
 ** currentStatus = Current leave status (Penging | Declined ...etc)
 ** approvers  = approvers array list (Line Manager | CRMC | HR)
 ** currentlyActiveTab = currently active tab Id
 ** userId = logged in user Id
 */
export const approvedLeavePermission = (params) => {
  const { currentStatus, approvers, currentlyActiveTab, userId } = params;
  const isLeaveStatusPending =
    currentStatus === leaveStatus.PENDING || currentStatus === leaveStatus.PENDING_CANCELLATION;
  const responseApprover = approvers.find(
    (approver) => approver.id === userId && approver.status === leaveStatus.PENDING,
  );
  const CRMCUser = findApprover(approvers, ATTRIBUTES.CRMC, currentlyActiveTab);
  const HRUser = findApprover(approvers, ATTRIBUTES.HR, currentlyActiveTab);
  const leaveApprover = responseApprover || CRMCUser || HRUser;
  const canApprove = isLeaveStatusPending && leaveApprover;
  return { canApprove, approver: leaveApprover };
};

export const sortByAlphabeticOrder = (a, b) => {
  if (a > b) {
    return -1;
  }
  if (a < b) {
    return 1;
  }
  return 0;
};

const getMappedLeavesData = (args) => {
  const { data, sheetMeta } = args;

  const [keys, headers] = [[], []];
  sheetMeta.forEach((meta) => {
    keys.push(meta.key);
    headers.push(meta.label);
  });
  const rows = data.map((entry) => keys.map((key) => get(entry, key)));
  return [headers, ...rows];
};

export function writeToXLSX(sheet, fileName) {
  const book = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(book, sheet, 'Summary');
  const currentDate = moment(new Date()).format('YYYY-MM-DD');
  XLSX.writeFile(book, `${fileName} - ${currentDate}.xlsx`);
}

export const generateLeavesQuotasReport = (data) => {
  const sheet = XLSX.utils.aoa_to_sheet(
    getMappedLeavesData({
      data,
      sheetMeta: leaveQuotaMeta,
      sheetKeys: leaveQuotaKeys,
    }),
  );
  writeToXLSX(sheet, 'Leave Quotas');
};

export const generateAllLeavesReport = (data) => {
  const sheet = XLSX.utils.aoa_to_sheet(
    getMappedLeavesData({
      data,
      sheetMeta: allLeavesMeta,
      sheetKeys: allLeavesKeys,
    }),
  );
  writeToXLSX(sheet, 'All Leaves');
};

export const generateLeavesLogReport = (data) => {
  const sheet = XLSX.utils.aoa_to_sheet(
    getMappedLeavesData({
      data,
      sheetMeta: leavesLogMeta,
    }),
  );
  writeToXLSX(sheet, 'Leaves Adjustment Logs');
};
