import React, { useEffect, useState } from 'react';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';
import { EnhancedDrawer } from '../../components/shared/antd';

const DealRequirementSuggestionOtherDeals = (props) => {
  const {
    onClose,
    isDrawerVisible,
    getDealRequirementSuggestionOtherDeals,
    suggestionObj,
    dealRequirementSuggestionOtherDeals,
  } = props;

  const [params, setParams] = useState({
    page: 0,
    size: 10,
  });

  useEffect(() => {
    if (isDrawerVisible) {
      getDealRequirementSuggestionOtherDeals(suggestionObj.id, params);
    }
  }, [isDrawerVisible]);

  useEffect(() => {
    if (isDrawerVisible) {
      getDealRequirementSuggestionOtherDeals(suggestionObj.id, {
        ...params,
      });
    }
  }, [params]);

  const getColumnsInfo = () => {
    const columns = [
      {
        title: 'Client Name',
        dataIndex: 'clientName',
        key: 'clientName',
      },
      {
        title: 'Deal No.',
        dataIndex: 'dealNumber',
        key: 'dealNumber',
      },
      {
        title: 'Deal Name',
        dataIndex: 'dealName',
        key: 'dealName',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'Comments',
        dataIndex: 'comments',
        key: 'comments',
      },
    ];
    return columns;
  };

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      page: pagination.current - 1,
      size: pagination.pageSize,
    });
  };

  return (
    <EnhancedDrawer
      visible={isDrawerVisible}
      title={`Pitched in other Deals: ${suggestionObj && suggestionObj.resourceName}`}
      width={720}
      onClose={onClose}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <EnhancedServerSideTable
        data={dealRequirementSuggestionOtherDeals.rows}
        columns={getColumnsInfo()}
        onChange={handleTableChange}
        className="deals-table-container"
        paginationInfo={{
          totalItems:
            dealRequirementSuggestionOtherDeals.pageInfo &&
            dealRequirementSuggestionOtherDeals.pageInfo.totalItems,
          pageSize: params.size,
          pageNumber: params.page + 1,
        }}
        showResetFiltersButton={false}
        scroll={{ x: 'max-content' }}
      />
    </EnhancedDrawer>
  );
};

export default DealRequirementSuggestionOtherDeals;
