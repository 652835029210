import Axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import {
  getHomePageVideoAction,
  getYoutubeVideoActions,
  getEventsAction,
  // getAPIFAILURE,
} from 'store/actions/HomePageActions';
import {
  normalizeHomePageUsefulResourceVideoData,
  normalizeEventsData,
} from 'components/homePageCMS/normalize/NormalizeWordpressData';
import {
  homepageVideoUrl,
  HTTP_STATUSES,
  homepageYoutubeUrl,
  homepageEventsUrl,
} from 'configs/constants';
import { getResponseError } from 'utils/sourceUtils';

/* eslint_disabled */
export const getHomePageVideo = () => (dispatch) => {
  const url = homepageVideoUrl.GET;
  Axios.get(url)
    .then((response) => {
      if (response.data.code === HTTP_STATUSES.SUCCESS) {
        const normalizedNews = normalizeHomePageUsefulResourceVideoData(response.data.data);
        dispatch(getHomePageVideoAction(normalizedNews[0]));
      } else {
        EnhancedNotification.error({ message: response.data.data.message });
        // dispatch(getAPIFAILURE());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({ message: getResponseError(error) });
      // dispatch(getAPIFAILURE());
    });
};

export const getHomePageVideoLinks = () => (dispatch) => {
  const url = homepageYoutubeUrl.GET;
  Axios.get(url)
    .then((response) => {
      if (response.status === HTTP_STATUSES.SUCCESS) {
        dispatch(getYoutubeVideoActions(response.data.data.items));
      } else {
        EnhancedNotification.error({ message: response.data.data.message });
        // dispatch(getAPIFAILURE());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({ message: getResponseError(error) });
      // dispatch(getAPIFAILURE());
    });
};

export const getEventsSource = () => (dispatch) => {
  const url = homepageEventsUrl.GET;
  Axios.get(url)
    .then((response) => {
      if (response.data.code === HTTP_STATUSES.SUCCESS) {
        const normalizeData = normalizeEventsData(response.data.data);
        dispatch(getEventsAction(normalizeData));
      } else {
        EnhancedNotification.error({ message: response.data.data.message });
        // dispatch(getAPIFAILURE());
      }
    })
    .catch((error) => {
      EnhancedNotification.error({ message: getResponseError(error) });
      // dispatch(getAPIFAILURE());
    });
};
