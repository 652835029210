import React from 'react';
import { connect } from 'react-redux';
import { logoutRequest } from 'store/actions/LoginActions';
import { LOGIN } from 'configs/routesConstants';
import { removeCookie } from '../utils/storageUtils';

const Logout = (props) => {
  removeCookie();
  props.logout();
  if (props.history.pathname !== LOGIN) {
    props.history.push(LOGIN);
  }
  return (
    <div>
      <h1>Logging out ...</h1>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logoutRequest()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
