import PropTypes from 'prop-types';
import React from 'react';
import { reviewTypes } from 'configs/employeeProfileConstants';

const ValidationErrors = (props) => {
  const { competencies, goals, commentLength } = props.emptyRatings;
  let hasUnmarkedRatings = false;
  let showError = false;

  if (competencies.length > 0 || goals.length > 0) {
    hasUnmarkedRatings = true;
  }

  if (hasUnmarkedRatings || commentLength === 0) {
    showError = true;
  }

  const submissionString =
    props.reviewType === reviewTypes.SELF ? 'submission' : 'sending for approval';
  const typeString = props.reviewType === reviewTypes.SELF ? 'employee' : 'manager';

  const unmarkedRatingsError = (
    <div className="mb-10">
      <p className="mb-4">Please mark ratings for the following before {submissionString}:</p>
      {competencies.length > 0 && (
        <p className="mb-4">
          <strong>Competencies: </strong>
          {competencies.map((competency) => (
            <span key="raterAttributeId">{competency}, </span>
          ))}
        </p>
      )}
      {goals.length > 0 && (
        <p>
          <strong>Goals: </strong>
          {goals.map((goal) => (
            <span key="raterAttributeId">{goal}, </span>
          ))}
        </p>
      )}
    </div>
  );

  const noCommentError = (
    <p>
      Please fill in the {typeString} comments before {submissionString}.
    </p>
  );

  const Error = (
    <div style={{ background: 'white', padding: '10px 0' }}>
      <div className="perf-eval-error">
        {hasUnmarkedRatings && unmarkedRatingsError}
        {commentLength === 0 && noCommentError}
      </div>
    </div>
  );

  return <React.Fragment>{showError && Error}</React.Fragment>;
};

ValidationErrors.propTypes = {
  emptyRatings: PropTypes.shape({
    competencies: PropTypes.arrayOf.isRequired,
    goals: PropTypes.arrayOf.isRequired,
    commentLength: PropTypes.number.isRequired,
  }).isRequired,
};

export default ValidationErrors;
