import {
  ADD_EMPLOYMENT_TYPES_INIT,
  ADD_EMPLOYMENT_TYPES_SUCCESS,
  ADD_EMPLOYMENT_TYPES_FAILURE,
  GET_EMPLOYMENT_TYPES_INIT,
  GET_EMPLOYMENT_TYPES_SUCCESS,
  GET_EMPLOYMENT_TYPES_FAILURE,
  DELETE_EMPLOYMENT_TYPES_INIT,
  DELETE_EMPLOYMENT_TYPES_SUCCESS,
  DELETE_EMPLOYMENT_TYPES_FAILURE,
  EDIT_EMPLOYMENT_TYPES_INIT,
  EDIT_EMPLOYMENT_TYPES_SUCCESS,
  EDIT_EMPLOYMENT_TYPES_FAILURE,
} from 'constants/EmploymentTypes';

const initialState = {
  employmentTypes: [],
  isLoading: false,
  error: null,
};

const employmentTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EMPLOYMENT_TYPES_INIT:
      return {
        ...state,
        loading: true,
      };
    case ADD_EMPLOYMENT_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        employmentTypes: [...state.employmentTypes, action.data],
      };
    case ADD_EMPLOYMENT_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case GET_EMPLOYMENT_TYPES_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_EMPLOYMENT_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        employmentTypes: action.data,
      };
    case GET_EMPLOYMENT_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case DELETE_EMPLOYMENT_TYPES_INIT:
      return {
        ...state,
        loading: true,
      };
    case DELETE_EMPLOYMENT_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        employmentTypes: state.employmentTypes.filter((type) => type.id !== action.data.id),
      };
    case DELETE_EMPLOYMENT_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case EDIT_EMPLOYMENT_TYPES_INIT:
      return {
        ...state,
        loading: true,
      };
    case EDIT_EMPLOYMENT_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        employmentTypes: state.employmentTypes.map((type) => {
          if (type.id === action.data.id) {
            return action.data;
          }
          return type;
        }),
      };
    case EDIT_EMPLOYMENT_TYPES_FAILURE:
      return {
        ...state,
        error: action.data,
      };
    default:
      return state;
  }
};

export default employmentTypesReducer;
