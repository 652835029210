import React, { Component } from 'react';
import { connect } from 'react-redux';
import htmlToText from 'html-to-text';
import { getYoutubeVideoAction } from 'store/actions/HomePageActions';
import './VenDLearning.css';

// HOC
import VideoPlaceHolder from './VideoPlaceHolder';

function VenDLearning(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        youtubeLinks: props.youtubeLinks,
      };
    }

    componentDidMount() {
      if (!this.props.isDataLoaded) {
        this.props.getYoutubeVideoAction();
      }
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.youtubeLinks !== this.props.youtubeLinks) {
        const { youtubeLinks } = nextProps;
        this.setState({ youtubeLinks });
      }
    }

    getVideoData = () => {
      const data1 = this.state.youtubeLinks;
      const styleForVideoTN = {
        height: '80px',
        margin: '0 auto',
        display: 'block',
      };
      const styleForVideoText = {
        marginLeft: '8px',
      };
      const data =
        data1 && data1.length > 0
          ? data1.map((da) => ({
              description: (
                <div className="description">
                  <a
                    style={{ width: '100px' }}
                    href={`https://www.youtube.com/watch?v=${da.id.videoId}`}
                    target="_blank"
                  >
                    <img
                      src={da.snippet.thumbnails.default.url}
                      style={styleForVideoTN}
                      alt="Feature Images"
                    />
                  </a>
                  <h4 className="ant-list-item-meta-title">
                    <a
                      style={{ width: '100px' }}
                      href={`https://www.youtube.com/watch?v=${da.id.videoId}`}
                      target="_blank"
                    >
                      <p style={styleForVideoText} className="list-element-title">
                        {da.snippet.title.length > 20
                          ? `${htmlToText.fromString(da.snippet.title.substr(0, 20))} ...`
                          : htmlToText.fromString(da.snippet.title)}
                      </p>
                    </a>
                    <div
                      className="ant-list-item-meta-description margin-top set-font-size"
                      style={styleForVideoText}
                    >
                      {da.snippet.description.length > 35
                        ? `${htmlToText.fromString(da.snippet.description.substr(0, 45))}...`
                        : htmlToText.fromString(da.snippet.description)}
                    </div>
                  </h4>
                </div>
              ),
            }))
          : '';
      return data;
    };

    render() {
      return <WrappedComponent title="Videos" filterOptions data={this.getVideoData()} />;
    }
  };
}

function mapStateToProps(state) {
  const { appReducer } = state;
  return {
    youtubeLinks: appReducer.youtubeLinks,
    isDataLoaded: appReducer.isDataLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getYoutubeVideoAction: () => dispatch(getYoutubeVideoAction()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VenDLearning(VideoPlaceHolder));
