/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import {
  getEmployeeRatingListInit,
  getEmployeeRatingListSuccess,
  getEmployeeRatingListFailure,
  getEmployeeRatingsInit,
  getEmployeeRatingsSuccess,
  getEmployeeRatingsFailure,
  getEmployeeRatingsSummaryInit,
  getEmployeeRatingsSummarySuccess,
  getEmployeeRatingsSummaryFailure,
} from 'store/actions/EmployeeRatingActions';
import { showLoader, hideLoader } from 'store/actions/AppLoaderActions';
import { employeeRatingUrls } from 'configs/constants';
// import fileDownload from 'js-file-download';
import DownloadExcelFile from '../utils/ExcelFilesUtils';

export const getEmployeeRatingList = (performanceCycleId) => (dispatch) => {
  let url = employeeRatingUrls.GET_LIST;
  if (performanceCycleId) {
    url = url.concat(`?performanceCycleId=${performanceCycleId}`);
  }
  dispatch(getEmployeeRatingListInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        dispatch(getEmployeeRatingListSuccess(response.data.data));
      } else {
        dispatch(getEmployeeRatingListFailure());
      }
    })
    .catch(() => {
      dispatch(getEmployeeRatingListFailure());
    });
};

export const downloadEmployeeRatings = (params) => (dispatch) => {
  let filter = '';
  if (params.reportees) {
    filter += `?reportees=${params.reportees}`;
  }
  if (params.cycleId) {
    if (params.reportees) filter += `&cycleId=${params.cycleId}`;
    else filter += `?cycleId=${params.cycleId}`;
  }
  let url = '';
  if (params.employeeId) {
    url = employeeRatingUrls.DOWNLOAD_EMPLOYEE_RATINGS.replace('{:employeeId}', params.employeeId);
  } else {
    url = employeeRatingUrls.DOWNLOAD_ALL_RATINGS.concat(filter);
  }

  dispatch(showLoader());

  axios
    .get(url)
    .then((response) => {
      dispatch(hideLoader());
      // if (response.data.success && response.data.data) {
      //   fileDownload(response.data.data, 'Ratings.csv');
      // }
      if (response.data.success && response.data.data) {
        const headers = [
          { key: 'resourceName', label: 'Resource Name' },
          { key: 'department', label: 'Department' },
          { key: 'competency', label: 'Competency' },
          { key: 'lineManager', label: 'Line Manager' },
          { key: 'performance_Month', label: 'Performance_Month' },
          { key: 'performance_Year', label: 'Performance_Year' },
          { key: 'project', label: 'Project' },
          { key: 'projectManager', label: 'Project Manager' },
          { key: 'projectArchitect', label: 'Project Architect' },
        ];
        DownloadExcelFile(
          [
            {
              arrayOfObjects: response.data.data.RatingsByPMAndArch,
              headerList: headers,
              name: 'Ratings By PM & ARCH',
            },
          ],
          `Ratings`,
        );
      }
    })
    .catch(() => {
      dispatch(hideLoader());
      EnhancedNotification.error({ message: 'Unable to download ratings.' });
    });
};

export const getEmployeeRatings = (employeeId) => (dispatch) => {
  const url = employeeRatingUrls.GET.replace('{:employeeId}', employeeId);

  dispatch(getEmployeeRatingsInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        dispatch(getEmployeeRatingsSuccess(response.data.data));
      } else {
        dispatch(getEmployeeRatingsFailure());
      }
    })
    .catch(() => {
      dispatch(getEmployeeRatingsFailure());
    });
};

export const getEmployeeRatingSummary = (employeeId) => (dispatch) => {
  const url = employeeRatingUrls.GET_RATINGS_SUMMARY.replace('{:employeeId}', employeeId);

  dispatch(getEmployeeRatingsSummaryInit());
  axios
    .get(url)
    .then((response) => {
      if (response.data.success) {
        dispatch(getEmployeeRatingsSummarySuccess(response.data.data));
      } else {
        dispatch(getEmployeeRatingsSummaryFailure());
      }
    })
    .catch(() => {
      dispatch(getEmployeeRatingsSummaryFailure());
    });
};
