/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import { convertToNumber } from 'utils/conversionUtils';

let projectTotal = {};

function normalizeDetails(logs) {
  let totalPercentage = 0;
  let totalHours = 0;
  const isPercentLog = logs && logs.length && logs[0].percentage;
  const normalizedHistory = [];
  logs.forEach((log) => {
    const percentage = convertToNumber(log.percentage);
    const hours = convertToNumber(log.hours);
    if (isPercentLog) {
      totalPercentage += percentage;
      if (!projectTotal[log.project.id]) {
        projectTotal[log.project.id] = percentage;
      } else {
        projectTotal[log.project.id] += percentage;
      }
    } else {
      totalHours += hours;
    }
    normalizedHistory.push({
      logId: log.id,
      percentage: percentage || null,
      hours: hours || null,
      projectId: log.project.id,
      projectName: log.project.name,
    });
  });

  return {
    total: isPercentLog ? totalPercentage : totalHours,
    details: normalizedHistory,
  };
}

export function normalizeHistory(logs) {
  projectTotal = {};
  const normalizedLogs = logs
    .map((log) => ({
      id: log.id,
      date: log.date,
      mood: log.mood,
      workLogDetails: normalizeDetails(log.work_log_details),
    }))
    .sort((a, b) => moment(a.date) < moment(b.date));

  const mostEngagingProjects = Object.entries(projectTotal)
    .sort((a, b) => b[1] - a[1])
    .map((arr) => +arr[0]);

  return {
    normalizedLogs,
    mostEngagingProjects,
  };
}
