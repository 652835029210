import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PivotTable from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import { GOAL_STATUS, DATE_FORMAT } from 'configs/employeeProfileConstants';
import moment from 'moment';
import EmployeesList from './EmployeesList';

const getCustomizedColumns = () => {
  const columns = [
    {
      title: 'Line Manager',
      dataIndex: 'managerName',
      key: 'managerName',
      config: {
        needFilter: true,
        showInTable: true,
        showInExport: true,
      },
      sorter: (a, b) => a.managerName.localeCompare(b.managerName),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      config: {
        showInTable: true,
        showInExport: true,
      },
    },
    {
      title: 'Total Weightage',
      dataIndex: 'approvedGoalsWeightage',
      key: 'approvedGoalsWeightage',
      sorter: (a, b) => a.approvedGoalsWeightage - b.approvedGoalsWeightage,
      config: {
        showInTable: true,
        showInExport: true,
      },
    },
  ];
  return columns;
};

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

const ApprovedGoals = (props) => {
  const { goalsReport, getGoalsReport, divisions, departments, competencies, businessUnits } =
    props;
  const [employeesList, setEmployeesList] = useState([]);
  const [approvedGoalsData, setApprovedGoalsData] = useState([]);

  const [pivotTable, setPivotTable] = useState({
    cols: ['category'],
    rows: ['competency', 'department'],
    hiddenFromDragDrop: ['goalsWeightageSummary', 'approvedGoalsWeightage'],
    rendererOptions: { plotly: { width: 600, height: 600 } },
    tableOptions: {
      height: '100vh',
      clickCallback: (e, value, filters, pivotData) => {
        // in some cases, some key values are coming as null in filters, specially when we click "sum" rows at the bottom, hence it is effecting filters
        const correctFilters = {};

        Object.keys(filters).forEach((key) => {
          if (filters[key]) {
            correctFilters[key] = filters[key];
          }
        });
        const resources = [];
        pivotData.forEachMatchingRecord(correctFilters, (record) => {
          resources.push(record);
        });
        setEmployeesList(resources);
      },
    },
    unusedOrientationCutoff: Infinity,
  });

  const getCategory = (weightage) => {
    let category = '';

    if (weightage > 100) {
      category = 'Above 100%';
    } else if (weightage < 100) {
      category = 'Less than 100%';
    } else if (weightage === 100) {
      category = '100%';
    }

    return category;
  };

  useEffect(() => {
    if (goalsReport.length > 0) {
      const approvedGoalsSummary = goalsReport.map((item) => {
        const approvedGoals = item.goalsWeightageSummary.find(
          (goals) => goals.status === GOAL_STATUS.APPROVED,
        );
        let approvedGoalsWeightage = 0;

        if (approvedGoals) {
          approvedGoalsWeightage = approvedGoals.totalWeightage;
        }

        return {
          ...item,
          category: getCategory(approvedGoalsWeightage),
          approvedGoalsWeightage,
        };
      });

      setApprovedGoalsData(approvedGoalsSummary);
    } else {
      setApprovedGoalsData([]);
    }
  }, [goalsReport]);

  useEffect(() => {
    getGoalsReport();
  }, []);

  return (
    <div style={{ overflow: 'hidden', overflowY: 'auto' }} className="mt-20">
      <PivotTable
        data={approvedGoalsData}
        renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
        {...pivotTable}
        onChange={(s) => setPivotTable(s)}
      />
      <div style={{ marginTop: '30px', overflowY: 'scroll' }}>
        <EmployeesList
          employees={employeesList}
          customizedColumns={getCustomizedColumns()}
          exportFileName={`Goals Approval Status - ${moment().format(DATE_FORMAT)}`}
          divisions={divisions}
          departments={departments}
          competencies={competencies}
          businessUnits={businessUnits}
        />
      </div>
    </div>
  );
};

ApprovedGoals.propTypes = {
  goalsReport: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      taleoId: PropTypes.string.isRequired,
      division: PropTypes.string.isRequired,
      competency: PropTypes.string.isRequired,
      department: PropTypes.string.isRequired,
      employeeId: PropTypes.number.isRequired,
      designation: PropTypes.string.isRequired,
      businessUnit: PropTypes.string.isRequired,
      managerName: PropTypes.string.isRequired,
      goalsWeightageSummary: PropTypes.arrayOf(
        PropTypes.shape({
          status: PropTypes.string.isRequired,
          totalWeightage: PropTypes.number.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  getGoalsReport: PropTypes.func.isRequired,
};

export default ApprovedGoals;
