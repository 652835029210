import { EnhancedIcon, EnhancedPopover } from 'components/shared/antd';
import React from 'react';
import PropTypes from 'prop-types';
import { goalStatusToClassMapping } from 'configs/constants';

const ManagerRatingStatusLabel = ({ status, comment }) => {
  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <span>
        <span className={`status-box ${goalStatusToClassMapping[status]}`}>{status}</span>
      </span>
      {comment && (
        <EnhancedPopover content={comment} title="Rejection Reason">
          <EnhancedIcon
            type="info-circle"
            style={{ marginLeft: 10, marginTop: 3, cursor: 'pointer' }}
          />
        </EnhancedPopover>
      )}
    </span>
  );
};

export default ManagerRatingStatusLabel;

ManagerRatingStatusLabel.propTypes = {
  status: PropTypes.string.isRequired,
  comment: PropTypes.string,
};

ManagerRatingStatusLabel.defaultProps = {
  comment: null,
};
