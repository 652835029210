import React, { Component, Fragment } from 'react';
import {
  EnhancedButton,
  EnhancedCol,
  EnhancedRow,
  EnhancedCard,
  EnhancedIcon,
  EnhancedTag,
  EnhancedLayout,
  EnhancedModal,
  EnhancedSelect,
  EnhancedDatePicker,
  EnhancedInput,
  EnhancedInputNumber,
  EnhancedForm,
} from 'components/shared/antd';
import moment from 'moment';
import { dateFormat } from 'configs/constants';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';
import 'components/TransientResource/TransientResource.css';
import { NOT_AVAILABLE } from 'configs/additionalConstants';
import RequiredField from 'components/RequiredField';
import { getFormattedDateWithHyphen } from '../../utils/DateUtils';
import { TRANSIENT_RESOURCE_STATUSES, TRANSIENT_RESOURCE_TYPES } from '../../configs/constants';
import EmployeeEditableInputs from '../employees/EmployeeEditableInputs';
import Permissions from '../../constants/AccessPermissions';
import Authorize from '../Authorize';

const { Header } = EnhancedLayout;
const { confirm } = EnhancedModal;
const { Option } = EnhancedSelect;

class TransientResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editVisible: false,
      openModal: false,
      transientResource: {
        firstName: null,
        lastName: null,
        type: undefined,
        isActive: undefined,
        externalExpMonths: '',
        externalExpYears: '',
        joiningDate: null,
        skills: [],
      },
      params: {
        page: 0,
        size: 10,
        sort: null,
        sortBy: null,
      },
      transientResourceTypes: Object.entries(TRANSIENT_RESOURCE_TYPES).map((x) => ({
        id: x[0].toLowerCase(),
        name: x[1],
      })),
      transientResourceStatuses: Object.entries(TRANSIENT_RESOURCE_STATUSES).map((x) => ({
        id: x[1],
        name: x[0],
      })),
      selectedRecord: null,
      transientResources: this.props.transientResources || [],
    };
  }

  componentDidMount() {
    this.props.getSkillsActions();
    this.props.getTransientResourceActions({
      page: this.state.params.page,
      size: this.state.params.size,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.transientResources !== this.props.transientResources) {
      this.setState({ transientResources: nextProps.transientResources });
    }
  }

  onJoiningDateChange = (date) => {
    this.setState({
      ...this.state,
      transientResource: { ...this.state.transientResource, joiningDate: date },
    });
  };

  onPrimarySkillsChange = (value) => {
    this.setState({
      ...this.state,
      transientResource: { ...this.state.transientResource, skills: value },
    });
  };

  onUpdateOfInput = (token, newValue) => {
    const { transientResource } = this.state;
    transientResource[token] = newValue;
    this.setState({
      transientResource,
    });
  };

  getColumnsInfo = () => {
    const columns = [
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
        width: '10%',
        filterConfig: {
          type: 'search',
          key: 'firstName',
        },
        sorter: true,
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
        width: '10%',
        filterConfig: {
          type: 'search',
          key: 'lastName',
        },
        sorter: true,
      },
      {
        title: 'Offer Type',
        dataIndex: 'type',
        render: (type) => this.renderTransientTypes(type),
        key: 'type',
        filterConfig: {
          type: 'search',
          key: 'type',
        },
        width: '10%',
        sorter: true,
      },
      {
        title: 'Joining Date',
        dataIndex: 'joiningDate',
        render: (date) => this.renderJoiningDate(date),
        key: 'joiningDate',
        width: '10%',
        sorter: true,
      },
      {
        title: 'External Experience',
        render: (data) => `${data.externalExpYears} Years ${data.externalExpMonths} Months`,
        key: 'externalExperience',
        width: '15%',
      },
      {
        title: 'Status',
        render: (status) => this.renderStatus(status),
        sorter: true,
        dataIndex: 'isActive',
        key: 'isActive',
        width: '10%',
      },
      {
        title: 'Skills',
        dataIndex: 'dealResourceSkills',
        render: this.renderPrimarySkills,
        key: 'dealResourceSkills',
        filterConfig: {
          type: 'search',
          key: 'skills',
        },
        width: '30%',
      },
      {
        title: 'Actions',
        key: 'actions',
        width: '5%',
        render: (text, record) => (
          <Authorize requiredPermissions={[Permissions.ResourcePlanner.TransientResource.Update]}>
            <span>
              <EnhancedIcon
                type="edit"
                style={{ cursor: 'pointer' }}
                onClick={() => this.showEditResourceModal(record)}
              />
              {/* As Per Discussion, We Added isActive boolean instead of deletedAt field. */}
              {/* <Divider type="vertical" />
            <Icon type="delete" style={{ cursor: 'pointer' }}
            onClick={() => this.showDeleteResourceModal(record)} />
            */}
            </span>
          </Authorize>
        ),
      },
    ];
    return columns;
  };

  tagStyle = {
    backgroundColor: '#fff',
    border: 'solid #DEDFD7 2px',
    minWidth: 30,
    height: 28,
    paddingTop: 2,
    marginRight: 5,
    marginTop: 5,
    borderRadius: '4px',
  };

  handleTableChange = (pagination, filters, sorter) => {
    const updatedParams = {
      ...this.state.params,
      page: pagination.current - 1,
      size: pagination.pageSize,
    };

    if (sorter.columnKey && sorter.order) {
      updatedParams.sort = sorter.columnKey;
      updatedParams.sortBy = sorter.order === 'ascend' ? 'ASC' : 'DESC';
    } else {
      updatedParams.sort = '';
      updatedParams.sortBy = '';
    }

    this.setState({
      params: updatedParams,
    });

    this.props.getTransientResourceActions(updatedParams);
  };

  handleExternalExpMonths = (data) => {
    this.setState({
      ...this.state,
      transientResource: { ...this.state.transientResource, externalExpMonths: data },
    });
  };

  handleExternalExpYears = (data) => {
    this.setState({
      ...this.state,
      transientResource: { ...this.state.transientResource, externalExpYears: data },
    });
  };

  handleTransientType = (data) => {
    this.setState({
      ...this.state,
      transientResource: { ...this.state.transientResource, type: data },
    });
  };

  handleTransientStatus = (data) => {
    this.setState({
      ...this.state,
      transientResource: { ...this.state.transientResource, isActive: data },
    });
  };

  showEditResourceModal = (record) => {
    const mappedSkills = record.dealResourceSkills.map((item) => item.skills.id);
    this.setState({
      editVisible: true,
      openModal: true,
      selectedRecord: record,
      transientResource: {
        ...record,
        joiningDate: record.joiningDate ? moment(record.joiningDate) : null,
        skills: mappedSkills,
      },
    });
  };

  hideEditResourceModal = () => {
    this.setState({
      editVisible: false,
      openModal: false,
      selectedRecord: null,
      transientResource: {
        firstName: null,
        lastName: null,
        type: undefined,
        isActive: undefined,
        externalExpMonths: '',
        externalExpYears: '',
        joiningDate: null,
        skills: [],
      },
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      transientResource: { ...this.state.transientResource, [name]: value },
    });
  };

  handleAddResource = () => {
    const { transientResource } = this.state;
    const data = {
      firstName: transientResource.firstName,
      lastName: transientResource.lastName,
      type: transientResource.type,
      isActive: transientResource.isActive,
      externalExpMonths: transientResource.externalExpMonths,
      externalExpYears: transientResource.externalExpYears,
      joiningDate: getFormattedDateWithHyphen(transientResource.joiningDate),
      skills: transientResource.skills,
    };
    const { createTransientResourceActions } = this.props;
    createTransientResourceActions(data);
    this.setState({
      transientResource: {
        firstName: null,
        lastName: null,
        type: undefined,
        isActive: undefined,
        externalExpMonths: '',
        externalExpYears: '',
        joiningDate: null,
        skills: [],
      },
      editVisible: false,
      openModal: false,
    });
  };

  handleEditResource = () => {
    const { selectedRecord, transientResource } = this.state;
    const editData = {
      firstName: transientResource.firstName,
      lastName: transientResource.lastName,
      type: transientResource.type,
      isActive: transientResource.isActive,
      externalExpMonths: transientResource.externalExpMonths,
      externalExpYears: transientResource.externalExpYears,
      joiningDate: getFormattedDateWithHyphen(transientResource.joiningDate),
      skills: transientResource.skills,
    };
    const { updateTransientResourceActions } = this.props;
    updateTransientResourceActions(editData, selectedRecord.id);
    this.setState({
      selectedRecord: null,
      transientResource: {
        firstName: null,
        lastName: null,
        type: undefined,
        isActive: undefined,
        externalExpMonths: '',
        externalExpYears: '',
        joiningDate: null,
        skills: [],
      },
      editVisible: false,
      openModal: false,
    });
  };

  handleDeleteResource = (record) => {
    const { deleteTransientResourceActions } = this.props;
    deleteTransientResourceActions({ id: record.id });
  };

  addResource = () => {
    const {
      editVisible,
      openModal,
      transientResource,
      transientResourceTypes,
      transientResourceStatuses,
    } = this.state;
    const skillsList = this.props.skills || [];
    const skills = skillsList.map((skill) => (
      <Option key={skill.id} value={skill.id}>
        {skill.name}
      </Option>
    ));
    const statuses = transientResourceStatuses.map((status) => (
      <Option key={status.id} value={status.id}>
        {status.name}
      </Option>
    ));
    const types = transientResourceTypes.map((type) => (
      <Option key={type.id} value={type.id}>
        {type.name}
      </Option>
    ));
    return (
      <EnhancedModal
        width={700}
        title={editVisible ? 'Edit Resource' : 'Add Resource'}
        maskClosable={false}
        visible={openModal}
        onCancel={this.hideEditResourceModal}
        footer={[
          <EnhancedButton key="back" onClick={this.hideEditResourceModal}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            key="submit"
            type="primary"
            onClick={editVisible ? this.handleEditResource : this.handleAddResource}
            disabled={
              !transientResource.firstName ||
              !transientResource.lastName ||
              !transientResource.type ||
              transientResource.externalExpYears < 0 ||
              transientResource.externalExpYears === '' ||
              transientResource.externalExpYears === null ||
              transientResource.externalExpMonths < 0 ||
              transientResource.externalExpMonths === '' ||
              transientResource.externalExpMonths === null ||
              (TRANSIENT_RESOURCE_TYPES[transientResource.type.toUpperCase()] ===
                TRANSIENT_RESOURCE_TYPES.OFFER_ACCEPTED &&
                !transientResource.joiningDate) ||
              !transientResource.skills.length > 0
            }
          >
            {editVisible ? 'Save' : 'Add'}
          </EnhancedButton>,
        ]}
      >
        <EnhancedRow type="flex" justify="center">
          <EnhancedForm layout="inline">
            <EnhancedRow className="w-full pb-16">
              <EnhancedCol lg={8}>
                <RequiredField> First Name </RequiredField>
              </EnhancedCol>
              <EnhancedCol lg={16}>
                <EnhancedInput
                  name="firstName"
                  value={transientResource.firstName}
                  onChange={this.handleChange}
                  placeholder="First Name"
                />
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="w-full pb-16">
              <EnhancedCol lg={8}>
                <RequiredField> Last Name </RequiredField>
              </EnhancedCol>
              <EnhancedCol lg={16}>
                <EnhancedInput
                  name="lastName"
                  value={transientResource.lastName}
                  onChange={this.handleChange}
                  placeholder="Last Name"
                />
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="w-full pb-16">
              <EnhancedCol lg={8}>
                <RequiredField> Offer Type </RequiredField>
              </EnhancedCol>
              <EnhancedCol lg={16}>
                <EnhancedSelect
                  mode="default"
                  name="type"
                  style={{ width: '100%' }}
                  placeholder="Select Offer Type"
                  value={transientResource.type}
                  optionFilterProp="children"
                  onChange={this.handleTransientType}
                >
                  {types}
                </EnhancedSelect>
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="w-full pb-16">
              <EnhancedCol lg={8}>
                {transientResource.type &&
                TRANSIENT_RESOURCE_TYPES[transientResource.type.toUpperCase()] ===
                  TRANSIENT_RESOURCE_TYPES.OFFER_ACCEPTED ? (
                  <RequiredField> Joining Date </RequiredField>
                ) : (
                  <span> Joining Date </span>
                )}
              </EnhancedCol>
              <EnhancedCol lg={16}>
                <EnhancedDatePicker
                  value={transientResource.joiningDate}
                  placeholder="Select Joining Date"
                  name="joiningDate"
                  format={dateFormat.DDMMMYYYYwithSpace}
                  style={{ width: '100%' }}
                  onChange={this.onJoiningDateChange}
                  allowClear
                />
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="w-full pb-16">
              <EnhancedCol lg={8}>
                <RequiredField> Status </RequiredField>
              </EnhancedCol>
              <EnhancedCol lg={16}>
                <EnhancedSelect
                  mode="default"
                  name="type"
                  style={{ width: '100%' }}
                  placeholder="Select Resource Status"
                  value={transientResource.isActive}
                  optionFilterProp="children"
                  onChange={this.handleTransientStatus}
                >
                  {statuses}
                </EnhancedSelect>
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="w-full pb-16">
              <EnhancedCol lg={8}>
                <RequiredField> Skills </RequiredField>
              </EnhancedCol>
              <EnhancedCol lg={16}>
                <EnhancedSelect
                  mode="multiple"
                  name="skills"
                  style={{ width: '100%' }}
                  placeholder="Select Skills"
                  value={transientResource.skills}
                  optionFilterProp="children"
                  onChange={this.onPrimarySkillsChange}
                  showSearch
                >
                  {skills}
                </EnhancedSelect>
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow className="w-full pb-16">
              <EnhancedCol lg={8}>
                <RequiredField> External Experience </RequiredField>
              </EnhancedCol>
              {editVisible ? (
                <EnhancedCol lg={16}>
                  <EmployeeEditableInputs
                    externalExpYears={transientResource.externalExpYears}
                    externalExpMonths={transientResource.externalExpMonths}
                    inputName="externalExpYears"
                    editingAllowed={editVisible}
                    updateInput={this.onUpdateOfInput}
                    field="Designation"
                  />
                </EnhancedCol>
              ) : (
                <EnhancedCol lg={16}>
                  <EnhancedInputNumber
                    placeholder="Select Years"
                    style={{ width: '50%' }}
                    name="externalExpYears"
                    value={transientResource.externalExpYears}
                    min={0}
                    max={99}
                    onChange={this.handleExternalExpYears}
                  />
                  <EnhancedInputNumber
                    placeholder="Select Months"
                    style={{ width: '50%' }}
                    name="externalExpMonths"
                    value={transientResource.externalExpMonths}
                    min={0}
                    max={12}
                    onChange={this.handleExternalExpMonths}
                  />
                </EnhancedCol>
              )}
            </EnhancedRow>
          </EnhancedForm>
        </EnhancedRow>
      </EnhancedModal>
    );
  };

  showAddResourceModal = () => {
    this.setState({ openModal: true });
  };

  showDeleteResourceModal = (record) => {
    const { handleDeleteResource } = this;
    confirm({
      title: 'Are you sure you want to delete this Resource?',
      content: 'Note: Resources associated with Deals cannot be deleted',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteResource(record);
      },
    });
  };

  handleExportTransientReport = () => {
    this.props.getAllTransientResourceActions({
      ...this.state.params,
      size: 100000,
    });
  };

  renderPrimarySkills = (multipleSkills, record, index) => {
    const actionOutline = 'solid #BFBFBF 2px';
    const { isActive } = record;
    const color = isActive ? '#5A5A5A' : 'red';
    const skills = multipleSkills.filter((skill) => skill.skills && skill.skills.name);
    if (skills.length === 0) {
      return NOT_AVAILABLE;
    }
    const showLessBadge = (
      <EnhancedTag
        style={{
          ...this.tagStyle,
          backgroundColor: 'white',
          color: 'rgb(90, 90, 90)',
          cursor: 'pointer',
          border: actionOutline,
        }}
        onClick={() => {
          const newTransientResources = [...this.state.transientResources];

          newTransientResources[index] = {
            ...newTransientResources[index],
            showAllPrimary: false,
          };

          this.setState({
            transientResources: newTransientResources,
          });
        }}
      >
        Show Less
      </EnhancedTag>
    );
    if (skills.length > 2) {
      if (record.showAllPrimary) {
        const allSkills = skills.map((skill) => (
          <EnhancedTag style={{ ...this.tagStyle, color }}>{skill.skills.name}</EnhancedTag>
        ));
        return [allSkills, showLessBadge];
      }
      const showMoreBadge = (
        <EnhancedTag
          style={{
            ...this.tagStyle,
            backgroundColor: 'white',
            color: 'rgb(90, 90, 90)',
            cursor: 'pointer',
            border: actionOutline,
          }}
          onClick={() => {
            const newTransientResources = [...this.state.transientResources];

            newTransientResources[index] = {
              ...newTransientResources[index],
              showAllPrimary: true,
            };

            this.setState({
              transientResources: newTransientResources,
            });
          }}
        >
          {`${skills.length - 2} More`}
        </EnhancedTag>
      );
      const slicedSkills = skills
        .slice(0, 2)
        .map((skill) => (
          <EnhancedTag style={{ ...this.tagStyle, color }}>
            {skill.skills !== undefined && skill.skills !== null ? skill.skills.name : ''}
          </EnhancedTag>
        ));
      return [...slicedSkills, showMoreBadge];
    }
    return skills.map((skill) => {
      if (skill && skill.skills) {
        return (
          <EnhancedTag style={{ ...this.tagStyle, color }}>
            {skill.skills !== undefined && skill.skills !== null ? skill.skills.name : ''}
          </EnhancedTag>
        );
      }
      return null;
    });
  };

  renderTransientTypes = (type) => TRANSIENT_RESOURCE_TYPES[type.toUpperCase()];

  renderJoiningDate = (date) => {
    if (date) {
      return moment(date).format(dateFormat.DDMMMYYYYwithSpace);
    }
    return NOT_AVAILABLE;
  };

  renderStatus = (status) => {
    const color = status ? 'green' : 'red';
    if (status) {
      return <EnhancedTag style={{ ...this.tagStyle, color }}>Active</EnhancedTag>;
    }
    return <EnhancedTag style={{ ...this.tagStyle, color }}>Inactive</EnhancedTag>;
  };

  render() {
    const columns = this.getColumnsInfo();
    const { transientResources } = this.state;
    const { isLoading } = this.props;
    return (
      <Fragment>
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol lg={12} xs={24}>
              <h1 className="heading-pro">Transient Resources</h1>
            </EnhancedCol>
            <EnhancedCol lg={12} xs={24} className="text-align-right">
              {/* <EnhancedRow style={{ textAlign: 'right' }}> */}
              <Authorize
                requiredPermissions={[Permissions.ResourcePlanner.TransientResource.Create]}
              >
                <EnhancedButton
                  type="primary"
                  onClick={this.showAddResourceModal}
                  style={{ margin: '10px 0px' }}
                >
                  {' '}
                  Add New Resource
                </EnhancedButton>
              </Authorize>
              {/* </EnhancedRow> */}
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        {this.addResource()}
        <div style={{ background: 'white' }}>
          <EnhancedCard loading={false} bordered={false}>
            <EnhancedRow />
          </EnhancedCard>
          <div>
            <EnhancedServerSideTable
              style={{ margin: '0px 25px' }}
              loading={isLoading}
              columns={columns}
              data={transientResources}
              onChange={this.handleTableChange}
              paginationInfo={{
                totalItems: this.props.pageInfo.totalItems,
                pageSize: this.props.pageInfo.size,
                pageNumber: this.props.pageInfo.page + 1,
              }}
              scroll={{ x: 1300 }}
              exportFileConfig={{
                showExportButton: true,
                handler: this.handleExportTransientReport,
              }}
              updateParams={(updatedParams) => {
                this.setState({
                  params: {
                    ...this.state.params,
                    ...updatedParams,
                    page: 0,
                  },
                });

                this.props.getTransientResourceActions({
                  ...this.state.params,
                  ...updatedParams,
                  page: 0,
                });
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default TransientResource;
