import XLSX from 'xlsx';
import { writeToXLSX, getMappedDataForExport } from 'utils/ExcelFilesUtils';

import { allEmployeesMeta } from '../configs/performanceManagementConstants';

const calculateCompetenciesRatingTotal = (competencies) => {
  let competenciesRatingTotal = 0.0;

  // no concept of separate weightage within competencies, hence they all are equal in worth
  if (competencies.length > 0) {
    competencies.forEach((competency) => {
      if (competency.rating) {
        competenciesRatingTotal += competency.rating;
      }
    });
  }

  return competenciesRatingTotal;
};

export const calculateCompetenciesOverallRating = (competencies) => {
  let competenciesOverallRating = 0.0;
  let competenciesTotal = 0.0;

  if (competencies.length > 0) {
    competenciesTotal = calculateCompetenciesRatingTotal(competencies);
    competenciesOverallRating = competenciesTotal / competencies.length;
  }

  return competenciesOverallRating;
};

const calculateGoalsTotalWeightage = (goals) => {
  const goalsTotalWeightage = goals.reduce(
    (accumulator, currentValue) => accumulator + currentValue.weightage,
    0,
  );

  return goalsTotalWeightage;
};

const calculateGoalsRatingTotal = (goals, totalWeightage) => {
  let goalsRatingTotal = 0.0;

  /*
    Calculation for goals:
    1. Check if total weightage is under 100, if yes then we need to discard the missing weightage from final goal rating.
    2.Each goal's rating will be counted as per its weightage
    */

  goals.forEach((goal) => {
    if (goal.rating) {
      const normalizedWeightage = goal.weightage / totalWeightage;
      goalsRatingTotal += normalizedWeightage * goal.rating;
    }
  });

  if (totalWeightage < 100) {
    const difference = 100 - totalWeightage;
    goalsRatingTotal -= goalsRatingTotal * (difference / 100);
  }

  return goalsRatingTotal;
};

export const calculateGoalsOverallRating = (goals) => {
  let goalsOverallRating = 0.0;

  if (goals.length > 0) {
    const totalWeightage = calculateGoalsTotalWeightage(goals);
    goalsOverallRating = calculateGoalsRatingTotal(goals, totalWeightage);
  }

  return goalsOverallRating;
};

export default function calculateOverallRating(
  goals,
  competencies,
  goalsWeightage = 0.7,
  competenciesWeightage = 0.3,
) {
  let overallRating = 0.0;
  let goalsRating = 0.0;
  let competenciesTotal = 0.0;

  goalsRating = calculateGoalsOverallRating(goals);
  overallRating += goalsRating * goalsWeightage;

  competenciesTotal = calculateCompetenciesOverallRating(competencies);
  overallRating += competenciesTotal * competenciesWeightage;

  return overallRating;
}

export const getMatchedRatingOption = (rating, ratingOptions) => {
  return ratingOptions.find(
    (ratingOption) => rating >= ratingOption.from && rating <= ratingOption.to,
  );
};

export const generatePerformanceCycleEmployeesReport = (
  data,
  pmCycleTitle,
  fileName = 'Performance Cycle Employees',
) => {
  const sheet = XLSX.utils.aoa_to_sheet(
    getMappedDataForExport({
      data,
      sheetMeta: allEmployeesMeta,
    }),
  );
  writeToXLSX(sheet, `${fileName} - ${pmCycleTitle}`);
};

export const checkEvaluationStatus = (pef, statusToCheck) => {
  return pef.status && pef.status.systemKey === statusToCheck;
};

export const validateRatingsSubmission = (goals, competencies, overallComment) => {
  const withoutRatings = {
    competencies: [],
    goals: [],
    commentLength: overallComment ? overallComment.trim().length : 0,
    hasError: false,
  };

  goals.forEach((goal) => {
    if (goal.rating === null || goal.rating === 0) {
      withoutRatings.goals.push(goal.title);
    }
  });

  competencies.forEach((competency) => {
    if (competency.rating === null || competency.rating === 0) {
      withoutRatings.competencies.push(competency.name);
    }
  });

  if (
    withoutRatings.goals.length > 0 ||
    withoutRatings.competencies.length > 0 ||
    withoutRatings.commentLength === 0
  ) {
    withoutRatings.hasError = true;
  }

  return withoutRatings;
};
