import { EnhancedCard } from 'components/shared/antd';
import React from 'react';
import { DROP_DOWNS } from 'configs/employeeProfileConstants';
import { getActiveEmployeesWithOptions } from 'utils/employeeUtils';
import CreateBasicInfo from './createBasicInfo';

class CreateEmployee extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // [`${stateName}Errors`]: {}, // TODO: This many not be needed
      // [DROP_DOWNS]: {},
    };
  }

  componentDidMount() {
    this.props.getAllEmployees();
    this.props.getDropdownOptions();
    this.props.getEmployeeBusinessUnits();
    this.props.getEmployeeDivisionsAction();
    this.props.getEmployeeCompetenciesAction();
    this.props.getEmployeeDepartments();
    this.props.getMiscellaneousData();
    this.props.getAllLocations();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps[DROP_DOWNS] !== this.props[DROP_DOWNS]) {
      // this.initializeDropDowns(nextProps[DROP_DOWNS]);
      // TODO: This may not be needed -> Generates error state for each value in state
      // this.initializeErrors(nextProps[propsName]);
    }
  }

  getValueForSelect = (items, type) => {
    if (this.props[items] && this.props[items].length > 0) {
      return this.props[items].map((item) => ({
        value: type ? `${item.firstName} ${item.lastName}` : item.name,
        key: item.id,
      }));
    }
    return [];
  };

  // initializeDropDowns = (props) => this.setState({ dropDowns: { ...props } });

  // TODO: This may not be needed -> Generates error state for each value in state
  // initializeErrors = (props) => {
  //   const errorObject = generateErrorObject(props[stateName]);
  //   this.setState({ [`${stateName}Errors`]: errorObject });
  // };

  render() {
    const { designations, employees } = this.props;
    const { user } = this.props;
    // const errors = this.state[`${stateName}Errors`]; // TODO: This may not be needed
    return (
      <EnhancedCard className="mt-20" bordered={false}>
        <CreateBasicInfo
          userId={this.props.user}
          userPermissions={user.permissions}
          employees={getActiveEmployeesWithOptions(employees)}
          businessUnits={this.getValueForSelect('businessUnits')}
          divisions={this.getValueForSelect('empDivisions')}
          competencies={this.getValueForSelect('competencies')}
          departments={this.getValueForSelect('departments')}
          designations={designations}
          locations={this.getValueForSelect('locations')}
          employmentTypes={this.props.employmentTypes}
          dropDowns={this.props[DROP_DOWNS]}
          createEmployeeInfo={this.props.addEmployee}
          isLoading={this.props.isLoading}
          isSuccessfull={this.props.isSuccessfull}
          createdEmployeeId={this.props.createdEmployeeId}
        />
      </EnhancedCard>
    );
  }
}

export default CreateEmployee;
