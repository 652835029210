/* eslint-disable */
import { EnhancedTabs } from 'components/shared/antd';
import React from 'react';
import { hasPermission } from 'utils/AccessUtils';

const { TabPane } = EnhancedTabs;

const renderTab = (tab, props) => {
  const { key, title } = tab;
  const Component = tab.component;
  return (
    <TabPane key={key} tab={title}>
      <Component {...props} />
    </TabPane>
  );
};

export default (props) => {
  const { tabsData, user } = props;

  return (
    <EnhancedTabs defaultActiveKey="1">
      {tabsData.map((tab) => {
        if (tab.permissions) {
          if (hasPermission(user.permissions, tab.permissions)) {
            return renderTab(tab, props);
          }
        } else {
          return renderTab(tab, props);
        }
      })}
    </EnhancedTabs>
  );
};
