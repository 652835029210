import React from 'react';
import { appSmithNavigationPath } from 'configs/constants';

const AppSmithWindow = (props) => {
  const { jwt } = props.auth.user;
  const link = appSmithNavigationPath.find((x) => x.key === props.match.path);
  const src = `${link.path}?embed=true&token=${jwt}`;
  // const src = `${link.path}?embed=true`;
  return (
    <div>
      <iframe
        title="AppSmith"
        src={src}
        frameBorder="0"
        scrolling="yes"
        seamless="seamless"
        style={{ display: 'block', width: '100%', height: '100vh' }}
      />
    </div>
  );
};
export default AppSmithWindow;
