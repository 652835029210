import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd';

const EnhancedBadge = (props) => {
  const { children, className, style, onClick, onHover, ...rest } = props;

  return (
    <Badge style={style} className={className} onClick={onClick} onHover={onHover} {...rest}>
      {children}
    </Badge>
  );
};

EnhancedBadge.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

EnhancedBadge.defaultProps = {
  className: '',
  style: {},
};

export default EnhancedBadge;
