import {
  GET_LOCATION_INIT,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAILURE,
  CREATE_LOCATION_INIT,
  CREATE_LOCATION_SUCCESS,
  CREATE_LOCATION_FAILURE,
  UPDATE_LOCATION_INIT,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILURE,
  DELETE_LOCATION_INIT,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
} from 'constants/LocationTypes';

const initialState = {
  locations: [],
  isLoading: false,
};

export default function locationsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LOCATION_INIT:
      return {
        ...state,
        isLoading: true,
        locations: [],
      };
    case GET_LOCATION_SUCCESS:
      return {
        ...state,
        locations: [...action.data],
        isLoading: false,
      };
    case GET_LOCATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        locations: [],
      };
    case CREATE_LOCATION_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_LOCATION_SUCCESS:
      return {
        ...state,
        locations: [...state.locations, action.data],
        isLoading: false,
      };
    case CREATE_LOCATION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_LOCATION_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_LOCATION_SUCCESS: {
      const id = action.data;
      const { locations } = state;
      const filteredLocations = locations.filter((elem) => elem.id !== id);
      return {
        ...state,
        isLoading: false,
        locations: filteredLocations,
      };
    }
    case DELETE_LOCATION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_LOCATION_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_LOCATION_SUCCESS: {
      const { locations } = state;
      const filteredLocations = locations.map((location) => {
        if (location.id === action.data.id) {
          return action.data;
        }
        return location;
      });
      return {
        ...state,
        isLoading: false,
        locations: filteredLocations,
      };
    }
    case UPDATE_LOCATION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
