import {
  getPrimaryTeamLeaves,
  getSecondaryTeamLeaves,
  updateTeamLeave,
  updateTeamLeaveAsHR,
  getLeaveCalendarSource,
  requestLeavesStatusesSource,
} from 'sources/LeavesSource';
import { showLoader, hideLoader } from 'store/actions/AppLoaderActions';
import { getUserAttributes } from 'sources/EmployeeSource';
import { LeavesStatusesSuccess } from 'store/actions/leaves/LeaveStatusesActions';
import { getUserAttributesSuccess } from 'store/actions/EmployeeActions';
import {
  UPDATE_TEAM_LEAVE_INIT,
  UPDATE_TEAM_LEAVE_SUCCESS,
  UPDATE_TEAM_LEAVE_FAILURE,
  UPDATE_TEAM_LEAVE_COMPLETE,
  GET_PRIMARY_TEAM_LEAVES_INIT,
  GET_PRIMARY_TEAM_LEAVES_SUCCESS,
  GET_PRIMARY_TEAM_LEAVES_FAILURE,
  GET_SECONDARY_TEAM_LEAVES_INIT,
  GET_SECONDARY_TEAM_LEAVES_SUCCESS,
  GET_SECONDARY_TEAM_LEAVES_FAILURE,
  GET_LEAVE_CALENDAR_INIT,
  GET_LEAVE_CALENDAR_SUCCESS,
  GET_LEAVE_CALENDAR_FAILURE,
} from 'constants/TeamLeavesTypes';
import { leaveTypeCategories } from '../../../configs/leaveConstants';

export const getPrimaryTeamLeavesInit = () => ({
  type: GET_PRIMARY_TEAM_LEAVES_INIT,
});

export const getPrimaryTeamLeavesSuccess = (data) => ({
  type: GET_PRIMARY_TEAM_LEAVES_SUCCESS,
  data,
});

export const getPrimaryTeamLeavesFailure = () => ({
  type: GET_PRIMARY_TEAM_LEAVES_FAILURE,
});

export const getSecondaryTeamLeavesInit = () => ({
  type: GET_SECONDARY_TEAM_LEAVES_INIT,
});

export const getSecondaryTeamLeavesSuccess = (data) => ({
  type: GET_SECONDARY_TEAM_LEAVES_SUCCESS,
  data,
});

export const getSecondaryTeamLeavesFailure = () => ({
  type: GET_SECONDARY_TEAM_LEAVES_FAILURE,
});

export const updateTeamLeaveInit = () => ({
  type: UPDATE_TEAM_LEAVE_INIT,
});

export const updateTeamLeaveSuccess = () => ({
  type: UPDATE_TEAM_LEAVE_SUCCESS,
});

export const updateTeamLeaveFailure = () => ({
  type: UPDATE_TEAM_LEAVE_FAILURE,
});

export const updateTeamLeaveComplete = () => ({
  type: UPDATE_TEAM_LEAVE_COMPLETE,
});

export const getLeaveCalendarInit = () => ({
  type: GET_LEAVE_CALENDAR_INIT,
});

export const getLeaveCalendarSuccess = (data) => ({
  type: GET_LEAVE_CALENDAR_SUCCESS,
  data,
});

export const getLeaveCalendarFail = () => ({
  type: GET_LEAVE_CALENDAR_FAILURE,
});

export const getPrimaryTeamLeavesAction = (
  attributeId,
  params,
  leaveTypeCategory = leaveTypeCategories.PRIMARY,
) => getPrimaryTeamLeaves(attributeId, { ...params, leaveTypeCategory });

export const getSecondaryTeamLeavesAction = (
  attributeId,
  params,
  leaveTypeCategory = leaveTypeCategories.SECONDARY,
) => getSecondaryTeamLeaves(attributeId, { ...params, leaveTypeCategory });

export const updateTeamLeaveAction = (data) => updateTeamLeave(data);
export const updateTeamLeaveAsPMAction = (leaveId, data) => updateTeamLeave(leaveId, data);
export const updateTeamLeaveAsHRAction = (leaveId, data) => updateTeamLeaveAsHR(leaveId, data);
export const getLeaveCalendar = () => getLeaveCalendarSource();

export const getTeamLeavesContainer = (userId, module) => async (dispatch) => {
  dispatch(showLoader());

  const promise1 = await getUserAttributes(userId, module);
  const promise2 = await requestLeavesStatusesSource();

  Promise.all([promise1, promise2]).then(([first, second]) => {
    dispatch(getUserAttributesSuccess(first));
    dispatch(LeavesStatusesSuccess(second));
    dispatch(updateTeamLeaveSuccess());
  });

  dispatch(hideLoader());
};
