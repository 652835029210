import { EnhancedButton, EnhancedIcon, EnhancedTooltip } from 'components/shared/antd';
import React from 'react';
import moment from 'moment';
import { dateFormat } from 'configs/constants';
import { INTERVIEW_RESULT_MAP, INTERVIEW_RESULT_FILTER } from './constants';

function generateColumns(state, fn) {
  const [{ trackList, clientList }, { handleEdit, handleDelete, handleQuestions }] = [state, fn];

  return [
    {
      title: 'Resource',
      dataIndex: 'resourceName',
      key: 'resourceName',
      width: '17%',
      filterConfig: {
        type: 'search',
        key: 'resourceName',
      },
      sorter: (a, b) => a.resourceName.localeCompare(b.resourceName),
    },
    {
      title: 'Client',
      dataIndex: 'clientName',
      key: 'clientName',
      width: '17%',
      filterConfig: {
        type: 'enhanced_filter',
        key: 'clientName',
        optionsConfig: {
          values: clientList,
        },
      },
      sorter: (a, b) => a.project.name.localeCompare(b.project.name),
    },
    {
      title: 'Track',
      key: 'trackName',
      dataIndex: 'trackName',
      width: '8%',
      render: (_text, record) => (record.track ? record.track.name : ''),
      sorter: (a, b) => a.track.name.localeCompare(b.track.name),
      filterConfig: {
        type: 'enhanced_filter',
        key: 'trackName',
        optionsConfig: {
          values: trackList,
        },
      },
    },
    {
      title: 'Interview Date',
      dataIndex: 'scheduledAt',
      key: 'scheduledAt',
      width: '10%',
      render: (value) => moment(value).format(dateFormat.DDMMMYYYYwithSpace),
      sorter: (a, b) => a.scheduledAt.localeCompare(b.scheduledAt),
    },
    {
      title: 'Result',
      dataIndex: 'result',
      key: 'result',
      width: '8%',
      render: (text) => (
        <div
          style={{
            color: INTERVIEW_RESULT_MAP[text].color,
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <EnhancedIcon type={INTERVIEW_RESULT_MAP[text].icon} />
          <span>{INTERVIEW_RESULT_MAP[text].label}</span>
        </div>
      ),
      sorter: (a, b) => a.result.localeCompare(b.result),
      filterConfig: {
        type: 'enhanced_filter',
        key: 'result',
        optionsConfig: {
          values: INTERVIEW_RESULT_FILTER,
          valueVar: 'value',
          labelVar: 'text',
        },
      },
    },
    {
      title: 'Client Comments',
      dataIndex: 'clientComments',
      key: 'clientComments',
      width: '14%',
      render: (text) => (
        <EnhancedTooltip title={text} placement="bottom">
          {text.length > 30 ? `${text.slice(0, 30)}...` : text}
        </EnhancedTooltip>
      ),
      sorter: (a, b) => a.clientComments.localeCompare(b.clientComments),
      filterConfig: {
        type: 'search',
        key: 'clientComments',
      },
    },
    {
      title: 'LM/Lead Comments',
      dataIndex: 'lmComments',
      key: 'lmComments',
      width: '14%',
      render: (text) => (
        <EnhancedTooltip title={text} placement="bottom">
          {text.length > 30 ? `${text.slice(0, 30)}...` : text}
        </EnhancedTooltip>
      ),
      sorter: (a, b) => a.lmComments.localeCompare(b.lmComments),
      filterConfig: {
        type: 'search',
        key: 'lmComments',
      },
    },
    {
      title: 'Questions',
      dataIndex: 'numQuestions',
      key: 'numQuestions',
      width: '7%',
      render: (_text, record) => (
        <EnhancedButton icon="eye" data-id={record.id} onClick={handleQuestions} />
      ),
    },
    {
      title: 'Actions',
      fixed: 'right',
      width: '7%',
      render: (_text, record) => (
        <div style={{ display: 'flex', gap: 8 }}>
          <EnhancedIcon
            data-id={record.id}
            role="button"
            style={{ cursor: 'pointer' }}
            type="edit"
            onClick={handleEdit}
          />
          <EnhancedIcon
            data-id={record.id}
            role="button"
            style={{ cursor: 'pointer' }}
            type="delete"
            onClick={handleDelete}
          />
        </div>
      ),
    },
  ];
}

export default generateColumns;
