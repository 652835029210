import { EnhancedButton } from 'components/shared/antd';
import React from 'react';

import PropTypes from 'prop-types';
import checkIcon from 'images/CheckCircle.svg';

const ApproveButton = (props) => {
  const { clickHandler, buttonText, style } = props;
  return (
    <EnhancedButton style={style || {}} onClick={clickHandler} className="btn-edit">
      <img src={checkIcon} alt="check icon" />
      <span>{buttonText}</span>
    </EnhancedButton>
  );
};

ApproveButton.PropTypes = {
  clickHandler: PropTypes.func,
  buttonText: PropTypes.string,
};

export default ApproveButton;
