/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import {
  getPEFDataInit,
  getPEFDataSuccess,
  getPEFDataFailure,
  submitPEFDataInit,
  submitPEFDataSuccess,
  submitPEFDataFailure,
  submitForApprovalInit,
  submitForApprovalSuccess,
  submitForApprovalFailure,
  approvePEFInit,
  approvePEFSuccess,
  approvePEFFailure,
} from 'store/actions/PerformanceEvaluationFormActions';
import { PMCycleUrls } from 'configs/constants';
import { showLoader, hideLoader } from 'store/actions/AppLoaderActions';
import { performanceEvaluationNormalizer } from 'normalizers/performanceManagementNormalizer';
import { getPMEmpCycle } from './PerformanceMangementSource';

export const getPEFData = (request) => (dispatch) => {
  dispatch(getPEFDataInit());
  const url = PMCycleUrls.GET_EMPLOYEE_PE.replace(
    '{performanceCycleId}',
    request.performanceCycleId,
  )
    .replace('{empId}', request.empId)
    .replace('{reviewType}', request.reviewType);

  dispatch(showLoader());

  axios
    .get(url)
    .then((response) => {
      dispatch(hideLoader());
      if (response.status === 200) {
        dispatch(getPEFDataSuccess(performanceEvaluationNormalizer(response.data.data)));
      } else {
        dispatch(getPEFDataFailure());
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      dispatch(getPEFDataFailure(error));
    });
};

export const submitPEFData = (request) => (dispatch) => {
  dispatch(submitPEFDataInit());
  const url = PMCycleUrls.SUBMIT_PM_EMP_CYCLE.replace(
    '{performanceCycleId}',
    request.performanceCycleId,
  )
    .replace('{empId}', request.empId)
    .replace('{reviewType}', request.reviewType);

  dispatch(showLoader());

  axios
    .put(url, { ...request.payload })
    .then((response) => {
      dispatch(hideLoader());
      if (response.status === 200) {
        let successMessage = 'Successfully saved';
        if (request.payload.freeze) {
          successMessage = 'Successfully submitted';
        }
        EnhancedNotification.success({ message: successMessage });
        dispatch(submitPEFDataSuccess());
        dispatch(getPEFData(request));
      } else {
        dispatch(submitPEFDataFailure());
        EnhancedNotification.error({
          message: 'An error occured',
        });
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      EnhancedNotification.error({
        message: 'An error occured',
      });
      dispatch(submitPEFDataFailure(error));
    });
};

export const getMyPEFData = (request) => (dispatch) => {
  dispatch(getPEFDataInit());
  const url = PMCycleUrls.GET_MY_PEF.replace(
    '{performanceCycleId}',
    request.performanceCycleId,
  ).replace('{reviewType}', request.reviewType);

  dispatch(showLoader());

  axios
    .get(url)
    .then((response) => {
      dispatch(hideLoader());
      if (response.status === 200) {
        dispatch(getPEFDataSuccess(performanceEvaluationNormalizer(response.data.data)));
      } else {
        dispatch(getPEFDataFailure());
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      dispatch(getPEFDataFailure(error));
    });
};

export const submitMyPEFData = (request) => (dispatch) => {
  dispatch(submitPEFDataInit());
  const url = PMCycleUrls.SUBMIT_MY_PEF.replace('{performanceCycleId}', request.performanceCycleId);

  dispatch(showLoader());

  axios
    .put(url, { ...request.payload })
    .then((response) => {
      dispatch(hideLoader());
      if (response.status === 200) {
        let successMessage = 'Successfully saved';
        if (request.payload.freeze) {
          successMessage = 'Successfully submitted';
        }

        EnhancedNotification.success({ message: successMessage });
        dispatch(submitPEFDataSuccess());
        dispatch(getMyPEFData(request));
      } else {
        dispatch(submitPEFDataFailure());
        EnhancedNotification.error({
          message: 'An error occured',
        });
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      EnhancedNotification.error({
        message: 'An error occured',
      });
      dispatch(submitPEFDataFailure(error));
    });
};

export const sendEvaluationsForApproval = (request) => (dispatch) => {
  dispatch(submitForApprovalInit());
  const url = PMCycleUrls.SUBMIT_FOR_APPROVAL;

  const { payload, refetchFunc } = request;

  dispatch(showLoader());

  axios
    .put(url, { ...payload })
    .then((approvalResponse) => {
      dispatch(hideLoader());
      if (approvalResponse.status === 200) {
        EnhancedNotification.success({ message: 'Successfully sent for approval' });

        dispatch(submitForApprovalSuccess());

        if (request.refetchWithData) {
          dispatch(getPEFData(request.refetchWithData));
        }

        if (refetchFunc) {
          refetchFunc();
        }
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      EnhancedNotification.error({
        message: error.response.data.message,
      });
      dispatch(submitForApprovalFailure());
    });
};

export const submitForApproval = (request) => (dispatch) => {
  dispatch(submitForApprovalInit());
  // const url = PMCycleUrls.SUBMIT_FOR_APPROVAL;

  const { refetchWithData, pefData } = request;

  const urlForPEFData = PMCycleUrls.SUBMIT_PM_EMP_CYCLE.replace(
    '{performanceCycleId}',
    refetchWithData.performanceCycleId,
  ).replace('{empId}', refetchWithData.empId);

  dispatch(showLoader());

  /*
  We also need to save review data along with approval request
  because it is possible that user directly hit approval button without saving the form
  */
  axios
    .put(urlForPEFData, { ...pefData })
    .then((pefDataResponse) => {
      if (pefDataResponse.status === 200) {
        dispatch(sendEvaluationsForApproval(request));
      } else {
        dispatch(hideLoader());
        dispatch(submitForApprovalFailure());
        EnhancedNotification.error({
          message: 'An error occured',
        });
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      EnhancedNotification.error({
        message: error.response.data.message,
      });
      dispatch(submitForApprovalFailure());
    });
};

export const approvePEFStatus = (request) => (dispatch) => {
  const url = PMCycleUrls.APPROVE_PEF;

  dispatch(showLoader());

  axios
    .put(url, { ...request.payload })
    .then((approvalResponse) => {
      dispatch(hideLoader());
      if (approvalResponse.status === 200) {
        EnhancedNotification.success({ message: `Successfully ${request.payload.status}` });

        if (request.refetchWithData) {
          dispatch(getPMEmpCycle(request.refetchWithData));
        }

        if (request.refetchFunc) {
          request.refetchFunc();
        }
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      EnhancedNotification.error({
        message: error.response.data.message,
      });
      dispatch(approvePEFFailure(error));
    });
};

export const approvePEF = (request) => (dispatch) => {
  dispatch(approvePEFInit());
  const url = PMCycleUrls.APPROVE_PEF;

  const { payload, refetchWithData, pefData } = request;

  const urlForPEFData = PMCycleUrls.SUBMIT_PM_EMP_CYCLE.replace(
    '{performanceCycleId}',
    refetchWithData.performanceCycleId,
  ).replace('{empId}', refetchWithData.empId);

  dispatch(showLoader());

  /*
  We also need to save review data along with approval request
  because it is possible that user directly hit approval button without saving the form
  */
  // const promises = [
  //   axios.put(url, {...payload}),
  //   axios.put(urlForPEFData, {...pefData})
  // ];

  axios
    .put(urlForPEFData, { ...pefData })
    .then((pefDataResponse) => {
      if (pefDataResponse.status === 200) {
        axios
          .put(url, { ...payload })
          .then((approvalResponse) => {
            dispatch(hideLoader());
            if (approvalResponse.status === 200) {
              EnhancedNotification.success({ message: `Successfully ${request.payload.status}` });

              dispatch(approvePEFSuccess());

              if (request.refetchWithData) {
                dispatch(getPEFData(request.refetchWithData));
              }
            }
          })
          .catch((error) => {
            dispatch(hideLoader());
            EnhancedNotification.error({
              message: error.response.data.message,
            });
            dispatch(approvePEFFailure(error));
          });
      } else {
        dispatch(hideLoader());
        dispatch(approvePEFFailure());
        EnhancedNotification.error({
          message: 'An error occured',
        });
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      EnhancedNotification.error({
        message: error.response.data.message,
      });
      dispatch(approvePEFFailure(error));
    });
  // Promise.all(promises)
  //   .then(([approvalResponse, pefDataResponse]) => {
  //     dispatch(hideLoader());
  //     if (approvalResponse.status === 200 && pefDataResponse.status === 200) {

  //       EnhancedNotification.success({ message: `Successfully ${request.payload.status}` });
  //       dispatch(approvePEFSuccess());

  //       if (request.refetchWithData) {
  //         dispatch(getPEFData(request.refetchWithData));
  //       }
  //     } else {
  //       dispatch(approvePEFFailure());
  //       EnhancedNotification.error({
  //         message: 'An error occured',
  //       })
  //     }
  //   })
  //   .catch((error) => {
  //     dispatch(hideLoader());
  //     EnhancedNotification.error({
  //       message: error.response.data.message,
  //     })
  //     dispatch(approvePEFFailure(error));
  //   });
};

export const reopenPEF = (request) => (dispatch) => {
  const { performanceCycleId, employeeId, params } = request;

  const url = PMCycleUrls.REOPEN_PEF.replace('{performanceCycleId}', performanceCycleId).replace(
    '{employeeId}',
    employeeId,
  );

  dispatch(showLoader());

  axios
    .put(url)
    .then((response) => {
      dispatch(hideLoader());
      if (response.status === 200) {
        EnhancedNotification.success({ message: 'Successfully updated' });
        dispatch(getPMEmpCycle(params));
      } else {
        EnhancedNotification.error({
          message: 'An error occured',
        });
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      EnhancedNotification.error({
        message: error.response.data.message,
      });
    });
};

export const markPEFAsFinal = (request) => (dispatch) => {
  const { performanceCycleId, employeeId, params } = request;

  const url = PMCycleUrls.MARK_PEF_AS_FINAL.replace(
    '{performanceCycleId}',
    performanceCycleId,
  ).replace('{employeeId}', employeeId);

  dispatch(showLoader());

  axios
    .put(url)
    .then((response) => {
      dispatch(hideLoader());
      if (response.status === 200) {
        EnhancedNotification.success({ message: 'Successfully updated' });
        dispatch(getPMEmpCycle(params));
      } else {
        EnhancedNotification.error({
          message: 'An error occured',
        });
      }
    })
    .catch((error) => {
      dispatch(hideLoader());
      EnhancedNotification.error({
        message: error.response.data.message,
      });
    });
};
