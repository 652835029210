import moment from 'moment';
import { dateFormat } from 'configs/constants';

export default function normalizeGetActivityLogResponse(res) {
  const data = res;
  const { history } = data;
  const dateFormate = dateFormat.DDMMMYYYYwithSpace;
  const normalizedActivities = {};
  history.forEach((element) => {
    const date = moment(element.createdAt).format(dateFormate);
    const elementTime = moment(element.createdAt).format('hh:mm:ss a');
    if (!normalizedActivities[date]) normalizedActivities[date] = [];
    normalizedActivities[date].push({
      value: element.result,
      time: elementTime,
    });
  });
  return {
    activityLog: normalizedActivities,
    numberOfActivities: history.length,
    totalPages: data.totalPages,
  };
}
