import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import ReactGA from 'react-ga';
import 'antd/dist/antd.less';

import store from './store/store';
import AppRoot from './AppRoot';
import { logoutRequest } from './store/actions/LoginActions';

import { GA_API, APP_VERSION } from './configs/constants';

import './index.less';
import { unregister } from './registerServiceWorker';
// initialise the authorization settings for the calls if user is already logged in
const { auth } = store.getState();

if (auth.user) {
  axios.defaults.headers.common.authorization = auth.user.jwt;
}

// If there is no analytics key in build then the whole process is bypassed.
if (GA_API) {
  ReactGA.initialize(GA_API, {
    gaOptions: {
      userId: (auth.user && auth.user.id) || null,
      appVersion: APP_VERSION,
    },
  });
}

// Add a response interceptor
axios.interceptors.response.use(
  (response) =>
    // Do something with response data
    response,
  (error) => {
    // Do something with response error
    if (error && error.response && error.response.status === 401) {
      store.dispatch(logoutRequest());
    }
    return Promise.reject(error);
  },
);

ReactDOM.render(<AppRoot />, document.getElementById('root'));
unregister();
