import {
  getEmployeeRatingList,
  getEmployeeRatings,
  downloadEmployeeRatings,
  getEmployeeRatingSummary,
} from 'sources/EmployeeRatingSource';

import {
  GET_EMPLOYEES_RATING_LIST_INIT,
  GET_EMPLOYEES_RATING_LIST_SUCCESS,
  GET_EMPLOYEES_RATING_LIST_FAILURE,
  GET_EMPLOYEE_RATING_INIT,
  GET_EMPLOYEE_RATING_SUCCESS,
  GET_EMPLOYEE_RATING_FAILURE,
  GET_EMPLOYEES_RATING_SUMMARY_INIT,
  GET_EMPLOYEES_RATING_SUMMARY_SUCCESS,
  GET_EMPLOYEES_RATING_SUMMARY_FAILURE,
} from 'constants/EmployeeRatingTypes';

export const getEmployeeRatingListInit = () => ({
  type: GET_EMPLOYEES_RATING_LIST_INIT,
});

export const getEmployeeRatingListSuccess = (data) => ({
  type: GET_EMPLOYEES_RATING_LIST_SUCCESS,
  data,
});

export const getEmployeeRatingListFailure = (data) => ({
  type: GET_EMPLOYEES_RATING_LIST_FAILURE,
  data,
});

// employee rating detail
export const getEmployeeRatingsInit = () => ({
  type: GET_EMPLOYEE_RATING_INIT,
});

export const getEmployeeRatingsSuccess = (data) => ({
  type: GET_EMPLOYEE_RATING_SUCCESS,
  data,
});

export const getEmployeeRatingsFailure = (data) => ({
  type: GET_EMPLOYEE_RATING_FAILURE,
  data,
});

// employee rating summary
export const getEmployeeRatingsSummaryInit = () => ({
  type: GET_EMPLOYEES_RATING_SUMMARY_INIT,
});

export const getEmployeeRatingsSummarySuccess = (data) => ({
  type: GET_EMPLOYEES_RATING_SUMMARY_SUCCESS,
  data,
});

export const getEmployeeRatingsSummaryFailure = (data) => ({
  type: GET_EMPLOYEES_RATING_SUMMARY_FAILURE,
  data,
});

export const getEmployeeRatingListAction = (performanceCycleId) =>
  getEmployeeRatingList(performanceCycleId);
export const getEmployeeRatingsAction = (employeeId) => getEmployeeRatings(employeeId);

export const downloadEmployeeRatingsAction = (params) => downloadEmployeeRatings(params);

export const getEmployeeRatingSummaryAction = (employeeId) => getEmployeeRatingSummary(employeeId);
