import React from 'react';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';

const EnhancedDrawer = (props) => {
  const { children, className, style, title, onClose, visible, width, bodyStyle } = props;
  return (
    <Drawer
      className={className}
      style={style}
      title={title}
      visible={visible}
      onClose={onClose}
      width={width}
      bodyStyle={bodyStyle}
    >
      {children}
    </Drawer>
  );
};

EnhancedDrawer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  bodyStyle: PropTypes.shape({}),
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

EnhancedDrawer.defaultProps = {
  className: '',
  style: {},
  title: '',
  width: 520,
  bodyStyle: {},
};

export default EnhancedDrawer;
