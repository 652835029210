/* eslint-disable import/prefer-default-export */
export function convertToNumber(number, isFloat = false) {
  if (Number.isNaN(+number)) return null;
  const result = (number * 10) / 10;
  return isFloat ? parseFloat(result.toFixed(2)) : result;
}

export function roundOffRating(rating, decimals = 2) {
  return rating ? rating.toFixed(decimals) : '';
}
