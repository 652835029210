import axios from 'axios';

import {
  getReportInit,
  getReportSuccess,
  getReportFailure,
  getReportWithFormatInit,
  getReportWithFormatSuccess,
  getReportWithFormatFailure,
} from 'store/actions/ReportActions';
import { REPORT_URL, dateFormat } from 'configs/constants';
import reportNormalizer from 'normalizers/reportNormalizers';

const cache = {};

const fetchReport = (startDate = '', endDate = '', format = null) => {
  const params = {
    startDate: startDate && startDate.format(dateFormat.YYYYMMDDwithHyphen),
    endDate: endDate && endDate.format(dateFormat.YYYYMMDDwithHyphen),
  };

  if (format) params.format = format;

  return axios
    .get(REPORT_URL, {
      params,
    })
    .then((response) => {
      if (!(response.status === 200 || response.data.success)) {
        throw new Error(response.data.code);
      }
      return response;
    });
};

export const getReport =
  (startDate = '', endDate = '') =>
  (dispatch) => {
    const key = `${startDate}-${endDate}`;
    if (cache[key]) {
      dispatch(getReportSuccess(cache[key]));
      return;
    }
    dispatch(getReportInit());
    fetchReport(startDate, endDate)
      .then((response) => {
        const report = reportNormalizer(response.data.data);
        cache[key] = report;
        dispatch(getReportSuccess(cache[key]));
      })
      .catch((error) => dispatch(getReportFailure(error)));
  };

export const getReportWithFormat =
  (startDate = '', endDate = '', format = 'csv') =>
  (dispatch) => {
    const key = `${startDate}-${endDate}-${format}`;

    if (cache[key]) {
      dispatch(getReportWithFormatSuccess(cache[key]));
      return;
    }

    dispatch(getReportWithFormatInit());
    fetchReport(startDate, endDate, format)
      .then((response) => {
        cache[key] = response.data;
        dispatch(getReportWithFormatSuccess(response.data));
      })
      .catch((error) => dispatch(getReportWithFormatFailure(error)));
  };
