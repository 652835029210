import {
  CREATE_WORKLOG_INIT,
  CREATE_WORKLOG_SUCCESS,
  CREATE_WORKLOG_FAILURE,
  CLEAR_WORKLOG_STATUS,
} from 'constants/WorkLogTypes';
import { GET_HISTORY_INIT } from 'constants/HistoryTypes';

const initialState = {
  isLoading: false,
  errorMessage: '',
  successMessage: '',
};
export default function workLogReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_WORKLOG_INIT: {
      return Object.assign({}, state, {
        isLoading: true,
        errorMessage: '',
        successMessage: '',
      });
    }
    case CREATE_WORKLOG_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: '',
        log: action.data,
        successMessage: action.message,
      });
    }
    case CREATE_WORKLOG_FAILURE: {
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: action.message,
        successMessage: '',
      });
    }
    case CLEAR_WORKLOG_STATUS: {
      return Object.assign({}, state, {
        errorMessage: '',
        successMessage: '',
      });
    }
    case GET_HISTORY_INIT: {
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: '',
        successMessage: '',
      });
    }
    default:
      return state;
  }
}
