import { prepareOptionsFromJSONDestructure } from 'utils/arrayUtils';

export const normalizeMiscellaneousData = (res) => {
  const miscellaneousData = res || [];
  return {
    divisions: prepareOptionsFromJSONDestructure(miscellaneousData.divisionsData) || [],
    designations: prepareOptionsFromJSONDestructure(miscellaneousData.designationsData) || [],
    employmentTypes: prepareOptionsFromJSONDestructure(miscellaneousData.employmentTypesData) || [],
  };
};

export const normalizeDesignationsData = (res) => {
  const designationsData = res || [];
  return prepareOptionsFromJSONDestructure(designationsData) || [];
};
