import {
  EnhancedButton,
  EnhancedCol,
  EnhancedRow,
  EnhancedCard,
  EnhancedAffix,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import { reviewTypes } from 'configs/employeeProfileConstants';
import evaluationStatus from 'configs/performanceManagementConstants';
import calculateOverallRating, { checkEvaluationStatus } from 'utils/performanceManagementUtils';
import { BREADCRUMBS } from 'configs/constants';
import { ATTRIBUTES } from 'configs/leaveConstants';
import PerformanceEvaluationFormHeader from 'components/shared/performanceEvaluationForm/header';
import ConfirmModal from 'components/shared/performanceEvaluationForm/ConfirmModal';
import CompetenciesStep from './steps/competenciesStep';
import EmployeeInformation from './steps/employeeInformationStep';
import PromotionStep from './steps/promotionStep';
import EndNoteStep from './steps/endNoteStep';
import GoalsSteps from './steps/goalsStep';
import TrainingAssessment from './steps/trainingAssessmentStep';
import StepsComponent from './stepsComponent';
// import AddButton from '../shared/employeeProfile/AddButton';
import statusConfirmContentMap from '../shared/performanceEvaluationForm/statusConfirmContentMap';
import ShowPreviousCycle from './showPreviousCycles';

const PerformanceEvaluationForm = (props) => {
  const [step, setStep] = useState(0);
  const {
    getPEFDataActions,
    submitPEFDataActions,
    pefData,
    getPMCycleDetail,
    pmCycleDetail,
    updateBreadCrumb,
    allEmployees,
    designations,
    getAllDesignations,
    approvePEF,
    showPreviousPMCycleActions,
    getRatingOptions,
    ratingOptions,
  } = props;
  const [pef, setPef] = useState({});
  const [showSelfEvaluationComments, setShowSelfEvaluationComments] = useState(false);

  const reviewType = new URLSearchParams(props.location.search).get('type');
  const { empId, id } = props.match.params;
  const [viewer] = useState(reviewType === 'self' ? 'Employee' : 'Manager');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmModalStatus, setConfirmModalStatus] = useState();
  const [emptyRatings, setEmptyRatings] = useState({
    competencies: [],
    goals: [],
    commentLength: null,
    hasError: false,
  });

  let steps = [
    {
      key: 0,
      title: 'Get Started',
      component: EmployeeInformation,
      dataKey: 'employeeInfo',
    },
    {
      key: 1,
      title: 'Competencies',
      component: CompetenciesStep,
      dataKey: 'competencies',
    },
    {
      key: 2,
      title: 'Goals',
      component: GoalsSteps,
      dataKey: 'goals',
    },
    {
      key: 3,
      title: 'Training',
      component: TrainingAssessment,
      dataKey: 'trainingInfo',
    },
    {
      key: 4,
      title: 'End Notes',
      component: EndNoteStep,
      dataKey: 'overallComment',
    },
  ];

  if (reviewType === reviewTypes.MANAGER) {
    steps = [
      {
        key: 0,
        title: 'Get Started',
        component: EmployeeInformation,
        dataKey: 'employeeInfo',
      },
      {
        key: 1,
        title: 'Competencies',
        component: CompetenciesStep,
        dataKey: 'competencies',
      },
      {
        key: 2,
        title: 'Goals',
        component: GoalsSteps,
        dataKey: 'goals',
      },
      {
        key: 3,
        title: 'Promotion & Designation',
        component: PromotionStep,
        dataKey: 'promotionInfo',
      },
      {
        key: 4,
        title: 'Training',
        component: TrainingAssessment,
        dataKey: 'trainingInfo',
      },
      {
        key: 5,
        title: 'End Notes',
        component: EndNoteStep,
        dataKey: 'overallComment',
      },
    ];
  }

  const updateDataFromComponent = (key, value) => {
    pef[key] = value;

    pef.overallRating = calculateOverallRating(pef.goals, pef.competencies);

    setPef({ ...pef });
  };

  useEffect(() => {
    getAllDesignations();
    getPEFDataActions({ reviewType, empId, performanceCycleId: id });
    getPMCycleDetail(id);
    getRatingOptions();
  }, []);

  useEffect(() => {
    if (pmCycleDetail) {
      updateBreadCrumb(BREADCRUMBS.PERFORMANCE_MANAGEMENT, id, pmCycleDetail.title);
    }
  }, [pmCycleDetail]);

  useEffect(() => {
    setPef(pefData);
    if (pefData.employeeInfo) {
      updateBreadCrumb(
        BREADCRUMBS.PERFORMANCE_MANAGEMENT_EMPLOYEE,
        empId,
        pefData.employeeInfo.name,
      );
    }
  }, [pefData]);

  const isEditable = () => {
    let editingAllowed = false;

    const isPending = checkEvaluationStatus(pef, evaluationStatus.PENDING);
    const isPendingApproval = checkEvaluationStatus(pef, evaluationStatus.PENDING_APPROVAL);

    // HR cannot edit self review in any case
    if (reviewType !== reviewTypes.SELF) {
      // HR can only edit that form from admin panel which is in pending or pending approval state
      if (isPending || isPendingApproval) {
        editingAllowed = true;
      }
    }
    return editingAllowed;
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const canApprove = () => {
    let isAllowed = false;

    const isPendingApproval = checkEvaluationStatus(pef, evaluationStatus.PENDING_APPROVAL);

    if (isPendingApproval) {
      isAllowed = true;
    }

    return isAllowed;
  };

  const getPefDataForSubmission = () => {
    return {
      goals: pef.goals,
      competencies: pef.competencies,
      pcRatingId: pef.pcRatingId,
      finalComment: pef.overallComment,
      trainingInfo: pef.trainingInfo,
      promotionInfo: pef.promotionInfo,
    };
  };

  const handleSave = () => {
    const payload = getPefDataForSubmission();

    submitPEFDataActions({ reviewType, empId, performanceCycleId: id, payload });
  };

  const handleApproval = ({ comment }) => {
    const as = ATTRIBUTES.HR;

    const request = {
      payload: {
        employeeIds: [pef.employeeInfo.id],
        ratingIds: [pef.pcRatingId],
        status: confirmModalStatus,
        as,
        comment,
      },
      refetchWithData: {
        performanceCycleId: id,
        empId,
        reviewType,
      },
      pefData: getPefDataForSubmission(),
    };

    approvePEF(request);
    setConfirmModalStatus(null);
  };

  const renderStatusConfirmModal = () => {
    const modalTitle = statusConfirmContentMap[confirmModalStatus];

    return (
      <ConfirmModal
        title={modalTitle}
        status={confirmModalStatus}
        onCancel={() => setConfirmModalStatus(false)}
        onOk={handleApproval}
      />
    );
  };

  const renderStepsButtons = () => {
    return (
      <EnhancedRow className="mb-20 mr-16">
        <EnhancedCol md={14} lg={16}>
          {isEditable() && (
            <EnhancedButton
              onClick={() => {
                handleSave();
              }}
              className="ml-10 mr-10"
            >
              Save Progress
            </EnhancedButton>
          )}

          {step === steps.length - 1 && canApprove() && (
            <EnhancedButton
              onClick={() => {
                setConfirmModalStatus(evaluationStatus.APPROVED);
              }}
              className="mr-10"
            >
              Approve
            </EnhancedButton>
          )}

          {step === steps.length - 1 && canApprove() && (
            <EnhancedButton
              onClick={() => {
                setConfirmModalStatus(evaluationStatus.REJECTED);
              }}
              className="mr-10"
            >
              Reject
            </EnhancedButton>
          )}
        </EnhancedCol>
        <EnhancedCol md={10} lg={8} className="text-right">
          {step > 0 && (
            <EnhancedButton
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Previous Step
            </EnhancedButton>
          )}

          {step < steps.length - 1 && (
            <EnhancedButton
              onClick={() => {
                setStep(step + 1);
              }}
              className="ml-10"
            >
              Next Step
            </EnhancedButton>
          )}
        </EnhancedCol>
      </EnhancedRow>
    );
  };

  const renderSwitch = () => {
    const stepObj = steps.find((item) => item.key === step);

    const Component = stepObj.component;

    return (
      <Component
        viewer={viewer}
        isEditable={isEditable()}
        data={pef[stepObj.dataKey] || {}}
        updateHandler={updateDataFromComponent}
        designations={stepObj.dataKey === 'promotionInfo' ? designations : null}
        allEmployees={stepObj.dataKey === 'trainingInfo' ? allEmployees : null}
        reviewType={reviewType}
        selfEvaluation={pef.selfEvaluation}
        showSelfEvaluationComments={showSelfEvaluationComments}
        ratingOptions={ratingOptions}
        pefData={pefData}
        emptyRatings={emptyRatings}
        setEmptyRatings={setEmptyRatings}
      />
    );
  };

  return (
    <div>
      <EnhancedCard>
        <React.Fragment>
          <PerformanceEvaluationFormHeader
            viewer={viewer}
            pef={pef}
            reviewType={reviewType}
            commentsCheckboxOnChange={setShowSelfEvaluationComments}
            commentsCheckboxValue={showSelfEvaluationComments}
            previousCyclesButtonOnClick={showModal}
            ratingOptions={ratingOptions}
            showMonthlyPerformanceRatings={reviewType === reviewTypes.MANAGER}
          />
          <EnhancedAffix offsetTop={165}>{renderStepsButtons()}</EnhancedAffix>
          <StepsComponent currentStep={step} steps={steps} />
          {renderStatusConfirmModal()}
          {renderSwitch()}
        </React.Fragment>
      </EnhancedCard>
      <ShowPreviousCycle
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        params={props.match.params}
        showPreviousPMCycleActions={showPreviousPMCycleActions}
        previousPMCycle={props.previousPMCycle}
      />
    </div>
  );
};
export default PerformanceEvaluationForm;
