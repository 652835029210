import { connect } from 'react-redux';
import MyProfile from 'components/MyProfile';
import {
  getEmployeeProfileHeaderAction,
  getEmployeeInfoAction,
  getEmployeeJobInfoAction,
  updateEmployeeJobInfoAction,
  deleteEmployeeJobInfoAction,
  createEmployeeJobInfoAction,
  getEmployeeTrackingInfoAction,
  updateEmployeeTrackingInfoAction,
  getEmployeeCompensationAction,
  getBenefitsAction,
  getEmployeeHistoryLogsAction,
  getEmployeeTalentAction,
  updateEmployeeTalentAction,
  updateEmployeeCareerPlanningAction,
  getEmployeeCertificatesAction,
  createEmployeeCertificatesAction,
  deleteEmployeeCertificatesAction,
  updateEmployeeCertificatesAction,
  getEmployeeEmploymentHistoryAction,
  getEmployeeEducationHistoryAction,
  createEmployeeEducationHistoryAction,
  deleteEmployeeEducationHistoryAction,
  updateEmployeeEducationHistoryAction,
  updateEmployeeEmploymentHistoryAction,
  createEmployeeEmploymentHistoryAction,
  deleteEmployeeEmploymentHistoryAction,
  getEmployeeCommentAction,
  createEmployeeCommentAction,
  deleteEmployeeCommentAction,
  getEmployeeDocumentsAction,
  getMaritalStatusesAction,
  getEmploymentStatusesAction,
  getReligionsAction,
  getBloodGroupsAction,
  getQualificationsAction,
  updateEmployeeInfoAction,
  updateEmployeeEmergencyContactAction,
  deleteEmployeeEmergencyContactAction,
  createEmployeeEmergencyContactAction,
  createEmployeeFamilyInfoAction,
  updateEmployeeFamilyInfoAction,
  deleteEmployeeFamilyInfoAction,
  getEmployeeDocumentURLAction,
  uploadEmployeeDocumentAction,
  updateEmployeePictureAction,
  getCommentAttachmentURLAction,
  deleteEmployeeDocumentAction,
  getEmployeeJobsListingAction,
  getReasonsAction,
  getPayFrequenciesAction,
  updateEmployeeCompensationAction,
  createEmployeeCompensationAction,
  getDropdownOptionsAction,
} from 'store/actions/MyProfileActions';
import {
  getEmployeesSkillsActions,
  getAllEmployeesActions,
  getEmployeeBusinessUnitAction,
  getEmployeeDivisionsAction,
  getEmployeeCompetenciesAction,
  getEmployeeDepartmentsAction,
} from 'store/actions/EmployeeActions';
import {
  getMiscellaneousDataAction,
  getJobDescriptionDocumentURLAction,
} from 'store/actions/MiscellaneousActions';
import updateBreadCrumb from 'store/actions/BreadCrumbActions';
import { getAllLocationActions } from 'store/actions/LocationActions';

const mapStateToProps = (
  { auth, myProfileReducer, miscellaneousReducer, employeeReducer, locationsReducer },
  ownProps,
) => ({
  user: auth.user,
  isLoading: myProfileReducer.isLoading,
  cardLoading: myProfileReducer.cardLoading,
  profileHeader: myProfileReducer.profileHeader,
  employeeInfo: myProfileReducer.employeeInfo,
  dropDowns: myProfileReducer.dropDowns,
  jobInfo: myProfileReducer.jobInfo,
  trackingInfo: myProfileReducer.trackingInfo,
  compensation: myProfileReducer.compensation,
  employeeCertificates: myProfileReducer.employeeCertificates,
  payFrequencies: myProfileReducer.payFrequencies,
  reasons: myProfileReducer.reasons,
  benefits: myProfileReducer.benefits,
  jobsListing: myProfileReducer.jobsListing,
  talentManagement: myProfileReducer.talentManagement,
  skillsOptions: employeeReducer.skills,
  employmentHistory: myProfileReducer.employmentHistory,
  educationHistory: myProfileReducer.educationHistory,
  documents: myProfileReducer.documents,
  comments: myProfileReducer.comments,
  employeeDocumentUrl: myProfileReducer.employeeDocumentUrl,
  commentAttachmentUrl: myProfileReducer.commentAttachmentUrl,
  designations: miscellaneousReducer.designations,
  divisions: miscellaneousReducer.divisions,
  employmentTypes: miscellaneousReducer.employmentTypes,
  jobDescriptionDocumentURL: miscellaneousReducer.jobDescriptionDocumentURL,
  isMiscellaneousLoading: miscellaneousReducer.isMiscellaneousLoading,
  allEmployees: employeeReducer.allEmployees,
  employees: employeeReducer.employees,
  employee:
    employeeReducer.employees.filter(
      (emp) => emp.id === parseInt(ownProps.match.params.id, 10),
    )[0] || {},
  businessUnits: employeeReducer.businessUnits,
  empDivisions: employeeReducer.divisions,
  competencies: employeeReducer.competencies,
  departments: employeeReducer.departments,
  locations: locationsReducer.locations,
  auditLogs: myProfileReducer.auditLogs,
});

function mapDispatchToProps(dispatch) {
  return {
    getEmployeeProfileHeader: (employeeId) => dispatch(getEmployeeProfileHeaderAction(employeeId)),
    getEmployeeInfo: (employeeId) => dispatch(getEmployeeInfoAction(employeeId)),
    getMaritalStatus: () => dispatch(getMaritalStatusesAction()),
    getEmploymentStatuses: () => dispatch(getEmploymentStatusesAction()),
    getBloodGroups: () => dispatch(getBloodGroupsAction()),
    getReligions: () => dispatch(getReligionsAction()),
    getQualifications: () => dispatch(getQualificationsAction()),
    getEmployeesSkills: () => dispatch(getEmployeesSkillsActions()),
    updateEmployeeInfo: (employeeId, data) => dispatch(updateEmployeeInfoAction(employeeId, data)),
    createEmployeeFamilyInfo: (id, data) => dispatch(createEmployeeFamilyInfoAction(id, data)),
    updateEmployeeFamilyInfo: (index, employeeId, familyInfoId, data) =>
      dispatch(updateEmployeeFamilyInfoAction(index, employeeId, familyInfoId, data)),
    deleteEmployeeFamilyInfo: (index, employeeId, familyInfoId) =>
      dispatch(deleteEmployeeFamilyInfoAction(index, employeeId, familyInfoId)),
    getEmployeeJobInfo: (employeeId) => dispatch(getEmployeeJobInfoAction(employeeId)),
    updateEmployeeJobInfo: (employeeId, jobInfoId, data, index) =>
      dispatch(updateEmployeeJobInfoAction(employeeId, jobInfoId, data, index)),
    deleteEmployeeJobInfo: (employeeId, jobInfoId, index) =>
      dispatch(deleteEmployeeJobInfoAction(employeeId, jobInfoId, index)),
    createEmployeeJobInfo: (employeeId, data, listingData) =>
      dispatch(createEmployeeJobInfoAction(employeeId, data, listingData)),
    getEmployeeTrackingInfo: (employeeId) => dispatch(getEmployeeTrackingInfoAction(employeeId)),
    updateEmployeeTrackingInfo: (employeeId, data) =>
      dispatch(updateEmployeeTrackingInfoAction(employeeId, data)),
    getBenefits: () => dispatch(getBenefitsAction()),
    getEmployeeCompensation: (employeeId) => dispatch(getEmployeeCompensationAction(employeeId)),
    updateEmployeeCompensation: (employeeId, compensationId, data) =>
      dispatch(updateEmployeeCompensationAction(employeeId, compensationId, data)),
    createEmployeeCompensation: (employeeId, data) =>
      dispatch(createEmployeeCompensationAction(employeeId, data)),
    getPayFrequencies: (employeeId) => dispatch(getPayFrequenciesAction(employeeId)),
    getReasons: () => dispatch(getReasonsAction()),
    getEmployeeJobsListing: (employeeId) => dispatch(getEmployeeJobsListingAction(employeeId)),
    getEmployeeTalent: (employeeId) => dispatch(getEmployeeTalentAction(employeeId)),
    updateEmployeeTalent: (employeeId, data) =>
      dispatch(updateEmployeeTalentAction(employeeId, data)),
    updateEmployeeCareerPlanning: (employeeId, data) =>
      dispatch(updateEmployeeCareerPlanningAction(employeeId, data)),
    createEmployeeCertificates: (employeeId, data) =>
      dispatch(createEmployeeCertificatesAction(employeeId, data)),
    getEmployeeCertificates: (employeeId, data) =>
      dispatch(getEmployeeCertificatesAction(employeeId, data)),
    updateEmployeeCertificates: (employeeId, certificatesId, data, index) =>
      dispatch(updateEmployeeCertificatesAction(employeeId, certificatesId, data, index)),
    deleteEmployeeCertificates: (employeeId, educationId, index) =>
      dispatch(deleteEmployeeCertificatesAction(employeeId, educationId, index)),
    getEmployeeEmploymentHistory: (employeeId) =>
      dispatch(getEmployeeEmploymentHistoryAction(employeeId)),
    getEmployeeEducationHistory: (employeeId) =>
      dispatch(getEmployeeEducationHistoryAction(employeeId)),
    createEmployeeEducationHistory: (employeeId, data) =>
      dispatch(createEmployeeEducationHistoryAction(employeeId, data)),
    updateEmployeeEducationHistory: (employeeId, certificatesId, data, index) =>
      dispatch(updateEmployeeEducationHistoryAction(employeeId, certificatesId, data, index)),
    deleteEmployeeEducationHistory: (employeeId, educationId, index) =>
      dispatch(deleteEmployeeEducationHistoryAction(employeeId, educationId, index)),
    updateEmployeeEmployment: (employeeId, employmentId, data, index) =>
      dispatch(updateEmployeeEmploymentHistoryAction(employeeId, employmentId, data, index)),
    createEmployeeEmployment: (employeeId, data, index) =>
      dispatch(createEmployeeEmploymentHistoryAction(employeeId, data, index)),
    deleteEmployeeEmployment: (employeeId, employmentId) =>
      dispatch(deleteEmployeeEmploymentHistoryAction(employeeId, employmentId)),
    getEmployeeDocuments: (employeeId) => dispatch(getEmployeeDocumentsAction(employeeId)),
    getEmployeeDocumentURL: (employeeId, documentId) =>
      dispatch(getEmployeeDocumentURLAction(employeeId, documentId)),
    getCommentAttachmentURL: (employeeId, documentId) =>
      dispatch(getCommentAttachmentURLAction(employeeId, documentId)),
    getJobDescriptionDocumentURL: (documentId) =>
      dispatch(getJobDescriptionDocumentURLAction(documentId)),
    uploadEmployeeDocument: (employeeId, documentId, empId) =>
      dispatch(uploadEmployeeDocumentAction(employeeId, documentId, empId)),
    updateEmployeePicture: (employeeId, base64) =>
      dispatch(updateEmployeePictureAction(employeeId, base64)),
    deleteEmployeeDocument: (employeeId, documentId) =>
      dispatch(deleteEmployeeDocumentAction(employeeId, documentId)),
    getEmployeeComment: (employeeId) => dispatch(getEmployeeCommentAction(employeeId)),
    createEmployeeComment: (employeeId, data) =>
      dispatch(createEmployeeCommentAction(employeeId, data)),
    deleteEmployeeComment: (employeeId, commentId) =>
      dispatch(deleteEmployeeCommentAction(employeeId, commentId)),
    updateBreadCrumb: (key, oldVal, newVal) => dispatch(updateBreadCrumb(key, oldVal, newVal)),
    createEmployeeEmergencyContact: (employeeId, data) =>
      dispatch(createEmployeeEmergencyContactAction(employeeId, data)),
    updateEmployeeEmergencyContact: (employeeId, data) =>
      dispatch(updateEmployeeEmergencyContactAction(employeeId, data)),
    deleteEmployeeEmergencyContact: (employeeId, emergencyContactId) =>
      dispatch(deleteEmployeeEmergencyContactAction(employeeId, emergencyContactId)),
    getMiscellaneousData: () => dispatch(getMiscellaneousDataAction()),
    getAllEmployees: () => dispatch(getAllEmployeesActions()),
    getEmployeeBusinessUnits: () => dispatch(getEmployeeBusinessUnitAction()),
    getEmployeeDivisionsAction: () => dispatch(getEmployeeDivisionsAction()),
    getEmployeeCompetenciesAction: () => dispatch(getEmployeeCompetenciesAction()),
    getEmployeeDepartments: () => dispatch(getEmployeeDepartmentsAction()),
    getAllLocations: () => dispatch(getAllLocationActions()),
    getDropdownOptions: () => dispatch(getDropdownOptionsAction()),
    getEmployeeHistoryLogs: (employeeId) => dispatch(getEmployeeHistoryLogsAction(employeeId)),
  };
}

const MyProfileContainer = connect(mapStateToProps, mapDispatchToProps)(MyProfile);

export default MyProfileContainer;
