import { connect } from 'react-redux';

import Project from 'components/project/Project';

import {
  getProjectsAction,
  createProjectAction,
  activateProjectAction,
  resetProjectActivation,
} from 'store/actions/ProjectActions';
import { getEmployeesActions, getEmployeeBusinessUnitAction } from 'store/actions/EmployeeActions';
import { getProjectRolesAction } from 'store/actions/ProjectRolesActions';

function mapStateToProps(state) {
  const { auth, projectReducer, employeeReducer, projectRolesReducer } = state;
  return {
    user: auth.user,
    readOnly: auth.user && auth.user.isReadOnly,
    isCRMC1: auth.user && auth.user.isCRMC1,
    isCRMC2: auth.user && auth.user.isCRMC2,
    projects: projectReducer.projects,
    isLoading: projectReducer.isLoading,
    isAdding: projectReducer.isAdding,
    employees: employeeReducer.employees,
    projectRoles: projectRolesReducer.projectRoles,
    isActivated: projectReducer.isActivated,
    businessUnits: employeeReducer.businessUnits,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectsAction: () => dispatch(getProjectsAction()),
    getEmployeesActions: (area) => dispatch(getEmployeesActions(area)),
    addProject: (project) => dispatch(createProjectAction(project)),
    getProjectRoles: () => dispatch(getProjectRolesAction()),
    activateProject: (req) => dispatch(activateProjectAction(req)),
    resetProjectActivation: () => dispatch(resetProjectActivation()),
    getBusinessUnits: () => dispatch(getEmployeeBusinessUnitAction()),
  };
}

const ProjectContainer = connect(mapStateToProps, mapDispatchToProps)(Project);

export default ProjectContainer;
