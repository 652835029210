import {
  LEAVES_STATUSES_GET,
  LEAVES_STATUSES_GET_SUCCESS,
  LEAVES_STATUSES_GET_FAIL,
} from 'constants/MyLeavesTypes';

const initialState = {
  leaveTypes: [],
  leavesStatuses: [],
};
export default function leavesStatusesReducer(state = initialState, action) {
  switch (action.type) {
    case LEAVES_STATUSES_GET: {
      return Object.assign({}, state, {
        leavesStatuses: [],
      });
    }
    case LEAVES_STATUSES_GET_SUCCESS: {
      return Object.assign({}, state, {
        leavesStatuses: action.data,
      });
    }
    case LEAVES_STATUSES_GET_FAIL: {
      return Object.assign({}, state, {
        leavesStatuses: [],
      });
    }
    default:
      return state;
  }
}
