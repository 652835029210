import { EnhancedRow, EnhancedButton, EnhancedCard, EnhancedForm } from 'components/shared/antd';
import React from 'react';
import { ROW_GUTTER_SIZE } from 'configs/employeeProfileConstants';
import { noData, createEmergencyContact, createSubHeading } from 'utils/FieldGenerateUtils';
import Permissions from 'constants/AccessPermissions';
import { hasPermission } from 'utils/AccessUtils';
import AddButton from 'components/shared/employeeProfile/AddButton';
import Contact from './contact';

const initialState = {
  employeeInfo: {
    fullname: '',
    contactNo: '',
    relationship: '',
  },
  disableSave: true,
  disableCreateButton: false,
};
class EmergencyContact extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
  }

  handleReset = () => {
    this.setState({ ...initialState });
  };

  handleSave = (updatedData) => {
    const { employeeId } = this.props;
    this.props.updateEmployeeEmergencyContact(employeeId, updatedData);
  };

  handleDelete = (emergencyContactId) => {
    const { employeeId } = this.props;
    this.props.deleteEmployeeEmergencyContact(employeeId, emergencyContactId);
  };

  handleChange = (value, key, error) => {
    const currentData = this.state.employeeInfo;
    const newData = Object.assign({}, currentData, { [key]: value });
    const isValid = Object.keys(newData).every((k) => newData[k] !== '');
    this.setState({ employeeInfo: newData, disableSave: !isValid });
    if (error) {
      this.setState({ disableSave: true });
    }
  };

  handleAdd = (e) => {
    e.preventDefault();
    const { employeeInfo } = this.state;
    const { employeeId } = this.props;
    this.props.createEmployeeEmergencyContact(employeeId, employeeInfo);
    this.handleReset();
  };

  showCreateForm = () => {
    this.setState({ disableCreateButton: true });
  };

  render() {
    const { userPermissions, cardLoading } = this.props;
    const { employeeInfo, disableCreateButton } = this.state;
    const canCreate = hasPermission(userPermissions, [
      Permissions.Employee.Profile.Info.EmergencyContact.Create,
    ]);
    const canEdit = hasPermission(userPermissions, [
      Permissions.Employee.Profile.Info.EmergencyContact.Update,
    ]);
    const canDelete = hasPermission(userPermissions, [
      Permissions.Employee.Profile.Info.EmergencyContact.Delete,
    ]);
    const emergencyContacts = this.props.emergencyContacts || [];

    return (
      <EnhancedCard className="mt-20 br-5 pos-rel">
        {canCreate && emergencyContacts.length < 2 && (
          <AddButton
            clickHandler={this.showCreateForm}
            buttonText="Add New Contact"
            disabled={disableCreateButton}
            buttonClasses={['btn-emergency-contact']}
          />
        )}
        {createSubHeading('Emergency Contacts (You can add up to two contacts)')}
        {disableCreateButton && (
          <EnhancedCard loading={cardLoading} className="mb-10 card-shadow" hoverable>
            {/* <EnhancedRow gutter={ROW_GUTTER_SIZE} className="mb-10"> */}
            <EnhancedForm>
              {createEmergencyContact(employeeInfo, true, userPermissions, this.handleChange)}
              <div className="flex justify-flex-end mt-20" style={{ paddingRight: 24 }}>
                <EnhancedButton onClick={this.handleReset} className="mr-10">
                  Cancel
                </EnhancedButton>
                <EnhancedButton
                  className="pr-10"
                  disabled={this.state.disableSave}
                  onClick={this.handleAdd}
                >
                  {'Add Contact'}
                </EnhancedButton>
              </div>
            </EnhancedForm>
            {/* </EnhancedRow> */}
          </EnhancedCard>
        )}
        {emergencyContacts.length ? (
          <EnhancedRow gutter={ROW_GUTTER_SIZE}>
            {emergencyContacts.map((val) => (
              <Contact
                canEdit={canEdit}
                canDelete={canDelete}
                val={val}
                userPermissions={userPermissions}
                onSave={this.handleSave}
                onDelete={this.handleDelete}
                cardLoading={cardLoading}
              />
            ))}
          </EnhancedRow>
        ) : (
          noData()
        )}
      </EnhancedCard>
    );
  }
}

export default EmergencyContact;
