import { connect } from 'react-redux';

import LogWork from 'components/LogWork';

import { createWorkLogAction, updateWorkLogAction } from 'store/actions/WorkLogActions';
import { getProjectsAction, getWorkLogProjectsAction } from 'store/actions/ProjectActions';

const mapStateToProps = (state) => {
  const { auth, projectReducer, workLogReducer, historyReducer } = state;
  return {
    user: auth.user,
    mostEngagingProjects: historyReducer.mostEngagingProjects,
    projects: projectReducer.projects,
    isLoading: workLogReducer.isLoading,
    successMessage: workLogReducer.successMessage,
    errorMessage: workLogReducer.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getWorkLogProjects: () => dispatch(getWorkLogProjectsAction()),
  getProjects: () => dispatch(getProjectsAction()),
  createWorkLog: (req, employeeId) => dispatch(createWorkLogAction(req, employeeId)),
  updateEmployeeWorkLog: (req, employeeId, workId, updateType = 'default') =>
    dispatch(updateWorkLogAction(req, employeeId, workId, updateType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogWork);
