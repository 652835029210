import {
  EnhancedRow,
  EnhancedCol,
  EnhancedCard,
  EnhancedDivider,
  EnhancedForm,
} from 'components/shared/antd';
import React, { useEffect, useState, useRef } from 'react';
import {
  ROW_GUTTER_SIZE,
  FULL_GRID_SIZE,
  TEXT,
  TEXTAREA,
  RATING,
  reviewTypes,
} from 'configs/employeeProfileConstants';
import {
  createFields,
  // createSubHeading,
} from 'utils/FieldGenerateUtils';
import { competenciesByCategory } from 'normalizers/performanceManagementNormalizer';
import AsteriskLabel from '../../shared/performanceEvaluationForm/asteriskHighlighted';
import { calculateCompetenciesOverallRating } from '../../../utils/performanceManagementUtils';
import Ratings from '../../shared/performanceEvaluationForm/Ratings';

const CompetenciesStep = (props) => {
  const {
    viewer,
    isEditable,
    data,
    updateHandler,
    reviewType,
    selfEvaluation,
    showSelfEvaluationComments,
    ratingOptions,
    pefData,
    emptyRatings,
    setEmptyRatings,
  } = props;
  const [competencies, setCompetencies] = useState([]);
  const categoryToCompetencies = useRef();
  const [competenciesOverallRating, setCompetenciesOverallRating] = useState({
    manager: 0.0,
    self: 0.0,
  });

  useEffect(() => {
    setCompetencies(data);
    categoryToCompetencies.current = competenciesByCategory(data);
  }, [data]);

  useEffect(() => {
    if (reviewType === reviewTypes.MANAGER) {
      setCompetenciesOverallRating({
        manager: calculateCompetenciesOverallRating(pefData.competencies),
        self:
          pefData.selfEvaluation === undefined
            ? competenciesOverallRating.self
            : calculateCompetenciesOverallRating(pefData.selfEvaluation.competencies),
      });
    } else {
      setCompetenciesOverallRating({
        manager: competenciesOverallRating.manager,
        self: calculateCompetenciesOverallRating(pefData.competencies),
      });
    }
  }, []);

  const handleSave = () => {};

  const handleChange = (value, key, error) => {
    if (!error) {
      const cloneCompetencies = [...competencies];
      const index = key.split('-');

      const foundCompetencyIndex = cloneCompetencies.findIndex(
        (competency) => competency.raterAttributeId === +index[1],
      );
      cloneCompetencies[foundCompetencyIndex][index[0]] = value;

      // deletes the competency name from error list if the rating is marked and becomes greater than 0
      const updatedCompetencyErrorArr = emptyRatings.competencies.filter(
        (item) => item !== cloneCompetencies[foundCompetencyIndex].name,
      );
      setEmptyRatings({
        competencies: updatedCompetencyErrorArr,
        goals: emptyRatings.goals,
        commentLength: emptyRatings.commentLength,
        hasError: emptyRatings.hasError,
      });

      if (reviewType === reviewTypes.MANAGER) {
        setCompetenciesOverallRating({
          manager: calculateCompetenciesOverallRating(pefData.competencies),
          self: competenciesOverallRating.self,
        });
      } else {
        setCompetenciesOverallRating({
          manager: competenciesOverallRating.manager,
          self: calculateCompetenciesOverallRating(pefData.competencies),
        });
      }
      setCompetencies(cloneCompetencies);
      updateHandler('competencies', cloneCompetencies);
    }
  };

  const getFieldsConfig = (competency, index) => {
    const fields = [
      {
        key: `rating-${index}`,
        label: <AsteriskLabel asterisk={`${viewer} Rating`} />,
        value: competency.rating,
        type: RATING,
        showRatingText: true,
        ratingOptions,
      },
      {
        key: `comment-${index}`,
        label: `${viewer} Comments`,
        value: competency.comment,
        type: TEXTAREA,
        noCapitalize: true,
        className: 'break-spaces',
      },
    ];

    if (selfEvaluation) {
      const competencyObj = selfEvaluation.competencies.find(
        (item) => item.ratingAttributeId === competency.ratingAttributeId,
      );

      fields.push({
        key: `selfrating-${index}`,
        label: `Employee Rating`,
        value: competencyObj.rating,
        type: RATING,
        disabled: true,
        showRatingText: true,
        ratingOptions,
      });

      if (showSelfEvaluationComments) {
        fields.push({
          key: `selfcomment-${index}`,
          label: `Employee Comments`,
          value: competencyObj.comment,
          type: TEXT,
          noCapitalize: true,
          className: 'break-spaces',
        });
      }
    }

    return fields;
  };

  return (
    <div>
      <EnhancedCard>
        <React.Fragment>
          <EnhancedRow type="flex" justify="space-between">
            <div>
              <h2>
                Competencies <strong>(Weight: 30.00%)</strong>
              </h2>
            </div>
            {reviewType === reviewTypes.MANAGER && (
              <Ratings
                reviewType="manager"
                value={competenciesOverallRating.manager}
                options={ratingOptions}
              />
            )}
            {(reviewType === reviewTypes.SELF ||
              (reviewType === reviewTypes.MANAGER && pefData.selfEvaluation !== undefined)) && (
              <Ratings
                reviewType="employee"
                value={competenciesOverallRating.self}
                options={ratingOptions}
              />
            )}
          </EnhancedRow>
          {categoryToCompetencies.current &&
            Object.keys(categoryToCompetencies.current).map((category) => {
              const competencyArr = categoryToCompetencies.current[category];
              return (
                <React.Fragment>
                  <h3 className="category-name">{category}</h3>
                  {competencyArr.map((competency) => {
                    return (
                      <React.Fragment>
                        <EnhancedRow key={competency.email} gutter={ROW_GUTTER_SIZE}>
                          <EnhancedCol span={FULL_GRID_SIZE}>
                            <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
                              loading={false}
                              bordered={false}
                              className="card-pd-0"
                            >
                              <div>
                                <EnhancedForm onSubmit={handleSave}>
                                  {createFields(
                                    [
                                      {
                                        key: `name-${competency.raterAttributeId}`,
                                        label: 'Compentency Name',
                                        value: competency.name,
                                        type: TEXT,
                                        noCapitalize: true,
                                        labelSize: 4,
                                        valueSize: 20,
                                      },
                                      {
                                        key: `criteria-${competency.raterAttributeId}`,
                                        label: 'Criteria',
                                        value: competency.description,
                                        type: TEXT,
                                        noCapitalize: true,
                                        labelSize: 4,
                                        valueSize: 20,
                                      },
                                    ],
                                    1,
                                    isEditable,
                                    [],
                                    handleChange,
                                  )}
                                  {createFields(
                                    getFieldsConfig(competency, competency.raterAttributeId),
                                    2,
                                    isEditable,
                                    [],
                                    handleChange,
                                  )}
                                </EnhancedForm>
                              </div>
                            </EnhancedCard>
                          </EnhancedCol>
                        </EnhancedRow>
                        <EnhancedDivider />
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            })}
        </React.Fragment>
      </EnhancedCard>
    </div>
  );
};
export default CompetenciesStep;
