export const GET_EMPLOYEE_PROFILE_HEADER_INIT = 'GET_EMPLOYEE_PROFILE_HEADER_INIT';
export const GET_EMPLOYEE_PROFILE_HEADER_SUCCESS = 'GET_EMPLOYEE_PROFILE_HEADER_SUCCESS';
export const GET_EMPLOYEE_PROFILE_HEADER_FAILURE = 'GET_EMPLOYEE_PROFILE_HEADER_FAILURE';
export const EMPLOYEE_PROFILE_CLEAR = 'EMPLOYEE_PROFILE_CLEAR';
export const UPDATE_EMPLOYEE_TRACKING_INFO_INIT = 'UPDATE_EMPLOYEE_TRACKING_INFO_INIT';
export const UPDATE_EMPLOYEE_TRACKING_INFO_SUCCESS = 'UPDATE_EMPLOYEE_TRACKING_INFO_SUCCESS';
export const UPDATE_EMPLOYEE_TRACKING_INFO_FAILURE = 'UPDATE_EMPLOYEE_TRACKING_INFO_FAILURE';
export const GET_EMPLOYEE_COMPENSATION_INIT = 'GET_EMPLOYEE_COMPENSATION_INIT';
export const GET_EMPLOYEE_COMPENSATION_SUCCESS = 'GET_EMPLOYEE_COMPENSATION_SUCCESS';
export const GET_EMPLOYEE_COMPENSATION_FAILURE = 'GET_EMPLOYEE_COMPENSATION_FAILURE';
export const GET_BENEFITS_SUCCESS = 'GET_BENEFITS_SUCCESS';
export const GET_BENEFITS_FAILURE = 'GET_BENEFITS_FAILURE';
export const GET_PAY_FREQUENCIES_SUCCESS = 'GET_PAY_FREQUENCIES_SUCCESS';
export const GET_PAY_FREQUENCIES_FAILURE = 'GET_PAY_FREQUENCIES_FAILURE';
export const GET_REASONS_SUCCESS = 'GET_REASONS_SUCCESS';
export const GET_REASONS_FAILURE = 'GET_REASONS_FAILURE';
export const GET_EMPLOYEE_JOBS_LISTING_SUCCESS = 'GET_EMPLOYEE_JOBS_LISTING_SUCCESS';
export const GET_EMPLOYEE_JOBS_LISTING_FAILURE = 'GET_EMPLOYEE_JOBS_LISTING_FAILURE';
export const UPDATE_EMPLOYEE_COMPENSATION_INIT = 'UPDATE_EMPLOYEE_COMPENSATION_INIT';
export const UPDATE_EMPLOYEE_COMPENSATION_SUCCESS = 'UPDATE_EMPLOYEE_COMPENSATION_SUCCESS';
export const UPDATE_EMPLOYEE_COMPENSATION_FAILURE = 'UPDATE_EMPLOYEE_COMPENSATION_FAILURE';
export const CREATE_EMPLOYEE_COMPENSATION_INIT = 'CREATE_EMPLOYEE_COMPENSATION_INIT';
export const CREATE_EMPLOYEE_COMPENSATION_SUCCESS = 'CREATE_EMPLOYEE_COMPENSATION_SUCCESS';
export const CREATE_EMPLOYEE_COMPENSATION_FAILURE = 'CREATE_EMPLOYEE_COMPENSATION_FAILURE';
export const DELETE_EMPLOYEE_COMPENSATION_INIT = 'DELETE_EMPLOYEE_COMPENSATION_INIT';
export const DELETE_EMPLOYEE_COMPENSATION_SUCCESS = 'DELETE_EMPLOYEE_COMPENSATION_SUCCESS';
export const DELETE_EMPLOYEE_COMPENSATION_FAILURE = 'CREATE_EMPLOYEE_COMPENSATION_FAILURE';
export const GET_EMPLOYEE_EMPLOYMENT_HISTORY_INIT = 'GET_EMPLOYEE_EMPLOYMENT_HISTORY_INIT';
export const GET_EMPLOYEE_EMPLOYMENT_HISTORY_SUCCESS = 'GET_EMPLOYEE_EMPLOYMENT_HISTORY_SUCCESS';
export const GET_EMPLOYEE_EMPLOYMENT_HISTORY_FAILURE = 'GET_EMPLOYEE_EMPLOYMENT_HISTORY_FAILURE';
export const GET_EMPLOYEE_AUDIT_LOGS_SUCCESS = 'GET_EMPLOYEE_AUDIT_LOGS_SUCCESS';
export const GET_EMPLOYEE_AUDIT_LOGS_FAILURE = 'GET_EMPLOYEE_AUDIT_LOGS_FAILURE';

// Employee Info case types
export const GET_EMPLOYEE_PROFILE_INIT = 'GET_EMPLOYEE_PROFILE_INIT';
export const GET_EMPLOYEE_AUDIT_LOGS_INIT = 'GET_EMPLOYEE_AUDIT_LOGS_INIT';
export const GET_EMPLOYEE_INFO_SUCCESS = 'GET_EMPLOYEE_INFO_SUCCESS';
export const GET_EMPLOYEE_INFO_FAILURE = 'GET_EMPLOYEE_INFO_FAILURE';
export const UPDATE_EMPLOYEE_INFO_INIT = 'UPDATE_EMPLOYEE_INFO_INIT';
export const UPDATE_EMPLOYEE_INFO_SUCCESS = 'UPDATE_EMPLOYEE_INFO_SUCCESS';
export const UPDATE_EMPLOYEE_INFO_FAILURE = 'UPDATE_EMPLOYEE_INFO_FAILURE';

// Employee Job Info case types
export const JOB_INFO_CALL_INIT = 'JOB_INFO_CALL_INIT';
export const JOB_INFO_CALL_FAILURE = 'JOB_INFO_CALL_FAILURE';
export const GET_EMPLOYEE_JOB_INFO_INIT = 'GET_EMPLOYEE_JOB_INFO_INIT';
export const GET_EMPLOYEE_JOB_INFO_SUCCESS = 'GET_EMPLOYEE_JOB_INFO_SUCCESS';
export const UPDATE_EMPLOYEE_JOB_INFO_SUCCESS = 'UPDATE_EMPLOYEE_JOB_INFO_SUCCESS';
export const DELETE_EMPLOYEE_JOB_INFO_SUCCESS = 'DELETE_EMPLOYEE_JOB_INFO_SUCCESS';
export const CREATE_EMPLOYEE_JOB_INFO_SUCCESS = 'CREATE_EMPLOYEE_JOB_INFO_SUCCESS';

// Dropdown types
export const GET_MARITAL_STATUSES_INIT = 'GET_MARITAL_STATUSES_INIT';
export const GET_MARITAL_STATUSES_SUCCESS = 'GET_MARITAL_STATUSES_SUCCESS';
export const GET_MARITAL_STATUSES_FAILURE = 'GET_MARITAL_STATUSES_FAILURE';
export const GET_EMPLOYMENT_STATUSES_INIT = 'GET_EMPLOYMENT_STATUSES_INIT';
export const GET_EMPLOYMENT_STATUSES_SUCCESS = 'GET_EMPLOYMENT_STATUSES_SUCCESS';
export const GET_EMPLOYMENT_STATUSES_FAILURE = 'GET_EMPLOYMENT_STATUSES_FAILURE';
export const GET_BLOOD_GROUPS_INIT = 'GET_BLOOD_GROUPS_INIT';
export const GET_BLOOD_GROUPS_SUCCESS = 'GET_BLOOD_GROUPS_SUCCESS';
export const GET_BLOOD_GROUPS_FAILURE = 'GET_BLOOD_GROUPS_FAILURE';
export const GET_RELIGIONS_INIT = 'GET_RELIGIONS_INIT';
export const GET_RELIGIONS_SUCCESS = 'GET_RELIGIONS_SUCCESS';
export const GET_RELIGIONS_FAILURE = 'GET_RELIGIONS_FAILURE';
export const GET_QUALIFICATIONS_INIT = 'GET_QUALIFICATIONS_INIT';
export const GET_QUALIFICATIONS_SUCCESS = 'GET_QUALIFICATIONS_SUCCESS';
export const GET_QUALIFICATIONS_FAILURE = 'GET_QUALIFICATIONS_FAILURE';

// Employee Emergency Contacts case types
export const UPDATE_EMPLOYEE_EMERGENCY_CONTACT_INIT = 'UPDATE_EMPLOYEE_EMERGENCY_CONTACT_INIT';
export const UPDATE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS =
  'UPDATE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS';
export const UPDATE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE =
  'UPDATE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE';
export const DELETE_EMPLOYEE_EMERGENCY_CONTACT_INIT = 'DELETE_EMPLOYEE_EMERGENCY_CONTACT_INIT';
export const DELETE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS =
  'DELETE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS';
export const DELETE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE =
  'DELETE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE';
export const CREATE_EMPLOYEE_EMERGENCY_CONTACT_INIT = 'CREATE_EMPLOYEE_EMERGENCY_CONTACT_INIT';
export const CREATE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS =
  'CREATE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS';
export const CREATE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE =
  'CREATE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE';

// Employee Family Info case types
export const CREATE_EMPLOYEE_FAMILY_INFO_INIT = 'CREATE_EMPLOYEE_FAMILY_INFO_INIT';
export const CREATE_EMPLOYEE_FAMILY_INFO_SUCCESS = 'CREATE_EMPLOYEE_FAMILY_INFO_SUCCESS';
export const CREATE_EMPLOYEE_FAMILY_INFO_FAILURE = 'CREATE_EMPLOYEE_FAMILY_INFO_FAILURE';
export const UPDATE_EMPLOYEE_FAMILY_INFO_INIT = 'UPDATE_EMPLOYEE_FAMILY_INFO_INIT';
export const UPDATE_EMPLOYEE_FAMILY_INFO_SUCCESS = 'UPDATE_EMPLOYEE_FAMILY_INFO_SUCCESS';
export const UPDATE_EMPLOYEE_FAMILY_INFO_FAILURE = 'UPDATE_EMPLOYEE_FAMILY_INFO_FAILURE';
export const DELETE_EMPLOYEE_FAMILY_INFO_INIT = 'DELETE_EMPLOYEE_FAMILY_INFO_INIT';
export const DELETE_EMPLOYEE_FAMILY_INFO_SUCCESS = 'DELETE_EMPLOYEE_FAMILY_INFO_SUCCESS';
export const DELETE_EMPLOYEE_FAMILY_INFO_FAILURE = 'DELETE_EMPLOYEE_FAMILY_INFO_FAILURE';

// Employee Documents case types
export const GET_EMPLOYEE_DOCUMENTS_INIT = 'GET_EMPLOYEE_DOCUMENTS_INIT';
export const GET_EMPLOYEE_DOCUMENTS_SUCCESS = 'GET_EMPLOYEE_DOCUMENTS_SUCCESS';
export const GET_EMPLOYEE_DOCUMENTS_FAILURE = 'GET_EMPLOYEE_DOCUMENTS_FAILURE';
export const GET_EMPLOYEE_DOCUMENT_URL_SUCCESS = 'GET_EMPLOYEE_DOCUMENT_URL_SUCCESS';
export const CREATE_EMPLOYEE_DOCUMENT_SUCCESS = 'CREATE_EMPLOYEE_DOCUMENT_SUCCESS';
export const CREATE_EMPLOYEE_DOCUMENT_INIT = 'CREATE_EMPLOYEE_DOCUMENT_INIT';
export const CREATE_EMPLOYEE_DOCUMENT_FAILURE = 'CREATE_EMPLOYEE_DOCUMENT_FAILURE';
export const UPDATE_EMPLOYEE_PICTURE_SUCCESS = 'UPDATE_EMPLOYEE_PICTURE_SUCCESS';
export const UPDATE_EMPLOYEE_PICTURE_FAILURE = 'UPDATE_EMPLOYEE_PICTURE_FAILURE';
export const UPDATE_EMPLOYEE_PICTURE_INIT = 'UPDATE_EMPLOYEE_PICTURE_INIT';
export const DELETE_EMPLOYEE_DOCUMENT_INIT = 'DELETE_EMPLOYEE_DOCUMENT_INIT';
export const DELETE_EMPLOYEE_DOCUMENT_SUCCESS = 'DELETE_EMPLOYEE_DOCUMENT_SUCCESS';
export const DELETE_EMPLOYEE_DOCUMENT_FAILURE = 'DELETE_EMPLOYEE_DOCUMENT_FAILURE';
export const GET_EMPLOYEE_DOCUMENT_URL_FAILURE = 'GET_EMPLOYEE_DOCUMENT_URL_FAILURE';

// Employee Comments case types
export const GET_EMPLOYEE_COMMENTS_INIT = 'GET_EMPLOYEE_COMMENTS_INIT';
export const GET_EMPLOYEE_COMMENTS_SUCCESS = 'GET_EMPLOYEE_COMMENTS_SUCCESS';
export const GET_EMPLOYEE_COMMENTS_FAILURE = 'GET_EMPLOYEE_COMMENTS_FAILURE';
export const CREATE_EMPLOYEE_COMMENT_INIT = 'CREATE_EMPLOYEE_COMMENT_INIT';
export const CREATE_EMPLOYEE_COMMENT_SUCCESS = 'CREATE_EMPLOYEE_COMMENT_SUCCESS';
export const CREATE_EMPLOYEE_COMMENT_FAILURE = 'CREATE_EMPLOYEE_COMMENT_FAILURE';
export const DELETE_EMPLOYEE_COMMENT_INIT = 'DELETE_EMPLOYEE_COMMENT_INIT';
export const DELETE_EMPLOYEE_COMMENT_SUCCESS = 'DELETE_EMPLOYEE_COMMENT_SUCCESS';
export const DELETE_EMPLOYEE_COMMENT_FAILURE = 'DELETE_EMPLOYEE_COMMENT_FAILURE';
export const GET_COMMENT_ATTACHMENT_URL_SUCCESS = 'GET_COMMENT_ATTACHMENT_URL_SUCCESS';
export const GET_COMMENT_ATTACHMENT_URL_FAILURE = 'GET_COMMENT_ATTACHMENT_URL_FAILURE';

// Employee Education History case types
export const GET_EMPLOYEE_EDUCATION_HISTORY_INIT = 'GET_EMPLOYEE_EDUCATION_HISTORY_INIT';
export const GET_EMPLOYEE_EDUCATION_HISTORY_SUCCESS = 'GET_EMPLOYEE_EDUCATION_HISTORY_SUCCESS';
export const GET_EMPLOYEE_EDUCATION_HISTORY_FAILURE = 'GET_EMPLOYEE_EDUCATION_HISTORY_FAILURE';
export const CREATE_EMPLOYEE_EDUCATION_HISTORY_INIT = 'CREATE_EMPLOYEE_EDUCATION_HISTORY_INIT';
export const CREATE_EMPLOYEE_EDUCATION_HISTORY_SUCCESS =
  'CREATE_EMPLOYEE_EDUCATION_HISTORY_SUCCESS';
export const CREATE_EMPLOYEE_EDUCATION_HISTORY_FAILURE =
  'CREATE_EMPLOYEE_EDUCATION_HISTORY_FAILURE';
export const EMPLOYMENT_CALL_INIT = 'EMPLOYMENT_CALL_INIT';
export const EMPLOYMENT_CALL_FAILURE = 'EMPLOYMENT_CALL_FAILURE';
export const UPDATE_EMPLOYEE_EMPLOYMENT_SUCCESS = 'UPDATE_EMPLOYEE_EMPLOYMENT_SUCCESS';
export const CREATE_EMPLOYEE_EMPLOYMENT_SUCCESS = 'CREATE_EMPLOYEE_EMPLOYMENT_SUCCESS';
export const DELETE_EMPLOYEE_EMPLOYMENT_SUCCESS = 'DELETE_EMPLOYEE_EMPLOYMENT_SUCCESS';
export const UPDATE_EMPLOYEE_EDUCATION_HISTORY_INIT = 'UPDATE_EMPLOYEE_EDUCATION_HISTORY_INIT';
export const UPDATE_EMPLOYEE_EDUCATION_HISTORY_SUCCESS =
  'UPDATE_EMPLOYEE_EDUCATION_HISTORY_SUCCESS';
export const UPDATE_EMPLOYEE_EDUCATION_HISTORY_FAILURE =
  'UPDATE_EMPLOYEE_EDUCATION_HISTORY_FAILURE';
export const DELETE_EMPLOYEE_EDUCATION_HISTORY_INIT = 'DELETE_EMPLOYEE_EDUCATION_HISTORY_INIT';
export const DELETE_EMPLOYEE_EDUCATION_HISTORY_SUCCESS =
  'DELETE_EMPLOYEE_EDUCATION_HISTORY_SUCCESS';
export const DELETE_EMPLOYEE_EDUCATION_HISTORY_FAILURE =
  'DELETE_EMPLOYEE_EDUCATION_HISTORY_FAILURE';

// Employee Talent Management case types
export const GET_EMPLOYEE_TALENT_INIT = 'GET_EMPLOYEE_TALENT_INIT';
export const GET_EMPLOYEE_TALENT_SUCCESS = 'GET_EMPLOYEE_TALENT_SUCCESS';
export const GET_EMPLOYEE_TALENT_FAILURE = 'GET_EMPLOYEE_TALENT_FAILURE';
export const UPDATE_EMPLOYEE_TALENT_INIT = 'UPDATE_EMPLOYEE_TALENT_INIT';
export const UPDATE_EMPLOYEE_TALENT_SUCCESS = 'UPDATE_EMPLOYEE_TALENT_SUCCESS';
export const UPDATE_EMPLOYEE_TALENT_FAILURE = 'UPDATE_EMPLOYEE_TALENT_FAILURE';

// Employee Career Planning case types
export const UPDATE_EMPLOYEE_CAREER_PLANNING_INIT = 'UPDATE_EMPLOYEE_CAREER_PLANNING_INIT';
export const UPDATE_EMPLOYEE_CAREER_PLANNING_SUCCESS = 'UPDATE_EMPLOYEE_CAREER_PLANNING_SUCCESS';
export const UPDATE_EMPLOYEE_CAREER_PLANNING_FAILURE = 'UPDATE_EMPLOYEE_CAREER_PLANNING_FAILURE';

// Employee Certificates case types
export const CREATE_EMPLOYEE_CERTIFICATES_INIT = 'CREATE_EMPLOYEE_CERTIFICATES_INIT';
export const CREATE_EMPLOYEE_CERTIFICATES_SUCCESS = 'CREATE_EMPLOYEE_CERTIFICATES_SUCCESS';
export const CREATE_EMPLOYEE_CERTIFICATES_FAILURE = 'CREATE_EMPLOYEE_CERTIFICATES_FAILURE';
export const GET_EMPLOYEE_CERTIFICATES_INIT = 'GET_EMPLOYEE_CERTIFICATES_INIT';
export const GET_EMPLOYEE_CERTIFICATES_SUCCESS = 'GET_EMPLOYEE_CERTIFICATES_SUCCESS';
export const GET_EMPLOYEE_CERTIFICATES_FAILURE = 'GET_EMPLOYEE_CERTIFICATES_FAILURE';
export const UPDATE_EMPLOYEE_CERTIFICATES_INIT = 'UPDATE_EMPLOYEE_CERTIFICATES_INIT';
export const UPDATE_EMPLOYEE_CERTIFICATES_SUCCESS = 'UPDATE_EMPLOYEE_CERTIFICATES_SUCCESS';
export const UPDATE_EMPLOYEE_CERTIFICATES_FAILURE = 'UPDATE_EMPLOYEE_CERTIFICATES_FAILURE';
export const DELETE_EMPLOYEE_CERTIFICATES_INIT = 'DELETE_EMPLOYEE_CERTIFICATES_INIT';
export const DELETE_EMPLOYEE_CERTIFICATES_SUCCESS = 'DELETE_EMPLOYEE_CERTIFICATES_SUCCESS';
export const DELETE_EMPLOYEE_CERTIFICATES_FAILURE = 'DELETE_EMPLOYEE_CERTIFICATES_FAILURE';
export const GET_EMPLOYEE_TRACKING_INFO_INIT = 'GET_EMPLOYEE_TRACKING_INFO_INIT';
export const GET_EMPLOYEE_TRACKING_INFO_SUCCESS = 'GET_EMPLOYEE_TRACKING_INFO_SUCCESS';
export const GET_EMPLOYEE_TRACKING_INFO_FAILURE = 'GET_EMPLOYEE_TRACKING_INFO_FAILURE';

// Employee Career Planning case types
export const DE_ACTIVATE_EMPLOYEE_INIT = 'DE_ACTIVATE_EMPLOYEE_INIT';
export const DE_ACTIVATE_EMPLOYEE_SUCCESS = 'DE_ACTIVATE_EMPLOYEE_SUCCESS';
export const DE_ACTIVATE_EMPLOYEE_FAILURE = 'DE_ACTIVATE_EMPLOYEE_FAILURE';

// Employee Goals case types
export const GET_GOAL_INIT = 'GET_GOAL_INIT';
export const GET_GOAL_SUCCESS = 'GET_GOAL_SUCCESS';
export const GET_GOAL_FAILURE = 'GET_GOAL_FAILURE';

export const DELETE_GOAL_INIT = 'DELETE_GOAL_INIT';
export const DELETE_GOAL_SUCCESS = 'DELETE_GOAL_SUCCESS';
export const DELETE_GOAL_FAILURE = 'DELETE_GOAL_FAILURE';

export const GET_GOAL_DETAILS_INIT = 'GET_GOAL_DETAILS_INIT';
export const GET_GOAL_DETAILS_SUCCESS = 'GET_GOAL_DETAILS_SUCCESS';
export const GET_GOAL_DETAILS_FAILURE = 'GET_GOAL_DETAILS_FAILURE';

export const UPDATE_GOAL_INIT = 'UPDATE_GOAL_INIT';
export const UPDATE_GOAL_SUCCESS = 'UPDATE_GOAL_SUCCESS';
export const UPDATE_GOAL_FAILURE = 'UPDATE_GOAL_FAILURE';

export const UPDATE_GOAL_STATUS_INIT = 'UPDATE_GOAL_STATUS_INIT';
export const UPDATE_GOAL_STATUS_SUCCESS = 'UPDATE_GOAL_STATUS_SUCCESS';
export const UPDATE_GOAL_STATUS_FAILURE = 'UPDATE_GOAL_STATUS_FAILURE';

// Employee Performance Review case types
export const GET_PERFORMANCE_REVIEW_INIT = 'GET_PERFORMANCE_REVIEW_INIT';
export const GET_PERFORMANCE_REVIEW_SUCCESS = 'GET_PERFORMANCE_REVIEW_SUCCESS';
export const GET_PERFORMANCE_REVIEW_FAILURE = 'GET_PERFORMANCE_REVIEW_FAILURE';
