import {
  EnhancedButton,
  EnhancedCol,
  EnhancedRow,
  EnhancedCard,
  EnhancedDivider,
  EnhancedSpin,
  EnhancedModal,
  EnhancedInput,
} from 'components/shared/antd';
import React, { Component } from 'react';
import moment from 'moment';
import { dateFormat, claimStatus, EMPLOYEE_EXPENSE_CLAIM } from 'configs/constants';
import _ from 'underscore';

import StatusTag from './StatusTag';

const COMMENT_MAX_LENGTH = 100;
class ClaimDetailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPreview: false,
      // disabledUntilLMapprove: false,
      commentMaxLengthExceed: false,
      approvalComments: '',
      amountReleased: null,
      commentSeeMore: false,
    };
    this.claimedAmount = 0;
    this.approvalDisabledUntilLMApproved = false;
    this.financeApproved = false;
  }

  componentWillReceiveProps() {
    this.claimedAmount = this.getTotalClaim();
    this.setState({ amountReleased: this.getTotalClaim() });
  }

  onSubmit = (action, approvalType) => {
    const { selectedClaim, user } = this.props;
    const { approvalComments, amountReleased } = this.state;
    const statusDto = {};
    selectedClaim.status.forEach((v) => {
      if (approvalType === 'Finance' && v.reference === 'Role') {
        statusDto.id = v.id;
        statusDto.amountReleased = amountReleased;
      } else if (v.referenceId === user.id && v.reference === 'Employee') {
        statusDto.id = v.id;
      }
    });
    statusDto.claimRequestId = selectedClaim.id;
    statusDto.comments = approvalComments;
    if (action === 'Approved') {
      this.props.claimApproved(statusDto);
    } else {
      this.props.claimDeclined(statusDto);
    }
    this.setState({ approvalComments: '' });
  };
  onApproval = (action) => {
    const { selectedClaim, user } = this.props;
    const { approvalComments } = this.state;
    const statusDto = {};
    selectedClaim.status.forEach((v) => {
      if (v.referenceId === user.id) {
        statusDto.id = v.id;
      }
    });
    statusDto.claimRequestId = selectedClaim.id;
    statusDto.comments = approvalComments;
    if (action === 'Approved') {
      this.props.claimApproved(statusDto);
    } else {
      this.props.claimDeclined(statusDto);
    }
    this.setState({ approvalComments: '' });
  };

  onApprovalCommentsChange = (event) => {
    const { approvalComments } = this.state;
    const comments = event.target.value.replace(/ +(?= )/g, '');
    this.setState({ approvalComments: comments }, () => {
      if (this.state.approvalComments.length <= COMMENT_MAX_LENGTH) {
        this.setState({ commentMaxLengthExceed: false });
      } else {
        this.setState({ commentMaxLengthExceed: true }, () => {
          this.setState({ approvalComments: approvalComments.slice(0, COMMENT_MAX_LENGTH) });
        });
      }
    });
  };

  onamountReleasedChange = (event) => {
    const amount = event.target.value;
    if (amount > this.claimedAmount) {
      this.setState({ amountReleased: Number(this.claimedAmount) });
    } else if (amount <= this.claimedAmount && amount > 0) {
      this.setState({ amountReleased: Number(amount) });
    } else {
      this.setState({ amountReleased: null });
    }
  };

  onFinanceApproval = (action) => {
    const { selectedClaim } = this.props;
    const { approvalComments, amountReleased } = this.state;
    const statusDto = {};
    selectedClaim.status.forEach((v) => {
      if (v.approveOrder === 3) {
        statusDto.id = v.id;
      }
    });
    statusDto.claimRequestId = selectedClaim.id;
    statusDto.comments = approvalComments;
    statusDto.amountReleased = amountReleased;
    if (action === 'Approved') {
      this.props.claimApproved(statusDto);
    } else {
      this.props.claimDeclined(statusDto);
    }
  };

  getTotalClaim = () => {
    const { selectedClaim } = this.props;
    let totalAmount = null;
    if (selectedClaim && selectedClaim.claimDetails) {
      selectedClaim.claimDetails.map((v) => {
        if (v.amount) {
          totalAmount += v.amount;
        }
        return totalAmount;
      });
    }
    return totalAmount;
  };
  getClaimType = (singleClaim) => {
    let type = null;
    if (
      singleClaim.claimTypeId === EMPLOYEE_EXPENSE_CLAIM.OTHER.id ||
      singleClaim.claimTypeId === EMPLOYEE_EXPENSE_CLAIM.TRAINING.id
    ) {
      type = singleClaim.description;
    } else {
      ({ type } = this.claimTypeAndCategoryFromClaimId(singleClaim.claimTypeId));
    }
    return type;
  };

  getClaimDetails = () => {
    const { selectedClaim } = this.props;
    const categorizedClaim = {};
    const { claimDetails } = selectedClaim;
    const totalClaimedAmount = this.getTotalClaim().toLocaleString();
    claimDetails.forEach((claim) => {
      if (categorizedClaim[this.claimTypeAndCategoryFromClaimId(claim.claimTypeId).category]) {
        categorizedClaim[this.claimTypeAndCategoryFromClaimId(claim.claimTypeId).category].push(
          claim,
        );
      } else {
        categorizedClaim[this.claimTypeAndCategoryFromClaimId(claim.claimTypeId).category] = [
          claim,
        ];
      }
      // this.claimTypeAndCategoryFromClaimId(element.claimTypeId).category
    });
    return (
      <div>
        <EnhancedRow>
          <EnhancedCol lg={4} className="detail-table-header">
            Category
          </EnhancedCol>
          <EnhancedCol lg={9} className="detail-table-header">
            Type
          </EnhancedCol>
          <EnhancedCol lg={4} className="detail-table-header">
            Start Date
          </EnhancedCol>
          <EnhancedCol lg={4} className="detail-table-header">
            End Date
          </EnhancedCol>
          <EnhancedCol
            lg={3}
            className="detail-table-header"
            style={{ textAlign: 'right', paddingRight: '0px' }}
          >
            Amount
          </EnhancedCol>
        </EnhancedRow>
        <hr />
        {Object.keys(categorizedClaim).map((claimCategory) => {
          // const category = claimCategory;
          const claimDetail = categorizedClaim[claimCategory];
          return claimDetail.map((c, i) => (
            <EnhancedRow>
              <EnhancedCol lg={4}>
                {i === 0 ? this.claimTypeAndCategoryFromClaimId(c.claimTypeId).category : null}
              </EnhancedCol>
              <EnhancedCol lg={9}>
                {this.getClaimType(c)}
                {/* {this.claimTypeAndCategoryFromClaimId(c.claimTypeId).type} */}
              </EnhancedCol>
              <EnhancedCol lg={4}>
                {moment(c.startDate).format(dateFormat.DDMMMYYYYwithSpace)}
              </EnhancedCol>
              <EnhancedCol lg={4}>
                {moment(c.endDate).format(dateFormat.DDMMMYYYYwithSpace)}
              </EnhancedCol>
              <EnhancedCol lg={3} style={{ textAlign: 'right', paddingRight: '0px' }}>
                {c.amount ? `${c.amount.toLocaleString()}` : '-'}
              </EnhancedCol>
            </EnhancedRow>
          ));
        })}
        {/* {
          selectedClaim && selectedClaim.claimDetails && selectedClaim.claimDetails.map(v => {
            return (
              <Row>
                <Col lg={4}>{this.claimTypeAndCategoryFromClaimId(v.claimTypeId).category}</Col>
                <Col lg={11}>{this.claimTypeAndCategoryFromClaimId(v.claimTypeId).type}</Col>
                <Col lg={5}>{moment(v.startDate).format(dateFormat.DDMMMYYYYwithSpace)}</Col>
                <Col lg={4} style={{ textAlign: 'center' }}>
                {v.amount ? `${v.amount} /-` : '-'}</Col>
              </Row>
            )
          })
        } */}
        <EnhancedDivider />
        <EnhancedRow>
          <EnhancedCol lg={18} />
          <EnhancedCol lg={2} style={{ textAlign: 'right' }}>
            <b>Total</b>
          </EnhancedCol>
          <EnhancedCol lg={3} style={{ textAlign: 'right', marginRight: '15px' }}>
            <b>{totalClaimedAmount}</b>
          </EnhancedCol>
        </EnhancedRow>
        {selectedClaim.amountReleased ? (
          <EnhancedRow>
            <EnhancedCol lg={15} />
            <EnhancedCol lg={5} style={{ textAlign: 'right' }}>
              <b>Approved Amount</b>
            </EnhancedCol>
            <EnhancedCol lg={3} style={{ textAlign: 'right', marginRight: '15px' }}>
              <b>{selectedClaim.amountReleased.toLocaleString()}</b>
            </EnhancedCol>
          </EnhancedRow>
        ) : null}
      </div>
    );
  };

  getProjectName = (projectId) => {
    const { claimProjectList } = this.props;
    let projectName = null;
    claimProjectList.forEach((project) => {
      if (project.id === Number(projectId)) {
        projectName = project.name;
      }
    });
    return projectName;
  };

  getHeading = (status) => {
    let heading = null;
    if (status.approveOrder === 1) {
      heading = 'LM Comments: ';
    } else if (status.approveOrder === 2) {
      heading = 'FH Comments: ';
    } else {
      heading = 'FM Comments: ';
    }
    return heading;
  };

  claimTypeAndCategoryFromClaimId = (claimId) => {
    const { claimTypes, claimCategory } = this.props;
    let type = null;
    let parentId = null;
    let category = null;
    claimTypes.forEach((element) => {
      if (element.id === claimId) {
        type = element.claimType;
        ({ parentId } = element);
      }
    });

    claimCategory.forEach((element) => {
      if (element.id === parentId) {
        category = element.claimType;
      }
    });

    return { type, category };
  };

  statusTagColor = (statusDto) => {
    let color = 'gray';
    if (statusDto.statusId === claimStatus.PENDING) {
      color = 'gray';
    }
    if (statusDto.statusId === claimStatus.APPROVED) {
      color = 'green';
    }
    if (statusDto.statusId === claimStatus.DECLINED) {
      color = 'red';
    }
    return color;
  };

  showImageInPreviewMode = (id) => {
    this.setState({
      showPreview: true,
      imageIdInPreview: id,
    });
  };

  closePreview = () => {
    this.setState({ showPreview: false });
  };

  hideModal = () => {
    this.setState({ approvalComments: '', amountReleased: null }, () => {
      this.props.hideModal();
    });
  };

  checkIfLMapprovedFirst = () => {
    const { selectedClaim, user } = this.props;
    this.approvalDisabledUntilLMApproved = false;
    // Approve order 1 must approve first
    // statusId 1 for pending
    if (selectedClaim.status) {
      selectedClaim.status.forEach((v, i) => {
        if (
          v.referenceId === user.id &&
          v.approveOrder === 2 &&
          selectedClaim.status[i - 1].statusId === claimStatus.PENDING
        ) {
          // this.setState({ disabledUntilLMapprove: true })
          this.approvalDisabledUntilLMApproved = true;
        }
        // if (v.approveOrder === 1 && v.statusId === 1) {
        //   this.setState({ disabledUntilLMapprove: true })
        // }
      });
    }
  };

  showApprovalStatus = () => {
    const { selectedClaim } = this.props;
    const { status } = selectedClaim;

    return <StatusTag statuses={status} />;
    // const apprvalStatus = status.map((v, i) => {
    //   return (
    // <div style={{ display: 'inline-block' }}>
    //   <Tooltip placement="topLeft" title={claimIdToStatus[v.statusId]}>
    //     <EnhancedTag
    //       style={{ cursor: 'default' }}
    //       color={this.statusTagColor(v)}
    //       key={i}
    //     >
    //       {claimApproveOrder[v.approveOrder]}
    //     </EnhancedTag>
    //   </Tooltip>
    // </div>
    //   <
    // )

    // })
    // return apprvalStatus;
  };

  commentsMandatoryCheck = () => {
    const { approvalComments } = this.state;
    let isComment = false;
    if (approvalComments && approvalComments.length > 1 && approvalComments.length < 100) {
      isComment = true;
    }
    return isComment;
  };

  mandatoryCheckForFinance = () => {
    const { approvalComments, amountReleased } = this.state;
    let isComment = false;
    if (
      approvalComments &&
      amountReleased !== null &&
      amountReleased !== '' &&
      approvalComments.length > 1 &&
      approvalComments.length < 100
    ) {
      isComment = true;
    }
    return isComment;
  };

  financeDeclinedCheck = () => {
    const { approvalComments } = this.state;
    let declinedButtonShouldDisabled = false;
    if (approvalComments.length > 1 && approvalComments.length < 100) {
      declinedButtonShouldDisabled = true;
    }
    return declinedButtonShouldDisabled;
  };

  disableButtonStyle = (params) => {
    let mandatoryCheck = null;
    if (params === 'financeDeclinedButton') {
      mandatoryCheck = this.financeDeclinedCheck();
    } else {
      mandatoryCheck = this.commentsMandatoryCheck();
    }
    if (this.approvalDisabledUntilLMApproved || !mandatoryCheck) {
      return {
        width: '100%',
      };
    }
    return {
      width: '100%',
      color: 'white',
      backgroundColor: '#ff0000a6',
    };
  };
  financeApprovalSection = () => {
    const { selectedClaim } = this.props;
    const { commentMaxLengthExceed, approvalComments, amountReleased } = this.state;
    const pendingFromFinance = _.findWhere(selectedClaim.status, {
      approveOrder: 3,
      statusId: claimStatus.PENDING,
    });
    // selectedClaim.status.forEach((v) => {
    //   if (v.approveOrder === 3 && v.statusId === claimStatus.APPROVED) {
    //     alreadyApproved = true;
    //   }
    // });

    if (pendingFromFinance) {
      return (
        <EnhancedCard>
          <EnhancedRow>
            <EnhancedCol lg={7} style={{ marginRight: '10px' }}>
              <EnhancedInput
                type="number"
                placeholder="Release Amount"
                value={amountReleased}
                onChange={this.onamountReleasedChange}
              />
            </EnhancedCol>
            <EnhancedCol lg={10} style={{ marginRight: '10px' }}>
              <EnhancedInput
                type="text"
                value={approvalComments}
                placeholder="Mention your comments here"
                onChange={this.onApprovalCommentsChange}
              />
              {commentMaxLengthExceed ? (
                <span style={{ color: 'red' }}>Comment maximume limit exceeded</span>
              ) : null}
            </EnhancedCol>
            <EnhancedCol lg={3} style={{ marginRight: '10px' }}>
              <EnhancedButton
                style={{ width: '100%' }}
                type="primary"
                onClick={() => this.onSubmit('Approved', 'Finance')}
                loading={this.state.approvedLoader}
                disabled={!this.mandatoryCheckForFinance()}
              >
                Approve
              </EnhancedButton>
            </EnhancedCol>
            <EnhancedCol lg={3}>
              <EnhancedButton
                style={this.disableButtonStyle('financeDeclinedButton')}
                type="secondary"
                onClick={() => this.onSubmit('Declined', 'Finance')}
                loading={this.state.declinedLoader}
                disabled={!this.financeDeclinedCheck()}
              >
                Decline
              </EnhancedButton>
            </EnhancedCol>
          </EnhancedRow>
        </EnhancedCard>
      );
    }
    return null;
  };
  approvalSection = () => {
    const { commentMaxLengthExceed, approvalComments } = this.state;
    const { selectedTabIndex, selectedClaim, user } = this.props;
    if (Number(selectedTabIndex) === 2) {
      let alreadyApprovedOrReject = false;
      if (selectedClaim.status) {
        selectedClaim.status.forEach((v, i) => {
          if (
            (v.referenceId === user.id && v.statusId !== claimStatus.PENDING) ||
            (selectedClaim.status[i - 1] &&
              selectedClaim.status[i - 1].statusId === claimStatus.DECLINED)
          ) {
            // This block will execute If LM+1 has already approved
            // Or LM declined
            alreadyApprovedOrReject = true;
          }
        });
      }

      if (!alreadyApprovedOrReject) {
        return (
          <EnhancedCard>
            <EnhancedRow>
              <EnhancedCol lg={17} style={{ marginRight: '10px' }}>
                <EnhancedInput
                  type="text"
                  placeholder="Mention your comments here"
                  value={approvalComments}
                  onChange={this.onApprovalCommentsChange}
                  disabled={this.approvalDisabledUntilLMApproved}
                />
                {commentMaxLengthExceed ? (
                  <span style={{ color: 'red' }}>Comment maximum limit exceeded</span>
                ) : null}
                {this.approvalDisabledUntilLMApproved ? (
                  <span>* Line manager has not approved yet</span>
                ) : null}
              </EnhancedCol>
              <EnhancedCol lg={3} style={{ marginRight: '10px' }}>
                <EnhancedButton
                  style={{ width: '100%' }}
                  type="primary"
                  onClick={() => this.onSubmit('Approved')}
                  loading={this.state.approvedLoader}
                  disabled={
                    this.approvalDisabledUntilLMApproved ||
                    commentMaxLengthExceed ||
                    !this.commentsMandatoryCheck()
                  }
                >
                  Approve
                </EnhancedButton>
              </EnhancedCol>
              <EnhancedCol lg={3}>
                <EnhancedButton
                  style={this.disableButtonStyle()}
                  type="secondary"
                  onClick={() => this.onSubmit('Declined')}
                  loading={this.state.declinedLoader}
                  disabled={
                    this.approvalDisabledUntilLMApproved ||
                    commentMaxLengthExceed ||
                    !this.commentsMandatoryCheck()
                  }
                >
                  Decline
                </EnhancedButton>
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCard>
        );
      }

      return null;
    }

    return null;
  };

  seeMoreComment = () => {
    this.setState({ commentSeeMore: !this.state.commentSeeMore });
  };

  commentsCollapse = (comments) => {
    let commentElement = null;
    if (comments && comments.length > 50 && this.state.commentSeeMore === false) {
      commentElement = (
        <span>
          {comments.slice(0, 50)}
          <EnhancedButton
            style={{ fontSize: '11px', border: 'none' }}
            type="link"
            size="small"
            onClick={() => {
              this.seeMoreComment();
            }}
          >
            Show More...
          </EnhancedButton>
        </span>
      );
    } else if (comments && comments.length > 25 && this.state.commentSeeMore === true) {
      commentElement = (
        <span>
          {comments}
          <EnhancedButton
            style={{ fontSize: '11px', border: 'none' }}
            type="link"
            size="small"
            onClick={() => {
              this.seeMoreComment();
            }}
          >
            Show Less...
          </EnhancedButton>
        </span>
      );
    } else {
      commentElement = comments;
    }
    return commentElement;
  };

  render() {
    const { visible, selectedClaim, claimImages, selectedTabIndex, claimImagesSuccess } =
      this.props;
    const { showPreview, imageIdInPreview } = this.state;
    this.checkIfLMapprovedFirst();
    if (_.isEmpty(selectedClaim)) {
      return null;
    }

    const claimImageSection =
      claimImages &&
      claimImages.map((v) => (
        <img
          className="claim-view-images"
          onClick={() => {
            this.showImageInPreviewMode(v);
          }}
          onKeyDown={() => {}}
          src={v}
          height="50px"
          width="50px"
          alt="preview icon"
          role="presentation"
        />
      ));
    return (
      <div>
        <EnhancedModal
          width="80%"
          visible={visible}
          footer={null}
          bodyStyle={{
            backgroundColor: '#f0f2f5',
            padding: '3vw',
          }}
          onCancel={this.hideModal}
        >
          <EnhancedRow gutter={8} type="flex">
            <EnhancedCol lg={16}>
              <EnhancedCard style={{ height: '100%' }}>
                {/* <div className="leave-form-header">
                  <h4 className="left">Claim Details: {selectedClaim.employee.fullName}</h4>
                  <h4 className="right">
                  Applying on:
                  {moment(selectedClaim.createdAt).format(dateFormat.DDMMMYYYYwithSpace)}
                  </h4>
                </div> */}
                <EnhancedRow className="claim-header">
                  <EnhancedCol span={24}>
                    <EnhancedRow style={{ marginBottom: '3px' }}>
                      {/* <Col lg={12} /> */}
                      <EnhancedCol lg={24}>
                        <h4>Status: </h4>
                        {this.showApprovalStatus()}
                      </EnhancedCol>
                    </EnhancedRow>

                    <EnhancedRow style={{ marginBottom: '3px' }}>
                      <EnhancedCol lg={12}>
                        <h4>Claimant: </h4>
                        {selectedClaim.employee.fullName}{' '}
                      </EnhancedCol>
                      <EnhancedCol lg={12}>
                        <h4>Date: </h4>
                        {moment(selectedClaim.createdAt).format(dateFormat.DDMMMYYYYwithSpace)}
                      </EnhancedCol>
                    </EnhancedRow>
                    <EnhancedRow style={{ marginBottom: '3px' }}>
                      <EnhancedCol lg={12}>
                        <h4>Project: </h4>
                        {this.getProjectName(selectedClaim.projectId)}
                      </EnhancedCol>
                      {selectedClaim.manager ? (
                        <EnhancedCol lg={12}>
                          <h4>Manager: </h4>
                          {selectedClaim.manager}{' '}
                        </EnhancedCol>
                      ) : null}
                    </EnhancedRow>
                    <EnhancedRow style={{ marginBottom: '3px' }}>
                      <EnhancedCol lg={24}>
                        <h4>Employee Comments: </h4>
                        {this.commentsCollapse(selectedClaim.comments)}
                      </EnhancedCol>
                    </EnhancedRow>
                    {selectedClaim.status &&
                      selectedClaim.status.map((approver) => (
                        <EnhancedRow style={{ marginBottom: '3px' }}>
                          <EnhancedCol lg={24}>
                            <h4>{this.getHeading(approver)}</h4>
                            {approver.comments ? (
                              approver.comments
                            ) : (
                              <span style={{ color: 'gray' }}>No comments available</span>
                            )}
                          </EnhancedCol>
                        </EnhancedRow>
                      ))}
                  </EnhancedCol>
                </EnhancedRow>
                <EnhancedDivider />

                {this.getClaimDetails()}
              </EnhancedCard>
            </EnhancedCol>
            <EnhancedCol lg={8}>
              <EnhancedCard style={{ height: '100%' }}>
                <div className="leave-form-header">
                  <h4 className="left">Claim Images</h4>
                </div>
                <EnhancedDivider />
                {claimImagesSuccess ? (
                  claimImageSection
                ) : (
                  <EnhancedSpin size="large" style={{ marginLeft: '40%' }} />
                )}
              </EnhancedCard>
            </EnhancedCol>
          </EnhancedRow>
          <div>
            {/* <Row className="marginTop15">
                    <Col lg={4} md={4} sm={24} xs={24} style={{ fontWeight: 450 }}>Comment: </Col>
                    <Col lg={20} md={20} sm={24} xs={24}>
                      <TextArea
                        style={{ resize: 'none' }}
                        placeholder="Mention your comments here"
                        rows="4"
                      />
                    </Col>
                  </Row> */}
            {this.approvalSection()}
            {Number(selectedTabIndex) === 3 ? this.financeApprovalSection() : null}
          </div>
        </EnhancedModal>
        {showPreview ? (
          <EnhancedModal
            // width="100%"
            visible={showPreview}
            footer={null}
            bodyStyle={{
              backgroundColor: '#f0f2f5',
              padding: '3vw',
            }}
            onCancel={this.closePreview}
          >
            <img src={imageIdInPreview} height="100%" width="100%" alt="preview img" />
          </EnhancedModal>
        ) : null}
      </div>
    );
  }
}

export default ClaimDetailView;
