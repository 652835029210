import { connect } from 'react-redux';
import PerformanceManagement from 'components/lineManagerDashboard/PerformanceManagement';
import {
  getEvaluationReportForLMAction,
  getGoalsReportForLMAction,
  getReporteesGoalsForLMAction,
  exportReporteesGoalsForLMAction,
  getReporteesEvaluationsForLMAction,
  exportReporteesEvaluationsForLMAction,
} from 'store/slices/PMDashboard';
import { getPMCycleActions } from 'store/actions/PerformanceMangementActions';
import {
  updateGoalActions,
  removeGoalActions,
  updateGoalStatusActions,
  getGoalDetailsActions,
} from 'store/actions/EmployeeProfileActions';
import {
  sendEvaluationsForApprovalAction,
  approvePEFStatusAction,
} from 'store/actions/PerformanceEvaluationFormActions';

import {
  getEmployeeDepartmentsAction,
  getEmployeeCompetenciesAction,
  getEmployeeDivisionsAction,
  getEmployeeBusinessUnitAction,
} from 'store/actions/EmployeeActions';

function mapStateToProps(state) {
  const { employeeReducer, pmDashboardReducer, goalReducer, auth } = state;

  const { divisions, departments, competencies, businessUnits } = employeeReducer;

  const { goalsReport, evaluationReport, reporteesGoals, reporteesEvaluations } =
    pmDashboardReducer;
  const { isLoading, goalDetails, goals } = goalReducer;

  return {
    isLoading,
    goalsReport,
    evaluationReport,
    divisions,
    departments,
    competencies,
    businessUnits,
    reporteesGoals,
    reporteesEvaluations,
    goals,
    goalDetails,
    pmCycles: state.performanceManagementReducer.pmCycles,
    user: auth.user,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getGoalsReport: () => dispatch(getGoalsReportForLMAction()),
  getEvaluationReport: (id) => dispatch(getEvaluationReportForLMAction(id)),
  getPMCycleActions: (params) => dispatch(getPMCycleActions(params)),
  getEmployeeDivisionsAction: () => dispatch(getEmployeeDivisionsAction()),
  getEmployeeDepartmentsAction: () => dispatch(getEmployeeDepartmentsAction()),
  getEmployeeCompetenciesAction: () => dispatch(getEmployeeCompetenciesAction()),
  getEmployeeBusinessUnitAction: (req) => dispatch(getEmployeeBusinessUnitAction(req)),
  getReporteesGoals: (params) => dispatch(getReporteesGoalsForLMAction(params)),
  exportReporteesGoals: (params) => dispatch(exportReporteesGoalsForLMAction(params)),
  getReporteesEvaluations: (params) => dispatch(getReporteesEvaluationsForLMAction(params)),
  exportReporteesEvaluations: (params) => dispatch(exportReporteesEvaluationsForLMAction(params)),
  updateGoal: (req) => dispatch(updateGoalActions(req)),
  getGoalDetails: (req) => dispatch(getGoalDetailsActions(req)),
  updateGoalStatus: (req) => dispatch(updateGoalStatusActions(req)),
  removeGoal: (req) => dispatch(removeGoalActions(req)),
  submitForApproval: (params) => dispatch(sendEvaluationsForApprovalAction(params)),
  approvePEF: (params) => dispatch(approvePEFStatusAction(params)),
});

const PerformanceManagementContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PerformanceManagement);

export default PerformanceManagementContainer;
