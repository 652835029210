import {
  EnhancedCol,
  EnhancedButton,
  EnhancedIcon,
  EnhancedCard,
  EnhancedRow,
  EnhancedDivider,
  EnhancedLayout,
  EnhancedModal,
  EnhancedInput,
} from 'components/shared/antd';
import React, { Component, Fragment } from 'react';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';

const { confirm } = EnhancedModal;
const { Header } = EnhancedLayout;

class Competencies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editVisible: false,
      addVisible: false,
      competencyName: '',
      editCompetencyName: '',
      selectedRecord: null,
      competencies: this.props.competencies || [],
    };
  }

  componentDidMount() {
    this.props.getEmployeeCompetenciesAction();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.competencies !== this.props.competencies) {
      this.setState({ competencies: nextProps.competencies });
    }
  }

  getColumnsInfo = () => {
    const columns = [
      {
        title: 'Competencies',
        dataIndex: 'name',
        key: 'name',
        width: '10%',
        filterConfig: {
          type: 'search',
          key: 'name',
        },
      },
      {
        title: 'Action',
        key: 'action',
        width: '5%',
        render: (text, record) => (
          <span>
            <EnhancedIcon
              type="edit"
              data-testid="edit-icon"
              style={{ cursor: 'pointer' }}
              onClick={() => this.showEditCompetencyModal(record)}
            />
            <EnhancedDivider type="vertical" />
            <EnhancedIcon
              type="delete"
              data-testid="delete-icon"
              style={{ cursor: 'pointer' }}
              onClick={() => this.showDeleteCompetencyModal(record)}
            />
          </span>
        ),
      },
    ];
    return columns;
  };

  showEditCompetencyModal = (record) => {
    this.setState({
      editVisible: true,
      selectedRecord: record,
      editCompetencyName: record.name,
    });
  };

  hideEditCompetencyModal = () => {
    this.setState({
      editVisible: false,
      addVisible: false,
      selectedRecord: null,
      editCompetencyName: '',
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleAddCompetency = () => {
    const { competencyName } = this.state;
    const { addCompetency } = this.props;
    addCompetency({ name: competencyName });
    this.setState({ competencyName: '', addVisible: false });
  };

  handleEditCompetency = () => {
    const { selectedRecord, editCompetencyName } = this.state;
    const { editCompetency } = this.props;
    editCompetency({ id: selectedRecord.id, name: editCompetencyName });
    this.setState({ selectedRecord: null, editCompetencyName: '', editVisible: false });
  };

  handleDeleteCompetency = (record) => {
    const { deleteCompetency } = this.props;
    deleteCompetency({ id: record.id });
  };

  editCompetency = () => {
    const { editVisible, addVisible, editCompetencyName, competencyName } = this.state;
    const CompetencyValue = editVisible ? editCompetencyName : competencyName;
    return (
      <EnhancedModal
        title={editVisible ? 'Edit Competency' : 'Add Competency'}
        visible={editVisible || addVisible}
        onCancel={this.hideEditCompetencyModal}
        footer={[
          <EnhancedButton key="back" onClick={this.hideEditCompetencyModal}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            key="submit"
            type="primary"
            onClick={editVisible ? this.handleEditCompetency : this.handleAddCompetency}
            disabled={!CompetencyValue || CompetencyValue === ''}
          >
            Save
          </EnhancedButton>,
        ]}
      >
        <EnhancedRow className="edit-skill">
          <EnhancedCol span={12}>
            <EnhancedInput
              name={editVisible ? 'editCompetencyName' : 'competencyName'}
              value={CompetencyValue}
              onChange={this.handleChange}
              placeholder="Enter Competency"
            />
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedModal>
    );
  };

  showAddCompetencyModal = () => {
    this.setState({ addVisible: true });
  };

  showDeleteCompetencyModal = (record) => {
    const { handleDeleteCompetency } = this;
    confirm({
      title: 'Are you sure you want to delete this Competency?',
      content: 'Note: Competencies associated with employees cannot be deleted',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteCompetency(record);
      },
    });
  };

  render() {
    const columns = this.getColumnsInfo();
    const { competencies } = this.state;
    const { isLoading } = this.props;
    return (
      <Fragment>
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol lg={12}>
              <h1 className="heading-pro">Competencies</h1>
            </EnhancedCol>
            <EnhancedCol span={6} offset={6}>
              <EnhancedButton
                type="primary"
                onClick={this.showAddCompetencyModal}
                style={{ float: 'right', marginTop: '5%' }}
              >
                {' '}
                Add New Competency
              </EnhancedButton>
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        {this.editCompetency()}
        <div style={{ background: 'white' }}>
          <EnhancedCard loading={isLoading} bordered={false}>
            <EnhancedRow />
          </EnhancedCard>
          <div>
            <EnhancedClientSideTable
              style={{ marginLeft: '25px' }}
              loading={isLoading}
              columns={columns}
              data={competencies}
              exportFileConfig={{
                fileName: 'Competencies',
                fileHeaders: [
                  {
                    label: 'Competency',
                    key: 'name',
                  },
                ],
                showExportButton: true,
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Competencies;
