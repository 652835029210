import { NOT_AVAILABLE } from 'configs/additionalConstants';
import moment from 'moment';
import XLSX from 'xlsx';
import { TRANSIENT_RESOURCE_TYPES } from '../configs/constants';
import { getFormattedDateWithHyphen } from './DateUtils';

export function transformTransientResourceForCSV(resources) {
  const headers = [
    'First Name',
    'Last Name',
    'Type',
    'Status',
    'Joining Date',
    'Experience',
    'Skills',
  ];
  const rows =
    resources.length > 0 &&
    resources.map((resource) => [
      resource.firstName ? resource.firstName : '',
      resource.lastName ? resource.lastName : '',
      resource.type ? TRANSIENT_RESOURCE_TYPES[resource.type.toUpperCase()] : '',
      resource.isActive ? 'Active' : 'Inactive',
      resource.joiningDate ? getFormattedDateWithHyphen(resource.joiningDate) : NOT_AVAILABLE,
      resource.externalExpYears || resource.externalExpMonths
        ? `${resource.externalExpYears} Years ${resource.externalExpMonths} Months`
        : '',
      resource.dealResourceSkills.length > 0
        ? resource.dealResourceSkills
            .map((resourceSkill) => {
              const res = resourceSkill.skills ? resourceSkill.skills.name : NOT_AVAILABLE;
              return res;
            })
            .join(' , ')
        : NOT_AVAILABLE,
    ]);
  return [headers, ...rows];
}

export function writeToXLSX(sheet, fileName) {
  const book = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(book, sheet, 'Summary');
  const currentDate = moment(new Date()).format('YYYY-MM-DD');
  XLSX.writeFile(book, `${fileName} - ${currentDate}.xlsx`);
}

export const generateTransientResourceReportCSV = (data) => {
  const sheet = XLSX.utils.aoa_to_sheet(transformTransientResourceForCSV(data.content));
  writeToXLSX(sheet, 'TransientResources');
};
