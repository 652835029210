import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedAffix,
  EnhancedCheckbox,
  EnhancedRate,
} from 'components/shared/antd';
import PropTypes from 'prop-types';
import React from 'react';
import { reviewTypes } from 'configs/employeeProfileConstants';
import { roundOffRating } from 'utils/conversionUtils';
import RatingText from './ratingText';

const PerformanceEvaluationFormHeader = (props) => {
  const {
    reviewType,
    pef,
    viewer,
    commentsCheckboxOnChange,
    previousCyclesButtonOnClick,
    commentsCheckboxValue,
    ratingOptions,
    showMonthlyPerformanceRatings,
  } = props;

  const getEmployeeOverallRating = () => {
    let response = null;

    if (reviewType === reviewTypes.MANAGER && pef.selfEvaluation) {
      response = (
        <EnhancedRow type="flex" align="middle" justify="space-between" className="color-white">
          <EnhancedCol>
            <EnhancedRow type="flex">
              <EnhancedCol className="mr-10">Employee Rating:</EnhancedCol>
              <EnhancedCol>
                <EnhancedRate
                  style={{ lineHeight: '20px' }}
                  disabled
                  allowHalf
                  value={pef.selfEvaluation.overallRating}
                />
                <span className="mr-10">{roundOffRating(pef.selfEvaluation.overallRating)}</span>
              </EnhancedCol>
              <EnhancedCol>
                <RatingText
                  rating={pef.selfEvaluation.overallRating}
                  ratingOptions={ratingOptions}
                />
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCol>
          <EnhancedCol>
            <EnhancedCheckbox
              className="boldFont mr-10"
              onChange={(e) => {
                commentsCheckboxOnChange(e.target.checked);
              }}
              checked={commentsCheckboxValue}
            />
            Show employee comments
          </EnhancedCol>
        </EnhancedRow>
      );
    }

    return response;
  };

  return (
    <React.Fragment>
      <EnhancedAffix offsetTop={63} className="performance-header-fixed mb-20">
        <EnhancedRow type="flex" justify="space-between" className="mb-12 color-white">
          <EnhancedCol>
            <h2 className="color-white">
              Performance Evaluation Form -{' '}
              {reviewType.slice(0, 1).toUpperCase() + reviewType.slice(1)} Review
            </h2>
          </EnhancedCol>
          <EnhancedCol>
            {showMonthlyPerformanceRatings && (
              <EnhancedButton
                className="btn-edit color-white"
                onClick={previousCyclesButtonOnClick}
              >
                Show average monthly performance ratings
              </EnhancedButton>
            )}
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow className="mb-20 " type="flex" align="middle">
          <EnhancedCol span={8}>
            <EnhancedRow type="flex" align="middle" className="color-white">
              <EnhancedCol className="mr-10">{viewer} Rating:</EnhancedCol>
              <EnhancedCol>
                <EnhancedRate
                  disabled
                  style={{ lineHeight: '20px' }}
                  allowHalf
                  value={pef.overallRating}
                />
                <span className="mr-10">{roundOffRating(pef.overallRating)}</span>
              </EnhancedCol>
              <EnhancedCol>
                <RatingText rating={pef.overallRating} ratingOptions={ratingOptions} />
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCol>
          <EnhancedCol span={16}>{getEmployeeOverallRating()}</EnhancedCol>
        </EnhancedRow>
      </EnhancedAffix>
    </React.Fragment>
  );
};

PerformanceEvaluationFormHeader.propTypes = {
  viewer: PropTypes.string.isRequired,
  reviewType: PropTypes.string.isRequired,
  pef: PropTypes.shape({
    overallRating: PropTypes.number,
  }).isRequired,
  commentsCheckboxOnChange: PropTypes.func,
  previousCyclesButtonOnClick: PropTypes.func,
  commentsCheckboxValue: PropTypes.bool,
  showMonthlyPerformanceRatings: PropTypes.bool,
};

PerformanceEvaluationFormHeader.defaultProps = {
  commentsCheckboxOnChange: () => {},
  previousCyclesButtonOnClick: () => {},
  commentsCheckboxValue: false,
  showMonthlyPerformanceRatings: false,
};

export default PerformanceEvaluationFormHeader;
