import { connect } from 'react-redux';

import ProjectRatingCRMC from 'components/pc/ProjectRatingCRMC';
import { getProjectsAction } from 'store/actions/ProjectActions';

import {
  getProjectRatingAction,
  addProjectRatingAction,
  deleteProjectRatingAction,
  addEmployeeProjectRatingAction,
  getCRMCPanelProjectDataAction,
  getRatingDetailsCRMCAction,
  resetRatingDetails,
  getProjectScoreAction,
} from 'store/actions/PerformanceCycleActions';

import updateBreadCrumb from 'store/actions/BreadCrumbActions';

function filterWorkForceEmployees(allEmployees, projects) {
  const activeProjects = projects.filter((project) => project.status === true);
  const activePMIds = activeProjects
    .filter((project) => project.manager)
    .map((project) => project.manager.id);
  const activeArchIds = activeProjects
    .filter((project) => project.architect)
    .map((project) => project.architect.id);
  return allEmployees.filter(
    (emp) => !activePMIds.includes(emp.id) && !activeArchIds.includes(emp.id),
  );
}

function mapStateToProps(state) {
  const { auth, performanceCycleReducer, employeeReducer, projectReducer } = state;
  return {
    userId: auth.user && auth.user.id,
    readOnly: auth.user && auth.user.isReadOnly,
    isCRMC1: auth.user && auth.user.isCRMC1,
    isCRMC2: auth.user && auth.user.isCRMC2,
    allEmployees: filterWorkForceEmployees(employeeReducer.employees, projectReducer.projects),
    isPM: auth.user.isPM,
    isArch: auth.user.isArch,
    projectRating: performanceCycleReducer.projectRating,
    ratingDetails: performanceCycleReducer.ratingDetails,
    isLoading: performanceCycleReducer.isLoading,
    projectScore: performanceCycleReducer.projectScore,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectsAction: () => dispatch(getProjectsAction()),
    getProjectRatingAction: (projectCycleId, projectId) =>
      dispatch(getProjectRatingAction(projectCycleId, projectId)),
    addProjectRatingAction: (payload) => dispatch(addProjectRatingAction(payload)),
    removeProjectRating: (req) => dispatch(deleteProjectRatingAction(req)),
    addEmployeeProjectRatingAction: (req) => dispatch(addEmployeeProjectRatingAction(req)),
    getCRMCPanelProjectDataAction: (projectCycleId, projectId) =>
      dispatch(getCRMCPanelProjectDataAction(projectCycleId, projectId)),
    updateBreadCrumb: (key, oldVal, newVal) => dispatch(updateBreadCrumb(key, oldVal, newVal)),
    getRatingDetailsCRMC: (payload) => dispatch(getRatingDetailsCRMCAction(payload)),
    resetRatingDetails: () => dispatch(resetRatingDetails()),
    getProjectScoreAction: (performanceCycleId, projectId) =>
      dispatch(getProjectScoreAction(performanceCycleId, projectId)),
  };
}

const ProjectRatingCRMCContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectRatingCRMC);

export default ProjectRatingCRMCContainer;
