import { connect } from 'react-redux';
import updateBreadCrumb from 'store/actions/BreadCrumbActions';
import PerformanceEvaluationForm from 'components/MyProfile/performanceEvaluation';
import {
  getMyPEFDataActions,
  submitMyPEFDataActions,
} from '../store/actions/PerformanceEvaluationFormActions';
import {
  getPMCycleDetailAction,
  getRatingOptionsActions,
} from '../store/actions/PerformanceMangementActions';

const mapStateToProps = (state) => {
  const { pefData, isLoading, isRedirect } = state.performanceEvaluationFormReducer;
  const { performanceManagementReducer } = state;
  return {
    pefData,
    isLoading,
    isRedirect,
    pmCycleDetail: performanceManagementReducer.pmCycleDetail,
    ratingOptions: performanceManagementReducer.ratingOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPEFDataActions: (params) => dispatch(getMyPEFDataActions(params)),
  submitPEFDataActions: (params) => dispatch(submitMyPEFDataActions(params)),
  getPMCycleDetail: (id) => dispatch(getPMCycleDetailAction(id)),
  updateBreadCrumb: (key, oldVal, newVal) => dispatch(updateBreadCrumb(key, oldVal, newVal)),
  getRatingOptions: () => dispatch(getRatingOptionsActions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceEvaluationForm);
