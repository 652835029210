import moment from 'moment-timezone';
import { NOT_AVAILABLE } from 'configs/additionalConstants';
import { mapEmployeePrimarySkills, mapEmployeeSecondarySkills } from 'utils/employeeUtils';

export function convertResignationToUTC(datetime) {
  let newDateTime = datetime;
  if (newDateTime) {
    const dateObj = new Date(newDateTime);
    const formattedDate = `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;
    newDateTime = new Date(`${formattedDate} 00:00:00`);
    newDateTime.setHours(newDateTime.getHours() - 5);
  }

  return newDateTime;
}

export function convertLeavingToUTC(datetime) {
  let newDateTime = datetime;
  if (newDateTime) {
    const dateObj = new Date(newDateTime);
    const formattedDate = `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;
    newDateTime = new Date(`${formattedDate} 23:59:59`);
    newDateTime.setHours(newDateTime.getHours() - 5);
  }

  return newDateTime;
}

export function convertResignationLocal(datetime) {
  let newDateTime = datetime;
  if (datetime && !moment.isMoment(newDateTime)) {
    const dateObj = new Date(newDateTime);
    const formattedDate = `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;
    newDateTime = new Date(`${formattedDate} 18:59:59`);
    newDateTime.setHours(newDateTime.getHours() + 5);
  }

  return newDateTime;
}

export function convertLeavingLocal(datetime) {
  let newDateTime = datetime;
  if (datetime && !moment.isMoment(newDateTime)) {
    const dateObj = new Date(newDateTime);
    const formattedDate = `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;
    newDateTime = new Date(`${formattedDate} 18:59:59`);
    newDateTime.setHours(newDateTime.getHours() + 5);
  }

  return newDateTime;
}

const getManagerName = (managerId, employeeArray) => {
  if (employeeArray) {
    const manager = employeeArray.filter((emp) => emp.id === managerId)[0];
    if (manager) return manager.fullName;
  }
  return NOT_AVAILABLE;
};

const getEmployeeGrade = (employee) => {
  return employee.grade ? employee.grade.toString() : '';
};

const getAddressString = (addressObj) => {
  if (!addressObj) return null;
  const { address, city, province, country } = addressObj;
  return `${address}, ${city}, ${province}, ${country}`;
};

export function normalizeGetAllEmployessResponse(res) {
  return res.map((data) => {
    const employee = data || {};
    return {
      id: employee.id,
      firstName: employee.firstName || '',
      name: employee.fullName,
      middleName: employee.middleName || '',
      lastName: employee.lastName || '',
      email: employee.email || '',
      details: employee.details,
      dateOfBirth: employee.dateOfBirth,
      designation: employee.employeeDesignation || '',
      managerId: employee.managerId,
      managerName: employee.managerName || '',
      isActive: employee.isActive,
      department: employee.department,
      grade: getEmployeeGrade(employee),
      subGrade: employee.subGrade,
      division: employee.division,
      competency: employee.competency,
      location: employee.employeeLocation || '',
      locationId: parseInt(employee.location, 10),
      taleoId: employee.oracleId || '',
      employmentStatus: employee.employmentStatus,
      businessUnit: employee.businessUnit,
      fuelAllowance: employee.fuelAllowance,
      inflationSupport: employee.inflationSupport,
      grossSalary: employee.grossSalary,
      joiningDate: employee.joiningDate,
      leavingDate: employee.leavingDate,
      permanentAddress: getAddressString(employee.permanentAddress),
      presentAddress: getAddressString(employee.presentAddress),
      managerOracleId: employee.managerOracleId,
      phoneNumber: employee.phoneNumber,
      mobileNumber: employee.mobileNumber,
      genderId: employee.genderId,
      employmentType: employee.employmentType,
      totalExperience: employee.totalExperience,
    };
  });
}

export function normalizeGetEmployeesResponse(res) {
  return res.map((data) => {
    const employee = data || {};
    const newResignationDate = convertResignationLocal(employee.resignationDate);
    const newLeavingDate = convertLeavingLocal(employee.leavingDate);
    const totalExperience = employee.experience.totalInWords;
    const experienceInYears = employee.experience.totalInYears;

    return {
      id: employee.id,
      name: data.fullName,
      firstName: employee.firstName || '',
      lastName: employee.lastName || '',
      phoneNumber: employee.phoneNumber || '',
      dateOfBirth: employee.dateOfBirth,
      email: employee.email || '',
      skype: employee.skype || '',
      joiningDate: employee.joiningDate,
      leavingDate: newLeavingDate,
      resignationDate: newResignationDate,
      graduationDate: employee.graduationDate,
      careerStartDate: employee.careerStartDate,
      totalExperience,
      experienceInYears,
      notes: employee.notes,
      skills: employee.employee_skills || [],
      primarySkills: mapEmployeePrimarySkills(employee.employee_skills) || [],
      secondarySkills: mapEmployeeSecondarySkills(employee.employee_skills) || [],
      area: employee.area || '',
      designation: employee.employeeDesignation || '',
      managerId: employee.managerId,
      managerName: getManagerName(employee.managerId, res),
      isActive: employee.isActive,
      department: employee.department,
      resourceType: employee.resourceType,
      location: employee.employeeLocation || '',
      locationId: parseInt(employee.location, 10),
      genderId: employee.genderId, // parseInt(employee.genderId, 10),
      taleoId: employee.oracleId || '',
      leavesDetails: employee.leaveDetails || [],
      businessUnit: employee.businessUnit,
      division: employee.division,
      competency: employee.competency,
      externalExpYears: employee.externalExpYears,
      externalExpMonths: employee.externalExpMonths,
    };
  });
}

export function normalizeGetEmployeesListingResponse(res) {
  return res.map((data) => {
    const employee = data || {};
    return {
      id: employee.id,
      name: data.fullName,
      firstName: employee.firstName || '',
      lastName: employee.lastName || '',
      phoneNumber: employee.phoneNumber || '',
      mobileNumber: employee.mobileNumber || '',
      email: employee.email || '',
      skype: employee.skype || '',
      dateOfBirth: employee.dateOfBirth ? moment(employee.dateOfBirth).format('DD-MM-YYYY') : null,
      designation: employee.employeeDesignation || '',
      isActive: employee.isActive,
      department: employee.department ? employee.department.name : null,
      location: employee.employeeLocation || '',
      locationId: parseInt(employee.location, 10),
      imageUrl: employee.imageUrl ? employee.imageUrl : null,
      manager: employee.manager ? employee.manager.fullName : null,
      businessUnit: employee.businessUnit ? employee.businessUnit.name : null,
      division: employee.division ? employee.division.name : null,
    };
  });
}

export function normalizeGetEmployeesOrgTreeResponse(data) {
  const employee = data || {};
  return {
    id: employee.id,
    fullName: employee.fullName ? employee.fullName : '',
    designation: employee.designation || '',
    imageUrl: employee.imageUrl ? employee.imageUrl : null,
    manager: employee.manager ? employee.manager : null,
    directReporteesCount: employee.directReporteesCount ? employee.directReporteesCount : 0,
    indirectReporteesCount: employee.indirectReporteesCount ? employee.indirectReporteesCount : 0,
    directReportees: employee.directReportees ? employee.directReportees : null,
  };
}

export function normalizeGetEmployeesBirthdayResponse(res) {
  return res.map((data) => {
    const employee = data || {};
    return {
      id: employee.id,
      isWish: employee.isWish,
      wishList: employee.wishList,
      name: data.fullName,
      firstName: employee.firstName || '',
      lastName: employee.lastName || '',
      dateOfBirth: employee.dateOfBirth,
      email: employee.email || '',
      isActive: employee.isActive,
      location: employee.employeeLocation || '',
      imageUrl: employee.imageUrl ? employee.imageUrl : null,
    };
  });
}

export function normalizeAddEmployeesResponse(data) {
  const employee = data || {};
  const totalExperience = employee.experience.totalInWords;

  return {
    id: employee.id,
    name:
      employee.firstName || employee.lastName ? `${employee.firstName} ${employee.lastName}` : '',
    firstName: employee.firstName || '',
    lastName: employee.lastName || '',
    phoneNumber: employee.phoneNumber || '',
    dateOfBirth: employee.dateOfBirth,
    email: employee.email || '',
    skype: employee.skype || '',
    joiningDate: employee.joiningDate,
    LeavingDate: employee.LeavingDate,
    notes: employee.notes,
    skills: employee.employee_skills || [],
    primarySkills: mapEmployeePrimarySkills(employee.employee_skills) || [],
    secondarySkills: mapEmployeeSecondarySkills(employee.employee_skills) || [],
    area: employee.area || '',
    designation: employee.employeeDesignation || '',
    managerId: employee.managerId,
    managerName: `${employee.manager.firstName} ${employee.manager.lastName}`,
    isActive: employee.isActive,
    department: employee.department,
    location: employee.employeeLocation || '',
    locationId: parseInt(employee.location, 10),
    genderId: employee.genderId, // parseInt(employee.genderId, 10),
    taleoId: employee.oracleId || '',
    resourceType: employee.resourceType,
    businessUnit: employee.businessUnit,
    division: employee.division,
    competency: employee.competency,
    totalExperience,
    externalExpYears: employee.externalExpYears,
    externalExpMonths: employee.externalExpMonths,
  };
}

export function normalizeGetEmployeesSkillsResponse(res) {
  return res.map((data) => {
    const skill = data || {};
    const normalizedSkill = {
      id: skill.id,
      name: '',
    };
    if (skill.skill) {
      normalizedSkill.name = skill.skill.name;
      normalizedSkill.expertise = skill.expertise;
      normalizedSkill.skill = skill.skill;
    } else {
      normalizedSkill.name = skill.name;
    }

    normalizedSkill.employee_skills = skill.employee_skills;
    return normalizedSkill;
  });
}

function displayRole(projectRole) {
  let result = [];
  if (typeof projectRole === 'undefined') {
    result = [];
  } else if (Array.isArray(projectRole)) {
    result = projectRole.map((a) => a.role);
  } else {
    result = [projectRole.role];
  }
  return result;
}

export function normalizeGetEmployeesProjectResponse(res) {
  return res.map((data) => {
    const newData = data || {};
    return {
      name:
        `${newData.employeeProject.employee.firstName} ${newData.employeeProject.employee.lastName}` ||
        '',
      employeeProjectId: newData.employeeProjectId,
      projectName: newData.employeeProject.project ? newData.employeeProject.project.name : '',
      role: displayRole(newData.projectRole),
      allocation: newData.employeeProject.allocation,
      comment: newData.employeeProject.notes,
      type: newData.employeeProject.type,
      startDate: newData.employeeProject.startDate ? newData.employeeProject.startDate : undefined,
      endDate: newData.employeeProject.endDate ? newData.employeeProject.endDate : undefined,
      extendedEndDate: newData.employeeProject.extendedEndDate
        ? newData.employeeProject.extendedEndDate
        : undefined,
      probability: newData.employeeProject.probability,
      projectId: newData.employeeProject.projectId,
      status: newData.employeeProject.status,
      project: newData.employeeProject.project,
    };
  });
}

export function normalizeUserAttr(res) {
  return res.map((data) => {
    const { attribute } = data || {};
    return {
      id: attribute.id,
      name: attribute.name,
    };
  });
}
