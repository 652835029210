import { connect } from 'react-redux';
import ResourceDetail from 'components/rca/ResourceDetail';
import { getResourceDetailActions } from 'store/actions/ResourceActions';
import updateBreadCrumb from 'store/actions/BreadCrumbActions';

function mapStateToProps(state) {
  const { auth, resourceReducer } = state;
  return {
    userId: auth.user && auth.user.id,
    readOnly: auth.user && auth.user.isReadOnly,
    resourceData: resourceReducer.resourceData,
    isLoading: resourceReducer.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getResourceDetailActions: (userId, employeeId, startDate, endDate) =>
      dispatch(getResourceDetailActions(userId, employeeId, startDate, endDate)),
    updateBreadCrumb: (key, oldVal, newVal) => dispatch(updateBreadCrumb(key, oldVal, newVal)),
  };
}
const ResourceDetailContainer = connect(mapStateToProps, mapDispatchToProps)(ResourceDetail);

export default ResourceDetailContainer;
