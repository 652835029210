import {
  GET_PROJECT_ACTIVITY_LOG_INIT,
  GET_PROJECT_ACTIVITY_LOG_SUCCESS,
  GET_PROJECT_ACTIVITY_LOG_FAILURE,
} from 'constants/ProjectActivityLogTypes';

const initialState = {
  activityLogs: [],
  isLoading: false,
};
export default function projectActivityLogReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROJECT_ACTIVITY_LOG_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_PROJECT_ACTIVITY_LOG_SUCCESS: {
      return {
        ...state,
        activityLogs: action.data,
        isLoading: false,
      };
    }
    case GET_PROJECT_ACTIVITY_LOG_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
