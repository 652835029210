import React from 'react';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';

const EnhancedTabs = (props) => {
  const { children, defaultActiveKey, ...rest } = props;

  return (
    <Tabs defaultActiveKey={defaultActiveKey} {...rest}>
      {children}
    </Tabs>
  );
};

EnhancedTabs.propTypes = {
  className: PropTypes.string,
};

EnhancedTabs.defaultProps = {
  className: '',
};

EnhancedTabs.TabPane = Tabs.TabPane;

export default EnhancedTabs;
