import {
  EnhancedCol,
  EnhancedButton,
  EnhancedIcon,
  EnhancedCard,
  EnhancedRow,
  EnhancedDivider,
  EnhancedLayout,
  EnhancedModal,
  EnhancedInput,
} from 'components/shared/antd';
import React, { Component, Fragment } from 'react';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';

const { confirm } = EnhancedModal;
const { Header } = EnhancedLayout;

class Departments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editVisible: false,
      addVisible: false,
      departmentName: '',
      editDepartmentName: '',
      selectedRecord: null,
      departments: this.props.departments || [],
    };
  }

  componentDidMount() {
    this.props.getEmployeeDepartmentsAction();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.departments !== this.props.departments) {
      this.setState({ departments: nextProps.departments });
    }
  }

  getColumnsInfo = () => {
    const columns = [
      {
        title: 'Departments',
        dataIndex: 'name',
        key: 'name',
        width: '10%',
        filterConfig: {
          type: 'search',
          key: 'name',
        },
      },
      {
        title: 'Action',
        key: 'action',
        width: '5%',
        render: (text, record) => (
          <span>
            <EnhancedIcon
              type="edit"
              data-testid="edit-icon"
              style={{ cursor: 'pointer' }}
              onClick={() => this.showEditDepartmentModal(record)}
            />
            <EnhancedDivider type="vertical" />
            <EnhancedIcon
              type="delete"
              data-testid="delete-icon"
              style={{ cursor: 'pointer' }}
              onClick={() => this.showDeleteDepartmentModal(record)}
            />
          </span>
        ),
      },
    ];
    return columns;
  };

  showEditDepartmentModal = (record) => {
    this.setState({
      editVisible: true,
      selectedRecord: record,
      editDepartmentName: record.name,
    });
  };

  hideEditDepartmentModal = () => {
    this.setState({
      editVisible: false,
      addVisible: false,
      selectedRecord: null,
      editDepartmentName: '',
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleAddDepartment = () => {
    const { departmentName } = this.state;
    const { addDepartment } = this.props;
    addDepartment({ name: departmentName });
    this.setState({ departmentName: '', addVisible: false });
  };

  handleEditDepartment = () => {
    const { selectedRecord, editDepartmentName } = this.state;
    const { editDepartment } = this.props;
    editDepartment({ id: selectedRecord.id, name: editDepartmentName });
    this.setState({ selectedRecord: null, editDepartmentName: '', editVisible: false });
  };

  handleDeleteDepartment = (record) => {
    const { deleteDepartment } = this.props;
    deleteDepartment({ id: record.id });
  };

  editDepartment = () => {
    const { editVisible, addVisible, editDepartmentName, departmentName } = this.state;
    const departmentValue = editVisible ? editDepartmentName : departmentName;
    return (
      <EnhancedModal
        title={editVisible ? 'Edit Department' : 'Add Department'}
        visible={editVisible || addVisible}
        onCancel={this.hideEditDepartmentModal}
        footer={[
          <EnhancedButton key="back" onClick={this.hideEditDepartmentModal}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            key="submit"
            type="primary"
            onClick={editVisible ? this.handleEditDepartment : this.handleAddDepartment}
            disabled={!departmentValue || departmentValue === ''}
            data-testid="edit-save-button"
          >
            Save
          </EnhancedButton>,
        ]}
      >
        <EnhancedRow className="edit-skill">
          <EnhancedCol span={12}>
            <EnhancedInput
              name={editVisible ? 'editDepartmentName' : 'departmentName'}
              value={departmentValue}
              onChange={this.handleChange}
              placeholder="Enter Department"
            />
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedModal>
    );
  };

  showAddDepartmentModal = () => {
    this.setState({ addVisible: true });
  };

  showDeleteDepartmentModal = (record) => {
    const { handleDeleteDepartment } = this;
    confirm({
      title: 'Are you sure you want to delete this Department?',
      content: 'Note: Departments associated with employees cannot be deleted',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteDepartment(record);
      },
    });
  };

  render() {
    const columns = this.getColumnsInfo();
    const { departments } = this.state;
    const { isLoading } = this.props;
    return (
      <Fragment>
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol lg={12}>
              <h1 className="heading-pro">Departments</h1>
            </EnhancedCol>
            <EnhancedCol span={6} offset={6}>
              <EnhancedButton
                type="primary"
                onClick={this.showAddDepartmentModal}
                style={{ float: 'right', marginTop: '5%' }}
              >
                {' '}
                Add New Department
              </EnhancedButton>
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        {this.editDepartment()}
        <div style={{ background: 'white' }}>
          <EnhancedCard loading={isLoading} bordered={false}>
            <EnhancedRow />
          </EnhancedCard>
          <div>
            <EnhancedClientSideTable
              style={{ marginLeft: '25px' }}
              loading={isLoading}
              columns={columns}
              data={departments}
              exportFileConfig={{
                fileName: 'Departments',
                fileHeaders: [
                  {
                    label: 'Department',
                    key: 'name',
                  },
                ],
                showExportButton: true,
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Departments;
