import moment from 'moment';
import React, { useState, useEffect } from 'react';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';

const DATE_FORMAT = 'YYYY-MM-DD';

const Logs = (props) => {
  const { logs, getGoalCategoryLogs } = props;

  const defaultParams = {
    page: 0,
    size: 10,
  };

  const [params, setParams] = useState(defaultParams);

  useEffect(() => {
    const payload = {
      categoryId: props.match.params.categoryId,
      params,
    };
    getGoalCategoryLogs(payload);
  }, []);

  useEffect(() => {
    const payload = {
      params: {
        ...params,
      },
      categoryId: props.match.params.categoryId,
    };
    getGoalCategoryLogs(payload);
  }, [params]);

  const handleTableChange = (pagination) => {
    setParams({
      ...params,
      page: pagination.current - 1,
      size: pagination.pageSize,
    });
  };

  const getColumnsInfo = () => {
    const columns = [
      {
        title: 'ID',
        dataIndex: ['user', 'oracleId'],
        key: 'user.oracleId',
        filterConfig: {
          type: 'search',
          key: 'oracleId',
        },
      },
      {
        title: 'Name',
        dataIndex: ['user', 'name'],
        key: 'user.name',
        filterConfig: {
          type: 'search',
          key: 'employeeName',
        },
      },
      {
        title: ' Assigned By',
        dataIndex: ['assignedByUser', 'name'],
        key: 'assignedByUser.name',
        filterConfig: {
          type: 'search',
          key: 'assignedByUser',
        },
      },
      {
        title: 'Assigned On',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (value) => (value ? moment.utc(value).format(DATE_FORMAT) : 'N/A'),
      },
      {
        title: 'Due Date',
        dataIndex: 'dueDate',
        key: 'dueDate',
      },
    ];
    return columns;
  };

  return (
    <React.Fragment className="mb-20">
      <EnhancedServerSideTable
        columns={getColumnsInfo()}
        data={logs.rows}
        onChange={handleTableChange}
        className="goal-table-container mt-20 mb-20"
        paginationInfo={{
          totalItems: logs.count,
          pageSize: params.size,
          pageNumber: params.page + 1,
        }}
        updateParams={(updatedParams) => {
          setParams({
            ...params,
            ...updatedParams,
            page: 0, // move to page no 0 in case filters are applied
          });
        }}
        scroll={{ x: 'max-content' }}
      />
    </React.Fragment>
  );
};

export default Logs;
