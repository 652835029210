const PROJECT_ROUTES = {
  // LINK ROUTES
  PROJECTS_DETAIL_ROUTE: '/crmc/projects/<%= status %>/<%= id %>',
  EMPLOYEES_LISTING_ROUTE: '/crmc/employees',
  EMPLOYEE_DETAIL_ROUTE: '/crmc/employees/<%= status %>/<%= id %>',
  EMPLOYEE_REPORT_ROUTE: '/employee/report/<%= id %>',
  EMPLOYEE_PROFILE_ROUTE: '/employees/<%= id %>',
  REPORTEE_PROFILE_ROUTE: '/employees/reportee/<%= id %>',
  EMPLOYEE_CREATE_ROUTE: '/employees/create',

  // ROUTES FOR CRMC_ROUTES FILE
  CRMC_DASHBOARD_ROUTE: '/dashboard',
  CRMC_PROJECTS_ROUTE: '/projects',
  CRMC_EMPLOYEES_ROUTE: '/employees',
  CRMC_ADD_EMPLOYEES_ROUTE: '/employees/add',
  CRMC_PROJECTS_LISTING_ROUTE: '/projects/:status',
  CRMC_PROJECTS_DETAIL_ROUTE: '/projects/:status/:id',
  CRMC_EMPLOYEES_LISTING_ROUTE: '/employees/:status',
  CRMC_EMPLOYEE_DETAIL_ROUTE: '/employees/:status/:id',
  CRMC_CURRENT_PROJECTS_ROUTE: '/projects/current',
  CRMC_COMPLETED_PROJECTS_ROUTE: '/projects/completed',
  CRMC_CURRENT_EMPLOYEES_ROUTE: '/employees/current',
  CRMC_NON_BILLABLE_ROUTE: '/employees/non-billable',
  CRMC_BILLABLE_RESOURCE_ROUTE: '/employees/billable',

  // ROUTES STATUS CONSTANTS
  BILLABLE_RESOURCE: 'billable',
  ALL: 'all',
  CURRENT: 'current',
  INACTIVE: 'inactive',
  NON_BILLABLE: 'non-billable',
};

export const PERFORMANCE_MANAGEMENT_ROUTES = {
  ADMIN_VIEW_EVALUATION:
    '/performance-management/<%= performanceCycleId %>/<%= employeeId %>?type=<%= reviewType %>',
  LINE_MANAGER_VIEW_EVALUATION:
    '/employees/reportee/<%= employeeId %>/<%= performanceCycleId %>?type=<%= reviewType %>',
};

export default PROJECT_ROUTES;
