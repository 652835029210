/* eslint-disable */
import {
  EnhancedCard,
  EnhancedRow,
  EnhancedButton,
  EnhancedCol,
  EnhancedIcon,
  EnhancedTabs,
  EnhancedSelect,
  EnhancedMenu,
  EnhancedDropdown,
} from 'components/shared/antd';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { parse } from 'utils/stringUtils';
import isEmpty from 'lodash/isEmpty';
import { LM_REPORTEE_TYPES } from 'configs/constants';
import PROJECT_ROUTES from '../../constants/UrlConstants';

const { TabPane } = EnhancedTabs;
const { Option } = EnhancedSelect;

class EmployeeRatingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attributes: [],
      sortedInfo: null,
      employeeRatingList: [],
      selectedEmployeeRatingList: [],
      tabType: '',
      attributeType: null,
    };
  }

  componentDidMount() {
    const { userId } = this.props;
    const { selectedCycleId } = this.state;
    if (this.props.isCrmc) {
      this.props.getPerformanceCRMCCyclesAction(userId);
    } else {
      this.props.getPerformanceCyclesAction();
    }

    this.props.getEmployeeRatingListAction(selectedCycleId);
  }

  componentWillReceiveProps(nextProps) {
    let selectedEmployeeRatingList = [];

    if (
      this.props.employeeRatingList !== nextProps.employeeRatingList &&
      !isEmpty(nextProps.employeeRatingList)
    ) {
      const { allEmployeeRatings, attributes } = nextProps.employeeRatingList;

      const tabType = allEmployeeRatings[0].name;

      if (allEmployeeRatings.length > 0) {
        selectedEmployeeRatingList = allEmployeeRatings.filter((er) => er.name === tabType)[0]
          .employeeRatings;
      }

      this.setState({
        employeeRatingList: allEmployeeRatings,
        selectedEmployeeRatingList,
        attributes,
        tabType,
      });
    }
  }

  getRatingAttributeColumns = () => {
    const { attributes } = this.state;
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    return attributes.map((attribute) => ({
      title: attribute.title,
      dataIndex: attribute.name,
      key: attribute.name,
      width: '100',
      sorter: (a, b) => this.sortRatingAttribute(a, b, attribute.name),
      sortOrder: sortedInfo.columnKey === attribute.name && sortedInfo.order,
      filterConfig: {
        type: 'range_filter',
        key: attribute.name,
      },
    }));
  };

  getColumnsInfo = () => {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        filterConfig: {
          type: 'search',
          key: 'name',
        },
        render: this.renderEmployeeFirstName,
        width: '100',
      },
    ].concat(this.getRatingAttributeColumns());
    if (this.state.tabType === 'indirect reporting') {
      columns.splice(1, 0, {
        title: 'Manager Name',
        dataIndex: 'managerName',
        key: 'managerName',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'managerName' && sortedInfo.order,
        filterConfig: {
          type: 'search',
          key: 'managerName',
        },
        render: this.renderEmployeeFirstName,
        width: '100',
      });
    }
    return columns;
  };

  getTabValues = () => {
    const { employeeRatingList } = this.state;
    const tabValues = [];

    if (employeeRatingList) {
      employeeRatingList.forEach((erl) => {
        if (erl.employeeRatings.length) {
          const value = (
            <TabPane
              tab={erl.name.charAt(0).toUpperCase() + erl.name.slice(1)}
              key={erl.name}
              style={{ backgroundColor: '#f0f2f5' }}
            >
              <p />
            </TabPane>
          );
          tabValues.push(value);
        }
      });
    }

    return tabValues;
  };

  setDropdownFilter = (value) => {
    this.setState({
      selectedCycleId: value,
    });
    this.props.getEmployeeRatingListAction(value);
  };

  handleRatingTypechange = (value) => {
    const { employeeRatingList } = this.state;
    let { selectedEmployeeRatingList } = this.state;
    const tabType = value;
    selectedEmployeeRatingList = employeeRatingList.filter((er) => er.name === tabType)[0]
      .employeeRatings;
    this.setState({
      tabType,
      selectedEmployeeRatingList,
    });
  };

  /**
   * Utility method for sorting rating based on selected rating attribute
   * @param {Object} a Employee 1 object with rating attributes
   * @param {Object} b Employee 2 object with rating attributes
   * @param {string} attribute Attribute to compare
   * @returns {number} -1 if a[attribute] < b[attribute], 1 if a[attribute] > b[attribute], else 0
   */
  sortRatingAttribute = (a, b, attribute) => {
    const factor1 = parseFloat(a[attribute]);
    const factor2 = parseFloat(b[attribute]);
    if (factor1 < factor2) return -1;
    if (factor1 > factor2) return 1;
    return 0;
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({ sortedInfo: sorter });
  };

  handleExportMenuClick = (event) => {
    const { selectedCycleId } = this.state;
    this.props.downloadEmployeeRatingsAction({
      reportees: event.key,
      cycleId: selectedCycleId,
    });
  };

  handleExportClickForCRMC = () => {
    const { selectedCycleId } = this.state;
    this.props.downloadEmployeeRatingsAction({
      cycleId: selectedCycleId,
    });
  };

  renderEmployeeFirstName = (name, data) => {
    const link = parse(PROJECT_ROUTES.EMPLOYEE_REPORT_ROUTE, { id: data.employeeId });

    return (
      <Link to={link}>
        <b style={{ color: '#5A5A5A' }}>{name}</b>
      </Link>
    );
  };

  render() {
    const { isLoading } = this.props;
    let { performanceCycles } = this.props;
    let { selectedEmployeeRatingList } = this.state;
    const columns = this.getColumnsInfo();
    performanceCycles = performanceCycles || [];
    performanceCycles = performanceCycles.filter((pc) => pc.status === 'Complete');

    const tabValues = this.getTabValues();
    const exportRatingsMenu = (
      <EnhancedMenu onClick={this.handleExportMenuClick}>
        <EnhancedMenu.Item key={LM_REPORTEE_TYPES.ALL}>All</EnhancedMenu.Item>
        <EnhancedMenu.Item key={LM_REPORTEE_TYPES.DIRECT}>Direct Reporting</EnhancedMenu.Item>
        <EnhancedMenu.Item key={LM_REPORTEE_TYPES.INDIRECT}>Indirect Reporting</EnhancedMenu.Item>
      </EnhancedMenu>
    );
    return (
      <div>
        <EnhancedCard
          style={{
            paddingBottom: 0,
            borderBottom: '3px solid #eee',
          }}
          bordered={false}
        >
          <EnhancedRow>
            <EnhancedCol lg={10}>
              <h2 className="heading-res">Review Reporting</h2>
            </EnhancedCol>
            <EnhancedCol offset={9} lg={3}>
              <EnhancedSelect
                showSearch
                style={{ width: '100%' }}
                placeholder="Select Cycle"
                onChange={this.setDropdownFilter}
                value={this.state.selectedCycleId}
              >
                {<Option value="">All</Option>}
                {performanceCycles.map((pc) => (
                  <Option style={{ textTransform: 'capitalize' }} value={pc.id} key={pc.id}>
                    {pc.name}
                  </Option>
                ))}
              </EnhancedSelect>
            </EnhancedCol>
            <EnhancedCol lg={1} style={{ 'margin-left': 13 }}>
              {this.props.isCrmc && (
                <EnhancedButton icon="file-excel" onClick={this.handleExportClickForCRMC}>
                  Export
                </EnhancedButton>
              )}
              {!this.props.isCrmc && (
                <EnhancedDropdown overlay={exportRatingsMenu}>
                  <EnhancedButton>
                    Export <EnhancedIcon type="down" />
                  </EnhancedButton>
                </EnhancedDropdown>
              )}
            </EnhancedCol>
          </EnhancedRow>
        </EnhancedCard>
        <EnhancedTabs activeKey={this.state.tabType} onChange={this.handleRatingTypechange}>
          {tabValues}
        </EnhancedTabs>
        <EnhancedCard>
          <EnhancedRow>
            <EnhancedCol lg={20}>
              <h3 className="empAvailable">
                Count:
                <span className="totalEmployeesNumber">
                  {`${selectedEmployeeRatingList.length}`}
                </span>
              </h3>
            </EnhancedCol>
          </EnhancedRow>
          <div>
            <EnhancedClientSideTable
              loading={isLoading}
              className="ratingGrid"
              columns={columns}
              scroll={{ x: 1450 }}
              data={selectedEmployeeRatingList}
              onChange={this.handleChange}
              size="middle"
            />
          </div>
        </EnhancedCard>
      </div>
    );
  }
}

export default EmployeeRatingList;
