import { connect } from 'react-redux';
import ProjectEmployees from 'components/rca/ProjectEmployees';
import { getRcaProjectEmployeesActions } from 'store/actions/RcaProjectEmployeesActions';
import updateBreadCrumb from 'store/actions/BreadCrumbActions';

function mapStateToProps(state) {
  const { auth, rcaProjectEmployeesReducer } = state;
  return {
    userId: auth.user && auth.user.id,
    readOnly: auth.user.isReadOnly,
    projectData: rcaProjectEmployeesReducer.projectData,
    isLoading: rcaProjectEmployeesReducer.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRcaProjectEmployeesActions: (userId, projectId, startDate, endDate) =>
      dispatch(getRcaProjectEmployeesActions(userId, projectId, startDate, endDate)),
    updateBreadCrumb: (key, oldVal, newVal) => dispatch(updateBreadCrumb(key, oldVal, newVal)),
  };
}
const ProjectEmployeesContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectEmployees);

export default ProjectEmployeesContainer;
