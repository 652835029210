import {
  addExpenseClaim,
  getClaims,
  getTeamClaims,
  getClaimsforFinance,
  getClaimsforHR,
  claimTypes,
  getClaimProjectList,
  getClaimImages,
  claimApproved,
  claimDeclined,
  getExportClaim,
} from 'sources/ExpenseClaimSource';

import {
  ADD_EXPENSE_CLAIM_INIT,
  ADD_EXPENSE_CLAIM_SUCCESS,
  ADD_EXPENSE_CLAIM_FAILURE,
  GET_CLAIMS_INIT,
  GET_CLAIMS_SUCCESS,
  GET_CLAIMS_FAILURE,
  GET_TEAM_CLAIMS_INIT,
  GET_TEAM_CLAIMS_SUCCESS,
  GET_TEAM_CLAIMS_FAILURE,
  GET_CLAIM_FOR_FINANCE_INIT,
  GET_CLAIM_FOR_FINANCE_SUCCESS,
  GET_CLAIM_FOR_FINANCE_FAILURE,
  GET_CLAIM_FOR_HR_INIT,
  GET_CLAIM_FOR_HR_SUCCESS,
  GET_CLAIM_FOR_HR_FAILURE,
  GET_CLAIM_TYPES_SUCCESS,
  GET_CLAIM_TYPES_FAILURE,
  GET_CLAIM_IMAGES_SUCCESS,
  GET_CLAIM_IMAGES_FAILURE,
  APPROVAL_STATUS_INIT,
  APPROVAL_STATUS_SUCCESS,
  APPROVAL_STATUS_FAILURE,
  CLAIM_PROJECT_LIST_SUCCESS,
  CLAIM_PROJECT_LIST_FAILURE,
} from 'constants/ExpenseClaimTypes';

export const addExpenseClaimInit = () => ({
  type: ADD_EXPENSE_CLAIM_INIT,
});

export const addExpenseClaimSuccess = () => ({
  type: ADD_EXPENSE_CLAIM_SUCCESS,
});

export const addExpenseClaimFailure = () => ({
  type: ADD_EXPENSE_CLAIM_FAILURE,
});

export const getClaimsInit = () => ({
  type: GET_CLAIMS_INIT,
});

export const getClaimsSuccess = (data) => ({
  type: GET_CLAIMS_SUCCESS,
  myClaims: data,
});

export const getClaimsFailure = () => ({
  type: GET_CLAIMS_FAILURE,
});

export const getTeamClaimsForFinanceInit = () => ({
  type: GET_CLAIM_FOR_FINANCE_INIT,
});

export const getTeamClaimsForFinanceSuccess = (data) => ({
  type: GET_CLAIM_FOR_FINANCE_SUCCESS,
  claimForFinance: data,
});

export const getTeamClaimsForFinanceFailure = () => ({
  type: GET_CLAIM_FOR_FINANCE_FAILURE,
});

export const getTeamClaimsForHRInit = () => ({
  type: GET_CLAIM_FOR_HR_INIT,
});

export const getTeamClaimsForHRSuccess = (data) => ({
  type: GET_CLAIM_FOR_HR_SUCCESS,
  claimForHR: data,
});

export const getTeamClaimsForHRFailure = () => ({
  type: GET_CLAIM_FOR_HR_FAILURE,
});

export const getTeamClaimsInit = () => ({
  type: GET_TEAM_CLAIMS_INIT,
});

export const getTeamClaimsSuccess = (data) => ({
  type: GET_TEAM_CLAIMS_SUCCESS,
  teamClaims: data,
});

export const getTeamClaimsFailure = () => ({
  type: GET_TEAM_CLAIMS_FAILURE,
});

export const getClaimTypesSuccess = (claimTypesParams) => ({
  type: GET_CLAIM_TYPES_SUCCESS,
  claimTypes: claimTypesParams,
});

export const getClaimTypesFailure = () => ({
  type: GET_CLAIM_TYPES_FAILURE,
});

export const getClaimImagesSuccess = (claimImages) => ({
  type: GET_CLAIM_IMAGES_SUCCESS,
  claimImages,
});

export const getClaimImagesFailure = () => ({
  type: GET_CLAIM_IMAGES_FAILURE,
});

export const approvalStatusInit = () => ({
  type: APPROVAL_STATUS_INIT,
});

export const approvalStatusSuccess = () => ({
  type: APPROVAL_STATUS_SUCCESS,
});

export const approvalStatusFailure = () => ({
  type: APPROVAL_STATUS_FAILURE,
});

export const claimProjectListSuccess = (claimProjectList) => ({
  type: CLAIM_PROJECT_LIST_SUCCESS,
  claimProjectList,
});

export const claimProjectListFailure = () => ({
  type: CLAIM_PROJECT_LIST_FAILURE,
});

export const getClaimTypesAction = () => claimTypes();

export const getClaimProjectListAction = () => getClaimProjectList();

export const addExpenseClaimAction = (expenseClaimDto) => addExpenseClaim(expenseClaimDto);

export const getClaimsAction = (params) => getClaims(params);

export const getTeamClaimsAction = (params) => getTeamClaims(params);

export const getClaimsforFinanceAction = (params) => getClaimsforFinance(params);

export const getClaimsforHRAction = (params) => getClaimsforHR(params);

export const getClaimImagesAction = (claimId) => getClaimImages(claimId);

export const claimApprovedAction = (status) => claimApproved(status);

export const claimDeclinedAction = (status) => claimDeclined(status);

export const getExportClaimAction = () => getExportClaim();

export const removeImageFromState = () => (dispatch) => {
  dispatch(getClaimImagesFailure());
};

export const resetApprovalStatusAfterSuccess = () => (dispatch) => {
  dispatch(approvalStatusInit());
};

export const resetSuccessFlagAction = () => (dispatch) => {
  // It is required to reset all flags if uses tries to claim multiple form
  dispatch(addExpenseClaimFailure());
};
