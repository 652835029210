import { ADD_EMPLOYEE_BIRTHDAY_SUCCESS, UPDATE_EMPLOYEE_WISH } from 'constants/EmployeeTypes';

const initialState = {
  employeesBirthday: [],
};

const updateWishForEmployee = (employees, employeeID) =>
  employees.map((employee) => {
    const emp = { ...employee };
    if (emp.id === employeeID) {
      emp.isWish = true;
    }
    return emp;
  });

export default function employeeBirthdayReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_EMPLOYEE_BIRTHDAY_SUCCESS:
      return {
        ...state,
        employeesBirthday: action.data,
      };
    case UPDATE_EMPLOYEE_WISH: {
      const employeesBirthdayData = updateWishForEmployee(state.employeesBirthday, action.data);
      return Object.assign({}, state, {
        employeesBirthday: employeesBirthdayData,
      });
    }
    default:
      return {
        ...state,
      };
  }
}
