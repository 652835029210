import React, { useEffect, useState } from 'react';
import { EnhancedCol, EnhancedButton, EnhancedCard, EnhancedRow } from 'components/shared/antd';
import { connect } from 'react-redux';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import { getProjectsAction } from 'store/actions/ProjectActions';
import { getInterviewsList, getReporteesTracks } from '../../sources/interviewSources';
import { XLS_FILENAME, XLS_META } from './constants';
import generateColumns from './columns';
import AddEditInterview from './AddEditInterview';
import QuestionEditor from './QuestionEditor';
import DeleteInterview from './DeleteInterview';

const InterviewTrackListing = (props) => {
  const { getInterviews, getProjects, getTracks, interviewList, clientList, trackList, loading } =
    props;
  const [data, setData] = useState([]);
  const [modal, setModal] = useState({});

  useEffect(() => {
    getInterviews();
    getProjects();
    getTracks();
  }, []);

  useEffect(() => {
    setData(interviewList);
  }, [interviewList]);

  useEffect(() => {
    const newData = [...interviewList];
    setData(newData);
  }, [interviewList]);

  const handleEdit = (event) => {
    const id = Number(event.currentTarget.dataset.id);
    setModal({
      type: 'edit',
      data: interviewList.find((item) => item.id === id),
      recordId: id,
    });
  };

  const handleDelete = (event) => {
    const id = Number(event.currentTarget.dataset.id);
    setModal({
      type: 'delete',
      recordId: id,
    });
  };

  const handleQuestions = (event) => {
    const id = Number(event.currentTarget.dataset.id);
    setModal({
      type: 'questions',
      data: interviewList.find((item) => item.id === id),
      recordId: id,
    });
  };

  const columns = generateColumns(
    { clientList, trackList },
    {
      handleEdit,
      handleDelete,
      handleQuestions,
    },
  );

  return (
    <div>
      <EnhancedCard>
        <EnhancedRow>
          <EnhancedCol sm={12}>
            <h2 className="heading-emp">Interview Tracking</h2>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow type="flex" justify="end" gutter={[16, 16]}>
          <EnhancedCol>
            <EnhancedButton type="primary" onClick={() => setModal({ type: 'create' })}>
              Create Interview Record
            </EnhancedButton>
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedCard>
      <EnhancedCard>
        <EnhancedClientSideTable
          rowKey={(record) => record.id}
          columns={columns}
          data={data}
          loading={loading}
          scroll={{ x: 1300 }}
          exportFileConfig={{
            fileName: XLS_FILENAME,
            fileHeaders: XLS_META,
            showExportButton: true,
          }}
        />
      </EnhancedCard>
      <AddEditInterview visible={modal.type === 'create'} onClose={() => setModal({})} />
      <AddEditInterview
        recordId={modal.recordId}
        data={modal.data}
        isEdit
        visible={modal.type === 'edit'}
        onClose={() => setModal({})}
      />
      <DeleteInterview
        visible={modal.type === 'delete'}
        recordId={modal.recordId}
        onClose={() => setModal({})}
      />
      <QuestionEditor
        visible={modal.type === 'questions'}
        recordId={modal.recordId}
        interviewData={modal.data}
        onClose={() => setModal({})}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.interviewReducer.loading,
  interviewList: state.interviewReducer.interviewList,
  clientList: state.projectReducer.projects.filter((v) => v.status),
  trackList: state.interviewReducer.trackList,
});

const mapDispatchToProps = (dispatch) => ({
  getInterviews: () => dispatch(getInterviewsList()),
  getProjects: () => dispatch(getProjectsAction()),
  getTracks: () => dispatch(getReporteesTracks()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InterviewTrackListing);
