import { EnhancedCol, EnhancedRow, EnhancedIcon, EnhancedCard } from 'components/shared/antd';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { genders } from 'configs/additionalConstants';

class LeavesSummaryCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { heading, pendingRequests, onClick, loading, gender } = this.props;

    let pending = '';
    if (pendingRequests) {
      pending = (
        <div>
          <hr />
          <div>
            <b> {pendingRequests} </b>
            Request Pending
          </div>
        </div>
      );
    } else {
      pending = (
        <div>
          <hr />
          <div>No Request</div>
        </div>
      );
    }

    return (
      <EnhancedCard bordered={false} className="leave-card" loading={loading}>
        <h4 className="heading">{heading}</h4>
        <EnhancedRow style={{ marginTop: 20 }}>
          <EnhancedCol span={10}>
            <EnhancedIcon style={{ marginRight: 15 }} type="compass" />
            Hajj
          </EnhancedCol>
          {gender !== genders.FEMALE && (
            <EnhancedCol span={14}>
              <EnhancedIcon style={{ marginRight: 15 }} type="man" />
              Paternity
            </EnhancedCol>
          )}
          {gender !== genders.MALE && (
            <EnhancedCol span={14}>
              <EnhancedIcon style={{ marginRight: 15 }} type="woman" />
              Maternity
            </EnhancedCol>
          )}
        </EnhancedRow>
        <EnhancedRow style={{ marginTop: 15 }}>
          <EnhancedCol span={24}>
            <EnhancedIcon type="heart-o" style={{ marginRight: 15 }} />
            Compassionate
          </EnhancedCol>
        </EnhancedRow>
        {pending}
        {onClick && (
          <button onClick={onClick} className="leave-card-hover">
            + Apply Leave
          </button>
        )}
      </EnhancedCard>
    );
  }
}

LeavesSummaryCard.propTypes = {
  onClick: PropTypes.func,
};

LeavesSummaryCard.defaultProps = {
  onClick: false,
};

export default LeavesSummaryCard;
