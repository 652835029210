import { EnhancedButton } from 'components/shared/antd';
import React from 'react';

import PropTypes from 'prop-types';
import closeIcon from 'images/CloseCircle.svg';

const RejectButton = (props) => {
  const { clickHandler, buttonText, style } = props;
  return (
    <EnhancedButton style={style || {}} onClick={clickHandler} className="btn-edit">
      <img src={closeIcon} alt="close icon" />
      <span>{buttonText}</span>
    </EnhancedButton>
  );
};

RejectButton.PropTypes = {
  clickHandler: PropTypes.func,
  buttonText: PropTypes.string,
};

export default RejectButton;
