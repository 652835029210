import {
  EnhancedCol,
  EnhancedButton,
  EnhancedIcon,
  EnhancedCard,
  EnhancedRow,
  EnhancedDivider,
  EnhancedLayout,
  EnhancedModal,
  EnhancedInput,
} from 'components/shared/antd';
import React, { Component, Fragment } from 'react';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';

const { confirm } = EnhancedModal;
const { Header } = EnhancedLayout;

class Designations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editVisible: false,
      addVisible: false,
      designationName: '',
      editDesignationName: '',
      selectedRecord: null,
      designations: this.props.designations || [],
    };
  }

  componentDidMount() {
    this.props.getAllDesignations();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.designations !== this.props.designations) {
      this.setState({ designations: nextProps.designations });
    }
  }

  getColumnsInfo = () => {
    const columns = [
      {
        title: 'Designation',
        dataIndex: 'name',
        key: 'name',
        width: '10%',
        filterConfig: {
          type: 'search',
          key: 'name',
        },
      },
      {
        title: 'Action',
        key: 'action',
        width: '5%',
        render: (text, record) => (
          <span>
            <EnhancedIcon
              type="edit"
              data-testid="edit-icon"
              style={{ cursor: 'pointer' }}
              onClick={() => this.showEditDesignationModal(record)}
            />
            <EnhancedDivider type="vertical" />
            <EnhancedIcon
              type="delete"
              data-testid="delete-icon"
              style={{ cursor: 'pointer' }}
              onClick={() => this.showDeleteDesignationModal(record)}
            />
          </span>
        ),
      },
    ];
    return columns;
  };

  showEditDesignationModal = (record) => {
    this.setState({
      editVisible: true,
      selectedRecord: record,
      editDesignationName: record.name,
    });
  };

  hideEditDesignationModal = () => {
    this.setState({
      editVisible: false,
      addVisible: false,
      selectedRecord: null,
      editDesignationName: '',
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleAddDesignation = () => {
    const { designationName } = this.state;
    const { createDesignation } = this.props;
    createDesignation({ name: designationName });
    this.setState({ designationName: '', addVisible: false });
  };

  handleEditDesignation = () => {
    const { selectedRecord, editDesignationName } = this.state;
    const { updateDesignation } = this.props;
    updateDesignation({ id: selectedRecord.id, name: editDesignationName });
    this.setState({ selectedRecord: null, editDesignationName: '', editVisible: false });
  };

  handleDeleteDesignation = (record) => {
    const { deleteDesignation } = this.props;
    deleteDesignation({ id: record.id });
  };

  editDesignation = () => {
    const { editVisible, addVisible, editDesignationName, designationName } = this.state;
    const designationValue = editVisible ? editDesignationName : designationName;
    return (
      <EnhancedModal
        title={editVisible ? 'Edit Designation' : 'Add Designation'}
        visible={editVisible || addVisible}
        onCancel={this.hideEditDesignationModal}
        footer={[
          <EnhancedButton key="back" onClick={this.hideEditDesignationModal}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            key="submit"
            type="primary"
            onClick={editVisible ? this.handleEditDesignation : this.handleAddDesignation}
            disabled={!designationValue || designationValue === ''}
          >
            Save
          </EnhancedButton>,
        ]}
      >
        <EnhancedRow className="edit-skill">
          <EnhancedCol span={24}>
            <EnhancedInput
              name={editVisible ? 'editDesignationName' : 'designationName'}
              value={designationValue}
              onChange={this.handleChange}
              placeholder="Enter Designation"
            />
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedModal>
    );
  };

  showAddDesignationModal = () => {
    this.setState({ addVisible: true });
  };

  showDeleteDesignationModal = (record) => {
    const { handleDeleteDesignation } = this;
    confirm({
      title: 'Are you sure you want to delete this Designation?',
      content: 'Note: Designations associated with employees cannot be deleted',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteDesignation(record);
      },
    });
  };

  render() {
    const columns = this.getColumnsInfo();
    const { designations } = this.state;
    const { isLoading } = this.props;
    return (
      <Fragment>
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol lg={12}>
              <h1 className="heading-pro">Designations</h1>
            </EnhancedCol>
            <EnhancedCol span={6} offset={6}>
              <EnhancedButton
                type="primary"
                onClick={this.showAddDesignationModal}
                style={{ float: 'right', marginTop: '5%' }}
              >
                {' '}
                Add New Designation
              </EnhancedButton>
            </EnhancedCol>
          </EnhancedRow>
        </Header>
        {this.editDesignation()}
        <div style={{ background: 'white' }}>
          <EnhancedCard loading={isLoading} bordered={false}>
            <EnhancedRow />
          </EnhancedCard>
          <div>
            <EnhancedClientSideTable
              style={{ marginLeft: '25px' }}
              loading={isLoading}
              columns={columns}
              data={designations}
              exportFileConfig={{
                fileName: 'Designations',
                fileHeaders: [
                  {
                    label: 'Designation',
                    key: 'name',
                  },
                ],
                showExportButton: true,
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Designations;
