import {
  GET_EMPLOYEE_ORG_TREE_INIT,
  GET_EMPLOYEE_ORG_TREE_SUCCESS,
  GET_EMPLOYEE_ORG_TREE_FAILURE,
} from 'constants/EmployeeDirectoryTypes';

const initialState = {
  employeeOrgTree: null,
  isLoading: false,
};
export default function employeeOrgTreeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_ORG_TREE_INIT: {
      return Object.assign({}, state, {
        employeeOrgTree: null,
        isLoading: true,
      });
    }
    case GET_EMPLOYEE_ORG_TREE_SUCCESS: {
      return Object.assign({}, state, {
        employeeOrgTree: action.data,
        isLoading: false,
      });
    }
    case GET_EMPLOYEE_ORG_TREE_FAILURE: {
      return Object.assign({}, state, {
        employeeOrgTree: action.data.response.data.code,
        isLoading: false,
      });
    }
    default:
      return state;
  }
}
