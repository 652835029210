import { connect } from 'react-redux';
import updateBreadCrumb from 'store/actions/BreadCrumbActions';
import { getUserAttributesAction } from 'store/actions/EmployeeActions';

import PerformanceEvaluationForm from 'components/employeeProfile/performanceEvaluation';
import {
  getPEFDataActions,
  submitPEFDataActions,
  submitForApprovalAction,
  approvePEFAction,
} from '../store/actions/PerformanceEvaluationFormActions';
import { getAllDesignationActions } from '../store/actions/DesignationActions';
import {
  getPMCycleDetailAction,
  showPreviousPMCycleActions,
  getRatingOptionsActions,
} from '../store/actions/PerformanceMangementActions';

const mapStateToProps = (state) => {
  const { pefData, isLoading, isRedirect } = state.performanceEvaluationFormReducer;
  const { performanceManagementReducer, auth, designationsReducer, employeeReducer } = state;
  return {
    user: auth.user,
    pefData,
    isLoading,
    isRedirect,
    allEmployees: employeeReducer.employees,
    designations: designationsReducer.designations,
    pmCycleDetail: performanceManagementReducer.pmCycleDetail,
    userAttributes: employeeReducer.userAttributes,
    previousPMCycle: performanceManagementReducer.previousCycle,
    ratingOptions: performanceManagementReducer.ratingOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPMCycleDetail: (id) => dispatch(getPMCycleDetailAction(id)),
  getAllDesignations: () => dispatch(getAllDesignationActions()),
  getPEFDataActions: (params) => dispatch(getPEFDataActions(params)),
  submitPEFDataActions: (params) => dispatch(submitPEFDataActions(params)),
  submitForApproval: (params) => dispatch(submitForApprovalAction(params)),
  approvePEF: (params) => dispatch(approvePEFAction(params)),
  updateBreadCrumb: (key, oldVal, newVal) => dispatch(updateBreadCrumb(key, oldVal, newVal)),
  getUserAttributes: (employeeId) => dispatch(getUserAttributesAction(employeeId)),
  showPreviousPMCycleActions: (params) => dispatch(showPreviousPMCycleActions(params)),
  getRatingOptions: () => dispatch(getRatingOptionsActions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceEvaluationForm);
