export const GET_GOAL_CATEGORY_GOAL_INIT = 'GET_GOAL_CATEGORY_GOAL_INIT';
export const GET_GOAL_CATEGORY_GOAL_SUCCESS = 'GET_GOAL_CATEGORY_GOAL_SUCCESS';
export const GET_GOAL_CATEGORY_GOAL_FAILURE = 'GET_GOAL_CATEGORY_GOAL_FAILURE';

export const GET_GOAL_CATEGORY_GOAL_DETAILS_INIT = 'GET_GOAL_CATEGORY_GOAL_DETAILS_INIT';
export const GET_GOAL_CATEGORY_GOAL_DETAILS_SUCCESS = 'GET_GOAL_CATEGORY_GOAL_DETAILS_SUCCESS';
export const GET_GOAL_CATEGORY_GOAL_DETAILS_FAILURE = 'GET_GOAL_CATEGORY_GOAL_DETAILS_FAILURE';

export const ADD_GOAL_CATEGORY_GOAL_INIT = 'ADD_GOAL_CATEGORY_GOAL_INIT';
export const ADD_GOAL_CATEGORY_GOAL_SUCCESS = 'ADD_GOAL_CATEGORY_GOAL_SUCCESS';
export const ADD_GOAL_CATEGORY_GOAL_FAILURE = 'ADD_GOAL_CATEGORY_GOAL_FAILURE';

export const UPDATE_GOAL_CATEGORY_GOAL_INIT = 'UPDATE_GOAL_CATEGORY_GOAL_INIT';
export const UPDATE_GOAL_CATEGORY_GOAL_SUCCESS = 'UPDATE_GOAL_CATEGORY_GOAL_SUCCESS';
export const UPDATE_GOAL_CATEGORY_GOAL_FAILURE = 'UPDATE_GOAL_CATEGORY_GOAL_FAILURE';

export const DELETE_GOAL_CATEGORY_GOAL_INIT = 'DELETE_GOAL_CATEGORY_GOAL_INIT';
export const DELETE_GOAL_CATEGORY_GOAL_SUCCESS = 'DELETE_GOAL_CATEGORY_GOAL_SUCCESS';
export const DELETE_GOAL_CATEGORY_GOAL_FAILURE = 'DELETE_GOAL_CATEGORY_GOAL_FAILURE';

export const GET_GOAL_CATEGORY_LOG_INIT = 'GET_GOAL_CATEGORY_LOG_INIT';
export const GET_GOAL_CATEGORY_LOG_SUCCESS = 'GET_GOAL_CATEGORY_LOG_SUCCESS';
export const GET_GOAL_CATEGORY_LOG_FAILURE = 'GET_GOAL_CATEGORY_LOG_FAILURE';

export const UPDATE_GOAL_CATEGORY_DETAIL_ASSIGN_GOAL_INIT =
  'UPDATE_GOAL_CATEGORY_DETAIL_ASSIGN_GOAL_INIT';
export const UPDATE_GOAL_CATEGORY_DETAIL_ASSIGN_GOAL_SUCCESS =
  'UPDATE_GOAL_CATEGORY_DETAIL_ASSIGN_GOAL_SUCCESS';
export const UPDATE_GOAL_CATEGORY_DETAIL_ASSIGN_GOAL_FAILURE =
  'UPDATE_GOAL_CATEGORY_DETAIL_ASSIGN_GOAL_FAILURE';
