import { connect } from 'react-redux';
import TeamLeavesList from 'components/leaves/TeamLeavesList';
import {
  getPrimaryTeamLeavesAction,
  getSecondaryTeamLeavesAction,
  updateTeamLeaveAction,
  updateTeamLeaveComplete,
  getLeavesStatuses,
  getTeamLeavesContainer,
} from 'store/actions/LeaveActions';

import { getUserAttributesAction } from 'store/actions/EmployeeActions';

const mapStateToProps = ({
  auth,
  teamLeavesReducer,
  publicHolidaysReducer,
  leavesStatusesReducer,
  employeeReducer,
}) => ({
  userId: auth.user && auth.user.id,
  userName: auth.user && auth.user.fullName,
  isManager: auth.user && auth.user.isManager,
  primary: teamLeavesReducer.primary,
  secondary: teamLeavesReducer.secondary,
  isPM: auth.user && auth.user.isPM,
  isDM: auth.user && auth.user.isDM,
  isCRMC2: auth.user && auth.user.isCRMC2,
  indirect: teamLeavesReducer.indirect,
  loader: teamLeavesReducer.loader,
  updateSuccess: teamLeavesReducer.updateSuccess,
  workingHolidays: publicHolidaysReducer.publicHolidays.workingHolidays,
  leavesStatuses: leavesStatusesReducer.leavesStatuses,
  userAttributes: employeeReducer.userAttributes,
});

const mapDispatchToProps = (dispatch) => ({
  getPrimaryTeamLeaves: (attributeId, params, categories) =>
    dispatch(getPrimaryTeamLeavesAction(attributeId, params, categories)),
  getSecondaryTeamLeaves: (attributeId, params, categories) =>
    dispatch(getSecondaryTeamLeavesAction(attributeId, params, categories)),
  getUserAttributes: (employeeId) => dispatch(getUserAttributesAction(employeeId)),
  updateTeamLeave: (data) => dispatch(updateTeamLeaveAction(data)),
  updateTeamLeaveComplete: () => dispatch(updateTeamLeaveComplete()),
  getLeavesStatuses: () => dispatch(getLeavesStatuses()),
  getTeamLeavesContainer: (userId, module) => dispatch(getTeamLeavesContainer(userId, module)),
});

const TeamLeavesListContainer = connect(mapStateToProps, mapDispatchToProps)(TeamLeavesList);

export default TeamLeavesListContainer;
