/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import {
  getDashboardInit,
  getDashboardSuccess,
  getDashboardFailure,
} from 'store/actions/DashboardActions';
import { dashboardUrls } from 'configs/constants';
import normalizeGetDashboardResponse from 'normalizers/dashboardNormalizers';

export const getDashboard = () => (dispatch) => {
  dispatch(getDashboardInit());
  axios
    .get(dashboardUrls.GET)
    .then((response) => {
      if (response.data.success) {
        // parse response through normalizer
        const DashboardNormalized = normalizeGetDashboardResponse(response.data.data);
        // call success or failure action
        dispatch(getDashboardSuccess(DashboardNormalized));
      } else {
        dispatch(getDashboardFailure());
      }
    })
    .catch(() => {
      EnhancedNotification.error({ message: 'Dashboard failed to load' });
      dispatch(getDashboardFailure());
    });
};
