import {
  getProjectReviewsCycle,
  getProjectReviewsByCycleId,
  getProjectReviewsProjectData,
  getProjectReviewsRatingsData,
  downloadResourceRatings,
} from 'sources/ProjectReviewsSource';

import {
  GET_PROJECT_REVIEWS_CYCLE_INIT,
  GET_PROJECT_REVIEWS_CYCLE_SUCCESS,
  GET_PROJECT_REVIEWS_CYCLE_FAILURE,
  GET_PROJECT_REVIEWS_BY_CYCLE_ID_INIT,
  GET_PROJECT_REVIEWS_BY_CYCLE_ID_SUCCESS,
  GET_PROJECT_REVIEWS_BY_CYCLE_ID_FAILURE,
  GET_PROJECT_REVIEWS_PROJECT_DATA_INIT,
  GET_PROJECT_REVIEWS_PROJECT_DATA_SUCCESS,
  GET_PROJECT_REVIEWS_PROJECT_DATA_FAILURE,
  POST_PROJECT_REVIEWS_RATING_DATA_INIT,
  POST_PROJECT_REVIEWS_RATING_DATA_SUCCESS,
  POST_PROJECT_REVIEWS_RATING_DATA_FAILURE,
  CLEAR_PROJECT_REVIEWS_RATING_DATA,
} from 'constants/ProjectReviewsTypes';

export const getProjectReviewsCycleInit = () => ({
  type: GET_PROJECT_REVIEWS_CYCLE_INIT,
});

export const getProjectReviewsCycleSuccess = (data) => ({
  type: GET_PROJECT_REVIEWS_CYCLE_SUCCESS,
  data,
});

export const getProjectReviewsCycleFailure = (data) => ({
  type: GET_PROJECT_REVIEWS_CYCLE_FAILURE,
  data,
});

export const getProjectReviewsByCycleIdInit = () => ({
  type: GET_PROJECT_REVIEWS_BY_CYCLE_ID_INIT,
});

export const getProjectReviewsByCycleIdSuccess = (data) => ({
  type: GET_PROJECT_REVIEWS_BY_CYCLE_ID_SUCCESS,
  data,
});

export const getProjectReviewsByCycleIdFailure = (data) => ({
  type: GET_PROJECT_REVIEWS_BY_CYCLE_ID_FAILURE,
  data,
});

export const getProjectReviewsProjectDataInit = () => ({
  type: GET_PROJECT_REVIEWS_PROJECT_DATA_INIT,
});

export const getProjectReviewsProjectDataSuccess = (data) => ({
  type: GET_PROJECT_REVIEWS_PROJECT_DATA_SUCCESS,
  data,
});

export const getProjectReviewsProjectDataFailure = (data) => ({
  type: GET_PROJECT_REVIEWS_PROJECT_DATA_FAILURE,
  data,
});

export const getProjectReviewsRatingDataInit = () => ({
  type: POST_PROJECT_REVIEWS_RATING_DATA_INIT,
});

export const getProjectReviewsRatingDataSuccess = (data) => ({
  type: POST_PROJECT_REVIEWS_RATING_DATA_SUCCESS,
  data,
});

export const getProjectReviewsRatingDataFailure = (data) => ({
  type: POST_PROJECT_REVIEWS_RATING_DATA_FAILURE,
  data,
});

export const clearProjectReviewsRatingData = () => ({
  type: CLEAR_PROJECT_REVIEWS_RATING_DATA,
});

export const getProjectReviewsCycleAction = () => getProjectReviewsCycle();
export const getProjectReviewsByCycleIdAction = (cycleId) => getProjectReviewsByCycleId(cycleId);
export const getProjectReviewsProjectDataAction = (projectCycleId, projectId) =>
  getProjectReviewsProjectData(projectCycleId, projectId);
export const getProjectReviewsRatingDataAction = (payload) => getProjectReviewsRatingsData(payload);
export const clearProjectReviewsRatingDataAction = () => (dispatch) => {
  dispatch(clearProjectReviewsRatingData());
};
export const downloadResourceRatingsAction = (cycleInfo) => downloadResourceRatings(cycleInfo);
