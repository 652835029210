import XLSX from 'xlsx';
import moment from 'moment';
import get from 'lodash/get';
import { DATE_FORMAT } from 'configs/employeeProfileConstants';

/**
 * @summary this function will return formatted data as per excel utility
 * @param {arrayOfObjects} arrayOfObjects
 * @param {Array} headerList
 * @author Muhammad Adam
 */
const ConvertAndGetXSLSFormattedData = (arrayOfObjects, headerList = []) => {
  if (!arrayOfObjects || arrayOfObjects.length === 0) return [];
  const firstObject = arrayOfObjects[0];
  const headers = Object.keys(firstObject).map((key) => {
    const header = headerList.find((x) => x.key.toLowerCase() === key.toLowerCase());
    if (header) return header.label;
    return key;
  });
  const rows = arrayOfObjects.map((item) => {
    const row = Object.values(item);
    return row;
  });
  return [headers, ...rows];
};

/**
 * @summary this function will download excelfile with one or more tabs
 * @param {arrayOfObjects} sheets
 * @param {string} fileName
 * @author Muhammad Adam
 */
const DownloadExcelFile = (sheets, fileName) => {
  if (!sheets || sheets.length === 0 || !fileName || fileName === '') return;
  const book = XLSX.utils.book_new();
  for (let i = 0; i < sheets.length; i += 1) {
    const data = ConvertAndGetXSLSFormattedData(sheets[i].arrayOfObjects, sheets[i].headerList);
    const sheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(book, sheet, sheets[i].name);
  }
  XLSX.writeFile(book, `${fileName}.xlsx`);
};

export function writeToXLSX(sheet, fileName) {
  const book = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(book, sheet, 'Summary');
  const currentDate = moment(new Date()).format('YYYY-MM-DD');
  XLSX.writeFile(book, `${fileName} - ${currentDate}.xlsx`);
}

export const getMappedDataForExport = (args) => {
  const { data, sheetMeta } = args;

  const headers = [];
  sheetMeta.forEach((meta) => {
    headers.push(meta.label);
  });
  const rows = data.map((entry) =>
    sheetMeta.map((meta) => {
      if (meta.renderer) {
        return meta.renderer(get(entry, meta.key), meta.key, meta.label);
      }
      return get(entry, meta.key);
    }),
  );
  return [headers, ...rows];
};

export const dateRenderer = (dateText) => (dateText ? moment(dateText).format(DATE_FORMAT) : '');

export default DownloadExcelFile;
