import { EnhancedRow, EnhancedCol, EnhancedCard, EnhancedCollapse } from 'components/shared/antd';
import React, { useState, useEffect, useRef } from 'react';
import { ROW_GUTTER_SIZE, FULL_GRID_SIZE, TEXT } from 'configs/employeeProfileConstants';
import { createFields } from 'utils/FieldGenerateUtils';
import { getMatchedRatingOption } from 'utils/performanceManagementUtils';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';

const { Panel } = EnhancedCollapse;

const EmployeeInformationStep = (props) => {
  const { data, ratingOptions } = props;
  const [empInfo, setEmpInfo] = useState({});
  const ratingOptionsToRender = useRef([]);

  const setRatingOptionsToRender = (options) => {
    if (options.length > 0 && ratingOptionsToRender.current.length === 0) {
      const ratingScale = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];

      ratingOptionsToRender.current = ratingScale.map((rating) => {
        const matchedRatingOption = getMatchedRatingOption(rating, options);

        return {
          rating,
          name: matchedRatingOption.name,
          description: matchedRatingOption.description,
        };
      });
    }
  };

  const columns = [
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      render: (rating, record) => (
        <pre>
          {rating} - {record.name}
        </pre>
      ),
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      render: (text) => <pre>{text}</pre>,
    },
  ];

  useEffect(() => {
    setEmpInfo(data);
  }, [data]);

  useEffect(() => {
    setRatingOptionsToRender(ratingOptions);
  }, [ratingOptions]);

  return (
    <div>
      <EnhancedCard>
        <React.Fragment>
          <h2>Employee Information</h2>
          <EnhancedRow gutter={ROW_GUTTER_SIZE}>
            <EnhancedCol span={FULL_GRID_SIZE}>
              <EnhancedCard /* If loop index is equals to current active card (card in which some action perfomed) */
                loading={false}
                bordered={false}
                className="card-pd-0"
              >
                <div>
                  {createFields(
                    [
                      {
                        key: 'oracleId',
                        label: 'ID',
                        value: empInfo.taleoId,
                        type: TEXT,
                      },
                      {
                        key: 'name',
                        label: 'Name',
                        value: empInfo.name,
                        type: TEXT,
                      },
                      {
                        key: 'joining',
                        label: 'Date of Joining',
                        value: empInfo.joiningDate,
                        type: TEXT,
                      },
                      {
                        key: 'Designation',
                        label: 'Designation',
                        value: empInfo.designation,
                        type: TEXT,
                      },
                      {
                        key: 'Grade',
                        label: 'Grade',
                        value: empInfo.grade,
                        type: TEXT,
                      },
                      {
                        key: 'subGrade',
                        label: 'Sub Grade',
                        value: empInfo.subGrade,
                        type: TEXT,
                      },
                      {
                        key: 'businessUnit',
                        label: 'Business Unit',
                        options: 'Business Unit',
                        value: empInfo.businessUnit,
                        type: TEXT,
                      },
                      {
                        key: 'division',
                        label: 'Division',
                        value: empInfo.division,
                        type: TEXT,
                      },
                      {
                        key: 'competency',
                        label: 'Competency',
                        value: empInfo.competency,
                        type: TEXT,
                      },
                      {
                        key: 'department',
                        label: 'Department',
                        value: empInfo.department,
                        type: TEXT,
                      },
                      {
                        key: 'reviewManagerName',
                        label: 'Review Manager',
                        value: empInfo.reviewManagerName,
                        type: TEXT,
                      },
                    ],
                    2,
                    false,
                  )}
                </div>
              </EnhancedCard>
            </EnhancedCol>
          </EnhancedRow>
        </React.Fragment>
      </EnhancedCard>
      <EnhancedCollapse bordered="true" style={{ marginTop: 24 }}>
        <Panel header="Rating Scale Information" key="1">
          <EnhancedClientSideTable
            columns={columns}
            data={ratingOptionsToRender.current}
            showResetFiltersButton={false}
          />
        </Panel>
      </EnhancedCollapse>
    </div>
  );
};
export default EmployeeInformationStep;
