import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import normalizeUser from 'normalizers/userNormalizers';
import { API_URL } from 'configs/constants';
import * as authTypes from 'constants/AuthTypes';
import { gaEvent } from 'sources/AnalyticsSource';
import { categories, events } from 'configs/gaConstants';
import { setCookie } from '../../utils/storageUtils';

export function logoutRequest() {
  delete axios.defaults.headers.common.authorization;
  gaEvent(categories.GENERAL, events.LOGOUT);
  return (dispatch) =>
    dispatch({
      type: authTypes.LOGOUT,
    });
}

function loginRequestSuccess(data) {
  return {
    type: authTypes.LOGIN_SUCCESS,
    data,
  };
}

function loginRequestFailed(error) {
  return {
    type: authTypes.LOGIN_FAILURE,
    error,
  };
}

export function loginRequest(tokenId) {
  return (dispatch) => {
    const dataToSend = {
      tokenId,
    };
    axios
      .post(`${API_URL}/auth/login`, dataToSend, {
        headers: {},
      }) // unset the default header
      .then(
        (response) => {
          const user = response.data.data;
          axios.defaults.headers.common.authorization = user.jwt;
          setCookie(user.jwt);
          dispatch(
            loginRequestSuccess(
              normalizeUser({
                ...user,
                tokenId,
              }),
            ),
          ); // Will be fired after API response
        },
        (error) => {
          EnhancedNotification.error({ message: error.response.data.message });
          dispatch(loginRequestFailed(error));
        },
      )
      .catch((error) => {
        dispatch(loginRequestFailed(error));
      });
  };
}
