import React from 'react';
import { EnhancedMenu } from 'components/shared/antd';
import Authorize from './Authorize';

const AuthorizedSubMenu = (props) => {
  const { children, requiredPermissions, ...rest } = props;
  return (
    <Authorize requiredPermissions={props.requiredPermissions}>
      <EnhancedMenu.SubMenu {...rest}>{children}</EnhancedMenu.SubMenu>
    </Authorize>
  );
};

// class AuthorizedSubMenu extends EnhancedMenu.SubMenu {
//   render() {
//     return (
//       <Authorize requiredPermissions={this.props.requiredPermissions}>{super.render()}</Authorize>
//     );
//   }
// }

export default AuthorizedSubMenu;
