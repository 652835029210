import {
  EnhancedRow,
  EnhancedButton,
  EnhancedCol,
  EnhancedCard,
  EnhancedModal,
} from 'components/shared/antd';
import React from 'react';
import {
  ROW_GUTTER_SIZE,
  FULL_GRID_SIZE,
  JOB_INFO as stateName,
  GRADES as gradeOptions,
  SUB_GRADES as subGradeOptions,
  IS_EDIT,
  TEXT,
  PASSWORD,
} from 'configs/employeeProfileConstants';
import {
  createFields,
  // createSubHeading,
  noData,
} from 'utils/FieldGenerateUtils';
import { getValueForKeyinJSON } from 'utils/arrayUtils';
import { generateErrorsArrayFromJSON, generateErrorObject } from 'utils/validationUtils';
import { hasPermission } from 'utils/AccessUtils';

import Permissions from 'constants/AccessPermissions';
import {
  DELETE_CONFIRM_PROMPT_MESSAGE,
  DELETE_CONFIRM_PROMPT_WARNING_MESSAGE,
} from 'configs/messageConstants';

class OrganizationEmploymentHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      [stateName]: this.props[stateName] || [],
      errors: [],
      disableSaveButton: true,
      disableCreateButton: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps[stateName] !== this.props[stateName]) {
      const newProps = nextProps[stateName] || [];

      // Expecting Array of Objects
      // Make clean error object with exclusions
      const errors = generateErrorsArrayFromJSON(newProps, [IS_EDIT]);

      this.setState({
        [stateName]: newProps,
        errors,
      });
    }
  }

  handleEdit = (index) => {
    const newData = [...this.state[stateName]];
    newData[index] = Object.assign({}, newData[index], { isEdit: true });
    this.setState({
      [stateName]: newData,
      disableCreateButton: true,
    });
  };

  handleCancel = (index) => {
    const currentData = [...this.state[stateName]];

    // If the object already existed and has an id,
    // then replace data from props on cancel
    if (currentData[index].id) {
      const propsDataAtIndex = this.props[stateName][index];
      // Replace specific object on which cancel was clicked with props version
      currentData[index] = Object.assign({}, propsDataAtIndex, {
        isEdit: false,
      });
      this.setState({
        [stateName]: currentData,
        disableCreateButton: false,
      });
    } else {
      const propsData = [...this.props[stateName]];
      const errorsData = generateErrorsArrayFromJSON(propsData, [IS_EDIT]);
      this.setState({ [stateName]: propsData, errors: errorsData, disableCreateButton: false });
    }
  };

  handleChange = (value, key, error) => {
    const newData = [...this.state[stateName]];

    const index = key.split('-')[1];
    const field = key.split('-')[0];

    newData[index] = Object.assign({}, { ...newData[index] }, { [field]: value });

    // Update specific error object and field
    const { errors } = this.state;
    errors[index] = Object.assign({}, { ...errors[index] }, { [field]: error });

    const allValid = Object.values(errors[index]).every((valueError) => valueError === false);

    this.setState({
      [stateName]: newData,
      errors,
      disableSaveButton: !allValid,
    });
  };

  handleSave = (index) => {
    if (!this.state.disableSaveButton) {
      const currentData = this.state[stateName];
      currentData[index] = {
        ...currentData[index],
        isEdit: false,
      };
      this.setState({ [stateName]: currentData, disableCreateButton: false });

      const { employeeId } = this.props;

      if (currentData[index].id) {
        this.props.updateEmployeeJobInfo(
          employeeId,
          currentData[index].id,
          currentData[index],
          index,
        );
      } else {
        this.props.createEmployeeJobInfo(employeeId, currentData[index]);
      }
    }
  };

  handleCreate = () => {
    const newOrganizationEmploymentObject = {
      id: null,
      startDate: '',
      endDate: '',
      grade: null,
      subGrade: '',
      designationId: null,
      departmentId: null,
      managerId: null,
      divisionId: null,
      grossSalary: null,
      fuelAllowance: null,
      isEdit: true,
    };
    let newOrganizationEmploymentErrorObject = generateErrorObject(
      newOrganizationEmploymentObject,
      [IS_EDIT],
    );

    // Mutate error object according to required conditions for appropiate validation
    newOrganizationEmploymentErrorObject = {
      ...newOrganizationEmploymentErrorObject,
      startDate: true,
      designationId: true,
      grade: true,
      subGrade: true,
      departmentId: true,
      grossSalary: true,
    };

    const updatedArray = [newOrganizationEmploymentObject, ...this.state[stateName]];
    const updatedErrorsArray = [newOrganizationEmploymentErrorObject, ...this.state.errors];

    this.setState({
      [stateName]: updatedArray,
      errors: updatedErrorsArray,
      disableSaveButton: true,
      disableCreateButton: true,
    });
  };

  handleDelete = (jobInfoId) => {
    EnhancedModal.confirm({
      title: DELETE_CONFIRM_PROMPT_MESSAGE,
      content: DELETE_CONFIRM_PROMPT_WARNING_MESSAGE,
      onOk: () => {
        const { employeeId } = this.props;
        this.props.deleteEmployeeJobInfo(employeeId, jobInfoId);
        const currentData = this.state[stateName];
        this.setState({ [stateName]: currentData });
      },
      onCancel() {},
    });
  };

  render() {
    const {
      userPermissions,
      managerOptions,
      divisionOptions,
      designationOptions,
      departmentOptions,
      businessUnitOptions,
      competencyOptions,
    } = this.props;
    const data = this.state[stateName];

    const canDelete = hasPermission(userPermissions, [Permissions.Employee.Profile.JobInfo.Delete]);

    return (
      <div className="mt-20">
        {/* {createSubHeading('Within Organization')} */}
        {data.length ? (
          <EnhancedRow gutter={ROW_GUTTER_SIZE}>
            {data.map((val, index) => {
              const {
                id,
                startDate,
                endDate,
                grade,
                subGrade,
                designationId,
                departmentId,
                businessUnitId,
                competencyId,
                managerId,
                divisionId,
                grossSalary,
                inflationSupport,
                fuelAllowance,
                isEdit = false,
              } = val;

              const designation = getValueForKeyinJSON(designationOptions, designationId);
              return (
                <EnhancedCol span={FULL_GRID_SIZE}>
                  <EnhancedCard className="mb-10 br-5">
                    <div className="flex" style={{ justifyContent: 'space-between' }}>
                      <span className="card-title mb-10">{designation}</span>
                      {canDelete && (
                        <EnhancedButton onClick={() => this.handleDelete(id)}>
                          Delete
                        </EnhancedButton>
                      )}
                    </div>
                    {createFields(
                      [
                        {
                          key: `startDate-${index}`,
                          label: 'Start Date',
                          value: startDate,
                          type: TEXT,
                        },
                        {
                          key: `endDate-${index}`,
                          label: 'End Date',
                          value: endDate || '---',
                          type: TEXT,
                        },
                        {
                          key: `designationId-${index}`,
                          label: 'Job Title',
                          value: designation,
                          type: TEXT,
                        },
                        {
                          key: `grade-${index}`,
                          label: 'Grade',
                          value: getValueForKeyinJSON(gradeOptions, grade),
                          type: TEXT,
                        },
                        {
                          key: `subGrade-${index}`,
                          label: 'Sub Grade',
                          value: getValueForKeyinJSON(subGradeOptions, subGrade),
                          type: TEXT,
                        },
                        {
                          key: `businessUnitId-${index}`,
                          label: 'Business Unit',
                          value: getValueForKeyinJSON(businessUnitOptions, businessUnitId),
                          type: TEXT,
                        },
                        {
                          key: `competencyId-${index}`,
                          label: 'Competency',
                          value: getValueForKeyinJSON(competencyOptions, competencyId),
                          type: TEXT,
                        },
                        {
                          key: `divisionId-${index}`,
                          label: 'Division',
                          value: getValueForKeyinJSON(divisionOptions, divisionId),
                          type: TEXT,
                        },
                        {
                          key: `departmentId-${index}`,
                          label: 'Department',
                          value: getValueForKeyinJSON(departmentOptions, departmentId),
                          type: TEXT,
                        },
                        {
                          key: `managerId-${index}`,
                          label: 'Manager Name',
                          value: getValueForKeyinJSON(managerOptions, managerId) || val.managerName,
                          type: TEXT,
                        },
                        {
                          key: `grossSalary-${index}`,
                          label: 'Gross Salary',
                          value: grossSalary,
                          type: PASSWORD,
                          viewPermissions: [
                            Permissions.Employee.Profile.Compensation.View,
                            Permissions.Reportee.Profile.Compensation.View,
                          ],
                        },
                        {
                          key: `inflationSupport-${index}`,
                          label: 'Inflation Support',
                          value: inflationSupport,
                          type: PASSWORD,
                          viewPermissions: [
                            Permissions.Employee.Profile.Compensation.View,
                            Permissions.Reportee.Profile.Compensation.View,
                          ],
                        },
                        {
                          key: `fuelAllowance-${index}`,
                          label: 'Fuel Allowance',
                          value: fuelAllowance,
                          type: PASSWORD,
                          viewPermissions: [
                            Permissions.Employee.Profile.Compensation.View,
                            Permissions.Reportee.Profile.Compensation.View,
                          ],
                        },
                      ],
                      2,
                      isEdit,
                      userPermissions,
                      this.handleChange,
                    )}
                  </EnhancedCard>
                </EnhancedCol>
              );
            })}
          </EnhancedRow>
        ) : (
          noData()
        )}
      </div>
    );
  }
}

export default OrganizationEmploymentHistory;
