import moment from 'moment-timezone';

export const getResponseError = (error) => {
  if (error.response) {
    return error.response.data.message;
  }
  return error.message;
};

/**
 * @summary Checks if the birthday of the employee is today
 * @param {string} dateOfBirth
 * @returns {boolean}
 * @author Abreeza Saleem
 */
export const getRemainingDaysFromBirthDay = (dateOfBirth) => {
  let daysToGo = -2;
  const employeeDOB = moment(dateOfBirth);
  employeeDOB.tz(moment.tz.guess()).format('YYYY-MM-DD');
  const employeeDOBMonth = employeeDOB.format('MM');
  const employeeDOBDay = employeeDOB.format('DD');
  const employeeDOBYear = moment().format('YYYY');
  const constructedBirthDay = moment(
    `${employeeDOBYear}-${employeeDOBMonth}-${employeeDOBDay}`,
    'YYYY-MM-DD',
  );
  if (
    constructedBirthDay.diff(moment().format('YYYY-MM-DD'), 'days') < 30 &&
    constructedBirthDay.diff(moment().format('YYYY-MM-DD'), 'days') >= -1
  ) {
    daysToGo = constructedBirthDay.diff(moment().format('YYYY-MM-DD'), 'days');
  }
  return daysToGo;
};
