/* eslint-disable import/prefer-default-export */
import * as Colors from 'constants/ColorTypes';
import { leaveStatus } from 'configs/leaveConstants';
import { employeeType } from 'configs/constants';

export const getLeaveStatusColor = (status) => {
  if (status === leaveStatus.APPROVED) return Colors.LEAVE_APPROVED_COLOR;
  if (status === leaveStatus.DECLINED) return Colors.LEAVE_DECLINED_COLOR;
  if (status === leaveStatus.CANCELLED) return Colors.LEAVE_CANCELLED_COLOR;
  if (status === leaveStatus.PENDING_CANCELLATION) {
    return Colors.LEAVE_PENDING_CANCELLATION_COLOR;
  }
  return Colors.LEAVE_PENDING_COLOR;
};

export const getDashboardColor = (option) => {
  if (option === employeeType.BILLABLE) return Colors.DASHBOARD_BILLABLE_COLOR;
  if (option === employeeType.NONBILLABLE) return Colors.DASHBOARD_NON_BILLABLE_COLOR;
  if (option === employeeType.BILLED) return Colors.DASHBOARD_BILLED_COLOR;
  if (option === employeeType.BENCH) return Colors.DASHBOARD_BENCH_COLOR;
  if (option === employeeType.INTERNAL) return Colors.DASHBOARD_INTERNAL_COLOR;
  if (option === employeeType.ADDITIONAL) return Colors.DASHBOARD_ADDITIONAL_COLOR;
  if (option === employeeType.BACKUP) return Colors.DASHBOARD_BACKUP_COLOR;
  if (option === 'overAllocated') return 'red';
  return '#FFFFFF';
};
export const getColor = (option) => {
  if (option === 'Android') return Colors.color[0];
  if (option === 'Architecture') return Colors.color[1];
  if (option === 'Automation') return Colors.color[2];
  if (option === 'Business Development') return Colors.color[3];
  if (option === 'Consultancy') return Colors.color[4];
  if (option === 'Data Sciences') return Colors.color[5];
  if (option === 'iOS') return Colors.color[6];
  if (option === 'Java') return Colors.color[7];
  if (option === 'PM') return Colors.color[8];
  if (option === 'QA') return Colors.color[9];
  if (option === 'UI/UX') return Colors.color[10];
  if (option === 'PMO') return Colors.color[11];
  if (option === 'QA') return Colors.color[12];
  if (option === 'QA Automation') return Colors.color[13];
  if (option === 'not_set') return Colors.color[14];
  if (option === 'Web') return Colors.color[15];
  if (option === 'Business Analysis') return Colors.color[16];
  if (option === 'DevOps') return Colors.color[17];
  if (option === 'Project Management') return Colors.color[18];
  return '#FFFFFF';
};
