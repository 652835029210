import { EnhancedCol, EnhancedRow, EnhancedDivider } from 'components/shared/antd';
import React, { Fragment } from 'react';

// Containers
import VendiansBirthdaysContainer from '../../containers/VendiansBirthdaysContainer';

// CSS
import './HomePageCMS.css';

// Components
import VideoSection from './modules/mainScreen/VideoSection';
import Marketing from './modules/mainScreen/Marketing';
import VenDLearning from './modules/mainScreen/VenDLearning';
import UpcomingEvents from './modules/mainScreen/UpcomingEvents';

import ArticleContainer from './modules/mainScreen/ArticleContainer';

const HomePageCMS = () => (
  <Fragment>
    <ArticleContainer />
    <EnhancedRow>
      <EnhancedCol>
        <div className="useful-resources-container">
          <h5 className="useful-resources-title">USEFUL RESOURCES</h5>
          <EnhancedDivider className="divider" />
        </div>
      </EnhancedCol>
    </EnhancedRow>
    <EnhancedRow gutter={16} style={{ marginBottom: 8 }}>
      <EnhancedCol lg={{ span: 14 }} xs={{ span: 24 }}>
        <VideoSection />
      </EnhancedCol>
      <EnhancedCol lg={{ span: 10 }} xs={{ span: 24 }}>
        <Marketing />
      </EnhancedCol>
    </EnhancedRow>
    <EnhancedRow gutter={16} style={{ marginTop: 24 }}>
      <EnhancedCol lg={{ span: 8 }} xs={{ span: 24 }}>
        <VenDLearning />
      </EnhancedCol>
      <EnhancedCol lg={{ span: 8 }} xs={{ span: 24 }}>
        <UpcomingEvents />
      </EnhancedCol>
      <EnhancedCol lg={{ span: 8 }} xs={{ span: 24 }}>
        <VendiansBirthdaysContainer />
      </EnhancedCol>
    </EnhancedRow>
  </Fragment>
);

export default HomePageCMS;
