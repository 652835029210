import {
  GET_DASHBOARD_INIT,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILURE,
} from 'constants/DashboardTypes';

const initialState = {
  dashboard: [],
  isDashboardLoading: false,
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_INIT: {
      return Object.assign({}, state, {
        dashboard: [],
        isDashboardLoading: true,
      });
    }
    case GET_DASHBOARD_SUCCESS: {
      return Object.assign({}, state, {
        dashboard: action.data,
        isDashboardLoading: false,
      });
    }
    case GET_DASHBOARD_FAILURE: {
      return Object.assign({}, state, {
        dashboard: [],
        isDashboardLoading: false,
      });
    }
    default:
      return state;
  }
}
