import { EnhancedCard, EnhancedSpin } from 'components/shared/antd';
import React from 'react';

import { generateErrorObject } from 'utils/validationUtils';
import {
  SKILL_BADGE,
  TALENT_MANAGEMENT as stateName,
  IS_EDIT,
  IS_LOADING,
} from 'configs/employeeProfileConstants';
import { createFields, noData } from 'utils/FieldGenerateUtils';
import { prepareOptionsFromJSON } from 'utils/arrayUtils';
import { isEmptyObject } from 'utils/objectUtils';

class TalentManagment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      [stateName]: this.props[stateName] || {},
      errors: [],
      skillsOptions: [],
      disableSaveButton: true,
    };
  }

  componentDidMount() {
    this.props.getEmployeesSkills();
    const employeeId = 'me';
    this.props.getEmployeeTalent(employeeId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps[stateName] !== this.props[stateName]) {
      const newProps = nextProps[stateName];

      // Expecting Array of Objects
      // Make clean error object with exclusions
      const errors = generateErrorObject(newProps, [IS_EDIT, IS_LOADING]);

      this.setState({
        [stateName]: newProps,
        errors,
      });
    }
    if (nextProps.skillsOptions !== this.props.skillsOptions) {
      this.setState({
        skillsOptions: nextProps.skillsOptions,
      });
    }
  }

  handleChange = (value, key, error) => {
    const currentData = this.state[stateName];
    const newData = Object.assign({}, currentData, { [key]: value });

    // Update error object and field
    const { errors } = this.state;
    const newErrors = Object.assign({}, { ...errors }, { [key]: error });

    // Check to see if there is any error
    // if any, disable save button
    // if none, enable save button
    const allValid = Object.values(newErrors).every((valueError) => valueError === false);

    this.setState({
      [stateName]: newData,
      errors,
      disableSaveButton: !allValid,
    });
  };

  handleSave = () => {
    if (!this.state.disableSaveButton) {
      const data = Object.assign({}, this.state[stateName], {
        isLoading: true,
      });
      this.setState({
        [stateName]: data,
      });
      const employeeId = 'me';
      this.props.updateEmployeeTalent(employeeId, data);
    }
  };

  render() {
    const { userPermissions, isLoading: globalLoading } = this.props;

    const data = this.state[stateName];
    const { isEdit, isLoading } = this.state[stateName];
    const { skillsOptions } = this.state;

    return (
      <EnhancedCard loading={globalLoading} bordered={false}>
        <EnhancedSpin spinning={isLoading || false}>
          <React.Fragment>
            {!isEmptyObject(data)
              ? createFields(
                  [
                    {
                      key: 'primarySkills',
                      label: 'Primary Skills',
                      value: data.primarySkills,
                      options: prepareOptionsFromJSON(skillsOptions),
                      type: SKILL_BADGE,
                    },
                    {
                      key: 'secondarySkills',
                      label: 'Secondary Skills',
                      value: data.secondarySkills,
                      options: prepareOptionsFromJSON(skillsOptions),
                      type: SKILL_BADGE,
                    },
                  ],
                  2,
                  isEdit,
                  userPermissions,
                  this.handleChange,
                )
              : noData()}
          </React.Fragment>
        </EnhancedSpin>
      </EnhancedCard>
    );
  }
}

export default TalentManagment;
