import {
  EnhancedCol,
  EnhancedRow,
  EnhancedButton,
  EnhancedIcon,
  EnhancedCard,
  EnhancedModal,
  EnhancedPopover,
  EnhancedDatePicker,
  EnhancedInputNumber,
} from 'components/shared/antd';
import EnhancedServerSideTable from 'components/shared/EnhancedServerSideTable';
import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { DATE_FORMAT, DATETIME_FORMAT_WITH_MERIDIAN } from 'configs/employeeProfileConstants';
import { NOT_AVAILABLE } from 'configs/additionalConstants';
import Authorize from '../Authorize';
import AccessPermissions from '../../constants/AccessPermissions';

const { confirm } = EnhancedModal;

const summaryLabels = [
  {
    label: 'Fresh New Deals',
    dataKey: 'freshNewDealsCount',
  },
  {
    label: 'Modified New Deals',
    dataKey: 'modifiedNewDealsCount',
  },
  {
    label: 'Fresh Farming Deals',
    dataKey: 'freshFarmingDealsCount',
  },
  {
    label: 'Modified Farming Deals',
    dataKey: 'modifiedFarmingDealsCount',
  },
  {
    label: 'Extension Deals',
    dataKey: 'extensionDealsCount',
  },
  {
    label: 'Total New Deals',
    dataKey: 'totalNewDealsCount',
  },
  {
    label: 'Total Farming Deals',
    dataKey: 'totalFarmingDealsCount',
  },
  {
    label: 'Total Deals',
    dataKey: 'totalDealsCount',
  },
];

const DealListing = (props) => {
  const {
    list,
    getDeal,
    getDealName,
    getDealOwner,
    getDealStages,
    getClient,
    getDealTypeCount,
    getEmployeeBusinessUnits,
    dealTypeCounts,
    syncDeal,
    planRunner,
    exportDeal,
    history,
    getDealEvents,
    dealEvents,
  } = props;

  const initialFilters = {
    type: '',
    status: '',
    clientName: '',
    dealName: '',
    dealOwner: '',
    stage: '',
    businessUnit: '',
    startDate: '',
    endDate: '',
    probabilityStart: '',
    probabilityEnd: '',
  };

  const [params, setParams] = useState({
    page: 0,
    size: 10,
    sortBy: '',
    sortOrder: '',
    ...initialFilters,
  });
  const [searchFormData, setSearchFormData] = useState({});
  const [dealStage, setDealStage] = useState('');

  useEffect(() => {
    getDeal({
      ...params,
    });
    getDealTypeCount({
      ...params,
    });
  }, [params]);

  useEffect(() => {
    getDealName();
    getDealOwner();
    getClient();
    getEmployeeBusinessUnits();
    getDealEvents();
    getDealStages();
  }, []);

  const handleSyncNow = () => {
    confirm({
      content: 'Are you sure you want to sync now?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        syncDeal(params);
      },
      onCancel() {},
    });
  };

  const handleRunPlanner = () => {
    confirm({
      content: 'Are you sure you want to run planner?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        planRunner();
      },
      onCancel() {},
    });
  };

  const handleTableChange = (pagination, filters, sort) => {
    const updatedParams = {
      ...params,
      page: pagination.current - 1,
      size: pagination.pageSize,
    };

    if (sort.columnKey && sort.order) {
      updatedParams.sortBy = sort.columnKey;
      updatedParams.sortOrder = sort.order;
    } else {
      updatedParams.sortBy = '';
      updatedParams.sortOrder = '';
    }

    setParams(updatedParams);
  };

  const handleExpectedChange = (dates) => {
    setSearchFormData({
      ...searchFormData,
      startDate: dates ? dates[0] : '',
      endDate: dates ? dates[1] : '',
    });
  };

  const handleProbabilityFromChange = (value) => {
    setSearchFormData({
      ...searchFormData,
      probabilityStart: value,
    });
  };

  const handleProbabilityToChange = (value) => {
    setSearchFormData({
      ...searchFormData,
      probabilityEnd: value,
    });
  };

  const onSearchClick = () => {
    setParams({
      ...params,
      ...searchFormData,
    });
  };

  const onDealNameClick = (dealId) => {
    history.push(`/deals/${dealId}`);
  };

  const columns = [
    {
      title: 'Business Unit',
      dataIndex: 'businessUnit',
      key: 'businessUnit',
      filterConfig: {
        type: 'enhanced_filter',
        key: 'businessUnit',
        optionsConfig: {
          values: props.businessUnits,
        },
      },
      ellipsis: true,
      sorter: true,
    },
    {
      title: 'Deal Name',
      dataIndex: 'dealName',
      key: 'dealName',
      filterConfig: {
        type: 'enhanced_filter',
        key: 'dealName',
        optionsConfig: {
          values: props.dealNames,
        },
      },
      ellipsis: true,
      width: 320,
      render: (name, data) => {
        return (
          <b
            className="pointer-enabled"
            aria-hidden="true"
            onClick={() => onDealNameClick(data.id)}
          >
            {name}
          </b>
        );
      },
      sorter: true,
    },
    {
      title: 'Deal Number',
      dataIndex: 'dealNumber',
      key: 'dealNumber',
      sorter: true,
    },
    {
      title: 'Deal Owner',
      dataIndex: 'dealOwner',
      key: 'dealOwner',
      sorter: true,
      filterConfig: {
        type: 'enhanced_filter',
        key: 'dealOwner',
        optionsConfig: {
          values: props.dealOwners,
        },
      },
      ellipsis: true,
    },
    {
      title: 'Client',
      dataIndex: 'clientName',
      key: 'clientName',
      sorter: true,
      filterConfig: {
        type: 'enhanced_filter',
        key: 'clientName',
        optionsConfig: {
          values: props.clientNames,
        },
      },
      ellipsis: true,
    },
    {
      title: 'Probability',
      dataIndex: 'probability',
      key: 'probability',
      sorter: true,
    },
    {
      title: 'Expected Start Date',
      dataIndex: 'expectedStartDate',
      key: 'expectedStartDate',
      sorter: true,
      render: (text) => moment(text).format(DATE_FORMAT),
    },
    {
      title: 'Total Required',
      dataIndex: 'totalRequired',
      key: 'totalRequired',
    },
    {
      title: 'Stage',
      dataIndex: 'stage',
      key: 'stage',
      sorter: true,
      filterConfig: {
        type: 'enhanced_filter',
        key: 'stage',
        optionsConfig: {
          values: props.dealStages,
        },
      },
    },
    {
      title: 'Closed At',
      dataIndex: 'closedAt',
      key: 'closedAt',
      sorter: true,
      render: (text) => moment(text).format(DATE_FORMAT),
    },
  ];

  const generateDealsReport = () => {
    const updatedParams = {
      ...params,
      size: 1000000,
    };
    exportDeal(updatedParams);
  };

  const changeActionHandler = (key) => {
    setDealStage(key);
    let status = '';
    let type = '';
    if (key === 'freshNewDealsCount') {
      status = 'fresh';
      type = 'new_lead';
    } else if (key === 'modifiedNewDealsCount') {
      status = 'modified';
      type = 'new_lead';
    } else if (key === 'freshFarmingDealsCount') {
      status = 'fresh';
      type = 'farming';
    } else if (key === 'modifiedFarmingDealsCount') {
      status = 'modified';
      type = 'farming';
    } else if (key === 'totalNewDealsCount') {
      type = 'new_lead';
    } else if (key === 'totalFarmingDealsCount') {
      type = 'farming';
    } else if (key === 'extensionDealsCount') {
      type = 'extension';
    }

    setParams({
      ...params,
      status,
      type,
    });
  };

  return (
    <Fragment>
      <EnhancedCard>
        <EnhancedRow type="flex" align="middle" justify="space-between" className="mb-20">
          <EnhancedCol>
            <h2 className="mb-20">All Deals</h2>
          </EnhancedCol>
          <EnhancedCol>
            <Authorize requiredPermissions={[AccessPermissions.ResourcePlanner.Sync]}>
              <EnhancedButton icon="api" className="mr-10" onClick={handleSyncNow}>
                Sync Now
              </EnhancedButton>
              <EnhancedPopover
                content={
                  dealEvents.lastSynced
                    ? moment(dealEvents.lastSynced).format(DATETIME_FORMAT_WITH_MERIDIAN)
                    : NOT_AVAILABLE
                }
                title="Last synced on"
              >
                <EnhancedIcon type="info-circle" className="mr-10" />
              </EnhancedPopover>
            </Authorize>

            <Authorize requiredPermissions={[AccessPermissions.ResourcePlanner.Plan]}>
              <EnhancedButton icon="retweet" type="primary" onClick={handleRunPlanner}>
                Run Planner
              </EnhancedButton>
              <EnhancedPopover
                content={
                  dealEvents.lastPlanned
                    ? moment(dealEvents.lastPlanned).format(DATETIME_FORMAT_WITH_MERIDIAN)
                    : NOT_AVAILABLE
                }
                title="Last planned on"
              >
                <EnhancedIcon type="info-circle" className="ml-10" />
              </EnhancedPopover>
            </Authorize>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow type="flex" align="middle" justify="space-between" className="color-white">
          <EnhancedCol />
          <EnhancedCol>
            <EnhancedRow type="flex" align="middle" justify="space-between">
              <EnhancedCol className="mr-10">
                <EnhancedRow type="flex" align="middle" justify="space-between">
                  <EnhancedCol className="mr-10">
                    <span className="color-black">Probability:</span>
                  </EnhancedCol>
                  <EnhancedCol className="mr-10">
                    <EnhancedInputNumber
                      min={41}
                      max={100}
                      value={searchFormData.probabilityStart}
                      placeholder="From"
                      onChange={handleProbabilityFromChange}
                      style={{ width: 130 }}
                      className="mr-10"
                    />
                    <EnhancedInputNumber
                      min={41}
                      max={100}
                      value={searchFormData.probabilityEnd}
                      placeholder="To"
                      onChange={handleProbabilityToChange}
                      style={{ width: 130 }}
                    />
                  </EnhancedCol>
                </EnhancedRow>
              </EnhancedCol>
              <EnhancedCol>
                <EnhancedRow type="flex" align="middle" justify="space-between">
                  <EnhancedCol className="mr-10">
                    <span className="color-black">Expected:</span>
                  </EnhancedCol>
                  <EnhancedCol>
                    <EnhancedDatePicker.RangePicker
                      ranges={{
                        Today: [moment(), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                      }}
                      onChange={handleExpectedChange}
                      value={[searchFormData.startDate, searchFormData.endDate]}
                    />
                  </EnhancedCol>
                </EnhancedRow>
              </EnhancedCol>
              <EnhancedCol className="mr-10">
                <EnhancedRow type="flex" align="middle" justify="space-between">
                  <EnhancedButton
                    // disabled={Object.values(searchFormData).every((x) => !x)}
                    icon="search"
                    type="primary"
                    className="ml-10"
                    onClick={onSearchClick}
                  >
                    Search
                  </EnhancedButton>
                </EnhancedRow>
              </EnhancedCol>
            </EnhancedRow>
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedCard>
      <EnhancedCard>
        <EnhancedRow type="flex" justify="space-between" align="middle" className="mb-20">
          <EnhancedCol>
            <EnhancedRow>
              {summaryLabels.map((item) => (
                <EnhancedCol span={5} className="mr-10" style={{ minWidht: 220, marginBottom: 8 }}>
                  <EnhancedButton
                    type={dealStage === item.dataKey ? 'primary' : 'default'}
                    onClick={() => changeActionHandler(item.dataKey)}
                    className="w-full"
                  >
                    <span className="mr-5">{item.label}:</span>
                    <span>{dealTypeCounts && dealTypeCounts[item.dataKey]}</span>
                  </EnhancedButton>
                </EnhancedCol>
              ))}
            </EnhancedRow>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow>
          <EnhancedCol span={24}>
            <EnhancedServerSideTable
              rowKey={(record) => record.id}
              columns={columns}
              data={list.rows}
              onChange={handleTableChange}
              className="deals-table-container"
              paginationInfo={{
                totalItems: list.pageInfo && list.pageInfo.totalItems,
                pageSize: params.size,
                pageNumber: params.page + 1,
              }}
              scroll={{ x: 'max-content' }}
              updateParams={(updatedParams, areAllFiltersReset) => {
                if (areAllFiltersReset) {
                  setParams({
                    ...params,
                    page: 0,
                    size: 10,
                    ...initialFilters,
                  });
                  setDealStage('');
                  setSearchFormData({});
                } else {
                  setParams({
                    ...params,
                    ...updatedParams,
                    page: 0, // move to page no 0 in case filters are applied
                  });
                }
              }}
              exportFileConfig={{
                showExportButton: true,
                handler: generateDealsReport,
              }}
            />
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedCard>
    </Fragment>
  );
};

export default DealListing;
