import { combineReducers } from 'redux';
import authReducer from 'store/reducers/authReducer';
import workLogReducer from 'store/reducers/workLogReducer';
import projectReducer from 'store/reducers/projectReducer';
import performanceCycleReducer from 'store/reducers/performanceCycleReducer';
import employeeSearchReducer from 'store/reducers/employeeSearchReducer';
import employeeOrgTreeReducer from 'store/reducers/employeeOrgTreeReducer';
import historyReducer from 'store/reducers/historyReducer';
import reportReducer from 'store/reducers/reportReducer';
import employeeReducer from 'store/reducers/employeeReducer';
import employmentTypesReducer from 'store/reducers/employmentTypesReducer';
import defaulterReducer from 'store/reducers/defaulterReducer';
import myLeaves from 'store/reducers/myLeavesReducer';
import leaveTypesReducer from 'store/reducers/leaveTypesReducer';
import leavesStatusesReducer from 'store/reducers/leavesStatusesReducer';
import teamLeavesReducer from 'store/reducers/teamLeavesReducer';
import publicHolidaysReducer from 'store/reducers/publicHolidaysReducer';
import resourceAvailabilityReducer from 'store/reducers/resourceAvailabilityReducer';
import dashboardReducer from 'store/reducers/dashboardReducer';
import addEmployeeReducer from 'store/reducers/addEmployeeReducer';
import projectEmployeesReducer from 'store/reducers/projectEmployeesReducer';
import projectActivityLogReducer from 'store/reducers/projectActivityLogReducer';
import teamLeaveBalanceReducer from 'store/reducers/teamLeaveBalanceReducer';

import employeeRatingReducer from 'store/reducers/employeeRatingReducer';
import roleReducer from 'store/reducers/roleReducer';
import resourceReducer from 'store/reducers/resourceReducer';
import designationsReducer from 'store/reducers/designationsReducer';
import locationsReducer from 'store/reducers/locationsReducer';
import rcaProjectsReducer from 'store/reducers/rcaProjectsReducer';
import breadCrumbReducer from 'store/reducers/breadCrumbReducer';
import rcaProjectEmployeesReducer from 'store/reducers/rcaProjectEmployeesReducer';
import employeeBirthdayReducer from 'store/reducers/employeeBirthdayReducer';
import appReducer from 'store/reducers/appReducer';
import newsReducer from 'store/reducers/newsReducer';
import projectRolesReducer from 'store/reducers/projectRolesReducer';

import addClaimReducer from 'store/reducers/addClaimReducer';
import getClaimReducer from 'store/reducers/getClaimReducer';
import employeeProfileReducer from 'store/reducers/employeeProfileReducer';
import myProfileReducer from 'store/reducers/myProfileReducer';
import miscellaneousReducer from 'store/reducers/miscellaneousReducer';
import appLoaderReducer from 'store/reducers/appLoaderReducer';

import resourcePlannerReducer from 'store/reducers/resourcePlannerReducer';

// Admin reducers
import adminGoalReducer from 'store/reducers/admin/goalReducer';
import predefinedGoalReducer from 'store/reducers/admin/predefinedGoalReducer';
import predefinedGoalCategoryReducer from 'store/reducers/admin/predefinedGoalCategoryReducer';
import predefinedGoalCategoryDetailReducer from 'store/reducers/admin/predefinedGoalCategoryDetailReducer';

import projectReviewsReducer from 'store/reducers/projectReviewsReducer';
import goalReducer from 'store/reducers/goalReducer';
import performanceManagementReducer from 'store/reducers/performanceManagementReducer';
import pmDashboardReducer from 'store/slices/PMDashboard';
import dealReducer from 'store/slices/Deal';
import interviewReducer from './interviewReducer';
import performanceEvaluationFormReducer from './performanceEvaluationFormReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  appReducer,
  workLogReducer,
  resourcePlannerReducer,
  projectReducer,
  reportReducer,
  historyReducer,
  employeeReducer,
  employmentTypesReducer,
  defaulterReducer,
  myLeaves,
  leaveTypesReducer,
  leavesStatusesReducer,
  teamLeavesReducer,
  publicHolidaysReducer,
  resourceAvailabilityReducer,
  dashboardReducer,
  addEmployeeReducer,
  projectEmployeesReducer,
  projectActivityLogReducer,
  teamLeaveBalanceReducer,
  performanceCycleReducer,
  employeeSearchReducer,
  employeeOrgTreeReducer,
  employeeRatingReducer,
  designationsReducer,
  locationsReducer,
  resourceReducer,
  rcaProjectsReducer,
  rcaProjectEmployeesReducer,
  roleReducer,
  employeeBirthdayReducer,
  newsReducer,
  breadCrumbReducer,
  addClaimReducer,
  getClaimReducer,
  projectRolesReducer,
  employeeProfileReducer,
  myProfileReducer,
  miscellaneousReducer,
  appLoaderReducer,
  projectReviewsReducer,
  interviewReducer,
  goalReducer,
  performanceManagementReducer,
  performanceEvaluationFormReducer,

  // Admin Reducers
  adminGoalReducer,
  predefinedGoalReducer,
  predefinedGoalCategoryReducer,
  predefinedGoalCategoryDetailReducer,
  pmDashboardReducer,
  dealReducer,
});

export default rootReducer;
