import {
  GET_NEWS_GRID_DATA,
  APPEND_NEWS_GRID_DATA,
  GET_RELATED_NEWS_DATA,
  GET_SINGLE_NEWS_DATA,
  GET_API_NEWS_FAILURE,
} from 'constants/NewsTypes';

const initialState = {
  allNews: [],
  singleNews: [],
  relatedNews: [],
  loader: false,
};
export default function newsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NEWS_GRID_DATA: {
      return Object.assign({}, state, {
        allNews: action.data.items,
        totalNewsCount: action.data.totalCount,
        loader: false,
      });
    }

    case APPEND_NEWS_GRID_DATA:
      return Object.assign({}, state, {
        allNews: [...state.allNews, ...action.data.items],
        totalNewsCount: action.data.totalCount,
        loader: false,
      });

    case GET_SINGLE_NEWS_DATA: {
      return Object.assign({}, state, {
        singleNews: action.data,
        loader: false,
      });
    }
    case GET_RELATED_NEWS_DATA: {
      return Object.assign({}, state, {
        relatedNews: action.data,
      });
    }
    case GET_API_NEWS_FAILURE: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}
