import React from 'react';
import { EnhancedMenu } from 'components/shared/antd';
import Authorize from './Authorize';

class AuthorizedMenuItem extends EnhancedMenu.Item {
  render() {
    return (
      <Authorize requiredPermissions={this.props.requiredPermissions}>{super.render()}</Authorize>
    );
  }
}

export default AuthorizedMenuItem;
