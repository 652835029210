import React from 'react';
import { Collapse } from 'antd';
import PropTypes from 'prop-types';

const EnhancedCollapse = (props) => {
  const { children, className, style, defaultActiveKey, accordion, onChange, bordered } = props;

  return (
    <Collapse
      className={className}
      style={style}
      onChange={onChange}
      defaultActiveKey={defaultActiveKey}
      accordion={accordion}
      bordered={bordered}
    >
      {children}
    </Collapse>
  );
};

EnhancedCollapse.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  onChange: PropTypes.func,
  accordion: PropTypes.bool,
  defaultActiveKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  bordered: PropTypes.bool,
};

EnhancedCollapse.defaultProps = {
  className: '',
  style: {},
  onChange: () => {},
  accordion: false,
  defaultActiveKey: [],
  bordered: true,
};

EnhancedCollapse.Panel = Collapse.Panel;
// const { Panel } = Collapse;
export default EnhancedCollapse;
