import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import {
  getEmployeeOrgTreeInit,
  getEmployeeOrgTreeSuccesss,
  getEmployeeOrgTreeFailure,
} from 'store/actions/EmployeeOrgTreeActions';

import { employeeOrgTreeUrls } from 'configs/constants';
import { normalizeGetEmployeesOrgTreeResponse } from 'normalizers/employeeNormalizers';

const getEmployeeOrgTree = (id) => {
  const path = `/${id}/orgChart`;

  const url = employeeOrgTreeUrls.GET.concat(path);
  return (dispatch) => {
    dispatch(getEmployeeOrgTreeInit());
    axios
      .get(url)
      .then((response) => {
        if (response.data.success) {
          // parse response through normalizer
          const { data } = response.data;
          const EmployeeOrgTreeResponse = normalizeGetEmployeesOrgTreeResponse(data);
          // call success or failure action
          dispatch(getEmployeeOrgTreeSuccesss(EmployeeOrgTreeResponse));
        } else {
          dispatch(getEmployeeOrgTreeFailure(response.data.code));
        }
      })
      .catch((error) => {
        dispatch(getEmployeeOrgTreeFailure(error));
        EnhancedNotification.error({
          message:
            'You are not authorized to see the organizational tree of a higher grade employee.',
        });
      });
  };
};
export default getEmployeeOrgTree;
