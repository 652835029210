import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';

const EnhancedSelect = (props) => {
  // data-index,
  const {
    children,
    className,
    style,
    mode,
    placeholder,
    onChange,
    // value,
    tokenSeparators,
    allowClear,
    showSearch,
    optionFilterProp,
    ref,
    id,
    prefix,
    filterOption,
    disabled,
    key,
    name,
    onSelect,
    notFoundContent,
    showAction,
    size,
    onBlur,
    onInputKeyDown,
    defaultValue,
    required,
    defaultActiveFirstOption,
    showArrow,
    maxTagCount,
    maxTagTextLength,
    autoClearSearchValue,
    ...rest
  } = props;

  return (
    <Select
      style={style}
      className={className}
      mode={mode}
      placeholder={placeholder}
      onChange={onChange}
      // value={value}
      tokenSeparators={tokenSeparators}
      allowClear={allowClear}
      showSearch={showSearch}
      optionFilterProp={optionFilterProp}
      ref={ref}
      id={id}
      prefix={prefix}
      filterOption={filterOption}
      disabled={disabled}
      key={key}
      name={name}
      onSelect={onSelect}
      notFoundContent={notFoundContent}
      showAction={showAction}
      size={size}
      onBlur={onBlur}
      onInputKeyDown={onInputKeyDown}
      defaultValue={defaultValue}
      required={required}
      defaultActiveFirstOption={defaultActiveFirstOption}
      showArrow={showArrow}
      maxTagCount={maxTagCount}
      maxTagTextLength={maxTagTextLength}
      autoClearSearchValue={autoClearSearchValue}
      {...rest} // other remaining properties
    >
      {children}
    </Select>
  );
};

EnhancedSelect.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  allowClear: PropTypes.bool,
  autoClearSearchValue: PropTypes.bool,
  defaultActiveFirstOption: PropTypes.bool,
  disabled: PropTypes.bool,
  filterOption: PropTypes.bool,
  mode: PropTypes.string,
  notFoundContent: PropTypes.string,
  showArrow: PropTypes.bool,
  showSearch: PropTypes.bool,
  size: PropTypes.string,
};

EnhancedSelect.defaultProps = {
  className: '',
  style: {},
  allowClear: false,
  autoClearSearchValue: true,
  defaultActiveFirstOption: true,
  disabled: false,
  filterOption: true,
  mode: 'default',
  notFoundContent: 'Not found',
  showArrow: true,
  showSearch: false,
  size: 'default',
};

EnhancedSelect.Option = Select.Option;
EnhancedSelect.OptGroup = Select.OptGroup;

export default EnhancedSelect;
