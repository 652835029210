import {
  GET_EMPLOYEE_PROFILE_HEADER_INIT,
  GET_EMPLOYEE_PROFILE_HEADER_SUCCESS,
  GET_EMPLOYEE_PROFILE_HEADER_FAILURE,
  GET_EMPLOYEE_PROFILE_INIT,
  GET_EMPLOYEE_AUDIT_LOGS_INIT,
  EMPLOYEE_PROFILE_CLEAR,
  GET_EMPLOYEE_INFO_FAILURE,
  GET_EMPLOYEE_INFO_SUCCESS,
  JOB_INFO_CALL_INIT,
  JOB_INFO_CALL_FAILURE,
  GET_EMPLOYEE_JOB_INFO_SUCCESS,
  UPDATE_EMPLOYEE_JOB_INFO_SUCCESS,
  DELETE_EMPLOYEE_JOB_INFO_SUCCESS,
  CREATE_EMPLOYEE_JOB_INFO_SUCCESS,
  UPDATE_EMPLOYEE_TRACKING_INFO_INIT,
  UPDATE_EMPLOYEE_TRACKING_INFO_SUCCESS,
  UPDATE_EMPLOYEE_TRACKING_INFO_FAILURE,
  GET_EMPLOYEE_COMPENSATION_INIT,
  GET_EMPLOYEE_COMPENSATION_SUCCESS,
  GET_EMPLOYEE_COMPENSATION_FAILURE,
  GET_PAY_FREQUENCIES_SUCCESS,
  GET_PAY_FREQUENCIES_FAILURE,
  GET_BENEFITS_SUCCESS,
  GET_BENEFITS_FAILURE,
  GET_REASONS_SUCCESS,
  GET_REASONS_FAILURE,
  GET_EMPLOYEE_JOBS_LISTING_SUCCESS,
  GET_EMPLOYEE_JOBS_LISTING_FAILURE,
  UPDATE_EMPLOYEE_COMPENSATION_INIT,
  UPDATE_EMPLOYEE_COMPENSATION_SUCCESS,
  UPDATE_EMPLOYEE_COMPENSATION_FAILURE,
  CREATE_EMPLOYEE_COMPENSATION_INIT,
  CREATE_EMPLOYEE_COMPENSATION_SUCCESS,
  CREATE_EMPLOYEE_COMPENSATION_FAILURE,
  GET_EMPLOYEE_TALENT_SUCCESS,
  GET_EMPLOYEE_TALENT_FAILURE,
  UPDATE_EMPLOYEE_TALENT_INIT,
  UPDATE_EMPLOYEE_TALENT_SUCCESS,
  UPDATE_EMPLOYEE_TALENT_FAILURE,
  UPDATE_EMPLOYEE_CAREER_PLANNING_INIT,
  UPDATE_EMPLOYEE_CAREER_PLANNING_SUCCESS,
  UPDATE_EMPLOYEE_CAREER_PLANNING_FAILURE,
  CREATE_EMPLOYEE_CERTIFICATES_INIT,
  CREATE_EMPLOYEE_CERTIFICATES_SUCCESS,
  CREATE_EMPLOYEE_CERTIFICATES_FAILURE,
  GET_EMPLOYEE_CERTIFICATES_INIT,
  GET_EMPLOYEE_CERTIFICATES_SUCCESS,
  GET_EMPLOYEE_CERTIFICATES_FAILURE,
  UPDATE_EMPLOYEE_CERTIFICATES_INIT,
  UPDATE_EMPLOYEE_CERTIFICATES_SUCCESS,
  UPDATE_EMPLOYEE_CERTIFICATES_FAILURE,
  DELETE_EMPLOYEE_CERTIFICATES_INIT,
  DELETE_EMPLOYEE_CERTIFICATES_SUCCESS,
  DELETE_EMPLOYEE_CERTIFICATES_FAILURE,
  GET_EMPLOYEE_EMPLOYMENT_HISTORY_SUCCESS,
  GET_EMPLOYEE_EMPLOYMENT_HISTORY_FAILURE,
  GET_EMPLOYEE_EDUCATION_HISTORY_SUCCESS,
  GET_EMPLOYEE_EDUCATION_HISTORY_FAILURE,
  CREATE_EMPLOYEE_EDUCATION_HISTORY_INIT,
  CREATE_EMPLOYEE_EDUCATION_HISTORY_SUCCESS,
  CREATE_EMPLOYEE_EDUCATION_HISTORY_FAILURE,
  EMPLOYMENT_CALL_INIT,
  EMPLOYMENT_CALL_FAILURE,
  UPDATE_EMPLOYEE_EMPLOYMENT_SUCCESS,
  CREATE_EMPLOYEE_EMPLOYMENT_SUCCESS,
  DELETE_EMPLOYEE_EMPLOYMENT_SUCCESS,
  UPDATE_EMPLOYEE_EDUCATION_HISTORY_INIT,
  UPDATE_EMPLOYEE_EDUCATION_HISTORY_SUCCESS,
  UPDATE_EMPLOYEE_EDUCATION_HISTORY_FAILURE,
  DELETE_EMPLOYEE_EDUCATION_HISTORY_INIT,
  DELETE_EMPLOYEE_EDUCATION_HISTORY_SUCCESS,
  DELETE_EMPLOYEE_EDUCATION_HISTORY_FAILURE,
  GET_EMPLOYEE_DOCUMENT_URL_SUCCESS,
  GET_COMMENT_ATTACHMENT_URL_SUCCESS,
  GET_EMPLOYEE_DOCUMENT_URL_FAILURE,
  GET_COMMENT_ATTACHMENT_URL_FAILURE,
  CREATE_EMPLOYEE_DOCUMENT_SUCCESS,
  DELETE_EMPLOYEE_DOCUMENT_SUCCESS,
  GET_EMPLOYEE_DOCUMENTS_SUCCESS,
  GET_EMPLOYEE_DOCUMENTS_FAILURE,
  GET_EMPLOYEE_COMMENTS_SUCCESS,
  GET_EMPLOYEE_COMMENTS_FAILURE,
  CREATE_EMPLOYEE_COMMENT_INIT,
  CREATE_EMPLOYEE_COMMENT_SUCCESS,
  CREATE_EMPLOYEE_COMMENT_FAILURE,
  DELETE_EMPLOYEE_COMMENT_INIT,
  DELETE_EMPLOYEE_COMMENT_SUCCESS,
  DELETE_EMPLOYEE_COMMENT_FAILURE,
  GET_EMPLOYEE_AUDIT_LOGS_SUCCESS,
  GET_EMPLOYEE_AUDIT_LOGS_FAILURE,
  GET_MARITAL_STATUSES_INIT,
  GET_MARITAL_STATUSES_SUCCESS,
  GET_MARITAL_STATUSES_FAILURE,
  GET_EMPLOYMENT_STATUSES_INIT,
  GET_EMPLOYMENT_STATUSES_SUCCESS,
  GET_EMPLOYMENT_STATUSES_FAILURE,
  GET_BLOOD_GROUPS_INIT,
  GET_BLOOD_GROUPS_SUCCESS,
  GET_BLOOD_GROUPS_FAILURE,
  UPDATE_EMPLOYEE_PICTURE_SUCCESS,
  UPDATE_EMPLOYEE_PICTURE_FAILURE,
  UPDATE_EMPLOYEE_PICTURE_INIT,
  GET_RELIGIONS_INIT,
  GET_RELIGIONS_SUCCESS,
  GET_RELIGIONS_FAILURE,
  GET_QUALIFICATIONS_INIT,
  GET_QUALIFICATIONS_SUCCESS,
  GET_QUALIFICATIONS_FAILURE,
  UPDATE_EMPLOYEE_INFO_INIT,
  UPDATE_EMPLOYEE_INFO_SUCCESS,
  UPDATE_EMPLOYEE_INFO_FAILURE,
  UPDATE_EMPLOYEE_EMERGENCY_CONTACT_INIT,
  UPDATE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS,
  UPDATE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE,
  DELETE_EMPLOYEE_EMERGENCY_CONTACT_INIT,
  DELETE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS,
  DELETE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE,
  CREATE_EMPLOYEE_EMERGENCY_CONTACT_INIT,
  CREATE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS,
  CREATE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE,
  CREATE_EMPLOYEE_FAMILY_INFO_INIT,
  CREATE_EMPLOYEE_FAMILY_INFO_SUCCESS,
  CREATE_EMPLOYEE_FAMILY_INFO_FAILURE,
  UPDATE_EMPLOYEE_FAMILY_INFO_INIT,
  UPDATE_EMPLOYEE_FAMILY_INFO_SUCCESS,
  UPDATE_EMPLOYEE_FAMILY_INFO_FAILURE,
  DELETE_EMPLOYEE_FAMILY_INFO_INIT,
  DELETE_EMPLOYEE_FAMILY_INFO_SUCCESS,
  DELETE_EMPLOYEE_FAMILY_INFO_FAILURE,
  GET_EMPLOYEE_TRACKING_INFO_INIT,
  GET_EMPLOYEE_TRACKING_INFO_SUCCESS,
  GET_EMPLOYEE_TRACKING_INFO_FAILURE,
  GET_PERFORMANCE_REVIEW_INIT,
  GET_PERFORMANCE_REVIEW_SUCCESS,
  GET_PERFORMANCE_REVIEW_FAILURE,
} from 'constants/EmployeeProfileTypes';
import {
  getEmployeeProfileHeader,
  getEmployeeInfo,
  updateEmployeeInfo,
  createEmployeeFamilyInfo,
  updateEmployeeFamilyInfo,
  deleteEmployeeFamilyInfo,
  getEmployeeJobInfo,
  updateEmployeeJobInfo,
  deleteEmployeeJobInfo,
  createEmployeeJobInfo,
  updateEmployeeTrackingInfo,
  getEmployeeCompensation,
  getPayFrequencies,
  getReasons,
  getBenefits,
  getEmployeeJobsListing,
  updateEmployeeCompensation,
  createEmployeeCompensation,
  getEmployeeTalent,
  updateEmployeeTalent,
  updateEmployeeCareerPlanning,
  createEmployeeCertificates,
  getEmployeeCertificates,
  updateEmployeeCertificates,
  deleteEmployeeCertificates,
  getEmployeeEmploymentHistory,
  getEmployeeEducationHistory,
  createEmployeeEducationHistory,
  updateEmployeeEducationHistory,
  deleteEmployeeEducationHistory,
  updateEmployeeEmploymentHistory,
  createEmployeeEmploymentHistory,
  deleteEmployeeEmploymentHistory,
  getEmployeeDocuments,
  getEmployeeComment,
  createEmployeeComment,
  getEmployeeHistoryLogs,
  deleteEmployeeComment,
  getEmployeeAuditLogs,
  getMaritalStatuses,
  getEmploymentStatuses,
  getBloodGroups,
  getReligions,
  getQualifications,
  getEmployeeDocumentURL,
  getCommentAttachmentURL,
  uploadEmployeeDocument,
  updateEmployeePicture,
  deleteEmployeeDocument,
  updateEmployeeEmergencyContact,
  deleteEmployeeEmergencyContact,
  createEmployeeEmergencyContact,
  getEmployeeTrackingInfo,
  getDropdownOptions,
  getPerformanceReview,
} from 'sources/MyProfileSource';
import { redirectToAllEmployees } from 'utils/employeeUtils';

export const getAuditLogsInit = () => ({
  type: GET_EMPLOYEE_AUDIT_LOGS_INIT,
});

export const getEmployeeProfileInit = () => ({
  type: GET_EMPLOYEE_PROFILE_INIT,
});

export const getEmployeeProfileHeaderInit = () => ({
  type: GET_EMPLOYEE_PROFILE_HEADER_INIT,
});
export const getEmployeeProfileHeaderSuccess = (data) => ({
  type: GET_EMPLOYEE_PROFILE_HEADER_SUCCESS,
  data,
});

export const getEmployeeProfileHeaderFailure = () => ({
  type: GET_EMPLOYEE_PROFILE_HEADER_FAILURE,
});

export const employeeProfileClear = () => ({
  type: EMPLOYEE_PROFILE_CLEAR,
});

export const getEmployeeInfoFailure = () => ({
  type: GET_EMPLOYEE_INFO_FAILURE,
});

export const getEmployeeInfoSuccess = (data) => ({
  type: GET_EMPLOYEE_INFO_SUCCESS,
  data,
});

export const updateEmployeeInfoInit = () => ({
  type: UPDATE_EMPLOYEE_INFO_INIT,
});

export const updateEmployeeInfoSuccess = (data) => ({
  type: UPDATE_EMPLOYEE_INFO_SUCCESS,
  data,
});

export const updateEmployeeInfoFailure = () => ({
  type: UPDATE_EMPLOYEE_INFO_FAILURE,
});

export const updateEmployeeEmergencyContactInit = () => ({
  type: UPDATE_EMPLOYEE_EMERGENCY_CONTACT_INIT,
});

export const updateEmployeeEmergencyContactSuccess = (data) => ({
  type: UPDATE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS,
  data,
});

export const updateEmployeeEmergencyContactFailure = () => ({
  type: UPDATE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE,
});

export const createEmployeeFamilyInfoInit = () => ({
  type: CREATE_EMPLOYEE_FAMILY_INFO_INIT,
});

export const createEmployeeFamilyInfoSuccess = (data) => ({
  type: CREATE_EMPLOYEE_FAMILY_INFO_SUCCESS,
  data,
});

export const createEmployeeFamilyInfoFailure = () => ({
  type: CREATE_EMPLOYEE_FAMILY_INFO_FAILURE,
});

export const updateEmployeeFamilyInfoInit = () => ({
  type: UPDATE_EMPLOYEE_FAMILY_INFO_INIT,
});

export const updateEmployeeFamilyInfoSuccess = (index, data) => ({
  type: UPDATE_EMPLOYEE_FAMILY_INFO_SUCCESS,
  index,
  data,
});

export const updateEmployeeFamilyInfoFailure = () => ({
  type: UPDATE_EMPLOYEE_FAMILY_INFO_FAILURE,
});

export const deleteEmployeeFamilyInfoInit = () => ({
  type: DELETE_EMPLOYEE_FAMILY_INFO_INIT,
});

export const deleteEmployeeFamilyInfoSuccess = (index) => ({
  type: DELETE_EMPLOYEE_FAMILY_INFO_SUCCESS,
  index,
});

export const deleteEmployeeFamilyInfoFailure = () => ({
  type: DELETE_EMPLOYEE_FAMILY_INFO_FAILURE,
});

export const getEmployeeJobInfoFailure = () => ({
  type: JOB_INFO_CALL_FAILURE,
});

export const getEmployeeJobInfoSuccess = (data) => ({
  type: GET_EMPLOYEE_JOB_INFO_SUCCESS,
  data,
});

export const updateEmployeeJobInfoInit = () => ({
  type: JOB_INFO_CALL_INIT,
});

export const updateEmployeeJobInfoFailure = () => ({
  type: JOB_INFO_CALL_FAILURE,
});

export const updateEmployeeJobInfoSuccess = (data, index) => ({
  type: UPDATE_EMPLOYEE_JOB_INFO_SUCCESS,
  data,
  index,
});

export const deleteEmployeeJobInfoInit = () => ({
  type: JOB_INFO_CALL_INIT,
});

export const deleteEmployeeJobInfoFailure = () => ({
  type: JOB_INFO_CALL_FAILURE,
});

export const deleteEmployeeJobInfoSuccess = (id) => ({
  type: DELETE_EMPLOYEE_JOB_INFO_SUCCESS,
  id,
});

export const createEmployeeJobInfoInit = () => ({
  type: JOB_INFO_CALL_INIT,
});

export const createEmployeeJobInfoFailure = () => ({
  type: JOB_INFO_CALL_FAILURE,
});

export const createEmployeeJobInfoSuccess = (newJobInfo) => ({
  type: CREATE_EMPLOYEE_JOB_INFO_SUCCESS,
  data: newJobInfo,
});

export const updateEmployeeTrackingInfoInit = () => ({
  type: UPDATE_EMPLOYEE_TRACKING_INFO_INIT,
});

export const updateEmployeeTrackingInfoSuccess = (data) => ({
  type: UPDATE_EMPLOYEE_TRACKING_INFO_SUCCESS,
  data,
});

export const getEmployeeTrackingInfoInit = () => ({
  type: GET_EMPLOYEE_TRACKING_INFO_INIT,
});
export const getEmployeeTrackingInfoSuccess = (data) => ({
  type: GET_EMPLOYEE_TRACKING_INFO_SUCCESS,
  data,
});

export const getEmployeeTrackingInfoFailure = () => ({
  type: GET_EMPLOYEE_TRACKING_INFO_FAILURE,
});

export const updateEmployeeTrackingInfoFailure = () => ({
  type: UPDATE_EMPLOYEE_TRACKING_INFO_FAILURE,
});

export const getEmployeeCompensationinit = () => ({
  type: GET_EMPLOYEE_COMPENSATION_INIT,
});

export const getEmployeeCompensationSuccess = (data) => ({
  type: GET_EMPLOYEE_COMPENSATION_SUCCESS,
  data,
});

export const getEmployeeCompensationFailure = () => ({
  type: GET_EMPLOYEE_COMPENSATION_FAILURE,
});

export const getPayFrequenciesSuccess = (data) => ({
  type: GET_PAY_FREQUENCIES_SUCCESS,
  data,
});

export const getBenefitsSuccess = (data) => ({
  type: GET_BENEFITS_SUCCESS,
  data,
});

export const getPayFrequenciesFailure = () => ({
  type: GET_PAY_FREQUENCIES_FAILURE,
});

export const getBenefitsFailure = () => ({
  type: GET_BENEFITS_FAILURE,
});

export const getReasonsSuccess = (data) => ({
  type: GET_REASONS_SUCCESS,
  data,
});

export const getReasonsFailure = () => ({
  type: GET_REASONS_FAILURE,
});

export const getEmployeeJobsListingSuccess = (data) => ({
  type: GET_EMPLOYEE_JOBS_LISTING_SUCCESS,
  data,
});

export const getEmployeeJobsListingFailure = () => ({
  type: GET_EMPLOYEE_JOBS_LISTING_FAILURE,
});

export const createEmployeeCompensationInit = () => ({
  type: CREATE_EMPLOYEE_COMPENSATION_INIT,
});

export const createEmployeeCompensationSuccess = (data) => ({
  type: CREATE_EMPLOYEE_COMPENSATION_SUCCESS,
  data,
});

export const createEmployeeCompensationFailure = () => ({
  type: CREATE_EMPLOYEE_COMPENSATION_FAILURE,
});

export const updateEmployeeCompensationInit = () => ({
  type: UPDATE_EMPLOYEE_COMPENSATION_INIT,
});

export const updateEmployeeCompensationSuccess = (data) => ({
  type: UPDATE_EMPLOYEE_COMPENSATION_SUCCESS,
  data,
});

export const updateEmployeeCompensationFailure = () => ({
  type: UPDATE_EMPLOYEE_COMPENSATION_FAILURE,
});

export const getEmployeeTalentSuccess = (data) => ({
  type: GET_EMPLOYEE_TALENT_SUCCESS,
  data,
});

export const getEmployeeTalentFailure = () => ({
  type: GET_EMPLOYEE_TALENT_FAILURE,
});

export const getEmployeeEmploymentHistorySuccess = (data) => ({
  type: GET_EMPLOYEE_EMPLOYMENT_HISTORY_SUCCESS,
  data,
});

export const getEmployeeEmploymentHistoryFailure = () => ({
  type: GET_EMPLOYEE_EMPLOYMENT_HISTORY_FAILURE,
});

export const getEmployeeEducationHistorySuccess = (data) => ({
  type: GET_EMPLOYEE_EDUCATION_HISTORY_SUCCESS,
  data,
});

export const getEmployeeEducationHistoryFailure = () => ({
  type: GET_EMPLOYEE_EDUCATION_HISTORY_FAILURE,
});

export const getEmployeeDocumentsSuccess = (data) => ({
  type: GET_EMPLOYEE_DOCUMENTS_SUCCESS,
  data,
});

export const getEmployeeDocumentsFailure = () => ({
  type: GET_EMPLOYEE_DOCUMENTS_FAILURE,
});

export const getEmployeeDocumentURLFailure = () => ({
  type: GET_EMPLOYEE_DOCUMENT_URL_FAILURE,
});

export const getCommentAttachmentURLFailure = () => ({
  type: GET_COMMENT_ATTACHMENT_URL_FAILURE,
});

export const getEmployeeDocumentURLSuccess = (url) => ({
  type: GET_EMPLOYEE_DOCUMENT_URL_SUCCESS,
  url,
});

export const getCommentAttachmentURLSuccess = (url) => ({
  type: GET_COMMENT_ATTACHMENT_URL_SUCCESS,
  url,
});

export const createEmployeeDocumentSuccess = (data) => ({
  type: CREATE_EMPLOYEE_DOCUMENT_SUCCESS,
  data,
});

export const updateEmployeePictureSuccess = (url) => ({
  type: UPDATE_EMPLOYEE_PICTURE_SUCCESS,
  url,
});

export const updateEmployeePictureFailure = () => ({
  type: UPDATE_EMPLOYEE_PICTURE_FAILURE,
});

export const updateEmployeePictureInit = () => ({
  type: UPDATE_EMPLOYEE_PICTURE_INIT,
});

export const deleteEmployeeDocumentSuccess = (id) => ({
  type: DELETE_EMPLOYEE_DOCUMENT_SUCCESS,
  id,
});

export const getEmployeeCommentSuccess = (data) => ({
  type: GET_EMPLOYEE_COMMENTS_SUCCESS,
  data,
});

export const getEmployeeCommentFailure = () => ({
  type: GET_EMPLOYEE_COMMENTS_FAILURE,
});

export const getEmployeeAuditLogsSuccess = (data) => ({
  type: GET_EMPLOYEE_AUDIT_LOGS_SUCCESS,
  data,
});

export const getEmployeeAuditLogsFailure = () => ({
  type: GET_EMPLOYEE_AUDIT_LOGS_FAILURE,
});

export const getMartialStatusesInit = () => ({
  type: GET_MARITAL_STATUSES_INIT,
});

export const getMartialStatusesSuccess = (data) => ({
  type: GET_MARITAL_STATUSES_SUCCESS,
  data,
});

export const getMartialStatusesFailure = () => ({
  type: GET_MARITAL_STATUSES_FAILURE,
});
export const getEmploymentStatusesInit = () => ({
  type: GET_EMPLOYMENT_STATUSES_INIT,
});

export const getEmploymentStatusesSuccess = (data) => ({
  type: GET_EMPLOYMENT_STATUSES_SUCCESS,
  data,
});

export const getEmploymentStatusesFailure = () => ({
  type: GET_EMPLOYMENT_STATUSES_FAILURE,
});
export const getBloodGroupsInit = () => ({
  type: GET_BLOOD_GROUPS_INIT,
});

export const getBloodGroupsSuccess = (data) => ({
  type: GET_BLOOD_GROUPS_SUCCESS,
  data,
});

export const getBloodGroupsFailure = () => ({
  type: GET_BLOOD_GROUPS_FAILURE,
});

export const getReligionsInit = () => ({
  type: GET_RELIGIONS_INIT,
});

export const getReligionsSuccess = (data) => ({
  type: GET_RELIGIONS_SUCCESS,
  data,
});

export const getReligionsFailure = () => ({
  type: GET_RELIGIONS_FAILURE,
});

export const getQualificationsInit = () => ({
  type: GET_QUALIFICATIONS_INIT,
});

export const getQualificationsSuccess = (data) => ({
  type: GET_QUALIFICATIONS_SUCCESS,
  data,
});

export const getQualificationsFailure = () => ({
  type: GET_QUALIFICATIONS_FAILURE,
});

export const deleteEmergencyContactInit = () => ({
  type: DELETE_EMPLOYEE_EMERGENCY_CONTACT_INIT,
});

export const deleteEmergencyContactSuccess = (emergencyContactId) => ({
  type: DELETE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS,
  emergencyContactId,
});

export const deleteEmergencyContactFailure = () => ({
  type: DELETE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE,
});
export const createEmergencyContactInit = () => ({
  type: CREATE_EMPLOYEE_EMERGENCY_CONTACT_INIT,
});

export const createEmergencyContactSuccess = (data) => ({
  type: CREATE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS,
  data,
});

export const createEmergencyContactFailure = () => ({
  type: CREATE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE,
});

export const createEmployeeCommentInit = () => ({
  type: CREATE_EMPLOYEE_COMMENT_INIT,
});

export const createEmployeeCommentSuccess = (data) => ({
  type: CREATE_EMPLOYEE_COMMENT_SUCCESS,
  data,
});

export const createEmployeeCommentFailure = () => ({
  type: CREATE_EMPLOYEE_COMMENT_FAILURE,
});

export const deleteEmployeeCommentInit = () => ({
  type: DELETE_EMPLOYEE_COMMENT_INIT,
});

export const deleteEmployeeCommentSuccess = (commentId) => ({
  type: DELETE_EMPLOYEE_COMMENT_SUCCESS,
  id: commentId,
});

export const deleteEmployeeCommentFailure = () => ({
  type: DELETE_EMPLOYEE_COMMENT_FAILURE,
});

export const employmentCallInit = () => ({
  type: EMPLOYMENT_CALL_INIT,
});

export const employmentCallFailure = () => ({
  type: EMPLOYMENT_CALL_FAILURE,
});

export const updateEmployeeEmploymentSuccess = (data, index) => ({
  type: UPDATE_EMPLOYEE_EMPLOYMENT_SUCCESS,
  data,
  index,
});

export const createEmployeeEmploymentSuccess = (data, index) => ({
  type: CREATE_EMPLOYEE_EMPLOYMENT_SUCCESS,
  data,
  index,
});

export const deleteEmployeeEmploymentSuccess = (id) => ({
  type: DELETE_EMPLOYEE_EMPLOYMENT_SUCCESS,
  id,
});

// Employee Education History actions
export const createEmployeeEducationHistoryInit = () => ({
  type: CREATE_EMPLOYEE_EDUCATION_HISTORY_INIT,
});

export const createEmployeeEducationHistorySuccess = (data) => ({
  type: CREATE_EMPLOYEE_EDUCATION_HISTORY_SUCCESS,
  data,
});

export const createEmployeeEducationHistoryFailure = () => ({
  type: CREATE_EMPLOYEE_EDUCATION_HISTORY_FAILURE,
});

export const updateEmployeeEducationHistoryInit = () => ({
  type: UPDATE_EMPLOYEE_EDUCATION_HISTORY_INIT,
});

export const updateEmployeeEducationHistorySuccess = (data, index) => ({
  type: UPDATE_EMPLOYEE_EDUCATION_HISTORY_SUCCESS,
  data,
  index,
});

export const updateEmployeeEducationHistoryFailure = (index) => ({
  type: UPDATE_EMPLOYEE_EDUCATION_HISTORY_FAILURE,
  index,
});

export const deleteEmployeeEducationHistoryInit = () => ({
  type: DELETE_EMPLOYEE_EDUCATION_HISTORY_INIT,
});

export const deleteEmployeeEducationHistorySuccess = (id) => ({
  type: DELETE_EMPLOYEE_EDUCATION_HISTORY_SUCCESS,
  id,
});

export const deleteEmployeeEducationHistoryFailure = (index) => ({
  type: DELETE_EMPLOYEE_EDUCATION_HISTORY_FAILURE,
  index,
});

// Employee Talent actions
export const updateEmployeeTalentInit = () => ({
  type: UPDATE_EMPLOYEE_TALENT_INIT,
});

export const updateEmployeeTalentSuccess = (data) => ({
  type: UPDATE_EMPLOYEE_TALENT_SUCCESS,
  data,
});

export const updateEmployeeTalentFailure = () => ({
  type: UPDATE_EMPLOYEE_TALENT_FAILURE,
});

// Employee Career Planning actions
export const updateEmployeeCareerPlanningInit = () => ({
  type: UPDATE_EMPLOYEE_CAREER_PLANNING_INIT,
});

export const updateEmployeeCareerPlanningSuccess = (data) => ({
  type: UPDATE_EMPLOYEE_CAREER_PLANNING_SUCCESS,
  data,
});

export const updateEmployeeCareerPlanningFailure = () => ({
  type: UPDATE_EMPLOYEE_CAREER_PLANNING_FAILURE,
});

// Employee Certificates actions
export const createEmployeeCertificatesInit = () => ({
  type: CREATE_EMPLOYEE_CERTIFICATES_INIT,
});

export const createEmployeeCertificatesSuccess = (data) => ({
  type: CREATE_EMPLOYEE_CERTIFICATES_SUCCESS,
  data,
});

export const createEmployeeCertificatesFailure = () => ({
  type: CREATE_EMPLOYEE_CERTIFICATES_FAILURE,
});

export const getEmployeeCertificatesInit = () => ({
  type: GET_EMPLOYEE_CERTIFICATES_INIT,
});

export const getEmployeeCertificatesSuccess = (data) => ({
  type: GET_EMPLOYEE_CERTIFICATES_SUCCESS,
  data,
});

export const getEmployeeCertificatesFailure = () => ({
  type: GET_EMPLOYEE_CERTIFICATES_FAILURE,
});

export const updateEmployeeCertificatesInit = () => ({
  type: UPDATE_EMPLOYEE_CERTIFICATES_INIT,
});

export const updateEmployeeCertificatesSuccess = (data, index) => ({
  type: UPDATE_EMPLOYEE_CERTIFICATES_SUCCESS,
  data,
  index,
});

export const updateEmployeeCertificatesFailure = (index) => ({
  type: UPDATE_EMPLOYEE_CERTIFICATES_FAILURE,
  index,
});

export const deleteEmployeeCertificatesInit = () => ({
  type: DELETE_EMPLOYEE_CERTIFICATES_INIT,
});

export const deleteEmployeeCertificatesSuccess = (id) => ({
  type: DELETE_EMPLOYEE_CERTIFICATES_SUCCESS,
  id,
});

export const deleteEmployeeCertificatesFailure = (index) => ({
  type: DELETE_EMPLOYEE_CERTIFICATES_FAILURE,
  index,
});

// Performance Review

export const getPerformanceReviewInit = () => ({
  type: GET_PERFORMANCE_REVIEW_INIT,
});

export const getPerformanceReviewSuccess = (data) => ({
  type: GET_PERFORMANCE_REVIEW_SUCCESS,
  data,
});

export const getPerformanceReviewFailure = (data) => ({
  type: GET_PERFORMANCE_REVIEW_FAILURE,
  data,
});

export const getPerformanceReviewAction = (requestObject) => (dispatch) => {
  dispatch(getPerformanceReview(requestObject));
};
export const getEmployeeProfileHeaderAction = (id) => getEmployeeProfileHeader(id);
export const getEmployeeInfoAction = (id) => getEmployeeInfo(id);
export const updateEmployeeInfoAction = (id, data) => updateEmployeeInfo(id, data);
export const createEmployeeFamilyInfoAction = (familyInfofId, data) =>
  createEmployeeFamilyInfo(familyInfofId, data);
export const updateEmployeeFamilyInfoAction = (index, id, familyInfofId, data) =>
  updateEmployeeFamilyInfo(index, id, familyInfofId, data);
export const deleteEmployeeFamilyInfoAction = (index, id, familyInfofId) =>
  deleteEmployeeFamilyInfo(index, id, familyInfofId);
export const getEmployeeJobInfoAction = (id) => getEmployeeJobInfo(id);
export const updateEmployeeJobInfoAction = (id, jobInfoId, data, index) =>
  updateEmployeeJobInfo(id, jobInfoId, data, index);
export const deleteEmployeeJobInfoAction = (id, jobInfoId, index) =>
  deleteEmployeeJobInfo(id, jobInfoId, index);
export const createEmployeeJobInfoAction = (id, data, listingData) =>
  createEmployeeJobInfo(id, data, listingData);
export const updateEmployeeTrackingInfoAction = (id, data) => updateEmployeeTrackingInfo(id, data);
export const getEmployeeCompensationAction = (id) => getEmployeeCompensation(id);
export const getBenefitsAction = (id) => getBenefits(id);
export const getEmployeeJobsListingAction = (id) => getEmployeeJobsListing(id);
export const getReasonsAction = () => getReasons();
export const getPayFrequenciesAction = (id) => getPayFrequencies(id);
export const updateEmployeeCompensationAction = (id, compensationId, data) =>
  updateEmployeeCompensation(id, compensationId, data);
export const createEmployeeCompensationAction = (id, data) => createEmployeeCompensation(id, data);
export const getEmployeeTalentAction = (id) => getEmployeeTalent(id);
export const getEmployeeEmploymentHistoryAction = (id) => getEmployeeEmploymentHistory(id);
export const getEmployeeEducationHistoryAction = (id) => getEmployeeEducationHistory(id);
export const getEmployeeDocumentURLAction = (employeeId, documentId) =>
  getEmployeeDocumentURL(employeeId, documentId);
export const getCommentAttachmentURLAction = (employeeId, commentId) =>
  getCommentAttachmentURL(employeeId, commentId);
export const uploadEmployeeDocumentAction = (base64, fileName, empId) =>
  uploadEmployeeDocument(base64, fileName, empId);
export const updateEmployeePictureAction = (empId, base64) => updateEmployeePicture(empId, base64);
export const deleteEmployeeDocumentAction = (empId, documentId) =>
  deleteEmployeeDocument(empId, documentId);
export const getEmployeeDocumentsAction = (id) => getEmployeeDocuments(id);
export const getEmployeeCommentAction = (id) => getEmployeeComment(id);
export const createEmployeeCommentAction = (employeeId, data) =>
  createEmployeeComment(employeeId, data);
export const deleteEmployeeCommentAction = (employeeId, commentId) =>
  deleteEmployeeComment(employeeId, commentId);
export const getEmployeeAuditLogsAction = (id) => getEmployeeAuditLogs(id);
export const employeeNotFoundAction = () => redirectToAllEmployees();
export const getMaritalStatusesAction = () => getMaritalStatuses();
export const getEmploymentStatusesAction = () => getEmploymentStatuses();
export const getBloodGroupsAction = () => getBloodGroups();
export const getReligionsAction = () => getReligions();
export const getQualificationsAction = () => getQualifications();
export const createEmployeeEmergencyContactAction = (id, data) =>
  createEmployeeEmergencyContact(id, data);
export const updateEmployeeEmergencyContactAction = (id, data) =>
  updateEmployeeEmergencyContact(id, data);
export const deleteEmployeeEmergencyContactAction = (employeeId, emergencyContactId) =>
  deleteEmployeeEmergencyContact(employeeId, emergencyContactId);

// Employee Education History source actions
export const createEmployeeEducationHistoryAction = (employeeId, data) =>
  createEmployeeEducationHistory(employeeId, data);
export const updateEmployeeEducationHistoryAction = (employeeId, educationId, data, index) =>
  updateEmployeeEducationHistory(employeeId, educationId, data, index);
export const deleteEmployeeEducationHistoryAction = (employeeId, educationId) =>
  deleteEmployeeEducationHistory(employeeId, educationId);

// Employee Talent source actions
export const updateEmployeeTalentAction = (id, data) => updateEmployeeTalent(id, data);

// Employee Career Planning source actions
export const updateEmployeeCareerPlanningAction = (id, data) =>
  updateEmployeeCareerPlanning(id, data);

// Employee Certificates source actions
export const createEmployeeCertificatesAction = (employeeId, data) =>
  createEmployeeCertificates(employeeId, data);

export const getEmployeeCertificatesAction = (employeeId, data) =>
  getEmployeeCertificates(employeeId, data);

export const updateEmployeeCertificatesAction = (employeeId, educationId, data, index) =>
  updateEmployeeCertificates(employeeId, educationId, data, index);
export const deleteEmployeeCertificatesAction = (employeeId, educationId) =>
  deleteEmployeeCertificates(employeeId, educationId);

// Employee Employment History source actions
export const createEmployeeEmploymentHistoryAction = (employeeId, data, index) =>
  createEmployeeEmploymentHistory(employeeId, data, index);
export const updateEmployeeEmploymentHistoryAction = (employeeId, employmentId, data, index) =>
  updateEmployeeEmploymentHistory(employeeId, employmentId, data, index);
export const deleteEmployeeEmploymentHistoryAction = (employeeId, employmentId) =>
  deleteEmployeeEmploymentHistory(employeeId, employmentId);

export const getEmployeeTrackingInfoAction = (employeeId) => getEmployeeTrackingInfo(employeeId);
export const getDropdownOptionsAction = () => getDropdownOptions();
export const getEmployeeHistoryLogsAction = (id) => getEmployeeHistoryLogs(id);
