import { EnhancedRow, EnhancedCol } from 'components/shared/antd';
import React from 'react';
import DateCard from 'components/DateCard';
import LogWorkBar from 'components/LogWorkBar';

const AddLogWorkCard = (props) => (
  <EnhancedRow className="logwork-card" type="flex" justify="start">
    <EnhancedCol lg={3} md={3} sm={24} xs={24}>
      <DateCard date={props.date} isLogged={false} />
    </EnhancedCol>
    <EnhancedCol lg={17} md={17} sm={24} xs={24} className="flexy-center">
      <LogWorkBar date={props.date} onAddWorkLogClicked={props.onAddWorkLogClicked} />
    </EnhancedCol>
  </EnhancedRow>
);

export default AddLogWorkCard;
