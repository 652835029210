import {
  EnhancedDivider,
  EnhancedList,
  EnhancedCollapse,
  EnhancedListItem,
} from 'components/shared/antd';
import React, { Fragment } from 'react';

import './Marketing.css';

const { Panel } = EnhancedCollapse;

const data = {
  'Human Resources': [
    {
      title: 'HR Portal',
      link: 'https://sites.google.com/venturedive.com/hrportal/home?authuser=0',
    },
    { title: 'CRMC Portal', link: 'https://sites.google.com/venturedive.com/vd-crmc/home' },
    {
      title: 'Our Values',
      link: 'https://sites.google.com/venturedive.com/hrportal/our-values?authuser=0',
    },
    {
      title: 'Company Policies',
      link: 'https://sites.google.com/venturedive.com/hrportal/policies?authuser=0',
    },
    {
      title: 'Career Paths',
      link: 'https://sites.google.com/venturedive.com/hrportal/career-paths?authuser=0',
    },
  ],
  'Marketing & Brand': [
    {
      title: 'Marketing Wiki',
      link: 'https://sites.google.com/venturedive.com/marketing/home?pli=1&authuser=0',
    },
    {
      title: 'Brand Guidelines',
      link: 'https://drive.google.com/drive/folders/1Z2A6T6wKVIXByWPutkqSsoZ4Z8eXHOCs',
    },
    {
      title: 'Corporate Decks',
      link: 'https://drive.google.com/drive/folders/1mTEY4OP_xjGOICko2Awhp9b8MTKhWFNd',
    },
  ],
  'Project Management & Delivery': [
    {
      title: 'Project Management Office',
      link: 'https://sites.google.com/a/venturedive.com/pmo/pmo-home',
    },
    {
      title: 'SOP’s & Information',
      link: 'https://sites.google.com/venturedive.com/vd-crmc/sops?authuser=0',
    },
  ],
  'Quality Assurance': [
    { title: 'QA Hub', link: 'https://sites.google.com/venturedive.com/sqa/' },
    { title: 'Learning', link: 'https://sites.google.com/venturedive.com/sqa/learning?authuser=0' },
    {
      title: 'Repository',
      link: 'https://sites.google.com/venturedive.com/sqa/repository?authuser=0',
    },
  ],
};

class Marketing extends React.Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { activeKey: '1' };
  }

  handleChange = (key) => {
    this.setState({ activeKey: key });
  };

  render() {
    const { activeKey } = this.state;

    return (
      <EnhancedCollapse
        className="collapse-menu-container marketingContainer"
        defaultActiveKey={['1']}
        accordion
        onChange={this.handleChange}
      >
        {Object.keys(data).map((key, index) => {
          const isPanelActive = activeKey === (index + 1).toString();
          return (
            <Panel
              className={`${index === 0 && 'top-border-rounded'} ${
                isPanelActive && 'active-title-background white-collapse-arrow '
              }`}
              header={
                <div
                  className={`format-list-title ${
                    isPanelActive ? 'active-title-content' : 'inactive-title-content'
                  }`}
                >
                  {key}
                </div>
              }
              // eslint-disable-next-line react/no-array-index-key
              key={index + 1}
            >
              <EnhancedList
                size="large"
                dataSource={data[key]}
                renderItem={(item, listIndex) => (
                  <Fragment>
                    <EnhancedListItem className="remove-list-padding format-list-item">
                      <a
                        // style={{ color: 'gray' }}
                        href={item.link}
                        target="_blank"
                      >
                        {item.title}
                      </a>
                      {/* {item.title} */}
                    </EnhancedListItem>
                    {listIndex < data[key].length - 1 && (
                      <EnhancedDivider className="divider-margin-23" />
                    )}
                  </Fragment>
                )}
              />
            </Panel>
          );
        })}
      </EnhancedCollapse>
    );
  }
}

export default Marketing;
