import {
  getGoalCategoryGoal,
  getGoalCategoryGoalDetails,
  removeGoalCategoryGoal,
  addGoalCategoryGoal,
  updateGoalCategoryGoal,
  getGoalCategoryLogsGoal,
  assignGoalCategory,
} from 'sources/admin/PredefinedGoalCategoriesDetailSource';

import {
  GET_GOAL_CATEGORY_GOAL_INIT,
  GET_GOAL_CATEGORY_GOAL_SUCCESS,
  GET_GOAL_CATEGORY_GOAL_FAILURE,
  GET_GOAL_CATEGORY_GOAL_DETAILS_INIT,
  GET_GOAL_CATEGORY_GOAL_DETAILS_SUCCESS,
  GET_GOAL_CATEGORY_GOAL_DETAILS_FAILURE,
  ADD_GOAL_CATEGORY_GOAL_INIT,
  ADD_GOAL_CATEGORY_GOAL_SUCCESS,
  ADD_GOAL_CATEGORY_GOAL_FAILURE,
  UPDATE_GOAL_CATEGORY_GOAL_INIT,
  UPDATE_GOAL_CATEGORY_GOAL_SUCCESS,
  UPDATE_GOAL_CATEGORY_GOAL_FAILURE,
  DELETE_GOAL_CATEGORY_GOAL_INIT,
  DELETE_GOAL_CATEGORY_GOAL_SUCCESS,
  DELETE_GOAL_CATEGORY_GOAL_FAILURE,
  GET_GOAL_CATEGORY_LOG_INIT,
  GET_GOAL_CATEGORY_LOG_SUCCESS,
  GET_GOAL_CATEGORY_LOG_FAILURE,
  UPDATE_GOAL_CATEGORY_DETAIL_ASSIGN_GOAL_INIT,
  UPDATE_GOAL_CATEGORY_DETAIL_ASSIGN_GOAL_SUCCESS,
  UPDATE_GOAL_CATEGORY_DETAIL_ASSIGN_GOAL_FAILURE,
} from 'constants/admin/PredefinedGoalCategoryDetailTypes';

export const getGoalCategoryGoalInit = () => ({
  type: GET_GOAL_CATEGORY_GOAL_INIT,
});

export const getGoalCategoryGoalSuccess = (data) => ({
  type: GET_GOAL_CATEGORY_GOAL_SUCCESS,
  data,
});

export const getGoalCategoryGoalFailure = (data) => ({
  type: GET_GOAL_CATEGORY_GOAL_FAILURE,
  data,
});

export const addGoalCategoryGoalInit = () => ({
  type: ADD_GOAL_CATEGORY_GOAL_INIT,
});

export const addGoalCategoryGoalSuccess = (data) => ({
  type: ADD_GOAL_CATEGORY_GOAL_SUCCESS,
  data,
});

export const addGoalCategoryGoalFailure = () => ({
  type: ADD_GOAL_CATEGORY_GOAL_FAILURE,
});

export const getGoalCategoryGoalDetailsInit = () => ({
  type: GET_GOAL_CATEGORY_GOAL_DETAILS_INIT,
});

export const getGoalCategoryGoalDetailsSuccess = (data) => ({
  type: GET_GOAL_CATEGORY_GOAL_DETAILS_SUCCESS,
  data,
});

export const getGoalCategoryGoalDetailsFailure = (data) => ({
  type: GET_GOAL_CATEGORY_GOAL_DETAILS_FAILURE,
  data,
});

export const updateGoalCategoryGoalInit = () => ({
  type: UPDATE_GOAL_CATEGORY_GOAL_INIT,
});

export const updateGoalCategoryGoalSuccess = (data) => ({
  type: UPDATE_GOAL_CATEGORY_GOAL_SUCCESS,
  data,
});

export const updateGoalCategoryGoalFailure = () => ({
  type: UPDATE_GOAL_CATEGORY_GOAL_FAILURE,
});

export const deleteGoalCategoryGoalInit = () => ({
  type: DELETE_GOAL_CATEGORY_GOAL_INIT,
});

export const deleteGoalCategoryGoalSuccess = (data) => ({
  type: DELETE_GOAL_CATEGORY_GOAL_SUCCESS,
  data,
});

export const deleteGoalCategoryGoalFailure = () => ({
  type: DELETE_GOAL_CATEGORY_GOAL_FAILURE,
});

export const getGoalCategoryLogInit = () => ({
  type: GET_GOAL_CATEGORY_LOG_INIT,
});

export const getGoalCategoryLogSuccess = (data) => ({
  type: GET_GOAL_CATEGORY_LOG_SUCCESS,
  data,
});

export const getGoalCategoryLogFailure = (data) => ({
  type: GET_GOAL_CATEGORY_LOG_FAILURE,
  data,
});

export const updateGoalCategoryDetailAssignGoalInit = () => ({
  type: UPDATE_GOAL_CATEGORY_DETAIL_ASSIGN_GOAL_INIT,
});

export const updateGoalCategoryDetailAssignGoalSuccess = (data) => ({
  type: UPDATE_GOAL_CATEGORY_DETAIL_ASSIGN_GOAL_SUCCESS,
  data,
});

export const updateGoalCategoryDetailAssignGoalFailure = () => ({
  type: UPDATE_GOAL_CATEGORY_DETAIL_ASSIGN_GOAL_FAILURE,
});

export const getGoalCategoryGoalActions = (requestObject) => (dispatch) => {
  dispatch(getGoalCategoryGoal(requestObject));
};

export const getGoalCategoryGoalDetailsActions = (requestObject) => (dispatch) => {
  dispatch(getGoalCategoryGoalDetails(requestObject));
};

export const addGoalCategoryGoalActions = (requestObject) => (dispatch) => {
  dispatch(addGoalCategoryGoal(requestObject));
};

export const updateGoalCategoryGoalActions = (requestObject) => (dispatch) => {
  dispatch(updateGoalCategoryGoal(requestObject));
};

export const removeGoalCategoryGoalActions = (requestObject) => (dispatch) => {
  dispatch(removeGoalCategoryGoal(requestObject));
};

export const getGoalCategoryLogsActions = (requestObject) => (dispatch) => {
  dispatch(getGoalCategoryLogsGoal(requestObject));
};

export const assignGoalCategoryAction = (requestObject) => (dispatch) => {
  dispatch(assignGoalCategory(requestObject));
};
