import {
  getEmploymentType,
  postEmploymentType,
  editEmploymentType,
  deleteEmploymentType,
} from 'sources/EmploymentTypesSource';

import {
  ADD_EMPLOYMENT_TYPES_INIT,
  ADD_EMPLOYMENT_TYPES_SUCCESS,
  ADD_EMPLOYMENT_TYPES_FAILURE,
  GET_EMPLOYMENT_TYPES_INIT,
  GET_EMPLOYMENT_TYPES_SUCCESS,
  GET_EMPLOYMENT_TYPES_FAILURE,
  DELETE_EMPLOYMENT_TYPES_INIT,
  DELETE_EMPLOYMENT_TYPES_SUCCESS,
  DELETE_EMPLOYMENT_TYPES_FAILURE,
  EDIT_EMPLOYMENT_TYPES_INIT,
  EDIT_EMPLOYMENT_TYPES_SUCCESS,
  EDIT_EMPLOYMENT_TYPES_FAILURE,
} from 'constants/EmploymentTypes';

export const addEmploymentTypeInit = () => ({
  type: ADD_EMPLOYMENT_TYPES_INIT,
});

export const addEmploymentTypeSuccess = (data) => ({
  type: ADD_EMPLOYMENT_TYPES_SUCCESS,
  data,
});

export const addEmploymentTypeFailure = () => ({
  type: ADD_EMPLOYMENT_TYPES_FAILURE,
});

export const getEmploymentTypeInit = () => ({
  type: GET_EMPLOYMENT_TYPES_INIT,
});

export const getEmploymentTypeSuccess = (data) => ({
  type: GET_EMPLOYMENT_TYPES_SUCCESS,
  data,
});

export const getEmploymentTypeFailure = () => ({
  type: GET_EMPLOYMENT_TYPES_FAILURE,
});

export const deleteEmploymentTypeInit = () => ({
  type: DELETE_EMPLOYMENT_TYPES_INIT,
});

export const deleteEmploymentTypeSuccess = (data) => ({
  type: DELETE_EMPLOYMENT_TYPES_SUCCESS,
  data,
});

export const deleteEmploymentTypeFailure = () => ({
  type: DELETE_EMPLOYMENT_TYPES_FAILURE,
});

export const editEmploymentTypeInit = () => ({
  type: EDIT_EMPLOYMENT_TYPES_INIT,
});

export const editEmploymentTypeSuccess = (data) => ({
  type: EDIT_EMPLOYMENT_TYPES_SUCCESS,
  data,
});

export const editEmploymentTypeFailure = () => ({
  type: EDIT_EMPLOYMENT_TYPES_FAILURE,
});

// Employment Types Thunks
export const getEmploymentTypesAction = () => getEmploymentType();
export const addEmploymentTypeAction = (requestObject) => (dispatch) => {
  dispatch(postEmploymentType(requestObject));
};
export const editEmploymentTypeAction = (requestObject) => (dispatch) => {
  dispatch(editEmploymentType(requestObject));
};
export const deleteEmploymentTypeAction = (requestObject) => (dispatch) => {
  dispatch(deleteEmploymentType(requestObject));
};
