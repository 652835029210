import React, { Component } from 'react';

import onClickOutside from 'react-onclickoutside';

import emptyFace from 'images/empty-face.png';
import verySadFace from 'images/very-sad-face.png';
import sadFace from 'images/sad-face.png';
import happyFace from 'images/happy-face.png';
import veryHappyFace from 'images/very-happy-face.png';

import 'components/EmotionCard.css';

class EmotionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmoticonMenu: false,
    };
  }

  toggleEmoticon = () => {
    this.setState({
      showEmoticonMenu: !this.state.showEmoticonMenu,
    });
  };

  handleClickOutside = () => {
    if (this.state.showEmoticonMenu) {
      this.setState({
        showEmoticonMenu: false,
      });
    }
  };

  selectedIcon = (icon) => {
    if (this.props.onEditEmotionClicked) {
      this.props.onEditEmotionClicked(
        this.props.selectedWorkId,
        this.props.date,
        this.props.details,
        icon,
      );
    }
  };

  render() {
    const { mood } = this.props;
    return (
      <div className="emotion-card">
        {!mood ? (
          <div
            tabIndex={0}
            role="button"
            src={emptyFace}
            onClick={this.toggleEmoticon}
            onKeyPress={this.toggleEmoticon}
            onMouseLeave={this.hideEmoticon}
          >
            <img className="small-image" src={emptyFace} alt="How was your day?" />
          </div>
        ) : null}
        {mood > 0 && mood <= 25 ? <img className="emotion-image" alt="" src={verySadFace} /> : null}
        {mood > 25 && mood <= 50 ? <img className="emotion-image" alt="" src={sadFace} /> : null}
        {mood > 50 && mood <= 75 ? <img className="emotion-image" alt="" src={happyFace} /> : null}
        {mood > 75 && mood <= 100 ? (
          <img className="emotion-image" alt="" src={veryHappyFace} />
        ) : null}
        {!mood && <div>How was your day?</div>}
        {this.state.showEmoticonMenu && !mood ? (
          <ul className="emotion-selection">
            <li>
              <div
                role="button"
                tabIndex={0}
                onClick={() => this.selectedIcon('25')}
                onKeyPress={() => this.selectedIcon('25')}
              >
                <img style={{ width: '20px' }} alt="" src={verySadFace} />
              </div>
            </li>
            <li>
              <div
                role="button"
                tabIndex={0}
                onClick={() => this.selectedIcon('50')}
                onKeyPress={() => this.selectedIcon('50')}
              >
                <img style={{ width: '20px', marginLeft: '10px' }} alt="" src={sadFace} />
              </div>
            </li>
            <li>
              <div
                role="button"
                tabIndex={0}
                onClick={() => this.selectedIcon('75')}
                onKeyPress={() => this.selectedIcon('75')}
              >
                <img style={{ width: '20px', marginLeft: '10px' }} alt="" src={happyFace} />
              </div>
            </li>
            <li>
              <div
                role="button"
                tabIndex={0}
                onClick={() => this.selectedIcon('100')}
                onKeyPress={() => this.selectedIcon('100')}
              >
                <img style={{ width: '20px', marginLeft: '10px' }} alt="" src={veryHappyFace} />
              </div>
            </li>
          </ul>
        ) : null}
      </div>
    );
  }
}

export default onClickOutside(EmotionCard);
