/* eslint-disable no-prototype-builtins */
/* eslint-disable */
import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import projectIcon from 'images/project_icon.svg';
import { dateFormat, BREADCRUMBS, CRMC_FILE_TITLE } from 'configs/constants';
import crmcCyclesNormalizers from 'normalizers/crmcCyclesNormalizers';
import RequiredField from '../RequiredField';
import RatingModalCRMC from 'components/pc/ratingModalCRMC';
import { CSVLink } from 'react-csv';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import { normalizeEmployeeRatings } from 'normalizers/performanceCycleNormalizer';
import DueDateExtensionModal from './DueDateExtensionModal';
import leaveIndicatorIcon from 'images/ic_leave_indicator.svg';
import {
  EnhancedDatePicker,
  EnhancedSelect,
  EnhancedAlert,
  EnhancedMenu,
  EnhancedTabs,
  EnhancedTooltip,
  EnhancedCard,
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedIcon,
  EnhancedModal,
  EnhancedInput,
  EnhancedInputNumber,
  EnhancedDropdown,
  EnhancedForm,
} from 'components/shared/antd';

const { confirm } = EnhancedModal;
const { TabPane } = EnhancedTabs;
const { Option } = EnhancedSelect;
const { MonthPicker } = EnhancedDatePicker;

class PerformanceCycle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: {
        valueQuality: 0,
        valueEfficiency: 0,
        valueSkill: 0,
        valueOwnership: 0,
        valueEthics: 0,
      },
      project: {
        name: '',
        startDate: '',
        endDate: '',
        projectManagerId: undefined,
        employeeId: props.userId,
      },
      projectCycleData: {},
      showAddNewForm: false,
      modalOpen: false,
      errorText: '',
      gridViewType: 1,
      isProjectWise: true,
      updatePerformanceCycleModalOpen: false,
      filteredRecords: null,
    };
  }

  componentWillMount() {
    this.props.getProjectByCycleIdActionForCRMC(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    const { projectCycleData, updateBreadCrumb, isAdding, match } = nextProps;
    const { cycleInfo = {} } = projectCycleData;
    const { cycleName = '' } = cycleInfo;
    const { params } = match;
    const { id } = params;

    if (cycleName) {
      updateBreadCrumb(BREADCRUMBS.CYCLES, id, cycleName);
    }
    if (this.props.match.params.status !== nextProps.match.params.status) {
      this.clearSearch();
    }
    if (!isAdding && this.props.isAdding && this.state.modalOpen) {
      this.handleCancel();
    }
  }

  getEmployeeRatingColumnsInfo = () => {
    const columns = [
      {
        title: 'Resource Name',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        filterConfig: {
          type: 'search',
          key: 'name',
        },
        render: this.renderResourceName,
      },
      {
        title: 'Project Name',
        dataIndex: 'projectName',
        key: 'projectName',
        width: '13%',
        filterConfig: {
          type: 'search',
          key: 'projectName',
        },
      },
      {
        title: 'Competency',
        dataIndex: 'department',
        key: 'department',
        width: '13%',
        filterConfig: {
          type: 'search',
          key: 'department',
        },
      },
      {
        title: 'Line Manager',
        dataIndex: 'lineManager',
        key: 'lineManager',
        width: '13%',
        filterConfig: {
          type: 'search',
          key: 'lineManager',
        },
      },
      {
        title: 'PM Status',
        dataIndex: 'PMStatus',
        key: 'PMStatus',
        width: '13%',
        render: this.renderStatusName,
      },
      {
        title: 'Architect Status',
        dataIndex: 'archStatus',
        key: 'archStatus',
        width: '10%',
        render: this.renderStatusName,
      },
      {
        title: 'Feedback Status',
        dataIndex: 'feedbackStatus',
        key: 'feedbackStatus',
        width: '10%',
        render: this.renderStatusName,
      },
      {
        title: 'Action',
        width: '10%',
        render: this.renderEmployeeRatingIcon,
        excludeFromExport: true,
      },
    ];
    !this.isCycleComplete();

    return columns;
  };

  renderDueDateIndicator = (indicator, data) => {
    if (!data.allowedAfterDueDateBy) return null;
    return (
      <EnhancedTooltip
        title={`Rating due date extended till ${moment(data.dueDate).format(
          dateFormat.DDMMMYYYYwithSpace,
        )}.`}
      >
        <img src={leaveIndicatorIcon} alt="due date extension indicator" />
      </EnhancedTooltip>
    );
  };

  getColumnsInfo = () => {
    const actions = {
      title: 'Action',
      width: '10%',
      render: this.renderIcon,
      excludeFromExport: true,
    };

    const columns = [
      {
        title: '',
        dataIndex: 'dueDateIndicator',
        key: 'dueDateIndicator',
        render: this.renderDueDateIndicator,
        excludeFromExport: true,
      },
      {
        title: 'Project Name',
        dataIndex: 'name',
        key: 'name',
        width: '18%',
        filterConfig: {
          type: 'search',
          key: 'name',
        },
        render: this.renderProjectName,
      },
      {
        title: 'Project Manager',
        dataIndex: 'projectManager',
        key: 'projectManager',
        width: '18%',
        filterConfig: {
          type: 'search',
          key: 'projectManager',
        },
      },
      {
        title: 'Project Architect',
        dataIndex: 'projectArchitect',
        key: 'projectArchitect',
        width: '18%',
        filterConfig: {
          type: 'search',
          key: 'projectArchitect',
        },
        render: this.renderProjectArchitect,
      },
      {
        title: 'PM Ratings',
        dataIndex: 'pmRatings',
        key: 'pmRatings',
        width: '12%',
        render: this.renderPMRatings,
      },
      {
        title: 'Architect Ratings',
        dataIndex: 'archRatings',
        key: 'archRatings',
        width: '12%',
        render: this.renderArchRatings,
      },
      {
        title: 'Resource Ratings',
        dataIndex: 'resourceRatings',
        key: 'resourceRatings',
        width: '12%',
        render: this.renderResourceRatings,
      },
      {
        title: '',
        dataIndex: 'totalRatings',
        key: 'totalRatings',
        render: this.renderTotalRatings,
      },
    ];
    if (!this.isCycleComplete()) {
      columns.push(actions);
    }
    return columns;
  };

  handleProjectCycleDueDatesUpdated = (data) => {
    const { projectCycleData } = this.props;
    const performanceCycleId = projectCycleData.cycleInfo.id;
    const employeeId = this.props.userId;
    this.props.extendProjectCycleDueDatesAction({
      performanceCycleId,
      employeeId,
      projectDueDates: data,
    });
    this.hideDueDateExtensionModal();
  };

  renderProjectArchitect = (pmRatings, data) => {
    return data.projectArchitect ? data.projectArchitect : '-';
  };

  renderPMRatings = (pmRatings, data) => {
    return `${data.pmRatings}/${data.totalRatings}`;
  };

  renderArchRatings = (archRatings, data) => {
    if (data.hasArchitect) {
      return `${data.archRatings}/${data.totalRatings}`;
    }
    return '-';
  };

  renderResourceRatings = (resourceRatings, data) => {
    return `${data.resourceRatings}/${data.totalRatings}`;
  };

  renderTotalRatings = () => null;

  renderStatusName = (data) => {
    if (data) {
      return 'Complete';
    } else {
      return 'Pending';
    }
  };

  confirmDelete = (data) => {
    confirm({
      title: 'Are you sure, you want to Delete?',
      content: '',
      okText: 'Yes',
      cancelText: 'Cancel',
      onOk: () => {
        this.deleteProjectCycle(data);
      },
    });
  };

  confirmEmployeeRatingDelete = (data) => {
    confirm({
      title: 'Are you sure, you want to Delete?',
      content: '',
      okText: 'Yes',
      cancelText: 'Cancel',
      onOk: () => {
        this.deleteProjectRating(data);
      },
    });
  };

  showTotal = (total) => `Total ${total} items`;

  showAddNew = () => {
    this.setState({
      showAddNewForm: !this.state.showAddNewForm,
    });
  };

  showModal = () => {
    this.setState({
      modalOpen: true,
    });
  };

  handleCancel = () => {
    this.setState({
      modalOpen: false,
      project: {
        name: '',
        startDate: '',
        endDate: '',
        projectManagerId: '',
      },
      errorText: '',
    });
  };

  handleNameChange = (value) => {
    const project = { ...this.state.project };
    project.name = value.target.value;
    this.setState({
      project,
    });
  };

  handleManagerChange = (manager) => {
    this.onChange('projectManagerId', manager);
  };

  disabledStartDate = (startValue) => {
    const endValue = this.state.project.endDate ? moment.utc(this.state.project.endDate) : Date();
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = (endValue) => {
    const startValue = moment.utc(this.state.project.startDate).add(1, 'days');
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  /* handleSave = (value) => {
    value.preventDefault();
    const { name } = this.state.project;
    const { projects } = this.props;
    const similarProject = projects.filter(project =>
      project.name.toLowerCase() === name.toLowerCase())[0];

    if (similarProject) {
      this.setState({
        errorText: 'Project with the same name already exists.',
      });
    } else {
      this.props.addProject(this.state.project);
      this.handleCancel();
    }
  } */
  shouldButtonDisable = () => {
    const { project } = this.state;
    if (project.name && project.projectManagerId && project.startDate) return false;
    return true;
  };
  isCycleComplete = () => {
    const { projectCycleData } = this.props;
    let { cycleInfo } = projectCycleData;
    cycleInfo = cycleInfo || {};
    if (cycleInfo.status) {
      return true;
    }
    return false;
  };
  hasDueDatePassed = () => {
    const { projectCycleData } = this.props;
    try {
      const today = moment.utc();
      const dueDate = moment(projectCycleData.cycleInfo.dueDate);
      return dueDate < today;
    } catch (err) {
      return false;
    }
  };
  clearSearch = () => {
    this.setState({
      /*     searchManagerText: '',
      searchProjectText: '', */
    });
  };

  gridViewTypeChange = (value) => {
    if (value == 1) {
      this.props.getProjectByCycleIdActionForCRMC(this.props.match.params.id);
      this.setState({
        gridViewType: 1,
        isProjectWise: true,
      });
    } else {
      this.props.getCRMCPanelProjectDataAction(this.props.match.params.id, 0);
      this.setState({
        gridViewType: 2,
        isProjectWise: false,
      });
    }
  };

  deleteProjectCycle = (data) => {
    const payload = {
      performanceCycleId: data.performanceCycleId,
      projectCycleId: data.projectCycleId,
      employeeId: this.props.userId,
    };
    this.props.deleteProjectCycleAction(payload);
  };

  deleteProjectRating = (data) => {
    const payload = {
      employeeId: this.props.userId,
      performanceCycleId: this.props.match.params.id,
      projectId: data.projectId,
      resourceId: data.employeeId,
      ignoreProjectIdForNextCall: true,
    };

    this.props.removeProjectRating(payload);
  };

  renderEmployeeRatingIcon = (name, data) => (
    <span>
      <EnhancedIcon
        onClick={() => this.onViewIconClick(data)}
        type="eye"
        style={{
          color: '#bcbcff',
          name,
        }}
      />{' '}
      {!this.isCycleComplete() && (
        <span>
          {' '}
          |
          <EnhancedIcon
            onClick={() => this.confirmEmployeeRatingDelete(data)}
            type="delete"
            style={{
              color: '#bcbcff',
              name,
            }}
          />
        </span>
      )}
    </span>
  );

  openRatingModal = (data) => {
    this.setState({
      currentlyFocusId: data.id,
      rating: data,
      addEditResourceModalVisible: true,
      selectedResourceName: data.name,
    });
  };

  handleEditClose = (close) => {
    this.setState({ addEditResourceModalVisible: close });
  };

  onViewIconClick = (data) => {
    const { getRatingDetailsCRMC } = this.props;
    const payload = {
      performanceCycleId: this.props.match.params.id,
      projectId: data.projectId,
      resourceId: data.employeeId,
    };
    getRatingDetailsCRMC(payload);
    this.openRatingModal(data);
  };

  renderIcon = (name, data) => {
    const { projectCycleData } = this.props;
    const { cycleInfo } = projectCycleData;
    return (
      <div>
        <EnhancedIcon onClick={() => this.confirmDelete(data)} type="delete" />
      </div>
    );
  };
  renderProjectName = (name, data) => (
    <Link to={`/pc/performance-cycles-crmc/${this.props.match.params.id}/${data.id}`}>
      <b style={{ color: '#5A5A5A' }}>{name}</b>
    </Link>
  );
  renderResourceName = (name, data) => <b style={{ color: '#5A5A5A' }}>{name}</b>;

  showUpdatePerformanceCycleModal = () => {
    this.setState({
      updatePerformanceCycleModalOpen: true,
    });
  };

  showDueDateExtensionModal = () => {
    this.setState({
      extendDueDateModalOpen: true,
    });
  };

  hideDueDateExtensionModal = () => {
    this.setState({
      extendDueDateModalOpen: false,
    });
  };

  handleUpdatePerformanceCycleModalCancel = () => {
    this.setState({
      updatePerformanceCycleModalOpen: false,
      dueDate: null,
    });
  };

  shouldUpdateButtonDisable = () => {
    const { dueDate } = this.state;
    if (dueDate) {
      return false;
    }
    return true;
  };

  handleUpdatePerformanceCycleSubmit = (value) => {
    value.preventDefault();
    const { dueDate } = this.state;
    const employeeId = this.props.userId;
    const { projectCycleData } = this.props;
    const performanceCycleId = projectCycleData.cycleInfo.id;
    this.props.updatePerformanceCycleAction({ performanceCycleId, employeeId, dueDate });
    this.handleUpdatePerformanceCycleModalCancel();
  };

  completeCycleConfirmation = () => {
    const employeeId = this.props.userId;
    const { projectCycleData } = this.props;
    const performanceCycleId = projectCycleData.cycleInfo.id;
    confirm({
      title: `Are you sure you want to mark the cycle as Complete?`,
      content: 'Cycle Completed, Cannot be undone',
      onOk: () => {
        this.props.markPerformanceCycleCompleteAction({ performanceCycleId, employeeId });
      },
      onCancel() {},
    });
  };

  onFreezeDateChange = (date, dateString) => {
    this.setState({
      dueDate: dateString,
    });
  };

  disableDueDate = (date) => date < moment(this.state.initiationDate);

  renderFormRow = () => {
    const { employees } = this.props;
    const { errorText } = this.state;
    return (
      <EnhancedRow type="flex" justify="center">
        {errorText && (
          <EnhancedAlert
            style={{ width: '100%' }}
            message={errorText}
            type="error"
            closable
            onClose={this.onClose}
          />
        )}
        <EnhancedForm layout="inline">
          <EnhancedCol span={24} className="form-col">
            <p>
              <RequiredField>Project Name </RequiredField>
            </p>
            <EnhancedInput
              placeholder="Project Name"
              id="projectName"
              onChange={this.handleNameChange}
              value={this.state.project.name}
            />
          </EnhancedCol>
          <EnhancedCol span={24} className="form-col">
            <p>
              <RequiredField>Project Manager</RequiredField>
            </p>
            <EnhancedSelect
              showSearch
              allowClear
              optionFilterProp="children"
              value={this.state.project.projectManagerId}
              style={{ width: '100%' }}
              placeholder="Please Select"
              onChange={this.handleManagerChange}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {employees && employees.length
                ? employees.map((employee) =>
                    employee.isActive ? (
                      <Option key={employee.id} value={employee.id}>
                        {employee.name}
                      </Option>
                    ) : (
                      ''
                    ),
                  )
                : null}
            </EnhancedSelect>
          </EnhancedCol>
          <EnhancedCol span={24} className="form-col">
            <p>
              <RequiredField>Start Date</RequiredField>
            </p>
            <EnhancedDatePicker
              style={{ width: '100%' }}
              onChange={this.onStartDateChange}
              placeholder="Enter Start Date"
              format={dateFormat.DDMMMYYYYwithSpace}
              allowClear
              disabledDate={this.disabledStartDate}
            />
          </EnhancedCol>
          <EnhancedCol span={24} className="form-col">
            <p>End Date</p>
            <EnhancedDatePicker
              style={{ width: '100%' }}
              onChange={this.onEndDateChange}
              placeholder="Enter End Date"
              format={dateFormat.DDMMMYYYYwithSpace}
              allowClear
              disabledDate={this.disabledEndDate}
            />
          </EnhancedCol>
        </EnhancedForm>
      </EnhancedRow>
    );
  };

  renderUpdatePerformanceCycleModal = () => {
    const { updatePerformanceCycleModalOpen } = this.state;

    return (
      <EnhancedModal
        title="Update Performance Cycle"
        visible={updatePerformanceCycleModalOpen}
        userId={this.props.userId}
        onCancel={this.handleUpdatePerformanceCycleModalCancel}
        width={650}
        footer={[
          <EnhancedButton key="back" onClick={this.handleUpdatePerformanceCycleModalCancel}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            key="update"
            type="primary"
            // loading={isAdding}
            onClick={this.handleUpdatePerformanceCycleSubmit}
            disabled={this.shouldUpdateButtonDisable()}
          >
            Save
          </EnhancedButton>,
        ]}
      >
        {this.renderUpdatePerformanceCycleFormRow()}
      </EnhancedModal>
    );
  };

  renderUpdatePerformanceCycleFormRow = () => {
    const { projectCycleData } = this.props;
    let { cycleInfo } = projectCycleData;
    return (
      <EnhancedRow type="flex" justify="center">
        <EnhancedForm layout="inline">
          <EnhancedCol span={12} className="form-col">
            <p>
              <RequiredField>Cycle Month</RequiredField>
            </p>
            <MonthPicker
              id="mo"
              attr="mo"
              defaultValue={moment(cycleInfo.startDate)}
              disabled={true}
              placeholder="Select month"
            />
          </EnhancedCol>
          <EnhancedCol span={12} className="form-col">
            <p>
              <RequiredField>Criteria(days)</RequiredField>
            </p>
            <EnhancedInputNumber
              placeholder="Eligibility criteria in days"
              min={1}
              value={cycleInfo.criteria}
              disabled={true}
            />
          </EnhancedCol>
          <EnhancedCol span={12} className="form-col">
            <p>
              <RequiredField> Initiation Date</RequiredField>
            </p>
            <EnhancedDatePicker
              id="initiationDate"
              attr="initiationDate"
              defaultValue={moment(cycleInfo.initiationDate)}
              format={dateFormat.DDMMMYYYYwithSpace}
              disabled={true}
              placeholder="Select Date"
            />
          </EnhancedCol>
          <EnhancedCol span={12} className="form-col">
            <p>
              <RequiredField>Due Date</RequiredField>
            </p>
            <EnhancedDatePicker
              id="dueDate"
              attr="dueDate"
              defaultValue={cycleInfo.dueDate ? moment(cycleInfo.dueDate) : null}
              onChange={this.onFreezeDateChange}
              format={dateFormat.DDMMMYYYYwithSpace}
              disabledDate={this.disableDueDate}
              placeholder="Select Date"
            />
          </EnhancedCol>
        </EnhancedForm>
      </EnhancedRow>
    );
  };

  renderModal = () => {
    const { isAdding } = this.props;
    const { modalOpen } = this.state;
    return (
      <EnhancedModal
        title="New Project"
        visible={modalOpen}
        userId={this.props.userId}
        onCancel={this.handleCancel}
        width={650}
        footer={[
          <EnhancedButton key="back" onClick={this.handleCancel}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            key="add"
            type="primary"
            loading={isAdding}
            disabled={this.shouldButtonDisable()}
            onClick={this.handleSubmit}
          >
            Save
          </EnhancedButton>,
        ]}
      >
        {this.renderFormRow()}
      </EnhancedModal>
    );
  };

  getProjectsForCycle = () => {
    const { projectCycleData } = this.props;
    const projectCycles = projectCycleData.projectCycles || [];
    return projectCycles.map((pc) => ({
      id: pc.id,
      name: pc.name,
    }));
  };

  handleExportMenuClick = (event) => {
    if (event.key === 'ratings') {
      const { cycleInfo } = this.props.projectCycleData;
      this.props.downloadPerformanceRatingsAction(cycleInfo);
    }
  };

  render() {
    const { isLoading, ratingDetails, resetRatingDetails } = this.props;
    const { projectRating } = this.props;
    const { isProjectWise, filteredRecords } = this.state;
    let { projectRatings } = projectRating;
    const { projectCycleData } = this.props;
    let projectCycles = projectCycleData.projectCycles || [];
    let { cycleInfo } = projectCycleData;
    cycleInfo = cycleInfo || {};
    const columnInfo = isProjectWise ? this.getColumnsInfo() : this.getEmployeeRatingColumnsInfo();
    let employeeCount = 0;
    let recordCount = 0;
    let ratingCount = '';
    let exportDataButton = '';
    let fileName = isProjectWise ? CRMC_FILE_TITLE.CYCLE_DETAILS : CRMC_FILE_TITLE.EMPLOYEE_RATINGS;

    const gridData = isProjectWise ? projectCycles : projectRatings;

    const dataForExcelFile = isProjectWise
      ? crmcCyclesNormalizers(columnInfo, JSON.parse(JSON.stringify(gridData)))
      : normalizeEmployeeRatings(gridData ? gridData : []);

    const exportRatingsMenu = (
      <EnhancedMenu onClick={this.handleExportMenuClick}>
        <EnhancedMenu.Item key="summary">
          <CSVLink data={dataForExcelFile} filename={`${fileName} (${cycleInfo.cycleName}).csv`}>
            Summary
          </CSVLink>
        </EnhancedMenu.Item>
        <EnhancedMenu.Item key="ratings">Detailed Reporting</EnhancedMenu.Item>
      </EnhancedMenu>
    );

    if (!isProjectWise && filteredRecords) {
      recordCount = filteredRecords.length;
      const employees = new Set(filteredRecords.map(({ employeeId }) => employeeId));
      employeeCount = employees.size;

      ratingCount = (
        <h3 className="empCount">
          Employees:
          <span className="totalEmployeesNumber">{`${employeeCount}`}</span>
          <span style={{ marginLeft: '10%' }}>Total Records:</span>
          <span className="totalEmployeesNumber">{`${recordCount}`}</span>
        </h3>
      );
      exportDataButton = (
        <CSVLink
          data={normalizeEmployeeRatings(projectRatings ? projectRatings : [])}
          filename={`Employees_Ratings_${new Date().toJSON().slice(0, 10).replace(/-/g, '/')}.csv`}
        >
          <EnhancedButton icon="file-excel" style={{ marginRight: '15px' }}>
            Export
          </EnhancedButton>
        </CSVLink>
      );
    }
    return (
      <div>
        {this.state.addEditResourceModalVisible && (
          <RatingModalCRMC
            onModalClose={this.handleEditClose}
            resourceName={this.state.selectedResourceName}
            projectRating={this.state.rating}
            visible={true}
            ratingDetails={ratingDetails}
            resetRatingDetails={resetRatingDetails}
          />
        )}
        <div style={{ background: 'white' }}>
          <EnhancedCard bordered={false}>
            <EnhancedRow>
              {/* <EnhancedCol span={24}> */}
              <EnhancedCol span={2}>
                <img src={projectIcon} alt="logo" style={{ width: '60px', height: '60px' }} />
              </EnhancedCol>
              <EnhancedCol span={20}>
                <h2 style={{ color: 'black', fontSize: '10' }}>
                  {`Cycle Details - ${cycleInfo.cycleName ? cycleInfo.cycleName : ''}`}
                </h2>
                <p>
                  {`${
                    cycleInfo.startDate
                      ? moment(cycleInfo.startDate).format(dateFormat.DDMMMYYYYwithSpace)
                      : ''
                  }
                      -
                      ${
                        cycleInfo.endDate
                          ? moment(cycleInfo.endDate).format(dateFormat.DDMMMYYYYwithSpace)
                          : ''
                      }`}
                </p>
                <p>{`Eligiblity criteria: ${cycleInfo.criteria ? cycleInfo.criteria : ''} days`}</p>
                {!this.isCycleComplete() && !this.props.isLoading ? (
                  <span>
                    <EnhancedButton
                      style={{ marginBottom: '10px' }}
                      onClick={this.completeCycleConfirmation}
                      loading={this.props.isLoading}
                    >
                      Mark as Completed
                    </EnhancedButton>
                  </span>
                ) : (
                  ''
                )}
                {!this.isCycleComplete() && (
                  <React.Fragment>
                    <EnhancedButton
                      style={{ marginBottom: '10px', marginLeft: '2%' }}
                      type="primary"
                      onClick={this.showUpdatePerformanceCycleModal}
                    >
                      Update Performance Cycle
                    </EnhancedButton>
                    {this.hasDueDatePassed() && (
                      <EnhancedButton
                        style={{ marginBottom: '10px', marginLeft: '2%' }}
                        type="primary"
                        onClick={this.showDueDateExtensionModal}
                      >
                        Update Deadlines
                      </EnhancedButton>
                    )}
                  </React.Fragment>
                )}
              </EnhancedCol>
              <EnhancedCol span={2}>
                {gridData && gridData.length ? (
                  <EnhancedDropdown overlay={exportRatingsMenu}>
                    <EnhancedButton>
                      Export <EnhancedIcon type="down" />
                    </EnhancedButton>
                  </EnhancedDropdown>
                ) : (
                  ''
                )}
              </EnhancedCol>
              {/* </EnhancedCol> */}
            </EnhancedRow>
          </EnhancedCard>
        </div>
        {this.state.modalOpen ? this.renderModal() : ''}
        {this.state.extendDueDateModalOpen && (
          <DueDateExtensionModal
            open={true}
            onClose={this.hideDueDateExtensionModal}
            projects={this.getProjectsForCycle()}
            onSave={this.handleProjectCycleDueDatesUpdated}
          />
        )}
        {this.state.updatePerformanceCycleModalOpen ? this.renderUpdatePerformanceCycleModal() : ''}
        <EnhancedCol span={24} style={{ backgroundColor: 'white' }}>
          <EnhancedTabs
            defaultActiveKey={this.state.gridViewType.toString()}
            onChange={this.gridViewTypeChange}
          >
            <TabPane tab="Project" key={1} style={{ backgroundColor: '#f0f2f5' }}>
              <p />
            </TabPane>
            <TabPane tab="Employee" key={2} style={{ backgroundColor: '#f0f2f5' }}>
              <p />
            </TabPane>
          </EnhancedTabs>
        </EnhancedCol>
        <EnhancedCol span={24} style={{ backgroundColor: 'white' }}>
          <EnhancedCol span={16}>{ratingCount}</EnhancedCol>
        </EnhancedCol>
        <EnhancedCol span={24} style={{ backgroundColor: 'white' }}>
          <EnhancedClientSideTable
            rowKey={(record) => record.id}
            loading={isLoading}
            columns={columnInfo}
            data={gridData || []}
            rowClassName={(record) => (record.auditLogs ? '' : 'hide-icon hide-li-icon')}
            rowExpandable={(record) => record.auditLogs}
            expandedRowRender={(record) => {
              return (
                record.auditLogs &&
                record.auditLogs.map((item) => {
                  if (item.type === 'dueDateUpdated') {
                    return (
                      <span>
                        <p>
                          Rating due date extended till &nbsp;
                          <b>{moment(item.value).format(dateFormat.DDMMMYYYYwithSpace)}</b>
                        </p>
                      </span>
                    );
                  }
                })
              );
            }}
            setFilteredRecords={(records) => {
              this.setState({
                filteredRecords: records,
              });
            }}
          />
        </EnhancedCol>
      </div>
    );
  }
}

export default PerformanceCycle;
