export const leaveStatus = {
  ALL: 'all',
  MY_PENDING: 'my-pending',
  PENDING: 'pending',
  DECLINED: 'declined',
  APPROVED: 'approved',
  CANCELLED: 'cancelled',
  PENDING_CANCELLATION: 'pending-cancellation',
};

export const leaveStatuses = Object.values(leaveStatus);

export const leaveTypes = {
  SICK: 1,
  CASUAL: 2,
  ANNUAL: 3,
  HAJJ: 4,
  COMPASSIONATE: 5,
  PATERNITY: 6,
  MATERNITY: 7,
  SABBATICAL: 8,
  LEAVE_WITHOUT_PAY: 9,
};

export const leaveTypeCategories = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const SORT_OPTIONS = {
  ascend: 'asc',
  descend: 'desc',
};

export const leaveStatusGroups = {
  USER: 'LeaveRequest',
  APPROVER: 'LeaveRequestApprover',
};

export const commonLeaves = [leaveTypes.ANNUAL, leaveTypes.CASUAL, leaveTypes.SICK];

export const ADVANCE_LEAVE_COUNT = 5;

export const ATTRIBUTES = {
  LINE_MANAGER: 'Line Manager',
  MANAGER: 'Manager',
  HR: 'HR',
  CRMC: 'CRMC',
};

export const ATTRIBUTE_IDS = {
  HR: 9,
};

export const ALL_LEAVES = { id: 0, name: 'All Leaves' };

export const HR_DASHBOARD_TABS = {
  [ATTRIBUTES.HR]: 'Leave Quotas',
};

export const DASHBOARD_TABS = {
  [ATTRIBUTES.LINE_MANAGER]: 'Direct Reporting',
  [ATTRIBUTES.MANAGER]: 'Indirect Reporting',
};

export const leaveQuotaKeys = [
  'taleoId',
  'email',
  'name',
  'designation',
  'lineManager',
  'deptt',
  'casual',
  'sick',
  'annual',
];

export const leaveQuotaMeta = [
  {
    key: 'taleoId',
    label: 'Taleo ID',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'designation',
    label: 'Designation',
  },
  {
    key: 'lineManager',
    label: 'Manager',
  },
  {
    key: 'deptt',
    label: 'Department',
  },
  {
    key: 'casual',
    label: 'Casual Leaves',
  },
  {
    key: 'sick',
    label: 'Sick Leaves',
  },
  {
    key: 'annual',
    label: 'Annual Leaves',
  },
];

export const allLeavesKeys = [
  'employeeTaleoId',
  'employeeName',
  'designation',
  'managerName',
  'deptt',
  'type',
  'status',
  'requestDate',
  'startDate',
  'endDate',
  'isHalfDay',
];

export const allLeavesMeta = [
  {
    key: 'employeeTaleoId',
    label: 'Taleo ID',
  },
  {
    key: 'employeeName',
    label: 'Name',
  },
  {
    key: 'designation',
    label: 'Designation',
  },
  {
    key: 'managerName',
    label: 'Manager',
  },
  {
    key: 'deptt',
    label: 'Department',
  },
  {
    key: 'type',
    label: 'Leave Type',
  },
  {
    key: 'status',
    label: 'Leave Status',
  },
  {
    key: 'requestDate',
    label: 'Request Date',
  },
  {
    key: 'startDate',
    label: 'Start Date',
  },
  {
    key: 'endDate',
    label: 'End Date',
  },
  {
    key: 'isHalfDay',
    label: 'Half Day',
  },
];

export const leavesLogMeta = [
  {
    key: 'employeeDetail.oracleId',
    label: 'Taleo ID',
  },
  {
    key: 'employeeDetail.fullName',
    label: 'Name',
  },
  {
    key: 'adjustedByUser.fullName',
    label: 'Adjusted By',
  },
  {
    key: 'count',
    label: 'Adjusted Count',
  },
  {
    key: 'action',
    label: 'Action',
  },
  {
    key: 'quotaBeforeAdjustment',
    label: 'Quota Before Adjustment',
  },
  {
    key: 'quotaAfterAdjustment',
    label: 'Quota After Adjustment',
  },
  {
    key: 'createdAt',
    label: 'Adjusted Date',
  },
  {
    key: 'comment',
    label: 'Comment',
  },
];
