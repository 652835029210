import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedIcon,
  EnhancedInput,
} from 'components/shared/antd';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import XLSX from 'xlsx';
import { Table, InputNumber } from 'antd';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import EnhancedFilter from 'components/EnhancedFIlter';
import { getMappedDataForExport, writeToXLSX } from 'utils/ExcelFilesUtils';

const EnhancedClientSideTable = (props) => {
  const {
    data,
    exportFileConfig: { fileName, fileHeaders, showExportButton },
    rowSelection,
    rowKey,
    rowClassName,
    showResetFiltersButton,
    columns,
    loading,
    scroll,
    onChange,
    style,
    expandedRowRender,
    rowExpandable,
    setFilteredRecords,
    locale,
    className,
    onRow,
    shouldResetFiltersOnDataChange,
  } = props;

  const filterKeyToCustomConfigMap = useRef({});

  const [dataSource, setDataSource] = useState([]);
  const [filterInfo, setFilterInfo] = useState({});
  const [searchBoxState, setSearchBoxState] = useState({});
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterColumn, setFilterColumn] = useState();

  const filterRecords = (records) => {
    let filteredList = [...records];

    Object.keys(filterInfo).forEach((filterKey) => {
      if (filterInfo[filterKey]) {
        const filterValue = filterInfo[filterKey];
        const { dataKey, customFilterFunc, type } = filterKeyToCustomConfigMap.current[filterKey];

        // enhanced filters has checkboxes, so multiple values can be received as filter
        if (type === 'enhanced_filter') {
          if (filterValue.length > 0) {
            const listToFilter = filterValue.map((item) => item.toLowerCase());
            filteredList = filteredList.filter((item) => {
              if (customFilterFunc) {
                return customFilterFunc(item, filterValue, filterKey);
              }
              return (
                get(item, dataKey) && listToFilter.includes(`${get(item, dataKey)}`.toLowerCase())
              );
            });
          }
        } else if (type === 'search') {
          filteredList = filteredList.filter((item) => {
            if (customFilterFunc) {
              return customFilterFunc(item, filterValue, filterKey);
            }
            return (
              get(item, dataKey) &&
              `${get(item, dataKey)}`.toLowerCase().includes(filterValue.toLowerCase())
            );
          });
        } else if (type === 'range_filter') {
          filteredList = filteredList.filter((item) => {
            let response = true;
            const currentValue = get(item, dataKey);

            if (filterValue.min) {
              response = response && currentValue >= filterValue.min;
            }
            if (filterValue.max) {
              response = response && currentValue <= filterValue.max;
            }

            return response;
          });
        }
      }
    });

    return filteredList;
  };

  const handleResetSearch = () => {
    setFilterInfo({});
    setSearchBoxState({});
  };

  useEffect(() => {
    if (shouldResetFiltersOnDataChange) {
      // whenever data is changed from parent, reset the applied filters
      handleResetSearch();
      setDataSource(data);
    } else {
      const filteredList = filterRecords(data);
      setDataSource(filteredList);

      if (setFilteredRecords) {
        setFilteredRecords(filteredList);
      }
    }
  }, [data]);

  useEffect(() => {
    const filteredList = filterRecords(data);
    setDataSource(filteredList);

    if (setFilteredRecords) {
      setFilteredRecords(filteredList);
    }
  }, [filterInfo]);

  const handleFilterVisible = (visible, column) => {
    setIsFilterVisible(visible);
    setFilterColumn(column);
  };

  const handleFilterApply = (dataIndex, selection) => {
    setFilterInfo({
      ...filterInfo,
      [dataIndex]: selection,
    });
    setIsFilterVisible(false);
  };

  const getEnhancedFilterOptions = (optionsConfig, dataIndex) => {
    const { values, valueVar = 'name', labelVar = 'name' } = optionsConfig;

    const filters = values.map((item) => ({
      text: item[labelVar],
      value: item[valueVar],
    }));

    const filteredValue = filterInfo[dataIndex] || [];

    return {
      filters,
      filteredValue,
      filterDropdownVisible: isFilterVisible && filterColumn === dataIndex,
      onFilterDropdownVisibleChange: (visible) => handleFilterVisible(visible, dataIndex),
      filterDropdown: (filterProps) => (
        <EnhancedFilter
          filters={filters}
          filterDropdownVisible={isFilterVisible && filterColumn === dataIndex}
          filteredValue={filteredValue}
          {...filterProps}
          onApply={(selection) => handleFilterApply(dataIndex, selection)}
        />
      ),
    };
  };

  const onSearchInputChange = (value, dataIndex) => {
    setSearchBoxState({
      ...searchBoxState,
      [dataIndex]: value,
    });
  };

  const onSearch = () => {
    setFilterInfo({
      ...filterInfo,
      [filterColumn]: searchBoxState[filterColumn],
    });
  };

  const getColumnSearchProps = (dataIndex, columnTitle) => ({
    filterDropdown: () => (
      <EnhancedRow className="drop-down-filter">
        <EnhancedCol span={19}>
          <EnhancedInput
            placeholder={`Search ${columnTitle}`}
            value={searchBoxState[dataIndex]}
            onChange={(e) => onSearchInputChange(e.target.value, dataIndex)}
            onPressEnter={onSearch}
          />
        </EnhancedCol>
        <EnhancedCol offset={1} span={4}>
          <EnhancedButton type="primary" shape="circle" icon="search" onClick={onSearch} />
        </EnhancedCol>
      </EnhancedRow>
    ),
    filterIcon: (filtered) => (
      <EnhancedIcon type="search" style={{ color: filtered ? '#1DAC8A' : undefined }} />
    ),
    filteredValue: filterInfo[dataIndex] ? [filterInfo[dataIndex]] : '',
    onFilterDropdownVisibleChange: (visible) => handleFilterVisible(visible, dataIndex),
    filterDropdownVisible: isFilterVisible && filterColumn === dataIndex,
  });

  const onRangeInputChange = (value, dataIndex, rangeType) => {
    let newRangeObj = {};
    if (searchBoxState[dataIndex]) {
      newRangeObj = {
        ...searchBoxState[dataIndex],
        [rangeType]: value,
      };
    } else {
      newRangeObj = {
        [rangeType]: value,
      };
    }
    setSearchBoxState((prevState) => ({
      ...prevState,
      [dataIndex]: newRangeObj,
    }));
  };

  const getRangeFilterDropdown = (dataIndex) => {
    return (
      <EnhancedRow className="drop-down-filter">
        <EnhancedCol span={19}>
          <EnhancedCol>
            Min:{' '}
            <InputNumber
              min={0}
              max={5}
              value={(searchBoxState[dataIndex] || {}).min || 0}
              onChange={(e) => onRangeInputChange(e, dataIndex, 'min')}
              onPressEnter={onSearch}
              style={{ marginBottom: 2, width: 55 }}
            />
          </EnhancedCol>
          <EnhancedCol>
            Max:{' '}
            <InputNumber
              min={0}
              max={5}
              value={(searchBoxState[dataIndex] || {}).max || 5}
              onChange={(e) => onRangeInputChange(e, dataIndex, 'max')}
              onPressEnter={onSearch}
              style={{ width: 55 }}
            />
          </EnhancedCol>
        </EnhancedCol>
        <EnhancedCol offset={0} span={3}>
          <EnhancedButton type="primary" shape="circle" icon="search" onClick={onSearch} />
        </EnhancedCol>
      </EnhancedRow>
    );
  };

  const getRangeFilterOptions = (dataIndex) => {
    return {
      filterDropdownVisible: isFilterVisible && filterColumn === dataIndex,
      filterIcon: (filtered) => (
        <EnhancedIcon type="filter" style={{ color: filtered ? '#1DAC8A' : undefined }} />
      ),
      filterDropdown: getRangeFilterDropdown(dataIndex),
      filteredValue: filterInfo[dataIndex] ? [filterInfo[dataIndex]] : '',
      onFilterDropdownVisibleChange: (visible) => handleFilterVisible(visible, dataIndex),
    };
  };

  const getColumnsInfo = () => {
    return columns.map((columnConfig) => {
      const { filterConfig } = columnConfig;
      let filterProps = {};

      if (filterConfig) {
        if (filterConfig.type === 'search') {
          filterProps = getColumnSearchProps(columnConfig.dataIndex, columnConfig.title);
        } else if (filterConfig.type === 'enhanced_filter') {
          filterProps = getEnhancedFilterOptions(
            filterConfig.optionsConfig,
            columnConfig.dataIndex,
          );
        } else if (filterConfig.type === 'range_filter') {
          filterProps = getRangeFilterOptions(columnConfig.dataIndex);
        }

        filterKeyToCustomConfigMap.current = {
          ...filterKeyToCustomConfigMap.current,
          [columnConfig.dataIndex]: {
            dataKey: filterConfig.key,
            customFilterFunc: filterConfig.customFunc,
            type: filterConfig.type,
          },
        };
      }

      return {
        ...columnConfig,
        ...filterProps,
      };
    });
  };

  const handleGenerateExcelFile = () => {
    const sheet = XLSX.utils.aoa_to_sheet(
      getMappedDataForExport({
        data: dataSource,
        sheetMeta: fileHeaders,
      }),
    );

    writeToXLSX(sheet, fileName);
  };

  return (
    <React.Fragment>
      {(showResetFiltersButton || showExportButton) && (
        <EnhancedRow type="flex" justify="end" align="middle" className="mb-20 mt-4 mr-5">
          <EnhancedCol>
            {showResetFiltersButton && (
              <EnhancedButton
                data-testid="clear-filters"
                icon="filter"
                onClick={() => handleResetSearch()}
              >
                Clear Filters
              </EnhancedButton>
            )}
            {!isEmpty(dataSource) && showExportButton && (
              <EnhancedButton icon="file-excel" className="ml-10" onClick={handleGenerateExcelFile}>
                Export
              </EnhancedButton>
            )}
          </EnhancedCol>
        </EnhancedRow>
      )}
      <Table
        loading={loading}
        dataSource={dataSource}
        columns={[...getColumnsInfo()]}
        rowSelection={rowSelection}
        rowKey={rowKey}
        pagination={{
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} records`,
          showSizeChanger: true,
          showQuickJumper: true,
          size: 'small',
        }}
        onChange={onChange}
        rowClassName={rowClassName}
        scroll={scroll}
        style={style}
        expandable={{ expandedRowRender, rowExpandable }}
        locale={locale}
        className={className}
        onRow={onRow}
      />
    </React.Fragment>
  );
};

EnhancedClientSideTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataIndex: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      renderer: PropTypes.func.isRequired,
      filterConfig: PropTypes.shape({
        type: PropTypes.string.isRequired,
        optionsConfig: PropTypes.arrayOf(
          PropTypes.shape({
            values: PropTypes.any,
            valueVar: PropTypes.string.isRequired,
            labelVar: PropTypes.string.isRequired,
          }),
        ),
        key: PropTypes.string.isRequired, // variable on which search will be applied within the data
        customFunc: PropTypes.func,
      }),
      sorter: PropTypes.func.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  exportFileConfig: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    showExportButton: PropTypes.bool,
    fileHeaders: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        renderer: PropTypes.func,
      }),
    ),
  }),
  showResetFiltersButton: PropTypes.bool,
  loading: PropTypes.bool,
  rowClassName: PropTypes.string,
  scroll: PropTypes.Object,
  style: PropTypes.Object,
  className: PropTypes.string,
  onRow: PropTypes.func.isRequired,
  rowExpandable: PropTypes.bool,
  shouldResetFiltersOnDataChange: PropTypes.bool,
  setFilteredRecords: PropTypes.func, // if consumer needs filtered records list to show some stats or anything
};

EnhancedClientSideTable.defaultProps = {
  showResetFiltersButton: true,
  exportFileConfig: {
    showExportButton: false,
  },
  loading: false,
  rowClassName: '',
  scroll: {},
  style: {},
  setFilteredRecords: () => {},
  className: '',
  rowExpandable: false,
  shouldResetFiltersOnDataChange: true,
};

export default EnhancedClientSideTable;
