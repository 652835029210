import { EnhancedNotification } from 'components/shared/antd';
import {
  getCompanyWideDashboard,
  getLeavesAdjustmentsLogs,
} from 'sources/hr/LeavesDashboardSource';
import {
  GET_COMPANY_WIDE_DASHBOARD_INIT,
  GET_COMPANY_WIDE_DASHBOARD_SUCCESS,
  GET_COMPANY_WIDE_DASHBOARD_FAILURE,
  GET_LEAVES_ADJUSTMENTS_LOGS_INIT,
  GET_LEAVES_ADJUSTMENTS_LOGS_SUCCESS,
  GET_LEAVES_ADJUSTMENTS_LOGS_FAILURE,
} from 'constants/LeaveDashboard';
import { showLoader, hideLoader } from 'store/actions/AppLoaderActions';
import { generateLeavesLogReport } from 'utils/leaveUtils';

// ASYNC ACTIONS
export const getCompanyWideDashboardInit = () => ({
  type: GET_COMPANY_WIDE_DASHBOARD_INIT,
});

export const getCompanyWideDashboardSuccess = (data) => ({
  type: GET_COMPANY_WIDE_DASHBOARD_SUCCESS,
  data,
});

export const getCompanyWideDashboardFailure = () => ({
  type: GET_COMPANY_WIDE_DASHBOARD_FAILURE,
});

export const getLeavesAdjustmentsLogsInit = () => ({
  type: GET_LEAVES_ADJUSTMENTS_LOGS_INIT,
});

export const getLeavesAdjustmentsLogsSuccess = (data) => ({
  type: GET_LEAVES_ADJUSTMENTS_LOGS_SUCCESS,
  data,
});

export const getLeavesAdjustmentsLogsFailure = () => ({
  type: GET_LEAVES_ADJUSTMENTS_LOGS_FAILURE,
});

export const getLeavesAdjustmentsLogsAction = (params) => (dispatch) => {
  dispatch(getLeavesAdjustmentsLogsInit());
  return getLeavesAdjustmentsLogs(params)
    .then((response) => dispatch(getLeavesAdjustmentsLogsSuccess(response)))
    .catch((error) => dispatch(getLeavesAdjustmentsLogsFailure(error)));
};

export const getCompanyWideDashboardAction = (params) => (dispatch) => {
  dispatch(getCompanyWideDashboardInit());
  return getCompanyWideDashboard(params)
    .then((response) => dispatch(getCompanyWideDashboardSuccess(response)))
    .catch((error) => dispatch(getCompanyWideDashboardFailure(error)));
};

export const exportLeavesLogAction = (params) => (dispatch) => {
  dispatch(showLoader());
  return getLeavesAdjustmentsLogs(params)
    .then((response) => {
      generateLeavesLogReport(response.rows);
      dispatch(hideLoader());
    })
    .catch((error) => {
      console.log(error);
      dispatch(hideLoader());
      EnhancedNotification.error({ message: 'Unable to download leaves logs list.' });
    });
};
