import { connect } from 'react-redux';
import { getDefaultersAction } from 'store/actions/DefaulterActions';
import Defaulters from 'components/Defaulters';

function mapStateToProps(state) {
  const { defaulterReducer } = state;
  return {
    ...defaulterReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDefaulters: (startDate, endDate) => dispatch(getDefaultersAction(startDate, endDate)),
  };
}

const DefaulterContainer = connect(mapStateToProps, mapDispatchToProps)(Defaulters);

export default DefaulterContainer;
