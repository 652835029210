import React from 'react';
import { EnhancedCol, EnhancedRow, EnhancedAvatar, EnhancedModal } from 'components/shared/antd';
import './employeeDirectory.css';

const EmployeeDirectoryModal = (props) => {
  const { name, designation, email, department, location, mobileNumber, manager, imageUrl } =
    props.employee;
  return (
    <EnhancedRow gutter={16} type="flex" style={{ marginTop: '20px' }} align="middle">
      <EnhancedModal
        className="employee-search-modal"
        width="550px"
        visible={props.visible}
        footer={null}
        bodyStyle={{
          padding: '1.5vw 2vw',
        }}
        onCancel={props.hideModal}
      >
        <EnhancedRow
          type="flex"
          align="middle"
          className="modal-top-row"
          style={{ flexWrap: 'initial' }}
        >
          {imageUrl && (
            <EnhancedCol flex="50px">
              <a href={imageUrl}>
                <EnhancedAvatar
                  className="event-avatar"
                  src={imageUrl}
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '100%',
                    background: '#0D2234',
                    marginRight: '20px',
                  }}
                >
                  {name.match(/\b(\w)/g).join('')}
                </EnhancedAvatar>
              </a>
            </EnhancedCol>
          )}
          <EnhancedCol flex="auto">
            <h1>{name}</h1>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow>
          <EnhancedCol span={24}>
            <p>
              <span>Email: </span>
              {email}
            </p>
            {designation && (
              <p>
                <span>Designation: </span>
                {designation}
              </p>
            )}
            {department && department !== 'not_set' && (
              <p>
                <span>Department: </span>
                {department}
              </p>
            )}
            {manager && (
              <p>
                <span>Manager: </span>
                {manager}
              </p>
            )}
            {location && location !== 'Not Set' && (
              <p>
                <span>Location: </span>
                {location}
              </p>
            )}
            {mobileNumber && (
              <p>
                <span>Mobile: </span>
                {mobileNumber}
              </p>
            )}
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedModal>
    </EnhancedRow>
  );
};

export default EmployeeDirectoryModal;
