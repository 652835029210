import { connect } from 'react-redux';

import PCProject from 'components/pc/PCProject';

import {
  getProjectByCycleIdAction,
  getProjectByCycleIdActionForCRMC,
  getRatingDetailsAction,
  addProjectRatingAction,
  resetRatingDetails,
} from 'store/actions/PerformanceCycleActions';

import updateBreadCrumb from 'store/actions/BreadCrumbActions';

function mapStateToProps(state) {
  const { auth, performanceCycleReducer } = state;
  return {
    userId: auth.user && auth.user.id,
    readOnly: auth.user.isReadOnly,
    isCRMC1: auth.user && auth.user.isCRMC1,
    isCRMC2: auth.user && auth.user.isCRMC2,
    projectCycleData: performanceCycleReducer.projectCycleData,
    ratingDetails: performanceCycleReducer.ratingDetails,
    isLoading: performanceCycleReducer.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectByCycleIdAction: (cycleId) => dispatch(getProjectByCycleIdAction(cycleId)),
    getProjectByCycleIdActionForCRMC: (cycleId) =>
      dispatch(getProjectByCycleIdActionForCRMC(cycleId)),
    addProjectRatingAction: (payload) => dispatch(addProjectRatingAction(payload)),
    updateBreadCrumb: (key, oldVal, newVal) => dispatch(updateBreadCrumb(key, oldVal, newVal)),
    getRatingDetails: (payload) => dispatch(getRatingDetailsAction(payload)),
    resetRatingDetails: () => dispatch(resetRatingDetails()),
  };
}

const PCProjectContainer = connect(mapStateToProps, mapDispatchToProps)(PCProject);

export default PCProjectContainer;
