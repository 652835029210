import { connect } from 'react-redux';
import Locations from 'components/locations/locations';
import {
  getAllLocationActions,
  createLocationActions,
  updateLocationActions,
  deleteLocationActions,
} from 'store/actions/LocationActions';

function mapStateToProps(state) {
  const { locationsReducer, auth } = state;
  const { locations, isLoading } = locationsReducer;
  return {
    locations,
    isLoading,
    userId: auth.user && auth.user.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAllLocations: () => dispatch(getAllLocationActions()),
    createLocation: (request) => dispatch(createLocationActions(request)),
    updateLocation: (request) => dispatch(updateLocationActions(request)),
    deleteLocation: (request) => dispatch(deleteLocationActions(request)),
  };
}

const LocationsContainer = connect(mapStateToProps, mapDispatchToProps)(Locations);

export default LocationsContainer;
