export function normalizeInterview(interviewObj) {
  return {
    ...interviewObj,
    resourceName: interviewObj.employee.fullName,
    clientName: interviewObj.project.name,
    trackName: interviewObj.track.name,
  };
}

export function normalizeInterviewsList(interviews) {
  return interviews.map((interview) => normalizeInterview(interview));
}
