import { connect } from 'react-redux';

import AddEmployee from 'components/employees/AddEmployee';

import { addEmployeeAction, resetAddEmployeeAction } from 'store/actions/AddEmployeeActions';
import { getAllDesignationActions } from 'store/actions/DesignationActions';

import {
  getEmployeesSkillsActions,
  getEmployeeDepartmentsAction,
  getEmployeeBusinessUnitAction,
  getEmployeeDivisionsAction,
  getEmployeeCompetenciesAction,
} from 'store/actions/EmployeeActions';
import { getAllLocationActions } from 'store/actions/LocationActions';

const mapStateToProps = (state) => {
  const { auth, addEmployeeReducer, employeeReducer, designationsReducer, locationsReducer } =
    state;
  return {
    isLoading: addEmployeeReducer.isLoading,
    userId: auth.user && auth.user.id,
    isSuccessfull: addEmployeeReducer.isSuccessfull,
    employees: employeeReducer.employees,
    locations: locationsReducer.locations,
    skills: employeeReducer.skills,
    designations: designationsReducer.designations,
    departments: employeeReducer.departments,
    businessUnits: employeeReducer.businessUnits,
    divisions: employeeReducer.divisions,
    competencies: employeeReducer.competencies,
    error: addEmployeeReducer.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addEmployee: (req, id) => dispatch(addEmployeeAction(req, id)),
  resetAddEmployee: () => dispatch(resetAddEmployeeAction()),
  getEmployeesSkillsActions: () => dispatch(getEmployeesSkillsActions()),
  getAllLocations: () => dispatch(getAllLocationActions()),
  getAllDesignations: () => dispatch(getAllDesignationActions()),
  getEmployeeDepartments: () => dispatch(getEmployeeDepartmentsAction()),
  getBusinessUnits: () => dispatch(getEmployeeBusinessUnitAction()),
  getDivisions: () => dispatch(getEmployeeDivisionsAction()),
  getCompetencies: () => dispatch(getEmployeeCompetenciesAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEmployee);
