import {
  EnhancedButton,
  EnhancedCol,
  EnhancedRow,
  EnhancedCard,
  EnhancedInput,
} from 'components/shared/antd';
import React, { Component } from 'react';
import moment from 'moment';
import { getLeaveStatusColor } from 'utils/colorUtils';
import { leaveStatus, leaveTypes } from 'configs/leaveConstants';
import { workDayCount, findLeaveStatus, approvedLeavePermission } from 'utils/leaveUtils';
import { Mobile, Desktop } from 'components/Responsive';
import { leaveStatusGroups } from '../../configs/leaveConstants';

const DATE_FORMAT = 'DD/MM/YYYY';
const { TextArea } = EnhancedInput;

export default class LeaveRequestCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      errorText: false,
      declinedLoader: false,
      approvedLoader: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loader) this.setState({ declinedLoader: false, approvedLoader: false });
  }

  onClickUpdate = (leaveRequestId, approver, status) => {
    const { comment } = this.state;
    const {
      user: { id: approverId },
      leavesStatuses,
    } = this.props;

    const statusObj = findLeaveStatus(status, leavesStatuses, leaveStatusGroups.APPROVER);
    const statusValue = statusObj.systemKey && statusObj.systemKey.toLowerCase();

    if (comment.trim() === '') {
      this.setState({ errorText: 'Comments are required' });
    } else {
      this.setState({
        errorText: '',
        declinedLoader: status === statusValue,
        approvedLoader: status === statusValue,
      });

      const payload = {
        leaveRequestId,
        approverId,
        statusId: statusObj.id,
        comments: comment,
        reference: approver.reference,
      };
      this.props.onUpdateLeave(payload);
    }
  };

  onClickComment = (leaveRequestId, approver) => {
    const { comment } = this.state;
    const {
      user: { id: approverId },
    } = this.props;
    if (comment.trim() === '') {
      this.setState({ errorText: 'Comments are required' });
    } else {
      this.setState({
        errorText: '',
      });

      const payload = {
        leaveRequestId,
        approverId,
        comments: comment,
        reference: approver.reference,
      };

      this.props.onUpdateLeave(payload);
    }
  };

  onChangeComment = (e) => this.setState({ comment: e.target.value });

  getLeaveBalanceFromRequest() {
    const { request } = this.props;
    let balance = 0;
    if (!request) balance = 0;
    switch (request.type) {
      case leaveTypes.CASUAL:
        balance = request.casualBalance;
        break;
      case leaveTypes.ANNUAL:
        balance = request.annualBalance;
        break;
      case leaveTypes.SICK:
        balance = request.sickBalance;
        break;
      default:
        return '';
    }

    return ` (${balance} leaves remaining) `;
  }

  render() {
    const {
      request: { id, currentStatus, leaveName, requester, requesterNote, isHalfDay, approvers },
      user: { id: userId },
      currentlyActiveTab,
    } = this.props;

    let { startDate, endDate, requestDate } = this.props.request;

    startDate = moment.utc(startDate);
    endDate = moment.utc(endDate);
    requestDate = moment.utc(requestDate);

    let duration = 1;
    if (isHalfDay) {
      duration = 0.5;
    } else if (endDate.diff(startDate, 'days') > 0) {
      duration = workDayCount(startDate, endDate, this.props.workingHolidays);
    }

    const { canApprove, approver } = approvedLeavePermission({
      approvers,
      currentlyActiveTab,
      currentStatus,
      userId,
    });
    return (
      <EnhancedCard
        style={{ borderRadius: 4, paddingBottom: '10px' }}
        title={
          <div>
            <Desktop>
              <span style={{ fontSize: 16, fontWeight: 500 }}>
                {`${requester.name} Leave Request`}
              </span>
            </Desktop>
            <Mobile>
              <div style={{ fontSize: 16, fontWeight: 500 }}>Leave Request</div>
              <span style={{ wordBreak: 'normal' }} className="header-green">
                {`Applied on: ${requestDate.format(DATE_FORMAT)}`}
              </span>
            </Mobile>
          </div>
        }
        extra={
          <Desktop>
            <span className="header-green">{`Applied on: ${requestDate.format(DATE_FORMAT)}`}</span>
          </Desktop>
        }
      >
        <Mobile>
          <EnhancedRow style={{ paddingBottom: 10 }}>
            <EnhancedCol span={24}>
              <span style={{ fontSize: 14, wordBreak: 'normal', fontWeight: 500 }}>
                {requester.name}
              </span>
            </EnhancedCol>
          </EnhancedRow>
        </Mobile>
        <EnhancedRow>
          <EnhancedCol lg={4} md={4} sm={24} xs={24} style={{ fontWeight: 450 }}>
            Dates:{' '}
          </EnhancedCol>
          <EnhancedCol lg={20} md={20} sm={24} xs={24}>{`${startDate.format(
            DATE_FORMAT,
          )} - ${endDate.format(DATE_FORMAT)}`}</EnhancedCol>
        </EnhancedRow>
        <EnhancedRow className="marginTop15">
          <EnhancedCol lg={4} md={4} sm={24} xs={24} style={{ fontWeight: 450 }}>
            Amount:{' '}
          </EnhancedCol>
          <EnhancedCol lg={20} md={20} sm={24} xs={24}>
            {`${duration} ${duration > 1 ? 'Leaves' : 'Leave'}`}
            {(currentStatus === leaveStatus.PENDING ||
              currentStatus === leaveStatus.PENDING_CANCELLATION) &&
              this.getLeaveBalanceFromRequest()}
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow className="marginTop15">
          <EnhancedCol lg={4} md={4} sm={24} xs={24} style={{ fontWeight: 450 }}>
            Type:{' '}
          </EnhancedCol>
          <EnhancedCol
            lg={20}
            md={20}
            sm={24}
            xs={24}
            style={{ textTransform: 'capitalize' }}
          >{`${leaveName} Leave`}</EnhancedCol>
        </EnhancedRow>
        <EnhancedRow className="marginTop15">
          <EnhancedCol lg={4} md={4} sm={24} xs={24} style={{ fontWeight: 450 }}>
            Status:{' '}
          </EnhancedCol>
          <EnhancedCol
            lg={20}
            md={20}
            sm={24}
            xs={24}
            style={{
              color: getLeaveStatusColor(currentStatus),
              textTransform: 'capitalize',
            }}
          >
            {currentStatus}
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow className="marginTop15">
          <EnhancedCol lg={4} md={4} sm={24} xs={24} style={{ fontWeight: 450 }}>
            Reason:{' '}
          </EnhancedCol>
          <EnhancedCol lg={20} md={20} sm={24} xs={24}>
            {requesterNote}
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow className="marginTop15">
          <EnhancedCol lg={4} md={4} sm={24} xs={24} style={{ fontWeight: 450 }}>
            Comment:{' '}
          </EnhancedCol>
          <EnhancedCol lg={20} md={20} sm={24} xs={24}>
            <TextArea
              style={{ resize: 'none' }}
              placeholder="Mention your comments here"
              rows="4"
              disabled={!canApprove}
              value={this.state.comment}
              onChange={this.onChangeComment}
            />
            {this.state.errorText && <small className="error">{this.state.errorText}</small>}
          </EnhancedCol>
        </EnhancedRow>
        {canApprove ? (
          <EnhancedRow gutter={16} className="marginTop15">
            <EnhancedCol lg={7} offset={3} md={7} sm={24} xs={24} className="leave-modal-buttons">
              <EnhancedButton
                style={{ width: '100%' }}
                type="secondary"
                onClick={() => this.onClickComment(id, approver)}
                loading={this.state.declinedLoader}
                disabled={this.state.acceptedLoader}
              >
                Comment
              </EnhancedButton>
            </EnhancedCol>
            <EnhancedCol
              style={{ color: 'red' }}
              lg={7}
              md={7}
              sm={24}
              xs={24}
              className="leave-modal-buttons"
            >
              <EnhancedButton
                style={{ width: '100%', color: 'white', backgroundColor: '#ff0000a6' }}
                type="secondary"
                onClick={() => this.onClickUpdate(id, approver, leaveStatus.DECLINED)}
                loading={this.state.declinedLoader}
                disabled={this.state.acceptedLoader}
              >
                Decline
              </EnhancedButton>
            </EnhancedCol>
            <EnhancedCol lg={7} md={7} sm={24} xs={24} className="leave-modal-buttons">
              <EnhancedButton
                style={{ width: '100%' }}
                type="primary"
                onClick={() => this.onClickUpdate(id, approver, leaveStatus.APPROVED)}
                loading={this.state.approvedLoader}
                disabled={this.state.declinedLoader}
              >
                Approve
              </EnhancedButton>
            </EnhancedCol>
          </EnhancedRow>
        ) : (
          <EnhancedRow gutter={16} className="marginTop15">
            <EnhancedCol lg={7} offset={17}>
              <EnhancedButton
                style={{ width: '100%' }}
                type="primary"
                onClick={() => this.props.closeModal()}
              >
                OK
              </EnhancedButton>
            </EnhancedCol>
          </EnhancedRow>
        )}
      </EnhancedCard>
    );
  }
}
