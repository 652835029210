import axios from 'axios';
import { EnhancedNotification } from 'components/shared/antd';
import {
  getRolesInit,
  getRolesSuccess,
  getRolesFailure,
  getRoleEmployeesInit,
  getRoleEmployeesSuccess,
  getRoleEmployeesFailure,
  addRoleInit,
  addRoleSuccess,
  addRoleFailure,
  updateRoleInit,
  updateRoleSuccess,
  updateRoleFailure,
  deleteRoleInit,
  deleteRoleSuccess,
  deleteRoleFailure,
  getDDLEmployeesInit,
  getDDLEmployeesSuccess,
  getDDLEmployeesFailure,
  assignRoleToEmployeeInit,
  assignRoleToEmployeeSuccess,
  assignRoleToEmployeeFailure,
  deleteRoleFromEmployeeInit,
  deleteRoleFromEmployeeSuccess,
  deleteRoleFromEmployeeFailure,
} from 'store/actions/RoleActions';

import {
  roleUrls,
  roleEmployeesURls,
  employeeDropDownUrls,
  assignRoleToEmployeeUrls,
} from 'configs/constants';

import {
  normalizeRoleEmployees,
  normalizeDDLEmployees,
} from 'normalizers/roleEmployeesNormalizers';

export const getRoles = () => (dispatch) => {
  dispatch(getRolesInit());
  axios
    .get(roleUrls.GET)
    .then((response) => {
      if (response.data.success) {
        dispatch(getRolesSuccess(response.data.data));
      } else {
        dispatch(getRolesFailure(response.data.code));
      }
    })
    .catch((error) => {
      dispatch(getRolesFailure(error));
    });
};

export const getRoleEmployees = (id) => {
  const url = roleEmployeesURls.GET.replace('{:id}', id);
  return (dispatch) => {
    dispatch(getRoleEmployeesInit());
    axios
      .get(url)
      .then((response) => {
        if (response.data.success) {
          const result = normalizeRoleEmployees(response.data.data);
          dispatch(getRoleEmployeesSuccess(result));
        } else {
          dispatch(getRoleEmployeesFailure(response.data.code));
        }
      })
      .catch((error) => {
        dispatch(getRoleEmployeesFailure(error));
      });
  };
};

export const getDDLEmployees = (userId) => {
  const url = employeeDropDownUrls.GET.replace('{:userId}', userId);
  return (dispatch) => {
    dispatch(getDDLEmployeesInit());
    axios
      .get(url)
      .then((response) => {
        if (response.data.success) {
          const result = normalizeDDLEmployees(response.data.data);
          dispatch(getDDLEmployeesSuccess(result));
        } else {
          dispatch(getDDLEmployeesFailure(response.data.code));
        }
      })
      .catch((error) => {
        dispatch(getDDLEmployeesFailure(error));
      });
  };
};

export const addRole = (req) => {
  const url = roleUrls.POST;
  return (dispatch) => {
    dispatch(addRoleInit());
    axios
      .post(url, req)
      .then((response) => {
        if (response.data.success) {
          dispatch(addRoleSuccess(response.data.data));
        } else {
          dispatch(addRoleFailure(response.data.code));
        }
      })
      .catch((error) => {
        dispatch(addRoleFailure(error));
      });
  };
};

export const assignRoleToEmployee = (roleId, employeeId, userId) => {
  const url = assignRoleToEmployeeUrls.POST;
  const req = {};
  req.roleId = roleId;
  req.employeeId = userId;
  req.userId = employeeId;
  return (dispatch) => {
    dispatch(assignRoleToEmployeeInit());
    axios
      .post(url, req)
      .then((response) => {
        if (response.data.success) {
          dispatch(assignRoleToEmployeeSuccess(response.data.data));
          EnhancedNotification.success({ message: 'Role Assigned Successfully.' });
          dispatch(getRoleEmployees(roleId));
        } else {
          EnhancedNotification.error({ message: 'Role Assignment Failed.' });
          dispatch(assignRoleToEmployeeFailure(response.data.code));
        }
      })
      .catch((error) => {
        dispatch(assignRoleToEmployeeFailure(error));
      });
  };
};

export const deleteRoleFromEmployee = (employeeRoleId, roleId, userId) => {
  const url = assignRoleToEmployeeUrls.DELETE.replace('{:id}', employeeRoleId);
  return (dispatch) => {
    dispatch(deleteRoleFromEmployeeInit());
    axios
      .delete(url, userId)
      .then((response) => {
        if (response.data.success) {
          dispatch(deleteRoleFromEmployeeSuccess(response.data.data));
          EnhancedNotification.success({ message: 'Role Deleted Successfully.' });
          dispatch(getRoleEmployees(roleId));
        } else {
          dispatch(deleteRoleFromEmployeeFailure(response.data.code));
          EnhancedNotification.error({ message: 'Role Delete Failure.' });
        }
      })
      .catch((error) => {
        dispatch(deleteRoleFromEmployeeFailure(error));
      });
  };
};

export const updateRole = (req) => {
  const url = roleUrls.PUT;
  return (dispatch) => {
    dispatch(updateRoleInit());
    axios
      .put(url, req)
      .then((response) => {
        if (response.data.success) {
          dispatch(updateRoleSuccess(response.data.data));
          dispatch(getRoles());
        } else {
          dispatch(updateRoleFailure(response.data.code));
        }
      })
      .catch((error) => {
        dispatch(updateRoleFailure(error));
      });
  };
};

export const deleteRole = (req) => {
  const url = roleUrls.DELETE.replace('{:id}', req);
  return (dispatch) => {
    dispatch(deleteRoleInit());
    axios
      .delete(url, req)
      .then((response) => {
        if (response.data.success) {
          dispatch(deleteRoleSuccess(response.data));
          dispatch(getRoles());
        } else {
          dispatch(deleteRoleFailure(response.data.code));
        }
      })
      .catch((error) => {
        dispatch(deleteRoleFailure(error));
      });
  };
};
