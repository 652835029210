export const XLS_FILENAME = 'Questions Frequency';

export const XLS_PER_TRACK_META = [
  {
    key: 'frequency',
    label: 'Frequency',
  },
  {
    key: 'question',
    label: 'Question',
  },
  {
    key: 'track.name',
    label: 'Track',
  },
];

export const XLS_PER_CLIENT_AND_TRACK_META = [
  {
    key: 'frequency',
    label: 'Frequency',
  },
  {
    key: 'question',
    label: 'Question',
  },
  {
    key: 'project.name',
    label: 'Client',
  },
  {
    key: 'track.name',
    label: 'Track',
  },
];
