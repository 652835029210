import { connect } from 'react-redux';

import {
  getProjectReviewsProjectDataAction,
  getProjectReviewsRatingDataAction,
  clearProjectReviewsRatingDataAction,
} from 'store/actions/ProjectReviewsActions';
import { getProjectScoreAction } from 'store/actions/PerformanceCycleActions';
import ProjectReviewsRating from '../components/pc/ProjectReviewsRating';

import updateBreadCrumb from '../store/actions/BreadCrumbActions';

function mapStateToProps(state) {
  const { projectReviewsReducer, auth, performanceCycleReducer } = state;
  return {
    userId: auth.user && auth.user.id,
    projectRatingData: projectReviewsReducer.projectRatingData,
    projectRating: projectReviewsReducer.projectRating,
    isLoading: projectReviewsReducer.isLoading,
    projectScore: performanceCycleReducer.projectScore,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectReviewsProjectDataAction: (projectCycleId, projectId) =>
      dispatch(getProjectReviewsProjectDataAction(projectCycleId, projectId)),
    getProjectReviewsRatingDataAction: (payload) =>
      dispatch(getProjectReviewsRatingDataAction(payload)),
    updateBreadCrumb: (key, oldVal, newVal) => dispatch(updateBreadCrumb(key, oldVal, newVal)),
    clearProjectReviewsRatingDataAction: () => dispatch(clearProjectReviewsRatingDataAction()),
    getProjectScoreAction: (performanceCycleId, projectId) =>
      dispatch(getProjectScoreAction(performanceCycleId, projectId)),
  };
}

const ProjectReviewsRatingContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectReviewsRating);

export default ProjectReviewsRatingContainer;
