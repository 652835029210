import React from 'react';
import { Slider } from 'antd';
import PropTypes from 'prop-types';

const EnhancedSlider = (props) => {
  const { className, style, value, disabled, min, max, step, range, onChange, reverse, ...rest } =
    props;

  return (
    <Slider
      className={className}
      style={style}
      value={value}
      disabled={disabled}
      min={min}
      max={max}
      step={step}
      onChange={onChange}
      range={range}
      reverse={reverse}
      {...rest}
    />
  );
};

EnhancedSlider.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  value: PropTypes.number,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  range: PropTypes.bool,
  reverse: PropTypes.bool,
};

EnhancedSlider.defaultProps = {
  className: '',
  style: {},
  value: 0,
  disabled: false,
  min: 0,
  max: 100,
  step: 1,
  range: false,
  reverse: false,
};

export default EnhancedSlider;
