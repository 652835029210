import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedIcon,
  EnhancedDivider,
  EnhancedAlert,
  EnhancedLayout,
  EnhancedModal,
  EnhancedSelect,
  EnhancedInput,
  EnhancedForm,
} from 'components/shared/antd';
/* eslint-disable no-prototype-builtins */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'components/project/Project.css';
import { NOT_AVAILABLE } from 'configs/additionalConstants';
import EnhancedClientSideTable from 'components/shared/EnhancedClientSideTable';
import RequiredField from '../components/RequiredField';

const { Header } = EnhancedLayout;
const { confirm } = EnhancedModal;
const { Option } = EnhancedSelect;

class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: null,
      sortedEmployeeInfo: null,
      roles: [],
      role: {
        id: null,
        role: null,
      },
      selectedEmployeeId: null,
      addRoleModalOpen: false,
      editRoleModalOpen: false,
      assignRoleModalOpen: false,
      errorText: '',
    };
  }

  componentDidMount() {
    this.props.getRolesAction();
    this.props.getAllDesignations();
  }

  componentWillReceiveProps(nextProps) {
    let { roles, role, employees } = this.props;
    roles = roles !== nextProps.roles ? nextProps.roles : roles;
    role = role !== nextProps.role ? nextProps.role : role;
    employees = employees !== nextProps.employees ? nextProps.employees : employees;
    if (role !== undefined) {
      this.setState({
        role,
      });
    }
    if (roles !== undefined) {
      this.setState({
        roles,
      });
    }
    if (employees !== undefined) {
      this.setState({
        employees,
      });
    }
  }

  onChange = (field, value) => {
    const project = { ...this.state.project };
    project[field] = value;
    this.setState({
      project,
    });
  };

  onClose = () => {
    this.setState({
      errorText: '',
      project: {
        ...this.state.project,
        name: '',
      },
    });
  };

  getColumnsInfo = () => {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'Role Name',
        dataIndex: 'role',
        key: 'role',
        width: '50%',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.role.localeCompare(b.role),
        sortOrder: sortedInfo.columnKey === 'role' && sortedInfo.order,
        filterConfig: {
          type: 'search',
          key: 'role',
        },
      },
      {
        title: 'Actions',
        width: '50%',
        render: this.renderRoleActions,
      },
    ];
    return columns;
  };

  getRoleEmployeesColumnsInfo = () => {
    let { sortedEmployeeInfo } = this.state;
    sortedEmployeeInfo = sortedEmployeeInfo || {};

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '33%',
        render: this.renderEmployeeFirstName,
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedEmployeeInfo.columnKey === 'name' && sortedEmployeeInfo.order,
        filterConfig: {
          type: 'search',
          key: 'name',
        },
      },
      {
        title: 'Designation',
        dataIndex: 'employeeDesignation',
        key: 'employeeDesignation',
        width: '33%',
        render: (employeeDesignation) =>
          employeeDesignation ? employeeDesignation.name : NOT_AVAILABLE,
        filterConfig: {
          type: 'enhanced_filter',
          key: 'employeeDesignation.name',
          optionsConfig: {
            values: this.props.designations,
          },
        },
      },
      {
        title: 'Actions',
        width: '33%',
        render: this.renderEmployeeRoleActions,
      },
    ];
    return columns;
  };

  showDeleteConfirm = (data) => {
    const { deleteRoleFromEmployeeAction } = this.props;
    const { userId } = this.props;
    confirm({
      title: 'Are you sure to delete the employee from this role?',
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteRoleFromEmployeeAction(data.id, data.roleId, userId);
      },
      onCancel() {},
    });
  };

  showTotal = (total) => `Total ${total} items`;

  showAddRoleModal = () => {
    this.setState({
      addRoleModalOpen: !this.state.addRoleModalOpen,
    });
  };

  showEditRoleModal = (data) => {
    const { role } = this.state;
    role.id = data.id;
    role.role = data.role;
    this.setState({
      role,
      editRoleModalOpen: !this.state.editRoleModalOpen,
    });
  };

  showAssignRoleModal = (data) => {
    const { id } = data;
    const { userId } = this.props;
    this.props.getRoleEmployeesAction(id);
    this.props.getDDLEmployeesAction(userId);
    const { role } = this.state;
    role.id = data.id;
    role.role = data.role;
    this.setState({
      role,
      assignRoleModalOpen: !this.state.assignRoleModalOpen,
    });
  };

  handleAddRoleCancel = () => {
    this.setState({
      addRoleModalOpen: false,
      role: {
        role: null,
      },
      errorText: '',
    });
  };

  handleEditRoleCancel = () => {
    this.setState({
      editRoleModalOpen: false,
      role: {
        role: null,
      },
      errorText: '',
    });
  };

  handleAssignRoleCancel = () => {
    this.setState({
      assignRoleModalOpen: false,
      role: {
        role: null,
      },
      errorText: '',
      selectedEmployeeId: null,
    });
  };

  handleRoleNameChange = (value) => {
    const role = { ...this.state.role };
    role.role = value.target.value;
    this.setState({
      role,
    });
  };

  handleEmployeeDDLChange = (value) => {
    this.setState({
      selectedEmployeeId: value,
    });
  };

  assignRoleToEmployee = () => {
    const { userId } = this.props;
    this.props.assignRoleToEmployeeAction(
      this.state.role.id,
      this.state.selectedEmployeeId,
      userId,
    );
    this.setState({
      selectedEmployeeId: null,
    });
  };

  // removeRoleFromEmployee = (data) => {
  //   this.props.deleteRoleFromEmployeeAction(data.id, data.roleId);
  // };

  shouldAddRoleButtonDisable = () => {
    const { role } = this.state;
    if (role.role) {
      return false;
    }
    return true;
  };

  shouldAssignRoleButtonDisable = () => {
    const { selectedEmployeeId } = this.state;
    if (selectedEmployeeId) {
      return false;
    }
    return true;
  };

  handleAddRoleSubmit = (value) => {
    value.preventDefault();
    const { role } = this.state.role;
    const { roles } = this.props;
    const similarRole = roles.filter((r) => r.role.toLowerCase() === role.toLowerCase())[0];

    if (similarRole) {
      this.setState({
        errorText: 'Role with the same name already exists.',
      });
    } else {
      this.props.addRoleAction(this.state.role);
      this.handleAddRoleCancel();
    }
  };

  handleEditRoleSubmit = (value) => {
    value.preventDefault();
    this.props.updateRoleAction(this.state.role);
    this.handleEditRoleCancel();
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({ sortedInfo: sorter });
  };

  handleEmployeeTableChange = (pagination, filters, sorter) => {
    this.setState({ sortedEmployeeInfo: sorter });
  };

  renderRoleFormRow = () => {
    const { errorText } = this.state;
    return (
      <EnhancedRow type="flex" justify="center">
        {errorText && (
          <EnhancedAlert
            style={{ width: '100%' }}
            message={errorText}
            type="error"
            closable
            onClose={this.onClose}
          />
        )}
        <EnhancedForm layout="inline">
          <EnhancedCol span={24} className="form-col">
            <p>
              <RequiredField>Role </RequiredField>
            </p>
            <EnhancedInput
              placeholder="Role"
              id="role"
              onChange={this.handleRoleNameChange}
              value={this.state.role.role}
            />
          </EnhancedCol>
        </EnhancedForm>
      </EnhancedRow>
    );
  };

  renderAssignRoleView = () => {
    const { role } = this.state;
    const { employees } = this.state;
    const { isLoading } = this.props;
    const { employeeRoles } = role;

    const employeesDropDown = (
      <EnhancedSelect
        value={this.state.selectedEmployeeId}
        showSearch
        style={{ width: 300 }}
        onChange={this.handleEmployeeDDLChange}
        placeholder="Select an Employee"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {employees.map((record) => (
          <Option key={record.id} value={record.id}>
            {record.name}
          </Option>
        ))}
      </EnhancedSelect>
    );

    return (
      <EnhancedRow type="flex" justify="center">
        <EnhancedForm layout="inline">
          <EnhancedCol span={17}>
            <RequiredField>Employees</RequiredField>
            {employeesDropDown}
          </EnhancedCol>
          <EnhancedCol span={6}>
            <EnhancedButton
              disabled={this.shouldAssignRoleButtonDisable()}
              onClick={() => this.assignRoleToEmployee()}
            >
              Add
            </EnhancedButton>
          </EnhancedCol>
          <EnhancedCol span={24} style={{ marginTop: 20 }}>
            <EnhancedClientSideTable
              loading={isLoading}
              onChange={this.handleEmployeeTableChange}
              columns={this.getRoleEmployeesColumnsInfo()}
              data={employeeRoles || []}
              exportFileConfig={{
                fileName: `Employees having ${role.role} Role`,
                fileHeaders: [
                  {
                    label: 'Name',
                    key: 'name',
                  },
                  {
                    label: 'Designation',
                    key: 'employeeDesignation',
                    renderer: (employeeDesignation) =>
                      employeeDesignation ? employeeDesignation.name : NOT_AVAILABLE,
                  },
                ],
                showExportButton: true,
              }}
            />
          </EnhancedCol>
        </EnhancedForm>
      </EnhancedRow>
    );
  };

  renderAssignRoleModal = () => {
    const { assignRoleModalOpen } = this.state;
    const { role } = this.state;
    const roleName = role.role ? role.role : '';
    return (
      <EnhancedModal
        title={`Assign Role - ${roleName}`}
        visible={assignRoleModalOpen}
        userId={this.props.userId}
        onCancel={this.handleAssignRoleCancel}
        onOk={this.handleAssignRoleCancel}
        width={650}
      >
        {this.renderAssignRoleView()}
      </EnhancedModal>
    );
  };

  renderEditRoleModal = () => {
    const { editRoleModalOpen } = this.state;
    return (
      <EnhancedModal
        title="Edit Role"
        visible={editRoleModalOpen}
        userId={this.props.userId}
        onCancel={this.handleEditRoleCancel}
        width={650}
        footer={[
          <EnhancedButton key="back" onClick={this.handleEditRoleCancel}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            key="add"
            type="primary"
            disabled={this.shouldAddRoleButtonDisable()}
            onClick={this.handleEditRoleSubmit}
          >
            Save
          </EnhancedButton>,
        ]}
      >
        {this.renderRoleFormRow()}
      </EnhancedModal>
    );
  };

  renderaddRoleModal = () => {
    const { addRoleModalOpen } = this.state;
    return (
      <EnhancedModal
        title="New Role"
        visible={addRoleModalOpen}
        userId={this.props.userId}
        onCancel={this.handleAddRoleCancel}
        width={650}
        footer={[
          <EnhancedButton key="back" onClick={this.handleAddRoleCancel}>
            Cancel
          </EnhancedButton>,
          <EnhancedButton
            key="add"
            type="primary"
            disabled={this.shouldAddRoleButtonDisable()}
            onClick={this.handleAddRoleSubmit}
          >
            Save
          </EnhancedButton>,
        ]}
      >
        {this.renderRoleFormRow()}
      </EnhancedModal>
    );
  };

  renderEmployeeRoleActions = (data) => (
    <div>
      <EnhancedIcon
        type="delete"
        role="presentation"
        style={{ cursor: 'pointer' }}
        onClick={() => this.showDeleteConfirm(data)}
      />
    </div>
  );

  renderRoleActions = (data) => {
    const href = '#';
    return (
      <div>
        {/* <EnhancedButton onClick={() => this.showEditRoleModal(data)}>
        <b style={{ color: '#5A5A5A' }}>Edit</b>
      </EnhancedButton>
      <Divider type="vertical" />
      <EnhancedButton onClick={() => this.showDeleteConfirm(data)}>
        <b style={{ color: '#5A5A5A' }}>Delete</b>
      </EnhancedButton> */}
        <EnhancedDivider type="vertical" />
        <Link to={href} onClick={() => this.showAssignRoleModal(data)}>
          <b style={{ color: '#5A5A5A' }}>Manage Users</b>
        </Link>
      </div>
    );
  };

  renderEmployeeFirstName = (name) => <b style={{ color: '#5A5A5A' }}>{name}</b>;

  render() {
    const { roles } = this.state;
    const { isLoading } = this.props;

    return (
      <div>
        <Header className="project-header">
          <EnhancedRow>
            <EnhancedCol lg={12}>
              <h1 className="heading-pro">Roles</h1>
            </EnhancedCol>
            {/* <Col lg={12} style={{ textAlign: 'right' }}>
              <EnhancedButton type="primary" onClick={this.showAddRoleModal}>
                {this.state.showAddNewForm ? 'Close' : 'Add New Role'}
              </EnhancedButton>
            </Col> */}
          </EnhancedRow>
        </Header>
        {this.state.addRoleModalOpen ? this.renderaddRoleModal() : ''}
        {this.state.editRoleModalOpen ? this.renderEditRoleModal() : ''}
        {this.state.assignRoleModalOpen ? this.renderAssignRoleModal() : ''}
        <div className="project-table">
          <EnhancedClientSideTable
            loading={isLoading}
            onChange={this.handleChange}
            columns={this.getColumnsInfo()}
            data={roles}
            exportFileConfig={{
              fileName: 'Roles',
              fileHeaders: [
                {
                  label: 'Role',
                  key: 'role',
                },
              ],
              showExportButton: true,
            }}
          />
        </div>
      </div>
    );
  }
}

export default Roles;
