import React from 'react';
import { EnhancedSpin } from 'components/shared/antd';

const ProjectScore = (props) => {
  const {
    projectScore: { data, loading },
  } = props;

  const getLoader = () => (
    <div>
      <EnhancedSpin tip="loading" />
    </div>
  );

  const getRating = () => (
    <span className="mr-16">
      <strong>Project Score:</strong> {data.score}
    </span>
  );

  return loading ? getLoader() : getRating();
};

export default ProjectScore;
