import { connect } from 'react-redux';
import {
  getAllEmployeesActions,
  getEmployeeDepartmentsAction,
  getEmployeeBusinessUnitAction,
  getEmployeeDivisionsAction,
  getEmployeeCompetenciesAction,
} from 'store/actions/EmployeeActions';
import {
  updateGoalActions,
  getGoalActions,
  managerAddGoalActions,
  removeGoalActions,
  getGoalDetailsActions,
  updateGoalStatusActions,
  exportAllGoalsAction,
} from 'store/actions/admin/GoalsActions';

import GoalsListing from '../../components/admin/Goals/GoalsListing';

function mapStateToProps(state) {
  const { auth, employeeReducer, goalReducer } = state;
  const { allEmployees, employees, departments, businessUnits, divisions, competencies } =
    employeeReducer;

  const { isLoading } = goalReducer;
  return {
    isLoading,
    userRole: auth.user,
    list: goalReducer.goals,
    details: goalReducer.goalDetails,
    allEmployees,
    employees,
    departments,
    businessUnits,
    divisions,
    competencies,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateGoal: (req) => dispatch(updateGoalActions(req)),
    getGoal: (req) => dispatch(getGoalActions(req)),
    managerAddGoal: (req) => dispatch(managerAddGoalActions(req)),
    getGoalDetails: (req) => dispatch(getGoalDetailsActions(req)),
    removeGoal: (req) => dispatch(removeGoalActions(req)),
    updateGoalStatus: (req) => dispatch(updateGoalStatusActions(req)),
    exportAllGoals: (params) => dispatch(exportAllGoalsAction(params)),
    getAllEmployeesActions: () => dispatch(getAllEmployeesActions()),
    getEmployeeDepartments: () => dispatch(getEmployeeDepartmentsAction()),
    getEmployeeBusinessUnits: () => dispatch(getEmployeeBusinessUnitAction()),
    getEmployeeDivisionsAction: () => dispatch(getEmployeeDivisionsAction()),
    getEmployeeCompetenciesAction: () => dispatch(getEmployeeCompetenciesAction()),
  };
}

const GoalsContainer = connect(mapStateToProps, mapDispatchToProps)(GoalsListing);

export default GoalsContainer;
