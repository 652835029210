import { EnhancedButton, EnhancedForm } from 'components/shared/antd';
import React from 'react';
import moment from 'moment';
import {
  TRACKING as stateName,
  NOTICE_PERIOD_TYPES,
  RESIGNED_TYPES,
  RESIGNATION_PRIMARY_REASONS,
  DATE_FORMAT,
} from 'configs/employeeProfileConstants';
import {
  createTrackingInfo,
  // createSubHeading,
  noData,
} from 'utils/FieldGenerateUtils';
import { isEmptyObject, subtractObject, trimObjValues } from 'utils/objectUtils';
import { hasPermission } from 'utils/AccessUtils';
import {
  DE_ACTIVATE_EMPLOYEE_CONFIRM_PROMPT_MESSAGE,
  DELETE_CONFIRM_PROMPT_WARNING_MESSAGE,
} from 'configs/messageConstants';
import Permissions from 'constants/AccessPermissions';
import EditButton from 'components/shared/employeeProfile/EditButton';
import Spinner from '../../shared/Spinner';
import confirmModal from '../../shared/ConfirmModal';

class Tracking extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      [stateName]: this.props[stateName] || {},
      dropDowns: {},
      isEdit: false,
      disableSave: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps[stateName] !== this.props[stateName]) {
      this.setState({ [stateName]: nextProps[stateName] });
    }

    if (
      nextProps.employeeDeactivation.success !== this.props.employeeDeactivation.success &&
      nextProps.employeeDeactivation.success
    ) {
      this.props.getEmployeeTrackingInfo(this.props.employeeId);
    }

    this.setState({
      dropDowns: {
        noticePeriodTypeOptions: NOTICE_PERIOD_TYPES,
        resignationTypeOptions: RESIGNED_TYPES,
        primaryReasonForLeavingOptions: RESIGNATION_PRIMARY_REASONS,
      },
    });
  }

  handleEdit = () => {
    this.setState({ isEdit: true, disableSave: true });
  };

  handleCancel = () => {
    this.setState({
      isEdit: false,
      [stateName]: this.props[stateName],
    });
  };

  handleSave = (e) => {
    e.preventDefault();
    if (!this.state.disableSave) {
      this.setState({ isEdit: false });

      const updatedObject = trimObjValues(
        subtractObject(this.state[stateName], this.props[stateName]),
        ['joiningDate'],
      );
      this.props.updateEmployeeTrackingInfo(this.props.employeeId, updatedObject);
    }
  };

  handleChange = (value, key, error) => {
    const currentData = this.state[stateName];
    const newData = Object.assign({}, currentData, { [key]: value });
    this.setState({ [stateName]: newData, error: false, disableSave: false });
    if (error) {
      this.setState({ disableSave: true });
    }

    if (key === 'probationPeriodDuration') {
      this.setState({
        ...this.state,
        tracking: {
          ...this.state.tracking,
          probationPeriodDuration: value,
          probationPeriodEnd: moment(
            moment(this.state.tracking.joiningDate).add(value, 'd').format(DATE_FORMAT),
          ).format(DATE_FORMAT),
        },
        disableSave: false,
      });
    } else {
      this.setState({ [stateName]: newData, error: false, disableSave: false });
    }
  };

  deActivate = () => {
    confirmModal(
      DE_ACTIVATE_EMPLOYEE_CONFIRM_PROMPT_MESSAGE,
      () => {
        const { employeeId } = this.props;
        this.props.deActivateEmployee(employeeId);
      },
      DELETE_CONFIRM_PROMPT_WARNING_MESSAGE,
    );
  };

  render() {
    const { tracking, dropDowns, isEdit, disableSave } = this.state;
    const { noticePeriodTypeOptions, resignationTypeOptions, primaryReasonForLeavingOptions } =
      dropDowns;
    const { userPermissions, cardLoading } = this.props;
    const isEmpty = isEmptyObject(tracking);
    const canEdit = hasPermission(userPermissions, [
      Permissions.Employee.Profile.JobInfo.Tracking.Update,
    ]);
    const canDisable = hasPermission(userPermissions, [Permissions.Employee.Delete]);
    return (
      <React.Fragment>
        {tracking.isActive && canDisable && (
          <div style={{ textAlign: 'right' }}>
            <EnhancedButton className="mb-10" onClick={this.deActivate} type="danger">
              Deactivate Employee
            </EnhancedButton>
          </div>
        )}
        {cardLoading ? (
          <Spinner tip="loading" />
        ) : (
          // <div className="mt-20 pos-rel">
          <EnhancedForm className="mt-20">
            <div className="flex justify-flex-end">
              <span>
                {canEdit && !isEmpty && !isEdit ? (
                  <EditButton clickHandler={this.handleEdit} buttonText="Edit" />
                ) : (
                  ''
                )}
              </span>
              <span className="pr-10">
                {canEdit && isEdit ? (
                  <EnhancedButton onClick={this.handleSave} type="primary" disabled={disableSave}>
                    Save
                  </EnhancedButton>
                ) : (
                  ''
                )}
              </span>
              <span>
                {canEdit && isEdit ? (
                  <EnhancedButton onClick={this.handleCancel}>Cancel</EnhancedButton>
                ) : (
                  ''
                )}
              </span>
            </div>
            {!isEmpty ? (
              <div className="mt-20">
                {createTrackingInfo(
                  tracking,
                  noticePeriodTypeOptions,
                  resignationTypeOptions,
                  primaryReasonForLeavingOptions,
                  isEdit,
                  userPermissions,
                  this.handleChange,
                )}
              </div>
            ) : (
              noData()
            )}
          </EnhancedForm>
          // </div>
        )}
      </React.Fragment>
    );
  }
}

export default Tracking;
