import { EnhancedIcon, EnhancedLayout } from 'components/shared/antd';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import LogWorkListContainer from 'containers/LogWorkListContainer';
import LogoutContainer from 'containers/LogoutContainer';
import MyLeavesContainer from 'containers/MyLeavesContainer';
import LeaveDashboardContainer from 'containers/LeaveDashboardContainer';
import LeaveCalendarContainer from 'containers/LeaveCalendarContainer';
import TeamLeavesListContainer from 'containers/TeamLeavesListContainer';
import MyPerformanceEvaluationFormContainer from 'containers/MyPerformanceEvaluationFormContainer';
import MyProfileContainer from 'containers/MyProfileContainer';
import Faq from 'components/Faq';
import ClaimFaq from 'components/expense/ClaimFaq';
import LeavesDashboardContainer from 'containers/hr/LeavesDashboardContainer';
import PCProjectContainer from 'containers/PCProjectContainer';
import PCProjectCRMCContainer from 'containers/PCProjectCRMCContainer';
import ProjectRatingContainer from 'containers/ProjectRatingContainer';
import ProjectRatingCRMCContainer from 'containers/ProjectRatingCRMCContainer';
import PerformanceCycleContainer from 'containers/PerformanceCycleContainer';
import EmployeeRatingListContainer from 'containers/EmployeeRatingListContainer';
import EmployeeSearchListContainer from 'containers/EmployeeSearchListContainer';
import EmployeeOrgTreeContainer from 'containers/EmployeeOrgTreeContainer';
import EmployeeHistoryContainer from 'containers/EmployeeHistoryContainer';
import PerformanceCycleCRMCContainer from 'containers/PerformanceCycleCRMCContainer';
import RolesContainer from 'containers/RolesContainer';
import ResourcesContainer from 'containers/ResourcesContainer';
import ResourceDetailContainer from 'containers/ResourceDetailContainer';
import ProjectEmployeesContainer from 'containers/rca/ProjectEmployeesContainer';
import SkillsContainer from 'containers/SkillsContainer';
import EmploymentTypesContainer from 'containers/EmploymentTypesContainer';
import DepartmentsContainer from 'containers/DepartmentsContainer';
import BusinessUnitContainer from 'containers/BusinessUnitContainer';
import DivisionContainer from 'containers/DivisionContainer';
import DesignationsContainer from 'containers/DesignationsContainer';
import LocationsContainer from 'containers/LocationsContainer';
import CompetencyContainer from 'containers/CompetencyContainer';
import ProjectRolesContainer from 'containers/ProjectRolesContainer';
import BreadCrumbContainer from 'containers/BreadCrumbContainer';
import AppLoaderContainer from 'containers/AppLoaderContainer';
import DealsContainer from 'containers/DealsContainer';
import DealsDetailContainer from 'containers/DealsDetailContainer';

import ExpenseClaimContainer from 'containers/expense/AddExpenseClaimContainer';
import ExpenseClaimListContainer from 'containers/expense/ExpenseClaimListContainer';

import GoalsContainer from 'containers/admin/GoalsContainer';
import PredefinedGoalsContainer from 'containers/admin/PredefinedGoalsContainer';
import PredefinedGoalsCategoriesContainer from 'containers/admin/PredefinedGoalsCategoriesContainer';
import PredefinedGoalsCategoriesDetailContainer from 'containers/admin/PredefinedGoalsCategoriesDetailContainer';

import AppMenu from 'components/AppMenu';
import HomePageCMS from 'components/homePageCMS/HomePageCMS';
import NewsGridView from 'components/homePageCMS/modules/news/NewsGridView';
import NewsDetailView from 'components/homePageCMS/modules/news/NewsDetailView';
import PMDashboardContainer from 'containers/admin/PMDashboardContainer';
import ProjectReviewsContainer from 'containers/ProjectReviewsContainer';
import ProjectReviewsRatingContainer from 'containers/ProjectReviewsRatingContainer';
import PRProjectContainer from 'containers/PRProjectContainer';
import resourcePlannerContainer from 'containers/resourcePlannerContainer';
import ResourceDeficitContainer from 'containers/ResourceDeficitContainer';
import BenchSummaryContainer from 'containers/BenchSummaryContainer';
import AdminRoutes from 'components/AdminRoutes';
import CRMCRoutes from 'components/CRMCRoutes';
import EmployeeRoutes from 'components/EmployeeRoutes';
import LMDashboardRoutes from 'components/LMDashboardRoutes';
import UserDetails from 'components/UserDetails';
import { gaEvent } from 'sources/AnalyticsSource';
import { categories, events } from 'configs/gaConstants';
import 'components/App.css';
import { loggerAreas, APP_VERSION } from 'configs/constants';
import { LOGIN, ROOT } from 'configs/routesConstants';
import { Desktop, Mobile } from 'components/Responsive';
import { hasPermission } from '../utils/AccessUtils';
import AccessPermissions from '../constants/AccessPermissions';
import { EMPLOYEES } from '../configs/employeeProfileConstants';
import AuthorizedRoute from '../components/AuthorizedRoute';
import TransientResourceContainer from '../containers/TransientResourceContainer';
import InterviewTrackingContainer from '../containers/InterviewTrackingContainer';
import InterviewQuestionsContainer from '../containers/InterviewQuestionsContainer';
import InterviewQuestionsFrequencyContainer from '../containers/InterviewQuestionsFrequencyContainer';

import {
  VENDIANS_APPLE_APP_STORE_LINK,
  VENDIANS_GOOGLE_PLAY_STORE_LINK,
} from '../configs/externalLinksConstants';
import PerformanceManagementContainer from '../containers/PerformanceManagementContainer';
import PerformanceManagementEmpContainer from '../containers/PerformanceManagementEmpContainer';
import PerformanceEvaluationFormContainer from '../containers/PerformanceEvaluationFormContainer';

const { Header, Content, Footer, Sider } = EnhancedLayout;

class App extends React.Component {
  state = {
    collapsed: false,
  };

  componentDidMount() {
    const { user } = this.props;
    this.checkPermissions(user);
    if (user) {
      const {
        Employee: { List },
      } = AccessPermissions;
      if (hasPermission(user.permissions, [List])) this.props.getEmployeesActions();
      this.props.getEmployeesBirthday();
      this.props.getPublicHolidays();
      this.props.getEmployeesProjectRolesActions(user.id);
    }
    // for header to show user menu correctly
    if (window.location.pathname === '/resourcePlanner/reports/bench-summary') {
      this.toggle();
    }
  }

  componentWillReceiveProps(nextProps) {
    this.checkPermissions(nextProps.user);
  }

  checkPermissions = (user) => {
    if (!user && this.props.history.location.pathname !== LOGIN) {
      this.props.history.push(LOGIN);
    }
  };

  gotoHomePage = () => this.props.history.push(ROOT);

  toggle = () => {
    gaEvent(categories.GENERAL, events.TOGGLE_SIDER, !this.state.collapsed);
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    const { match, user, location } = this.props;
    const { collapsed } = this.state;
    const isAdmin = user && user.isAdmin;
    const isArch = user && user.isArch;
    const isFunctionalHead = user && user.isFunctionalHead;
    const isCRMC1 = user && user.isCRMC1;
    const isPM = user && user.isPM;
    const isCRMC2 = user && user.isCRMC2;
    const internal = user && user.internal;
    const isManager = user && user.isManager;
    const isSales = user && user.isSales;
    const isEngineering = user && user.isEngineering;
    const userArea = user && user.area;
    const isLogger = loggerAreas.includes(userArea);
    const name = user ? user.fullName : '';
    const tokenId = user && user.tokenId;
    const isRater = user && user.isRater;
    const isLeadership = user && user.isLeadership;
    const isDM = user && user.isDM;
    const isRCA = user && user.isRCA;

    return (
      <EnhancedLayout className="app-layout">
        {/* for bench report */}
        {window.location.pathname !== '/resourcePlanner/reports/bench-summary' && (
          <Sider
            className={`sider ${!collapsed && 'expanded'}`}
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={250}
          >
            <div
              className={`logo ${!collapsed && 'expanded'}`}
              onClick={this.gotoHomePage}
              onKeyDown={this.gotoHomePage}
              role="link"
              tabIndex={-1}
            />
            <Mobile>
              <div className="name">{name} </div>

              <AppMenu
                isAdmin={isAdmin}
                isCRMC1={isCRMC1}
                isPM={isPM}
                isCRMC2={isCRMC2}
                internal={internal}
                isArch={isArch}
                isFunctionalHead={isFunctionalHead}
                isManager={isManager}
                history={this.props.history}
                isLogger={isLogger}
                isSales={isSales}
                isEngineering={isEngineering}
                isRater={isRater}
                isLeadership={isLeadership}
                isDM={isDM}
                isRCA={isRCA}
                userArea={userArea}
                tokenId={tokenId}
                logout
              />
            </Mobile>
            <Desktop>
              <AppMenu
                isAdmin={isAdmin}
                isCRMC1={isCRMC1}
                isCRMC2={isCRMC2}
                isPM={isPM}
                internal={internal}
                isArch={isArch}
                isFunctionalHead={isFunctionalHead}
                isManager={isManager}
                history={this.props.history}
                isLogger={isLogger}
                userArea={userArea}
                isSales={isSales}
                isEngineering={isEngineering}
                isRater={isRater}
                isLeadership={isLeadership}
                isDM={isDM}
                isRCA={isRCA}
                tokenId={tokenId}
                logout={false}
              />
            </Desktop>
          </Sider>
        )}
        <EnhancedLayout>
          <Header className={this.state.collapsed ? 'app-header-unfold' : 'app-header-fold'}>
            <EnhancedIcon
              type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
              className={this.state.collapsed ? 'icon-menu-unfold' : 'icon-menu-fold'}
              onClick={this.toggle}
            />
            <UserDetails collapsed={this.state.collapsed} history={this.props.history} />
          </Header>
          <Content className={this.state.collapsed ? 'app-content-unfold' : 'app-content-fold'}>
            <BreadCrumbContainer location={location} />
            <AppLoaderContainer />
            <Switch>
              <Route
                // path={match.path}
                path={`${match.path}logwork`}
                component={LogWorkListContainer}
              />
              <Route path={`${match.path}faqs`} component={Faq} />
              <Route
                path={`${match.path}admin`}
                render={(props) => <AdminRoutes {...props} isAdmin={isAdmin} />}
              />
              <Route path={`${match.path}crmc`} render={(props) => <CRMCRoutes {...props} />} />
              <Route
                path={`${match.path}${EMPLOYEES}`}
                render={(props) => <EmployeeRoutes {...props} />}
              />
              <Route path={`${match.path}logout`} component={LogoutContainer} />
              <Route path={`${match.path}my-leave-requests`} component={MyLeavesContainer} />
              <Route path={`${match.path}leave-dashboard`} component={LeaveDashboardContainer} />
              <Route path={`${match.path}leave-calendar`} component={LeaveCalendarContainer} />
              <Route
                path={`${match.path}team-leave-requests`}
                component={TeamLeavesListContainer}
              />
              <Route path={`${match.path}profile`} component={MyProfileContainer} exact />
              <Route
                path={`${match.path}profile/:performanceCycleId`}
                component={MyPerformanceEvaluationFormContainer}
                exact
              />
              <Route
                path={`${match.path}hr/leaves-dashboard`}
                component={LeavesDashboardContainer}
              />
              <AuthorizedRoute path="/pm-admin-dashboard" component={PMDashboardContainer} />

              <AuthorizedRoute path={`${match.path}roles`} component={RolesContainer} />
              <AuthorizedRoute path={`${match.path}skills`} component={SkillsContainer} />
              <AuthorizedRoute
                path={`${match.path}employment-types`}
                component={EmploymentTypesContainer}
              />
              <AuthorizedRoute
                exact
                path={`${match.path}departments`}
                component={DepartmentsContainer}
              />
              <AuthorizedRoute
                path={`${match.path}business-unit`}
                component={BusinessUnitContainer}
              />
              <AuthorizedRoute path={`${match.path}division`} component={DivisionContainer} />

              <AuthorizedRoute
                path={`${match.path}designations`}
                component={DesignationsContainer}
              />
              <AuthorizedRoute path={`${match.path}locations`} component={LocationsContainer} />
              <AuthorizedRoute path={`${match.path}competencies`} component={CompetencyContainer} />
              <AuthorizedRoute
                path={`${match.path}project-roles`}
                component={ProjectRolesContainer}
              />
              {isRater && (
                <Route
                  path={`${match.path}pc/performance-cycles/:id/:projectId`}
                  component={ProjectRatingContainer}
                />
              )}
              <Route
                path={`${match.path}pc/performance-cycles-crmc/:id/:projectId`}
                component={ProjectRatingCRMCContainer}
              />
              {isRater && (
                <Route
                  path={`${match.path}pc/performance-cycles/:id`}
                  component={PCProjectContainer}
                />
              )}
              {/* Project Reviews Page */}
              <Route
                path={`${match.path}pc/project-reviews/:id/:projectId`}
                component={ProjectReviewsRatingContainer}
              />
              <Route path={`${match.path}pc/project-reviews/:id`} component={PRProjectContainer} />
              <Route path={`${match.path}pc/project-reviews`} component={ProjectReviewsContainer} />
              <Route
                path={`${match.path}employee/report/:id`}
                component={EmployeeHistoryContainer}
              />
              <Route
                path={`${match.path}employee-ratings`}
                component={EmployeeRatingListContainer}
              />
              <Route
                path={`${match.path}employee-directory`}
                component={EmployeeSearchListContainer}
              />
              <AuthorizedRoute
                exact
                path={`${match.path}employee-organization-tree`}
                component={EmployeeOrgTreeContainer}
              />
              <Route
                path={`${match.path}pc/performance-cycles-crmc/:id`}
                component={PCProjectCRMCContainer}
              />
              <Route exact path={`${match.path}deals`} component={DealsContainer} />
              <Route path={`${match.path}deals/:id`} component={DealsDetailContainer} exact />
              {isRater && (
                <Route
                  component={PerformanceCycleContainer}
                  path={`${match.path}pc/performance-cycles`}
                />
              )}
              <Route
                component={PerformanceCycleCRMCContainer}
                path={`${match.path}pc/performance-cycles-crmc`}
              />
              {user && <Route exact path={match.path} component={HomePageCMS} />}
              <Route path={`${match.path}news`} component={NewsGridView} exact />
              <Route path={`${match.path}news/:id`} component={NewsDetailView} exact />
              <Route path={`${match.path}skills`} component={SkillsContainer} />
              <Route path={`${match.path}billing`} component={ResourcesContainer} />
              <Route
                path={`${match.path}expense-claim/request`}
                component={ExpenseClaimContainer}
              />
              <Route
                path={`${match.path}expense-claim/list`}
                component={ExpenseClaimListContainer}
              />
              <Route path={`${match.path}expense-claim/faqs`} component={ClaimFaq} />
              <Route
                exact
                path={`${match.path}rca/billing-employees/:employeeId`}
                component={ResourceDetailContainer}
              />
              <Route
                exact
                path={`${match.path}rca/billing-projects/:projectId`}
                component={ProjectEmployeesContainer}
              />
              <AuthorizedRoute
                path={`${match.path}resourcePlanner/dealSummary`}
                component={resourcePlannerContainer}
                exact
              />
              <AuthorizedRoute
                path={`${match.path}resourcePlanner/transientResource`}
                component={TransientResourceContainer}
                exact
              />
              <AuthorizedRoute
                path={`${match.path}resourcePlanner/reports/resource-deficit`}
                component={ResourceDeficitContainer}
                exact
              />
              <AuthorizedRoute
                path={`${match.path}resourcePlanner/reports/planning-summary`}
                component={resourcePlannerContainer}
                exact
              />
              <AuthorizedRoute
                path={`${match.path}resourcePlanner/reports/bench-summary`}
                component={BenchSummaryContainer}
                exact
              />
              <Route path={`${match.path}goals`} component={GoalsContainer} />
              <Route
                exact
                path={`${match.path}goals-categories/:categoryId`}
                component={PredefinedGoalsCategoriesDetailContainer}
              />
              <Route
                path={`${match.path}goals-categories`}
                component={PredefinedGoalsCategoriesContainer}
              />
              <Route path={`${match.path}predefined-goals`} component={PredefinedGoalsContainer} />
              <Route
                path={`${match.path}interview-tracking`}
                component={InterviewTrackingContainer}
              />
              <Route
                path={`${match.path}interview-questions`}
                component={InterviewQuestionsContainer}
              />
              <Route
                path={`${match.path}interview-questions-frequency`}
                component={InterviewQuestionsFrequencyContainer}
              />
              <AuthorizedRoute
                path={`${match.path}performance-management`}
                component={PerformanceManagementContainer}
                exact
              />
              <AuthorizedRoute
                path={`${match.path}performance-management/:id`}
                component={PerformanceManagementEmpContainer}
                exact
              />
              <AuthorizedRoute
                path={`${match.path}performance-management/:id/:empId`}
                component={PerformanceEvaluationFormContainer}
                exact
              />
              <Route
                path={`${match.path}lm-dashboard`}
                render={(props) => <LMDashboardRoutes {...props} />}
              />
              <Redirect to={ROOT} />
            </Switch>
          </Content>
          <Footer className="app-footer">
            <span>{APP_VERSION}</span>
            All Rights Reserved © {new Date().getFullYear()} VentureDive Pvt Ltd.
            <div className="app-store-links">
              <a href={VENDIANS_APPLE_APP_STORE_LINK} target="_blank" rel="noopener noreferrer">
                <svg
                  fill="rgba(0,0,0,0.65)"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 50 50"
                  width="32px"
                  height="32px"
                >
                  <path d="M 44.527344 34.75 C 43.449219 37.144531 42.929688 38.214844 41.542969 40.328125 C 39.601563 43.28125 36.863281 46.96875 33.480469 46.992188 C 30.46875 47.019531 29.691406 45.027344 25.601563 45.0625 C 21.515625 45.082031 20.664063 47.03125 17.648438 47 C 14.261719 46.96875 11.671875 43.648438 9.730469 40.699219 C 4.300781 32.429688 3.726563 22.734375 7.082031 17.578125 C 9.457031 13.921875 13.210938 11.773438 16.738281 11.773438 C 20.332031 11.773438 22.589844 13.746094 25.558594 13.746094 C 28.441406 13.746094 30.195313 11.769531 34.351563 11.769531 C 37.492188 11.769531 40.8125 13.480469 43.1875 16.433594 C 35.421875 20.691406 36.683594 31.78125 44.527344 34.75 Z M 31.195313 8.46875 C 32.707031 6.527344 33.855469 3.789063 33.4375 1 C 30.972656 1.167969 28.089844 2.742188 26.40625 4.78125 C 24.878906 6.640625 23.613281 9.398438 24.105469 12.066406 C 26.796875 12.152344 29.582031 10.546875 31.195313 8.46875 Z" />
                </svg>
              </a>
              <a href={VENDIANS_GOOGLE_PLAY_STORE_LINK} target="_blank" rel="noopener noreferrer">
                <svg
                  fill="rgba(0,0,0,0.65)"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 50 50"
                  width="32px"
                  height="32px"
                >
                  <path d="M 7.125 2 L 28.78125 23.5 L 34.71875 17.5625 L 8.46875 2.40625 C 8.03125 2.152344 7.5625 2.011719 7.125 2 Z M 5.3125 3 C 5.117188 3.347656 5 3.757813 5 4.21875 L 5 46 C 5 46.335938 5.070313 46.636719 5.1875 46.90625 L 27.34375 24.90625 Z M 36.53125 18.59375 L 30.1875 24.90625 L 36.53125 31.1875 L 44.28125 26.75 C 45.382813 26.113281 45.539063 25.304688 45.53125 24.875 C 45.519531 24.164063 45.070313 23.5 44.3125 23.09375 C 43.652344 22.738281 38.75 19.882813 36.53125 18.59375 Z M 28.78125 26.3125 L 6.9375 47.96875 C 7.300781 47.949219 7.695313 47.871094 8.0625 47.65625 C 8.917969 47.160156 26.21875 37.15625 26.21875 37.15625 L 34.75 32.25 Z" />
                </svg>
              </a>
            </div>
          </Footer>
        </EnhancedLayout>
      </EnhancedLayout>
    );
  }
}

export default App;
