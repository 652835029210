import React from 'react';
import { getLeaveStatusColor } from 'utils/colorUtils';
import { EnhancedCol, EnhancedRow, EnhancedDivider } from 'components/shared/antd';

const ExpandedTableRow = ({ record }) => {
  const { approvers } = record;

  return (
    approvers &&
    approvers.map((approver, index) => (
      <React.Fragment>
        <EnhancedRow>
          <EnhancedCol lg={5} md={5} sm={24} xs={24}>
            {approver.roles.map((role) => (
              <div>
                <b>{role.name}</b>
              </div>
            ))}
          </EnhancedCol>
          <EnhancedCol lg={5} md={5} sm={24} xs={24}>
            <b>Status</b>
          </EnhancedCol>
          <EnhancedCol lg={5} md={5} sm={24} xs={24} className="mr-16">
            <b>Comments</b>
          </EnhancedCol>
          <EnhancedCol lg={5} md={5} sm={24} xs={24}>
            <b>Last updated</b>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow>
          <EnhancedCol lg={5} md={5} sm={24} xs={24}>
            {approver.name}
          </EnhancedCol>
          <EnhancedCol
            lg={5}
            md={5}
            sm={24}
            xs={24}
            style={{
              color: getLeaveStatusColor(approver.status),
              textTransform: 'capitalize',
            }}
          >
            <b>{approver.status}</b>
          </EnhancedCol>
          <EnhancedCol lg={5} md={5} sm={24} xs={24} className="mr-16">
            {approver.comments}
          </EnhancedCol>
          <EnhancedCol lg={5} md={5} sm={24} xs={24}>
            {approver.actionDate}
          </EnhancedCol>
        </EnhancedRow>
        {index < approvers.length - 1 && <EnhancedDivider />}
      </React.Fragment>
    ))
  );
};

export default ExpandedTableRow;
