import React, { useState, useEffect } from 'react';
import RequiredField from 'components/RequiredField';
import {
  EnhancedCol,
  EnhancedRow,
  EnhancedButton,
  EnhancedModal,
  EnhancedSelect,
  EnhancedInputNumber,
  EnhancedForm,
} from 'components/shared/antd';

const RequirementForm = (props) => {
  const {
    dealDetail,
    isEdit,
    details,
    isModelVisible,
    handleClose,
    skills: availableSkills,
    addDealRequirementsAction,
    updateDealRequirementsAction,
    dealRequirementParams,
  } = props;

  const initialRequirementsField = {
    resourceRequired: '',
    minExperienceLevel: null,
    maxExperienceLevel: null,
    skills: [],
  };

  const [requirementsField, setRequirementsField] = useState({
    ...initialRequirementsField,
  });

  const handleResourceRequiredChange = (value) => {
    setRequirementsField({
      ...requirementsField,
      resourceRequired: value,
    });
  };

  const handleSkillsChange = (value) => {
    setRequirementsField({
      ...requirementsField,
      skills: value,
    });
  };

  const handleMinExperienceChange = (value) => {
    setRequirementsField({
      ...requirementsField,
      minExperienceLevel: value !== '' ? value : null,
    });
  };

  const handleMaxExperienceChange = (value) => {
    setRequirementsField({
      ...requirementsField,
      maxExperienceLevel: value !== '' ? value : null,
    });
  };

  const addNewRequirements = () => {
    addDealRequirementsAction(requirementsField, dealDetail.id, {
      ...dealRequirementParams,
      page: 0,
    });
    handleClose();
    setRequirementsField({
      ...initialRequirementsField,
    });
  };

  const editRequirements = () => {
    updateDealRequirementsAction(
      requirementsField,
      details.id,
      dealDetail.id,
      dealRequirementParams,
    );
    handleClose();
    setRequirementsField({
      ...initialRequirementsField,
    });
  };

  const closeModalHandler = () => {
    handleClose();
    setRequirementsField({
      ...initialRequirementsField,
    });
  };

  useEffect(() => {
    setRequirementsField({
      resourceRequired: details.resourceRequired || '',
      minExperienceLevel:
        details.minExperienceLevel !== undefined ? details.minExperienceLevel.toString() : null,
      maxExperienceLevel:
        details.maxExperienceLevel !== undefined ? details.maxExperienceLevel.toString() : null,
      skills: details.skills || [],
    });
  }, [details]);

  const isSubmitDisabled = () => {
    const { resourceRequired, minExperienceLevel, maxExperienceLevel, skills } = requirementsField;
    return (
      !resourceRequired ||
      minExperienceLevel === null ||
      maxExperienceLevel === null ||
      skills.length === 0 ||
      (minExperienceLevel !== null &&
        maxExperienceLevel !== null &&
        parseInt(maxExperienceLevel, 10) <= parseInt(minExperienceLevel, 10))
    );
  };

  return (
    <EnhancedModal
      visible={isModelVisible}
      title={isEdit ? 'Edit Requirement' : 'Add New Requirement'}
      width={520}
      onOk={closeModalHandler}
      onCancel={closeModalHandler}
      bodyStyle={{ paddingBottom: 80 }}
      footer={[
        <EnhancedButton
          key="submit"
          type="primary"
          onClick={isEdit ? editRequirements : addNewRequirements}
          disabled={isSubmitDisabled()}
        >
          Submit
        </EnhancedButton>,
      ]}
    >
      <EnhancedForm layout="vertical" hideRequiredMark>
        <EnhancedRow gutter={16}>
          <EnhancedCol span={24}>
            <EnhancedForm.Item>
              <RequiredField>
                <span className="color-black">Resource Required</span>
              </RequiredField>
              <EnhancedInputNumber
                min={0.25}
                name="resourceRequired"
                value={requirementsField.resourceRequired}
                onChange={handleResourceRequiredChange}
                className="mt-8"
              />
            </EnhancedForm.Item>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow gutter={16}>
          <EnhancedCol span={12}>
            <EnhancedForm.Item>
              <RequiredField>
                <span className="color-black">Min Experience</span>
              </RequiredField>
              <EnhancedInputNumber
                min={0}
                max={45}
                name="minExperienceLevel"
                value={requirementsField.minExperienceLevel}
                onChange={handleMinExperienceChange}
                className="mt-8"
              />
            </EnhancedForm.Item>
          </EnhancedCol>
          <EnhancedCol span={12}>
            <EnhancedForm.Item>
              <RequiredField>
                <span className="color-black">Max Experience</span>
              </RequiredField>
              <EnhancedInputNumber
                min={0}
                max={45}
                name="maxExperienceLevel"
                value={requirementsField.maxExperienceLevel}
                onChange={handleMaxExperienceChange}
                className="mt-8"
              />
            </EnhancedForm.Item>
          </EnhancedCol>
        </EnhancedRow>
        <EnhancedRow gutter={16}>
          <EnhancedCol span={24}>
            <EnhancedForm.Item>
              <RequiredField>
                <span className="color-black">Skills</span>
              </RequiredField>
              <EnhancedSelect
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Please choose the skills"
                value={requirementsField.skills}
                className="mt-8"
                onChange={handleSkillsChange}
                name="skills"
                showSearch
                optionFilterProp="children"
              >
                {availableSkills.map((item) => (
                  <EnhancedSelect.Option value={item.name} key={item.id}>
                    {item.name}
                  </EnhancedSelect.Option>
                ))}
              </EnhancedSelect>
            </EnhancedForm.Item>
          </EnhancedCol>
        </EnhancedRow>
      </EnhancedForm>
    </EnhancedModal>
  );
};

export default RequirementForm;
