import { convertToNumber } from 'utils/conversionUtils';

function normalizeWorkLogFields(logs) {
  return logs.map((log) => ({
    projectId: log.projectId,
    percentage: convertToNumber(log.allocation) || null,
  }));
}

export function normalizeCreateWorkLogPayload(req, dateTimeString) {
  const object = {
    date: dateTimeString,
    logs: normalizeWorkLogFields(req),
  };
  const worklogs = [];
  worklogs.push(object);
  return {
    worklogs,
  };
}

function normalizeWorkLogFieldsRes(res) {
  let total = 0;
  const details = res.map((log) => {
    total += +log.hours + +log.percentage;
    return {
      projectId: log.project.id,
      projectName: log.project.name,
      logId: log.id,
      hours: +log.hours || '',
      percentage: +log.percentage,
    };
  });
  return {
    total,
    details,
  };
}

export function normalizeCreateWorkLogResponse(res) {
  return {
    id: res.id,
    date: res.date,
    employeeId: res.employee.id,
    mood: res.mood,
    workLogDetails: normalizeWorkLogFieldsRes(res.work_log_details),
  };
}
