import {
  EnhancedRow,
  EnhancedCol,
  EnhancedButton,
  EnhancedCard,
  EnhancedAffix,
} from 'components/shared/antd';
import React, { useState, useEffect } from 'react';
import { BREADCRUMBS } from 'configs/constants';
import { reviewTypes } from 'configs/employeeProfileConstants';
import evaluationStatus from 'configs/performanceManagementConstants';
import { ATTRIBUTES } from 'configs/leaveConstants';
import PerformanceEvaluationFormHeader from 'components/shared/performanceEvaluationForm/header';
import ConfirmModal from 'components/shared/performanceEvaluationForm/ConfirmModal';
import { hasPermission } from 'utils/AccessUtils';
import AccessPermissions from 'constants/AccessPermissions';
import calculateOverallRating, {
  checkEvaluationStatus,
  validateRatingsSubmission,
} from 'utils/performanceManagementUtils';
import CompetenciesStep from 'components/performanceEvaluationForm/steps/competenciesStep';
import EmployeeInformation from 'components/performanceEvaluationForm/steps/employeeInformationStep';
import EndNoteStep from 'components/performanceEvaluationForm/steps/endNoteStep';
import GoalsSteps from 'components/performanceEvaluationForm/steps/goalsStep';
import TrainingAssessment from 'components/performanceEvaluationForm/steps/trainingAssessmentStep';
import StepsComponent from 'components/performanceEvaluationForm/stepsComponent';
import PromotionStep from 'components/performanceEvaluationForm/steps/promotionStep';
import ShowPreviousCycle from 'components/performanceEvaluationForm/showPreviousCycles';
import statusConfirmContentMap from '../../shared/performanceEvaluationForm/statusConfirmContentMap';
import ValidationErrors from '../../shared/performanceEvaluationForm/ValidationErrors';

const PerformanceEvaluationForm = (props) => {
  const [step, setStep] = useState(0);
  const {
    getPEFDataActions,
    submitPEFDataActions,
    pefData,
    getPMCycleDetail,
    pmCycleDetail,
    updateBreadCrumb,
    user,
    allEmployees,
    designations,
    getAllDesignations,
    submitForApproval,
    getUserAttributes,
    approvePEF,
    showPreviousPMCycleActions,
    ratingOptions,
    getRatingOptions,
    userAttributes,
  } = props;
  const [pef, setPef] = useState({});
  const [showSelfEvaluationComments, setShowSelfEvaluationComments] = useState(false);

  const reviewType = new URLSearchParams(props.location.search).get('type');
  const { performanceCycleId, id } = props.match.params;
  const [viewer] = useState(reviewType === reviewTypes.SELF ? 'Employee' : 'Manager');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmModalStatus, setConfirmModalStatus] = useState();
  const [emptyRatings, setEmptyRatings] = useState({
    competencies: [],
    goals: [],
    commentLength: null,
    hasError: false,
  });

  const isAuthorized = hasPermission(user.permissions, [
    AccessPermissions.Reportee.Profile.Compensation.View,
    AccessPermissions.Employee.Profile.Compensation.View,
  ]);

  const isPendingApprovalStatus = confirmModalStatus === evaluationStatus.PENDING_APPROVAL;

  let steps = [
    {
      key: 0,
      title: 'Get Started',
      component: EmployeeInformation,
      dataKey: 'employeeInfo',
    },
    {
      key: 1,
      title: 'Competencies',
      component: CompetenciesStep,
      dataKey: 'competencies',
    },
    {
      key: 2,
      title: 'Goals',
      component: GoalsSteps,
      dataKey: 'goals',
    },
    {
      key: 3,
      title: 'Training',
      component: TrainingAssessment,
      dataKey: 'trainingInfo',
    },
    {
      key: 4,
      title: 'End Notes',
      component: EndNoteStep,
      dataKey: 'overallComment',
    },
  ];

  if (isAuthorized && reviewType === reviewTypes.MANAGER) {
    steps = [
      {
        key: 0,
        title: 'Get Started',
        component: EmployeeInformation,
        dataKey: 'employeeInfo',
      },
      {
        key: 1,
        title: 'Competencies',
        component: CompetenciesStep,
        dataKey: 'competencies',
      },
      {
        key: 2,
        title: 'Goals',
        component: GoalsSteps,
        dataKey: 'goals',
      },
      {
        key: 3,
        title: 'Promotion & Designation',
        component: PromotionStep,
        dataKey: 'promotionInfo',
      },
      {
        key: 4,
        title: 'Training',
        component: TrainingAssessment,
        dataKey: 'trainingInfo',
      },
      {
        key: 5,
        title: 'End Notes',
        component: EndNoteStep,
        dataKey: 'overallComment',
      },
    ];
  }

  const updateDataFromComponent = (key, value) => {
    pef[key] = value;

    pef.overallRating = calculateOverallRating(pef.goals, pef.competencies);

    setPef({ ...pef });
  };

  useEffect(() => {
    getAllDesignations();
    getUserAttributes(user.id);
    getPEFDataActions({ reviewType, empId: id, performanceCycleId });
    getPMCycleDetail(performanceCycleId);
    getRatingOptions();
  }, []);

  useEffect(() => {
    if (pmCycleDetail) {
      updateBreadCrumb(BREADCRUMBS.PERFORMANCE_MANAGEMENT, performanceCycleId, pmCycleDetail.title);
    }
  }, [pmCycleDetail]);

  useEffect(() => {
    setPef(pefData);
    if (pefData.employeeInfo) {
      updateBreadCrumb(BREADCRUMBS.EMPLOYEE, id, pefData.employeeInfo.name);
      updateBreadCrumb(BREADCRUMBS.PERFORMANCE_MANAGEMENT_EMPLOYEE, id, pefData.employeeInfo.name);
    }
  }, [pefData]);

  const isEditable = () => {
    let editingAllowed = false;

    const isRater = pef.raterId === user.id;
    const isPending = checkEvaluationStatus(pef, evaluationStatus.PENDING);
    const isPendingApproval = checkEvaluationStatus(pef, evaluationStatus.PENDING_APPROVAL);

    // Manager cannot edit self review in any case
    if (reviewType !== reviewTypes.SELF) {
      // LM can only edit review which has pending status
      if (isRater && isPending) {
        editingAllowed = true;
      }

      // not LM but someone from upward hierarchy of LM, or HR
      if (!isRater && (isPending || isPendingApproval)) {
        editingAllowed = true;
      }
    }

    return editingAllowed;
  };

  const canSubmitForApproval = () => {
    let isAllowed = false;

    const isRater = pef.raterId === user.id;
    const isPending = checkEvaluationStatus(pef, evaluationStatus.PENDING);

    if (isRater && isPending) {
      isAllowed = true;
    }

    return isAllowed;
  };

  const canApprove = () => {
    let isAllowed = false;

    const isApprover = (pef.approvers || []).find((approver) => +approver.userId === user.id);
    const isHR = userAttributes.find((userAttribute) => userAttribute.name === ATTRIBUTES.HR);

    const isPendingApproval = checkEvaluationStatus(pef, evaluationStatus.PENDING_APPROVAL);

    if ((isApprover || isHR) && isPendingApproval) {
      isAllowed = true;
    }

    return isAllowed;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const renderSwitch = () => {
    const stepObj = steps.find((item) => item.key === step);

    const Component = stepObj.component;

    return (
      <Component
        viewer={viewer}
        isEditable={isEditable()}
        designations={stepObj.dataKey === 'promotionInfo' ? designations : null}
        allEmployees={stepObj.dataKey === 'trainingInfo' ? allEmployees : null}
        data={pef[stepObj.dataKey] || {}}
        updateHandler={updateDataFromComponent}
        reviewType={reviewType}
        selfEvaluation={pef.selfEvaluation}
        showSelfEvaluationComments={showSelfEvaluationComments}
        ratingOptions={ratingOptions}
        pefData={pefData}
        emptyRatings={emptyRatings}
        setEmptyRatings={setEmptyRatings}
      />
    );
  };

  const getPefDataForSubmission = () => {
    return {
      goals: pef.goals,
      competencies: pef.competencies,
      pcRatingId: pef.pcRatingId,
      finalComment: pef.overallComment,
      trainingInfo: pef.trainingInfo,
      promotionInfo: pef.promotionInfo,
    };
  };

  const handleSave = () => {
    const payload = getPefDataForSubmission();

    submitPEFDataActions({ reviewType, empId: id, performanceCycleId, payload });
  };

  const handleSubmitForApproval = () => {
    const unmarkedRatingsObj = validateRatingsSubmission(
      pef.goals,
      pef.competencies,
      pef.overallComment,
    );
    setEmptyRatings(unmarkedRatingsObj);

    if (!unmarkedRatingsObj.hasError) {
      const request = {
        payload: {
          employeeIds: [pef.employeeInfo.id],
          ratingIds: [pef.pcRatingId],
        },
        refetchWithData: {
          performanceCycleId,
          empId: id,
          reviewType,
        },
        pefData: getPefDataForSubmission(),
      };

      submitForApproval(request);
      setConfirmModalStatus(null);
    } else {
      setConfirmModalStatus(null);
    }
  };

  const handleApproval = ({ comment }) => {
    // if present as approver, then he shud approve it as LM, Not HR.
    let as;
    const isApprover = (pef.approvers || []).find((approver) => +approver.userId === user.id);
    const isHR = userAttributes.find((userAttribute) => userAttribute.name === ATTRIBUTES.HR);

    // Both HR and LM can approve from employee profile flow
    if (isApprover) {
      as = ATTRIBUTES.LINE_MANAGER;
    } else if (isHR) {
      as = ATTRIBUTES.HR;
    }

    const request = {
      payload: {
        employeeIds: [pef.employeeInfo.id],
        ratingIds: [pef.pcRatingId],
        status: confirmModalStatus,
        as,
        comment,
      },
      refetchWithData: {
        performanceCycleId,
        empId: id,
        reviewType,
      },
      pefData: getPefDataForSubmission(),
    };

    approvePEF(request);
    setConfirmModalStatus(null);
  };

  const renderStatusConfirmModal = () => {
    let modalTitle = statusConfirmContentMap[confirmModalStatus];

    if (confirmModalStatus === evaluationStatus.PENDING_APPROVAL) {
      modalTitle = 'Are you sure you want to send the evaluation for approval?';
    }

    return (
      <ConfirmModal
        title={modalTitle}
        status={confirmModalStatus}
        onCancel={() => {
          setConfirmModalStatus(null);
        }}
        onOk={isPendingApprovalStatus ? handleSubmitForApproval : handleApproval}
      />
    );
  };

  const renderStepsButtons = () => {
    return (
      <EnhancedRow className="mb-20 mr-16">
        <EnhancedCol md={14} lg={16}>
          {isEditable() && (
            <EnhancedButton
              onClick={() => {
                handleSave();
              }}
              className="ml-10 mr-10"
            >
              Save Progress
            </EnhancedButton>
          )}

          {step === steps.length - 1 && canSubmitForApproval() && (
            <EnhancedButton
              // disabled={!enableSubmitForApprovalButton()}
              onClick={() => setConfirmModalStatus(evaluationStatus.PENDING_APPROVAL)}
              className="mr-10"
            >
              Send for Approval
            </EnhancedButton>
          )}

          {step === steps.length - 1 && canApprove() && (
            <EnhancedButton
              onClick={() => setConfirmModalStatus(evaluationStatus.APPROVED)}
              className="mr-10"
            >
              Approve
            </EnhancedButton>
          )}

          {step === steps.length - 1 && canApprove() && (
            <EnhancedButton onClick={() => setConfirmModalStatus(evaluationStatus.REJECTED)}>
              Reject
            </EnhancedButton>
          )}
        </EnhancedCol>
        <EnhancedCol md={10} lg={8} className="text-right">
          {step > 0 && (
            <EnhancedButton
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Previous Step
            </EnhancedButton>
          )}

          {step < steps.length - 1 && (
            <EnhancedButton
              onClick={() => {
                setStep(step + 1);
              }}
              className="ml-10"
            >
              Next Step
            </EnhancedButton>
          )}
        </EnhancedCol>
      </EnhancedRow>
    );
  };
  return (
    <div>
      <EnhancedCard>
        <React.Fragment>
          <PerformanceEvaluationFormHeader
            viewer={viewer}
            pef={pef}
            reviewType={reviewType}
            commentsCheckboxOnChange={setShowSelfEvaluationComments}
            commentsCheckboxValue={showSelfEvaluationComments}
            previousCyclesButtonOnClick={showModal}
            ratingOptions={ratingOptions}
            showMonthlyPerformanceRatings={reviewType === reviewTypes.MANAGER}
          />
          <EnhancedAffix offsetTop={165}>
            {renderStepsButtons()}
            <ValidationErrors emptyRatings={emptyRatings} reviewType={reviewType} />
          </EnhancedAffix>
          <StepsComponent currentStep={step} steps={steps} />
          {renderStatusConfirmModal()}
          {renderSwitch()}
        </React.Fragment>
      </EnhancedCard>
      <ShowPreviousCycle
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        params={{ empId: id }}
        showPreviousPMCycleActions={showPreviousPMCycleActions}
        previousPMCycle={props.previousPMCycle}
      />
    </div>
  );
};
export default PerformanceEvaluationForm;
