import { EnhancedButton, EnhancedInput, EnhancedCheckbox } from 'components/shared/antd';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class EnhancedFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      visible: true,
    };
  }

  onSearchInputChange = (event) => {
    this.setState({
      searchText: event.target.value,
    });
  };

  getMatchingOptions = () => {
    if (this.state.searchText) {
      const regex = new RegExp(this.state.searchText, 'i');
      return this.props.filters.filter((item) => regex.test(item.text));
    }
    return this.props.filters;
  };

  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const { selectedKeys, setSelectedKeys } = this.props;
    const finalSelectedKeys = checked
      ? [...selectedKeys, name]
      : selectedKeys.filter((key) => key !== name);
    setSelectedKeys(finalSelectedKeys);
  };

  handleApply = () => {
    this.props.onApply([...this.props.selectedKeys]);
  };

  handleClear = () => {
    this.props.onApply([]);
  };

  render() {
    const { filterByID, filterDropdownVisible } = this.props;

    if (filterDropdownVisible !== this.state.visible) {
      this.setState({ visible: filterDropdownVisible });
      this.props.setSelectedKeys([...this.props.filteredValue]);
    }

    const options = this.getMatchingOptions();
    return (
      <div className="enhanced-filter">
        {this.props.showSearch && (
          <div className="enhanced-filter-search">
            <EnhancedInput
              placeholder="Search Options"
              value={this.state.searchText}
              onChange={this.onSearchInputChange}
            />
          </div>
        )}
        <div className="enhanced-filter-options">
          {options &&
            options.map(({ value, text, id }) => (
              <div key={filterByID ? id : value}>
                <EnhancedCheckbox
                  name={filterByID ? id : value}
                  // explicit false is important for controlled checkboxes
                  checked={this.props.selectedKeys.includes(filterByID ? id : value) || false}
                  onChange={this.handleCheckboxChange}
                >
                  {text}
                </EnhancedCheckbox>
              </div>
            ))}
        </div>
        <div className="enhanced-filter-actions">
          <EnhancedButton type="default" onClick={this.handleApply} className="mr-16">
            Apply
          </EnhancedButton>
          <EnhancedButton type="default" onClick={this.handleClear}>
            Clear
          </EnhancedButton>
        </div>
      </div>
    );
  }
}

export default EnhancedFilter;

EnhancedFilter.propTypes = {
  showSearch: PropTypes.bool,
  onApply: PropTypes.func.isRequired,
};

EnhancedFilter.defaultProps = {
  showSearch: true,
};
