import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MyLeaves from 'components/leaves/MyLeaves';

import {
  getMyLeaves,
  getLeavesDetails,
  getLeavesStatuses,
  requestEditLeave,
  cancelApprovedLeave,
} from 'store/actions/LeaveActions';

function mapStateToProps(state) {
  const { auth, myLeaves, leavesStatusesReducer, publicHolidaysReducer } = state;
  return {
    user: auth.user || {},
    joiningDate: auth.user && auth.user.joiningDate,
    myLeaves,
    leavesStatuses: leavesStatusesReducer.leavesStatuses,
    workingHolidays: publicHolidaysReducer.publicHolidays.workingHolidays,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getMyLeaves,
      getLeavesDetails,
      getLeavesStatuses,
      requestEditLeave,
      cancelApprovedLeave,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MyLeaves);
