import { EnhancedCol, EnhancedRow } from 'components/shared/antd';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { leaveStatus } from 'configs/leaveConstants';
import { getLeaveStatusColor } from 'utils/colorUtils';
import { capitalizeString } from 'utils/stringUtils';
import { Desktop, Mobile } from 'components/Responsive';

const DATE_FORMAT = 'DD/MM/YYYY';

class HistoryListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { leaveRequest } = this.props;
    let { startDate, endDate, requestDate } = leaveRequest;
    const { duration } = leaveRequest;

    startDate = moment.utc(startDate);
    endDate = moment.utc(endDate);
    requestDate = moment.utc(requestDate);

    const color = getLeaveStatusColor(leaveRequest.currentStatus);

    return (
      <div>
        <Desktop>
          <EnhancedRow style={{ fontSize: '10px' }}>
            <EnhancedCol lg={1} align="center">
              <div className="counter" style={{ backgroundColor: color }}>
                {duration}
              </div>
            </EnhancedCol>
            <EnhancedCol lg={4} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
              <h4>{leaveRequest.leaveName}</h4>
              <h4 className="grey-color">Reason: {leaveRequest.requesterNote}</h4>
            </EnhancedCol>
            <EnhancedCol lg={4}>
              <h4>Status</h4>
              <h4 style={{ color }}>{capitalizeString(leaveRequest.currentStatus)}</h4>
            </EnhancedCol>
            <EnhancedCol lg={4}>
              <h4 className="grey-color">Requested Date</h4>
              <h4 className="grey-color">{requestDate.format(DATE_FORMAT)}</h4>
            </EnhancedCol>
            <EnhancedCol lg={4}>
              <h4 className="grey-color">From Date</h4>
              <h4 className="grey-color">{startDate.format(DATE_FORMAT)}</h4>
            </EnhancedCol>
            <EnhancedCol lg={4}>
              <h4 className="grey-color">To Date</h4>
              <h4 className="grey-color">{endDate.format(DATE_FORMAT)}</h4>
            </EnhancedCol>
            <EnhancedCol lg={3}>
              {leaveRequest.currentStatus !== leaveStatus.CANCELLED &&
                leaveRequest.currentStatus !== leaveStatus.DECLINED &&
                leaveRequest.currentStatus !== leaveStatus.PENDING_CANCELLATION && (
                  <div>
                    <button
                      onClick={() => this.props.onCancel(leaveRequest)}
                      className="grey-color transparent-button"
                    >
                      Cancel
                    </button>
                    {leaveRequest.currentStatus !== leaveStatus.APPROVED && (
                      <span>
                        <span> | </span>
                        <button
                          onClick={() => this.props.onEdit(leaveRequest)}
                          className="grey-color transparent-button"
                        >
                          Edit
                        </button>
                      </span>
                    )}
                  </div>
                )}
            </EnhancedCol>
          </EnhancedRow>
        </Desktop>
        <Mobile>
          <EnhancedRow>
            <EnhancedRow style={{ paddingBottom: 8 }}>
              <EnhancedCol span={4} align="center">
                <div className="counter" style={{ backgroundColor: color, width: 50, height: 50 }}>
                  {duration}
                </div>
              </EnhancedCol>
              <EnhancedCol span={20} align="center" style={{ paddingTop: 10 }}>
                <h4>{capitalizeString(leaveRequest.leaveName)}</h4>
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow>
              <EnhancedCol span={24} className="history-details">
                <h4 className="grey-color">Reason: {leaveRequest.requesterNote}</h4>
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow>
              <EnhancedCol span={12} className="history-details">
                <h4>Status</h4>
              </EnhancedCol>
              <EnhancedCol span={12} className="history-details">
                <h4 style={{ color }}>{capitalizeString(leaveRequest.currentStatus)}</h4>
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedRow>
              <EnhancedCol span={12} className="history-details">
                <h4 className="grey-color">From Date</h4>
                <h4 className="grey-color">{startDate.format(DATE_FORMAT)}</h4>
              </EnhancedCol>
              <EnhancedCol span={12} className="history-details">
                <h4 className="grey-color">To Date</h4>
                <h4 className="grey-color">{endDate.format(DATE_FORMAT)}</h4>
              </EnhancedCol>
            </EnhancedRow>
            <EnhancedCol span={24} className="history-details" style={{ textAlign: 'center' }}>
              {leaveRequest.currentStatus !== leaveStatus.CANCELLED &&
                leaveRequest.currentStatus !== leaveStatus.DECLINED &&
                leaveRequest.currentStatus !== leaveStatus.PENDING_CANCELLATION && (
                  <div>
                    <button
                      onClick={() => this.props.onCancel(leaveRequest)}
                      className="grey-color transparent-button"
                      style={{ fontWeight: 900 }}
                    >
                      Cancel
                    </button>
                    {leaveRequest.currentStatus !== leaveStatus.APPROVED && (
                      <span>
                        <span> | </span>
                        <button
                          onClick={() => this.props.onEdit(leaveRequest)}
                          className="grey-color transparent-button"
                          style={{ fontWeight: 900 }}
                        >
                          Edit
                        </button>
                      </span>
                    )}
                  </div>
                )}
            </EnhancedCol>
          </EnhancedRow>
        </Mobile>
      </div>
    );
  }
}

HistoryListItem.propTypes = {
  leaveRequest: PropTypes.object.isRequired, // eslint-disable-line
};

export default HistoryListItem;
