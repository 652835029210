/* eslint-disable import/prefer-default-export, no-param-reassign */
import moment from 'moment';
import { GENESIS_DATE } from 'configs/constants';

export default function normalizeDefaulters(res) {
  const managers = new Set();
  const employees = new Set();
  const defaulters = res
    .map((record) => {
      if (record.name) employees.add(record.name);
      if (record.manager) managers.add(record.manager);
      record.missingAllocations = record.missingAllocations.filter(
        (missingDate) =>
          moment(missingDate).isSameOrBefore(moment(), 'day') &&
          moment(missingDate).isSameOrAfter(moment(GENESIS_DATE), 'day'),
      );
      return record;
    })
    .filter((defaulter) => defaulter.missingAllocations.length)
    .sort((a, b) => {
      const lengthA = a.missingAllocations.length;
      const lengthB = b.missingAllocations.length;
      if (lengthA < lengthB) {
        return 1;
      } else if (lengthA > lengthB) {
        return -1;
      }
      return 0;
    });

  return {
    defaulters,
    employees: [...employees].sort(), // Set to Array
    managers: [...managers].sort(), // Set to Array
  };
}
